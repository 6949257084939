import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import Button from "components/Button";
import { isToday } from "joynt/components/DailySchedule/util";
import { SessionDate } from "joynt/components/TimeUtils";
import IconButton from "components/IconButton";
import Preloader from "components/Preloader";

function SessionText(props) {
    const { start, started, status } = props;

    const statusText = {
        live: "Live now",
        ended: "This session has ended",
        pending: "Scheduled in %s",
        "start-soon": "Starting soon",
        "start-today": "Starting today",
        "just-started": "Just started",
        "ended-today": "Ended today",
    };

    //const startMoment = useMoment(start);
    const startDate = new Date(start * 1000);
    const startedDate = new Date(started * 1000);

    const now = new Date();
    const today = isToday(startDate);
    const startingSoon = startDate.getTime() - now.getTime() < 1 * 60 * 1000;
    const justStarted = now.getTime() - startedDate.getTime() < 1 * 60 * 1000;

    if (status === "ended") {
        if (today) return statusText["ended-today"];
        return statusText["ended"];
    }

    if (status === "live") {
        if (justStarted) return statusText["just-started"];
        return statusText["live"];
    }

    if (startingSoon) return statusText["start-soon"];
    if (today) return statusText["start-today"];

    return statusText["pending"];
}

export function SessionStateInfo(props) {
    const {
        isPending,
        onJoin,
        onStart,
        onEnd,
        onGoToStage,
        isStageSession,
        isConnected,
        status,
        start,
        end,
    } = props;

    const isLive = status === "live";
    const hasEnded = status === "ended";

    return (
        <div className={"session-info gap-md cols cols-spread"}>
            <div>
                <div className={"session-info__text"}>
                    {start && (
                        <SessionText
                            start={start}
                            end={end}
                            started={start}
                            status={status}
                        />
                    )}
                </div>
                {start && end && <SessionDate start={start} end={end} />}
            </div>
            <div className={"cols cols-middle"}>
                {!isConnected && (
                    <div className={"cols gap-sm"}>
                        {isStageSession && (
                            <Button variant={"text"} onClick={onGoToStage}>
                                Go to stage
                            </Button>
                        )}
                        {isLive && onEnd && (
                            <Button onClick={onEnd}>End session</Button>
                        )}
                        {isLive && onJoin && (
                            <Button onClick={onJoin}>Join now</Button>
                        )}
                        {!isLive && onStart && (
                            <Button onClick={onStart}>
                                {hasEnded ? "Start again" : "Start session"}
                            </Button>
                        )}
                    </div>
                )}
                {isConnected && isLive && (
                    <div className={"cols gap-sm cols-middle"}>
                        <div>You're on session</div>
                        <IconButton icon={"mui-launch"} onClick={onGoToStage} />
                    </div>
                )}
                <Preloader visible={isPending} />
            </div>
        </div>
    );
}

SessionStateInfo.propTypes = {
    onJoin: PropTypes.func,
    onStart: PropTypes.func,
    onGoToStage: PropTypes.func,
    isJoined: PropTypes.bool,
    status: PropTypes.oneOf(["live", "ended", "pending"]),
    start: PropTypes.number,
    end: PropTypes.number,
};
SessionStateInfo.defaultProps = {
    isJoined: false,
};

export default SessionStateInfo;
