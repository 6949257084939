import React, {useCallback, useState} from 'react';
import {useIdentity} from "joynt/hooks/participants";
import TypingDots from "./TypingDots";
import {useMessaging} from "joynt/hooks";
import usePrivateChannel from "joynt/websockets/hooks/usePrivateChannel";
import useWhisper from "joynt/websockets/hooks/useWhisper";
//import PropTypes from 'prop-types';

let typingTimeout = null;

export function TypingIndicator({identity}) {
    const {name} = useIdentity(identity);
    return <div className={'cols gap-xs cols-middle'}>
        <TypingDots />
        <div className={'type-sm o-50'}>{name} is typing</div>
    </div>
}

export function useTypingIndication(id) {
    const {identity} = useMessaging({parent: id});
    const channel = id ? `chat-${id}` : null;
    const [typing, setTyping] = useState(null);

    const handler = useCallback(e=> {
        setTyping(e);
        if (typingTimeout) clearTimeout(typingTimeout);
        typingTimeout = setTimeout(()=>setTyping(null), 2000);
    }, [setTyping]);

    const sub = usePrivateChannel(channel);
    const send = useWhisper(sub, 'typing', handler);

    const notifyTyping = useCallback(() => {
        if (send) send({identity});
    }, [send, identity]);

    return [typing, notifyTyping];
}

TypingIndicator.propTypes = {};
TypingIndicator.defaultProps = {};
