import React from 'react';
//import Icon from "components/Icon";

import DateRangePicker from "playground/booking/DateRangePicker";
import GuestPicker from "playground/booking/GuestPicker";

export default class SearchForm extends React.PureComponent {

    render() {
        const { onChange, type, id } = this.props;
        const { date_from, date_to } = this.props.data;
        return (<>
            <DateRangePicker
                startDate={date_from}
                endDate={date_to}
                onChange={onChange}
            />
            <GuestPicker
                type={type}
                id={id}
            />
        </>);
    }

}