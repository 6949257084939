import { matchPath } from "react-router";
import { parseQueryString } from "util/uri";
import domainRouter from "util/domainRouter";

export const selectRouteParam = (state, key, defaultValue = null) => {
    if (key === "lang") return selectLang(state);

    const { routePattern, params: defaultParams } = domainRouter();

    if (defaultParams[key]) return defaultParams[key];
    if (!state.router) return defaultValue;
    const route = matchPath(state.router.location.pathname, {
        path: routePattern,
    });
    let query = state.router.query || {};
    let search = state.router.location.search;
    if (route && route.params && route.params[key] && !query[key]) {
        return route.params[key];
    }
    if (!query && search) query = parseQueryString(search) || {};
    if (!query) return null;
    return query[key] || defaultValue;
};

export const selectLang = (state) => {
    const { routePattern } = domainRouter();

    const pathname = state
        ? state.router.location.pathname
        : window.location.pathname;
    const route = matchPath(pathname, { path: routePattern });
    if (!route || !route.params) return "pl";
    return route.params.lang || "pl";
};
