import React from 'react';
import cn from "classnames";
//import PropTypes from 'prop-types';

import SessionControls from "joynt/components/SessionControls";

import Timer from "joynt/components/Timer";
import IconButton from "components/IconButton";
import Button from "components/Button";

import "./style.css";
import CircularProgress from "@material-ui/core/CircularProgress";

export function StageSession(props) {

    const {
        id,
        name,
        isLive,
        isPending,
        isConnected,
        isConnecting,
        start,
        started,
        canEdit,
        onShow,
        onEdit
    } = props;

    return (<div className={cn("main-stage-session brand-bg-alt", {
        "is-pending": isPending || isConnecting || isConnected,
        "is-joining": isConnecting || isConnected,
        "dark-mode": isLive
    })}>
        <div className={"main-stage-session__spinner"}>
            <CircularProgress size={32} color={"inherit"} />
            {isConnecting && <div>Joining session</div>}
        </div>
        <div className={"main-stage-session__content"}>
            <div className={"main-stage-session__header"}>
                <div className={"main-stage-session__status"}>
                    {isLive ? "Live now" : "Coming up next"}
                </div>
                <div className={"main-stage-session__name"}>
                    {name}
                </div>
            </div>
            { (start || isLive) && <div className={"main-stage-session__time"}>
                <div>{isLive ? 'Session started' : 'Session will start'}</div>
                <div className={'time-to-session'}>
                    <Timer start={isLive ? started : start} humanTimeThreshold={7200} countdown={!isLive} />
                </div>
            </div> }
            <div className={"main-stage-session__controls cols cols-center"}>
                <SessionControls id={id} />
            </div>
        </div>
        <div className={"main-stage-session__footer"}>
            <div className={"cols cols-middle"}>
                {canEdit && <IconButton
                    icon={"mui-settings"}
                    onClick={onEdit}
                    size={"small"}
                />}
            </div>
            <div className={"cols cols-middle"}>
                <Button variant={"text"} onClick={onShow}>
                    Session details
                </Button>
            </div>
        </div>
    </div>);

}

StageSession.propTypes = {};
StageSession.defaultProps = {};

export default StageSession;
