import React from 'react';
import cn from "classnames";
import {useClick, useData} from "state/hooks";
import AnswerResponses from "./AnswerResponses";
//import PropTypes from 'prop-types';

const nullArr = [];

function AnswerBar({title, total, votes}) {
    const percentage = (votes*100) / total;

    return <div className={'poll-bar'}>
        <div className={'poll-bar-bg'} style={{width: `${percentage}%`}}/>
        <div className={'poll-bar-label'}>
            {title} { votes ? <span>({votes})</span> : null }
        </div>
    </div>
}

export default function Answer(props) {
    const {id, postId, answers, totalVotes, enableVoting, anonymousVoting} = props;
    const answer = answers[id] || {};
    const {count, identities} = answer;
    const votes = count || 0;
    const onVote = useClick(props);
    const {title} = useData(props);
    const ids = identities || nullArr;
    const showVoters = totalVotes > 0 && !anonymousVoting;

    return <div onClick={enableVoting ? onVote : null} className={cn('poll-answer cols gap-sm', {
        'enabled': enableVoting
    })}>
        <AnswerBar title={title} total={totalVotes} votes={votes} />
        { showVoters ? <AnswerResponses id={id} post={postId} votes={ids} /> : null }
    </div>;
}

Answer.propTypes = {};
Answer.defaultProps = {};
