import { takeEvery, put, call, select } from "redux-saga/effects";
import { pushRoute } from "state/actions/router";
import { pending } from "state/actions/ui";
import { nonce, processAuthCodeCallback } from "util/auth/auth0";
import { change, storeAlias, storeOne } from "state/actions/data";
import { error } from "util/saga/feedback";
import { selectRouteParam } from "state/selectors/router";
import { rawRequest, request } from "util/api/client";
import domainRouter from "util/domainRouter";
import { setSession, removeSession } from "util/auth";
import { isPending } from "state/selectors/ui";
import { refreshTokenFailed, refreshTokenSuccess } from "state/actions/auth";
import { NS_VALIDATION } from "state/sagas/validation";
import { appendQueryParams } from "util/uri";

const domainConfig = domainRouter();

const LOGIN_PAGE_URI = "/login";

const AUTH_URIS = [LOGIN_PAGE_URI, "", "/auth", "/signup", "/logout"];

function makeUri(path = "") {
    const _window = window || {};
    const location = _window.location || {};
    const { protocol, host } = location;
    let uri = `${protocol}//${host}${path}`;
    let pathname = location.pathname;
    if (path === "" && pathname === "/public") {
        return `${uri}${pathname}${location.search}`;
    }
    return uri;
}

function* storeRedirectUri() {
    let redirectUri = yield select((store) =>
        selectRouteParam(store, "redirectUri")
    );
    let id = yield select((store) => selectRouteParam(store, "id"));
    let path = window.location.pathname;

    if (!redirectUri && id) redirectUri = `${path}?id=${id}`;

    if (!redirectUri || path === "/public") {
        redirectUri = appendQueryParams(`${path}${window.location.search}`, {
            fromLogin: true,
        });
        if (path === LOGIN_PAGE_URI) return;
    }

    let redirectPath = redirectUri ? redirectUri.split("?")[0] : null;

    if (redirectUri && AUTH_URIS.indexOf(redirectPath) === -1) {
        localStorage.setItem("redirect_uri", redirectUri);
    }
}

function* handleRedirect() {
    let uri = localStorage.getItem("redirect_uri");
    let search = window.location.search;
    localStorage.removeItem("redirect_uri");
    uri = decodeURIComponent(uri);
    if (!uri || uri === "null") uri = `/${search}`;
    yield put(pushRoute(uri));
}

function* handleFetchUser({ payload }) {
    try {
        const response = yield request({ url: "user?without-services=true" });
        if (response) {
            const userData = response.data.data;
            yield put(storeOne("db.users", userData.id, userData));
            yield put(storeAlias("db.users", "@currentUser", userData.id));
            const settings = yield request({
                url: "console/user_settings/current",
            });
            const settingsData = settings.data.data;
            yield put(
                storeOne("console.user_settings", settingsData.id, settingsData)
            );
            yield put(
                storeAlias(
                    "console.user_settings",
                    "@currentUser",
                    settingsData.id
                )
            );
        }
    } catch (e) {
        //throw e;
    }
}

function* handleFormError(form, error) {
    yield put(
        change(NS_VALIDATION, `ui.forms/${form}`, {
            error,
        })
    );
}

function* handleSignupServer({ payload }) {
    try {
        yield put(pending("signup", true));
        const { email, password } = payload;
        yield call(storeRedirectUri);
        const service = domainConfig.service;
        const data = { email, password, service };
        const response = yield rawRequest({
            method: "post",
            url: "auth/signup",
            data,
        });
        yield put(pending("signup", false));
        if (response.data.error) {
            yield handleFormError("signup", response.data.error);
            yield error({ message: response.data.error });
        } else {
            yield call(setSession, response.data.data);
            yield handleRedirect();
        }
    } catch (e) {
        yield put(pending("signup", false));
        yield error(e);
    }
}

function* loginServer(username, password) {
    const data = { username, password };
    const response = yield rawRequest({
        method: "post",
        url: "auth/login",
        data,
    });
    if (response.data.error) {
        yield handleFormError("login", response.data.error);
        throw new Error(response.data.error);
    }
    return response.data.data;
}

function* handleLogin({ payload }) {
    try {
        const { email, password } = payload;
        yield put(pending("login", true));
        yield call(storeRedirectUri);
        let result = yield call(loginServer, email, password);
        yield call(setSession, result);
        yield handleRedirect();
        yield put(pending("login", false));
    } catch (e) {
        yield put(pending("login", false));
        yield error(e);
    }
}

function* handleAuthorizeSocial({ payload }) {
    try {
        const { connection } = payload;
        const data = {
            connection,
            redirect_uri: makeUri("/auth"),
            state: nonce(),
        };
        yield put(pending("login", true));
        yield call(storeRedirectUri);
        const response = yield rawRequest({
            method: "post",
            url: "auth/social",
            data,
        });
        if (response.data.error) throw new Error(response.data.error);
        window.location = response.data.data.redirect_uri;
    } catch (e) {
        yield put(pending("login", false));
        yield error(e);
    }
}

function* handleLoginApiKey() {
    try {
        yield call(storeRedirectUri);
        yield call(handleRedirect);
    } catch (e) {
        throw e;
    }
}

function* handleLogout(action) {
    try {
        yield put(pending("logout", true));
        const response = yield rawRequest({
            method: "post",
            url: "auth/logout",
            query: { returnTo: makeUri() },
        });
        if (response.data.error) {
            throw new Error(response.data.error);
        }
        const { redirect_uri } = response.data.data;
        yield call(removeSession);
        window.location = redirect_uri;
    } catch (e) {
        yield put(pending("logout", false));
        yield error(e);
    }
}

function* handleLoginRedirect(action) {
    try {
        if (window.location.protocol === "file:") return;
        const redirect = window.location.pathname;
        const search = window.location.search;
        const uri = encodeURIComponent(`${redirect}${search}`);
        let targetUri = `${LOGIN_PAGE_URI}`;
        if (uri && uri !== "%2F") targetUri = `${targetUri}?redirectUri=${uri}`;
        if (redirect !== LOGIN_PAGE_URI) {
            yield put(pushRoute(targetUri));
        }
    } catch (e) {
        yield error(e);
    }
}

function* handleExchangeAuthCode(action) {
    try {
        const service = domainConfig.service;
        const authResult = yield call(processAuthCodeCallback, service);
        yield call(setSession, authResult);
        yield handleRedirect();
    } catch (e) {
        yield error(e);
    }
}

function* handleAuthRefresh({ payload }) {
    try {
        const requestId = "token-refresh";
        const alreadyPending = yield select((store) =>
            isPending(store, requestId)
        );
        if (alreadyPending) return null;
        yield put(pending(requestId, true));
        const { access_token } = payload;
        const response = yield rawRequest({
            url: "auth/refresh",
            method: "post",
            data: { access_token },
        });
        const data = response.data;
        if (data.error) {
            throw new Error(data.error);
        }
        yield call(setSession, data.data);
        yield put(refreshTokenSuccess());
        yield put(pending(requestId, false));
    } catch (e) {
        yield put(refreshTokenFailed());
        yield error(e);
    }
}

function* handlePasswordReset({ payload }) {
    const requestId = "password-reset";
    try {
        const { username } = payload;
        yield put(pending(requestId, true));
        const response = yield rawRequest({
            url: `auth/password-reset`,
            method: "post",
            data: { username },
        });
        const data = response.data;
        if (data.success) {
            yield put(change("ui.forms", "password-reset", { success: true }));
        } else {
            yield put(change("ui.forms", "password-reset", { success: false }));
        }
        yield put(pending(requestId, false));
    } catch (e) {
        yield put(pending(requestId, false));
        yield error(e);
    }
}

export default function* () {
    yield takeEvery("AUTH.REQUIRE_LOGIN", handleLoginRedirect);
    yield takeEvery("AUTH.FETCH_USER", handleFetchUser);
    yield takeEvery("AUTH.LOGOUT", handleLogout);
    yield takeEvery("AUTH.LOGIN", handleLogin);
    yield takeEvery("AUTH.SIGNUP", handleSignupServer);
    yield takeEvery("AUTH.LOGIN_SOCIAL", handleAuthorizeSocial);
    yield takeEvery("AUTH.SET_API_KEY", handleLoginApiKey);
    yield takeEvery("AUTH.EXCHANGE_CODE", handleExchangeAuthCode);
    yield takeEvery("AUTH.REFRESH.FETCH", handleAuthRefresh);
    yield takeEvery("AUTH.PASSWORD_RESET", handlePasswordReset);
}
