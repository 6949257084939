import React from "react";
import { useSelector } from "react-redux";

import { confirmYes, confirmNo, confirmResponse } from "state/actions/ui";

import ConfirmDialog from "components/dialogs/Confirm";
import { useActions } from "state/hooks";

function selectConfirmState(store) {
    const data = store.ui.confirmMessage;
    const isOpen = store.ui.confirmOpen || false;
    return { data, isOpen };
}

function Confirm(props) {
    const { Component } = props;
    const { data, isOpen } = useSelector((s) => selectConfirmState(s));
    const confirmProps =
        typeof data === "object"
            ? { isOpen, ...data }
            : { isOpen, message: data };
    const actions = useActions({
        yes: confirmYes,
        no: confirmNo,
        response: confirmResponse,
    });
    return <Component {...props} {...confirmProps} {...actions} />;
}

Confirm.defaultProps = {
    Component: ConfirmDialog,
};

export default Confirm;
