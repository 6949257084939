import React from "react";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_FOLLOW } from "joynt/config/features";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import SessionType from "joynt/edit/types/Session/SessionType";
import SessionDate from "joynt/edit/types/Session/SessionDate";
import Field from "containers/Field";
import Page from "joynt/views/Page";

import SessionFeatures from "./../SessionFeatures";
import SessionAccess from "./../SessionAccess";
import SessionActivity from "./../SessionActivity";
import { ParentSpace } from "../../fields/ParentSpace";
import ImageField from "joynt/components/Form/ImageField";
import {Description} from "joynt/edit/types/fields";

//import PropTypes from 'prop-types';

export function SessionCreateForm(props) {
    const { id, isDraft } = props;
    const isDev = useFeatureEnabled(FEATURE_FOLLOW);

    return (
        <FieldsLayout>
            <FieldGroup label={"1. Choose session type"}>
                <SessionType />
            </FieldGroup>

            <FieldGroup label={"2. Choose where to set up your session"}>
                <ParentSpace id={id} />
            </FieldGroup>

            <FieldGroup label={"3. Choose date and time"}>
                <SessionDate />
            </FieldGroup>

            <FieldGroup label={"4. Session title"}>
                <Field id={"name"} label={"Title"} validate={true} />
                {/*<Field id={'description'} label={'Subtitle'}/>*/}
            </FieldGroup>

            <FieldGroup label={"5. Who's invited"}>
                <SessionAccess {...props} />
            </FieldGroup>

            <FieldGroup label={"6. Cover image"}>
                <ImageField />
            </FieldGroup>

            <FieldGroup label={"7. Session description"}>
                <Description id={id} />
            </FieldGroup>

            {/*<FieldGroup*/}
            {/*    label={"Session roles"}*/}
            {/*    description={"Add speakers and moderators to your sessions"}*/}
            {/*>*/}
            {/*    <AssignRoles id={id} scope={"event"} />*/}
            {/*</FieldGroup>*/}

            {isDraft && (
                <FieldGroup label={"7. Add tools"}>
                    <SessionFeatures />
                </FieldGroup>
            )}

            {isDev && <SessionActivity {...props} />}

            {/*<RolesAndAccess />*/}
        </FieldsLayout>
    );
}

SessionCreateForm.propTypes = {};
SessionCreateForm.defaultProps = {};

export default SessionCreateForm;
