import React, { useCallback } from "react";
import PropTypes from "prop-types";
import UtilityNavDrawer from "./UtilityNavDrawer";
import { useUiFlag } from "state/hooks";
import useScreenSize from "util/hooks/useScreenSize";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";

export function UtilityNavDrawerContainer(props) {
    const { defaultView } = props;

    const [isOpen, setOpen] = useUtilityNav();
    const [, onShowSecSidebar] = useUiFlag("mobile.showUtilityNav");
    const { isMobile } = useScreenSize();
    const toggle = useCallback(() => {
        setOpen(!isOpen ? defaultView : null);
    }, [isOpen, setOpen, defaultView]);

    const close = useCallback(() => {
        if (!isMobile) {
            setOpen(null);
        } else {
            onShowSecSidebar(false);
        }
    }, [setOpen, onShowSecSidebar, isMobile]);

    return (
        <UtilityNavDrawer
            {...props}
            onClose={close}
            onToggle={toggle}
            isOpen={!!isOpen}
        />
    );
}

UtilityNavDrawerContainer.propTypes = {
    id: PropTypes.string,
};
UtilityNavDrawerContainer.defaultProps = {
    defaultView: "main",
};

export default UtilityNavDrawerContainer;
