import {useCallback, useContext, useMemo} from "react";
import EntityContext from "containers/context/EntityContext";
import {useActions} from "state/hooks";
import {change} from "state/actions/data";
import {useSelector} from "react-redux";
import {selectEntity} from "state/selectors/data";

export function useEntity() {

    const path = useContext(EntityContext);
    const [type, id] = path.split('/');

    const {onChange} = useActions({
        onChange: change
    });

    const data = useSelector(s => selectEntity(s, type, id));
    const handleChange = useCallback(data => {
        onChange(type, id, data);
    }, [onChange, type, id]);

    return useMemo(() => ({
        onChange: handleChange,
        data
    }), [
        handleChange,
        data
    ])

}