import React from 'react';
import {useNodeData} from "joynt/hooks";
import {useCover} from "state/hooks";
import cn from "classnames";

import UploadOverlay from "./UploadOverlay";

//import PropTypes from 'prop-types';

export function HeaderLogo({ id }) {
    const { media_logo: media, can_edit } = useNodeData(id);
    const img = useCover(media, [200, 200]);

    const style = {
        backgroundImage: img ? `url("${img}")` : null,
    };

    if (!img && !can_edit) return null;

    return (
        <div className={cn("workspace-header__logo", {
            "is-empty": !img
        })} style={style}>
            {can_edit ? <UploadOverlay field={"media_logo"} /> : null}
        </div>
    );
}

HeaderLogo.propTypes = {};
HeaderLogo.defaultProps = {};

export default HeaderLogo;
