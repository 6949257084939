import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import {usePending, useUiFlag} from "state/hooks";

import {
    useDevSessionFlow, useSessionFlow,
    useSessionFlowSchedule,
    useSetSessionFlowSegment
} from "./../hooks";

import SessionFlowSchedule from "./SessionFlowSchedule";
import Preloader from "components/Preloader";
import Timer from "joynt/components/Timer";
import {OptionSelect} from "joynt/components/Select";
import Checkbox from "components/fields/checkbox/Checkbox";

function FlowSegment(props) {
    const {
        session,
        id,
        name,
        message,
        started,
        onClick,
        isCurrent,
        isPast,
        duration
    } = props;

    const pending = usePending(`${session}.flow.${id}`);

    return <div className={cn("session-flow-segment", {
        "is-current": isCurrent,
        "is-past": isPast
    })} onClick={onClick}>
        <Preloader visible={pending} />
        <div className={"cols cols-spread"}>
            <div>{name}</div>
            {isCurrent && <div>{message}</div>}
            {isCurrent && <Timer
                countdown={!!duration}
                start={duration ? started+duration : started}
                humanTimeThreshold={false}
            />}
        </div>
    </div>
}

function ToggleFlag(props) {

    const {flag} = props;
    const [value, onChange] = useUiFlag(flag);

    return <Checkbox
        variant={"checkbox"}
        value={value}
        onChange={onChange}
        label={flag}
    />
}

export function SessionFlowScheduleContainer(props) {

    const {id} = props;

    const onSetSegment = useSetSessionFlowSegment(id);

    const {path} = useSessionFlow();

    const {
        items: segments,
        current,
        started
    } = useSessionFlowSchedule(id);

    const {
        flow,
        forcedFlow,
        flows,
        onChangeFlow
    } = useDevSessionFlow(id);

    const segmentIds = segments.map(s => s.id);

    const flowOptions = useMemo(() => {
        return flows.map(id => ({id, label: id}));
    }, [flows]);

    return (<SessionFlowSchedule {...props}>
        <div>{path}</div>
        <div className={"cols gap-sm"}>
            <OptionSelect
                value={flow}
                options={flowOptions}
                onChange={onChangeFlow}
                label={"Session flow"}
            />
            <div>{forcedFlow}</div>
        </div>
        <ToggleFlag
            flag={'dev.session.overridePeersReq'}
        />

        {segments.map((s, i) => {
            return <FlowSegment
                key={s.id}
                {...s}
                name={s.name || s.id}
                onClick={()=>onSetSegment(s.id)}
                isCurrent={s.id === current}
                isPast={segmentIds.indexOf(s.id) < segmentIds.indexOf(current)}
                started={started}
                session={id}
            />
        })}

    </SessionFlowSchedule>);

}

SessionFlowScheduleContainer.propTypes = {
    id: PropTypes.string
};
SessionFlowScheduleContainer.defaultProps = {};

export default SessionFlowScheduleContainer;