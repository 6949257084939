import React from "react";
import PropTypes from "prop-types";

import { useEdgesData, useNodeData } from "joynt/hooks";

import PostCard from "./PostCard";
import PostAuthor from "./../PostAuthor";
import PostTags from "./../PostTags";
import { useSelector } from "react-redux";
import { selectPostContent } from "joynt/state/selectors/joint";
import { useClick, usePending, useRouteParam } from "state/hooks";
import { ROUTE_ID, ROUTE_POST } from "joynt/config";
import { useMedia } from "containers/media";
import { useFetchLinkData } from "joynt/components/LinkEmbed";
import { useImpressionMonitor } from "joynt/components/ImpressionMonitor";

const LinkPostCard = React.forwardRef((props, ref) => {
    const { link, ...other } = props;
    const linkData = useFetchLinkData(link);

    const image = linkData.image;
    const title = other.title || linkData.title;
    const description = other.content || linkData.description;

    return (
        <PostCard
            {...other}
            ref={ref}
            title={title}
            image={image}
            content={description}
            isPending={other.isPending || linkData.pending}
        />
    );
});

export function PostCardContainer(props) {
    const { id, showParentLink } = props;
    const [, showPost] = useRouteParam(ROUTE_POST);
    const { name, created_at, identity } = useNodeData(id);
    const { parent_node } = useEdgesData(id);
    const { content, link, image, properties } = useSelector((s) =>
        selectPostContent(s, id)
    );
    const click = useClick({ onClick: showPost, id });
    const { thumb } = useMedia();
    const isPending = usePending(id);
    const { ref, hasNotifications } = useImpressionMonitor(id, true);
    const [currentWorkspace] = useRouteParam(ROUTE_ID);

    const thumbnail =
        image && image.type === "image" ? thumb(image, [120, 90]) : null;

    const PostCardComponent = link ? LinkPostCard : PostCard;

    return (
        <PostCardComponent
            {...props}
            ref={ref}
            hasNotifications={hasNotifications}
            title={name}
            content={content}
            postedAt={created_at}
            postedIn={
                parent_node?.id !== currentWorkspace && showParentLink
                    ? parent_node?.name
                    : null
            }
            author={identity ? <PostAuthor id={identity} /> : null}
            tags={<PostTags id={id} />}
            properties={properties}
            onClick={click}
            image={thumbnail}
            link={link}
            isPending={isPending}
        />
    );
}

PostCardContainer.propTypes = {
    id: PropTypes.string,
};
PostCardContainer.defaultProps = {
    showParentLink: true
};

export default PostCardContainer;
