function startOfDay(date) {
    date.setHours(0,0,0,0);
    return date;
}

function endOfDay(date) {
    date.setHours(23,59,59,0);
    return date;
}

export function selectRange(date, mode) {
    if (mode === "day") {
        let start = startOfDay(new Date(date));
        let end = endOfDay(new Date(date));
        return {start, end}
    }
    if (mode === "month") {
        let start = startOfDay(new Date(date));
        start.setDate(1);
        let end = endOfDay(new Date(date));
        let days = (new Date(date.getFullYear(), date.getMonth()+1, 0).getDate())
        end.setDate(days);
        return {start, end}
    }
    if (mode === "week") {
        let start = startOfDay(new Date(date));
        let dayOfWeek = date.getDay();
        let end = endOfDay(new Date(date));
        start.setDate(Math.max(date.getDate()-dayOfWeek, 1));
        end.setDate(start.getDate()+6);
        return {start, end}
    }
}