import { useField } from "containers/Field";
import { useApiActions } from "state/hooks";
import { dragSectionCallback } from "cms/state/actions/cms";
import { useCallback, useMemo } from "react";

export function usePageSectionsList(type, id, callbackFn) {
    const [value, onChange] = useField("sections");
    const { onDragSection } = useApiActions({
        onDragSection: dragSectionCallback,
    });

    const handleSort = useCallback(
        (items) => {
            console.log("onSort", type, id, items);
            onChange(items);
        },
        [id, onChange, type]
    );

    const handleDrag = useCallback(
        (...args) => {
            console.log("onDrag", type, id, args);
            onDragSection(callbackFn, type, id, ...args);
        },
        [type, id, callbackFn, onDragSection]
    );

    return useMemo(
        () => ({ onSort: handleSort, onDrag: handleDrag, value, onChange }),
        [handleSort, handleDrag, value, onChange]
    );
}
