import { useCallback, useEffect, useMemo, useState } from "react";

function selectPage(items, start, end, focus) {
    if (!focus) return items.slice(start, end);

    return items
        .filter((u) => u.uid === focus)
        .concat(items.filter((u) => u.uid !== focus).slice(start, end));
}

export default function usePagination(items, perPage, focus) {
    const limit = focus ? perPage - 1 : perPage;

    const count = items.length;
    const pages = Math.ceil(count / perPage);
    const [page, setPage] = useState(1);
    const ids = items.map((item) => item.uid);
    const [id, setId] = useState(ids[0]);

    const setPageAndId = useCallback(
        (page) => {
            setPage(page);
            setId(items[(page - 1) * limit]?.uid);
        },
        [setPage, setId, items, limit]
    );

    useEffect(() => {
        if (page > pages) setPageAndId(pages);
    }, [page, pages, setPageAndId]);

    const nextPage = useCallback(() => {
        setPageAndId(page === pages ? 1 : page + 1);
    }, [setPageAndId, page, pages]);

    const prevPage = useCallback(() => {
        setPageAndId(page === 1 ? pages : page - 1);
    }, [setPageAndId, page, pages]);

    const start = (page - 1) * limit;
    const end = start + limit;

    let pagedItems = useMemo(
        () => selectPage(items, start, end, focus, id),
        [items, start, end, focus, id]
    );

    return useMemo(
        () => ({
            items: pagedItems,
            id,
            page,
            pages,
            prevPage: prevPage,
            nextPage: nextPage,
        }),
        [id, pagedItems, page, pages, prevPage, nextPage]
    );
}
