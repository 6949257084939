import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import AggregateRoot from "containers/AggregateRoot";

import HeaderWelcome from "./HeaderWelcome";
import HeaderLogo from "./HeaderLogo";
import HeaderImage from "./HeaderImage";

import "./style.css";

export function WorkspaceHeader(props) {
    const { id, children, className } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id}>
            <div className={cn("workspace-header cols", className)}>
                <HeaderWelcome id={id} />
                <HeaderLogo id={id} />
                <HeaderImage id={id}>{children}</HeaderImage>
            </div>
        </AggregateRoot>
    );
}

WorkspaceHeader.propTypes = {
    id: PropTypes.string,
};
WorkspaceHeader.defaultProps = {};

export default WorkspaceHeader;
