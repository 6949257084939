export default {
    ENV: process.env.REACT_APP_ENV,
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
    ZUU_CONSOLE_API_ENDPOINT: process.env.REACT_APP_ZUU_CONSOLE_API_ENDPOINT,
    ZUU_CONSOLE_PRESET: process.env.REACT_APP_ZUU_CONSOLE_PRESET,
    ECHO_SERVER: process.env.REACT_APP_ECHO_SERVER,
    THUMBNAIL_ENDPOINT: process.env.REACT_APP_THUMBNAIL_ENDPOINT,
    NODE_API_ENDPOINT: process.env.REACT_APP_NODE_API_ENDPOINT,
    EXTENSION_AUTH_DOMAIN: process.env.REACT_APP_EXTENSION_AUTH_DOMAIN,
    VAPID_PUBLIC_KEY: process.env.REACT_APP_VAPID_PUBLIC_KEY,
    GIPHY_API_KEY: process.env.REACT_APP_GIPHY_API_KEY,
    OPENTOK_API_KEY: process.env.REACT_APP_OPENTOK_API_KEY,
    AGORA_APP_ID: process.env.REACT_APP_AGORA_APP_ID,
    MIXPANEL_PROJECT_ID: process.env.REACT_APP_MIXPANEL_PROJECT_ID,
    LOGROCKET_PROJECT_ID: process.env.REACT_APP_LOGROCKET_PROJECT_ID,
    FILES_CDN: process.env.REACT_APP_FILES_CDN,
    USE_BROWSER_ENV: process.env.REACT_APP_USE_BROWSER_ENV,
    APP_SERVICE_ID: process.env.REACT_APP_APP_SERVICE_ID,
    SERVICE_ID: process.env.REACT_APP_SERVICE_ID,
    GUEST_TOKEN: process.env.REACT_APP_GUEST_TOKEN,
};
