import React from "react";

import AceEditor from "react-ace-cdn";

const options = {
    tabSize: 4,
};

export default class CodeEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.editorProps = { $blockScrolling: Infinity };
        //this.editorProps = {};
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.rootId && prevProps.rootId !== this.props.rootId) {
            this.initialize();
        }
    }

    initialize() {}

    handleChange = (value) => {
        const { onChange, id } = this.props;
        if (id) {
            onChange(id, value);
        } else {
            onChange(value);
        }
    };

    render() {
        const { mode, format, value, markers, annotations } = this.props;
        let editorMode = mode || "html";
        if (format === "json") editorMode = "json";
        return (
            <>
                <AceEditor
                    fontSize="14px"
                    width="100%"
                    height={this.props.height || "370px"}
                    mode={editorMode}
                    //theme="solarized_light"
                    theme={"solarized_dark"}
                    onChange={this.handleChange}
                    readOnly={this.props.readOnly || false}
                    name={this.props.id}
                    value={value}
                    editorProps={this.editorProps}
                    debounceChangePeriod={1000}
                    wrapEnabled={this.props.wrapEnabled}
                    setOptions={options}
                    markers={markers}
                    annotations={annotations}
                />
            </>
        );
    }
}

CodeEditor.defaultProps = {
    wrapEnabled: false,
    markers: [],
    annotations: [],
};
