import React from "react";
//import PropTypes from 'prop-types';

import PropTypes from "prop-types";
import cn from "classnames";

import IconButton from "components/IconButton";

import {
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_OWNER,
} from "joynt/config";

import ContextMenu from "components/ContextMenu";
import Preloader from "components/Preloader";

import { ToolbarOptions } from "./ToolbarOptions";
import NetworkState from "./NetworkState";
import useScreenSize from "util/hooks/useScreenSize";

import {
    EndCallButton,
    FullscreenButton,
    SettingsButton,
} from "./Buttons";

import { SessionNotifications } from "joynt/meeting/notifications";

import "./style.css";
import AudioVideo from "joynt/components/SessionToolbar/AudioVideo";
import RaiseHand from "joynt/components/SessionToolbar/Buttons/RaiseHand";

export function SessionToolbar(props) {
    const {
        id,
        role,
        onLeaveSession,
        onToggleScreenShare,
        onToggleTileView,
        onToggleLive,
        isLive,
        isMainStage,
        isSharingScreen,
        layout,
        info,
        onMouseOver,
        onMouseOut,
        pending,
        showNetworkQuality,
        isHidden,
    } = props;

    const canShareScreen = onToggleScreenShare && isLive;

    const canManageFlow =
        [EVENT_ROLE_ADMIN, EVENT_ROLE_OWNER].indexOf(role) > -1;

    const { isDesktop } = useScreenSize();

    return (
        <div
            className={cn("meeting-toolbar-area", {
                "is-hidden": isHidden,
            })}
        >
            <div
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                className={cn("meeting-toolbar dark-mode", {
                    "is-pending": !!pending,
                })}
            >
                <Preloader visible={pending} />
                {isSharingScreen && (
                    <div
                        className={
                            "toolbar-notification cols cols-center type-default"
                        }
                    >
                        You are sharing screen
                    </div>
                )}
                {info && (
                    <div
                        className={
                            "toolbar-notification cols cols-center type-default"
                        }
                    >
                        {info === "muted" &&
                            "You're muted, enable mic to speak"}
                        {info === "off-stage" &&
                            "You're off stage, go on stage to speak"}
                    </div>
                )}
                <div className={"toolbar-notification-area"}>
                    <NetworkState show={showNetworkQuality} />
                    <SessionNotifications id={id} />
                </div>
                <div className={"toolbar-controls cols cols-3 gap-xs"}>
                    <div className={"cols gap-xs"}>
                        <RaiseHand />
                        {isLive && (
                            <div className={"cols gap-xs"}>
                                <IconButton
                                    size={"medium"}
                                    withTooltip
                                    label={"Share screen"}
                                    icon={
                                        isSharingScreen
                                            ? "mui-stop_screen_share"
                                            : "mui-screen_share"
                                    }
                                    onClick={onToggleScreenShare}
                                    disabled={!canShareScreen}
                                />
                            </div>
                        )}
                    </div>

                    <div className={"cols cols-center gap-sm"}>
                        <AudioVideo />
                        <EndCallButton onClick={onLeaveSession} />
                    </div>

                    <div className={"cols cols-right cols-middle"}>
                        {isDesktop && (
                            <>
                                <IconButton
                                    size={"medium"}
                                    withTooltip
                                    label={
                                        layout === "grid"
                                            ? "Session view"
                                            : "Toggle tiles"
                                    }
                                    icon={
                                        layout === "grid"
                                            ? "mui-view_sidebar"
                                            : "mui-view_module"
                                    }
                                    onClick={onToggleTileView}
                                    disabled={!onToggleTileView}
                                />
                                <FullscreenButton />
                            </>
                        )}

                        <SettingsButton />
                        {isMainStage && canManageFlow && (
                            <ContextMenu RenderMenuItems={ToolbarOptions}>
                                {({ onMenuOpen }) => (
                                    <IconButton
                                        size={"small"}
                                        icon={"mui-more_vert"}
                                        onClick={onMenuOpen}
                                    />
                                )}
                            </ContextMenu>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

SessionToolbar.propTypes = {
    pending: PropTypes.bool,
    isHidden: PropTypes.bool,
};
SessionToolbar.defaultProps = {
    pending: false,
    isHidden: false,
};

export default SessionToolbar;
