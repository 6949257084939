import React, {useCallback} from "react";
import PropTypes from 'prop-types';

import Sidebar from "./Sidebar";
import SidebarNav from "./SidebarNav/SidebarNav.container";
import SidebarTray from "./SidebarTray/SidebarTray.container";
import { useRouter } from "joynt/hooks/channels";
import { useNodeData } from "joynt/hooks";
import SidebarOverlay from "./SidebarOverlay";
import useScreenSize from "util/hooks/useScreenSize";
import { useUiFlag } from "state/hooks";
import IconButton from "components/IconButton";

export function SidebarContainer(props) {
    const { id, collapsible } = props;
    const { onLink } = useRouter();
    const { can_edit } = useNodeData(id);
    const { isDesktop, isTablet } = useScreenSize();

    const [showSidebar, onShowSidebar] = useUiFlag("mobile.showSidebar");

    const hide = useCallback(() => {
        onShowSidebar(false);
    }, [onShowSidebar]);

    const handleTrayClick = useCallback((...args) => {
        const newId = args[0];
        const isSameSpace = newId === id;

        if (isSameSpace && !showSidebar) {
            onShowSidebar(true);
        } else if (isSameSpace && showSidebar) {
            onShowSidebar(false);
        } else if (!isSameSpace && showSidebar) {
            onLink(...args);
        } else {
            onShowSidebar(true);
            onLink(...args);
        }
    }, [
        id,
        showSidebar,
        onShowSidebar,
        onLink
    ]);

    const onClick = useCallback((...args) => {
        if (!collapsible) hide();
        onLink(...args);
    }, [
        collapsible,
        hide,
        onLink
    ]);

    return (
        <Sidebar collapsed={collapsible && !showSidebar}>
            {!isDesktop && <SidebarOverlay onClick={hide} />}
            <SidebarTray
                onClick={isTablet ? handleTrayClick : onLink}
                selected={id}
                header={collapsible && <IconButton
                    onClick={()=>onShowSidebar(!showSidebar)}
                    icon={showSidebar ? "mui-menu_open" : "mui-menu"}
                />}
            />
            <SidebarNav id={id} onClick={onClick} isAdmin={!!can_edit} />
        </Sidebar>
    );
}

SidebarContainer.propTypes = {
    collapsible: PropTypes.bool
};
SidebarContainer.defaultProps = {
    collapsible: false
};

export default SidebarContainer;
