import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Icon from "components/Icon";

import "./style.css";
import useScreenSize from "util/hooks/useScreenSize";

export function UtilityNavDrawer(props) {
    const { children, nav, isOpen, isHiddenNav, onClose, onToggle } = props;

    const { isMobile } = useScreenSize();

    const className = cn("utility-nav-drawer", {
        "is-open": isOpen && !isMobile,
        "hide-nav": isHiddenNav,
    });

    return (
        <div className={className}>
            <div className={"utility-nav-drawer__overlay"} onClick={onClose} />
            <div className={"utility-nav-drawer__view"}>
                <button
                    className={"utility-nav-drawer-toggle"}
                    onClick={onToggle}
                >
                    <Icon>mui-chevron_left</Icon>
                </button>
                <div className={"utility-nav-drawer__content"}>
                    {nav && !isHiddenNav && (
                        <div className={"utility-nav utility-nav-drawer__nav"}>
                            {nav}
                        </div>
                    )}
                    <div className={"utility-nav-drawer__body"}>{children}</div>
                </div>
            </div>
        </div>
    );
}

UtilityNavDrawer.propTypes = {
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    isHiddenNav: PropTypes.bool,
};
UtilityNavDrawer.defaultProps = {
    isOpen: true,
    isHiddenNav: false,
};

export default UtilityNavDrawer;
