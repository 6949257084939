import React from 'react';
import PropTypes from 'prop-types';
import SelectValue from "./SelectValue";
import {useData} from "state/hooks";

export function SelectValueContainer(props) {

    const {type, value} = props;
    const {name} = useData({type, id: value});

    return (<SelectValue
        {...props}
        value={value ? name : null}
    />);

}

SelectValueContainer.propTypes = {
    id: PropTypes.string
};
SelectValueContainer.defaultProps = {};

export default SelectValueContainer;