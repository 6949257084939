import React from "react";
import cn from "classnames";
import Radio from "@material-ui/core/Radio";

import "./style.css";

function RadioButton(props) {
    const { isSelected, label, children, onClick, ...other } = props;

    return (
        <div
            onClick={onClick}
            role={"button"}
            className={cn(
                "radio-button",
                isSelected && "radio-button--is-selected"
            )}
        >
            <Radio
                checked={isSelected}
                size={"small"}
                {...other}
                color={"primary"}
            />
            {label}
            {children}
        </div>
    );
}

export default RadioButton;
