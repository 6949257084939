import React from "react";
import { OptionSelect } from "joynt/components/Select";

export default function SelectSubchannel(props) {
    const { label, url, type, list, value, options, onChange } = props;
    return (
        <div className={"space-header__select-subchannel"}>
            <OptionSelect
                label={label}
                url={url}
                type={type}
                list={list}
                value={value}
                onChange={onChange}
                options={options}
            />
        </div>
    );
}
