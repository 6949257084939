import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import Popover from "components/Popover";
import SelectValue from "./SelectValue";
import SelectList from "./SelectList";

import "./style.css";
import Icon from "components/Icon";
import IconButton from "components/IconButton";

export function Select(props) {
    const { onChange, type, list, value, label, url, nullable } = props;

    const clear = useCallback(
        (e) => {
            e.stopPropagation();
            onChange(null);
        },
        [onChange]
    );

    return (
        <Popover
            trigger={({ onClick }) => (
                <div onClick={onClick} className={"select-input"}>
                    <SelectValue type={type} value={value} label={label} />
                    {value && nullable && (
                        <IconButton
                            icon={"mui-clear"}
                            onClick={clear}
                            size={"small"}
                        />
                    )}
                    <div className={"select-input__icon"}>
                        <Icon>mui-arrow_drop_down</Icon>
                    </div>
                </div>
            )}
            content={({ onClose }) => (
                <SelectList
                    onSelect={onChange}
                    onClose={onClose}
                    type={type}
                    list={list}
                    url={url}
                    value={value}
                />
            )}
        />
    );
}

Select.propTypes = {};
Select.defaultProps = {};

export default Select;
