import React from "react";
//import PropTypes from 'prop-types';
import { EditorBlock } from "draft-js";

import "./style.css";

export function Callout(props) {
    return (
        <div className={"block-frame"}>
            <EditorBlock {...props} />
        </div>
    );
}

Callout.propTypes = {};
Callout.defaultProps = {};

export default Callout;
