import React, { useState } from "react";
//import PropTypes from 'prop-types';

import uuid from "uuid/v4";
import { selectEntity } from "state/selectors/data";
import { useSelector } from "react-redux";

import Popover from "@material-ui/core/Popover";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-full-node-drag";
import cn from "classnames";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import ItemFormLink from "playground/cms/views/Sections/SectionForm/ContentCollection/ItemFormLink";
import { useActions } from "state/hooks";
import { change, pathDetach, pathAppend, create } from "state/actions/data";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import Button from "components/Button";
import { selectLink } from "playground/cms/Link";

function selectNavTree(store, id) {
    const type = "cms.nav-links";
    const rootType = "cms.navigation";

    const data = selectEntity(store, rootType, id);

    function getChildLinks(store, linkId, depth) {
        const link = selectEntity(store, type, linkId);
        const linkData = selectLink(store, type, linkId);

        const parentRef = link.__parent;
        const parentType = parentRef.key === "links" ? rootType : type;
        return {
            id: link.id,
            type,
            expanded: true,
            title: linkData.label || linkData.url,
            depth,
            parent: {
                id: parentRef.id,
                type: parentType,
                key: parentRef.key,
            },
            children:
                link?.children?.map((childId) =>
                    getChildLinks(store, childId, depth + 1)
                ) || [],
        };
    }
    const root = {
        id: data.id,
        type: rootType,
        title: data.name,
        expanded: true,
        depth: 0,
        children:
            data?.links?.map((linkId) => {
                return getChildLinks(store, linkId, 1);
            }) || [],
    };
    return [root];
}

function Placeholder() {
    return <div>placeholder</div>;
}

function nodeTitle({ node, onClick, onShowMenu, focused }) {
    const isRoot = node.type === "cms.navigation";
    return (
        <div
            className={cn("rstcustom__rowTitleContent", {
                "is-focused": focused,
                "is-root": isRoot,
            })}
            onClick={!isRoot ? () => onClick(node.id) : null}
            onContextMenu={(evt) => onShowMenu(evt, node.id)}
        >
            {node.title}
        </div>
    );
}

function LinkForm(props) {
    const { id } = props;
    return (
        <AggregateRoot type={"cms.nav-links"} id={id} disableSubmit={true}>
            <ItemFormLink type={"cms.nav-links"} id={id} />
        </AggregateRoot>
    );

    return (
        <AggregateRoot type={"cms.nav-links"} id={id} disableSubmit={true}>
            <Field id={"label"} label={"Label"} />
            <Field id={"url"} label={"Url"} />
        </AggregateRoot>
    );
}

function typeKey(type) {
    return {
        "cms.navigation": "links",
        "cms.nav-links": "children",
    }[type];
}

function treeFind(tree, id) {
    if (!tree) return null;
    if (tree.id === id) return tree;
    for (let i = 0; i < tree.children.length; i++) {
        const found = treeFind(tree.children[i], id);
        if (found) return found;
    }
    return null;
}

export function NavTree(props) {
    const { id } = props;
    const maxDepth = 2;

    const [menuState, setMenuState] = useState({
        contextMenu: false,
        contextMenuAnchor: null,
    });

    const onShowMenu = (evt, id) => {
        evt.preventDefault();
        setMenuState({ contextMenu: id, contextMenuAnchor: evt.target });
    };
    const onHideMenu = () => {
        setMenuState({ contextMenu: false });
    };

    const tree = useSelector((store) => selectNavTree(store, id));
    const { onChange, onDetach, onCreate, onAppend } = useActions({
        onChange: change,
        onDetach: pathDetach,
        onAppend: pathAppend,
        onCreate: create,
    });

    const [current, setCurrent] = useState(null);

    const canDrag = ({ node }) => node.type !== "cms.navigation";
    const canDrop = ({ node, nextParent }) => !!nextParent?.id;
    const moveNode = ({ node, nextParentNode, prevPath, nextPath }) => {
        const removeFrom = [node.parent.type, node.parent.id, node.parent.key];
        console.log("moveNode", node);
        console.log("removeFrom", removeFrom, node.id);
        onDetach(removeFrom, node.id);
        onChange(node.type, node.id, {
            __parent: {
                id: nextParentNode.id,
                key: typeKey(nextParentNode.type),
            },
        });
        const key = typeKey(nextParentNode.type);
        let nextChildren = nextParentNode.children.map((n) => n.id);
        nextChildren = nextChildren.filter(
            (n, index, arr) => arr.indexOf(n) === index
        );
        onChange(nextParentNode.type, nextParentNode.id, {
            [key]: nextChildren,
        });
        console.log(node, nextParentNode);
    };
    const canHaveChildren = (node) => !!node.id && node.depth < maxDepth;
    const nodeProps = ({ node }) => ({
        title: nodeTitle({
            node,
            onClick: setCurrent,
            onShowMenu,
            focused: current === node.id,
        }),
    });
    const handleRemove = (id) => {
        const node = treeFind(tree[0], id);
        const path = [node.parent.type, node.parent.id, node.parent.key];
        onDetach(path, id);
        setCurrent(node.parent.id);
        onHideMenu();
    };
    const handleCreate = (parent) => {
        const parentNode = treeFind(tree[0], parent);
        const newId = uuid();
        const key = typeKey(parentNode.type);
        onCreate("cms.nav-links", {
            id: newId,
            type: "sitemap",
            link_action: "link",
            __parent: {
                id: parentNode.id,
                key,
            },
        });
        const parentPath = [parentNode.type, parentNode.id, key];
        onAppend(parentPath, newId);
        setCurrent(newId);
        onHideMenu();
    };

    return (
        <>
            <div className={"cols gap-md"}>
                <div style={{ height: "600px", width: "220px" }}>
                    {tree[0]?.children?.length ? (
                        <SortableTree
                            scaffoldBlockPxWidth={14}
                            theme={FileExplorerTheme}
                            onlyExpandSearchedNodes={false}
                            canNodeHaveChildren={canHaveChildren}
                            generateNodeProps={nodeProps}
                            onMoveNode={moveNode}
                            rowHeight={24}
                            onChange={() => null}
                            canDrag={canDrag}
                            canDrop={canDrop}
                            treeData={tree}
                            placeholderRenderer={Placeholder}
                        />
                    ) : (
                        <div>
                            <Button
                                variant={"outlined"}
                                onClick={() => handleCreate(tree[0].id)}
                            >
                                Add link
                            </Button>
                        </div>
                    )}
                </div>
                <div className={"rows grow"}>
                    {current && <LinkForm id={current} />}
                </div>
            </div>
            <Popover
                open={!!menuState.contextMenu}
                anchorEl={menuState.contextMenuAnchor}
                onClose={onHideMenu}
            >
                <SelectList>
                    <SelectListItem
                        onClick={() => handleRemove(menuState.contextMenu)}
                    >
                        Remove
                    </SelectListItem>
                    <SelectListItem
                        onClick={() => handleCreate(menuState.contextMenu)}
                    >
                        Create
                    </SelectListItem>
                </SelectList>
            </Popover>
        </>
    );
}

NavTree.propTypes = {};

NavTree.defaultProps = {};

export default NavTree;
