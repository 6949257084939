import React from "react";
import PropTypes from "prop-types";

import Preloader from "components/Preloader";
import { useRouteParam } from "state/hooks";
import { useNode } from "joynt/hooks";
import FormHeader from "joynt/components/Form/FormHeader";

import "./style.css";

export function FormLayout(props) {
    const {
        children,
        title,
        onReject,
        pending,
        nodeName,
        infoLeft,
        infoRight,
        tabs,
        showBackLink,
        isMobile,
    } = props;

    return (
        <div className={"form-layout"}>
            <FormHeader
                title={title}
                parentName={nodeName}
                onReject={onReject}
                showBackLink={showBackLink}
            >
                {tabs}
            </FormHeader>
            <Preloader visible={pending} />
            <div className={"form-layout__body"}>
                {!isMobile && (infoLeft || infoRight) && (
                    <div className={"form-side-tutorial"}>
                        {infoLeft || null}
                    </div>
                )}
                <div className={"form-layout__content"}>{children}</div>
                {!isMobile && (infoRight || infoLeft) && (
                    <div className={"form-side-tutorial"}>
                        {infoRight || null}
                    </div>
                )}
            </div>
        </div>
    );
}

FormLayout.propTypes = {
    pending: PropTypes.bool,
    title: PropTypes.string,
    onReject: PropTypes.func,
    nodeName: PropTypes.string,
    showBackLink: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default function FormLayoutContainer(props) {
    const id = useRouteParam("id")[0];
    const { name } = useNode(id);

    return <FormLayout {...props} nodeName={name} />;
}

FormLayoutContainer.propTypes = {};

FormLayoutContainer.defaultProps = {
    showBackLink: false,
    isMobile: false,
};
