import React from "react";
import SectionPreviewContainer from "./../SectionPreview";
import SectionCardLabel from "./SectionCardLabel";
//import PropTypes from 'prop-types';

import "./style.css";

export function SectionCard(props, ref) {
    const {} = props;
    return (
        <SectionPreviewContainer
            {...props}
            Content={SectionCardLabel}
            className={"section-card"}
            ref={ref}
        />
    );
}

// SectionCard.propTypes = {};
// SectionCard.defaultProps = {};

const SectionCardRef = React.forwardRef(SectionCard);

export default SectionCardRef;
