import React from "react";
import { useData, useRouteParam } from "state/hooks";
import Button from "components/Button";
import Icon from "components/Icon";
import { SectionTemplate } from "playground/cms/views/Sections/SectionForm";
import AggregateRoot from "containers/AggregateRoot";

//import PropTypes from 'prop-types';

export function SectionPreviewHeader(props) {
    const { id } = props;

    const { type, template } = useData(props);

    const { description: templateName } = useData({
        type: "cms.sections",
        id: template,
    });

    const [, setPreview] = useRouteParam("sectionPreview");

    return (
        <div className={"pad-md cols cols-spread"}>
            <div>{templateName}</div>
            <div className={"cols cols-middle gap-sm"}>
                <AggregateRoot type={"cms.sections"} id={id}>
                    <SectionTemplate type={"cms.sections"} id={id} />
                </AggregateRoot>
                <Button
                    size={"small"}
                    variant={"outlined"}
                    color={"default"}
                    onClick={() => setPreview(null)}
                    endIcon={<Icon>mui-close</Icon>}
                >
                    Zamknij podgląd
                </Button>
            </div>
        </div>
    );
}

SectionPreviewHeader.propTypes = {};

SectionPreviewHeader.defaultProps = {
    type: "cms.sections",
};

export default SectionPreviewHeader;
