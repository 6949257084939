import React from 'react';
import {POST_TYPE_ALBUM, POST_TYPE_POLL, POST_TYPE_TEXT} from "joynt/config";
import Tab from "./Tab";
//import PropTypes from 'prop-types';

import "./style.css";

const tabs = [
    {
        id: POST_TYPE_TEXT,
        label: "Create post",
        icon: "mui-add",
    },
    {
        id: POST_TYPE_POLL,
        label: "Create poll",
        icon: "mui-poll",
    },
    {
        id: POST_TYPE_ALBUM,
        label: "Add files",
        icon: "mui-add",
    },
];

export function Tabs(props) {

    const {onChange, value} = props;

    return tabs.map(tab => <Tab
        key={tab.id}
        {...tab}
        isSelected={tab.id === value}
        onClick={onChange}
    />);

}

Tabs.propTypes = {};
Tabs.defaultProps = {};

export default Tabs;
