import React, { useState } from "react";
import { SideHelperList } from "joynt/components/SideHelperList";
import Accordion from "joynt/components/Accordion/Accordion";

import "./style.css";

export const TutorialAccordionList = (props) => {
    const { data } = props;
    const [activeAccordionId, setActiveAccordionId] = useState();

    const handleAccordionOpen = (isExpanded, id) => {
        if (id !== activeAccordionId) {
            setActiveAccordionId(id);
        } else {
            setActiveAccordionId(undefined);
        }
    };

    if (!data) return null;

    return (
        <SideHelperList {...props} className={"tutorial-accordion-list"}>
            {data?.map((element) => {
                return (
                    <Accordion
                        key={element.id}
                        onChange={handleAccordionOpen}
                        {...element}
                        expanded={element.id === activeAccordionId}
                    />
                );
            })}
        </SideHelperList>
    );
};

export default TutorialAccordionList;
