import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";

export function PageSeo(props) {
    const { type, id } = props;
    return (
        <div className={"rows gap-md"}>
            <Form
                type={type}
                id={id}
                selectFields={{ seo: true, open_graph: true }}
            />
        </div>
    );
}

PageSeo.propTypes = {};

PageSeo.defaultProps = {};

export default PageSeo;
