import React from "react";
import PropTypes from "prop-types";
import RoleTag from "./RoleTag";

import roles from "./roles";

export function RoleTagContainer(props) {
    const { role } = props;
    const config = roles[role] || {};

    return (
        <RoleTag color={config.color} {...props}>
            {config.label}
        </RoleTag>
    );
}

RoleTagContainer.propTypes = {
    role: PropTypes.string,
};
RoleTagContainer.defaultProps = {};

export default RoleTagContainer;
