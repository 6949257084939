import Callout from "./Callout";
import ToDo from "./ToDo";
import Link from "./Link";
import Files from "./Files";

import "./style.css";

export const draftNativeBlocks = [
    {
        id: "unstyled",
        name: "Text",
        icon: "mui-title",
    },
    {
        id: "header-one",
        name: "Heading 1",
        icon: "h1",
        keywords: "|h1|title",
    },
    {
        id: "header-two",
        name: "Heading 2",
        icon: "h2",
        keywords: "|h2|title",
    },
    {
        id: "header-three",
        name: "Heading 3",
        icon: "h3",
        keywords: "|h3|title",
    },
    {
        id: "unordered-list-item",
        name: "Bulleted list",
        icon: "mui-format_list_bulleted",
        keywords: "|ul|list",
    },
    {
        id: "ordered-list-item",
        name: "Numbered list",
        icon: "mui-format_list_numbered",
        keywords: "|ol|list",
    },
    {
        id: "blockquote",
        name: "Quote",
        icon: "mui-format_quote",
    },
    {
        id: "code-block",
        name: "Code block",
        icon: "mui-code",
    },
];

export const customBlockTypes = [
    {
        id: "joynt-todo",
        name: "To-do list",
        component: ToDo,
        editable: true,
        icon: "mui-checklist",
        keywords: "|todo",
    },
    {
        id: "joynt-callout",
        name: "Callout",
        component: Callout,
        editable: true,
        icon: "a",
    },
    {
        id: "joynt-link",
        name: "Link",
        component: Link,
        atomic: true,
        editable: false,
        icon: "mui-link",
    },
    {
        id: "joynt-files",
        name: "Attachment",
        component: Files,
        atomic: true,
        editable: false,
        icon: "mui-attach_file",
        keywords: "|file|image|upload|img",
    },
];
