import React from 'react';
import PropTypes from 'prop-types';
import Button from "components/Button";
import {CircularProgress} from "@material-ui/core";
import Icon from "components/Icon";

export function FollowButton(props) {

    const {onClick, isFollowing, isPending} = props;

    return (<Button
        onClick={onClick}
        variant={isFollowing ? "contained" : "outlined"}
        startIcon={isFollowing && !isPending && <Icon>mui-check</Icon>}
    >
        <div className={"cols gap-xs cols-middle"}>
            {isPending && <CircularProgress
                size={16}
                color={"inherit"}
            />}
            <div>
                {isFollowing ? "Unfollow" : "Follow"}
            </div>
        </div>
    </Button>);

}

FollowButton.propTypes = {
    isFollowing: PropTypes.bool,
    isPending: PropTypes.bool,
    onClick: PropTypes.func
};
FollowButton.defaultProps = {
    isPending: false,
    isFollowing: false
};

export default FollowButton;
