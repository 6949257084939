import React from 'react';
import PropTypes from 'prop-types';

export function ProfileIcon(props) {

    const {avatar, onClick} = props;

    return (<div onClick={onClick} className={"cursor-pointer"}>
        {avatar}
    </div>);

}

ProfileIcon.propTypes = {
    onClick: PropTypes.func
};
ProfileIcon.defaultProps = {};

export default ProfileIcon;
