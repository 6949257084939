import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import Field from "containers/Field";
import { EntryDescription } from "playground/cms/views/Entries";
import { Property, PropertyGrid } from "playground/cms/fields/Properties";
import DatePicker from "playground/cms/fields/DatePicker";
import { MediaField } from "playground/cms/fields/Media";

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
    headline: false,
    text: false,
    media: false,
};

export function PostsForm(props) {
    const { type, id } = props;
    return (
        <div className={"pad-md rows gap-md"}>
            <EntryDescription {...props} />
            <Field id={"intro_text"} label={"Tekst wstępny"} />
            <PropertyGrid>
                <Property label={"Data utworzenia"}>
                    <Field
                        id={"created_at"}
                        FieldComponent={DatePicker}
                        label={"Data utworzenia"}
                        nullable={true}
                        className={"cols gap-xs"}
                    />
                </Property>
                <Property label={"Data publikacji"}>
                    <Field
                        id={"published_at"}
                        FieldComponent={DatePicker}
                        label={"Data publikacji"}
                        nullable={true}
                        className={"cols gap-xs"}
                    />
                </Property>
                <Property label={"Kontakt"}>
                    <Field
                        id={"contact"}
                        fieldType={"select"}
                        source={"api:cms.entries.contacts"}
                        variant={"inline"}
                        label={"Przypisz osobę"}
                        nullable={true}
                    />
                </Property>
            </PropertyGrid>
            <MediaField id={"attachments"} label={"Załączniki"} />
            <Form type={type} id={id} selectFields={selectFields} />
        </div>
    );
}

PostsForm.propTypes = {};

PostsForm.defaultProps = {};

export default PostsForm;
