import React from 'react';
import cn from "classnames";

//import PropTypes from 'prop-types';

import "./style.css";
import {useMoment} from "joynt/components/DateTimePicker/hooks";
import {isToday} from "joynt/components/DailySchedule/util";

export function DayTrack(props) {

    const {
        time,
        children
    } = props;

    const day = new Date(time*1000);
    const today = isToday(day);
    const moment = useMoment(time);
    const dow = day.getDay();
    const isSunday = dow === 0;

    return (<div className={cn("day-track", {
        "is-sunday": isSunday,
        "is-today": today
    })}>
        <div className={"day-track__header"}>
            <div className={"day-track__label"}>
                <div className={"day-track__day"}>
                    {moment.format('DD')}
                </div>
                <div className={"day-track__day-of-week"}>
                    {moment.format('ddd')}
                </div>
            </div>
        </div>
        <div className={"day-track__events"}>
            {children}
        </div>
    </div>);

}

DayTrack.propTypes = {};
DayTrack.defaultProps = {};

export default DayTrack;
