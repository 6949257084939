const JitsiMeetJS = window.JitsiMeetJS;

function onConnectionFailed(evt) {
    console.log('onConnectionFailed', evt);
}

function disconnect(evt) {
    console.log('disconnect', evt);
}

function onUserJoined(evt) {
    console.log('onUserJoined', evt);
}

const config = {
    serviceUrl: `https://localhost:7443/http-bind`,
    hosts: {
        domain: 'meet.jitsi',
        muc: 'muc.meet.jitsi'
    }
};

class JitsiMeetSession {

    constructor(token, actions) {
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        JitsiMeetJS.init();

        this.token = token;
        this.tracksIndex = {};
        this.tracks = [];
        this.participantId = null;
        this.actions = actions;
    }

    start = (id) => {
        const token = this.token;

        const connection = new JitsiMeetJS.JitsiConnection(null, token, config);

        function onConferenceJoined() {
            console.log('conferenceJoined');
        }

        const onConnectionSuccess = (evt) => {
            const room = connection.initJitsiConference(id, {
                openBridgeChannel: true
            });

            room.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => {
                const participant = track.getParticipantId();
                const id = track.getId();
                const type = track.getType();
                if (track.isLocal() && type === 'audio') return;
                this.tracksIndex[id] = track;
                if (!this.tracks[participant]) {
                    this.tracks = {...this.tracks};
                    this.tracks[participant] = {};
                }
                this.tracks[participant] = {...this.tracks[participant]};
                this.tracks[participant].local = track.isLocal();
                this.tracks[participant].active = true;
                this.tracks[participant][type] = id;
                if (type === 'video') this.tracks[participant].id = id;
                this.actions.setTracks(Object.values(this.tracks));
            });

            room.on(JitsiMeetJS.events.conference.USER_LEFT, (id) => {
                console.log('onUserLeft', id, this.tracks[id]);
                this.tracks = {...this.tracks};
                delete this.tracks[id];
                this.actions.setTracks(Object.values(this.tracks));
                console.log(room.getParticipants());
            });

            room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
            room.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);

            room.join(token);

            this.participantId = room.myUserId();

            JitsiMeetJS.createLocalTracks({devices: ['audio', 'video']})
                .then(localTracks => localTracks.forEach(track=>room.addTrack(track)))
                .catch(e => console.log(e));
        }

        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
        connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, disconnect);

        connection.connect();
    }

    attachTrack = (id, container) => {
        console.log('attachTrack', id, container);
        this.tracksIndex[id].attach(container);
    }
}

export default JitsiMeetSession;