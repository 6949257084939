import React from 'react';
//import PropTypes from 'prop-types';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
//import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import {thumbnail} from 'util/thumbnail';

class Media extends React.PureComponent {
    render() {
        const { file } = this.props.data;
        const thumbPath = thumbnail(file, 'sm');
        return <div className={'gallery-slide'}>
            <img src={thumbPath} alt={file} />
        </div>;
    }
}

//const MediaIterator = ArrayIterator(withData(Media));
const ConnectedMedia = withData(Media);

const sliderSettings = {
    dots: false,
    lazyLoad: 'ondemand'
};

export default class RoomGallery extends React.Component {

    render() {
        const { media } = this.props;
        return (<Slider {...sliderSettings}>
            {media.map(image => {
                return <ConnectedMedia key={image} type={'cms.media'} id={image} />;
            })}
        </Slider>);
    }

}

RoomGallery.propTypes = {};