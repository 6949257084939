import {useEffect, useState} from "react";
import {useActions} from "state/hooks";
import * as actions from "joynt/state/actions/presence";
import {notify} from "state/actions/ui";
import useEcho from "joynt/websockets/hooks/useEcho";

export default function usePresenceChannel(channel) {
    const [subscription, setSubscription] = useState(null);
    const {onNotify} = useActions({onNotify: notify});
    const echo = useEcho();

    const {
        onConnected,
        onJoin,
        onLeave,
        onUpdate
    } = useActions({
        onConnected: actions.connectedToChannel,
        onJoin: actions.userJoined,
        onLeave: actions.userLeft,
        onUpdate: actions.updateState
    });

    const wsChannel = `node.${channel}`;

    useEffect(() => {
        console.log(`Subscribe to ${wsChannel}`);
        let sub = null;
        if (echo) {
            sub = echo.join(wsChannel)
                .here(users => onConnected(channel, users))
                .joining(user => onJoin(channel, user))
                .leaving(user => onLeave(channel, user))
                .listen('.presence', (e) => onUpdate(e.data));
            sub.listen('.subscription_error', code => {
                onNotify(`Error while connecting to websocket server (${code})`, 'error');
            });
            setSubscription(sub);
        }
        return () => {
            console.log(`Unsubscribe from ${wsChannel}`);
            if (echo) {
                echo.leave(wsChannel);
                setSubscription(null);
            }
        }
    }, [
        echo,
        channel,
        wsChannel,
        setSubscription,
        onConnected,
        onJoin,
        onLeave,
        onUpdate,
        onNotify
    ]);

    return subscription;
}