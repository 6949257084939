import React from 'react';

//import PropTypes from 'prop-types';

import "./style.css";

export function SessionFlowSchedule(props) {

    const {children} = props;

    return (<div>
        {children}
    </div>);

}

SessionFlowSchedule.propTypes = {};
SessionFlowSchedule.defaultProps = {};

export default SessionFlowSchedule;
