import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";

export function DayTrackList(props) {

    const {children} = props;

    return (<div className={"day-track-list"}>
        {children}
    </div>);

}

DayTrackList.propTypes = {};
DayTrackList.defaultProps = {};

export default DayTrackList;
