import React from 'react';
//import PropTypes from 'prop-types';

import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useSessionState} from "joynt/meeting/hooks/useSessionState";
import {SESSION_POLICY_MEETING} from "joynt/config";
import cn from "classnames";
import IconButton from "components/IconButton";
import Button from "components/Button";
import useSessionControls from "joynt/meeting/hooks/useSessionControls";

export function RaiseHand(props) {

    const {size, isMobile} = props;

    const {session, policy, isAllowedOnStage} = useMeeting();
    const isMeeting = policy === SESSION_POLICY_MEETING;

    const { isLive, raisedHand, setRaisedHand } =
        useSessionState(session);

    const {onToggleLive} = useSessionControls(session);

    const canRaiseHand = isMeeting || (!isAllowedOnStage && !isLive);
    const canGoOnStage = !isMeeting && (isLive || isAllowedOnStage);

    return (<>
        {canRaiseHand && <IconButton
            withTooltip
            onClick={() => setRaisedHand(!raisedHand)}
            icon={"mui-pan_tool"}
            label={raisedHand ? "Raised hand" : "Raise hand"}
            className={cn("session-hand", {
                on: raisedHand,
            })}
            size={size}
        />}
        {canGoOnStage && !isMobile && <Button
            onClick={onToggleLive}
            variant={isLive ? "outlined" : "contained"}
        >
            {isLive ? "Leave stage" : "Go on stage"}
        </Button>}
        {canGoOnStage && isMobile && <IconButton
            onClick={onToggleLive}
            icon={isLive ? "mui-do_not_touch" : "mui-pan_tool"}
            className={cn("session-hand", {
                on: isLive,
            })}
            size={size}
        />}
    </>);

}

RaiseHand.propTypes = {};

RaiseHand.defaultProps = {
    size: "medium"
};

export default RaiseHand;