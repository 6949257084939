import React from "react";
import { useRouteParam } from "state/hooks";
import Switch from "components/fields/checkbox/Switch";
import Button from "components/Button";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function PreviewToggle(props) {
    const {} = props;
    const [preview, setPreview] = useRouteParam("sectionPreview");
    if (preview) return null;
    return (
        <Button
            onClick={() => setPreview(preview ? null : true)}
            size={"small"}
            variant={"outlined"}
            color={"default"}
            startIcon={<Icon>mui-format_shapes</Icon>}
        >
            Podgląd
        </Button>
    );
    return (
        <Switch
            id={"preview"}
            value={preview === "true"}
            onChange={(v) => setPreview(preview ? null : true)}
        />
    );
}

PreviewToggle.propTypes = {};

PreviewToggle.defaultProps = {};

export default PreviewToggle;
