import React from 'react';
import PropTypes from 'prop-types';
import EventHeader from "./EventHeader";
import {useNodeData} from "joynt/hooks";

export function EventHeaderContainer(props) {

    const {id} = props;

    const {
        name,
        event_start,
        event_end,
        description
    } = useNodeData(id);

    return (<EventHeader
        {...props}
        name={name}
        description={description}
        start={event_start}
        end={event_end}
    />);

}

EventHeaderContainer.propTypes = {
    id: PropTypes.string
};
EventHeaderContainer.defaultProps = {};

export default EventHeaderContainer;