import React, { useCallback } from "react";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';

import cn from "classnames";
import { SketchPicker } from "react-color";
import Popover from "components/Popover";
import Select from "components/fields/select/Select";
import { importColorScheme } from "cms/state/actions/theme";

import "playground/theme/ColorScheme/style.css";
import IconButton from "components/IconButton";

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

function ColorPickerField(props) {
    const { value, label, onChange } = props;
    let style = {};
    if (value) style.backgroundColor = value;

    const change = useCallback(
        (value) => {
            onChange(value.hex);
        },
        [onChange]
    );

    return (
        <Popover
            anchorOrigin={anchorOrigin}
            trigger={({ onClick }) => (
                <div
                    style={style}
                    onClick={onClick}
                    className={cn("swatch-color", `swatch-color--${label}`)}
                >
                    <div className={"swatch-color__name"}>{label}</div>
                    <div className={"swatch-color__hex"}>{value}</div>
                </div>
            )}
            content={({ onClose }) => (
                <SketchPicker color={value || "#ffffff"} onChange={change} />
            )}
        />
    );
}

const colors = ["primary_color", "success_color", "light_color", "dark_color"];

const mapState = (store, props) => ({});
const mapDispatch = {
    onImport: importColorScheme,
};

class ColorSchemeField extends React.PureComponent {
    change = (id, newValue) => {
        const { onChange } = this.props;
        const value = this.props.value || {};
        const next = { ...value };
        next[id] = newValue;
        onChange(next);
    };

    importFromPreset = (from) => {
        const { entityType, entityId, onImport } = this.props;
        onImport(from, entityId, entityType);
    };

    render() {
        const value = this.props.value || {};

        return (
            <div className={"grid grid-5 grow swatch"}>
                {colors.map((id) => {
                    return (
                        <ColorPickerField
                            key={id}
                            value={value[id]}
                            label={id.replace("_color", "")}
                            onChange={(v) => this.change(id, v)}
                        />
                    );
                })}
                {/*<IconButton icon={'mui-search'} />*/}
                <Select
                    source={"api:console.presets"}
                    onChange={this.importFromPreset}
                    ValueComponent={({ onClick }) => (
                        <div onClick={onClick}>
                            <IconButton
                                icon={"mui-search"}
                                onClick={() => null}
                            />
                        </div>
                    )}
                />
            </div>
        );
    }
}

ColorSchemeField.propTypes = {};

export default connect(mapState, mapDispatch)(ColorSchemeField);
