import React from 'react';

import MuiList from '@material-ui/core/List';
import List from "containers/List";
import Preloader from "components/Preloader";
import Header from 'joynt/chrome-extension/Header';
import Field from 'containers/Field';
import NodesView from "./NodesView";

//import 'joynt/chrome-extension/style.css';
import ListView from "containers/ListView";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import Button from "components/Button";
import DateDiff from "components/DateDiff";
import {POST_TYPE_FILTER_ID} from "joynt/config";

function Post(props) {
    const {
        id,
        parent,
        link,
        name,
        selectionText,
        onClick,
        created_at
    } = props;

    const length = selectionText.length;
    const maxLength = 200;

    return <div className={'card default-c600'}>
        <div className={'fill rows gap-sm'}>
            <div className={'rows gap-xs'}>
                <div>{ length > maxLength
                    ? selectionText.substring(0,maxLength) + '(...)'
                    : selectionText}</div>
                <div className={'rows gap-sm'}>
                    { name ? <div>{name}</div> : null }
                    <div className={'cols gap-sm'}>
                        <div className={'o-50'}>{link || id}</div>
                        <div>
                            <DateDiff value={created_at}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button onClick={()=>onClick(parent)}>
                    Add selection to existing document
                </Button>
            </div>
        </div>
    </div>
}

Post.autoFillProps = [
    'link',
    'title',
    'parent',
    'created_at'
];

const PostIterator = ArrayIterator(withData(Post));

function Stem({name, posts}) {
    return <div>
        {name}
        <List
            type={'db.posts'}
            items={posts}
            Iterator={PostIterator}
        />
    </div>
}

Stem.autoFillProps = [
    'name',
    'posts'
];

class JointExtension extends React.PureComponent {

    render() {
        const {
            view,
            onLogout,
            userId,
            isAuthenticated,
            isPending,
            searchPending,
            tab,
            id,
            stateId,
            list,
            onNavigateToNode,
            onSubmitPost,
            onSubmitFragment,
            selectionText,
            currentTabUrl
        } = this.props;

        const LINK_TYPE = 'cea8ad00-3d13-428f-a57d-96ee19758d4a';
        //const stemsUrl = `joynt/nodes?graph-root=${id}&subtype=${LINK_TYPE}&graph-depth=99&node_type=stem&graph-edges=child,joined,stem&created_by=currentUser&limit=20`;
        const pageUrl = encodeURIComponent(currentTabUrl);
        const postsUrl = `joynt/posts?limit=1&${POST_TYPE_FILTER_ID}=${LINK_TYPE}&link=${pageUrl}`;

        return (<div className={'extension'}>
            <Preloader visible={isPending} />

            { isAuthenticated && userId ? <Header
                id={id}
                current={tab}
                onLogout={onLogout}
                onChangeTab={this.handleChangeTab}
            /> : null }

            { isAuthenticated && userId ? <React.Fragment>
                <div className={'rows extension-list relative'}>
                    <Preloader visible={searchPending} />
                    { currentTabUrl ? <div className={'rows gap-sm pad-sm'}>
                        <ListView
                            type={'db.posts'}
                            list={'posts'}
                            url={postsUrl}
                            requireView={true}
                            Iterator={PostIterator}
                            onClick={onSubmitFragment}
                            selectionText={selectionText}
                        />
                    </div> : null }
                    <div className={'cols pad-sm extension-filter'}>
                        <Field
                            pending={searchPending}
                            variant={'search'}
                            fullWidth={true}
                            size={'sm'}
                            entityType={'app.filters'}
                            entityId={'nodes.graph'}
                            id={'search'}
                            label={'Search...'}
                        />
                    </div>
                    <MuiList>
                        <NodesView
                            id={id}
                            selected={stateId}
                            view={view}
                            list={list}
                            nodesView={'nodes.list'}
                            onSecondaryAction={onSubmitPost}
                            secondaryActionIcon={'mui-add'}
                            secondaryActionButton={true}
                            onNavigateToNode={onNavigateToNode}
                        />
                    </MuiList>
                </div>
            </React.Fragment> : null }
        </div>);
    }

}

export default JointExtension;