import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import CalendarEvent from "./CalendarEvent";
import {useNodeData} from "joynt/hooks";
import {useSession} from "joynt/hooks/sessions";

function calculatePosition(props) {

    const {start, end, trackWidth, event_start, event_end} = props;

    const duration = event_end - event_start;
    const range = end - start;
    const timePerPx = trackWidth/range;

    const startRelative = event_start - start;
    let left = (startRelative*100)/range;

    const width = duration*timePerPx;
    return {width, left};
}

export function CalendarEventContainer(props) {

    const {
        id,
        start,
        end,
        width
    } = props;

    const {event_start, event_end, name} = useNodeData(id);

    const positionProps = useMemo(() => ({
        start,
        end,
        event_start,
        event_end,
        trackWidth: width
    }), [
        start,
        end,
        event_start,
        event_end,
        width
    ])

    const pos = calculatePosition(positionProps)

    const {onShow} = useSession(id);

    return <CalendarEvent
        left={pos.left+'%'}
        width={pos.width+'px'}
        name={name}
        onClick={onShow}
    />

}

CalendarEventContainer.propTypes = {
    id: PropTypes.string
};
CalendarEventContainer.defaultProps = {};

export default CalendarEventContainer;