import React, { useCallback } from "react";
import { usePagination } from "joynt/hooks";
import Fetch from "containers/Fetch";
import List from "containers/List";
import { ReverseScrollContainer } from "joynt/components/ReverseScrollContainer";
import cn from "classnames";
import { CircularProgress } from "@material-ui/core";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import { Message, MessageSkeleton } from "joynt/components/messages";
import { ArrayIterator } from "components/list/iterator";
//import PropTypes from 'prop-types';

function ShowMore({ onClick, children, pending }) {
    return (
        <div onClick={onClick} className={"more-btn"}>
            {pending ? (
                <>
                    <CircularProgress size={16} thickness={3} />
                    <span>Loading...</span>
                </>
            ) : (
                children
            )}
        </div>
    );
}

const ScrollMessageIterator = InfiniteScrollIterator(Message);
const MessageIterator = ArrayIterator(Message);

export function MessagesSkeleton() {
    return (
        <div className={"fill rows rows-bottom"}>
            <MessageSkeleton />
            <MessageSkeleton />
            <MessageSkeleton />
            <MessageSkeleton />
        </div>
    );
}

export function MessagesEmptyState({ text }) {
    return (
        <div className={"messages-empty-state"}>
            <div className={"messages-empty-state__image"} />
            <div className={"messages-empty-state__text"}>{text}</div>
        </div>
    );
}

MessagesEmptyState.defaultProps = {
    text: "It's a bit quiet in here",
};

export function MessagesList(props) {
    const { id, limit, fallbackIdentity, scrollPagination, className, mode } =
        props;
    const list = ["thread", id].join(".");
    const type = "db.messages";

    const { page, first, hasMore, pending, loadPage } = usePagination(
        type,
        list
    );
    //const url = `joynt/messages?parent=${id}&limit=${limit}&order=created_at desc`;
    const url = `v2/joynt/threads/${id}/messages?limit=${limit}`;
    //const pending = useSelector(s=>isPending(s, [type,list].join('/')));
    const nextPage = useCallback(() => loadPage(page + 1), [loadPage, page]);

    if (!id) return null;

    if (!scrollPagination) {
        return (
            <Fetch
                type={type}
                list={list}
                filter={list}
                append={true}
                url={url}
            >
                {hasMore ? (
                    <div className={"cols cols-center"}>
                        <ShowMore onClick={nextPage} pending={pending}>
                            Load earlier comments
                        </ShowMore>
                    </div>
                ) : null}
                <List
                    mode={mode}
                    type={type}
                    list={list}
                    filter={list}
                    fallbackIdentity={fallbackIdentity}
                    reverse={true}
                    useWindowScroll={false}
                    Iterator={MessageIterator}
                    pendingState={!page ? <MessagesSkeleton /> : null}
                    emptyState={<MessagesEmptyState />}
                    pendingReplace={true}
                    className={"messages-list rows gap-3 rows-bottom"}
                />
            </Fetch>
        );
    }

    return (
        <Fetch type={type} list={list} filter={list} append={true} url={url}>
            <ReverseScrollContainer
                item={first}
                pending={pending}
                className={cn(className, "overflow-scroll")}
                page={page}
            >
                <List
                    type={type}
                    list={list}
                    filter={list}
                    fallbackIdentity={fallbackIdentity}
                    mode={mode}
                    reverse={true}
                    useWindowScroll={false}
                    Iterator={ScrollMessageIterator}
                    pendingState={!page ? <MessagesSkeleton /> : null}
                    emptyState={<MessagesEmptyState />}
                    pendingReplace={true}
                    className={"messages-list rows gap-3 rows-bottom"}
                />
            </ReverseScrollContainer>
        </Fetch>
    );
}

MessagesList.propTypes = {};
MessagesList.defaultProps = {
    limit: 18,
    scrollPagination: true,
};
