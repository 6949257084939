import React from "react";
import {
    ListItem as BaseListItem,
    MenuItems as BaseMenuItems,
} from "playground/cms/views/Resources";
import { useDomainCertificate } from "./../useDomainCertificate";
import MenuItem from "components/context-menu/MenuItem";

//import PropTypes from 'prop-types';

function DomainInfo({ id }) {
    const { status, validTo } = useDomainCertificate(id);
    if (!validTo) return null;
    return <span className={status.color}>{validTo.fromNow()}</span>;
}

function MenuItems(props) {
    const { id } = props;
    const { onRenew } = useDomainCertificate(id);
    return [
        <BaseMenuItems {...props} />,
        <MenuItem
            key={"ctx-renew"}
            onClick={onRenew}
            primaryText={"Renew certificate"}
        />,
    ];
}

export function ListItem(props) {
    const { id } = props;

    return (
        <BaseListItem {...props} MenuItems={MenuItems}>
            <DomainInfo id={id} />
        </BaseListItem>
    );
}

ListItem.propTypes = {};

ListItem.defaultProps = {};

export default ListItem;
