import React from 'react';
import {useNode} from "joynt/hooks";
import {useRouter} from "joynt/hooks/channels";
import {useClick} from "state/hooks";

export default function NodeLink(props) {
    const {data, id} = props;
    const {name} = data;
    const {name: label} = useNode(id);
    const {onLink} = useRouter();
    const click = useClick({onClick: onLink, id});
    return <div className={'link'} onClick={click}>{name || label}</div>;
}

NodeLink.defaultProps = {
    data: {}
}