import { EditorState, Modifier, SelectionState } from "draft-js";

export function updateBlockData(editorState, block, newData) {
    const userSelection = editorState.getSelection();
    const blockSelection = SelectionState.createEmpty(block.getKey());
    const newContent = Modifier.setBlockData(
        editorState.getCurrentContent(),
        blockSelection,
        newData
    );
    let nextState = EditorState.set(editorState, {
        currentContent: newContent,
    });
    return EditorState.forceSelection(nextState, userSelection);
}
