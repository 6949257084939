import React from 'react';
import PropTypes from 'prop-types';
import PeopleList from "./PeopleList";
import useParticipants from "joynt/participants/hooks/useParticipants";

export function PeopleListContainer(props) {

    const {id} = props;
    const list = `participants.${id}`;
    const {online} = useParticipants(id, list);

    return (<PeopleList
        {...props}
        items={online}
    />);

}

PeopleListContainer.propTypes = {
    id: PropTypes.string
};
PeopleListContainer.defaultProps = {};

export default PeopleListContainer;