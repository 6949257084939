import useMeeting from "joynt/meeting/hooks/useMeeting";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import {useNode, useNodeData} from "joynt/hooks";
import {useSelector} from "react-redux";
import {selectEventRole} from "joynt/state/selectors/joint";
import {canEndSession, canStartSession} from "joynt/config/roles";
import {useCallback, useMemo} from "react";
import {isPending} from "state/selectors/ui";
import {useUiFlag} from "state/hooks";

export function useStage(id, scheduled) {

    const isEvent = !!scheduled;

    const {
        session,
        onJoinSession,
        onStartNextSession,
        onEndSession
    } = useMeeting();

    const [{joined, loading, error}] = useConnectionState(id);

    const {
        current_event,
        next_event
    } = useNodeData(id);

    const {
        current_event: sessionCurrentEvent
    } = useNode(session);

    const stageEventId = current_event || next_event || id;

    const role = useSelector(s=>selectEventRole(s, stageEventId));
    const canStart = canStartSession.indexOf(role) > -1;
    const canEnd = canEndSession.indexOf(role) > -1;

    const pending = useSelector(store=>isPending(store, id));
    const joinPending = useUiFlag('meetingPendingSession')[0];
    const joining = loading || (joinPending === id);

    const isConnected = joined && session && ((session === id));
    const isLive = !isEvent || !!current_event || (sessionCurrentEvent === id);

    const eventId = isLive ? current_event : next_event || id;

    const join = useCallback(()=> onJoinSession(id), [id, onJoinSession]);
    const resume = useCallback(() => onStartNextSession(id), [id, onStartNextSession]);
    const endSession = useCallback(() => onEndSession(id), [id, onEndSession]);

    return useMemo(() => ({
        onJoin: join,
        onStartNextSession: canStart ? resume : null,
        onEndSession: canEnd ? endSession : null,
        isPending: pending && !joining,
        isJoining: joining,
        isConnected,
        isLive,
        role,
        error,
        session,
        eventId
    }), [
        session,
        role,
        canStart,
        canEnd,
        join,
        resume,
        endSession,
        pending,
        joining,
        isConnected,
        isLive,
        error,
        eventId
    ]);

}