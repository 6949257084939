import React, { useState } from "react";
//import PropTypes from 'prop-types';
import UserAccess from "./UserAccess";
import { useApiActions, useData, useFetch, useList } from "state/hooks";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
import { grantServiceAccess } from "cms/state/actions/services";
import { FormLayout } from "playground/cms/Form";

export function UserAccessContainer(props) {
    const { id } = props;
    const data = useData(props);
    const { owner, user_grants } = data?.access || {};

    const type = "console.user-service-access";
    const list = type;

    useFetch({
        url: `console/user-service-access?service=${id}`,
        type,
        list,
    });

    const { onGrant } = useApiActions({
        onGrant: grantServiceAccess,
    });

    const { items } = useList(type, list);
    const [email, setEmail] = useState(null);

    const submit = () => {
        if (!email) return;
        onGrant(id, email);
        setEmail(null);
    };

    return (
        <UserAccess
            {...props}
            email={email}
            setEmail={setEmail}
            onSubmit={submit}
            items={items}
        />
    );
}

UserAccessContainer.propTypes = {};

UserAccessContainer.defaultProps = {};

export default UserAccessContainer;
