import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";

export function useRouteParam(key, params = {}) {
    const dispatch = useDispatch();
    const setParam = useCallback((value) => {
        const newParams = {...params};
        newParams[key] = value;
        dispatch(pushRouteParams(newParams));
    }, [params, key, dispatch]);
    const value = useSelector(store=>selectRouteParam(store, key));
    return [value, setParam];
}