import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

import Icon from "components/Icon";
import MuiIconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const loaderStyle = {
  position: "absolute",
  top: 0,
  left: 0,
};

export default class IconButton extends React.PureComponent {
  render() {
    const {
      label,
      onClick,
      icon,
      defaultIconType,
      size,
      checked,
      isPending,
      ...other
    } = this.props;

    const button = (
      <MuiIconButton
        onClick={onClick}
        color={checked ? "primary" : "inherit"}
        {...other}
      >
        {isPending && (
          <div style={loaderStyle}>
            <CircularProgress thickness={2} disableShrink={true} size={40} />
          </div>
        )}
        <Icon defaultType={defaultIconType} fontSize={size}>
          {icon}
        </Icon>
      </MuiIconButton>
    );

    if (!label || other.disabled) return button;

    return <Tooltip title={label}>{button}</Tooltip>;
  }
}

IconButton.defaultProps = {
  isPending: false,
};

IconButton.propTypes = {
  icon: PropTypes.string,
  isPending: PropTypes.bool,
  onClick: PropTypes.func,
};
