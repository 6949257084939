import React, {useCallback} from "react";
import PropTypes from "prop-types";
import Session from "./Session";
import { useSessionUtils } from "joynt/components/UtilityNav/Session/hooks";
import { useUiFlag } from "state/hooks";
import { FLAG_SESSION_NAV } from "joynt/config";
import useMeeting from "joynt/meeting/hooks/useMeeting";

// import {useHasScope, useUiFlag} from "state/hooks";
// import {SCOPE_MASTER} from "joynt/config";

export function SessionContainer(props) {
    const { id } = props;

    const [view, setView] = useUiFlag(FLAG_SESSION_NAV, null);
    const views = useSessionUtils(id);
    const {policy} = useMeeting();

    const onClose = useCallback(() => {
        setView(null)
    }, [setView]);

    /**
     * Remove if we don't need debugger anymore
     */

    // const admin = useHasScope(SCOPE_MASTER);
    // const [debug, setSessionDebug] = useUiFlag("meeting.debug");

    return <Session
        {...props}
        policy={policy}
        onOpen={setView}
        onClose={onClose}
        view={view}
        views={views}
    />;
}

SessionContainer.propTypes = {
    id: PropTypes.string,
};
SessionContainer.defaultProps = {};

export default SessionContainer;
