import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import { usePushRoute } from "state/hooks";

import "./style.css";

export function ErrorPage(props) {
    const { error, title, description } = props;
    const pushRoute = usePushRoute();
    const goHome = () => pushRoute("/");
    return (
        <div
            className={"rows rows-middle rows-center gap-sm text-center pad-lg"}
        >
            <h1>{title}</h1>
            <p>{description}</p>
            <Button onClick={goHome}>Powrót</Button>
        </div>
    );
}

ErrorPage.propTypes = {};

ErrorPage.defaultProps = {};

export default ErrorPage;
