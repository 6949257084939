import React from "react";
import PropTypes from "prop-types";
import ParticipantsList from "./ParticipantsList";
import { useRoleGroups } from "joynt/components/Roles/hooks";
import { useNodeData } from "joynt/hooks";
import { RoleListItem } from "joynt/components/IdentityListItem";
import { ParticipantsCount } from "joynt/components/SessionParticipation";

export function ParticipantsListContainer(props) {
    const { id } = props;
    const list = `node-roles.${id}`;
    const groups = useRoleGroups(id, list, "attendees");
    const { roles_data } = useNodeData(id);
    const confirmed = roles_data?.confirmed?.identities || [];
    const denied = roles_data?.denied?.identities || [];
    const items = groups[0]?.items || [];
    const pending = items.filter((id) => {
        return confirmed.indexOf(id) === -1 && denied.indexOf(id) === -1;
    });

    const listGroups = {
        confirmed,
        denied,
        pending,
    };

    return (
        <ParticipantsList {...props} addon={<ParticipantsCount id={id} />}>
            <div className={"rows gap-4"}>
                {Object.keys(listGroups).map((role) => {
                    // eslint-disable-next-line jsx-a11y/aria-role
                    return listGroups[role].map((id) => (
                        <RoleListItem key={id} id={id} role={role} />
                    ));
                })}
            </div>
        </ParticipantsList>
    );
}

ParticipantsListContainer.propTypes = {
    id: PropTypes.string,
};
ParticipantsListContainer.defaultProps = {};

export default ParticipantsListContainer;
