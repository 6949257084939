import {useRouteNodes} from "joynt/hooks/channels";
import {useUiFlag} from "state/hooks";
import {FLAG_SESSION_ID} from "joynt/config";
import {useEdgesData} from "joynt/hooks/useEdgesData";

export function usePresenceId() {
    const {id} = useRouteNodes();
    const [session] = useUiFlag(FLAG_SESSION_ID);
    const {workspace} = useEdgesData(session);
    return session ? workspace : id;
}
