import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Field from "containers/Field";

export function ToggleMenuItemField(props) {
    return <Field>
        {({onChange, value}) => <ToggleMenuItem
            id={props.id}
            value={value[props.id]}
            onChange={onChange}
            primaryText={props.label}
        />}
    </Field>
}

export default function ToggleMenuItem(props) {
    const {
        primaryText,
        id,
        value,
        onChange
    }= props;

    const toggle = useCallback(()=>{
        onChange(id, !value);
    }, [id, value, onChange]);
    return <ListItem button onClick={toggle}>
        <ListItemIcon>
            <Switch checked={!!value} />
        </ListItemIcon>
        <ListItemText
            primary={primaryText} />
    </ListItem>
}

ToggleMenuItem.propTypes = {};