import React from 'react';
import NetworkStateInfo from "./NetworkStateInfo";
import NetworkStateProvider from "./NetworkStateProvider";
import OfflineOverlay from "./OfflineOverlay";
import {useNetworkState} from "./index";
//import PropTypes from 'prop-types';

function ShowNetworkStateInfo() {
    const {quality: {online}, timeOffline} = useNetworkState();
    const timeout = timeOffline > 5;

    return <>
        <NetworkStateInfo
            isOnline={online}
            isVisible={!online && !timeout}
        />
        <OfflineOverlay
            isVisible={!online && timeout}
        />
    </>;
}

export function NetworkState(props) {

    const {children} = props;

    return (<NetworkStateProvider>
        <ShowNetworkStateInfo />
        {children || null}
    </NetworkStateProvider>);

}

NetworkState.propTypes = {};
NetworkState.defaultProps = {};
