import React from "react";
//import PropTypes from 'prop-types';
import MediaPreview from "./MediaPreview";
import { useData, useRouteParam } from "state/hooks";
import { thumbnail } from "util/thumbnail";
import { enforceHttps } from "util/uri";
import env from "util/env";
import AggregateRoot from "containers/AggregateRoot";
import fileTypes from "components/media-preview/fileTypes";

export function MediaPreviewContainer(props) {
    const { id, onSave } = props;
    const data = useData({ type: "cms.media", id });
    const [, showPreview] = useRouteParam("preview");

    const onShowPreview = () => {
        showPreview(["cms.media", id].join("/"));
    };
    const onDownload = () => {
        const host = enforceHttps(env("FILES_CDN"));
        const url = `${host}${data.file}`;
        window.open(url);
    };

    const preview = thumbnail(data.file, [200, 150]);
    const PreviewComponent = fileTypes[data.type] || fileTypes.default;

    //return <pre>{JSON.stringify(data, null, 2)}</pre>;

    return (
        <AggregateRoot type={"cms.media"} id={id} disableSubmit={true}>
            <MediaPreview
                {...data}
                {...props}
                preview={preview}
                onShowPreview={onShowPreview}
                onDownload={onDownload}
            >
                <PreviewComponent {...data} />
            </MediaPreview>
        </AggregateRoot>
    );
}

MediaPreviewContainer.propTypes = {};

MediaPreviewContainer.defaultProps = {};

export default MediaPreviewContainer;
