import {useCallback} from "react";
import {useApiActions} from "state/hooks";
import {moveNode} from "joynt/state/actions";

export function useMoveNode(id) {
    const {
        onMoveNode
    } = useApiActions({
        onMoveNode: moveNode
    });

    return useCallback((passedId) => {
        onMoveNode(id ? id : passedId)
    }, [onMoveNode, id]);
}