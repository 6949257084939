import { isBrowser } from "util/ssr";
import env from "util/env";

export const services = () => {
    const joyntDb =
        (isBrowser() && window.localStorage
            ? window.localStorage.getItem("joynt.database")
            : null) || env("SERVICE_ID");

    return {
        joynt: {
            routePattern: "/:path?",
            params: {},
            router: "joynt",
            themeProps: {
                presetId: "joynt-website",
                className: "joynt-theme",
                apply: true,
                target: true,
                loadFonts: true,
                enable: false,
            },
            apiContext: { project: joyntDb },
            iconLibraries: ["material-ui"],
        },
        zuu: {
            routePattern: "/:lang/:service/:view?",
            params: { lang: "pl" },
            themeProps: {},
            iconLibraries: ["fh-default", "font-awesome", "material-ui"],
        },
        zuuConsole: {
            routePattern: "/console",
            params: {},
            themeProps: {},
        },
    };
};

export const hosts = {
    joynt: "joynt",
    "joynt-app": "joynt",
    "joint-app": "joynt",
    "joynt-dev": "joynt",
    localhost: "zuu",
};
