import useMeeting from "joynt/meeting/hooks/useMeeting";
import {SESSION_POLICY_MEETING} from "joynt/config";
import {useSessionState} from "joynt/meeting/hooks/useSessionState";
import {useEffect} from "react";
import {useRouteParam, useUiFlag} from "state/hooks";

export default function useAutoJoin(id) {
    const [forceJoin] = useRouteParam('join');
    const {isAllowedOnStage, event, policy} = useMeeting();
    const {isLive, setIsLive} = useSessionState(id);
    const autoJoin = policy === SESSION_POLICY_MEETING || forceJoin;
    const shouldJoin = (autoJoin || isAllowedOnStage) && !isLive;
    const [intent] = useUiFlag(`meeting.${id}.liveIntent`);

    useEffect(() => {
        if (!isAllowedOnStage && isLive) {
            setIsLive(false, 'not-allowed');
        }
    }, [event, isAllowedOnStage, isLive, setIsLive]);

    useEffect(() => {
        if (shouldJoin && intent !== false) {
            setIsLive(true, autoJoin ? 'auto' : 'role');
        }
    }, [intent, autoJoin, shouldJoin, setIsLive]);
}