import React from "react";
//import PropTypes from 'prop-types';
import { useThread } from "joynt/hooks";
import { useCurrentUser, useClick } from "state/hooks";
import cn from "classnames";
import { IdentityAvatarContainer as UserAvatar } from "joynt/components/Avatar";
import { MessageTime } from "components/DateDiff";
import Skeleton from "joynt/components/Skeleton";
import { useNotificationCount } from "joynt/hooks/notifications";
import { useData } from "state/hooks";
import NodeLink from "joynt/components/Post/NodeLink";
import { renderMentions } from "joynt/components/messages/index";

export default function ThreadContainer(props) {
    const { id } = props;

    const threadProps = useThread(id);
    const onClick = useClick(props);

    return <Thread {...props} {...threadProps} onClick={onClick} />;
}

function MessageSummary({ id }) {
    const { content, identity, media, link } = useData({
        type: "db.messages",
        id,
    });
    const { name, created_by } = useData({
        type: "db.identities",
        id: identity,
    });
    const user = useCurrentUser();
    const identityName = created_by === user ? "You" : name;
    if (link) return `${identityName} shared a link`;
    if (media && media.length) return `${identityName} shared an image`;
    if (!content) return null;
    return `${identityName}: ${renderMentions(content, true)}`;
}

function Thread(props) {
    const {
        onClick,
        name,
        genericName,
        identity,
        timestamp,
        selected,
        id,
        lastMessage,
        parent,
        parent_node,
    } = props;

    const [notifications] = useNotificationCount(id);

    const isSelected = selected === id;

    return (
        <div
            className={cn("thread", {
                active: isSelected,
                unread: notifications > 0,
            })}
            onClick={onClick}
        >
            <div className={"rows rows-left gap-xs"}>
                <div className={"cols"}>
                    <div className={"thread__unread"}></div>
                    <div className={"relative"}>
                        <UserAvatar id={identity} />
                    </div>
                </div>
            </div>
            <div className={"thread__content grow rows"}>
                <div className={"cols cols-spread cols-top gap-sm"}>
                    {name || genericName ? (
                        <div className={"thread__subject"}>
                            {name || genericName}
                        </div>
                    ) : (
                        <div
                            className={
                                "thread__subject list-item-title-placeholder"
                            }
                        >
                            {"No subject"}
                        </div>
                    )}
                    <div className={"thread__time"}>
                        <MessageTime value={timestamp} short={true} />
                    </div>
                </div>
                {lastMessage ? (
                    <div className={"thread__text"} data-private>
                        <MessageSummary id={lastMessage} />
                    </div>
                ) : null}
                <div className={"thread__signature"}>
                    in <NodeLink id={parent} data={parent_node} />
                </div>
            </div>
        </div>
    );
}

Thread.defaultProps = {
    post_types: [],
};

Thread.propTypes = {};

export function ThreadSkeleton(props) {
    return (
        <div className={"thread-skeleton"}>
            <Skeleton width={240} height={47}>
                <circle cx="20" cy="20" r="20" />
                <rect x="56" y="0" rx="3" ry="3" width="200" height="10" />
                <rect x="56" y="20" rx="4" ry="4" width="120" height="13" />
            </Skeleton>
        </div>
    );
}
