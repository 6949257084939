import React from "react";
//import PropTypes from 'prop-types';
import Toolbar from "./Toolbar";
import { usePageUrl } from "playground/cms/state/hooks";
import { useCurrentService } from "playground/cms/hooks";
import { useHasScope } from "playground/console/UserAccess";
import { SCOPE_WEBSITES_DEV } from "config/access-scopes";

export function ToolbarContainer(props) {
    const { state } = props;

    const url = usePageUrl(state.pageType, state.pageId);
    const serviceId = useCurrentService();
    const devAccess = useHasScope(SCOPE_WEBSITES_DEV);

    return (
        <Toolbar
            {...props}
            pageUrl={url}
            serviceId={serviceId?.id}
            devAccess={devAccess}
        />
    );
}

ToolbarContainer.propTypes = {};

ToolbarContainer.defaultProps = {};

export default ToolbarContainer;
