import { useProfilePopover } from "joynt/participants";
import useChat from "joynt/participants/hooks/useChat";
import { useCallback } from "react";
import useTouchInteraction from "util/hooks/useTouchInteraction";
import { useIdentity } from "joynt/hooks/participants";

export default function useProfileInteraction(workspace, id) {
    const { onShow: onShowProfile, onHide } = useProfilePopover();
    const { onShow: onClick } = useChat(workspace, id);
    const { isOwn } = useIdentity(id);

    const mouseOver = useCallback(
        (e) => {
            onShowProfile(id, e.currentTarget);
        },
        [onShowProfile, id]
    );

    const click = useCallback(() => {
        if (!isOwn) onClick();
        onHide();
    }, [onClick, onHide, isOwn]);

    return useTouchInteraction(mouseOver, onHide, click);
}
