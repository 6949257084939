import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import env from 'util/env';
import {applyPreset, compileStyles} from "cms/state/actions/theme";
import Fetch from "containers/Fetch";
import {selectMuiTheme} from "cms/state/selectors/theme";
import {ThemeProvider} from '@material-ui/styles';
import {selectAliasedEntity} from "state/selectors/data";
import {Helmet} from "react-helmet";
import {changed} from "util/changed";

const mapStateToProps = (store, props) => {
    const {className} = props;
    const alias = props.alias || '@currentPreset';
    const apply = props.apply || (alias === '@currentPreset');
    const enable = props.enable !== false;
    const muiTheme = apply ? selectMuiTheme(store, alias, props.dark) : null;
    const userSettings = selectAliasedEntity(
        store,
        'console.user_settings',
        '@currentUser'
    );
    const { userPreset } = props;
    const variantClass = muiTheme
        ? 'theme-' + muiTheme.palette.type + ' default'
        : 'default';

    const p = {
        enable,
        alias,
        apply,
        muiTheme,
        bodyClass: apply ? cn(variantClass, className) : null,
        preset: selectAliasedEntity(store, 'console.presets', alias)
    };
    if (userPreset && apply && userSettings.theme_preset) {
        //p.presetId = userSettings.theme_preset;
        //p.preset = selectEntity(store, 'console.presets', p.presetId);
    }

    return p;
};

const mapDispatchToProps = {
    applyPreset: applyPreset,
    compileStyles: compileStyles
};

class Theme extends React.PureComponent {
    componentDidMount() {
        const { preset } = this.props;
        if (preset.id) {
            this.apply();
        }
    }

    componentDidUpdate(prevProps) {
        const { preset } = this.props;
        if (preset.id && changed(['preset', 'dark', 'alias'], this.props, prevProps)) {
            this.apply();
        }
    }

    apply = () => {
        const { preset, target, loadFonts, applyPreset, dark } = this.props;
        applyPreset(preset.id, target, loadFonts, dark);
    }

    render() {
        const {
            children,
            presetId,
            bodyClass,
            muiTheme,
            apply,
            alias,
            enable
        } = this.props;

        return (<Fetch
                type={'console.presets'}
                id={presetId}
                alias={alias}
                url={'console/presets/:id'}
                enable={!!enable}
                useContext={false}
            >
            { apply ? <Helmet bodyAttributes={{
                class: bodyClass
            }}>
                <link type="text/css" rel="stylesheet" href="https://cdn.joynt.works/atoms/style.min.css" />
            </Helmet> : null }
            { apply && muiTheme && children ? <ThemeProvider theme={muiTheme}>
                {children}
            </ThemeProvider> : children }
        </Fetch>);
    }

}

Theme.defaultProps = {
    presetId: env('ZUU_CONSOLE_PRESET'),
    enable: true
};

const ConnectedTheme = connect(
    mapStateToProps,
    mapDispatchToProps
)(Theme);

export default ConnectedTheme;