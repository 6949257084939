import React from 'react';
//import PropTypes from 'prop-types';
import EntityContext from "containers/context/EntityContext";
import Field from 'containers/Field';
import {FILTER_NS} from "config/list-aliases";
import {useField} from "state/hooks";

export default function SectionFilter(props) {

    const { id, service, library } = props;
    let path = [FILTER_NS, id].join('/');

    const {onChange} = useField(FILTER_NS, id, 'library');

    return (<EntityContext.Provider value={path}>
        <div className={'rows gap-xs pad-sm'}>
            <div className={'cols gap-xs'}>
                <div onClick={()=>onChange(service)}>
                    {service}
                </div>
                <div onClick={()=>onChange(library)}>
                    library
                </div>
            </div>
            <div className={'cols cols-left gap-sm cols-middle'}>
                <Field
                    id={'search'}
                    fieldType={'text'}
                    variant={'dense'}
                    label={'Szukaj...'}
                    nullable={true}
                />
                <Field
                    id={'type'}
                    fieldType={'select'}
                    source={'schema:types.sections'}
                    variant={'dense'}
                    label={'Typ sekcji'}
                    nullable={true}
                />
                <Field
                    id={'cms_tags'}
                    fieldType={'select'}
                    source={'api:tags.cms'}
                    variant={'dense'}
                    label={'Tagi'}
                    nullable={true}
                />
            </div>
        </div>
    </EntityContext.Provider>);

}

SectionFilter.propTypes = {};