import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import List from "containers/List";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import ResourceItem from "components/user-menu/ResourceItem";
import resourceIcons from 'config/icons';
import {ListItem} from "components/list/items/ListItem";

export const ResourceLabeledIterator = ArrayIterator(withData(({data,type,id,active,onClick}) => {
    let resourceId = data.path;
    const isActive = active === resourceId;
    const handleClick = useCallback(
        ()=>onClick(resourceId),
        [resourceId, onClick]
    );
    const icon = data.icon || resourceIcons[resourceId];
    return <ResourceItem
        {...data}
        icon={icon}
        onClick={handleClick}
        isActive={isActive}
    />
}));

export const ResourceListIterator = ArrayIterator(withData((props) => {
    return <ListItem
        {...props}
    />
}));

export const classes = {
    list: 'rows',
    default: 'pad-sm phone@pad-sm grid grid-4 phone@grid-3'
};

export default class UserResources extends React.PureComponent {

    render() {
        const { onClick, items, active, filter, variant } = this.props;
        return (<>
            <div className={classes[variant] || classes.default}>
                <List onClick={onClick}
                  type={'app.user-menu'}
                  active={active}
                  items={items}
                  filter={filter}
                  Iterator={variant === 'list'
                      ? ResourceListIterator
                      : ResourceLabeledIterator
                  }
                />
            </div>
        </>);
    }

}

UserResources.propTypes = {
    active: PropTypes.string,
    items: PropTypes.array,
    onClick: PropTypes.func
};