import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
import { useData } from "state/hooks";
import ContextMenu from "components/ContextMenu";
import ContextMenuItems from "playground/cms/views/Resources/ListItem/ContextMenuItems";
import IconButton from "components/IconButton";

function AccessItem(props) {
    const data = useData(props);
    const user = useData({ type: "console.users", id: data.user });
    return (
        <ContextMenu
            type={props.type}
            id={props.id}
            RenderMenuItems={ContextMenuItems}
        >
            {({ onMenuOpen }) => (
                <div
                    className={"cols cols-spread cols-middle gap-sm"}
                    onContextMenu={onMenuOpen}
                >
                    <div>
                        <div>{user.name || data.invite_email}</div>
                    </div>
                    <div>{!data.accepted && "pending"}</div>
                    <div>
                        <IconButton
                            fontSize={"small"}
                            icon={"mui-more_horiz"}
                            onClick={onMenuOpen}
                        />
                    </div>
                </div>
            )}
        </ContextMenu>
    );
}

AccessItem.defaultProps = {
    type: "console.user-service-access",
};

export function UserAccess(props) {
    const { email, setEmail, onSubmit, items } = props;
    return (
        <FormLayout.Fieldset pad>
            <div className={"rows gap-lg"}>
                <div className={"cols gap-xs"}>
                    <TextField
                        value={email || ""}
                        onChange={setEmail}
                        label={"Invite email"}
                    />
                    <Button onClick={onSubmit}>Invite</Button>
                </div>
                <div className={"rows gap-sm"}>
                    {items.map((item) => (
                        <AccessItem key={item} id={item} />
                    ))}
                </div>
            </div>
        </FormLayout.Fieldset>
    );
}

UserAccess.propTypes = {};

UserAccess.defaultProps = {
    items: [],
};

export default UserAccess;
