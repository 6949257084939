import React, { useCallback } from "react";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import GiphyPicker from "./GiphyPicker";
import { Picker as EmojiPicker } from "emoji-mart";
//import PropTypes from 'prop-types';

import "emoji-mart/css/emoji-mart.css";
import UploadButton from "components/upload/UploadButton";

export default function MessageInputIcons(props) {
  const { onAddLink, onUpload, value, onChange } = props;

  const addGif = useCallback(
    (gifUrl) => {
      onAddLink(gifUrl);
    },
    [onAddLink]
  );

  const addEmoji = useCallback(
    (emoji) => {
      onChange(`${value} ${emoji.native}`);
    },
    [value, onChange]
  );

  return (
    <div className={"cols cols-middle grow"}>
      {onUpload ? (
        <UploadButton
          onUpload={onUpload}
          trigger={
            <IconButton
              size={"small"}
              icon={"mui-attach_file"}
              onClick={() => null}
            />
          }
        />
      ) : null}
      {onAddLink ? (
        <Popover
          trigger={({ onClick }) => (
            <IconButton size={"small"} icon={"mui-gif"} onClick={onClick} />
          )}
          content={({ onClose }) => (
            <GiphyPicker
              onSelect={(e) => {
                addGif(e);
                onClose();
              }}
            />
          )}
        />
      ) : null}
      <Popover
        trigger={({ onClick }) => (
          <IconButton
            size={"small"}
            icon={"mui-emoji_emotions"}
            onClick={onClick}
          />
        )}
        content={({ onClose }) => (
          <EmojiPicker
            emojiTooltip={true}
            onSelect={(e) => {
              addEmoji(e);
              onClose();
            }}
            showPreview={false}
            showSkinTones={false}
          />
        )}
      />
    </div>
  );
}

MessageInputIcons.propTypes = {};
MessageInputIcons.defaultProps = {};
