import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import ProfileSettings from "./ProfileSettings";
import AggregateRoot from "containers/AggregateRoot";
import {useForm} from "joynt/hooks";
import {usePending} from "state/hooks";

export function ProfileSettingsContainer(props) {

    const type = "db.identities";

    const {
        id,
        onChangeView
    } = props;

    const close = useCallback(() => onChangeView(null), [onChangeView]);

    const {
        onSubmit,
        onReject,
        isDraft,
        isValid
    } = useForm(type, id, close);

    const isPending = usePending(id);

    return (<AggregateRoot
        type={type}
        id={id}
        validate={true}
        disableSubmit={true}
    >
        <ProfileSettings
            {...props}
            onSubmit={onSubmit}
            isPending={isPending}
            isDraft={isDraft}
            isValid={isValid}
            onReject={onReject}
        />
    </AggregateRoot>);

}

ProfileSettingsContainer.propTypes = {
    id: PropTypes.string.isRequired
};
ProfileSettingsContainer.defaultProps = {};

export default ProfileSettingsContainer;