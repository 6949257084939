import React from "react";
import useScreenSize from "util/hooks/useScreenSize";
import Drawer from "@material-ui/core/Drawer";
import Dialog from "@material-ui/core/Dialog";

//import PropTypes from 'prop-types';

export function ResponsiveDialog(props) {
    const { isMobile } = useScreenSize();

    if (isMobile) return <Drawer anchor={"bottom"} {...props} />;
    return <Dialog {...props} />;
}

ResponsiveDialog.propTypes = {};
ResponsiveDialog.defaultProps = {};

export default ResponsiveDialog;
