import React from 'react';
import {useNodeData} from "joynt/hooks";
import {usePost} from "joynt/components/Post/hooks";
import {POST_TYPE_LINK, POST_TYPE_WHITEBOARD} from "joynt/config";
import {useSelector} from "react-redux";
import {Embed} from "joynt/views/External";
import Fetch from "containers/Fetch";
import {usePresentation} from "joynt/meeting/presentation/usePresentation";
import Button from "components/Button";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

function selectLinks(s, id) {
    const {posts} = s.data['db.nodes'][id] || {};
    const allPosts = s.data['db.posts'] || {};
    const links = posts ? posts.filter(p => {
        return allPosts[p] && [POST_TYPE_LINK, POST_TYPE_WHITEBOARD].indexOf(allPosts[p].subtype) > -1;
    }) : [];
    return links;
}

function RenderPresentation({id}) {
    const {onEndPresentation, pending} = usePresentation();
    const {name} = useNodeData(id);
    const links = useSelector(s=>selectLinks(s, id)) || [];
    const post = usePost(links[0]);

    return <div className={'default rows fill'}>
        <Preloader visible={pending} />
        <div className={'cols gap-sm pad-sm-h cols-middle'}>
            <div>{name}</div>
            <Button onClick={onEndPresentation} variant={'text'}>
                End presentation
            </Button>
        </div>
        <div className={'grow'}>
            { post && post.link ? <Embed url={post.link} /> : null }
        </div>
    </div>
}

export function PresentationView(props) {

    const {id} = props;

    return (<Fetch
        type={'db.nodes'}
        id={id}
    >
        <RenderPresentation id={id} />
    </Fetch>);

}

PresentationView.propTypes = {};
PresentationView.defaultProps = {};
