import React from "react";
//import PropTypes from 'prop-types';
import SectionsStack from "./SectionsStack";
import { selectEntity } from "state/selectors/data";
import { useSelector } from "react-redux";

function selectSections(store, id) {
    const stack = [id];
    const data = selectEntity(store, "cms.sections", id);
    const templateData = selectEntity(store, "cms.sections", data.template);
    if (data.template) stack.push(data.template);
    if (templateData.reference_id) stack.push(templateData.reference_id);
    return stack;
}

export function SectionsStackContainer(props) {
    const { id } = props;
    const stack = useSelector((store) => selectSections(store, id));

    return <SectionsStack {...props} items={stack} />;
}

SectionsStackContainer.propTypes = {};

SectionsStackContainer.defaultProps = {};

export default SectionsStackContainer;
