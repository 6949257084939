import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import DashboardTile from "./../DashboardTile";
import Button from "components/Button";
import Icon from "components/Icon";

export function CreateTask(props) {
    const { title, buttonLabel, onClick } = props;

    return (
        <DashboardTile color={"dark"} className={"witness-create-task"}>
            <div className={"witness-create-task__title"}>{title}</div>
            <div className={"witness-create-task__button"}>
                <div className={"witness-create-task__img"}></div>
                {onClick && (
                    <Button onClick={onClick} startIcon={<Icon>mui-add</Icon>}>
                        {buttonLabel}
                    </Button>
                )}
            </div>
        </DashboardTile>
    );
}

CreateTask.propTypes = {
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func,
};
CreateTask.defaultProps = {
    title: "Go on, do stuff and gain badges!",
    buttonLabel: "New Witness Task",
};

export default CreateTask;
