import React from "react";
import cn from "classnames";

import Html from "components/Html";
import { renderMentions } from "joynt/components/messages/messageUtils";
import ContextMenu from "components/ContextMenu";
import MessageContextMenu from "joynt/components/messages/message/MessageContextMenu";
import IconButton from "components/IconButton";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";
import { MessageBodyDeleted } from "./index";

import "./style.css";

export function MessageBodyBubble(props) {
    const {
        addReactions,
        content,
        files,
        id,
        isDeleted,
        isOwn,
        link,
        messageClick,
        onHide,
        className,
    } = props;

    if (isDeleted) return <MessageBodyDeleted />;

    return (
        <section
            data-private
            className={cn(
                "message__body",
                `message__body--bubble`,
                className,
                isOwn && "message__body--own"
            )}
        >
            <div className={"message__content"}>
                {content && (
                    <Html
                        onClick={messageClick}
                        onMouseLeave={onHide}
                        className={"message__text"}
                        value={renderMentions(content)}
                    />
                )}
                {link && <div className={cn("message__link")}>{link}</div>}
                {files && <div className={"message__files"}>{files}</div>}
            </div>
            <div className={"message__content-menu"}>
                {addReactions}
                {isOwn && (
                    <div className={"message__context-menu"}>
                        <ContextMenu
                            id={id}
                            RenderMenuItems={MessageContextMenu}
                        >
                            {({ onMenuOpen }) => (
                                <IconButton
                                    icon={"mui-more_vert"}
                                    onClick={onMenuOpen}
                                    size={"small"}
                                />
                            )}
                        </ContextMenu>
                    </div>
                )}
            </div>
        </section>
    );
}

MessageBodyBubble.propTypes = {
    addReactions: MessagePropTypes.addReactions,
    content: MessagePropTypes.content,
    files: MessagePropTypes.files,
    id: MessagePropTypes.id,
    isDeleted: MessagePropTypes.isDeleted,
    isOwn: MessagePropTypes.isOwn,
    link: MessagePropTypes.link,
    messageClick: MessagePropTypes.messageClick,
    onHide: MessagePropTypes.onHide,
    className: MessagePropTypes.className,
};

export default MessageBodyBubble;
