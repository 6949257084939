import React, { useState } from "react";
//import PropTypes from 'prop-types';
import TextField from "components/fields/text/TextField";
import Dialog from "@material-ui/core/Dialog";
import Map from "components/fields/geolocation/Map";

import "./style.css";
import Icon from "components/Icon";

const normalizeLatLng = (latLng = {}) => {
    return {
        lat: latLng ? latLng.lat * 1 || 0 : 0,
        lng: latLng ? latLng.lng * 1 || 0 : 0,
    };
};

const MapsImg = (props) => {
    let key = "AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc"; //window.GOOGLE_API_KEY;
    const { lat, lng } = props;
    if (!lat || !lng) return null;
    let src =
        `https://maps.googleapis.com/maps/api/staticmap` +
        `?center=${lat},${lng}&zoom=15` +
        `&markers=color:red%7Clabel:A%7C${lat},${lng}` +
        `&size=120x120&key=${key}`;
    return <img style={{ width: "100%" }} src={src} alt="map" />;
};

function DefaultLocationComponent(props) {
    const { value, onChange, onSearch } = props;
    return (
        <div className={"cols gap-md"}>
            <div onClick={onSearch} className={"geolocation-preview"}>
                {value.lat && value.lng ? (
                    <MapsImg lat={value.lat} lng={value.lng} />
                ) : (
                    <div className={"rows rows-center gap-xs"}>
                        <Icon>mui-room</Icon>
                        <div className={"geolocation-preview-label"}>
                            Otwórz mapę
                        </div>
                    </div>
                )}
            </div>
            <div className={"rows gap-sm"}>
                <TextField
                    label={"Lat"}
                    onChange={(val) => onChange("lat", val)}
                    value={value.lat || ""}
                />
                <TextField
                    label={"Lng"}
                    onChange={(val) => onChange("lng", val)}
                    value={value.lng || ""}
                />
            </div>
        </div>
    );
}

export default function GeolocationField(props) {
    const { onChange, value } = props;
    const [open, setOpen] = useState(false);

    const change = (id, value) => {
        const { onChange } = props;
        const prevValue = props.value || {};
        const nextValue = { ...prevValue };
        nextValue[id] = value;
        console.log(prevValue, nextValue);
        onChange(nextValue);
    };

    const marker = { position: normalizeLatLng(value) };
    const GeoLocationComponent = props.GeoLocationComponent;

    return (
        <>
            <GeoLocationComponent
                value={value}
                onChange={change}
                onSearch={() => setOpen(true)}
            />
            <Dialog open={open} onClose={() => setOpen(false)}>
                {open && <Map marker={marker} setGeolocation={onChange} />}
            </Dialog>
        </>
    );
}

GeolocationField.defaultProps = {
    GeoLocationComponent: DefaultLocationComponent,
    value: {},
};

GeolocationField.propTypes = {};
