import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import cn from "classnames";
import { LinearProgress } from "@material-ui/core";

export default class Preloader extends React.PureComponent {
    render() {
        let {
            progress,
            text,
            visible,
            className,
            noOverlay,
            variant,
            ...other
        } = this.props;
        let mode = progress > 1 && progress < 100 ? "static" : "indeterminate";
        return (
            <div
                className={cn(className, "ui-preloader", {
                    "default-f50": !noOverlay,
                    "is-visible": visible,
                    "ui-preloader--linear": variant === "linear",
                })}
            >
                <div>
                    <div className={"ui-preloader__content"}>
                        {this.props.children}
                    </div>
                    <div
                        className={
                            "ui-preloader__indicator rows rows-center gap-xs"
                        }
                    >
                        {variant === "linear" ? (
                            <LinearProgress />
                        ) : (
                            <CircularProgress
                                variant={mode}
                                value={progress}
                                thickness={3}
                                {...other}
                            />
                        )}
                        {text ? (
                            <div className={"type-default o-50"}>{text}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
