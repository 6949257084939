import React from "react";
//import PropTypes from 'prop-types';
import Field from "containers/Field";

export function StaticLink() {
    return (
        <>
            <Field
                id={"link_action"}
                label={"Link action"}
                fieldType={"select"}
                source={"schema:data.buttonActions"}
            />
            <Field id={"link"} label={"Link"} />
            <Field id={"link_anchor"} label={"Link anchor"} />
            <Field id={"link_label"} label={"Link label"} />
        </>
    );
}

StaticLink.propTypes = {};

StaticLink.defaultProps = {};

export default StaticLink;
