import React from "react";
import { useNode } from "joynt/hooks";
import { types as nodeTypes } from "joynt/config/types";
import { useRoleGroups } from "joynt/components/Roles/hooks";
import AvatarGroup from "joynt/components/AvatarGroup/AvatarGroup.container";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import NodeAvatar from "joynt/components/Roles/NodeAccess/NodeAvatar";
//import PropTypes from 'prop-types';

import "./style.css";
import Preloader from "components/Preloader";
import IconButton from "components/IconButton";

function RolesCount({ value }) {
    if (value < 2) return <strong>Only you</strong>;
    return <strong>{`You and ${value - 1} people`}</strong>;
}

function InheritedRoles(props) {
    const { id, showOnly } = props;
    const isDraft = useSelector((s) => selectIsDraft(s, "db.nodes", id));
    const list = isDraft ? `node-roles.${id}` : `node-roles.${id}`;
    const groups = useRoleGroups(id, list, "allCurrentGroups");

    const selectedGroups = showOnly
        ? groups.filter((g) => showOnly.indexOf(g.id) > -1)
        : groups;

    return (
        <div className={"roles-group-avatars"}>
            {selectedGroups.map((group) => (
                <div key={group.id} className={"roles-group-avatars__group"}>
                    <div className={"roles-group-avatars__name"}>
                        {group.label}
                    </div>
                    <AvatarGroup ids={group.items} />
                </div>
            ))}
        </div>
    );
}

function ParentNode(props) {
    const { id } = props;

    const { name, subtype } = useNode(id);
    let nodeName = name ? name : `New ${nodeTypes[subtype]?.label}`;

    return (
        <div className={"cols gap-xs"}>
            <NodeAvatar size={"xs"} id={id} />
            <div>{nodeName}</div>
        </div>
    );
}

export function NodeAccess(props) {
    const { id, node, rolesCount, isPending, onShowSettings } = props;

    return (
        <div className={"node-access"}>
            <div className={"cols cols-spread cols-top"}>
                <div className={"rows gap-sm"}>
                    <div className={"rows"}>
                        <ParentNode id={node} />
                        <RolesCount value={rolesCount} />
                    </div>
                    <InheritedRoles id={id} showOnly={["admin"]} />
                </div>
                <IconButton
                    onClick={onShowSettings}
                    icon={"mui-settings"}
                    size={"small"}
                />
            </div>
            <Preloader visible={isPending} />
        </div>
    );
}

NodeAccess.propTypes = {};
NodeAccess.defaultProps = {};

export default NodeAccess;
