import { BlockMapBuilder, EditorState } from "draft-js";
import { moveFocusToBlockEnd } from "joynt/components/ContentEditor/util/moveFocusToBlockEnd";

export function moveBlock(editorState, block, before) {
    let nextState = editorState;
    let contentState = editorState.getCurrentContent();

    let blocks = contentState.getBlocksAsArray();
    let keys = blocks.map((block) => block.getKey());
    let blockIndex = keys.indexOf(block);
    let newBlocks = [];
    let movedBlock = blocks[blockIndex];

    blocks.forEach((contentBlock) => {
        if (contentBlock.getKey() === before) {
            newBlocks.push(movedBlock);
        }
        if (contentBlock.getKey() !== block) {
            newBlocks.push(contentBlock);
        }
    });

    contentState = contentState.merge({
        blockMap: BlockMapBuilder.createFromArray(newBlocks),
    });

    nextState = EditorState.push(nextState, contentState, "move-block");
    nextState = moveFocusToBlockEnd(nextState, movedBlock);

    return nextState;
}
