export function getDaysInMonthUTC(month, year) {
    const date = new Date(Date.UTC(year, month, 1));
    const days = [];
    while (date.getUTCMonth() === month) {
        let newDate = new Date(date);
        newDate.setHours(0,0,0,0);
        days.push(newDate);
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}