import PublishSession from "./PublishSession";
import UnpublishSession from "./UnpublishSession";
import CreatePublishedSession from "./CreatePublishedSession";
import SessionPublishStatus from "./SessionPublishStatus";

export default {
    "session-publish": PublishSession,
    "session-unpublish": UnpublishSession,
    "session-create-publish": CreatePublishedSession,
    "session-publish-status": SessionPublishStatus,
};
