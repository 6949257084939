import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Popover from "components/Popover";
import SelectValue from "joynt/components/Select/SelectValue/SelectValue";
import SelectListItem from "joynt/components/Select/SelectList/SelectListItem/SelectListItem";
import Icon from "components/Icon";

function RenderSelectList(props) {
    const { onClose, onSelect, items } = props;

    const select = useCallback(
        (value) => {
            onSelect(value);
            onClose();
        },
        [onSelect, onClose]
    );

    return (
        <div className={"select-list"}>
            <div className={"select-options"}>
                {items.map((item) => {
                    return (
                        <SelectListItem
                            key={item}
                            onClick={() => select(item.id)}
                            label={item.label}
                            icon={item.icon}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export function OptionSelect(props) {
    const { options, value, label, onChange } = props;

    const current = options.find((o) => o.id === value) || {};

    return (
        <Popover
            trigger={({ onClick }) => (
                <div onClick={onClick} className={"select-input"}>
                    <SelectValue
                        value={current?.label}
                        label={label}
                        icon={current?.icon}
                    />
                    <div className={"select-input__icon"}>
                        <Icon>mui-arrow_drop_down</Icon>
                    </div>
                </div>
            )}
            content={({ onClose }) => (
                <RenderSelectList
                    onClose={onClose}
                    onSelect={onChange}
                    items={options}
                    value={value}
                />
            )}
        />
    );
}

OptionSelect.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
};
OptionSelect.defaultProps = {
    options: [],
};

export default OptionSelect;
