import React from 'react';
//import PropTypes from 'prop-types';
import cn from 'classnames';

export default class FormInput extends React.PureComponent {

    render() {
        const {
            inspectId,
            input_type,
            input_name,
            input_value,
            input_label,
            placeholder,
            classes,
            items
        } = this.props;

        const componentProps = {
            'data-inspect': inspectId,
            'data-type': 'form-input',
            'data-s': 'input'
        };

        if (input_type === 'select')
            return <select
                className={classes.input}
                {...componentProps}
            >
                {items ? items.map(id=>{
                    return <option value={id}>
                        {id}
                    </option>;
                }) : null}
            </select>;

        if (input_type === 'checkbox')
            return <div
                className={'Checkbox'}
                {...componentProps}
            >
                <input value={input_value} type={'checkbox'} />
                <label className={'Checkbox__label'}>
                    <i className={'fh fh-check-thin Checkbox__check'} />
                    {input_label}
                </label>
            </div>;

        if (input_type === 'text')
            return <input
                type={'text'}
                name={input_name}
                className={cn('input--text',classes.input)}
                placeholder={placeholder}
                {...componentProps}
            />;

        if (input_type === 'textarea')
            return <textarea
                name={input_name}
                className={cn('input--textarea',classes.input)}
                placeholder={placeholder}
                {...componentProps}
            />;

        return <div className={classes.input}>
            input:{input_type}
        </div>;
    }

}

FormInput.autoFillProps = [
    'input_type',
    'input_value',
    'input_label',
    'input_name'
];

FormInput.propTypes = {};