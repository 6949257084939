import React, { useCallback } from "react";
import NetworkingTable from "./NetworkingTable";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import { useEditPage } from "joynt/hooks/channels";
import { DEFAULT_NETWORKING_TABLE_LIMIT } from "joynt/config";

import PropTypes from "prop-types";
import IconButton from "components/IconButton";
import ContextMenu from "components/ContextMenu";
import MenuItem from "components/context-menu/MenuItem";

function tableStatus({
    isOpen,
    isConnected,
    isPending,
    isPendingAny,
    maxParticipants,
    participants,
    isAdmin,
}) {
    if (isPending && !isConnected) return "disconnecting";
    if (isPending) return "connecting";
    if (isConnected) return "connected";
    if (participants >= maxParticipants && !isAdmin) return "full";
    if (!isOpen) return "closed";
    if (!isConnected && isPendingAny) return "lock";
    return "open";
}

function useTableStatus(props) {
    const { id } = props;
    const { session } = useMeetingSession();
    const [{ loading: isPending }] = useConnectionState(id);
    const [{ loading: isPendingAny }] = useConnectionState(session);
    const { can_edit } = useNodeData(id);

    const isConnected = session === id;
    return tableStatus({
        ...props,
        isPending,
        isPendingAny,
        isConnected,
        isAdmin: can_edit,
    });
}

const buttonLabels = {
    disconnecting: "Leaving...",
    connecting: "Joining...",
    connected: "Leave",
    closed: "Closed",
    open: "Join",
    lock: "Join",
    full: "All spots taken",
};

function AdminActions(props) {
    const { id } = props;
    const onEdit = useEditPage();
    const { session_chat: chat } = useEdgesData(id);

    const items = [];

    items.push(
        <MenuItem
            key={"table"}
            icon={"mui-settings"}
            onClick={() => onEdit(id)}
            primaryText={"Table settings"}
        />
    );
    if (chat)
        items.push(
            <MenuItem
                key={"table"}
                icon={"mui-chat"}
                onClick={() => onEdit(chat)}
                primaryText={"Chat settings"}
            />
        );

    return (
        <ContextMenu items={items}>
            {({ onMenuOpen }) => (
                <IconButton
                    icon={"mui-more_vert"}
                    size={"small"}
                    onClick={onMenuOpen}
                />
            )}
        </ContextMenu>
    );
}

function NetworkingTableContainer(props) {
    const {
        isOpen,
        onJoin,
        onLeave,
        id,
        maxParticipants: propsMaxParticipants,
    } = props;

    const { name, can_edit: canEdit, event_max_participants } = useNodeData(id);

    const maxParticipants = event_max_participants || propsMaxParticipants;

    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );

    const status = useTableStatus({
        id,
        isOpen,
        maxParticipants,
        participants: presence.length,
    });

    const buttonLabel = buttonLabels[status];

    const isDisabled = !["connected", "open"].includes(status);
    const isConnected = ["connected"].includes(status);
    const isPending = ["connecting", "disconnecting"].includes(status);

    const toggleConnected = useCallback(() => {
        if (status === "open") onJoin(id);
        if (status === "connected") onLeave();
    }, [onJoin, onLeave, id, status]);

    const handleButtonClick = !isDisabled ? toggleConnected : null;

    const actions = canEdit ? <AdminActions id={id} /> : null;

    return (
        <NetworkingTable
            {...{
                actions,
                id,
                name,
                presence,
                isPending,
                isDisabled,
                isConnected,
                handleButtonClick,
                buttonLabel,
                maxParticipants,
            }}
        />
    );
}

NetworkingTableContainer.propTypes = {
    maxParticipants: PropTypes.number,
};
NetworkingTableContainer.defaultProps = {
    maxParticipants: DEFAULT_NETWORKING_TABLE_LIMIT,
};

export default NetworkingTableContainer;
