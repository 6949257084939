import React from "react";
//import PropTypes from 'prop-types';
import { useData } from "state/hooks";
import { Property } from "./Property";
import Field from "containers/Field";
import DatePicker from "playground/cms/fields/DatePicker";
import cn from "classnames";
import Icon from "components/Icon";

function DateValue(props) {
    const { value, label, onClick, onClear } = props;
    return (
        <div
            onClick={onClick}
            className={cn("value-box", {
                "is-empty": !value,
            })}
        >
            {value || label}
            {value && (
                <div className={"remove-btn value-clear"} onClick={onClear}>
                    <Icon>mui-clear</Icon>
                </div>
            )}
        </div>
    );
}

export function DateRange(props) {
    const { date_from, date_to } = useData(props);
    return (
        <Property label={"Termin"} isEmpty={!date_from && !date_to}>
            <div className={"value-boxes"}>
                <Field
                    id={"date_from"}
                    fieldType={"date"}
                    label={"Od"}
                    variant={"dense"}
                    FieldComponent={DatePicker}
                    ValueComponent={DateValue}
                />
                <Field
                    id={"date_to"}
                    fieldType={"date"}
                    label={"Do"}
                    variant={"dense"}
                    FieldComponent={DatePicker}
                    ValueComponent={DateValue}
                />
            </div>
        </Property>
    );
}

DateRange.propTypes = {};

DateRange.defaultProps = {};

export default DateRange;
