import React from 'react';
import ProfileIcon from "./ProfileIcon";
import {IdentityAvatarContainer as Avatar} from "joynt/components/Avatar";
import {useUserSettings} from "joynt/components/UserSettings";

//import PropTypes from 'prop-types';

export function ProfileIconContainer(props) {

    const {id} = props;
    const {id: profileId, identity, onToggle: onClick} = useUserSettings(id);

    return (<ProfileIcon
        {...props}
        avatar={<Avatar id={identity} />}
        id={identity}
        onClick={onClick}
        isActive={!!profileId}
    />);

}

ProfileIconContainer.propTypes = {};
ProfileIconContainer.defaultProps = {};

export default ProfileIconContainer;