import React from "react";
//import PropTypes from 'prop-types';

import {useNodeData} from "joynt/hooks";
import { usePending } from "state/hooks";
import Preloader from "components/Preloader";
import AggregateRoot from "containers/AggregateRoot";
import { SessionType } from "joynt/edit/types/fields";
import CloudRecording from "joynt/sidebar/CloudRecording";
import EventFlow from "joynt/sidebar/EventFlow";

export default function SessionSidebar(props) {
    const { id } = props;
    const { name, current_event } = useNodeData(id);
    const pending = usePending(id);

    return (
        <>
            <Preloader visible={pending} />
            <div className={"relative pad-sm rows gap-md"}>
                <div>{name}</div>
                <AggregateRoot type={"db.nodes"} id={id}>
                    <EventFlow id={id} />
                    <SessionType label={"Session profile"} layout={"rows"} />

                    <CloudRecording id={current_event || id} />
                </AggregateRoot>
            </div>
        </>
    );
}

SessionSidebar.propTypes = {};
SessionSidebar.defaultProps = {};
