import React, { useEffect, useRef, useState } from "react";
import { useActions, useUiFlag } from "state/hooks";
import TextField from "components/fields/text/TextField";
import { fileSearch } from "cms/state/actions/theme";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

//import PropTypes from 'prop-types';

export function FileSearch(props) {
    const { onShowFile } = props;

    const [search, setSearch] = useUiFlag("theme.fileManager.search", {});
    const [focus, setFocus] = useState(false);

    const { onSearch } = useActions({
        onSearch: fileSearch,
    });

    const blurTm = () => {
        setTimeout(() => setFocus(false), 200);
    };

    const ref = useRef();

    return (
        <>
            <div ref={ref}>
                <TextField
                    value={search.query}
                    onChange={(v) => onSearch(v)}
                    label="Search files"
                    variant={"search"}
                    onFocus={() => setFocus(true)}
                    onBlur={blurTm}
                    autoFocus={false}
                />
            </div>
            <Popper
                open={focus && search?.query}
                anchorEl={ref.current}
                placement={"bottom-start"}
                transition
                className={"file-search-results"}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                        <div>
                            {search?.result &&
                                search.result.map((f) => (
                                    <div
                                        onClick={() => onShowFile(f.id)}
                                        className={"file-search-result"}
                                    >
                                        L{f.line} {f.id}{" "}
                                        <pre>{f.context.trim()}</pre>
                                    </div>
                                ))}
                        </div>
                    </Fade>
                )}
            </Popper>
        </>
    );
}

FileSearch.propTypes = {};

FileSearch.defaultProps = {};

export default FileSearch;
