import { takeEvery, put, select, all, call } from "redux-saga/effects";
import {
    change,
    pathAppend,
    reset,
    storeList,
    storeOne,
} from "state/actions/data";
import { create } from "state/actions/create";
import { parseTemplateMapping } from "cms/util/templatable";
import uuid from "uuid/v4";
import { pushRouteParams } from "state/actions/router";
import {
    selectAllChanges,
    selectEntity,
    selectFieldValue,
} from "state/selectors/data";
import {
    post,
    put as apiPut,
    submitBatch,
    submitBatchCallback,
    submitOne,
} from "state/actions/api";
import { selectRouteParam } from "state/selectors/router";
import { listAppend } from "state/actions/list";
import { stylesHelper } from "cms/styles/legacy/selectors";
import CssClassNameBag from "cms/styles/legacy/php/CssClassNameBag";
import {
    fieldToType,
    selectComponentRoot,
    selectLibraryId,
    typeToField,
} from "cms/state/selectors/cms";
import { setFlag } from "state/actions/ui";
import { copySection } from "cms/state/sagas/drag-section";
import { createComponent, storeStyles } from "cms/state/actions/cms";
import { saveMedia } from "state/actions/media";
import { request } from "util/api/client";
import { confirm } from "util/saga/feedback";

function parseContext(context) {
    let contexts = context.split(":");
    let index = { all: [] };
    contexts.forEach((ctx) => {
        let [typeStr, id] = ctx.split("/");
        let [alias, type] = typeStr.split("@");
        if (!type) {
            type = alias;
            alias = null;
        }
        if (alias) index[alias] = [type, id];
        index.all.push([type, id]);
    });
    return index;
}

function matchContext(context, target) {
    let contexts = parseContext(context);
    let targetContext = contexts.all[contexts.all.length - 1];
    let [targetAlias, targetPath] = target.split("@");
    if (!targetPath) {
        targetPath = targetAlias;
        targetAlias = null;
    }
    if (targetAlias) targetContext = contexts[targetAlias];
    targetContext.push(targetPath);
    return targetContext;
}

/**
 * Dispatch change to entities following rewire context path
 *
 * @param action
 * @returns {IterableIterator<PutEffect<{payload, type}>>}
 */
function* handleChange(action) {
    try {
        const { payload } = action;
        const { type, id, update, rewire, rewireContext } = payload;

        let parsed = parseTemplateMapping(rewire, false);
        let updateKeys = Object.keys(update);

        for (let i = 0; i < updateKeys.length; i++) {
            let value = update[updateKeys[i]];
            let target = parsed[updateKeys[i]];
            if (target && rewireContext) {
                let [rewireType, rewireId, targetPath] = matchContext(
                    rewireContext,
                    target
                );
                let rewireUpdate = {};
                rewireUpdate[targetPath] = value;
                yield put(change(rewireType, rewireId, rewireUpdate));
            } else {
                let directUpdate = {};
                directUpdate[updateKeys[i]] = value;
                yield put(change(type, id, directUpdate));
            }
        }
    } catch (e) {
        throw e;
    }
}

function* handleMove({ payload }) {
    try {
        const { prev, next } = payload;
        if (prev.id !== next.id) {
            yield put(change(prev.type, prev.id, prev.update));
        }
        yield put(change(next.type, next.id, next.update));
    } catch (e) {
        console.log(e);
    }
}

function* handleCreate({ payload }) {
    try {
        const { type, id, key, item } = payload;
        const childKey = typeToField(type);
        const data = {
            id: uuid(),
            element_type: "structured",
            active: true,
            ...item,
            __parent: { id, key: childKey },
        };
        yield put(create("cms.components", data));
        yield put(pathAppend([type, id, key], data.id));
        yield put(
            pushRouteParams({ focus: ["cms.components", data.id].join("/") })
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleCopy({ payload }) {
    try {
        const { type, id } = payload;
        const path = [type, id].join("/");
        yield put(setFlag("clipboard", path));
    } catch (e) {
        console.log(e);
    }
}

function* handlePaste({ payload }) {
    try {
        const clipboard = yield select((store) => store.ui.clipboard);
        if (clipboard) {
            const { appendPath } = payload;
            const [appendType, appendId, appendField] = appendPath.split("/");
            const [type, id] = clipboard.split("/");
            const copied = yield copySection(type, id);
            copied.__parent = { id: appendId, type: fieldToType(appendField) };
            yield put(storeOne(type, copied.id, copied));
            let next = yield select((store) =>
                selectFieldValue(store, appendType, appendId, appendField)
            );
            next = next ? next.slice() : [];
            next.push(copied.id);
            let update = {};
            update[appendField] = next;
            yield put(change(appendType, appendId, update));
            yield put(pathAppend(appendPath.split("/"), copied.id));
            yield put(setFlag("clipboard", null));
        }
    } catch (e) {
        console.log(e);
    }
}

const aggregateRoots = [
    "cms.pages",
    "cms.sections",
    "cms.tags",
    "cms.navigation",
    "cms.entries.rooms",
    "cms.entries.offers",
    "cms.entries.projects",
    "cms.entries.posts",
    "cms.settings",
    "cms.layouts",
    "console.presets",
    "cms.styles",
    "theme.files",
    "cms.translations",
    "cms.value-store",
    "console.schema-config",
];

const pathSplitRegex = /(?<=^[^\/]*)\//;

function* handleSaveAll({ context, callback }) {
    try {
        const changes = yield select((store) => selectAllChanges(store));
        const paths = [];
        const types = [];
        for (let i = 0; i < changes.length; i++) {
            let path = changes[i];
            let [type, id] = path.split(pathSplitRegex);
            types.push(type);
            if (aggregateRoots.indexOf(type) > -1) {
                if (paths.indexOf(path) === -1) paths.push(path);
            } else {
                let root = yield select((store) =>
                    selectComponentRoot(store, type, id)
                );
                let rootData = root.id
                    ? yield select((store) =>
                          selectEntity(store, root.type, root.id)
                      )
                    : {};
                let data = yield select((store) =>
                    selectEntity(store, type, id)
                );
                if (
                    (root.id && aggregateRoots.indexOf(root.type) > -1) ||
                    rootData.symbol ||
                    data.symbol
                ) {
                    let rootPath = [root.type || type, root.id || id].join("/");
                    if (paths.indexOf(rootPath) === -1) paths.push(rootPath);
                }
            }
        }
        //let styles = yield select((store) => store.changes["cms.styles"]);

        yield put(
            submitBatchCallback(context)(
                callback,
                "cms/batch",
                paths,
                "cms.save",
                true
            )
        );
        if (types.indexOf("cms.media") > -1) {
            yield put(saveMedia(context)());
        }
        //if (styles) yield saveStyles({ context }, styles);
        //yield put(resetAll());
    } catch (e) {
        console.log(e);
    }
}

function* saveStyles({ context }, stylesData) {
    try {
        const changed = stylesData
            ? stylesData
            : yield select((store) => store.changes["cms.styles"]);
        const keys = Object.keys(changed);
        const submits = [];
        keys.forEach((id) => {
            submits.push(call(saveStyle, { context, payload: { id } }));
        });
        yield all(submits);
    } catch (e) {
        console.log(e);
    }
}

function* saveStyle({ context, payload }) {
    try {
        const { id } = payload;
        const style = yield select((store) =>
            selectEntity(store, "cms.styles", id)
        );
        const data = {
            id,
            styles: style,
        };
        const prev = yield select((store) => store.data["cms.styles"]);
        yield put(post(context)("cms/styles", data));
        let update = { ...prev };
        update[id] = style;
        yield put(storeList("cms.styles", null, update));
        yield put(reset("cms.styles", id));
    } catch (e) {
        console.log(e);
    }
}

export function makeMergeStyles(state) {
    let stylesApi = stylesHelper(state);
    let api = stylesApi.api();
    return (base = {}, extend = {}) => {
        let keys = Object.keys(base).concat(Object.keys(extend));
        const result = {};
        keys.forEach((key) => {
            let merge = new CssClassNameBag();
            merge = merge.extend(new CssClassNameBag(base[key]), api.options);
            if (extend && extend[key])
                merge = merge.extend(
                    new CssClassNameBag(extend[key]),
                    api.options
                );
            result[key] = merge.getClassNames();
        });
        return result;
    };
}

function* handleCreateStyleFrom({ context, payload }) {
    try {
        const { type, id, styleName } = payload;
        const data = yield select((store) => selectEntity(store, type, id));
        const { style: styleId, styles } = data;
        const parentStyle = yield select((store) =>
            selectEntity(store, "cms.styles", styleId)
        );
        const mergeFn = yield select(makeMergeStyles);
        const targetStyles = mergeFn(parentStyle, styles);

        // create styleName style from targetStyles
        yield put(change("cms.styles", styleName, targetStyles));
        yield put(change(type, id, { styles: null, style: styleName }));
    } catch (e) {
        console.error(e);
    }
}

function* handleInlineStyle({ context, payload }) {
    try {
        const { type, id } = payload;
        const data = yield select((store) => selectEntity(store, type, id));
        const { style: styleId, styles } = data;
        const parentStyle = yield select((store) =>
            selectEntity(store, "cms.styles", styleId)
        );
        const mergeFn = yield select(makeMergeStyles);
        const targetStyles = mergeFn(parentStyle || {}, styles || {});
        yield put(change(type, id, { styles: targetStyles, style: null }));
    } catch (e) {
        console.error(e);
    }
}

function* handleSaveToLibrary({ context, payload }) {
    try {
        const { type, id } = payload;
        const library = yield select(selectLibraryId);
        if (!library) {
            alert(`Library not connected to project`);
            return;
        }
        const copy = yield copySection(type, id);
        const symbol = { ...copy };
        if (type === "cms.components") symbol.symbol = true;
        const url = `cms/batch`;
        const data = {
            [type]: [symbol],
        };
        yield put(apiPut({ ...context, project: library })(url, data));
    } catch (e) {
        console.log(e);
    }
}

function* handleImportFromLibrary({ context, payload }) {
    try {
        const { type, id, targetType, targetId } = payload;
        //const library = yield select(selectLibraryId);
        const copy = yield copySection(type, id);
        const data = { ...copy, symbol: false };
        console.log(data);
        yield put(
            createComponent(targetType, targetId, typeToField(targetType), data)
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleToggleProperty({ context, payload }) {
    try {
        const { type, id, property } = payload;
        const data = yield select((s) => selectEntity(s, type, id));
        const update = { [property]: !data[property] };
        yield put(change(type, id, update));
        yield put(submitOne(context)(type, id));
    } catch (e) {
        console.error(e);
    }
}

function* handleFetchStyles({ context }) {
    try {
        const response = yield request({
            url: "theme/styles?all=true&def=true",
            context,
        });
        const data = response.data.data;
        yield put(storeStyles(data));
    } catch (e) {
        console.error(e);
    }
}

function* handleDeleteUnusedSections({ context }) {
    try {
        if (
            yield confirm(
                "Are you sure you want to delete all unused sections?"
            )
        ) {
            const response = yield request({
                url: "cms/sections/unused",
                method: "delete",
                context,
            });
        }
    } catch (e) {
        console.error(e);
    }
}

function* handleExportToLibrary({ payload: { id }, context }) {
    try {
        const response = yield request({
            url: `cms/sections/${id}/export`,
            method: "post",
            context,
        });
        const data = response.data.data;
        yield put(storeOne("cms.sections", data.id, data));
    } catch (e) {
        console.error(e);
    }
}

function* handleCreateTag({ payload, context, callback }) {
    try {
        const response = yield request({
            url: "cms/tags/cms",
            method: "post",
            data: payload.data,
            context,
        });
        const data = response.data.data;
        yield put(storeOne("cms.tags", data.id, data));
        yield put(listAppend("cms.tags", "tags.cms.sections", data.id));
        if (callback) callback(data);
        console.log(data);
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("CMS.COMPONENT.CHANGE", handleChange);
    yield takeEvery("CMS.COMPONENT.MOVE", handleMove);
    yield takeEvery("CMS.COMPONENT.CREATE", handleCreate);
    yield takeEvery("CMS.SAVE", handleSaveAll);
    yield takeEvery("CMS.STYLES.CREATE_FROM", handleCreateStyleFrom);
    yield takeEvery("CMS.STYLES.INLINE", handleInlineStyle);
    yield takeEvery("CMS.COMPONENT.COPY", handleCopy);
    yield takeEvery("CMS.COMPONENT.PASTE", handlePaste);
    yield takeEvery("CMS.LIBRARY.SAVE", handleSaveToLibrary);
    yield takeEvery("CMS.LIBRARY.IMPORT", handleImportFromLibrary);
    yield takeEvery("CMS.TOGGLE_PROPERTY", handleToggleProperty);
    yield takeEvery("CMS.STYLES.FETCH", handleFetchStyles);
    yield takeEvery("CMS.SECTIONS.DELETE_UNUSED", handleDeleteUnusedSections);
    yield takeEvery("CMS.LIBRARY.EXPORT", handleExportToLibrary);
    yield takeEvery("CMS.CREATE_TAG", handleCreateTag);
}
