import React from "react";
import TransferList from "playground/cms/fields/TransferList";
import Field from "containers/Field";

//import PropTypes from 'prop-types';

export function TagsField(props) {
    const { id, label, entityType, listId, ...rest } = props;
    return (
        <Field
            // key={`tags-${id}`}
            {...rest}
            id={id}
            fieldType={"select"}
            source={"api:tags"}
            label={label}
            nullable={true}
            multiple={true}
            taggable={true}
            targetEntityType={entityType}
            targetListId={listId}
            SelectMenuComponent={TransferList}
        />
    );
}

TagsField.propTypes = {};

TagsField.defaultProps = {
    id: "tags",
    label: "Tagi",
};

export default TagsField;
