import React, { useCallback } from "react";
import FileManager from "playground/theme/FileManager";
import { useCurrentService } from "playground/cms/hooks";
import { useActions, useApiActions, useData } from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field/Field";
import TypographyFonts from "playground/theme/TypographyFonts";
import ColorScheme from "playground/theme/ColorScheme";
import TypographyStyles from "playground/theme/TypographyStyles";
import { FormLayout } from "playground/cms/Form";
import { compileStyles } from "cms/state/actions/theme";
import Button from "components/Button";
import { buildTheme } from "playground/cms/state/actions/buildTheme";
import { ThemeGit } from "playground/console/views/Themes";

//import PropTypes from 'prop-types';

function ThemeTypography(props) {
    const { presetId } = props;

    return (
        <AggregateRoot
            type={"console.presets"}
            id={presetId}
            disableSubmit={true}
        >
            <div className={"pad-md"}>
                <Field
                    id={"typography"}
                    fieldType={"typography"}
                    FieldComponent={TypographyFonts}
                />
            </div>
            <Field
                id={"typography.styles"}
                fieldType={"type-styles"}
                FieldComponent={TypographyStyles}
            />
        </AggregateRoot>
    );
}

function ThemeColors(props) {
    const { presetId } = props;

    return (
        <AggregateRoot
            type={"console.presets"}
            id={presetId}
            disableSubmit={true}
        >
            <Field
                id={"color_scheme"}
                fieldType={"colors"}
                FieldComponent={ColorScheme}
            />
        </AggregateRoot>
    );
}

export function ThemeSettings(props) {
    const {} = props;

    const { theme: themeId } = useCurrentService();
    const theme = useData({ type: "console.themes", id: themeId });
    const { preset: presetId } = theme;
    const preset = useData({ type: "console.presets", id: presetId });

    const { onBuildStyles } = useActions({ onBuildStyles: compileStyles });
    const { onBuild } = useApiActions({ onBuild: buildTheme });

    const previewStyles = useCallback(() => {
        onBuildStyles(themeId);
    }, [onBuildStyles, themeId]);

    const runBuildTheme = useCallback(() => {
        onBuild(themeId);
    }, [onBuild, themeId]);

    return (
        <FormLayout className={"theme-dark"}>
            <FormLayout.Header>
                <div className={"cols cols-spread"}>
                    <FormLayout.Title>{theme.slug}</FormLayout.Title>
                    <div className={"cols gap-xs"}>
                        <Button onClick={runBuildTheme}>Build</Button>
                        <Button onClick={previewStyles}>Preview styles</Button>
                    </div>
                </div>
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                <FormLayout.Tab id={"files"} label={"Theme files"}>
                    <FileManager />
                </FormLayout.Tab>
                <FormLayout.Tab id={"colors"} label={"Colors"}>
                    <ThemeColors presetId={presetId} />
                </FormLayout.Tab>
                <FormLayout.Tab id={"typography"} label={"Typography"}>
                    <ThemeTypography presetId={presetId} />
                </FormLayout.Tab>
                <FormLayout.Tab id={"github"} label={"Github"}>
                    <ThemeGit id={themeId} />
                </FormLayout.Tab>
            </FormLayout.Content>
        </FormLayout>
    );
}

ThemeSettings.propTypes = {};

ThemeSettings.defaultProps = {};

export default ThemeSettings;
