import React from "react";
//import PropTypes from 'prop-types';
import {
    useData,
    useFetch,
    useList,
    useListReload,
    useRegisterList,
} from "state/hooks";
import { appendQueryParams } from "util/uri";
import { useSelector } from "react-redux";
import { selectList } from "state/selectors/data";

import BookingIntegration from "./BookingIntegration";

const supportedTypes = [
    "hotelsystems",
    "hotres",
    "profitroom",
    "nfhotel",
    "idosell",
];
const apiSupported = ["hotelsystems", "hotres", "profitroom", "nfhotel"];

function selectBeIntegration(store, list) {
    const integrations = selectList(store, "cms.integrations", list);
    return integrations.find((integration) =>
        supportedTypes.includes(integration.type)
    );
}

function parsePaste(str) {
    if (!str || typeof str !== "string") return str;
    if (str.includes(".idobooking.com")) {
        let regex = new RegExp(/ob\[([0-9]*)]/);
        let match = regex.exec(str);
        if (!match) return str;
        return match[1];
    }
    return str;
}

export function BookingIntegrationContainer(props) {
    const { type, id } = props;

    const { property, external_id: value } = useData(props);

    const url = appendQueryParams("cms/integrations", { property });
    const list = ["cms.integrations", property].filter(Boolean).join(".");

    const integrationsPending = useFetch({
        list,
        url,
        type: "cms.integrations",
        enable: true,
    });

    const integration = useSelector((store) =>
        selectBeIntegration(store, list)
    );

    const integrationId = integration?.id;

    const queryListId = integrationId;
    const queryList = [type, queryListId].join("/");
    const queryType = type;
    const entryType = queryType.replace("cms.entries.", "");
    const supportsApi =
        integration?.type && apiSupported.includes(integration?.type);
    const enableQuery = !!integrationId && supportsApi;

    const queryUrl = appendQueryParams("cms/query", {
        integration: integrationId || null,
        query_type: "entries",
        entry_type: entryType,
        list: queryList,
    });

    const queryUrlCached = appendQueryParams(queryUrl, { cached: true });

    const queryProps = {
        list: queryListId,
        type: queryType,
        url: queryUrl,
        enable: !!enableQuery,
    };

    const queryPending = useFetch({ ...queryProps, url: queryUrlCached });
    useRegisterList(queryProps);

    const { items } = useList(queryType, queryListId);

    const [onReload] = useListReload(queryType, queryListId);

    const isPending =
        integrationsPending || (integrationId && queryPending && supportsApi);
    const isValidValue = items.includes(value) || !items.length || !value;

    const handlePaste = (e) => {
        e.preventDefault();
        let str = (e.clipboardData || window.clipboardData).getData("text");
        return parsePaste(str);
    };

    return (
        <BookingIntegration
            {...props}
            integrationId={integrationId}
            supportsApi={supportsApi}
            isValid={isValidValue || !supportsApi}
            onReload={onReload}
            list={queryListId}
            isPending={isPending}
            isEmpty={integrationId && !isPending && !items.length}
            onPaste={handlePaste}
        />
    );
}

BookingIntegrationContainer.propTypes = {};

BookingIntegrationContainer.defaultProps = {};

export default BookingIntegrationContainer;
