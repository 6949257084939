import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";

export function SessionStatus(props) {
    const { text, color } = props;

    return (
        <div
            className={cn("session-status", {
                [`session-status--${color}`]: true,
            })}
        >
            {text}
        </div>
    );
}

SessionStatus.propTypes = {
    text: PropTypes.string,
    color: PropTypes.oneOf(["default", "green", "secondary"]),
};
SessionStatus.defaultProps = {
    color: "default",
};

export default SessionStatus;
