import React, {useCallback} from "react";

import {useActions, useApiActions} from "state/hooks";
import {handleUploadCallback} from "state/actions/upload";
import UploadButton from "components/upload/UploadButton";
import Button from "components/Button";
import Icon from "components/Icon";
import {pathDetach} from "state/actions/data";
import Container from "./Container";

function Upload(props) {
    const {path} = props;

    const {
        handleUpload
    } = useApiActions({
        handleUpload: handleUploadCallback
    });

    const onUpload = useCallback(files => {
        files.forEach(file => {
            handleUpload(null, 'db.media', path.split('/'), file);
        })
    }, [handleUpload, path]);

    return <div className={'cols cols-center pad-xs'}>
        <UploadButton
            onUpload={onUpload}
            trigger={<Button
                onClick={_=>null}
                variant={'text'}
                endIcon={<Icon>mui-cloud_upload</Icon>}
            >
                Upload a file
            </Button>} />
    </div>;
}

function RenderField(props) {
    const {entityType, entityId, id, readOnly} = props;
    const path = [entityType, entityId, id].join('/');

    const {
        handleDelete
    } = useActions({
        handleDelete: pathDetach
    });

    const onDelete = useCallback(id => {
        handleDelete(path.split('/'), id);
    }, [handleDelete, path]);


    return <div>
        <Container
            {...props}
            onDelete={!readOnly && onDelete}
        />
        {!readOnly && <Upload path={path} />}
    </div>
}

export function Field(props) {
    return <RenderField {...props} />;
}