import React from 'react';
import {usePending} from "state/hooks";
import Fetch from "containers/Fetch";
import {Spaces, SpacesSkeleton} from "joynt/views/spaces";
import {SpaceNestingContext} from "joynt/views/index";
//import PropTypes from 'prop-types';

export default function ChildSpaces({id}) {

    const list = `db.nodes.${id}.child-spaces`;
    const url = `joynt/nodes/${id}/spaces`;

    const pending = usePending(['db.nodes', list].join('/'));

    return (<Fetch
        type={'db.nodes'}
        url={url}
        list={list}
        force={true}
    >
        <SpaceNestingContext.Provider value={true}>
            {pending ? <div className={'spaces-grid'}>
                <SpacesSkeleton />
            </div> : <Spaces
                id={id}
                list={list}
                className={'spaces-grid grow overflow-scroll'}
                tabs={false}
            /> }
        </SpaceNestingContext.Provider>
    </Fetch>);
}

ChildSpaces.propTypes = {};
ChildSpaces.defaultProps = {};
