import React from "react";
import Drawer from "@material-ui/core/Drawer";
//import PropTypes from 'prop-types';

export function DrawerOverlay(props) {
    const { id, open, onClick, Component } = props;

    return (
        <Drawer anchor={"bottom"} open={open} onClose={onClick}>
            <div className={"rows pad-sm grow"}>
                <Component id={id} onClose={onClick} />
            </div>
        </Drawer>
    );
}

DrawerOverlay.propTypes = {};
DrawerOverlay.defaultProps = {};

export default DrawerOverlay;
