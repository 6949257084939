import React, { useCallback } from "react";

import { usePagination } from "joynt/hooks";
import { useRootNode } from "joynt/hooks/channels";
import { useRouteParams } from "state/hooks/useRouteParams";

import ListView from "containers/ListView";
import InboxEmptyState from "joynt/inbox/EmptyState";
import ScrollFade from "joynt/components/ScrollFade";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import { InboxThreadCardContainer } from "joynt/components/Inbox";
import { ThreadSkeleton } from "joynt/inbox/Thread";
import { FLAG_CHAT_THREAD, NODE_TYPE_CHAT } from "joynt/config";
import { useUiFlag } from "state/hooks";

//import PropTypes from 'prop-types';

const ThreadCardIterator = InfiniteScrollIterator(InboxThreadCardContainer);

function ThreadsSkeleton(props) {
    return (
        <>
            <ThreadSkeleton />
            <ThreadSkeleton />
            <ThreadSkeleton />
        </>
    );
}

export function Inbox(props) {
    const { isPage, onShow } = props;

    const filter = "all";
    const id = useRootNode();

    const list = ["db.nodes", id, "threads", filter].join(".");
    const { page, hasMore } = usePagination("db.nodes", list);
    const pushRouteParams = useRouteParams();

    const onShowThreadInbox = useCallback(
        (id) => {
            pushRouteParams({ view: "inbox", thread: id, side: null });
        },
        [pushRouteParams]
    );

    const [, showChatPopup] = useUiFlag(FLAG_CHAT_THREAD);

    const onShowThreadPopup = (id) => {
        if (onShow) onShow(null);
        showChatPopup(id);
    };

    const onShowThread = isPage ? onShowThreadPopup : onShowThreadInbox;

    let url = `v2/joynt/nodes/${id}/inbox?subtype=${NODE_TYPE_CHAT}`;

    return (
        <ScrollFade className={"inbox-threads"} hasMore={hasMore}>
            <ListView
                type={"db.nodes"}
                view={null}
                list={list}
                filter={list}
                url={url}
                append={true}
                useWindowScroll={false}
                onClick={onShowThread}
                Iterator={ThreadCardIterator}
                className={"rows gap-5"}
                selected={null}
                pendingState={!page ? <ThreadsSkeleton /> : null}
                pendingReplace={true}
                emptyState={<InboxEmptyState />}
            />
        </ScrollFade>
    );
}

Inbox.propTypes = {};
Inbox.defaultProps = {};

export default Inbox;
