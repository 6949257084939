import React from "react";
import FormGroup from "joynt/components/Form/FormGroup";
import RolesContainer, {
    Container as Roles,
} from "joynt/components/Roles/Container";
import FormActions from "joynt/components/Form/FormActions";
import { HelperText } from "joynt/components/HelperText";
import { useNodeData } from "joynt/hooks";

import "./style.css";
import Button from "components/Button";

function Invite(props) {
    const { onSubmit, onClose, defaultRole, id } = props;
    const { can_edit } = useNodeData(id);

    if (!can_edit) {
        return (
            <div className={"invite rows gap-6 form-group invite--no-access"}>
                <div className={"card"}>
                    <h2>
                        <span>⚠</span>️
                    </h2>
                    You are not allowed to edit roles for this space. <br />
                    If you wanted to invite someone, reach out to one of the
                    admins of the space.
                </div>
                <Button onClick={onClose}>Close</Button>
            </div>
        );
    }

    return (
        <div className={"invite rows gap-md pad-bottom-6"}>
            {defaultRole && (
                <HelperText text={"You can always assign roles later"} />
            )}
            <FormGroup title={"Choose people from Joynt or add emails"}>
                <Roles {...props} onSubmit={null} />
            </FormGroup>
            <FormActions
                onSubmit={onSubmit}
                onReject={onClose}
                submitLabel={"Save and invite"}
            />
        </div>
    );
}

Invite.propTypes = {};
Invite.defaultProps = {};

export default function InviteContainer({ onClose, ...other }) {
    return (
        <RolesContainer
            {...other}
            onClose={onClose}
            onSubmit={onClose}
            Component={Invite}
        />
    );
}
