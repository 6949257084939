import React from "react";
//import PropTypes from 'prop-types';

import Icon from "components/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

function ProgressStatusIcon({ status }) {
    if (status === "success") return <Icon>mui-check</Icon>;
    return <CircularProgress size={16} />;
}

export function ProgressLog(props) {
    const { log } = props;

    return (
        <div className={"rows gap-xs"}>
            {Object.values(log).map((item, index) => {
                return (
                    <div key={item.id} className={"cols gap-sm cols-middle"}>
                        <ProgressStatusIcon status={item.status} />
                        <div>{item.message}</div>
                    </div>
                );
            })}
        </div>
    );
}

ProgressLog.propTypes = {};

ProgressLog.defaultProps = {
    log: {},
};

export default ProgressLog;
