import React from "react";
//import PropTypes from 'prop-types';

export function FieldsLayout(props) {
    const { children } = props;

    return <div className={"fields-layout grow rows gap-md"}>{children}</div>;
}

FieldsLayout.propTypes = {};
FieldsLayout.defaultProps = {};

export default FieldsLayout;
