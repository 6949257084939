import React from 'react';
import PropTypes from 'prop-types';
import StageSession from "./StageSession";
import StageTile from "joynt/components/StageTile";
import {useStage} from "joynt/hooks/sessions";

export function StageSessionContainer(props) {

    const {id, onShowSettings, ...other} = props;

    const {
        eventId,
        isPending,
        isJoining,
        isLive
    } = useStage(id, true);

    const hasSession = eventId && eventId !== id;

    return <StageSession
        hasSession={hasSession}
        onShowSettings={onShowSettings}
    >
        {hasSession && <StageTile
            {...other}
            id={eventId}
            isPending={isPending || isJoining}
            isLive={isLive}
        />}
    </StageSession>;
}

StageSessionContainer.propTypes = {
    id: PropTypes.string
};
StageSessionContainer.defaultProps = {};

export default StageSessionContainer;