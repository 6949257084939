import React, {useCallback, useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectLibraryId} from "cms/state/selectors/cms";
import ApiContext from "containers/context/ApiContext";
import Fetch from "containers/Fetch";
import List from "containers/List";
import {NS_COMPONENTS} from "cms/components/types";
import {ArrayIterator} from "components/list/iterator";
import {useClick, useData} from "state/hooks";
import Icon from "components/Icon";
import icons from "config/icons";
import Card from "@material-ui/core/Card";
//import PropTypes from 'prop-types';

function ComponentPreview(props) {
    const click = useClick(props);
    const {element_type} = useData(props);
    return <Card onClick={click} className={'component-type type-default pad-xs default-c500 cols cols-middle gap-xs'}>
        <Icon defaultType={'mui'} fontSize={'default'}>{icons['cms.components.'+element_type]}</Icon>
        <div>{element_type}</div>
    </Card>;
}

const ComponentIterator = ArrayIterator(ComponentPreview);

export default function LibraryComponents(props) {

    const {onClick} = props;

    const library = useSelector(selectLibraryId);
    const apiContext = useMemo(() => ({
        project: library
    }), [library]);

    const url = `cms/components`;

    const list = [NS_COMPONENTS, 'library'].join('.');
    const click = useCallback((id)=>onClick(NS_COMPONENTS, id), [onClick]);

    return (<ApiContext.Provider value={apiContext}>
        <Fetch
            type={NS_COMPONENTS}
            list={list}
            url={url}
            force={true}
        >
            <List
                type={NS_COMPONENTS}
                list={list}
                onClick={click}
                Iterator={ComponentIterator} />
        </Fetch>
    </ApiContext.Provider>);

}

LibraryComponents.propTypes = {};
LibraryComponents.defaultProps = {};
