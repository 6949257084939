import React from "react";
//import PropTypes from 'prop-types';

import { useActions, useFetch, useList } from "state/hooks";
import { pushRoute } from "state/actions/router";
import Projects from "./Projects";

export function ProjectsContainer(props) {
    const type = "console.services";
    const list = "console.services";

    const pending = useFetch({
        url: "console/services?type=website,db,apartments",
        type,
        list,
        filter: list,
    });

    const { items } = useList(type, list);
    const { onPush } = useActions({ onPush: pushRoute });
    const lang = "pl";
    const onClick = (id) => onPush(`/${lang}/${id}`);

    return <Projects items={items} type={type} onClick={onClick} />;
}

ProjectsContainer.propTypes = {};

ProjectsContainer.defaultProps = {};

export default ProjectsContainer;
