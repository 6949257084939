import React from 'react';
import {useNodeData} from "joynt/hooks";
import SidebarItem from "../SidebarItem";
import SpaceAvatar from "joynt/components/SpaceAvatar";

//import PropTypes from 'prop-types';

export function Workspace(props) {

    const {id} = props;
    const {name} = useNodeData(id);

    return <SidebarItem
        label={name}
        icon={<SpaceAvatar
            id={id}
            size={"sm"}
        />}
        showArrow={true}
        {...props}
    />;
}

Workspace.propTypes = {};
Workspace.defaultProps = {};

export default Workspace;
