import React from "react";
import PropTypes from "prop-types";
import cn from "classnames/dedupe";
import Icon from "components/Icon";
import Link from "cms/containers/Link";

/*const defaultClasses = {
    button: 'btn primary btn-flat',
    label: 'btn-label',
    icon: 'btn-icon',
    badge: 'btn-badge'
};

const exampleProps = {
    classes: defaultClasses,
    label: 'Button label',
    icon: 'icon',
    badge: 2
};*/

/**
 * Concepts:
 * - button with headline
 * - badge (attachment inner/outer)
 * - media button type ?
 * - triggers modal
 * - linkable
 * - tooltip
 * - call / email
 * - forward icon (custom or download)
 * - pseudo md parsing in label
 */
export default class Button extends React.PureComponent {
    render() {
        const {
            inspectId,
            classes,
            attributes,
            className,
            label,
            link,
            link_label,
            link_action,
            link_ref,
            icon,
            enable_badge,
            badge_value,
            headline,
        } = this.props;

        const { onClick } = this.props;

        /** Support for legacy property name **/
        const showLabel = label || link_label;

        return (
            <Link
                className={cn(className, classes.button, {
                    "c-btn--headline": !!headline,
                })}
                onClick={onClick}
                action={link_action}
                link={link}
                id={link_ref}
                data-type={"button"}
                data-inspect={inspectId}
                data-s={"button"}
                {...attributes}
            >
                <span>
                    {icon ? (
                        <span
                            data-s={"button_icon"}
                            className={cn(classes.button_icon)}
                        >
                            <Icon>{icon}</Icon>
                        </span>
                    ) : null}
                    {showLabel ? (
                        <>
                            {headline ? (
                                <span className={cn(classes.button_heading)}>
                                    <span
                                        className={cn(classes.button_headline)}
                                    >
                                        {headline}
                                    </span>
                                    <span className={cn(classes.button_label)}>
                                        {showLabel}
                                    </span>
                                </span>
                            ) : (
                                <span
                                    data-s={"button_label"}
                                    className={cn(classes.button_label)}
                                >
                                    {showLabel}
                                </span>
                            )}
                        </>
                    ) : null}
                </span>
                {enable_badge ? (
                    <div className={cn(classes.button_badge)}>
                        {badge_value}
                    </div>
                ) : null}
            </Link>
        );
    }
}

Button.autoFillProps = [
    "link",
    "link_label",
    "link_action",
    "icon",
    "headline",
];

Button.propTypes = {
    link_label: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
};

Button.defaultProps = {};
