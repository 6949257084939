import React from "react";
import { connect } from "react-redux";
import LinkerContext from "cms/context/LinkerContext";
import { selectLinkRef } from "cms/selectors/nav";

const mapStateToProps = (store, props) => {
    return {
        ...selectLinkRef(store, props),
    };
};

const mapDispatchToProps = {};

class Link extends React.PureComponent {
    render() {
        const { href, linkUri, linkValue, linkType, ...other } = this.props;

        if (!href) return <div {...other}>{this.props.children}</div>;

        return (
            <a data-href={href} {...other}>
                {this.props.children}
            </a>
        );
    }
}

function Linker(props) {
    return (
        <LinkerContext.Consumer>
            {(value) => <Link {...props} {...value(props)} />}
        </LinkerContext.Consumer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Linker);
