import { getCurrentBlock } from "./getCurrentBlock";
import { EditorState, Modifier, SelectionState } from "draft-js";

export function clearTextTypeahead(editorState, trigger = "/") {
    let nextState = editorState;
    let currentBlock = getCurrentBlock(nextState);
    let currentBlockKey = currentBlock.getKey();
    let from = currentBlock.getText().lastIndexOf(trigger);
    let to = currentBlock.getLength();

    const selection = new SelectionState({
        anchorKey: currentBlockKey,
        anchorOffset: to,
        focusKey: currentBlockKey,
        focusOffset: from,
        isBackward: true,
    });

    return EditorState.set(nextState, {
        currentContent: Modifier.removeRange(
            nextState.getCurrentContent(),
            selection,
            "backward"
        ),
    });
}
