import React from 'react';
import PropTypes from 'prop-types';

import LinkEmbed from "joynt/components/LinkEmbed";
import Button from "components/Button";
import Icon from "components/Icon";

export function Whiteboard(props) {

    const {editable, link, onDelete, onPresent} = props;

    return (<div className={'rows gap-xs'}>
        <LinkEmbed
            link={link}
            readOnly={!editable}
            onDelete={onDelete}
        />
        { link && onPresent && !editable ? <Button
            onClick={onPresent}
            variant={'outlined'}
            startIcon={<Icon>mui-present_to_all</Icon>}
        >
            Present to all
        </Button> : null }
    </div>);

}

Whiteboard.propTypes = {
    editable: PropTypes.bool,
    onDelete: PropTypes.func,
    onPresent: PropTypes.func,
    link: PropTypes.string
};
Whiteboard.defaultProps = {
    editable: false
};

export default Whiteboard;
