import React from 'react';
import Timeline from "joynt/timeline";
import {SpacesIterator} from "joynt/views/spaces/Spaces";
import {useChannels, useRouter} from "joynt/hooks/channels";
import Fetch from "containers/Fetch";
import List from "containers/List"
import {useList, usePending} from "state/hooks";
import {useSelector} from "react-redux";
import {selectGroups} from "state/selectors/lists";
import {useFeatureEnabled, useKeep, useNodeData} from "joynt/hooks";
import {NODE_TYPE_EVENT} from "joynt/config";
import {SpacesSkeleton} from "joynt/views/spaces";
import {FEATURE_OPTIMIZED_API} from "joynt/config/features";
//import PropTypes from 'prop-types';


const eventGroups = {
    type: 'db.nodes',
    groups: [
        {
            id: 'now',
            label: 'Live now',
            qualifier: (state, item) => {
                return item.event_state === 'live';
            }
        },
        {
            id: 'upcoming',
            label: 'Upcoming events',
            qualifier: (state, item) => {
                const now = Date.now();
                return item.event_start > now/1000;
            }
        },
        {
            id: 'past',
            label: 'Past events',
            qualifier: (state, item) => {
                const now = Date.now();
                return item.event_end < now/1000;
            }
        }
    ]
}



export default function Agenda(props) {

    const {id, showTimeline} = props;

    const {subtype} = useNodeData(id);
    const viewType = subtype === NODE_TYPE_EVENT ? 'sessions' : 'events';
    const list = `db.nodes.${id}.${viewType}`;

    const {onLink, onPreview} = useRouter();
    const {onEdit} = useChannels(id, list);
    const {onKeep} = useKeep();
    const isDevApi = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const url = isDevApi
        ? `v2/joynt/nodes/${id}/${viewType}`
        : `joynt/nodes/${id}/${viewType}`;

    const items = useList('db.nodes', list);
    const groups = useSelector(s=>selectGroups(s, items.items, eventGroups));
    const pending = usePending(['db.nodes', list].join('/'));

    return (<div className={'rows relative'}>
        { showTimeline ? <Timeline
            {...props}
            list={list}
            onClick={onPreview}
        /> : null }
        <Fetch
            type={'db.nodes'}
            url={url}
            list={list}
            enable={true}
            force={true}
        >
            <div className={'rows gap-md'}>
                {groups.map(group => {
                    return group.items.length > 0 || pending ? <div className={'rows gap-sm'} key={group.id}>
                        <h4 className={'type-heading-sm'}>{group.label}</h4>
                        <div className={'spaces-grid grow'}>
                            <List
                                onClick={onLink}
                                onPreview={onPreview}
                                onEdit={onEdit}
                                onKeep={onKeep}
                                type={'db.nodes'}
                                list={list}
                                items={group.items}
                                pendingState={<SpacesSkeleton />}
                                pendingReplace={true}
                                Iterator={SpacesIterator}
                            />
                        </div>
                    </div> : null;
                })}
            </div>
        </Fetch>
    </div>);

}

Agenda.propTypes = {};
Agenda.defaultProps = {
    showTimeline: true
};
