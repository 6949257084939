import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { change } from "state/actions/data";
import { selectEntity, selectFieldValue } from "state/selectors/data";
import fieldTypes from "components/fields";
import EntityContext, {
    AggregateRootContext,
} from "containers/context/EntityContext";
import FormRenderContext from "containers/context/FormRenderContext";
import { isValidField, selectFormSubmitted } from "state/selectors/validation";
import { ValidationContext } from "containers/context/ValidationContext";
import { useActions } from "state/hooks";

const nullObj = {};

const mapStateToProps = (store, props) => {
    let error =
        props.validate || props.validateOnSubmit
            ? isValidField(store, props.entityType, props.entityId, props.id)
            : null;

    let submitAttempt = selectFormSubmitted(
        store,
        props.entityType,
        props.entityId
    );
    if (props.validateOnSubmit && !submitAttempt) error = null;

    return {
        value: props.id
            ? selectFieldValue(
                  store,
                  props.entityType,
                  props.entityId,
                  props.id
              )
            : selectEntity(store, props.entityType, props.entityId),
        handleChange: props.onChange,
        error: !!error,
        helperText: error || props.helperText,
    };
};

const mapDispatchToProps = {
    onChange: change,
};

export function useField(fieldId, type, id) {
    const entity = useContext(EntityContext);

    const [entityType, entityId] =
        !type && !id && entity ? entity.split("/") : [type, id];

    const value = useSelector((store) => {
        return selectFieldValue(store, entityType, entityId, fieldId);
    });

    const { onChange } = useActions({
        onChange: change,
    });

    const onSubmit = useContext(AggregateRootContext);
    const onValidate = useContext(ValidationContext);

    const handleChange = useCallback(
        (value) => {
            const update = {};
            update[fieldId] = value;
            onChange(entityType, entityId, update);
            if (onValidate) onValidate(entityType, entityId);
            if (onSubmit) onSubmit();
        },
        [entityType, entityId, fieldId, onChange, onValidate, onSubmit]
    );

    return [value, handleChange];
}

class Field extends React.PureComponent {
    componentDidMount() {
        const { id, defaultValue, value } = this.props;
        if (defaultValue && value !== defaultValue) {
            this.handleChange(id, defaultValue);
        }
    }

    handleFieldChange = (value, debounce) => {
        const { id } = this.props;
        this.handleChange(id, value, debounce);
    };

    handleChange = (id, value, debounce) => {
        const {
            entityType,
            entityId,
            onChange,
            onSubmit,
            onValidate,
            submit,
            handleChange,
        } = this.props;
        let update = {};
        update[id] = value;

        if (handleChange) handleChange(value);

        onChange(entityType, entityId, update, !!onValidate);

        if (onValidate) onValidate(entityType, entityId);

        if (!debounce && onSubmit && submit !== false) {
            onSubmit();
        }
    };

    render() {
        const {
            // eslint-disable-next-line
            entityType,
            // eslint-disable-next-line
            entityId,
            children,
            id,
            FieldComponent,
        } = this.props;

        const render = children || this.props.render || RenderFieldType;

        const props = {
            ...this.props,
            onChange: id ? this.handleFieldChange : this.handleChange,
        };

        if (FieldComponent) return <FieldComponent {...props} />;

        return render(props);
    }
}

Field.propTypes = {
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    id: PropTypes.string,
    children: PropTypes.func,
};

export const ConnectedField = connect(
    mapStateToProps,
    mapDispatchToProps
)(Field);

class FieldContext extends React.PureComponent {
    render() {
        const { entityType, entityId } = this.props;

        if (entityType && entityId)
            return (
                <AggregateRootContext.Consumer>
                    {(onSubmit) => {
                        return (
                            <FormRenderContext.Consumer>
                                {(render) => (
                                    <ConnectedField
                                        onSubmit={onSubmit}
                                        render={render}
                                        {...this.props}
                                    />
                                )}
                            </FormRenderContext.Consumer>
                        );
                    }}
                </AggregateRootContext.Consumer>
            );

        return (
            <AggregateRootContext.Consumer>
                {(onSubmit) => (
                    <ValidationContext.Consumer>
                        {(onValidate) => (
                            <EntityContext.Consumer>
                                {(value) => {
                                    if (!value)
                                        return (
                                            <div>
                                                Unable to resolve entity from
                                                EntityContext
                                            </div>
                                        );

                                    const [entityType, entityId] =
                                        value.split("/");
                                    return (
                                        <FormRenderContext.Consumer>
                                            {(render) => (
                                                <ConnectedField
                                                    entityType={entityType}
                                                    entityId={entityId}
                                                    onSubmit={onSubmit}
                                                    onValidate={onValidate}
                                                    render={render}
                                                    {...this.props}
                                                />
                                            )}
                                        </FormRenderContext.Consumer>
                                    );
                                }}
                            </EntityContext.Consumer>
                        )}
                    </ValidationContext.Consumer>
                )}
            </AggregateRootContext.Consumer>
        );
    }
}

export default FieldContext;

export function RenderFieldType(props) {
    const type = props.fieldType || "text";
    const FieldComponent =
        props.FieldComponent || fieldTypes[type] || fieldTypes.default;
    if (!FieldComponent)
        return (
            <div>
                Unknown field type {type} ({props.label})
            </div>
        );
    return <FieldComponent {...props} />;
}

export function RenderField(props) {
    return <ConnectedField {...props}>{RenderFieldType}</ConnectedField>;
}
