import React from "react";
import { useData } from "state/hooks";
import { SelectListItem } from "playground/ui/SelectList";

//import PropTypes from 'prop-types';

export function SectionEntryItem(props) {
    const { type, id, onClick, ...rest } = props;
    const { title, slug } = useData(props);

    return (
        <SelectListItem onClick={onClick} subtitle={title} {...rest}>
            #{slug}
        </SelectListItem>
    );
}

SectionEntryItem.propTypes = {};

SectionEntryItem.defaultProps = {};

export default SectionEntryItem;
