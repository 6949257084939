import React, { useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import cn from "classnames";

import DraftEditor from "@draft-js-plugins/editor";

import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/anchor/lib/plugin.css";
import "@draft-js-plugins/focus/lib/plugin.css";
import "@draft-js-plugins/side-toolbar/lib/plugin.css";

import "draft-js/dist/Draft.css";

import "./editor.css";

import { createInitialEditorState } from "./util";
import createPlugins from "./plugins";

import MentionSuggestions from "./MentionSuggestions";
import CustomToolbar from "./InlineToolbar";
import { useHandleEditorChange } from "./hooks";
import { useApiActions } from "state/hooks";
import { createPostContent } from "joynt/state/actions/posts";

export const DraftDndContext = React.createContext({});

export function Editor(props) {
    const { id, initialValue, onSave, onUpload, readOnly } = props;

    const [editorState, setEditorState] = useState(() =>
        createInitialEditorState(initialValue)
    );

    const [hover, setHover] = useState(null);

    const { onCreateContent } = useApiActions({
        onCreateContent: createPostContent,
    });

    const create = useCallback(
        (data, cb) => {
            onCreateContent({ parent: id, ...data }, cb);
        },
        [onCreateContent, id]
    );

    const dndCtx = useMemo(
        () => ({
            hover,
            setHover,
        }),
        [hover, setHover]
    );

    const editor = useRef(null);

    const {
        plugins,
        InlineToolbar,
        MentionSuggestionsRender,
        BlockTypeSelect,
        EmojiSuggestions,
        LinkButton,
        MiscEditing,
        //EmojiSelect,
    } = useMemo(() => {
        return createPlugins({ readOnly, onCreateContent: create, onUpload });
    }, [readOnly, create, onUpload]);

    const handleChange = useHandleEditorChange({
        onSave,
        setEditorState,
    });
    const handleDroppedFiles = useCallback((s, files) => {
        //onUpload(files);
        return true;
    }, []);

    const handlePastedFiles = useCallback(
        (files) => {
            onUpload(files);
            return true;
        },
        [onUpload]
    );

    return (
        <div
            className={cn("editor-canvas", {
                "read-only": readOnly,
            })}
        >
            <DraftDndContext.Provider value={dndCtx}>
                <DraftEditor
                    editorState={editorState}
                    onChange={handleChange}
                    plugins={plugins}
                    ref={editor}
                    placeholder={null}
                    readOnly={readOnly || hover}
                />
            </DraftDndContext.Provider>
            {!readOnly && <MiscEditing />}
            <InlineToolbar>
                {(props) => (
                    <CustomToolbar {...props} LinkButton={LinkButton} />
                )}
            </InlineToolbar>
            <MentionSuggestions id={id} Component={MentionSuggestionsRender} />
            <EmojiSuggestions />
            {/*<EmojiSelect />*/}
            <BlockTypeSelect />
        </div>
    );
}

Editor.propTypes = {
    id: PropTypes.string,
    initialValue: PropTypes.object,
    onSave: PropTypes.func,
    onUpload: PropTypes.func,
    readOnly: PropTypes.bool,
};
Editor.defaultProps = {
    text: "Some default editor content",
    readOnly: false,
};

export default Editor;
