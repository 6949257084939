import {useCallback, useContext} from 'react';
import {AgoraContext} from "../context/AgoraContext";

export const useRTMControls = () => {

    const connection = useContext(AgoraContext);
    const {rtm: rtmClient, rtmChannel} = connection;

    const toggleAttendeeAudio = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'mute-audio',
            }, userId);
        } catch (error) {
            console.log(error);
            return error;
        }

    }, [rtmClient]);

    const toggleAttendeeVideo = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'mute-video',
            }, userId);
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmClient]);

    const removeAttendee = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'remove-attendee',
            }, userId);
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmClient]);

    const stopAttendeeScreenShare = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'stop-screen-share',
            }, userId);
        } catch (error) {
            return error;
        }
    }, [rtmClient]);

    const inviteOnStage = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'stage-invite'
            }, userId);
        } catch (error) {
            return error;
        }
    }, [rtmClient]);

    const removeFromStage = useCallback(async (userId) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text: 'stage-remove'
            }, userId);
        } catch (error) {
            return error;
        }
    }, [rtmClient]);

    const setChannelUserAttribute = useCallback(async (userId, attr, value) => {
        try {
            let prevAttr = await rtmClient.getChannelAttributesByKeys(rtmChannel.channelId, [attr]);
            let prev = prevAttr && prevAttr[attr] ? JSON.parse(prevAttr[attr].value) : {};
            let next = {...prev, [userId]: value};
            return await rtmClient.setChannelAttributes(rtmChannel.channelId, {
                [attr]: JSON.stringify(next)
            }, {
                enableNotificationToChannelMembers: true
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmClient, rtmChannel]);

    const setChannelAttribute = useCallback(async (attr, value) => {
        try {
            return await rtmClient.setChannelAttributes(rtmChannel.channelId, {
                [attr]: value
            }, {
                enableNotificationToChannelMembers: true
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmClient, rtmChannel]);

    const leave = useCallback(async () => {
        await rtmChannel && rtmChannel.leave();
        await rtmClient && rtmClient.logout();
    }, [rtmChannel, rtmClient]);

    const sendChannelMessage = useCallback(async (text) => {
        try {
            await rtmChannel.sendMessage({text})
        } catch (error) {
            return error;
        }
    }, [rtmChannel]);

    const sendPeerMessage = useCallback(async (text, uid) => {
        try {
            return await rtmClient.sendMessageToPeer({
                text
            }, uid);
        } catch (error) {
            return error;
        }
    }, [rtmClient]);

    const muteAll = useCallback(async () => {
        try {
            await rtmChannel.sendMessage({text: 'mute-all'})
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmChannel]);

    const requestLogs = useCallback(async () => {
        try {
            await rtmChannel.sendMessage({text: 'request-logs'});
        } catch (error) {
            console.log(error);
            return error;
        }
    }, [rtmChannel]);

    return {
        toggleAttendeeVideo,
        toggleAttendeeAudio,
        stopAttendeeScreenShare,
        removeAttendee,
        sendChannelMessage,
        sendPeerMessage,
        leave,
        muteAll,
        inviteOnStage,
        removeFromStage,
        requestLogs,
        setChannelUserAttribute,
        setChannelAttribute
    }

}
