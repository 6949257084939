import {useSessionState} from "joynt/meeting/hooks/useSessionState";
import {useCallback} from "react";

export default function useSessionControls(id) {

    const {
        audio,
        video,
        isLive,
        setAudio,
        setVideo,
        setIsLive,
        onShowSettings
    } = useSessionState(id);

    const onToggleAudio = useCallback(() => {
        setAudio(!audio);
    }, [audio, setAudio]);

    const onToggleVideo = useCallback(() => {
        setVideo(!video);
    }, [video, setVideo]);

    const onToggleLive = useCallback(() => {
        if (setIsLive) setIsLive(!isLive);
    }, [isLive, setIsLive]);

    return {
        onToggleAudio,
        onToggleVideo,
        onToggleLive,
        onGoLive: setIsLive,
        onShowSettings
    };
}