import React from 'react';
import PropTypes from 'prop-types';

import Section from "joynt/components/RoomStage/Section";
import Button from "components/Button";
import EmptyState from "./EmptyState";

import "./style.css";
import Preloader from "components/Preloader";

export function Meetings(props) {

    const {
        onCreate,
        isPending,
        children
    } = props;

    const hasSessions = React.Children.count(children);

    return (<Section name={"Quick meeting"} cols={1}>
        <div className={"rows rows-center gap-md pad-md"}>
            {!hasSessions && !isPending && <EmptyState />}
            <Button onClick={onCreate}>
                Start new session now
            </Button>
            {hasSessions > 0 && <div className={"rows gap-sm grow"}>
                {children}
            </div>}
        </div>
        <Preloader visible={isPending} />
    </Section>);

}

Meetings.propTypes = {
    isPending: PropTypes.bool
};
Meetings.defaultProps = {
    isPending: false
};

export default Meetings;
