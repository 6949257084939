import useLinkMetadata from "./useLinkMetadata";
import useFetch from "state/hooks/useFetch";
import {useMemo} from "react";

export function useFetchLinkData(link) {

    const data = useLinkMetadata(link);
    const {fetchUrl, id} = data;

    const pending = useFetch({
        type: 'db.link-embed',
        id,
        url: fetchUrl,
        once: true
    });

    return useMemo(() => ({
        pending,
        ...data
    }), [pending, data]);

}