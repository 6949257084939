import React from 'react';
import PropTypes from 'prop-types';

export function SidebarHeader(props) {

    const {subtitle, children} = props;

    return (<div className={"sidebar-nav__header"}>
        {children}
        {subtitle && <div className={"sidebar-nav__subtitle"}>
            {subtitle}
        </div> }
    </div>);

}

SidebarHeader.propTypes = {
    subtitle: PropTypes.string
};
SidebarHeader.defaultProps = {};

export default SidebarHeader;
