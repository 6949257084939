import React, { useCallback } from "react";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

export function SettingsButton(props) {
    const { onShowSettings } = useMeeting();

    const click = useCallback(() => onShowSettings(true), [onShowSettings]);

    return (
        <IconButton
            withTooltip
            size={"medium"}
            label={"Settings"}
            icon={"mui-settings"}
            onClick={click}
            disabled={!onShowSettings}
        />
    );
}

SettingsButton.propTypes = {};
SettingsButton.defaultProps = {};

export default SettingsButton;
