import React from "react";
import { useActions, useUiFlag } from "state/hooks";
import { pushRouteParams } from "state/actions/router";
import { useSelector } from "react-redux";

//import PropTypes from 'prop-types';

import NetworkingTables from "./NetworkingTables";
import NetworkingLandingPage from "./NetworkingLandingPage";

import "./style.css";

export function Networking(props) {
    const { id } = props;
    const [joined, setJoined] = useUiFlag(`networking.${id}.joined`);
    const { onSetPath } = useActions({ onSetPath: pushRouteParams });
    const full = useSelector((s) => s.router.location.pathname === "/page");
    const toggleFullPage = () => onSetPath({ path: full ? null : "page" });

    if (joined)
        return (
            <NetworkingTables
                {...props}
                onLeave={() => setJoined(null)}
                onToggleFull={toggleFullPage}
            />
        );

    return (
        <NetworkingLandingPage
            {...props}
            onJoin={() => setJoined(true)}
            onToggleFull={toggleFullPage}
        />
    );
}

Networking.propTypes = {};
Networking.defaultProps = {};

export default Networking;
