import {useCallback, useMemo} from 'react';
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
//import PropTypes from 'prop-types';

export function useNodePage(type, param = ROUTE_PAGE) {

    const [value,setEdit] = useRouteParam(param);
    const [routeType, id] = value ? value.split('.') : [];

    const show = useCallback(id => {
        if (!id) {
            setEdit(null);
            return;
        }
        setEdit(param === ROUTE_PAGE ? `${type}.${id}` : id);
    }, [setEdit, type, param]);

    return useMemo(() => ({
        id,
        type: routeType,
        onShow: show
    }), [id, show, routeType]);

}
