export const registerListProps = (props) => {
    return {
        type: 'LIST.REGISTER_PROPS',
        payload: props
    }
};

export const reload = context => (type, list, url) => {
    return {
        type: 'LIST.RELOAD',
        payload: {
            type,
            list,
            url
        },
        context
    }
};

export const listRemove = (type, list, id) => {
    return {
        type: 'LIST.REMOVE',
        payload: {
            type,
            list,
            id
        }
    }
};

export const listAppend = (type, list, id, position) => {
    return {
        type: 'LIST.APPEND',
        payload: {
            type,
            list,
            id,
            position
        }
    }
};

export const listUpdated = (type, list, timestamp) => ({
    type: 'LIST.UPDATED',
    payload: {type, list, timestamp}
});