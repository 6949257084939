import React, { useMemo } from "react";
//import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { useNodeData } from "joynt/hooks";

import { useSessionFlowArgs, useSetSessionFlowSegment } from "./hooks";
import { selectFlowSchedule, selectFlowSegment } from "./selectors";
import { renderSegment } from "./util";

import { SessionFlowContext } from "./SessionFlowContext";
import PhoneSessionLayout from "joynt/components/SessionLayout/PhoneSessionLayout";
import useScreenSize from "util/hooks/useScreenSize";

function phoneDecorator(data) {
    return {
        ...data,
        LayoutComponent: PhoneSessionLayout,
        SHOW_SESSION_TOOLBAR: false,
        MAX_TILES_FOCUSED: 1,
        MAX_TILES_GRID: 1,
        SESSION_LAYOUT: "grid",
    };
}

function SessionFlowContextProvider(props) {
    const { children, id, path } = props;

    const setSegment = useSetSessionFlowSegment(id);

    const { session_flow_segment_started: started } = useNodeData(id);

    const [flow, currentPath] = path.split(".");
    const [current] = currentPath.split(":");

    const schedule = useSelector((s) => selectFlowSchedule(s, flow));
    const steps = schedule.map((s) => s.id);
    const step = useSelector((s) => selectFlowSegment(s, flow, currentPath));
    const next = steps[steps.indexOf(current) + 1];

    const args = useSessionFlowArgs(id);
    const { isMobile } = useScreenSize();

    const data = useMemo(() => {
        const segment = renderSegment(step, args);
        if (isMobile) return phoneDecorator(segment, args);
        return segment;
    }, [step, args, isMobile]);

    const context = useMemo(
        () => ({
            id: flow,
            path,
            setSegment,
            current,
            started,
            next,
            data,
        }),
        [flow, path, setSegment, current, started, next, data]
    );

    return (
        <SessionFlowContext.Provider value={context}>
            {children}
        </SessionFlowContext.Provider>
    );
}

export function SessionFlowProvider(props) {
    const { id, children } = props;

    if (!id) return children;

    return (
        <SessionFlowContextProvider {...props}>
            {children}
        </SessionFlowContextProvider>
    );
}

SessionFlowProvider.propTypes = {};
SessionFlowProvider.defaultProps = {};

export default SessionFlowProvider;
