import { useSelector } from "react-redux";
import { selectRouteParam } from "state/selectors/router";
import { ROUTE_CHANNEL, ROUTE_ID } from "joynt/config";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useMemo } from "react";
import { useRootNode } from "./useRootNode";
import { spaceTypes } from "joynt/config/types";

export function useRouteNodes() {
    const rootNode = useRootNode();

    const id = useSelector((s) => selectRouteParam(s, ROUTE_ID));
    const root = id || rootNode;
    const channel = useSelector((s) => selectRouteParam(s, ROUTE_CHANNEL));

    const spaceTypesIds = Object.keys(spaceTypes);
    const { subtype: channelType } = useNodeData(channel);
    const { parent_space } = useEdgesData(channel);
    const { workspace } = useNodeData(parent_space);
    const isSubchannel =
        spaceTypesIds.indexOf(channelType) === -1 && parent_space && !workspace;

    return useMemo(
        () => ({
            rootNode,
            id: root,
            channel: isSubchannel ? parent_space : channel,
            subchannel: isSubchannel ? channel : null,
        }),
        [rootNode, channel, root, isSubchannel, parent_space]
    );
}
