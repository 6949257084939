import React from 'react';
import PropTypes from 'prop-types';
import MainStageEvent from "./MainStageEvent";
import {useNodeData} from "joynt/hooks";
import EventTimer from "joynt/components/LiveNow/EventTimer";

export function MainStageEventContainer(props) {

    const {id} = props;
    const {event_state, name} = useNodeData(id);
    const isLive = event_state === "live";

    if (!name) return null;

    return (<MainStageEvent
        {...props}
        isLive={isLive}
        name={name}
        timer={isLive ? <EventTimer id={id} /> : null}
    />);

}

MainStageEventContainer.propTypes = {
    onClick: PropTypes.func
};
MainStageEventContainer.defaultProps = {};

export default MainStageEventContainer;