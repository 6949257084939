import React, {useCallback} from 'react';
import {useForm, useNode} from "joynt/hooks";
import {useRouter} from "joynt/hooks/channels";
import NodeEditForm from "joynt/edit";
import PageLayout from "joynt/modals/PageLayout";
import {useRouteParams} from "state/hooks/useRouteParams";
import {NODE_TYPE_EVENT} from "joynt/config";
//import PropTypes from 'prop-types';

export default function NodeSettings(props) {
    const {
        id,
        transition,
        onClose,
        pageType,
        redirect
    } = props;

    const {workspace, subtype, session_type} = useNode(id);
    const route = useRouteParams();
    const type = workspace && !session_type ? "page" : "modal";

    const {onLink: link} = useRouter();

    const submitCallback = useCallback(draft => {
        if (workspace) {
            route({id, page: 'workspace-welcome'})
        } else {
            if (subtype !== NODE_TYPE_EVENT) {
                link(id);
            }
        }
    }, [route, id, link, workspace, subtype]);

    const close = useCallback((draft)=>{
        onClose();
        if (draft && redirect) submitCallback(draft);
    }, [onClose, submitCallback, redirect]);

    const {onSubmit, onReject, isDraft} = useForm(
        'db.nodes',
        id,
        close
    );

    const draft = isDraft || pageType === "create";

    return <PageLayout {...transition} onClose={onReject} type={type}>
        <NodeEditForm
            id={id}
            onSubmit={onSubmit}
            onReject={onReject}
            isDraft={draft}
        />
    </PageLayout>
}


NodeSettings.propTypes = {};
NodeSettings.defaultProps = {
    redirect: true
};
