import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";

export function PostAuthor(props) {

    const {avatar, name} = props;

    return (<div className={"post-author"}>
        {avatar}
        <div className={"post-author__name"}>{name}</div>
    </div>);

}

PostAuthor.propTypes = {
    avatar: PropTypes.element,
    name: PropTypes.string
};

PostAuthor.defaultProps = {};

export default PostAuthor;
