import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import { useActions, useClick, useData } from "state/hooks";

import { assignNodeRole, removeNodeRole } from "./../state/actions";

import { useNodeRoles } from "./../hooks";

function Role(props) {
    const { id } = props;
    const { name } = useData({ type: "db.node-roles", id });
    const click = useClick(props);

    return <div onClick={click}>{name}</div>;
}

export function AssignNodeRoles(props) {
    const { node, id } = props;
    const { roles } = useData({ type: "app.node-roles", id });
    const { items } = useNodeRoles(node);
    const assigned = roles || [];
    const available = items.filter((id) => assigned.indexOf(id) === -1);

    const { onAdd, onRemove } = useActions({
        onAdd: assignNodeRole,
        onRemove: removeNodeRole,
    });

    const addRole = useCallback(
        (roleId) => {
            onAdd(id, roleId);
        },
        [id, onAdd]
    );

    const removeRole = useCallback(
        (roleId) => {
            onRemove(id, roleId);
        },
        [id, onRemove]
    );

    return (
        <div className={"cols gap-lg pad-6"}>
            <div className={"rows"}>
                <div>Available roles</div>
                {available.map((id) => (
                    <Role key={id} id={id} onClick={addRole} />
                ))}
            </div>
            <div className={"rows"}>
                <div>Assigned roles</div>
                {assigned.map((id) => (
                    <Role key={id} id={id} onClick={removeRole} />
                ))}
            </div>
        </div>
    );
}

AssignNodeRoles.propTypes = {};
AssignNodeRoles.defaultProps = {};

export default AssignNodeRoles;
