import { useSelector } from "react-redux";

function selectProgress(state, progressId) {
    if (!progressId) return { status: "pending" };
    return state?.notifications?.notifications[progressId] || {};
}

export function useOperationProgress(id) {
    return useSelector((state) => selectProgress(state, id));
}
