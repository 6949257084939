import React from 'react';
import PropTypes from 'prop-types';

import {useNodeData, useThread} from "joynt/hooks";
import SidebarItem from "../SidebarItem";
import PostIdentity from "joynt/post/PostIdentity";
import {NodeTypeIcon} from "joynt/components/Sidebar/Icons";

export function Chat(props) {
    const {id, variant, maxAvatars} = props;
    const {name, subtype, published} = useNodeData(id);
    const {genericName, identities, isPrivateChat} = useThread(id);

    const showAvatars = identities.length <= maxAvatars
        && variant === "channel"
        && isPrivateChat;

    return <SidebarItem
        label={name || genericName}
        icon={showAvatars
            ? <PostIdentity identities={identities} />
            : <NodeTypeIcon
                type={subtype}
                isRestricted={!showAvatars ? !published : false}
            />}
        {...props}
    />;
}

Chat.propTypes = {
    maxAvatars: PropTypes.number
};
Chat.defaultProps = {
    maxAvatars: 1
};

export default Chat;
