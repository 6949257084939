import React from "react";
import Property from "./Property";
import SelectField from "components/fields/select/Select";

//import PropTypes from 'prop-types';

export function Select(props) {
    const { value, placeholder } = props;

    return (
        <Property label={"Wyżywienie"} isEmpty={!value}>
            <SelectField {...props} label={placeholder} />
        </Property>
    );
}

Select.propTypes = {};

Select.defaultProps = {};

export default Select;
