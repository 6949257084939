import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import MainStageItem from "./MainStageItem";
import {useNodeData} from "joynt/hooks";
import EventTimer from "joynt/components/LiveNow/EventTimer";
import {NODE_TYPE_EVENT} from "joynt/config";
import {useNodePopover} from "joynt/components/NodeInfo/hooks";

export function MainStageItemContainer(props) {

    const {id, workspace} = props;
    const {event_state, name} = useNodeData(id);
    const {subtype} = useNodeData(workspace);
    const isLive = event_state === "live";
    const {onShow, onHide} = useNodePopover();

    const show = useCallback(e => {
        onShow(id, e.currentTarget);
    }, [onShow, id]);

    if (!name) return null;

    return (<MainStageItem
        {...props}
        label={subtype === NODE_TYPE_EVENT
            ? "Event stage"
            : "Workspace stage"}
        isLive={isLive}
        name={name}
        timer={isLive ? <EventTimer id={id} /> : null}
        onMouseEnter={show}
        onMouseLeave={onHide}
    />);

}

MainStageItemContainer.propTypes = {
    id: PropTypes.string
};
MainStageItemContainer.defaultProps = {};

export default MainStageItemContainer;