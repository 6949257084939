import React from 'react';
import {connect} from 'react-redux';
import {selectEntity} from "state/selectors/data";
import MuiAvatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
import {thumbnail} from "util/thumbnail";

function getImage(media) {
    if (media && media.length > 0)
        return media[0];
}

const avatarClasses = (active, color) => {
    color = color ? `bg-${color}` : 'primary';
    if (!active) color = 'light-c50';
    return {
        root: 'list-item-avatar',
        colorDefault: active ? color + ' active' : color
    }
};

const mapStateToProps = (store, props) => {
    const mediaId = getImage(props.media);
    const mediaType = props.mediaType || 'db.media';
    const media = mediaId
        ? selectEntity(store, mediaType, mediaId)
        : null;
    return {
        src: media
            ? thumbnail(media.file, [64,64])
            : null
    }
};

const mapDispatchToProps = {};

class Avatar extends React.PureComponent {

    render() {
        const { src, active, icon, color, style } = this.props;
        return src ? <MuiAvatar
                classes={avatarClasses(active, color)}
                style={style}
                src={src}
            >{src}</MuiAvatar>
            : <MuiAvatar
                classes={avatarClasses(active, color)}
                style={style}
            >
                <Icon defaultType={'mui'}>{icon}</Icon>
        </MuiAvatar>;

    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Avatar);