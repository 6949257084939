import React from 'react';
//import PropTypes from 'prop-types';

import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import Price from "playground/booking/Price";

export default class ProductItem extends React.Component {

    render() {
        const { title, price } = this.props.data;
        return (<div>
            <div>{title}</div>
            <div>
                <Price price={price} />
            </div>
        </div>);
    }

}

export const ProductItemIterator = ArrayIterator(withData(ProductItem));

ProductItem.propTypes = {};