import {useContext, useMemo} from "react";
import ApiContext from "containers/context/ApiContext";
import {useDispatch} from "react-redux";

export function useApiActions(actionsMap) {
    const actions = useMemo(() => {
        return actionsMap;
        // eslint-disable-next-line
    }, Object.values(actionsMap));

    const apiContext = useContext(ApiContext);
    const dispatch = useDispatch();

    return useMemo(() => {
        const boundActions = {};
        for (let actionName in actions) {
            let action = actions[actionName];
            let boundAction = action(apiContext);
            boundActions[actionName] = (...args) => dispatch(boundAction(...args));
        }
        return boundActions;
    }, [actions, dispatch, apiContext]);
}