import { useActions } from "state/hooks";
import { change, create } from "state/actions/data";

export default function useFileActions() {
    const { onChange, onCreate } = useActions({
        onChange: change,
        onCreate: create,
    });

    const handleChange = (id, value) => {
        onChange("theme.files", id, { content: value });
    };

    const handleCreate = (path) => {
        onCreate(
            "theme.files",
            { id: path, content: "", deleted: false },
            "default"
        );
    };

    const handleRename = (id, name) => {
        onChange("theme.files", id, { rename: name });
    };

    const handleDelete = (id) => {
        onChange("theme.files", id, { deleted: true, rename: false });
    };

    return {
        onChange: handleChange,
        onCreate: handleCreate,
        onRename: handleRename,
        onDelete: handleDelete,
    };
}
