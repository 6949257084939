import React, { useCallback } from "react";

import AggregateRoot from "containers/AggregateRoot";
import Editor from "joynt/components/ContentEditor";
import { useField } from "containers/Field";
import { usePostEdit } from "joynt/components/Post";

//import PropTypes from 'prop-types';

import "./page.css";

function PageEditor(props) {
    const { id } = props;

    const [content, setContent] = useField("content", "db.nodes", id);

    const { onUpload } = usePostEdit(id);

    const onSave = useCallback(
        (content) => {
            setContent({ ...content, version: "draft-js.raw" });
        },
        [setContent]
    );

    return (
        <div className={"page"}>
            <Editor
                key={id}
                id={id}
                onSave={onSave}
                onUpload={onUpload}
                initialValue={content}
            />
            {/*<div>*/}
            {/*    <pre>{JSON.stringify(content, null, 4)}</pre>*/}
            {/*</div>*/}
        </div>
    );
}

export function Page(props) {
    const { id } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id}>
            <PageEditor {...props} />
        </AggregateRoot>
    );
}

Page.propTypes = {};
Page.defaultProps = {};

export default Page;
