import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';

import {useActions, useUiFlag} from "state/hooks";
import {feedbackHide} from "state/actions/ui";
import {useSelector} from "react-redux";
import {useNotification} from "joynt/hooks/notifications";
import {selectSnackbarNotification} from "state/selectors/ui";

import Snackbar from "components/Snackbar/Snackbar";
import {icons} from "joynt/notifications/config";
import NotificationDescription from "joynt/notifications/Description";

export function SnackbarContainer(props) {

    const {onClose} = useActions({onClose: feedbackHide});
    const [isOpen] = useUiFlag('snackbar');
    const notification = useSelector(s=>selectSnackbarNotification(s));
    const {id, message: notificationMessage} = notification;
    const seen = useNotification();

    const icon = id
        ? icons[notificationMessage]
        : null;

    const message = id
        ? <NotificationDescription id={id} />
        : <span>{notificationMessage}</span>;

    const close = useCallback(() => {
        onClose();
        if (id) seen(id);
    }, [onClose, seen, id]);

    return <Snackbar
        isOpen={isOpen}
        onClose={close}
        {...notification}
        icon={icon}
        message={message}
    />;

}

SnackbarContainer.propTypes = {};
SnackbarContainer.defaultProps = {};

export default SnackbarContainer;