import React, { useState } from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';
import {
    useActions,
    useFetch,
    useListReload,
    useRegisterList,
} from "state/hooks";
import { useSelector } from "react-redux";
import { selectList } from "state/selectors/data";

import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-full-node-drag";

import "react-sortable-tree/style.css";
import { change } from "state/actions/data";

function listToTree(list) {
    const map = {}; // Create an object to hold all nodes by id
    const roots = []; // Create an array to hold all the roots

    // First pass: create a map of all nodes
    list.forEach((node) => {
        if (!map[node.id]) {
            map[node.id] = { ...node, children: [] };
        }
    });

    // Second pass: set children and find roots
    list.forEach((node) => {
        if (node.parent) {
            if (!map[node.parent]) {
                map[node.parent] = { children: [] }; // Handle case with missing parent
            }
            map[node.parent].children.push(map[node.id]);
        } else {
            roots.push(map[node.id]);
        }
    });

    return roots;
}

function selectCategoriesTree(store) {
    const data = selectList(store, "cms.categories", "cms.categories.all");
    const list = data.map((cat) => ({
        id: cat.id,
        title: cat.title,
        expanded: true,
        parent: cat.parent_category,
    }));
    return listToTree(list);
}

function Placeholder() {
    return <div>placeholder</div>;
}

function nodeTitle({ node, onClick, focused }) {
    return (
        <div
            className={cn("rstcustom__rowTitleContent", {
                "is-focused": focused,
            })}
            onClick={() => onClick(node.path)}
        >
            {node.title}
        </div>
    );
}

export function CategoriesTree(props) {
    const { onSelect, selected } = props;

    const fetchProps = {
        url: "cms/categories",
        type: "cms.categories",
        list: "cms.categories.all",
    };
    useFetch(fetchProps);
    useRegisterList(fetchProps);

    const [id, setId] = useState(null);
    const [onReload] = useListReload(fetchProps.type, fetchProps.list);
    const tree = useSelector((store) => selectCategoriesTree(store));
    const current = onSelect ? selected : id;

    const { onChange } = useActions({ onChange: change });

    const canHaveChildren = () => true;
    const onClick = (id) => {
        if (onSelect) {
            onSelect(id);
        } else {
            setId(id);
        }
    };
    const nodeProps = ({ node }) => ({
        title: nodeTitle({
            node,
            onClick,
            focused: current === node.id,
        }),
    });
    const canDrag = () => true;
    const canDrop = () => true;
    const moveNode = ({ node, nextParentNode }) => {
        const id = node.id;
        const parent = nextParentNode?.id;
        onChange("cms.categories", id, { parent_category: parent });
        console.log("moveNode", node, nextParentNode);
    };

    return (
        <>
            <div onClick={onReload}>Reload</div>
            <SortableTree
                scaffoldBlockPxWidth={14}
                theme={FileExplorerTheme}
                onlyExpandSearchedNodes={false}
                canNodeHaveChildren={canHaveChildren}
                generateNodeProps={nodeProps}
                onMoveNode={moveNode}
                rowHeight={24}
                onChange={() => null}
                canDrag={canDrag}
                canDrop={canDrop}
                treeData={tree}
                placeholderRenderer={Placeholder}
            />
            {/*<pre>{JSON.stringify(tree, null, 2)}</pre>*/}
        </>
    );
}

CategoriesTree.propTypes = {};

CategoriesTree.defaultProps = {};

export default CategoriesTree;
