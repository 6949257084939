import React from "react";
import cn from "classnames";

import PropTypes from "prop-types";
import Icon from "components/Icon";

import "./style.css";

export function PillButton(props) {

    const {
        icon,
        label,
        onClick,
        isActive,
        hasNotifications,
        variant
    } = props;

    const className = cn("pill-button", {
        "is-active": isActive,
        "has-notifications": hasNotifications,
        [`variant-${variant}`]: true
    });

    return (
        <div className={className} onClick={onClick}>
            {icon && <div className={"pill-button__icon"}>
                <Icon size={"xs"}>{icon}</Icon>
            </div>}
            <span className={"pill-button__label"}>{label}</span>
        </div>
    );
}

PillButton.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    hasNotifications: PropTypes.bool,
    variant: PropTypes.oneOf(["default", "outlined"])
};

PillButton.defaultProps = {
    isActive: false,
    hasNotifications: false,
    variant: "default"
}

export default PillButton;
