import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

import {useParticipation} from "joynt/hooks/sessions";
import SessionConfirmActions from "joynt/components/SessionParticipation/SessionConfirmActions";
import Grow from "@material-ui/core/Grow";
import FollowButton from "joynt/components/FollowButton";
import {useNodeData} from "joynt/hooks";

import "./style.css";

export function SessionParticipants(props) {

    const {id} = props;
    const {isConfirmed} = useParticipation(id);
    const {can_edit} = useNodeData(id);

    const [show, setShow] = useState(!isConfirmed);

    useEffect(() => {
        let tm;
        if (show && isConfirmed) {
            tm = setTimeout(() => setShow(false), 5000);
        }
        if (!show && !isConfirmed) {
            setShow(true);
        }
        return () => {
            if (tm) clearTimeout(tm);
        }
    }, [setShow, show, isConfirmed]);

    return <Grow in={show} unmountOnExit={true} mountOnEnter={true}>
        <div className={"session-desc-participants brand-bg-alt"}>
            <div className={"cols cols-middle gap-sm"}>
                {isConfirmed ? "Thank you!" : "Please confirm if you will attend the event"}
            </div>
            <div className={"cols gap-xs"}>
                {can_edit && <FollowButton id={id} />}
                <SessionConfirmActions id={id} />
            </div>
        </div>
    </Grow>;
}

SessionParticipants.propTypes = {};

SessionParticipants.defaultProps = {};

export default SessionParticipants;