import React from "react";
import { useData } from "state/hooks";

//import PropTypes from 'prop-types';

export function RoleLabel(props) {
    const { id } = props;
    const { name } = useData({ type: "db.node-roles", id });
    return <div className={"role-label"}>{name}</div>;
}

RoleLabel.propTypes = {};
RoleLabel.defaultProps = {};

export default RoleLabel;
