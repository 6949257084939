import {selectList} from "state/selectors/lists";

function dayRange(day) {
    const date = new Date(day*1000);
    const nextDate = new Date(day*1000);
    nextDate.setUTCDate(date.getUTCDate() + 1);
    const next = nextDate.getTime()/1000;
    return [day, next];
}

export function selectDailySessions(store, list, day) {
    const ids = selectList(store, "db.nodes", list) || [];
    const data = store.data['db.nodes'] || {};
    const range = dayRange(day);

    return ids.filter(id => {
        let item = data[id] || {};
        return item.event_start >= range[0] && item.event_start < range[1];
    });
}
