import React from "react";
import { useFetch, useRouteParam } from "state/hooks";
import env from "util/env";
import Fetch from "containers/Fetch";
//import PropTypes from 'prop-types';

const ENDPOINT = env("ZUU_CONSOLE_API_ENDPOINT");

export default function FetchPageData(props) {
    const { children } = props;
    const [service] = useRouteParam("service");
    const [id] = useRouteParam("id");
    const [type] = useRouteParam("resource");
    const [lang] = useRouteParam("lang");

    const url = `${ENDPOINT}/v2/cms/page/?service=${service}&id=${id}&type=${type}&lang=${lang}`;

    useFetch({
        type: "theme.files",
        url: "cms/theme/files?v3=true&v4=true",
        list: "default",
        fetchId: "theme-files",
    });

    return (
        <Fetch
            list={"default"}
            url={url}
            fetchId={"front.page"}
            enable={true}
            useContext={false}
        >
            {children}
        </Fetch>
    );
}

FetchPageData.propTypes = {};
FetchPageData.defaultProps = {};
