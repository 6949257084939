import React from "react";
//import PropTypes from 'prop-types';

import Popover from "components/Popover";
import Property from "./Property";

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export function PropertyPopover(props) {
    const { children, value, label, placeholder, ...other } = props;
    return (
        <Popover
            trigger={({ onClick }) => (
                <Property
                    onClick={onClick}
                    label={label}
                    placeholder={placeholder}
                    isEmpty={!value}
                >
                    {value ? (
                        <span dangerouslySetInnerHTML={{ __html: value }} />
                    ) : null}
                </Property>
            )}
            anchorOrigin={anchorOrigin}
        >
            {children}
        </Popover>
    );
}

PropertyPopover.propTypes = {};

PropertyPopover.defaultProps = {};

export default PropertyPopover;
