import React from "react";
//import PropTypes from 'prop-types';

import { Route as BaseRoute, Switch } from "react-router";
import Entrypoint from "joynt/Entrypoint";
import authRoutes from "components/auth/routes";

import WelcomeView from "joynt/welcome";
import JoyntApp from "joynt/App";
import JoyntAppPublic from "joynt/AppPublic";

import Login from "joynt/screens/Login";
import SessionRecordView from "joynt/components/SessionRecordView";
import { routes } from "joynt/dev";
import EntrypointPublic from "joynt/EntrypointPublic";

const authProps = {
    LoginLayout: Login,
};

function Route(props) {
    const { children, ...other } = props;
    return (
        <BaseRoute {...other}>
            <Entrypoint>{children}</Entrypoint>
        </BaseRoute>
    );
}

export default function Router() {
    return (
        <Switch>
            {authRoutes(authProps)}
            <Route exact path="/welcome">
                <WelcomeView />
            </Route>
            <BaseRoute exact path="/session-record">
                <SessionRecordView />
            </BaseRoute>
            {routes()}
            <BaseRoute path="/public">
                <EntrypointPublic>
                    <JoyntAppPublic />
                </EntrypointPublic>
            </BaseRoute>
            <Route path="/:path?">
                <JoyntApp />
            </Route>
        </Switch>
    );
}

Router.propTypes = {};
