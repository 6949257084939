import React from "react";
//import PropTypes from 'prop-types';
import WebsiteDomain from "./WebsiteDomain";
import { useData, useField, useFormField } from "state/hooks";
import { MenuItems as BaseMenuItems } from "playground/cms/views/Resources";
import ContextMenu from "components/ContextMenu";
import MenuItem from "components/context-menu/MenuItem";
import { useDomainCertificate } from "playground/console/views/Domains/useDomainCertificate";
import AggregateRoot from "containers/AggregateRoot";

function MenuItems(props) {
    const { id, onTogglePrimary, isPrimary } = props;
    const { onRenew, statusId } = useDomainCertificate(id);
    const { onChange: changeCertificate, value: certEnabled } =
        useFormField("secure_free");
    const toggleCert = () => changeCertificate(!certEnabled);
    return [
        <MenuItem
            key={"ctx-cert"}
            icon={"mui-renew"}
            onClick={onRenew}
            primaryText={"Renew certificate"}
        />,
        <MenuItem
            key={"ctx-primary"}
            icon={"mui-star"}
            onClick={onTogglePrimary}
            primaryText={isPrimary ? "Remove primary" : "Set primary"}
        />,
        <MenuItem
            key={"ctx-cert"}
            icon={"mui-lock"}
            onClick={toggleCert}
            primaryText={
                certEnabled ? "Disable certificate" : "Enable certificate"
            }
        />,
        <BaseMenuItems key={"ctx-base"} {...props} />,
    ];
}

export function WebsiteDomainContainer(props) {
    const { type, id } = props;
    const { name, service, active } = useData(props);

    const { value: primaryDomains, onChange: changePrimaryDomains } = useField(
        "console.services.website",
        service,
        "canonical_domains"
    );

    const isPrimary = (primaryDomains || []).includes(id);
    const cert = useDomainCertificate(id);

    const togglePrimary = () => {
        const prev = primaryDomains || [];
        const next = prev.includes(id)
            ? prev.filter((d) => d !== id)
            : [...prev, id];
        changePrimaryDomains(next);
    };

    return (
        <AggregateRoot type={type} id={id} disableSubmit={true}>
            <ContextMenu
                type={type}
                id={id}
                isPrimary={isPrimary}
                onTogglePrimary={togglePrimary}
                RenderMenuItems={MenuItems}
            >
                {({ onMenuOpen }) => (
                    <WebsiteDomain
                        {...props}
                        name={name}
                        isActive={!!active}
                        isPrimary={isPrimary}
                        certificate={cert}
                        onTogglePrimary={togglePrimary}
                        onMenuOpen={onMenuOpen}
                    />
                )}
            </ContextMenu>
        </AggregateRoot>
    );
}

WebsiteDomainContainer.propTypes = {};

WebsiteDomainContainer.defaultProps = {};

export default WebsiteDomainContainer;
