import Activity from "./Activity";
import People from "./People";
import Inbox from "./Inbox";
import Profile from "./Profile";
import Share from "./Share";
import Calendar from "./Calendar";

export default {
    activity: Activity,
    people: People,
    inbox: Inbox,
    profile: Profile,
    share: Share,
    calendar: Calendar
}