import React, {useCallback, useContext, useEffect, useState} from 'react';
import cn from "classnames";
import {AgoraContext} from "joynt/meeting/agora-ng/context/AgoraContext";
import Icon from "components/Icon";
import {useIdentity} from "joynt/hooks/participants";
import {useAgoraClient} from "joynt/meeting/agora-ng/hooks";
import {AudioIcon, VideoIcon} from "joynt/meeting/stage/VideoTile";
//import PropTypes from 'prop-types';

function RenderClients({data}) {
    return <div className={'cols gap-lg'}>
        {data.map(client => <RenderClient key={client.id} data={client} />)}
    </div>
}

function RenderClient({data}) {
    return <div className={'rows'}>
        <div className={'cols cols-middle gap-xs'}>
            <strong>{data.id}</strong>
            <div><Icon>mui-person</Icon> {data.users}</div>
            <div><Icon>mui-mic</Icon> {data.audio}</div>
            <div><Icon>mui-videocam</Icon> {data.video}</div>
        </div>
        <div className={'o-50 type-body-sm'}>{data.state}</div>
    </div>
}

function RenderUsers({data}) {
    return <div className={'grid gap-sm grid-2'}>
        {data.map(user => <RenderUser key={user.id} data={user} />)}
    </div>
}

function RenderUser({data}) {
    const {id} = data;
    const {name} = useIdentity(id);

    const agora = useContext(AgoraContext);
    const client = useAgoraClient();

    const subscribe = useCallback(async () => {
        await client.unmountTile(id);
        await client.mountTile(id);
    }, [client, id]);

    return <div className={cn('rows pad-sm', 'debug-user', {
        subscribed: data.subscribed,
        'has-video': data.publishedVideo,
        'has-track': data.video
    })}>
        <div>{name || id}</div>
        <div className={'cols cols-middle gap-xs'}>
            <AudioIcon on={data.publishedAudio} onClick={()=>agora.peerCmd(id, 'toggle-audio')} />
            <VideoIcon on={data.publishedVideo} onClick={()=>agora.peerCmd(id, 'toggle-video')} />
            <div onClick={subscribe}><Icon>mui-refresh</Icon></div>
            <div className={'o-50'}>{data.client}</div>
        </div>
        {data.subError ? <div>{data.subError}</div> : null }
    </div>
}

export default function Client(props) {

    const [data, setData] = useState({});

    const client = useContext(AgoraContext);

    useEffect(() => {
        client.watch(setData);
        return () => client.watch(null);
    }, [client, setData]);

    const get = useCallback(() => {
        setData(client.debug());
    }, [client, setData]);

    return (<div className={'rows pad-md gap-md'}>
        <div className={'cols gap-xs'}>
            <div onClick={get}>refresh</div>
            <div>{data.ts}</div>
            <div onClick={()=>console.log(client)}>AgoraClient</div>
            <div onClick={()=>console.log(client.rtc)}>AgoraSdkWrapper</div>
        </div>
        {data.clients ? <RenderClients data={data.clients} /> : null}
        {data.users ? <RenderUsers data={data.users} /> : null}
    </div>);

}

Client.propTypes = {};
Client.defaultProps = {};
