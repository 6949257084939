import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import Preloader from "components/Preloader";
import Timer from "joynt/components/Timer";
import Button from "components/Button";
import { TimerProgressBar } from "joynt/components/ProgressBar";

import "./style.css";

export function StageStatusMessage(props) {
    const {
        message,
        duration,
        started,
        isSpeaker,
        canProgress,
        isPending,
        onProgress,
        next_label,
        next,
        children,
    } = props;

    return (
        <div
            className={cn("stage-message dark-mode bg", {
                "is-pending": isPending,
            })}
        >
            <Preloader visible={isPending} />
            {canProgress && message && (
                <div className={"stage-message__text"}>{message}</div>
            )}
            <div className={"stage-message__content"}>
                {duration && started && (
                    <div className={"stage-message__timer"}>
                        <Timer
                            countdown={!!duration}
                            start={duration ? started + duration : started}
                            humanTimeThreshold={false}
                        />
                    </div>
                )}
                {!canProgress && message && (
                    <div className={"stage-message__text"}>{message}</div>
                )}
                {canProgress && (
                    <Button
                        onClick={onProgress}
                        variant={next === "end" ? "contained" : "outlined"}
                        color={"primary"}
                    >
                        {next_label}
                    </Button>
                )}
                {children}
            </div>
            {duration && isSpeaker && (
                <TimerProgressBar start={started} target={started + duration} />
            )}
        </div>
    );
}

StageStatusMessage.defaultProps = {
    next_label: "Next",
    isPending: false,
    isSpeaker: false,
    canProgress: false,
};

StageStatusMessage.propTypes = {};

export default StageStatusMessage;
