import React from "react";

import cn from "classnames";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import IconPickerDialog from "./IconPickerDialog";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Icon from "components/Icon";

import "./style.css";

function PickerField({ onClick, onClear, value, label }) {
    const clear = (e) => {
        e.stopPropagation();
        onClear();
    };
    return (
        <div
            onClick={onClick}
            className={"icon-picker-field cols gap-xs cols-middle cols-spread"}
        >
            <div className={"cols cols-middle gap-xs"}>
                <Avatar className={cn("avatar-lg", { "t-primary": !!value })}>
                    <Icon>{value || "mui-more_horiz"}</Icon>
                </Avatar>
                <Button variant={"text"} onClick={() => null}>
                    {label || "Change icon"}
                </Button>
            </div>
            {value ? <IconButton onClick={clear} icon={"mui-close"} /> : null}
        </div>
    );
}

function IconPickerDense({ onClick, onClear, value, label }) {
    const clear = (e) => {
        e.stopPropagation();
        onClear();
    };
    return (
        <div
            onClick={onClick}
            className={cn("input-dense icon-picker-dense", {
                "is-empty": !value,
            })}
        >
            <div className={"icon-picker-dense-value"}>
                {value && (
                    <div className={"icon-picker-dense-icon"}>
                        <Icon>{value}</Icon>
                    </div>
                )}
                <div className={"icon-picker-dense-label"}>
                    {label}
                    {value && <> ({value})</>}
                </div>
            </div>
            {value ? (
                <IconButton onClick={clear} icon={"mui-close"} size={"small"} />
            ) : null}
        </div>
    );
}

export default function IconPicker(props) {
    const [open, setOpen] = React.useState(false);

    const { label, value, libraries, variant, onChange, render } = props;

    let FieldComponent = props.PickerComponent;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClear = () => onChange(null);

    const select = (v) => {
        onChange(v);
        setOpen(false);
    };

    if (variant === "inline")
        return (
            <div className={"desktop@w-sm h-md rows overflow-hide"}>
                <IconPickerDialog onSubmit={select} libraries={libraries} />
            </div>
        );

    if (variant === "dense") FieldComponent = IconPickerDense;

    return (
        <React.Fragment>
            <FieldComponent
                label={label}
                onClick={handleOpen}
                onOpen={handleOpen}
                onClear={handleClear}
                value={value}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{ root: "dialog-top" }}
            >
                <div className={"rows h-lg overflow-hide"}>
                    <IconPickerDialog onSubmit={select} libraries={libraries} />
                </div>
            </Dialog>
        </React.Fragment>
    );
}

IconPicker.defaultProps = {
    PickerComponent: PickerField,
};
