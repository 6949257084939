import React from "react";
import PropTypes from "prop-types";

import IconButton from "components/IconButton";
//import KeepIcon from "joynt/components/KeepIcon";

import "./style.css";

export function SpaceHeader(props) {
    const {
        name,
        tabs,
        canEdit,
        onEdit,
        parentLink,
        userOptions,
        children,
    } = props;

    return (
        <div className={"space-header"}>
            <div className={"space-header__top"}>
                <div className={"space-header__info"}>
                    {parentLink && (
                        <div className={"space-header__parent"}>
                            {parentLink}
                        </div>
                    )}
                    <div className={"space-header__name"}>{name}</div>
                    <div className={"space-header__icons"}>
                        {canEdit && onEdit && (
                            <IconButton
                                onClick={onEdit}
                                size={"small"}
                                icon={"mui-settings"}
                            />
                        )}
                        {/*<KeepIcon id={id} />*/}
                    </div>
                </div>
                {userOptions && (
                    <div className={"space-header__actions"}>{userOptions}</div>
                )}
            </div>
            {tabs && <div>{tabs}</div>}
            {children}
        </div>
    );
}

SpaceHeader.propTypes = {
    name: PropTypes.string,
    onEdit: PropTypes.func,
    canEdit: PropTypes.bool,
};

SpaceHeader.defaultProps = {
    canEdit: false,
};

export default SpaceHeader;
