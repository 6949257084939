import React from 'react';
import {connect} from 'react-redux';

import {selectList} from "state/selectors/lists";

import SideNav from 'components/user-menu/SideNav';
import {pushRouteParams} from "state/actions/router";

const mapStateToProps = (store, props) => {
    return {
        items: selectList(store, 'app.user-menu', 'pages')
        //selectPinnedResources(store),
    }
};

const mapDispatchToProps = {
    onPushRouteParams: pushRouteParams
};

class SideNavContainer extends React.Component {

    render() {
        return (<SideNav {...this.props} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideNavContainer);