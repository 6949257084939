import React from "react";
import ContextMenu from "components/ContextMenu";
//TODO: replace with joynt/components/IconButton
import IconButton from "components/IconButton";
import MenuItem from "components/context-menu/MenuItem";

export default function PostContextMenu(props) {
    const {
        onDelete,
        onEdit,
        //onShare,
        onMove,
        onPresent,
        onUpgrade,
    } = props;

    const items = [];
    //if (onShare) items.push(<MenuItem onClick={onShare} icon={'mui-ios_share'} primaryText={'Share to session'} />);

    if (onPresent)
        items.push(
            <MenuItem
                key={"post-present"}
                onClick={onPresent}
                icon={"mui-ios_share"}
                primaryText={"Present"}
            />
        );

    if (onEdit) {
        items.push(
            <MenuItem
                key={"post-edit"}
                onClick={onEdit}
                icon={"mui-edit"}
                primaryText={"Edit"}
            />
        );
    }

    if (onDelete) {
        items.push(
            <MenuItem
                key={"post-delete"}
                onClick={onDelete}
                icon={"mui-delete"}
                primaryText={"Delete"}
            />
        );
    }

    if (onMove) {
        items.push(
            <MenuItem
                key={"post-move"}
                onClick={onMove}
                primaryText={"Move post"}
            />
        );
    }

    if (onUpgrade) {
        items.push(
            <MenuItem
                key={"post-upgrade"}
                icon={"mui-upgrade"}
                onClick={onUpgrade}
                primaryText={"Upgrade editor"}
            />
        );
    }

    return (
        <ContextMenu items={items}>
            {({ onMenuOpen }) => (
                <IconButton
                    icon={"mui-more_vert"}
                    onClick={onMenuOpen}
                    size={"small"}
                />
            )}
        </ContextMenu>
    );
}

PostContextMenu.propTypes = {};
PostContextMenu.defaultProps = {};
