import React, { useCallback, useEffect, useState } from "react";
//import PropTypes from 'prop-types';

import {
    findCanvasRoot,
    findFrameTargetElement,
    findRootElement,
    calculateFramePosition,
} from "./dom";
import { useData } from "state/hooks";

const frameStyle = (state) => {
    return state.id
        ? {
              left: state.x + "px",
              top: state.y + "px",
              width: state.width + "px",
              height: state.height + "px",
              opacity: 1,
          }
        : {
              opacity: 0,
          };
};

export function FocusFrame(props) {
    const { value, state: editorState } = props;
    const { id } = value || {};
    const [state, setState] = useState({});
    const root = findCanvasRoot();

    const data = useData({
        type: editorState.focusType,
        id: editorState.focusId,
    });

    const updatePosition = useCallback((root, value) => {
        const target = findFrameTargetElement(root, value);
        if (!target) {
            setState({});
            return;
        }
        setState({
            id: value.id,
            ...calculateFramePosition(target, findRootElement()),
        });
        return target;
    }, []);

    // useEffect(() => {
    //     const listener = (e) => {
    //         //console.log("scroll", e);
    //         updatePosition(root, value);
    //     };
    //     root.addEventListener("scroll", listener);
    //     return () => {
    //         root.removeEventListener("scroll", listener);
    //     };
    // }, [root, value, updatePosition]);

    useEffect(() => {
        const el = updatePosition(root, value);
        if (el) {
            //el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [root, value, updatePosition]);

    const style = frameStyle(state);
    const type = data.element_type || editorState.focusType;
    const typeLabel = data.style || type; //[type, data.style].filter(Boolean).join(".");

    const labelClick = (e) => {
        e.stopPropagation();
        alert("FocusFrame clicked");
    };

    return (
        <div className={"focus-frame"} data-focus={state.id} style={style}>
            <div className={"focus-frame-label"} onClick={labelClick}>
                {typeLabel} / {editorState.style}{" "}
                {editorState?.click?.list?.itemType}
            </div>
        </div>
    );
}

FocusFrame.propTypes = {};

FocusFrame.defaultProps = {};

export default FocusFrame;
