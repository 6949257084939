import { useActions, useApiActions } from "state/hooks";
import {
    createPostSubmit,
    postUploadMultiple,
    resetPost,
    submitPost,
} from "joynt/state/actions/posts";
import { createPostContent } from "joynt/state/actions/posts";
import { useCallback, useMemo } from "react";
import { change, pathDetach } from "state/actions/data";
import { entityDeleteCallback } from "state/actions/delete";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import { useForm } from "joynt/hooks";

export function usePostEdit(id, list, type) {
    const isDraft = useSelector((s) => selectIsDraft(s, "db.nodes", id));

    const { onReject, onDetach, onChange } = useActions({
        onReject: resetPost,
        onDetach: pathDetach,
        onChange: change,
    });

    const {
        onCreate,
        onSubmit,
        onSubmitCreate,
        onUpload,
        onDelete,
    } = useApiActions({
        onCreate: createPostContent,
        onSubmitCreate: createPostSubmit,
        onSubmit: submitPost,
        onUpload: postUploadMultiple,
        onDelete: entityDeleteCallback,
    });

    const submit = useCallback(() => {
        if (isDraft) {
            onSubmitCreate(id, list, type);
        } else {
            onSubmit(id);
        }
    }, [onSubmit, onSubmitCreate, isDraft, id, list, type]);

    const reject = useCallback(() => {
        onReject(id);
    }, [onReject, id]);

    const upload = useCallback(
        (files, post) => {
            onUpload(null, id, files, post);
        },
        [onUpload, id]
    );

    const create = useCallback(
        (type) => {
            onCreate({ parent: id, subtype: type });
        },
        [id, onCreate]
    );

    const deletePost = useCallback(() => {
        onDelete(reject, "db.nodes", id, null, `joynt/nodes/:id`);
    }, [onDelete, reject, id]);

    const removeContent = useCallback(
        (contentId) => {
            onDetach(["db.nodes", id, "posts"], contentId);
        },
        [onDetach, id]
    );

    const changeNode = useCallback(
        (data) => {
            onChange("db.nodes", id, data);
        },
        [onChange, id]
    );

    const { isValid, onValidate } = useForm("db.nodes", id);

    return useMemo(
        () => ({
            isDraft,
            isValid,
            onSubmit: submit,
            onValidate,
            onReject: reject,
            onUpload: upload,
            onDelete: deletePost,
            onCreateContent: create,
            onDeleteContent: removeContent,
            onChange: changeNode,
        }),
        [
            isDraft,
            isValid,
            submit,
            onValidate,
            reject,
            upload,
            deletePost,
            create,
            removeContent,
            changeNode,
        ]
    );
}
