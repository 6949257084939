import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import FontWeights from "./FontWeights";
import FontPreview from "playground/theme/FontPreview";

export function FontFamily(props) {
    const { value, label, onChange, onSelectFont } = props;
    const { source, family } = value;

    const change = useCallback(
        (nextValue) => {
            onChange({ ...value, ...nextValue });
        },
        [onChange, value]
    );

    return (
        <div className={"preset-font-family"}>
            <div className={"o-50"}>{label}</div>
            <div className={"grow cols"}>
                <div className={"rows grow"}>
                    <div
                        className={"preset-font-family-preview"}
                        onClick={onSelectFont}
                    >
                        <FontPreview family={family} source={source} />
                    </div>
                    <FontWeights
                        value={value}
                        source={source}
                        family={family}
                        onChange={change}
                    />
                </div>
            </div>
        </div>
    );
}

FontFamily.propTypes = {};

FontFamily.defaultProps = {
    value: {},
};

export default FontFamily;
