import React from "react";
//import PropTypes from 'prop-types';

import SessionPreviewSection from "./../SessionPreviewSection";

export function ParticipantsList(props) {
    const { children, ...other } = props;
    return (
        <SessionPreviewSection title={"Attendees"} {...other}>
            {children}
        </SessionPreviewSection>
    );
}

ParticipantsList.propTypes = {};
ParticipantsList.defaultProps = {};

export default ParticipantsList;
