import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {useEdgesData, useNodeData} from "joynt/hooks";
import {useSessionState} from "joynt/hooks/sessions";
import {useRouter} from "joynt/hooks/channels";

import {EventTimer} from "joynt/components/LiveNow";
import NodeInfo from "./NodeInfo";
import AvatarGroup from "joynt/components/AvatarGroup";

export function NodeInfoContainer(props) {

    const {id} = props;

    const {
        stage,
        isConnected,
        isConnecting,
        isLive,
        isStageSession,
        onJoinSession,
        presence
    } = useSessionState(id);

    const {onLink} = useRouter();
    const link = useCallback(() => onLink(id), [onLink, id]);

    const {name} = useNodeData(id);
    const {parent} = useEdgesData(id);
    const parentId = isStageSession ? stage : parent;

    const {name: parentName, workspace} = useNodeData(parentId);
    let stageName = parentName;
    if (workspace) stageName = "Workspace stage";

    return (<NodeInfo
        {...props}
        name={name}
        stageName={stageName}
        isLive={isLive}
        isConnected={isConnected}
        isConnecting={isConnecting}
        onJoin={onJoinSession}
        onLink={link}
        avatars={<AvatarGroup ids={presence} />}
        timer={<EventTimer id={id} />}
    />);

}

NodeInfoContainer.propTypes = {
    id: PropTypes.string
};
NodeInfoContainer.defaultProps = {};

export default NodeInfoContainer;