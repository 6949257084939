import React from 'react';
// import PropTypes from 'prop-types';

export function DrawerContent(props) {

    const {children, view} = props;

    return (
        <>
            {children || view}
        </>
    );

}

DrawerContent.propTypes = {
};

DrawerContent.defaultProps = {
};

export default DrawerContent;
