import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import {createSelector} from "reselect";
import StyleOption from "cms/ui/style/StyleOption";
import TagsSelect from "cms/ui/style/TagsSelect";
import StyleElementValue from "cms/ui/style/StyleElementValue";
import Icon from "components/Icon";

const selectStylesIndex = createSelector([
    store => store.data['cms.styles'].options
], (options) => {
    let index = {};
    Object.keys(options).forEach(group => {
        options[group].forEach(cn => {
            index[cn] = group;
        });
    });
    return index;
});

const selectOptions = (store, id) => {
    let styles = store.data['cms.styles'];
    let elementRoles = styles.element_roles[id] || [];
    let options = [];
    elementRoles.forEach(role => {
        let roleOptions = styles.option_roles[role];
        options = options.concat(roleOptions);
    });
    return options;
};

const selectOptionType = (store, id) => {
    let styles = store.data['cms.styles'];
    let optionType = styles.option_types[id];
    if (optionType) return optionType.type;
    return null;
};

const matchOptions = (value, index) => {
    if (!value) return [];
    if (!value.forEach) return [];
    let options = [];
    value.forEach(className => {
        let optionGroup = index[className];
        if (optionGroup && options.indexOf(optionGroup) === -1) {
            options.push(optionGroup);
        }
    });
    return options;
};

const nullArr = [];

const mapStateToProps = (store, props) => {
    let value = props.value || nullArr;
    let index = selectStylesIndex(store);
    let type = selectOptionType(store, props.id);
    if (value && (type === 'value' || type === 'select') && !Array.isArray(value)) value = [value];
    let valueOptions = matchOptions(value || [], index);
    let allOptions = selectOptions(store, props.id);
    let options = valueOptions.slice();
    let otherOptions = allOptions.filter(id=>options.indexOf(id)===-1);
    let classes = value && value.filter ? value.filter(v => !index[v]) : nullArr;
    return {
        index,
        type,
        options,
        otherOptions,
        classes
    }
};

const mapDispatchToProps = {};

class StyleElement extends React.PureComponent {

    add = (value) => {
        const { id, onChange } = this.props;
        const currentValue = this.props.value || [];
        let nextValue = currentValue.slice();
        if (nextValue.indexOf(value) === -1) {
            nextValue.push(value);
        }
        onChange(id, nextValue);
    };

    remove = (value) => {
        const { id, onChange } = this.props;
        const currentValue = this.props.value || [];
        const nextValue = currentValue.filter(v=>v!==value);
        onChange(id, nextValue);
    };

    change = (nextValue) => {
        const { id, onChange } = this.props;
        onChange(id, nextValue.slice());
    };

    render() {
        const {
            id,
            onFocus,
            options,
            otherOptions,
            selected,
            value,
            classes,
            type
        } = this.props;

        return <div className={cn('style-element-panel', {
            'has-value': !!value,
            'selected': selected
        })}>
            <div
                className={cn('form-section-heading', {
                    'with-arrow': !type
                })}
                onClick={!type ? onFocus : null}
            >
                {!type ? <div className={'dropdown-arrow'}>{selected
                    ? <Icon>mui-arrow_drop_down</Icon>
                    : <Icon>mui-arrow_right</Icon>}
                </div> : null }
                <StyleElementValue
                    id={id}
                    type={type}
                    value={value ? value[0] : ''}
                    onChange={(value)=>this.change([value])}
                />
            </div>
            {!type && (value || selected) ? <div
                className={'style-element-content rows'}
            >
                <div className={'rows'}>
                    {options.map(opt => {
                        return <StyleOption
                            key={opt}
                            id={opt}
                            value={value}
                            onChange={this.change}
                        />;
                    })}
                </div>
                <TagsSelect
                    value={classes}
                    className={'style-option style-option--classes'}
                    tagClassName={'style-class'}
                    onAddTag={this.add}
                    onRemoveTag={this.remove}
                    selected={selected}
                />
                {selected ? <div className={'rows o-50'}>
                        {otherOptions.map(opt => {
                            return <StyleOption
                                key={opt}
                                id={opt}
                                value={value}
                                onChange={this.change}
                            />;
                        })}</div>
                    : null}
            </div> : null}
        </div>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StyleElement);