import React from 'react';
import PropTypes from 'prop-types';
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import List from "containers/List";
import Icon from "components/Icon";

class Amenity extends React.PureComponent {
    render() {
        const { icon, title } = this.props.data;
        if (!icon) return null;
        return <div className={'cols cols-middle gap-xs'}>
            <Icon>{icon}</Icon>
            <div>{title}</div>
        </div>;
    }
}

export const AmenityIterator = ArrayIterator(withData(Amenity));

export default class Amenities extends React.PureComponent {

    render() {
        const { items } = this.props;
        return (<>
            <List
                type={'cms.enum-values'}
                items={items}
                Iterator={AmenityIterator} />
        </>);
    }

}

Amenities.propTypes = {
    items: PropTypes.array
};
Amenities.defaultProps = {
    items: []
};