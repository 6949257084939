import React from "react";
//import PropTypes from 'prop-types';

import FontPreview from "playground/theme/FontPreview";

import "./style.css";

export function FontItem(props) {
    const { family, source, onClick, style } = props;
    return (
        <div className={"font-browser-item"} onClick={onClick} style={style}>
            {family && source && (
                <FontPreview
                    family={family}
                    source={source}
                    className={"font-preview-text"}
                />
            )}
        </div>
    );
}

FontItem.propTypes = {};

FontItem.defaultProps = {};

export default FontItem;
