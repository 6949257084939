import React from "react";
import Popper from "@material-ui/core/Popper";
import BlockTypeList from "./../../BlockTypeList";

//import PropTypes from 'prop-types';

export function BlockTypePopover(props) {
    const { show, anchor, search } = props;
    const e = anchor?.current;

    return (
        <>
            <Popper
                open={!!show && !!e}
                anchorEl={e}
                placement={"bottom-start"}
            >
                <div className={"editor-popover"}>
                    <BlockTypeList {...props} search={search} />
                </div>
            </Popper>
        </>
    );
}

BlockTypePopover.propTypes = {};
BlockTypePopover.defaultProps = {};

export default BlockTypePopover;
