import React, { useState } from "react";

import cn from "classnames";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText } from "@material-ui/core";
import StylesImport from "playground/cms/views/Sections/DragSectionPrompt/StylesImport";
import { FormLayout, OverlayForm } from "playground/cms/Form";
import Radio from "@material-ui/core/Radio";

import "./style.css";

function choices(data, templateData, origin, target) {
    const { type, template, library_export, used_in } = data;
    const result = [];

    if (target !== origin) {
        if (library_export) {
            result.push({
                id: "ref",
                text: "Użyj szablonu z biblioteki",
                info: "Umożliwia używanie tego samego szablonu w wielu projektach",
            });
        }
        result.push({
            id: "clone",
            text: "Skopiuj do projektu",
            info: "Aby niezależnie zmieniać szablon",
        });
        return result;
    }

    if (type === "view") {
        result.push({
            id: "attach",
            text: "Użyj szablonu",
            info: `Utwórz sekcję z szablonem ${data.description}`,
        });
        result.push({
            id: "clone",
            text: "Skopiuj szablon",
            info: "Umożliwia niezależne edytowanie szablonu",
        });
    } else {
        result.push({
            id: "attach",
            text: "Dołącz tą samą sekcję w tym miejscu",
            info: `Zmiany w sekcji będą widoczne na ${used_in?.length} podstronach`,
        });
        result.push({
            id: "clone",
            text: "Skopiuj sekcję",
            info: "Zmiany w sekcji nie będą widoczne na innych podstronach",
        });
        if (template) {
            result.push({
                id: "clone-view",
                text: "Skopiuj sekcję i szablon",
            });
        }
    }

    return result;
}

function Local(props) {
    return <div>local</div>;
}

function Library(props) {
    return <div>library</div>;
}

function Action(props) {
    const { onClick, text, info, children, isSelected } = props;
    return (
        <div
            className={cn("select-action", {
                "is-selected": isSelected,
            })}
            onClick={onClick}
        >
            <div className={"select-action-radio"}>
                <Radio onClick={onClick} checked={isSelected} />
            </div>
            <div className={"rows"}>
                <div className={"select-action-title"}>{text}</div>
                <div className={"select-action-info"}>{info}</div>
            </div>
        </div>
    );
}

function DragSectionPrompt(props) {
    const { id, data, templateData, origin, target, open, onClose, onSelect } =
        props;
    //const { title, description, type, library_ref } = data;

    const actions = choices(data, templateData, origin, target);
    const [selected, setSelected] = useState(actions[0].id);
    const [stylesState, setStylesState] = useState({});

    const crossOrigin = origin !== target;

    const onSubmit = () => {
        if (crossOrigin && selected === "clone") {
            onSelect("clone", { styles: stylesState });
            return;
        }
        onSelect(selected);
    };

    let title = "Dodaj sekcję";

    if (origin !== target) {
        if (target === "local") title = "Importuj sekcję";
        if (origin === "local") title = "Eksportuj sekcję";
    }

    return (
        <OverlayForm
            open={!!open}
            onClose={onClose}
            width={"md"}
            position={"center"}
        >
            <FormLayout>
                <FormLayout.Header>
                    <FormLayout.Title>{title}</FormLayout.Title>
                </FormLayout.Header>
                <FormLayout.Content>
                    <div>
                        <div className={"rows pad-lg gap-lg"}>
                            <div className={"select-actions"}>
                                {actions.map((data, i) => {
                                    return (
                                        <Action
                                            key={i}
                                            {...data}
                                            onClick={() => setSelected(data.id)}
                                            isSelected={selected === data.id}
                                        />
                                    );
                                })}
                            </div>
                            {crossOrigin && selected === "clone" && (
                                <StylesImport
                                    id={id}
                                    onSelect={onSelect}
                                    origin={origin}
                                    target={target}
                                    onSetState={setStylesState}
                                />
                            )}
                        </div>
                    </div>
                </FormLayout.Content>
                <FormLayout.Footer>
                    <FormLayout.Actions
                        onCancel={onClose}
                        onSubmit={onSubmit}
                    />
                </FormLayout.Footer>
            </FormLayout>
        </OverlayForm>
    );
}

export default DragSectionPrompt;
