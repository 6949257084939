import Default from './Default';

import TextField from "./text/TextField";
import Rewire from './Rewire';
import Select from "./select/Select";
import RichText from "./RichText";
import Collection from "./collection/Collection";
import Checkbox from "./checkbox/Checkbox";
import UiColorPicker from './ui-color-picker/UiColorPicker';
import IconPickerField from "./icon-picker/IconPickerField";
import ReferenceField from "./reference/ReferenceField";
import MediaCollection from "./media/MediaCollection";
import Stepper from "components/fields/numeric/Stepper";
import Datepicker from './datepicker/Datepicker';
import FormSection from "components/fields/form-section/FormSection";
import Code from "components/fields/code/Code";
import ColorSchemeField from "components/fields/color-scheme/ColorSchemeField";
import TypographySchemeField from "components/fields/typography-scheme/TypographySchemeField";
import TypographyStylesField from "components/fields/typography-styles/TypographyStylesField";
import GeolocationField from "components/fields/geolocation/GeolocationField";
import OAuthField from "components/fields/oauth";
import DateTimePicker from "components/fields/datepicker/DateTimePicker";
import AvatarUpload from "components/fields/media/AvatarUpload";
import Upload from "components/fields/media/Upload";

export default {
    'text': TextField,
    'rewire': Rewire,
    'select': Select,
    'date': Datepicker,
    'datetime': DateTimePicker,
    'rich': RichText,
    'collection': Collection,
    'checkbox': Checkbox,
    'ui-color-picker': UiColorPicker,
    'icon': IconPickerField,
    'fontawesome': IconPickerField,
    'ref': ReferenceField,
    'media': MediaCollection,
    'stepper': Stepper,
    'tab': FormSection,
    'code': Code,
    'default': Default,
    'colors': ColorSchemeField,
    'typography': TypographySchemeField,
    'type-styles': TypographyStylesField,
    'geolocation': GeolocationField,
    'oauth': OAuthField,
    'avatar': AvatarUpload,
    'upload': Upload
}