import React from "react";
//import PropTypes from 'prop-types';
import SelectComponentType from "./SelectComponentType";

export function SelectComponentTypeContainer(props) {
    const {} = props;
    return <SelectComponentType {...props} />;
}

SelectComponentTypeContainer.propTypes = {};

SelectComponentTypeContainer.defaultProps = {};

export default SelectComponentTypeContainer;
