import React from "react";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";

import "./style.css";
import TextField from "components/fields/text/TextField";

function ListItem(props) {
    const { type, id, onClick, count, ...other } = props;
    const { title } = useData({ type, id });

    return (
        <div className={"item"} onClick={onClick}>
            <div className={"item-label"}>{title}</div>
            {count && <div className={"count-badge"}>{count}</div>}
        </div>
    );
}

function List(props) {
    const { children } = props;
    return (
        <div className={"transfer-list-items"}>{children || "No items"}</div>
    );
}

export function TransferList(props) {
    const { items, value, onToggle, counts, isPending, search, onSearch } =
        props;

    return (
        <>
            <div className={"transfer-list"}>
                <List>
                    <div className={"transfer-list-filter"}>
                        <TextField
                            variant={"search"}
                            value={search}
                            onChange={onSearch}
                            label={"Search"}
                        />
                    </div>
                    {items.length
                        ? items.map((t) => (
                              <ListItem
                                  key={t}
                                  type={"cms.tags"}
                                  id={t}
                                  onClick={() => onToggle(t)}
                                  selected={value.includes(t)}
                                  count={counts[t]}
                              />
                          ))
                        : null}
                </List>
                <List>
                    {value.length
                        ? value.map((t) => (
                              <ListItem
                                  key={t}
                                  type={"cms.tags"}
                                  id={t}
                                  onClick={() => onToggle(t)}
                                  selected={value.includes(t)}
                                  count={counts[t]}
                              />
                          ))
                        : null}
                </List>
            </div>
        </>
    );
}

TransferList.propTypes = {};

TransferList.defaultProps = {
    items: [],
    value: [],
    counts: {},
};

export default TransferList;
