import React, {useCallback} from 'react';
import MenuItem from "components/context-menu/MenuItem";
import PropTypes from 'prop-types';
import {useMemberManagement, useRoleManagement, useRoles} from "joynt/hooks/participants";
import {useRTMControls} from "joynt/meeting/agora-ng/hooks";
import useSessionPresence from "joynt/participants/hooks/useSessionPresence";
import Divider from "@material-ui/core/Divider";

export default function AttendeeContextMenu(props) {
    const { id, parent, workspace, session } = props;
    const roles = useRoles(id, parent);
    const {onAssignRole} = useRoleManagement(parent);
    const {onRemove, onBan} = useMemberManagement(workspace);

    const assign = useCallback(
        role=>onAssignRole(id, role),
        [onAssignRole, id]
    );

    const {connected, audio, video, screen} = useSessionPresence(id, session);

    const {
        toggleAttendeeVideo,
        toggleAttendeeAudio,
        removeAttendee,
        inviteOnStage,
        removeFromStage,
        stopAttendeeScreenShare
    } = useRTMControls();

    return <>
        <MenuItem
            icon={'mui-record_voice_over'}
            primaryText={'Speaker'}
            selected={roles.speaker}
            onClick={()=>assign('speaker')}
        />
        <MenuItem
            icon={'mui-admin_panel_settings'}
            primaryText={'Moderator'}
            selected={roles.moderator}
            onClick={()=>assign('moderator')}
        />
        <MenuItem
            icon={'mui-verified_user'}
            primaryText={'Admin'}
            selected={roles.admin}
            onClick={()=>assign('admin')}
        />
        <Divider />
        {connected && audio ? <MenuItem
            icon={audio ? 'mui-mic_off' : 'mui-mic'}
            primaryText={audio ? 'Mute' : 'Unmute'}
            selected={false}
            onClick={()=>toggleAttendeeAudio(id)}
        /> : null }
        {connected && video ? <MenuItem
            icon={video ? 'mui-videocam_off' : 'mui-videocam'}
            primaryText={video ? 'Disable cam' : 'Enable cam'}
            selected={false}
            onClick={()=>toggleAttendeeVideo(id)}
        /> : null }
        {connected && screen ? <MenuItem
            icon={'mui-stop_screen_share'}
            primaryText={'Stop screen share'}
            onClick={()=>stopAttendeeScreenShare(id)}
        /> : null }
        { connected ? <MenuItem
            icon={'mui-person_add'}
            primaryText={'Invite on stage'}
            onClick={()=>inviteOnStage(id)}
        /> : null}
        { connected ? <MenuItem
            icon={'mui-person_remove'}
            primaryText={'Remove from stage'}
            onClick={()=>removeFromStage(id)}
        /> : null}
        { connected ? <MenuItem
            icon={'mui-person_off'}
            primaryText={'Remove from session'}
            onClick={()=>removeAttendee(id)}
        /> : null}
        {connected ? <Divider /> : null }
        <MenuItem
            icon={'mui-person_remove'}
            primaryText={'Remove from workspace'}
            onClick={()=>onRemove(id)} />
        <MenuItem
            icon={'mui-block'}
            primaryText={'Ban from workspace'}
            onClick={()=>onBan(id)} />
    </>;
}

AttendeeContextMenu.propTypes = {
    id: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired
};
AttendeeContextMenu.defaultProps = {};
