import { useUserProfile } from "joynt/hooks/useUserProfile";
import { FEATURE_OPTIMIZED_API } from "joynt/config/features";

const enabled = [FEATURE_OPTIMIZED_API];

export function useFeatureEnabled(feature) {
    const profile = useUserProfile();

    if (enabled.indexOf(feature) > -1) return true;

    return profile[`feature_flag_${feature}`] === true;
}
