import React, { useCallback, useState } from "react";
import Tabs, { Tab } from "joynt/components/Tabs";
import Button from "components/Button";
import { useForm, useNode } from "joynt/hooks";
import { usePending } from "state/hooks";
import FormLayout from "joynt/components/Form/FormLayout/FormLayout";
import { types } from "joynt/config/types";
import { NODE_TYPE_EVENT, NODE_TYPE_SPACE } from "joynt/config";
import AggregateRoot from "containers/AggregateRoot";
//import PropTypes from 'prop-types';

function getformTitle(step, isDraft, type) {
    if (step.title) return step.title;
    if (isDraft) return `Create ${type}`;
    return `${type} settings`;
}

export default function MultiStepForm(props) {
    const {
        id,
        steps,
        onReject,
        isDraft,
        onSubmit,
        showBackLink,
        infoLeft,
        infoRight,
    } = props;

    const { subtype, workspace } = useNode(id);
    const type = types[subtype];
    const pending = usePending(id);
    const stepIds = Object.keys(steps);
    const [step, setStep] = useState(stepIds[0]);
    const stepIndex = stepIds.indexOf(step);
    const isLastStep = stepIndex === stepIds.length - 1;
    const isFirstStep = stepIndex === 0;
    const StepComponent = steps[step] ? steps[step].component : false;
    const prev = useCallback(() => setStep(stepIds[stepIndex - 1]), [
        setStep,
        stepIds,
        stepIndex,
    ]);
    const next = useCallback(() => setStep(stepIds[stepIndex + 1]), [
        setStep,
        stepIds,
        stepIndex,
    ]);
    const { onSubmit: save, isValid } = useForm("db.nodes", id, next);

    const handleSave = useCallback(() => save(), [save]);
    const handleSubmit = useCallback(() => onSubmit(), [onSubmit]);

    if (!StepComponent) return null;

    let name = type.label;
    if (subtype === NODE_TYPE_EVENT && workspace) name = "Event";
    if (subtype === NODE_TYPE_SPACE && workspace) name = "Workspace";

    const isSubmit = steps[step].submit;
    const createLabel = `Create ${name}`;
    const formTitle = getformTitle(steps[step], isDraft, name);
    const isMultiStep = stepIds.length > 1;

    const stepProps = {
        id,
        isDraft,
        onSubmit: isSubmit ? save : next,
        onReject: isDraft && isMultiStep ? prev : onReject,
        isValid,
    };

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
            <FormLayout
                onReject={onReject}
                pending={pending}
                title={formTitle}
                showBackLink={showBackLink}
                infoLeft={infoLeft}
                infoRight={infoRight}
                tabs={
                    !isDraft && stepIds.length > 1 ? (
                        <Tabs>
                            {stepIds.map((stepId) => (
                                <Tab
                                    key={stepId}
                                    onClick={() => setStep(stepId)}
                                    selected={step === stepId}
                                >
                                    {steps[stepId].label}
                                </Tab>
                            ))}
                        </Tabs>
                    ) : null
                }
            >
                <StepComponent {...stepProps} />
                {!steps[step].disableActions && (
                    <>
                        {isLastStep || !isDraft ? (
                            <div className={"cols cols-center pad-md gap-xs"}>
                                <Button
                                    size={"large"}
                                    color={"default"}
                                    onClick={
                                        isDraft && stepIds.length > 1
                                            ? prev
                                            : onReject
                                    }
                                >
                                    {isDraft ? "Back" : "Cancel"}
                                </Button>
                                <Button
                                    size={"large"}
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                >
                                    {isDraft ? createLabel : "Save changes"}
                                </Button>
                            </div>
                        ) : (
                            <div className={"cols cols-center pad-md gap-xs"}>
                                <Button
                                    size={"large"}
                                    color={"default"}
                                    onClick={isFirstStep ? onReject : prev}
                                >
                                    {isFirstStep ? "Cancel" : "Back"}
                                </Button>
                                <Button
                                    size={"large"}
                                    onClick={isSubmit ? handleSave : next}
                                >
                                    {isSubmit ? createLabel : "Next"}
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </FormLayout>
        </AggregateRoot>
    );
}

MultiStepForm.propTypes = {};
MultiStepForm.defaultProps = {};
