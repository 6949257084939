import { useEdgesData, useFeatureEnabled, useNodeData } from "joynt/hooks";
import { useMemo } from "react";
import { FEATURE_DEV } from "joynt/config/features";

export default function useSessionUtils(id) {
    const edges = useEdgesData(id);
    const { can_edit, session_type } = useNodeData(id);
    const isDev = useFeatureEnabled(FEATURE_DEV);

    return useMemo(() => {
        //const sessions = edges.sessions || edges.parent_sessions;
        const ids = [];

        if (session_type !== "witness") {
            ids.push({ type: "people", id });
        }

        if (edges.session_chat)
            ids.push({ type: "chat", id: edges.session_chat });

        if (edges.feed) ids.push({ type: "files", id: edges.feed });
        if (edges.feed) ids.push({ type: "polls", id: edges.feed });

        if (edges.threads) ids.push({ type: "qa", id: edges.threads });

        if (isDev && !!session_type) ids.push({ type: "flow", id: id });
        if (isDev && !!session_type) ids.push({ type: "dev", id: id });

        // if (sessions) ids.push({ type: "rooms", id: sessions });
        if (can_edit) ids.push({ type: "settings", id });

        return ids;
    }, [edges, can_edit, session_type, id, isDev]);
}
