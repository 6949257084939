import React from "react";
import { useNode, useNodeData } from "joynt/hooks";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import SessionPublishedStatus from "./../SessionPublishedStatus";
import { CollapsedField } from "joynt/components/Form";
import { sessionTypes } from "joynt/config/sessions";
import SessionType from "./../SessionType";
import SessionAccess from "./../SessionAccess";

//import PropTypes from 'prop-types';

export function ManageSessionAccess(props) {
    const { id } = props;
    const { hidden } = useNodeData(id);
    const { session_type } = useNode(id);
    const isPrivateSession = session_type === "private";

    return (
        <FieldsLayout>
            {hidden && !isPrivateSession && (
                <SessionPublishedStatus {...props} />
            )}
            {session_type && (
                <CollapsedField
                    label={
                        <>
                            {sessionTypes[session_type]?.label},
                            {sessionTypes[session_type]?.description}
                        </>
                    }
                    expandLabel={"Change"}
                    collapseOnClick={true}
                >
                    <SessionType />
                </CollapsedField>
            )}
            <SessionAccess {...props} />
        </FieldsLayout>
    );
}

ManageSessionAccess.propTypes = {};
ManageSessionAccess.defaultProps = {};

export default ManageSessionAccess;
