import React, { useCallback } from "react";
import PropTypes from "prop-types";
import RoleItem from "./RoleItem";
import { useClick, useData } from "state/hooks";
import { useIdentity } from "joynt/hooks/participants";
import { policies } from "joynt/edit/access/config";
import { IdentityAvatarContainer as IdentityAvatar } from "joynt/components/Avatar";
import Role from "./Role";
import RoleSelect from "./RoleSelect";
import UserNodeRoles from "./../NodeRoles/UserNodeRoles";

export function RoleItemContainer(props) {
    const { id, scope, onRemove, onRevert, onChangeRole } = props;

    const data = useData(props);
    const { identity, action } = data;
    const click = useClick(props);
    const remove = useClick({ onClick: onRemove, id });
    const revert = useClick({ onClick: onRevert, id });
    const changeRole = useCallback(
        (role) => {
            onChangeRole(id, { role });
        },
        [onChangeRole, id]
    );
    const { name } = useIdentity(identity);
    const isOwner = data.role === "owner";
    const inherited = data.inherited && !action;

    const toBeRemoved = action === "remove";
    const canRevert = ["change", "remove"].indexOf(action) > -1;

    const roleName = isOwner ? "Owner" : policies?.[scope]?.[data.role]?.label;

    const roleCantBeChanged = isOwner || (inherited && !identity);
    const readOnly = isOwner || inherited;

    return (
        <RoleItem
            {...props}
            {...data}
            inherited={inherited}
            isPendingInvite={!data.joined && !action}
            name={name}
            onClick={click}
            onRemove={remove}
            onRevert={canRevert && revert}
            onChangeRole={changeRole}
            avatar={identity && <IdentityAvatar id={data.identity} />}
            role={
                isOwner || roleCantBeChanged || toBeRemoved ? (
                    <Role label={roleName} />
                ) : (
                    <RoleSelect
                        scope={scope}
                        onSelect={changeRole}
                        value={roleName}
                    />
                )
            }
            readOnly={readOnly}
            userRoles={<UserNodeRoles id={data.id} />}
        />
    );
}

RoleItemContainer.propTypes = {
    id: PropTypes.string,
};
RoleItemContainer.defaultProps = {};

export default RoleItemContainer;
