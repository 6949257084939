import React from 'react'
import PropTypes from 'prop-types';
import cn from "classnames";

import Icon from "components/Icon";

import "./style.css";

export function DrawerNavButton(props) {

    const {
        label,
        icon,
        onClick,
        hasNotifications,
        color,
        avatar
    } = props;

    const className = cn("drawer-nav-button", {
        "has-notifications": hasNotifications,
        [`drawer-nav-button--${color}`]: true
    });

    return (<div className={className} onClick={onClick}>
        <div className={"drawer-nav-button__label"}>
            {avatar && <div className={"drawer-nav-button__avatar"}>
                {avatar}
            </div>}
            {icon && <Icon>{icon}</Icon>}
            <div className={"drawer-nav-button__name"}>
                {label}
            </div>
        </div>
        <Icon>mui-chevron_right</Icon>
    </div>);

}

DrawerNavButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    hasNotifications: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "link"])
};
DrawerNavButton.defaultProps = {
    hasNotifications: false,
    color: "primary"
};

export default DrawerNavButton;
