import React from 'react';
//import PropTypes from 'prop-types';
import Icon from "components/Icon";
import Popover from '@material-ui/core/Popover';

import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';
import {DayPickerRangeController} from "react-dates";

import 'playground/booking/css/calendar.css';

function DateFormat({date}) {
    return <div className={'date-value'}>
        <div className={'date-primary'}>{date.format('DD MMMM')}</div>
        <div className={'date-secondary'}>{date.format('YYYY, dddd')}</div>
    </div>
}

function DateRangePicker(props) {
    const {
        dateFrom,
        dateTo,
        onOpenDateFrom,
        onOpenDateTo
    } = props;

    return <div onClick={onOpenDateFrom} className={'booking-search-field cols cols-middle gap-sm'}>
        <Icon>mui-today</Icon>
        <div className={'date'}>
            {dateFrom ? <DateFormat date={dateFrom} /> : 'none'}
        </div>
        <Icon>mui-arrow_forward</Icon>
        <div onClick={onOpenDateTo} className={'date'}>
            {dateTo ? <DateFormat date={dateTo} /> : 'none'}
        </div>
    </div>;
}

const defaultDateFrom = moment();
const defaultDateTo = moment().add(1, 'days');

export default class DateRangePickerContainer extends React.PureComponent {

    static valueFormat = 'YYYY-MM-DD';

    constructor(props) {
        super(props);
        this.state = {
            focusedInput: 'startDate',
            open: false,
            anchorEl: null
        };
    }

    change = ({startDate, endDate}) => {
        console.log(startDate, endDate);
        const { onChange } = this.props;
        const { focusedInput } = this.state;
        const update = {};
        if (startDate) update.date_from = startDate.format(DateRangePickerContainer.valueFormat);
        if (focusedInput === 'startDate') update.date_to = null;
        if (focusedInput === 'endDate') {
            update.date_to = endDate ? endDate.format(DateRangePickerContainer.valueFormat) : null;
        }
        onChange(update);
        if (update.date_from && update.date_to) {
            this.setState({focusedInput: 'startDate', open: false});
        }
    };

    open = (evt) => {
        this.setState({
            anchorEl: evt.target,
            open: true,
            focusedInput: 'startDate'
        });
    };

    openDateTo = (evt) => {
        this.setState({
            anchorEl: evt.target,
            open: true,
            focusedInput: 'endDate'
        });
        evt.preventDefault();
        evt.stopPropagation();
    };

    focus = (focusedInput) => {
        if (!focusedInput) focusedInput = 'startDate';
        this.setState({ focusedInput });
    };

    close = () => {
        this.setState({
            open: false
        })
    };

    render() {
        //const displayFormat = 'DD MMMM';
        const valueFormat = DateRangePickerContainer.valueFormat;
        const { startDate, endDate, render, ...other } = this.props;
        const { nullable } = this.props;
        const { focusedInput } = this.state;
        const date_from = startDate
            ? moment(startDate, valueFormat)
            : (nullable ? null : defaultDateFrom);
        const date_to = endDate
            ? moment(endDate, valueFormat)
            : (nullable ? null : defaultDateTo);
        const RenderComponent = render || DateRangePicker;
        return (<>
            <RenderComponent
                {...other}
                dateFrom={date_from}
                dateTo={date_to}
                onOpenDateFrom={this.open}
                onOpenDateTo={this.openDateTo}
            />
            <Popover
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                onClose={this.close}
            >
                <div className={'rows'}>
                    <div className={'pad-sm'}>
                        <DayPickerRangeController
                            numberOfMonths={2}
                            startDate={date_from} // momentPropTypes.momentObj or null,
                            endDate={date_to} // momentPropTypes.momentObj or null,
                            onDatesChange={this.change} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={this.focus} // PropTypes.func.isRequired,
                            minDate={moment()}
                        />
                    </div>
                    <div className={'pad-md'}>
                        {focusedInput === 'startDate' ? 'Data przyjazdu' : 'Data wyjazdu'}
                    </div>
                </div>
            </Popover>
        </>);
    }

}

DateRangePickerContainer.propTypes = {};