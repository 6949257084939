import React from "react";
import PropTypes from "prop-types";

import "./style.css";

function calc(size, border, value) {
    const center = size / 2;
    const radius = (size - border) / 2;
    const deg = (360 / 60) * value;
    const angle = deg * (Math.PI / 180);

    return {
        x: center + radius * Math.sin(angle),
        y: center - radius * Math.cos(angle),
    };
}

export function ProgressClock(props) {
    const { progress, minutesLeft } = props;

    const size = 180;
    const b = 30;

    const { x, y } = calc(size, b, (60 / 100) * progress);
    const style = { "--x": `${x}px`, "--y": `${y}px` };

    return (
        <div className={"progress-clock"}>
            <div className={"progress-clock__clock"} style={style}>
                <div className={"progress-clock__minutes"}>{minutesLeft}</div>
                <div className={"progress-clock__label"}>min left</div>
            </div>
        </div>
    );
}

ProgressClock.propTypes = {
    minutesLeft: PropTypes.number,
    progress: PropTypes.number,
};
ProgressClock.defaultProps = {
    minutesLeft: 0,
    progress: 0,
};

export default ProgressClock;
