import {
    selectEntity,
    selectEntityData,
    selectFieldValue,
} from "state/selectors/data";
import { invert } from "lodash";

export const NS_SCHEMA = "schema.db";
export const NS_SCHEMA_FIELDS = "db.fields";
export const NS_SCHEMA_TYPES = "db.types";

export const DB_SYSTEM_TYPES = [
    "db.types",
    "db.filters",
    "db.list-views",
    "db.form-views",
    "db.enum-definitions",
    "db.fields",
];

export const findSchema = (store, type, id) => {
    const entity = selectEntity(store, type, id);
    const entityType = entity.element_type || entity.type;
    const schema = Object.values(store.data[NS_SCHEMA] || {});
    const typeId = type.split(".")[1];
    const formSchema = schema.filter((item) => {
        return item.slug === typeId && !item.subtype_of;
    });
    const schemaId = formSchema.length ? formSchema[0].id : null;
    if (schemaId && entityType) {
        const typeSchema = schema.filter((item) => {
            return item.subtype_of === schemaId && item.slug === entityType;
        });
        if (typeSchema.length) return typeSchema[0].id;
    }
    return schemaId;
};

export function selectFields(store, type, id, schemaProps, selectFields) {
    let schemaId = findSchema(store, type, id);
    let index = selectFieldValue(store, "schema.db", schemaId, "field_index");
    let fields = selectFieldValue(store, "schema.db", schemaId, "fields");
    if (selectFields) {
        if (!index) return null;
        let revIndex = invert(index);
        let exclude = Object.values(selectFields).indexOf(false) > -1;
        if (exclude) {
            fields = fields.filter((fieldId) => {
                let fieldName = revIndex[fieldId];
                if (selectFields[fieldName]) return true;
                return exclude && selectFields[fieldName] !== false;
            });
        } else {
            fields = Object.keys(selectFields)
                .map((fieldName) => {
                    return typeof selectFields[fieldName] === "function"
                        ? selectFields[fieldName](
                              store,
                              fieldName,
                              index[fieldName]
                          )
                        : index[fieldName];
                })
                .filter(Boolean)
                .reduce((acc, fieldId) => {
                    if (Array.isArray(fieldId)) {
                        acc.push(...fieldId);
                    } else {
                        acc.push(fieldId);
                    }
                    return acc;
                }, []);
        }
    }
    return fields;
}

export function selectExtendedFieldsForType(store, schemaTypeId) {
    const typeDefinition = selectEntityData(store, NS_SCHEMA, schemaTypeId);
    const settingsId = typeDefinition?.field_index?.settings;
    const settingsDefinition = selectEntityData(
        store,
        NS_SCHEMA_FIELDS,
        settingsId
    );
    const settingsFields = settingsDefinition?.fields;
    return (
        settingsFields?.reduce((acc, fieldId) => {
            const field = selectEntityData(store, NS_SCHEMA_FIELDS, fieldId);
            if (field?.properties?.extension) acc.push(field);
            return acc;
        }, []) || []
    );
}

export function selectExtendedFieldsForEntity(store, type, id) {
    const schemaTypeId = findSchema(store, type, id);
    return selectExtendedFieldsForType(store, schemaTypeId);
}

export function selectExtendedFieldsIds(store, type, id) {
    return selectExtendedFieldsForEntity(store, type, id).map(
        (field) => field.id
    );
}

export function selectSchemaExtensions(store) {
    const types = store?.data?.[NS_SCHEMA] || {};
    const relationTypes = ["collection"];
    return Object.values(types).reduce((acc, type) => {
        if (!type?.field_index?.settings) return acc;
        const extendedFields = selectExtendedFieldsForType(store, type.id);
        if (extendedFields.length) {
            const parentType = type.subtype_of ? types[type.subtype_of] : null;
            const typePath = ["cms", parentType?.slug, type.slug]
                .filter(Boolean)
                .join(".");
            acc[typePath] = extendedFields
                .filter((field) => relationTypes.includes(field.type))
                .reduce((typeAcc, field) => {
                    if (!typeAcc.fields) typeAcc.fields = {};
                    typeAcc.fields[field.slug] = [
                        ["cms", field.slug].join("."),
                    ];
                    return typeAcc;
                }, {});
        }
        return acc;
    }, {});
}
