//import React from 'react';
//import PropTypes from 'prop-types';

export default function Pending(props) {

    return null;

}

Pending.propTypes = {};
Pending.defaultProps = {};
