import React from "react";
//import PropTypes from 'prop-types';

import AttributeEditor from "playground/cms/fields/Attributes";

function toList(attrMap) {
    return Object.keys(attrMap).map((name) => {
        return [name, attrMap[name]].filter(Boolean).join(":");
    });
}

function toMap(attrList) {
    return attrList.reduce((acc, attr) => {
        const [name, value] = attr.split(":");
        acc[name] = value || null;
        return acc;
    }, {});
}

export function Attributes(props) {
    const { value, onChange } = props;

    const attrMap = toMap(value || []);
    const change = (nextMap) => onChange(toList(nextMap));

    return <AttributeEditor value={attrMap} onChange={change} />;
}

Attributes.propTypes = {};

Attributes.defaultProps = {
    value: [],
};

export default Attributes;
