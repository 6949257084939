export const clipboardPaste = (context) => (target, contents) => ({
    type: "CMS.CLIPBOARD.PASTE",
    payload: { target, contents },
    context,
});

export const clipboardCopyContent = (type, id, path) => ({
    type: "CMS.CLIPBOARD.COPY_CONTENT",
    payload: { type, id, path },
});

export const clipboardPasteContent = (type, id, path) => ({
    type: "CMS.CLIPBOARD.PASTE_CONTENT",
    payload: { type, id, path },
});
