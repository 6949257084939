import React from "react";
import Field from "containers/Field";
//import PropTypes from 'prop-types';

export default function GalleryForm(props) {
    return (
        <>
            <Field id={"media"} fieldType={"media"} label={"Media"} />

            <Field
                id={"tags"}
                fieldType={"select"}
                source={"api:cms.tags"}
                multiple={true}
                taggable={true}
                label={"Tags"}
            />

            <Field id={"title"} fieldType={"text"} label={"Title"} />

            <Field id={"headline"} fieldType={"text"} label={"Headline"} />

            <Field id={"text"} fieldType={"rich"} label={"Text"} />
        </>
    );
}

GalleryForm.propTypes = {};
GalleryForm.defaultProps = {};
