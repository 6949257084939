import { useList } from "state/hooks";
import { useMemo } from "react";
import { useEdges } from "joynt/hooks";

export function useNodeRoles(id) {
    const { workspace } = useEdges(id);

    const type = "db.node-roles";
    const list = `node.${workspace}.node-roles`;

    const { items } = useList(type, list);

    return useMemo(() => ({ items, list }), [items, list]);
}
