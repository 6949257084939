import React from 'react';
import PropTypes from 'prop-types';

import DayTrackList from "./../DayTrackList";

import "./style.css";

function Hours(props) {
    const {items} = props;

    return <div className={"hours-scale"}>
        {items.map(i => {
            return <div key={i}>{i}:00</div>
        })}
    </div>
}

function HourGrid(props) {
    const {items} = props;

    return <div className={"hour-grid"}>
        {items.map(i => {
            return <div key={i} />
        })}
    </div>
}

export const CalendarTimeline = React.forwardRef((props, ref) => {

    const {header, hours, children} = props;

    return (<div className={"calendar-timeline"}>
        <div className={"calendar-timeline__header"}>
            {header}
        </div>
        <Hours items={hours} />
        <div className={"days-outer"}>
            <HourGrid items={hours} />
            <div ref={ref} className={"days-scroll"}>
                <DayTrackList>
                    {children}
                </DayTrackList>
            </div>
        </div>
    </div>);

});

CalendarTimeline.propTypes = {
    hours: PropTypes.array
};
CalendarTimeline.defaultProps = {
    hours: []
};

export default CalendarTimeline;
