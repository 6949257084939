import { useCallback, useMemo } from "react";
import { useUiFlag } from "state/hooks";
//import PropTypes from 'prop-types';

const defaultState = {
    loading: false,
    joined: false,
    localUserId: false,
    error: false,
};

export default function useConnectionState(id) {
    const flagId = `meeting.connection.${id}`;
    const [state, setState] = useUiFlag(flagId, defaultState);

    const set = useCallback(
        (next) => {
            if (next === null) return setState(defaultState);
            setState((prev) => ({ ...prev, ...next }));
        },
        [setState]
    );

    return useMemo(() => {
        return [state || defaultState, set];
    }, [state, set]);
}

useConnectionState.propTypes = {};
useConnectionState.defaultProps = {};
