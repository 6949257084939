import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Tags(props) {
    return (
        <>
            {props.children}
            <Field
                id={"tags"}
                label={"Tags"}
                fieldType={"select"}
                source={"api:tags"}
                multiple={true}
                taggable={true}
            />
            <Field
                id={"tags_filter"}
                label={"Tags filter"}
                fieldType={"select"}
                source={"api:tags"}
                multiple={true}
                taggable={true}
            />
        </>
    );
}

Tags.propTypes = {};

Tags.defaultProps = {};

export default Tags;
