import { useCallback, useMemo, useState } from "react";
import { useSessionPresence } from "joynt/meeting/hooks/useSessionPresence";
import useNetworkQuality from "joynt/meeting/hooks/useNetworkQuality";
import useLogger from "joynt/meeting/hooks/useLogger";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import { useActions } from "state/hooks";
import { notify, setFlag } from "state/actions/ui";
import { useSessionNotifications } from "joynt/meeting/notifications";
import { useConfig } from "joynt/meeting/agora-ng/hooks";
import updateActiveSpeakers from "joynt/meeting/util/updateActiveSpeakers";

export function useProcessEvents(props) {
    const {
        user,
        channel: propChannel,
        setUsers,
        setActiveSpeakers,
        setLocalTracks,
    } = props;

    const [ch, setCh] = useState(propChannel);
    const channel = ch || propChannel;

    const logger = useLogger();
    const { onUpdatePresence } = useSessionPresence(channel);
    const { setQuality } = useNetworkQuality();
    const { ACTIVE_SPEAKER_THRESHOLD } = useConfig();

    //const [, setConnectionState] = useConnectionState(channel);

    const { setAudio, setVideo } = useSessionState(channel);
    const { notify: onNotify } = useActions({ notify });
    const { onNotify: onNotifySession, onDismiss: onDismissSession } =
        useSessionNotifications(channel);

    const { onSetFlag } = useActions({ onSetFlag: setFlag });

    const setConnectionState = useCallback(
        (event) => {
            let eventChannel = event.channel || channel;
            let next = event.data;
            let flagId = `meeting.connection.${eventChannel}`;
            onSetFlag(flagId, (prev) => ({ ...prev, ...next }));
        },
        [onSetFlag, channel]
    );

    return useCallback(
        (events) => {
            switch (events.event) {
                case "channel":
                    console.log("CHANNEL CHANGED", events.data);
                    setLocalTracks([]);
                    setUsers([]);
                    setActiveSpeakers([]);
                    setCh(events.data.channel);
                    break;
                case "joining":
                    setConnectionState(events);
                    break;
                case "leaving":
                    setConnectionState(events);
                    break;
                case "track-publish":
                    setConnectionState(events);
                    if (events.data.videoStatus === "error") {
                        setVideo(false);
                        onNotify(events.data.videoError, "error");
                    }
                    if (events.data.audioStatus === "error") {
                        setAudio(false);
                        onNotify(events.data.audioError, "error");
                    }
                    break;
                case "media-device-changed":
                    onNotifySession({ ...events });
                    break;
                case "session-error":
                    onNotify(events.data.error, "error");
                    onNotifySession({
                        error: events.data.error,
                        type: "error",
                    });
                    break;
                case "audio-level-too-low":
                    onNotifySession({
                        id: "AUDIO_LEVEL_TOO_LOW",
                        error: "Your microphone volume is too low or it's not working",
                        type: "error",
                        permanent: true,
                    });
                    break;
                case "audio-level-too-low-recover":
                    onDismissSession("AUDIO_LEVEL_TOO_LOW");
                    onNotifySession({
                        error: "It seems problem with your microphone was solved",
                        type: "success",
                    });
                    break;
                case "connection-state-change":
                    setConnectionState({
                        channel: events.channel,
                        data: {
                            connectionState: events.data,
                            loading: ["CONNECTING", "DISCONNECTING"].includes(
                                events.data.currentState
                            ),
                        },
                    });
                    break;
                case "user-joined":
                    logger("User joined", events.data.remoteUser.uid);
                    setUsers(() => Object.values(events.users));
                    onUpdatePresence({
                        [events.data.remoteUser.uid]: {
                            connected: true,
                            timestamp: Date.now(),
                        },
                    });
                    break;
                case "user-left":
                    logger("User left", events.data.remoteUser.uid);
                    setUsers(() => Object.values(events.users));
                    onUpdatePresence({
                        [events.data.remoteUser.uid]: {
                            connected: false,
                            audio: false,
                            video: false,
                        },
                    });
                    break;
                case "user-published":
                    logger(
                        `User published ${events.data.mediaType}`,
                        events.data.remoteUser.uid
                    );
                    setUsers(() => Object.values(events.users));
                    if (events.data.mediaType === "audio") {
                        setActiveSpeakers((prev) => {
                            let data = { ...prev };
                            delete data[events.data.remoteUser.uid];
                            return data;
                        });
                    }
                    onUpdatePresence({
                        [events.data.remoteUser.uid]: {
                            connected: true,
                            [events.data.mediaType]: true,
                            timestamp: Date.now(),
                        },
                    });
                    break;
                case "user-unpublished":
                    logger(
                        `User unpublished ${events.data.mediaType}`,
                        events.data.remoteUser.uid
                    );
                    setUsers(() => Object.values(events.users));
                    onUpdatePresence({
                        [events.data.remoteUser.uid]: {
                            [events.data.mediaType]: false,
                        },
                    });
                    break;
                case "update-remote-tracks":
                    setUsers(() => Object.values(events.users));
                    break;
                case "dev-unpublish":
                    onUpdatePresence({
                        [events.data.remoteUser.uid]: {
                            audio: false,
                            video: false,
                        },
                    });
                    break;
                case "volume-indicator":
                    setActiveSpeakers((prev) =>
                        updateActiveSpeakers(
                            events.data.result,
                            user,
                            ACTIVE_SPEAKER_THRESHOLD,
                            prev
                        )
                    );
                    break;
                case "network-quality":
                    const { uplinkNetworkQuality, downlinkNetworkQuality } =
                        events.data.stats;
                    const quality = {
                        uplink: uplinkNetworkQuality,
                        downlink: downlinkNetworkQuality,
                    };
                    if (quality.uplink > 1 || quality.downlink > 1) {
                        logger(
                            `Connection quality down:${quality.downlink} up:${quality.uplink}`
                        );
                    }
                    setQuality(quality);
                    break;
                case "update-local-tracks":
                    const { tracks } = events.data;
                    setLocalTracks(tracks);
                    break;
                default:
                    break;
            }
        },
        [
            setLocalTracks,
            setUsers,
            setActiveSpeakers,
            setConnectionState,
            onNotifySession,
            onDismissSession,
            onNotify,
            logger,
            onUpdatePresence,
            setQuality,
            setVideo,
            setAudio,
            ACTIVE_SPEAKER_THRESHOLD,
            user,
        ]
    );
}

export default function useSessionEvents(props) {
    //const [networkQuality, setNetworkQuality] = useState(null);
    const [activeSpeakers, setActiveSpeakers] = useState({});
    const [users, setUsers] = useState([]);
    const [localTracks, setLocalTracks] = useState([]);

    const handleEvent = useProcessEvents({
        ...props,
        setUsers,
        setActiveSpeakers,
        setLocalTracks,
    });

    return useMemo(
        () => ({
            localTracks,
            users,
            activeSpeakers,
            handleEvent,
        }),
        [localTracks, users, activeSpeakers, handleEvent]
    );
}
