import { ArrayIterator } from "components/list/iterator";
import { useList } from "state/hooks";
import List from "containers/List";
import React from "react";
import { Resource } from "./Resource";

const ResourceIterator = ArrayIterator(Resource);

export function ResourceGroup({ id, label, active, onClick }) {
    const { items } = useList("app.user-menu", id);
    if (!items?.length) return null;

    return (
        <div className={"side-nav-group rows"}>
            <div className={"side-nav-group-heading"}>{label || id}</div>
            <div className={"side-nav-group-items"}>
                <List
                    onClick={onClick}
                    type={"app.user-menu"}
                    items={items}
                    active={active}
                    Iterator={ResourceIterator}
                />
            </div>
        </div>
    );
}
