import React from "react";
import PropTypes from "prop-types";
import Preloader from "components/Preloader";
import { useMedia } from "containers/media";
import FileTypeIcon from "./FileTypeIcon";
import { useClick } from "state/hooks";
import Icon from "components/Icon";

export default function GridFile(props) {
    const { fileType, isPending, onDelete, id } = props;

    const { Render } = useMedia();
    const handleDelete = useClick({ onClick: onDelete, id });
    const onClick = useClick(props);

    if (!Render) return <div>No file renderer provided</div>;

    return (
        <div className={"media-file relative"} onClick={onClick}>
            {fileType === "image" ? (
                <Render {...props} />
            ) : (
                <FileTypeIcon {...props} />
            )}
            <Preloader
                visible={isPending}
                thickness={2}
                size={16}
                color={"inherit"}
            />
            {onDelete && (
                <div className={"media-delete"} onClick={handleDelete}>
                    <Icon>mui-close</Icon>
                </div>
            )}
        </div>
    );
}

GridFile.defaultProps = {
    isPending: false,
};

GridFile.propTypes = {
    fileType: PropTypes.oneOf(["image", "video", "document", "audio"]),
    src: PropTypes.string,
    isPending: PropTypes.bool,
    extension: PropTypes.string,
};
