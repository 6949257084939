import React from "react";
import cn from "classnames";
// import PropTypes from 'prop-types';

import "./style.css";

export function SessionPageLayout(props) {
    const { header, footer, content, children, hasAccount } = props;

    return (
        <div className={"session-page networking-view dark-mode bg"}>
            {header}
            <div className={"networking-view__content"}>
                {content}
                {children && (
                    <div
                        className={cn(
                            "session-page__content",
                            hasAccount === false && "unauthenticated"
                        )}
                    >
                        {children}
                    </div>
                )}
            </div>
            {footer}
        </div>
    );
}

SessionPageLayout.propTypes = {};

SessionPageLayout.defaultProps = {};

export default SessionPageLayout;
