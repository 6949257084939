export default `
.color-swatch {
    height: 26px;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    transition: all .1s;
    transform-origin: center center;
}

.color-swatch:hover {
    box-shadow: 0px 0px 8px rgba(0,0,0,0.6);
}

.color-swatch svg {
    height: 16px;
    stroke: #ffffff;
    fill: #ffffff;
}
`;