import {useMemo} from "react";
import {useNode} from "joynt/hooks";

export function useRoles(id, node) {
    const {
        event_speakers,
        event_moderators,
        recipient_identities,
        identity
    } = useNode(node);

    return useMemo(() => {
        const roles = {};
        if (identity === id) roles.owner = true;
        if (event_speakers && event_speakers.indexOf(id) > -1) roles.speaker = true;
        if (event_moderators && event_moderators.indexOf(id) > -1) roles.moderator = true;
        if (recipient_identities && recipient_identities.indexOf(id) > -1) roles.admin = true;
        if (roles.owner) roles.admin = true;
        return roles;
    }, [id, event_speakers, event_moderators, recipient_identities, identity]);
}