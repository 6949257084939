import React from "react";
//import PropTypes from "prop-types";

import "./style.sidebar-tutorial.css";

export default function HomeTutorial(props) {

    return <div className={'sidebar-tutorial grow'}>
        <div className={'rows gap-sm type-body-sm'}>
            <p>
                <strong>Explore the possibilities of your workspace</strong>
            </p>
            <p>
                ● Joynt brings out the best in the individual, and promote the most meaningful of community and workspace workflows.
            </p>
            <p>
                ● A complete toolkit to run complex social structures, organisations, projects, events and gatherings in a permanent way.
            </p>
        </div>
    </div>;

}

HomeTutorial.defaultProps = {};
HomeTutorial.propTypes = {};