export function bindApiActions(connectedActions, actions) {
    return function(dispatch, ownProps, props) {
        const apiContext =  ownProps.apiContext || props.apiContext;

        const boundActions = {};
        for (let k in connectedActions) {
            boundActions[k] = (...args) => dispatch(connectedActions[k](apiContext)(...args));
        }
        for (let k in actions) {
            boundActions[k] = (...args) => dispatch(actions[k](...args));
        }

        return boundActions;
    }
}