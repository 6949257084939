import React from 'react';
import PropTypes from 'prop-types';
import {Calendar, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "./style.css";

export function DatePickerCalendar(props) {
    const {value, onChange, ...other} = props;
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <Calendar
            date={value}
            onChange={onChange}
            disablePast={true}
            {...other}
        />
    </MuiPickersUtilsProvider>
}

DatePickerCalendar.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
};
DatePickerCalendar.defaultProps = {};

export default DatePickerCalendar;
