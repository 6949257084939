import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export default function Tab(props) {

    const {selected, onClick, children} = props;
    return (<div className={cn('tab', {
        'is-active': selected
    })} onClick={onClick}>
        {children}
    </div>);

}

Tab.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func
};

Tab.defaultProps = {
    selected: false
};
