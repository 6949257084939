import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'css/user-menu/resource-item.css';
import Icon from "components/Icon";

export default class ResourceItem extends React.Component {

    render() {
        const { icon, label, name, onClick, isActive } = this.props;
        const className = 'resource-item rows rows-center rows-middle pad-sm align-center';
        const classes = {
            active: !!isActive
        };
        classes[className] = true;
        return (<div className={classNames(classes)} onClick={onClick}>
            <div className={'resource-item-icon'}><Icon defaultType={'mui'}>{icon}</Icon></div>
            <div className={'resource-item-label'}>{label || name}</div>
        </div>);
    }

}

ResourceItem.propTypes = {
    isActive: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};