import React, { useCallback } from "react";

import DrawerHeader from "joynt/components/UtilityNav/DrawerNav/DrawerHeader";
import { useGlobalNotifications } from "joynt/hooks/notifications";

function HeaderWithCount({ header, id }) {
    const { count } = useGlobalNotifications(id);

    if (count) {
        return header + ` (${count})`;
    }
    return header;
}

const ViewsHeaderMapper = {
    inbox: <HeaderWithCount id={"messages"} header={"inbox"} />,
    activity: <HeaderWithCount id={"activity"} header={"activity"} />,
};

export default function DrawerHeaderContainer(props) {
    const { view, onShow, defaultView } = props;

    const goBack = useCallback(() => {
        onShow(defaultView);
    }, [onShow, defaultView]);

    const label = ViewsHeaderMapper[view?.toLowerCase()] || view;

    return <DrawerHeader onGoBack={goBack} label={label} />;
}
