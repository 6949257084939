import React from "react";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
import { useData } from "state/hooks";
import Preloader from "components/Preloader";
import Icon from "components/Icon";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

function CommitLog({ id }) {
    const { message } = useData({ type: "theme.vcs-log", id });
    return (
        <div className={"cols gap-xs"}>
            <div>{id}</div>
            <div>{message}</div>
        </div>
    );
}

export function ThemeGitConsole(props) {
    const { data, onPush, onPull, onCommit, onReload, isPending } = props;
    const [commitMessage, setCommitMessage] = React.useState("");
    return (
        <>
            <div className={"pad-lg rows gap-lg"}>
                <div className={"cols cols-spread cols-top"}>
                    <h2>{data.repository}</h2>
                    <div className={"cols cols-right gap-sm"}>
                        <IconButton onClick={onReload} icon={"mui-refresh"} />
                        <Button
                            onClick={onPull}
                            startIcon={<Icon>mui-cloud_download</Icon>}
                        >
                            Pull
                        </Button>
                        <Button
                            onClick={onPush}
                            endIcon={<Icon>mui-cloud_upload</Icon>}
                        >
                            Push
                        </Button>
                    </div>
                </div>
                <div className={"rows gap-md"}>
                    {data.status === 1 && (
                        <div className={"cols gap-md"}>
                            <TextField
                                value={commitMessage}
                                onChange={setCommitMessage}
                                label={"Commit message"}
                            />
                            <Button
                                disabled={!commitMessage || isPending}
                                onClick={() => onCommit(commitMessage)}
                            >
                                Commit
                            </Button>
                        </div>
                    )}
                </div>
                <pre dangerouslySetInnerHTML={{ __html: data.message }}></pre>
                <div className={"rows gap-sm"}>
                    {data.log &&
                        data.log.map((id) => {
                            return <CommitLog key={id} id={id} />;
                        })}
                </div>
            </div>
            <Preloader visible={isPending} />
        </>
    );
}

ThemeGitConsole.propTypes = {};

ThemeGitConsole.defaultProps = {
    data: {},
};

export default ThemeGitConsole;
