import React from 'react';
import {connect} from 'react-redux';
import {withData} from "containers/Entity";
import List from "containers/List";
import {ArrayIterator} from "components/list/iterator";
import {selectFilter} from "state/selectors/filters";
import {setFilter} from "state/actions/filters";
import EntityContext from "containers/context/EntityContext";
import Field from "containers/Field";

import 'css/filters.css';
import Icon from "components/Icon";

const mapStateToProps = (store, props) => {
    return {
        value: selectFilter(store, props.filter)
    }
};

const mapDispatchToProps = {
    setFilter
};

function FilterField(props) {
    const { type, label, source } = props;
    const { key } = props.data;
    return <div>
        <Field
            id={key}
            fieldType={type}
            variant={type === 'text' ? 'inline' : 'filter'}
            changeMode={'change'}
            source={source}
            label={label}
            nullable={true}
            multiple={true}
        />
    </div>;
}

FilterField.autoFillProps = [
    'label',
    'type',
    'source'
];

const FilterFieldIterator = ArrayIterator(withData(FilterField, {context: false}));

class ListFilterView extends React.PureComponent {

    render() {
        const { data, filter } = this.props;
        const filters = data.filters_config;

        if (!filters || !filters.length) return null;

        return (<div className={'pad-xs'}>
            <div className={'cols cols-middle gap-xs list-filter'}>
                <Icon>mui-search</Icon>
                <EntityContext.Provider value={['app.filters', filter].join('/')}>
                    <List
                        type={'db.filters_config'}
                        items={data.filters_config}
                        Iterator={FilterFieldIterator} />
                </EntityContext.Provider>
            </div>
        </div>);
    }

}

export default withData(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListFilterView));