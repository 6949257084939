export const jointAddPost = context => (parent) => {
    return {
        type: 'JOINT.ADD_POST',
        payload: {parent},
        context
    }
};

export const jointAddFragment = context => (data, stem, parent) => {
    return {
        type: 'JOINT.ADD_FRAGMENT',
        payload: {data, stem, parent},
        context
    }
};