export const enableNotifications = (context) => () => ({
    type: 'NOTIFICATIONS.SUBSCRIBE',
    payload: {},
    context
});

export const disableNotifications = (context) => () => ({
    type: 'NOTIFICATIONS.UNSUBSCRIBE',
    payload: {},
    context
});

export const updateNotificationStatus = status => ({
    type: 'NOTIFICATIONS.STATUS',
    payload: { status }
});

export const triggerUiNotification = (type, data = {}) => ({
    type: 'NOTIFICATIONS.TRIGGER',
    payload: {type, data}
});

export const markAsSeen = context => (id) => ({
    type: 'NOTIFICATIONS.MARK_AS_SEEN',
    payload: {id},
    context
});