import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

const themeColors = [
    "primary_color",
    "success_color",
    "light_color",
    "dark_color",
];

const fontTypes = ["body_font", "heading_font", "decorative_font"];

function Colors(props) {
    const { value } = props;
    return (
        <div>
            {themeColors.map((color) => (
                <div key={color}>
                    {color}: {value[color]}
                </div>
            ))}
        </div>
    );
}

Colors.defaultProps = {
    value: {},
};

function Typography(props) {
    const { value } = props;
    return (
        <div>
            {fontTypes.map((font) => (
                <div key={font}>
                    {font}: {value[font]?.family}
                </div>
            ))}
        </div>
    );
}

Typography.defaultProps = {
    value: {},
};

export function ThemePreset(props) {
    const {} = props;
    return (
        <div className={"cols cols-2 gap-md"}>
            <Field id={"preset.color_scheme"}>
                {({ value, onChange }) => {
                    return <Colors value={value} />;
                }}
            </Field>
            <Field id={"preset.typography"}>
                {({ value, onChange }) => {
                    return <Typography value={value} />;
                }}
            </Field>
        </div>
    );
}

ThemePreset.propTypes = {};

ThemePreset.defaultProps = {};

export default ThemePreset;
