export const colors = {
    red: '#f44336',
    pink: '#e91e63',
    purple: '#9c27b0',
    'deep-purple': '#673ab7',
    indigo: '#3f51b5',
    blue: '#2196f3',
    'light-blue': '#03a9f4',
    cyan: '#00bcd4',
    teal: '#009688',
    green: '#4caf50',
    'light-green': '#8bc34a',
    lime: '#cddc39',
    yellow: '#ffeb3b',
    amber: '#ffc107',
    orange: '#ff9800',
    'deep-orange': '#ff5722',
    'brown': '#795548',
    'grey': '#9e9e9e'
};

export const variants = [
    'lighten-1',
    'lighten-2',
    'lighten-3',
    'lighten-4',
    'lighten-5',
    'lighten-6',
    'lighten-7',
    'lighten-8',
    'darken-1',
    'darken-2',
    'darken-3',
    'darken-4',
    'darken-5',
    'darken-6'
];