import React from "react";

import { useSetSessionFlowSegment } from "joynt/components/SessionFlow/hooks";
import Button from "components/Button";
import { useSession, useSessionState } from "joynt/hooks/sessions";
import { useNodeData } from "joynt/hooks";
import cn from "classnames";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import { useCurrentIdentity } from "joynt/hooks";
import SessionStateLabel from "./../SessionStateLabel/SessionStateLabel";

import "./style.css";

export function SessionControlPanel(props) {
    const { id } = props;
    const userId = useCurrentIdentity(id);

    const onSetSegment = useSetSessionFlowSegment(id);

    const { onJoinSession, onStartSession, isConnected, hasEnded } =
        useSessionState(id);

    const {
        event_state,
        event_started: started,
        event_start: start,
        can_edit,
    } = useNodeData(id);

    const { onEdit } = useSession(id);

    const isLive = event_state === "live";
    const isAdmin = !!can_edit;

    return (
        <div className={cn("session-control-panel")}>
            <div
                className={cn("session-control-panel__content", {
                    centered: isLive,
                    "session-control-panel__content--live": isLive,
                })}
            >
                {isConnected && (
                    <div className={"session-control-panel__presence"}>
                        <Avatar size={"xs"} id={userId} /> You're in session
                    </div>
                )}
                <SessionStateLabel
                    {...{
                        state: event_state,
                        isLive,
                        hasEnded,
                        start,
                        started,
                    }}
                />
                <>
                    {isAdmin && hasEnded && (
                        <Button
                            onClick={onEdit}
                            size={"large"}
                            variant={"outlined"}
                        >
                            Reschedule session
                        </Button>
                    )}
                    {isAdmin && !isLive && !hasEnded && (
                        <Button onClick={onStartSession} size={"large"}>
                            Start session
                        </Button>
                    )}
                    {isAdmin && isLive && (
                        <Button
                            onClick={() => onSetSegment("end")}
                            variant={"outlined"}
                            size={"large"}
                        >
                            End session
                        </Button>
                    )}
                    {userId && isLive && !isConnected && (
                        <Button onClick={onJoinSession} size={"large"}>
                            Join session
                        </Button>
                    )}
                </>
            </div>
        </div>
    );
}

SessionControlPanel.propTypes = {};
SessionControlPanel.defaultProps = {};

export default SessionControlPanel;
