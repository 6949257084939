import React from "react";
import WorkspaceHeader from "joynt/components/WorkspaceHeader";
import Feed from "joynt/views/feed/Feed";
import Page from "joynt/views/Page";
import HelperBar from "joynt/components/Help/HelperBar";
//import PropTypes from 'prop-types';
import "./views.css";

export function WorkspaceOverviewFeed(props) {
    const { id } = props;

    return (
        <div className={"rows overflow-hide gap-md"}>
            <div className={"cols overflow-hide"}>
                <div className={"workspace-overview__layout"}>
                    <div className={"workspace-overview__header no-shrink"}>
                        <WorkspaceHeader {...props} />
                    </div>
                    <Feed {...props} showHeader={false}>
                        <div className={"workspace-overview__feed-header"}>
                            <HelperBar />
                            <h3 className={"workspace-overview__heading"}>
                                Newest posts in your space:
                            </h3>
                        </div>
                    </Feed>
                    <div className={"workspace-overview__about"}>
                        <div className={"card"}>
                            <Page id={id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

WorkspaceOverviewFeed.propTypes = {};
WorkspaceOverviewFeed.defaultProps = {};

export default WorkspaceOverviewFeed;
