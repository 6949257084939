import FileManagerContainer from "cms/ui/theme/containers/FileManager";
import Github from "cms/ui/theme/containers/Github";
import ThemePresetContainer from "cms/ui/theme/containers/ThemePreset";
import TypographyContainer from "cms/ui/theme/containers/Typography";

export default {
    preset: ThemePresetContainer,
    files: FileManagerContainer,
    typography: TypographyContainer,
    vcs: Github
};