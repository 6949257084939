import {useCallback} from "react";
import {useSelector} from "react-redux";
import {useActions} from "state/hooks";
import {hideNotification} from "state/actions/ui";
import {useNotification} from "./useNotification";

export function useNotifications() {
    const list = useSelector(store=>store.ui.notifications);
    const seen = useNotification();
    const {onHide} = useActions({
        onHide: hideNotification
    });
    const hide = useCallback((id) => {
        onHide(id);
        seen(id);
    }, [onHide, seen]);
    return [list, hide];
}