export function selectDefinedStyles(store, type) {
    const data = Object.keys(store?.data?.["cms.styles"] || {});
    const changes = Object.keys(store?.changes?.["cms.styles"] || {});
    const all = [...new Set([...data, ...changes])];
    const ofType = all.filter((id) => id.indexOf(type) === 0).sort();
    return ofType;
}

export function selectComponentTypes(store) {
    const defs = store?.data?.["cms.styles"]?.definitions || {};
    const componentTypes = Object.keys(defs).filter((k) => {
        return ![
            "options",
            "flags",
            "option_types",
            "option_roles",
            "element_roles",
            "element_groups",
            "display_map",
        ].includes(k);
    });
    return componentTypes;
}

export function selectElements(store, id, value = {}) {
    const [type, style] = id.split(".");
    const defs = store?.data?.["cms.styles"]?.definitions || {};
    const optionTypes = defs?.["option_types"];
    const typeDefinition = defs[type] || {};
    const availableElements = Object.keys(typeDefinition);
    const definedElements = Object.keys(value || {}).filter(
        (k) => value[k] !== null
    );
    const classListElements = availableElements.filter((k) => {
        if (k === "@attributes") return false;
        return !Object.keys(optionTypes).includes(k);
    });
    const definedClassListElements = definedElements.filter((k) => {
        return classListElements.includes(k);
    });

    return {
        defined: definedElements,
        classList: classListElements,
        available: availableElements,
        definedClassList: definedClassListElements,
    };
}
