import React from 'react';
import {connect} from 'react-redux';
import NotifcationsContainer from 'containers/Notifications';
import {pushActivityEvent} from "joynt/state/actions/activity";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";

const mapStateToProps = (store, props) => {
    return {
        namespace: '.activity'
    }
};

const mapDispatchToProps = bindApiActions({
    onPushMessage: pushActivityEvent
});

class Activity extends React.PureComponent {

    handle = (msg) => {
        const { onPushMessage } = this.props;
        onPushMessage(msg.context);
    };

    render() {
        const { namespace } = this.props;
        return (<NotifcationsContainer
            namespace={namespace}
            handler={this.handle}
        >
            {this.props.children}
        </NotifcationsContainer>);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Activity));