import React, { useState } from "react";
//import PropTypes from 'prop-types';
import SitemapSearch from "./SitemapSearch";
import { useFetch, useFilter, useList } from "state/hooks";
import { useSelector } from "react-redux";
import { selectResultsByPageType } from "./selectors";

export function SitemapSearchContainer(props) {
    const { pageId, sectionId } = props;

    const type = "cms.sitemap";

    const [page, setPage] = useState(pageId);

    const pending = useFetch({
        type,
        url: "cms/sitemap",
        list: type,
        filter: type,
    });

    useFetch({
        type: "cms.sitemap.sections",
        url: `cms/sitemap/${page}/sections`,
        list: "cms.sitemap.sections",
        enable: !!page,
    });

    const { items: allItems } = useList(type, type, type);
    const { items: sections } = useList(
        "cms.sitemap.sections",
        "cms.sitemap.sections",
        "cms.sitemap.sections"
    );
    const { value, onSet } = useFilter(type, type, type);

    const [group, setGroup] = useState(null);

    const groups = useSelector((store) => {
        return selectResultsByPageType(store, type);
    });

    const currentGroup = Object.keys(groups).includes(group) ? group : null;

    const items = currentGroup ? groups[currentGroup].items : allItems;

    return (
        <SitemapSearch
            {...props}
            type={type}
            isPending={pending}
            items={items}
            sections={sections}
            groups={Object.values(groups)}
            onSetGroup={setGroup}
            selectedGroup={currentGroup}
            page={page}
            onSetPage={setPage}
        />
    );
}

SitemapSearchContainer.propTypes = {};

SitemapSearchContainer.defaultProps = {};

export default SitemapSearchContainer;
