import React from "react";
import SessionTrack from "joynt/components/SessionTrack/SessionTrack";
import DailySelect from "joynt/components/DateTimePicker/DailySelect/DailySelect";
import "./style.css";

export default function DailyCalendar(props) {
    const { workspaceHeader, day, onChange, name, color, avatar, children } =
        props;

    const date = new Date(day * 1000);

    return (
        <div className={"daily-calendar"}>
            <DailySelect startingDate={date} onChange={onChange} />
            <section className={"daily-calendar__body"}>
                {/*<header className={"daily-calendar__header"}>*/}
                {/*    {workspaceHeader}*/}
                {/*</header>*/}

                {/*<SessionTrack name={name} color={color} avatar={avatar}>*/}
                {children}
                {/*</SessionTrack>*/}
            </section>
        </div>
    );
}
