import React from "react";

//import PropTypes from 'prop-types';

export function ZuuLogo(props) {
    const { className } = props;
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256.06 48.55"
        >
            <defs>
                <path id="A" d="M-860.4 345.92h762.36v210.31H-860.4z" />
                <path
                    id="B"
                    d="M101.42 15.8v19.76h-4.4v-2.52c-1.48 1.81-3.81 2.77-6.37 2.77-5.07 0-8.51-2.77-8.51-8.73V15.8h4.63v10.66c0 3.59 1.7 5.29 4.62 5.29 3.22 0 5.4-2 5.4-5.96V15.8h4.63z"
                />
            </defs>
            <clipPath id="C">
                <use href="#A" />
            </clipPath>
            <g
                opacity=".2"
                clip-path="url(#C)"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
            >
                <path d="M144.96 23.09l-32.98 19.05 16.49 9.52-16.49 28.56 32.98 19.04 32.99-19.04-16.49-9.52 16.49-28.56z" />
                <path d="M144.96 23.09v19.05l-16.49 9.52" />
                <path d="M111.98 42.14h65.97" />
                <path d="M111.98 80.22l65.97-38.08" />
            </g>
            <g fill="#6d6e71">
                <path d="M77.11,31.86v3.7H60.05v-2.92L70.9,19.5H60.28v-3.7h16.54v2.92L65.94,31.86H77.11z" />
                <use href="#B" />
                <use href="#B" x="26.12" />
                <path d="M133.46 33.71c0-1.15.89-2 1.96-2s2 .85 2 2-.93 2.04-2 2.04c-1.07-.01-1.96-.9-1.96-2.04zm38.33-17.47l-7.33 19.32h-1.74l-6.51-16.76-6.55 16.76h-1.7l-7.33-19.32h1.78l6.44 17.24 6.59-17.24h1.59l6.55 17.21 6.51-17.21h1.7zm2.56 9.66c0-5.74 4.11-9.81 9.66-9.81s9.66 4.07 9.66 9.81-4.11 9.81-9.66 9.81c-5.56-.01-9.66-4.08-9.66-9.81zm17.42 0c0-4.85-3.33-8.14-7.77-8.14s-7.77 3.29-7.77 8.14 3.33 8.14 7.77 8.14 7.77-3.29 7.77-8.14zm17.69-9.81v1.81c-.15 0-.3-.04-.44-.04-4.29 0-6.85 2.85-6.85 7.62v10.06h-1.85v-19.3h1.78v4.22c1.18-2.81 3.73-4.37 7.36-4.37zm12.91 9.14l-5.03 4.59v5.74h-1.85V8.1h1.85v19.43l12.36-11.29h2.37l-8.33 7.81 9.14 11.51h-2.29l-8.22-10.33zm12.36 8l.85-1.48c1.48 1.26 4.11 2.33 6.96 2.33 4.07 0 5.7-1.44 5.7-3.63 0-5.62-12.8-1.48-12.8-9.07 0-2.92 2.44-5.29 7.33-5.29 2.44 0 5.11.74 6.62 1.89l-.85 1.48c-1.63-1.18-3.74-1.74-5.81-1.74-3.77 0-5.44 1.55-5.44 3.63 0 5.81 12.8 1.7 12.8 9.07 0 3.15-2.7 5.29-7.62 5.29-3.19-.01-6.22-1.08-7.74-2.48z" />
            </g>
            <path
                d="M7.01 13.44L25.3 2.88l18.29 10.56-9.15 15.84 9.15 5.28L25.3 45.11 7.01 34.56l9.15-15.84z"
                fill="#782761"
            />
            <path d="M43.59 13.44L25.3 2.88v10.56z" fill="#f5a4c7" />
            <path d="M25.3 2.88v10.56l-9.14 5.28-9.15-5.28z" fill="#88d1d1" />
            <path d="M7.01 13.44H25.3V2.88z" fill="#00b5a5" />
            <path d="M25.3 45.11V34.56l9.14-5.28 9.15 5.28z" fill="#f5a4c7" />
            <path d="M43.59 34.56H25.3v10.55z" fill="#b64e87" />
            <path d="M43.59 13.44L7.01 34.56H25.3l9.14-5.28z" fill="#f06ca8" />
            <path d="M16.16 18.72l9.14-5.28h18.29L7.01 34.56z" fill="#b64e87" />
        </svg>
    );
}

ZuuLogo.propTypes = {};

ZuuLogo.defaultProps = {};

export default ZuuLogo;
