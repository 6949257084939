import React from 'react';
import PropTypes from 'prop-types';
import SessionItem from "./SessionItem";
import {useNodeData} from "joynt/hooks";

export function SessionItemContainer(props) {

    const {id} = props;

    const {name, event_start, event_end} = useNodeData(id);

    return (<SessionItem
        {...props}
        name={name}
        start={event_start}
        end={event_end}
    />);

}

SessionItemContainer.propTypes = {
    id: PropTypes.string
};
SessionItemContainer.defaultProps = {};

export default SessionItemContainer;