import React, { createRef, useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";

import Profiles from "joynt/participants/Profiles";

import DashboardTile from "joynt/components/DashboardTile";
import { useWindowSize } from "util/hooks/useWindowSize";

export function AboutDashboardTile({ children, fullList, ...props }) {
    const contentRef = createRef();
    const { width } = useWindowSize();
    const showList = fullList && fullList?.length > 5;

    const [isTruncated, setIsTruncated] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const element = contentRef.current;
        if (element && !showList) {
            setIsTruncated(element.scrollHeight > element.clientHeight);
        }
        if (showList) {
            setIsTruncated(true);
        }
    }, [contentRef, showList, isTruncated, width]);

    return (
        <>
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <DashboardTile
                    {...props}
                    className={"about-view__modal-dialog"}
                >
                    {showList ? <Profiles items={fullList} /> : children}
                </DashboardTile>
            </Dialog>
            <DashboardTile
                {...props}
                ctaLabel={isTruncated ? "View more" : null}
                onCtaClick={isTruncated ? () => setIsModalOpen(true) : null}
            >
                <div ref={contentRef} className={"about-view__limited-tile"}>
                    {children}
                </div>
            </DashboardTile>
        </>
    );
}
export default AboutDashboardTile;
