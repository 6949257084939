import React from "react";
//import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

import { useData } from "state/hooks";
import Field from "containers/Field";
import CollectionField from "./ContentCollection";
import { useAdaptiveContent } from "./hooks";
import Media from "playground/cms/fields/Media";
import { selectDynamicContentType } from "playground/cms/state/selectors/sections";
import SectionContentTypes from "./SectionContentTypes";
import SectionUsage from "./SectionUsage";
import SectionComponents from "./SectionComponents";

import "./style.css";
import TagsField from "playground/cms/fields/TagsField";
import { pageTypeToCmsType } from "cms/util";

export function SectionContent(props) {
    const { type: entityType, id, isPreview, isDraft } = props;
    const { type, used_in, reference_id, template } = useData(props);
    const content = useAdaptiveContent(id, isDraft);
    const isVisible = (field) => {
        if (field === "media" && content.background) return true;
        if (isDraft && content.optional.includes(field)) return true;
        return (
            content.visible.includes(field) || content.static.includes(field)
        );
    };
    const setVisible = (field) => content.setVisible(field);
    const isTemplate = type === "view";
    const hasTemplate = !!template;

    const dynamicContentType = useSelector((store) => {
        return selectDynamicContentType(store, entityType, id);
    });

    const tagsEntityType = dynamicContentType
        ? pageTypeToCmsType(dynamicContentType.entry_type)
        : null;

    const tagsListId = tagsEntityType
        ? ["cms.components", dynamicContentType.component_id].join(".")
        : null;

    return (
        <>
            <div className={"rows gap-xs"}>
                {used_in && used_in.length > 1 && (
                    <SectionUsage
                        type={entityType}
                        id={id}
                        isTemplate={isTemplate}
                        usedIn={used_in.length}
                    />
                )}

                {dynamicContentType && (
                    <div className={"form-info teal"}>
                        <span>
                            Ta sekcja wyświetla dynamiczną treść z{" "}
                            <strong>{dynamicContentType?.entry_type}</strong>
                        </span>
                    </div>
                )}
            </div>

            {isVisible("tags") && (
                <TagsField
                    id={"tags"}
                    label={"Tagi"}
                    entityType={tagsEntityType}
                    listId={tagsListId}
                />
            )}

            {isVisible("title") && (
                <Field
                    id={"title"}
                    label={"Tytuł sekcji"}
                    className={"page-title"}
                />
            )}

            {isVisible("headline") && (
                <Field id={"headline"} label={"Podtytuł"} />
            )}
            {isVisible("text") && (
                <Field
                    id={"text"}
                    label={"Treść"}
                    fieldType={"rich"}
                    disableSubmit={true}
                />
            )}
            {isVisible("media") && (
                <Field
                    id={"media"}
                    fieldType={"media"}
                    fieldEntityType={"cms.sections"}
                    fieldEntityId={id}
                    FieldComponent={Media}
                    label={content.background ? "Zdjęcie w tle" : "Media"}
                />
            )}
            {isVisible("gallery") && (
                <CollectionField
                    id={"gallery"}
                    label={"Elementy treści"}
                    fieldEntityId={id}
                    schema={content.schemas.gallery}
                />
            )}
            {isVisible("links") && (
                <CollectionField
                    id={"links"}
                    label={"Odnośniki"}
                    fieldEntityId={id}
                    schema={content.schemas.links}
                />
            )}

            <SectionContentTypes
                optional={content.optional}
                all={content.all}
                onToggle={setVisible}
            />

            {/*{!isTemplate && !isPreview && <SectionTemplate {...props} />}*/}

            {content.hasComponents && (
                <div>
                    this section has components{" "}
                    {!isTemplate && <span>/ create template</span>}
                </div>
            )}

            {reference_id && <div>Referencja do szablonu z biblioteki</div>}

            <SectionComponents sectionId={id} items={content.components} />
        </>
    );
}

SectionContent.propTypes = {};

SectionContent.defaultProps = {};

export default SectionContent;
