import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import Entity from "containers/Entity";
import MuiListItem from "@material-ui/core/ListItem";
import classNames from "classnames";
import ListItemText from "@material-ui/core/ListItemText";
import {ArrayIterator} from "components/list/iterator";
import {SortableIterator} from "components/dnd";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Icon from "components/Icon";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from "components/Avatar";
import Button from "components/Button";

import iconsConfig from 'config/icons';
import {types as nodeTypes} from "joynt/config/types"

import 'css/list/list-item.css';
import IconButton from "components/IconButton";

function SecondaryAction({action, icon, button, onContextMenu}) {
    const click = useCallback(evt => {
        evt.stopPropagation();
        evt.preventDefault();
        action();
    }, [action]);
    return <ListItemSecondaryAction>
        { action && button
            ? <Button
                onClick={click}
                size={'large'}
                className={'secondary-action-button'}
            >
                <Icon>{icon}</Icon>
            </Button> : null }
        { action && !button
            ? <IconButton icon={icon} onClick={click}/>
            : null }
        { onContextMenu
            ? <IconButton icon={'mui-more_vert'} onClick={onContextMenu} />
            : null }
    </ListItemSecondaryAction>;
}

function RenderListItemAvatar(props) {
    const {
        icon,
        media,
        type,
        color,
        active,
        isParent,
        mediaType,
        Component
    } = props;

    let typeIcon = icon || iconsConfig[type];

    if (Component) return <ListItemAvatar>
        <Component {...props} icon={typeIcon} />
    </ListItemAvatar>;

    return !isParent ? <ListItemAvatar>
        <Avatar
            active={active}
            icon={typeIcon}
            media={media}
            color={color}
            mediaType={mediaType}
        />
    </ListItemAvatar> : <ListItemAvatar>
        <Avatar icon={'arrow_back_ios'} />
    </ListItemAvatar>;
}

export const ListItem = React.forwardRef((props, ref) => {
    const {
        type,
        id,
        onClick,
        onSecondaryAction,
        onContextMenu,
        isDragging,
        selectedId,
        parent,
        secondaryActionIcon,
        secondaryActionButton,
        Avatar,
        Info
    } = props;

    const selected = selectedId === id;
    const context = onContextMenu ? (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        onContextMenu(evt, type, id)
    } : null;
    const action = onSecondaryAction ? (evt) => onSecondaryAction(id) : null;
    const mediaType = type.indexOf('cms.') === 0 ? 'cms.media' : 'db.media';

    return <Entity type={type} id={id}>
        {({data})=>{

            const isParent = parent === id;
            let title = data.name;
            const subtitle = nodeTypes[data.subtype] ? nodeTypes[data.subtype].label : null;
            const fallback = 'No name';
            if (isParent) title = `go back to ${title}`;
            const titleComponent = title || <div className={'list-item-title-placeholder'}>{fallback}</div>;
            const isActive = data.active || false;
            const icon = nodeTypes[data.subtype] ? nodeTypes[data.subtype].icon : null;

            return <MuiListItem selected={selected} className={classNames({
                'list-item': true,
                'is-dragging': isDragging
            })} button ref={ref} onContextMenu={context} onClick={()=>onClick(id)}>

                <RenderListItemAvatar
                    isParent={isParent}
                    type={type}
                    id={id}
                    active={isActive}
                    icon={icon}
                    media={data.media}
                    color={data.color}
                    mediaType={mediaType}
                    Component={Avatar} />

                <ListItemText primary={titleComponent} secondary={subtitle} />

                { Info ? <ListItemSecondaryAction>
                    <Info type={type} id={id} />
                </ListItemSecondaryAction> : null }

                { !isParent && (action || context) ? <SecondaryAction
                    action={action}
                    icon={secondaryActionIcon || 'mui-edit'}
                    button={secondaryActionButton}
                    onContextMenu={context}
                /> : null }

            </MuiListItem>
        }}
    </Entity>;
});

export const SortableListItemIterator = SortableIterator(ListItem);
export const ListItemIterator = ArrayIterator(ListItem);

ListItem.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    id: PropTypes.string,
    Avatar: PropTypes.func,
    Info: PropTypes.func
};