import React from "react";
import { FormLayout } from "playground/cms/Form";
import SectionContent from "./SectionContent";
import SectionContentLegacy from "./SectionContentLegacy";
import SectionSettings from "./SectionSettings";
import SectionFormHeader from "./SectionFormHeader";
import { useData } from "state/hooks";
import PageTranslations from "playground/cms/views/Pages/PageForm/PageTranslations";
import { DebugIconButton } from "playground/debug";

//import PropTypes from 'prop-types';

export function SectionForm(props) {
    const { isPreview, isDraft } = props;
    const { type } = useData(props);
    const isLegacy = ["list-static"].includes(type);
    const Content = isLegacy ? SectionContentLegacy : SectionContent;
    return (
        <FormLayout {...props}>
            <FormLayout.Header>
                <SectionFormHeader {...props} />
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                <FormLayout.Tab id={"content"} label={"Treść"}>
                    <div className={"rows pad-md gap-md"}>
                        <Content {...props} />
                    </div>
                </FormLayout.Tab>
                <FormLayout.Tab id={"settings"} label={"Ustawienia"}>
                    <div className={"rows pad-md gap-md"}>
                        <SectionSettings {...props} />
                    </div>
                </FormLayout.Tab>
                <FormLayout.Tab id={"translations"} label={"Tłumaczenia"}>
                    <div className={"rows pad-md"}>
                        <PageTranslations {...props} />
                    </div>
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                <DebugIconButton type={props.type} id={props.id} />
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

SectionForm.propTypes = {};

SectionForm.defaultProps = {};

export default SectionForm;
