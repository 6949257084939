import React from "react";
//import PropTypes from 'prop-types';

import { useNode } from "joynt/hooks";
import { usePending } from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import Preloader from "components/Preloader";
import formType from "./types";

export default function NodeEditForm(props) {
    const { id } = props;
    const { isDraft, onSubmit, onReject } = props;

    const data = useNode(id);
    const { workspace, session_type } = data;

    const FormComponent = formType(data);

    const isPending = usePending(id);

    if (!FormComponent) {
        return (
            <div className={"pad-md"}>
                No form configured for this node type
            </div>
        );
    }

    const form = (
        <FormComponent
            id={id}
            isDraft={isDraft}
            onReject={onReject}
            onSubmit={onSubmit}
        />
    );

    if (workspace && !session_type)
        return (
            <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
                {form}
            </AggregateRoot>
        );

    return (
        <AggregateRoot
            type={"db.nodes"}
            id={id}
            disableSubmit={true}
            validate={true}
        >
            <Preloader visible={isPending} />
            <div className={"type-default overflow-hide rows edit-modal"}>
                {form}
            </div>
        </AggregateRoot>
    );
}

NodeEditForm.propTypes = {};
