import React  from "react";
import {connect} from "react-redux";

import TextField from 'components/fields/text/TextSearch';
import WebfontIcon from "components/WebfontIcon";

import libraries from 'config/icon-libraries';
import {selectRouteParam} from "state/selectors/router";
import domainRouter from "util/domainRouter";

const {iconLibraries} = domainRouter();

const listItemStyle = {
  width: "40px",
  float: "left",
  textAlign: "center",
  fontSize: "1.6rem",
  padding: "10px 0"
};

const librariesPerService = {
  'default': ['fh-default', 'font-awesome', 'material-ui']
};

function getServiceLibraries(service) {
  const libs = iconLibraries || librariesPerService[service] || librariesPerService.default;
  let icons = [];
  libs.forEach(libId=>{ icons = icons.concat(libraries[libId]); });
  return icons;
}

const mapState = (store, props) => {
  return {
    service: selectRouteParam(store, 'service')
  }
};

const mapDispatch = {};

class IconPickerDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      icons: [],
      filteredIcons: [],
      hover: null
    };
  }

  componentDidMount() {
    const { service } = this.props;
    const icons = getServiceLibraries(service);
    this.setState({ icons, filteredIcons: icons });
  }

  handleSubmit = (name) => {
    this.props.onSubmit(name);
  };

  handleHover(name) {
    this.setState({
      ...this.state,
      hover: name
    });
  }

  filter = (value) => {
    let filteredList = this.state.icons;
    this.setState({search: value});
    filteredList = filteredList.filter(item => {
      return item.toLowerCase().search(value.toLowerCase()) !== -1;
    });
    this.setState({ filteredIcons: filteredList });
  };

  render() {
    const listItems = this.state.filteredIcons.map((name, key) => (
      <div
        key={key}
        style={listItemStyle}
        onClick={()=>this.handleSubmit(name)}
        onMouseOver={this.handleHover.bind(this, name)}
      >
        <WebfontIcon>{name}</WebfontIcon>
      </div>
    ));
    return (
      <>
        <div className={'rows'}>
          <TextField label="Find icon" onChange={this.filter} value={this.state.search} />
          <div className={'cols cols-center o-50 pad-xs'}>
            {this.state.hover ? this.state.hover : null}
          </div>
        </div>
        <div className={'grow pad-sm overflow-scroll'}>
          {listItems}
        </div>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(IconPickerDialog);
