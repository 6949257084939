import React from "react";
import IconButton from "joynt/components/IconButton";
import { useEditPage } from "joynt/hooks/channels";
import { useClick } from "state/hooks";

const SettingsIconButton = ({ id }) => {
    const onEdit = useEditPage();
    const edit = useClick({ onClick: onEdit, id });
    return (
        <IconButton
            withTooltip
            label={"Settings"}
            icon={"mui-settings"}
            size={"small"}
            onClick={edit}
        />
    );
};

export default SettingsIconButton;
