import React from 'react';
import {connect} from 'react-redux';
import ListView from "containers/ListView";
import List from 'containers/List';
import PendingState from "components/list/PendingState";
import {pushRouteParams} from "state/actions/router";
import {ResourceIconIterator} from "components/user-menu/ResourceIcon";
import {SortableListItemIterator} from "components/list/items/ListItem";
import {create} from "state/actions/create";
import uuid from "uuid/v4";
import MuiList from "@material-ui/core/List";
import Fab from "@material-ui/core/Fab";
import {Icon} from "@material-ui/core";
import ListItemMenu from "components/context-menu/ListItemMenu";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    onShowResource: pushRouteParams,
    onCreate: create
};

class ResourceList extends React.PureComponent {

    resource = (id) => {
        const { onShowResource } = this.props;
        onShowResource({type: id});
    };

    create = () => {
        const { onCreate, type, list } = this.props;
        const newId = uuid();
        const newItem = {id: newId, draft: true};
        if (type === 'cms.components') newItem.type = 'text';
        onCreate(type, newItem, list);
        this.show(newId);
        //this.props.pushQuery({id: newId, tab: 'edit'});
        //this.cancelCreate();
    };

    show = (id) => {
        const { onClick, onShowResource, type } = this.props;

        if (type === 'cms.components') {
            onShowResource({
                list: 'tree',
                focus: [type, id].join('/'),
                ins: 'edit'
            });
        } else {
            onClick(id);
        }
    };

    render() {
        const {
            type,
            id,
            list,
            onEdit,
            onSortEnd
        } = this.props;

        return (<div className={'cols grow overflow-hide'}>
            <div className={'pad-xs'}>
                <List
                    type={'app.user-menu'}
                    list={'pages'}
                    onClick={this.resource}
                    active={type}
                    Iterator={ResourceIconIterator} />
            </div>
            <div className={'rows default-c700 grow overflow-scroll'}>
                <MuiList className={'grow'}>
                    <ListView
                        context={true}
                        RenderMenuItems={ListItemMenu}
                        type={type}
                        list={list}
                        selectedId={id}
                        onClick={this.show}
                        onSecondaryAction={onEdit}
                        pendingState={type !== 'cms.pages' && <PendingState />}
                        enable={type !== 'cms.pages'}
                        onSortEnd={onSortEnd}
                        emptyState={<div>nothing here</div>}
                        Iterator={SortableListItemIterator}
                    />
                </MuiList>
                <div className={'list-actions'}>
                    <div className={'list-fab'}>
                        <Fab onClick={this.create} color={'secondary'}>
                            <Icon>add</Icon>
                        </Fab>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceList);