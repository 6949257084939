import React from "react";

import "joynt/css/welcome.css";

import AggregateRoot from "containers/AggregateRoot";
import SetupIdentity from "joynt/welcome/SetupIdentity";
import { logout } from "state/actions/auth";
import {useForm} from "joynt/hooks";
import {useActions, useApiActions, usePending, useRouteParam} from "state/hooks";
import {submitConfigureAccount} from "joynt/state/actions/account";
import {ROUTE_CHANNEL, ROUTE_ID} from "joynt/config";

export function WelcomeView(props) {

    const {children} = props;

    const [id] = useRouteParam(ROUTE_ID);
    const [ch] = useRouteParam(ROUTE_CHANNEL);

    const {isValid} = useForm("ui.forms", "welcome");

    const isPending = usePending("welcome");

    const { onSubmit } = useApiActions({
        onSubmit: submitConfigureAccount
    });

    const { onLogout } = useActions({
        onLogout: logout,
    });

    const handleSubmit = () => onSubmit(id || null, ch || null);

    return (
        <AggregateRoot
            type={"ui.forms"}
            id={"welcome"}
            validate={true}
            disableSubmit={true}
        >
            <SetupIdentity
                isValid={isValid}
                pending={isPending}
                onSubmit={handleSubmit}
                onLogout={onLogout}
                children={children}
            />
        </AggregateRoot>
    );
}

export default WelcomeView;