import React from 'react';

export default class Point extends React.Component {

    handleOnClickCopy() {
        this.props.copyPoint(this.props.point.x, this.props.point.y);
    }

    handleMouseUp(e) {
        if (e.altKey) {
            this.props.deletePoint(this.props.index);
            return;
        }
        this.props.onMouseUp(e);
    }

    render() {
        if (this.props.point.tmp) return null;
        const {x, y} = this.props;

        const activePoint = {
            position: 'absolute',
            left: x + 'px',
            top: y + 'px',
            width: '10px',
            height: '10px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '3',
            cursor: 'pointer',
            fontSize: '30px',
            fontWeight: 'bold'
        };
        const inactivePoint = {
            ...activePoint,
            backgroundColor: '#FFFF00',
            zIndex: '2'
        };
        const selectedPoint = {
            ...activePoint,
            backgroundColor: 'red'
        };

        const style = this.props.selected ? (
            this.props.pointSelected ? selectedPoint : activePoint
        ) : inactivePoint;

        const numberingPoints = this.props.showIndex ? this.props.index+1 : null;
        return (this.props.selected ? (<div className="point" style={style}
            onMouseDown={this.props.onMouseDown}
            onMouseUp={this.handleMouseUp.bind(this)}>
            {numberingPoints}
        </div>) : (
            <div className="point" style={style}
                 onClick={this.handleOnClickCopy.bind(this)}
            >{numberingPoints}</div>
        ));
    }

}