import React from 'react';
//import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import NodeSelectDialog from "joynt/modals/NodeSelectDialog";
import Preloader from "components/Preloader";
import {ResponsivePopover} from "components/Popover";
import {useActions, usePending, useRouteParam} from "state/hooks";
import {nodeSelect, nodeSelectCancel} from "joynt/state/actions";

export default function NodeSelect(props) {

    const [open] = useRouteParam('select');
    const pending = usePending('move-node');

    const {onSelect, onCancel} = useActions({
        onSelect: nodeSelect,
        onCancel: nodeSelectCancel
    })

    return (<ResponsivePopover
        open={open}
        onClose={onCancel}
        Component={Dialog}
    >
        <NodeSelectDialog onSelect={onSelect} />
        <Preloader visible={pending} />
    </ResponsivePopover>);

}

NodeSelect.propTypes = {};