import React from "react";
//import PropTypes from 'prop-types';
import Languages from "./Languages";
import { useFormField } from "state/hooks";

export function LanguagesContainer(props) {
    const {} = props;

    const { value: languagesValue, onChange } = useFormField("languages");
    const { value: visibleLanguagesValue, onChange: onChangeVisibleLanguages } =
        useFormField("visible_languages");

    const languages = languagesValue || [];
    const visibleLanguages = visibleLanguagesValue || [];

    const handleAddLanguage = (v) => {
        if (!languages.includes(v)) {
            onChange([...languages, v]);
        }
        if (!visibleLanguages.includes(v)) {
            onChangeVisibleLanguages([...visibleLanguages, v]);
        }
    };

    const handleRemoveLanguage = (v) => {
        onChange(languages.filter((l) => l !== v));
        onChangeVisibleLanguages(visibleLanguages.filter((l) => l !== v));
    };

    const handleToggleVisible = (v) => {
        onChangeVisibleLanguages(
            visibleLanguages.includes(v)
                ? visibleLanguages.filter((l) => l !== v)
                : [...visibleLanguages, v]
        );
    };

    return (
        <Languages
            onAdd={handleAddLanguage}
            onRemove={handleRemoveLanguage}
            onToggleVisible={handleToggleVisible}
            languages={languages}
            visibleLanguages={visibleLanguages}
            {...props}
        />
    );
}

LanguagesContainer.propTypes = {};

LanguagesContainer.defaultProps = {};

export default LanguagesContainer;
