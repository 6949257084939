export default {
    latency: 1200,
    maxAge: 36,
    stateSanitizer: (state) => {
        return {
            ...state,
            changes: state.changes,
            ui: state.ui,
            lists: state.lists,
            data: {
                ...state.data,
                "cms.components": "<<TRIM>>",
                "cms.sections": "<<TRIM>>",
                "schema.db": "<<TRIM>>",
                "schema.data.value": "<<TRIM>>",
                "cms.media": "<<TRIM>>",
                "db.fields": "<<TRIM>>",
                "theme.files": "<<TRIM>>",
                "cms.items": "<<TRIM>>",
                "cms.styles": "<<TRIM>>",
                "cms.links": "<<TRIM>>",
            },
        };
    },
    actionSanitizer: (action) => {
        if (action.type.indexOf("STORE") > -1) return { type: action.type };

        return action;
    },
};
