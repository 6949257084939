import React from 'react';
// import PropTypes from 'prop-types';

import "./style.css";

export function TabsNav(props) {

    const {children} = props;
    return (<div className={"tabs-nav"}>{children}</div>);

}

TabsNav.propTypes = {};
TabsNav.defaultProps = {};

export default TabsNav;
