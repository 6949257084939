import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import useMeeting from "joynt/meeting/hooks/useMeeting";

import {
    EndCallButton,
} from "joynt/components/SessionToolbar/Buttons";

import { useUiFlag } from "state/hooks";
import IconButton from "components/IconButton";
import { FLAG_SESSION_NAV } from "joynt/config";
import { useEdgesData } from "joynt/hooks";
import { useNotificationCount } from "joynt/hooks/notifications";

import Badge from "@material-ui/core/Badge";
import AudioVideo from "joynt/components/SessionToolbar/AudioVideo";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";

export default function StageOverlayToolbarContainer() {
    const { session, event, onLeaveSession } = useMeeting();
    const { session_chat: chatId } = useEdgesData(event);

    const [notifications] = useNotificationCount(chatId);
    const [, onShowSessionView] = useUiFlag(FLAG_SESSION_NAV);

    const [, setCollapsed] = useUiFlag(`layout.collapse.${session}`);
    const goFull = useCallback(() => setCollapsed(true), [setCollapsed]);

    const showChat = () => {
        goFull();
        onShowSessionView("chat");
    };

    return (
        <StageOverlayToolbar
            onLeaveSession={onLeaveSession}
            onShowChat={chatId ? showChat : null}
            notifications={notifications}
        />
    );
}

const stopPropagation = (cb) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    cb();
};

const blockEvents = stopPropagation(() => null);

function StageOverlayToolbar(props) {
    const {
        onLeaveSession,
        onShowChat,
        notifications,
    } = props;

    return (
        <div className={"stage-overlay-toolbar cols gap-sm cols-spread grow"}>
            <div className={"cols gap-sm"} onClick={blockEvents}>
                <AudioVideo />
                <EndCallButton onClick={stopPropagation(onLeaveSession)} />
            </div>
            <div className={"cols gap-sm"}>
                {onShowChat && (
                    <Badge
                        badgeContent={notifications || 0}
                        color={"secondary"}
                        variant={"dot"}
                        overlap={"circular"}
                    >
                        <IconButton
                            onClick={onShowChat}
                            tooltip={"Chat"}
                            icon={"mui-chat"}
                        />
                    </Badge>
                )}
            </div>
        </div>
    );
}

StageOverlayToolbar.propTypes = {};
StageOverlayToolbar.defaultProps = {};
