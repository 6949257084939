import React, { useLayoutEffect } from "react";
import cn from "classnames";

import { useUiFlag } from "state/hooks";
import { FLAG_SESSION_FLOATING } from "joynt/config";
import { bindVideoToStage } from "joynt/meeting/MeetingOverlay";
import useSizeObserver from "util/hooks/useSizeObserver";

//import PropTypes from 'prop-types';

export const StageElement = React.forwardRef((props, ref) => {
    const { children, className, id } = props;
    const { current: e } = ref || {};
    const { search } = window.location;
    const [, setFloating] = useUiFlag(FLAG_SESSION_FLOATING);

    useLayoutEffect(() => {
        const status = bindVideoToStage();
        setFloating(!status);
    }, [search, e, id, setFloating]);

    useSizeObserver({
        callback: bindVideoToStage,
        element: ref,
    });

    bindVideoToStage();

    return (
        <div className={cn(className)} ref={ref}>
            {children}
        </div>
    );
});

StageElement.propTypes = {};
StageElement.defaultProps = {};

export default StageElement;
