import React from 'react';
import PropTypes from 'prop-types';
import useFetch from "state/hooks/useFetch";
import {useNodeData} from "joynt/hooks";
import viewComponents from "joynt/components/UtilityNav/Session/views";

export function ViewContainer(props) {

    const {id, type} = props;
    const url = `v2/joynt/nodes/${id}`;
    const data = useNodeData(id);
    const exists = !!data.id;

    /** Some views do their own node request **/
    const noFetch = ["chat", "rooms"].indexOf(type) > -1;

    const pending = useFetch({
        type: "db.nodes",
        url,
        enable: !exists && !noFetch
    })

    const ViewComponent = viewComponents[type] || null;
    const isPending = pending && !exists && !noFetch;

    if (isPending) return null;

    return (<>
        {ViewComponent && <ViewComponent
            id={id}
        />}
    </>);

}

ViewContainer.propTypes = {
    id: PropTypes.string
};
ViewContainer.defaultProps = {};

export default ViewContainer;