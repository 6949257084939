import React from "react";
import cn from "classnames";
import { useChatTicker } from "joynt/components/ChatTicker";
import Grow from "@material-ui/core/Grow";
import SlideTransition from "joynt/components/LiveNow/SlideTransition";
import { Message } from "joynt/components/ChatTicker";
//import PropTypes from 'prop-types';

export function ChatNotification(props) {
    const { id, onClick } = props;
    const { message, show } = useChatTicker(id);

    return (
        <div className={cn("session-window-chat", {
            "has-notifications": show
        })} onClick={onClick}>
            <Grow in={show}>
                <div className={"session-window-chat__box"}>
                    {message && (
                        <SlideTransition
                            id={id}
                            current={message}
                            Component={Message}
                        />
                    )}
                </div>
            </Grow>
        </div>
    );
}

ChatNotification.propTypes = {};
ChatNotification.defaultProps = {};

export default ChatNotification;
