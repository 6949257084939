import {useContext} from 'react';
import {MediaRenderContext} from "./MediaRenderContext";
//import PropTypes from 'prop-types';

export function useMedia(props) {

    return useContext(MediaRenderContext) || {};

}

useMedia.propTypes = {};
useMedia.defaultProps = {};
