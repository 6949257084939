import React from 'react';
// import PropTypes from 'prop-types';

import "./style.css";

export function Breadcrumbs(props) {

    const {children} = props;

    return (<div className={"header-nav-breadcrumbs"}>
        {children}
    </div>);

}

Breadcrumbs.propTypes = {};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;