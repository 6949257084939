import React from "react";
import Layout from "joynt/meeting/settings/Layout";
import Button from "components/Button";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export default function AllowAccessInfo(props) {
    const { onRetry, onDeny } = props;

    return (
        <Layout
            title={"Camera and mic permissions"}
            description={
                "Please allow mic and camera permissions so that you will fully experience the meeting."
            }
        >
            <div>
                <div className={"allow-media"} />
            </div>
            <div className={"cols cols-center gap-xs text-center"}>
                If you can't see the popup please make sure that the devices are
                connected.
            </div>
            <div className={"cols cols-center"}>
                <Button
                    onClick={onRetry}
                    startIcon={<Icon>mui-refresh</Icon>}
                    color={"default"}
                >
                    Check again
                </Button>
            </div>
            <div className={"cols cols-center"}>
                <div onClick={onDeny} className={"link"}>
                    Join without audio/video
                </div>
            </div>
        </Layout>
    );
}

AllowAccessInfo.propTypes = {};
AllowAccessInfo.defaultProps = {};
