import React from 'react';
import PropTypes from 'prop-types';

import {IdentityAvatarContainer as IdentityAvatar} from "joynt/components/Avatar";

import "./style.css";

export function Avatar(props) {

    const {
        id,
        onClick,
        onMouseEnter,
        onMouseLeave,
        isOnline,
        isAway
    } = props;

    return (
        <div
            className={"utility-nav-avatar"}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <IdentityAvatar
                id={id}
                isOnline={isOnline}
                isAway={isAway}
            />
        </div>
    );

}

Avatar.propTypes = {
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};
Avatar.defaultProps = {};

export default Avatar;
