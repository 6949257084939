export const DEFAULT_HQ_PROFILE = "480p_9"; //"360p_1";
export const DEFAULT_LQ_PRESET = "120p_1";
export const QUALITY_OPTIMIZE_ABOVE = 5;
export const AGORA_AREAS = ["EUROPE"];
export const AGORA_EXTENDED_CLIENTS = 0;
export const AGORA_LOG_LEVEL = 0;
export const MAX_PUBLISHED_STREAMS = 50;
export const MAX_TILES_GRID = 12;
export const MAX_TILES_FOCUSED = 6;
export const MAX_TILES_MINIMIZED = 1;
export const MAX_VISIBLE_STREAMS = 12;
export const SHOW_AUDIO_STREAMS = false;
export const TRACK_VOLUME_SPEAKING_THRESHOLD = 0.3;
export const TRACK_VOLUME_SPEAKING_TIMEOUT = 300;
export const ACTIVE_SPEAKER_THRESHOLD = 7;
export const TOOLBAR_MOUSE_TIMEOUT = 3000;
export const CLIENT_LOGS = true;
export const SESSION_LAYOUT = "meeting";
export const SESSION_LAYOUT_MINIMIZED = "meeting";
