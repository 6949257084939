import { useEffect, useMemo } from "react";

import { setScreenSize } from "state/actions/ui";
import { useActions } from "state/hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
    TODO: check these values with design system,
    identify all components relying on this, make queries consistent
**/

const MOBILE_WIDTH = 700;
const SMALL_TABLET_WIDTH = 900;
const TABLET_WIDTH = 1200;
const SMALL_DESKTOP_WIDTH = 1440;

function captureScreenSize(breakpoints = {}) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return setScreenSize({
        width,
        height,
        ...breakpoints,
    });
}

function useBreakpoints() {
    const isTouchscreen = useMediaQuery(`(pointer: coarse)`);
    const isVeryWide = useMediaQuery(`(min-aspect-ratio: 18/10)`); //used for phones in landscape orientation
    const isHorizontalPhone = isVeryWide && isTouchscreen;

    const isMobile =
        useMediaQuery(`(max-width: ${MOBILE_WIDTH}px)`) || isHorizontalPhone;
    const isDesktop = useMediaQuery(`(min-width: ${TABLET_WIDTH}px)`);
    const isLargeDesktop = useMediaQuery(
        `(min-width: ${SMALL_DESKTOP_WIDTH}px)`
    );
    const isTablet =
        useMediaQuery(
            `(min-width: ${MOBILE_WIDTH}px) and (max-width: ${TABLET_WIDTH}px)`
        ) && !isHorizontalPhone;
    const isSmallTablet =
        useMediaQuery(
            `(min-width: ${MOBILE_WIDTH}px) and (max-width: ${SMALL_TABLET_WIDTH}px)`
        ) && !isHorizontalPhone;

    return useMemo(
        () => ({
            isMobile,
            isDesktop,
            isLargeDesktop,
            isTablet,
            isSmallTablet,
            isTouchscreen,
            isHorizontalPhone,
        }),
        [
            isMobile,
            isDesktop,
            isLargeDesktop,
            isTablet,
            isSmallTablet,
            isTouchscreen,
            isHorizontalPhone,
        ]
    );
}

const useScreenQuery = () => {
    const { setSize } = useActions({
        setSize: captureScreenSize,
    });

    useEffect(() => {
        // Add event listener
        window.addEventListener("resize", setSize); //FIXME: should we use debounce here? debounce(handleResize, 300)

        // Call handler right away so state gets updated with initial window size
        setSize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", setSize);
    }, [setSize]); // Empty array ensures that effect is only run on mount

    const breakpoints = useBreakpoints();

    useEffect(() => {
        setSize(breakpoints);
    }, [breakpoints, setSize]);
};

export default useScreenQuery;
