import {
    selectAliasedEntity,
    selectEntity,
    selectFilteredItems,
} from "state/selectors/data";

export function selectLayouts(store, type, id) {
    let page = selectEntity(store, type, id);
    let layouts = [];

    if (!page.layout) {
        let defaultLayout = selectDefaultPageLayout(store, type, id);
        layouts.push(defaultLayout);
        page = selectEntity(store, "cms.layouts", defaultLayout);
    }

    function findParentLayouts(layouts, page) {
        if (!page.layout) return layouts;
        if (page.layout) layouts.push(page.layout);
        const layout = selectEntity(store, "cms.layouts", page.layout);
        return findParentLayouts(layouts, layout);
    }

    return findParentLayouts(layouts, page);
}

export function selectDefaultPageLayout(store, type, id) {
    if (type === "cms.layouts") return null;

    const page = selectEntity(store, type, id);

    let settings = selectAliasedEntity(
        store,
        "cms.settings",
        "@websiteSettings"
    );
    let typeLayoutsIds = settings.default_layouts || [];
    let matchingLayouts = selectFilteredItems(
        store,
        "cms.layout-ref",
        typeLayoutsIds,
        (layout) => {
            return layout.entry_type === page.type;
        }
    );
    if (matchingLayouts.length) return matchingLayouts[0].layout;
    if (page.type === "offers" && settings.default_layout_offers) {
        return settings.default_layout_offers;
    }
    return settings.default_layout;
}
