import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "components/Icon";
import { useNode } from "joynt/hooks";
import usePhoneNav from "joynt/components/AppLayout/hooks/usePhoneNav";

export default function BottomNav(props) {
    const { id } = props;
    const { name, icon, node_type } = useNode(id);
    const { value, onChange } = usePhoneNav();
    const isHome = node_type === "root";

    const change = useCallback((e, newValue) => onChange(newValue), [onChange]);

    return (
        <BottomNavigation value={value} onChange={change} showLabels>
            <BottomNavigationAction
                label={"Workspace"}
                value={null}
                icon={<Icon>{icon || "home"}</Icon>}
            />
            <BottomNavigationAction
                label="Inbox"
                value={"inbox"}
                icon={<Icon>mui-mail</Icon>}
            />
            {!isHome && (
                <BottomNavigationAction
                    label="Menu"
                    value={"people"}
                    icon={<Icon>mui-chrome_reader_mode</Icon>}
                />
            )}
        </BottomNavigation>
    );
}

BottomNav.propTypes = {};
BottomNav.defaultProps = {};
