import React from "react";
import useCloudRecording from "joynt/meeting/hooks/useCloudRecording";
import { useNodeData } from "joynt/hooks";
import SessionRecordingState from "joynt/meeting/SessionRecordingState";
import Button from "components/Button";
import Icon from "components/Icon";
import { usePending } from "state/hooks";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export default function CloudRecording(props) {
    const { id } = props;

    const { event_record_state } = useNodeData(id);
    //const [, session] = event_session_id ? event_session_id.split(":") : [];
    const pending = usePending(id);

    const isRecording = event_record_state === "recording";

    const { onStartRecording, onStopRecording, canRecord } = useCloudRecording(
        id
    );

    if (!canRecord) return <SessionRecordingState id={id} />;

    return (
        <div className={"rows"}>
            <Preloader noOverlay visible={pending} />
            <div className={"rows gap-xs"}>
                <SessionRecordingState id={id} />
                <div className={"cols cols-middle gap-sm"}>
                    {!isRecording ? (
                        <Button
                            className={"recording-button"}
                            onClick={onStartRecording}
                            color={"default"}
                            startIcon={<Icon>mui-fiber_manual_record</Icon>}
                        >
                            Record
                        </Button>
                    ) : (
                        <Button
                            className={
                                "recording-button recording-button--recording"
                            }
                            onClick={onStopRecording}
                            color={"default"}
                            startIcon={<Icon>mui-stop</Icon>}
                        >
                            Stop
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

CloudRecording.propTypes = {};
CloudRecording.defaultProps = {};
