import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import SidebarNav from "./SidebarNav";
import SidebarContent from "./SidebarContent";
import SidebarFooter from "./SidebarFooter";
import SidebarSection from "./SidebarSection";

import {useEdgesData, useNodeData} from "joynt/hooks";

import {
    useChannels,
    useNodeChannels,
    useRouteNodes,
} from "joynt/hooks/channels";

import Button from "components/Button";
import Icon from "components/Icon";
import {ROUTE_PAGE} from "joynt/config";
import HomeTutorial from "joynt/components/Sidebar/SidebarNav/HomeTutorial";
import {useRouteParam} from "state/hooks";
import SidebarGroups from "joynt/components/Sidebar/SidebarNav/SidebarGroups";
import Schedule from "joynt/components/Sidebar/SidebarNav/Widgets/Schedule";
import Subworkspaces from "joynt/components/Sidebar/SidebarNav/Widgets/Subworkspaces";
import Header from "joynt/components/Sidebar/SidebarNav/Widgets/Header";
import {useSelector} from "react-redux";
import {selectSidebarNav} from "joynt/components/Sidebar/SidebarNav/selectors/selectSidebarNav";
import Listings from "joynt/components/Sidebar/SidebarNav/Widgets/Listings";
import NodesGroup from "joynt/components/Sidebar/SidebarNav/Widgets/NodesGroup";
import Events from "joynt/components/Sidebar/SidebarNav/Widgets/Events";
import LiveSessions from "joynt/components/Sidebar/SidebarNav/Widgets/LiveSessions";
import Channels from "joynt/components/Sidebar/SidebarNav/Widgets/Channels";
import WebinarSeries from "joynt/components/Sidebar/SidebarNav/Widgets/WebinarSeries";

const types = {
    'sidebar-nav-header': Header,
    'schedule': Schedule,
    'workspaces': Subworkspaces,
    'listings': Listings,
    'projects': NodesGroup,
    'pinned': NodesGroup,
    'events': Events,
    'bookmarks': NodesGroup,
    'sessions': LiveSessions,
    'spaces': SidebarGroups,
    'channels': Channels,
    'upcoming': NodesGroup,
    'webinar-series': WebinarSeries
}

function SidebarWidget(props) {
    const {type, ...other} = props;
    const WidgetComponent = types[type];
    if (!WidgetComponent) return <SidebarSection {...props} name={type} />;
    return <WidgetComponent type={type} {...other} />;
}

export function SidebarNavContainer(props) {

    const {id, onClick, isAdmin} = props;
    const list = `db.nodes.${id}.channels`;

    const {node_type} = useNodeData(id);
    const {channel, subchannel} = useRouteNodes();
    const [,setPage] = useRouteParam(ROUTE_PAGE);
    const create = useCallback(() => setPage(`create-space.${id}`), [id, setPage]);
    const {onCreate} = useChannels(id, list);

    const selected = subchannel || channel || id;
    const {path} = useEdgesData(selected);

    const {views, view} = useNodeChannels(id, channel);
    const defaultView = views[0]?.id;
    const isHome = node_type === "root";

    const {sections} = useSelector(s => selectSidebarNav(s, id));

    const widgetProps = {
        id,
        view,
        onClick,
        selected,
        path,
        isAdmin,
        onCreate
    }

    return (<SidebarNav {...props}>
        {sections.header.map(s => <SidebarWidget
            key={s}
            type={s}
            viewId={defaultView}
            {...widgetProps}
        />)}
        <SidebarContent>
            {sections.content.map(s => <SidebarWidget
                key={s}
                type={s}
                {...widgetProps}
            />)}
            { isHome && <HomeTutorial /> }
        </SidebarContent>
        { isAdmin && !isHome && <SidebarFooter>
            <Button
                onClick={create}
                variant={"outlined"}
                startIcon={<Icon>mui-add</Icon>}
            >
                Create
            </Button>
        </SidebarFooter> }
    </SidebarNav>);

}

SidebarNavContainer.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    isAdmin: PropTypes.bool
};

SidebarNavContainer.defaultProps = {
    isAdmin: false
};

export default SidebarNavContainer;