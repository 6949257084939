import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import EditorJs from "react-editor-js";
import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import Embed from "@editorjs/embed";
import List from "@editorjs/list";
import { useField } from "containers/Field";
import AggregateRoot from "containers/AggregateRoot";

import "joynt/css/editorjs.css";
import RenderContent from "joynt/content";

const tools = {
    heading: Header,
    paragraph: Paragraph,
    embed: Embed,
    list: List,
};

export default function Page(props) {
    const { id, autoSave } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={!autoSave}>
            <PageRender {...props} />
        </AggregateRoot>
    );
}

Page.propTypes = {};
Page.defaultProps = {
    autoSave: false,
};

function PageRender(props) {
    const { id, edit, className } = props;

    const [content, setContent] = useField("content", "db.nodes", id);

    const change = useCallback(
        async (editor) => {
            const content = await editor.saver.save();
            setContent(content);
        },
        [setContent]
    );

    if (!edit)
        return (
            <div className={cn("page-content", className)}>
                <RenderContent content={content} />
            </div>
        );

    return (
        <div className={cn("page-content", className)}>
            <EditorJs
                key={id}
                holder={"editorjs"}
                tools={tools}
                onChange={change}
                placeholder={"Type something"}
                data={content}
            />
            <div id={"editorjs"} />
        </div>
    );
}
