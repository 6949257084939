import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import IconButton from "joynt/components/IconButton";

export function RoleItem(props) {
    const {
        email,
        action,
        name,
        avatar,
        role,
        readOnly,
        onClick,
        onRevert,
        onRemove,
        inherited,
        isPendingInvite,
        userRoles,
        children,
    } = props;

    return (
        <div
            className={cn("role-item", {
                [`role-item--${action}`]: !!action,
            })}
            onClick={onClick}
        >
            <div className={"role-item__header"}>
                <div className={"role-item__identity"}>
                    {avatar && (
                        <div className={"role-item__avatar"}>{avatar}</div>
                    )}
                    <div className={"role-item__body"}>
                        <div className={"role-item__name"}>{name || email}</div>
                        {userRoles}
                    </div>
                    {isPendingInvite && (
                        <div className={"role-item__status"}>
                            Pending invite
                        </div>
                    )}
                    {inherited && (
                        <div className={"role-item__status"}>Inherited</div>
                    )}
                </div>
                <div className={"role-item__actions"}>
                    {action && <>({action})</>}
                    {role && <div className={"role-item__role"}>{role}</div>}
                    {!readOnly && onRemove && !onRevert && (
                        <IconButton
                            icon={"mui-delete"}
                            onClick={onRemove}
                            size={"small"}
                        />
                    )}
                    {!readOnly && onRevert && (
                        <IconButton
                            icon={"mui-undo"}
                            onClick={onRevert}
                            size={"small"}
                        />
                    )}
                </div>
            </div>
            {children && <div className={"role-item__content"}>{children}</div>}
        </div>
    );
}

RoleItem.propTypes = {
    email: PropTypes.string,
    name: PropTypes.string,
    action: PropTypes.oneOf(["add", "remove", "change", null]),
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    inherited: PropTypes.bool,
    readOnly: PropTypes.bool,
};

RoleItem.defaultProps = {
    inherited: false,
    readOnly: false,
    userRoles: null,
};

export default RoleItem;
