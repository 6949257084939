import React from "react";

import PropTypes from "prop-types";
import IconButton from "components/IconButton";
import Field from "containers/Field";
import PageUrl from "./PageUrl";
import PageActiveStatus from "playground/cms/views/Pages/PageForm/PageActiveStatus";
import Button from "components/Button";
import Icon from "components/Icon";

const taggable = [
    "cms.entries.offers",
    "cms.entries.rooms",
    "cms.entries.posts",
    "cms.entries.reviews",
    "cms.entries.attractions",
    "cms.products",
    "cms.categories",
];
const linkable = [
    "cms.pages",
    "cms.entries.offers",
    "cms.entries.rooms",
    "cms.entries.posts",
    "cms.products",
    "cms.categories",
];
const titles = {
    "cms.pages": "title",
    "cms.layouts": "description",
    "cms.entries.offers": "title",
    default: "title",
};

const titleLabels = {
    "cms.entries.offers": "Nazwa oferty",
    "cms.entries.contacts": "Nazwa kontaktu",
    default: "Tytuł",
};

export default function PageFormHeader(props) {
    const { onOpenPreview, onClose, type, id } = props;
    const { children, ...other } = props;

    return (
        <div className={"page-form-header rows gap-sm"}>
            <PageActiveStatus {...other} />
            <div className={"cols cols-middle default-c500"}>
                <div className={"grow"}>
                    <Field
                        id={titles[type] || titles.default}
                        variant={"inline"}
                        className={"page-title"}
                        label={titleLabels[type] || titleLabels.default}
                        changeMode={"change"}
                    />
                    {linkable.includes(type) && <PageUrl {...other} />}
                </div>
                <div className={"cols cols-middle gap-md"}>
                    {onOpenPreview ? (
                        <>
                            <Button
                                onClick={() => onOpenPreview("designer")}
                                size={"small"}
                                variant={"outlined"}
                                color={"default"}
                                startIcon={<Icon>mui-format_shapes</Icon>}
                            >
                                Podgląd
                            </Button>
                        </>
                    ) : null}
                    {onClose ? (
                        <IconButton icon={"mui-close"} onClick={onClose} />
                    ) : null}
                    {/*<IconButton icon={'mui-more_vert'} />*/}
                </div>
            </div>
            {/*{taggable.includes(type) && (*/}
            {/*    <Field*/}
            {/*        key={`tags-${id}`}*/}
            {/*        id={"tags"}*/}
            {/*        fieldType={"select"}*/}
            {/*        source={"api:tags"}*/}
            {/*        label={"Tagi"}*/}
            {/*        nullable={true}*/}
            {/*        multiple={true}*/}
            {/*        taggable={true}*/}
            {/*        variant={"inline"}*/}
            {/*    />*/}
            {/*)}*/}
            {children}
        </div>
    );
}

PageFormHeader.propTypes = {
    onOpenPreview: PropTypes.func,
};
