import React from 'react';
import {connect} from 'react-redux';

import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import Popover from 'components/Popover';
import EntityContext from "containers/context/EntityContext";
import Field from 'containers/Field';
import {createStyle} from 'cms/state/actions/cms';
import Button from "components/Button";
import IconButton from "components/IconButton";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = bindApiActions({
    onSubmit: createStyle
});

function CreateStyleForm(props) {
    const { onSubmit, type } = props;

    return <div className={'pad-sm'}>
        <EntityContext.Provider value={'@app/create-style'}>
            <Field
                id={'name'}
                fieldType={'text'}
                variant={'dense'}
                label={'Style name'}
                defaultValue={type+'.'}
            />
            <Field
                id={'clone'}
                fieldType={'checkbox'}
                label={'Use all current styles'}
            />
            <Field
                id={'clone-defined'}
                fieldType={'checkbox'}
                label={'Use current defined style'}
            />
            <Field
                id={'assign'}
                fieldType={'checkbox'}
                label={'Assign to current element'}
            />
        </EntityContext.Provider>
        <Button onClick={onSubmit}>save</Button>
    </div>
}

class CreateStyle extends React.PureComponent {

    render() {
        return (<Popover
            content={({onClose})=><CreateStyleForm
                {...this.props}
                onClose={onClose}
            />}
            trigger={({onClick})=><IconButton
                onClick={onClick}
                icon={'mui-add'}
                size={'small'}
            />}
        />);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateStyle));