import React from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

const types = {
};

class CustomField extends React.PureComponent {

    render() {
        const data = this.props.data;
        const props = data.properties || {};
        const id = props.custom_view_id;
        const Component = types[id];

        if (Component) return <Component
            {...this.props}
            {...props}
            id={data.source}
        />;
        return (<div>CustomField</div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomField);