import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";

export default class Button extends React.PureComponent {
    render() {
        const {
            onClick,
            active,
            buttonColor,
            buttonClassName,
            ...other
        } = this.props;
        return (
            <MuiButton
                className={buttonClassName}
                variant={"contained"}
                color={buttonColor || "primary"}
                onClick={onClick}
                {...other}
            >
                {this.props.children}
            </MuiButton>
        );
    }
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
};
