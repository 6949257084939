import React from "react";
import Icon from "components/Icon";

import "./styles.css";

export default function HelperCard(props) {
    const { icon, title, onClick } = props;
    return (
        <div className={"helper-card"} onClick={onClick}>
            <Icon fontSize={"xl"}>{icon}</Icon>
            <div className={"helper-card__title"}>{title}</div>
        </div>
    );
}
