export const updateRoles = context => (id, publish) => ({
    type: 'JOINT.ROLES.UPDATE',
    payload: {id, publish},
    context
});

export const updateRolesCallback = context => (cb, ...args) => {
    const action = updateRoles(context)(...args);
    action.callback = cb;
    return action;
}

export const changeRole = (id, data, list) => ({
    type: 'JOINT.ROLES.CHANGE',
    payload: {id, data, list}
});

export const joinNode = context => (to, from) => ({
    type: 'JOINT.MEMBERS.JOIN',
    payload: {from, to},
    context
});

export const leaveNode = context => (node) => ({
    type: 'JOINT.MEMBERS.LEAVE',
    payload: {node},
    context
});