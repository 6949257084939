import React from 'react';
import {connect} from 'react-redux';
import {pushRouteParams} from "state/actions/router";
import Dialog from '@material-ui/core/Dialog';
import {selectRouteParam} from "state/selectors/router";

import ProductDetails from "playground/booking/ProductDetails";
import RoomDetails from "playground/booking/RoomDetails";

const mapStateToProps = (store, props) => {
    const show = selectRouteParam(store, 'show');
    const id = selectRouteParam(store, 'id');
    return {
        open: !!show,
        type: show,
        id
    }
};

const mapDispatchToProps = {
    pushQuery: pushRouteParams
};

class Dialogs extends React.PureComponent {

    close = () => {
        const { pushQuery } = this.props;
        pushQuery({show: null, id: null});
    };

    render() {
        const { open, type, id } = this.props;

        return (<Dialog open={open} onClose={this.close}>
            { type === 'cms.products' ? <ProductDetails type={type} id={id} /> : null }
            { type === 'cms.entries.rooms' ? <RoomDetails type={type} id={id} /> : null }
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dialogs);