const primaryColor = '#4800bb';
const secondaryColor = '#ff7bac';
const fontFamily = 'Roboto';

export default {
    palette: {
        type: 'light',
        primary: { main: primaryColor },
        secondary: { main: secondaryColor }
    },
    typography: {
        fontSize: 12,
        fontFamily: fontFamily
    },
    overrides: {
        MuiIconButton: {
            root: {
                padding: '8px'
            },
            label: {
                fontSize: '24px',
            }
        },
        MuiIcon: {
            root: {
                fontSize: '24px'
            }
        },
        MuiTextField: {
            root: {
                color: 'red'
            }
        }
    }
}