import React, {useCallback} from 'react';
import Button from "components/Button";
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
//import PropTypes from 'prop-types';

export default function WorkspaceWelcome(props) {

    const {onClose} = props;
    const [,setPage] = useRouteParam(ROUTE_PAGE);
    const invite = useCallback(() => setPage('invite-admins'), [setPage]);

    return (<div className={'rows rows-center gap-lg pad-lg-h'}>
        <div className={"rows gap-md type-lg text-center"}>
            <p><strong>Your workspace has been created</strong></p>
            <p>Your workspace is now in "Under construction" mode.<br />You can set it up and publish it when you'll be ready.</p>
            <p><strong>Invite admins and collaborators</strong></p>
            <p>Do you want to invite admins and collaborators now?<br />You can always do it later.</p>
        </div>
        <div className={'cols gap-sm cols-center'}>
            <Button size={"large"} onClick={onClose} color={"default"}>Later</Button>
            <Button size={"large"} onClick={invite}>Add now</Button>
        </div>
    </div>);

}

WorkspaceWelcome.propTypes = {};
WorkspaceWelcome.defaultProps = {};
