import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {
    useFeatureEnabled,
    useNode,
    useNodeData,
    useThread,
} from "joynt/hooks";
import Fetch from "containers/Fetch";
import { MessageForm, MobileMessageForm } from "joynt/components/messages";
import { useMessaging } from "joynt/hooks";
import IconButton from "joynt/components/IconButton";
import Icon from "components/Icon";
import AggregateRoot from "containers/AggregateRoot";
import { useApiActions, usePending, useRouteParam } from "state/hooks";
import { handleUploadCallback } from "state/actions/upload";
import Upload from "components/upload/Upload";
import KeepIcon from "joynt/components/KeepIcon";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import PostIdentity from "joynt/post/PostIdentity";
import { ROUTE_NODE_EDIT } from "joynt/config";
import {
    MessagesList,
    MessagesSkeleton,
    MessagesEmptyState,
} from "./MessagesList";
import ImpressionMonitor from "joynt/components/ImpressionMonitor";
import { FEATURE_OPTIMIZED_API } from "joynt/config/features";
import useScreenSize from "util/hooks/useScreenSize";
import SettingsIconButton from "joynt/components/SettingsIcon";
import { useRegisterChat } from "joynt/components/ScreenVisibility";

import "./style.css";

function ChatAccess(props) {
    const { id } = props;
    const { public: isPublic, published, can_edit } = useNode(id);
    const onEdit = useRouteParam(ROUTE_NODE_EDIT)[1];
    const { identities } = useThread(id);

    if (published) return null;
    if (isPublic)
        return (
            <div
                className={"chat-header__lock"}
                onClick={can_edit ? () => onEdit(id) : null}
            >
                <Icon size={"small"}>mui-lock</Icon>
            </div>
        );

    return <PostIdentity identities={identities} />;
}

export default function Chat(props) {
    const {
        id,
        node,
        debug,
        onClose,
        showHeader,
        pending,
        className,
        variant,
        enableSessions,
    } = props;

    const {
        thread,
        post,
        parent,
        genericName,
        threadType,
        description,
        parent_node,
    } = useThread(id);

    const nodeId = node || id;
    const isPending = usePending(nodeId) || pending;

    useRegisterChat(id);

    const { isMobile } = useScreenSize();
    const { can_edit } = useNodeData(id);

    const { onCreateMessage, identity } = useMessaging({
        thread,
        post,
        parent: id,
    });

    const { onJoinSession } = useMeeting();

    const { onUpload } = useApiActions({
        onUpload: handleUploadCallback,
    });

    const upload = useCallback(
        (files) => {
            const cb = (file) => onCreateMessage({ media: [file] });
            files.forEach((file) => onUpload(cb, "db.media", null, file));
        },
        [onCreateMessage, onUpload]
    );

    const isChat = threadType === "private-chat";

    const isDev = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const url = isDev ? `v2/joynt/nodes/${nodeId}` : `joynt/nodes/${nodeId}`;

    const parentName = parent_node?.name;
    const visibleDescription = description || parentName;

    const chatExists = !!thread;
    const canEdit = chatExists && can_edit && !isChat;

    return (
        <Fetch type={"db.nodes"} id={nodeId} enable={!thread} url={url}>
            <ImpressionMonitor
                key={id}
                id={id}
                on={thread && !isPending}
                className={cn("chat", className)}
            >
                <Upload
                    onFileUpload={upload}
                    className={"rows grow overflow-hide@desktop"}
                >
                    <div
                        className={
                            "chat-content rows grow overflow-hide@desktop"
                        }
                    >
                        {showHeader && (
                            <div className={"row pad-4"}>
                                <div
                                    className={
                                        "chat-header cols cols-spread cols-middle"
                                    }
                                >
                                    <div className={"chat-header__details"}>
                                        <AggregateRoot
                                            type={"db.nodes"}
                                            id={id}
                                        >
                                            <ChatAccess id={id} />
                                            <div className={"chat-title"}>
                                                <div className={"chat-name"}>
                                                    {genericName || "Thread"}
                                                </div>
                                                {visibleDescription && (
                                                    <div
                                                        className={
                                                            "private-chat__source"
                                                        }
                                                    >
                                                        {visibleDescription}
                                                    </div>
                                                )}
                                            </div>
                                        </AggregateRoot>
                                    </div>
                                    <div className={"cols"}>
                                        {enableSessions && onJoinSession && (
                                            <IconButton
                                                icon={"mui-videocam"}
                                                onClick={() =>
                                                    onJoinSession(id, false)
                                                }
                                            />
                                        )}
                                        {chatExists && <KeepIcon id={id} />}
                                        {canEdit && (
                                            <SettingsIconButton id={id} />
                                        )}
                                        {onClose && (
                                            <IconButton
                                                icon={"mui-close"}
                                                onClick={onClose}
                                            />
                                        )}
                                    </div>
                                </div>

                                {/*{description && (*/}
                                {/*    <div*/}
                                {/*        className={*/}
                                {/*            "row chat-header__description"*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        {description}*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </div>
                        )}
                        {thread ? (
                            <MessagesList
                                key={id}
                                fallbackIdentity={identity}
                                className={cn(
                                    "grow message-list",
                                    `variant-${variant}`
                                )}
                                id={thread}
                                mode={variant || (isChat ? "chat" : "channel")}
                            />
                        ) : (
                            <div className={"grow pad-sm"}>
                                {isPending ? (
                                    <MessagesSkeleton />
                                ) : (
                                    <MessagesEmptyState />
                                )}
                            </div>
                        )}
                        <div className={"rows gap-sm message-form-area"}>
                            {isMobile ? (
                                <MobileMessageForm
                                    submitMode={"button"}
                                    onCreateMessage={onCreateMessage}
                                    onUpload={upload}
                                    parent={id}
                                />
                            ) : (
                                <MessageForm
                                    submitMode={"enter"}
                                    onCreateMessage={onCreateMessage}
                                    onUpload={upload}
                                    parent={id}
                                />
                            )}

                            {debug ? (
                                <div className={"cols gap-sm type-sm o-50"}>
                                    <div>thread: {thread}</div>
                                    <div>post: {post}</div>
                                    <div>node: {id}</div>
                                    <div>parent: {parent}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Upload>
            </ImpressionMonitor>
        </Fetch>
    );
}

Chat.defaultProps = {
    debug: false,
    showHeader: true,
    enableSessions: false,
};

Chat.propTypes = {
    variant: PropTypes.oneOf(["channel", "chat", "popup", "sidebar"]),
};
