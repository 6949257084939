import React from "react";
import PropTypes from "prop-types";
import SessionConfirmActions from "./SessionConfirmActions";
import { useParticipation } from "joynt/hooks/sessions";

export function SessionConfirmActionsContainer(props) {
    const { id } = props;
    const actionsProps = useParticipation(id);

    return <SessionConfirmActions
        {...props}
        {...actionsProps}
    />;
}

SessionConfirmActionsContainer.propTypes = {
    id: PropTypes.string,
};
SessionConfirmActionsContainer.defaultProps = {};

export default SessionConfirmActionsContainer;
