import React, {useMemo} from 'react';
import {MediaRenderContext} from "containers/media/MediaRenderContext";
//import PropTypes from 'prop-types';

export function Provider(props) {

    const {children, Render, thumb} = props;

    const context = useMemo(() => ({
        Render,
        thumb
    }), [Render, thumb]);

    return (<MediaRenderContext.Provider value={context}>
        {children || null}
    </MediaRenderContext.Provider>);

}

Provider.propTypes = {};
Provider.defaultProps = {};
