import React from 'react';
import {connect} from 'react-redux';
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";
import PageForm from "cms/ui/forms/PageForm";

const mapStateToProps = (store, props) => {
    const type = selectRouteParam(store, 'resource', 'cms.pages');
    const id = selectRouteParam(store, 'id');
    return {
        type,
        id,
        entityPath: [type, id].join('/')
    }
};

const mapDispatchToProps = {
    pushRouteParams: pushRouteParams
};

class SectionsListView extends React.PureComponent {

    click = (id) => {
        const { pushRouteParams } = this.props;
        pushRouteParams({focus:'cms.sections/'+id, list: 'tree', section: id});
    };

    submit = () => {

    };

    create = () => {
        const { pushRouteParams } = this.props;
        pushRouteParams({create: 'cms.sections'});
    };

    render() {
        const { type, id } = this.props;
        return <PageForm
            type={type}
            id={id}
            onEditSection={this.click}
            onFabClick={this.create}
            preview={'page'}
        />;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionsListView);