import React from "react";

import NodeAccess from "joynt/components/Roles/NodeAccess";
//import PropTypes from 'prop-types';

export function SessionAccess(props) {
    const { id } = props;

    return <NodeAccess id={id} />;
}

SessionAccess.propTypes = {};
SessionAccess.defaultProps = {};

export default SessionAccess;
