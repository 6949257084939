import React from "react";
import RenderShape from "plan-editor/RenderShape";

function mouseEventToPoint(e) {
    const img = document.getElementById("img");
    const pageX = e.pageX;
    const pageY = e.pageY;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = img.getBoundingClientRect();
    const imgPositionLeft = elemRect.left - bodyRect.left;
    const imgPositionTop = elemRect.top - bodyRect.top;
    const imgPointX = pageX - imgPositionLeft;
    const imgPointY = pageY - imgPositionTop;

    return {
        x: imgPointX,
        y: imgPointY,
    };
}

function scale(point, scale) {
    return {
        x: point.x / scale,
        y: point.y / scale,
    };
}

export default class Editor extends React.PureComponent {
    defaultCursor = "crosshair";

    constructor(props) {
        super(props);
        this.state = {
            cursor: this.defaultCursor,
            cursorXY: [],
            panMode: false,
            panOffset: { x: 0, y: 0 },
            panStart: null,
            drawMode: false,
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown, false);
        document.addEventListener("keyup", this.handleKeyUp, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
        document.removeEventListener("keyup", this.handleKeyUp, false);
    }

    handleOnClickImg = (e) => {
        console.log("handleOnClickImg");
        if (e.altKey) {
            const point = scale(mouseEventToPoint(e), this.props.scale);

            this.props.setImgPoints(point.x, point.y);
        }
    };

    handleOnLoadImg = () => {
        const img = document.getElementById("img");
        const imgWidth = img.width;
        const imgHeight = img.height;
        this.props.setImgWidthAndHeight(imgWidth, imgHeight);
    };

    handleMouseUp = (e) => {
        e.preventDefault();
        let stateUpdate = {};
        stateUpdate.panMode = false;
        stateUpdate.panStart = null;
        this.setState(stateUpdate);
    };

    handleMouseDown = (e) => {
        e.preventDefault();
        if (this.state.panMode) {
            this.setState({
                panStart: mouseEventToPoint(e),
            });
        }
    };

    handleKeyDown = (e) => {
        if (e.code === "Space" && !this.state.panMode) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ panMode: true });
            return;
        }
        if (e.key === "Alt") {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ drawMode: true });
        }
    };

    handleKeyUp = (e) => {
        if (e.code === "Space" && this.state.panMode) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({ panMode: false, drawMode: false });
    };

    handleMouseLeave = () => {
        this.setState({
            panMode: false,
            drawMode: false,
        });
    };

    handleMouseMove = (e) => {
        const point = mouseEventToPoint(e);
        const { panMode, panStart, panOffset, drawMode } = this.state;
        if (panMode && panStart) {
            e.preventDefault();
            e.stopPropagation();
            const offset = {
                x: point.x + panOffset.x - panStart.x,
                y: point.y + panOffset.y - panStart.y,
            };
            this.setState({ panOffset: offset });
            console.log(point, offset);
            return;
        }

        if (drawMode) {
            this.setState({ cursorXY: [point.x, point.y] });
        }
    };

    handleSetActivePoint = (index, status) => {
        this.setState({
            ...this.state,
            activePoint: status ? index : null,
        });
    };

    handleWheel = (e) => {
        const { scale, onSetScale } = this.props;
        if (e.deltaY > 0) {
            onSetScale(scale - 0.1);
        } else {
            onSetScale(scale + 0.1);
        }
    };

    render() {
        const { panOffset, drawMode, panMode } = this.state;

        const {
            shapes,
            showLabels,
            showIndex,
            resourceType,
            selected,
            scale,
            onDeletePoint,
            onCopyPoint,
            onSelectShape,
        } = this.props;

        const lineY = {
            position: "absolute",
            left: this.state.cursorXY[0] + "px",
            borderRight: "1px dashed",
            pointerEvents: "none",
            height: "300%",
            zIndex: "1",
        };
        const lineX = {
            position: "absolute",
            top: this.state.cursorXY[1] + "px",
            borderBottom: "1px dashed",
            pointerEvents: "none",
            width: "200%",
            zIndex: "1",
        };
        const canvasStyle = {
            position: "relative",
            overflow: "hidden",
            flexGrow: 1,
        };

        const imageStyle = {
            transform: `scale(${scale})`,
            transformOrigin: "0 0",
            transition: "transform .05 s",
            boxShadow: "0 0 15px 10px rgba(0,0,0,0.2)",
        };

        if (this.state.drawMode) imageStyle.cursor = "crosshair";
        if (this.state.panMode) imageStyle.cursor = "move";

        const panWrapperStyle = {
            position: "absolute",
            transform: `translateX(${panOffset.x}px) translateY(${panOffset.y}px)`,
        };
        const elementPanStyle = {
            width: window.innerWidth - 280,
            position: "relative",
        };

        const lines =
            drawMode && this.state.cursorXY.length > 0 ? (
                <div>
                    <div style={lineY} />
                    <div style={lineX} />
                </div>
            ) : null;

        const shapeIds = Object.keys(shapes);

        const points = !panMode
            ? shapeIds.map((shapeId) => {
                  let isSelected = selected === shapeId;
                  return (
                      <RenderShape
                          key={shapeId}
                          id={shapeId}
                          type={resourceType}
                          isSelected={isSelected}
                          showLabels={showLabels}
                          showIndex={isSelected && showIndex}
                          shape={shapes[shapeId]}
                          scale={scale}
                          onDeletePoint={onDeletePoint}
                          onCopyPoint={onCopyPoint}
                          onSelectPoint={this.handleSetActivePoint}
                          onSelectShape={!drawMode ? onSelectShape : null}
                      />
                  );
              })
            : null;

        const shapesFill = [];
        const labels = [];

        /*const points =
            shapeIds.map((shapeId) => {
                let isSelected = (this.props.selected === shapeId);
                let shapeData = shapes[shapeId];
                let shapePoints = parsePoints(shapeData.points);
                return shapePoints.map((point, index) => {
                    let isPointSelected = (isSelected && index === this.state.activePoint);
                    return (<Point key={index} point={point}
                       selected={isSelected}
                       pointSelected={isPointSelected}
                       deletePoint={()=>this.props.deletePoint(index)}
                       copyPoint={this.props.copyPoint}
                       showIndex={isSelected && showIndex}
                       index={index}
                       scale={this.props.scale}
                       onMouseDown={this.handleSetActivePoint.bind(this, index, true)}
                       onMouseUp={this.handleSetActivePoint.bind(this, index, false)}
                    />);
                });
            });

        const shapesFill = shapeIds.length > 0 ?
            shapeIds.map((shapeId) => {
                let isSelected = shapeId === this.props.selected;
                let shapeData = shapes[shapeId];
                let shapePoints = parsePoints(shapeData.points);
                return (<Shape
                    scale={this.props.scale}
                    points={shapePoints}
                    isActive={isSelected}
                    edge={(isSelected && !this.state.activePoint) ? this.state.edge: null}
                />);
            }) : null;

        const labels = shapeIds.length > 0 ?
            shapeIds.map((shapeId, i) => {
                let shapeData = shapes[shapeId];
                let shapePoints = parsePoints(shapeData.points);
                return shapePoints.length > 3 ?
                    <ApartmentName
                        key={i}
                        label={shapeId}
                        type={resourceType}
                        id={shapeId}
                        isActive={showLabels}
                        scale={this.props.scale}
                        points={shapePoints}
                    /> : null;
            })
            : null;*/

        const img =
            this.props.filePreview !== "" ? (
                <img
                    alt="img"
                    id="img"
                    onLoad={this.handleOnLoadImg}
                    onClick={this.handleOnClickImg}
                    src={this.props.filePreview}
                    style={imageStyle}
                    onMouseUp={this.handleMouseUp}
                    onMouseDown={this.handleMouseDown}
                    onMouseLeave={this.handleMouseLeave}
                    onMouseMove={this.handleMouseMove}
                />
            ) : null;

        return (
            <div style={canvasStyle} onWheel={this.handleWheel}>
                <div style={elementPanStyle}>
                    <div style={panWrapperStyle}>
                        <div id="points">{points}</div>
                        {shapesFill}
                        {labels}
                        {lines}
                        {img}
                    </div>
                </div>
            </div>
        );
    }
}
