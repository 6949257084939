import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Nav(props) {
    return (
        <>
            {props.children}
            <Field
                id={"navigation"}
                label={"Menu"}
                fieldType={"select"}
                source={"api:navigation"}
            />
            <Field id={"menu_level"} label={"Menu level"} />
            <Field
                id={"nested"}
                label={"Pełne menu dwupoziomowe"}
                fieldType={"checkbox"}
            />
        </>
    );
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
