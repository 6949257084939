import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { useActions, useData } from "state/hooks";
import { change } from "state/actions/data";
import ComponentModeSelect from "playground/designer/ComponentEditor/ComponentModeSelect";
import TagsField from "playground/cms/fields/TagsField";

function detectMode(data) {
    if (data?.ui_mode) return data?.ui_mode;

    const mapStr = data?.template_id || "";

    if (data?.entry_type) {
        return "own-query";
    }
    if (["@section-links-visible"].includes(mapStr)) {
        return "section-links-visible";
    }
    if (["@section-items-visible"].includes(mapStr)) {
        return "section-items-visible";
    }
    if (
        ["@section-items", "gallery:items"].includes(mapStr) ||
        mapStr.indexOf("gallery") > -1
    ) {
        return "section-items";
    }
    if (
        ["@section-links", "links:items"].includes(mapStr) ||
        mapStr.indexOf("links") > -1
    ) {
        return "section-links";
    }
    if (data.template_id === "@query-list") {
        return "query-result";
    }
    return "own-items";
}

function changeMode(mode, data) {
    let next = { ...data };
    console.log(next, mode);
    switch (mode) {
        case "own-items":
            next.template_id = null;
            break;
        case "section-items":
            next.template_id = "@section-items";
            break;
        case "section-links":
            next.template_id = "@section-links";
            break;
        case "section-items-visible":
            next.template_id = "@section-items-visible";
            break;
        case "section-links-visible":
            next.template_id = "@section-links-visible";
            break;
        case "query-result":
            next.template_id = "@query-list";
            break;
        case "own-query":
            next.template_id = null;
            if (!next.entry_type) {
                next.entry_type = "offers";
            }
            break;
        default:
            break;
    }
    //next.ui_mode = mode;
    return next;
}

const listModes = {
    "own-items": "Własne elementy",
    "section-items": "Elementy sekcji",
    "section-items-visible": "Elementy sekcji +visible",
    "section-links": "Odnośniki sekcji",
    "section-links-visible": "Odnośniki sekcji +visible",
    "query-result": "Wynik zapytania",
    "own-query": "Własne zapytanie",
};

function ListQuery(props) {
    const { type, id, queryEntityType, queryListId } = props;

    const data = useData(props);
    const { entry_type } = data;

    return (
        <>
            <Field
                id={"entry_type"}
                label={"Entry Type"}
                fieldType={"select"}
                source={"schema:entries.types"}
                nullable={true}
            />
            {/*<Field*/}
            {/*    id={"tags"}*/}
            {/*    label={"Tags"}*/}
            {/*    fieldType={"select"}*/}
            {/*    source={"api:tags"}*/}
            {/*    multiple={true}*/}
            {/*    taggable={true}*/}
            {/*    nullable={true}*/}
            {/*/>*/}

            <TagsField
                id={"tags"}
                label={"Tagi"}
                entityType={queryEntityType}
                listId={queryListId}
            />
            <Field
                id={"category"}
                label={"Category"}
                fieldType={"select"}
                source={"api:categories"}
                nullable={true}
            />
            <Field
                id={"filtering_mode"}
                label={"Filtering Mode"}
                fieldType={"select"}
                source={"schema:data.list-filter"}
            />
            <Field
                id={"order"}
                label={"Order"}
                fieldType={"select"}
                source={"schema:data.list-order"}
            />
            <Field id={"limit"} label={"Limit"} />
            <Field
                id={"paginate"}
                label={"Enable Pagination"}
                fieldType={"checkbox"}
            />
            <Field
                id={"data_source"}
                label={"Data Source"}
                fieldType={"select"}
                source={"schema:data.list-data-source"}
            />
            <Field
                id={"defined_order"}
                label={"Defined Order"}
                fieldType={"resource-collection"}
            />
            <Field
                id={"disable_filter_all"}
                label={"Disable Filter All"}
                fieldType={"checkbox"}
            />
            <Field
                id={"link_action"}
                label={"Link Action"}
                fieldType={"select"}
                source={"schema:data.buttonActions"}
                nullable={true}
            />
            <Field
                id={"target_page.resource_id"}
                label={"Target Page"}
                fieldType={"select"}
                source={"api:sitemap"}
                nullable={true}
            />
        </>
    );
}

export function List(props) {
    const { type, id, children } = props;
    const data = useData({ type, id });
    const mode = detectMode(data);
    const { onChangeEntity } = useActions({ onChangeEntity: change });
    const selectMode = (mode) => {
        onChangeEntity(type, id, changeMode(mode, data));
    };
    return (
        <>
            <ComponentModeSelect
                onChange={selectMode}
                value={mode}
                options={listModes}
            />
            {children}
            {/*<div>{JSON.stringify(data?.__parent)}</div>*/}
            {mode !== "own-query" && (
                <Field
                    id={"items"}
                    label={"Items"}
                    fieldType={"collection"}
                    zmapAs={["items", "collection"]}
                />
            )}
            {mode === "own-query" && <ListQuery {...props} />}
        </>
    );
}

List.propTypes = {};

List.defaultProps = {
    type: "cms.components",
};

export default List;
