import React from 'react';
import PropTypes from 'prop-types';

import SidebarItem from "../SidebarItem";
import {SpaceAvatar} from "joynt/components/SpaceAvatar";

export function Home(props) {

    const {name, icon, color} = props;

    return <SidebarItem
        {...props}
        label={name}
        icon={<SpaceAvatar
            size={"sm"}
            icon={icon}
            color={color}
        />}
        showArrow={false}
    />;

}

Home.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string
};
Home.defaultProps = {
    name: "Home",
    icon: "mui-home",
    color: "teal"
};

export default Home;
