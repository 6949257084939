import React from "react";
import PropTypes from "prop-types";
import DrawerNav from "./DrawerNav";
import DrawerNavGroup from "./DrawerNavGroup";
import DrawerNavButton from "./../DrawerNavButton";
import Button from "components/Button";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import { ParticipantList } from "joynt/participants";
import { NotificationButton } from "./../Button";
import Icon from "components/Icon";
import { useUserSettings } from "joynt/components/UserSettings";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import useScreenSize from "util/hooks/useScreenSize";

function AccountSettings(props) {
    const { id, onClick } = props;
    const { identity } = useUserSettings(id);

    return (
        <DrawerNavButton
            label={"Account settings"}
            onClick={onClick}
            avatar={identity ? <Avatar id={identity} /> : null}
        />
    );
}

export function DrawerNavContainer(props) {
    const { isMobile } = useScreenSize();

    const { workspace, id, isPage } = props;
    const [, setView] = useUtilityNav();

    return (
        <DrawerNav {...props}>
            <DrawerNavGroup>
                <AccountSettings id={id} onClick={() => setView("profile")} />
                {!isPage && (
                    <NotificationButton
                        group={"activity"}
                        Component={DrawerNavButton}
                        label={"Activity"}
                        icon={"mui-notifications"}
                        onClick={() => setView("activity")}
                    />
                )}
                {!isPage && (
                    <DrawerNavButton
                        label={"Calendar"}
                        onClick={() => setView("calendar")}
                        icon={"mui-today"}
                    />
                )}
                {!isMobile && (
                    <NotificationButton
                        group={"messages"}
                        Component={DrawerNavButton}
                        label={"Inbox"}
                        icon={"mui-inbox"}
                        onClick={() => setView("inbox")}
                    />
                )}
                <DrawerNavButton
                    icon={"mui-share"}
                    label={"Invite people"}
                    onClick={() => setView("invite")}
                    color={"link"}
                />
            </DrawerNavGroup>
            <DrawerNavGroup>
                <ParticipantList
                    parent={workspace}
                    id={id}
                    showOffline={false}
                />
                <Button
                    onClick={() => setView("people")}
                    variant={"text"}
                    endIcon={<Icon>mui-arrow_forward</Icon>}
                >
                    View all members
                </Button>
            </DrawerNavGroup>
        </DrawerNav>
    );
}

DrawerNavContainer.propTypes = {
    id: PropTypes.string,
};
DrawerNavContainer.defaultProps = {};

export default DrawerNavContainer;
