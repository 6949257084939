import React from "react";
//import PropTypes from 'prop-types';

import { useNodeData } from "joynt/hooks";
import CalendarEmblem from "joynt/components/Calendar/CalendarEmblem";
import SessionDescription from "./SessionDescription";

import "./style.css";

export function SessionSummary(props) {
    const { id, showAttendance } = props;

    const {
        event_start: start,
    } = useNodeData(id);

    return (
        <div className={"networking-session-info__description"}>
            <CalendarEmblem date={start * 1000} />
            <SessionDescription
                id={id}
                truncate={"modal"}
                showAttendance={showAttendance}
            />
        </div>
    );
}

SessionSummary.propTypes = {};
SessionSummary.defaultProps = {
    showAttendance: true
};

export default SessionSummary;
