import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { OverlayForm } from "playground/cms/Form";

export function ComponentBox(props) {
    const { children, settings } = props;

    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            <div
                className={"form-section variant-contained"}
                onClick={() => setShowSettings(true)}
            >
                {children}
            </div>
            {settings && (
                <OverlayForm
                    open={showSettings}
                    onClose={() => setShowSettings(false)}
                    position={"center"}
                    width={"sm"}
                    fullHeight={false}
                >
                    <div className={"pad-md rows"}>{settings}</div>
                </OverlayForm>
            )}
        </>
    );
}

ComponentBox.propTypes = {};

ComponentBox.defaultProps = {};

export default ComponentBox;
