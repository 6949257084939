import React from 'react';
import {connect} from 'react-redux';

import SectionBrowser from "cms/ui/SectionBrowser";
import {selectRouteParam} from "state/selectors/router";
import {selectEntity} from "state/selectors/data";
import {FILTER_NS} from "config/list-aliases";
import {appendQueryParams} from "util/uri";
import {selectLibraryId} from "cms/state/selectors/cms";

const mapStateToProps = (store, props) => {
    const service = selectRouteParam(store, 'service');
    const list = ['cms.sections', 'library'].join('.');
    const filter = selectEntity(store, FILTER_NS, list);
    const library = selectLibraryId(store);
    const fetchService = filter.library || service;
    const urlFilters = {...filter};
    delete urlFilters.library;
    const url = appendQueryParams(
        `v2/cms/sections/?service=${fetchService}`,
        urlFilters
    );
    const isLayout = selectRouteParam(store, 'resource') === 'cms.layouts';
    return {
        type: 'cms.sections',
        list,
        url,
        service,
        library,
        origin: fetchService,
        isLayout
    }
};

const mapDispatchToProps = {};

function CreateContainer(props)  {

    return (<SectionBrowser
        {...props}
        create={true}
    />);

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateContainer);