import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';

import {html} from "js-beautify";
import {renderToStaticMarkup} from "react-dom/server";

import { Provider } from 'react-redux';
import { ReactReduxContext } from 'react-redux';

import CodeEditor from 'components/fields/code/CodeEditor';

function renderToHtml(node) {
    try {
        return html(renderToStaticMarkup(node));
    } catch (error) {
        return null;
    }
}

const mapState = (store, props) => {
    return {
        store
    };
};

class ComponentPreview extends React.PureComponent {

    renderPreview = ({store}) => {
        const { children } = this.props;
        const component = React.Children.only(children);
        const asHtml = renderToHtml(<Provider store={store}>
            {component}
        </Provider>);
        return (<CodeEditor value={asHtml} rootId={'preview'} />);
    };

    render() {
        return <ReactReduxContext.Consumer>
            {this.renderPreview}
        </ReactReduxContext.Consumer>;

    }

}

ComponentPreview.propTypes = {};

export default connect(mapState)(ComponentPreview);