import React, {useCallback} from 'react';
import MediaBrowserContainer from "containers/media-browser/MediaBrowser";
import ListFormLayout from "components/layouts/ListFormLayout";
import SideNavContainer from "containers/user-menu/SideNav";
import UserMenu from "containers/user-menu/UserMenu";
import {useRouteParam} from "state/hooks";
import {useActions} from "state/hooks";
import {pushRouteParams} from "state/actions/router";
import ChangesSubmit from "cms/ui/containers/ChangesSubmit";
//import PropTypes from 'prop-types';

export default function MediaLibrary(props) {

    const [resource] = useRouteParam('resource');
    const {onPushParams} = useActions({
        onPushParams: pushRouteParams
    });
    const [navOpen, setNavOpen] = useRouteParam('nav');
    const toggleNav = useCallback(()=>setNavOpen(!navOpen), [navOpen, setNavOpen]);
    const onClick = useCallback(id => {
        onPushParams({resource:id, view: null});
    }, [onPushParams]);

    return <ChangesSubmit>
        <ListFormLayout
            sideNav={<SideNavContainer
                onClick={onClick}
                onShowUserMenu={toggleNav}
                showUserMenu={navOpen}
            />}
            userMenu={<UserMenu resource={resource} onClick={onClick} />}
            showUserMenu={navOpen}
            content={<div className={'grow rows overflow-hide'}>
                <MediaBrowserContainer />
            </div>}
        />
    </ChangesSubmit>;

}

MediaLibrary.propTypes = {};
MediaLibrary.defaultProps = {};
