import React from 'react';
import {connect} from 'react-redux';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Confirmation extends React.Component {

    render() {
        const { data } = this.props;

        return (<div>
            {JSON.stringify(data)}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Confirmation);