import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import RoomsForm from "./RoomsForm";

export function Products(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"room"} label={"Pokój"}>
                        <RoomsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
