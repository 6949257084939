import React, {useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import {selectList} from "state/selectors/data";
import DaysSelect from "./DaysSelect";
import Scale from "./Scale";
import Track from "./Track";
//import PropTypes from 'prop-types';

import 'joynt/css/timeline.css';

function groupEventsByDay(events) {
    const groups = {};
    if (!events) return groups;

    events.forEach(function(e) {
        if (!e.event_start) return;
        const day = moment.unix(e.event_start).startOf('day').format('X');
        if (!groups[day]) groups[day] = {id: day, day: day, events: []};
        groups[day].events.push(e.id);
    });
    return groups;
}

function detectCollision(events, matchEvent) {
    let mStart = matchEvent.event_start * 1;
    let mEnd = matchEvent.event_end * 1;
    for(let i = 0; i < events.length; i++) {
        let start = events[i].event_start * 1;
        let end = events[i].event_end * 1;
        if (mStart >= start && mStart < end) return true;
        if (start >= mStart && start < mEnd) return true;
    }
    return false;
}

function findTrack(tracks, event) {
    for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        if (track.length === 0) return i;
        if (!detectCollision(track, event)) return i;
    }
    return null;
}

function selectTracks(events) {
    let tracks = [[]];
    events.forEach(event => {
        let trackId = findTrack(tracks, event);
        if (trackId === null) {
            tracks.push([]);
            trackId = tracks.length - 1;
        }
        tracks[trackId].push(event);
    });
    return tracks.map(track => {
        return track.map(event=>event.id);
    });
}

function findClosestDay(result, day) {
    if (!result) return day;
    const now = Date.now() / 1000;
    if (now-day > result-day) return day;
    return result;
}

export default function Timeline(props) {

    const {id, onClick} = props;
    const list = props.list || `db.nodes.spaces.${id}`;
    const items = useSelector(store=>selectList(store, 'db.nodes', list));
    const days = groupEventsByDay(items) || [];
    const defaultDay = days[Object.keys(days).reduce(findClosestDay, null)] || {};
    const validDays = Object.values(days).map(d => d.day);
    const [day, setDay] = useState(defaultDay.day);
    const selectedDay = day && validDays.indexOf(day) > -1
        ? day
        : defaultDay.day;

    const dayEventsList = days[selectedDay] ? days[selectedDay].events : [];
    const dayEvents = items.filter(event => dayEventsList.indexOf(event.id) > -1);
    const tracks = selectTracks(dayEvents);
    const range = {min: 999999999999, max: 0};

    dayEvents.forEach(item => {
        if (item.event_start < range.min) range.min = item.event_start*1;
        if (item.event_end > range.max) range.max = item.event_end*1;
    });

    return (<div className={'rows gap-md'}>
        <div className={'cols cols-center gap-sm'}>
            <DaysSelect
                days={Object.values(days)}
                onClick={setDay}
                value={selectedDay}
            />
        </div>
        <div className={'rows'}>
            <div className={'timeline'}>
                <Scale
                    min={range.min}
                    max={range.max}
                />
                {tracks.map((trackIds, i) => {
                    return <Track
                        key={i}
                        items={trackIds}
                        min={range.min}
                        max={range.max}
                        onClick={onClick}
                    />
                })}
            </div>
        </div>
    </div>);

}

Timeline.propTypes = {};
Timeline.defaultProps = {};

Timeline.propTypes = {};
Timeline.defaultProps = {};
