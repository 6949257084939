import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SessionCard from "./SessionCard";
import SessionConfirmActions from "./../SessionParticipation/SessionConfirmActions";
import { useNodeData } from "joynt/hooks";
import RenderContent from "joynt/content";
import { SessionDate } from "joynt/components/TimeUtils";
import { useSessionParticipants } from "joynt/hooks/sessions";
import AvatarGroup from "joynt/components/AvatarGroup";
import { useNodePage } from "joynt/hooks/channels/useNodePage";
import { ParticipantsCount } from "joynt/components/SessionParticipation";
import SessionCover from "joynt/components/SessionCover";

export function SessionCardContainer(props) {
    const { id } = props;
    const { name, content, event_start, event_end } = useNodeData(id);
    const { identities, max, count } = useSessionParticipants(id);
    const { onShow } = useNodePage("session");
    const show = useCallback(() => onShow(id), [onShow, id]);

    return (
        <SessionCard
            {...props}
            name={name}
            text={
                <RenderContent size={"sm"} content={content} maxLength={240} />
            }
            date={<SessionDate start={event_start} end={event_end} />}
            participants={<AvatarGroup ids={identities} />}
            actions={<SessionConfirmActions id={id} />}
            image={<SessionCover id={id} />}
            addon={max || count ? <ParticipantsCount id={id} /> : null}
            onClick={show}
        />
    );
}

SessionCardContainer.propTypes = {
    id: PropTypes.string,
};
SessionCardContainer.defaultProps = {};

export default SessionCardContainer;
