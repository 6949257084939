import React from "react";
//import Avatar from "joynt/components/Avatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export default function GroupAccess(props) {
    const { name, description, icon, children } = props;
    return (
        <div className={"cols cols-middle cols-spread gap-md"}>
            <div className={"cols cols-top gap-sm"}>
                <div>
                    {icon ? (
                        <Avatar size={"medium"}>
                            <Icon fontSize={"medium"}>{icon}</Icon>
                        </Avatar>
                    ) : (
                        <Avatar size={"medium"} />
                    )}
                </div>
                <div className={"rows rows-left"}>
                    <div className={"type-lg"}>{name}</div>
                    {description ? (
                        <div className={"type-sm o-50"}>{description}</div>
                    ) : null}
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
}

GroupAccess.propTypes = {};
GroupAccess.defaultProps = {};
