import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";
import PropTypes from "prop-types";

export function IdentityListItem(props) {
    const {
        avatar,
        name,
        description,
        className,
        addon,
        onClick,
        onMouseLeave,
    } = props;
    return (
        <div
            className={cn("identity-list-item", className)}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
        >
            {avatar}
            <div className={"identity-list-item__body"}>
                <div className={"identity-list-item__text"}>
                    {name && (
                        <p className={"identity-list-item__name"}>{name}</p>
                    )}
                    {description && (
                        <p className={"identity-list-item__description"}>
                            {description}
                        </p>
                    )}
                </div>
                {addon && (
                    <div className={"identity-list-item__addon"}>{addon}</div>
                )}
            </div>
        </div>
    );
}

IdentityListItem.propTypes = {
    avatar: PropTypes.node,
    name: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    addon: PropTypes.node,
};
IdentityListItem.defaultProps = {};

export default IdentityListItem;
