export const availableViews = {
    people: {
        label: "People",
        icon: "mui-people",
    },
    chat: {
        label: "Chat",
        icon: "mui-chat",
        notifications: true,
    },
    qa: {
        label: "Q&A",
        icon: "mui-forum",
        notifications: true,
    },
    files: {
        label: "Materials",
        icon: "mui-attachment",
        notifications: true,
    },
    polls: {
        label: "Polls",
        icon: "mui-poll",
        notifications: true,
    },
    rooms: {
        label: "Rooms",
        icon: "mui-group_work",
        notifications: true,
    },
    flow: {
        label: "Flow",
        icon: "mui-history",
    },
    dev: {
        label: "Dev",
        icon: "mui-settings",
    },
    settings: {
        label: "Settings",
        icon: "mui-settings",
    },
};
