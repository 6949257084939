import React from 'react';
import {connect} from 'react-redux';
import EntityContext from "containers/context/EntityContext";
import Field from 'containers/Field';
import BookingSummary from "playground/booking/BookingSummary";
import Payment from "playground/booking/Payment";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Checkout extends React.Component {

    render() {
        const {type, id} = this.props;
        const path = [type, id].join('/');
        return (<EntityContext.Provider value={path}>
            <div className={'cols cols-even gap-md phone@rows'}>
                <div className={'rows gap-md'}>
                    <div className={'rows gap-sm'}>
                        <h3>Twoja rezerwacja</h3>
                        <BookingSummary />
                    </div>
                    <div className={'rows gap-sm'}>
                        <h3>Twoje dane</h3>
                        <div className={'cols gap-sm'}>
                            <Field label={'First name'} id={'firstname'} fieldType={'text'} />
                            <Field label={'Last name'} id={'lastname'} fieldType={'text'} />
                        </div>
                        <Field label={'Email address'} id={'email'} fieldType={'text'} />
                        <Field label={'Chcę otrzymać fakturę VAT'} id={'vat_invoice'} fieldType={'checkbox'} />
                        <Field label={'Dodatkowe uwagi odnośnie pobytu'} id={'remarks'} fieldType={'checkbox'} />
                        <Field label={'Rezrwuję dla innej osoby'} id={'guest_data'} fieldType={'checkbox'} />
                    </div>
                </div>
                <div className={'rows gap-md'}>
                    <div className={'rows gap-sm'}>
                        <h3>Potwierdź rezerwację</h3>
                        <Payment />
                    </div>
                    <div className={'rows gap-sm'}>
                        <Field label={'Akceptuję regulamin rezerwacji'} id={'accept_terms'} fieldType={'checkbox'} />
                        <Field label={'Akceptuję RODO i politykę prywatności'} id={'accept_privacy'} fieldType={'checkbox'} />
                        <Field label={'Wyrażam zgodę na otrzymywanie materiałów'} id={'accept_marketing'} fieldType={'checkbox'} />
                    </div>
                </div>
            </div>
        </EntityContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout);