import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";

export function PagesLayout(props) {
    const { children, isCollapsed } = props;

    return (
        <div
            className={cn("pages-layout", {
                "is-collapsed": isCollapsed,
            })}
        >
            {children}
        </div>
    );
}

PagesLayout.propTypes = {};

PagesLayout.defaultProps = {};

export default PagesLayout;
