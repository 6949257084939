import { takeEvery, put, select } from 'redux-saga/effects';
import { create } from 'state/actions/data';
import { selectCurrentUserId } from "state/selectors/app";

//import { push } from 'state/actions/modal';

function* handleCreate({payload, callback}) {
    try {
        const { type, item, list, position } = payload;
        const userId = yield select(store=>selectCurrentUserId(store));
        const data = {...item, created_by: userId};
        yield put(create(type, data, list, position));
        if (callback) callback(data);
        //yield put(push([type, item.id].join('|')));
    } catch (e) {
        console.log(e);
    }
}

export default function*(action) {
    yield takeEvery('ENTITY.CREATE', handleCreate);
}