import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useAgoraClient } from "joynt/meeting/agora-ng/hooks";
import { useMediaDevices } from "joynt/meeting/devices";
import { useSampleTrackVolume } from "joynt/meeting/hooks/useSampleTrackVolume";

import MicrophoneLevel from "./MicrophoneLevel";

export function MicrophoneLevelContainer(props) {
    const rtc = useAgoraClient();

    const [track, setTrack] = useState(null);
    const [volume, setVolume] = useState(0);

    const { selectedDevices } = useMediaDevices();
    const { audioInput } = selectedDevices;

    const ref = useRef();

    useEffect(() => {
        rtc.createMicrophoneTrack(audioInput).then((t) => {
            ref.current = t;
            setTrack(t);
        });
        return () => {
            if (ref.current) {
                ref.current.stop();
                ref.current.close();
                setTrack(null);
            }
        };
    }, [rtc, ref, setTrack, audioInput]);

    useSampleTrackVolume(track, setVolume, 200);

    const percentVolume = Math.floor(volume * 100);

    if (!track) return null;

    return <MicrophoneLevel volume={percentVolume} />;
}

MicrophoneLevelContainer.propTypes = {};

MicrophoneLevelContainer.defaultProps = {};

export default MicrophoneLevelContainer;
