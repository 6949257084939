import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Section from "./../ListItem";
import Checkbox from "components/fields/checkbox/Checkbox";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";
import { usePending } from "state/hooks";
import { FormLayout, OverlayForm } from "playground/cms/Form";

function selectInitialState(store, props) {
    const { sections, pageType, pageId } = props;

    if (sections)
        return sections.reduce(
            (acc, section) => ({ ...acc, [section]: 2 }),
            {}
        );

    if (!sections && props.pageType) {
        const page = selectEntity(store, pageType, pageId);
        if (!page?.sections) return {};
        return page.sections.reduce((acc, sectionId) => {
            const section = selectEntity(store, "cms.sections", sectionId);
            acc[sectionId] = 2;
            if (!section.active) acc[sectionId] = 0;
            if (section.used_in.length > 1) acc[sectionId] = 1;
            return acc;
        }, {});
    }

    return {};
}

export function MultipleSections(props) {
    const { open, pageType, pageId, onClose, onSelect } = props;
    const click = () => null;
    const initial = useSelector((store) => selectInitialState(store, props));
    const [state, setState] = useState(initial);
    const sections = Object.keys(initial);
    const toggle = (section, status) => {
        setState({ ...state, [section]: status });
    };
    const pending = usePending(pageId);
    return (
        <OverlayForm
            open={open}
            onClose={onClose}
            width={"md"}
            position={"center"}
        >
            <FormLayout isPending={pending}>
                <FormLayout.Header>
                    {pageType} {pageId}
                </FormLayout.Header>
                <FormLayout.Content>
                    <div className={"pad-sm"}>
                        <table className={"list"}>
                            <tr>
                                <th>Section</th>
                                <th>Incl</th>
                                <th>Attach</th>
                            </tr>
                            {sections.map((section) => (
                                <tr>
                                    <td>
                                        <Section key={section} id={section} />
                                    </td>
                                    <td>
                                        <Checkbox
                                            variant={"checkbox"}
                                            value={!!state[section]}
                                            onChange={() =>
                                                toggle(
                                                    section,
                                                    state[section] > 0 ? 0 : 1
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        {state[section] > 0 && (
                                            <Checkbox
                                                variant={"checkbox"}
                                                value={state[section] === 1}
                                                onChange={() =>
                                                    toggle(
                                                        section,
                                                        state[section] > 1
                                                            ? 1
                                                            : 2
                                                    )
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </FormLayout.Content>
                <FormLayout.Footer>
                    <FormLayout.Actions
                        onCancel={onClose}
                        onSubmit={() => onSelect(null, state)}
                    />
                </FormLayout.Footer>
            </FormLayout>
        </OverlayForm>
    );
}

MultipleSections.propTypes = {};

MultipleSections.defaultProps = {};

export default MultipleSections;
