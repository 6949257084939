import {useCallback, useEffect, useState} from "react";

const permissionApi = navigator.permissions;

export default function useBrowserPermission(name) {
    const [state, setState] = useState(null);
    useEffect(() => {
        async function check() {
            if (permissionApi) {
                const status = await permissionApi.query({ name });
                setState(status.state);
                status.onchange = function () {
                    setState(this.state);
                };
                return;
            }
            setState("unknown");
        }
        if (state === null) {
            check().catch((error) => {
                console.error(error);
                setState("unknown");
            });
        }
    }, [name, setState, state]);

    const reset = useCallback(() => setState(null), [setState]);

    return [state, reset];
}
