import React from "react";
//import PropTypes from 'prop-types';
import { Provider } from "containers/media";
import { useData } from "state/hooks";
import { token } from "util/auth";
import * as fileUtils from "util/file";
import env from "util/env";
import FileTypeIcon from "components/file/FileTypeIcon";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_SECURE_MEDIA } from "joynt/config/features";
const thumbnailTypes = [
    "jpg",
    "jpeg",
    "gif",
    "png",
    "JPG",
    "JPEG",
    "blob",
    "svg",
];

const thumb = (file, size = []) => {
    const [w, h] = size;
    if (!w && !h) return file;

    let thumbUrl = file;

    if (w && w !== "auto") thumbUrl += `&w=${w}`;
    if (h && h !== "auto") thumbUrl += `&h=${h}`;

    return thumbUrl;
};

const sign = (url) => {
    const accessToken = token();
    if (!accessToken) return null;
    return `${url}&auth=${accessToken}`;
};

const secureThumb = (media, size) => {
    const url = thumb(media.secure_url, size);
    if (!url) return null;
    return sign(url);
};

const publicThumb = (media, size) => {
    return `${env("THUMBNAIL_ENDPOINT")}/thumb?file=${thumb(media.file, size)}`;
};

const noopThumb = () => null;

const NoopRender = ({ src }) => {
    const style = { backgroundImage: `url("${src}")` };
    return <div className={"media-preview"} style={style} />;
};

function NoPreview({ extension }) {
    return (
        <div className={"media-generic"}>
            <FileTypeIcon extension={extension} />
        </div>
    );
}

function Render(props) {
    const { id, type, generateThumbnail } = props;
    const media = useData({ id, type });
    const { file } = media;
    const extension = fileUtils.extension(file);

    if (thumbnailTypes.indexOf(extension) === -1)
        return <NoPreview extension={extension} />;

    const url = generateThumbnail(media, [200, 150]);

    if (!url) return <NoPreview extension={extension} />;

    const style = {
        backgroundImage: `url("${url}")`,
    };

    if (["svg", "png"].indexOf(extension) > -1)
        style.backgroundSize = "contain";

    return <div className={"media-preview"} style={style} />;
}

const makeRender = (thumb) => {
    return (props) => <Render {...props} generateThumbnail={thumb} />;
};

const RenderSecure = makeRender(secureThumb);
RenderSecure.defaultProps = { type: "db.media" };

const RenderPublic = makeRender(publicThumb);
RenderPublic.defaultProps = { type: "db.media" };

export function MediaProvider({ mode, children }) {
    if (mode === "secure")
        return (
            <Provider Render={RenderSecure} thumb={secureThumb}>
                {children || null}
            </Provider>
        );

    if (mode === "noop")
        return (
            <Provider Render={NoopRender} thumb={noopThumb}>
                {children || null}
            </Provider>
        );

    return (
        <Provider Render={RenderPublic} thumb={publicThumb}>
            {children || null}
        </Provider>
    );
}

export default function MediaProviderContainer(props) {
    const secure = useFeatureEnabled(FEATURE_SECURE_MEDIA);

    return <MediaProvider {...props} mode={secure ? "secure" : null} />;
}

MediaProvider.propTypes = {};
MediaProvider.defaultProps = {};
