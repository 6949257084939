import React from 'react';
//import {useMeeting, useMeetingSession} from "joynt/meeting/MeetingContainer";
import useLoadScript from "util/hooks/useLoadScript";
//import PropTypes from 'prop-types';

export default function ZoomSdkSession(props) {

    //const {access} = useMeeting();
    //const {identity: user} = useMeetingSession();
    const url = 'https://source.zoom.us/instant/1.9.0/lib';
    const scriptLoaded = useLoadScript(url, 'zoom-sdk');

    return (<div>
        {scriptLoaded ? <div>ready</div> : <div>loading zoom sdk</div>}
    </div>);

}

ZoomSdkSession.propTypes = {};
ZoomSdkSession.defaultProps = {};
