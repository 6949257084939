import React from "react";
import cn from "classnames";

import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

import {
    MessageBodyBubble,
    MessageFooterExtended,
    MessageHeaderSimple,
} from "joynt/components/messages/message/subcomponents";

import "./style.css";

export function SidebarMessage(props) {
    const {
        addReactions,
        author,
        avatar,
        className,
        content,
        createdAt,
        files,
        id,
        isDeleted,
        isOwn,
        link,
        messageClick,
        onHide,
        reactions,
        unread,
    } = props;
    return (
        <article
            className={cn(
                "message",
                { "own-message": isOwn },
                `message--sidebar`,
                className
            )}
        >
            <MessageHeaderSimple
                {...{ className }}
                {...{ avatar, className, author, createdAt, unread, isOwn }}
            />
            <MessageBodyBubble
                {...{
                    content,
                    files,
                    isDeleted,
                    isOwn,
                    link,
                    messageClick,
                    onHide,
                }}
            />
            <MessageFooterExtended
                {...{ reactions, isOwn, addReactions, id }}
            />
        </article>
    );
}

SidebarMessage.propTypes = MessagePropTypes;
