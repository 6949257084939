import anchorme from 'anchorme';

const options = {
    exclude: (str) => {
        if (str.startsWith("http://")) return false;
        if (str.startsWith("https://")) return false;
        return true;

        //think about adding email support later
        //anchorme.validate.email(str)
    },
    specialTransform: [
        {
            test: /joynt/,
            transform: s => '<!--REMOVE LINK-->'
        }
    ],
    attributes: {target: '_blank'}
}

export default function extractLinks(text) {

    let textContent = new DOMParser().parseFromString(text, 'text/html');
    let content = textContent.body.textContent;
    let links = anchorme.list(content);
    if (links.length) {
        links = links.map(l => l.string).filter(str=>!options.exclude(str));
        content = anchorme({
            input: content,
            options
        });
    }
    content = content.replace(/<!--REMOVE LINK-->/g, '');
    return [content, links];

}