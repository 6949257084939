import React from "react";
import Button from "components/Button";
import { ErrorPage as BaseErrorPage } from "./NoAccess";

//import PropTypes from 'prop-types';

export function ErrorPage(props) {
    const { error } = props;
    const reload = () => (window.location.href = window.location.href);

    return (
        <BaseErrorPage
            text={
                <div className={"rows rows-center rows-middle grow gap-sm"}>
                    <p>Something went wrong</p>
                    <div className={"rows rows-center o-50 type-sm"}>
                        {error}
                    </div>
                </div>
            }
            action={<Button onClick={reload}>Reload page</Button>}
        />
    );
}

ErrorPage.propTypes = {};
ErrorPage.defaultProps = {};

export default ErrorPage;
