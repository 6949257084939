import {useCallback, useMemo} from "react";
import {useApiActions, useCurrentUser, useData, usePending} from "state/hooks";
import {reaction} from "joynt/state/actions/reactions";

const nullObj = {};

export function useReaction({type, id}) {
    const {
        onReaction
    } = useApiActions({
        onReaction: reaction
    });

    const user = useCurrentUser();
    const {reactions: reactionsData} = useData({type, id});

    const reactions = useMemo(() => {
        let asObject = {};
        if (!reactionsData) return asObject;
        reactionsData.forEach(group => {
            asObject[group._id] = group;
        });
        return asObject;
    }, [reactionsData]);

    const pending = usePending(`${id}.reaction`);

    const handle = useCallback((reaction_type) => {
        onReaction(type, id, reaction_type);
    }, [onReaction, type, id]);

    const {total, you} = useMemo(() => {
        let total = 0;
        let you = null;
        if (!reactions) return {total, you};
        Object.keys(reactions).forEach(type => {
            total += reactions[type].count;
            if (reactions[type].users && reactions[type].users.indexOf(user) > -1) {
                you = type;
            }
        });
        return {total, you};
    }, [reactions, user]);

    const enabled = true;

    return {
        enabled,
        reactions: reactions || nullObj,
        onReaction: handle,
        pending,
        total,
        you
    };
}