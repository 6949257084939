import React from 'react';
// import PropTypes from 'prop-types';
import "./style.css";
import {GhostIcon, ShadowIcon} from "joynt/components/Icons";

export function EmptyState(props) {

    const {text} = props;

    return (<div className={"sessions-slider-empty-state"}>
        <ShadowIcon>
            <GhostIcon />
        </ShadowIcon>
        <div className={"sessions-slider-empty-state__text"}>
            {text}
        </div>
    </div>);

}

EmptyState.propTypes = {};

EmptyState.defaultProps = {
    text: "There are no scheduled sessions"
};

export default EmptyState;