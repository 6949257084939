import React, { useState } from "react";
import { ClipboardContext } from "./ClipboardContext";
import { useApiActions } from "state/hooks";
import { clipboardPaste } from "cms/state/actions/clipboard";

//import PropTypes from 'prop-types';

export function ClipboardContextProvider(props) {
    const { children } = props;

    const [contents, setContents] = useState([]);

    const { paste } = useApiActions({
        paste: clipboardPaste,
    });

    const context = {
        onCopy: (item) => setContents([item]),
        onPaste: (target) => {
            paste(target, contents);
            setContents([]);
        },
        contents,
        types: contents.map((item) => item.type),
    };

    return (
        <ClipboardContext.Provider value={context}>
            {children}
        </ClipboardContext.Provider>
    );
}

ClipboardContextProvider.propTypes = {};

ClipboardContextProvider.defaultProps = {};

export default ClipboardContextProvider;
