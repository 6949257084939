import React from "react";

import "./style.css";

export const SideHelperSection = ({ title, content }) => {
  return (
    <section className={"side-helper-section"}>
      <header className={"side-helper-section__title"}>
        <h4>{title}</h4>
      </header>
      <p className={"side-helper-section__content"}>{content}</p>
    </section>
  );
};

export default SideHelperSection;
