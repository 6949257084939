/**
 * @param type
 * @param id
 * @param target
 * @param mode replace|append|prepend
 */

export const clone = (type, id, target, mode = 'replace') => {
    return {
        type: 'DATA.CLONE',
        payload: {
            type,
            id,
            target,
            mode
        }
    }
};