import React from "react";

//import PropTypes from 'prop-types';
import "./style.css";

export function SidebarNav(props) {
    const { children } = props;

    return <div className={"sidebar-nav"}>{children}</div>;
}

SidebarNav.propTypes = {};
SidebarNav.defaultProps = {};

export default SidebarNav;
