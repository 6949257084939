import { useMemo } from "react";

import { useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectUserNodeIdentity } from "joynt/state/selectors/joint";
import { selectEntity } from "state/selectors/data";

export function useSessionFlowArgs(session) {
    const { identity: a, visible_for: bIds, can_edit } = useNodeData(session);

    const identity = useSelector((s) => selectUserNodeIdentity(s, session));

    const b = bIds ? bIds[0] : null;

    const { name: aName } = useSelector((s) =>
        selectEntity(s, "db.identities", a)
    );
    const { name: bName } = useSelector((s) =>
        selectEntity(s, "db.identities", b)
    );

    return useMemo(
        () => ({
            aName: aName ? aName.split(" ")[0] : null,
            bName: bName ? bName.split(" ")[0] : null,
            a,
            b,
            identity,
            isAdmin: can_edit,
        }),
        [aName, bName, a, b, identity, can_edit]
    );
}
