import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { Event, StaticLink } from "./Features";
import LinkForm from "playground/cms/Link/LinkForm";
import { useActions, useData } from "state/hooks";
import ComponentModeSelect from "playground/designer/ComponentEditor/ComponentModeSelect";
import { change } from "state/actions/data";

function detectMode(data) {
    if (data.ui_mode) return data.ui_mode;

    if (!data?.template_id) return "link-ref";

    if (data?.template_id === "@link-item") {
        return "link-item";
    }

    if (data?.link_ref) {
        return "link-ref";
    }

    return "none";
}

function changeMode(mode, data) {
    let next = { ...data };
    switch (mode) {
        case "link-item":
            next.template_id = "@link-item";
            next.link_ref = null;
            break;
        case "link-ref":
            next.template_id = null;
            next.link_ref = {};
            break;
        case "none":
            next.template_id = null;
            next.link_ref = null;
            break;
    }
    next.ui_mode = mode;
    return next;
}

const modes = {
    "link-item": "Odnośnik elementu listy",
    "link-ref": "Przypisz odnośnik",
    none: "Brak",
};

export function Button(props) {
    const { type, id, children } = props;
    const data = useData(props);
    const { enable_badge } = data;
    const mode = detectMode(data);
    const { onChangeEntity } = useActions({ onChangeEntity: change });
    const selectMode = (mode) => {
        onChangeEntity(type, id, changeMode(mode, data));
    };
    return (
        <>
            <ComponentModeSelect
                onChange={selectMode}
                value={mode}
                options={modes}
            />
            {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
            {children}
            {mode === "link-ref" && <LinkForm {...props} variant={"dense"} />}
            {mode === "none" && <StaticLink />}

            <Field id={"icon"} label={"Icon"} fieldType={"icon"} />
            <Field id={"headline"} label={"Headline"} />

            <Field id={"tooltip_text"} label={"Tooltip text"} />

            <Event />

            <Field
                id={"enable_badge"}
                label={"Enable badge"}
                fieldType={"checkbox"}
            />
            {enable_badge && <Field id={"badge_value"} label={"Badge value"} />}
        </>
    );
}

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
