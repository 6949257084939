import React from 'react';
import {ChildComponents} from "cms/components/Layout";
import {NS_COMPONENTS} from "cms/components/types";
//import PropTypes from 'prop-types';

export default class Form extends React.PureComponent {

    render() {
        const {
            components,
            classes,
            inspectId
        } = this.props;

        return <form
            data-inspect={inspectId}
            data-type={'form'}
            data-s={'form'}
            className={classes.form}
        >
            <ChildComponents
                itemType={NS_COMPONENTS}
                items={components}
            />
        </form>;
    }

}

Form.propTypes = {};
Form.autoFillProps = [
    'components'
];