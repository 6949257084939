import React from "react";

import fakeIdentities from "joynt/meeting/mock/data/identities";
import useMockSessionContext from "joynt/meeting/mock/hooks/useMockSessionContext";
import { MeetingContext } from "joynt/meeting/MeetingContext";
import MockSession from "joynt/meeting/mock/MockSession";
import { NetworkState } from "joynt/network-state";
import { SessionFlowProvider } from "joynt/components/SessionFlow";

export default function MockMeeting(props) {
    const channel = "stage-dev";
    const user = fakeIdentities[0].id;
    const ctx = useMockSessionContext(channel);
    return (
        <NetworkState>
            <MeetingContext.Provider value={ctx}>
                <SessionFlowProvider id={channel} path={"default.default"}>
                    <MockSession user={user} channel={channel} {...props} />
                </SessionFlowProvider>
            </MeetingContext.Provider>
        </NetworkState>
    );
}
