import React from 'react';
import PropTypes from 'prop-types';
import WitnessLeaveSession from "./WitnessLeaveSession";
import AggregateRoot from "containers/AggregateRoot";

import {useForm} from "joynt/hooks";
import {useField} from "state/hooks";

import WitnessMessage from "./../WitnessMessage";
import WitnessReport from "./../WitnessReport";

export function WitnessLeaveSessionContainer(props) {

    //const {} = props;

    const type = "app.forms";
    const id = "witness-leave";

    const {onReject} = useForm(type, id);
    const {value: action, onChange: onSetAction} = useField(type, id, "action_type");

    const types = {
        message: WitnessMessage,
        report: WitnessReport,
        default: WitnessLeaveSession
    }

    const FormComponent = types[action] || types.default;

    return (<AggregateRoot type={type} id={id} disableSubmit={true}>
        <FormComponent
            {...props}
            onReject={onReject}
            onSetAction={onSetAction}
        />
    </AggregateRoot>);

}

WitnessLeaveSessionContainer.propTypes = {
    id: PropTypes.string
};
WitnessLeaveSessionContainer.defaultProps = {};

export default WitnessLeaveSessionContainer;