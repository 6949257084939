import React, { useCallback, useState } from "react";
import cn from "classnames";
import { MentionsInput, Mention } from "react-mentions";
import { useApiActions } from "state/hooks";
import { fetchMentionSuggestions } from "joynt/state/actions/messages";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
//import PropTypes from 'prop-types';

const mentionsStyles = {
    suggestions: {
        borderRadius: "var(--border-radius)",
        border: "1px solid var(--mentions-border-color)",
        background: "var(--mentions-background-color)",
        padding: 0,
        overflow: "hidden",
    },
};

function renderSuggestion(identity) {
    return (
        <div className={"mention-identity cols cols-middle gap-xs"}>
            <IdentityAvatarContainer id={identity.id} size={"xs"} />
            <div className={"type-sm"}>{identity.display}</div>
        </div>
    );
}

const MentionsTextArea = React.forwardRef((props, inputRef) => {
    const { id, onBlur, onFocus, isFocused } = props;
    const { onFetch } = useApiActions({ onFetch: fetchMentionSuggestions });
    const fetchUsers = useCallback(
        (search, cb) => {
            onFetch(id, search, cb);
        },
        [id, onFetch]
    );
    const [focused, setFocused] = useState(isFocused);
    return (
        <div
            className={cn("message-input", {
                "is-focused": focused,
            })}
        >
            <MentionsInput
                {...props}
                style={mentionsStyles}
                ref={inputRef}
                focused={isFocused}
                onFocus={onFocus ? onFocus : () => setFocused(true)}
                onBlur={onBlur ? onBlur : () => setFocused(false)}
                allowSuggestionsAboveCursor={true}
                data-private="lipsum"
            >
                <Mention
                    trigger={"@"}
                    data={fetchUsers}
                    renderSuggestion={renderSuggestion}
                />
            </MentionsInput>
        </div>
    );
});

export default class TextInput extends React.PureComponent {
    handleChange = (evt) => {
        const { onChange } = this.props;
        onChange(evt.target.value);
    };

    handleKey = (e) => {
        const { value } = this.props;
        if (!this.props.onEnterKey) return;
        let code = e.keyCode ? e.keyCode : e.which;

        /**
         * Allow newline
         */
        if (code === 13 && e.shiftKey && value) {
            return;
        }
        /**
         * Send message
         */
        if (code === 13) {
            e.preventDefault();
            if (!value) return;
            this.props.onEnterKey(e);
        }
    };

    handlePaste = (e) => {
        const { onUpload } = this.props;
        let data = e.clipboardData.items[0];
        let file = data.getAsFile() || e.clipboardData.files[0];
        if (file && onUpload) onUpload([file]);
    };

    render() {
        const {
            value,
            parent,
            placeholder,
            onBlur,
            onFocus,
            isFocused,
            inputRef,
        } = this.props;
        return (
            <MentionsTextArea
                inputRef={inputRef}
                onBlur={onBlur}
                onFocus={onFocus}
                id={parent}
                className={"mentions"}
                rows={1}
                wrap="soft"
                onChange={this.handleChange}
                onPaste={this.handlePaste}
                onKeyPress={this.handleKey}
                value={value}
                placeholder={placeholder}
                data-private="lipsum"
                isFocused={isFocused}
            />
        );
    }
}

TextInput.propTypes = {};
TextInput.defaultProps = {};
