import React from "react";
import PropTypes from "prop-types";
import IdentityListItem from "./IdentityListItem";
import { useIdentity } from "joynt/hooks/participants";
import { useCover } from "state/hooks";
import Avatar from "joynt/components/Avatar";

export function IdentityListItemContainer(props) {
    const { id } = props;
    const { name, media, company_name, company_role } = useIdentity(id);
    const image = useCover(media, [64, 64]);
    return (
        <IdentityListItem
            {...props}
            avatar={<Avatar src={image} name={name} />}
            name={name}
            description={
                company_role && [company_role, company_name].join(" at ")
            }
        />
    );
}

IdentityListItemContainer.propTypes = {
    id: PropTypes.string,
};
IdentityListItemContainer.defaultProps = {};

export default IdentityListItemContainer;
