import React from "react";
// import PropTypes from 'prop-types';

import Schedule from "joynt/components/Schedule";

import "./views.css";

export function RoomSchedule(props) {
    return (
        <div className={"card room-schedule pad-sm"}>
            <Schedule {...props} />
        </div>
    );
}

RoomSchedule.propTypes = {};
RoomSchedule.defaultProps = {};

export default RoomSchedule;
