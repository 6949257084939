import React from "react";
import { withSchema } from "containers/Field/withSchema";

import CollectionContainer from "./CollectionContainer";
import { ModalCollectionRenderer } from "components/fields/collection/ModalCollection";
import { DefaultCollection } from "components/fields/collection/DefaultCollection";

export class Collection extends React.PureComponent {
    render() {
        const CollectionRenderer =
            this.props.CollectionRenderer || DefaultCollection;
        const variant = this.props.variant;
        return (
            <CollectionContainer {...this.props}>
                {variant === "dense"
                    ? ModalCollectionRenderer
                    : CollectionRenderer}
            </CollectionContainer>
        );
    }
}

export default withSchema()(Collection);
