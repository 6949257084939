import React from "react";
import { connect } from "react-redux";
import * as tree from "state/actions/tree";
import { moveComponent, createComponent } from "cms/state/actions/cms";
import cn from "classnames";
import { selectList } from "state/selectors/lists";
import FileExplorerTheme from "react-sortable-tree-theme-full-node-drag";
import Menu from "@material-ui/core/Menu";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
import ComponentContextMenu from "cms/ui/ComponentContextMenu";

import "react-sortable-tree/style.css";
import "cms/ui/css/component-tree.css";
import { typeToField } from "cms/state/selectors/cms";
import Icon from "components/Icon";
import icons from "config/icons";

export const canHaveChildren = [
    "section",
    "layout",
    "structured",
    "list",
    "card",
    "form",
    "form-select",
    "context",
    "geo",
    "query",
    "plan",
    "link",
];

const contextMenuOrigin = {
    horizontal: "left",
    vertical: "bottom",
};

function findParentNode(data, id, parent) {
    for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
            return { id: parent, children: data.map((node) => node.id) };
        } else {
            let result = findParentNode(data[i].children, id, data[i].id);
            if (result) return result;
        }
    }
    return null;
}

function calculateMoveNode({ type, id, data }, args) {
    let node = args.node || {};
    let nextParent = findParentNode(args.treeData, node.id, null);
    let prevParent = findParentNode(data, node.id, null);
    prevParent.children = prevParent.children.filter((id) => id !== node.id);
    if (!prevParent) return null;
    if (!nextParent) return null;
    let prevType = prevParent.id ? "cms.components" : type;
    let prevUpdate = {};
    prevUpdate[typeToField(prevType)] = prevParent.children;
    let prev = {
        type: prevType,
        id: prevParent.id ? prevParent.id : id,
        update: prevUpdate,
    };
    let nextType = nextParent.id ? "cms.components" : type;
    let nextUpdate = {};
    nextUpdate[typeToField(nextType)] = nextParent.children;
    let next = {
        type: nextType,
        id: nextParent.id ? nextParent.id : id,
        update: nextUpdate,
    };
    return {
        prev,
        next,
    };
}

const mapStateToProps = (store, props) => {
    return {
        data: props.tree,
        filter: selectList(store, props.type, [props.list, "filter"].join("/")),
    };
};

const mapDispatchToProps = {
    onExpand: tree.expand,
    onCollapse: tree.collapse,
    onChange: moveComponent,
    onCreate: createComponent,
};

function ComponentTreePlaceholder(props) {
    return (
        <div className={"w-fill pad-sm rows rows-center o-50"}>
            No components
        </div>
    );
}

class ComponentTree extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { contextMenu: false, contextMenuAnchor: null };
    }

    generateNodeProps = ({ node, isSearchMatch }) => {
        return {
            title: this.nodeTitle(node, isSearchMatch),
            selected: this.props.focusId === node.id,
        };
    };

    showContextMenu = (evt, id) => {
        evt.preventDefault();
        this.setState({ contextMenu: id, contextMenuAnchor: evt.target });
    };

    hideContextMenu = (id) => {
        this.setState({ contextMenu: false });
    };

    nodeTitle = (node, match) => {
        const icon = icons["cms.components." + node.type];
        return (
            <div
                className={cn("rstcustom__rowTitleContent", {
                    "text-primary": this.props.focusId === node.id,
                    "is-disabled": !!node.disabled,
                })}
                onClick={() => this.handleClick("cms.components", node.id)}
                onContextMenu={(evt) => this.showContextMenu(evt, node.id)}
            >
                {icon ? (
                    <Icon defaultType={"mui"} className={"icon"}>
                        {icon}
                    </Icon>
                ) : null}
                {node.label || (
                    <span className={"new-component-label"}>new component</span>
                )}
            </div>
        );
    };

    handleClick = (type, id) => {
        const treeType = this.props.type;
        this.props.onExpand(treeType, this.props.id, id);
        this.props.onClick(type, id);
    };

    canDrag = ({ node }) => {
        return true;
        //if (!node.parent) return false;
    };

    canDrop = ({ node, nextParent }) => {
        if (!nextParent) return true;
        return canHaveChildren.indexOf(nextParent.type) > -1;
    };

    canNodeHaveChildren = (node) => {
        if (!node) return true;
        return canHaveChildren.indexOf(node.type) > -1;
    };

    handleMoveNode = (args) => {
        const { onChange } = this.props;
        const update = calculateMoveNode(this.props, args);
        if (!update) return;
        onChange(update.prev, update.next);
    };

    handleCreate = (type) => {
        const { onCreate, focusType, focusId } = this.props;
        onCreate(focusType, focusId, typeToField(focusType), {
            element_type: type,
        });
    };

    search = ({ node, searchQuery }) => {
        if (!searchQuery.length) return false;
        return searchQuery.indexOf(node.id) > -1;
    };

    render() {
        const { type, id, data } = this.props;
        const { onVisibilityToggle } = this.props;

        const { contextMenu, contextMenuAnchor } = this.state;

        if (!data) return <div>Tree data not available</div>;

        return (
            <>
                {data ? (
                    <SortableTree
                        scaffoldBlockPxWidth={20}
                        theme={FileExplorerTheme}
                        onlyExpandSearchedNodes={false}
                        rowHeight={28}
                        treeData={data}
                        canDrag={this.canDrag}
                        canDrop={this.canDrop}
                        canNodeHaveChildren={this.canNodeHaveChildren}
                        onChange={() => null}
                        onVisibilityToggle={onVisibilityToggle}
                        onMoveNode={this.handleMoveNode}
                        generateNodeProps={this.generateNodeProps}
                        placeholderRenderer={ComponentTreePlaceholder}
                    />
                ) : null}
                <Menu
                    open={!!contextMenu}
                    anchorEl={contextMenuAnchor}
                    anchorOrigin={contextMenuOrigin}
                    onClose={this.hideContextMenu}
                >
                    <ComponentContextMenu
                        id={contextMenu}
                        rootId={id}
                        rootType={type}
                        onClose={this.hideContextMenu}
                    />
                </Menu>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentTree);
