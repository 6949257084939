import joining from "./joining";
import witness from "joynt/components/Witness/flows";
import networking from "joynt/components/SessionPage/Networking/flow";
import webinar from "joynt/components/SessionPage/flow";

export default {
    joining,
    ...witness,
    ...networking,
    ...webinar,
    default: [],
};
