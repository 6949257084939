import envVars from "config/env";
import { bootstrap } from "util/bootstrap";
import jsonSchema from "config/schema";
import { selectLang } from "state/selectors/router";
import hijackConsole from "util/console";

function legacySchema(jsonSchema) {
    if (window.location.href.indexOf("hotel-slask") > -1) {
        jsonSchema["cms.sections"] = jsonSchema["cms.sections.legacy"];
        return jsonSchema;
    }
    return jsonSchema;
}

hijackConsole();

bootstrap({
    env: envVars,
    schema: legacySchema(jsonSchema),
    langProvider: selectLang,
});
