import React from 'react';
import PropTypes from 'prop-types';
import {useWorkspaces} from "joynt/hooks/channels";
import SidebarNodeItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/SidebarItem.container";
import SidebarSection from "joynt/components/Sidebar/SidebarNav/SidebarSection";

export function Subworkspaces(props) {

    const {id, onClick} = props;
    const sub = useWorkspaces(id);

    if (!sub.length) return null;

    return (<SidebarSection name={"Sub-workspaces"}>
        {sub.map(id => <SidebarNodeItem
            key={id}
            id={id}
            onClick={onClick}
        />)}
    </SidebarSection>);

}

Subworkspaces.propTypes = {};

Subworkspaces.defaultProps = {};

export default Subworkspaces;