import React from "react";
import Sessions from "joynt/sidebar/Sessions";

//import PropTypes from 'prop-types';

export function Rooms(props) {
    const { id } = props;

    return (
        // <Sessions id={id} />
        <div>wip</div>
    );
}

Rooms.propTypes = {};
Rooms.defaultProps = {};

export default Rooms;
