import React, {useCallback, useRef, useState} from 'react';
import {useAssignRoles} from "joynt/edit/access/hooks";
import IdentitySearch from "joynt/edit/access/IdentitySearch";
import List from "containers/List";
import {ArrayIterator} from "components/list/iterator";
import Member from "joynt/edit/access/Member";
import {accessScopes} from "joynt/edit/access/config";
import Popover from "components/Popover";
import Button from "components/Button";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

const MemberIterator = ArrayIterator(Member);
const anchorOrigin = {
    horizontal: 100,
    vertical: 0
}

export default function AssignRoles(props) {

    const {id, label, scope, children} = props;
    const [showSearch, setShowSearch] = useState(false);

    const {users, onAssignRole, userRole} = useAssignRoles(scope, id);
    const defaultRole= Object.keys(accessScopes[scope])[0];

    const toggleIdentity = useCallback(id => {
        onAssignRole(id, defaultRole);
        setShowSearch(false);
    }, [onAssignRole, defaultRole, setShowSearch]);

    const anchorRef = useRef(null);

    return <div className={'rows gap-sm grow'}>
        { label ? <div className={'cols cols-middle cols-spread'}>
            <div className={'type-lg'}>{label}</div>
        </div> : null }
        { children || (users && users.length) ? <div className={'rows gap-xs'}>
            {children}
            { users && users.length ? <List
                type={'db.identities'}
                items={users}
                Iterator={MemberIterator}
                onAssignRole={onAssignRole}
                identityRole={userRole}
                scope={scope}
            /> : null }
        </div> : null }
        <div ref={anchorRef}>
            <Button
                onClick={()=>setShowSearch(!showSearch)}
                variant={'text'}
                startIcon={<Icon>mui-add</Icon>}
            >
                Add role
            </Button>
        </div>
        <Popover
            open={showSearch}
            onClose={()=>setShowSearch(false)}
            anchorEl={()=>anchorRef.current}
            anchorOrigin={anchorOrigin}
        >
            <div className={'pad-xs'}>
                <IdentitySearch
                    id={id}
                    onSelect={toggleIdentity}
                />
            </div>
        </Popover>
    </div>;
}

AssignRoles.defaultProps = {
    scope: 'access'
}

AssignRoles.propTypes = {};
AssignRoles.defaultProps = {};
