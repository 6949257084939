import React from "react";

import SessionSummary from "./SessionSummary";
import SessionSummaryMobile from "./SessionSummary.mobile";
import useScreenSize from "util/hooks/useScreenSize";

function SessionSummaryContainer(props) {
    const { isMobile } = useScreenSize();

    if (isMobile) {
        return <SessionSummaryMobile {...props} />;
    }

    return <SessionSummary {...props} />;
}

export default SessionSummaryContainer;
