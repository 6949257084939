import React from 'react';
import {connect} from 'react-redux';
import ComponentPreview from "cms/ComponentPreview";
import Field from 'containers/Field';
import EntityContext from "containers/context/EntityContext";
import RewireContext from "containers/context/RewireContext";
import components from 'cms/components';

const mapStateToProps = (store, props) => ({});
const mapDispatchToProps = {};

class FrontComponents extends React.PureComponent {

    render() {
        const ComponentType = components.button;
        const TextType = components.text;

        const component = <div>
            <ComponentType id={'button'} />
            <TextType id={'button'} rewire={'title'} />
            <RewireContext.Provider value={'app.data/something'}>
                <ComponentType id={'button'} />
                <TextType id={'button'} rewire={'title,badge:headline'} />
            </RewireContext.Provider>
        </div>;

        return (<div className={'cols gap-md pad-lg'}>
            <div className={'area'}>
                <EntityContext.Provider value={'app.data/something'}>
                    <div className={'rows gap-sm'}>
                        <Field id={'title'} label={'title'} />
                        <Field id={'label'} label={'Label'} />
                        <Field id={'icon'} label={'Icon'} />
                        <Field id={'href'} label={'Href'} />
                        <Field id={'badge'} label={'Badge'} />
                    </div>
                </EntityContext.Provider>
            </div>
            <div className={'area'}>
                <EntityContext.Provider value={'app.components/button'}>
                    <div className={'rows gap-sm'}>
                        <Field id={'title'} label={'Title'} />
                        <Field id={'label'} label={'Label'} />
                        <Field id={'icon'} label={'Icon'} />
                        <Field id={'href'} label={'Href'} />
                        <Field id={'badge'} label={'Badge'} />
                        <Field id={'rewire'} label={'RewireProps'} fieldType={'rewire'} />
                    </div>
                </EntityContext.Provider>
            </div>
            <div className={'area grow'}>
                <div className={'rows gap-md'}>
                    <div className={'area'}>
                        {component}
                    </div>
                    <ComponentPreview>
                        {component}
                    </ComponentPreview>
                </div>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontComponents);