import React from 'react';

const RewireContext = React.createContext(null);
export default RewireContext;

function merge(prev, value) {
    if (!prev) return value;
    let next = prev.split(':');
    next.push(value);
    return next.join(':');
}

export function MergeRewireContext(props) {
    const { value } = props;
    return <RewireContext.Consumer>
        {(prevValue)=><RewireContext.Provider value={merge(prevValue, value)}>
            {props.children}
        </RewireContext.Provider>}
    </RewireContext.Consumer>;
}