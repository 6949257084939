import React, { useState } from "react";
import { useRouteNodes } from "joynt/hooks/channels";
import ChannelView from "joynt/ChannelView";
import Fetch from "containers/Fetch";
import { ResponsiveModal } from "components/Modal";
import App from "./App";

//import PropTypes from 'prop-types';

import AuthForm from "components/auth/AuthForm";
import WelcomeView from "./welcome";

import { useSelector } from "react-redux";
import { isAuthenticated } from "state/selectors/auth";
import { usePending, useRouteParam, useUiFlag } from "state/hooks";
import { useJoin, useNodeData } from "joynt/hooks";
import AppLayout from "joynt/components/AppLayout";
import PublicChannel from "joynt/websockets/PublicChannel";
import EchoProvider from "joynt/websockets/EchoProvider";
import useFetch from "state/hooks/useFetch";
import useScreenSize from "util/hooks/useScreenSize";
import AppPhone from "joynt/AppPhone";

const urls = {
    tos: "https://joynt.works/terms-of-service",
    privacy: "https://joynt.works/privacy-policy",
};

function ModalAuthForm(props) {
    const [view, onChangeView] = useState("login");
    return (
        <div className={"login-content"}>
            <AuthForm view={view} onChangeView={onChangeView} urls={urls} />
        </div>
    );
}

function Welcome(props) {
    const { id } = props;
    return (
        <WelcomeView id={id}>
            <div className={"type-default text-center"}>
                Please fill out your profile information and photo - it will
                help others to get know you quicker. Your social media profiles
                are optional, but we strongly recommend you include them also.
            </div>
        </WelcomeView>
    );
}

export function AppPublic(props) {
    const {} = props;
    const { id, channel, subchannel, rootNode } = useRouteNodes();

    const hasAuth = useSelector((s) => isAuthenticated(s));
    const pending = usePending([id, channel]);

    const { user_role } = useNodeData(id);
    const [showAuth, onShowAuth] = useUiFlag("ui.authForm");
    const [createProfile] = useRouteParam("createProfile");
    const { onJoin } = useJoin();

    const hasAccount = hasAuth && rootNode;
    const hasRole = !!user_role;
    const { isMobile } = useScreenSize();

    const register = () => {
        if (!hasAccount) {
            onShowAuth(true);
            return;
        }
        if (!hasRole) {
            onJoin(rootNode, id);
        }
    };

    const nodeId = channel || id;

    useFetch({
        type: "db.nodes",
        id: nodeId,
        url: "v2/joynt/nodes/:id",
        enable: !!nodeId,
    });

    return (
        <>
            {hasAccount && hasRole ? (
                <App
                    {...props}
                    requireProfile={false}
                    disableFetch={true}
                    url={`v2/joynt/nodes/${nodeId}/networking`}
                    Component={isMobile ? AppPhone : undefined}
                />
            ) : (
                <EchoProvider>
                    <PublicChannel id={channel} />
                    <AppLayout id={channel} isPending={pending} isPage={true}>
                        <ChannelView
                            id={id}
                            channel={channel}
                            subchannel={subchannel}
                            view={"public-session-page"}
                            hasAccount={hasAccount}
                            hasRole={hasRole}
                            hasProfile={!!rootNode}
                            hasAuth={hasAuth}
                            onRegister={register}
                        />
                    </AppLayout>
                </EchoProvider>
            )}
            <ResponsiveModal
                open={!hasAuth && showAuth}
                onClose={() => onShowAuth(false)}
            >
                <ModalAuthForm />
            </ResponsiveModal>
            {hasAuth && (!hasAccount || createProfile) && <Welcome id={id} />}
        </>
    );
}

AppPublic.propTypes = {};
AppPublic.defaultProps = {};

export default AppPublic;
