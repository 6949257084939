import React from 'react';
import PropTypes from 'prop-types';
import Page from "joynt/views/Page";

export function Description(props) {

    const {id} = props;

    return (<div
        className={
            "grow text-content text-edit text-content--default"
        }
    >
        <Page id={id} edit={true} />
    </div>);

}

Description.propTypes = {};

Description.defaultProps = {};

export default Description;