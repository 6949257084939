import React, { useState } from "react";

import useSelectMedia from "./useSelectMedia";
import { getLinkRef } from "playground/cms/Link/selectors";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import ConfigSearch from "./../ConfigSearch";
import SitemapSearch from "./../SitemapSearch";

//import PropTypes from 'prop-types';

export function LinkTypeSelect(props) {
    const { onChange, type, data, onClose } = props;

    const onSelectMedia = useSelectMedia(onChange);
    const linkRef = getLinkRef(type, data) || {};
    const refType = linkRef?.type;

    const [view, setView] = useState(refType === "sitemap" ? "sitemap" : null);

    const handleSetView = (e, v) => {
        e.stopPropagation();
        setView(v);
    };

    const handleSelectSitemap = (id, section = null) => {
        const next = {
            link_ref: {
                type: "sitemap",
                resource_id: id,
                section_id: section,
                url: null,
                link_anchor: null,
            },
        };
        if (section) next.link_ref.custom_anchor = null;
        onChange(next);
        onClose();
    };

    const handleSelectConfig = (key) => {
        onChange({
            link_ref: {
                type: "config",
                config_key: key,
                resource_id: null,
                url: null,
            },
        });
        onClose();
    };

    if (!view)
        return (
            <SelectList onClick={onClose}>
                <SelectListItem
                    onClick={(e) => handleSetView(e, "sitemap")}
                    isSelected={refType === "sitemap"}
                >
                    Podstrona
                </SelectListItem>
                <SelectListItem
                    onClick={onSelectMedia}
                    isSelected={refType === "media"}
                >
                    Plik z biblioteki
                </SelectListItem>
                <SelectListItem
                    onClick={() => onChange({ link_ref: { type: "external" } })}
                    isSelected={refType === "external"}
                >
                    External
                </SelectListItem>
                <SelectListItem
                    onClick={(e) => handleSetView(e, "config")}
                    isSelected={refType === "config"}
                >
                    Wybierz z ustawień
                </SelectListItem>
                <SelectListItem
                    onClick={() => onChange({ link_ref: null })}
                    isSelected={!refType}
                >
                    Wpisz z palca
                </SelectListItem>
            </SelectList>
        );

    if (view === "config") {
        return (
            <ConfigSearch
                {...props}
                onSelect={handleSelectConfig}
                onClose={(e) => handleSetView(e, null)}
            />
        );
    }

    return (
        <SitemapSearch
            {...props}
            onSelect={handleSelectSitemap}
            onClose={(e) => handleSetView(e, null)}
            pageId={linkRef.resource_id}
            sectionId={linkRef.section_id}
        />
    );
}

LinkTypeSelect.propTypes = {};

LinkTypeSelect.defaultProps = {};

export default LinkTypeSelect;
