import React from 'react';
import PropTypes from 'prop-types';

import EventHeader from "./EventHeader";
import About from "./About";
import {RolesInfo} from "joynt/views/about";
import EventStage from "./EventStage";

import "./style.css";

export function EventOverview(props) {

    const {
        id,
        hasContent
    } = props;

    return (<div className={"event-overview"}>
        <EventHeader id={id} />
        {hasContent ? (
            <About id={id} />
        ) : null}
        <RolesInfo id={id} />
        <EventStage id={id} />
    </div>);

}

EventOverview.propTypes = {
    hasContent: PropTypes.bool
};

EventOverview.defaultProps = {
    hasContent: false
};

export default EventOverview;
