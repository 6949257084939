import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export function SettingsGroup(props) {
    const { name, children } = props;

    return (
        <div className={"settings-group"}>
            <div className={"settings-group__header"}>{name}</div>
            <div className={"settings-group__body"}>{children}</div>
        </div>
    );
}

SettingsGroup.propTypes = {
    name: PropTypes.string,
};

SettingsGroup.defaultProps = {};

export default SettingsGroup;
