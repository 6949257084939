import React from 'react';
import {useSchedule} from "joynt/components/Schedule/hooks";
import StageSessionList from "joynt/components/WorkspaceStage/StageSessionList";
import Schedule from "joynt/components/Schedule/Schedule";
import {useNodeData} from "joynt/hooks";

//import PropTypes from 'prop-types';

export function StageSchedule(props) {

    const {id} = props;

    const {can_edit} = useNodeData(id);

    const {
        selectedDay,
        onSelectDay,
        onCreate,
        count,
        list,
        isPending: isPendingSchedule
    } = useSchedule(id, "schedule");

    return (<Schedule
        id={id}
        list={list}
        onChangeDay={onSelectDay}
        selectedDay={selectedDay}
        onCreate={onCreate}
        sessionCount={count}
        hasSessions={count[selectedDay] > 0}
        isPending={isPendingSchedule}
        canEdit={can_edit}
        ListComponent={StageSessionList}
    />);

}

StageSchedule.propTypes = {};
StageSchedule.defaultProps = {};

export default StageSchedule;
