import {useCallback} from "react";
import {assignRole} from "joynt/state/actions";
import {useApiActions} from "state/hooks";

export function useRoleManagement(channel) {
    const {onAssignRole} = useApiActions({
        onAssignRole: assignRole
    });

    const assign = useCallback((...args) => {
        onAssignRole(channel, ...args);
    }, [channel, onAssignRole]);

    return {onAssignRole: assign};
}