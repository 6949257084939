import React from "react";
import { useSelector } from "react-redux";
import { selectLibraryId } from "cms/state/selectors/cms";
import Select from "components/fields/select/Select";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSectionsFilter } from "playground/cms/views/Sections/SectionsFilterContext";

//import PropTypes from 'prop-types';

function AddonItem({ children, onClick }) {
    return (
        <ListItem button onClick={onClick}>
            <ListItemText primary={children} />
        </ListItem>
    );
}

export function LibraryFilter(props) {
    const { type, list } = props;
    const { value, onSet } = useSectionsFilter();
    const library = useSelector((state) => selectLibraryId(state));

    function RenderAddon({ onClose }) {
        return (
            <div>
                <AddonItem
                    onClick={() => {
                        onSet("service", null);
                        onClose();
                    }}
                >
                    Current service
                </AddonItem>
                {library && (
                    <AddonItem
                        onClick={() => {
                            onSet("service", library);
                            onClose();
                        }}
                    >
                        Library
                    </AddonItem>
                )}
            </div>
        );
    }

    return (
        <div className={"cols grow cols-middle gap-sm type-lg"}>
            <Select
                label={"Current service"}
                url={"console/services?type=website"}
                id={"cms.sections.services"}
                type={"console.services"}
                list={"cms.sections.services"}
                value={value.service}
                nullable={true}
                onChange={(v) => onSet("service", v)}
                variant={"inline"}
                SelectListAddon={RenderAddon}
            />
        </div>
    );
}

LibraryFilter.propTypes = {};

LibraryFilter.defaultProps = {
    type: "cms.sections",
    list: "cms.sections",
};

export default LibraryFilter;
