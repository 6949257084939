import React from "react";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import { useActions, useUiFlag } from "state/hooks";
import { logout } from "state/actions/auth";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import { useAccess, useHasScope } from "playground/console/UserAccess";
import { SCOPE_WEBSITES_DEV } from "config/access-scopes";
import useLocalStorage from "util/hooks/useLocalStorage";

//import PropTypes from 'prop-types';

export function UserMenu(props) {
    const {} = props;

    const devAccess = useHasScope(SCOPE_WEBSITES_DEV);
    const { userMode, setUserMode } = useAccess();

    const { onLogout } = useActions({
        onLogout: logout,
    });

    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton onClick={onClick} icon={"mui-account_circle"} />
            )}
            content={({ onClose }) => (
                <SelectList onClick={onClose}>
                    {(devAccess || userMode) && (
                        <SelectListItem onClick={() => setUserMode(!userMode)}>
                            {userMode ? "Tryb dewelopera" : "Tryb użytkownika"}
                        </SelectListItem>
                    )}
                    <SelectListItem onClick={() => onLogout()}>
                        Wyloguj
                    </SelectListItem>
                </SelectList>
            )}
        />
    );
}

UserMenu.propTypes = {};

UserMenu.defaultProps = {};

export default UserMenu;
