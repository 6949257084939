import React from "react";
import Form from "containers/Form";
import Field from "containers/Field/Field";
import { useModuleEnabled } from "playground/cms/Schema";
//import PropTypes from 'prop-types';

const fields = { tags: true };

export default function PageSettings({ type, id }) {
    //const {} = props;
    const hasProperties = useModuleEnabled("properties");
    return (
        <div className={"rows gap-md"}>
            {hasProperties && (
                <Field
                    id={"property"}
                    label={"Obiekt"}
                    fieldType={"select"}
                    nullable={true}
                    source={"api:cms.properties"}
                />
            )}
            <Form type={type} id={id} selectFields={fields} />
            <Field id={"description"} label={"Opis"} />
        </div>
    );
}

PageSettings.propTypes = {};
PageSettings.defaultProps = {};
