import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import WitnessBoard from "./WitnessBoard";
import {
    createHourRange,
    startOfDay,
} from "joynt/components/DateTimePicker/util";
import { useMoment } from "joynt/components/DateTimePicker/hooks";
import { selectRange } from "joynt/components/DailySchedule/util";
import { appendQueryParams } from "util/uri";
import useFetch from "state/hooks/useFetch";
import { POST_TYPE_TEXT, ROUTE_POST } from "joynt/config";
import { selectList } from "state/selectors/lists";
import { useSelector } from "react-redux";
import { useCreatePost, useNodeData } from "joynt/hooks";
import Icon from "components/Icon";
import { selectUserNodeIdentity } from "joynt/state/selectors/joint";
import { usePending, useRouteParam } from "state/hooks";
import RangeNav from "joynt/components/Calendar/RangeNav";
import RangeLabel from "joynt/components/Calendar/RangeLabel";
import Preloader from "components/Preloader";
import WitnessDashboard from "./../WitnessDashboard";
import CreateTask from "joynt/components/Witness/WitnessDashboard/CreateTask";
import TaskSlots from "joynt/components/Witness/WitnessDashboard/TaskSlots";

function startOfHour(date) {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

function nextHour() {
    const date = startOfHour(new Date());
    date.setHours(date.getHours() + 1);
    return date;
}

function getHours(time, hours) {
    let result = [];
    let date = startOfHour(new Date(time * 1000));
    for (let i = 0; i < hours; i++) {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() + i);
        result.push(newDate.getTime() / 1000);
    }
    return result;
}

function HourSlotCreate(props) {
    const { label } = props;

    return (
        <div className={"hour-slot-create"}>
            {label && <div className={"hour-slot-create__label"}>{label}</div>}
            <div className={"hour-slot-create__icon"}>
                <Icon>mui-add</Icon>
            </div>
        </div>
    );
}

function OwnHourSlot(props) {
    const { id, isPast, witness, onClick, matched, onUnmatch } = props;

    const { name } = useNodeData(id);

    const unmatch = (e) => {
        e.stopPropagation();
        onUnmatch();
    };

    return (
        <div
            className={cn("hour-slot is-own", {
                "has-task": !!id,
                "is-matched": !!matched,
            })}
            onClick={onClick}
        >
            {name && <div>{name}</div>}
            {!isPast && !id && !matched && (
                <HourSlotCreate
                    label={!!witness ? "Create matching task" : null}
                />
            )}
            {matched && (
                <div className={"matched-link"} onClick={unmatch}>
                    Paired
                </div>
            )}
        </div>
    );
}

function WitnessHourSlot(props) {
    const { id, matched } = props;

    return (
        <div
            className={cn("hour-slot is-witness", {
                "has-task": !!id,
                "is-matched": !!matched,
            })}
        >
            {id && (
                <div>
                    <Icon>mui-account_circle</Icon> Witness task
                </div>
            )}
        </div>
    );
}

function HourSlot(props) {
    const { time, onCreate, sessions } = props;
    const m = useMoment(time);
    const isPast = new Date().getTime() / 1000 >= time;
    const { own, witness } = sessions;
    const id = own || witness;
    const { task_witness_match: matched } = useNodeData(own);
    const pending = usePending(id);

    const [, setPage] = useRouteParam(ROUTE_POST);

    const create = useCallback(() => {
        const range = createHourRange(time, 1);
        onCreate({
            event_start: range[0],
            event_end: range[1],
        });
    }, [onCreate, time]);

    const match = (id) => {
        const range = createHourRange(time, 1);
        onCreate({
            event_start: range[0],
            event_end: range[1],
        });
    };

    const show = (id) => {
        setPage(id);
    };

    const click = () => {
        if (matched) {
            show(own);
            return;
        }
        if (!isPast && !own && !witness) {
            create();
            return;
        }
        if (!isPast && !own && witness) {
            match(witness);
            return;
        }
        if (own) {
            show(own);
        }
    };

    return (
        <div
            className={cn("schedule-hour", {
                "is-pending": pending,
            })}
        >
            <div className={"schedule-hour__label"}>{m.format("HH:mm")}</div>
            <div className={"hour-slots"}>
                <OwnHourSlot
                    id={own}
                    isPast={isPast}
                    onClick={click}
                    witness={witness}
                    matched={matched}
                />
                <WitnessHourSlot id={matched || witness} matched={matched} />
                <Preloader visible={pending} size={24} />
            </div>
        </div>
    );
}

HourSlot.defaultProps = {
    sessions: {},
};

function sessionType(data) {
    if (data.user_role === "owner") return "own";
    if (data.task_witness_match) return "witness-paired";

    return "witness";
}

function selectWitnessSessionsByHour(store, list) {
    const nodes = store.data["db.nodes"];
    const items = selectList(store, "db.nodes", list);
    const sessions = {};

    items.forEach((id) => {
        let itemData = nodes[id] || {};
        let start =
            startOfHour(new Date(itemData.event_start * 1000)).getTime() / 1000;
        if (!sessions[start]) sessions[start] = {};
        let type = sessionType(itemData);
        if (!sessions[start][type]) sessions[start][type] = id;
    });

    return sessions;
}

export function WitnessBoardContainer(props) {
    const { id } = props;

    const date = startOfDay(new Date());
    date.setHours(6);
    const time = date.getTime() / 1000;

    const [day, setDay] = useState(time);

    const hours = getHours(day, 18);
    const range = selectRange(new Date(day * 1000), "day");

    const params = {
        start: range.start.getTime() / 1000,
        end: range.end.getTime() / 1000,
        task_witness: true,
    };

    const list = `witness.${id}`;
    const url = appendQueryParams(`v2/joynt/nodes/${id}/witness`, params);

    const [onCreate] = useCreatePost(id, list);

    const create = useCallback(
        (data = {}) => {
            onCreate(null, POST_TYPE_TEXT, true, {
                node_role: "task",
                task_witness: true,
                public: false,
                published: false,
                event_start: nextHour().getTime() / 1000,
                ...data,
            });
        },
        [onCreate]
    );

    const createAtTime = useCallback(
        (time) => {
            create({ event_start: time });
        },
        [create]
    );

    useFetch({
        type: "db.nodes",
        url,
        list,
    });

    const sessions = useSelector((s) => selectWitnessSessionsByHour(s, list));
    const identity = useSelector((s) => selectUserNodeIdentity(s, id));

    const slots = hours.filter((h) => {
        return !!sessions[h]?.witness;
    });

    return (
        <WitnessBoard {...props}>
            <WitnessDashboard>
                {/*<CreateTask onClick={create} />*/}
                <CreateTask onClick={null} />
                <TaskSlots items={slots} onCreate={createAtTime} />
            </WitnessDashboard>
            <div className={"witness-schedule"}>
                <div className={"witness-schedule__filter"}>
                    <div>
                        <RangeNav mode={"day"} day={day} onChange={setDay} />
                    </div>
                    <div className={"witness-schedule__range"}>
                        <RangeLabel day={day} mode={"day"} />
                    </div>
                    <div />
                </div>
                <div className={"witness-schedule__schedule"}>
                    <div className={"witness-schedule__header"}>
                        <div className={"witness-schedule__labels"}>
                            <div>My witness tasks</div>
                            <div>Available witness tasks</div>
                        </div>
                    </div>
                    <div className={"witness-schedule__list"}>
                        <div className={"schedule-hours"}>
                            {hours.map((h) => {
                                return (
                                    <HourSlot
                                        key={h}
                                        time={h}
                                        onCreate={create}
                                        sessions={sessions[h]}
                                        identity={identity}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </WitnessBoard>
    );
}

WitnessBoardContainer.propTypes = {
    id: PropTypes.string,
};
WitnessBoardContainer.defaultProps = {};

export default WitnessBoardContainer;
