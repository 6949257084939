import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import PopoverOverlay from "./PopoverOverlay";
import { getParents } from "util/dom";
import useScreenSize from "util/hooks/useScreenSize";
import DrawerOverlay from "joynt/components/PopoverOverlay/DrawerOverlay";

const calculatePlacement = (e) => {
    if (!e) return "left";

    if (getParents(e, ".session-nav").length) return "left";

    if (getParents(e, ".secondary-sidebar").length) return "left";

    if (getParents(e, ".sidebar-layout").length) return "right";

    if (getParents(e, ".utility-nav-drawer").length) return "left";

    return "top";
};

export function PopoverOverlayContainer(props) {
    const { Context, children, responsive, ...other } = props;

    const { isDesktop } = useScreenSize();
    const [{ id, anchor }, setState] = useState({});
    const [visible, setVisible] = useState(false);

    const timer = useRef(null);

    const show = useCallback(
        (id, anchor) => {
            if (timer && timer.current) clearTimeout(timer.current);
            setState({ id, anchor });
            setVisible(true);
        },
        [setState, setVisible]
    );

    const hideNow = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    const hide = useCallback(() => {
        timer.current = setTimeout(hideNow, 200);
    }, [hideNow]);

    const mouseEnter = useCallback(() => {
        if (timer.current) clearTimeout(timer.current);
    }, [timer]);

    /*const exited = () => {
        setState({id: null, anchor: null});
    }*/

    const context = useMemo(
        () => ({
            onShow: show,
            onHide: hide,
        }),
        [show, hide]
    );

    let placement = calculatePlacement(anchor);

    const OverlayComponent =
        !responsive || isDesktop ? PopoverOverlay : DrawerOverlay;

    return (
        <>
            <Context.Provider value={context}>{children}</Context.Provider>
            <OverlayComponent
                {...other}
                id={id}
                onMouseEnter={mouseEnter}
                onMouseLeave={hideNow}
                onClick={hideNow}
                onClose={hide}
                open={visible}
                anchorEl={anchor}
                placement={placement}
                responsive={responsive && !isDesktop}
            />
        </>
    );
}

PopoverOverlayContainer.propTypes = {
    id: PropTypes.string,
};
PopoverOverlayContainer.defaultProps = {};

export default PopoverOverlayContainer;
