import React from 'react';
//import PropTypes from 'prop-types';
import Preloader from "components/Preloader";

export default class PendingState extends React.Component {

    render() {
        return (<Preloader visible={true} />);
    }

}

PendingState.propTypes = {};