import React from "react";
import { connect } from "react-redux";
import Fetch from "containers/Fetch";
import { withApiContext, bindApiActions } from "containers/context/ApiContext";

import MediaBrowser from "cms/media-browser/MediaBrowser";
import { pushRouteParams } from "state/actions/router";
import { selectRouteParam } from "state/selectors/router";
import { handleBrowserUpload } from "state/actions/upload";
import { entityDelete } from "state/actions/delete";
import {
    createDirectory,
    deleteDirectory,
    importMedia,
    saveMedia,
    selectMedia,
    syncMedia,
} from "state/actions/media";
import { isPending } from "state/selectors/ui";

const mapStateToProps = (store, props) => {
    return {
        view: selectRouteParam(store, "mediaView") || "grid",
        path: selectRouteParam(store, "dir"),
        focus: selectRouteParam(store, "focus"),
        pending: isPending(store, [
            "cms.media",
            "cms.media/browser",
            "cms.media.directories/default",
        ]),
    };
};

const mapDispatchToProps = bindApiActions(
    {
        onFileUpload: handleBrowserUpload,
        onDelete: entityDelete,
        onCreateDirectory: createDirectory,
        onDeleteDirectory: deleteDirectory,
        onImportMedia: importMedia,
        onSyncMedia: syncMedia,
        onSaveMedia: saveMedia,
    },
    {
        onChangePath: pushRouteParams,
        onChangeView: pushRouteParams,
        onPreview: pushRouteParams,
        onSelect: selectMedia,
    }
);

const fetchDirs = {
    url: "cms/browser/dirs?batch=true",
    type: "cms.media.directories",
    useContext: true,
};

const fetchMedia = {
    url: "cms/media",
    type: "cms.media",
    list: "browser",
    useContext: true,
    force: true,
};

class MediaBrowserContainer extends React.PureComponent {
    path = (path) => {
        const { onChangePath } = this.props;
        onChangePath({ dir: path });
    };

    changeView = (view) => {
        const { onChangeView } = this.props;
        onChangeView({ mediaView: view });
    };

    upload = (files) => {
        const { onFileUpload, path } = this.props;
        onFileUpload("cms.media", "browser", path, files);
    };

    handleDelete = (type, list, id) => {
        const { onDelete } = this.props;
        onDelete(type, id, "cms/browser");
    };

    select = (id) => {
        const { onSelect } = this.props;
        onSelect(id);
    };

    preview = (id) => {
        const { onPreview } = this.props;
        onPreview({ preview: ["cms.media", id].join("/") });
    };

    createDirectory = (name) => {
        const { onCreateDirectory } = this.props;
        onCreateDirectory(name);
    };

    deleteDirectory = (name) => {
        const { onDeleteDirectory } = this.props;
        onDeleteDirectory(name);
    };

    importVideo = (url) => {
        const { onImportMedia } = this.props;
        onImportMedia("video", url);
    };

    importPano = (url) => {
        const { onImportMedia } = this.props;
        onImportMedia("pano", url);
    };

    submit = () => {
        const { onSaveMedia } = this.props;
        onSaveMedia();
    };

    render() {
        const { selection, onSelect } = this.props;

        return (
            <>
                <Fetch {...fetchDirs} />
                <Fetch {...fetchMedia} />
                <MediaBrowser
                    {...this.props}
                    onPreview={this.preview}
                    onChangePath={this.path}
                    onChangeView={this.changeView}
                    onFileUpload={this.upload}
                    onDelete={this.handleDelete}
                    onSelect={onSelect ? this.select : null}
                    onCreateDirectory={this.createDirectory}
                    onDeleteDirectory={this.deleteDirectory}
                    onSaveMedia={this.submit}
                    onImportVideo={this.importVideo}
                    onImportPano={this.importPano}
                    selection={selection}
                />
            </>
        );
    }
}

export default withApiContext()(
    connect(mapStateToProps, mapDispatchToProps)(MediaBrowserContainer)
);
