import React from "react";
import cn from "classnames";

import PostLink from "joynt/components/Post/PostContent/content-types/Link";
//import PropTypes from 'prop-types';

import "./style.css";

export const Link = React.forwardRef((props, ref) => {
    const { block, contentState, isFocused, onClick } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const data = entity.getData();
    const className = cn("joynt-link", { "is-focused": isFocused });

    return (
        <div
            className={className}
            ref={ref}
            onClick={!isFocused ? onClick : null}
        >
            <PostLink id={data.post} />
        </div>
    );
    //return <div>Link {JSON.stringify(data)}</div>;
});

Link.propTypes = {};
Link.defaultProps = {};

export default Link;
