//import PropTypes from 'prop-types';

import {useApiActions, usePending} from "state/hooks";
import {useCallback, useMemo} from "react";
import {startPresentation} from "joynt/state/actions/meetings";
import {useNodeData} from "joynt/hooks";
import useMeeting from "joynt/meeting/hooks/useMeeting";

export function usePresentation() {

    const {event} = useMeeting();
    const {event_presentation: id} = useNodeData(event);
    const pending = usePending(`presentation.${event}`);
    const {onPresent} = useApiActions({onPresent: startPresentation});

    const present = useCallback((id) => {
        onPresent(event, id);
    }, [onPresent, event]);

    const endPresentation = useCallback(() => {
        onPresent(event, null);
    }, [onPresent, event]);

    return useMemo(() => ({
        id,
        pending,
        onPresent: present,
        onEndPresentation: endPresentation
    }), [id, pending, present, endPresentation]);

}
