import {useCallback} from "react";
import {useSelector} from "react-redux";
import {selectPage, selectPageCount} from "state/selectors/pagination";
import {selectList} from "state/selectors/lists";
import {isPending} from "state/selectors/ui";
import {useActions} from "state/hooks";
import {setPage} from "state/actions/pagination";

export function usePagination(type, list) {
    const page = useSelector(store=>selectPage(store, type, list));
    const total = useSelector(store=>selectPageCount(store, type, list));
    const items = useSelector(store=>selectList(store, type, list));
    const pending = useSelector(store=>isPending(store, [type,list].join('/')));
    const {onSetPage} = useActions({onSetPage: setPage});
    const loadPage = useCallback((p) => {
        onSetPage(list, p);
    }, [onSetPage, list]);

    return {
        page,
        total,
        hasMore: page < total,
        pending: pending,
        first: items[0],
        last: items[items.length-1],
        loadPage
    }
}