import Resources from "playground/cms/views/Resources";
import Websites from "./Websites";
import Domains from "./Domains";
import Themes from "./Themes";

export default {
    "console.services.website": Websites,
    "console.domains": Domains,
    "console.themes": Themes,
    default: Resources,
};
