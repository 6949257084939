import { takeEvery } from 'redux-saga/effects';

function* localStorageSetItem(action) {
    try {
        const { key, value } = action.payload;
        if (value !== null) {
            yield localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    } catch (e) {
        throw e;
    }
}

export default function*() {
    yield takeEvery('LOCAL_STORAGE.SET', localStorageSetItem);
}