import React from "react";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import { DangerZone } from "joynt/edit/types/fields";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_FOLLOW } from "joynt/config/features";

//import PropTypes from 'prop-types';

export function ProjectAdvancedSettings(props) {
    //const {} = props;
    const enableFollow = useFeatureEnabled(FEATURE_FOLLOW);

    return (
        <FieldsLayout>
            {enableFollow && (
                <FieldGroup label={"Follow"}>
                    <Field
                        id={"unfollow"}
                        fieldType={"checkbox"}
                        label={"Unfollow"}
                    />
                </FieldGroup>
            )}
            <DangerZone />
        </FieldsLayout>
    );
}

ProjectAdvancedSettings.propTypes = {};
ProjectAdvancedSettings.defaultProps = {};

export default ProjectAdvancedSettings;
