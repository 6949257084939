import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import Preloader from "components/Preloader";

export function CreateLayout(props) {
    const { title, isPending, className, children } = props;

    const classes = cn("pad-xlg grow rows gap-lg", className);

    return (
        <>
            <div className={classes}>
                {title && <h1>{title}</h1>}
                {children}
            </div>
            <Preloader visible={isPending} />
        </>
    );
}

CreateLayout.propTypes = {};

CreateLayout.defaultProps = {};

export default CreateLayout;
