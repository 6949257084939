import React from "react";
import { connect } from "react-redux";

import { selectAppApiContext } from "state/selectors/app";
import { selectAliasedEntity, selectEntity } from "state/selectors/data";
import { pushRoute, pushRouteParams } from "state/actions/router";
import { selectRouteParam } from "state/selectors/router";

import RenderPage from "cms/RenderPage";
import { Helmet } from "react-helmet";
import LinkerContext from "cms/context/LinkerContext";
import ApiContext from "containers/context/ApiContext";
import GlobalScripts from "cms/GlobalScripts";
import { selectWebsiteCanonicalHost } from "cms/state/selectors/cms";

function propOrRouteParam(store, props, key) {
    if (props[key]) return props[key];
    return selectRouteParam(store, key);
}

const ASSET_DOMAIN = process.env.REACT_APP_ASSETS_DOMAIN;
const CACHE_BYPASS_PORT = process.env.REACT_APP_CACHE_BYPASS_PORT;

export function assetUrl(service, asset) {
    return `http://${service}.${ASSET_DOMAIN}:${CACHE_BYPASS_PORT}/${asset}`;
}

const mapStateToProps = (store, props) => {
    const serviceAlias = props.service || props.match.params.service;
    const service = selectAliasedEntity(
        store,
        "console.services",
        serviceAlias
    );
    const host = selectWebsiteCanonicalHost(store, serviceAlias);
    const theme = selectEntity(store, "console.themes", service.theme);
    return {
        serviceAlias,
        service: service.id,
        uri: propOrRouteParam(store, props, "uri") || "/index",
        preset: theme.preset,
        apiContext: selectAppApiContext(store, { service: service.id }),
        stylesheet: `${host}/assets/styles/all.min.css`,
    };
};

const mapDispatchToProps = {
    pushRoute: pushRoute,
    pushRouteParams: pushRouteParams,
};

export function makeLink(push, pushParams, service, props) {
    const { linkType, linkUri } = props; //linkValue

    let href = linkType;
    let onClick = null;

    if (linkType === "page-ref") {
        href = `/${service}/render?uri=/${linkUri}`;
        onClick = (evt) => {
            evt.preventDefault();
            pushParams({ uri: `/${linkUri}`, focus: null });
        };
    }

    if (linkType === "link") {
        href = `/${service}/render?uri=${linkUri}`;
        onClick = (evt) => {
            evt.preventDefault();
            pushParams({ uri: linkUri, focus: null });
        };
    }

    if (linkType === "call") {
        return { ...props, onClick: null, href: "tel:+48111" };
    }

    return { ...props, href, onClick };
}

class PageService extends React.PureComponent {
    link = (props) => {
        const { serviceAlias, pushRoute, pushRouteParams } = this.props;
        return makeLink(pushRoute, pushRouteParams, serviceAlias, props);
    };

    render() {
        const { apiContext, serviceAlias, uri, stylesheet, ...other } =
            this.props;

        return (
            <ApiContext.Provider value={apiContext}>
                <Helmet>
                    <link rel="stylesheet" href={stylesheet} />
                    <body className={"theme-scope"} />
                </Helmet>
                <LinkerContext.Provider value={this.link}>
                    <GlobalScripts>
                        <RenderPage
                            {...other}
                            apiContext={apiContext}
                            uri={uri}
                            service={serviceAlias}
                        />
                    </GlobalScripts>
                </LinkerContext.Provider>
            </ApiContext.Provider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageService);
