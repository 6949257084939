import React from 'react';
import PropTypes from 'prop-types';
import Poll from "joynt/components/Poll";
import AggregateRoot from "containers/AggregateRoot";

export function PollContainer(props) {

    const {id} = props;

    return (<AggregateRoot type={"db.posts"} id={id} disableSubmit={true}>
        <Poll {...props} />
    </AggregateRoot>);

}

PollContainer.propTypes = {
    id: PropTypes.string
};
PollContainer.defaultProps = {};

export default PollContainer;