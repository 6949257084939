import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers'; //InlineDatePicker

/* eslint-disable */
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IconButton from "components/IconButton";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Datepicker extends React.PureComponent {

    change = (value) => {
        const { onChange } = this.props;
        let timestamp = value.startOf('day').format('X');
        onChange(timestamp);
    };

    clear = () => {
        const { onChange } = this.props;
        onChange(null);
    };

    render() {
        const { value, label, readOnly } = this.props;

        let dateValue = value
            ? moment.unix(value)
            : null;

        return (<div className={'cols cols-middle'}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    keyboard
                    value={dateValue}
                    onChange={this.change}
                    inputVariant="outlined"
                    label={label}
                    readOnly={readOnly}
                />
            </MuiPickersUtilsProvider>
            <IconButton icon="mui-clear" onClick={this.clear} />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Datepicker);