import React from "react";
//import PropTypes from 'prop-types';
import PageForm from "./PageForm";
import { FormContainer } from "playground/cms/Form";
import { useActions, useData } from "state/hooks";
import { pushRouteParams } from "state/actions/router";

export function PageFormContainer(props) {
    const {} = props;
    const { pushRoute } = useActions({ pushRoute: pushRouteParams });

    const preview = (view) => pushRoute({ view });
    const { sections } = useData(props);

    return (
        <FormContainer
            FormComponent={PageForm}
            {...props}
            sectionsCount={sections?.length}
            onOpenPreview={preview}
        />
    );
}

PageFormContainer.propTypes = {};

PageFormContainer.defaultProps = {};

export default PageFormContainer;
