import React from "react";
import { useFormField } from "state/hooks";
import FieldLayout from "joynt/components/Form/FieldLayout";
import Field from "containers/Field";
import cn from "classnames";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

//import PropTypes from 'prop-types';

function ToggleableFieldGroup({ header, active, children }) {
    return (
        <div
            className={cn("rows settings-field-group", {
                "is-active": active,
            })}
        >
            {header}
            {active && children}
        </div>
    );
}
function RadioSelect({ value, onChange, options }) {
    return (
        <RadioGroup
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
        >
            {options.map(({ id, label }) => {
                return (
                    <FormControlLabel
                        control={<Radio color={"primary"} />}
                        value={id}
                        label={label}
                    />
                );
            })}
        </RadioGroup>
    );
}

export function ActivitySummarySettings(props) {
    const options = [
        { id: "daily", label: "Daily" },
        { id: "weekly", label: "Weekly" },
        { id: "monthly", label: "Monthly" },
    ];
    const { value: enabled } = useFormField("email_summary");

    return (
        <ToggleableFieldGroup
            active={enabled}
            header={
                <FieldLayout name={"Receive activity summary"}>
                    <Field id={"email_summary"} fieldType={"checkbox"} />
                </FieldLayout>
            }
        >
            <Field id={"email_summary_interval"} options={options}>
                {RadioSelect}
            </Field>
        </ToggleableFieldGroup>
    );
}

ActivitySummarySettings.propTypes = {};
ActivitySummarySettings.defaultProps = {};

export default ActivitySummarySettings;
