import React from "react";
import ThemeStyles from "cms/ui/style/ThemeStyles";
import cn from 'classnames';
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check"

export function ThemeSelect(props) {
    const { value, values, onChange } = props;
    return <ThemeStyles className={'grid grid-8 gap-px pad-xs'}>
        {values.map(theme => {
            const checked = value && value.indexOf(theme) > -1;
            return <div
                className={cn('color-swatch',theme, {checked})}
                onClick={()=>onChange(theme)}
                key={theme}
            >
                {checked ? <Check /> : null}
            </div>;
        })}
        { value && value.length ? <div className={'color-swatch'} onClick={()=>onChange(null)}>
            <Close />
        </div> : null }
    </ThemeStyles>;
}