import React from 'react';

import {useRouteParam} from "state/hooks";
import useRouteTransition from "joynt/edit/hooks/useRouteTransition";
import FormLayout from "joynt/components/Form/FormLayout/FormLayout";
import {ROUTE_ID, ROUTE_PAGE} from "joynt/config";
import PageLayout from "./PageLayout";
import pages from "./pages";

export default function SettingsPageContainer(props) {

    const [id] = useRouteParam(ROUTE_ID);
    const transition = useRouteTransition(ROUTE_PAGE);
    const {id: pageStr, onClose} = transition;
    const [page, pageId] = pageStr ? pageStr.split('.') : [];
    const config = pages?.[page] || {};

    const {
        type,
        title,
        component: PageComponent,
        props: pageProps,
        infoLeft: LeftInfo,
        infoRight: RightInfo
    } = config;

    if (type === "container") return <PageComponent
        transition={transition}
        onClose={onClose}
        id={pageId || id}
        {...pageProps}
    />;

    if (type === "drawer") return <PageLayout {...transition} type={type}>
        <PageComponent
            id={pageId || id}
            onClose={onClose}
            {...pageProps}
        />
    </PageLayout>

    return <PageLayout {...transition} type={type}>
        {PageComponent && <FormLayout
            onReject={onClose}
            title={title}
            infoLeft={LeftInfo ? <LeftInfo /> : null}
            infoRight={RightInfo && <RightInfo />}
        >
            <PageComponent
                id={pageId || id}
                onClose={onClose}
                {...pageProps}
            />
        </FormLayout>}
    </PageLayout>;
}

SettingsPageContainer.defaultProps = {
    type: "page"
}

