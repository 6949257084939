import React from "react";
import moment from "moment";

export function MessageTime({value}) {
    if (!value) return null;
    let m = moment.unix(value);
    let daysSince = moment().startOf('day').diff(
        m.clone().startOf('day'),
        'days'
    );
    let moreThanDayAgo = daysSince > 0;
    let full = m.format('D MMM, HH:mm');
    if (moreThanDayAgo) {
        return <span title={full}>{m.format('DD MMM')}</span>;
    } else {
        return <span title={full}>{m.format('HH:mm')}</span>;
    }
}

export default function DateDiff({value, short}){
    if (!value) return null;

    let m = moment.unix(value);
    let full = m.format('D MMM, HH:mm');

    if (short) {
        let str = m.fromNow(true);
        return <span title={full}>{str.replace('minutes', 'min')
            .replace('months', 'm')
            .replace('hours', 'h')}</span>;
    }
    if (value) return <span title={full}>{m.fromNow()}</span>
    return null;
}