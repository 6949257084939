import React, { useCallback, useMemo, useState } from "react";

import useInterval from "util/hooks/useInterval";
import moment from "moment";
import { useMoment } from "joynt/components/DateTimePicker/hooks";

import messages from "./Timer.messages";

function formatTime(date) {
    let str = date.toISOString().substr(11, 8);

    if (str.indexOf("00:") === 0) {
        return str.substr(3, 6);
    }

    return str;
}

export default function Timer(props) {
    const {
        start,
        countdown,
        humanTimeThreshold,
        className,
        bareValue,
        countdownText,
    } = props;

    if (!start) return null;

    const diff = start - moment().unix();

    if (countdown) {
        if (diff <= 0)
            return <span className={className}>{messages.startingSoon}</span>;

        return (
            <Countdown
                countdownText={countdownText}
                bareValue={bareValue}
                className={className}
                start={start}
                humanTimeThreshold={humanTimeThreshold}
            />
        );
    }
    return <Elapsed start={start} className={className} />;
}

function Elapsed({ start, className }) {
    const value = useCallback(() => {
        const now = moment().unix();
        const duration = now - start;
        return formatTime(new Date(duration * 1000));
    }, [start]);

    const [elapsed, setElapsed] = useState(value());

    useInterval(() => {
        setElapsed(value());
    }, 1000);

    return <span className={className}>{elapsed}</span>;
}

function formatCountdownText(
    bareValue,
    secondsLeft,
    threshold,
    eventStartToDate
) {
    if (threshold && secondsLeft > threshold) {
        // event starts in more than hour, use relative time
        let countdownString = eventStartToDate.fromNow();

        if (bareValue) {
            const splitStr = countdownString.split(" ");
            splitStr.shift();
            return splitStr.join(" ");
        }

        return countdownString;
    }

    //event starts in less than an hour, use precise timer
    return formatTime(new Date(secondsLeft * 1000));
}

function Countdown(props) {
    const { start, humanTimeThreshold, className, bareValue, countdownText } =
        props;

    // default threshold for human-friendly times to 2hrs
    let threshold = humanTimeThreshold;
    const eventStartToDate = useMoment(start);

    const value = useCallback(() => {
        const now = moment().unix();
        return start - now;
    }, [start]);

    const [timeToEvent, setTimeToEvent] = useState(value());

    useInterval(
        () => {
            setTimeToEvent(value());
        },
        timeToEvent > 0 ? 1000 : null
    );

    const str = useMemo(() => {
        return formatCountdownText(
            bareValue,
            timeToEvent,
            threshold,
            eventStartToDate
        );
    }, [bareValue, timeToEvent, threshold, eventStartToDate]);

    if (timeToEvent <= 0)
        return <span className={className}>{messages.now}</span>;

    return (
        <span className={className}>
            {countdownText && countdownText + " "}
            {str}
        </span>
    );
}

Countdown.defaultProps = {
    humanTimeThreshold: 7200,
};

Timer.defaultProps = {
    countdown: false,
    humanTimeThreshold: false,
    countdownText: null,
};
