import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TasksBoard from "./TasksBoard";
import { useSelector } from "react-redux";
import { useActions, useApiActions } from "state/hooks";
import { change } from "state/actions/data";
import { submitOne } from "state/actions/api";
import { useCreatePost } from "joynt/hooks";
import { POST_TYPE_TEXT } from "joynt/config";
import { selectList } from "state/selectors/data";
import { BoardContext } from "./context";

function selectBoard(s, id, list) {
    const sourceItems = selectList(s, "db.nodes", list);
    const groups = ["backlog", "todo", "in-progress", "done"];

    const items = Object.values(
        sourceItems.sort((a, b) => {
            // let aPos = a.position !== undefined ? a.position : -1;
            // let bPos = b.position !== undefined ? b.position : -1;
            // if (aPos < bPos) return -1;
            // if (aPos > bPos) return 1;
            if (a.created_at > b.created_at) return -1;
            if (a.created_at < b.created_at) return 1;
            return 0;
        })
    );

    const columns = groups.map((group) => ({
        id: group,
        title: group,
        cards: items
            .filter((item) => {
                const status = item.task_status || "backlog";
                return status === group;
            })
            .map((item) => ({ id: item.id })),
    }));

    return { columns };
}

export function TasksBoardContainer(props) {
    const { id, list } = props;

    const board = useSelector((s) => selectBoard(s, id, list));

    const { onChange } = useActions({ onChange: change });
    const { onSubmit } = useApiActions({ onSubmit: submitOne });
    const [onCreate] = useCreatePost(id, list);

    const onDragEnd = (item, from, to) => {
        onChange("db.nodes", item.id, {
            task_status: to.toColumnId,
            position: to.toPosition,
        });
        onSubmit("db.nodes", item.id);
    };

    const create = useCallback(
        (column) => {
            onCreate(null, POST_TYPE_TEXT, true, {
                node_role: "task",
                task_status: column,
            });
        },
        [onCreate]
    );

    return (
        <BoardContext.Provider value={create}>
            <TasksBoard {...props} board={board} onDragEnd={onDragEnd} />
        </BoardContext.Provider>
    );
}

TasksBoardContainer.propTypes = {
    id: PropTypes.string,
};
TasksBoardContainer.defaultProps = {};

export default TasksBoardContainer;
