import React, { useState } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import { useApiActions, useData, useField } from "state/hooks";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import SelectList, {
    SelectListDivider,
    SelectListItem,
} from "playground/ui/SelectList";
import { useSelector } from "react-redux";
import Icon from "components/Icon";
import { normalizeType } from "cms/util/connect";
import { createStyleFrom, inlineStyle } from "cms/state/actions/cms";
import Button from "components/Button";
import PopoverInput from "playground/ui/PopoverInput";
import { normalizeStyleType } from "playground/theme/StyleEditor/util";

function StylesList(props) {
    const { type, onChange } = props;

    const styles = useSelector((state) => {
        return Object.keys(state?.data?.["cms.styles"] || {}).filter(
            (style) => style.indexOf(`${type}.`) > -1
        );
    });

    return (
        <div>
            {styles.map((style) => {
                return (
                    <SelectListItem key={style} onClick={() => onChange(style)}>
                        {style}
                    </SelectListItem>
                );
            })}
        </div>
    );
}

function CreateStyle(props) {
    const { styleType, onCreate, onClose } = props;
    const [name, setName] = useState(`${styleType}.`);

    const create = () => {
        onCreate(name);
        setName("");
        onClose();
    };

    return (
        <SelectList>
            <SelectListItem onClick={onClose}>Cancel</SelectListItem>
            <PopoverInput
                onChange={setName}
                value={name}
                placeholder={"Style name"}
                autoFocus={true}
            />
            <Button onClick={create}>Create style</Button>
        </SelectList>
    );
}

export function StyleSelect(props) {
    const { type, id, onEdit } = props;

    const [view, setView] = useState();
    const { value, onChange } = useField(type, id, "style");
    const { onChange: onChangeStyles } = useField(type, id, "styles");
    const { element_type } = useData(props);
    const styleType = normalizeStyleType(type, element_type || "section");
    const visibleValue = value || styleType;
    const parentStyle = value || styleType;

    const { onCreate, onInline } = useApiActions({
        onCreate: createStyleFrom,
        onInline: inlineStyle,
    });

    const create = (name) => {
        onCreate(type, id, name);
    };

    const inline = () => {
        onInline(type, id);
    };

    const changeView = (e, view) => {
        e.stopPropagation();
        setView(view);
    };

    return (
        <>
            <Popover
                trigger={({ onClick }) => (
                    <div
                        className={cn("component-style-select", {
                            "is-default": !value,
                        })}
                        onClick={onClick}
                    >
                        <div className={"component-style-select-value"}>
                            {visibleValue}
                        </div>
                        <Icon>mui-keyboard_arrow_down</Icon>
                    </div>
                )}
                content={({ onClose }) => (
                    <>
                        {view === "create" ? (
                            <CreateStyle
                                onClose={() => setView(null)}
                                styleType={styleType}
                                onCreate={create}
                            />
                        ) : (
                            <SelectList onClick={onClose}>
                                {!!value && (
                                    <>
                                        <SelectListItem
                                            onClick={() => onChange(null)}
                                        >
                                            Set to default
                                        </SelectListItem>
                                        <SelectListDivider />
                                    </>
                                )}
                                <div
                                    style={{
                                        maxHeight: "400px",
                                        overflow: "auto",
                                    }}
                                >
                                    <StylesList
                                        type={styleType}
                                        onChange={onChange}
                                    />
                                </div>
                                <SelectListDivider />
                                <SelectListItem
                                    onClick={() => onEdit(parentStyle)}
                                >
                                    Edit global <strong>{parentStyle}</strong>
                                </SelectListItem>
                                <SelectListItem
                                    onClick={() => onChangeStyles(null)}
                                >
                                    Reset all classes
                                </SelectListItem>
                                <SelectListItem
                                    onClick={(e) => changeView(e, "create")}
                                >
                                    Create new style from
                                </SelectListItem>
                                <SelectListItem onClick={inline}>
                                    Inline all classes
                                </SelectListItem>
                            </SelectList>
                        )}
                    </>
                )}
            />
        </>
    );
}

StyleSelect.propTypes = {};

StyleSelect.defaultProps = {};

export default StyleSelect;
