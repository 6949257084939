import React from "react";

//import PropTypes from 'prop-types';

export function Plan(props) {
    const {} = props;
    return <div>Type</div>;
}

Plan.propTypes = {};

Plan.defaultProps = {};

export default Plan;
