import React from 'react';
import {useData} from "state/hooks";
import {useNotificationAction, useNotifications} from "joynt/hooks/notifications";
import Toast from "joynt/notifications/Toast";
import NotificationDescription from "joynt/notifications/Description";
import NotificationArea from "joynt/notifications/NotificationArea";
import {icons, variants} from "./config";
//import PropTypes from 'prop-types';

function ToastNotification(props) {
    const {id, onHide} = props;
    const {onClick} = useNotificationAction(id);
    const {notification_type: type, count} = useData({type: 'db.notifications', id});

    return <Toast
        id={id}
        count={count}
        onClick={onClick}
        onHide={onHide}
        icon={icons[type] || 'mui-notifications_active'}
        variant={variants[type] || 'default'}
        description={<NotificationDescription id={id} count={count} />}
    />;
}

export default function Notifications(props) {

    const [items, onHide] = useNotifications();

    return (<div className={'toast-overlay'}>
        <NotificationArea>
            {items.map(id=><ToastNotification
                id={id}
                key={id}
                onHide={onHide}
            />)}
        </NotificationArea>
    </div>);

}

Notifications.propTypes = {};
Notifications.defaultProps = {};
