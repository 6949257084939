import React from 'react';
import PropTypes from 'prop-types';
import PostTags from "./PostTags";
import PostTagsEdit from "./PostTagsEdit";
import {useFeatureEnabled} from "joynt/hooks";
import {FEATURE_TAGS} from "joynt/config/features";

export function PostTagsContainer(props) {

    const {editable} = props;
    const tagsEnabled = useFeatureEnabled(FEATURE_TAGS);

    if (!tagsEnabled) return null;

    if (editable)
        return <PostTagsEdit {...props} />;

    return (<PostTags {...props} />);

}

PostTagsContainer.propTypes = {
    id: PropTypes.string,
    editable: PropTypes.bool
};

PostTagsContainer.defaultProps = {
    editable: false
};

export default PostTagsContainer;