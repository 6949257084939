import React from "react";
import cn from "classnames";
import Profiles from "joynt/participants/Profiles";
import { useNode } from "joynt/hooks";
import { NODE_TYPE_EVENT } from "joynt/config";
import Page from "joynt/views/Page";
import VideoPlayer from "joynt/components/VideoPlayer";
import AboutDashboardTile from "./AboutDashboardTile";
//import PropTypes from 'prop-types';

import "./style.css";
import { PromoTile } from "joynt/components/PromoTile";
import SessionStateInfo from "joynt/components/SessionStateInfo";

export function RolesInfo(props) {
    const { id } = props;

    const {
        event_speakers,
        event_moderators,
        visible_for,
        recipient_identities,
        subtype,
        identity,
    } = useNode(id);

    const parent = id;

    let speakers = event_speakers || [];
    let moderators = event_moderators || [];
    let admins = recipient_identities || [];
    let members = visible_for || [];

    if (admins.indexOf(identity) === -1) {
        admins = [identity].concat(admins);
    }

    const isEvent = subtype === NODE_TYPE_EVENT;

    const mapRoles = (list, role) => {
        return list.map((element) => {
            return {
                parent,
                id: element,
                role,
            };
        });
    };

    speakers = mapRoles(speakers, "speaker");
    moderators = mapRoles(moderators, "moderator");
    admins = mapRoles(admins, "admin");
    members = mapRoles(members, "collaborator");

    const eventRoles = [...speakers, ...moderators, ...admins, ...members];
    const workspaceRoles = [...admins, ...members];

    return isEvent ? (
        <AboutDashboardTile
            header={"Roles"}
            className={"col grow"}
            fullList={eventRoles}
        >
            <div className={"rows gap-5"}>
                <Profiles items={[...eventRoles].splice(0, 5)} />
            </div>
        </AboutDashboardTile>
    ) : (
        <AboutDashboardTile
            header={"Roles"}
            className={"col grow"}
            fullList={workspaceRoles}
        >
            <div className={"rows gap-5"}>
                <Profiles items={[...workspaceRoles].splice(0, 5)} />
            </div>
        </AboutDashboardTile>
    );
}

export default function About(props) {
    const { id } = props;
    const { content, event_record_uri, description, subtype } = useNode(id);
    const hasContent = content && content.blocks && content.blocks.length;
    const isEvent = subtype === NODE_TYPE_EVENT;

    return (
        <div className={"rows gap-md"}>
            {isEvent && <SessionStateInfo id={id} />}
            {event_record_uri ? (
                <div className={"gallery__preview h-min-md rows"}>
                    <VideoPlayer url={event_record_uri} />
                </div>
            ) : null}
            <div
                className={cn(
                    "about-view__tiles grid gap-4",
                    !hasContent && "about-view__tiles--no-description"
                )}
            >
                {hasContent ? (
                    <AboutDashboardTile
                        header={description}
                        className={"about-view__description"}
                    >
                        <Page {...props} />
                    </AboutDashboardTile>
                ) : null}
                <RolesInfo id={id} />
                <PromoTile
                    big
                    variant={"primary"}
                    label={"Explore the possibilities of your workspace!"}
                />
            </div>
        </div>
    );
}

About.propTypes = {};
About.defaultProps = {};
