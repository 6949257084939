import React from "react";
//import PropTypes from 'prop-types';

import Select from "components/fields/select/Select";
import IconButton from "components/IconButton";
import FontFamily from "./FontFamily";

import "./style.css";

export function TypographyFonts(props) {
    const { fonts, value, onChange, onBrowse, onImport } = props;

    return (
        <div className={"cols grow"}>
            <div className={"cols gap-xs grow"}>
                {fonts.map((id) => {
                    return (
                        <FontFamily
                            key={id}
                            label={id.replace("_font", "")}
                            value={value[id]}
                            onChange={(v) => onChange(id, v)}
                            onSelectFont={() => onBrowse(id)}
                        />
                    );
                })}
            </div>
            <Select
                source={"api:console.presets"}
                onChange={onImport}
                ValueComponent={({ onClick }) => (
                    <div onClick={onClick}>
                        <IconButton icon={"mui-search"} onClick={() => null} />
                    </div>
                )}
            />
        </div>
    );
}

TypographyFonts.propTypes = {};

TypographyFonts.defaultProps = {
    value: {},
    fonts: [],
};

export default TypographyFonts;
