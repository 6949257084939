import { useActions, useApiActions } from "state/hooks";
import { handleBrowserUpload } from "state/actions/upload";
import { entityDelete } from "state/actions/delete";
import {
    createDirectory,
    deleteDirectory,
    importMedia,
    saveMedia,
    syncMedia,
    selectMedia,
} from "state/actions/media";

export function useMediaActions() {
    const apiActions = useApiActions({
        onUploadMedia: handleBrowserUpload,
        onDeleteMedia: entityDelete,
        onCreateDirectory: createDirectory,
        onDeleteDirectory: deleteDirectory,
        onImportMedia: importMedia,
        onSyncMedia: syncMedia,
        onSaveMedia: saveMedia,
    });
    const { onSelect } = useActions({
        onSelect: selectMedia,
    });

    return { ...apiActions, onSelect };
}
