import "regenerator-runtime/runtime";

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {createBrowserHistory, createMemoryHistory} from 'history';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from './reducers';

import {getPersistedState, persistState} from "./helpers/local-storage";
import reduxDevOptions from './dev-config';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevOptions)
    : compose;

export const history = createBrowserHistory({
    basename: process.env.REACT_APP_BASENAME
});

export const memHistory = createMemoryHistory();

/**
 * Patch console in order to suppress annoying useLayoutEffect SSR
 * warnings while using React.renderToStaticMarkup in browser
 *
 * @param nativeConsole
 * @returns object
 */
function patchConsoleError(nativeConsole) {
    const newConsole = {...window.console};
    newConsole.error = function(message) {
        if (message.indexOf && message.indexOf('Warning: useLayoutEffect') > -1) {
        } else {
            nativeConsole.error(message);
        }
    };
    return newConsole;
}

window.console = patchConsoleError(window.console);

const configureStore = (initialState = {}, {reducers, sagas}) => {
    const sagaMiddleware = createSagaMiddleware();
    const preloadedState = {...getPersistedState(), ...initialState};
    const store = createStore(
        createRootReducer(reducers, history), // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                sagaMiddleware
            ),
        )
    );
    store.subscribe(()=>persistState(store.getState()));
    sagas.map(saga => sagaMiddleware.run(saga));
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }
    return {
        store,
        history
    };
};

export default configureStore;