import React from "react";
import Resources, { ResourceForm } from "playground/cms/views/Resources";
import { FormLayout, OverlayForm } from "playground/cms/Form";
import { useApiActions, useData, useRouteParam } from "state/hooks";
import Button from "components/Button";
import { submitWebsite } from "cms/state/actions/services";
import WebsiteForm from "./WebsiteForm";
import CreateWebsite from "./CreateWebsite";
import DestroyProgress from "./CreateWebsite/DestroyProgress";
import WebsiteDomains from "./WebsiteDomains";
import CmsSnapshots from "./CmsSnapshots";
import SchemaSettings from "./SchemaSettings";
import { ListLayout } from "playground/cms/List";
import { Search } from "playground/cms/List/Filter";
import UserAccess from "./UserAccess";
//import PropTypes from 'prop-types';

function WebsiteTheme(props) {
    const { id } = props;
    const { theme } = useData(props);

    if (!theme) return <div>Theme not assigned</div>;

    return (
        <ResourceForm type={"console.themes"} id={theme} disableFetch={true}>
            <FormLayout>
                <div>theme git goes here</div>
                <FormLayout.Header replace />
                <FormLayout.Footer replace />
            </FormLayout>
        </ResourceForm>
    );
}

function WebsiteCms(props) {
    const { id } = props;
    const { content_source } = useData(props);

    if (!content_source) return <div>Cms not assigned</div>;

    return (
        <>
            <ResourceForm
                type={"console.services.cms"}
                id={content_source}
                disableFetch={true}
            >
                <FormLayout>
                    <FormLayout.Header replace />
                    <FormLayout.Footer replace />
                </FormLayout>
            </ResourceForm>
        </>
    );
}

export function MakeWebsiteForm(props) {
    const { id, type, name, onClose, onSubmit } = props;
    return (
        <FormLayout>
            <FormLayout.Header prepend>
                <FormLayout.Title>{name}</FormLayout.Title>
            </FormLayout.Header>
            <FormLayout.Content prepend>
                <FormLayout.Tab id={"general"} label={"General"}>
                    {id && <WebsiteForm {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"access"} label={"Access"}>
                    {id && <UserAccess {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"domains"} label={"Domeny"}>
                    {id && <WebsiteDomains {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"schema"} label={"Schema"}>
                    {id && <SchemaSettings {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"snapshots"} label={"Snapshots"}>
                    {id && <CmsSnapshots {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"theme"} label={"Theme"}>
                    {id && <WebsiteTheme {...props} />}
                </FormLayout.Tab>
                <FormLayout.Tab id={"cms"} label={"Cms"}>
                    {id && <WebsiteCms {...props} />}
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer replace>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onSubmit(type, id)}>Submit</Button>
            </FormLayout.Footer>
        </FormLayout>
    );
}

export function Websites(props) {
    const { id, type, setId, onClose } = props;
    const { onSubmit } = useApiActions({
        onSubmit: submitWebsite,
    });
    const { name } = useData(props);

    const [createMode, setCreateMode] = useRouteParam("create");
    const create = () => setCreateMode(true);

    const destroyed = useData({ type: "app.destroy.website", id });
    const showId = destroyed.id ? null : id;

    return (
        <>
            <Resources {...props} id={showId} onCreate={create}>
                <ListLayout>
                    <ListLayout.Filter>
                        <Search type={type} name={"search"} />
                    </ListLayout.Filter>
                    <ListLayout.Header>
                        <ListLayout.Header.Icons prepend={true}>
                            <ListLayout.Header.Filter />
                        </ListLayout.Header.Icons>
                    </ListLayout.Header>
                </ListLayout>

                {MakeWebsiteForm({ ...props, id, name, onSubmit, onClose })}
            </Resources>
            <OverlayForm
                open={!!destroyed.id}
                onClose={onClose}
                position={"center"}
            >
                <DestroyProgress {...destroyed} onClose={onClose} />
            </OverlayForm>
            <OverlayForm
                open={!!createMode}
                onClose={() => setCreateMode(null)}
                position={"center"}
            >
                {createMode && (
                    <CreateWebsite onClose={() => setCreateMode(null)} />
                )}
            </OverlayForm>
        </>
    );
}

Websites.propTypes = {};

Websites.defaultProps = {};

export default Websites;
