import React from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";

import Button from "components/Button";
import Icon from "components/Icon";
import Zoom from "@material-ui/core/Zoom";
import SlideTransition from "joynt/components/LiveNow/SlideTransition";
import Badge from "@material-ui/core/Badge";

import "./style.css";

export function ChatButton(props) {
    const {
        onClick,
        id,
        message,
        show,
        MessageComponent,
        notifications,
        children,
    } = props;

    return (
        <div
            className={cn("chat-ticker", {
                "show-message": !!show,
            })}
        >
            <div className={"chat-ticker__message"}>
                <Zoom in={show}>
                    <div>
                        <div onClick={onClick} className={"chat-ticker-bubble"}>
                            {message && (
                                <SlideTransition
                                    id={id}
                                    current={message}
                                    Component={MessageComponent}
                                />
                            )}
                        </div>
                    </div>
                </Zoom>
            </div>
            <div className={"chat-ticker__button"}>
                <Badge badgeContent={notifications} color={"secondary"}>
                    <Button
                        startIcon={<Icon>mui-chat</Icon>}
                        onClick={onClick}
                        size={"large"}
                    >
                        {children}
                    </Button>
                </Badge>
            </div>
        </div>
    );
}

ChatButton.propTypes = {};
ChatButton.defaultProps = {};

export default ChatButton;
