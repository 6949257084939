import {createSelector} from 'reselect';

import {resolveAlias, selectEntity, selectFieldValue, selectList} from "state/selectors/data";
import {selectFilteredListData} from "state/selectors/lists";
import {selectLang} from "state/selectors/router";

const nullObj = {};

export const selectCurrentUserId = (store) => {
    return resolveAlias(store, 'db.users', '@currentUser');
};

export const matchUser = (store, id) => {
    return selectCurrentUserId(store) === id;
};

export const selectUserProfile = (store) => {
    const user = selectCurrentUserId(store);
    const profile = selectFieldValue(store, 'db.users', user, 'app_access_profile');
    return selectEntity(store, 'db.user-profiles', profile);
};

export const selectResourceId = (store, resource, id) => {
    if (!resource) return null;
    const types = selectList(store, 'db.types', 'db.types');
    //eslint-disable-next-line
    let [app, resourceType] = resource.split('.');

    let entity = id ? selectEntity(store, resource, id) : {};
    if (entity.subtype) return entity.subtype;

    let resolvedType = null;
    Object.values(types).forEach(type => {
        if (type.name === resourceType) resolvedType = type;
    });
    if (!resolvedType) return null;
    return resolvedType.id;
};

export const selectViewList = (store, viewType, resource, id) => {
    let typeId = selectResourceId(store, resource, id);

    const listData = selectList(store, viewType, viewType);
    const views = Object.values(listData).filter(view=>{
        return view.resource === typeId;
    });
    return views.map(view=>view.id);
};

export const selectFormViews = (store, resource, id) => {
    return selectViewList(store, 'db.form_views', resource, id);
};

export const selectListViews = (store, resource) => {
    return selectViewList(store, 'db.list_views', resource);
};

export const selectSubtypes = (store, type) => {
    const id = selectResourceId(store, type);
    return selectFilteredListData(store, 'db.types', (item => {
        return item.subtype_of === id;
    }));
};

export const selectAppApiContext = createSelector(
    (store, props) => props.apiContext,
    (store, props) => props.service,
    (store) => selectLang(store),
    (context, service, lang) => {
        let nextContext = context || nullObj;
        if (service) nextContext.project = service;
        if (lang) nextContext.lang = lang;
        return nextContext;
    }
);