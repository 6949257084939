import React from "react";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useList } from "state/hooks";

import NetworkingTable from "./Table";
import { useChannels } from "joynt/hooks/channels";
import { NODE_TYPE_SPACE } from "joynt/config";
import { useNodeData } from "joynt/hooks";
import { SESSION_TYPE_NETWORKING_TABLE } from "joynt/config/sessions";
import Button from "components/Button";

//import PropTypes from 'prop-types';

export function NetworkingTables(props) {
    const { id, isLive } = props;
    const { onJoinSession, onLeaveSession } = useMeeting();

    const list = `db.nodes.sessions.${id}`;

    const join = (id) => onJoinSession(id, false);

    const { can_edit } = useNodeData(id);
    const canJoin = isLive;

    const { onCreate, onEdit } = useChannels(id, list);

    const create = () => {
        onCreate({
            subtype: NODE_TYPE_SPACE,
            event_session_policy: "meeting",
            session_type: SESSION_TYPE_NETWORKING_TABLE,
            unfollow: true,
        });
    };

    const { items } = useList("db.nodes", list);

    return (
        <div className={"rows grow"}>
            <div className={"networking-layout"}>
                <div className={"networking-tables"}>
                    {items.map((item) => (
                        <NetworkingTable
                            id={item}
                            key={item}
                            isOpen={canJoin}
                            onJoin={join}
                            onLeave={onLeaveSession}
                        />
                    ))}
                    {can_edit && (
                        <div className={"networking-table create-table"}>
                            <div className={"create-table__seats"}>
                                <div
                                    className={"networking-table__empty-avatar"}
                                />
                                <div
                                    className={"networking-table__empty-avatar"}
                                />
                                <div
                                    className={"networking-table__empty-avatar"}
                                />
                                <div
                                    className={"networking-table__empty-avatar"}
                                />
                            </div>
                            <Button
                                variant={"outlined"}
                                className={"create-table__button"}
                                onClick={() => create()}
                            >
                                Add new table
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

NetworkingTables.propTypes = {};
NetworkingTables.defaultProps = {};

export default NetworkingTables;
