import React from "react";
import cn from "classnames";
import Field from "containers/Field";
import { useField } from "state/hooks";
import Popover from "components/Popover";
import SelectList, {
    SelectListDivider,
    SelectListItem,
} from "playground/ui/SelectList";
import { breakpoints } from "config/devices";
import IconButton from "components/IconButton";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function ComponentBreakpoints(props) {
    const {} = props;
    const { value, onChange } = useField(props.type, props.id, "breakpoints");
    const icon = value ? breakpoints[value]?.icon : "mui-devices";
    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton
                    icon={icon}
                    onClick={onClick}
                    size={"small"}
                    className={cn({
                        "o-30": !value,
                    })}
                />
            )}
            content={({ onClose }) => (
                <SelectList onClick={onClose}>
                    <SelectListItem
                        onClick={() => onChange(null)}
                        label="Wszystkie"
                        icon={<Icon>mui-close</Icon>}
                        isSelected={!value}
                    />
                    <SelectListDivider />
                    {Object.values(breakpoints).map((bp) => (
                        <SelectListItem
                            key={bp.id}
                            label={bp.label}
                            onClick={() => onChange(bp.id)}
                            icon={<Icon>{bp.icon}</Icon>}
                            isSelected={bp.id === value}
                        />
                    ))}
                </SelectList>
            )}
        />
    );
}

ComponentBreakpoints.propTypes = {};

ComponentBreakpoints.defaultProps = {};

export default ComponentBreakpoints;
