import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { StaticLink } from "./Features";

export function Layout(props) {
    return (
        <>
            {props.children}
            <Field id={"element_id"} label={"DOM ID"} />
            <StaticLink />
        </>
    );
}

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
