import {useState} from "react";
import {useDrop} from "react-dnd";
import {bindVideoToStage} from "joynt/meeting/MeetingOverlay";

export function useViewportArea() {
    const [pos, setPos] = useState(null);

    const [,drop] = useDrop({
        accept: 'float',
        hover: (item, monitor) => {
            const initialPointer = monitor.getInitialClientOffset();
            const initialSource = monitor.getInitialSourceClientOffset()
            const xDiff = initialPointer.x - initialSource.x;
            const yDiff = initialPointer.y - initialSource.y;
            const offset = monitor.getClientOffset();
            const view = document.querySelector('.view');
            const e = document.querySelector('.floating-session');

            const maxX = window.innerWidth - e.offsetWidth - 20;
            const maxY = view.offsetHeight - e.offsetHeight - 20;

            let x = Math.min(offset.x - xDiff, maxX);
            let y = Math.min(offset.y - yDiff, maxY);

            x = Math.max(x, view.offsetLeft);
            y = Math.max(y, 0);

            setPos({x, y});

            bindVideoToStage()
        }
    });

    return [pos, drop];
}