import { useApiActions } from "state/hooks/useApiActions";
import { postCallback } from "state/actions/api";
import { usePending } from "state/hooks/usePending";

export function useHttpRequest({ id, url, callback }) {
    const { onPost } = useApiActions({
        onPost: postCallback,
    });

    const handle = (data) => {
        onPost(callback, url, data, id);
    };

    const isPending = usePending(id);

    return [handle, isPending];
}
