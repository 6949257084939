import React, {useState} from 'react';
//import PropTypes from 'prop-types';

import Preloader from "components/Preloader";
import MuiList from "@material-ui/core/List";
import {withData} from "containers/Entity";
import {usePending, useRouteParam} from "state/hooks";
import Fetch from "containers/Fetch";
import List from "containers/List";
import {ListItemIterator} from "joynt/modals/NodeListItem";

function NodeInfo(props) {
    const { onClick, name, edges_ref } = props;
    const { parent, parent_node } = edges_ref;
    return <div className={'cols gap-sm'}>
        {parent_node
            ? <div onClick={()=>onClick(parent)}>
                {parent_node.name}
            </div>: null}
        <div>{name}</div>
    </div>
}

NodeInfo.autoFillProps = [
    'edges_ref',
    'name'
];

const NodeInfoWithData = withData(NodeInfo);

export default function NodeSelectDialog(props) {
    const list = 'db.nodes.find';
    const pending = usePending(['db.nodes', list].join('/'));
    const [boundary] = useRouteParam('select');
    const [workspace] = useRouteParam('id');

    const {onSelect} = props;
    const [parent, setParent] = useState(workspace || null);

    let url = `joynt/nodes/${parent}/find?`;
    if (boundary) url += `&graph-boundary=${boundary}`;

    return (<div className={'pad-sm w-md h-lg'}>
        <Preloader visible={pending} />
        <div>
            <NodeInfoWithData
                type={'db.nodes'}
                id={parent}
                onClick={setParent}
            />
        </div>
        <Fetch
            type={'db.nodes'}
            list={list}
            url={url} />
        <MuiList>
            <List
                type={'db.nodes'}
                list={list}
                Iterator={ListItemIterator}
                onClick={setParent}
                onSecondaryAction={onSelect}
                secondaryActionIcon={'mui-save_alt'}
                secondaryActionButton={true}
            />
        </MuiList>
    </div>);
}

NodeSelectDialog.propTypes = {};