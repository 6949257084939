import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import FormSection from "playground/designer/ComponentEditor/FormSection";

export function Event(props) {
    const {} = props;

    return (
        <FormSection label={"Śledzenie zdarzenia"}>
            <Field id={"event_name"} label={"Event name"} />
            <Field id={"event_param"} label={"Event param"} />
            <Field id={"event_label"} label={"Event label"} />
        </FormSection>
    );
}

Event.propTypes = {};

Event.defaultProps = {};

export default Event;
