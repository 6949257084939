import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconButton from "components/IconButton";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import resourceIcons from 'config/icons';

export const ResourceIconIterator = ArrayIterator(withData(({data,type,id,active,onClick}) => {
    let resourceId = data.path; //['db', data.name].join('.');
    const icon = data.icon || resourceIcons[resourceId];
    const isActive = active === resourceId;
    const handleClick = useCallback(()=>{
        onClick(resourceId)
    }, [resourceId, onClick]);
    return <ResourceIcon
        {...data}
        icon={icon}
        onClick={handleClick}
        isActive={isActive}
    />
}));

export default class ResourceIcon extends React.Component {

    render() {
        const { onClick, icon, isActive } = this.props;
        return (<div className={cn({'text-primary': isActive})}>
            <IconButton
                onClick={onClick}
                icon={icon}
                defaultIconType={'mui'}
                color={'inherit'}
            />
        </div>);
    }

}

ResourceIcon.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func
};