import React from "react";
import { connect } from "react-redux";
import { selectAllChanges } from "state/selectors/data";
import { withApiContext, bindApiActions } from "containers/context/ApiContext";

import "cms/ui/css/changes-submit.css";
import { save } from "cms/state/actions/cms";
import { isPending } from "state/selectors/ui";
import CircularProgress from "@material-ui/core/CircularProgress";
import { resetAll } from "state/actions/data";

import Button from "components/Button";
import IconButton from "components/IconButton";
import { compileStyles } from "cms/state/actions/theme";

const ignoreTypes = ["app.filters", "app.validation"];

const mapStateToProps = (store, props) => {
    const changes = selectAllChanges(store, ignoreTypes);
    const presetChanges = changes.filter(
        (item) => item.indexOf("console.presets") > -1
    );
    return {
        presetChanges: presetChanges.length,
        changed: changes.length,
        pending: isPending(store, "cms.save"),
    };
};

const mapDispatchToProps = bindApiActions(
    {
        onSubmit: save,
    },
    {
        onRevert: resetAll,
        onReloadTheme: compileStyles,
    }
);

class ChangesSubmit extends React.PureComponent {
    submit = () => {
        const { onSubmit, presetChanges, onReloadTheme } = this.props;
        onSubmit();
        if (presetChanges) onReloadTheme();
    };

    revert = () => {
        const { onRevert, presetChanges, onReloadTheme } = this.props;
        onRevert();
        if (presetChanges) onReloadTheme();
    };

    render() {
        const { changed, pending, presetChanges, onReloadTheme } = this.props;

        return (
            <>
                {this.props.children}
                {pending ? (
                    <div className={"changes-submit"}>
                        <CircularProgress />
                    </div>
                ) : null}
                {changed && !pending > 0 ? (
                    <div className={"cols cols-middle changes-submit"}>
                        <div>{changed} changes</div>
                        <IconButton icon={"mui-close"} onClick={this.revert} />
                        {presetChanges ? (
                            <IconButton
                                onClick={onReloadTheme}
                                icon={"mui-replay"}
                                label={"Reload theme"}
                            />
                        ) : null}
                        <Button onClick={this.submit}>Save</Button>
                    </div>
                ) : null}
            </>
        );
    }
}

export default withApiContext()(
    connect(mapStateToProps, mapDispatchToProps)(ChangesSubmit)
);
