import React from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import IconButton from "components/IconButton";
import Field from "containers/Field";
import Icon from "components/Icon";
import { selectPageUrl } from "cms/state/selectors/cms";

function usePageUrl(type, id) {
    return useSelector((store) => selectPageUrl(store, type, id, null));
}

const taggable = ["cms.entries.offers", "cms.entries.posts", "cms.products"];

export default function PageFormHeader(props) {
    const { onOpenPreview, onClose, type, children } = props;
    const url = usePageUrl(props.type, props.id);

    return (
        <div className={"page-form-header rows gap-sm"}>
            <div className={"cols cols-middle default-c500"}>
                <div className={"grow"}>
                    <Field
                        id={"title"}
                        variant={"inline"}
                        className={"page-title"}
                        label={"Tytuł podstrony"}
                        changeMode={"change"}
                    />
                    <div className={"slug-field cols"}>
                        <span className={"slug-domain cols cols-middle gap-px"}>
                            <Icon>mui-link</Icon>
                            <div>{url}/</div>
                        </span>
                        <Field
                            id={"slug"}
                            variant={"inline"}
                            className={"slug-edit"}
                            label={"adres url"}
                            changeMode={"change"}
                        />
                    </div>
                </div>
                <div className={"cols"}>
                    {onOpenPreview ? (
                        <IconButton
                            icon={"mui-open_in_new"}
                            onClick={onOpenPreview}
                        />
                    ) : null}
                    {onClose ? (
                        <IconButton icon={"mui-close"} onClick={onClose} />
                    ) : null}
                    {/*<IconButton icon={'mui-more_vert'} />*/}
                </div>
            </div>
            {taggable.includes(type) && (
                <Field
                    id={"tags"}
                    fieldType={"select"}
                    source={"api:tags"}
                    label={"Tagi"}
                    nullable={true}
                    multiple={true}
                    taggable={true}
                    variant={"inline"}
                />
            )}
            {children}
        </div>
    );
}

PageFormHeader.propTypes = {
    onOpenPreview: PropTypes.func,
};
