import React from "react";
//import PropTypes from 'prop-types';
import ItemForm from "./ItemForm";
import { useForm } from "state/hooks";
import { useFormContext } from "playground/cms/Form";
import AggregateRoot from "containers/AggregateRoot";
import { FormContext } from "playground/cms/Form/FormContext";

export function ItemFormContainer(props) {
    const { type, id, onClose } = props;
    const { onReject } = useForm(type, id, onClose);
    const { onSave } = useFormContext();
    const save = () => {
        onSave();
        onClose();
    };
    const context = {
        onCancel: onReject,
        onSave: save,
        onSubmit: save,
    };

    return (
        <AggregateRoot type={type} id={id} disableSubmit={true}>
            <FormContext.Provider value={context}>
                <ItemForm {...props} {...context} />
            </FormContext.Provider>
        </AggregateRoot>
    );
}

ItemFormContainer.propTypes = {};

ItemFormContainer.defaultProps = {};

export default ItemFormContainer;
