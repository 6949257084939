import React from "react";
//import PropTypes from 'prop-types';

import { useNotificationData } from "joynt/hooks/notifications";
import PostIdentity from "joynt/post/PostIdentity";
import { useData } from "state/hooks";
import stringReplace from "react-string-replace";
import moment from "moment";

import { descriptions } from "./descriptions";

function GroupNotification(props) {
    const { description, identities } = props;
    return (
        <div className={"rows gap-2"}>
            <div>{description}</div>
            <PostIdentity identities={identities} />
        </div>
    );
}

function PostNotification(props) {
    const { subject, identity, inParent } = props;
    return (
        <div className={"rows gap-2"}>
            <div>{subject}</div>
            <div>
                {identity} {inParent}
            </div>
        </div>
    );
}

const layouts = {
    "post-published": PostNotification,
    "message.comment.group": GroupNotification,
    "message.question.reply.group": GroupNotification,
};

function SystemNotification({ id, type }) {
    const { created } = useData({ type: "db.notifications", id });

    if (type === "app.node-roles-result") {
        return (
            <span>
                Congratulations! You’ve created your workspace!
                <br />
                Your invitation has been sent to {created} people.
            </span>
        );
    }
}

export default function Description(props) {
    const { id } = props;

    const {
        subjectName: resource,
        identityName: identity,
        parentName: name,
        link,
        type,
        count,
        identities,
        activity,
    } = useNotificationData(id);

    if (type && type.indexOf("app.") > -1) {
        return <SystemNotification id={id} type={type} />;
    }

    let descriptionType = count > 1 ? `${type}.multiple` : type;

    if (identities && identities.length > 1) {
        descriptionType = `${type}.group`;
    }

    const getVar = (key) => {
        const eventTime = activity?.data?.event_start;
        const vars = {
            identity: <strong>{identity}</strong>,
            parent: <strong>{name}</strong>,
            inParent: name ? (
                <span>
                    {" "}
                    in <strong>{name}</strong>
                </span>
            ) : null,
            subject: <strong>{resource}</strong>,
            count,
            type: descriptionType,
            link,
            eventTime: eventTime
                ? moment.unix(eventTime).format("DD MMM, HH:mm")
                : null,
            eventTimeDiff: eventTime ? moment.unix(eventTime).fromNow() : null,
        };
        return key ? vars[key] : vars;
    };

    const text =
        descriptions[descriptionType] ||
        descriptions[type] ||
        "{identity}: {type}";

    const replaceFn = (match, i) => {
        return getVar(match) || null;
    };

    const description = stringReplace(text, /{([A-Za-z]*)}/g, replaceFn);
    const LayoutComponent = layouts[descriptionType];

    if (LayoutComponent)
        return (
            <LayoutComponent
                description={description}
                identities={identities}
                {...getVar()}
            />
        );

    return <span>{description}</span>;
}

Description.propTypes = {};
Description.defaultProps = {};
