import React from "react";
import PropTypes from "prop-types";
import StageTile from "./StageTile";
import SessionCoverTile from "joynt/components/SessionCoverTile";

export function StageTileContainer(props) {
    const { image } = props;

    if (image) {
        return <SessionCoverTile {...props} />;
    }

    return <StageTile {...props} />;
}

StageTileContainer.propTypes = {
    id: PropTypes.string,
};
StageTileContainer.defaultProps = {};

export default StageTileContainer;
