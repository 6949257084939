import React from 'react';
import PropTypes from 'prop-types';

import RichText from "components/fields/RichText";
import Html from "components/Html";

import "css/quill.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

function RenderText(props) {
    const { value } = props;
    if (!value) return null;
    return (
        <Html
            className={"post-text"}
            value={value}
            data-private="lipsum"
        />
    );
}

export function Text(props) {

    const {id, editable, value, onChange, onUpload} = props;

    if (!editable && !value) return null;

    return editable ? (
        <RichText
            data-private="lipsum"
            entityType={"db.posts"}
            entityId={id}
            id={"content"}
            variant={"inline"}
            fieldType={"rich"}
            readOnly={!editable}
            label={"Write something..."}
            changeMode={"change"}
            submit={false}
            backend={"quill"}
            onUpload={onUpload}
            className={"post-text"}
            value={value}
            onChange={onChange}
        />
    ) : (
        <RenderText value={value} />
    );
}

Text.propTypes = {
    value: PropTypes.string,
    editable: PropTypes.bool
};
Text.defaultProps = {
    editable: false
};

export default Text;
