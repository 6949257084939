import React from "react";

import Joyride from "./Joyride";
import * as types from "./types";
import { useJoyride } from "joynt/components/Help/Joyride/hooks";
import PropTypes from "prop-types";

export default function JoyrideContainer(props) {
    const { id } = props;

    const { onComplete, shouldRun } = useJoyride(id);

    const steps = types[id];

    return <Joyride steps={steps} run={shouldRun} callback={onComplete} />;
}

JoyrideContainer.propTypes = {
    id: PropTypes.string.isRequired,
};
