import React from "react";

import IconButton from "joynt/components/IconButton";
import { mapTypeToIcon } from "joynt/components/Inbox";
import "./style.css";
import Divider from "@material-ui/core/Divider";

const filters = [
    {
        name: "all",
        label: "Messages",
    },
    {
        name: "private",
        label: "Direct",
    },
    {
        name: "channels",
        label: "Chats",
    },
    {
        name: "Divider",
    },
    {
        name: "comments",
        label: "Comments",
    },
];

const InboxFilters = ({ onChange, value }) => {
    return (
        <div className={"inbox__filters grow"}>
            {filters.map((filter) => {
                if (filter.name === "Divider") {
                    return <Divider orientation="vertical" variant="middle" />;
                }
                return (
                    <IconButton
                        key={filter.name}
                        selectable
                        selected={filter.name === value}
                        size={"medium"}
                        onClick={() => onChange(filter.name)}
                        icon={mapTypeToIcon(filter.name)}
                        label={filter.label}
                    />
                );
            })}
        </div>
    );
};

export default InboxFilters;
