import React from "react";
import Resources, { ResourceForm } from "playground/cms/views/Resources";
import { ListLayout } from "playground/cms/List";
import { FormLayout } from "playground/cms/Form";
import { Search } from "playground/cms/List/Filter";
import ThemeFormActions from "./ThemeFormActions";
import Form from "containers/Form";

//import PropTypes from 'prop-types';

export function Themes(props) {
    const { type } = props;
    return (
        <Resources {...props}>
            <ListLayout>
                <ListLayout.Filter>
                    <Search type={type} name={"search"} />
                </ListLayout.Filter>
                <ListLayout.Header>
                    <ListLayout.Header.Icons prepend={true}>
                        <ListLayout.Header.Filter />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
            </ListLayout>
            <FormLayout>
                <FormLayout.Content replace={true}>
                    <FormLayout.Tab id={"theme"} label={"Theme"}>
                        <FormLayout.Fieldset pad>
                            <ThemeFormActions {...props} />
                            <Form {...props} />
                        </FormLayout.Fieldset>
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Resources>
    );
}

Themes.propTypes = {};

Themes.defaultProps = {};

export default Themes;
