import {useUiFlag} from "state/hooks";
import {useCallback} from "react";

export default function useVideoSettings(id) {
    const [showSettings, onShowSettings] = useUiFlag('meetingSettings', false);

    const settingsId = `meeting.settingsConfirmed`;
    const [settingsConfirmed, setSettingsConfirmed] = useUiFlag(settingsId);

    const onAcceptSettings = useCallback(() => {
        onShowSettings(false);
    }, [onShowSettings]);

    const onResetSettings = useCallback(() => {
        setSettingsConfirmed(false);
    }, [setSettingsConfirmed])

    return {
        showSettings,
        settingsConfirmed,
        onShowSettings,
        onAcceptSettings,
        onResetSettings
    }
}