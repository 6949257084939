import React from "react";
//import PropTypes from 'prop-types';
import { useNodeData } from "joynt/hooks";
import { useClick } from "state/hooks";
import { useNotificationCount } from "joynt/hooks/notifications";
import { NODE_TYPE_CHAT, NODE_TYPE_EVENT } from "joynt/config";

import itemTypes from "./item-types";
import IconButton from "joynt/components/IconButton";
import { useEditPage } from "joynt/hooks/channels";
import useScreenSize from "util/hooks/useScreenSize";

function itemType({ workspace, subtype, node_type }) {
    if (node_type === "root") return "home";

    if (workspace) return subtype === NODE_TYPE_EVENT ? "event" : "workspace";

    if (subtype === NODE_TYPE_EVENT) return "session";

    if (subtype === NODE_TYPE_CHAT) return "chat";

    return "channel";
}

export function SidebarItemContainer(props) {
    const { id } = props;

    const { workspace, subtype, node_type, can_edit } = useNodeData(id);

    const variant = workspace ? "workspace" : "channel";
    const click = useClick(props);
    const onEdit = useEditPage();
    const edit = useClick({ onClick: onEdit, id });

    const [notifications, , mentions] = useNotificationCount(id);
    const { isMobile } = useScreenSize();
    const type = itemType({
        workspace,
        subtype,
        node_type,
    });

    const ItemComponent = itemTypes[type];

    return (
        <ItemComponent
            variant={variant}
            {...props}
            onClick={click}
            hasNotifications={!!notifications}
            mentions={mentions}
            editIcon={
                !isMobile && can_edit && type !== "home" ? (
                    <IconButton
                        icon={"mui-settings"}
                        size={"small"}
                        onClick={edit}
                    />
                ) : null
            }
        />
    );
}

SidebarItemContainer.propTypes = {};
SidebarItemContainer.defaultProps = {};

export default SidebarItemContainer;
