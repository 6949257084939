import React from "react";
import cn from "classnames";
import "./style.css";

//import PropTypes from 'prop-types';

export function BackToSession(props) {
    const { onClick, visible } = props;
    return (
        <div
            className={cn("back-to-session", {
                "is-visible": visible,
            })}
            onClick={onClick}
        >
            <div className={"back-to-session__label"}>Back to session</div>
        </div>
    );
}

BackToSession.propTypes = {};
BackToSession.defaultProps = {};

export default BackToSession;
