import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import { useWorkspaceId } from "joynt/hooks";
import { useIdentity, useUserPresence } from "joynt/hooks/participants";
import useProfileInteraction from "joynt/participants/hooks/useProfileInteraction";

export function AvatarContainer(props) {
    const { id } = props;

    const workspace = useWorkspaceId();

    const { created_by } = useIdentity(id);
    const presence = useUserPresence(created_by);

    const events = useProfileInteraction(workspace, id);

    return (
        <Avatar
            {...props}
            {...events}
            isOnline={true}
            isAway={!!presence.away}
        />
    );
}

AvatarContainer.propTypes = {
    id: PropTypes.string,
};
AvatarContainer.defaultProps = {};

export default AvatarContainer;
