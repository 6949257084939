import React from 'react';
//import PropTypes from 'prop-types';

import IconButtonGroup from "cms/ui/IconButtonGroup";

import inspectOptions from 'cms/ui/config/inspect-options';
import listOptions from 'cms/ui/config/list-options';
import settingsOptions from 'cms/ui/config/settings-options';

import InspectorLayout from "cms/ui/InspectorLayout";

import views from './views';
import EntityContext from "containers/context/EntityContext";

export default class Inspector extends React.PureComponent {

    render() {
        const {
            listView,
            inspectorView,
            onExit,
            onChangeInspectorView,
            onChangeListView,
            path,
            pending,
            devMode,
            ...other
        } = this.props;

        let primaryView = listView;

        if (!devMode && primaryView === 'tree') {
            primaryView = 'user-edit';
        }

        const PrimaryView = views[primaryView] || null;
        const SecondaryView = views[inspectorView] || null;

        const devProps = {
            primary: PrimaryView ? <PrimaryView {...other} /> : null,
            secondary: listView === 'tree' && SecondaryView
                ? <SecondaryView {...other} />
                : null,
            secondaryNav: <IconButtonGroup
                value={inspectorView}
                items={inspectOptions}
                onToggle={onChangeInspectorView}
            />
        };

        const userProps = {
            primary: PrimaryView ? <PrimaryView {...other} /> : null
        };

        const inspectorProps = devMode ? devProps : userProps;

        return <EntityContext.Provider value={path}>
            <InspectorLayout
                pending={pending}
                onExit={onExit}
                primaryNav={<div className={'cols gap-md'}>
                    <IconButtonGroup
                        value={listView}
                        items={listOptions}
                        onToggle={onChangeListView}
                    />
                    <IconButtonGroup
                        value={listView}
                        items={settingsOptions}
                        onToggle={onChangeListView}
                    />
                </div>}
                {...inspectorProps}
            />
        </EntityContext.Provider>;
    }

}

Inspector.propTypes = {};