export const bootstrap = (context) => (serviceType, bootstrapId, taskId) => {
    return {
        type: "APP.BOOTSTRAP",
        payload: {
            serviceType,
            bootstrapId,
            taskId,
        },
        context,
    };
};

export const invite = (context) => (email) => {
    return {
        type: "APP.INVITE",
        payload: {
            email,
        },
        context,
    };
};

export const resetAppState = () => ({
    type: "APP.STATE.RESET",
});
