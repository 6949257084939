import React from 'react';
import {IdentityAvatarContainer} from "joynt/components/Avatar";
import Icon from "components/Icon";
import {useNotificationData} from "joynt/hooks/notifications";
//import PropTypes from 'prop-types';

export default function Avatar({id, icon}) {
    const {identity} = useNotificationData(id);
    return <div className={'avatar-action'}>
        <IdentityAvatarContainer id={identity.id} size={'medium'} />
        <div className={'avatar-action__icon'}>
            <Icon>{icon}</Icon>
        </div>
    </div>;
}

Avatar.propTypes = {};
Avatar.defaultProps = {};
