import React from "react";
import GridFile from "joynt/components/Files/GridFile";
import Grid from "joynt/components/Files/Grid";
import List from "joynt/components/Files/List";
import ListFile from "joynt/components/Files/ListFile";
//import PropTypes from "prop-types";

export function File(props) {
    return <div>file</div>;
}

const config = {
    grid: {
        container: Grid,
        item: GridFile
    },
    list: {
        container: List,
        item: ListFile
    }
}

export default function AdaptiveFileList(props) {

    const {children, FileContainer} = props;

    let mode = 'list';

    // const count = React.Children.count(children);
    const types = React.Children.map(children, child => child.props.fileType);

    if (types.indexOf('document') === -1) mode = 'grid';

    const Container = config[mode].container;
    const Item = config[mode].item;

    const itemProps = FileContainer ? {Render: Item} : {};
    const ItemComponent = FileContainer || Item;

    return <Container>
        {React.Children.map(children, (child) => <ItemComponent
            {...child.props}
            {...itemProps}
        />)}
    </Container>;
}

AdaptiveFileList.defaultProps = {};
AdaptiveFileList.propTypes = {};