import React from 'react';
import ActivityFeed from "./ActivityFeed";
import ActivityLog from "./ActivityLog";
import Tab from "joynt/components/Tabs/Tab";
import {useListReload, useUiFlag} from "state/hooks";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

const views = {
    feed: {
        id: 'feed',
        label: 'Activity feed',
        view: ActivityFeed,
        type: 'db.nodes.activity.spaces'
    },
    log: {
        id: 'log',
        label: 'Activity log',
        view: ActivityLog,
        type: 'db.activity'
    }
}

export function ActivityView(props) {

    const {id} = props;
    const [viewType, setViewType] = useUiFlag('activity.viewType', 'feed');

    const view = views[viewType];
    const ViewComponent = view.view;

    const list = [view.type, id].join('.');

    const [onReload] = useListReload(view.type, list);

    return <div className={'rows gap-md'}>
        <div className={'cols cols-middle cols-center gap-xs'}>
            <IconButton icon={'mui-refresh'} onClick={onReload} />
            {Object.values(views).map(view => {
                return <Tab
                    key={view.id}
                    onClick={()=>setViewType(view.id)}
                    selected={viewType === view.id}
                >
                    {view.label}
                </Tab>
            })}
        </div>
        <ViewComponent
            {...props}
            list={list}
            type={view.type}
        />
    </div>;
}

ActivityView.propTypes = {};
ActivityView.defaultProps = {};
