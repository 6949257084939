import React from 'react';
import PropTypes from 'prop-types';

import {policies} from "joynt/edit/access/config";
import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "components/Popover";

export default function RoleSelectMenu(props) {
    const {onSelect, trigger, scope} = props;

    const options = policies[scope];

    const content = ({onClose}) => <MuiList onClick={onClose}>
        {Object.keys(options).map(opt => {
            return <ListItem
                key={`option-${opt}`}
                onClick={()=>onSelect(options[opt].id)}
                button
            >
                <ListItemText
                    primary={options[opt].label}
                    secondary={options[opt].description} />
            </ListItem>
        })}
    </MuiList>;

    return <Popover trigger={trigger} content={content} />;
}

RoleSelectMenu.propTypes = {
    scope: PropTypes.string.isRequired
};
RoleSelectMenu.defaultProps = {
    scope: 'roles'
};
