import React from 'react';
import {ParticipantList} from "joynt/participants";
import {useMeetingSession} from "joynt/meeting/MeetingContainer";
import {useRouteParam} from "state/hooks";
import {ROUTE_ID} from "joynt/config";

//import PropTypes from 'prop-types';

export function People(props) {

    const [workspace] = useRouteParam(ROUTE_ID);
    const {session, event} = useMeetingSession();

    return (
        <ParticipantList
            parent={workspace}
            id={session}
            event={event || session}
        />
    );

}

People.propTypes = {};
People.defaultProps = {};

export default People;
