import React from "react";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

const views = {
    grid: { icon: "mui-view_module" },
    gallery: { icon: "mui-view_module" },
    list: { icon: "mui-view_list" },
};

export function ListViewFilter(props) {
    const { value, onChange } = props;
    return (
        <>
            {Object.keys(views).map((view) => {
                const { icon } = views[view];
                return (
                    <IconButton
                        key={view}
                        icon={icon}
                        onClick={() => onChange(view)}
                        checked={value === view}
                    />
                );
            })}
        </>
    );
}

ListViewFilter.propTypes = {};

ListViewFilter.defaultProps = {};

export default ListViewFilter;
