import React from "react";
//import PropTypes from 'prop-types';
import Designer from "./Designer";
import { EditorStateProvider } from "./EditorState";
import { DebugConsoleProvider } from "playground/debug";

export function DesignerContainer(props) {
    const {} = props;
    return (
        <EditorStateProvider>
            <DebugConsoleProvider>
                <Designer />
            </DebugConsoleProvider>
        </EditorStateProvider>
    );
}

DesignerContainer.propTypes = {};

DesignerContainer.defaultProps = {};

export default DesignerContainer;
