import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import IconButton from "components/IconButton";
import "./style.css";

export function SessionHeader(props) {
    const {
        name,
        timer,
        avatar,
        controls,
        parentName,
        isExpanded,
        onToggleExpand,
        isDesktop,
        isMobile,
        centered,
        back,
    } = props;

    return (
        <div
            className={cn("session-header", {
                "is-expanded": isExpanded,
                centered: centered,
            })}
        >
            {centered && isDesktop && (
                <div className={"session-header__back"}>{back}</div>
            )}
            <div className={"session-header__content"}>
                <div
                    className={"session-header__avatar"}
                    onClick={onToggleExpand}
                >
                    {avatar}
                </div>
                <div className={"session-header__text"}>
                    <div className={"session-header__name"}>{name}</div>
                    <div className={"session-header__description"}>
                        {parentName}
                    </div>
                </div>
            </div>
            <div className={"session-header__controls"}>
                {controls}
                {!isMobile && (
                    <IconButton
                        onClick={onToggleExpand}
                        icon={
                            isExpanded
                                ? "mui-close_fullscreen"
                                : "mui-open_in_full"
                        }
                        size={"small"}
                    />
                )}
                <div className={"session-header__timer"}>{timer}</div>
            </div>
        </div>
    );
}

SessionHeader.propTypes = {
    isExpanded: PropTypes.bool,
    centered: PropTypes.bool,
};
SessionHeader.defaultProps = {
    isExpanded: false,
    centered: false,
};

export default SessionHeader;
