import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';
import GitStatus from "cms/ui/theme/GitStatus";
import EntityContext from "containers/context/EntityContext";
import Field from "containers/Field";
import Button from "components/Button";
import IconButton from "components/IconButton";
import List from "containers/List";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import Preloader from "components/Preloader";

function VcsLogEntry(props) {
    const {
        hash,
        message,
        onClick
    } = props;

    const click = useCallback(()=>onClick(hash), [onClick,hash]);

    return <div className={'cols gap-sm'} onClick={click}>
        <div>{hash}</div>
        <div>{message}</div>
    </div>
}

VcsLogEntry.autoFillProps = [
    'hash',
    'message'
];

const VcsLogIterator = ArrayIterator(withData(VcsLogEntry));

export default class Github extends React.PureComponent {

    render() {
        const {
            pending,
            status,
            repository,
            message,
            log,
            onReload,
            onSubmit,
            onOpenCommit,
            onOpenRepository,
            onPush,
            onPull,
            onInit
        } = this.props;

        return (<div>
            <Preloader visible={pending} />
            <div className={'pad-sm'} onClick={onOpenRepository}>
                <h3>{repository}</h3>
            </div>
            <GitStatus status={status}>
                <div className={'cols cols-middle'}>
                    <IconButton icon={'mui-refresh'} onClick={onReload} />
                    { status !== 99 ? <>
                        <IconButton icon={'mui-cloud_upload'} onClick={onPush} />
                        <IconButton icon={'mui-cloud_download'} onClick={onPull} />
                    </> : <Button onClick={onInit}>connect</Button> }
                </div>
            </GitStatus>
            <div className={'pad-sm rows gap-md'}>
            { status === 1 ? <EntityContext.Provider value={'@app.forms/theme-commit'}>
                <div className={'cols gap-xs'}>
                    <div className={'grow'}>
                        <Field id={'commit_message'} label={"Commit message"} fieldType={'text'} />
                    </div>
                    <div>
                        <Button onClick={onSubmit}>commit</Button>
                    </div>
                </div>
            </EntityContext.Provider> : null }
            <div className={'rows'}>
                <List
                    type={'theme.vcs-log'}
                    items={log}
                    Iterator={VcsLogIterator}
                    onClick={onOpenCommit}
                />
            </div>
            <pre style={{ whiteSpace: 'pre-wrap', fontSize: '10px' }}
                 dangerouslySetInnerHTML={{__html: message }}
            />
            </div>
        </div>);
    }

}

Github.propTypes = {};