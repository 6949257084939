import React from "react";
import { useApiActions, usePending, useRouteParam } from "state/hooks";
import Dialog from "@material-ui/core/Dialog";
import { apartmentsImport } from "cms/state/actions/apartments";
import Button from "components/Button";
import Preloader from "components/Preloader";

//import PropTypes from 'prop-types';

function ApartmentsImport(props) {
    const { id } = props;
    const { onImport } = useApiActions({ onImport: apartmentsImport });
    const isPending = usePending(id);
    return (
        <div className={"pad-lg"}>
            <Preloader visible={isPending} />
            <div className={"cols gap-xs"}>
                <Button onClick={() => onImport(id)}>Preview</Button>
                <Button onClick={() => onImport(id, true)}>Import</Button>
            </div>
        </div>
    );
}

export function ApartmentsImportDialog(props) {
    //const {} = props;
    const [id, setOpen] = useRouteParam("apartments-import");

    return (
        <Dialog open={!!id} onClose={() => setOpen(null)}>
            <ApartmentsImport id={id} />
        </Dialog>
    );
}

ApartmentsImportDialog.propTypes = {};
ApartmentsImportDialog.defaultProps = {};

export default ApartmentsImportDialog;
