import { useCurrentService } from "playground/cms/hooks";
import { useSelector } from "react-redux";
import { selectWebsiteCanonicalHost } from "cms/state/selectors/cms";
import { useData } from "state/hooks";
import { useMemo } from "react";

export function usePageUrl(type, id) {
    const website = useCurrentService();
    const host = useSelector((store) =>
        selectWebsiteCanonicalHost(store, website.id)
    );
    const { slug } = useData({ type, id });

    return useMemo(
        () => ({
            host,
            slug,
        }),
        [host, slug]
    );
}
