import React, { useState } from "react";
import RolesList from "joynt/components/Roles/RolesList";
import { useAccessSettings, useRoleEdit } from "joynt/components/Roles/hooks";
import { useSelector } from "react-redux";
import { selectAccessProfile } from "joynt/components/Roles/state/selectors";
import { useEdges, useFeatureEnabled } from "joynt/hooks";
import NodeRoleGroup from "./NodeRoleGroup";
import { usePending } from "state/hooks";
import Preloader from "components/Preloader";
import IconButton from "components/IconButton";
import SearchInput from "./../SearchInput";
import AssignAccessRoles from "joynt/components/Roles/NodeRoles/AssignAccessRoles";
import { FEATURE_WORKSPACE_ROLES } from "joynt/config/features";

//import PropTypes from 'prop-types';

export function ManageRoles(props) {
    const { id, list, scope } = props;

    const {
        onRemoveRole,
        onRevertRole,
        onChangeRole,
        //onAddEmail,
        //onAddRole,
    } = useRoleEdit(list, "member");

    const profile = useSelector((store) => selectAccessProfile(store, id));
    const { type, onChange } = useAccessSettings(id);
    const { parent } = useEdges(id);
    const isPending = usePending(list);

    const inheritsAdmins = profile === "public" || type === "admins";
    const [showInherited, setShowInherited] = useState(false);

    const enableWorkspaceRoles = useFeatureEnabled(FEATURE_WORKSPACE_ROLES);

    const inheritedRoles = (
        <RolesList
            id={id}
            list={list}
            scope={scope}
            type={"app.node-roles"}
            groupBy={"inheritedAssignedGroups"}
            onRemove={onRemoveRole}
            onRevert={onRevertRole}
            onChangeRole={onChangeRole}
        />
    );

    return (
        <div className={"manage-roles"}>
            {profile === "private" && <SearchInput id={id} list={list} />}
            {enableWorkspaceRoles && (
                <AssignAccessRoles id={id} assigned={true} />
            )}
            <RolesList
                id={id}
                list={list}
                scope={scope}
                type={"app.node-roles"}
                groupBy={"assignedGroups"}
                onRemove={onRemoveRole}
                onRevert={onRevertRole}
                onChangeRole={onChangeRole}
            />

            {inheritsAdmins && (
                <NodeRoleGroup
                    id={parent}
                    nameTemplate={
                        type === "admins"
                            ? "Admins and collaborators of :name"
                            : "Everyone from :name"
                    }
                    role={
                        <div>
                            {type === "admins" && (
                                <IconButton
                                    onClick={() => onChange("private")}
                                    icon={"mui-delete"}
                                    size={"small"}
                                />
                            )}
                            <IconButton
                                onClick={() => setShowInherited(!showInherited)}
                                icon={
                                    showInherited
                                        ? "mui-keyboard_arrow_up"
                                        : "mui-keyboard_arrow_down"
                                }
                                size={"small"}
                            />
                        </div>
                    }
                />
            )}

            {inheritsAdmins && showInherited ? inheritedRoles : null}
            <Preloader visible={isPending} />
        </div>
    );
}

ManageRoles.propTypes = {};
ManageRoles.defaultProps = {
    groupBy: "workspaceGroups",
    scope: "admins",
};

export default ManageRoles;
