import { takeEvery, put, select, all, call } from "redux-saga/effects";
import { request } from "util/api/client";
import { fetch } from "state/actions/api";
import { selectEntity, selectList } from "state/selectors/data";
import {
    selectWebsiteChanges,
    selectWebsiteData,
} from "cms/state/selectors/services";
import { notify, pending } from "state/actions/ui";
import {
    change,
    reset,
    resetTypes,
    storeBatch,
    storeOne,
} from "state/actions/data";
import { confirm, success, error } from "util/saga/feedback";
import { deleteSuccessBatch } from "state/actions/delete";
import { bootstrap } from "state/actions/bootstrap";
import { listAppend } from "state/actions/list";

function* handleCreateSnapshot({ payload, context }) {
    const { id } = payload;

    try {
        yield put(pending(`${id}.dump`, true));
        const response = yield request({
            url: `console/cms/${id}/dump`,
            method: "get",
            context,
        });
        yield put(notify(`Snapshot created`, "success"));
        yield put(
            fetch(context)(
                "console.cms.snapshots",
                id,
                `console/cms/${id}/snapshots`
            )
        );
        yield put(pending(`${id}.dump`, false));
    } catch (e) {
        yield put(pending(`${id}.dump`, false));
        console.error(e);
    }
}

function* handleRestoreSnapshot({ payload, context }) {
    const { id, from } = payload;

    try {
        if (!id) throw new Error("Target id not provided");
        if (!from) throw new Error("From path not provided");

        yield put(pending(`${id}.restore`, true));

        const response = yield request({
            url: `console/cms/${id}/restore?from=${from}`,
            method: "get",
            context,
        });

        yield put(notify(`Restored from ${from}`, "success"));

        yield put(pending(`${id}.restore`, false));

        yield put(
            fetch(context)(
                "console.cms.snapshots",
                id,
                `console/cms/${id}/snapshots`
            )
        );
    } catch (e) {
        if (id) {
            yield put(pending(`${id}.restore`, false));
        }
        yield put(notify(e.message, "error"));
        console.error(e);
    }
}

function* handleSubmitWebsite({ payload, context }) {
    const { type, id } = payload;
    try {
        const changes = yield select((state) =>
            selectWebsiteChanges(state, type, id)
        );
        /** Normalize type id **/
        if (type === "console.services") {
            changes["console.services.website"] = changes["console.services"];
            delete changes["console.services"];
        }
        yield put(pending(id, true));
        const response = yield request({
            url: `console/websites/${id}`,
            method: "post",
            data: changes,
            context,
        });
        if (changes["console.schema-config"]) {
            const config = yield select((state) =>
                selectEntity(state, "console.schema-config", id)
            );
            yield postSchemaUpdate({ payload: { id, config }, context });
        }
        yield put(pending(id, false));
        console.log(changes, response);
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

function* handleExportWebsite({ payload, context }) {
    const { id } = payload;
    try {
        if (!id) return;
        yield put(pending("create-website", true));
        const response = yield request({
            url: `console/websites/${id}/export`,
            method: "get",
            context,
        });
        const data = response.data.data;
        yield put(storeOne("app.create.website", "create-website", data));
        yield put(pending("create-website", false));
        console.log(data);
    } catch (e) {
        yield put(pending("create-website", false));
        console.error(e);
    }
}

function* handleSubmitCreateWebsite({ payload, context }) {
    const { id } = payload;
    const type = "app.create.website";
    try {
        const createData = yield select((state) =>
            selectEntity(state, type, id)
        );
        const requestData = {
            "console.services.website": createData.website,
            "console.presets": createData.preset,
            "console.themes": createData.theme,
            "console.services.cms": createData.cms,
            options: createData.options,
        };
        yield put(pending(id, true));
        const response = yield request({
            url: `console/websites/create`,
            method: "post",
            data: requestData,
            context,
        });
        yield put(pending(id, false));
        yield put(change(type, id, { status: "pending" }));
        const data = response.data.data;
        yield put(
            change(type, id, {
                status: "success",
                website: data?.["console.services.website"]?.[0].id,
                domain: data?.["console.domains"]?.[0].id,
            })
        );
        yield put(storeBatch(data, {}));
    } catch (e) {
        yield put(pending(id, false));
        yield put(
            change(type, id, {
                status: "error",
                error: e.message,
            })
        );
        console.error(e);
    }
}

function* handleDestroyWebsite({ payload, context }) {
    try {
        const { id } = payload;
        if (!id) {
            alert("No website selected");
            return;
        }
        if (yield confirm("Are you sure you want to delete this website?")) {
            const response = yield request({
                url: `console/websites/${id}/purge`,
                method: "post",
                context,
            });
            yield put(
                storeOne("app.destroy.website", id, {
                    id,
                    resources: response.data.data,
                })
            );
            yield put(deleteSuccessBatch(response.data.data));
        }
    } catch (e) {
        console.error(e);
    }
}

function* postSchemaUpdate({ payload, context }) {
    const { id, config } = payload;
    try {
        yield put(notify(`Schema updated`, "success"));
        yield put(
            resetTypes(
                [
                    "schema.db",
                    "schema.data.value",
                    "db.fields",
                    "schema.data",
                    "app.user-menu",
                    "app.user-menu-groups",
                ],
                true
            )
        );
        yield put(reset("console.schema-config", id));
        if (config) {
            yield put(storeOne("console.schema-config", id, config));
        }
        yield put(bootstrap(context)("website", id, id));
    } catch (e) {
        console.error(e);
    }
}

function* handleUpdateSchemaConfig({ payload, context }) {
    const { id, data } = payload;
    try {
        const response = yield request({
            url: `console/websites/${id}/schema`,
            method: "post",
            data,
            context,
        });
        yield postSchemaUpdate({
            payload: { id, config: response.data.data },
            context,
        });
    } catch (e) {
        console.error(e);
    }
}

function* grantServiceAccess({ payload, context }) {
    const { id, email } = payload;
    try {
        const type = yield "console.user-service-access";
        const response = yield request({
            url: `console/user-service-access/invite`,
            method: "post",
            data: { service: id, email },
            context,
        });
        const data = response?.data?.data || {};
        yield put(storeOne(type, data.id, data));
        yield put(listAppend(type, type, data.id));
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("CMS.SNAPSHOTS.CREATE", handleCreateSnapshot);
    yield takeEvery("CMS.SNAPSHOTS.RESTORE", handleRestoreSnapshot);
    yield takeEvery("CMS.WEBSITES.SUBMIT", handleSubmitWebsite);
    yield takeEvery("CMS.WEBSITES.CREATE.EXPORT", handleExportWebsite);
    yield takeEvery("CMS.WEBSITES.CREATE.SUBMIT", handleSubmitCreateWebsite);
    yield takeEvery("CMS.WEBSITES.DESTROY", handleDestroyWebsite);
    yield takeEvery("CMS.WEBSITES.SCHEMA.UPDATE", handleUpdateSchemaConfig);
    yield takeEvery("CMS.WEBSITES.ACCESS.GRANT", grantServiceAccess);
}
