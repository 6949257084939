import React from 'react';
import {connect} from 'react-redux';
import IdeNav from "cms/ui/IdeNav";
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";

const mapStateToProps = (store, props) => {
    return {
        display: selectRouteParam(store, 'display'),
        device: selectRouteParam(store, 'device'),
        devMode: selectRouteParam(store, 'dev')
    }
};

const mapDispatchToProps = {
    pushRouteParams: pushRouteParams
};

class IdeNavContainer extends React.Component {

    toggleDisplay = (value) => {
        const { display, pushRouteParams } = this.props;
        let prev = display ? display.split(',') : [];
        let next = prev.slice();
        if (prev.indexOf(value) === -1) {
            next.push(value);
        } else {
            next = next.filter(v=>v!==value);
        }
        pushRouteParams({display: next.join(',')});
    };

    toggleDevice = (value) => {
        const { pushRouteParams } = this.props;
        pushRouteParams({device: value});
    };

    toggleView = (value) => {
        const { pushRouteParams } = this.props;
        pushRouteParams({list: value});
    };

    toggleDev = (value) => {
        const { pushRouteParams } = this.props;
        pushRouteParams({dev: value ? value : null});
    }

    render() {
        return (<IdeNav
            {...this.props}
            onToggleDisplay={this.toggleDisplay}
            onToggleDevice={this.toggleDevice}
            onToggleView={this.toggleView}
            onToggleDev={this.toggleDev}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IdeNavContainer);