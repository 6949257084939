import React from "react";
import Button from "components/Button";
import FieldLayout from "joynt/components/Form/FieldLayout";
import { useEntity } from "state/hooks";
import { useDeleteNode, useMoveNode } from "joynt/hooks/channels";
import Field from "containers/Field";
import SelectField from "joynt/edit/SelectField";
import { types as nodeTypes } from "joynt/config/types";
import { useFeatureEnabled, useNode } from "joynt/hooks";
import { NODE_TYPE_EVENT, NODE_TYPE_SPACE } from "joynt/config";
import { FEATURE_DEV } from "joynt/config/features";
//import PropTypes from 'prop-types';

export function DangerZone(props) {
    const { id } = useEntity();
    const onDelete = useDeleteNode(id);
    const onMoveNode = useMoveNode(id);
    const { subtype, node_type } = useNode(id);
    const workspace = [NODE_TYPE_EVENT, NODE_TYPE_SPACE].indexOf(subtype) > -1;
    const isDev = useFeatureEnabled(FEATURE_DEV);
    const isRoot = node_type === "root";

    return (
        <>
            {!isRoot ? (
                <FieldLayout
                    name={"Danger zone"}
                    description={"Delete or change parent node"}
                >
                    <div className={"cols gap-xs"}>
                        {isDev && onMoveNode ? (
                            <Button onClick={onMoveNode} color={"default"}>
                                Move
                            </Button>
                        ) : null}
                        {onDelete ? (
                            <Button onClick={onDelete} color={"default"}>
                                Delete
                            </Button>
                        ) : null}
                    </div>
                </FieldLayout>
            ) : null}
            {isDev ? (
                <FieldLayout
                    name={"Node type"}
                    description={"Visible only for joynt admins"}
                >
                    <div className={"cols gap-sm"}>
                        <Field id={"subtype"}>
                            {({ onChange, value }) => (
                                <SelectField
                                    onChange={onChange}
                                    value={value}
                                    options={nodeTypes}
                                />
                            )}
                        </Field>
                        {workspace && !isRoot ? (
                            <Field
                                id={"workspace"}
                                label={"Workspace"}
                                fieldType={"checkbox"}
                            />
                        ) : null}
                    </div>
                </FieldLayout>
            ) : null}
        </>
    );
}

DangerZone.propTypes = {};
DangerZone.defaultProps = {};
