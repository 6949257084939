import React from "react";
//import PropTypes from "prop-types";

import "./grid.css";

export default function Grid(props) {
    const { children } = props;

    // const types = React.Children.map(children, child => child.props.type);

    return (
        <div
            className={"file-grid"}
            data-count={React.Children.count(children)}
        >
            {children}
        </div>
    );
}

Grid.defaultProps = {};
Grid.propTypes = {};
