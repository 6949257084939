import React from "react";
import { useRouteParam } from "state/hooks";
import SectionForm from "./SectionForm";
import SectionPreview from "./SectionPreview";
import { OverlayForm } from "playground/cms/Form";
import SectionBrowser from "./SectionBrowser";
import DragSectionPrompt from "./DragSectionPrompt";

//import PropTypes from 'prop-types';

export function SectionOverlayForm(props) {
    const { onClose, id, isBrowser, onCloseBrowser, browser, pageType } = props;
    const [preview] = useRouteParam("sectionPreview");

    return (
        <>
            <OverlayForm
                onClose={onClose}
                open={!!id}
                width={preview ? "wide" : null}
            >
                {id && !preview && (
                    <SectionForm
                        type={"cms.sections"}
                        id={id}
                        onClose={onClose}
                    />
                )}
                {id && preview && <SectionPreview id={id} onClose={onClose} />}
            </OverlayForm>
            {!isBrowser && (
                <>
                    <SectionBrowser
                        open={browser}
                        pageType={pageType}
                        onClose={onCloseBrowser}
                    />
                    <DragSectionPrompt />
                </>
            )}
        </>
    );
}

SectionOverlayForm.propTypes = {};

SectionOverlayForm.defaultProps = {};

export default SectionOverlayForm;
