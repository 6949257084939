import React from "react";
import useStreamPagination from "joynt/meeting/hooks/useStreamPagination";
import { PresentationView } from "joynt/meeting/presentation";
import AudioStreams from "joynt/meeting/stage/AudioStreams";
import VolumeLevels from "joynt/meeting/stage/VolumeLevels";
//import PropTypes from 'prop-types';

import "./style.css";
import { useRaisedHandList } from "joynt/meeting/hooks/useRaisedHandList";
import StagePagination from "joynt/meeting/stage/StagePagination";

function isPublishing(user) {
    return user.videoTrack || user.hasVideo || user.audioTrack;
}

export default function Streams(props) {
    const {
        channel,
        user,
        users: allUsers,
        limit,
        focus,
        layout,
        showAll,
        quality,
        component,
        node,
        showAudioStreams,
    } = props;

    const VideoTile = component;

    const {
        id: pageUserId,
        items: users,
        page,
        pages,
        prevPage,
        nextPage,
    } = useStreamPagination(allUsers, limit, layout !== "grid" ? focus : null);

    const hands = useRaisedHandList(channel);

    return (
        <VolumeLevels>
            {node && (
                <div className={"video-tile focus"}>
                    <PresentationView id={node} />
                </div>
            )}
            {users.map((stream) => {
                let userId = stream.uid.toString();
                let isScreen = userId.indexOf("screen_") > -1;
                if (!isPublishing(stream) && !showAll && !isScreen) return null;
                let isFocused = !node && userId === focus;
                return (
                    <VideoTile
                        hand={hands.includes(userId)}
                        local={userId === user}
                        own={userId === user}
                        key={userId}
                        user={stream}
                        focus={isFocused}
                        quality={isFocused ? 0 : quality}
                    />
                );
            })}
            {pages > 1 && (
                <StagePagination
                    id={pageUserId}
                    page={page}
                    pages={pages}
                    onPrev={prevPage}
                    onNext={nextPage}
                />
            )}
            <AudioStreams
                user={user}
                users={allUsers}
                visible={showAudioStreams}
            />
        </VolumeLevels>
    );
}

Streams.propTypes = {};
Streams.defaultProps = {};
