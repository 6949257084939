import React from "react";
//import PropTypes from 'prop-types';

import { useData, useField } from "state/hooks";
import StyleEditor from "playground/theme/StyleEditor";
import { normalizeStyleType } from "playground/theme/StyleEditor/util";

export function ComponentStyles(props) {
    const { state, setState } = props;

    const { style, type, element_type } = useData({
        type: state.focusType,
        id: state.focusId,
    });

    const styleType = normalizeStyleType(state.focusType, element_type || type);

    const { value, onChange } = useField(
        state.focusType,
        state.focusId,
        "styles"
    );

    if (!styleType) {
        return (
            <div>
                <div>{state.focusType}</div>
                <div>{state.focusId}</div>
                <div>{type}</div>
                <div>{element_type}</div>
            </div>
        );
    }

    return (
        <StyleEditor
            {...props}
            value={value}
            onChange={onChange}
            id={styleType}
            element={state.style}
            onSelectElement={(e) => setState({ style: e })}
            entityType={state.focusType}
            entityId={state.focusId}
            onEditGlobalStyle={(editStyle) => setState({ editStyle })}
        />
    );
}

ComponentStyles.propTypes = {};

ComponentStyles.defaultProps = {};

export default ComponentStyles;
