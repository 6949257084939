import React, { useState } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";

import { useSelector } from "react-redux";

import { useActions, useData, useUiFlag } from "state/hooks";
import { change } from "state/actions/data";
import TextField from "components/fields/text/TextField";
import Field from "containers/Field/Field";

import { getLinkRef, setLinkRef, selectLink } from "./../selectors";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import LinkAction from "./LinkAction";
import LinkTypeSelect from "./LinkTypeSelect";

import Icon from "components/Icon";

import "./style.css";
import LinkStatus from "playground/cms/Link/LinkForm/LinkStatus";

const urlActions = ["link", "modal", "call", "email"];

const allowedLinkTypesByAction = {
    link: ["external", "sitemap", "media", "config"],
    modal: ["external", "sitemap", "config"],
    call: ["config"],
    email: ["config"],
};

function LinkTypeSelectPopover(props) {
    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton icon={"mui-search"} onClick={onClick} />
            )}
            content={({ onClose }) => (
                <LinkTypeSelect onClose={onClose} {...props} />
            )}
        />
    );
}

const actionIcons = {
    link: "mui-link",
    call: "mui-phone",
    email: "mui-email",
    media: "mui-photo",
    sitemap: "mui-account_tree",
};

function changeLink(type, prev, next, prevLink) {
    const prevLinkRef = getLinkRef(type, prev);
    let nextLinkRef = getLinkRef(null, next);

    if (!nextLinkRef.type) nextLinkRef = null;

    const { link_ref, ...rest } = next;
    const nextAction = next.link_action;

    if (next.link_action && next.link_action !== prev.link_action) {
        if (!urlActions.includes(nextAction)) {
            console.log(`Clear link_ref on switching action to ${nextAction}`);
            nextLinkRef = null;
        } else {
            if (
                (allowedLinkTypesByAction[nextAction] || []).includes(
                    prevLinkRef?.type
                )
            ) {
                console.log(
                    `Retain link_ref on switching action to ${nextAction}`
                );
                nextLinkRef = prevLinkRef;
            } else {
                console.log(
                    `Clear unsupported link_ref type on switching action to ${nextAction}`
                );
                nextLinkRef = null;
            }
        }
    }

    if (nextLinkRef?.type && nextLinkRef?.type !== prevLinkRef?.type) {
        rest.link = null;
        if (nextLinkRef?.type === "sitemap") {
            const [url, anchor] = prev.link?.split("#") || [];
            if (anchor && nextLinkRef) {
                console.log(
                    `Retain anchor on switching link_ref type ${anchor}`
                );
                nextLinkRef.link_anchor = anchor;
            }
        }
    }

    if (prevLinkRef?.type && !nextLinkRef) {
        if (
            (allowedLinkTypesByAction[nextAction] || []).includes(
                prevLinkRef?.type
            )
        ) {
            rest.link = prev.link || prevLink.url;
            console.log(`Retain url on clearing link_ref ${rest.link}`);
        }
        if (type === "cms.nav-links") {
            nextLinkRef = {
                type: "external",
                url: prevLink.url,
            };
        }
    }

    const nextLink = { ...rest, ...setLinkRef(type, nextLinkRef) };
    return nextLink;
}

const NullFieldDecorator = ({ children }) => children;

export function LinkForm(props) {
    const { type, id, schema } = props;
    const { children, variant, ...rest } = props;

    const data = useData(props);
    const linkRef = getLinkRef(type, data);
    const linkRefType = getLinkRef(type, data)?.type;
    const link = useSelector((store) => selectLink(store, type, id));
    const action = data?.link_action || "link";

    const { onChange } = useActions({ onChange: change });

    const handleChangeAction = (action) => {
        handleChange({ link_action: action });
    };

    const handleChange = (next) => {
        onChange(type, id, changeLink(type, data, next, link));
    };

    const handleChangeUrl = (next) => {
        if (type === "cms.nav-links" || linkRefType === "external") {
            onChange(type, id, { ...setLinkRef(type, { url: next }) });
            return;
        } else {
            onChange(type, id, { link: next });
        }
    };

    const targetLinkId = linkRefType === "external" ? "link_ref.url" : "link";
    const linkTypeIcon = actionIcons[linkRefType] || actionIcons[action];

    return (
        <div className={"rows gap-sm"}>
            <LinkAction
                value={action}
                onChange={handleChangeAction}
                variant={variant}
            />
            <div className={"rows gap-xs"}>
                <TextField
                    variant={variant}
                    value={link?.url}
                    placeholder={link?.defaultUrl}
                    label={"URL"}
                    fieldType={"text"}
                    onChange={handleChangeUrl}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        autoFocus: link.canOverrideLink,
                        startAdornment: linkTypeIcon && (
                            <Icon>{linkTypeIcon}</Icon>
                        ),
                        endAdornment: (
                            <LinkTypeSelectPopover
                                onChange={handleChange}
                                type={type}
                                data={data}
                            />
                        ),
                        readOnly: !link.canOverrideLink || undefined,
                    }}
                />
                {linkRefType && linkRefType !== "external" && (
                    <LinkStatus
                        linkType={linkRefType}
                        isBroken={link?.broken}
                    />
                )}
            </div>
            <div>
                {!link.canOverrideLink && data?.link && (
                    <strong>
                        Link {data?.link} is being overriden by linkRef
                    </strong>
                )}
                {link.defaultUrl && data?.link === link.defaultUrl && (
                    <strong>Link value is the same as default</strong>
                )}
            </div>
            {(type === "cms.nav-links" || linkRef?.custom_label) && (
                <TextField
                    variant={variant}
                    label={"link_ref.custom_label"}
                    value={linkRef?.custom_label || ""}
                    placeholder={link?.defaultLabel}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(v) =>
                        handleChange({
                            link_ref: { ...linkRef, custom_label: v },
                        })
                    }
                />
            )}
            {type !== "cms.nav-links" && (
                <Field
                    variant={variant}
                    id={"link_label"}
                    label={"Tekst linku / przycisku"}
                    fieldType={"text"}
                    placeholder={link?.defaultLabel}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        autoFocus: !link.canOverrideLink,
                    }}
                />
            )}
            {link.defaultLabel && data?.link_label === link.defaultLabel && (
                <strong>Link label is the same as default</strong>
            )}
            {linkRefType === "sitemap" &&
                (!linkRef.section_id || linkRef.custom_anchor) && (
                    <>
                        {/*<TextField*/}
                        {/*    value={linkRef?.link_anchor || ""}*/}
                        {/*    label={"link_ref.link_anchor"}*/}
                        {/*    onChange={(v) =>*/}
                        {/*        handleChange({*/}
                        {/*            link_ref: { ...linkRef, link_anchor: v },*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*    InputProps={{*/}
                        {/*        startAdornment: <div>#</div>,*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <TextField
                            variant={variant}
                            value={linkRef?.custom_anchor || ""}
                            label={"link_ref.custom_anchor"}
                            onChange={(v) =>
                                handleChange({
                                    link_ref: { ...linkRef, custom_anchor: v },
                                })
                            }
                            InputProps={{
                                startAdornment: <div>#</div>,
                            }}
                        />
                    </>
                )}
            <div className={"rows gap-sm"} style={{ display: "none" }}>
                <pre>{JSON.stringify(linkRef, null, 2)}</pre>
                <pre>{JSON.stringify(link, null, 2)}</pre>

                <div>
                    Action defaults like phone number are being overriden by
                    link and label when they are provided from linkRef unless
                    linkRef doesnt provide a label (ie media, external)
                </div>
                <div>Link gets overriden by linkRef.url:</div>
                <div>
                    Special case is external when link overrides linkRef.url
                </div>
                <div>
                    Probably link should be set to null when changing ref to
                    external and this field could be used for editing
                    linkRef.url
                </div>
                <div>
                    ref_url and ref_label are generated server side, write a
                    function which tries to resolve these values from state to
                    make it more reactive
                </div>

                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
        </div>
    );
}

LinkForm.propTypes = {};

LinkForm.defaultProps = {
    variant: null,
    FieldDecorator: NullFieldDecorator,
};

export default LinkForm;
