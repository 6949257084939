import {useApiActions, useRouteParam} from "state/hooks";
import {post} from "state/actions/api";
import {useFeatureEnabled, useUserProfile} from "joynt/hooks";
import {FEATURE_DEV} from "joynt/config/features";
import {useCallback, useMemo, useState} from "react";

const nullObj = {};

export function useJoyride(id) {

    const {post: postRequest} = useApiActions({post});

    const profile = useUserProfile();
    const profileId = profile.id;
    const completedTutorials = profile.tutorials || nullObj;
    const isCompleted = !!completedTutorials[id];
    const isDev = useFeatureEnabled(FEATURE_DEV);

    const url = `joynt/user-profiles/${profileId}`;

    const [routeJoyride] = useRouteParam('joyride');
    const initialShouldRun = isDev && (!isCompleted || routeJoyride === id);
    const [shouldRun, setShouldRun] = useState(initialShouldRun);

    const onComplete = useCallback((data) => {
        const {type: eventType} = data;
        if (eventType !== 'tour:end') return;

        setShouldRun(false);

        postRequest(url, {
            id: profileId,
            tutorials: {
                ...completedTutorials,
                [id]: true
            }
        }, null, 'db.user-profiles');
    }, [
        postRequest,
        url,
        profileId,
        id,
        completedTutorials,
        setShouldRun
    ]);

    return useMemo(() => ({
        isCompleted,
        onComplete,
        shouldRun
    }), [onComplete, isCompleted, shouldRun]);

}