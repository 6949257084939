import React from "react";
//import PropTypes from 'prop-types';

import {
    SectionContext,
    ComponentContext as ZmapComponentContext,
    ComponentMappingProvider,
} from "playground/designer/Zmap";

import { FormContext } from "playground/designer/ComponentEditor";

export function ComponentContext(props) {
    const { sectionId, type, id, children } = props;
    return (
        <SectionContext sectionId={sectionId}>
            <ZmapComponentContext type={type} id={id}>
                <ComponentMappingProvider type={type} id={id}>
                    <FormContext type={type} id={id}>
                        {children}
                    </FormContext>
                </ComponentMappingProvider>
            </ZmapComponentContext>
        </SectionContext>
    );
}

ComponentContext.propTypes = {};

ComponentContext.defaultProps = {
    type: "cms.components",
};

export default ComponentContext;
