import Gallery from "./Gallery";
import { PagesView as Pages } from "./Pages";
import Settings from "./Settings";
import Media from "./Media";
import Sections from "./Sections";
import Resources from "./Resources";
import Offers from "./Offers";
import Products from "./Products";
import Categories from "./Categories";
import Entries from "./Entries";
import Rooms from "./Rooms";
import Posts from "./Posts";
import RoomsConf from "./RoomsConf";
import Plans from "./Plans";
import Projects from "./Projects";
import Contacts from "./Contacts";
import Attractions from "./Attractions";
import Properties from "./Properties";

import { views as apartmentsViews } from "playground/apartments";

export default {
    "cms.entries.gallery": Gallery,
    "cms.pages": Pages,
    "cms.entries.offers": Offers,
    "cms.layouts": Pages,
    "cms.settings": Settings,
    "cms.media": Media,
    "cms.sections": Sections,
    "cms.products": Products,
    "cms.categories": Categories,
    "cms.properties": Properties,
    "cms.entries.rooms": Rooms,
    "cms.entries.rooms_conf": RoomsConf,
    "cms.entries.posts": Posts,
    "cms.entries.projects": Projects,
    "cms.entries.reviews": Entries,
    "cms.entries.contacts": Contacts,
    "cms.entries.attractions": Attractions,
    "cms.plans": Plans,
    ...apartmentsViews,
    default: Resources,
};
