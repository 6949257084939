import React from "react";
import Field from "containers/Field";

import { accessTypes, sessionBackends } from "joynt/config/sessions";
import Page from "joynt/views/Page";

import FieldGroup from "joynt/components/Form/FieldGroup";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldLayout from "joynt/components/Form/FieldLayout";
import SelectField from "joynt/edit/SelectField";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import { viewTypes } from "joynt/config/types";

import { AdvancedSettings, DangerZone, CardAppearance } from "./fields";
import CategorySelect from "joynt/edit/CategorySelect";
import GroupAccess from "joynt/edit/access/GroupAccess";
import { useEdges, useNode, useNodeData } from "joynt/hooks";
import ParentWorkspace from "joynt/edit/types/fields/ParentWorkspace";
import CreateWorkspaceLeft from "joynt/modals/info/CreateWorkspaceLeft";
import CreateWorkspaceRight from "joynt/modals/info/CreateWorkspaceRight";
import { HelperText } from "joynt/components/HelperText";
import Roles from "joynt/components/Roles";
import { workspacePlans } from "joynt/config/plans";

//import PropTypes from 'prop-types';

function Step1({ id, isDraft }) {
    const { subworkspace } = useNode(id);

    return (
        <FieldsLayout>
            {subworkspace && isDraft && <ParentWorkspace id={id} />}

            <FieldGroup
                label={"1. Access"}
                helperText={
                    <HelperText
                        text={"What's this?"}
                        tooltip={
                            "Your workspace is a lot like a building. It can be public, so that anyone can visit. Or invite only, where you select the people at the door. Or private, like your room, where only you have access."
                        }
                    />
                }
            >
                <Field id={"access_type"}>
                    {({ onChange, value }) => (
                        <CategorySelect
                            onChange={onChange}
                            value={value || "open"}
                            options={Object.values(accessTypes)}
                        />
                    )}
                </Field>
            </FieldGroup>
            <FieldGroup label={"2. Workspace name and subtitle"}>
                <Field id={"name"} label={"Name"} validate={true} />
                <Field id={"description"} label={"Subtitle"} optional={true} />
            </FieldGroup>

            <CardAppearance
                icon={true}
                label={"3. Workspace avatar"}
                variant={"workspace"}
                showCard={!!subworkspace}
            />

            <FieldGroup label={"4. About"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>

            {isDraft && (
                <div className={"rows rows-center gap-md w-fill"}>
                    <div className={"text-center type-lg"}>
                        <p>
                            After finishing this process you can invite admins
                            and collaborators to help you build up your space.
                        </p>
                    </div>
                    <div className={"cols cols-center"}>
                        <img
                            src={
                                "https://joynt.works/thumb?file=joynt-website/user/app/workspace-create-4.svg"
                            }
                            alt={"Create workspace"}
                        />
                    </div>
                    <div className={"text-center type-lg"}>
                        <p>
                            You will be able to add channels, chats, feeds and
                            rooms and invite members to your workspace later on
                        </p>
                    </div>
                </div>
            )}

            {!isDraft && (
                <WorkspaceAdvancedSettings id={id} isDraft={isDraft} />
            )}
        </FieldsLayout>
    );
}

function WorkspaceAdvancedSettings({ id, isDraft }) {
    const { parent } = useEdges(id);
    const { name: parentName } = useNodeData(parent);

    return (
        <AdvancedSettings>
            <FieldLayout
                name={"Main stage"}
                description={"Enable main stage for this event"}
            >
                <Field
                    id={"disable_stage"}
                    fieldType={"checkbox"}
                    negate={"true"}
                />
            </FieldLayout>
            <FieldLayout
                name={"Video session provider"}
                description={"Choose service for hosting live session"}
            >
                <Field id={"event_session_provider"}>
                    {({ onChange, value }) => (
                        <SelectField
                            onChange={onChange}
                            value={value}
                            options={sessionBackends}
                        />
                    )}
                </Field>
            </FieldLayout>
            <FieldLayout name={"Estimated max participants"}>
                <Field id={"event_max_participants"} fieldType={"text"} />
            </FieldLayout>
            <FieldLayout
                name={"Enable workspace notifications"}
                description={
                    "Allow to send notifications to all workspace members"
                }
            >
                <Field id={"enable_notifications"} fieldType={"checkbox"} />
            </FieldLayout>
            <FieldLayout name={"Default view"}>
                <Field id={"default_view"}>
                    {({ onChange, value }) => (
                        <SelectField
                            onChange={onChange}
                            value={value}
                            options={viewTypes}
                        />
                    )}
                </Field>
            </FieldLayout>
            <FieldLayout name={"Workspace plan"}>
                <Field id={"workspace_plan"}>
                    {({ onChange, value }) => (
                        <SelectField
                            onChange={onChange}
                            value={value}
                            options={workspacePlans}
                        />
                    )}
                </Field>
            </FieldLayout>
            <GroupAccess
                name={`Allow members to join`}
                description={`Allow anyone who can see "${parentName}" to see this`}
                icon={"mui-supervisor_account"}
            >
                <Field id={"published"} fieldType={"checkbox"} negate={false} />
            </GroupAccess>
            <GroupAccess
                name={`Inherit admins and collaborators from parent`}
                description={`All admins and members from "${parentName}"`}
                icon={"mui-verified_user"}
            >
                <Field id={"public"} fieldType={"checkbox"} negate={false} />
            </GroupAccess>
            {!isDraft ? <DangerZone /> : null}
        </AdvancedSettings>
    );
}

function InvitePeople(props) {
    const { id } = props;
    return <Roles id={id} />;
}

const createSteps = {
    step1: {
        component: Step1,
        label: "Settings",
        submit: true,
    },
};

const editSteps = {
    ...createSteps,
    step2: {
        component: InvitePeople,
        label: "Manage members",
        disableActions: true,
    },
};

export default function Workspace(props) {
    const { isDraft } = props;

    return (
        <MultiStepForm
            {...props}
            showBackLink={true}
            steps={isDraft ? createSteps : editSteps}
            infoLeft={isDraft ? <CreateWorkspaceLeft /> : null}
            infoRight={isDraft ? <CreateWorkspaceRight /> : null}
        />
    );
}

Workspace.propTypes = {};
Workspace.defaultProps = {};
