import React from 'react';
import PropTypes from 'prop-types';
import {useNodeData} from "joynt/hooks";
import {NODE_TYPE_EVENT} from "joynt/config";
import MoreLink from "joynt/components/Sidebar/SidebarNav/MoreLink";
import SidebarSection from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import SidebarItem from "joynt/components/Sidebar/SidebarNav/SidebarItem";
import {EventIcon} from "joynt/components/Sidebar/Icons";

function ScheduleItem(props) {
    const {label} = props;
    return <SidebarItem
        label={label}
        icon={<EventIcon icon={"mui-schedule"}/>}
        {...props}
    />;
}

export function Schedule(props) {

    const {id, view, onClick} = props;
    const {node_type, subtype} = useNodeData(id);

    const isHome = node_type === "root";
    const isEvent = subtype === NODE_TYPE_EVENT;

    if (!isHome && isEvent) return <SidebarSection>
        <ScheduleItem
            label={"Event schedule"}
            isActive={view === "event-schedule"}
            onClick={()=>onClick(id, null, "event-schedule")}
        />
    </SidebarSection>;

    if (isHome || isEvent) return null;

    return (<SidebarSection>
        <ScheduleItem
            label={"Workspace schedule"}
            isActive={view === "workspace-schedule"}
            onClick={()=>onClick(id, null, "workspace-schedule")}
        />
        <MoreLink
            onClick={()=>onClick(id, null, "all-sessions")}
            label={"All sessions"}
            isActive={view === "all-sessions"}
        />
    </SidebarSection>);

}

Schedule.propTypes = {};

Schedule.defaultProps = {};

export default Schedule;