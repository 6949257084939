import React, {useCallback, useMemo} from 'react';
//import PropTypes from 'prop-types';

import {DateTimePicker as MuiDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";

export default function DateTimePicker(props) {

    const { value, label, onChange, readOnly, ...other } = props;

    const change = useCallback((value) => {
        onChange(value.format('X'));
    }, [onChange]);

    let dateValue = useMemo(() => {
        return value ? moment.unix(value) : null;
    }, [value]);

    return (<div className={'cols cols-middle'}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiDateTimePicker
                keyboard
                value={dateValue}
                onChange={change}
                inputVariant="outlined"
                label={label}
                readOnly={readOnly}
                autoOk={true}
                format={'ddd, DD MMM H:mm'}
                {...other}
            />
        </MuiPickersUtilsProvider>
    </div>);

}

DateTimePicker.propTypes = {};
DateTimePicker.defaultProps = {};
