import React from "react";

import ProfileInfo from "./ProfileInfo";
import { ProfileOverlayContext } from "./hooks/useProfilePopover";
import PopoverOverlay from "joynt/components/PopoverOverlay";
//import PropTypes from 'prop-types';

export default function ProfileInfoOverlay(props) {
    return (
        <PopoverOverlay
            {...props}
            responsive={true}
            Context={ProfileOverlayContext}
            Component={props.component}
        />
    );
}

ProfileInfoOverlay.propTypes = {};
ProfileInfoOverlay.defaultProps = {
    component: ProfileInfo,
};
