import React from "react";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export function DrawerHeader(props) {
    const { onGoBack, label } = props;

    return (
        <div className={"utility-nav-header"}>
            <div className={"utility-nav-header__name"}>{label}</div>
            <div className={"utility-nav-header__back"} onClick={onGoBack}>
                <Icon fontSize={"small"}>mui-arrow_back</Icon>
                Menu
            </div>
        </div>
    );
}

DrawerHeader.propTypes = {};
DrawerHeader.defaultProps = {};

export default DrawerHeader;
