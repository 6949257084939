import React from "react";
import PropTypes from "prop-types";

import { DrawerHeader } from "../DrawerNav";
import { DrawerNav } from "../DrawerNav";

import DrawerContent from "./DrawerContent";

import views from "./views";

export function DrawerContentContainer(props) {
    const { view, defaultView } = props;
    const ViewComponent = views[view] || DrawerNav;

    return (
        <DrawerContent {...props}>
            {view && view !== defaultView && <DrawerHeader {...props} />}
            {ViewComponent && <ViewComponent {...props} />}
        </DrawerContent>
    );
}

DrawerContentContainer.propTypes = {
    id: PropTypes.string,
    defaultView: PropTypes.string,
};
DrawerContentContainer.defaultProps = {
    defaultView: "main",
};

export default DrawerContentContainer;
