import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Slide from "@material-ui/core/Slide";

export function SlideTransition(props) {
    const { id, current, next, Component, ...other } = props;
    const eventId = current || next;
    const [show, setShow] = useState(true);
    const [currentVisible, setCurrent] = useState(eventId);

    useEffect(() => {
        setShow(true);
    }, [id]);

    useEffect(() => {
        if (eventId !== currentVisible) setShow(false);
    }, [eventId, currentVisible, setShow]);

    const showNext = useCallback(() => {
        setShow(true);
        setCurrent(eventId);
    }, [setShow, setCurrent, eventId]);

    if (!Component) return <div>SlideTransition error, provide Component</div>;

    return (
        <div className={"no-shrink relative overflow-hide"}>
            <Slide
                in={show}
                onExited={showNext}
                direction={!show ? "up" : "down"}
                mountOnEnter={true}
                unmountOnExit={true}
            >
                <div>
                    <Component {...other} id={currentVisible} />
                </div>
            </Slide>
        </div>
    );
}

SlideTransition.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

SlideTransition.defaultProps = {};

export default SlideTransition;
