export const accessTypes = {
    public: {
        id: "public",
        label: "Public",
        data: {
            public: true,
            published: true,
        },
    },
    reset: {
        id: "reset",
        label: "As members",
        data: {
            public: false,
            published: true,
        },
    },
    admins: {
        id: "admins",
        label: "Only admins",
        data: {
            public: true,
            published: false,
        },
    },
    private: {
        id: "private",
        label: "Private",
        data: {
            public: false,
            published: false,
        },
    },
};
