import React, { useState } from "react";
import PropTypes from "prop-types";

import RoleItem from "./../RoleItem";
import { useRoleGroups } from "./../hooks";
import AssignNodeRoles from "joynt/components/Roles/NodeRoles/AssignNodeRoles";
import { ResponsiveModal } from "components/Modal";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_WORKSPACE_ROLES } from "joynt/config/features";

function RoleGroup(props) {
    const { label, items, type, ...other } = props;

    return (
        <div className={"rows gap-xs"}>
            {label && <div>{label}</div>}
            {items.map((item) => (
                <RoleItem {...other} id={item} key={item} type={type} />
            ))}
        </div>
    );
}

export default function RolesList(props) {
    const { id, list, groupBy, type, ...other } = props;

    const groups = useRoleGroups(id, list, groupBy);
    const [editRole, setEditRole] = useState(false);
    const enableWorkspaceRoles = useFeatureEnabled(FEATURE_WORKSPACE_ROLES);

    if (!groups.length) return null;

    return (
        <>
            <div className={"rows gap-sm"}>
                {groups.map((group) => (
                    <RoleGroup
                        key={group.id}
                        {...group}
                        {...other}
                        type={type}
                        onClick={enableWorkspaceRoles ? setEditRole : null}
                    />
                ))}
            </div>
            {enableWorkspaceRoles && (
                <ResponsiveModal
                    open={editRole}
                    onClose={() => setEditRole(false)}
                >
                    <AssignNodeRoles node={id} id={editRole} />
                </ResponsiveModal>
            )}
        </>
    );
}

RolesList.propTypes = {
    value: PropTypes.array,
    type: PropTypes.string,
};
