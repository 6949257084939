import { RichUtils } from "draft-js";

import {
    moveFocusToBlockEnd,
    clearTextTypeahead,
    getCurrentBlock,
    updateBlockData,
} from "./../../util";

const TODO_TRIGGER = "[]";
const TODO_BLOCK_TYPE = "joynt-todo";
const DEFAULT_BLOCK_TYPE = "unstyled";

const toggleToDoBlockType = (editorState, newType = DEFAULT_BLOCK_TYPE) => {
    let nextState = editorState;
    const block = getCurrentBlock(editorState);
    nextState = updateBlockData(nextState, block, { completed: false });
    nextState = RichUtils.toggleBlockType(nextState, newType);
    nextState = clearTextTypeahead(nextState, TODO_TRIGGER.substr(0));
    nextState = moveFocusToBlockEnd(nextState, block);
    return nextState;
};

export function createToDoBlock(str, editor) {
    if (str !== TODO_TRIGGER.substr(TODO_TRIGGER.length - 1)) {
        return false;
    }
    const editorState = editor.getEditorState();
    const selection = editorState.getSelection();
    const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey());
    const blockType = currentBlock.getType();
    const blockLength = currentBlock.getLength();

    const nextType =
        blockType !== TODO_BLOCK_TYPE ? TODO_BLOCK_TYPE : DEFAULT_BLOCK_TYPE;

    if (
        blockLength === TODO_TRIGGER.length - 1 &&
        currentBlock.getText() ===
            TODO_TRIGGER.substr(0, TODO_TRIGGER.length - 1)
    ) {
        editor.setEditorState(toggleToDoBlockType(editorState, nextType));
        return true;
    }

    return false;
}
