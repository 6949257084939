import React from "react";
import PropTypes from "prop-types";

import Layout from "joynt/meeting/settings/Layout";
import CameraPreview from "joynt/meeting/settings/CameraPreview";
import DeviceSelection from "joynt/meeting/settings/DeviceSelection";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DeviceAccessGate from "joynt/meeting/settings/DeviceAccessGate";
import { useMediaDevices } from "joynt/meeting/devices";
import MicrophoneLevel from "joynt/meeting/settings/MicrophoneLevel";
import MicrophoneTest from "joynt/meeting/settings/MicrophoneTest";

const titles = {
    invite: "You've been invited on stage",
    "join-request": "You're about to go live",
    default: "You're about to join session",
};

function Settings(props) {
    const { showPreview } = props;
    const { microphoneAvailable } = useMediaDevices();

    return (
        <>
            <CameraPreview showPreview={showPreview} />
            {showPreview && microphoneAvailable && <MicrophoneLevel />}
            {showPreview && microphoneAvailable && <MicrophoneTest />}
            <DeviceSelection />
        </>
    );
}

export function ConfirmJoin(props) {
    const {
        reason,
        showPreview,
        onDeny,
        onLeave,
        onConfirm,
        confirmLabel,
        leaveLabel,
        denyLabel,
    } = props;

    const title = titles[reason] || titles.default;

    return (
        <DeviceAccessGate>
            <Layout
                title={title}
                description={
                    showPreview
                        ? "Please test your camera and mic before joining the meeting"
                        : "You can disable video and/or audio now"
                }
            >
                <Settings showPreview={showPreview} />
                <DialogActions
                    style={{ justifyContent: "center", padding: "1rem" }}
                >
                    <Button
                        variant="contained"
                        color="default"
                        size="large"
                        onClick={onLeave}
                    >
                        {leaveLabel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={onConfirm}
                    >
                        {confirmLabel}
                    </Button>
                </DialogActions>
                {onLeave !== onDeny && (
                    <div className={"cols cols-center"}>
                        <div onClick={onDeny} className={"link"}>
                            {denyLabel}
                        </div>
                    </div>
                )}
            </Layout>
        </DeviceAccessGate>
    );
}

ConfirmJoin.propTypes = {
    reason: PropTypes.string,
    onConfirm: PropTypes.func,
    onDeny: PropTypes.func,
    confirmLabel: PropTypes.string,
    denyLabel: PropTypes.string,
};
ConfirmJoin.defaultProps = {
    confirmLabel: "Join now",
    leaveLabel: "Leave",
    denyLabel: "Join without audio/video",
    showPreview: true,
};

export default ConfirmJoin;
