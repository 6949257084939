import React from "react";
import PropTypes from "prop-types";
import SessionStateInfo from "./SessionStateInfo";
import { useNodeData } from "joynt/hooks";
import { useSessionState } from "joynt/hooks/sessions";
import { usePending } from "state/hooks";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";

export function SessionStateInfoContainer(props) {
    const { id } = props;

    const { event_state, event_start, event_end, event_started, event_ended } =
        useNodeData(id);

    const {
        onJoinSession,
        onStartSession,
        onGoToStage,
        isConnected,
        isStageSession,
        onEndSession,
    } = useSessionState(id);

    const isPending = usePending(id);
    const [{ loading: isConnecting }] = useConnectionState(id);

    const pending = isPending || isConnecting;

    return (
        <SessionStateInfo
            isPending={pending}
            {...props}
            status={event_state}
            start={event_start}
            end={event_end}
            started={event_started}
            ended={event_ended}
            onJoin={onJoinSession}
            onStart={onStartSession}
            onGoToStage={onGoToStage}
            isConnected={isConnected}
            isStageSession={isStageSession}
            onEnd={onEndSession}
        />
    );
}

SessionStateInfoContainer.propTypes = {
    id: PropTypes.string,
};
SessionStateInfoContainer.defaultProps = {};

export default SessionStateInfoContainer;
