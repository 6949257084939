import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import StageSchedule from "./StageSchedule";
import useFetch from "state/hooks/useFetch";
import {useApiActions, useList} from "state/hooks";
import Preloader from "components/Preloader";
import {scheduleSession} from "joynt/state/actions/meetings";
import {useNodeData} from "joynt/hooks";
import SessionItem from "joynt/components/StageSchedule/SessionItem";

export function StageScheduleContainer(props) {

    const {id, type} = props;
    const list = `schedule.${id}`;

    const pending = useFetch({
        type,
        url: `joynt/nodes/${id}/schedule`,
        list: list
    });

    const {items: sessions} = useList(type, list);

    const {onSchedule} = useApiActions({
        onSchedule: scheduleSession
    });

    const schedule = useCallback((session) => {
        onSchedule(id, session);
    }, [onSchedule, id]);

    const {next_event, current_event} = useNodeData(id);

    return (<StageSchedule
        {...props}
    >
        {sessions.map(session => <SessionItem
            key={session}
            id={session}
            onSchedule={()=>schedule(session)}
            isNext={next_event === session}
            isCurrent={current_event === session}
        />)}
        <div className={"cols cols-center pad-2"}>
            <div onClick={()=>schedule(null)}>
                clear
            </div>
        </div>
        <Preloader visible={pending} />
    </StageSchedule>);

}

StageScheduleContainer.propTypes = {
    id: PropTypes.string
};
StageScheduleContainer.defaultProps = {
    type: "db.nodes"
};

export default StageScheduleContainer;