import React from 'react';
import PropTypes from 'prop-types';

import Upload from "components/upload/Upload";

import "./style.css";

export function PostCreate(props) {

    const {tabs, onUpload, children} = props;

    return (<div className={"post-create"}>
        <Upload onFileUpload={onUpload}>
            <div className={"post-create__tabs"}>
                {tabs}
            </div>
            <div className={"post-create__content"}>
                {children}
            </div>
        </Upload>
    </div>);

}

PostCreate.propTypes = {
    onUpload: PropTypes.func
};

PostCreate.defaultProps = {};

export default PostCreate;
