import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";
import Button from "components/Button";
import {SessionDate} from "joynt/components/TimeUtils";

export function SessionItem(props) {

    const {
        onSchedule,
        name,
        start,
        end,
        //isLive,
        isNext,
        isCurrent
    } = props;

    return (<div className={"stage-schedule-session"}>
        <div>
            <div>{name}</div>
            <div className={"o-50"}>
                <SessionDate start={start} end={end} />
            </div>
        </div>
        <div>
            {!isNext && !isCurrent && <Button onClick={onSchedule}>
                Set as next
            </Button>}
        </div>
    </div>);

}

SessionItem.propTypes = {
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    onSchedule: PropTypes.func,
    isLive: PropTypes.bool,
    isNext: PropTypes.bool,
    isCurrent: PropTypes.bool
};
SessionItem.defaultProps = {
    isLive: false,
    isNext: false,
    isCurrent: false
};

export default SessionItem;
