import { useCallback } from "react";

import { useApiActions } from "state/hooks";
import { setSessionFlowSegment } from "joynt/components/SessionFlow/actions";

export function useSetSessionFlowSegment(session) {
    const { onSetSegment } = useApiActions({
        onSetSegment: setSessionFlowSegment,
    });

    return useCallback(
        (segment) => {
            onSetSegment(session, segment);
        },
        [session, onSetSegment]
    );
}
