import React, {useCallback, useEffect} from 'react';
import Console from "joynt/meeting/debug-console/Console";
import {useActions, useUiFlag} from "state/hooks";
import {storeReport} from "joynt/state/actions/meetings-debug";
import {storeList} from "state/actions/data";
import identities from "joynt/meeting/mock/data/identities";
import {AgoraContext} from "joynt/meeting/agora-ng/context/AgoraContext";
//import PropTypes from 'prop-types';

const stats = {"network":{"d16d85d4-660f-11ea-b5a1-0242ac170008":{"uplinkNetworkQuality":1,"downlinkNetworkQuality":1}},"audio":{"d16d85d4-660f-11ea-b5a1-0242ac170008":{"transportDelay":101,"end2EndDelay":101,"receiveBitrate":14000,"receiveLevel":1,"receiveBytes":6545,"receiveDelay":136,"receivePackets":187,"receivePacketsLost":0,"totalDuration":4,"totalFreezeTime":0,"freezeRate":0,"packetLossRate":0,"publishDuration":4571,"codecType":"opus"}},"video":{"d16d85d4-660f-11ea-b5a1-0242ac170008":{"transportDelay":101,"end2EndDelay":101,"receiveBitrate":42056,"receiveBytes":24397,"receiveDelay":81.50000000000001,"receivePackets":43,"receivePacketsLost":0,"receiveResolutionHeight":90,"receiveResolutionWidth":160,"totalDuration":4,"totalFreezeTime":1,"freezeRate":0.25,"packetLossRate":0,"publishDuration":4338,"codecType":"VP8","decodeFrameRate":8,"renderFrameRate":8,"receiveFrameRate":8}}};
const clientDebug = {"clients":[{"id":"main","joined":true,"state":"CONNECTED","users":3,"audio":1,"video":2},{"id":0,"joined":true,"state":"CONNECTED","users":3,"audio":0,"video":1}],"users":[{"id":"95ad53de-c528-11eb-9b50-0242ac110003","audio":true,"video":true,"client":"main","published":true,"publishedVideo":true,"publishedAudio":true,"subscribed":true},{"id":"e5ec1942-c3f3-11eb-99fd-0242ac110003","audio":false,"video":true,"client":"main","published":true,"publishedVideo":true,"publishedAudio":false,"subscribed":true},{"id":"4cb67e90-c3f1-11eb-a077-0242ac110003","audio":false,"video":true,"client":0,"published":true,"publishedVideo":true,"publishedAudio":false,"subscribed":true}],"ts":1624861360526};

const FakeAgora = {
    debug: () => clientDebug,
    peerCmd: (id, msg) => {
        alert(`Send ${msg} to ${id}`);
    },
    watch: () => null
};

const reports = [
    {
        participant_id: identities[0].id,
        report: {
            logs: [
                {type: 'warn', data: 'user1 Something'},
                {type: 'error', data: 'user1 Something else'}
            ],
            info: {
                userAgent: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.9'
            },
            data: {
                ts: 1234,
                ui: {},
                presence: {}
            }
        }
    },
    {
        participant_id: identities[1].id,
        report: {
            logs: [
                {data: 'user2 Something'},
                {data: 'user2 Something else'}
            ],
            info: {
                userAgent: 'different useragent'
            },
            data: {
                ts: 1234,
                ui: {},
                presence: {}
            }
        }
    }
]

export default function ExampleConsole(props) {

    const {onStoreReport, onStoreList} = useActions({
        onStoreReport: storeReport,
        onStoreList: storeList
    });

    const [initialized, setInitialized] = useUiFlag('dev.mockConsoleData', false);

    const initData = useCallback(() => {
        onStoreList('db.identities', 'dev.meeting-console', identities.slice(0, reports.length));
        reports.forEach((report) => {
            onStoreReport(report.participant_id, report.report);
        });
    }, [onStoreList, onStoreReport]);

    const update = useCallback(() => {
        setInitialized(false);
    }, [setInitialized]);

    useEffect(() => {
        if (!initialized) {
            initData();
            setInitialized(true);
        }
    }, [initData, initialized, setInitialized]);

    return <AgoraContext.Provider value={FakeAgora}>
        <Console onRefresh={update} stats={stats} />
    </AgoraContext.Provider>;

}

ExampleConsole.propTypes = {};
ExampleConsole.defaultProps = {};
