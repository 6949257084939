import React from "react";
import cn from "classnames";

//import PropTypes from 'prop-types';

export function StylePanel(props) {
    const { name, action, onHeaderClick, children } = props;

    return (
        <div className={"style-panel"}>
            <div
                className={cn("style-panel-header", {
                    "is-clickable": !!onHeaderClick,
                })}
                onClick={onHeaderClick}
            >
                <div className={"style-panel-name"}>{name}</div>
                {action && <div className={"style-panel-action"}>{action}</div>}
            </div>
            <div className={"style-panel-content"}>{children}</div>
        </div>
    );
}

StylePanel.propTypes = {};

StylePanel.defaultProps = {};

export default StylePanel;
