import React from 'react';
//import PropTypes from 'prop-types';

function LogEntry({type, data}) {
    return <div className={'console-log-entry'}>
        {type}
        {JSON.stringify(data)}
    </div>
}

export default function LogsList(props) {
    const {logs} = props;
    return <div className={'console-log-list'}>
        {logs.map((entry, i) => {
            return <LogEntry key={i} {...entry} />
        })}
    </div>
}

LogsList.defaultProps = {
    logs: []
};

LogsList.propTypes = {};
