import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";
import Field from "containers/Field";
import NavTree from "./NavTree";

export function NavigationForm(props) {
    const {} = props;
    const data = useData(props);
    return (
        <FormLayout>
            <FormLayout.Content>
                <FormLayout.Tab id={"nav"} label={"Navigation"}>
                    <FormLayout.Fieldset pad={true}>
                        <Field id={"name"} label={"Nazwa"} />
                        <NavTree {...props} />
                    </FormLayout.Fieldset>
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

NavigationForm.propTypes = {};

NavigationForm.defaultProps = {};

export default NavigationForm;
