import { takeEvery, put, putResolve, call, select } from "redux-saga/effects";
import { fetch, fetchOne } from "state/actions/api";
import { handleFetchEntity } from "state/sagas/api";
import { pending } from "state/actions/ui";
import { pushRouteParams } from "state/actions/router";
import { post } from "util/api/saga";
import { resetTypes, storeBatch, storeOne } from "state/actions/data";
import { resetAppState } from "state/actions/bootstrap";
import { setupSchema } from "state/util/normalize";
import { selectSchemaExtensions } from "state/selectors/schema";
import jsonSchema from "config/schema";
import { request } from "util/api/client";

function* handleBootstrap(action) {
    try {
        const { context, payload } = action;
        const { serviceType, bootstrapId, taskId } = payload;
        yield put(pending(taskId || bootstrapId || "app", true));
        if (serviceType === "db") yield bootstrapDb(context, bootstrapId);
        if (serviceType === "website") yield bootstrapCms(context);
        if (serviceType === "console") yield bootstrapConsole(context);
        if (serviceType === "apartments") yield bootstrapApartments(context);
        yield put(pending(taskId || bootstrapId || "app", false));
    } catch (e) {
        throw e;
    }
}

function* bootstrapDb(context, type) {
    yield put(pending("app", true));
    const apiFetch = fetch(context);

    yield call(handleFetchEntity, {
        context,
        payload: {
            type: "db.users",
            url: "db/user?without-services=true",
            alias: "@currentUser",
        },
    });

    yield put(apiFetch("schema.db", "schema.db", "db/schema?normalize=true"));
    yield put(
        apiFetch("app.user-menu", "app.user-menu", "db/schema/user-menu")
    );
    yield put(
        apiFetch(
            "db.types",
            "db.types",
            "db/types",
            null,
            null,
            null,
            "bootstrap"
        )
    );
    yield put(apiFetch("db.form_views", "db.form_views", "db/form-views"));
    yield put(apiFetch("db.list_views", "db.list_views", "db/list-views"));
    yield put(apiFetch("db.filters", "db.filters", "db/filters"));

    yield put(pending("app", false));
}

function extendSchemaConfig(config, extensions) {
    const next = { ...config };
    Object.keys(extensions).forEach((key) => {
        if (next[key]) {
            next[key] = {
                ...next[key],
                fields: {
                    ...(next[key].fields || {}),
                    ...(extensions[key].fields || {}),
                },
            };
        }
    });
    return next;
}

function* fetchSchema(context) {
    yield put(pending("schema.db", true));
    const response = yield request({
        url: `cms/schema?normalize=true`,
        type: "schema.db",
        list: "schema.db",
        context,
    });
    const data = response.data.data;
    const meta = response.data.meta;

    yield put(storeBatch(data, meta));

    const extensions = yield select(selectSchemaExtensions);
    const schemaConfig = extendSchemaConfig(jsonSchema, extensions);

    setupSchema(schemaConfig, null);

    yield put(pending("schema.db", false));
}

function* bootstrapCms(context) {
    const apiFetch = fetch(context);
    const apiFetchOne = fetchOne(context);
    yield put(resetAppState());
    //yield put(apiFetch("schema.db", "schema.db", "cms/schema?normalize=true"));
    yield call(fetchSchema, context);
    yield put(
        apiFetch("schema.db", "schema.console", "console/schema?normalize=true")
    );
    yield put(
        apiFetch("app.user-menu", "app.user-menu", "cms/schema/user-menu")
    );
    yield put(
        apiFetchOne("cms.settings", null, "cms/settings", "@websiteSettings")
    );
}

function* bootstrapApartments(context) {
    const apiFetch = fetch(context);
    const apiFetchOne = fetchOne(context);
    yield put(resetAppState());
    yield put(
        apiFetch("schema.db", "schema.db", "apartments/schema?normalize=true")
    );
    yield put(
        apiFetch("schema.db", "schema.console", "console/schema?normalize=true")
    );
    yield put(
        apiFetch(
            "app.user-menu",
            "app.user-menu",
            "apartments/schema/user-menu"
        )
    );
    //yield put(apiFetchOne('cms.settings', null, 'cms/settings', '@websiteSettings'));
}

function* bootstrapConsole(context) {
    const apiFetch = fetch(context);
    yield put(
        apiFetch("schema.db", "schema.console", "console/schema?normalize=true")
    );
    yield put(
        apiFetch("app.user-menu", "app.user-menu", "console/schema/user-menu")
    );
}

export function tokenUrl(token) {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/signup?inviteToken=${token}`;
}

function* handleInvite({ context }) {
    try {
        yield put(pushRouteParams({ share: "invite-token" }));
        yield put(pending("invite-token", true));
        const response = yield call(
            post,
            {},
            `console/user-service-access/invite`,
            null,
            context
        );
        yield put(
            storeOne("ui.link-share", "invite-token", {
                id: "invite-token",
                url: tokenUrl(response.data.data.invite_token),
            })
        );
        yield put(pending("invite-token", false));
    } catch (e) {
        console.log(e);
    }
}

function* handleResetAppState({ payload }) {
    try {
        const keys = yield select((store) => {
            const all = Object.keys(store?.data || {});
            return all.filter((key) => {
                return (
                    !key.startsWith("console.") &&
                    !key.startsWith("db.users") &&
                    //!key.startsWith("schema.") &&
                    !key.startsWith("cms.services")
                );
            });
        });
        yield put(resetTypes(keys, true));
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("APP.BOOTSTRAP", handleBootstrap);
    yield takeEvery("APP.INVITE", handleInvite);
    yield takeEvery("APP.STATE.RESET", handleResetAppState);
}
