import React from 'react';

import MuiSnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "./SnackbarContent";

//import PropTypes from 'prop-types';

const variantIcon = {
    success: 'mui-check-circle',
    warning: 'mui-warning',
    error: 'mui-error',
    info: 'mui-info',
};

export function Snackbar(props) {
    const {
        isOpen,
        autoHideDuration,
        onClose,
        anchorOrigin,
        status,
        message
    } = props;

    const icon = props.icon || variantIcon[status];

    return (<MuiSnackbar
        className={'snackbar'}
        anchorOrigin={anchorOrigin}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
    >
        <SnackbarContent
            icon={icon}
            onClose={onClose}
            variant={status}
            message={message}
        />
    </MuiSnackbar>);
}

Snackbar.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    autoHideDuration: 6000
}

export default Snackbar;
