import React, { useCallback } from "react";
import { useNode, useNodeData } from "joynt/hooks";
import { useCurrentUser, useRouteParam } from "state/hooks";
import { useIdentity, useUserPresence } from "joynt/hooks/participants";
import { thumbnail } from "util/thumbnail";
import Preloader from "components/Preloader";
import Avatar from "joynt/components/Avatar";
import Button from "components/Button";
import Icon from "components/Icon";
import Popover from "components/Popover";
import ProfileIcons from "joynt/participants/ProfileIcons";
import { useRouter } from "joynt/hooks/channels";
import useChat from "joynt/participants/hooks/useChat";
import useMeeting from "joynt/meeting/hooks/useMeeting";
//import PropTypes from 'prop-types';

function FindUser({ id, session, identityName }) {
    const { name } = useNodeData(id);

    const { onLink } = useRouter();
    const { onJoinSession } = useMeeting();

    const onClick = useCallback(() => {
        if (session) {
            onJoinSession(session, false);
            return;
        }
        onLink(id);
    }, [onLink, onJoinSession, session, id]);

    return (
        <div className={"find-user"} onClick={onClick}>
            <div className={"find-user__location"}>
                {session ? (
                    <div>Right now {identityName} is watching</div>
                ) : (
                    <div>Right now {identityName} is in</div>
                )}
                <div className={"teal"}>
                    {name} <Icon>mui-chevron_right</Icon>
                </div>
            </div>
            <div className={"link cols gap-xs cols-middle"}>
                <Icon>mui-location_on</Icon>
                Find
            </div>
        </div>
    );
}

export function ProfileInfoPopover(props) {
    const { id, parent, isOnline, children } = props;

    return (
        <Popover
            trigger={children}
            content={({ onClose }) => (
                <div className={"pad-sm"}>
                    <ProfileInfo
                        id={id}
                        parent={parent}
                        isOnline={isOnline}
                        onClose={onClose}
                    />
                </div>
            )}
        />
    );
}

export default function ProfileInfo(props) {
    const { id, isOnline, onClose } = props; //parent

    const {
        image,
        name,
        company_name,
        company_role,
        description,
        social_linkedin,
        social_twitter,
        created_by,
    } = useIdentity(id);

    const userId = useCurrentUser();
    const isYou = created_by === userId;
    const presence = useUserPresence(created_by);
    const { id: node, channel, session } = presence;
    const location = session || channel || node;
    const [spaceId] = useRouteParam("id");
    const { onShow: onShowChat } = useChat(spaceId, id);
    const pending = false;

    const src = image?.file ? thumbnail(image.file, [64, 64]) : null;

    const message = useCallback(() => {
        onShowChat();
        onClose();
    }, [onShowChat, onClose]);

    return (
        <>
            <Preloader visible={pending} />
            <div className={"profile-info-popover rows gap-sm type-default"}>
                <div className={"cols cols-top gap-sm"}>
                    <Avatar
                        isOnline={isOnline}
                        isAway={presence.away}
                        name={name}
                        src={src}
                        size={"medium"}
                    />
                    <div className={"rows grow gap-xs"}>
                        <div className={"cols grow cols-spread cols-top"}>
                            <div className={"rows grow"}>
                                <div className={"type-lg"}>{name}</div>
                                {company_name ? (
                                    <div className={"o-50"}>
                                        {company_role} at {company_name}
                                    </div>
                                ) : null}
                            </div>
                            {location && (location !== spaceId || session) ? (
                                <FindUser
                                    id={location}
                                    session={session}
                                    identityName={name}
                                />
                            ) : null}
                        </div>
                        <div className={"rows"}>
                            <div>{description}</div>
                            <ProfileIcons
                                linkedin={social_linkedin}
                                twitter={social_twitter}
                            />
                        </div>
                    </div>
                </div>
                {!pending && !isYou ? (
                    <div className={"cols cols-center gap-xs"}>
                        {/*<Button onClick={()=>null}>Ask to meet</Button>*/}
                        <Button
                            variant={"outlined"}
                            disabled
                            onClick={message}
                            startIcon={<Icon>mui-videocam</Icon>}
                        >
                            Video call
                        </Button>
                        <Button
                            variant={"outlined"}
                            onClick={message}
                            startIcon={<Icon>mui-chat</Icon>}
                        >
                            Private chat
                        </Button>
                    </div>
                ) : null}
            </div>
        </>
    );
}

ProfileInfo.propTypes = {};
ProfileInfo.defaultProps = {};
