import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import WitnessReport from "./WitnessReport";
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {MODAL_WITNESS_LEAVE} from "./../modals";

export function WitnessReportContainer(props) {

    //const {} = props;

    const [,setPage] = useRouteParam(ROUTE_PAGE);

    const onCancel = useCallback(() => {
        setPage(MODAL_WITNESS_LEAVE);
    }, [setPage]);

    return (<WitnessReport
        {...props}
        onCancel={onCancel}
    />);

}

WitnessReportContainer.propTypes = {
    id: PropTypes.string
};
WitnessReportContainer.defaultProps = {};

export default WitnessReportContainer;