import React from "react";

//import PropTypes from 'prop-types';

export function AttributeLayout(props) {
    const { name, value } = props;

    return (
        <div className={"attr-row"}>
            <div className={"attr-name"}>{name}</div>
            {value && <div className={"attr-value"}>{value}</div>}
        </div>
    );
}

AttributeLayout.propTypes = {};

AttributeLayout.defaultProps = {};

export default AttributeLayout;
