import React from 'react';
import PropTypes from 'prop-types';

import Icon from "components/Icon";

export function LiveEventIcon(props) {

    const {icon} = props;

    return (<div className={"live-event-icon"}>
        <Icon>{icon}</Icon>
    </div>);

}

LiveEventIcon.propTypes = {
    icon: PropTypes.string
};
LiveEventIcon.defaultProps = {
    icon: "mui-play_arrow"
};

export default LiveEventIcon;
