import React from 'react';
import PropTypes from 'prop-types';
import EventStage from "./EventStage";
import {useStage} from "joynt/hooks/sessions";
import {useNodeData} from "joynt/hooks";
import {useRouter} from "joynt/hooks/channels";
import {useClick} from "state/hooks";

export function EventStageContainer(props) {

    const {id} = props;

    const {
        eventId,
        isLive,
        isConnected,
    } = useStage(id, true);

    const {
        name,
        event_start,
        event_end
    } = useNodeData(eventId);

    const {onLink} = useRouter();
    const link = useClick({onClick: onLink, id: eventId});

    return (<EventStage
        {...props}
        name={name}
        start={event_start}
        end={event_end}
        id={eventId}
        isLive={isLive}
        isConnected={isConnected}
        onLink={link}
    />);

}

EventStageContainer.propTypes = {
    id: PropTypes.string
};

EventStageContainer.defaultProps = {};

export default EventStageContainer;