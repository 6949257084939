import { takeEvery, put, select } from 'redux-saga/effects';
import {selectEntity} from "state/selectors/data";
import uuid from 'uuid/v4';
import {pathAppend, storeOne} from "state/actions/data";

function* handleClone({payload}) {
    try {
        const { type, id, target } = payload;
        const targetPath = target.split('/');
        const item = yield select(store=>selectEntity(store, type, id));
        const clone = {...item, id: uuid()};
        yield put(storeOne(type, clone.id, clone));
        yield put(pathAppend(targetPath, clone.id));
    } catch (e) {
        console.log(e);
    }
}

export default function*(action) {
    yield takeEvery('DATA.CLONE', handleClone);
}