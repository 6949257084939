import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import Icon from "components/Icon";

export function LiveSessionIcon(props) {

    const {isConnected, icon, size} = props;

    const className = cn("live-session-icon", {
        [`size-${size}`]: true
    });

    return (<div className={className}>
        <Icon>{isConnected ? "mui-bolt": icon}</Icon>
    </div>);

}

LiveSessionIcon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md"]),
    isConnected: PropTypes.bool
};
LiveSessionIcon.defaultProps = {
    icon: "mui-play_arrow",
    size: "md",
    isConnected: false
};

export default LiveSessionIcon;
