import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import List from "containers/List";
import { ArrayIterator } from "components/list/iterator";
import { withData } from "containers/Entity";
import FilePreview from "components/file/FilePreview";
import Filter from "containers/Filter";
import FilterField from "containers/FilterField";
import { MediaIterator } from "components/fields/media/MediaCollection";
import IconButtonGroup from "cms/ui/IconButtonGroup";
import Upload from "components/upload/Upload";
import IconButton from "components/IconButton";
import MediaLibraryCreate from "cms/media-browser/MediaLibraryCreate";
import Field from "containers/Field";

import "css/media-browser.css";
import Icon from "components/Icon";
import Preloader from "components/Preloader";
import AggregateRoot from "containers/AggregateRoot";
import { useList } from "state/hooks";
import { selectFilteredListData } from "state/selectors/lists";
import { selectList } from "state/selectors/data";
import { useSelector } from "react-redux";

const libraryConfig = {
    files: {
        label: "Wszystkie pliki",
        icon: "mui-insert_drive_file",
    },
    videos: {
        label: "Filmy",
        icon: "mui-movie_creation",
    },
    pano: {
        label: "Panoramy Google",
        icon: "mui-360",
    },
};

function matchPath(current, path) {
    if (current && path && current.indexOf(path) === 0) return true;
    if (!current && path === "files") return true;
    //if (path === 'files' && ['videos', 'pano'].indexOf(current) === -1) return true;
    return false;
}

function MediaDirectory(props) {
    const { id, onClick, selected } = props;
    const click = useCallback((evt) => onClick(id), [onClick, id]);
    const isSelected = matchPath(selected, id);

    return (
        <div
            className={cn("media-directory", {
                active: isSelected,
            })}
            onClick={click}
        >
            <Icon fontSize={"small"}>folder</Icon>
            <span>{id}</span>
        </div>
    );
}

function selectDirectories(state, dir) {
    const all = selectList(state, "cms.media.directories", "default");
    return all.filter((d) => {
        if (!dir) return d.path.indexOf("/") === -1;
        return d.path.indexOf(dir) === 0 || d.path.indexOf("/") === -1;
    });
    return all;
}

function MediaLibrary(props) {
    const { id, onClick, selected } = props;

    const info = libraryConfig[id] || {};
    const { label, icon } = info;
    const isSelected = matchPath(selected, id);
    const click = useCallback(
        (evt) => {
            onClick(id !== "files" ? id : null);
        },
        [onClick, id]
    );
    const showFiles =
        id === "files" && ["videos", "pano"].indexOf(selected) === -1;

    const directories = useSelector((state) =>
        selectDirectories(state, selected)
    );

    return (
        <>
            <div
                onClick={click}
                className={cn("media-library", { active: isSelected })}
            >
                <Icon fontSize={"default"}>{icon}</Icon>
                <span>{label}</span>
            </div>
            {showFiles ? (
                <div className={"rows media-directories overflow-scroll"}>
                    {id} {selected}
                    {directories.map((dir) => (
                        <MediaDirectory
                            key={dir.id}
                            id={dir.id}
                            onClick={onClick}
                            selected={selected}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
}

function Media(props) {
    const { file, type } = props;
    return <FilePreview file={file} type={type} />;
}

Media.autoFillProps = ["file", "type"];

function MediaListItem(props) {
    const { id, file, type } = props;

    return (
        <AggregateRoot type={"cms.media"} id={id} disableSubmit={true}>
            <div className={"cols gap-sm"}>
                <div className={"w-xs"}>
                    <FilePreview file={file} type={type} />
                </div>
                <div className={"rows gap-xs"}>
                    <div>
                        {file} {type}
                    </div>
                    <Field id={"title"} label={"Title"} variant={"dense"} />
                    <Field id={"alt"} label={"Alt"} variant={"dense"} />
                </div>
            </div>
        </AggregateRoot>
    );
}

MediaListItem.autoFillProps = Media.autoFillProps;

const MediaDirectoryIterator = ArrayIterator(withData(MediaDirectory));
const MediaLibraryIterator = ArrayIterator(withData(MediaLibrary));
const MediaListIterator = ArrayIterator(withData(MediaListItem));

const viewClasses = {
    list: "rows gap-sm",
    grid: "grid grid-4 media-grid fill",
};

const viewIterators = {
    list: MediaListIterator,
    grid: MediaIterator,
};

const viewOptions = [
    {
        value: "grid",
        icon: "mui-grid_on",
    },
    {
        value: "list",
        icon: "mui-list",
    },
];

const watchQuery = ["dir"];

export default class MediaBrowser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: false };
    }

    search = () => {
        this.setState({ search: !this.state.search });
    };

    render() {
        const {
            onChangePath,
            onChangeView,
            onFileUpload,
            onDelete,
            onSelect,
            onSelectionRemove,
            onPreview,
            onDeleteDirectory,
            onSaveMedia,
            selection,
            list,
            type,
            view,
            path,
            pending,
        } = this.props;

        const { search } = this.state;

        const isFiles = ["videos", "pano"].indexOf(path) === -1;

        return (
            <div
                className={"media-browser default-f10 cols grow overflow-hide"}
            >
                <Preloader visible={pending} />
                <div className={"grow rows"}>
                    <div className={"grow cols overflow-hide"}>
                        <Filter type={type} list={list} watchQuery={watchQuery}>
                            <div className={"rows media-browser-sidebar"}>
                                <div className={"rows gap-sm overflow-hide"}>
                                    <div className={"pad-sm-h"}>
                                        <MediaLibraryCreate
                                            {...this.props}
                                            onUpload={onFileUpload}
                                        />
                                    </div>
                                    <List
                                        selected={path}
                                        onClick={onChangePath}
                                        type={"cms.media.libraries"}
                                        list={"default"}
                                        Iterator={MediaLibraryIterator}
                                    />
                                </div>
                            </div>
                            <div className={"rows grow default overflow-hide"}>
                                <div
                                    className={
                                        "cols cols-middle cols-spread gap-sm pad-sm-h"
                                    }
                                >
                                    <div>{path}</div>
                                    <div className={"cols gap-sm cols-middle"}>
                                        {path && isFiles ? (
                                            <IconButton
                                                icon={"mui-delete"}
                                                size={"small"}
                                                onClick={() =>
                                                    onDeleteDirectory(path)
                                                }
                                            />
                                        ) : null}
                                        <IconButtonGroup
                                            items={viewOptions}
                                            onToggle={onChangeView}
                                            value={view}
                                        />
                                        <IconButton
                                            checked={search}
                                            size={"small"}
                                            icon={"mui-search"}
                                            onClick={this.search}
                                        />
                                    </div>
                                </div>
                                {search ? (
                                    <FilterField
                                        type={type}
                                        list={list}
                                        fieldType={"text"}
                                        variant={"search"}
                                        label={"Search..."}
                                        id={"search"}
                                    />
                                ) : null}
                                <div
                                    className={
                                        "rows grow overflow-scroll pad-sm"
                                    }
                                >
                                    <Upload
                                        onFileUpload={onFileUpload}
                                        className={"rows grow"}
                                    >
                                        <div
                                            className={cn(
                                                viewClasses[view],
                                                "pad-xs"
                                            )}
                                        >
                                            <List
                                                type={type}
                                                list={list}
                                                filtered={true}
                                                Iterator={viewIterators[view]}
                                                emptyState={
                                                    <div>no files here</div>
                                                }
                                                onDelete={onDelete}
                                                onClick={onSelect || onPreview}
                                                onSubmit={onSaveMedia}
                                                readOnly={!!onSelect}
                                            />
                                        </div>
                                    </Upload>
                                </div>
                                {onSelect && selection ? (
                                    <div className={"pad-sm dark-c800"}>
                                        <h3>selected files</h3>
                                        <div className={viewClasses.grid}>
                                            <List
                                                type={type}
                                                items={selection}
                                                Iterator={viewIterators.grid}
                                                readOnly={true}
                                                onClick={onSelectionRemove}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </Filter>
                    </div>
                </div>
            </div>
        );
    }
}

MediaBrowser.propTypes = {};

MediaBrowser.defaultProps = {
    view: "grid",
    list: "browser",
    type: "cms.media",
};
