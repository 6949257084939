import React from "react";
import { useEdgesData } from "joynt/hooks";
import { useRouter } from "joynt/hooks/channels";

//import PropTypes from 'prop-types';

export function PostTags(props) {
    const { id } = props;
    const { tag_names: tags } = useEdgesData(id);
    const { onLink } = useRouter();

    if (!tags || tags?.length < 1) return null;

    return (
        <div className={"tags cols gap-xs"}>
            {Object.keys(tags).map((tagId) => (
                <div
                    key={tagId}
                    className={"link"}
                    onClick={() => onLink(tagId)}
                >
                    {tags[tagId]}
                </div>
            ))}
        </div>
    );
}

PostTags.propTypes = {};
PostTags.defaultProps = {};

export default PostTags;
