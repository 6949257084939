import {useChannelView} from "joynt/hooks/channels";
import {useUiFlag} from "state/hooks";
import {useCallback} from "react";

export default function usePhoneNav() {
    const [view, onChangeView] = useChannelView();
    const [showSidebar] = useUiFlag('mobile.showSidebar');
    const [showSecSidebar, onShowSecSidebar] = useUiFlag('mobile.showUtilityNav');

    let value = null;
    if (view === 'inbox') value = 'inbox';
    if (showSidebar) value = 'nav';
    if (showSecSidebar) value = 'people';

    const onChange = useCallback(nextValue => {
        if (nextValue === 'inbox') {
            onChangeView('inbox');
            onShowSecSidebar(false);
            return;
        }
        if (nextValue === 'people') {
            onShowSecSidebar(true);
            return;
        }
        onChangeView(null);
        onShowSecSidebar(false);
    }, [onChangeView, onShowSecSidebar]);

    return {
        value,
        onChange
    }

}