import React, { useState } from "react";
import ListForm from "playground/designer/ComponentEditor/Types/List";
import ComponentBox from "./../ComponentBox";
import { useData, useFetch, useList } from "state/hooks";
import { useComponentMapping } from "playground/designer/Zmap";
import { takeProps } from "util/takeProps";
import { appendQueryParams } from "util/uri";
import { pageTypeToCmsType } from "cms/util";
//import PropTypes from 'prop-types';

const listProps = [
    "entry_type",
    "data_source",
    "checkin",
    "checkout",
    "tags",
    "filtering_mode",
    "limit",
    "defined_order",
    "query_spec.tags",
];

function listDataUrl(props) {
    if (!props.entry_type) return null;
    const params = takeProps(listProps, props);
    return appendQueryParams("cms/data?raw=true", params);
}

function useListQuery(type, id) {
    const mapping = useComponentMapping(type, id);
    const data = useData({ type, id });
    const url = listDataUrl({ ...data, ...mapping.result });
    const resourceType = pageTypeToCmsType(data.entry_type);
    const listId = [type, id].join(".");
    return {
        type: resourceType,
        list: listId,
        url,
    };
}

function QuerySettings(props) {
    const { items } = useList(props.queryEntityType, props.queryListId);
    return <ListForm {...props} />;
}

export function List(props) {
    const { sectionId, items, type, id } = props;
    const { entry_type, template_id, tags, limit } = useData(props);
    const fetchProps = useListQuery(type, id);

    useFetch(fetchProps);

    const { items: queryItems } = useList(fetchProps.type, fetchProps.list);

    return (
        <ComponentBox
            settings={
                <QuerySettings
                    type={"cms.components"}
                    id={id}
                    queryEntityType={fetchProps.type}
                    queryListId={fetchProps.list}
                />
            }
        >
            {entry_type ? (
                <div>
                    List of <strong>{entry_type}</strong> {queryItems.length}
                </div>
            ) : (
                <div>
                    List of items {items?.length}
                    <div>{template_id}</div>
                </div>
            )}
        </ComponentBox>
    );
}

List.propTypes = {};

List.defaultProps = {};

export default List;
