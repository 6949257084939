import React from 'react';
import PropTypes from 'prop-types';

import Section from "joynt/components/RoomStage/Section";
import EmptyState from "./EmptyState";

import "./style.css";

export function StageSession(props) {

    const {hasSession, onShowSettings, children} = props;
    return (<Section name={"Featured stage"} onShowSettings={onShowSettings}>
        {!hasSession && <EmptyState />}
        {children}
    </Section>);

}

StageSession.propTypes = {
    hasSession: PropTypes.bool
};
StageSession.defaultProps = {
    hasSession: false
};

export default StageSession;
