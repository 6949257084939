import React from "react";
import cn from "classnames";

import "./style.css";
import PropTypes from "prop-types";

export default function CalendarEmblem(props) {
    const { date, onClick, size } = props;

    const fullDate = date ? new Date(date) : null;

    const month = date ? fullDate.toLocaleString("en-US", { month: "short" }) : null;
    const day = date ? fullDate.getDate() : null;

    return (
        <div
            className={cn(
                "calendar-emblem",
                onClick && "clickable",
                size && `calendar-emblem--${size}`
            )}
        >
            <div className={"calendar-emblem--month"}>{month}</div>
            <div className={"calendar-emblem--day"}>{day}</div>
        </div>
    );
}

CalendarEmblem.propTypes = {
    date: PropTypes.number,
    function: PropTypes.func,
    size: PropTypes.string,
};
