import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import useSizeObserver from "util/hooks/useSizeObserver";
import { isEqual } from "lodash";

import "./style.css";
import DotsNav from "joynt/components/Slider/DotsNav";
import ArrowsNav from "./ArrowsNav";
import usePrevious from "util/hooks/usePrevious";

export default function Slider(props) {
    const { className, children, dots, arrows, items, margin, key } = props;

    const ref = useRef();
    const count = React.Children.count(children);
    const visibleItems = Math.floor(items);
    const pageCount = Math.ceil(count / Math.floor(items));

    const [width, setWidth] = useState(null);
    const [slide, setSlideState] = useState(0);
    const [isSliding, setIsSliding] = useState(false);

    const setSlide = useCallback(
        (id) => {
            setIsSliding(true);
            setSlideState(id);
            setTimeout(function () {
                setIsSliding(false);
            }, 300);
        },
        [setSlideState, setIsSliding]
    );

    useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth);
        }
    }, [ref]);

    const sliderResizeCallback = useCallback(
        (size) => {
            const width = size[0].contentRect.width;
            setWidth(width);
        },
        [setWidth]
    );

    useSizeObserver({
        callback: sliderResizeCallback,
        element: ref,
    });

    const pageMarginWidth = (visibleItems - 1) * margin;
    const slidesSpace = width - pageMarginWidth;
    const slideWidth = slidesSpace / items;
    const slideWithMargin = slideWidth + margin;

    let containerWidth = slideWithMargin * count - margin;
    let itemIndex = visibleItems * slide;
    let offset = slideWithMargin * itemIndex;
    if (offset > 0) offset -= slideWidth * ((items - visibleItems) / 2);

    const style = {
        position: "relative",
    };

    const containerStyle = {
        width: `${containerWidth}px`,
        display: "flex",
        transform: `translateX(${0 - offset}px)`,
        transition: "transform .3s",
    };

    const slideStyle = {
        width: `${slideWidth}px`,
        height: "100%",
        marginRight: `${margin}px`,
        overflow: "hidden",
    };

    const prev = useCallback(
        () =>
            setSlide((s) => {
                return s > 0 ? s - 1 : count - 1;
            }),
        [setSlide, count]
    );

    const next = useCallback(
        () =>
            setSlide((s) => {
                return s < count - 1 ? s + 1 : 0;
            }),
        [setSlide, count]
    );

    const previousState = usePrevious({ count, key });

    useEffect(() => {
        if (
            !isEqual(previousState?.count, count) ||
            !isEqual(previousState.key, key)
        ) {
            setSlide(0);
        }
    }, [previousState, setSlide, count, key]);

    return (
        <>
            {arrows && (
                <ArrowsNav
                    onPrev={prev}
                    onNext={next}
                    count={pageCount}
                    current={slide + 1}
                />
            )}
            <div
                className={cn(
                    "slider",
                    cn(className, {
                        "is-sliding": isSliding,
                    })
                )}
                style={style}
            >
                <div ref={ref} className={"slider-slides"}>
                    <div className={"slider-container"} style={containerStyle}>
                        {React.Children.map(children, (child, index) => {
                            let visible =
                                index >= itemIndex &&
                                index < itemIndex + visibleItems;
                            let fn = null;
                            if (index < itemIndex) fn = prev;
                            if (index >= itemIndex + visibleItems) fn = next;
                            return (
                                <div
                                    style={slideStyle}
                                    key={index}
                                    className={cn({
                                        slide: true,
                                        "slide-in": visible,
                                        "slide-out": !visible,
                                    })}
                                    onClick={fn}
                                >
                                    {child}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {dots && pageCount && (
                    <DotsNav
                        count={pageCount}
                        current={slide}
                        onChange={setSlide}
                    />
                )}
            </div>
        </>
    );
}

Slider.defaultProps = {
    dots: false,
    arrows: false,
    items: 1,
    margin: 0,
};

Slider.propTypes = {
    dots: PropTypes.bool,
    arrows: PropTypes.bool,
    items: PropTypes.number,
    margin: PropTypes.number,
};
