import React from "react";
import cn from "classnames";
import Children from "react-children-utilities";
//import PropTypes from 'prop-types';

import Preloader from "components/Preloader";
import FormActions from "./FormActions";
import FormSection from "./FormSection";
import { extendChildren, findRoot, mergeChildren } from "playground/cms/util";

import "./style.css";

const FormTabContext = React.createContext({});

function FormFooter(props) {
    const { children } = props;

    if (!React.Children.count(children)) return null;

    return (
        <div className={"form-footer cols cols-right cols-middle gap-xs"}>
            {children}
        </div>
    );
}

function FormHeader(props) {
    const { children } = props;

    if (!React.Children.count(children)) return null;

    return <div className={"form-header rows"}>{children}</div>;
}

function currentTab(children, current) {
    return React.Children.toArray(children).filter(
        (child) => child.type === FormTabContent && child.props.id === current
    );
}

function FormContent(props) {
    const { children } = props;
    const { current, tabs } = React.useContext(FormTabContext);
    const childrenTabs =
        tabs.length > 0 ? currentTab(children, current) : children;
    return <div className={"form-content"}>{childrenTabs}</div>;
}

function FormHeaderNav(props) {
    const { children } = props;
    return <div className={"form-header-nav"}>{children}</div>;
}

FormHeader.Nav = FormHeaderNav;

function FormTabs(props) {
    const { children } = props;
    const { current, onChange, tabs } = React.useContext(FormTabContext);
    return (
        <div className={"form-header-tabs cols cols-middle"}>
            {tabs.map((tab) => (
                <FormTab
                    key={tab.id}
                    onClick={() => onChange(tab.id)}
                    isSelected={current === tab.id}
                    badge={tab.badge}
                >
                    {tab.label}
                </FormTab>
            ))}
            {children}
        </div>
    );
}

function FormTab(props) {
    const { onClick, isSelected, badge, children } = props;
    return (
        <div
            className={cn("form-header-tab", {
                "is-selected": isSelected,
            })}
            onClick={onClick}
        >
            {children}
            {badge && <div className={"form-header-tab-badge"}>{badge}</div>}
        </div>
    );
}

function FormTabContent(props) {
    const { children } = props;
    return children;
}

FormTabs.Tab = FormTab;

function findContentTabs(children) {
    const tabs = [];
    if (!children) return tabs;
    React.Children.toArray(children).forEach((child) => {
        if (child.type === FormTabContent) {
            tabs.push({
                id: child.props.id,
                label: child.props.label,
                badge: child.props.badge,
            });
        }
    });
    return tabs;
}

export function FormLayout(props) {
    const { isPending, children: inputChildren } = props;
    const children = mergeChildren(inputChildren, FormLayout);

    const header = extendChildren(FormLayout.Header, children);
    const content = extendChildren(FormLayout.Content, children);
    const footer = extendChildren(FormLayout.Footer, children);

    const tabs = findContentTabs(content?.props?.children);
    const [tab, setTab] = React.useState(tabs[0]?.id);
    const tabContext = {
        current: tab,
        onChange: setTab,
        tabs,
    };

    return (
        <FormTabContext.Provider value={tabContext}>
            <div className={"form rows overflow-hide"}>
                {header}
                {content}
                {footer}
                <Preloader visible={isPending} />
            </div>
        </FormTabContext.Provider>
    );
}

function FormFieldset(props) {
    const { pad, children } = props;
    return <div className={cn("form-fieldset", { pad })}>{children}</div>;
}

function FormTitle(props) {
    return <div className={"form-title"}>{props.children}</div>;
}

const ChildrenUtils = {
    tabs: function (children) {
        return Children.filter(
            children,
            (child) => child.type === FormTabContent
        );
    },
    findLayout: function (children) {
        return findRoot(children, FormLayout);
    },
};

FormLayout.Title = FormTitle;
FormLayout.Header = FormHeader;
FormLayout.Tabs = FormTabs;
FormLayout.Tabs.Tab = FormTab;
FormLayout.Footer = FormFooter;
FormLayout.Content = FormContent;
FormLayout.Actions = FormActions;
FormLayout.Tab = FormTabContent;
FormLayout.Section = FormSection;
FormLayout.Fieldset = FormFieldset;
FormLayout.Children = ChildrenUtils;

FormLayout.propTypes = {};

FormLayout.defaultProps = {};

export default FormLayout;
