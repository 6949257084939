import {useCallback} from "react";
import useSessionEvents from "./useSessionEvents";
import UserState from "joynt/meeting/agora-ng/AgoraClient/UserState";

const usersState = new UserState();

export default function useMockEvents(channel, user) {

    const {handleEvent, users, activeSpeakers} = useSessionEvents({
        user,
        channel
    });

    const publishEvent = useCallback((type, data) => {
        const event = usersState.event({event: type, data});
        console.log(event);
        handleEvent(event);
    }, [handleEvent]);

    return {
        users,
        activeSpeakers,
        onPublishEvent: publishEvent
    }
}