import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";
import "./style-dark.css";
import "./style-sm.css";

import Preloader from "components/Preloader";

export const FeedPost = React.forwardRef((props, ref) => {

    const {children, isNew, isPending} = props;

    const className = cn("post post--lg rows gap-md", {
        "is-new": isNew
    })

    return (<div className={className} ref={ref}>
        {children}
        <Preloader visible={isPending} />
    </div>);

});

FeedPost.propTypes = {
    isPending: PropTypes.bool,
    isNew: PropTypes.bool
};
FeedPost.defaultProps = {
    isPending: false,
    isNew: false
};

export default FeedPost;
