import React from 'react';
import {connect} from 'react-redux';
import {pushRouteParams} from "state/actions/router";
import IdePage from "cms/ui/IdePage";
import {selectRouteParam} from "state/selectors/router";
import {openMediaSelect} from "state/actions/media";
import {selectWebsiteCanonicalHost} from "cms/state/selectors/cms";

const mapStateToProps = (store, props) => {
    const service = selectRouteParam(store, 'service');
    const host = selectWebsiteCanonicalHost(store, service);
    const stylesheet = `${host}/assets/styles/all.min.css`;
    return {
        stylesheet,
        service,
        devMode: !!selectRouteParam(store,'dev')
    }
};

const mapDispatchToProps = {
    onFocus: pushRouteParams,
    onSelectMedia: openMediaSelect
};

class IdePageContainer extends React.PureComponent {

    focus = (path, s, section, view, ins) => {
        const params = {
            focus: path,
            list: view,
            s,
            section
        };
        if (ins) params.ins = ins;
        this.props.onFocus(params)
    };

    render() {
        return (<IdePage
            {...this.props}
            onFocus={this.focus}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IdePageContainer);