import React from "react";
import PropTypes from "prop-types";

import { useUserProfile } from "joynt/hooks";

import AggregateRoot from "containers/AggregateRoot";
import FieldLayout from "joynt/components/Form/FieldLayout";
import Field from "containers/Field";

const flags = [
    { id: "feature_flag_secure_media", name: "Secure media" },
    { id: "feature_flag_optimized_api", name: "Use optimized api" },
    { id: "feature_flag_tags", name: "Enable tags" },
    { id: "feature_flag_follow", name: "Enable following" },
    { id: "feature_flag_draft_editor", name: "Enable draft-js editor" },
    { id: "feature_flag_workspace_roles", name: "Enable workspace roles" },
];

export function DevSettings(props) {
    const { isDev, type } = props;
    const { id } = useUserProfile();

    return (
        <AggregateRoot type={type} id={id}>
            <FieldLayout
                name={"Enable dev features"}
                description={"Experimental feature"}
            >
                <Field id={"feature_flag_dev"} fieldType={"checkbox"} />
            </FieldLayout>
            {isDev && (
                <>
                    {flags.map((flag) => (
                        <FieldLayout
                            name={flag.name}
                            description={"Experimental feature"}
                        >
                            <Field id={flag.id} fieldType={"checkbox"} />
                        </FieldLayout>
                    ))}
                </>
            )}
            {isDev && (
                <FieldLayout
                    name={"Stealth mode"}
                    description={
                        "Your actions won't trigger notifications except for messages"
                    }
                >
                    <Field id={"stealth_mode"} fieldType={"checkbox"} />
                </FieldLayout>
            )}
            {isDev && (
                <FieldLayout name={"Away mode"} description={"Yellow dot"}>
                    <Field id={"away_mode"} fieldType={"checkbox"} />
                </FieldLayout>
            )}
        </AggregateRoot>
    );
}

DevSettings.propTypes = {
    type: PropTypes.string,
};
DevSettings.defaultProps = {
    type: "db.user-profiles",
};

export default DevSettings;
