import React from "react";
//import PropTypes from 'prop-types';

import AttributeLayout from "./AttributeLayout";
import AttributeInput from "./AttributeInput";

export function Attribute(props) {
    const { index, name, value, focusId, onChange, onEdit } = props;

    return (
        <AttributeLayout
            name={
                <AttributeInput
                    value={name}
                    onChange={(v) => onChange({ name: v })}
                    onEdit={(edit) => onEdit(edit ? `${index}:name` : null)}
                    edit={focusId === `${index}:name`}
                    placeholder={"name"}
                />
            }
            value={
                <AttributeInput
                    value={value}
                    onChange={(v) => onChange({ value: v })}
                    onEdit={(edit) => onEdit(edit ? `${index}:value` : null)}
                    edit={focusId === `${index}:value`}
                    placeholder={"value"}
                />
            }
        />
    );
}

Attribute.propTypes = {};

Attribute.defaultProps = {};

export default Attribute;
