//import PropTypes from 'prop-types';

import {useUiFlag} from "state/hooks";
import {useCallback} from "react";

export default function useSessionSettings() {

    const [settings, setSettings] = useUiFlag('session.devSettings', {});

    const onChange = useCallback((key, value) => {
        setSettings(prev => ({
            ...prev,
            [key]: value
        }))
    }, [setSettings]);

    return {
        settings,
        onChange
    }


}

useSessionSettings.propTypes = {};
useSessionSettings.defaultProps = {};
