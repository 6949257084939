import { selectEntity, selectFieldValue } from "state/selectors/data";

const languages = ["pl", "en", "de", "ru", "fr"];

const nullObj = {};
const nullArr = [];

export function selectNavItems(store, props) {
    const { navigation, template_id } = props;
    if (template_id === "lang") return ["pl", "de", "en"];
    if (navigation)
        return (
            selectFieldValue(store, "cms.navigation", navigation, "links") ||
            nullArr
        );
    return nullArr;
}

export function selectSitemapLink(store, link) {
    const { custom_label, resource_id } = link;
    const page = selectEntity(store, "cms.pages", resource_id);
    const label = custom_label || page.nav_label || page.title;
    return {
        linkType: "page-ref",
        linkValue: page.id,
        linkUri: page.slug,
        label,
    };
}

export function selectLangLink(store, lang) {
    return {
        linkType: "lang",
        linkValue: lang,
        label: lang,
    };
}

export function selectLinkRef(store, { id, href, action }) {
    if (!id && action)
        return {
            linkType: action,
        };

    if (languages.indexOf(id) > -1) return selectLangLink(store, id);

    const link = selectEntity(store, "cms.nav-links", id);
    const { type } = link;

    if (type === "sitemap") return selectSitemapLink(store, link);

    if (href)
        return {
            linkType: "link",
            linkUri: href,
        };

    return nullObj;
}
