import React, { useEffect, useRef } from "react";
import Button from "components/Button";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export default function FormLayout(props) {
    const {
        title,
        children,
        onSubmit,
        submitLabel,
        onCancel,
        cancelLabel,
        header,
        footer,
        error,
        isValid,
        isPending,
    } = props;

    const formRef = useRef(null);

    useEffect(() => {
        const formEl = formRef.current;

        function handleEnterKeyPress(event) {
            const key = event.keyCode;
            if (key === 13) {
                onSubmit();
            }
        }
        // subscribe event
        formEl.addEventListener("keydown", handleEnterKeyPress);
        return () => {
            // unsubscribe event
            formEl.removeEventListener("keydown", handleEnterKeyPress);
        };
    }, [onSubmit]);

    return (
        <div className={"form-layout relative rows rows-center gap-md"}>
            <Preloader visible={isPending} />
            {header}
            <div className={"type-heading"}>{title}</div>
            {error && (
                <div className={"form-status form-status--error"}>{error}</div>
            )}
            <form
                ref={formRef}
                className={"login-form rows gap-md phone@pad-md"}
                onSubmit={onSubmit}
            >
                <div className={"rows gap-md"}>
                    <div className={"rows gap-sm"}>{children || null}</div>
                    <div className={"cols-center cols gap-xs"}>
                        {onCancel ? (
                            <Button
                                onClick={onCancel}
                                size={"large"}
                                color={"default"}
                            >
                                {cancelLabel}
                            </Button>
                        ) : null}
                        {onSubmit ? (
                            <Button
                                onClick={onSubmit}
                                disabled={!isValid}
                                size={"large"}
                            >
                                {submitLabel}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </form>
            {footer}
        </div>
    );
}

FormLayout.propTypes = {};
FormLayout.defaultProps = {};
