import React from "react";
import SectionPreviewContainer from "./../SectionPreview";
//import PropTypes from 'prop-types';

import "./style.css";

function Content(props) {
    const { data, onContextMenu } = props;
    const { title, description, used_in } = data;
    return <div>{description || title}</div>;
}

export function SectionGalleryItem(props, ref) {
    const {} = props;
    return (
        <SectionPreviewContainer
            {...props}
            className={"section-gallery-item"}
            ref={ref}
            Content={Content}
        />
    );
}

// SectionGalleryItem.propTypes = {};
// SectionGalleryItem.defaultProps = {};

const SectionGalleryItemRef = React.forwardRef(SectionGalleryItem);

export default SectionGalleryItemRef;
