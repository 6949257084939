import { selectEntityData } from "state/selectors/data";

import {
    SESSION_TYPE_NETWORKING,
    SESSION_TYPE_NETWORKING_TABLE, SESSION_TYPE_WEBINAR,
    SESSION_TYPE_WITNESS,
} from "joynt/config/sessions";

export function selectNodeFlowPath(store, id) {
    const {
        event_state,
        session_type,
        session_flow,
        session_flow_segment: currentSegment,
    } = selectEntityData(store, "db.nodes", id);

    let flow = session_flow;
    let segment = currentSegment;

    if (
        !flow &&
        [
            SESSION_TYPE_WITNESS,
            SESSION_TYPE_WEBINAR,
            SESSION_TYPE_NETWORKING,
            SESSION_TYPE_NETWORKING_TABLE,
        ].includes(session_type)
    ) {
        flow = session_type;
    }

    if (!flow) flow = "default";
    if (event_state === "ended") segment = "end";

    if (!segment) return flow;
    return [flow, segment].join(".");
}
