import React, {useEffect} from 'react';
import {useApiActions, usePending, useUiFlag} from "state/hooks";
import {disableNotifications, enableNotifications} from "joynt/state/actions/notifications";
import Button from "components/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//import PropTypes from 'prop-types';

export default function EnableNotifications(props) {

    //const {} = props;
    const [enabled, setIsEnabled] = useUiFlag('notifications.push');

    useEffect(() => {
        navigator.serviceWorker.getRegistration()
            .then((reg) => setIsEnabled(!!reg));
    }, [setIsEnabled]);

    const {onSubscribe, onUnsubscribe} = useApiActions({
        onSubscribe: enableNotifications,
        onUnsubscribe: disableNotifications
    });
    const pending = usePending('notifications-register');
    return (<Button onClick={enabled ? onUnsubscribe : onSubscribe}>
        { pending || enabled === null ? <CircularProgress /> : <>
            {enabled ? 'Disable' : 'Enable'}
        </> }
    </Button>);

}

EnableNotifications.propTypes = {};
EnableNotifications.defaultProps = {};
