import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import PageTitleSlug from "./../PageTitleSlug";
import Field from "containers/Field/Field";
import { useApiActions, useData } from "state/hooks";
import { createPage } from "cms/state/actions/pages";
import DragSectionPrompt from "playground/cms/views/Sections/DragSectionPrompt/DragSectionPrompt.container";
import { ListItem } from "playground/cms/views/Resources";
import { FormSection } from "playground/cms/Form";

function CreateFrom(props) {
    const { type, id } = props;

    return (
        <>
            <FormSection
                variant={"contained"}
                name={"Skopiuj treść i sekcje z"}
            >
                <div className={"list"}>
                    <ListItem type={type} id={id} />
                </div>
            </FormSection>
            <DragSectionPrompt />
        </>
    );
}

export function CreatePageForm(props) {
    //props.onSave

    const { onSubmitCreate } = useApiActions({
        onSubmitCreate: createPage,
    });

    const handleCreate = () => {
        onSubmitCreate(props.type, props.id);
    };

    const { create_from } = useData(props);

    return (
        <FormLayout {...props}>
            <FormLayout.Content>
                <FormLayout.Tab id={"create-page"}>
                    <div className={"pad-lg rows gap-lg"}>
                        <h1>New {props.type}</h1>
                        <div className={"rows gap-md"}>
                            {create_from && (
                                <CreateFrom
                                    type={props.type}
                                    id={create_from}
                                />
                            )}
                            {props.type === "cms.layouts" ? (
                                <Field
                                    id={"description"}
                                    label={"Nazwa szablonu"}
                                />
                            ) : (
                                <PageTitleSlug {...props} />
                            )}
                            <div>
                                Podstrona nie będzie widoczna live. W następnym
                                kroku można dodać zdjęcia i pozostałą treść
                            </div>
                            <div>
                                {props.isValid ? "IS VALID" : "is not valid"}
                            </div>
                        </div>
                        <FormLayout.Actions
                            {...props}
                            onSubmit={handleCreate}
                        />
                    </div>
                </FormLayout.Tab>
            </FormLayout.Content>
            {/*<FormLayout.Footer>*/}
            {/*    <FormLayout.Actions {...props} />*/}
            {/*</FormLayout.Footer>*/}
        </FormLayout>
    );
}

CreatePageForm.propTypes = {};

CreatePageForm.defaultProps = {};

export default CreatePageForm;
