import React, {useState} from 'react';
import {useNodeData} from "joynt/hooks";
import {useSelector} from "react-redux";
import {selectDailySessions} from "joynt/components/Schedule/selectors";
import {SessionItem} from "joynt/components/Schedule/SessionList";
import StageSession from "./StageSession";
import {useMoment} from "joynt/components/DateTimePicker/hooks";

//import PropTypes from 'prop-types';

function ScheduleList(props) {
    const {
        isOpen,
        selectedDay,
        children,
        hasMore,
        onToggle
    } = props;

    const moment = useMoment(selectedDay);
    const format = "DD MMM";

    return <div className={"stage-schedule rows gap-xs"}>
        <div className={"stage-schedule__header"}>
            <strong>{moment.format(format)}</strong> schedule
        </div>
        {hasMore && <div
            className={"stage-schedule__toggle"}
            onClick={onToggle}>
            {!isOpen ? "Show past sessions" : "Hide past sessions"}
        </div>}

        {children}
    </div>;
}

export function StageSessionList(props) {

    const {
        id,
        list,
        selectedDay
    } = props;

    const {current_event, next_event} = useNodeData(id);
    const stageSessionId = current_event || next_event;

    const sessions = useSelector(s => selectDailySessions(s, list, selectedDay));
    //const prevSessions = sessions.slice(0, sessions.indexOf(stageSessionId));
    const nextSessions = sessions.slice(sessions.indexOf(stageSessionId)+1);

    const [showAll, setShowAll] = useState(false);
    const sessionsList = showAll ? sessions : nextSessions;

    return <div className={"stage-sessions"}>
        {sessions.indexOf(stageSessionId) > -1 && <StageSession
            id={stageSessionId}
        />}
        {sessions.length > 0 && <ScheduleList
            selectedDay={selectedDay}
            isOpen={showAll}
            onToggle={()=>setShowAll(!showAll)}
            hasMore={sessions.length > nextSessions.length}
        >
            {sessionsList.map(session => {
                return <SessionItem key={session} id={session} />
            })}
        </ScheduleList>}
    </div>;

}

StageSessionList.propTypes = {};
StageSessionList.defaultProps = {};

export default StageSessionList;
