import React from 'react';
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";
import Icon from "components/Icon";

export function TaskCheck(props) {

    const {
        onClick,
        value
    } = props;

    const click = (e) => {
        e.stopPropagation();
        onClick();
    }

    return (<div
        className={cn("task-check", {
            "is-checked": value
        })}
        onClick={click}
    >
        <Icon>mui-check</Icon>
    </div>);

}

TaskCheck.propTypes = {};
TaskCheck.defaultProps = {};

export default TaskCheck;
