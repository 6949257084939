import React, { useState } from "react";
import Field from "containers/Field";
import { useData } from "state/hooks";
import Media from "playground/cms/fields/Media";

//import PropTypes from 'prop-types';

function hasContent(field, data) {
    if (field === "media") return data?.[field]?.length;
    return !!data[field];
}

export function ItemFormContent(props) {
    const data = useData(props);
    const { schema } = props;
    const [showAllFields, setShowAllFields] = useState(false);
    const schemaFields = Object.keys(schema || {});
    const isVisible = (field) => {
        return (
            schemaFields.includes(field) ||
            hasContent(field, data) ||
            showAllFields
        );
    };
    return (
        <div className={"pad-md rows gap-sm"}>
            {isVisible("title") && <Field id={"title"} label={"Title"} />}
            {isVisible("headline") && (
                <Field id={"headline"} label={"Headline"} />
            )}
            {isVisible("text") && (
                <Field id={"text"} label={"Headline"} fieldType={"rich"} />
            )}
            {isVisible("icon") && (
                <Field id={"icon"} label={"Wybierz ikonę"} fieldType={"icon"} />
            )}
            {isVisible("media") && (
                <Field
                    id={"media"}
                    label={"Media"}
                    fieldType={"media"}
                    FieldComponent={Media}
                />
            )}
            <div onClick={() => setShowAllFields(!showAllFields)}>
                {!showAllFields ? "pokaż wszystko" : "ukryj"}
            </div>
        </div>
    );
}

ItemFormContent.propTypes = {};

ItemFormContent.defaultProps = {};

export default ItemFormContent;
