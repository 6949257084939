export default class EventHandler {

    constructor(client) {
        this.client = client;
        this.reset();
    }

    reset() {
        this.eventQueue = [];
        this.handler = null;
    }

    bindEventHandler(handler) {
        this.handler = handler;
        this.flushEventQueue();
    }

    trigger(event) {
        if (this.handler) {
            return this.handler(event);
        }

        this.eventQueue.push(event);
    }

    flushEventQueue() {
        this.eventQueue.forEach(event => {
            this.trigger(event);
        });
        this.eventQueue = [];
    }

}