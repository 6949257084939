import React, { useCallback } from "react";
import { useData, useApiActions, useRouteParam } from "state/hooks";
import { entityDelete } from "state/actions/delete";
import { toggleProperty } from "cms/state/actions/cms";
import MenuItem from "components/context-menu/MenuItem";
import { useCreate } from "playground/cms/hooks";

//import PropTypes from 'prop-types';

export function ContextMenuItems(props) {
    const { type, id } = props;
    const { active } = useData(props);
    const { onDelete, onToggle } = useApiActions({
        onDelete: entityDelete,
        onToggle: toggleProperty,
    });
    const deleteUrl = type.replace(".", "/") + "/:id";
    const handleDelete = useCallback(
        () => onDelete(type, id, null, deleteUrl),
        [type, id, onDelete, deleteUrl]
    );
    const toggle = useCallback(
        () => onToggle(type, id, "active"),
        [type, id, onToggle]
    );
    const [, setId] = useRouteParam("id");
    const { onCreate } = useCreate({ type, setId });
    const onDuplicate = useCallback(
        () => onCreate({ create_from: id }),
        [onCreate, id]
    );
    return [
        <MenuItem
            key={"ctx-active"}
            onClick={toggle}
            icon={active ? "mui-close" : "mui-check"}
            primaryText={active ? "Disable" : "Enable"}
        />,
        <MenuItem
            key={"ctx-delete"}
            onClick={handleDelete}
            icon={"mui-delete"}
            primaryText={"Delete"}
        />,
        <MenuItem
            key={"ctx-copy"}
            onClick={onDuplicate}
            icon={"mui-content-copy"}
            primaryText={"Duplicate"}
        />,
    ];
}

ContextMenuItems.propTypes = {};

ContextMenuItems.defaultProps = {};

export default ContextMenuItems;
