import React from "react";
import { connect } from "react-redux";

import { selectEntity } from "state/selectors/data";
import { login, loginSocial, passwordReset, signup } from "state/actions/auth";
import { isPending } from "state/selectors/ui";
import { pushRoute, pushRouteParams } from "state/actions/router";
import { isValidForm, selectFormError } from "state/selectors/validation";
import AggregateRoot from "containers/AggregateRoot";
import { selectRouteParam } from "state/selectors/router";
import { withApiContext, bindApiActions } from "containers/context/ApiContext";
import LoginForm from "components/auth/LoginForm";
import SignupForm from "components/auth/SignupForm";
import PasswordResetForm from "components/auth/PasswordResetForm";

const mapStateToProps = (store, props) => {
    const view = props.view || "login";
    const data = selectEntity(store, "ui.forms", view);
    return {
        id: selectRouteParam(store, "id"),
        entityType: "ui.forms",
        entityId: view,
        username: data.username,
        password: data.password,
        pending: isPending(store, view),
        valid: isValidForm(store, "ui.forms", view),
        error: selectFormError(store, "ui.forms", view),
        view,
        expiresAt: localStorage.getItem("expires_at"),
        inviteToken: selectRouteParam(store, "inviteToken"),
        redirectUri: selectRouteParam(store, "redirectUri"),
    };
};

const mapDispatchToProps = bindApiActions(
    {
        onLogin: login,
        onSignUp: signup,
        onResetPassword: passwordReset,
    },
    {
        onSocialLogin: loginSocial,
        push: pushRoute,
        pushRouteParams: pushRouteParams,
    }
);

const views = {
    login: LoginForm,
    signup: SignupForm,
    "password-reset": PasswordResetForm,
};

class AuthForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { inviteToken } = this.props;
        if (inviteToken) {
            this.setState({ inviteToken });
            this.props.pushRouteParams({ inviteToken: null });
        }
    }

    submit = () => {
        const { onLogin, username, password } = this.props;
        onLogin(username, password);
    };

    signup = () => {
        const { onSignUp, username, password, id } = this.props;
        const { inviteToken } = this.state;
        onSignUp(username, password, inviteToken, id);
    };

    reset = () => {
        const { onResetPassword, username } = this.props;
        onResetPassword(username);
    };

    social = (connection) => {
        const { onSocialLogin } = this.props;
        onSocialLogin(connection);
    };

    showLogin = () => {
        this.changeView("login");
    };

    showSignup = () => {
        this.changeView("signup");
    };

    showPasswordReset = () => {
        this.changeView("password-reset");
    };

    changeView = (view) => {
        if (this.props.onChangeView) {
            this.props.onChangeView(view);
            return;
        }
        const { push, id, redirectUri } = this.props;
        let url = `${view}`;
        if (redirectUri) url += `?redirectUri=${redirectUri}`;
        if (!redirectUri && id) url += `?id=${id}`;
        push(url);
    };

    render() {
        //const { inviteToken } = this.state;
        const { entityType, entityId, view } = this.props;

        const formProps = {
            title: this.props.title,
            header: this.props.header,
            footer: this.props.footer,
            isValid: this.props.valid,
            urls: this.props.urls,
            onLogin: this.submit,
            onSignup: this.signup,
            onResetPassword: this.reset,
            onChangeView: this.changeView,
            isPending: this.props.pending,
            error: this.props.error,
            enableSocial: this.props.enableSocial,
        };

        const FormComponent = views[view];
        return (
            <AggregateRoot
                type={entityType}
                id={entityId}
                validate={true}
                disableSubmit={true}
            >
                <FormComponent {...formProps} onChangeView={this.changeView} />
            </AggregateRoot>
        );
    }
}

export default withApiContext()(
    connect(mapStateToProps, mapDispatchToProps)(AuthForm)
);
