import React from "react";
import Lottie from "react-lottie";
import animationData from "./success-check.json";

function SuccessCheck(props) {
    const options = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return <Lottie options={options} height={"120px"} width={"120px"} />;
}

SuccessCheck.propTypes = {};

SuccessCheck.defaultProps = {};

export default SuccessCheck;
