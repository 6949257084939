import React from "react";
import cn from "classnames";

import { MessageTime } from "joynt/components/messages";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

export function MessageHeaderSimple(props) {
    const { avatar, className, author, createdAt, isOwn, unread } = props;

    if (!avatar) return null;

    return (
        <header
            data-private
            className={cn(
                "message__header message__header--simple cols gap-3",
                !avatar && "message__header--chat--no-avatar",
                isOwn && "message__header--own",
                className
            )}
        >
            <div className={"message__header__avatar col"}>{avatar}</div>
            <div className={"message__header__author col"}>
                {isOwn ? "You" : author}
            </div>
            {createdAt && (
                <div className={cn("message__header__status col")}>
                    <span
                        className={cn(
                            "message__header__date",
                            unread && "message__header__date--unread"
                        )}
                    >
                        <MessageTime time={createdAt} format={"short"} />
                    </span>
                </div>
            )}
        </header>
    );
}

MessageHeaderSimple.propTypes = {
    avatar: MessagePropTypes.avatar,
    className: MessagePropTypes.className,
    author: MessagePropTypes.author,
    createdAt: MessagePropTypes.createdAt,
    isOwn: MessagePropTypes.isOwn,
    unread: MessagePropTypes.unread,
};

export default MessageHeaderSimple;
