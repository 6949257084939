import React, {useCallback} from "react";
//import PropTypes from "prop-types";
import MediaPreview from "./MediaPreview";
import {useData, useRouteParam} from "state/hooks";
import {ROUTE_MEDIA} from "joynt/config";
import {enforceHttps} from "util/uri";
import env from "util/env";
import { getIn } from "lodash-redux-immutability";
import {useSelector} from "react-redux";

export default function MediaPreviewContainer(props) {
    const {} = props;
    const [item, setItem] = useRouteParam(ROUTE_MEDIA);
    const [path, id] = item ? item.split(':') : [];
    const data = useData({type: "db.media", id});
    const extension = data.file ? data.file.split('.').pop() : null;
    const {file} = data;
    const files = useSelector(store => path ? getIn(store.data, path.split('/')) : null) || [];
    const currentIndex = files.indexOf(id);
    const next = files[currentIndex + 1] || files[0];
    const prev = files[currentIndex - 1] || files[files.length-1];

    const close = useCallback(() => setItem(null), [setItem]);
    const show = useCallback(id => setItem(`${path}:${id}`), [path, setItem]);

    const download = useCallback(() => {
        const host = enforceHttps(env('FILES_CDN'));
        const url = `${host}${file}`;
        window.open(url);
    }, [file]);

    return <MediaPreview
        {...props}
        id={id}
        open={!!item}
        file={data.file}
        url={data.url}
        fileType={data.type}
        fileExtension={extension}
        onClose={close}
        onRequestDownload={download}
        onShowNext={next ? ()=>show(next) : null}
        onShowPrev={prev ? ()=>show(prev) : null}
    />;
}

MediaPreviewContainer.defaultProps = {};
MediaPreviewContainer.propTypes = {};