import React from "react";
import PropTypes from "prop-types";

import "./style.css";
import "./mobile.css";

export function SidebarTray(props) {
    const { header, footer, children } = props;
    return (
        <div className={"sidebar-tray"}>
            {header && <div className={"sidebar-tray__header"}>{header}</div>}
            <div className={"sidebar-tray__content"}>{children}</div>
            {footer && <div className={"sidebar-tray__footer"}>{footer}</div>}
        </div>
    );
}

SidebarTray.propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
};
SidebarTray.defaultProps = {};

export default SidebarTray;
