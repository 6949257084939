import env from 'util/env';

const COOKIE_DOMAIN = env('EXTENSION_AUTH_DOMAIN');

export function chromeApi() {
    if (undefined !== window.chrome) return window.chrome;
    return false;
}

export function authToken() {
    if (!chromeApi()) return false;
    const chrome = chromeApi();
    return new Promise((resolve, reject) => {
        chrome.cookies.get(
            {url: COOKIE_DOMAIN, name: 'access_token'},
            (value) => {
                if (!value) {
                    reject();
                    return;
                }
                resolve(value.value);
            }
        )
    })
}

export function currentTabUrl() {
    return new Promise((resolve, reject) => {
        const chrome = chromeApi();
        if (!chrome || !chrome.tabs || !chrome.tabs.query) {
            resolve(window.location.href);
            return;
        }
        chrome.tabs.query({active: true, currentWindow: true}, ([tab]) => {
            resolve(tab.url);
        });
    });
}

export function getSelection() {
    return new Promise((resolve, reject) => {
        const chrome = chromeApi();
        if (!chrome.tabs) {
            resolve('Lorem ipsum dolor');
        }
        chrome.tabs.executeScript( {
            code: "window.getSelection().toString();"
        }, function(selection, err) {
            if (err) {
                reject(err);
                return;
            }
            resolve(selection ? selection[0] : null);
        });
    })
}