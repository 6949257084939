import { useSelector } from "react-redux";

export function useRaisedHandList(id) {
    return useSelector((s) => {
        const participants = s?.presence?.[id]?.session || {};
        const raisedHand = [];
        Object.keys(participants).forEach(function (participantId) {
            if (participants[participantId].connected && participants[participantId].hand)
                raisedHand.push(participantId);
        });
        return raisedHand;
    });
}
