import React from "react";
import Field from "containers/Field";

export default function IdentityStep2() {
    return (
        <>
            <div className={"type-heading-sm"}>2. About you</div>
            <div className={"rows gap-sm"}>
                <Field
                    id={"description"}
                    label={"Short description"}
                    fieldType={"text"}
                    optional
                />
                <Field optional id={"social_linkedin"} label={"Linkedin"} />
                <Field optional id={"social_twitter"} label={"Twitter"} />
            </div>
        </>
    );
}
