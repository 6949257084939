import React from "react";
import PropTypes from "prop-types";

import KeepIcon from "joynt/components/KeepIcon";
import Button from "components/Button";
import Icon from "components/Icon";
import { SessionDate } from "joynt/components/TimeUtils";

import "./style.css";
import IconButton from "components/IconButton";
import Page from "joynt/views/Page";
import { LiveNowBadge } from "joynt/components/LiveNow";
import { DEFAULT_CARD_COLOR } from "joynt/config";
import SessionControls from "joynt/components/SessionControls";
import RolesList from "./RolesList";
import ParticipantsList from "./ParticipantsList";
import {
    SessionConfirmActions,
    SessionStatus,
} from "joynt/components/SessionParticipation";
import NodeLink from "joynt/components/Post/NodeLink";
import SessionCover from "joynt/components/SessionCover";

export function SessionPreview(props) {
    const {
        id,
        onLink,
        name,
        color,
        parent,
        isLive,
        start,
        end,
        image,
        onClose,
    } = props;

    return (
        <div className={"session-preview"}>
            <div className={`session-preview__header`}>
                <div className={`session-preview__header_bg bg-${color}`} />
                <div className={"session-preview__header_content"}>
                    {isLive && <LiveNowBadge animate={true} />}
                    <div>
                        <SessionDate start={start} end={end} />
                    </div>
                </div>
                <div className={"session-preview__close"}>
                    <IconButton onClick={onClose} icon={"mui-close"} />
                </div>
            </div>
            <div className={"session-preview__content"}>
                <div className={"cols cols-spread"}>
                    <div className={"rows gap-3"}>
                        <div className={"session-preview__title grow"}>
                            {name}
                        </div>
                        <div className={"cols gap-xs"}>
                            <div className={"o-50"}>in</div>
                            <NodeLink id={parent} />
                        </div>
                    </div>
                    <KeepIcon id={id} />
                </div>
                <Page id={id} />
                <RolesList id={id} />
                {image ? (
                    <div className={"session-preview__image"}>
                        <SessionCover id={id} />
                    </div>
                ) : (
                    <SessionStatus id={id} />
                )}
                <ParticipantsList id={id} />
            </div>
            <div className={"session-preview__footer"}>
                <div className={"rows rows-center gap-sm"}>
                    <div className={"cols cols-center cols-middle gap-sm"}>
                        <SessionConfirmActions id={id} />
                        <SessionControls id={id} size={"medium"} />
                    </div>
                    <Button
                        onClick={onLink}
                        variant={"text"}
                        endIcon={<Icon>mui-arrow_forward</Icon>}
                    >
                        Go to session page
                    </Button>
                </div>
            </div>
        </div>
    );
}

SessionPreview.propTypes = {
    color: PropTypes.string,
    onJoin: PropTypes.func,
    isJoined: PropTypes.bool,
};
SessionPreview.defaultProps = {
    color: DEFAULT_CARD_COLOR,
    isJoined: false,
};

export default SessionPreview;
