import console from './schema/console';
import db from './schema/db';
import cms from './schema/cms';
import booking from './schema/booking';
import joint from './schema/joint';

export default {
    ...console,
    ...db,
    ...cms,
    ...booking,
    ...joint
};