import React, {useContext, useMemo, useState} from 'react';
//import PropTypes from 'prop-types';
import NotificationList from "joynt/notifications/NotificationList";
import Entrypoint from "joynt/Entrypoint";
import env from "util/env";
import {enforceHttps, serializeQueryParams} from "util/uri";
import useFetch from "state/hooks/useFetch";
import {useData} from "state/hooks";
import {useSelector} from "react-redux";
import {selectList} from "state/selectors/data";
import ApiContext from "containers/context/ApiContext";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";

const API_BASE_URL = env('ZUU_CONSOLE_API_ENDPOINT');

function PushPreview({id}) {
    const type = 'app.push-notification-preview';

    const pending = useFetch({
        type,
        id,
        url: `joynt/notifications/${id}/preview/push`
    });
    const data = useData({type, id});
    const notification = useData({type: 'db.notifications', id});
    const activity = useData({type: 'db.activity', id: notification.activity});

    if (pending) return <div>Loading...</div>;

    return <div className={'rows gap-lg'}>
        <div>{notification.notification_group} {notification.notification_type}</div>
        <div>{activity.created_at} {notification.created_at}</div>
        <div>
            {data.email_title}
        </div>
        <div className={'rows gap-sm'}>
            <strong>{data.title}</strong>
            <p>{data.body}</p>
            <div className={'o-20 type-sm'}>{data.tag}</div>
        </div>
    </div>;
}

function WithUserApiKey(props) {
    const {children} = props;
    const type = 'console.user-api-keys';
    const list = 'user-api-keys'
    const pending = useFetch({
        url: 'joynt/user/api-keys',
        type,
        list
    });
    const keys = useSelector(s=>selectList(s, type, list));
    const key = keys.length ? keys[0].key : null;
    const prev = useContext(ApiContext);
    const value = useMemo(() => ({
        ...prev,
        apiKey: key
    }), [prev, key]);

    if (pending) return <div>Waiting for user token</div>;
    if (!value.apiKey) return <div>No api key for user</div>;

    return <ApiContext.Provider value={value}>
        {children}
    </ApiContext.Provider>;
}

function ActivitySettings(props) {
    return <div className={'rows gap-sm pad-md'}>
        <AggregateRoot {...props} disableSubmit={true}>
            <Field fieldType={"text"} id={"limit"} label={"Limit"} />
            <Field fieldType={"checkbox"} id={"unseen"} label={"Only show unseen events"} />
        </AggregateRoot>
    </div>;
}

export function NotificationDevConsoleContainer(props) {
    return <Entrypoint>
        <WithUserApiKey>
            <NotificationDevConsole {...props} />
        </WithUserApiKey>
    </Entrypoint>;
}

export function NotificationDevConsole(props) {

    const [id, setId] = useState();
    const [view, setView] = useState('notifications');

    const {apiKey} = useContext(ApiContext);
    const activitySettings = useData({type: 'app.activity-preview-settings', id: 'preview'});
    const activityParams = serializeQueryParams({...activitySettings, api_key: apiKey});

    const urls = {
        notifications: `notifications/${id}/preview/email?api_key=${apiKey}`,
        activity: `notifications/preview/activity?${activityParams}`
    }

    const url = enforceHttps(`${API_BASE_URL}/joynt/${urls[view]}`);
    const listUrl = `joynt/user/notifications/tag`;

    return (
        <div className={"cols overflow-hide h-100v"}>
            <div className={"rows overflow-hide w-lg"}>
                <div className={"cols gap-md pad-md"}>
                    <div onClick={()=>setView('notifications')}>Notifications</div>
                    <div onClick={()=>setView('activity')}>Activity</div>
                </div>
                { view === 'activity' && <ActivitySettings
                    type={'app.activity-preview-settings'}
                    id={'preview'}
                /> }
                { view === 'notifications' && <div className={"rows overflow-scroll"}>
                    <NotificationList
                        onClick={setId}
                        url={listUrl}
                    />
                </div> }
            </div>
            <div className={"default rows grow"}>
                {(id || view !== 'notifications') && <iframe
                    src={url}
                    title={'Notification preview'}
                    className={'grow'}
                />}
            </div>
            { view === 'notifications' && <div className={"w-md pad-md overflow-scroll"}>
                {id && <PushPreview id={id} />}
            </div> }
        </div>
    );
}

NotificationDevConsole.propTypes = {};
NotificationDevConsole.defaultProps = {};

export default NotificationDevConsoleContainer;