import React from "react";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import SelectField from "joynt/edit/SelectField";
import { viewTypes } from "joynt/config/types";
import FieldLayout from "joynt/components/Form/FieldLayout";

import { AdvancedSettings, DangerZone, RolesAndAccess } from "./fields";

import { useEntity } from "state/hooks";
import Page from "joynt/views/Page";
import SessionActivity from "joynt/edit/types/Session/SessionActivity";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_FOLLOW } from "joynt/config/features";

function Form(props) {
    const { id } = useEntity();
    const isDev = useFeatureEnabled(FEATURE_FOLLOW);

    return (
        <FieldsLayout>
            <FieldGroup label={"Space name and description"}>
                <Field id={"name"} label={"Name"} validate={true} />
                <Field id={"description"} label={"Description"} />
            </FieldGroup>

            <FieldGroup label={"Description"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>

            <FieldGroup label={"Color"}>
                <Field
                    id={"color"}
                    fieldType={"ui-color-picker"}
                    label={"Choose color"}
                />
            </FieldGroup>

            <RolesAndAccess />

            <AdvancedSettings>
                <FieldLayout name={"Spots available"}>
                    <Field id={"event_max_participants"} fieldType={"text"} />
                </FieldLayout>
                {isDev && <SessionActivity {...props} />}
                <Field id={"session_type"} label={"Session type"} />
                <FieldLayout name={"Default view"}>
                    <Field id={"default_view"}>
                        {({ onChange, value }) => (
                            <SelectField
                                onChange={onChange}
                                value={value}
                                options={viewTypes}
                            />
                        )}
                    </Field>
                </FieldLayout>
                <DangerZone />
            </AdvancedSettings>
        </FieldsLayout>
    );
}

const steps = {
    step1: {
        component: Form,
        label: "Settings",
    },
};

export default function Space(props) {
    return <MultiStepForm {...props} steps={steps} showHeader={false} />;
}

Space.propTypes = {};
Space.defaultProps = {};
