import React from 'react';
//import PropTypes from 'prop-types';
import cn from 'classnames/dedupe';
import Text from "cms/components/Text";
import Icon from "components/Icon";

export default class MediaElement extends React.PureComponent {

    render() {
        const {
            icon,
            className,
            classes,
            ...other
        } = this.props;

        return (<div className={cn(classes.item,className)}>
            { icon ? <div className={cn('l-area', classes.icon)}>
                <Icon>{icon}</Icon>
            </div> : null }
            <div className={cn('l-area', classes.content)}>
                <Text {...other} classes={classes} />
            </div>
        </div>);
    }

}

MediaElement.propTypes = {};

MediaElement.autoFillProps = [
    'title',
    'icon',
    'headline'
];