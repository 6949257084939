import Text from "./Text";
import Layout from "./Layout";
import Button from "./Button";
import Icon from "./Icon";
import Template from "./Template";
import Nav from "./Nav";
import Card from "./Card";
import List from "./List";
import Link from "./Link";
import Query from "./Query";
import Value from "./Value";
import Tags from "./Tags";
import Form from "./Form";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormSelectOption from "./FormSelectOption";
import FormConsent from "./FormConsent";
import Section from "./Section";
import Plan from "./Plan";
import Map from "./Map";
import SchemaForm from "./SchemaForm";

export default {
    "cms.sections": Section,

    text: Text,
    structured: Layout,
    button: Button,
    icon: Icon,
    template: Template,
    nav: Nav,
    card: Card,
    list: List,
    link: Link,
    query: Query,
    value: Value,
    tags: Tags,
    form: Form,
    "form-input": FormInput,
    "form-select": FormSelect,
    "form-select-option": FormSelectOption,
    "form-consent": FormConsent,
    // plan: Plan,
    map: Map,

    default: SchemaForm,
};
