import React, { useCallback, useMemo } from "react";
import { SessionNotificationContext } from "joynt/meeting/notifications/context";
import { useActions, useList } from "state/hooks";
import { create } from "state/actions/create";
import { useMediaDevices } from "joynt/meeting/devices";
import { useNetworkState } from "joynt/network-state";
import { listRemove } from "state/actions/list";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
//import PropTypes from 'prop-types';

function useNotificationsLogic(id) {
    const { onCreate, onDismiss } = useActions({
        onCreate: create,
        onDismiss: listRemove,
    });

    const type = "app.session-notifications";
    const list = `session.${id}`;

    const [{ audioStatus }] = useConnectionState(id);
    const hasAudio = audioStatus === "success";

    const onNotify = useCallback(
        (data) => {
            onCreate(type, data, list, -1);
        },
        [type, list, onCreate]
    );

    const dismiss = useCallback(
        (id) => {
            onDismiss(type, list, id);
        },
        [onDismiss, type, list]
    );

    const { pending, cameraAccess, cameraAvailable } = useMediaDevices();
    const {
        quality: { session: network, offline_time },
    } = useNetworkState();

    const { items } = useList(type, list) || [];

    const notifications = useMemo(() => {
        let notifications = items.slice();

        if (network === "offline") {
            if (offline_time > 5) {
                notifications.push("NO_INTERNET_FOR_GOOD");
            } else {
                notifications.push("NO_INTERNET");
            }
        }

        if (network === "bad") {
            notifications.push("BAD_CONNECTION_QUALITY");
        }

        if (!cameraAvailable) {
            if (cameraAccess !== "granted") {
                if (cameraAccess === "prompt") {
                    notifications.push("CAMERA_ACCESS_PROMPT");
                } else {
                    notifications.push("CAMERA_ACCESS_DENIED");
                }
            } else {
                if (!pending) notifications.push("NO_CAMERA_AVAILABLE");
            }
        }

        notifications = notifications.filter((item) => {
            if (item === "AUDIO_LEVEL_TOO_LOW") {
                return hasAudio;
            }
            return true;
        });

        return notifications;
    }, [
        items,
        pending,
        network,
        offline_time,
        cameraAvailable,
        cameraAccess,
        hasAudio,
    ]);

    return useMemo(
        () => ({
            notifications,
            count: notifications.length,
            onNotify,
            onDismiss: dismiss,
        }),
        [notifications, onNotify, dismiss]
    );
}

export function NotificationsProvider(props) {
    const { id, children } = props;

    const context = useNotificationsLogic(id);

    return (
        <SessionNotificationContext.Provider value={context}>
            {children || null}
        </SessionNotificationContext.Provider>
    );
}

NotificationsProvider.propTypes = {};
NotificationsProvider.defaultProps = {};
