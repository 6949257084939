import React from 'react';

//import PropTypes from 'prop-types';

export function SidebarContent(props) {

    const {children} = props;

    return (<div className={"sidebar-nav__content"}>
        {children}
    </div>);

}

SidebarContent.propTypes = {};
SidebarContent.defaultProps = {};

export default SidebarContent;
