import React from 'react';
import PropTypes from 'prop-types';
import Button from "components/Button";

import {
    POST_TYPE_ALBUM,
    POST_TYPE_POLL,
    POST_TYPE_TEXT
} from "joynt/config";

const submitLabels = {
    [POST_TYPE_TEXT]: "Post now",
    [POST_TYPE_POLL]: "Create poll",
    [POST_TYPE_ALBUM]: "Post now",
};

export function PostCreateFooter(props) {

    const {
        type,
        children,
        onReject,
        onSubmit
    } = props;

    return (<div className={"cols cols-spread cols-middle"}>
        {children}
        <div className={"cols gap-xs"}>
            {onReject && (
                <Button onClick={onReject} color={"default"}>
                    Cancel
                </Button>
            )}
            <Button onClick={onSubmit}>
                {submitLabels[type]}
            </Button>
        </div>
    </div>);

}

PostCreateFooter.propTypes = {
    type: PropTypes.string,
    onReject: PropTypes.func,
    onSubmit: PropTypes.func
};
PostCreateFooter.defaultProps = {};

export default PostCreateFooter;
