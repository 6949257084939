import { draftNativeBlocks, customBlockTypes } from "./types";

class BlockTypeRepository {
    constructor() {
        this.data = [];
    }

    add(blocks) {
        this.data = this.data.concat(blocks);
    }

    find(id) {
        return this.data.filter((block) => block.id === id)[0];
    }
}

export function createBlockTypes() {
    const blocks = new BlockTypeRepository();
    blocks.add(draftNativeBlocks);
    blocks.add(customBlockTypes);
    return blocks;
}

export default createBlockTypes;
