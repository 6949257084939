import {useIdentity} from "joynt/hooks/participants";
import {VideoTile} from "joynt/meeting/stage";
import Webcam from "react-webcam";
import React from "react";

export default function VideoTileContainer(props) {
    const {uid, audioTrack, videoTrack} = props.user;
    const {name} = useIdentity(uid)
    return <VideoTile
        {...props}
        identity={uid}
        name={name}
        audio={!!audioTrack}
        video={!!videoTrack}
    >
        { !!videoTrack ? <Webcam className={'stream-debug'} /> : null }
    </VideoTile>
}