import { useRouteParam } from "state/hooks";
import React from "react";
import { ResourceGroup } from "./ResourceGroup";
import { Resource } from "./Resource";

import "./style.css";

const groupLabels = {
    pages: "Podstrony",
    entries: "Moduły",
};

export function SideNav() {
    const [type, onClick] = useRouteParam("resource", {
        section: null,
        id: null,
    });
    return (
        <div className={"side-nav"}>
            {/*<div onClick={() => onClick(null)}>home</div>*/}
            <ResourceGroup
                id={"main"}
                onClick={onClick}
                active={type}
                label={"Main"}
            />
            <ResourceGroup
                id={"pages"}
                onClick={onClick}
                active={type}
                label={groupLabels.pages}
            />
            <ResourceGroup
                id={"entries"}
                onClick={onClick}
                active={type}
                label={groupLabels.entries}
            />
            <Resource
                type={"app.user-menu"}
                id={"settings"}
                onClick={onClick}
                active={type}
            />
            <Resource
                type={"app.user-menu"}
                id={"media"}
                onClick={onClick}
                active={type}
            />
        </div>
    );
}
