import React from "react";
import PropTypes from "prop-types";
import SessionCover from "./SessionCover";
import { useNodeData } from "joynt/hooks";
import { useCover } from "state/hooks";
import { SessionStatus } from "joynt/components/SessionParticipation";

export function SessionCoverContainer(props) {
    const { id } = props;
    const { media, name } = useNodeData(id);
    const image = useCover(media, [320]);

    return (
        <SessionCover
            {...props}
            image={image}
            title={name}
            status={<SessionStatus id={id} />}
        />
    );
}

SessionCoverContainer.propTypes = {
    id: PropTypes.string,
};
SessionCoverContainer.defaultProps = {};

export default SessionCoverContainer;
