export default {
    "+1": "👍",
    shock: "😮",
    surprised: "😲",
    heart_eyes: "😍",
    sweat_smile: "😅",
    laughing: "😀",
    rotfl: "🤣",
    sunglasses: "😎",
    heart: "❤️",
    interesting: "🤔",
    fire: "🔥",
    zap: "⚡",
    rocket: "🚀",
    tada: "🎉",
    champagne: "🍾",
    sparkle: "✨",
    rainbow: "🌈",
    pray: "🙏",
    dart: "🎯",
    alien: "👽",
    xd: "😆",
    mindblown: "🤯",
    whale: "🐳",
    money: "💸",
    biohazard: "☣︎",
    sad: "🙁",
    tear_smile: "🥲",
    angry: "😡",
    facepalm: "🤦‍♂️",
    facepalm_girl: "🤦‍♀️",
    vomit: "🤮",
    panic: "😱",
    poop: "💩",
    skull: "☠️",
};
