const ids = [
    'ee68258b-3d4a-4069-8d20-24f90afc5fd8',
    '083a3bae-b3db-4f58-b3c4-cc50de4db476',
    'cbe9c944-bf07-42e1-bae5-c6b49eac0f85',
    '69ef2441-235f-41f0-9cc4-1dc3a64a12f2',
    'a2690e07-154b-402f-908d-95e97bfefb72',
    'fdc953fb-dfbc-444c-ba4c-e8665af0c5e0',
    '2c2ccf51-a9d8-445f-b7d6-8c3449646d33',
    '9326a0d1-393d-44df-af93-d9575096158f',
    '3e22d300-b47b-49e5-abc0-447ac3d82e76',
    'aebdf307-6b56-49a4-b49e-29b340406c09',
    'b27be39e-346d-4dfa-b622-e04c311c7b0f',
    'f1e21259-edac-4008-9a8f-24f4fe3dd838',
    'de6ccc09-f06d-46d0-b149-f59d9bf7cdc2',
    '9aaa9b4e-c772-460c-8236-235ae6e34179',
    '4d0d7c13-d550-40fa-93af-893253d7f4f7',
    'ed3e4681-e1a8-4297-85a4-4690c90cb33a',
    '9d02bd90-827a-4bc0-9a2a-17c836ad685e',
    '09c28fea-4b9d-4e18-99b4-061c52848354',
    '5aa5e5c6-f164-46de-b647-cbf92cb16635',
    'd0499698-b8c0-40c1-b95f-dc30a61e27c5',
    'ee34813c-1805-4843-af88-e3dca5668d88',
    '944f9186-becf-40f6-8aec-4c37d8383c27',
    '92301e7c-df9f-4ad5-be29-4d857025bf33',
    '76882ecf-6295-47de-a023-66c0a6a92e8a',
    '3c2ae02e-2ab4-463c-a2c4-1d134361b592',
    '4b561512-1671-482b-9a33-136b2f0660d8',
    'ab77a570-c8c7-428a-bdf4-3b4db7b7a5b9',
    '0da8d160-69ec-4979-bcad-ac00b5f6f7a6',
    'ea9cadd0-f2ad-4494-a7e7-31cfd0fa0c28',
    '14aab9cd-7ad0-4ea7-b192-86b3c584239c',
    'e0249d8b-7ba4-45a6-b9b5-a7600b059cb4',
    'e34e7097-d858-4d4a-b991-3c69ff6e1760',
    '315ca63e-5ebc-4f12-bf57-a668aedbef45',
    'cab915ed-ebf0-41ca-8b2b-f432b5bec7a2',
    'b772e069-d92d-442b-99d5-4940fda00725',
    '9d26ae4c-7a46-4165-a546-159ce9e0b78b',
    '2dc44537-c075-4fef-bb7b-2bf008da8d5e',
    'a63b29f9-2ad4-43ba-9d53-41a7fcaf686a',
    '1cb9a31d-3873-4330-bbd3-ce7a9201507f',
    '5bbada3a-595a-4a62-8f69-8e76f1ae8869'
];

const names = [
    'Darin Ruiz',
    'Kara Valdez',
    'Joanna Stokes',
    'Clifton Hamilton',
    'Matt French',
    'Sophia Sutton',
    'Georgia Vaughn',
    'Chelsea Lambert',
    'Eunice Holland',
    'Stacy Griffin',
    'Johanna Weaver',
    'Henrietta Medina',
    'Devin Mills',
    'Rita Tyler',
    'Ellen Fox',
    'Noel Graham',
    'Lowell Goodwin',
    'Allen Ortiz',
    'Wilma Goodman',
    'Doreen Green',
    'Pamela Jackson',
    'Bertha Reynolds',
    'Cristina Fisher',
    'Myron Snyder',
    'Regina Ramirez',
    'Dallas Cain',
    'Nina Franklin',
    'Angel Moss',
    'Tony Terry',
    'Susie Jones',
    'Jack Mckenzie',
    'Lucia Frazier',
    'Hazel Andrews',
    'Shane Barton',
    'Monique Hansen',
    'Laurence Ballard',
    'Kevin Ellis',
    'Jody Vasquez',
    'Tracy Garrett',
    'Lena Morrison',
    'Floyd Cannon',
    'Emmett Tran',
    'Elijah Ross',
    'Raquel Brewer',
    'Jan Sullivan',
    'Maryann Mcbride',
    'Kate Frank',
    'Bobby Knight',
    'Vera Carson',
    'Ronald Lyons'
];

const identities = ids.map((id, i) => ({
    id,
    name: names[i]
}));

export default identities;
