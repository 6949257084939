import React from 'react';
import PropTypes from 'prop-types';
import SessionTrack from "./SessionTrack";
import {useNodeData} from "joynt/hooks";
import SpaceAvatar from "joynt/components/SpaceAvatar";

export function SessionTrackContainer(props) {

    const {id} = props;
    const {name, color} = useNodeData(id);

    return (<SessionTrack
        color={color}
        {...props}
        name={name}
        avatar={<SpaceAvatar id={id} noTooltip={true} size={"sm"} />}
    />);

}

SessionTrackContainer.propTypes = {
    id: PropTypes.string
};
SessionTrackContainer.defaultProps = {};

export default SessionTrackContainer;