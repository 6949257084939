import { useSelector } from "react-redux";
import { useActions } from "state/hooks/useActions";
import { setJoyrideId, setJoyrideRun } from "joynt/state/actions/joyride";

export function useJoyrideProvider() {
    const currentJoyride = {
        activeId: useSelector((store) => store.ui["joyride.id"]),
        run: useSelector((store) => store.ui["joyride.run"]),
    };

    const { setJoyride, setJoyrideShouldRun } = useActions({
        setJoyrideShouldRun: setJoyrideRun,
        setJoyride: setJoyrideId,
    });
    return {
        currentJoyrideId: currentJoyride?.activeId,
        currentJoyrideRun: currentJoyride?.run,
        setJoyride,
        setJoyrideShouldRun,
    };
}
