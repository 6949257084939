import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

function scrollToBottom(e) {
    e.scrollTop = e.scrollHeight;
}

export function ReverseScrollContainer({ page, item, className, pending, children }) {
    const [lastItem, setLastItem] = useState(null);
    const [ref, setRef] = useState(null);

    useEffect(() => {
        setLastItem(null);
        return () => {};
    }, []);

    useEffect(() => {
        if (ref && item !== lastItem) {
            scrollToBottom(ref);
            setLastItem(item);
        }
        if (ref && !pending && item && page === 1) {
            scrollToBottom(ref);
        }
    }, [ref, page, item, lastItem, setLastItem, pending]);

    const refCallback = (e) => {
        if (e) setRef(e);
    };

    return (
        <div className={className} ref={refCallback}>
            {children}
        </div>
    );
}

ReverseScrollContainer.propTypes = {};
ReverseScrollContainer.defaultProps = {};
