import React from "react";
import { usePending } from "state/hooks";

import FormActions from "joynt/components/Form/FormActions";
import { useNodeData } from "joynt/hooks";

import SessionPublished from "./SessionPublished";
import SessionUnpublished from "./SessionUnpublished";

//import PropTypes from 'prop-types';

export function SessionPublishStatus(props) {
    const { id, yes } = props;
    const pending = usePending(id);
    const { hidden } = useNodeData(id);

    const Component = !hidden ? SessionPublished : SessionUnpublished;

    return (
        <Component
            {...props}
            isPending={pending}
            yes={yes}
            no={yes}
            actions={<FormActions onSubmit={yes} submitLabel={"Got it"} />}
        />
    );
}

SessionPublishStatus.propTypes = {};
SessionPublishStatus.defaultProps = {};

export default SessionPublishStatus;
