import React from 'react';
//import PropTypes from 'prop-types';

export default function Value(props) {
    if (props.classes && props.classes.value) {
        return <div className={props.classes.value}>{props.value || null}</div>;
    }
    return props.value || null;
}

Value.propTypes = {};