import {findDOMNode} from "react-dom";
const moveMonitor = {y: 0};

export const collectDragActors = (props, monitor) => {
    return {
        drag: monitor ? monitor.getItem() : null,
        hover: {
            id: props.id,
            index: props.index,
            type: props.type,
            origin: props.origin,
            level: props.level
        }
    }
};


export function sortingMonitor(props, monitor, component) {
    const { drag, hover } = collectDragActors(props, monitor);

    const dragIndex = drag.index;
    const hoverIndex = hover.index;
    const dragId = drag.id;
    const hoverId = hover.id;

    // Don't replace items with themselves
    if (dragId === hoverId) {
        return false;
    }

    if (hover.origin && (drag.origin !== hover.origin)) {
        return false;
        //console.log(drag, hover, props);
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y; // - hoverBoundingRect.top;
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (
        (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) &&
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
    ) {
        return false;
    }

    // Dragging upwards
    if (
        (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) &&
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
    ) {
        return false;
    }

    //console.log(dragIndex, hoverIndex, clientOffset, hoverBoundingRect);

    let moveDelta = Math.abs(moveMonitor.y - clientOffset.y);

    if (moveDelta < 20) {
        //return false;
    }
    moveMonitor.y = clientOffset.y;

    //console.log('reorder',dragIndex,hoverIndex,hoverClientX,hoverClientY);

    return {drag, hover};
}

export default sortingMonitor;