import React from 'react';
import {connect} from 'react-redux';
import Context from "cms/components/Context";
import {MergeRewireContext} from "containers/context/RewireContext";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ContextContainer extends React.PureComponent {

    render() {
        const { id } = this.props;
        const contextPath = 'context@' + ['cms.context', id].join('/');

        return (<MergeRewireContext value={contextPath}>
            <Context {...this.props} />
        </MergeRewireContext>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContextContainer);