import React from 'react';
import {connect} from 'react-redux';
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";
import {isPending} from "state/selectors/ui";
import uuid from 'uuid/v4';
import classNames from 'classnames';
import Form from 'containers/Form';

import FilterField from "containers/FilterField";
import ListView from "containers/ListView";
import SideNavContainer from "containers/user-menu/SideNav";
import ListFormLayout from "components/layouts/ListFormLayout";
import FormLayout from "components/layouts/FormLayout";
import UserMenu from "containers/user-menu/UserMenu";
import EntityContext from "containers/context/EntityContext";
import FormButtons from "components/FormButtons";
import PendingState from "components/list/PendingState";
import ListFormPageView from "playground/ListFormPageView";
import List from "@material-ui/core/List";
import ListItemMenu from "components/context-menu/ListItemMenu";

import {create} from "state/actions/create";
import {reject} from "state/actions/data";
import {submitOneCallback} from "state/actions/api";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import FormStatic from "containers/FormStatic";
import {entityDelete} from "state/actions/delete";
import DomainCertificate, {DomainListItem} from "console/DomainCertificate";
import {ListItem} from "components/list/items/ListItem";
import {ArrayIterator} from "components/list/iterator";

function CustomizeListItem(props) {
    const { type } = props;
    if (type === 'console.domains') {
        return <DomainListItem {...props} />;
    }
    return <ListItem {...props} />;
}

const ListItemIterator = ArrayIterator(CustomizeListItem);

const CMS_PAGE_RESOURCES = [
    'cms.pages',
    'cms.entries.offers',
    'cms.entries.rooms',
    'cms.entries.projects',
    'cms.layouts'
];

const selectSubtype = (type) => {
    if (!type) return null;
    let parts = type.split('.');
    if (parts.length === 3) return parts[2];
    return null;
};

const mapStateToProps = (store, props) => {
    const requiredResources = [
        'db.types/db.types',
        'db.form_views/db.form_views'
    ];
    const resource = selectRouteParam(store, 'resource');
    const id = selectRouteParam(store, 'id');
    return {
        list: 'default',
        resource,
        subtype: selectSubtype(resource),
        id,
        nav: selectRouteParam(store, 'nav'),
        view: selectRouteParam(store, 'view'),
        isPending: isPending(store, requiredResources),
        formPending: isPending(store, [id])
    }
};

const mapDispatchToProps = bindApiActions({
    onSubmit: submitOneCallback,
    onDelete: entityDelete
},{
    pushQuery: pushRouteParams,
    onCreate: create,
    onCancel: reject
});

export const searchInput = {
    variant: 'search',
    fullWidth: true,
    label: 'Search...'
};

class ListFormView extends React.PureComponent {

    nav = () => {
        this.props.pushQuery({nav: !this.props.nav ? true : null});
    };

    click = (id) => {
        this.props.pushQuery({resource: id, id: null}, true);
    };

    clickItem = (id) => {
        this.props.pushQuery({id, view: null});
    };

    close = () => {
        this.props.pushQuery({id: null, view: null});
    };

    cancel = () => {
        const { resource, id, onCancel } = this.props;
        onCancel(resource, id);
        this.close();
    };

    create = () => {
        const { onCreate, resource, list, subtype } = this.props;
        const newId = uuid();
        const item = {id: newId, draft: true};
        if (subtype) item.type = subtype;
        onCreate(resource, item, list);
        this.props.pushQuery({id: newId, tab: 'edit'});
        this.cancelCreate();
    };

    delete = (id) => {
        const { resource, onDelete } = this.props;
        onDelete(resource, id);
    };

    cancelCreate = () => {
        this.props.pushQuery({create: null});
    };

    submitClose = () => {
        const { onSubmit, resource, id} = this.props;
        onSubmit(()=>this.close(), resource, id);
    };

    submit = () => {
        const { onSubmit, resource, id} = this.props;
        onSubmit(()=>null, resource, id);
    };

    view = (id) => {
        const { pushQuery } = this.props;
        pushQuery({view: id, id: null});
    };

    render() {
        const {
            resource,
            id,
            list,
            isPending,
            formPending,
            nav,
            view,
            onSubmit,
            onDelete
        } = this.props;

        if (CMS_PAGE_RESOURCES.indexOf(resource) > -1)
            return <ListFormPageView {...this.props} />;

        const showForm = !isPending && (id || view);

        return <>
            <ListFormLayout
                isPending={isPending}
                sideNav={<SideNavContainer
                    onClick={this.click}
                    onShowUserMenu={this.nav}
                    showUserMenu={!resource || nav}
                />}
                userMenu={<UserMenu resource={resource} onClick={this.click} />}
                showUserMenu={!resource || nav}
                onFabClick={!id ? this.create : null}
                list={resource ? <div className={classNames({
                    'default rows grow': true,
                    hide: !!nav
                })}>
                    <div className={'pad-sm sticky-top default'}>
                        <FilterField
                            {...searchInput}
                            type={resource}
                            id={'search'}
                            label={'Search...'}
                        />
                        { resource === 'console.services.website'
                            ? <div onClick={()=>this.view('createWebsite')}>create website</div>
                            : null }
                    </div>
                    <div className={'grow'}>
                        <List>
                            <ListView
                                type={resource}
                                list={list}
                                onClick={this.clickItem}
                                onDelete={onDelete}
                                context={true}
                                RenderMenuItems={ListItemMenu}
                                Iterator={ListItemIterator}
                                pendingState={<PendingState />}
                                selectedId={id}
                            />
                        </List>
                    </div>
                </div> : null}
                form={showForm ? <>
                    { id ? <FormView
                        type={resource}
                        id={id}
                        pending={formPending}
                        onCancel={this.cancel}
                        onSubmit={this.submitClose}
                    /> : null }
                    {view ? <FormStatic
                        id={view}
                        onClose={this.close}
                        onSubmit={onSubmit}
                    /> : null }
                </> : null}
            />
        </>;
    }

}

function FormView(props) {
    const {
        type,
        id,
        pending,
        onCancel,
        onSubmit
    } = props;
    const entityPath = [type, id].join('/');
    return <EntityContext.Provider value={entityPath}>
        <FormLayout
            pending={pending}
            className={'form-regular'}
            onClose={onCancel}
            form={<div className={'rows gap-md'}>
                { type === 'console.domains'
                    ? <DomainCertificate type={type} id={id} />
                    : null }
                <Form type={type} id={id} />
            </div>}
            footer={<FormButtons
                onCancel={onCancel}
                onSubmit={onSubmit}
            />}
        />
    </EntityContext.Provider>
}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListFormView));