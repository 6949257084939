import React, { useCallback, useMemo, useState } from "react";
import { useRouteParam } from "state/hooks";
import SectionOverlayForm from "./SectionOverlayForm";

//import PropTypes from 'prop-types';

export function usePageSections() {
    const [section, showSection] = useRouteParam("section");
    const onCloseSection = useCallback(() => showSection(null), [showSection]);
    const [createSection, setCreateSection] = useState(false);
    const onCloseCreateSection = useCallback(
        () => setCreateSection(null),
        [setCreateSection]
    );
    const onToggleCreateSection = useCallback(() => {
        setCreateSection(!createSection);
    }, [createSection, setCreateSection]);
    return useMemo(
        () => ({
            section,
            onCloseSection,
            createSection,
            onCloseCreateSection,
            onToggleCreateSection,
        }),
        [
            section,
            createSection,
            onCloseSection,
            onCloseCreateSection,
            onToggleCreateSection,
        ]
    );
}

export function PageSections(props) {
    const { type, section, onCloseSection, createSection, onClose } = props;

    return (
        <SectionOverlayForm
            id={section}
            onClose={onCloseSection}
            isBrowser={false}
            onCloseBrowser={onClose}
            browser={!!createSection}
            pageType={type}
        />
    );
}

PageSections.propTypes = {};

PageSections.defaultProps = {};

export default PageSections;
