import React from 'react';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class BookingSummary extends React.Component {

    render() {
        return (<Card className={'pad-sm'}>
            Rezerwujesz <strong>1 pokój</strong>, pobyt dla <strong>osób</strong> w terminie <strong>date_from</strong> do <strong>date_to</strong>
        </Card>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingSummary);