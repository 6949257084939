import React from "react";
import { DangerZone } from "joynt/edit/types/fields";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import CategorySelect from "joynt/edit/CategorySelect";
import { sessionPolicies } from "joynt/config/sessions";
import FieldLayout from "joynt/components/Form/FieldLayout";
import SelectField from "joynt/edit/SelectField";
import { viewTypes } from "joynt/config/types";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_DEV } from "joynt/config/features";

//import PropTypes from 'prop-types';

export function SessionAdvancedSettings(props) {
    const isDev = useFeatureEnabled(FEATURE_DEV);
    return (
        <FieldsLayout>
            <FieldGroup label={"Choose session type"}>
                <Field id={"event_session_policy"}>
                    {({ onChange, value }) => (
                        <CategorySelect
                            onChange={onChange}
                            value={value}
                            options={sessionPolicies}
                        />
                    )}
                </Field>
            </FieldGroup>

            <DangerZone />

            {isDev && (
                <FieldGroup label={"Dev settings"}>
                    <FieldLayout name={"Default view"}>
                        <Field id={"default_view"}>
                            {({ onChange, value }) => (
                                <SelectField
                                    onChange={onChange}
                                    value={value}
                                    options={viewTypes}
                                />
                            )}
                        </Field>
                    </FieldLayout>
                    <Field
                        id={"event_record_uri"}
                        fieldType={"text"}
                        label={"Recording url"}
                    />
                    <Field
                        id={"session_type"}
                        fieldType={"text"}
                        label={"Session type"}
                    />
                    <Field
                        id={"access_type"}
                        fieldType={"text"}
                        label={"Access type"}
                    />
                    <FieldLayout name={"Estimated max participants"}>
                        <Field
                            id={"event_max_participants"}
                            fieldType={"text"}
                        />
                    </FieldLayout>
                </FieldGroup>
            )}
        </FieldsLayout>
    );
}

SessionAdvancedSettings.propTypes = {};
SessionAdvancedSettings.defaultProps = {};

export default SessionAdvancedSettings;
