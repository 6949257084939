import React from 'react';
import IconButtonGroup from "cms/ui/IconButtonGroup";
import IconButton from "cms/ui/IconButton";

import displayOptions, {devDisplayOptions} from 'cms/ui/config/display-options';
import deviceOptions from 'cms/ui/config/device-options';
import Checkbox from "components/fields/checkbox/Checkbox";

export default class IdeNav extends React.Component {

    render() {
        const {
            onToggleDisplay,
            onToggleDevice,
            onToggleView,
            onToggleDev,
            devMode,
            display,
            device
        } = this.props;
        return (<div className={'cols cols-middle gap-md'}>
            <div>
                <IconButton
                    onClick={()=>onToggleView('create')}
                    icon={'mui-add'}
                    value={'Add section'}
                />
            </div>
            <div className={'ide-nav-options'}>
                <IconButtonGroup
                    multiple={true}
                    value={display}
                    items={devMode
                        ? devDisplayOptions
                        : displayOptions}
                    onToggle={onToggleDisplay}
                />
            </div>
            <div className={'ide-nav-options'}>
                <IconButtonGroup
                    value={device}
                    items={deviceOptions}
                    onToggle={onToggleDevice}
                />
            </div>
            <div>
                <Checkbox
                    label={'Dev mode'}
                    value={!!devMode}
                    onChange={onToggleDev}
                />
            </div>
        </div>);
    }

}

IdeNav.propTypes = {};