import React from "react";
import { ResponsiveModal } from "components/Modal";
import PageModal from "joynt/modals/PageModal";
import Slide from "@material-ui/core/Slide";
import { Dialog } from "@material-ui/core";
//import PropTypes from 'prop-types';

import "./style.css";

export default function PageLayout(props) {
    const { open, onClose, type, transitionProps, children } = props;

    if (type === "modal")
        return (
            <ResponsiveModal open={open} onClose={onClose} maxWidth={"md"}>
                {children}
            </ResponsiveModal>
        );

    if (type === "drawer")
        return (
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
                className={"drawer-container"}
            >
                <div className={"drawer"}>{children || null}</div>
            </Dialog>
        );

    return (
        <PageModal transitionProps={transitionProps} open={open}>
            {children}
        </PageModal>
    );
}

PageLayout.propTypes = {};
PageLayout.defaultProps = {};
