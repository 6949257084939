export function selectListTags(store, type, id) {
    const counts =
        store?.["lists-meta"]?.[type]?.[id || type]?.pagination?.tag_counts;
    if (!counts) return [];
    const arr = Object.keys(counts).map((tag) => ({
        id: tag,
        count: counts[tag],
    }));
    return arr.sort((a, b) => b.count - a.count); //.slice(0, 10);
}

export function selectListTagsIndex(store, type, id) {
    const counts = selectListTags(store, type, id);
    return counts.reduce((acc, tag) => {
        acc[tag.id] = tag.count;
        return acc;
    }, {});
}
