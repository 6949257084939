import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useCallback } from "react";
import { useActions, useUiFlag } from "state/hooks";
import { useMediaDevices } from "joynt/meeting/devices";
import useVideoSettings from "joynt/meeting/hooks/useVideoSettings";
import { goLive as goLiveAction } from "joynt/state/actions/meetings";
import { FLAG_SESSION_FLOATING, FLAG_SESSION_STAGE_LAYOUT } from "joynt/config";
import { SESSION_LAYOUT } from "joynt/meeting/agora-ng/config";

//import {useMicrophoneLevel} from "joynt/meeting/hooks/useMicrophoneLevel";

export function useSessionState(id) {
    const settings = useVideoSettings(id);

    const { onShowSession } = useMeeting();
    const { cameraAvailable, microphoneAvailable } = useMediaDevices();
    const { onGoLive } = useActions({ onGoLive: goLiveAction });

    const [isLive, setIsLive] = useUiFlag(`meeting.${id}.live`, false);
    const [info, setInfo] = useUiFlag(`meeting.${id}.stageInfo`, null);

    const [layout, setLayout] = useUiFlag(
        FLAG_SESSION_STAGE_LAYOUT,
        SESSION_LAYOUT
    );
    const [audio, setAudio] = useUiFlag("meeting.audio", cameraAvailable);
    const [video, setVideo] = useUiFlag("meeting.video", microphoneAvailable);
    const [isMinimized] = useUiFlag(FLAG_SESSION_FLOATING);
    const [raisedHand, setRaisedHand] = useUiFlag("session.hand");

    const goLive = useCallback(
        (state, reason) => {
            onGoLive(id, state, reason);
        },
        [id, onGoLive]
    );

    return {
        video,
        audio,
        setVideo,
        setAudio,
        isLive,
        publishAudio: isLive && audio && microphoneAvailable,
        publishVideo: isLive && video && cameraAvailable,
        setIsLive: goLive,
        setLiveStatus: setIsLive,
        layout: layout,
        setLayout,
        onShowSession,
        info,
        onSetInfo: setInfo,
        isMinimized,
        raisedHand,
        setRaisedHand,
        ...settings,
    };
}
