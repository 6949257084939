import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export function PopoverInput(props) {
    const { value, onChange, ...rest } = props;
    const change = (e) => onChange(e.target.value);

    return (
        <input
            className={"popover-input"}
            onChange={change}
            value={value}
            {...rest}
        />
    );
}

PopoverInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

PopoverInput.defaultProps = {
    value: "",
};

export default PopoverInput;
