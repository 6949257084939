import React from "react";
//import PropTypes from 'prop-types';

import AggregateRoot from "containers/AggregateRoot";
import FormRenderContext from "containers/context/FormRenderContext";
import ZmapField from "./../ZmapField";

export function FormContext(props) {
    const { type, id, children } = props;

    const render = (props) => {
        return <ZmapField {...props} variant={"dense"} />;
    };

    return (
        <AggregateRoot type={type} id={id} disableSubmit={true}>
            <FormRenderContext.Provider value={render}>
                {children}
            </FormRenderContext.Provider>
        </AggregateRoot>
    );
}

FormContext.propTypes = {};

FormContext.defaultProps = {};

export default FormContext;
