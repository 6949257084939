import React from "react";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import { AccessProfile } from "../../fields/AccessProfile";
import NodeAccess from "joynt/components/Roles/NodeAccess";

//import PropTypes from 'prop-types';

export function ProjectAccess(props) {
    const { id } = props;

    return (
        <FieldsLayout>
            <FieldGroup label={"Project room visibility"}>
                <AccessProfile id={id} />
            </FieldGroup>

            <FieldGroup label={"Who's invited"}>
                <NodeAccess id={id} />
            </FieldGroup>
        </FieldsLayout>
    );
}

ProjectAccess.propTypes = {};
ProjectAccess.defaultProps = {};

export default ProjectAccess;
