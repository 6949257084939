import React from "react";
import PropTypes from "prop-types";

import IconButton from "components/IconButton";
import List from "containers/List";
import { ResponsiveTabsIterator } from "./ResponsiveTabs";
import PillButton from "joynt/components/PillButton";
import NodeTab from "./NodeTab";

import "./style.css";

const ChannelTabIterator = ResponsiveTabsIterator(NodeTab);

export function Tabs(props) {
    const {
        views,
        view,
        onChangeView,
        canEdit,
        onCreate,
        list,
        onClick,
        onEdit,
        id,
        channel,
    } = props;

    return (
        <div className={"space-tabs "}>
            <div className={"space-tabs__buttons"}>
                {views.map((nodeChannel) => {
                    return (
                        <PillButton
                            key={nodeChannel.id}
                            id={nodeChannel.id}
                            icon={nodeChannel.icon}
                            label={nodeChannel.label}
                            onClick={() => onChangeView(nodeChannel.id)}
                            isActive={!channel && view === nodeChannel.id}
                        />
                    );
                })}
            </div>
            <List
                type={"db.nodes"}
                list={list || "db.nodes.channels"}
                onClick={onClick}
                onEdit={onEdit}
                onSortEnd={null}
                Iterator={ChannelTabIterator}
                parent={id}
                selected={channel}
                pendingId={["db.nodes", list].join("/")}
                pendingReplace={true}
            />
            {canEdit && onCreate && (
                <IconButton
                    onClick={onCreate}
                    icon={"mui-add"}
                    className={"inline"}
                />
            )}
        </div>
    );
}

Tabs.propTypes = {
    id: PropTypes.string,
    view: PropTypes.string,
    views: PropTypes.array,
    onClick: PropTypes.func,
    onChangeView: PropTypes.func,
    onCreate: PropTypes.func,
    channel: PropTypes.string,
    canEdit: PropTypes.bool,
};
Tabs.defaultProps = {
    views: [],
    canEdit: false,
};

export default Tabs;
