import React from "react";
import Field from "containers/Field";

//import PropTypes from 'prop-types';

const features = [
    {
        id: "features.chat",
        label: "Chat",
    },
    {
        id: "features.files",
        label: "Files & polls",
    },
    {
        id: "features.qa",
        label: "Q&A",
    },
    {
        id: "features.rooms",
        label: "Breakout rooms",
    },
];

export function SessionFeatures() {
    return (
        <div className={"grid grid-2 gap-sm"}>
            {features.map((feature) => {
                return (
                    <Field
                        key={feature.id}
                        id={feature.id}
                        fieldType={"checkbox"}
                        variant={"checkbox"}
                        label={feature.label}
                    />
                );
            })}
        </div>
    );
}

SessionFeatures.propTypes = {};
SessionFeatures.defaultProps = {};

export default SessionFeatures;
