import React, { useCallback } from "react";
import PropTypes from "prop-types";

import EnumList from "containers/EnumList";
import { ArrayIterator } from "components/list/iterator";
import { withData } from "containers/Entity";
import icons from "config/icons";
import Icon from "components/Icon";
import Card from "@material-ui/core/Card";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import LibraryComponents from "cms/ui/LibraryComponents";

function Type(props) {
    const { value, onClick } = props;
    const click = useCallback(
        (evt) => {
            onClick(value);
        },
        [value, onClick]
    );
    return (
        <Card
            onClick={click}
            className={
                "component-type type-default pad-xs default-c500 cols cols-middle gap-xs"
            }
        >
            <Icon defaultType={"mui"} fontSize={"default"}>
                {icons["cms.components." + value]}
            </Icon>
            <div>{value}</div>
        </Card>
    );
}

Type.autoFillProps = ["label", "value"];

const TypeIterator = ArrayIterator(withData(Type));

const groups = {
    basic: [
        "structured",
        "text",
        "button",
        "list",
        "template",
        "card",
        "query",
        "link",
    ],
    all: [
        "booking-engine",
        "button",
        "text",
        "card",
        "contact-form",
        "context",
        "form-input",
        "form-select-option",
        "form-select",
        "form-consent",
        "form",
        "icon",
        "list",
        "map",
        "media-element",
        "nav",
        "newsletter",
        "plan",
        "quickbook",
        "slider-nav",
        "structured",
        "symbol",
        "tags",
        "template",
        "value",
    ],
};

function filterComponents(types, group) {
    return types.filter((type) => {
        let id = type.replace("types.components.", "");
        return groups[group].indexOf(id) > -1;
    });
}

export default class SelectComponentType extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { group: "basic" };
    }

    select = (value) => {
        const { onSelect, onClose } = this.props;
        onSelect(value);
        onClose();
    };

    group = (evt, id) => {
        this.setState({ group: id });
    };

    filter = (types) => {
        const { group } = this.state;
        return filterComponents(types, group);
    };

    render() {
        const { onImport } = this.props;
        const { group } = this.state;
        return (
            <div className={"rows"}>
                <Tabs
                    value={group}
                    onChange={this.group}
                    className={"default-c600"}
                >
                    <Tab value={"basic"} label={"Podstawowe"} />
                    <Tab value={"all"} label={"Wszystkie"} />
                    <Tab value={"library"} label={"Library"} />
                </Tabs>
                <div className={"pad-sm grid grid-4 gap-xs"}>
                    {group === "library" ? (
                        <LibraryComponents onClick={onImport} />
                    ) : (
                        <EnumList
                            type={"schema.data"}
                            id={"types.components"}
                            onClick={this.select}
                            Iterator={TypeIterator}
                            filter={(v) => this.filter(v)}
                        />
                    )}
                </div>
            </div>
        );
    }
}

SelectComponentType.propTypes = {
    onSelect: PropTypes.func.isRequired,
};
