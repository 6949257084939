import React from 'react';
import {connect} from 'react-redux';
import ResourceList from "cms/ui/ResourceList";
import {pushRoute} from "state/actions/router";
import {selectLang, selectRouteParam} from "state/selectors/router";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import {submitOrder} from "state/actions/api";

const mapStateToProps = (store, props) => {
    return {
        service: selectRouteParam(store, 'service'),
        type: selectRouteParam(store, 'type', selectRouteParam(store, 'resource')),
        id: selectRouteParam(store, 'id'),
        list: 'default',
        lang: selectLang(store)
    }
};

const mapDispatchToProps = bindApiActions({
    onSortEnd: submitOrder
},{
    onShowPage: pushRoute
});

class ResourceListContainer extends React.Component {

    show = (id) => {
        const { onShowPage, lang, service, type } = this.props;
        let uri = `/${lang}/${service}/front?id=${id}&list=pages&resource=${type}` ;
        onShowPage(uri);
    };

    edit = (id) => {
        const { onShowPage, lang, service, type } = this.props;
        let uri = `/${lang}/${service}/front?id=${id}&list=sections&tab=edit&resource=${type}`;
        onShowPage(uri);
    };

    sort = (order) => {
        const { type, list, onSortEnd } = this.props;
        let url = type.indexOf('cms.entries') === 0
            ? 'cms/entries/treeIndex'
            : type.replace(/\./g, '/') + '/treeIndex';
        onSortEnd(url, type, list, order);
    };

    render() {
        return (<ResourceList
            {...this.props}
            onSortEnd={this.sort}
            onClick={this.show}
            onEdit={this.edit}
        />);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceListContainer));