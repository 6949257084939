import React, { useState } from "react";
//import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import Button from "components/Button";
import IdentityStep1 from "joynt/welcome/IdentityStep1";
import IdentityStep2 from "joynt/welcome/IdentityStep2";
import Preloader from "components/Preloader";

import "./style.css";
import Icon from "components/Icon";

export default function SetupIdentity(props) {
    const { isValid, pending, onSubmit, onLogout, children } = props;
    const [step, setStep] = useState(1);

    const next = step === 1 ? () => setStep(2) : onSubmit;

    const info =
        React.Children.count(children) > 0 ? (
            children
        ) : (
            <div className={"type-default"}>
                Joynt offers a novel and easy way of handling your remote work
                and life in one place. Collaboartion, communcation and everyday
                stuff are all neatly managed using contexts.
            </div>
        );

    return (
        <Dialog open={true} maxWidth={"lg"}>
            <Preloader visible={!!pending} />
            <div className={"setup-identity"}>
                <div
                    className={
                        "setup-identity-info rows dark-mode bg pad-md rows-spread gap-sm"
                    }
                >
                    <div className={"rows gap-sm rows-center rows-spread grow"}>
                        <div className={"rows gap-sm rows-center grow"}>
                            <div className={"type-heading-sm"}>Welcome</div>
                            {info}
                        </div>
                        <div className={"text-center type-sm o-50"}>
                            We do not use or interpret your personal information
                            in any way - it is only for the benefit of your
                            social interactions in Joynt!
                        </div>
                    </div>
                    <div className={"cols cols-center"}>
                        <Button
                            onClick={onLogout}
                            variant={"text"}
                            endIcon={<Icon>mui-logout</Icon>}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
                <div className={"rows pad-md setup-identity__form"}>
                    <div className={"rows rows-center rows-spread grow gap-sm"}>
                        {step === 1 ? <IdentityStep1 {...props} /> : null}
                        {step === 2 ? <IdentityStep2 {...props} /> : null}
                        <div className={"cols cols-center gap-xs"}>
                            {step === 2 ? (
                                <Button
                                    color={"default"}
                                    size={"large"}
                                    onClick={() => setStep(1)}
                                >
                                    Back
                                </Button>
                            ) : null}
                            <Button
                                disabled={!isValid}
                                onClick={next}
                                size={"large"}
                            >
                                {step === 1 ? "Next" : "Create"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

SetupIdentity.propTypes = {};
SetupIdentity.defaultProps = {};
