import React from "react";
//import PropTypes from 'prop-types';
import { useMoment } from "./useMoment";
import Popover from "components/Popover";
import cn from "classnames";
import Icon from "components/Icon";
import DatePickerCalendar from "./DatePickerCalendar";

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

function DefaultValue({ value, label, onClick, nullable, onClear, className }) {
    return (
        <div onClick={onClick} className={className}>
            <div>{value || label}</div>
            {value && onClear && nullable && (
                <span onClick={onClear}>usuń</span>
            )}
        </div>
    );
}

export function DatePicker(props) {
    const { value, onChange, format, ValueComponent } = props;

    const defaultValue = value ? value : new Date().getTime() / 1000;
    const momentValue = useMoment(defaultValue);
    const str = value ? momentValue.format(format) : null;
    const change = (v) => onChange(v.unix());
    const clear = (e) => {
        e.stopPropagation();
        onChange(null);
    };

    return (
        <Popover
            anchorOrigin={anchorOrigin}
            trigger={({ onClick }) => (
                <ValueComponent
                    {...props}
                    onClick={onClick}
                    value={str}
                    onClear={clear}
                />
            )}
            content={({ onClose }) => (
                <div onClick={null}>
                    <DatePickerCalendar
                        value={momentValue}
                        onChange={(v) => {
                            change(v);
                            onClose();
                        }}
                    />
                </div>
            )}
        />
    );
}

DatePicker.propTypes = {};

DatePicker.defaultProps = {
    ValueComponent: DefaultValue,
    format: "DD MMM YY",
};

export default DatePicker;
