import React from 'react';
import {connect} from 'react-redux';

import TreeView from 'cms/ui/TreeView';
import {selectEntity} from "state/selectors/data";
import {createComponent, importFromLibrary} from "cms/state/actions/cms";
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";
import {canHaveChildren} from "cms/ui/ComponentTree";
import {normalizeType} from "cms/util/connect";
import {typeToField} from "cms/state/selectors/cms";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";

const nullObj = {};

const mapStateToProps = (store, props) => {
    const sectionId = selectRouteParam(store, 'section');
    const section = selectEntity(store, props.type, sectionId);
    const template = selectEntity(store, props.type, section.template);
    const focused = props.focusType
        ? selectEntity(store, props.focusType, props.focusId)
        : nullObj;
    const focusComponentType = normalizeType(focused.element_type) || 'section';

    return {
        section,
        template,
        canHaveChildren: canHaveChildren.indexOf(focusComponentType) > -1
    }
};

const mapDispatchToProps = bindApiActions({
    onImport: importFromLibrary
}, {
    onCreate: createComponent,
    onFocus: pushRouteParams,
    onChangeTemplate: pushRouteParams
});

class TreeViewContainer extends React.PureComponent {

    handleFocus = (type,id) => {
        this.props.onFocus({
            focus: [type,id].join('/'),
            s: null
        });
    };

    handleCreate = (type) => {
        const { onCreate, focusType, focusId } = this.props;
        onCreate(focusType, focusId, typeToField(focusType), {
            element_type: type,
            active: true,
            disable: false
        });
    };

    handleChangeTemplate = () => {
        const { onChangeTemplate } = this.props;
        onChangeTemplate({list: 'template'});
    };

    handleImport = (libraryType, libraryId) => {
        const { onImport, focusType, focusId } = this.props;
        onImport(libraryType, libraryId, focusType, focusId);
    }

    render() {
        return (<TreeView
            {...this.props}
            onFocus={this.handleFocus}
            onCreate={this.handleCreate}
            onImport={this.handleImport}
            onChangeTemplate={this.handleChangeTemplate}
        />);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(TreeViewContainer));