import React from "react";

import { useFormField } from "state/hooks";
import Attributes from "playground/cms/fields/Attributes";

const hidden = ["_"];

export function ComponentAttributes(props) {
    const { value, onChange } = useFormField("attributes");
    return (
        <Attributes
            value={value}
            onChange={onChange}
            hiddenAttributeNames={hidden}
        />
    );
}

ComponentAttributes.propTypes = {};

ComponentAttributes.defaultProps = {
    value: {},
};

export default ComponentAttributes;
