import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import {
    useActions,
    useApiActions,
    usePending,
    useRouteParam,
    useUiFlag,
} from "state/hooks";
import Preloader from "components/Preloader";
import { Header } from "./Header";
import { SideNav } from "./SideNav";
import ServiceSettings from "playground/cms/ServiceSettings";
import IconButton from "components/IconButton";

import "./style.css";
import { useCreate } from "playground/cms/hooks";
import { NotificationsIcon } from "playground/cms/Notifications";
import { OverlayForm } from "playground/cms/Form";
import LocaleSelect from "./LocaleSelect";
import UserMenu from "playground/cms/Layout/UserMenu";
import { pushRoute } from "state/actions/router";
import { SchemaModulesProvider } from "playground/cms/Schema";
import { DebugConsoleProvider } from "playground/debug";
import { useHasScope } from "playground/console/UserAccess";
import { SCOPE_CONSOLE, SCOPE_WEBSITES_MANAGE } from "config/access-scopes";

export function Layout(props) {
    const { views, NavComponent } = props;
    const [id, setId] = useRouteParam("id");
    const [type] = useRouteParam("resource");
    const [service] = useRouteParam("service");
    const [, showCommandPalette] = useUiFlag("app.cmdk");

    const adminAccess = useHasScope(SCOPE_CONSOLE);
    const manageAccess = useHasScope(SCOPE_WEBSITES_MANAGE);

    const { onPushRoute } = useActions({
        onPushRoute: pushRoute,
    });

    const close = useCallback(() => setId(null), [setId]);

    let pending = usePending([type, type].join("/"));
    if (type === "cms.sections") pending = false;

    const [settings, showSettings] = useRouteParam("settings");

    const { onCreate } = useCreate({ type, setId });
    const create = useCallback(() => onCreate(), [onCreate]);

    const View = views[type] || views.default;

    return (
        <DebugConsoleProvider>
            <SchemaModulesProvider>
                <div
                    id="cms-layout"
                    className={"cms-layout cols grow h-min-100v"}
                >
                    <Header>
                        <div className={"cols cols-spread cols-middle grow"}>
                            <div className={"cols cols-middle gap-sm"}>
                                <div className={"cols cols-middle pad-sm-h"}>
                                    <IconButton
                                        icon={"mui-arrow_back"}
                                        onClick={() => onPushRoute("/")}
                                    />
                                    <Header.Title
                                        onClick={() => showCommandPalette(true)}
                                    >
                                        {service || "zuu.console"}
                                    </Header.Title>
                                </div>
                                {service && (
                                    <Header.Actions>
                                        <LocaleSelect id={service} />
                                    </Header.Actions>
                                )}
                            </div>
                            <div className={"cols pad-sm-h"}>
                                {manageAccess && (
                                    <IconButton
                                        icon={"mui-settings"}
                                        onClick={() => showSettings(true)}
                                    />
                                )}
                                <NotificationsIcon />
                                <UserMenu />
                            </div>
                        </div>
                    </Header>
                    <Preloader visible={pending} />
                    <NavComponent />
                    <div className={"cols grow"}>
                        {type && (
                            <View
                                type={type}
                                id={id}
                                onClose={close}
                                onCreate={create}
                                onClick={setId}
                            />
                        )}
                    </div>
                </div>
                <OverlayForm
                    open={!!settings}
                    onClose={() => showSettings(null)}
                    anchor={"right"}
                >
                    {!!settings && (
                        <ServiceSettings onClose={() => showSettings(null)} />
                    )}
                </OverlayForm>
            </SchemaModulesProvider>
        </DebugConsoleProvider>
    );
}

Layout.propTypes = {};
Layout.defaultProps = {
    views: {},
    NavComponent: SideNav,
};
