import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUserNodeIdentity } from "joynt/state/selectors/joint";
import { useActions, useApiActions, useUiFlag } from "state/hooks";
import { FLAG_CHAT_THREAD } from "joynt/config";
import { createMessage, showChat } from "joynt/state/actions/messages";
import uuid from "uuid/v4";
import extractLinks from "util/extractLinks";

export function useMessaging(props) {
    const { parent, thread, type } = props;
    const identity = useSelector((s) => selectUserNodeIdentity(s, parent));
    const [chatThreadId] = useUiFlag(FLAG_CHAT_THREAD);
    const { onShowChat } = useActions({ onShowChat: showChat });

    const { onCreateMessage } = useApiActions({
        onCreateMessage: createMessage,
    });

    const create = useCallback(
        (data) => {
            const message = { id: uuid(), ...data };
            if (message.content) {
                let [content, links] = extractLinks(message.content);
                if (links.length) {
                    message.link = links[0];
                    message.content = content;
                }
            }
            onCreateMessage(thread, message, parent, type);
        },
        [parent, thread, type, onCreateMessage]
    );

    return {
        identity,
        onCreateMessage: create,
        onCreateThread: (...args) => {
            console.log(`createThread`, args, props);
        },
        onShowChat,
        chatThreadId,
    };
}
