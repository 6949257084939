import React from "react";

import { useUserProfile } from "joynt/hooks";

import AggregateRoot from "containers/AggregateRoot";
import FieldLayout from "joynt/components/Form/FieldLayout";
import Field from "containers/Field";
import EnableNotifications from "./EnableNotifications";
import ActivitySummarySettings from "./ActivitySummarySettings";
//import PropTypes from 'prop-types';

import "./style.css";

export function NotificationSettings(props) {
    const { isAdmin } = props;
    const type = "db.user-profiles";

    const {
        name,
        id,
        notifications_browser,
        notifications_phone,
    } = useUserProfile();

    return (
        <AggregateRoot type={type} id={id}>
            <ActivitySummarySettings />
            <FieldLayout name={"Enable email notifications"} description={name}>
                <Field id={"notifications_email"} fieldType={"checkbox"} />
            </FieldLayout>
            <FieldLayout
                name={"Enable Chrome push notifications"}
                description={"Works on desktop and Android"}
            >
                <div className={"cols gap-sm"}>
                    <Field
                        id={"notifications_browser"}
                        fieldType={"checkbox"}
                    />
                    {notifications_browser ? <EnableNotifications /> : null}
                </div>
            </FieldLayout>
            {isAdmin && (
                <FieldLayout
                    name={"Enable phone notifications"}
                    description={"Using Pushover integration"}
                >
                    <Field id={"notifications_phone"} fieldType={"checkbox"} />
                </FieldLayout>
            )}
            {isAdmin && notifications_phone && (
                <FieldLayout
                    name={"Pushover user key"}
                    description={"Enter Pushover key visible in the mobile app"}
                >
                    <Field
                        id={"notifications_pushover_key"}
                        fieldType={"text"}
                    />
                </FieldLayout>
            )}
        </AggregateRoot>
    );
}

NotificationSettings.propTypes = {};
NotificationSettings.defaultProps = {};

export default NotificationSettings;
