import React from "react";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import { AdvancedSettings, DangerZone, RolesAndAccess } from "./fields";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldLayout from "joynt/components/Form/FieldLayout";
import PostTags from "joynt/components/Post/PostTags";
//import PropTypes from 'prop-types';

function Form({ id }) {
    return (
        <FieldsLayout>
            <FieldGroup label={"Feed name and description"}>
                <Field id={"name"} label={"Name"} />
                <Field id={"description"} label={"Description"} />
            </FieldGroup>

            <PostTags id={id} editable={true} />

            <FieldLayout
                name={"Allow anyone to post"}
                description={"Anyone will be able to create posts here"}
            >
                <Field id={"allow_posting"} fieldType={"checkbox"} />
            </FieldLayout>

            <FieldLayout
                name={"Enable email notifications"}
                description={"Send instant emails with published posts"}
            >
                <Field id={"notifications_email"} fieldType={"checkbox"} />
            </FieldLayout>

            <RolesAndAccess />

            <AdvancedSettings>
                <DangerZone />
            </AdvancedSettings>
        </FieldsLayout>
    );
}

const steps = {
    step1: {
        component: Form,
        label: "Settings",
    },
};

export default function Feed(props) {
    return <MultiStepForm {...props} steps={steps} showHeader={false} />;
}

Feed.propTypes = {};
Feed.defaultProps = {};
