import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import { useWorkspaceId } from "joynt/hooks";
import useProfileInteraction from "joynt/participants/hooks/useProfileInteraction";

export function AvatarContainer(props) {
    const { id } = props;

    const workspace = useWorkspaceId();
    const events = useProfileInteraction(workspace, id);

    return <Avatar {...events} {...props} />;
}

AvatarContainer.propTypes = {
    id: PropTypes.string,
};
AvatarContainer.defaultProps = {};

export default AvatarContainer;
