import {useActions} from "state/hooks";
import {sessionPresence} from "joynt/state/actions/presence";
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

const nullObj = {};

export function useSessionPresence(id) {
    const {update} = useActions({update: sessionPresence});
    const onUpdatePresence = useCallback((users) => {
        Object.keys(users).forEach(userId => {
            let value = users[userId];
            if (userId.indexOf('screen') > -1) {
                userId = userId.replace('screen_', '');
                value.screen = !!value.video;
                delete value.connected;
                delete value.video;
                delete value.audio;
            }
            update(id, userId, value);
        });
    }, [update, id]);

    const presence = useSelector(s=>s.presence[id] || nullObj);

    return useMemo(() => ({
        presence,
        onUpdatePresence
    }), [presence, onUpdatePresence]);
}