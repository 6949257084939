import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Field from 'containers/Field';
import IconButton from "components/IconButton";

function setFocus(el, end) {
    console.log('Set focus', end);
    let s = window.getSelection();
    let r = document.createRange();
    if (end && el.lastChild) {
        let len = el.lastChild.length;
        r.setStart(el.lastChild, len); //);
        r.setEnd(el.lastChild, len); //input.childElementCount);
    } else {
        r.setStart(el, el.childElementCount);
        r.setEnd(el, el.childElementCount);
    }
    s.removeAllRanges();
    s.addRange(r);
}

function caretMove(el, next, delay) {
    const move = (el, next) => {
        const siblings = Array.from(el.parentNode.childNodes);
        let currentIndex = false;
        let editable = null;
        for (let i = 0; i < siblings.length; i++) {
            let node = siblings[i];
            let editableElement = node.querySelector('[contenteditable]');
            if (next && currentIndex !== false && !editable && editableElement) {
                editable = editableElement;
            }
            if (siblings[i] === el) currentIndex = i;
            if (!next && currentIndex === false && editableElement) {
                editable = editableElement;
            }
        }
        if (!editable) return;
        setFocus(editable, !next);
    };
    if (delay) {
        setTimeout(() => move(el, next), delay);
    } else {
        move(el, next);
    }
}

export default class Task extends React.PureComponent {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {focus: false};
    }

    click = () => this.props.onClick(this.props.id);

    remove = (e) => {
        const { onDelete } = this.props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        caretMove(this.ref.current, false, 0);
        onDelete(this.props.id);
    };

    create = () => {
        const { onCreate } = this.props;
        onCreate();
        caretMove(this.ref.current, true, 10);
    };

    focus = () => this.setState({focus: true});
    blur = () => this.setState({focus: false});

    render() {
        const { data, readOnly, disableDelete } = this.props;
        const { completed } = data; //description
        const { focus } = this.state;

        return (<div className={classNames(
            'task cols cols-middle gap-sm',
            {'task--completed': !!completed}
        )} ref={this.ref}>
            <Field
                id={'completed'}
                variant={'check'}
                fieldType={'checkbox'}
                readOnly={readOnly}
            />
            <Field
                id={'description'}
                label={'Enter short task description'}
                fieldType={'text'}
                variant={'inline'}
                disabled={completed || readOnly}
                className={'task__description grow'}
                onEnterKeyPress={this.create}
                onBackspaceKeyPress={!readOnly ? this.remove : null}
                onFocus={this.focus}
                onBlur={this.blur}
            />
            {!readOnly && !disableDelete && !focus
                ? <IconButton icon={'mui-clear'} onClick={this.remove} />
                : null }
        </div>);
    }

}

Task.propTypes = {
    onClick: PropTypes.func,
    onDelete: PropTypes.func
};