import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useData } from "state/hooks";
import BaseIdentityListItem from "./../IdentityListItem";
import IdentityListItem from "./../IdentityListItem.container";
import RoleTag from "joynt/components/Roles/RoleTag";
import { useProfilePopover } from "joynt/participants";

export function RoleListItemContainer(props) {
    const { id, role: propsRole, ...other } = props;
    const { email, identity, role } = useData({ type: "app.node-roles", id });
    const { onShow, onHide } = useProfilePopover();
    const click = useCallback((e) => onShow(identity, e.currentTarget), [
        identity,
        onShow,
    ]);

    if (!identity) return <BaseIdentityListItem name={email} />;

    return (
        <IdentityListItem
            id={identity}
            addon={<RoleTag role={propsRole || role} />}
            onClick={click}
            onMouseLeave={onHide}
            {...other}
        />
    );
}

RoleListItemContainer.propTypes = {
    id: PropTypes.string,
};
RoleListItemContainer.defaultProps = {};

export default RoleListItemContainer;
