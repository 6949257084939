import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Icon from "components/Icon";

import "./style.css";

export function FieldGroup(props) {
    const {
        label,
        description,
        collapsible,
        children,
        addon,
        helperText,
    } = props;

    const [collapsed, setCollapsed] = useState(collapsible);
    const toggle = useCallback(() => setCollapsed((v) => !v), [setCollapsed]);

    return (
        <div className={"rows gap-sm"}>
            <div
                className={cn("cols grow cols-spread", {
                    "field-group--collapsible": collapsible,
                    "cursor-pointer": collapsible,
                })}
            >
                <div className={"cols grow gap-sm"}>
                    <div className={"rows gap-px"} onClick={toggle}>
                        <div className={cn("cols gap-xs cols-middle")}>
                            {collapsible ? (
                                <div>
                                    <Icon fontSize={"medium"}>
                                        {!collapsed
                                            ? "mui-keyboard_arrow_up"
                                            : "mui-keyboard_arrow_down"}
                                    </Icon>
                                </div>
                            ) : null}
                            <div className={"grow type-lg"}>{label}</div>
                        </div>
                        {description ? (
                            <div className={"type-sm o-50"}>{description}</div>
                        ) : null}
                    </div>
                    {helperText}
                </div>
                {addon ? addon : null}
            </div>
            {!collapsed || !collapsible ? (
                <div className={"rows gap-xs"}>{children}</div>
            ) : null}
        </div>
    );
}

FieldGroup.propTypes = {
    label: PropTypes.string,
    description: PropTypes.string,
    collapsible: PropTypes.bool,
    helperText: PropTypes.element,
};

FieldGroup.defaultProps = {
    collapsible: false,
};

export default FieldGroup;
