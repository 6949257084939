import React, {useCallback, useMemo} from 'react';
//import PropTypes from 'prop-types';
import useBrowserPermission from "./hooks/useBrowserPermission";
import {BrowserPermissionsContext} from "joynt/meeting/devices/context";

export default function BrowserPermissions(props) {

    const {children} = props;

    const [camera, resetCam] = useBrowserPermission('camera');
    const [microphone, resetMic] = useBrowserPermission('microphone');

    const onCheckPermissions = useCallback(() => {
        resetCam();
        resetMic();
    }, [resetCam, resetMic]);

    const context = useMemo(() => ({
        cameraAccess: camera,
        microphoneAccess: microphone,
        permissionsApiNotAvailable: [camera, microphone].indexOf('unknown') > -1,
        onCheckPermissions
    }), [camera, microphone, onCheckPermissions]);

    return <BrowserPermissionsContext.Provider value={context}>
        {children}
    </BrowserPermissionsContext.Provider>;

}

BrowserPermissions.propTypes = {};
BrowserPermissions.defaultProps = {};