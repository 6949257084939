import { push as routerPush } from "connected-react-router";
import { parseQueryString, serializeQueryParams } from "util/uri";
import { matchPath, generatePath } from "react-router";
import domainRouter from "util/domainRouter";

//const config = domainRouter();
//const routePattern = config.routePattern;

export const pushRouteParams = (params = {}, replace = false) => {
    const routePattern = domainRouter().routePattern;
    let parts = [window.location.pathname];
    let matchedPath =
        matchPath(window.location.pathname, {
            path: routePattern,
        }) || {};
    let routeParams = matchedPath.params || {};
    let prevQueryParams = parseQueryString(window.location.search);
    let prevParams = { ...routeParams, ...prevQueryParams };
    let nextParams = replace ? params : { ...prevParams, ...params };
    for (let k in nextParams) {
        if (nextParams[k] === null) {
            delete nextParams[k];
            delete routeParams[k];
        }
    }
    let nextPath = generatePath(routePattern, {
        ...routeParams,
        ...nextParams,
    });
    if (!nextPath) nextPath = "/";
    if (window.location.pathname.indexOf("/dev") > -1) {
        nextPath = window.location.pathname;
    }
    parts[0] = [nextPath];
    for (let k in routeParams) {
        delete nextParams[k];
    }
    let queryString = serializeQueryParams(nextParams);
    if (queryString) parts.push(queryString);
    let location = parts.join("?");
    return routerPush(location);
};

export const pushRoute = (route) => {
    if (window.location && window.location.protocol === "file:")
        return { type: "NULL" };
    return routerPush(route);
};
