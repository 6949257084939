import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import RoomsConfForm from "./RoomsConfForm";

export function RoomsConf(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"room_conf"} label={"Sala"}>
                        <RoomsConfForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

RoomsConf.propTypes = {};

RoomsConf.defaultProps = {};

export default RoomsConf;
