import React from 'react';
//import PropTypes from 'prop-types';

import 'cms/ui/css/tags-input.css';

class TagsInput extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {value: null};
    }

    change = (evt) => {
        this.setState({value: evt.target.value});
    };

    key = (evt) => {
        const { onAddTag } = this.props;
        if (evt.key === 'Enter' || evt.key === ' ') {
            if (onAddTag) onAddTag(this.state.value);
            this.setState({value: null});
        }
    };

    render() {
        const { label } = this.props;
        const { value } = this.state;

        return <input
            type={'text'}
            placeholder={label}
            value={value || ''}
            className={'tags-input'}
            onChange={this.change}
            onKeyPress={this.key}
        />;
    }
}

export default class TagsSelect extends React.Component {

    remove = (value) => {
        const { onRemoveTag } = this.props;
        onRemoveTag(value);
    };

    add = (value) => {
        const { onAddTag } = this.props;
        onAddTag(value);
    };

    render() {
        const {
            className,
            tagClassName,
            value,
            selected
        } = this.props;

        if (!value.length && !selected) return null;

        return (<div className={className}>
            {value.map(c => <div
                className={tagClassName} key={c}
                onClick={()=>this.remove(c)}
            >
                {c}
            </div>)}
            { selected ? <TagsInput
                label={'Custom classes'}
                onAddTag={this.add}
            /> : null }
        </div>);
    }

}

TagsSelect.defaultProps = {
    value: []
};

TagsSelect.propTypes = {};