//import React from 'react';
import {useMoment} from "joynt/components/DateTimePicker/hooks";

//import PropTypes from 'prop-types';

export function RangeLabel(props) {

    const {start, end, mode} = props;
    const startM = useMoment(start);
    const endM = useMoment(end);

    if (mode === "day")
        return startM.format('D MMMM YYYY');

    return [
        startM.format('D'),
        endM.format('D MMMM YYYY')
    ].join('-');

}

RangeLabel.propTypes = {};
RangeLabel.defaultProps = {};

export default RangeLabel;
