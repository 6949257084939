import React from 'react';
import PropTypes from 'prop-types';
import {POST_TYPE_ALBUM, POST_TYPE_POLL, POST_TYPE_TEXT} from "joynt/config";
import contentTypes from "joynt/components/Post/PostContent/content-types";
import AggregateRoot from "containers/AggregateRoot";

const Text = contentTypes[POST_TYPE_TEXT];
const Poll = contentTypes[POST_TYPE_POLL];
const Files = contentTypes[POST_TYPE_ALBUM];

export function PostCreateContent(props) {

    const {
        posts,
        types,
        type,
        onUpload
    } = props;

    const showFiles = posts[POST_TYPE_ALBUM] || type === POST_TYPE_ALBUM;
    const showPoll = posts[POST_TYPE_POLL] && type === POST_TYPE_POLL;

    return (<>
        <Text id={posts[POST_TYPE_TEXT]} editable={true} />
        {showFiles && <Files
            id={posts[POST_TYPE_ALBUM]}
            editable={true}
            showPlaceholder={true}
            onUpload={onUpload}
        />}
        {showPoll && (
            <Poll id={posts[POST_TYPE_POLL]} editable={true} />
        )}
        {types.map((postType) => {
            if (!postType || !posts[postType]) return null;
            return posts[postType].map((postId) => {
                const PostComponent = contentTypes[postType];
                return (
                    <AggregateRoot
                        key={postId}
                        type={"db.posts"}
                        id={postId}
                    >
                        <PostComponent id={postId} editable={true} />
                    </AggregateRoot>
                );
            });
        })}
    </>);

}

PostCreateContent.propTypes = {
    type: PropTypes.string,
    posts: PropTypes.object,
    types: PropTypes.array,
    onUpload: PropTypes.func
};
PostCreateContent.defaultProps = {
    posts: {},
    types: []
};

export default PostCreateContent;
