function detectCollision(events, matchEvent) {
    let mStart = matchEvent.event_start * 1;
    let mEnd = matchEvent.event_end * 1;
    for(let i = 0; i < events.length; i++) {
        let start = events[i].event_start * 1;
        let end = events[i].event_end * 1;
        if (mStart >= start && mStart < end) return true;
        if (start >= mStart && start < mEnd) return true;
    }
    return false;
}

function findTrack(tracks, event) {
    for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        if (track.length === 0) return i;
        if (!detectCollision(track, event)) return i;
    }
    return null;
}

export function selectTracks(store, ids) {
    let tracks = [[]];
    ids.forEach(id => {
        let event = store.data["db.nodes"][id] || {};
        let trackId = findTrack(tracks, event);
        if (trackId === null) {
            tracks.push([]);
            trackId = tracks.length - 1;
        }
        tracks[trackId].push(event);
    });
    return tracks.map(track => {
        return track.map(event=>event.id);
    });
}