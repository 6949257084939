export const setJoyrideId = (id) => ({
    type: "JOYRIDE.ID.SET",
    payload: { id },
});

export const setJoyrideRun = (run) => {
    console.log("setJoyrideRun", run);
    return {
        type: "JOYRIDE.RUN.SET",
        payload: { run },
    };
};
