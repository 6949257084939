import React from 'react';
import {connect} from 'react-redux';
import Inspector from "cms/ui/Inspector";
import {selectLang, selectRouteParam} from "state/selectors/router";
import {pushRoute, pushRouteParams} from "state/actions/router";
import {selectComponentRoot} from "cms/state/selectors/cms";
import {openMediaLibrary} from "state/actions/media";

const mapStateToProps = (store, props) => {
    const focus = selectRouteParam(store, 'focus');
    const [focusType,focusId] = focus ? focus.split('/') : [null,null];
    const root = selectComponentRoot(store, focusType, focusId);
    const devMode = selectRouteParam(store, 'dev');
    return {
        type: root.type || focusType,
        id: root.id || focusId,
        pageId: selectRouteParam(store, 'id'),
        pageType: selectRouteParam(store, 'resource'),
        focusStyle: selectRouteParam(store, 's'),
        focusType,
        focusId,
        listView: selectRouteParam(store, 'list', 'tree'),
        inspectorView: selectRouteParam(store, 'ins', 'style'),
        service: selectRouteParam(store, 'service'),
        path: [focusType, focusId].join('/'),
        pending: store.pending['front.page'],
        lang: selectLang(store),
        devMode
    }
};

const mapDispatchToProps = {
    pushRoute: pushRoute,
    pushRouteParams: pushRouteParams,
    onOpenMediaLibrary: openMediaLibrary
};

class InspectorContainer extends React.PureComponent {

    back = () => {
        const {
            pushRoute,
            lang,
            service,
            pageId,
        } = this.props;

        pushRoute(`/${lang}/${service}?resource=cms.pages&id=${pageId}`);
    };

    list = (id) => {
        const { pushRouteParams, onOpenMediaLibrary } = this.props;
        if (id === 'media') return onOpenMediaLibrary();
        pushRouteParams({list: id});
    };

    inspector = (id) => {
        const { pushRouteParams } = this.props;
        pushRouteParams({ins: id});
    };

    render() {
        return (<Inspector
            {...this.props}
            onChangeListView={this.list}
            onChangeInspectorView={this.inspector}
            onExit={this.back}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectorContainer);