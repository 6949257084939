import React from 'react';
import SidebarBreadcrumbs, {ParentNode} from "./SidebarBreadcrumbs";
import {useEdgesData, useNodeData} from "joynt/hooks";
import {useClick} from "state/hooks";

//import PropTypes from 'prop-types';

function ParentNodeContainer(props) {
    const {id} = props;
    const {name, icon, color} = useNodeData(id);
    const click = useClick(props);

    return <ParentNode
        name={name}
        icon={icon}
        color={color}
        onClick={click}
    />;
}

export function SidebarBreadcrumbsContainer(props) {

    const {id, onClick} = props;
    const {path, root} = useEdgesData(id);
    const workspacePath = path.slice(path.indexOf(root));

    return (<SidebarBreadcrumbs>
        {workspacePath && workspacePath.map(n=><ParentNodeContainer
            key={n}
            id={n}
            onClick={onClick}
        />)}
    </SidebarBreadcrumbs>);

}

SidebarBreadcrumbsContainer.propTypes = {};
SidebarBreadcrumbsContainer.defaultProps = {};

export default SidebarBreadcrumbsContainer;