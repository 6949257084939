import React from 'react';
import NotificationCenter from "joynt/notifications/NotificationCenter";

//import PropTypes from 'prop-types';

export function Activity(props) {

    //const {} = props;
    return (<NotificationCenter />);

}

Activity.propTypes = {};
Activity.defaultProps = {};

export default Activity;
