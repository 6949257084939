import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function FormField(props) {
    const {} = props;
    return (
        <>
            <Field
                id={"input_label"}
                label={"Input label"}
                fieldType={"text"}
            />
            <Field id={"input_name"} label={"Input name"} fieldType={"text"} />
            <Field
                id={"input_value"}
                label={"Input value"}
                fieldType={"text"}
            />
            <Field
                id={"placeholder"}
                label={"Input placeholder"}
                fieldType={"text"}
            />
        </>
    );
}

FormField.propTypes = {};

FormField.defaultProps = {};

export default FormField;
