import {useCallback} from 'react';
//import PropTypes from 'prop-types';

export default function useMockNetworkQuality(onPublishEvent) {

    return useCallback((quality) => {
        onPublishEvent('network-quality', {
            stats: {
                downlinkNetworkQuality: quality,
                uplinkNetworkQuality: quality
            }
        });
    }, [onPublishEvent]);

}

useMockNetworkQuality.propTypes = {};
useMockNetworkQuality.defaultProps = {};
