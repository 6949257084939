import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {months} from "joynt/components/DailySchedule/data";
import Popover from "components/Popover";
import Icon from "components/Icon";

import "./style.css";
import IconButton from "components/IconButton";

function MonthSelectNav(props) {
    const {
        children,
        value,
        year,
        onChange
    } = props;

    const prev = useCallback(() => {
        if (value === 1) {
            onChange(12, year-1);
            return;
        }
        onChange(value-1, year);
    }, [onChange, value, year]);

    const next = useCallback(() => {
        if (value === 12) {
            onChange(1, year+1);
            return;
        }
        onChange(value+1, year);
    }, [onChange, value, year]);

    return <div className={"month-select-nav"}>
        <IconButton onClick={prev} icon={"mui-chevron_left"} />
        {children}
        <IconButton onClick={next} icon={"mui-chevron_right"} />
    </div>
}

export function MonthSelect(props) {

    const {
        value,
        year,
        onChange,
        enableNav
    } = props;

    const select = (<Popover
        trigger={({onClick}) => <div className={"month-select"} onClick={onClick}>
            {months[value]} {year} <Icon>mui-arrow_drop_down</Icon>
        </div>}
        content={({onClose}) => <div>
            {months.map((m, i) => {
                return <ListItem
                    button
                    onClick={()=>{
                        onChange(i, year);
                        onClose();
                    }}
                >
                    <ListItemText primary={months[i]} />
                </ListItem>;
            })}
        </div>}
    />);

    if (!enableNav) return select;

    return <MonthSelectNav {...props}>
        {select}
    </MonthSelectNav>
}

MonthSelect.propTypes = {
    value: PropTypes.number,
    year: PropTypes.number,
    enableNav: PropTypes.bool
};
MonthSelect.defaultProps = {
    year: (new Date()).getFullYear(),
    enableNav: false
};

export default MonthSelect;
