import React from 'react';
import {TutorialAccordionList} from "joynt/components/TutorialAccordionList";
//import PropTypes from 'prop-types';

const content = [
    {
        id: 1,
        title: 'What is a workspace?',
        content: 'Lorem ipsum'
    },
    {
        id: 2,
        title: 'Networking and collaboration',
        content: 'Lorem ipsum'
    },
    {
        id: 3,
        title: 'Creating community',
        content: 'Lorem ipsum'
    },
    {
        id: 4,
        title: 'Channel types',
        content: 'Lorem ipsum'
    }
]

export default function AboutWorkspace(props) {

    return <TutorialAccordionList
        header={'About the Workspace'}
        variant={1}
        data={content}
    />;

}

AboutWorkspace.propTypes = {};
AboutWorkspace.defaultProps = {};
