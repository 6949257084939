import React from 'react';
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import IconButton from "components/IconButton";
import Button from "components/Button";
import Icon from "components/Icon";

export function UploadOverlay(props) {

    const {
        field,
        variant,
        icon
    } = props;

    return (
        <Field
            id={field}
            fieldType={"upload"}
            trigger={({ onClick }) => (
                <div className={"upload-overlay"}>
                    {variant === "icon"
                        ? <IconButton onClick={onClick} icon={icon} />
                        : <Button
                            onClick={onClick}
                            variant={"outlined"}
                            startIcon={<Icon>{icon}</Icon>}
                        >
                            Upload image
                        </Button>}
                </div>
            )}
        />
    );
}

UploadOverlay.defaultProps = {
    variant: "icon",
    icon: "mui-cloud_upload"
}

UploadOverlay.propTypes = {};

export default UploadOverlay;
