import { takeEvery, put, race, take, select, call } from "redux-saga/effects";
import { closeMediaLibrary, openMediaLibrary } from "state/actions/media";
import { selectChangedEntities, selectFieldValue } from "state/selectors/data";
import { change, storeList, storeOne } from "state/actions/data";
import * as api from "util/api/saga";
import { notify, pending } from "state/actions/ui";
import { fetch, post } from "state/actions/api";
import { deleteSuccess } from "state/actions/delete";
import { confirm } from "util/saga/feedback";
import { pushRouteParams } from "state/actions/router";
import { create } from "state/actions/create";
import uuid from "uuid/v4";
import { request } from "util/api/client";
import { selectList } from "state/selectors/lists";

function* handleOpenSelect({ payload, callback }) {
    try {
        console.log(payload);
        yield put(openMediaLibrary());
        const selection = yield race({
            select: take("CMS.MEDIA.SELECT"),
            cancel: take("CMS.MEDIA.CLOSE"),
        });
        if (selection.select) {
            if (callback) {
                callback(selection.select);
                yield put(closeMediaLibrary());
                return;
            }
            const { path, replace } = payload;
            const [type, id, field] = path.split("/");
            const prev = yield select((store) =>
                selectFieldValue(store, type, id, field)
            );
            const next = prev && !replace ? prev.slice() : [];
            const selected = selection.select.payload.id;
            next.push(selected);
            let update = {};
            update[field] = next;
            yield put(change(type, id, update));
        }
        yield put(closeMediaLibrary());
    } catch (e) {
        console.log(e);
    }
}

function* handleCreateDirectory({ context, payload }) {
    try {
        const { name } = payload;
        const request = { name, allDirectories: true };
        yield put(pending("cms.media", true));
        yield call(api.post, request, "cms/browser/dirs", null, context);
        yield put(pending("cms.media", false));
        yield put(
            fetch(context)(
                "cms.media.directories",
                "default",
                "cms/browser/dirs?batch=true"
            )
        );
        yield put(pushRouteParams({ dir: name }));
    } catch (e) {
        console.log(e);
        yield put(pending("cms.media", false));
    }
}

function* handleDeleteDirectory({ context, payload }) {
    try {
        const { name } = payload;
        if (yield confirm("Are you sure?")) {
            yield put(pending("cms.media/browser", true));
            yield put(pushRouteParams({ dir: null }));
            yield request({
                context,
                method: "delete",
                url: `cms/browser/dirs?name=${name}`,
            });
            yield put(deleteSuccess("cms.media.directories", name));
            yield put(pending("cms.media/browser", false));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleImportMedia({ context, payload }) {
    const id = uuid();

    try {
        const { type, url } = payload;
        yield put(pending(id, true));
        yield put(pushRouteParams({ dir: type === "video" ? "videos" : type }));
        yield put(create("cms.media", { id, type }, "browser"));
        const request = { id, url };
        const response = yield call(
            api.post,
            request,
            `cms/media/import`,
            null,
            context
        );
        if (response.data.data) {
            yield put(storeOne("cms.media", id, response.data.data));
        }
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
        yield put(deleteSuccess("cms.media", id));
        console.log(e);
    }
}

function* handleSyncMedia({ context, payload }) {
    try {
        const { dir } = payload;
        yield put(pending("cms.media/browser", true));
        const url = `cms/browser/sync`;
        const prevState = yield select((store) =>
            selectList(store, "cms.media", "browser")
        );
        const response = yield request({
            method: "post",
            url,
            context,
            data: { dir: dir || "" },
        });
        const data = response.data.data;
        const nextState = data.map((item) => item.id);
        yield put(
            storeList(
                "cms.media",
                "browser",
                response.data.data,
                null,
                true,
                true,
                1
            )
        );
        const newMedia = nextState.filter(
            (id) => !prevState.includes(id)
        ).length;
        if (newMedia) {
            yield put(
                notify(
                    `Dodano ${newMedia} nowych plików w katalogu ${dir || "/"}`,
                    "success"
                )
            );
        } else {
            yield put(notify(`Brak nowych plików do synchronizacji`));
        }
        yield put(pending("cms.media/browser", false));
    } catch (e) {
        console.log(e);
    }
}

function* handleSaveMedia({ context, payload }) {
    try {
        const data = yield select((store) =>
            selectChangedEntities(store, "cms.media")
        );
        if (data.length) {
            //yield put(pending('cms.media', true));
            const url = "cms/media/update";
            yield put(post(context)(url, data, "cms.media", "cms.media"));
            //yield put(pending('cms.media', false));
        }
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeEvery("CMS.MEDIA.CREATE_DIRECTORY", handleCreateDirectory);
    yield takeEvery("CMS.MEDIA.DELETE_DIRECTORY", handleDeleteDirectory);
    yield takeEvery("CMS.MEDIA.IMPORT", handleImportMedia);
    yield takeEvery("CMS.MEDIA.SYNC", handleSyncMedia);
    yield takeEvery("CMS.MEDIA.OPEN_SELECT", handleOpenSelect);
    yield takeEvery("CMS.MEDIA.SAVE", handleSaveMedia);
}
