import * as d3 from "d3";

const color = d3
    .scaleLinear()
    .domain([0, 6])
    .range(["hsl(0, 0%, 95%)", "hsl(263, 100%, 37%)", "hsl(337, 100%, 69%)"])
    .interpolate(d3.interpolateHcl);

export default function render(data, onClick, onHover, width, height) {
    const svg = d3.create("svg");

    const pack = d3.pack().size([width, height]).padding(8);

    const d = d3
        .hierarchy(data)
        .sum((d) => d.size)
        .sort((a, b) => b.value - a.value);

    const root = pack(d);
    let focus = root;
    let view;

    svg.attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
        .style("display", "block")
        .style("margin", "0 -14px")
        .style("background", color(0))
        .style("cursor", "pointer")
        .on("click", () => {
            zoom(root);
            onClick(d.data.id);
        });

    const node = svg
        .append("g")
        .selectAll("circle")
        .data(root.descendants().slice(1))
        .join("circle")
        .attr("fill", (d) => (d.children ? color(d.depth) : "white"))
        //.attr("pointer-events", d => !d.children ? "none" : null)
        .on("mouseover", function (d) {
            onHover(d.data.id);
            d3.select(this).attr("stroke", "#000");
        })
        .on("mouseout", function () {
            onHover(null);
            d3.select(this).attr("stroke", null);
        })
        .on("click", (d) => {
            d3.event.stopPropagation();
            //if (!d.children) return;
            onClick(d.data.id);
        });

    const label = svg
        .append("g")
        .style("font", "10px sans-serif")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(root.descendants())
        .join("text")
        .style("fill-opacity", (d) => (d.parent === root ? 1 : 0))
        .style("display", (d) => (d.parent === root ? "inline" : "none"))
        .text((d) => d.data.name);

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
        const k = width / v[2];

        view = v;

        label.attr(
            "transform",
            (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
        );
        node.attr(
            "transform",
            (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
        );
        node.attr("r", (d) => d.r * k);
    }

    function zoom(d) {
        focus = d;

        const transition = svg
            .transition()
            .duration(750)
            .tween("zoom", (d) => {
                const i = d3.interpolateZoom(view, [
                    focus.x,
                    focus.y,
                    focus.r * 2,
                ]);
                return (t) => zoomTo(i(t));
            });

        label
            .filter(function (d) {
                return d.parent === focus || this.style.display === "inline";
            })
            .transition(transition)
            .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
            .on("start", function (d) {
                if (d.parent === focus) this.style.display = "inline";
            })
            .on("end", function (d) {
                if (d.parent !== focus) this.style.display = "none";
            });
    }

    function findNode(id, root) {
        if (root.data.id === id) return root;
        if (!root.children) return null;
        for (let i = 0; i < root.children.length; i++) {
            let node = findNode(id, root.children[i]);
            if (node) return node;
        }
        return null;
    }

    function handleZoom(id) {
        let node = findNode(id, root);
        if (node) zoom(node);
    }

    return [
        svg.node(),
        (id) => {
            handleZoom(id);
        },
    ];
}
