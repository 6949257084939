import React from "react";
//import PropTypes from 'prop-types';

import { useZmapContext, ZmapContext } from "./ZmapContext";
import { useSelector } from "react-redux";
import { selectContext } from "./selectors";

export function ComponentContext(props) {
    const { type, id } = props;
    const prev = useZmapContext();
    const next = useSelector((store) => {
        return selectContext(store, prev, type, id);
    });
    return (
        <ZmapContext.Provider value={next}>
            {props.children}
        </ZmapContext.Provider>
    );
}

ComponentContext.propTypes = {};

ComponentContext.defaultProps = {};

export default ComponentContext;
