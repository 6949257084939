const icons = {
    all: "mui-mail",
    private: "mui-send",
    channels: "mui-chat",
    comments: "mui-mode_comment",
};

export function mapTypeToIcon(type) {
    return icons[type] || icons.private;
}
