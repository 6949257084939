import React from 'react';
import PropTypes from 'prop-types';
import UploadButton from "components/upload/UploadButton";
import Button from "components/Button";
import Icon from "components/Icon";

import "./style.css";

export function UploadPlaceholder(props) {

    const {onUpload} = props;

    return (<div className={"upload-placeholder"}>
        <UploadButton
            onUpload={onUpload}
            trigger={
                <Button
                    onClick={(_) => null}
                    variant={"text"}
                    endIcon={<Icon>mui-cloud_upload</Icon>}
                >
                    Upload a file
                </Button>
            }
        />
    </div>);

}

UploadPlaceholder.propTypes = {
    onUpload: PropTypes.func
};
UploadPlaceholder.defaultProps = {};

export default UploadPlaceholder;
