import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";
import SpaceHeader from "joynt/components/SpaceHeader/SpaceHeader";

export function WitnessBoard(props) {

    const {children} = props;

    return (<div className={"witness-board"}>
        <SpaceHeader
            name={"Witness board"}
        />
        {children}
    </div>);

}

WitnessBoard.propTypes = {};
WitnessBoard.defaultProps = {};

export default WitnessBoard;
