export const workspaceGroups = {
    type: "app.node-roles",
    groups: [
        {
            id: "draft",
            label: "Modified roles",
            qualifier: (state, item) => {
                return !!item.action;
            },
        },
        {
            id: "existing",
            label: "Current roles",
            qualifier: (state, item) => {
                return !item.action && !item.inherited;
            },
        },
        {
            id: "inherited",
            label: "Inherited",
            qualifier: (state, item) => {
                return !!item.inherited;
            },
        },
    ],
};

const roleGroups = [
    {
        id: "admin",
        label: "Admins",
    },
    {
        id: "member",
        label: "Collaborators",
    },
    {
        id: "guest",
        label: "Members",
    },
];

export const inheritedGroups = {
    type: "app.node-roles",
    groups: roleGroups.map((group) => ({
        ...group,
        qualifier: (state, item) => {
            return !!item.inherited && item.role === group.id;
        },
        valueMap: (item) => item.identity,
    })),
};

export const inheritedAssignedGroups = {
    type: "app.node-roles",
    groups: roleGroups.map((group) => ({
        ...group,
        qualifier: (state, item) => {
            return !!item.inherited && item.role === group.id && !item.action;
        },
        valueMap: (item) => item.id,
    })),
};

export const allGroups = {
    type: "app.node-roles",
    groups: roleGroups.map((group) => ({
        ...group,
        qualifier: (state, item) => {
            return item.role === group.id;
        },
        valueMap: (item) => item.identity || item.id,
    })),
};

export const allCurrentGroups = {
    type: "app.node-roles",
    groups: roleGroups.map((group) => ({
        ...group,
        qualifier: (state, item) => {
            return item.role === group.id && item.action !== "remove";
        },
        valueMap: (item) => item.identity || item.id,
    })),
};

export const assignedGroupsDraft = {
    type: "app.node-roles",
    groups: [
        {
            id: "draft",
            label: null,
            qualifier: (state, item) => {
                return !!item.action;
            },
        },
        // {
        //     id: 'existing',
        //     label: 'Current roles',
        //     qualifier: (state, item) => {
        //         return !item.action && !item.inherited;
        //     }
        // }
    ],
};

export const assignedGroups = {
    type: "app.node-roles",
    groups: [
        {
            id: "draft",
            label: null,
            qualifier: (state, item) => {
                return !!item.action;
            },
        },
        {
            id: "existing",
            label: "Current roles",
            qualifier: (state, item) => {
                return !item.action && !item.inherited;
            },
        },
    ],
};

export const allAssigned = {
    type: "app.node-roles",
    groups: [
        {
            id: "all",
            label: null,
            qualifier: (state, item) => {
                return !!item.action || !item.inherited;
            },
        },
    ],
};

export const adminRoles = {
    type: "app.node-roles",
    groups: [
        {
            id: "admins",
            label: null,
            qualifier: (state, item) => {
                return item.identity && item.role !== "guest";
            },
        },
    ],
};

export const attendees = {
    type: "app.node-roles",
    groups: [
        {
            id: "attendees",
            label: null,
            qualifier: (state, item) => {
                return !!item.identity;
            },
        },
    ],
};
