import React, { useRef, useState } from "react";
//import PropTypes from 'prop-types';

import {
    useComponentMapping,
    useFieldMapping,
    ZmapSuggest,
} from "playground/designer/Zmap";
import cn from "classnames";
import { RenderFieldType } from "containers/Field";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

import "./style.css";
import { useActions, useFormField } from "state/hooks";
import Button from "components/Button";
import Icon from "components/Icon";

function MappedValue({ value }) {
    if (value === null) return "<null>";
    if (value === "") return "<empty string>";
    if (Array.isArray(value)) {
        let type = value?.[0]?.["#schema"];
        if (type) return `[${type}]`;
        return `Array[] ${value.length}`;
    }
    if (typeof value === "string") return value;
    if (typeof value === "number") return value;
    if ([true, false].includes(value))
        return `Bool ${value ? "true" : "false"}`;
    return value ? JSON.stringify(value).slice(0, 50) : null;
}

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

function mapToStr(map) {
    const { _aliases, ...rest } = map;
    console.log(map, rest);
    return (_aliases || [])
        .concat(Object.values(rest))
        .filter(Boolean)
        .join(",");
}

function AliasMapping(props) {
    const { from, source } = props;
    return (
        <div className={"rows gap-sm pad-sm"}>
            <div>
                From alias <strong>{source}</strong>
            </div>
            <div>{from}</div>
        </div>
    );
}

function EditMapping(props) {
    const { id, mapping, onClose } = props;
    const { fields } = useComponentMapping();
    const { onChange } = useFormField("template_id");
    const value = fields[id] || null;
    const isUsingAlias =
        mapping?.source?.indexOf(":") === -1 &&
        mapping?.source?.indexOf("@") === 0;

    const [stateFrom, setStateFrom] = useState(null);
    const [stateTo, setStateTo] = useState(null);

    function isString(value) {
        return typeof value === "string";
    }

    const [from, to] = isString(value) ? value.split(":") : [];

    const fromValue = isString(stateFrom) ? stateFrom : from;
    const toValue = isString(stateTo) ? stateTo : to;

    const submit = (nextValue) => {
        const next = mapToStr({
            ...fields,
            [id]: nextValue,
        });
        onChange(next);
        setStateFrom(null);
        setStateTo(null);
        if (onClose) onClose();
    };

    const change = () => {
        let to = toValue || fromValue === id ? null : id;
        if (stateTo) to = stateTo;
        if (toValue) to = toValue;
        const nextValue = [fromValue, to].filter(Boolean).join(":");
        console.log(nextValue, toValue, stateTo, fromValue, id);
        //return;
        submit(nextValue);
    };

    const removeMapping = () => {
        submit(null);
    };

    if (isUsingAlias) return <AliasMapping {...mapping} />;

    return (
        <div className={"rows gap-xs pad-sm"}>
            <ZmapSuggest value={fromValue} onChange={setStateFrom} />
            <input
                className={"zmap-input"}
                value={toValue}
                onChange={(e) => setStateTo(e.target.value)}
                placeholder={id}
            />
            {isUsingAlias && (
                <div>
                    using alias <strong>{mapping.source}</strong>
                </div>
            )}
            <div>
                {mapping.from} -> {mapping.value}
            </div>
            <div className={"cols gap-xs"}>
                {!isUsingAlias && mapping && (
                    <Button color={"default"} onClick={removeMapping}>
                        <Icon>mui-close</Icon>
                    </Button>
                )}
                <Button onClick={change} fullWidth>
                    Save
                </Button>
            </div>
            {/*<pre>{JSON.stringify(fields, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(mapping, null, 2)}</pre>*/}
        </div>
    );
}

export function ZmapField(props) {
    const { entityType: type, entityId: id, id: fieldId, zmapAs } = props;
    const mapping = useFieldMapping(type, id, fieldId, zmapAs);
    const mapped = !!mapping.from;
    const [open, setOpen] = useState(false);
    const ref = useRef();
    return (
        <div
            ref={ref}
            className={cn({
                "mapped-field": mapped,
                "unmapped-field": !mapped,
            })}
        >
            <RenderFieldType {...props} variant={"dense"} />
            {mapped ? (
                <div className={"mapped-value"} onClick={() => setOpen(!open)}>
                    <MappedValue value={mapping.value} />
                </div>
            ) : (
                <div className={"add-mapping"} onClick={() => setOpen(true)}>
                    <Icon>mui-add</Icon>
                </div>
            )}
            {/*<pre>{JSON.stringify(mapping, null, 2)}</pre>*/}
            <Popper
                open={open}
                anchorEl={ref.current}
                placement={"bottom-end"}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                        <div className={"mapped-field-popup"}>
                            <div
                                className={"close-popup-btn"}
                                onClick={() => setOpen(false)}
                            >
                                <Icon>mui-close</Icon>
                            </div>
                            <EditMapping
                                id={fieldId}
                                mapping={mapping}
                                onClose={() => setOpen(false)}
                            />
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

ZmapField.propTypes = {};

ZmapField.defaultProps = {};

export default ZmapField;
