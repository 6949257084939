import React, { useState } from "react";
import Field from "containers/Field";
import { useData, useFetch, useFormField, usePending } from "state/hooks";
import PageSectionsListContainer from "./PageSectionsList.container";
import { useSelector } from "react-redux";
import Preloader from "components/Preloader";
import {
    selectLayouts,
    selectDefaultPageLayout,
} from "playground/cms/state/selectors/layout";
import Icon from "components/Icon";
import { PageLayoutSettingsPopover, LayoutsPath } from "./PageLayoutSettings";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

export function LayoutSectionsList(props) {
    const { type, id, onEditStyle, ...rest } = props;
    const { layout } = useData({ type, id });

    useFetch({
        type: "cms.layouts",
        url: "cms/layouts",
        list: "layout-select",
        yieldData: true,
    });

    const [editLayout, setEditLayout] = useState(false);
    const pageType = editLayout ? "cms.layouts" : type;
    const pageId = editLayout ? editLayout : id;
    const isPending = usePending(editLayout);

    const layouts = useSelector((store) => {
        return selectLayouts(store, type, id);
    });

    const { onChange } = useFormField("layout");
    const defaultTemplate = useSelector((store) => {
        return selectDefaultPageLayout(store, type, id);
    });
    const { description, title } = useData({
        type: "cms.layouts",
        id: defaultTemplate,
    });
    const defaultLayoutName = title || description;

    const handleEditStyles = () => {
        onEditStyle(editLayout || layout || layouts[0]);
    };

    return (
        <>
            <div className={"page-layout-settings cols gap-lg"}>
                <div className={"cols cols-middle gap-sm grow"}>
                    {editLayout ? (
                        <IconButton
                            icon={"mui-arrow_back"}
                            onClick={() => setEditLayout(false)}
                            size={"small"}
                            className={"inline"}
                        />
                    ) : null}
                    <div className={"cols cols-middle cols-spread gap-sm grow"}>
                        <div className={"cols cols-middle gap-sm grow"}>
                            <div className={"label"}>
                                <Icon>mui-web</Icon>
                                <div>Szablon:</div>
                            </div>
                            {!editLayout && (
                                <div>
                                    <PageLayoutSettingsPopover
                                        type={type}
                                        id={id}
                                        value={layout}
                                        defaultValue={layouts[0]}
                                        onEdit={setEditLayout}
                                    />
                                </div>
                            )}
                            {editLayout && (
                                <LayoutsPath
                                    items={layouts}
                                    onClick={setEditLayout}
                                    value={editLayout}
                                />
                            )}
                        </div>
                        {onEditStyle && (
                            <div onClick={handleEditStyles}>edytuj styl</div>
                        )}
                    </div>
                </div>
                {type === "cms.layouts" && (
                    <div className={"cols cols-middle gap-sm"}>
                        <div className={"label"}>Page type</div>
                        <Field
                            id={"page_type"}
                            fieldType={"select"}
                            source={"schema:data.layout-page-types"}
                            variant={"inline"}
                            label={"Typ podstrony"}
                        />
                    </div>
                )}
            </div>

            <PageSectionsListContainer {...rest} type={pageType} id={pageId} />
            <Preloader visible={isPending} />
        </>
    );
}

LayoutSectionsList.propTypes = {};

LayoutSectionsList.defaultProps = {};

export default LayoutSectionsList;
