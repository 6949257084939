import React from 'react';
//import PropTypes from 'prop-types';

import 'cms/ui/css/ide-context-menu.css';

export default function IdePageContextMenu(props) {

    const {
        x,
        y,
        menu,
        onClose,
        onFocus,
        onSelectMedia,
        onHover
    } = props;
    let style = {left: `${x}px`, top: `${y}px`};
    const focus = (evt, {type, path, s, section, dataPath}) => {
        if (type === 'media') {
            if (dataPath) {
                onSelectMedia(dataPath, true);
                return;
            }
            onSelectMedia([path,'media'].join('/'), true);
            return;
        }
        onFocus(path, s, section, 'tree');
        onClose();
        evt.stopPropagation();
    };
    return (<div
        onClick={onClose}
        style={style}
        className={'ide-context-menu default rows'}
    >
        { menu ? menu.map((item,index) => {
            const click = (evt) => focus(evt,item);
            const hover = (evt) => onHover(evt,item.path);
            return <div
                className={'menu-item'}
                key={index}
                onMouseOver={hover}
                onClick={click}>
                {item.type}
            </div>
        }) : null }
    </div>);

}

IdePageContextMenu.propTypes = {};