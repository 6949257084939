import React from "react";
import SessionLayout from "joynt/components/SessionLayout";
import views from "./../aside";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";

//import PropTypes from 'prop-types';

export function Layout(props) {
    const { Component, ...other } = props;

    const flow = useSessionFlow();
    const data = flow?.data || {};

    const AsideComponent = data.aside ? views[data.aside] : null;

    const configProps = {};

    if (data.header) configProps.HeaderComponent = data.header;
    const LayoutComponent = data.LayoutComponent || Component || SessionLayout;

    return (
        <LayoutComponent
            {...other}
            leftInfo={AsideComponent ? <AsideComponent /> : null}
            {...configProps}
        />
    );
}

Layout.propTypes = {};
Layout.defaultProps = {
    Component: SessionLayout,
};

export default Layout;
