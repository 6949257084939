import React from "react";
//import PropTypes from 'prop-types';

import SessionCard from "joynt/components/SessionListItem";
import { useSession } from "joynt/hooks/sessions";

export function SessionItemContainer(props) {
    const { id, Component } = props;

    const { onShow, onEdit } = useSession(id);

    return <Component {...props} onClick={onShow} onEdit={onEdit} />;
}

SessionItemContainer.propTypes = {};
SessionItemContainer.defaultProps = {
    Component: SessionCard,
};

export default SessionItemContainer;
