import { useCallback, useMemo } from "react";

import { useNodeData, useUserProfile } from "joynt/hooks";
import { usePending, useRouteParam } from "state/hooks";
import {
    FLAG_SESSION_PENDING,
    NODE_TYPE_EVENT,
    NODE_TYPE_PROJECT,
    NODE_TYPE_SPACE,
    NODE_TYPE_VIEWS,
    ROUTE_VIEW,
    STAGE_VIEW,
} from "joynt/config";
import { selectEntityData } from "state/selectors/data";
import { useSelector } from "react-redux";
import { selectFlag } from "state/selectors/ui";
import { WORKSPACE_PLAN_LITE } from "joynt/config/plans";
import { FEATURE_TASKS, FEATURE_WITNESS } from "joynt/config/features";
import {SESSION_TYPE_NETWORKING, SESSION_TYPE_WEBINAR, SESSION_TYPE_WEBINAR_SERIES} from "joynt/config/sessions";
import {selectUserProfile} from "state/selectors/app";
import { memoize } from "lodash";

const homeViews = [
    { id: "home", label: "About", type: "home", icon: "mui-home" },
    {
        id: "workspaces",
        label: "Spaces",
        type: "workspaces",
        icon: "mui-view_module",
    },
    { id: "agenda", label: "Events", type: "agenda", icon: "mui-event" },
    { id: "feed", label: "Posts", type: "feed", icon: "mui-art_track" },
];

const allWorkspacesViews = [
    {
        id: STAGE_VIEW,
        hide: true,
        icon: "mui-videocam",
        label: "Main stage",
        type: "workspace-stage",
    },
    { id: "feed", label: "Posts", type: "feed", icon: "mui-art_track" },
    {
        id: "workspace-schedule",
        hide: true,
        label: "Events",
        type: "workspace-schedule",
        icon: "mui-event",
    },
    {
        id: "all-sessions",
        hide: true,
        label: "Past sessions",
        type: "session-browser",
    },
    {
        id: "workspaces",
        label: "Spaces",
        type: "workspaces",
        icon: "mui-view_module",
    },
    { id: "agenda", label: "Events", type: "agenda", icon: "mui-event" },
];

const workspaceViews = [
    { id: "about", label: "About", type: "workspace", icon: "mui-home" },
    ...allWorkspacesViews,
    {
        id: "tasks",
        label: "Tasks",
        type: "tasks",
        icon: "mui-assignment_turned_in",
        flag: FEATURE_TASKS,
    },
    {
        id: "witness",
        label: "Witness",
        type: "witness-board",
        icon: "mui-account_circle",
        flag: FEATURE_WITNESS,
    },
];

const eventViews = [
    { id: "about", label: "About", type: "event-overview" },
    {
        id: "event-schedule",
        label: "Event schedule",
        type: "event-schedule",
        hide: true,
    },
    {
        id: STAGE_VIEW,
        hide: true,
        icon: "mui-videocam",
        label: "Main stage",
        type: "main-stage",
    },
];

const webinarSeriesViews = [
    {id: "about", label: "About", type: "webinar-series-overview"}
];

const workspaceLiteViews = [
    { id: "about", label: "About", type: "workspace-feed", icon: "mui-home" },
    ...allWorkspacesViews,
];

const projectRoomViews = [
    {
        id: "live-now",
        label: "Live now",
        type: "room-stage",
        icon: "mui-videocam",
    },
    { id: "about", label: "About", type: "room-about", icon: "mui-info" },
    {
        id: "schedule",
        label: "Schedule",
        type: "room-schedule",
        icon: "mui-history",
    },
    { id: "posts", label: "Posts", type: "feed", icon: "mui-art_track" },
    {
        id: "tasks",
        label: "Tasks",
        type: "tasks",
        icon: "mui-assignment_turned_in",
        flag: FEATURE_TASKS,
    },
];

const projectRoomLiveViews = [
    {
        id: STAGE_VIEW,
        label: "Stage",
        type: "main-stage",
        icon: "mui-videocam",
    },
    ...projectRoomViews,
];

const sessionViews = [
    { id: "about", label: "About", type: "about", icon: "mui-info" },
];

const sessionLiveViews = [
    { id: STAGE_VIEW, label: "Stage", type: "meeting", icon: "mui-videocam" },
    ...sessionViews,
];

const stageSessionViews = [...sessionViews];

const roomViews = [
    { id: STAGE_VIEW, label: "Stage", type: "meeting", icon: "mui-videocam" },
    { id: "about", label: "About", type: "about", icon: "mui-info" },
    { id: "posts", label: "Posts", type: "feed", icon: "mui-art_track" },
];

const networkingViews = [
    {
        id: "networking-session",
        type: "networking-session",
        label: "Home",
        icon: "mui-home",
    },
    { id: "main-chat", type: "session-chat", label: "Chat", icon: "mui-chat" },
    { id: "inbox", type: "inbox", label: "Inbox", icon: "mui-email" },
    { id: "people", type: "people", label: "People", icon: "mui-people" },
];

const sessionPageViews = [
    {
        id: "webinar",
        type: "webinar",
        label: "Home",
        icon: "mui-home",
    },
    {
        id: "main-chat",
        type: "session-chat",
        label: "Chat",
        icon: "mui-chat",
    },
    { id: "qa", type: "qa", label: "Q&A", icon: "mui-question_answer" },
    {
        id: "materials",
        type: "feed",
        label: "Materials",
        icon: "mui-art_track",
    },
    { id: "inbox", type: "inbox", label: "Inbox", icon: "mui-email" },
    { id: "people", type: "people", label: "People", icon: "mui-people" },
];

const channelViews = {
    home: homeViews,
    workspace: workspaceViews,
    event: eventViews,
    "workspace-lite": workspaceLiteViews,
    project: projectRoomViews,
    session: sessionViews,
    "session-live": sessionLiveViews,
    "stage-session": stageSessionViews,
    room: roomViews,
    "project-live": projectRoomLiveViews,
    "networking-session": networkingViews,
    webinar: sessionPageViews,
    'webinar-series': webinarSeriesViews
};

function selectNodeView(s, id) {
    const data = selectEntityData(s, "db.nodes", id);
    const {
        subtype,
        node_type,
        workspace,
        event_stage,
        event_state,
        workspace_plan,
        session_type,
        default_view,
    } = data;
    const pendingSession = selectFlag(s, FLAG_SESSION_PENDING);

    const isLive = event_state === "live" || pendingSession === id;
    const isEvent = subtype === NODE_TYPE_EVENT;
    const isRoot = node_type === "root";
    const isLite = workspace_plan === WORKSPACE_PLAN_LITE;

    if (isRoot) return "home";
    if (session_type === SESSION_TYPE_NETWORKING) return "networking-session";
    if (session_type === SESSION_TYPE_WEBINAR) return "webinar";
    if (session_type === SESSION_TYPE_WEBINAR_SERIES) return "webinar-series";
    if (workspace && isEvent) return "event";
    if (workspace) return isLite ? "workspace-lite" : "workspace";
    if (subtype === NODE_TYPE_PROJECT && isLive) return "project-live";
    if (subtype === NODE_TYPE_PROJECT) return "project";
    if (subtype === NODE_TYPE_SPACE) return "room";
    if (isEvent && default_view && default_view !== "default")
        return default_view;
    if (isEvent && event_stage) return "stage-session";
    if (isEvent && isLive) return "session-live";
    if (isEvent) return "session";

    return null;
}

const nullArr = [];

const globalViews = ["inbox", "calendar"];

function filterUserViews(views, profile) {
    return views.filter(v => {
        return !v.flag || profile[`feature_flag_${v.flag}`];
    });
}

const filterUserViewsMemo = memoize(filterUserViews);

export function selectNodeChannels(store, id) {
    const profile = selectUserProfile(store);
    const viewId = selectNodeView(store, id);
    const views = channelViews[viewId] || nullArr;
    return filterUserViewsMemo(views, profile);
}

export function useNodeChannels(id, channel) {
    const [selectedView, selectView] = useRouteParam(ROUTE_VIEW);

    const selectDefaultView = useCallback(
        (v) => {
            selectView(v !== "overview" ? v : null);
        },
        [selectView]
    );

    const channels = useSelector(s => selectNodeChannels(s, id));

    const currentView = useMemo(() => {
        const validViews = channels.map((c) => c.id);

        if (globalViews.indexOf(selectedView) > -1)
            validViews.push(selectedView);

        if (selectedView && validViews.indexOf(selectedView) > -1)
            return selectedView;

        return validViews[0];
    }, [selectedView, channels]);

    const viewConfig = useMemo(() => {
        return channels.filter((c) => c.id === currentView)[0] || {};
    }, [channels, currentView]);

    const {
        subtype: channelType,
        session_type,
        default_view,
    } = useNodeData(channel);

    const channelIsPending = usePending(channel);

    let channelView = NODE_TYPE_VIEWS[channelType];

    if (session_type && default_view && default_view !== "default")
        channelView = default_view;

    if (session_type === SESSION_TYPE_NETWORKING) channelView = "networking-session";
    if (session_type === SESSION_TYPE_WEBINAR) channelView = "webinar";
    if (session_type === SESSION_TYPE_WEBINAR_SERIES) channelView = "webinar-series";

    if (!channelView && channelIsPending) channelView = "pending";

    let type = channelView || viewConfig.type;

    if (globalViews.indexOf(currentView) > -1) type = currentView;

    return useMemo(
        () => ({
            views: channels,
            view: !channel ? currentView : null,
            type: type,
            onChangeView: selectDefaultView,
        }),
        [channels, channel, currentView, type, selectDefaultView]
    );
}
