import { useEdgesData } from "joynt/hooks/useEdgesData";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useCallback, useMemo } from "react";
import { useRouter } from "joynt/hooks/channels";
import { FLAG_SESSION_PENDING, STAGE_VIEW } from "joynt/config";
import { usePending, useUiFlag } from "state/hooks";
import { useSelector } from "react-redux";
import {
    selectEventRole,
    selectPresentIdentities,
} from "joynt/state/selectors/joint";
import { useNode } from "joynt/hooks";
import { canEndSession, canStartSession } from "joynt/config/roles";

export function useSessionState(id) {
    const { event_state } = useNode(id);
    const { stage } = useEdgesData(id);

    const role = useSelector((s) => selectEventRole(s, id));
    const canStart = canStartSession.indexOf(role) > -1;
    const canEnd = canEndSession.indexOf(role) > -1;

    const {
        session,
        onJoinSession,
        onStartNextSession,
        onEndSession,
        onResetSession,
    } = useMeeting();

    const { onLink } = useRouter();

    const isPending = usePending(stage);
    const [pending] = useUiFlag(FLAG_SESSION_PENDING);
    const [connectionState] = useUiFlag(`meeting.connection.${stage}`);

    const isConnected = session === stage;
    const isConnecting =
        pending === stage ||
        (isConnected && connectionState && connectionState.loading);
    const isStageSession = stage !== id;
    const isLive = event_state === "live";
    const hasEnded = event_state === "ended";

    const join = useCallback(() => {
        onJoinSession(stage);
    }, [stage, onJoinSession]);

    const start = useCallback(() => {
        if (isStageSession) {
            onStartNextSession(stage);
            return;
        }
        onJoinSession(stage);
    }, [onJoinSession, onStartNextSession, isStageSession, stage]);

    const end = useCallback(() => {
        onEndSession(stage);
    }, [onEndSession, stage]);

    const reset = useCallback(() => {
        onResetSession(id);
    }, [onResetSession, id]);

    const linkToStage = useCallback(() => {
        onLink(stage, null, STAGE_VIEW);
    }, [onLink, stage]);

    const presence = useSelector((s) =>
        selectPresentIdentities(s, stage, "sessions")
    );

    return useMemo(
        () => ({
            onGoToStage: linkToStage,
            onJoinSession: onJoinSession ? join : null,
            onStartSession: onStartNextSession ? start : null,
            onEndSession: onEndSession ? end : null,
            onResetSession: onResetSession ? reset : null,
            isStageSession,
            isConnected,
            isConnecting,
            isLive,
            isPending,
            stage,
            session,
            presence,
            role,
            canStart,
            canEnd,
            hasEnded,
        }),
        [
            linkToStage,
            onJoinSession,
            join,
            onStartNextSession,
            start,
            onEndSession,
            end,
            onResetSession,
            reset,
            isStageSession,
            isConnected,
            isConnecting,
            isLive,
            isPending,
            stage,
            session,
            presence,
            role,
            canStart,
            canEnd,
            hasEnded,
        ]
    );
}
