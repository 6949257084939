import React from 'react';
import {connect} from 'react-redux';
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";
import {isPending} from "state/selectors/ui";
import uuid from 'uuid/v4';
import classNames from 'classnames';
import Form from 'containers/Form';

import FilterField from "containers/FilterField";
import ListView from "containers/ListView";
import SideNavContainer from "containers/user-menu/SideNav";
import ListFormLayout from "components/layouts/ListFormLayout";
import FormLayout from "components/layouts/FormLayout";
import UserMenu from "containers/user-menu/UserMenu";
import EntityContext, {AggregateRootContext} from "containers/context/EntityContext";
import FormButtons from "components/FormButtons";
import PendingState from "components/list/PendingState";
import PageForm from "cms/ui/forms/PageForm";
import Fetch from "containers/Fetch";
import {Dialog} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {create} from "state/actions/create";
import {reject} from "state/actions/data";
import {submitOne, submitOrder} from "state/actions/api";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import ListItemMenu from "components/context-menu/ListItemMenu";
import {createSection} from "cms/state/actions/cms";

const mapStateToProps = (store, props) => {
    const requiredResources = [
        'db.types/db.types',
        'db.form_views/db.form_views'
    ];
    return {
        list: 'default',
        resource: selectRouteParam(store, 'resource'),
        id: selectRouteParam(store, 'id'),
        nav: selectRouteParam(store, 'nav'),
        isPending: isPending(store, requiredResources),
        showCreateDialog: !!selectRouteParam(store, 'create'),
        section: selectRouteParam(store, 'section')
    }
};

const mapDispatchToProps = bindApiActions({
    onSubmit: submitOne,
    onSortEnd: submitOrder
},{
    pushQuery: pushRouteParams,
    onCreate: create,
    onCreateSection: createSection,
    onCancel: reject
});

export const searchInput = {
    variant: 'search',
    fullWidth: true,
    label: 'Search...'
};



class ListFormView extends React.PureComponent {

    nav = () => {
        this.props.pushQuery({nav: !this.props.nav ? true : null});
    };

    click = (id) => {
        this.props.pushQuery({resource: id, id: null}, true);
    };

    clickItem = (id) => {
        this.props.pushQuery({id});
    };

    close = () => {
        this.props.pushQuery({section: null});
    };

    cancel = () => {
        const { resource, id, onCancel } = this.props;
        onCancel(resource, id);
    };

    cancelClose = () => {
        const {pushQuery} = this.props;
        this.cancel();
        pushQuery({id: null, section: null});
    }

    cancelSection = () => {
        this.props.onCancel('cms.sections', this.props.section);
        this.props.pushQuery({section: null});
    };

    createDialog = () => {
        this.props.pushQuery({create: 'cms.pages'});
    };

    create = () => {
        const { onCreate, resource, list } = this.props;
        const newId = uuid();
        onCreate(resource, {id: newId, draft: true}, list);
        this.props.pushQuery({id: newId, tab: 'edit'});
        this.cancelCreate();
    };

    createSection = () => {
        const {resource, id, onCreateSection, pushQuery} = this.props;
        const newId = uuid();
        onCreateSection(resource, id, {id: newId});
        pushQuery({section: newId, create: null});
    }

    cancelCreate = () => {
        this.props.pushQuery({create: null});
    };

    editSection = (id) => {
        this.props.pushQuery({section: id});
    };

    submitClose = () => {
        this.submit();
        this.close();
    };

    submitSection = () => {
        const { onSubmit, section } = this.props;
        onSubmit('cms.sections', section);
    };

    submit = () => {
        const { onSubmit, resource, id} = this.props;
        onSubmit(resource, id);
    };

    sort = (order) => {
        const { resource, list, onSortEnd } = this.props;
        let url = resource.indexOf('cms.entries') === 0
            ? 'cms/entries/treeIndex'
            : resource.replace(/\./g, '/') + '/treeIndex';
        onSortEnd(url, resource, list, order);
    };

    render() {
        const {
            resource,
            id,
            list,
            isPending,
            nav,
            showCreateDialog,
            section
        } = this.props;

        //const entityPath = [resource, id].join('/');
        const entityPath = ['cms.sections', section].join('/');

        return <>
            <Dialog open={showCreateDialog} onClose={this.cancelCreate}>
                <div>
                    <div className={'pad-sm type-lg'}>
                        Create a new:
                    </div>
                    <List>
                        <ListItem button onClick={this.create}>
                            <ListItemText
                                primary={'Page'}
                                secondary={'Create a new page'}
                            />
                        </ListItem>
                        <ListItem button onClick={this.createSection}>
                            <ListItemText
                                primary={'Section'}
                                secondary={'Create a new section'}
                            />
                        </ListItem>
                        <ListItem button disabled>
                            <ListItemText
                                primary={'Attach section'}
                                secondary={'Attach or copy existing section'}
                            />
                        </ListItem>
                    </List>
                </div>
            </Dialog>
            <ListFormLayout
                isPending={isPending}
                sideNav={<SideNavContainer
                    onClick={this.click}
                    onShowUserMenu={this.nav}
                    showUserMenu={!resource || nav}
                />}
                userMenu={<UserMenu resource={resource} onClick={this.click} />}
                showUserMenu={!resource || nav}
                onFabClick={!id ? this.create : null}
                list={resource ? <div className={classNames({
                    'default rows grow': true,
                    hide: !!nav
                })}>
                    <div className={'pad-sm'}>
                        <FilterField
                            {...searchInput}
                            type={resource}
                            id={'search'}
                            label={'Search...'}
                        />
                    </div>
                    <div className={'grow rows'}>
                        <List className={'grow'}>
                            <ListView
                                type={resource}
                                list={list}
                                filter={'cms.pages/default'}
                                filterFetch={false}
                                sortable={true}
                                context={true}
                                RenderMenuItems={ListItemMenu}
                                onClick={this.clickItem}
                                onSortEnd={this.sort}
                                pendingState={<PendingState />}
                            />
                        </List>
                    </div>
                </div> : null}
                secondaryList={id ? <div className={'grow rows overflow-hide page-form default'}>
                    <Fetch type={resource} id={id} url={`${resource}/:id`}>
                        <PageForm
                            type={resource}
                            id={id}
                            onEditSection={this.editSection}
                            onCancel={this.cancel}
                            onClose={this.cancelClose}
                            onSubmit={this.submit}
                            onFabClick={this.createDialog}
                        />
                    </Fetch>
                </div> : null}
                form={!isPending && section ? <AggregateRootContext.Provider value={this.submit}>
                    <EntityContext.Provider value={entityPath}>
                        <Fetch type={'cms.sections'} id={section} url={`cms/sections/:id`}>
                            <FormLayout
                                className={'form-regular'}
                                onClose={this.cancelSection}
                                form={<Form type={'cms.sections'} id={section} />}
                                footer={<FormButtons
                                    onCancel={this.cancelSection}
                                    onSubmit={this.submitSection}
                                />}
                            />
                        </Fetch>
                    </EntityContext.Provider>
                </AggregateRootContext.Provider> : null}
            />
        </>;
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListFormView));