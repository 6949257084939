import React from 'react';
import {connect} from 'react-redux';

import Entity from 'containers/Entity';
import {selectEntity} from "state/selectors/data";

const mapStateToProps = (store, props) => {
    const data = selectEntity(store, 'db.nodes', props.id);
    return {
        name: data.name
    }
};

const mapDispatchToProps = {};

class Header extends React.PureComponent {

    render() {
        const {
            name
        } = this.props;

        return (<div className={'default-c600 cols cols-spread cols-middle'}>
            <div style={{ display: 'inline', flex: 1, paddingLeft: '10px', overflow: 'hidden', maxWidth: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {name}
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ padding: '0.6rem', display: 'flex', flex: 1, alignItems: 'center' }}>
                    <Entity type={'db.users'} alias={'@currentUser'}>
                        {({data}) => {
                            return <div>{data.name}</div>;
                        }}
                    </Entity>
                </div>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);