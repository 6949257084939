import React from 'react';
import PropTypes from 'prop-types';
import SelectListItem from "./SelectListItem";
import {useClick, useData} from "state/hooks";

export function SelectListItemContainer(props) {

    const {name} = useData(props);
    const click = useClick(props);

    return (<SelectListItem
        {...props}
        label={name}
        onClick={click}
    />);

}

SelectListItemContainer.propTypes = {
    id: PropTypes.string
};
SelectListItemContainer.defaultProps = {};

export default SelectListItemContainer;