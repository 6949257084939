import React, { useEffect, useMemo, useRef, useState } from "react";

import Popover from "components/Popover";
import { useWindowSize } from "util/hooks/useWindowSize";
import { PillButton } from "joynt/components/PillButton";
//import PropTypes from 'prop-types';

function TabList({ items, RenderItem, ...other }) {
    return (
        <div className={"channel-select"}>
            {items.map((id) => {
                return <RenderItem key={id} id={id} {...other} />;
            })}
        </div>
    );
}

const measureStyle = {
    position: "absolute",
    visibility: "hidden",
    pointerEvents: "none",
};

const wrapperStyle = {
    overflow: "hidden",
    marginTop: "-20px",
    paddingTop: "20px",
};

function RenderTabs(props) {
    const { items, wrap, RenderItem, ...other } = props;
    const { selected } = props;

    const [visibleItems, wrappedItems] = useMemo(() => {
        if (wrap === null || wrap < 0) return [items];
        let selectedIndex = items.indexOf(selected);
        if (selectedIndex <= wrap - 1) {
            return [items.slice(0, wrap), items.slice(wrap)];
        }
        let visible = items.slice(0, wrap - 1);
        visible.push(selected);
        let wrapped = items.slice(wrap - 1).filter((id) => id !== selected);
        return [visible, wrapped];
    }, [items, selected, wrap]);

    return (
        <div className={"space-tabs__buttons"}>
            {visibleItems.map((id) => {
                return <RenderItem key={id} id={id} {...other} />;
            })}
            {wrappedItems ? (
                <Popover
                    trigger={({ onClick }) => (
                        <PillButton
                            variant={"outlined"}
                            onClick={onClick}
                            icon={"mui-toc"}
                            label={"More"}
                        />
                    )}
                    content={({ onClose }) => (
                        <div onClick={onClose}>
                            <TabList
                                RenderItem={RenderItem}
                                items={wrappedItems}
                                {...other}
                            />
                        </div>
                    )}
                />
            ) : null}
        </div>
    );
}

function ResponsiveTabs(props) {
    const { items, ...other } = props;
    const { RenderItem } = props;

    const measure = useRef(false);
    const [wrap, setWrapIndex] = useState(0);
    const { width } = useWindowSize();

    useEffect(() => {
        const spacing = 10;
        const extraSpace = 48;
        if (measure && measure.current) {
            const parentWidth = measure.current.parentNode.offsetWidth;
            const tabsWidth = measure.current.offsetWidth;
            if (parentWidth < tabsWidth) {
                const children = measure.current.childNodes;
                let cumulativeWidth = extraSpace;
                let wrapIndex = null;
                children.forEach((child, index) => {
                    let tabWidth = child.offsetWidth;
                    cumulativeWidth += tabWidth + spacing;
                    if (cumulativeWidth > parentWidth && wrapIndex === null) {
                        wrapIndex = index;
                        setWrapIndex(wrapIndex || 1);
                    }
                });
            } else {
                setWrapIndex(null);
            }
        }
    }, [measure, items, width]);

    return (
        <div className={"grow rows"} style={wrapperStyle}>
            <div ref={measure} className={"cols gap-xs"} style={measureStyle}>
                {items.map((id) => {
                    return <RenderItem key={id} id={id} {...other} />;
                })}
            </div>
            <div className={"cols gap-xs"}>
                <RenderTabs
                    wrap={wrap}
                    items={items}
                    RenderItem={RenderItem}
                    {...other}
                />
            </div>
        </div>
    );
}

export const ResponsiveTabsIterator = (RenderItem) => ({ items, ...other }) => {
    return <ResponsiveTabs items={items} RenderItem={RenderItem} {...other} />;
};

ResponsiveTabs.propTypes = {};
ResponsiveTabs.defaultProps = {};
