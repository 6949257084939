import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import Field from "containers/Field";
import TagsField from "playground/cms/fields/TagsField";
import CategoriesTree from "playground/cms/views/Categories/CategoriesTree";
import { MediaField } from "playground/cms/fields/Media";

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
};

function CategorySelect(props) {
    const { onChange, value } = props;
    return (
        <div style={{ width: "320px", height: "400px" }}>
            <CategoriesTree onSelect={onChange} selected={value} />
        </div>
    );
}

export function CategoryForm(props) {
    const { type, id } = props;

    return (
        <div className={"pad-md rows gap-md"}>
            <Field
                id={"parent_category"}
                fieldType={"select"}
                source={"api:categories"}
                label={"Nadrzędna kategoria"}
                nullable={true}
                multiple={false}
                SelectMenuComponent={CategorySelect}
                // variant={"dense"}
            />
            <TagsField label={"Tagi kategorii"} />
            <Field
                label={"Typ wpisu"}
                id={"entry_type"}
                fieldType={"select"}
                source={"schema:entries.types"}
            />
            <TagsField id={"list_tags"} label={"Tagi listy"} />
            <MediaField id={"media"} label={"Zdjęcia kategorii"} />
            <Field
                id={"headline"}
                fieldType={"text"}
                label={"Podtytuł"}
                size={"large"}
            />
            <Field id={"text"} fieldType={"rich"} label={"Treść"} />

            {/*<Form type={type} id={id} selectFields={selectFields} />*/}
        </div>
    );
}

CategoryForm.propTypes = {};

CategoryForm.defaultProps = {};

export default CategoryForm;
