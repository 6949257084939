import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {selectChannelGroup} from "joynt/hooks/channels/useChannelGroups";
import SidebarSection, {CreateChannelIcon} from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import SidebarNodeItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/SidebarItem.container";
import {NODE_TYPE_EVENT} from "joynt/config";
import {useNodeData} from "joynt/hooks";
import LiveNowContainer from "joynt/components/LiveNow";
import MainStageItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/MainStageItem/MainStageItem.container";

export function LiveSessions(props) {

    const {id, type, onClick, selected, path, isAdmin, onCreate} = props;

    const {subtype, node_type, current_event, next_event} = useNodeData(id);

    const isHome = node_type === "root";
    const isEvent = subtype === NODE_TYPE_EVENT;
    const hasEvent = current_event || next_event;

    const nodes = useSelector(s => selectChannelGroup(s, null, id, "sessions"));
    const hasMainStage = !isHome && hasEvent;
    //if (!nodes.length && !hasEvent) return null;

    return (<SidebarSection
        name={"Live now"}
        icon={isAdmin && <CreateChannelIcon
            onClick={()=>onCreate({subtype: NODE_TYPE_EVENT})}
        />}
        emptyState={"There are no live sessions now"}
    >
        {(nodes.length || hasMainStage) && <>
            {!isHome && hasEvent && <LiveNowContainer
                id={id}
                Component={MainStageItem}
            />}
            {!!nodes.length && nodes.map(n => <SidebarNodeItem
                key={n}
                id={n}
                onClick={onClick}
                isActive={selected === n}
                isHighlighted={path && path.indexOf(n) > -1}
            />)}
        </>}
    </SidebarSection>);

}

LiveSessions.propTypes = {};

LiveSessions.defaultProps = {};

export default LiveSessions;