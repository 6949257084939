import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {selectNodeChannels} from "joynt/hooks/channels/useNodeChannels";
import SidebarItem from "joynt/components/Sidebar/SidebarNav/SidebarItem";
import SidebarSection from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import {useEdgesData} from "joynt/hooks";

export function Listings(props) {

    const {id, onClick, view} = props;

    const views = useSelector(s =>  selectNodeChannels(s, id));
    const {child_workspaces, child_events} = useEdgesData(id);

    const counts = useMemo(() => ({
        "workspaces": child_workspaces,
        "agenda": child_events
    }), [child_workspaces, child_events]);

    const listings = useMemo(() => {
        const countFiltered = Object.keys(counts);
        return views.slice(1)
            .filter(v => {
                return countFiltered.indexOf(v.id) === -1
                    || counts[v.id] > 0;
            })
            .filter(v => !v.hide);
    }, [views, counts]);

    if (!listings.length) return null;

    return (<SidebarSection name={"Listings"}>
        {listings.map(v => <SidebarItem
            key={v.id}
            icon={v.icon}
            label={v.label}
            variant={"pinned"}
            isActive={v.id === view}
            onClick={()=>onClick(id, null, v.id)}
        />)}
    </SidebarSection>);

}

Listings.propTypes = {};

Listings.defaultProps = {};

export default Listings;