import React from 'react';
import {enforceHttps} from "util/uri";
import env from "util/env";
import VideoPlayer from "joynt/components/VideoPlayer";

//import PropTypes from 'prop-types';

export default function Video(props) {

    const {file} = props;
    const host = enforceHttps(env('FILES_CDN'));
    const playbackUrl = `${host}${file}`;

    return <VideoPlayer url={playbackUrl} />;
}

Video.propTypes = {};
Video.defaultProps = {};
