import React from "react";
//import PropTypes from 'prop-types';

import "./style.css";

export const SessionWindow = React.forwardRef((props, ref) => {
    const { style, children } = props;

    return (
        <div ref={ref} className={"floating-session dark-mode"} style={style}>
            {children}
        </div>
    );
});

SessionWindow.propTypes = {};
SessionWindow.defaultProps = {};

export default SessionWindow;
