import React, { useCallback, useEffect, useState } from "react";

import { useApiActions } from "state/hooks";
import { fetchMentionSuggestions } from "joynt/state/actions/messages";
//import PropTypes from "prop-types";

export default function MentionSuggestions(props) {
    const { id, Component } = props;

    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [search, setSearch] = useState(null);

    const { onFetch } = useApiActions({
        onFetch: fetchMentionSuggestions,
    });

    useEffect(() => {
        onFetch(id, search, (response) => setSuggestions(response));
    }, [id, onFetch, search, setSuggestions]);

    const onOpen = useCallback(
        (_open) => {
            setOpen(_open);
        },
        [setOpen]
    );

    const onSearchChange = useCallback(
        ({ value }) => {
            setSearch(value);
        },
        [setSearch]
    );
    return (
        <Component
            open={open}
            onOpenChange={onOpen}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            onAddMention={() => {
                // get the mention object selected
            }}
        />
    );
}

MentionSuggestions.defaultProps = {};
MentionSuggestions.propTypes = {};
