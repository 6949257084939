import React from 'react';
// import PropTypes from 'prop-types';
import {useEvents} from "joynt/hooks/channels";
import SidebarSection, {CreateChannelIcon} from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import SidebarNodeItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/SidebarItem.container";
import {NODE_TYPE_EVENT} from "joynt/config";

export function WebinarSeries(props) {

    const {id, onClick, isAdmin, onCreate} = props;
    const events = useEvents(id);

    return (<SidebarSection
        name={"Webinar series"}
        emptyState={"There are no scheduled webinars yet"}
        icon={isAdmin && <CreateChannelIcon
            onClick={()=>onCreate({
                subtype: NODE_TYPE_EVENT,
                workspace: true,
                session_type: "webinar-series"
            })}
        />}
    >
        {events.map(id => <SidebarNodeItem
            key={id}
            id={id}
            onClick={onClick}
        />)}
    </SidebarSection>);

}

WebinarSeries.propTypes = {};

WebinarSeries.defaultProps = {};

export default WebinarSeries;