import React from "react";
//import PropTypes from 'prop-types';
import FontItem from "./FontItem";
import { useData } from "state/hooks";

export function FontItemContainer(props) {
    const { type, id, style, onClick } = props;
    const { family, source } = useData(props);
    const click = () => onClick(source, family);
    return (
        <FontItem
            family={family}
            source={source}
            style={style}
            onClick={click}
        />
    );
}

FontItemContainer.propTypes = {};

FontItemContainer.defaultProps = {};

export default FontItemContainer;
