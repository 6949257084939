import {useSelector} from "react-redux";
import {selectEntity} from "state/selectors/data";
import {useActions, useRouteParam, useUiFlag} from "state/hooks";
import {useEffect, useState} from "react";
import {autoJoinMeeting} from "joynt/state/actions/meetings";
import {useSessionState} from "joynt/meeting/hooks/useSessionState";

export default function useProgramaticJoin(id) {

    const {onJoinSession} = useActions({onJoinSession: autoJoinMeeting});
    const {setAudio} = useSessionState(id);
    const [join] = useRouteParam('join');
    const [session] = useUiFlag('meetingSessionId', null);
    const access = useSelector(s=>selectEntity(s, 'db.session-access', id));
    const [joined, setJoined] = useState(false);

    useEffect(() => {
        if (join && !joined && !session && access.token) {
            setJoined(true);
            setAudio(false);
            onJoinSession(id);
        }
    }, [
        join,
        joined,
        access,
        session,
        onJoinSession,
        setAudio,
        id
    ]);

}