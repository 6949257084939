import { useMemo } from "react";
import { useCurrentUser, useData } from "state/hooks";
import { sanitize } from "dompurify";

const nullObj = {};

const sanitizationConfig = { ALLOWED_TAGS: [] };

export function useNotificationData(id) {
    const user = useCurrentUser();
    const data = useData({ type: "db.notifications", id });
    const identity = useData({ type: "db.identities", id: data.identity });
    const activity = useData({ type: "db.activity", id: data.activity });
    const subject = useData({
        type: ["db", activity.resource_type].join("."),
        id: activity.resource_id,
    });

    return useMemo(() => {
        let subjectName = subject.name;

        if (subject.type === "identities" && subject.created_by === user)
            subjectName = "You";

        let identityName = identity.created_by === user ? "You" : identity.name;

        let parent = data.parent_node || nullObj;

        return {
            data,
            count: data.count || 1,
            identities: data.identities,
            identity,
            activity,
            type: data.notification_type,
            priority: data.notification_priority,
            subjectName: subjectName
                ? sanitize(subjectName, sanitizationConfig)
                : sanitize(activity.resource_name, sanitizationConfig),
            link: activity.link_url,
            identityName,
            parentName: sanitize(parent.name, sanitizationConfig),
        };
    }, [data, identity, activity, subject, user]);
}
