import {useCallback} from "react";
import {useActions, useRouteParam} from "state/hooks";
import {ROUTE_CHANNEL, ROUTE_THREAD, ROUTE_VIEW} from "joynt/config";
import {pushRouteParams} from "state/actions/router";

export function useChannelView() {
    const [view] = useRouteParam(ROUTE_VIEW);
    const {pushParams} = useActions({pushParams: pushRouteParams});
    const change = useCallback(v => {
        const params = {};
        params[ROUTE_VIEW] = v !== 'overview' ? v : null;
        params[ROUTE_CHANNEL] = null;
        params[ROUTE_THREAD] = null;
        pushParams(params);
    }, [pushParams]);
    return [view, change];
}