import { useContext, useEffect } from "react";
import { FloatingSessionContext } from "joynt/components/FloatingSession/context";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export function useDraggable(ref) {
    const item = { id: "float", type: "float" };
    const pos = useContext(FloatingSessionContext);

    const [result, drag, preview] = useDrag({
        item,
        collect: (monitor, props) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const { isDragging } = result;

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    useEffect(() => {
        if (isDragging) {
            document.body.classList.add("dragging");
        } else {
            document.body.classList.remove("dragging");
        }
        return () => {
            document.body.classList.remove("dragging");
        };
    }, [isDragging]);

    drag(ref);

    const style = {};

    if (pos) {
        style.left = `${pos.x}px`;
        style.top = `${pos.y}px`;
    }

    return { style, isDragging };
}
