import { takeEvery, select, put } from "redux-saga/effects";
import { copySection } from "cms/state/sagas/drag-section";
import { selectEntity } from "state/selectors/data";
import { dragSection } from "cms/state/actions/cms";
import { denormalizeEntity } from "state/util/normalize";
import uuid from "uuid/v4";
import { change, storeOne } from "state/actions/data";
import { setFlag } from "state/actions/ui";
import { selectFlag } from "state/selectors/ui";

function* handlePaste({ payload, context }) {
    try {
        const { target, contents } = payload;
        const content = contents[0];
        const copy = yield copySection(content.type, content.id);
        const page = yield select((store) =>
            selectEntity(store, target.type, target.id)
        );

        const newSections = page.sections.reduce((acc, section) => {
            acc.push(section);
            if (section === target.section) acc.push("@DROP");
            return acc;
        }, []);

        const request = {
            refId: content.id,
            newId: "@DROP",
            origin: "local",
            target: "local",
            currentItems: page.sections,
            items: newSections,
        };

        yield put(dragSection(context)(target.type, target.id, request));

        console.log(copy);
    } catch (e) {
        console.error(e);
    }
}

function* handleCopyContent({ payload }) {
    try {
        console.log("copy", payload);
        const { type, id, path } = payload;
        const data = yield select((store) =>
            denormalizeEntity(store, type, id)
        );
        const source = data[path] || [];
        const copy = source.map((item) => ({ ...item, id: uuid() }));
        yield put(setFlag("clipboard.contents", copy));
        console.log(copy);
    } catch (e) {
        console.error(e);
    }
}

function* handlePasteContent({ payload }) {
    try {
        console.log("paste", payload);
        const { type, id, path } = payload;
        const data = yield select((store) =>
            denormalizeEntity(store, type, id)
        );
        const contents = yield select((store) =>
            selectFlag(store, "clipboard.contents")
        );
        if (!contents) return;
        const next = [...data[path], ...contents];
        yield put(storeOne(type, id, { ...data, [path]: next }));
        yield put(change(type, id, { [path]: next.map((item) => item.id) }));
        yield put(setFlag("clipboard.contents", null));
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("CMS.CLIPBOARD.PASTE", handlePaste);
    yield takeEvery("CMS.CLIPBOARD.COPY_CONTENT", handleCopyContent);
    yield takeEvery("CMS.CLIPBOARD.PASTE_CONTENT", handlePasteContent);
}
