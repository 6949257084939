import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { Helmet } from "react-helmet";
import Entrypoint from "containers/Entrypoint";
import { useCurrentService } from "playground/cms/hooks";
import { useActions, useData, useFetch, useRouteParam } from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import ColorScheme from "./ColorScheme";
import TypographyFonts from "./TypographyFonts";
import { StylesProvider } from "cms/styles/legacy";
import { useSelector } from "react-redux";
import ControlledSelect from "components/fields/select/ControlledSelect";
import StyleEditor from "./StyleEditor";
import { change } from "state/actions/data";
import {
    selectDefinedStyles,
    selectComponentTypes,
} from "./StyleEditor/selectors";
import SelectComponentType from "playground/designer/SelectComponentType";
import FileManager from "playground/theme/FileManager";
import ChangesSubmit from "cms/ui/containers/ChangesSubmit";
import SchemaSettings from "playground/console/views/Websites/SchemaSettings";
import ZmapPlayground from "playground/designer/Zmap";
import ApiPlayground from "playground/theme/ApiPlayground/ApiPlayground";

function StylesManager(props) {
    const [id, setId] = useState("button");
    const [type] = id.split(".");

    const [element, setElement] = useState(null);

    const styles = useSelector((store) => {
        return selectDefinedStyles(store, type);
    });

    const componentTypes = useSelector((store) => {
        return selectComponentTypes(store);
    });

    const changeType = (v) => {
        setElement(null);
        setId(v);
    };

    const value = useData({ type: "cms.styles", id: id });
    const { onChange } = useActions({ onChange: change });

    const handleChange = (next) => {
        onChange("cms.styles", id, next);
    };

    return (
        <div className={"rows gap-md pad-lg"}>
            <div className={"cols gap-sm"}>
                <div className={"rows gap-xs"}>
                    <ControlledSelect
                        options={componentTypes.map((t) => ({
                            id: t,
                            label: t,
                        }))}
                        value={id}
                        onChange={changeType}
                    />
                    {styles.map((s) => (
                        <div key={s} onClick={() => setId(s)}>
                            {s}
                        </div>
                    ))}
                </div>
                <div>
                    {id && (
                        <StyleEditor
                            id={id}
                            element={element}
                            onSelectElement={setElement}
                            value={value}
                            onChange={handleChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function ThemePreset(props) {
    const { presetId } = props;

    return (
        <AggregateRoot
            type={"console.presets"}
            id={presetId}
            disableSubmit={true}
        >
            <Field
                id={"typography"}
                fieldType={"typography"}
                FieldComponent={TypographyFonts}
            />
            <Field
                id={"color_scheme"}
                fieldType={"colors"}
                FieldComponent={ColorScheme}
            />
            <Field id={"typography.styles"} fieldType={"type-styles"} />
        </AggregateRoot>
    );
}

const views = {
    api: ApiPlayground,
    zmap: ZmapPlayground,
    "file-manager": FileManager,
    "schema-settings": SchemaSettings,
    "component-select": SelectComponentType,
    "theme-preset": ThemePreset,
    "style-manager": StylesManager,
};

function Playground(props) {
    const { theme: themeId } = useCurrentService();
    const theme = useData({ type: "console.themes", id: themeId });
    const { preset: presetId } = theme;
    const preset = useData({ type: "console.presets", id: presetId });

    const viewProps = {
        presetId,
        themeId,
    };

    const viewIds = Object.keys(views);

    const [view, setView] = useRouteParam("v");
    const [, setMainView] = useRouteParam("view");
    const currentView = view || viewIds[0];

    const ViewComponent = views[currentView];

    return (
        <ChangesSubmit>
            <div className={"h-min-100v rows"}>
                <div className={"cols pad-sm gap-xs"}>
                    <div onClick={() => setMainView(null)}>back</div>
                    {viewIds.map((id) => (
                        <div key={id} onClick={() => setView(id)}>
                            {id}
                        </div>
                    ))}
                </div>
                {ViewComponent && <ViewComponent {...viewProps} />}
            </div>
        </ChangesSubmit>
    );
}

function Styles(props) {
    const { children } = props;
    const service = useCurrentService();
    useFetch({
        list: "default",
        url: `v2/cms/styles?service=${service.id}`,
        useContext: false,
        fetchId: "front.page",
    });
    return <StylesProvider>{children}</StylesProvider>;
}

export function ThemePlayground(props) {
    const {} = props;
    return (
        <Entrypoint>
            <Helmet defer={false}>
                <title>zuu.designer v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <body className={"theme-dark default cms-theme"} />
            </Helmet>
            <Styles>
                <Playground />
            </Styles>
        </Entrypoint>
    );
}

ThemePlayground.propTypes = {};

ThemePlayground.defaultProps = {};

export default ThemePlayground;
