import React, { useCallback } from "react";
import cn from "classnames";
import { useNode } from "joynt/hooks";
import { useRouteParam } from "state/hooks";
import Button from "components/Button";
import { EVENT_ROLE_ATTENDEE, ROUTE_PAGE } from "joynt/config";
import Timer from "joynt/components/Timer";
import PendingSession from "joynt/meeting/PendingSession";
import Preloader from "components/Preloader";

export default function StageEvent(props) {
    const {
        id,
        isPending,
        isJoining,
        role,
        isLive,
        onJoin,
        onResume,
        onEnd,
    } = props;

    const { name, event_start, event_started } = useNode(id);
    const [, onShowInfo] = useRouteParam(ROUTE_PAGE);
    const showInfo = useCallback(() => onShowInfo(`session.${id}`), [
        onShowInfo,
        id,
    ]);

    return (
        <div className={"stage-info rows grow relative brand-bg"}>
            <div
                className={cn(
                    "grow rows rows-middle rows-center stage-info-content",
                    {
                        "is-pending": isPending || isJoining,
                    }
                )}
            >
                <div className={"rows rows-center gap-lg"}>
                    <div
                        className={
                            "rows gap-md stage-event-info brand-bg-alt pad-lg rows-center"
                        }
                    >
                        <div
                            className={
                                "rows rows-center block align-center gap-md"
                            }
                        >
                            <div className={"rows gap-sm"}>
                                <div>
                                    {isLive ? "Live now" : "Coming up next"}
                                </div>
                                <div className={"type-heading"}>{name}</div>
                            </div>
                            {/*fixme: below timer could be a separate component, as it is reused elsewhere*/}
                            {(event_start || isLive) && (
                                <div className={"rows gap-sm"}>
                                    <div>
                                        {isLive
                                            ? "Session started"
                                            : "Session will start"}
                                    </div>
                                    <div className={"time-to-session"}>
                                        <Timer
                                            start={
                                                isLive
                                                    ? event_started
                                                    : event_start
                                            }
                                            humanTimeThreshold={7200}
                                            countdown={!isLive}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={"cols cols-middle gap-xs"}>
                                {!isLive && onResume && (
                                    <div>
                                        <Button
                                            onClick={onResume}
                                            size={"large"}
                                        >
                                            Start session
                                        </Button>
                                    </div>
                                )}
                                {isLive && (
                                    <div className={"cols cols-middle gap-xs"}>
                                        {onEnd && (
                                            <Button
                                                onClick={onEnd}
                                                color={"default"}
                                            >
                                                End session
                                            </Button>
                                        )}
                                        <Button onClick={onJoin} size={"large"}>
                                            Join session
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={"cols cols-center cols-middle gap-sm"}>
                        {role && role !== EVENT_ROLE_ATTENDEE && (
                            <div className={"o-50"}>You're {role}</div>
                        )}
                        <div className={"cols cols-middle gap-xs"}>
                            <Button onClick={showInfo} color={"default"}>
                                Learn more
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <PendingSession visible={isJoining} />
            <Preloader visible={isPending} />
        </div>
    );
}

StageEvent.propTypes = {};
StageEvent.defaultProps = {};
