import React from 'react';
//import PropTypes from 'prop-types';

import SessionAside from "joynt/components/Witness/SessionAside";

export function WitnessSolo(props) {

    //const {} = props;

    return (<SessionAside
        title={"Congratulations and welcome to your solo Witness Task session."}
    >
        <h3>First things first:</h3>
        <div>Please follow instructions soon the screen.</div>
        <div>
            <p>Make sure the task is well defined - for example:</p>
            <p>Good description:<br />„I want to write 2 pages of my new novel.” — 2 pages is a well stated goal, easy to measure.</p>
            <p>Bad description:<br />„I want to work on my novel.” — less productive without a clear goal.</p>
        </div>
    </SessionAside>);

}

WitnessSolo.propTypes = {};
WitnessSolo.defaultProps = {};

export default WitnessSolo;
