import React from "react";

import {
    BoldButton,
    CodeButton,
    ItalicButton,
    UnderlineButton,
} from "@draft-js-plugins/buttons";

export default function (props) {
    const { LinkButton, ...other } = props;

    return (
        <>
            <BoldButton {...other} />
            <ItalicButton {...other} />
            <UnderlineButton {...other} />
            <CodeButton {...other} />
            <LinkButton {...other} />
        </>
    );
}
