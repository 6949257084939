import React from "react";
import { connect } from "react-redux";

import List from "cms/components/List";
import Fetch from "containers/Fetch";
import { serializeQueryParams } from "util/uri";
import { selectList } from "state/selectors/lists";
import { isPending } from "state/selectors/ui";
import { takeProps } from "util/takeProps";
import { selectEntityData } from "state/selectors/data";

const listProps = [
    "entry_type",
    "data_source",
    "checkin",
    "checkout",
    "tags",
    "filtering_mode",
    "limit",
    "defined_order",
];

function listDataUrl(props) {
    if (!props.entry_type) return null;
    const params = takeProps(listProps, props);
    return (
        "cms/data?raw=true&order=tree_index ASC&" + serializeQueryParams(params)
    );
}

const mapStateToProps = (store, props) => {
    const { entry_type, query_id } = props;

    if (query_id) {
        const query = selectEntityData(store, "cms.query", query_id);
        return {
            item_type: query.type,
            url: null,
            items: props.items,
        };
    }

    const type = entry_type
        ? ["cms.entries", entry_type].join(".")
        : props.item_type || "cms.items";

    const list = [type, "data", props.id].join(".");
    const items = selectList(store, type, list).slice(0, 30);

    return {
        item_type: type,
        list,
        pending: isPending(store, [type, list].join("/")),
        url: listDataUrl({ ...props, ...store.router.query }),
        items: entry_type ? items : props.collection || props.items,
    };
};

const mapDispatchToProps = {};

class ListContainer extends React.PureComponent {
    render() {
        const { item_type, url, list } = this.props;

        if (url) {
            return (
                <>
                    <Fetch
                        type={item_type}
                        url={url}
                        list={list}
                        enable={item_type && url && list}
                        force={true}
                    >
                        <List {...this.props} />
                    </Fetch>
                </>
            );
        }

        return (
            <>
                <List {...this.props} />
            </>
        );
    }
}

const ConnectedListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListContainer);

ConnectedListContainer.autoFillProps = List.autoFillProps;

export default ConnectedListContainer;
