import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Button from "components/Button";
import Icon from "components/Icon";
import IconButton from "components/IconButton";

import "./style.css";

export function CollapsedField(props) {
    const {
        label,
        expandLabel,
        collapseOnClick,
        collapsed: initialCollapsed,
        helperText,
        variant,
        children,
    } = props;

    const [collapsed, setCollapsed] = useState(initialCollapsed);

    return (
        <div
            className={cn("collapsed-field", {
                [`variant-${variant}`]: true,
                "is-expanded": !collapsed,
                "with-helper-text": !!helperText,
            })}
        >
            <div className={"collapsed-field__header"}>
                <div className={"collapsed-field__label"}>
                    <div className={"collapsed-field__label-text"}>{label}</div>
                    {helperText && <div>{helperText}</div>}
                </div>
                {collapsed ? (
                    <Button
                        onClick={() => setCollapsed(false)}
                        variant={"text"}
                        endIcon={<Icon>mui-expand_more</Icon>}
                    >
                        {expandLabel}
                    </Button>
                ) : (
                    <IconButton
                        onClick={() => setCollapsed(true)}
                        icon={"mui-expand_less"}
                    />
                )}
            </div>

            {!collapsed && (
                <div
                    className={"collapsed-field__content"}
                    onClick={collapseOnClick ? () => setCollapsed(true) : null}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

CollapsedField.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    expandLabel: PropTypes.string,
    variant: PropTypes.oneOf(["default", "contained"]),
};
CollapsedField.defaultProps = {
    collapsed: true,
    expandLabel: "More",
    variant: "default",
};

export default CollapsedField;
