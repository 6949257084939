import React from "react";
import { useClick } from "state/hooks";
import Field from "containers/Field";
import IconButton from "joynt/components/IconButton";
//import PropTypes from 'prop-types';

export default function AnswerEditable(props) {
  const { id, index, onDelete } = props;
  const remove = useClick({ id, onClick: onDelete });
  return (
    <div className={"cols cols-middle gap-sm"}>
      <Field
        label={`Option ${index + 1}`}
        entityType={"db.answers"}
        entityId={id}
        id={"title"}
        type={"text"}
      />
      <IconButton
        withTooltip
        size={"small"}
        icon={"mui-close"}
        label={"Delete"}
        onClick={remove}
      />
    </div>
  );
}

AnswerEditable.propTypes = {};
AnswerEditable.defaultProps = {};
