import {useMessaging, useNode} from "joynt/hooks";
import {useData} from "state/hooks";
import {useRouteParam} from "state/hooks";
import {ROUTE_THREAD} from "joynt/config";
import {useCallback, useMemo} from "react";

export function usePostComments(id, threadType = 'comments') {
    const {thread: threadId} = useNode(id);
    const post = useData({type: 'db.posts', id: threadId});
    const thread = useData({type: 'db.threads', id: threadId});
    const showThread = useRouteParam(ROUTE_THREAD)[1];

    const show = useCallback(() => showThread(id), [showThread, id]);

    const {onCreateMessage} = useMessaging({
        thread: thread.id,
        post: post.id,
        parent: id,
        type: threadType
    });

    return useMemo(() => ({
        lastMessage: thread.last_message,
        onCreateMessage,
        onShowThread: show,
        thread: thread.id,
        post: post.id,
        participants: thread.participants || [],
        count: thread.message_count || 0
    }), [thread, post, onCreateMessage, show]);
}