import React from 'react';
//import PropTypes from 'prop-types';

import CodeEditor from 'components/fields/code/CodeEditor';
import Select from "components/fields/select/Select";
import IconButton from "components/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Popover from "components/Popover";
import FileCreate from "cms/ui/theme/FileCreate";

const headerStyle = {
    position: 'relative'
};

export default class FileManager extends React.PureComponent {

    render() {
        const {
            type,
            id,
            onSelectFile,
            onChange,
            onRevert,
            onSubmit,
            onApply,
            onCreate,
            data,
            dirty,
            pending,
            pendingApply
        } = this.props;

        return (<div className={'rows grow ace-solarized-dark'}>
            <div className={'cols cols-bottom gap-sm pad-sm ace_gutter'} style={headerStyle}>
                <Select
                    type={type}
                    list={'default'}
                    id={'file'}
                    value={id}
                    label={'Select file'}
                    variant={'dense'}
                    onChange={onSelectFile}
                />
                <div className={'cols'}>
                    <Popover
                        trigger={({onClick})=><IconButton icon={'mui-add'} onClick={onClick}/>}
                        content={({onClose})=><FileCreate onSubmit={onCreate} onClose={onClose}/>} />
                </div>
                { dirty ? <div className={'cols'}>
                    <IconButton onClick={onRevert} icon={'mui-undo'} disabled={pending} />
                    <IconButton onClick={onSubmit} icon={'mui-save'} disabled={pending} />
                    {onApply ? <IconButton onClick={onApply} icon={'mui-replay'} disabled={pending} /> : null }
                </div> : null }
            </div>
            { pendingApply || pending ? <div style={{ height: '4px', width: '100%' }}>
                <LinearProgress color={pendingApply ? 'primary' : 'secondary'} />
            </div> : null }
            <div className={'grow'}>
                <CodeEditor
                    id={'content'}
                    value={data.content || ''}
                    onChange={onChange}
                    height={'100%'}
                    mode={'less'}
                    readOnly={pending}
                />
            </div>
        </div>);
    }

}

FileManager.propTypes = {};