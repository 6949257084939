import React from 'react';
//import PropTypes from 'prop-types';
import ProfileInfo from "./ProfileInfo";
import {useIdentity} from "joynt/hooks/participants";
import {IdentityAvatarContainer as Avatar} from "joynt/components/Avatar";

export function ProfileInfoContainer(props) {

    const {id} = props;
    const {name, company_role} = useIdentity(id);

    return (<ProfileInfo
        {...props}
        name={name}
        role={company_role}
        avatar={<Avatar id={id} />}
    />);

}

ProfileInfoContainer.propTypes = {};
ProfileInfoContainer.defaultProps = {};

export default ProfileInfoContainer;
