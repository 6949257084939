import React from "react";

import { useSessionFlow } from "joynt/components/SessionFlow/hooks";
import StageOverlay from "./../StageOverlay";
import { usePending } from "state/hooks";

import views from "joynt/components/SessionFlow/views";
import StageCover from "joynt/components/SessionFlow/StageCover";

function DefaultOverlay(props) {
    const { offline } = props;
    const Component = views.default;

    if (offline)
        return (
            <StageCover variant={"landscape"}>
                <Component {...props} />
            </StageCover>
        );

    return <Component {...props} />;
}

export function Stage(props) {
    const { id, Component, overlay, ...other } = props;
    const { isMinimized } = other;

    const flow = useSessionFlow();
    const data = flow.data || {};
    const pending = usePending(`${id}.flow`);

    const OverlayComponent = views[data.view] || DefaultOverlay;

    const showOverlay =
        flow &&
        data.overlay !== false &&
        data.id &&
        (data.visible !== "admin" || data.isAdmin) &&
        (!isMinimized || data.floating);

    return (
        <Component
            id={id}
            {...other}
            overlay={
                <>
                    {overlay}
                    {showOverlay && (
                        <StageOverlay>
                            <OverlayComponent
                                started={flow.started}
                                next={flow.next}
                                onProgress={() => flow.setSegment(flow.next)}
                                isPending={pending}
                                isMinimized={isMinimized}
                                {...data}
                            />
                        </StageOverlay>
                    )}
                </>
            }
        />
    );
}

Stage.propTypes = {};
Stage.defaultProps = {};

export default Stage;
