import React from "react";
import { Player, Audio as VimeAudio, DefaultUi } from "@vime/react";
import { enforceHttps } from "util/uri";
import env from "util/env";
//import PropTypes from 'prop-types';

export function Audio(props) {
    const { file } = props;
    const host = enforceHttps(env("FILES_CDN"));
    const playbackUrl = `${host}${file}`;

    return <Player autoplay autopause icons={"material"}>
        <VimeAudio>
            <source src={playbackUrl} />
        </VimeAudio>
        <DefaultUi />
    </Player>;
}

Audio.propTypes = {};
Audio.defaultProps = {};

export default Audio;
