import React, {useCallback, useState} from 'react';
import Field from "containers/Field";
import AnswerList from "./AnswerList";
import Button from "components/Button";
import Modal from "components/Modal";
import VoteForm from "./VoteForm";
import PollSettings from "./PollSettings";
import Dialog from "@material-ui/core/Dialog";
import {useData} from "state/hooks";
//import PropTypes from 'prop-types';

function RenderAnswers(props) {
    return <AnswerList {...props} />;
}

export default function Poll(props) {

    const {id, onDelete, editable} = props;
    const {poll_anonymous: anonymousVoting} = useData({type: 'db.posts', id});
    const canVote = true;
    const enableVoting = true;
    const [settings, onShowSettings] = useState(false);
    const showSettings = editable && settings;
    const toggleSettings = useCallback(() => {
        onShowSettings(p=>!p);
    }, [onShowSettings]);

    return (<div className={'poll rows gap-sm'}>
        <Field
            id={'answers'}
            fieldType={'collection'}
            readOnly={!editable}
            postId={id}
            onDeletePost={onDelete}
            CollectionRenderer={RenderAnswers}
            enableVoting={enableVoting}
            anonymousVoting={anonymousVoting}
            onToggleSettings={toggleSettings}
        />
        <Dialog open={showSettings} onClose={toggleSettings}>
            <PollSettings id={id} onToggleSettings={toggleSettings} />
        </Dialog>
        {!editable && canVote && !enableVoting ? <div className={'cols cols-center'}>
            <Modal
                trigger={({onClick})=><Button onClick={onClick} variant={'outlined'} size={'large'}>
                    Vote now
                </Button>}
                content={({onClose})=><VoteForm
                    onClose={onClose}
                    postId={id}
                />}
            />
        </div> : null }
    </div>);

}

Poll.propTypes = {};
Poll.defaultProps = {};
