import { MessageForm as Form } from "./message-form";
import Msg, { MessageSkeleton as MsgSkel } from "./message";
import MobileMessageForm from "./message-form/MobileMessageForm"
import {
    MessageTime,
    renderMentions,
} from "joynt/components/messages/messageUtils";

export const MessageForm = Form;
export const Message = Msg;
export const MessageSkeleton = MsgSkel;
export { MessageTime, renderMentions, MobileMessageForm };
