import React from "react";
//import PropTypes from 'prop-types';

import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import FieldsLayout from "joynt/components/Form/FieldsLayout";

import { RolesAndAccess, AdvancedSettings, DangerZone } from "./fields";
import SessionActivity from "joynt/edit/types/Session/SessionActivity";
import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_FOLLOW } from "joynt/config/features";

function Form(props) {
    const { isDraft } = props;
    const isDev = useFeatureEnabled(FEATURE_FOLLOW);

    return (
        <FieldsLayout>
            <FieldGroup label={"Chat name and description"}>
                <Field id={"name"} label={"Name"} validate={true} />
                <Field id={"description"} label={"Description"} />
            </FieldGroup>

            <RolesAndAccess />

            {!isDraft && (
                <AdvancedSettings>
                    <DangerZone />
                    {isDev && <SessionActivity {...props} />}
                </AdvancedSettings>
            )}
        </FieldsLayout>
    );
}

const steps = {
    step1: {
        component: Form,
        label: "Settings",
    },
};

export default function Chat(props) {
    return <MultiStepForm {...props} steps={steps} showHeader={false} />;
}

Chat.propTypes = {};
Chat.defaultProps = {};
