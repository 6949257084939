import React from 'react';
//import PropTypes from 'prop-types';

export default function EmptyState(props) {
    return <div className={'pad-md grow rows-middle rows rows-center gap-sm'}>
        <div className={'type-lg o-50'}>
            You don't have any messages
        </div>
        {/*<div>
            <Button onClick={onCreate}>Create a thread</Button>
        </div>*/}
    </div>
}

EmptyState.propTypes = {};
EmptyState.defaultProps = {};
