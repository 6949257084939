import { serializeQueryParams } from "util/uri";
import useFetch from "state/hooks/useFetch";
import { useEdges, useNode } from "joynt/hooks";
import { useAccessSettings } from "./useAccessSettings";
import { accessTypes } from "joynt/components/Roles/config";

export function useFetchRoles(id, list) {
    const { parent } = useEdges(id);
    const { type } = useAccessSettings(id);
    const typeDef = accessTypes[type] || {};
    const { access_roles: roles } = useNode(id);

    let url = `joynt/nodes/${id}/roles/preview`;
    let query = {};

    query.parent = parent;
    query.inherit = typeDef?.data?.public || false;
    query.publish = typeDef?.data?.published || false;

    if (roles && roles.length) {
        query.roles = roles.join(",");
    }

    url += `?${serializeQueryParams(query)}`;

    return useFetch({
        type,
        list,
        url,
        fetchId: list,
    });
}
