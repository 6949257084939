import { useData } from "state/hooks";
import { useIdentity } from "joynt/hooks/participants";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import React from "react";

import "./style.css";
import Html from "components/Html";
import { renderMentions } from "joynt/components/messages";

export function Message(props) {
    const { id } = props;
    const { identity, content, media } = useData({ type: "db.messages", id });
    const { name } = useIdentity(identity);

    const visibleContent =
        media && media.length ? "Sent a file" : renderMentions(content, true);

    return (
        <div className={"chat-ticker-message"}>
            <div className={"chat-ticker-message__identity"}>
                <Avatar id={identity} size={"xs"} />
                <div className={"chat-ticker-message__name"}>{name}</div>
            </div>
            <Html
                className={"chat-ticker-message__body"}
                value={visibleContent}
            />
        </div>
    );
}
