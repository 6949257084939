import React from "react";
import { useData } from "state/hooks";
import icons from "config/icons";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import Icon from "components/Icon";
import { useRouteParams } from "state/hooks/useRouteParams";
//import PropTypes from 'prop-types';

function pageTypeToCmsType(type) {
    const map = {
        pages: "cms.pages",
        layouts: "cms.layouts",
        products: "cms.products",
        categories: "cms.categories",
    };
    return map[type] || ["cms.entries", type].join(".");
}

function SectionUsageListItem(props) {
    const { id, page_type, onClick } = props;
    const type = pageTypeToCmsType(page_type);
    const data = useData({ type, id });
    const title = data.title || props.title || data.description;
    const slug = data.slug || props.slug;
    const icon = icons[type] || null;
    return (
        <SelectListItem
            onClick={() => onClick(type, id)}
            subtitle={slug ? `/${slug}` : null}
            icon={icon && <Icon defaultType={"mui"}>{icon}</Icon>}
        >
            {title}
        </SelectListItem>
    );
}

export function SectionUsageList(props) {
    const { used_in } = useData(props);
    const push = useRouteParams();
    const linkToPage = (type, id) => {
        push({ resource: type, id });
    };
    return (
        <SelectList>
            {used_in.map((page) => (
                <SectionUsageListItem
                    key={page.id}
                    id={page.id}
                    type={"cms.sections"}
                    onClick={linkToPage}
                    {...page}
                />
            ))}
        </SelectList>
    );
}

SectionUsageList.propTypes = {};

SectionUsageList.defaultProps = {};

export default SectionUsageList;
