import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { FormLayout, OverlayForm } from "playground/cms/Form";
import Button from "components/Button";
import List from "containers/List";
import { SortableIterator } from "components/dnd";
import CollectionItem from "./CollectionItem";
import ItemForm from "./ItemForm.container";
import Field from "containers/Field";
import MuiList from "@material-ui/core/List";

import "./style.css";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import CollectionActions from "./CollectionActions";
import { useEditorState } from "playground/designer/EditorState";

const ItemIterator = SortableIterator(CollectionItem);

function ItemsCollection(props) {
    const {
        label,
        itemType,
        value,
        onCreate,
        schema,
        id: fieldId,
        fieldEntityType,
        fieldEntityId,
        onChange,
    } = props;

    const [stateId, setStateId] = useState(null);
    const [editorState, setEditorState] = useEditorState();

    const itemPath = [fieldEntityType, fieldEntityId, fieldId].join("/");

    const id =
        editorState?.itemPath === itemPath ? editorState?.itemId : stateId;

    const setId = editorState
        ? (itemId) =>
              setEditorState({
                  itemId,
                  itemPath,
              })
        : setStateId;

    const onClose = () => setId(null);

    return (
        <>
            <FormLayout.Section
                name={label}
                action={
                    <div className={"cols cols-middle"}>
                        <CollectionActions
                            type={fieldEntityType}
                            id={fieldEntityId}
                            path={fieldId}
                            onChange={onChange}
                        />
                        <Button
                            variant={"text"}
                            onClick={() => onCreate()}
                            endIcon={<Icon>mui-add_circle</Icon>}
                        >
                            Dodaj
                        </Button>
                    </div>
                }
            >
                <MuiList>
                    <List
                        type={itemType}
                        items={value}
                        Iterator={ItemIterator}
                        onSortEnd={props.onSortEnd}
                        onClick={setId}
                        onDelete={props.onDelete}
                    />
                </MuiList>
            </FormLayout.Section>
            <OverlayForm
                open={!!id}
                onClose={onClose}
                position={"center"}
                fullHeight={false}
                width={"md"}
                zIndex={5}
            >
                {id ? (
                    <ItemForm
                        type={itemType}
                        id={id}
                        schema={schema}
                        onClose={onClose}
                    />
                ) : null}
            </OverlayForm>
        </>
    );
}

ItemsCollection.defaultProps = {
    value: [],
};

function RenderItemsCollection(props) {
    return <ItemsCollection {...props} />;
}

export function CollectionField(props) {
    const { id, label, schema, fieldEntityId } = props;
    return (
        <Field
            id={id}
            label={label}
            fieldType={"collection"}
            fieldEntityType={"cms.sections"}
            fieldEntityId={fieldEntityId}
            schema={schema}
            CollectionRenderer={RenderItemsCollection}
        />
    );
}

CollectionField.propTypes = {};

CollectionField.defaultProps = {
    schema: {},
};

export default CollectionField;
