import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";

export function SessionCover(props) {
    const { image, title, status, children } = props;
    return (
        <div
            className={cn("session-cover", {
                "is-empty": !image,
            })}
        >
            {image && <img src={image} alt={title} />}
            <div className={"session-cover__status"}>{status}</div>
            {children}
        </div>
    );
}

SessionCover.propTypes = {};
SessionCover.defaultProps = {};

export default SessionCover;
