import React, { useCallback, useMemo } from "react";
import { useApiActions, useFetch, useRegisterList } from "state/hooks";
import { submitOrder } from "state/actions/api";
import List from "containers/List";
import { SortableIterator } from "components/dnd";
import { ArrayIterator } from "components/list/iterator";
import ListItem from "playground/cms/views/Resources/ListItem";
import { appendQueryParams } from "util/uri";

//import PropTypes from 'prop-types';

function iterator(props) {
    if (props.sortable) return SortableIterator(props.Item);

    return ArrayIterator(props.Item);
}

function useSortable(type) {
    const { onSortEnd } = useApiActions({ onSortEnd: submitOrder });

    const updateOrder = useCallback(
        (items) => {
            const path = type.split(".").slice(0, 2).join("/");
            onSortEnd(`${path}/treeIndex`, type, type, items);
        },
        [type, onSortEnd]
    );

    return useMemo(() => ({ onSortEnd: updateOrder }), [updateOrder]);
}

function Wrapper(props) {
    if (!props.enable) {
        return props.children;
    }
    return <div className={props.className}>{props.children}</div>;
}

export function ListContainer(props) {
    const { type, className, list, sortable, onClick } = props;
    const { Item, ...rest } = props;
    let url = props.url || type;
    const listId = list || type;

    const { onSortEnd } = useSortable(type);

    const fetchProps = {
        type,
        list: listId,
        url,
        filter: listId,
        yieldData: true,
    };

    useRegisterList(fetchProps);
    useFetch(fetchProps);

    return (
        <Wrapper enable={!sortable} className={className}>
            <List
                {...rest}
                selected={props.id}
                list={listId}
                url={url}
                Iterator={iterator(props)}
                onSortEnd={sortable ? onSortEnd : null}
            />
        </Wrapper>
    );
}

ListContainer.propTypes = {};

ListContainer.defaultProps = {
    Item: ListItem,
};

export default ListContainer;
