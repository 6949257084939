import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";
import Html from "components/Html";
import DateDiff from "components/DateDiff";
import Preloader from "components/Preloader";
import PostContent from "joynt/components/Post/PostContent/PostContent.container";

const MaterialPostCard = React.forwardRef((props, ref) => {
    const {
        id,
        title,
        postedAt,
        author,
        tags,
        hasNotifications,
        onClick,
        isPending,
    } = props;

    return (
        <div
            className={cn("material-post-card post-card", {
                "is-new": hasNotifications,
            })}
            onClick={onClick}
            ref={ref}
        >
            <div className={"material-post-card__layout"}>
                <div className={"material-post-card__header"}>
                    {author}
                    <div className={"post-card__posted_at"}>
                        <DateDiff value={postedAt} />
                    </div>
                </div>
                <div className={"post-card__content"}>
                    <div className={"post-card__title"}>
                        <Html value={title} sanitizationMode={"plain-text"} />
                    </div>
                    <PostContent id={id} />
                    {tags && <div className={"post-card__tags"}>{tags}</div>}
                </div>
            </div>
            <Preloader visible={isPending} />
        </div>
    );
});

MaterialPostCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    postedAt: PropTypes.number,
    onClick: PropTypes.func,
    author: PropTypes.element,
    tags: PropTypes.element,
    hasNotifications: PropTypes.bool,
    isPending: PropTypes.bool,
};
MaterialPostCard.defaultProps = {
    author: null,
    hasNotifications: false,
    isPending: false,
};

export default MaterialPostCard;
