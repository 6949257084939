import React from "react";
import Field from "containers/Field/Field";
import TextField from "components/fields/text/TextField";

//import PropTypes from 'prop-types';

export function ArrayTextField(props) {
    const { id, label, ...rest } = props;

    return (
        <Field id={id} fieldType={"text"}>
            {({ value, onChange }) => {
                return (
                    <TextField
                        {...rest}
                        label={label}
                        value={value ? value.join(",") : ""}
                        onChange={(v) => onChange(v !== "" ? v.split(",") : [])}
                    />
                );
            }}
        </Field>
    );
}

ArrayTextField.propTypes = {};

ArrayTextField.defaultProps = {};

export default ArrayTextField;
