import React, {useCallback} from 'react';
import {useGlobalNotifications} from "joynt/hooks/notifications";
import TabButton from "joynt/components/TabButton";
//import PropTypes from 'prop-types';

export default function InboxIcon(props) {

    const {onClick, active} = props;

    const {
        show,
        count,
        onSeen
        //onReset
    } = useGlobalNotifications('messages');

    const click = useCallback((e) => {
        onSeen();
        onClick(e);
    }, [onSeen, onClick]);

    return <TabButton
        label={count ? `Inbox (${count})` : 'Inbox'}
        active={active}
        icon={'mui-inbox'}
        onClick={click}
        count={show ? count : null}
    />
}

InboxIcon.propTypes = {};
InboxIcon.defaultProps = {};
