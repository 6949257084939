import React from 'react';
import PropTypes from 'prop-types';
import SidebarSectionIcon from "./SidebarSectionIcon";

export function CreateChannelIcon(props) {
    return <SidebarSectionIcon
        icon={"mui-add_circle"}
        {...props}
    />
}

CreateChannelIcon.propTypes = {};

CreateChannelIcon.defaultProps = {};

export default CreateChannelIcon;