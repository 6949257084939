export const setApiKey = (api_key) => {
    return {
        type: 'AUTH.SET_API_KEY',
        payload: { api_key }
    }
};

export const logout = () => {
    return {
        type: 'AUTH.LOGOUT'
    }
};

export const signup = context => (email, password, token, node) => {
    return {
        type: 'AUTH.SIGNUP',
        payload: {
            email,
            password,
            token,
            node
        },
        context
    }
};

export const login = context => (email, password) => {
    return {
        type: 'AUTH.LOGIN',
        payload: {
            email,
            password
        },
        context
    }
};

export const loginSocial = (connection) => {
    return {
        type: 'AUTH.LOGIN_SOCIAL',
        payload: {
            connection
        }
    }
};

export const parseHash = () => {
    return {
        type: 'AUTH.PARSE_HASH'
    }
};

export const checkSession = () => {
    return {
        type: 'AUTH.CHECK_SESSION'
    }
};

export const authError = () => {
    return {
        type: 'AUTH.ERROR'
    }
};

export const fetchUser = () => {
    return {
        type: 'AUTH.FETCH_USER'
    }
};

export const requireLogin = () => {
    return {
        type: 'AUTH.REQUIRE_LOGIN'
    }
};

export const exchangeAuthCode = () => {
    return {
        type: 'AUTH.EXCHANGE_CODE'
    }
};

export const passwordReset = context => (username) => ({
    type: 'AUTH.PASSWORD_RESET',
    payload: {username}
});

export const refreshToken = (access_token) => ({
    type: 'AUTH.REFRESH.FETCH',
    payload: {access_token}
});

export const refreshTokenSuccess = () => ({
    type: 'AUTH.REFRESH.SUCCESS'
});

export const refreshTokenFailed = () => ({
    type: 'AUTH.REFRESH.FAIL'
});
