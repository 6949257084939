export function audioTrack() {
    return {
        play: () => null,
        isPlaying: true,
        getVolumeLevel: () => 0,
        stop: () => null
    }
}

export function videoTrack() {
    return {
        play: () => null,
        stop: () => null,
        isPlaying: true
    }
}