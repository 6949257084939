import React from "react";
import { useList, useRouteParam } from "state/hooks";
import { ResourceGroup } from "playground/cms/Layout/SideNav/ResourceGroup";

//import PropTypes from 'prop-types';

export function Nav(props) {
    const [type, onClick] = useRouteParam("resource", {
        section: null,
        id: null,
    });
    const { items: groups } = useList("app.user-menu-groups", "default");
    return (
        <div className={"side-nav"}>
            {groups.map((group) => (
                <ResourceGroup
                    key={group}
                    id={group}
                    onClick={onClick}
                    active={type}
                />
            ))}
        </div>
    );
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
