export const createSnapshot = (context) => (id) => ({
    type: "CMS.SNAPSHOTS.CREATE",
    payload: { id },
    context,
});

export const restoreSnapshot = (context) => (id, from) => ({
    type: "CMS.SNAPSHOTS.RESTORE",
    payload: { id, from },
    context,
});

export const submitWebsite = (context) => (type, id) => ({
    type: "CMS.WEBSITES.SUBMIT",
    payload: { type, id },
    context,
});

export const exportWebsite = (context) => (id) => ({
    type: "CMS.WEBSITES.CREATE.EXPORT",
    payload: { id },
    context,
});

export const submitCreateWebsite = (context) => (id) => ({
    type: "CMS.WEBSITES.CREATE.SUBMIT",
    payload: { id },
    context,
});

export const destroyWebsite = (context) => (id) => ({
    type: "CMS.WEBSITES.DESTROY",
    payload: { id },
    context,
});

export const updateSchemaConfig = (context) => (id, data) => ({
    type: "CMS.WEBSITES.SCHEMA.UPDATE",
    payload: { id, data },
    context,
});

export const grantServiceAccess = (context) => (id, email) => ({
    type: "CMS.WEBSITES.ACCESS.GRANT",
    payload: { id, email },
    context,
});
