import React, { useMemo, useState } from "react";
import { useApiActions, useData, usePending } from "state/hooks";
import { commit, initVcs, pull, push } from "cms/state/actions/theme-vcs";
import { fetchOne } from "state/actions/api";

export function useThemeGit(theme) {
    const { onPush, onPull, onCommit, onInit, fetch } = useApiActions({
        onPush: push,
        onPull: pull,
        onCommit: commit,
        onInit: initVcs,
        fetch: fetchOne,
    });

    const { vcs_repository } = useData({ type: "console.themes", id: theme });
    const data = useData({ type: "theme.vcs", id: theme });
    const isPending = usePending(`${theme}.vcs`);

    const handleCommit = (message) => {
        if (message) {
            onCommit(theme, message);
        }
    };

    const handleLink = () => {
        window.open(`https://github.com/${vcs_repository}`);
    };

    const handlePush = () => onPush(theme);
    const handlePull = () => onPull(theme);
    const handleInit = () => onInit(theme);

    const handleFetch = () => {
        fetch(
            "theme.vcs",
            theme,
            `console/themes/:id/vcs`,
            null,
            `${theme}.vcs`
        );
    };

    return useMemo(
        () => ({
            onCommit: handleCommit,
            onLink: handleLink,
            onPush: handlePush,
            onPull: handlePull,
            onInit: handleInit,
            onReload: handleFetch,
            data,
            isPending,
        }),
        [
            handleCommit,
            handleInit,
            handleLink,
            handlePull,
            handlePush,
            handleFetch,
            data,
            isPending,
        ]
    );
}
