import React from "react";
import { useNodeData } from "joynt/hooks";
import { RoleItem } from "./../RoleItem/RoleItem";
import IconButton from "components/IconButton";
import NodeAvatar from "joynt/components/Roles/NodeAccess/NodeAvatar";
//import PropTypes from "prop-types";

export default function NodeRoleGroup(props) {
    const { id, onChange, onClick, nameTemplate, ...other } = props;

    const { name: nodeName } = useNodeData(id);

    const name = nameTemplate.replace(":name", nodeName);

    return (
        <RoleItem
            avatar={<NodeAvatar id={id} size={"sm"} />}
            name={name}
            onClick={onClick}
            role={
                onChange && (
                    <IconButton
                        onClick={() => onChange("private")}
                        icon={"mui-delete"}
                        size={"small"}
                    />
                )
            }
            {...other}
        />
    );
}

NodeRoleGroup.defaultProps = {
    nameTemplate: ":name",
};
NodeRoleGroup.propTypes = {};
