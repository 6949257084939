import React from "react";

//import PropTypes from 'prop-types';

export function PendingState(props) {
    const { status, prevItems } = props;
    let p = prevItems;
    if (!p) p = 3;

    return (
        <div className={"section-grid pending"}>
            <div className={"section-card"} />
            {p > 1 && <div className={"section-card"} />}
            {p > 2 && <div className={"section-card"} />}
            {status && <div className={"status"}>{status}</div>}
        </div>
    );
}

PendingState.propTypes = {};

PendingState.defaultProps = {
    prevItems: 3,
};

export default PendingState;
