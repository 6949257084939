import {useList} from "state/hooks";
import {useSelector} from "react-redux";
import {selectGroups} from "state/selectors/lists";

import * as roleGroups from "joynt/components/Roles/role-groups";

export function useRoleGroups(id, list, groupBy = 'workspaceGroups') {

    const type = "app.node-roles";
    //const list = `node-roles.${id}`;

    const {items} = useList(type, list);

    let groups = useSelector(s=>selectGroups(
        s,
        items,
        roleGroups[groupBy],
        true
    ));

    groups = groups.filter(group => !!group.items.length);

    return groups
}