import React from "react";

import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";

const nullObj = {};

function WithSchema(props) {
    const { children } = props;
    const schema = useSelector((store) => {
        return props.type && props.id
            ? selectEntity(store, props.type, props.id)
            : nullObj;
    });
    const { properties } = schema || nullObj;
    return children({ ...properties, ...props, schema });
}

export function withSchema() {
    return function (Decorated) {
        return function (decoratorProps) {
            const { fieldEntityType, fieldEntityId } = decoratorProps;

            return (
                <WithSchema type={fieldEntityType} id={fieldEntityId}>
                    {(props) => <Decorated {...props} {...decoratorProps} />}
                </WithSchema>
            );
        };
    };
}
