import React from "react";
import cn from "classnames";

import Preloader from "components/Preloader";
import Button from "components/Button";
import Avatar from "../Avatar";

import "./style.css";
import PropTypes from "prop-types";

import useScreenSize from "util/hooks/useScreenSize";
import { DEFAULT_NETWORKING_TABLE_LIMIT } from "joynt/config";

function columnLayoutMapper({ participants, maxParticipants, isMobile }) {
    if (isMobile) return 4;

    const number = parseInt(
        participants > maxParticipants ? participants : maxParticipants
    );

    if (number === 4) {
        return 2;
    } else if (number <= 3) {
        return number;
    } else if (number > 9) {
        return 4;
    } else {
        return 5;
    }
}

const EmptyPlaceAvatar = () => {
    return <div className={"tile-identity networking-table__empty-avatar"} />;
};

let emptyPlaces = [];

export function NetworkingTable(props) {
    const {
        actions,
        handleButtonClick,
        buttonLabel,
        isDisabled,
        name,
        presence,
        isConnected,
        isPending,
        onInfoClick,
        maxParticipants,
    } = props;
    const { isMobile } = useScreenSize();
    const participants = presence.length || 0;

    let numberOfSlots = maxParticipants - participants;

    if (numberOfSlots >= 0) {
        emptyPlaces = new Array(numberOfSlots).fill(<EmptyPlaceAvatar />);
    } else {
        emptyPlaces = [];
    }

    return (
        <div
            className={cn("networking-table", {
                "is-connected": isConnected,
            })}
        >
            <Preloader visible={isPending} />
            <div className={"networking-table__topbar"}>
                <p className={"networking-table__name"}>{name}</p>
                <div className={"networking-table__actions"}>
                    {onInfoClick && (
                        <p
                            className={"networking-table__info"}
                            onClick={onInfoClick}
                        >
                            Info
                        </p>
                    )}
                    {actions}
                </div>
            </div>
            <div className={"networking-table__participants"}>
                <div
                    className={"networking-table__participants-cluster"}
                    data-cluster-size={columnLayoutMapper({
                        isMobile,
                        participants,
                        maxParticipants,
                    })}
                >
                    {presence.map((person) => (
                        <Avatar id={person} />
                    ))}
                    {emptyPlaces}
                </div>
            </div>
            <div
                className={cn(
                    "networking-table__button-wrapper",
                    isDisabled && "networking-table__button-wrapper--disabled"
                )}
            >
                <Button
                    disabled={isDisabled}
                    buttonClassName={"networking-table__button"}
                    buttonColor={"primary"}
                    onClick={handleButtonClick}
                    size={"large"}
                >
                    {buttonLabel && (
                        <p className={"networking-table__button-label"}>
                            {buttonLabel}
                        </p>
                    )}
                </Button>
            </div>
        </div>
    );
}

NetworkingTable.propTypes = {
    maxParticipants: PropTypes.number,
};
NetworkingTable.defaultProps = {
    maxParticipants: DEFAULT_NETWORKING_TABLE_LIMIT,
};

export default NetworkingTable;
