import { EditorState, RichUtils } from "draft-js";

import { getCurrentBlock } from "./getCurrentBlock";
import { insertCustomAtomicBlock } from "./insertCustomAtomicBlock";
import { moveFocusToBlockEnd } from "./moveFocusToBlockEnd";
//import { replaceWithCustomBlock } from "playground/editor/util/replaceWithCustomBlock";
import { insertNewBlockToContentState } from "./insertNewBlockToContentState";
import { createBlock } from "./createBlock";
import { clearTextTypeahead } from "./clearTextTypeahead";

export function applyBlockType(
    blocks,
    editorState,
    blockTypeId,
    modify = false,
    data = {}
) {
    if (!blockTypeId) return editorState;

    let nextState = editorState;
    const config = blocks.find(blockTypeId);

    nextState = clearTextTypeahead(nextState);

    let currentBlock = getCurrentBlock(nextState);
    nextState = moveFocusToBlockEnd(nextState, currentBlock);

    let isTextBlock = !config.atomic;
    let isEmptyBlock = currentBlock.getLength() === 0;

    if (isTextBlock) {
        if (modify || isEmptyBlock) {
            return RichUtils.toggleBlockType(nextState, blockTypeId);
        }
        let newBlock = createBlock(blockTypeId);
        nextState = EditorState.set(nextState, {
            currentContent: insertNewBlockToContentState(
                nextState.getCurrentContent(),
                newBlock,
                currentBlock.getKey()
            ),
        });
        return moveFocusToBlockEnd(nextState, newBlock);
    }

    if (isEmptyBlock) {
        //return replaceWithCustomBlock(nextState, blockTypeId);
    }

    return insertCustomAtomicBlock(nextState, blockTypeId, data);
}
