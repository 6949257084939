import React from 'react';

import SpaceHeader from "joynt/components/SpaceHeader";
import Schedule from "joynt/components/Schedule";
import DaysNav from "joynt/components/Schedule/DaysNav";

//import PropTypes from 'prop-types';

export function EventSchedule(props) {

    const {id} = props;

    return (<div className={"rows overflow-hide"}>
        <SpaceHeader id={id} />
        <Schedule
            {...props}
            NavComponent={DaysNav}
        />
    </div>);

}

EventSchedule.propTypes = {};
EventSchedule.defaultProps = {};

export default EventSchedule;
