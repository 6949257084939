import React from 'react';

import 'css/style.css';
import 'css/links.css';
import 'css/material-colors.css';
import 'css/preloader.css';
import 'css/ui/scroll.css';
import 'css/sortable-tree.css';
import 'css/fields/search-input.css';
import 'css/fields/rewire.css';
import 'css/phone.css';
import 'css/form.css';
import 'css/mui.css';
import 'css/atoms.css';
import 'css/typography.css';

import 'react-dates/initialize';

import PageHead from "joynt/PageHead";
import Router from "joynt/Router";

function Main() {
    return (<>
        <PageHead />
        <Router />
    </>);
}

export default Main;
