import React from 'react';
import Edit from "cms/ui/containers/Edit";
//import PropTypes from 'prop-types';

export default function UserEdit(props) {
    //const {} = props;
    return (<div className={'pad-md overflow-scroll'}>
        <Edit {...props} />
    </div>);

}

UserEdit.propTypes = {};
UserEdit.defaultProps = {};
