export default class UserState {
    constructor() {
        this.reset();
    }
    add(user) {
        if (!this.users[user.uid])
            this.users[user.uid] = {uid: user.uid};

        if (user.audioTrack) this.users[user.uid] = {
            ...this.users[user.uid],
            audioTrack: user.audioTrack
        }

        if (user.videoTrack) this.users[user.uid] = {
            ...this.users[user.uid],
            videoTrack: user.videoTrack
        }

        if (user.hasVideo) this.users[user.uid] = {
            ...this.users[user.uid],
            hasVideo: true
        }

        this.users = {...this.users};
    }
    remove(user) {
        delete this.users[user.uid];
        this.users = {...this.users};
    }
    update(user, data) {
        if (!this.users[user.uid]) return this.users;
        //return this.add(user);

        this.users[user.uid] = {
            ...this.users[user.uid],
            ...data
        };

        // this.users[user.uid] = user;
        
        this.users = {...this.users};
    }
    get() {
        return this.users;
    }
    reset() {
        this.users = {};
    }
    event = (event) => {
        const {mediaType, remoteUser} = event.data;

        let trackPropName = mediaType === 'audio'
            ? 'audioTrack'
            : 'videoTrack';

        switch (event.event) {
            case 'user-published':
                let publishedData = {
                    [trackPropName]: remoteUser[trackPropName]
                };
                if (mediaType === "video") publishedData.hasVideo = true;
                this.update(remoteUser, publishedData);
                event.users = this.get();
                break;
            case 'user-unpublished':
                let unpublishedData = {
                    [trackPropName]: null
                }
                if (mediaType === "video") unpublishedData.hasVideo = false;
                this.update(remoteUser, unpublishedData);
                event.users = this.get();
                break;
            case 'user-joined':
                this.add(remoteUser);
                event.users = this.get();
                break;
            case 'user-left':
                this.remove(remoteUser);
                event.users = this.get();
                break;
            default:
                break;
        }

        return event;
    }
}