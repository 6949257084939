import React, {useCallback} from 'react';
import Button from "components/Button";
//import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "components/Icon";

export default function ButtonTrigger(props) {

    const {src, pending, onClear} = props;

    const click = useCallback(evt => {
        if (src) {
            onClear();
            evt.stopPropagation();
            evt.preventDefault();
        }
        return null;
    }, [src, onClear]);

    const icon = !src ? <Icon>mui-cloud_upload</Icon> : <Icon>mui-close</Icon>;

    return (<Button
        onClick={click}
        disabled={!!pending}
        fullWidth={true}
        variant={'outlined'}
        endIcon={!pending ? icon : null}
    >
        { pending ? <CircularProgress
            size={24}
        /> : <>
            { src ? 'Remove image' : 'Upload image'}
        </> }
    </Button>);

}

ButtonTrigger.propTypes = {};
ButtonTrigger.defaultProps = {};
