import { useApiActions, usePending } from "state/hooks";
import {
    confirmParticipation,
    denyParticipation,
    revokeConfirmation,
} from "joynt/state/actions/meetings";
import { useCallback, useMemo } from "react";
import { useNodeData } from "joynt/hooks";

export function useParticipation(id) {
    const { confirm, deny, revoke } = useApiActions({
        confirm: confirmParticipation,
        deny: denyParticipation,
        revoke: revokeConfirmation
    });

    const onConfirm = useCallback(() => {
        confirm(id);
    }, [confirm, id]);

    const onDecline = useCallback(() => {
        deny(id);
    }, [deny, id]);

    const onRevoke = useCallback(() => {
        revoke(id);
    }, [revoke, id]);

    const {
        session_confirmed: isConfirmed,
        session_declined: isDeclined,
    } = useNodeData(id);

    const isPending = usePending(`session.participation.${id}`);

    return useMemo(
        () => ({
            onConfirm,
            onDecline,
            onRevoke,
            isConfirmed,
            isDeclined,
            isPending,
        }),
        [
            onConfirm,
            onDecline,
            onRevoke,
            isConfirmed,
            isDeclined,
            isPending
        ]
    );
}
