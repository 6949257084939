import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

//import PropTypes from 'prop-types';

export function DefaultConfirmDialog(props) {
    const { yes, no, message, description, cancelText, confirmText } = props;

    return (
        <>
            {message ? (
                <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
            ) : null}
            <DialogContent>
                {description ? (
                    <DialogContentText>{description}</DialogContentText>
                ) : null}
            </DialogContent>
            <DialogActions
                style={{ justifyContent: "center", padding: "1rem" }}
            >
                {cancelText && (
                    <Button
                        color="primary"
                        variant={"outlined"}
                        size="large"
                        onClick={no}
                    >
                        {cancelText}
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={yes}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </>
    );
}

DefaultConfirmDialog.propTypes = {};

DefaultConfirmDialog.defaultProps = {
    cancelText: "Cancel",
    confirmText: "Delete",
    description: "This cannot be undone.",
};

export default DefaultConfirmDialog;
