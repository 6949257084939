import React, {useCallback, useState} from 'react';
import cn from 'classnames';
import Field from "containers/Field";
import List from "containers/List";
import {useNode, useReaction} from "joynt/hooks";
import {useClick, useData} from "state/hooks";
import {ArrayIterator} from "components/list/iterator";
import Button from "components/Button";
//import PropTypes from 'prop-types';

function Option(props) {
    const {id, selected} = props;
    const {title} = useData(props);
    const click = useClick(props);
    return <div onClick={click} className={cn('poll-option', {
        'is-selected': id === selected
    })}>{title}</div>;
}

const OptionIterator = ArrayIterator(Option);

function OptionsList(props) {
    const {value, ...other} = props;
    return <List
        type={'db.answers'}
        items={value}
        Iterator={OptionIterator}
        {...other}
    />;
}

function RenderOptions(props) {
    return <OptionsList {...props} />;
}

export default function VoteForm(props) {

    const {postId, onClose} = props;
    const postRef = {type: 'db.posts', id: postId};
    const {parent} = useData(postRef);
    const {name: postTitle} = useNode(parent);

    const {onReaction} = useReaction(postRef);
    const [selected, setSelected] = useState(null);

    const submit = useCallback(() => {
        onReaction(selected);
        onClose();
    }, [onReaction, onClose, selected]);

    return (<div className={'pad-md rows gap-sm w-md'}>
        <div className={'type-lg'}>{postTitle}</div>
        <div className={'rows gap-sm'}>
            <Field
                id={'answers'}
                fieldType={'collection'}
                readOnly={true}
                postId={postId}
                onClick={setSelected}
                selected={selected}
                CollectionRenderer={RenderOptions}
            />
        </div>
        <div className={'cols cols-center gap-xs'}>
            <Button onClick={onClose} size={'large'} color={'default'}>Cancel</Button>
            <Button onClick={submit} size={'large'} disabled={!selected}>Submit</Button>
        </div>
    </div>);

}

VoteForm.propTypes = {};
VoteForm.defaultProps = {};
