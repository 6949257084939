import Workspace from "./Workspace";
import Event from "./Event";
import Session from "./Session";
import TypeSelect from "./TypeSelect";
import Chat from "./Chat";
import Feed from "./Feed";
import External from "./External";
import Space from "./Space";
import Node from "./Node";
import Project from "./Project";
import WebinarSeries from "./WebinarSeries";

import sessionConfirmTypes from "./Session/confirm";

import {
    NODE_TYPE_EVENT,
    NODE_TYPE_PROJECT,
    NODE_TYPE_SPACE,
    NODE_TYPE_VIEWS,
} from "joynt/config";

import {SESSION_TYPE_WEBINAR_SERIES} from "joynt/config/sessions";

const formTypes = {
    "type-select": TypeSelect,

    workspace: Workspace,
    event: Event,

    session: Session,
    chat: Chat,
    feed: Feed,
    external: External,
    space: Space,
    project: Project,

    'webinar-series': WebinarSeries,

    /** generic types **/
    messages: Node,
    spaces: Node,
    page: Node,
    sessions: Node,
};

/**
 * Treat events which are not workspaces as "sessions"
 * Treat spaces which are workspaces as "workspaces"
 *
 * @param subtype
 * @param workspace
 * @param session_type
 * @returns {string|*}
 */
function nodeType({ subtype, workspace, session_type }) {
    if (!subtype) return "type-select";
    if (!workspace) {
        let type = NODE_TYPE_VIEWS[subtype];
        if (subtype === NODE_TYPE_PROJECT) return "project";
        if (type === "event") return "session";
        return type;
    }
    if (session_type === SESSION_TYPE_WEBINAR_SERIES) return "webinar-series";
    if (subtype === NODE_TYPE_SPACE) return "workspace";
    if (subtype === NODE_TYPE_EVENT) return "event";
}

export default function (params) {
    const formType = formTypes[nodeType(params)];
    return formType || null;
}

export const confirmTypes = {
    ...sessionConfirmTypes,
};
