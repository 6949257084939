import React from 'react';
import {connect} from 'react-redux';
import {exchangeAuthCode} from "state/actions/auth";
import AuthPending from "components/auth/AuthPending";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    process: exchangeAuthCode
};

class AuthCallback extends React.Component {

    componentDidMount() {
        this.props.process();
    }

    render() {
        return <AuthPending />;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthCallback);