import React from "react";
import Header from "joynt/components/WorkspaceHeader";
import "./style.css";
import HeaderNav from "joynt/components/SessionPage/HeaderNav";
import {useEdgesData, useNodeData} from "joynt/hooks";

export default function SessionOverviewHeader(props) {
    const {id} = props;

    const {parent} = useEdgesData(id);
    const {session_type: parentSessionType} = useNodeData(parent);
    const {session_type} = useNodeData(id);

    const isWebinarSeries = [parentSessionType, session_type].includes("webinar-series");

    return <div className={"session-page-header"}>
        {isWebinarSeries && <HeaderNav id={id} />}
        <Header className={"session-overview-header"} {...props} />
    </div>;
}
