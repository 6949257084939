import React from 'react';
import {useNodeData} from "joynt/hooks";
import SidebarItem from "../SidebarItem";
import {LiveEventIcon, EventIcon} from "joynt/components/Sidebar/Icons";

//import PropTypes from 'prop-types';

export function Event(props) {
    const {id} = props;
    const {name, current_event} = useNodeData(id);

    return <SidebarItem
        label={name}
        icon={current_event
            ? <LiveEventIcon/>
            : <EventIcon />}
        {...props}
    />;
}

Event.propTypes = {};
Event.defaultProps = {};

export default Event;
