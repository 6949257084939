import React from 'react';
import PropTypes from 'prop-types';

import types from "./content-types";
import AggregateRoot from "containers/AggregateRoot";
import {usePost} from "joynt/components/Post";
import {useClick} from "state/hooks";

export function ContentBlockContainer(props) {

    const {id, onDelete} = props;
    const {subtype} = usePost(id);

    const deletePost = useClick({onClick: onDelete, id});

    const Component = types[subtype];

    return <AggregateRoot type={'db.posts'} id={id} disableSubmit={true}>
        {Component
            ? <Component
                {...props}
                onDelete={onDelete ? deletePost : null}
                id={id}
            />
            : null}
    </AggregateRoot>;

}

ContentBlockContainer.propTypes = {
    id: PropTypes.string,
    onDelete: PropTypes.func
};

ContentBlockContainer.defaultProps = {};

export default ContentBlockContainer;