import {useRouteParam} from "state/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";

export default function useRouteTransition(param) {
    const [routeId, setRouteId] = useRouteParam(param);
    const [id, setId] = useState(null);
    const [open, setOpen] = useState(false);

    const hide = useCallback(()=>{
        setId(null);
    }, [setId]);

    const onClose = useCallback(()=>{
        if (open) {
            setRouteId(null);
            setOpen(false);
        }
    }, [setOpen, setRouteId, open]);

    useEffect(() => {
        if (routeId) {
            setId(routeId);
            setOpen(true);
        } else {
            onClose();
        }
    }, [routeId, onClose]);

    const transitionProps = useMemo(() => ({
        in: open,
        onExited: hide
    }), [open, hide]);

    return useMemo(() => ({
        id, open, onClose, transitionProps
    }), [id, open, onClose, transitionProps]);
}