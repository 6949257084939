import React from 'react';
import {connect} from 'react-redux';
import {selectRouteParam} from "state/selectors/router";
import {selectEntity} from "state/selectors/data";
import MediaPreview from "components/dialogs/MediaPreview";
import {pushRouteParams} from "state/actions/router";
import {enforceHttps} from "util/uri";
import env from 'util/env';

const nullObj = {};

const mapStateToProps = (store, props) => {
    let item = selectRouteParam(store, 'preview');
    const [type, id] = item ? item.split('/') : [];
    const data = id ? selectEntity(store, type, id) : nullObj;
    const extension = data.file ? data.file.split('.').pop() : null;
    return {
        open: !!item,
        type,
        id,
        file: data.file,
        url: data.url,
        fileType: data.type,
        fileExtension: extension
    }
};

const mapDispatchToProps = {
    pushQuery: pushRouteParams
};

class MediaPreviewContainer extends React.Component {

    download = () => {
        const { file } = this.props;
        const host = enforceHttps(env('FILES_CDN'));
        const url = `${host}${file}`;
        window.open(url);
    };

    open = () => {
        const { url } = this.props;
        window.open(url);
    };

    close = () => {
        const { pushQuery } = this.props;
        pushQuery({preview: null});
    };

    edit = () => {
        const {id, pushQuery} = this.props;
        pushQuery({media: id});
    }

    render() {
        return (<MediaPreview
            {...this.props}
            onClose={this.close}
            onRequestOpen={null}
            onRequestDownload={this.download}
            onEdit={null}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaPreviewContainer);