import React from "react";
import { FormLayout } from "playground/cms/Form";
import Form from "containers/Form";
//import PropTypes from 'prop-types';

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
};

export function PropertyForm(props) {
    const { type, id } = props;
    return (
        <FormLayout.Fieldset pad>
            <Form type={type} id={id} selectFields={selectFields} />
        </FormLayout.Fieldset>
    );
}

PropertyForm.propTypes = {};

PropertyForm.defaultProps = {};

export default PropertyForm;
