import {useEffect, useState} from "react";

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        const current = ref.current;
        if (current) observer.observe(current)
        // Remove the observer as soon as the component is unmounted
        return () => { if(current) observer.disconnect() }
    }, [ref, observer])

    return isIntersecting
}