import { useActions, useApiActions, useData, usePending } from "state/hooks";
import { exportWebsite, submitCreateWebsite } from "cms/state/actions/services";
import { deleteSuccess } from "state/actions/delete";
import { useMemo } from "react";

export function useCreateWebsite({ type, id }) {
    const { onExport, onSubmit } = useApiActions({
        onExport: exportWebsite,
        onSubmit: submitCreateWebsite,
    });

    const { onReset } = useActions({
        onReset: deleteSuccess,
    });

    const { status } = useData({ type, id });
    const isPending = usePending(id);

    return useMemo(
        () => ({
            onExport,
            onSubmit: () => onSubmit(id),
            onReset: () => onReset(type, id),
            status,
            isPending,
        }),
        [onExport, onSubmit, onReset, type, id, status, isPending]
    );
}
