import React, { useCallback, useContext, useState } from "react";

import { useTaskList } from "joynt/components/Tasks/hooks";
import { useFilter, useRouteParam } from "state/hooks";
import { POST_TYPE_TEXT, ROUTE_POST } from "joynt/config";
import TaskList from "./../TaskList";
import { useCreatePost } from "joynt/hooks";
import uuid from "uuid/v4";
import SpaceHeader from "joynt/components/SpaceHeader";
import TasksBoard from "joynt/components/Tasks/TasksBoard";
import IconButton from "components/IconButton";
import Select, { OptionSelect } from "joynt/components/Select";
import { SpaceNestingContext } from "joynt/views";

//import PropTypes from 'prop-types';

const taskStatusOptions = [
    {
        id: "all",
        label: "All tasks",
        value: null,
    },
    {
        id: "incomplete",
        label: "Incomplete tasks",
        value: false,
    },
];

const orderOptions = [
    {
        id: "created",
        label: "Recently created",
        value: null,
    },
    {
        id: "due",
        label: "Due date",
        value: "task_due desc",
    },
    {
        id: "updates",
        label: "Recently updated",
        value: "published_at desc,created_at desc",
    },
];

function getFilterValue(filters, id) {
    let opt = filters.filter((f) => f.id === id);
    if (!opt.length) return null;
    return opt[0].value;
}

function getFilterIdByValue(filters, value) {
    let opt = filters.filter((f) => f.value === value);
    if (!opt.length) return null;
    return opt[0].id;
}

function IdentityFilter(props) {
    const { value, onChange, id } = props;
    const url = `v2/joynt/nodes/${id}/participants`;
    const list = `tasks.${id}.identities`;

    return (
        <Select
            label={"Everyone"}
            type={"db.identities"}
            id={list}
            list={list}
            url={url}
            onChange={onChange}
            variant={"inline"}
            value={value}
            nullable={true}
        />
    );
}

function TasksFilter(props) {
    const { id, list } = props;
    const { value, onSet: onSetFilter } = useFilter(list, "db.nodes", list);
    const nodesList = `tasks.${id}.nodes`;
    const changeNode = useCallback(
        (id) => {
            onSetFilter("id", id);
        },
        [onSetFilter]
    );
    const changeTaskStatusFilter = (v) => {
        onSetFilter("task_complete", getFilterValue(taskStatusOptions, v));
    };
    const url = `v2/joynt/nodes/${id}/graph`;
    return (
        <div className={"cols cols-middle gap-xs"}>
            <div className={"o-50"}>Filter by:</div>
            <OptionSelect
                label={"All tasks"}
                options={taskStatusOptions}
                value={getFilterIdByValue(
                    taskStatusOptions,
                    value.task_complete
                )}
                onChange={changeTaskStatusFilter}
                variant={"inline"}
            />
            <Select
                label={"All spaces"}
                type={"db.nodes"}
                id={nodesList}
                list={nodesList}
                url={url}
                onChange={changeNode}
                variant={"inline"}
                value={value.id}
                nullable={true}
            />
            <IdentityFilter
                id={id}
                value={value.task_assignees}
                onChange={(v) => onSetFilter("task_assignees", v)}
            />
        </div>
    );
}

function SortFilter(props) {
    const { list } = props;

    const { value, onSet: onSetFilter } = useFilter(list, "db.nodes", list);

    const changeOrderFilter = (v) => {
        onSetFilter("order", getFilterValue(orderOptions, v));
    };

    return (
        <div className={"cols cols-middle gap-xs"}>
            <div className={"o-50"}>Sort by:</div>
            <OptionSelect
                label={"Order by"}
                options={orderOptions}
                value={
                    getFilterIdByValue(orderOptions, value.order) || "created"
                }
                onChange={changeOrderFilter}
                variant={"inline"}
            />
        </div>
    );
}

export function View(props) {
    const { id } = props;
    const { list } = useTaskList(id);
    const { value: filter } = useFilter(list, "db.nodes", list);
    const [, show] = useRouteParam(ROUTE_POST);
    const [onCreate] = useCreatePost(id, list);
    const create = useCallback(() => {
        const taskId = uuid();
        console.log(filter); //FIXME
        onCreate(taskId, POST_TYPE_TEXT, true, {
            node_role: "task",
            task_assignees: filter.task_assignees
                ? [filter.task_assignees]
                : [],
            parent: filter.id ? filter.id : id,
        });
    }, [filter, onCreate, id]);
    const [view, setView] = useState("list");

    const views = {
        list: TaskList,
        board: TasksBoard,
    };

    const ViewComponent = views[view];
    const nested = useContext(SpaceNestingContext);

    return (
        <div>
            {!nested && <SpaceHeader id={id} />}
            <div className={"cols cols-spread"}>
                <div className={"cols cols-middle"}>
                    <TasksFilter id={id} list={list} />
                </div>
                <div className={"cols gap-sm"}>
                    <div className={"cols"}>
                        <IconButton
                            icon={"mui-view_list"}
                            size={"small"}
                            onClick={() => setView("list")}
                            checked={view === "list"}
                        />
                        <IconButton
                            icon={"mui-view_column"}
                            size={"small"}
                            onClick={() => setView("board")}
                            checked={view === "board"}
                        />
                    </div>
                    <SortFilter id={id} list={list} />
                </div>
            </div>

            <ViewComponent
                id={id}
                list={list}
                onClick={show}
                onCreate={create}
            />
        </div>
    );
}

View.propTypes = {};
View.defaultProps = {};

export default View;
