import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useNodeData } from "joynt/hooks";
import { useSessionFlowArgs } from "./useSessionFlowArgs";
import { renderSegments } from "./../util";
import { selectFlowSchedule, selectNodeFlowPath } from "./../selectors";

export function useSessionFlowSchedule(session) {
    const { session_flow_segment_started: started } = useNodeData(session);

    const path = useSelector((s) => selectNodeFlowPath(s, session));
    const [flow, pathCurrent] = path.split(".");
    const current = pathCurrent || "start";

    const schedule = useSelector((s) => selectFlowSchedule(s, flow));

    const steps = schedule.map((s) => s.id);
    const next = steps[steps.indexOf(current) + 1];

    const args = useSessionFlowArgs(session);

    return useMemo(
        () => ({
            flow,
            items: renderSegments(schedule, args),
            current,
            next,
            started,
        }),
        [flow, schedule, args, current, next, started]
    );
}
