/* eslint-disable */
import CssClassNameBag from "./CssClassNameBag";
import Scope from "./Scope";

const str_replace = require("locutus/php/strings/str_replace");
const array_unique = require("locutus/php/array/array_unique");
const array_merge = require("locutus/php/array/array_merge");
const strpos = (haystack, needle) => {
    return haystack.indexOf(needle);
};

class Styles {
    constructor() {
        this.options = Array();
        this.styles = Array();
    }

    append(ns, styles) {
        if (!styles) return;
        var newClassNames = new CssClassNameBag();

        for (var className of Object.values(styles)) {
            newClassNames = newClassNames.extend(
                new CssClassNameBag(className),
                this.options
            );
        }

        var existing =
            undefined !== this.styles[ns] && this.styles[ns]
                ? this.styles[ns]
                : new CssClassNameBag();
        var merge = existing.extend(newClassNames, this.options);
        this.styles[ns] = merge;
    }

    appendConfig(ns, config) {
        this.styles[ns] = config;
    }

    fetch(
        ns,
        parentScope = undefined //echo 'fetch ' . $ns . '/'; //if ($parentScope) echo $parentScope->getNamespace();
    ) //echo PHP_EOL;
    {
        var styles =
            undefined !== this.styles[ns] && this.styles[ns]
                ? this.styles[ns]
                : new CssClassNameBag("@all");

        if (!styles || !styles.hasAny) return new CssClassNameBag();

        if (styles.hasAny("@all")) {
            var nsParts = ns.split(":");
            if (nsParts.length < 2) return styles;
            var type = nsParts[0];
            var primaryType = type;
            var idParts = nsParts[1].split(".");
            if (idParts.length > 1) type = idParts[1];
            var parentNs =
                idParts.length > 1 ? nsParts[0] + "." + idParts[1] : nsParts[0];
            var extendWith = this.fetch(parentNs, parentScope);

            if (parentScope) {
                //echo 'type: ' . $primaryType . '/' . $type . PHP_EOL;
                parentNs = parentScope.getNamespace();
                parentNs = str_replace("." + primaryType, "." + type, parentNs);

                if (strpos(parentNs, type) > -1) {
                    //echo ' ^ other' . PHP_EOL;
                    //var_dump($otherWith);
                    var otherWith = this.fetch(
                        parentNs,
                        parentScope.getParentScope()
                    );
                    extendWith = extendWith.extend(otherWith, this.options);
                }
            }

            return styles.replace("@all", extendWith, this.options);
        }

        return styles.replace(
            "@all",
            new CssClassNameBag(Array()),
            this.options
        );
    }

    getNs(scopeNs, key) {
        var ns = scopeNs + "." + key;
        return undefined !== this.styles[ns] && this.styles[ns]
            ? this.styles[ns]
            : undefined;
    }

    fetchNs(namespaces, key) {
        var merge = new CssClassNameBag();
        var allNamespaces = Array();
        var extendedNamespaces = Array();

        for (var ns of Object.values(namespaces)) {
            allNamespaces.push(ns);
            this.extendNs(ns, key, allNamespaces, extendedNamespaces);
        }

        namespaces = allNamespaces.reverse();

        for (var ns of Object.values(namespaces)) {
            var styles =
                undefined !== this.styles[ns] && this.styles[ns]
                    ? this.styles[ns]
                    : undefined;

            if (styles) {
                var mergeClasses =
                    styles.hasAnyLike("@all") ||
                    -1 !== extendedNamespaces.indexOf(ns);

                if (mergeClasses) {
                    merge = merge.extend(styles, this.options);
                } else {
                    merge = styles;
                }
            }
        }

        return merge;
    }

    extendNs(ns, key, all, extended) {
        var baseNsParts = ns.split(".");
        var extendNs = baseNsParts[0] + ".@extends";
        all.push(ns);

        if (undefined !== this.styles[extendNs]) {
            var extendsNs = this.styles[extendNs];
            extendsNs = extendsNs.__toString();
            all.push(extendsNs + "." + key);
            extended.push(ns);
            extended.push(ns + "." + key);
            this.extendNs(extendsNs, key, all, extended);
        }
    }

    scope(ns) {
        return new Scope(ns, this);
    }

    appendOptions(scopes) {
        for (var scope in scopes) {
            var options = scopes[scope];
            this.appendScopeOptions(scope, options);
        }
    }

    appendScopeOptions(scope, options) {
        var existing =
            undefined !== this.options[scope] && this.options[scope]
                ? this.options[scope]
                : Array();
        this.options[scope] = Object.values(
            array_unique(array_merge(existing, options))
        );
    }

    getOptions(scope = undefined) {
        if (!scope) return this.options;
        return this.options[scope];
    }

    dump() {}
}

export default Styles;
