import React, { useCallback, useContext, useMemo, useState } from "react";
import Popper, { usePopper } from "joynt/components/Popper";
import { useReaction } from "joynt/hooks";
import { useIdentity } from "joynt/hooks/participants";
import emojis from "joynt/components/Reactions/emojis";
//import PropTypes from 'prop-types';

const ReactionsContext = React.createContext({});

export function useReactionsOverlay({ type, id }) {
    const { onShow, ...other } = useContext(ReactionsContext);
    const show = useCallback(
        (evt, reactionType) => {
            onShow(evt, { type, id, reactionType });
        },
        [onShow, type, id]
    );
    return {
        onShow: show,
        ...other,
    };
}

function IdentityName({ id }) {
    const { name } = useIdentity(id);
    return name || null;
}

function ReactionList({ reactionType, ...other }) {
    const { reactions } = useReaction(other);
    const { identities } = reactions[reactionType];
    const limit = 5;
    const showIdentities = identities.slice(0, limit);
    const othersCount = identities.length - limit;
    const isEmojiReaction = Object.keys(emojis).indexOf(reactionType) > -1;
    return (
        <div className={"rows type-default"}>
            {isEmojiReaction ? (
                <div>
                    <strong>Reacted with {reactionType}</strong>
                </div>
            ) : null}
            <div className={"rows"}>
                {showIdentities.map((id) => (
                    <div key={id}>
                        <IdentityName id={id} />
                    </div>
                ))}
                {othersCount > 0 ? <div>...and {othersCount} more</div> : null}
            </div>
        </div>
    );
}

export default function ReactionsOverlay(props) {
    const { children } = props;

    const [reactionProps, setReactionProps] = useState({});
    const { onShow, onHide, state } = usePopper();

    const show = useCallback(
        (evt, props) => {
            setReactionProps(props);
            onShow(evt);
        },
        [setReactionProps, onShow]
    );

    const context = useMemo(
        () => ({
            onShow: show,
            onHide,
        }),
        [show, onHide]
    );

    return (
        <ReactionsContext.Provider value={context}>
            {children}
            <Popper {...state} onHide={onHide} placement={"top"}>
                <ReactionList {...reactionProps} />
            </Popper>
        </ReactionsContext.Provider>
    );
}

ReactionsOverlay.propTypes = {};
ReactionsOverlay.defaultProps = {};
