import React, { useCallback, useContext, useEffect, useMemo } from "react";
//import PropTypes from 'prop-types';
import {
    useConfig,
    useRTMEvents,
    useSessionConfig,
    useSessionContext,
} from "./hooks";
import { useScreenShare } from "./hooks";
import { Stage } from "joynt/meeting/stage";
import VideoTile from "./VideoTileContainer";
import SessionToolbar, {
    SessionToolbarPortal,
} from "joynt/components/SessionToolbar";
import { usePending, useUiFlag } from "state/hooks";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { SESSION_POLICY_MEETING } from "joynt/config";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import Streams from "joynt/meeting/stage/Streams";
import useStreams from "joynt/meeting/hooks/useStreams";

import { usePresentation } from "joynt/meeting/presentation";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import SessionContextProvider from "joynt/meeting/agora-ng/SessionContextProvider";
import { AgoraContext } from "joynt/meeting/agora-ng/context/AgoraContext";
import { useSessionLayout } from "joynt/components/SessionLayout";

import { Stage as FlowStageDecorator } from "joynt/components/SessionFlow/Decorators";
import useScreenSize from "util/hooks/useScreenSize";
import FloatingSessionOverlay from "joynt/components/FloatingSession/StageOverlay";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";

export default function Session(props) {
    const { access } = props;
    const { channel, user } = access;
    const { onLeaveSession } = useMeeting();
    const pending = usePending(channel);

    const [{ joined, loading, error, connectionState }] =
        useConnectionState(channel);

    const connection = useContext(AgoraContext);

    const retryConnect = useCallback(() => {
        connection.join({ channel, user: access.user, access });
    }, [connection, channel, access]);

    return (
        <SessionContextProvider error={error} joined={joined} access={access}>
            {joined ? (
                <StageSessionContainer
                    joined={true}
                    pending={pending}
                    channel={channel}
                    user={user}
                />
            ) : (
                <Stage
                    pending={loading}
                    error={error}
                    status={connectionState?.currentState}
                    onRetry={retryConnect}
                    onLeave={onLeaveSession}
                />
            )}
        </SessionContextProvider>
    );
}

Session.propTypes = {};
Session.defaultProps = {};

function StageSessionContainer(props) {
    const { users, activeSpeakers, localTracks, channel } = useSessionContext();

    return (
        <AgoraStage
            {...props}
            channel={channel}
            users={users}
            activeSpeakers={activeSpeakers}
            localTracks={localTracks}
            TileComponent={VideoTile}
        />
    );
}

export const AgoraStage = (props) => {
    const { channel, user } = props;

    const { users, activeSpeakers, localTracks } = props;

    const { session, event, policy, role } = useMeeting();

    const { TileComponent } = props;

    const { isDesktop } = useScreenSize();

    const {
        audio,
        setAudio,
        setVideo,
        setIsLive,
        setLayout,
        onShowSession,
        isMinimized,
    } = useSessionState(channel);

    // join the call
    const { loading, pending, error } = props;

    const { toggleScreenShare, isSharingScreen } = useScreenShare();

    useRTMEvents({
        toggleAudio: setAudio,
        toggleVideo: setVideo,
        toggleScreenShare,
        toggleLive: setIsLive,
    });

    const {
        SHOW_SESSION_TOOLBAR,
        SHOW_TILES_FOR_DISCONNECTED_USERS,
        MAX_PUBLISHED_STREAMS,
        MAX_TILES_FOCUSED,
        MAX_TILES_GRID,
        QUALITY_OPTIMIZE_ABOVE,
        PRIORITIZE_ACTIVE_STREAMS,
        SHOW_AUDIO_STREAMS,
        SESSION_LAYOUT,
        MAX_TILES_MINIMIZED,
    } = useConfig();

    const {
        tracks,
        focus: streamsFocus,
        remote,
        screen,
    } = useStreams({
        user,
        localTracks,
        users,
        activeSpeakers,
        showAll: SHOW_TILES_FOR_DISCONNECTED_USERS,
        prioritizeActive: PRIORITIZE_ACTIVE_STREAMS,
    });

    const focus = streamsFocus;
    const { id: presentedNode } = usePresentation();
    const focusedLayout = SESSION_LAYOUT !== "grid" || screen || presentedNode;

    let limit = focusedLayout ? MAX_TILES_FOCUSED : MAX_TILES_GRID;
    if (isMinimized) limit = MAX_TILES_MINIMIZED;

    if (presentedNode) limit--;

    //if (isMobile) limit = 1;

    let visibleTracks = useMemo(() => {
        return tracks.slice(0, limit);
    }, [tracks, limit]);

    let streamsCount = visibleTracks.length;
    if (presentedNode) streamsCount++;

    const quality = remote > QUALITY_OPTIMIZE_ABOVE || focusedLayout ? 1 : 0;

    const shouldDisconnectOnMute =
        remote > MAX_PUBLISHED_STREAMS && !isSharingScreen;

    useEffect(() => {
        if (policy === SESSION_POLICY_MEETING && shouldDisconnectOnMute) {
            setIsLive(!!audio);
        }
    }, [shouldDisconnectOnMute, policy, audio, setIsLive]);

    const { onToggle: onToggleCollapsed } = useSessionLayout();

    const onToggleTileView = useCallback(() => {
        setLayout(SESSION_LAYOUT === "grid" ? "meeting" : "grid");
    }, [SESSION_LAYOUT, setLayout]);

    const onToggleScreenShare = useCallback(() => {
        toggleScreenShare(!isSharingScreen);
    }, [toggleScreenShare, isSharingScreen]);

    const canToggleScreen = !screen || isSharingScreen;
    const canChangeLayout = !screen;

    const [debug] = useUiFlag("meeting.debug");

    return (
        <>
            <FlowStageDecorator
                id={event || session}
                count={streamsCount}
                pending={loading}
                focused={focusedLayout}
                error={error ? error.message : null}
                onOverlayClick={onShowSession}
                onClick={isDesktop ? onToggleCollapsed : () => null}
                isMinimized={isMinimized}
                debug={!!debug}
                Component={Stage}
                overlay={
                    isMinimized && isDesktop ? (
                        <FloatingSessionOverlay onClick={onToggleCollapsed} />
                    ) : null
                }
            >
                <Streams
                    channel={channel}
                    user={user}
                    users={tracks}
                    limit={limit}
                    layout={SESSION_LAYOUT}
                    focus={focus}
                    showAll={SHOW_TILES_FOR_DISCONNECTED_USERS}
                    quality={quality}
                    component={TileComponent}
                    node={presentedNode}
                    showAudioStreams={SHOW_AUDIO_STREAMS}
                />
            </FlowStageDecorator>
            {SHOW_SESSION_TOOLBAR && (
                <SessionToolbarPortal>
                    <SessionToolbar
                        pending={pending}
                        onToggleScreenShare={
                            canToggleScreen ? onToggleScreenShare : null
                        }
                        isSharingScreen={isSharingScreen}
                        layout={SESSION_LAYOUT}
                        onToggleTileView={
                            canChangeLayout ? onToggleTileView : null
                        }
                    />
                </SessionToolbarPortal>
            )}
        </>
    );
};

AgoraStage.defaultProps = {
    users: [],
    activeSpeakers: {},
};
