const schemaTypes = ["products", "categories", "properties"];

export const typeToCmsType = (type) => {
    if (schemaTypes.includes(type)) {
        return "cms." + type;
    }
    return ["cms.entries", type].join("/");
};

export const cmsTypeToType = (cmsType) => {
    return cmsType.replace(/^cms\./, "").replace(/^cms\.entries\./, "");
};

export function pageTypeToCmsType(type) {
    const map = {
        pages: "cms.pages",
        layouts: "cms.layouts",
        products: "cms.products",
        categories: "cms.categories",
    };
    return map[type] || ["cms.entries", type].join(".");
}
