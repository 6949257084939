import { setIn } from 'lodash-redux-immutability';
import {TREE_EXPAND} from "config/list-aliases";

const selectLists = (state) => {
    let filtered = {};
    let types = Object.keys(state);
    for (let i = 0; i < types.length; i++) {
        let type = types[i];
        let lists = Object.keys(state[type]);
        for (let j = 0; j < lists.length; j++) {
            let list = lists[j];
            if (list.indexOf('/'+TREE_EXPAND) > -1) {
                filtered = setIn(filtered, [type, list], state[type][list]);
            }
        }
    }
    return filtered;
};

export const getPersistedState = () => {
    return localStorage.getItem('reduxState')
        ? JSON.parse(localStorage.getItem('reduxState'))
        : {};
};

export const persistState = (state) => {
    /*let preset = state.data['console.presets']
        ? state.data['console.presets']['@currentPreset']
        : null;*/

    let stateToPersist = {
        auth: {...state.auth},
        //ui: {...state.ui},
        lists: selectLists(state.lists),
        //data: {}
        //changes: {...state.changes}
    };

    delete stateToPersist.auth.error;

    /*if (preset) {
        stateToPersist.data['console.presets'] = {
            '@currentPreset': preset
        };
        stateToPersist.data['console.presets'][preset] = state.data['console.presets'][preset];
    }*/

    localStorage.setItem('reduxState', JSON.stringify(stateToPersist));
};