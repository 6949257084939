import React from "react";
import ConfirmDialog from "joynt/components/ConfirmDialog";

//import PropTypes from 'prop-types';

export function SessionPublished(props) {
    //const {} = props;

    return (
        <ConfirmDialog
            {...props}
            title={
                <>
                    Your session has been
                    <br />
                    successfully published!
                </>
            }
        >
            <p>
                We will notify{" "}
                <strong>
                    people in this space or with access to this session.
                </strong>
            </p>
        </ConfirmDialog>
    );
}

SessionPublished.propTypes = {};
SessionPublished.defaultProps = {};

export default SessionPublished;
