import React, { useCallback } from "react";
import PropTypes from "prop-types";

import PostContent from "./PostContent";
import ContentBlock from "./ContentBlock.container";
import { useNode } from "joynt/hooks";
import { useActions } from "state/hooks";
import { pathDetach } from "state/actions/data";
import ContentEditor, {
    useContentEditor,
} from "joynt/components/ContentEditor";
import AggregateRoot from "containers/AggregateRoot";

function EditContainer(props) {
    const { id, posts } = props;

    const { onDetach } = useActions({
        onDetach: pathDetach,
    });

    const deletePost = useCallback(
        (post) => {
            onDetach(["db.nodes", id, "posts"], post);
        },
        [onDetach, id]
    );

    return (
        <PostContent {...props}>
            {posts &&
                posts.map((post) => (
                    <ContentBlock
                        key={post}
                        id={post}
                        editable={true}
                        onDelete={deletePost}
                    />
                ))}
        </PostContent>
    );
}

export function PostContentDraft(props) {
    const { id, editable } = props;

    const { value, onChange, onUpload } = useContentEditor(id);
    const { updated_at } = useNode(id);

    const readOnly = !editable;
    const key = [id, readOnly ? `read-only.${updated_at}` : "editable"].join(
        "."
    );

    return (
        <PostContent>
            <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
                <ContentEditor
                    key={key}
                    initialValue={value}
                    onSave={onChange}
                    onUpload={onUpload}
                    readOnly={readOnly}
                />
            </AggregateRoot>
        </PostContent>
    );
}

export function PostContentContainer(props) {
    const { id, editable } = props;
    const { posts, content } = useNode(id);

    const version = content?.version;

    if (version === "draft-js.raw") {
        return <PostContentDraft {...props} />;
    }

    if (editable) return <EditContainer id={id} posts={posts} />;

    if (!posts || !posts.length) return null;

    return (
        <PostContent {...props}>
            {posts &&
                posts.map((post) => <ContentBlock key={post} id={post} />)}
        </PostContent>
    );
}

PostContentContainer.propTypes = {
    id: PropTypes.string,
    editable: PropTypes.bool,
};
PostContentContainer.defaultProps = {
    editable: false,
};

export default PostContentContainer;
