import { store as libraryStore } from "playground/cms/views/Sections/SectionBrowser/store";
import { selectEntity } from "state/selectors/data";
import { denormalize } from "state/util";
import { getLibraryState } from "playground/cms/state/selectors/library";

function selectUsedStyles(data) {
    let styles = [];
    if (!data) return styles;
    if (data.style) styles.push({ id: data.id, style: data.style });
    const reducer = (acc, item) => {
        if (item.style) acc.push({ id: item.id, style: item.style });
        if (item.components) acc = item.components.reduce(reducer, acc);
        return acc;
    };
    styles = data.items.reduce(reducer, styles);
    return styles;
}

export function selectStylesImport(target, origin, id) {
    if (!id) return [];
    const libraryState = getLibraryState(origin);
    const state = getLibraryState(target);
    const data = denormalize(libraryState, "cms.sections", id);
    if (!data) return [];
    let styles = selectUsedStyles(data);
    if (data.template) {
        const template = denormalize(
            libraryState,
            "cms.sections",
            data.template
        );
        styles = styles.concat(selectUsedStyles(template));
    }
    return styles.map((style) => {
        return {
            ...style,
            local: selectEntity(state, "cms.styles", style.style),
        };
    });
}
