import React from "react";
import ConfirmJoin from "./ConfirmJoin.container";
import StageCover from "joynt/components/SessionFlow/StageCover";
import ResponsiveDialog from "components/ResponsiveDialog";

//import PropTypes from 'prop-types';

export function ConfirmJoinView(props) {
    const { isMinimized } = props;

    return (
        <StageCover>
            {!isMinimized ? (
                <ConfirmJoin />
            ) : (
                <ResponsiveDialog open={true}>
                    <ConfirmJoin />
                </ResponsiveDialog>
            )}
        </StageCover>
    );
}

ConfirmJoinView.propTypes = {};
ConfirmJoinView.defaultProps = {};

export default ConfirmJoinView;
