import { selectList } from "state/selectors/data";

export function selectResultsByPageType(state, type) {
    const results = selectList(state, type, type);
    return results.reduce((acc, item) => {
        const pageType = item.page_type || "unknown";
        if (!acc[pageType]) {
            acc[pageType] = {
                id: pageType,
                items: [],
            };
        }
        acc[pageType].items.push(item.id);
        return acc;
    }, {});
}
