import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import PostUnder from "./PostUnder";
import {useActions} from "state/hooks";
import {change} from "state/actions/data";
import {useNode} from "joynt/hooks";

export function PostUnderContainer(props) {

    const {id, parent, show} = props;
    const {parent: value} = useNode(id);

    const { onChange } = useActions({ onChange: change });

    useEffect(() => {
        onChange("db.nodes", id, { parent });
    }, [parent, id, onChange]);

    const handleChange = useCallback((v) => {
        onChange("db.nodes", id, { parent: v });
    }, [id, onChange]);

    if (!show) return null;

    return <PostUnder
        parent={parent}
        value={value !== parent ? value : null}
        onChange={handleChange}
    />

}

PostUnderContainer.propTypes = {
    id: PropTypes.string
};
PostUnderContainer.defaultProps = {};

export default PostUnderContainer;