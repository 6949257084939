import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Modal from "components/Modal";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
//import PropTypes from 'prop-types';

function CreateLayer({ onClose, onSubmit }) {
    const [name, setName] = useState("");
    const submit = () => {
        onSubmit(name);
        onClose();
    };
    return (
        <div className={"l-rows l-rows--gap-md l-area--padding"}>
            <TextField label="Layer name" value={name} onChange={setName} />
            <Button onClick={submit}>Create</Button>
        </div>
    );
}

export default function Layers(props) {
    const { items, value, onChange, onAdd } = props;

    return (
        <>
            <Modal
                trigger={({ onClick }) => (
                    <ListItem button onClick={onClick}>
                        Add layer
                    </ListItem>
                )}
                content={({ onClose }) => (
                    <CreateLayer onClose={onClose} onSubmit={onAdd} />
                )}
            />
            {items.map((id) => {
                return (
                    <ListItem
                        button
                        key={id}
                        onClick={() => onChange(id)}
                        selected={value === id}
                    >
                        <ListItemText primary={id} />
                    </ListItem>
                );
            })}
        </>
    );
}

Layers.propTypes = {};
Layers.defaultProps = {};
