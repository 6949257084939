import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";
import IconButton from "components/IconButton";

export function RangeNav(props) {

    const {
        onToday,
        onPrev,
        onNext
    } = props;

    return (<div className={"range-nav"}>
        <IconButton onClick={onPrev} icon={"mui-chevron_left"} />
        <div className={"today-btn"} onClick={onToday}>
            Today
        </div>
        <IconButton onClick={onNext} icon={"mui-chevron_right"} />
    </div>);

}

RangeNav.propTypes = {};
RangeNav.defaultProps = {};

export default RangeNav;
