import React from "react";

import SelectLayout from "./SelectLayout";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

//import PropTypes from 'prop-types';

export function ControlledSelect(props) {
    const { value, onChange, label, options, nullable, ...other } = props;

    return (
        <SelectLayout
            {...other}
            label={label}
            value={value}
            onClear={() => onChange(null)}
            selectMenu={(onClose) => {
                return (
                    <>
                        {nullable && (
                            <ListItem
                                button
                                selected={!value}
                                onClick={() => {
                                    onChange(null);
                                    onClose();
                                }}
                            >
                                <ListItemText>default</ListItemText>
                            </ListItem>
                        )}
                        {options.map((opt) => {
                            return (
                                <ListItem
                                    button
                                    selected={opt.id === value}
                                    key={opt.id}
                                    onClick={() => {
                                        onChange(opt.id);
                                        onClose();
                                    }}
                                >
                                    <ListItemText>{opt.label}</ListItemText>
                                </ListItem>
                            );
                        })}
                    </>
                );
            }}
        />
    );
}

ControlledSelect.propTypes = {};

ControlledSelect.defaultProps = {
    nullable: true,
};

export default ControlledSelect;
