import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Preloader from "components/Preloader";
import Fab from "@material-ui/core/Fab";
import {Icon} from "@material-ui/core";
import Portal from "@material-ui/core/Portal";

export default class ListFormLayout extends React.Component {

    render() {
        const {
            isPending,
            sideNav,
            list,
            content,
            secondaryList,
            form,
            userMenu,
            onFabClick,
            showUserMenu
        } = this.props;

        const fab = onFabClick ? <div className={'list-actions'}>
            <div className={'list-fab'}>
                <Fab onClick={onFabClick} color={'secondary'}>
                    <Icon>add</Icon>
                </Fab>
            </div>
        </div>: null;

        return (<div className={classNames(
            'console-layout default-c600 grow',
            {
                'with-user-menu': showUserMenu,
                'with-form': !!form
            }
        )}>
            <Preloader visible={isPending} />
            <div className={'console-nav rows pad-sm phone@cols primary fixed'}>
                {sideNav}
            </div>
            <Portal>
                <div className={classNames('console-user-menu default',{
                    'is-visible': showUserMenu
                })}>
                    {userMenu}
                </div>
            </Portal>
            { content ? content : null }
            { list ? <div className={'console-list relative grow rows default-c700'}>
                {list}
                {fab && !secondaryList ? fab : null}
            </div> : null }
            {secondaryList ? <div className={'console-list-secondary rows relative'}>
                {secondaryList}
                {fab ? fab : null}
            </div> : null}
            { form ? <div className={'console-form grow rows form-shadow'}>
                    {form}
            </div> : null }
        </div>);
    }

}

ListFormLayout.propTypes = {
    isPending: PropTypes.bool
};