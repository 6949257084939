import React from 'react';
import PropTypes from 'prop-types';
import FeedPost from "./FeedPost";
import {useImpressionMonitor} from "joynt/components/ImpressionMonitor";

import PostHeader from "./../PostHeader";
import PostContent from "./../PostContent";
import PostComments from "./../PostComments";
import PostTags from "./../PostTags";

export function FeedPostContainer(props) {

    const {id} = props;
    const {ref, hasNotifications} = useImpressionMonitor(id, true);

    return (<FeedPost
        {...props}
        isNew={hasNotifications}
        ref={ref}
    >
        <PostHeader id={id} />
        <PostTags id={id} />
        <PostContent id={id} />
        <PostComments id={id} />
    </FeedPost>);

}

FeedPostContainer.propTypes = {
    id: PropTypes.string
};
FeedPostContainer.defaultProps = {};

export default FeedPostContainer;