import React, {useContext} from "react";

export const BrowserPermissionsContext = React.createContext({});
export const BrowserMediaDevicesContext = React.createContext({});

export function useBrowserPermissions() {
    return useContext(BrowserPermissionsContext);
}

export function useMediaDevices() {
    return useContext(BrowserMediaDevicesContext);
}
