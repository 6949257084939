import React, { useState } from "react";

import IconButton from "components/IconButton";

import "./style.css";
import Icon from "components/Icon";
import { PillButton } from "joynt/components/PillButton";

export default function DailySelect(props) {
    const { startingDate, onChange } = props;

    const [currentDate, setCurrentDate] = useState(startingDate);
    return (
        <header className={"daily-select"}>
            <Icon>mui-calendar_month</Icon>
            <div className={"daily-select__day"}>
                {new Date(currentDate).toLocaleDateString(
                    {},
                    { dateStyle: "long" }
                )}
            </div>
            <div className={"daily-select__controls"}>
                <IconButton
                    className={"daily-select__chevron"}
                    icon={"mui-chevron_left"}
                    onClick={() => {
                        const newDate = new Date(currentDate);
                        newDate.setDate(newDate.getDate() - 1);
                        const newTime = newDate.getTime();
                        onChange(newTime);
                        setCurrentDate(newTime);
                    }}
                />
                <IconButton
                    className={"daily-select__chevron"}
                    icon={"mui-chevron_right"}
                    onClick={() => {
                        const newDate = new Date(currentDate);
                        newDate.setDate(newDate.getDate() + 1);
                        const newTime = newDate.getTime();
                        onChange(newTime);
                        setCurrentDate(newTime);
                    }}
                />
            </div>
            <PillButton
                label={"Today"}
                onClick={() => {
                    const newDate = new Date(currentDate);
                    setCurrentDate(new Date());
                    const newTime = newDate.getTime();
                    onChange(newTime);
                    setCurrentDate(newTime);
                }}
            />
        </header>
    );
}
