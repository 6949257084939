import React from 'react';
import {connect} from 'react-redux';
import UserSettings from "components/user-menu/UserSettings";
import AggregateRoot from "containers/AggregateRoot";
import {resolveAlias} from "state/selectors/data";

const mapStateToProps = (store, props) => {
    const id = resolveAlias(store, 'console.user_settings', '@currentUser');
    return {
        type: 'console.user_settings',
        id: id || 'current'
    }
};

const mapDispatchToProps = {};

class UserSettingsContainer extends React.PureComponent {

    render() {
        return (<AggregateRoot {...this.props}>
            <UserSettings />
        </AggregateRoot>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSettingsContainer);