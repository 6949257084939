import React from 'react';
import PropTypes from 'prop-types';

import DailySchedule from "joynt/components/DailySchedule";
import Button from "components/Button";
import Icon from "components/Icon";
import SessionList from "./SessionList";
import EmptyState from "./EmptyState";

export function Schedule(props) {

    const {
        id,
        list,
        stageSessionProps,
        onChangeDay,
        selectedDay,
        onCreate,
        canEdit,
        sessionCount,
        hasSessions,
        isPending,
        tracks,
        NavComponent,
        ListComponent
    } = props;

    return (<div className={"schedule rows gap-md overflow-scroll"}>
        <div className={"schedule__nav rows gap-md"}>
            <NavComponent
                value={selectedDay}
                onChange={onChangeDay}
                count={sessionCount}
            />
            {!hasSessions && !isPending && <EmptyState />}
            {canEdit && <div className={"cols cols-center"}>
                <Button
                    onClick={onCreate}
                    variant={"outlined"}
                    startIcon={<Icon>mui-event</Icon>}
                >
                    Schedule session
                </Button>
            </div>}
        </div>
        <div className={"schedule__list"}>
            <ListComponent
                id={id}
                list={list}
                stageSessionProps={stageSessionProps}
                selectedDay={selectedDay}
                tracks={tracks}
            />
        </div>
    </div>);
}

Schedule.propTypes = {
    list: PropTypes.string.isRequired,
    stageSessionProps: PropTypes.object,
    onChangeDay: PropTypes.func,
    selectedDay: PropTypes.number,
    onCreate: PropTypes.func,
    canEdit: PropTypes.bool,
    sessionCount: PropTypes.object,
    hasSessions: PropTypes.bool,
    isPending: PropTypes.bool,
    tracks: PropTypes.bool
};
Schedule.defaultProps = {
    canEdit: false,
    hasSessions: false,
    isPending: false,
    tracks: false,
    sessionCount: {},
    NavComponent: DailySchedule,
    ListComponent: SessionList
};

export default Schedule;
