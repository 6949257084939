import cms from "cms/state/sagas/cms";
import themeVcs from "cms/state/sagas/theme-vcs";
import dragSection from "cms/state/sagas/drag-section";
import apartments from "cms/state/sagas/apartments";
import services from "cms/state/sagas/services";
import clipboard from "cms/state/sagas/clipboard";
import pages from "cms/state/sagas/pages";

export default [
    cms,
    themeVcs,
    dragSection,
    apartments,
    services,
    clipboard,
    pages,
];
