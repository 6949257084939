import {useCallback} from "react";

import {setFlag} from "state/actions/ui";
import {useSelector} from "react-redux";
import {selectFlag} from "state/selectors/ui";
import {useActions} from "state/hooks/useActions";

export function useUiFlag(id, defaultValue) {
    const {onSetFlag} = useActions({onSetFlag: setFlag});
    const value = useSelector(s=>selectFlag(s, id));
    const setter = useCallback((value)=>onSetFlag(id, value), [id, onSetFlag]);
    const noValue = value === undefined || value === null;
    return [noValue ? defaultValue : value, setter];
}