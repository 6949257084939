import React from "react";
import PostIdentity from "joynt/post/PostIdentity";
import { useReactionsOverlay } from "joynt/components/Reactions/ReactionsOverlay";
//import PropTypes from 'prop-types';

export default function AnswerResponses(props) {
    const { limit, id, post, votes, className } = props;
    const { onShow, onHide } = useReactionsOverlay({
        type: "db.posts",
        id: post,
    });

    if (votes.length <= limit)
        return (
            <div className={className}>
                <PostIdentity identities={votes} />
            </div>
        );

    return (
        <div
            className={className}
            onMouseEnter={(e) => onShow(e, id)}
            onMouseLeave={onHide}
        >
            <PostIdentity identities={votes.slice(0, limit)} />
            <div className={"responses-count"}>{votes.length}</div>
        </div>
    );
}

AnswerResponses.propTypes = {};
AnswerResponses.defaultProps = {
    limit: 3,
    votes: [],
    className: "poll-responses cols cols-middle",
};
