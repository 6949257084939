import React, {useCallback} from 'react';
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useRouteParam} from "state/hooks";
import {useRTMControls} from "joynt/meeting/agora-ng/hooks";
import useCloudRecording from "joynt/meeting/hooks/useCloudRecording";
import MenuItem from "components/context-menu/MenuItem";
import Divider from "@material-ui/core/Divider";
//import PropTypes from 'prop-types';

export function ToolbarOptions(props) {
    const {
        onEndSession,
        onStartNextSession
    } = useMeeting();

    const [id] = useRouteParam('id');

    const end = useCallback(()=>onEndSession(id), [id, onEndSession]);
    const next = useCallback(()=>onStartNextSession(id), [id, onStartNextSession]);

    const {muteAll} = useRTMControls();

    const {
        onStartRecording,
        onStopRecording,
        canRecord
    } = useCloudRecording();

    return <>
        <MenuItem
            icon={'mui-skip_next'}
            primaryText={'Start next session'}
            onClick={next}
        />
        <MenuItem
            icon={'mui-stop'}
            primaryText={'End current session'}
            onClick={end}
        />
        <Divider />
        { canRecord && onStartRecording ?
            <MenuItem
                icon={'mui-fiber_manual_record'}
                primaryText={'Start recording'}
                onClick={onStartRecording}
            />
            : null}
        { canRecord && onStopRecording ?
            <MenuItem
                icon={'mui-stop'}
                primaryText={'Stop recording'}
                onClick={onStopRecording}
            />
            : null}
        { canRecord && (onStartRecording || onStopRecording) ? <Divider /> : null }
        <MenuItem
            icon={'mui-mic_off'}
            primaryText={'Mute all'}
            onClick={()=>muteAll()} />
    </>;
}

ToolbarOptions.propTypes = {};
ToolbarOptions.defaultProps = {};
