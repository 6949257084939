import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";

export function RadioButtonGroup(props) {
    const { variant, children } = props;
    return (
        <div
            className={cn("radio-btn-group", {
                "radio-btn-group--dense": variant === "dense",
            })}
        >
            {children}
        </div>
    );
}

RadioButtonGroup.propTypes = {};

RadioButtonGroup.defaultProps = {};

export default RadioButtonGroup;
