import React from 'react';
//import PropTypes from 'prop-types';
import PageService from "cms/PageService";
import ScrollManager from "cms/ScrollManager";


export default class PageServiceClient extends React.PureComponent {

    render() {
        return (
            <ScrollManager>
                <PageService {...this.props} />
            </ScrollManager>);
    }

}

PageServiceClient.propTypes = {};