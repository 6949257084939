import React, { useMemo } from "react";
import { useEntity } from "joynt/edit/hooks";
import { useEdges, useNodeData } from "joynt/hooks";
import { useAccessSettings } from "joynt/components/Roles/hooks";
import { accessTypes } from "joynt/components/Roles/config";
import CategorySelect from "joynt/edit/CategorySelect";

//import PropTypes from 'prop-types';

export function AccessProfile(props) {
    const { id } = props;
    const { data } = useEntity();
    const { parent } = useEdges(id);
    const { name: parentName } = useNodeData(parent);
    const { onChange: onChangeAccess } = useAccessSettings(id);

    const { published } = data;

    const options = useMemo(() => {
        return Object.values({
            public: {
                ...accessTypes.public,
                description: `All members of ${parentName} can access this space.`,
            },
            private: {
                ...accessTypes.private,
                description: "Only selected members can access this space.",
            },
        });
    }, [parentName]);

    return (
        <CategorySelect
            options={options}
            value={published ? "public" : "private"}
            onChange={onChangeAccess}
        />
    );
}

AccessProfile.propTypes = {};
AccessProfile.defaultProps = {};

export default AccessProfile;
