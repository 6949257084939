import React, { useMemo, useState } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import { useData, useFormField } from "state/hooks";
import Field from "containers/Field";

import formTypes from "./Types";
import ComponentAttributes from "./ComponentAttributes";
import IconButton from "components/IconButton";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import {
    SectionContext,
    ComponentContext,
    ZmapSuggest,
    ComponentMappingProvider,
    useComponentMapping,
} from "playground/designer/Zmap";
import ZmapField from "./ZmapField";
import Icon from "components/Icon";
import Popover from "components/Popover";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import HyperscriptEditor from "./ComponentForm/HyperscriptEditor";
import FormContext from "./ComponentForm/FormContext";
import Tabs from "./ComponentForm/Tabs";

import "./style.css";

function ZmapEdit(props) {
    const { value, onChange } = props;
    const [state, setState] = useState("");

    const aliases = useMemo(() => {
        return value?.split(",")?.filter((p) => p.indexOf("@") === 0) || [];
    }, [value]);

    const add = (next) => {
        console.log("add", next, value, state, aliases);
        if (aliases.includes(next)) {
            setState("");
            return;
        }
        onChange([value, next].filter((v) => !!v).join(","));
        setState("");
    };

    const remove = (v) => {
        onChange(
            value
                .split(",")
                .filter((p) => !!p && p !== v)
                .join(",")
        );
    };

    return (
        <div className={"grow"}>
            <ZmapSuggest value={state} onChange={setState} onAccept={add} />
            <div className={"zmap-aliases cols"}>
                {aliases.map((a) => {
                    return (
                        <div
                            className={"zmap-alias-pill"}
                            key={a}
                            onClick={() => remove(a)}
                        >
                            {a}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function ComponentZmap(props) {
    const { tab, onToggle } = props;
    const mapping = useComponentMapping();
    const [stateShowVisible, setShowVisible] = useState(false);
    const showVisibleProp =
        mapping?.fields?.visible ||
        stateShowVisible ||
        Object.keys(mapping?.result || {}).includes("visible");
    const { value, onChange } = useFormField("template_id");
    const [showInput, setShowInput] = useState(false);
    const fieldsCount = mapping?.zmap?.length;
    const aliases = useMemo(() => {
        return value?.split(",")?.filter((p) => p.indexOf("@") === 0) || [];
    }, [value]);
    return (
        <>
            {/*<pre>{JSON.stringify(mapping, null, 2)}</pre>*/}
            {/*<div>Component mapping add if</div>*/}
            <div className={"cols cols-spread cols-middle"}>
                <div
                    onClick={() => setShowInput(!showInput)}
                    className={"cursor-pointer"}
                >
                    {!fieldsCount ? (
                        <div className={"component-no-mapping"}>
                            Brak mapowania
                        </div>
                    ) : (
                        <div>Mapowanie ({fieldsCount})</div>
                    )}
                </div>
                {!showInput && <div>{aliases.join(", ")}</div>}
                <div>
                    <Popover
                        trigger={({ onClick }) => (
                            <IconButton
                                icon={"mui-more_vert"}
                                size={"small"}
                                onClick={onClick}
                            />
                        )}
                        content={({ onClose }) => (
                            <SelectList onClick={onClose}>
                                <SelectListItem
                                    onClick={() => setShowInput(!showInput)}
                                >
                                    Add mapping
                                </SelectListItem>
                                <SelectListItem
                                    onClick={() => {
                                        onToggle();
                                        setShowInput(true);
                                    }}
                                >
                                    Edit zmap manually
                                </SelectListItem>
                                <SelectListItem
                                    onClick={() =>
                                        setShowVisible(!showVisibleProp)
                                    }
                                >
                                    Show visible prop
                                </SelectListItem>
                                {value && (
                                    <SelectListItem
                                        onClick={() => onChange(null)}
                                    >
                                        Remove all mapping
                                    </SelectListItem>
                                )}
                            </SelectList>
                        )}
                    />
                </div>
            </div>
            {showInput && (
                <div className={"cols cols-top zmap-input-container"}>
                    <Field
                        id={"template_id"}
                        label={null}
                        FieldComponent={tab === "zmap" ? null : ZmapEdit}
                    />
                </div>
            )}
            {showVisibleProp && <VisibleProp />}
        </>
    );
}

function ComponentForm(props) {
    const { type, id, tab, onChangeTab } = props;
    const { element_type } = useData(props);

    const FormComponent = formTypes[element_type || type] || formTypes.default;
    const zmapFields = useComponentMapping();

    const toggleZmap = () =>
        onChangeTab(tab === "zmap" ? "properties" : "zmap");

    return (
        <FormContext type={type} id={id}>
            <div className={"rows gap-xs"}>
                <Tabs value={tab} onChange={onChangeTab} />

                {FormComponent &&
                    (["properties", "zmap"].includes(tab) || !tab) && (
                        <>
                            <FormComponent {...props}>
                                <ComponentZmap
                                    tab={tab}
                                    onToggle={toggleZmap}
                                />
                            </FormComponent>
                        </>
                    )}
                {tab === "attributes" && <ComponentAttributes />}
                {tab === "hyperscript" && <HyperscriptEditor />}
            </div>
        </FormContext>
    );
}

function VisibleProp(props) {
    const FieldComponent = (props) => (
        <div className={"input-dense"}>Render if</div>
    );
    return <ZmapField id={"visible"} FieldComponent={FieldComponent} />;
}

export function ComponentEditor(props) {
    const { state, setState, onClose } = props;
    const { focusType: type, focusId: id } = state;
    const data = useData({ type, id });
    const isDraft = useSelector((store) => selectIsDraft(store, type, id));
    const mapping = data.template_id;
    return (
        <SectionContext
            pageType={state.pageType}
            pageId={state.pageId}
            sectionId={state.section}
        >
            <ComponentContext type={type} id={id}>
                <ComponentMappingProvider type={type} id={id}>
                    <div className={"component-form-outer"}>
                        <div className={"close-popup-btn"} onClick={onClose}>
                            <Icon>mui-close</Icon>
                        </div>
                        <div
                            className={cn(
                                "component-form pad-sm overflow-scroll",
                                {
                                    [`component-form--${state.componentEditTab}`]: true,
                                }
                            )}
                        >
                            {/*<div className={"cols cols-spread cols-middle"}>*/}
                            {/*    <div>*/}
                            {/*        {isDraft ? `new ${type}` : `Edit ${type}`}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <ComponentForm
                                type={type}
                                id={id}
                                tab={state.componentEditTab}
                                onChangeTab={(tab) => {
                                    setState({ componentEditTab: tab });
                                }}
                            />
                            {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
                        </div>
                    </div>
                </ComponentMappingProvider>
            </ComponentContext>
        </SectionContext>
    );
}

ComponentEditor.propTypes = {};

ComponentEditor.defaultProps = {};

export default ComponentEditor;
