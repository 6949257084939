import React from "react";
import cn from "classnames";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

import {
    MessageBodyBubble,
    MessageFooterCompact,
    MessageHeaderCompact,
} from "joynt/components/messages/message/subcomponents";

import "./style.css";

export function ChatMessage(props) {
    const {
        addReactions,
        avatar,
        className,
        content,
        createdAt,
        files,
        isDeleted,
        isOwn,
        link,
        messageClick,
        notificationStatus,
        onHide,
        reactions,
        id,
    } = props;
    return (
        <article
            className={cn(
                "message",
                { "own-message": isOwn },
                `message--chat`,
                className
            )}
        >
            <MessageHeaderCompact {...{ avatar, className, isOwn }} />
            <MessageBodyBubble
                {...{
                    addReactions,
                    content,
                    files,
                    id,
                    isDeleted,
                    isOwn,
                    link,
                    messageClick,
                    onHide,
                }}
            />
            <MessageFooterCompact
                {...{ notificationStatus, reactions, isOwn, createdAt }}
            />
        </article>
    );
}

ChatMessage.propTypes = MessagePropTypes;
