import { memoize } from "lodash";
import moment from "moment";
import { useCurrentUser, useData, usePending } from "state/hooks";

const nullArr = [];

export const getMoment = memoize((time) => {
    if (!time) return moment();
    return moment.unix(time);
});

export function useMessage(id) {
    const data = useData({ type: "db.messages", id });
    const {
        content,
        created_by,
        created_at,
        is_deleted,
        media,
        identity,
        link,
    } = data;
    const user = useCurrentUser();
    const pending = usePending(id);
    return {
        identity: identity,
        content,
        created_by: created_by || user,
        created_at: getMoment(created_at),
        media: media || nullArr,
        link,
        pending,
        is_deleted,
        isOwn: created_by === user,
    };
}
