import React from "react";
import cn from "classnames";

//import PropTypes from 'prop-types';

export function SelectListItem(props) {
    const { children, label, onClick, subtitle, icon, isSelected } = props;
    return (
        <div
            className={cn("select-list-item", {
                "is-selected": isSelected,
            })}
            onClick={onClick}
        >
            {icon && <div className={"select-list-item-icon"}>{icon}</div>}
            <div>
                <div>{label || children}</div>
                {subtitle && (
                    <div className={"select-list-item-subtitle"}>
                        {subtitle}
                    </div>
                )}
            </div>
        </div>
    );
}

SelectListItem.propTypes = {};

SelectListItem.defaultProps = {};

export default SelectListItem;
