import { useRouteParam, useUiFlag } from "state/hooks";
import { FLAG_UTILITY_NAV, ROUTE_PAGE, ROUTE_UTILITY_NAV } from "joynt/config";
import { useCallback, useMemo } from "react";
import useScreenSize from "util/hooks/useScreenSize";

function useUtilityNavImpl(isMobile) {
    const route = useRouteParam(ROUTE_UTILITY_NAV);
    const state = useUiFlag(FLAG_UTILITY_NAV);

    if (isMobile) return route;
    return state;
}

export default function useUtilityNav() {
    const { isMobile } = useScreenSize();
    const [view, setView] = useUtilityNavImpl(isMobile);
    const [, setPage] = useRouteParam(ROUTE_PAGE);
    const show = useCallback(
        (id) => {
            if (id === "invite") {
                setView(null);
                setPage("invite");
                return;
            }
            setView(id);
        },
        [setView, setPage]
    );
    return useMemo(() => {
        return [view, show];
    }, [view, show]);
}
