import React from 'react';
import {CardSkeleton} from "joynt/components/Card";
//import PropTypes from 'prop-types';

export function SpacesSkeleton(props) {
    return <>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
    </>;
}

SpacesSkeleton.propTypes = {};
SpacesSkeleton.defaultProps = {};
