import React from 'react';
//import PropTypes from 'prop-types';

import PopoverOverlay from "joynt/components/PopoverOverlay";
import {NodeOverlayContext} from "./hooks/useNodePopover";
import NodeInfo from "./NodeInfo.container";

export function NodeInfoOverlay(props) {

    return (<PopoverOverlay
        {...props}
        Context={NodeOverlayContext}
        Component={NodeInfo}
    />);

}

NodeInfoOverlay.propTypes = {};
NodeInfoOverlay.defaultProps = {};

export default NodeInfoOverlay;
