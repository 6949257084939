import React, {useMemo} from 'react';
import MenuItem from "components/context-menu/MenuItem";
import {POST_TYPE_ALBUM, POST_TYPE_LINK, POST_TYPE_POLL, POST_TYPE_TEXT, POST_TYPE_WHITEBOARD} from "joynt/config";
import {postTypes} from "joynt/config/types";
import ContextMenu from "components/ContextMenu";
//import PropTypes from 'prop-types';

const allTypes = [
    {
        id: POST_TYPE_ALBUM,
        label: 'Attach files'
    },
    {
        id: POST_TYPE_LINK,
        label: 'Share link'
    },
    {
        id: POST_TYPE_TEXT,
        label: 'Create post'
    },
    {
        id: POST_TYPE_POLL,
        label: 'Create a poll'
    },
    {
        id: POST_TYPE_WHITEBOARD,
        label: 'Create whiteboard'
    }
]

export default function CreateContextMenu(props) {

    const {onCreate, types, children} = props;

    const allowedTypes = useMemo(() => {
        if (!types.length) return allTypes;
        return allTypes.filter(type => types.indexOf(type.id) > -1);
    }, [types]);

    const createContextMenuItems = allowedTypes.map(type => {
        return <MenuItem
            onClick={()=>onCreate(null, type.id, true)}
            primaryText={type.label}
            icon={postTypes[type.id].icon} />
    });

    return <ContextMenu items={createContextMenuItems}>
        {children}
    </ContextMenu>

}

CreateContextMenu.propTypes = {};
CreateContextMenu.defaultProps = {
    types: []
};
