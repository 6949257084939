import React, { useCallback } from "react";
import Button from "components/Button";
import { useRouteParam } from "state/hooks";
import { ROUTE_VIEW } from "joynt/config";
import Icon from "components/Icon";
import DailyCalendar from "joynt/components/Calendar/DailyCalendar";
import { useRootNode } from "joynt/hooks/channels";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";

//import PropTypes from 'prop-types';

export function Calendar(props) {
    const root = useRootNode();

    const [, setView] = useRouteParam(ROUTE_VIEW);
    const [, setUtilityView] = useUtilityNav();

    const show = useCallback(() => {
        setUtilityView(null);
        setView("calendar");
    }, [setView, setUtilityView]);

    return (
        <section className={"pad-5"}>
            <DailyCalendar id={root} />
            <Button
                onClick={show}
                variant={"text"}
                endIcon={<Icon>mui-arrow_forward</Icon>}
            >
                Show full calendar
            </Button>
        </section>
    );
}

Calendar.propTypes = {};
Calendar.defaultProps = {};

export default Calendar;
