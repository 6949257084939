import React from "react";
import IdentityStep1 from "joynt/welcome/IdentityStep1";
import IdentityStep2 from "joynt/welcome/IdentityStep2";
import FormActions from "joynt/components/Form/FormActions";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export function ProfileSettings(props) {
    const { onSubmit, onReject, isValid, isDraft, isPending } = props;

    return (
        <div className={"rows"}>
            <div className={"rows gap-md"}>
                <div className={"rows gap-sm"}>
                    <IdentityStep1 />
                </div>
                <div className={"rows gap-sm"}>
                    <IdentityStep2 />
                </div>
                <FormActions
                    onSubmit={onSubmit}
                    onReject={onReject}
                    isValid={isValid}
                    isDraft={isDraft}
                />
            </div>
            <Preloader visible={isPending} />
        </div>
    );
}

ProfileSettings.propTypes = {};
ProfileSettings.defaultProps = {};

export default ProfileSettings;
