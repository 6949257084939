import React from 'react';

//import PropTypes from 'prop-types';
import "./style.css";

export function StageOverlay(props) {

    const {children} = props;

    return (<div className={"flow-stage-overlay dark-mode"}>
        {children}
    </div>);

}

StageOverlay.propTypes = {};
StageOverlay.defaultProps = {};

export default StageOverlay;
