import React from 'react';
//import PropTypes from 'prop-types';

import {useNodeData} from "joynt/hooks";
import {useOverflowToggle} from "./hooks/useOverflowToggle";
import SessionInfoDate from "./SessionInfoDate";
import SessionText from "./SessionText";
import Speakers from "./Speakers";
import ToggleButton from "./ToggleButton";
import ResponsiveDialog from "components/ResponsiveDialog";
import SessionConfirmActions
from "joynt/components/SessionParticipation/SessionConfirmActions/SessionConfirmActions.container";
import {useSessionParticipants} from "joynt/hooks/sessions";
import {ParticipantsCount} from "joynt/components/SessionParticipation";
import AvatarGroup from "joynt/components/AvatarGroup/AvatarGroup.container";
import Participants from "joynt/components/SessionPage/SessionSummary/Participants";

function DescriptionModal(props) {
    const {id, open, onClose} = props;
    return (<ResponsiveDialog open={open} onClose={onClose}>
        <div className={"session-description-modal"}>
            <SessionDescription id={id} truncate={false} />
        </div>
    </ResponsiveDialog>)
}

export function SessionDescription(props) {

    const { id, truncate, showAttendance } = props;

    const {
        event_start: start,
        event_end: finish,
        name,
        event_speakers: speakers,
    } = useNodeData(id);

    const {
        ref,
        hasMoreContent,
        showContent,
        onToggle
    } = useOverflowToggle();

    const displayMoreButton = truncate && (hasMoreContent || showContent);
    const isTruncated = truncate && (!showContent || truncate === "modal");

    return (<>
        {truncate === "modal" && <DescriptionModal
            id={id}
            open={showContent}
            onClose={onToggle}
        />}

        <div className={"session-description"}>
            {name && (
                <h2 className={"session-description__name"}>
                    {name}
                </h2>
            )}
            {start && (
                <SessionInfoDate
                    start={start * 1000}
                    finish={finish * 1000}
                />
            )}
            {speakers?.length > 0 && (
                <Speakers
                    className={"session-description__speakers"}
                    value={speakers}
                />
            )}
            <SessionText
                id={id}
                ref={ref}
                isTruncated={isTruncated}
            />
            <div
                className={
                    "session-description__footer"
                }
            >
                {showAttendance && <Participants
                    id={id}
                />}
                {displayMoreButton && (
                    <ToggleButton on={!isTruncated} onClick={onToggle} />
                )}
            </div>
        </div>
    </>);

}

SessionDescription.propTypes = {};
SessionDescription.defaultProps = {};

export default SessionDescription;