import React from "react";
import { FormLayout } from "playground/cms/Form";
import ItemFormContent from "./ItemFormContent";
import ItemFormLink from "playground/cms/views/Sections/SectionForm/ContentCollection/ItemFormLink";
import { DebugIconButton } from "playground/debug";

//import PropTypes from 'prop-types';

export function ItemForm(props) {
    const { type, schema } = props;
    return (
        <FormLayout {...props}>
            <FormLayout.Header>
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                {type === "cms.items" && (
                    <FormLayout.Tab id={"content"} label={"Treść"}>
                        <ItemFormContent {...props} />
                    </FormLayout.Tab>
                )}
                <FormLayout.Tab id={"link"} label={"Odnośnik"}>
                    <ItemFormLink {...props} />
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                <DebugIconButton type={props.type} id={props.id} />
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

ItemForm.propTypes = {};

ItemForm.defaultProps = {};

export default ItemForm;
