import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import DaysNav from "./DaysNav";

function startOfDay(timestamp) {
    let date = new Date(timestamp*1000);
    date.setHours(0,0,0,0);
    return date.getTime()/1000;
}

export function DaysNavContainer(props) {

    const {
        count,
        value,
        onChange
    } = props;

    const days = useMemo(() => {
        return Object.keys(count).map(ts => startOfDay(ts));
    }, [count]);

    useEffect(() => {
        if (days.length && days.indexOf(value) === -1) onChange(days[0]);
    }, [days, value, onChange]);

    return (<DaysNav
        {...props}
        days={days}
    />);

}

DaysNavContainer.propTypes = {
    id: PropTypes.string
};
DaysNavContainer.defaultProps = {
    count: {}
};

export default DaysNavContainer;