import React, {useCallback} from 'react';
import UserSettingsDrawer from "./UserSettingsDrawer";
import {useActions, useRouteParam} from "state/hooks";
import {ROUTE_IDENTITY_EDIT} from "joynt/config";
import {reject} from "state/actions/data";
//import PropTypes from 'prop-types';

export function UserSettingsDrawerContainer(props) {

    const [id, setId] = useRouteParam(ROUTE_IDENTITY_EDIT);
    const {onReject} = useActions({onReject: reject});

    const close = useCallback(() => {
        onReject('db.identities', id);
        setId(null);
    }, [setId, onReject, id]);

    return (<UserSettingsDrawer
        {...props}
        onClose={close}
        id={id}
        isOpen={!!id}
    />);

}

UserSettingsDrawerContainer.propTypes = {};
UserSettingsDrawerContainer.defaultProps = {};

export default UserSettingsDrawerContainer;
