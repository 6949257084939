import React from "react";
import { useData } from "state/hooks";
import cn from "classnames";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function LayoutDropdown(props) {
    const { onClick, isDefault } = props;
    const { title, description } = useData(props);
    return (
        <div
            className={cn("page-layout-dropdown", { "is-default": isDefault })}
            onClick={onClick}
        >
            <div>{title || description}</div>
            <Icon>mui-arrow_drop_down</Icon>
        </div>
    );
}

LayoutDropdown.propTypes = {};

LayoutDropdown.defaultProps = {};

export default LayoutDropdown;
