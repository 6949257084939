import React from 'react';
// import PropTypes from 'prop-types';

import "./style.css";
import Breadcrumbs from "./Breadcrumbs";
import PublicPageToggle from "./PublicPageToggle";

export function HeaderNav(props) {

    const {id} = props;

    return (<div className={"session-page-header-nav"}>
        <Breadcrumbs id={id} />
        <PublicPageToggle id={id} />
    </div>);

}

HeaderNav.propTypes = {};

HeaderNav.defaultProps = {};

export default HeaderNav;