import React from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import cn from "classnames";

import "./style.css";

export function OverlayForm(props) {
    const { onClose, children, open, position, width, fullHeight, zIndex } =
        props;
    const className = cn(
        "overlay-form",
        {
            "is-open": open,
            [width]: !!width,
            "full-height": fullHeight,
        },
        position
    );
    return (
        <>
            {createPortal(
                <div className={className} style={zIndex ? { zIndex } : null}>
                    <div className={"backdrop"} onClick={onClose} />
                    <div className={"overlay-form-body rows overflow-hide"}>
                        {children}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
}

OverlayForm.propTypes = {
    position: PropTypes.oneOf(["left", "right", "center"]),
    width: PropTypes.oneOf(["sm", "md", "lg", "xl", "wide"]),
    fullHeight: PropTypes.bool,
};

OverlayForm.defaultProps = {
    position: "right",
    width: "lg",
    fullHeight: true,
};

export default OverlayForm;
