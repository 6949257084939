import {useEffect, useRef} from "react";

export default function useSizeObserver({ callback, element }) {

    const observer = useRef(null);

    useEffect(() => {
        const e = element?.current;

        // if we are already observing old element
        if (observer && observer.current && e) {
            observer.current.unobserve(e);
        }
        const resizeObserverOrPolyfill = ResizeObserver;
        observer.current = new resizeObserverOrPolyfill(callback);

        if (e) {
            observer.current.observe(e);
        }

        return () => {
            if (observer && observer.current && e) {
                observer.current.unobserve(e);
            }
        };
    }, [callback, element]);

};