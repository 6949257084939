import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import EmptyState from "./EmptyState";

import "./style.css";

export function AvatarGroup(props) {
    const { label, showEmptyState, children, isEmpty, exceedingAvatars, size } =
        props;

    return (
        <div className={cn("avatar-group", size && `avatar-group--${size}`)}>
            {!isEmpty && (
                <>
                    {children}
                    {exceedingAvatars > 0 && (
                        <div
                            className={
                                "avatar-group__avatar avatar-group__exceeding-number"
                            }
                        >
                            +{exceedingAvatars}
                        </div>
                    )}
                </>
            )}
            {isEmpty && showEmptyState && <EmptyState />}
            {label && <div className={"avatar-group__label"}>{label}</div>}
        </div>
    );
}

AvatarGroup.propTypes = {
    showEmptyState: PropTypes.bool,
    maxSize: PropTypes.number,
};
AvatarGroup.defaultProps = {
    showEmptyState: false,
    maxSize: 10,
};

export default AvatarGroup;
