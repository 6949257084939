import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import RangeNav from "./RangeNav";

function rangeMod(day, mode, mod) {
    const date = new Date(day*1000);
    if (mode === "day") {
        date.setDate(date.getDate()+mod);
    }
    if (mode === "month") {
        date.setMonth(date.getMonth()+mod);
    }
    if (mode === "week") {
        date.setDate(date.getDate()+(mod*7));
    }
    return date.getTime()/1000;
}

export function RangeNavContainer(props) {

    const {
        mode,
        day,
        onChange
    } = props;

    const today = (new Date());
    const dayDate = new Date(day*1000);
    today.setHours(dayDate.getHours(),0,0, 0);
    const todayTs = today.getTime()/1000;

    const onToday = useCallback(() => {
        onChange(todayTs);
    }, [onChange, todayTs]);

    const onPrev = useCallback(() => {
        onChange(rangeMod(day, mode, -1));
    }, [onChange, day, mode]);

    const onNext = useCallback(() => {
        onChange(rangeMod(day, mode, 1));
    }, [onChange, day, mode]);

    return (<RangeNav
        {...props}
        onToday={onToday}
        onPrev={onPrev}
        onNext={onNext}
    />);

}

RangeNavContainer.propTypes = {
    id: PropTypes.string,
    mode: PropTypes.string
};
RangeNavContainer.defaultProps = {
    mode: "day"
};

export default RangeNavContainer;