import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { useNodeData } from "joynt/hooks";
import Fetch from "containers/Fetch";
import DashboardTile from "joynt/components/DashboardTile";
import SessionControls from "joynt/components/SessionControls";
import { SessionDate } from "joynt/components/TimeUtils";
import Preloader from "components/Preloader";

import "./style.css";
import { useEditPage } from "joynt/hooks/channels";
import IconButton from "components/IconButton";
import Timer from "joynt/components/Timer";

const stateNames = {
    pending: "Up next",
    live: "Live now",
    ended: "Ended",
    default: "Up next",
};

export function StageTile(props) {
    const { id, isLive, isPending, className, showTimer, showControls } = props;

    const {
        name,
        event_started: started,
        event_start: start,
        event_end: end,
        event_state,
        can_edit,
    } = useNodeData(id);

    const onEdit = useEditPage();
    const edit = useCallback(() => onEdit(id), [onEdit, id]);
    const hasEnded = event_state === "ended";

    const stateName = stateNames[event_state] || stateNames.default;

    return (
        <Fetch type={"db.nodes"} id={id} url={`v2/joynt/nodes/:id`}>
            <DashboardTile
                className={cn(className, "stage-tile brand-bg-alt", {
                    "is-live": isLive,
                    "dark-mode": isLive,
                })}
            >
                <div className={"rows gap-md"}>
                    <div className={"rows gap-xs"}>
                        <div className={"stage-tile__status"}>
                            {isLive ? "Live now" : stateName}
                        </div>
                        <div className={"stage-tile__content"}>
                            <div className={"stage-tile__hours"}>
                                <SessionDate start={start} end={end} />
                            </div>
                            <div className={"stage-tile__name"}>{name}</div>
                        </div>
                    </div>
                    {showTimer && !hasEnded && (
                        <div className={"stage-tile__timer"}>
                            <Timer
                                start={isLive ? started : start}
                                humanTimeThreshold={7200}
                                countdown={!isLive}
                            />
                        </div>
                    )}
                    {showControls && (
                        <div className={"cols cols-center"}>
                            <SessionControls id={id} size={"medium"} />
                        </div>
                    )}
                    {can_edit && (
                        <div className={"stage-tile__edit"}>
                            <IconButton
                                icon={"mui-settings"}
                                onClick={edit}
                                size={"small"}
                            />
                        </div>
                    )}
                </div>
                <Preloader visible={isPending} />
            </DashboardTile>
        </Fetch>
    );
}

StageTile.propTypes = {
    isLive: PropTypes.bool,
    isPending: PropTypes.bool,
    showTimer: PropTypes.bool,
    showControls: PropTypes.bool,
};
StageTile.defaultProps = {
    isLive: false,
    isPending: false,
    showTimer: false,
    showControls: true,
};

export default StageTile;
