import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";

export function AppLayout(props) {
    const {
        children,
        sidebarNav,
        utilityNav,
        showSidebar,
        showUtilityNav,
        isStageView,
    } = props;

    const className = cn("joynt-layout", {
        "show-sidebar": showSidebar,
        "show-utility-nav": showUtilityNav,
        "stage-view": isStageView,
    });

    return (
        <div className={className}>
            {sidebarNav}
            <div className={"main"}>
                <div className={"content"}>{children || null}</div>
            </div>
            {utilityNav}
        </div>
    );
}

AppLayout.propTypes = {
    showSidebar: PropTypes.bool,
    showUtilityNav: PropTypes.bool,
    isStageView: PropTypes.bool,
};
AppLayout.defaultProps = {
    showSidebar: false,
    showUtilityNav: false,
    isStageView: false,
};

export default AppLayout;
