import React, { useCallback } from "react";
import { useChannels } from "joynt/hooks/channels";
import { types, workspaceTypes } from "joynt/config/types";
import { useFeatureEnabled, useNodeData } from "joynt/hooks";
import CategorySelect from "joynt/edit/CategorySelect";
import FieldGroup from "joynt/components/Form/FieldGroup/FieldGroup";
import { FEATURE_DEV } from "joynt/config/features";
import { WORKSPACE_PLAN_DEFAULT } from "joynt/config/plans";

//import PropTypes from 'prop-types';

function useNodeChildTypes(id) {
    const { subtype, workspace, workspace_plan } = useNodeData(id);
    const type = workspace ? workspaceTypes[subtype] : types[subtype];
    const childTypes = workspace
        ? type.childTypesPlans?.[workspace_plan] ||
          type.childTypesPlans?.[WORKSPACE_PLAN_DEFAULT] ||
          []
        : type.childTypes || [];
    const suggested = childTypes.map((id) => types[id]);
    const suggestedIds = suggested.map((t) => t.id);
    const other = Object.values(types).filter((t) => {
        return suggestedIds.indexOf(t.id) === -1;
    });

    return { suggested, other };
}

export function CreateSpace(props) {
    const { id } = props;
    const list = `db.nodes.${id}.channels`;

    const { suggested, other } = useNodeChildTypes(id);
    const { onCreate } = useChannels(id, list);

    const createType = useCallback(
        (type) => {
            onCreate({ subtype: type });
        },
        [onCreate]
    );

    const isDev = useFeatureEnabled(FEATURE_DEV);

    return (
        <div className={"rows gap-md"}>
            <CategorySelect options={suggested} onChange={createType} />
            {isDev && (
                <FieldGroup label={"Show more types"} collapsible={true}>
                    <CategorySelect options={other} onChange={createType} />
                </FieldGroup>
            )}
        </div>
    );
}

CreateSpace.propTypes = {};
CreateSpace.defaultProps = {};

export default CreateSpace;
