import React from "react";
import {
    useActions,
    useApiActions,
    useData,
    useFetch,
    useList,
    usePending,
} from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { save } from "cms/state/actions/cms";
import Button from "components/Button";
import Preloader from "components/Preloader";
import { change, create } from "state/actions/data";
import { entityDelete } from "state/actions/delete";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

function Translation(props) {
    const { type, id, onDelete } = props;
    const data = useData(props);
    const { onChange } = useActions({ onChange: change });
    return (
        <AggregateRoot type={type} id={id} disableSubmit={true}>
            <div className={"cols cols-middle gap-sm"}>
                <Field id={"key"} label={"Key"} />
                <Field id={"value"} label={"Value"} />
                {/*<div*/}
                {/*    onClick={() =>*/}
                {/*        onChange(type, id, {*/}
                {/*            translatable_meta: {*/}
                {/*                value: {*/}
                {/*                    all: "in all languages",*/}
                {/*                },*/}
                {/*            },*/}
                {/*        })*/}
                {/*    }*/}
                {/*>*/}
                {/*    Change*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Field id={"active"} fieldType={"checkbox"} />*/}
                {/*</div>*/}
                <IconButton onClick={onDelete} icon={"mui-delete"} />
            </div>
        </AggregateRoot>
    );
}

Translation.defaultProps = {
    type: "cms.translations",
};

export function Translations(props) {
    const { id: type } = props;
    useFetch({
        url: type,
        type: type,
        list: type,
    });
    const { items } = useList(type, type);
    const { onSubmit, onRemove } = useApiActions({
        onSubmit: save,
        onRemove: entityDelete,
    });
    const pending = usePending([[type, type].join("/"), "cms.save"]);
    const { onCreate } = useActions({ onCreate: create });
    const handleCreate = () => {
        onCreate(type, {}, type);
    };
    const handleDelete = (id) => {
        onRemove(type, id, type, [type.replace(/\./, "/"), id].join("/"));
    };
    return (
        <div className={"settings-view"}>
            <Preloader visible={pending} />
            <div className={"settings-view-content"}>
                <div className={"rows gap-xs"}>
                    {items.map((item) => (
                        <Translation
                            type={type}
                            key={item}
                            id={item}
                            onDelete={() => handleDelete(item)}
                        />
                    ))}
                </div>
            </div>
            <div className={"settings-view-footer"}>
                <Button onClick={handleCreate} size={"large"} color={"default"}>
                    Dodaj
                </Button>
                <Button onClick={onSubmit} size={"large"}>
                    Zapisz
                </Button>
            </div>
        </div>
    );
}

Translations.propTypes = {};

Translations.defaultProps = {};

export default Translations;
