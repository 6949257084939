import {useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

export function Hours({start, end, format}) {

    const startMoment = useMemo(() => moment.unix(start), [start]);
    const endMoment = useMemo(() => moment.unix(end), [end]);

    const values = [];
    if (start) values.push(startMoment.format(format));
    if (end) values.push(endMoment.format(format));

    return values.join(' - ');
}

Hours.propTypes = {
    format: PropTypes.string
};

Hours.defaultProps = {
    format: 'H:mm'
};

export default Hours;
