import React from 'react';
import {connect} from 'react-redux';
import ApiContext from "containers/context/ApiContext";
import Fetch from "containers/Fetch";
import {selectAppApiContext} from "state/selectors/app";

const mapStateToProps = (store, props) => {
    return {
        url: `cms/data?raw=true&order=tree_index%20ASC&entry_type=rooms&data_source=nfhotel&checkin=2020-05-20&checkout=2020-05-22&&limit=6&api_key=alamakota`,
        apiContext: selectAppApiContext(store, {service: 'a73e4c94-b2dc-11e9-911e-0242ac170006'})
    }
};

const mapDispatchToProps = {};

class BookingSearch extends React.PureComponent {

    render() {
        const { url, apiContext } = this.props;
        return (<ApiContext.Provider value={apiContext}>
            {JSON.stringify(apiContext)}
            <Fetch
                type={'cms.entries.offers'}
                list={'booking-search'}
                url={url}
            />
            <div>BookingSearch {url}</div>
        </ApiContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingSearch);