import React from "react";

import { useSelector } from "react-redux";
import { selectEntity, selectEntityData } from "state/selectors/data";
import Field from "containers/Field";
import Collection from "playground/cms/fields/Collection";
import {
    selectExtendedFieldsIds,
    selectSchemaExtensions,
} from "state/selectors/schema";
import AggregateRoot from "containers/AggregateRoot";
import ItemFormLink from "playground/cms/views/Sections/SectionForm/ContentCollection/ItemFormLink";
import evaluateSourceTemplate from "components/fields/select/evaluateSourceTemplate";
import EnumValues from "playground/cms/fields/EnumValues";
//import PropTypes from 'prop-types';

function SchemaForm(props) {
    const { schemaId, type, id } = props;
    const schema = useSelector((store) => {
        return selectEntityData(store, "db.fields", schemaId);
    });
    const fields = schema?.fields || [];
    return (
        <>
            <AggregateRoot type={type} id={id} disableSubmit={true}>
                <ItemFormLink type={type} id={id}>
                    {fields.map((field) => {
                        return (
                            <SchemaField
                                key={field}
                                id={field}
                                fieldEntityType={type}
                                fieldEntityId={id}
                            />
                        );
                    })}
                </ItemFormLink>
            </AggregateRoot>
        </>
    );
}

function RenderCollection(props) {
    return <Collection {...props} />;
}

function CollectionField(props) {
    return (
        <Field
            {...props}
            CollectionRenderer={RenderCollection}
            FormComponent={SchemaForm}
        />
    );
}
function DefaultField(props) {
    return <Field {...props} />;
}

function evaluateSource(store, source, type, id) {
    const data = selectEntity(store, type, id);
    return evaluateSourceTemplate(source, data);
}

function isEnumSelect(source) {
    return source.indexOf("enum-definitions/") > -1;
}

function enumTypeFromSource(source) {
    return source.split("/")[1];
}

function SelectField(props) {
    const { source, fieldEntityType: type, fieldEntityId: id } = props;
    const evaledSource = useSelector((store) => {
        return evaluateSource(store, source, type, id);
    });
    if (isEnumSelect(evaledSource)) {
        return (
            <Field
                {...props}
                enumType={enumTypeFromSource(evaledSource)}
                FieldComponent={EnumValues}
            />
        );
    }
    return <Field {...props} source={evaledSource} />;
}

const fieldTypes = {
    collection: CollectionField,
    select: SelectField,
    default: DefaultField,
};

function getItemType(schema) {
    return ["cms", schema.slug].join(".");
}

function SchemaField(props) {
    const { id } = props;
    const schema = useSelector((store) => {
        return selectEntityData(store, "db.fields", id);
    });
    if (!schema?.id) return <div>No field definition</div>;
    const FieldComponent = fieldTypes[schema.type] || fieldTypes.default;
    return (
        <div>
            <FieldComponent
                {...props}
                id={schema.slug}
                label={schema.label}
                fieldType={schema.type}
                schemaId={schema.id}
                itemType={getItemType(schema)}
                {...(schema.properties || {})}
            />
        </div>
    );
}

export function ExtendedFields(props) {
    const { type, id } = props;
    const fields = useSelector((store) =>
        selectExtendedFieldsIds(store, type, id)
    );

    //const extensions = useSelector((store) => selectSchemaExtensions(store));

    return (
        <div className={"rows gap-sm"}>
            {fields?.map((fieldId) => (
                <SchemaField
                    key={fieldId}
                    id={fieldId}
                    fieldEntityType={type}
                    fieldEntityId={id}
                />
            ))}
        </div>
    );
}

ExtendedFields.propTypes = {};

ExtendedFields.defaultProps = {};

export default ExtendedFields;
