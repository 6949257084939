import {useCallback} from 'react';
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
//import PropTypes from 'prop-types';

export function useEditPage(param = ROUTE_PAGE) {

    const [,setEdit] = useRouteParam(param);

    return useCallback(id => {
        if (!id) {
            setEdit(null);
            return;
        }
        setEdit(param === ROUTE_PAGE ? `edit.${id}` : id);
    }, [setEdit, param]);

}

useEditPage.propTypes = {};
useEditPage.defaultProps = {};
