import React from "react";
//import PropTypes from 'prop-types';

import { IdentityBoxContainer } from "joynt/components/IdentityBox";
import SessionConfirmActions from "./SessionConfirmActions";
import { useSessionParticipants } from "joynt/hooks/sessions";

export function SessionParticipation(props) {
    const { id } = props;
    const { identities } = useSessionParticipants(id);

    return (
        <div className={"rows gap-md"}>
            <div>Attendees</div>
            <div className={"roles"}>
                {identities.map((identity) => {
                    let p = { id: identity, role: "confirmed" };
                    return <IdentityBoxContainer {...p} />;
                })}
            </div>
            <SessionConfirmActions id={id} />
        </div>
    );
}

SessionParticipation.propTypes = {};
SessionParticipation.defaultProps = {};

export default SessionParticipation;
