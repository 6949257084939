import { takeEvery, select, fork, put } from "redux-saga/effects";
import {
    selectAliasedEntity,
    selectEntity,
    selectList,
} from "state/selectors/data";
import { loadWebfont } from "util/theme/font-loader";
import hexRgb from "hex-rgb";
import hexHsl from "hex-to-hsl";
import less from "less";
import { notify, pending, setFlag } from "state/actions/ui";
import { change, storeOne } from "state/actions/data";
import { error } from "util/saga/feedback";
import {
    convertPresetToLessVars,
    responsivePreviewBreakpoints,
} from "util/theme";
import { selectRouteParam } from "state/selectors/router";
import { listAppend } from "state/actions/list";

function hexRgbStr(hex) {
    const rgb = hexRgb(hex, { format: "array" });
    return rgb.slice(0, 3).join(",");
}

function hexHsStr(hex) {
    const hsl = hexHsl(hex);
    hsl[1] = hsl[1] + "%";
    return hsl.slice(0, 2).join(",");
}

function setDocumentCssVariables(variables, target) {
    if (!target) return;
    if (target === true) target = document.documentElement;
    for (let k in variables) {
        let varName = "--" + k;
        let value = variables[k];
        target.style.setProperty(varName, value);
    }
}

function* handleApplyPreset(action) {
    try {
        const { id, target, loadFonts, dark } = action.payload;
        const preset = yield select((store) =>
            selectEntity(store, "console.presets", id)
        );

        let vars = {};
        let mapping = dark ? "dark" : preset.mapping;

        vars["primary-color"] = preset.color_scheme.primary_color;
        vars["success-color"] = preset.color_scheme.success_color;
        vars["dark-color"] = preset.color_scheme.dark_color;
        vars["default-color"] =
            mapping === "dark"
                ? preset.color_scheme.dark_color
                : preset.color_scheme.light_color;
        vars["invert-color"] =
            mapping === "dark"
                ? preset.color_scheme.light_color
                : preset.color_scheme.dark_color;

        vars["primary-color-rgb"] = hexRgbStr(vars["primary-color"]);
        vars["success-color-rgb"] = hexRgbStr(vars["success-color"]);

        vars["primary-color-hs"] = hexHsStr(vars["primary-color"]);
        vars["success-color-hs"] = hexHsStr(vars["success-color"]);

        const fontFamilies = ["heading_font", "body_font", "decorative_font"];

        if (loadFonts && preset.typography) {
            let typo = preset.typography || {};
            fontFamilies.forEach((key) => {
                if (typo[key]) {
                    let varName = key.replace(/_/g, "-") + "-family";
                    vars[varName] = typo[key].family;
                    loadWebfont(typo[key]);
                }
            });
        }

        setDocumentCssVariables(vars, target);
    } catch (e) {
        console.log(e);
    }
}

function* handleLoadWebfont({ payload }) {
    try {
        const { family, source } = payload;
        yield loadWebfont({ family, source });
    } catch (e) {
        console.log(e);
    }
}

function* renderLess(styles, options) {
    try {
        const result = yield less.render(styles, options);
        const containerId = "less:static-less-style";
        console.log(result);
        yield put(
            storeOne("theme.files", "_compiled", { content: result.css })
        );
        //yield put(change("theme.files", "_compiled", { content: result.css }));
        yield put(listAppend("theme.files", "default", "_compiled"));
        // let container = document.getElementById(containerId);
        // if (!container) {
        //     container = document.createElement("style");
        //     container.setAttribute("id", containerId);
        //     container.setAttribute("type", "text/css");
        //     document
        //         .getElementById("theme-shadow-root")
        //         .shadowRoot.appendChild(container);
        // }
        // if (result.css) {
        //     container.textContent = result.css;
        // }
        yield put(pending("less-render", false));
    } catch (e) {
        console.log(e);
        yield error(e);
        yield put(pending("less-render", false));
    }
}

function matchLessImports(themeFiles, entrypoints) {
    const importRegex = /^(?!\/\*)@import\s+"([^"]*)";/gm;
    const paths = [];
    entrypoints.forEach((entrypoint) => {
        const contents = themeFiles[entrypoint]?.content;
        if (!contents) return;
        let match;
        while ((match = importRegex.exec(contents))) {
            let path = match[1];
            if (!path.includes(".less")) path += ".less";
            if (!paths.includes(path)) paths.push(path);
        }
        paths.push(entrypoint);
    });
    return paths;
}

function stripImports(contents) {
    return contents.replace(/@import[^;]*;/g, "");
}

function* handleCompileStyles({ payload }) {
    try {
        const { themeId } = payload;

        yield put(pending("less-render", true));
        const allFiles = yield select((store) =>
            selectList(store, "theme.files", "default")
        );

        const lessFilesByTheme = allFiles
            .filter((file) => file.id.includes("assets/less"))
            .reduce((acc, file) => {
                const [theme, path] = file.id.split("/assets/less/");
                if (!acc[theme]) acc[theme] = {};
                acc[theme][path] = file;
                return acc;
            }, {});

        const entrypoints = ["imports.less", "custom.less"];

        const themeSlugs = yield select((store) => {
            const theme = selectEntity(store, "console.themes", themeId);
            const parent = selectAliasedEntity(
                store,
                "console.themes",
                theme.parent
            );
            return [parent.slug, theme.slug];
        });

        const prefixedSlugs = themeSlugs.map((slug) => `themes/${slug}`);

        const byPath = {};

        prefixedSlugs.forEach((theme) => {
            const themeFiles = lessFilesByTheme[theme];
            if (!themeFiles) return;
            const imports = matchLessImports(themeFiles, entrypoints);
            imports.forEach((path) => {
                const content = themeFiles[path]?.content;
                if (!content) return;
                byPath[path] = stripImports(content);
            });
        });

        const contents = Object.values(byPath);

        const framework = allFiles.find((file) => file.id === "framework");
        const allStyles = [framework.content, ...contents];
        const stylesText = allStyles.join("\r\n\r\n");

        const theme = yield select((store) => {
            return selectEntity(store, "console.themes", themeId);
        });

        const preset = yield select((store) =>
            selectEntity(store, "console.presets", theme.preset)
        );
        const device = yield select((store) =>
            selectRouteParam(store, "device", "desktop")
        );
        const vars = {
            ...convertPresetToLessVars(preset),
            ...responsivePreviewBreakpoints(device),
        };

        console.log(vars);

        const options = {
            modifyVars: vars,
        };
        yield fork(renderLess, stylesText, options);
    } catch (e) {
        console.log(e);
        yield error(e);
        yield put(pending("less-render", false));
    }
}

function* handleImportColorScheme({ payload }) {
    try {
        const type = "console.presets";
        const { from, to, typeTo } = payload;
        const fromData = yield select((store) =>
            selectEntity(store, type, from)
        );
        const update = { color_scheme: fromData.color_scheme };
        yield put(change(typeTo || type, to, update));
    } catch (e) {
        console.log(e);
    }
}

function* handleImportTypography({ payload }) {
    try {
        const type = "console.presets";
        const { from, to, typeTo } = payload;
        const fromData = yield select((store) =>
            selectEntity(store, type, from)
        );
        const update = { typography: fromData.typography };
        yield put(change(typeTo || type, to, update));
    } catch (e) {
        console.log(e);
    }
}

function* handleFileSearch({ payload }) {
    try {
        const { query, cb } = payload;
        const files = yield select((store) =>
            selectList(store, "theme.files", "default")
        );
        const candidateFiles = files.filter((f) => f.id.includes(".less"));
        const result = candidateFiles.filter((f) => f.content.includes(query));

        const withInfo = result.map((f) => {
            const lines = f.content.split("\n");
            const line = lines.find((l) => l.includes(query));
            const index = lines.indexOf(line);
            return {
                id: f.id,
                line: index + 1,
                index: line.indexOf(query),
                context: line,
            };
        });

        if (cb) {
            cb(result);
            if (!result.length)
                yield put(notify(`${query} not found in files.`));
        }

        yield put(
            setFlag("theme.fileManager.search", { query, result: withInfo })
        );
    } catch (e) {}
}

export default function* () {
    yield takeEvery("THEME.APPLY_PRESET", handleApplyPreset);
    yield takeEvery("THEME.LOAD_WEBFONT", handleLoadWebfont);
    yield takeEvery("THEME.COMPILE_STYLES", handleCompileStyles);
    yield takeEvery("THEME.IMPORT_COLOR_SCHEME", handleImportColorScheme);
    yield takeEvery("THEME.IMPORT_TYPOGRAPHY", handleImportTypography);
    yield takeEvery("THEME.FILES.SEARCH", handleFileSearch);
}
