import {useMemo} from 'react';
import moment from "moment";

//import PropTypes from 'prop-types';

export function Duration({start, end}) {

    const m = useMemo(() => moment.duration(end-start, 'seconds'), [end, start]);
    return m.humanize();

}

Duration.propTypes = {};
Duration.defaultProps = {};

export default Duration;
