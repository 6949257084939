import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { FormLayout, FormSection } from "playground/cms/Form";
import ContentSelect from "./ContentSelect";
import ArrayTextField from "./ArrayTextField";

export function AdvancedSettings(props) {
    return (
        <div className={"rows gap-sm"}>
            <FormSection name={"Theme"} variant={"contained"}>
                <FormLayout.Fieldset>
                    <Field
                        id={"theme.parent"}
                        fieldType={"select"}
                        label={"Parent theme"}
                        source={"api:console.themes"}
                        nullable={true}
                    />
                    <div className={"cols gap-sm"}>
                        <Field
                            id={"options.theme_from"}
                            fieldType={"select"}
                            label={"Copy theme"}
                            source={"api:console.themes"}
                            nullable={true}
                        />
                        <ArrayTextField
                            id={"options.theme_copy"}
                            label={"Copy files"}
                        />
                    </div>
                    <Field
                        id={"website.library"}
                        fieldType={"select"}
                        label={"Library"}
                        source={"api:console.services?type=website"}
                        nullable={true}
                    />
                </FormLayout.Fieldset>
            </FormSection>
            <FormSection name={"Content"} variant={"contained"}>
                <div className={"cols gap-sm"}>
                    <Field
                        id={"options.content_from"}
                        fieldType={"select"}
                        label={"Content from"}
                        source={"api:console.services?type=cms"}
                        nullable={true}
                    />

                    <ContentSelect {...props} />
                </div>
                <Field
                    id={"options.content_cleanup"}
                    fieldType={"checkbox"}
                    label={"Remove unused sections"}
                />
            </FormSection>
        </div>
    );
}

AdvancedSettings.propTypes = {};

AdvancedSettings.defaultProps = {};

export default AdvancedSettings;
