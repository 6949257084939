import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (store, props) => {
    return {};
};

const mapDispatchToProps = {};

class ContextMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { open: false, anchorEl: null, id: null };
    //this.setState();
    }

  open = (evt, type, id) => {
      evt.preventDefault();
      evt.stopPropagation();
      this.setState({ open: true, anchorEl: evt.target, id });
  };

  close = (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      this.setState({ open: false, anchorEl: null, id: null });
  };

  render() {
      return this.props.children({
          ...this.props,
          ...this.state,
          onMenuOpen: this.open,
          onMenuClose: this.close,
      });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
