import React, { useCallback } from "react";
import Popper, { usePopper } from "joynt/components/Popper";
//TODO: replace with joynt/components/IconButton
import IconButton from "components/IconButton";
import cn from "classnames";
import EmojiPicker from "joynt/components/Reactions/EmojiPicker";
//import PropTypes from 'prop-types';

export default function AddReaction(props) {
    const { onSelect } = props;

    const { handlers, state, onHide } = usePopper();

    const add = useCallback(
        (reaction) => {
            onSelect(reaction);
            onHide();
        },
        [onHide, onSelect]
    );

    return (
        <div className={"add-reaction"}>
            <IconButton
                withTooltip
                size={"small"}
                {...handlers}
                icon={"mui-add_reaction"}
                label={"Add reaction"}
                className={cn(
                    { "is-open": state.open },
                    "add-reaction__button"
                )}
            />
            <Popper {...state} onHide={onHide}>
                <div className={"reactions-picker rows"}>
                    <EmojiPicker onSelect={add} />
                </div>
            </Popper>
        </div>
    );
}

AddReaction.propTypes = {};
AddReaction.defaultProps = {};
