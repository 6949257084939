import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import "./style.css";
import Icon from "components/Icon";

export function SidebarItem(props) {
    const {
        className,
        onClick,
        onMouseEnter,
        onMouseLeave,
        isActive,
        isHighlighted,
        hasNotifications,
        variant,
        icon,
        label,
        mentions,
        endIcon,
        editIcon,
        showArrow,
        header,
        children
    } = props;

    const hasContent = React.Children.count(children) > 0;

    return (
        <div
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn("sidebar-item", className, {
                [`sidebar-item--${variant}`]: true,
                "is-active": isActive,
                "is-highlighted": isHighlighted,
                "has-notifications": hasNotifications,
                "is-editable": !!editIcon,
                "has-content": React.Children.count(children) > 0
            })}
        >
            <div className={"sidebar-item__inner"}>
                {header && <div className={"sidebar-item__header"}>
                    {header}
                </div>}
                <div className={"sidebar-item__content"}>
                    {icon && (
                        <div className={"sidebar-item__icon"}>
                            {React.isValidElement(icon) ? (
                                icon
                            ) : (
                                <Icon>{icon}</Icon>
                            )}
                        </div>
                    )}
                    <div className={"sidebar-item__name"}>
                        <div className={"sidebar-item__label"}>{label}</div>
                    </div>
                    {showArrow && !isActive && (
                        <div className={"sidebar-item__end_icon"}>
                            <Icon>mui-chevron_right</Icon>
                        </div>
                    )}
                    {endIcon && (
                        <div className={"sidebar-item__end_icon"}>{endIcon}</div>
                    )}
                    {editIcon && (
                        <div className={"sidebar-item__edit_icon"}>{editIcon}</div>
                    )}
                    {mentions > 0 && (
                        <div className={"sidebar-item__badge"}>{mentions}</div>
                    )}
                </div>
                {hasContent && <div className={"sidebar-item__footer"}>
                    {children}
                </div>}
            </div>
        </div>
    );
}

SidebarItem.propTypes = {
    isActive: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    hasNotifications: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.node,
    variant: PropTypes.oneOf(["channel", "workspace", "pinned"]),
    mentions: PropTypes.number,
    endIcon: PropTypes.element,
    editIcon: PropTypes.element,
    showArrow: PropTypes.bool,
};

SidebarItem.defaultProps = {
    variant: "channel",
    isActive: false,
    isHighlighted: false,
    hasNotifications: false,
    showArrow: false,
    mentions: 0,
};

export default SidebarItem;
