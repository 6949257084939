import React from "react";
import { UserAccessContext } from "./UserAccessContext";
import { useCurrentUser, useData, useUiFlag } from "state/hooks";
import { SCOPE_MASTER } from "config/access-scopes";
import useLocalStorage from "util/hooks/useLocalStorage";

//import PropTypes from 'prop-types';

export function UserAccessProvider(props) {
    const {} = props;

    const user = useCurrentUser();
    const userData = useData({ type: "db.users", id: user });
    const [userMode, setUserMode] = useLocalStorage("cms.dev.userAccessMode");

    const hasScope = (scope) => {
        let userScopes = userData?.scopes || [];
        if (userMode) userScopes = [];
        if (userScopes.includes(SCOPE_MASTER)) return true;
        return userScopes.includes(scope);
    };

    const ctx = { hasScope, userMode, setUserMode };

    return (
        <UserAccessContext.Provider value={ctx}>
            {props.children}
        </UserAccessContext.Provider>
    );
}

UserAccessProvider.propTypes = {};

UserAccessProvider.defaultProps = {};

export default UserAccessProvider;
