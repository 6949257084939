import React from "react";
//import PropTypes from 'prop-types';

import Pages from "./../Pages";
import { FormLayout } from "playground/cms/Form";
import Form from "containers/Form";
import { ListLayout } from "playground/cms/List";
import { Search, Tags } from "playground/cms/List/Filter";

import {
    useHasProperties,
    PropertyFilter,
} from "playground/cms/views/Properties";

const fields = {
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    layout: false,
    type: false,
    title: false,
    slug: false,
};

function DefaultEntryForm(props) {
    const { type, id } = props;
    return (
        <FormLayout>
            <FormLayout.Content prepend={true}>
                <FormLayout.Tab id={"settings"} label={"Ustawienia"}>
                    <div className={"rows pad-md gap-sm"}>
                        <Form type={type} id={id} selectFields={fields} />
                    </div>
                </FormLayout.Tab>
            </FormLayout.Content>
        </FormLayout>
    );
}

function DefaultEntryList(props) {
    const { type } = props;

    const hasProperties = useHasProperties();

    return (
        <ListLayout>
            <ListLayout.Filter>
                <div className={"rows gap-sm"}>
                    <Search type={type} name={"search"} />
                    {hasProperties && <PropertyFilter type={type} />}
                    <Tags type={type} name={"tags"} />
                </div>
            </ListLayout.Filter>
            <ListLayout.Header>
                <ListLayout.Header.Icons prepend={true}>
                    <ListLayout.Header.Filter />
                </ListLayout.Header.Icons>
            </ListLayout.Header>
        </ListLayout>
    );
}

export function Entries(props) {
    const { type, id, children } = props;
    const form = FormLayout.Children.findLayout(children);
    const list = ListLayout.Children.findLayout(children);
    return (
        <Pages {...props}>
            {list ? list : DefaultEntryList(props)}
            {form ? form : DefaultEntryForm(props)}
        </Pages>
    );
}

Entries.propTypes = {};

Entries.defaultProps = {};

export default Entries;
