import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";

export function CirclesLayout(props) {

    const {children, circles, isOpen} = props;

    const className = cn("circles-layout", {
        "is-open": isOpen
    })

    return (
        <div className={className}>
            <div className={"circles"}>
                {circles}
            </div>
            {children}
        </div>
    );

}

CirclesLayout.propTypes = {
    isOpen: PropTypes.bool
};
CirclesLayout.defaultProps = {
    isOpen: false
};

export default CirclesLayout;
