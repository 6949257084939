import React, { useRef } from "react";
import PropTypes from "prop-types";
import SessionWindow from "./SessionWindow";
import { useDraggable } from "./../hooks";
import { FloatingOverlay } from "joynt/components/SessionFlow/Decorators";
import { StageElement } from "../../Session/StageElement";
import MobileBar from "./MobileBar";
import useScreenSize from "util/hooks/useScreenSize";

export function SessionWindowContainer(props) {
    const ref = useRef(null);
    const { style, isDragging } = useDraggable(ref);
    const { isMobile } = useScreenSize();

    if (isMobile) return <FloatingOverlay {...props} Component={MobileBar} />;

    return (
        <SessionWindow {...props} ref={ref} style={style}>
            <FloatingOverlay {...props} isDragging={isDragging}>
                <StageElement className={"floating-video"} />
            </FloatingOverlay>
        </SessionWindow>
    );
}

SessionWindowContainer.propTypes = {
    id: PropTypes.string,
};
SessionWindowContainer.defaultProps = {};

export default SessionWindowContainer;
