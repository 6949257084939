import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import SitemapEntryItem from "./SitemapEntryItem";
import { Search } from "playground/cms/List/Filter";
import IconButton from "components/IconButton";
import SectionEntryItem from "./SectionEntryItem";

export function SitemapSearch(props) {
    const {
        pageId,
        sectionId,
        type,
        onSelect,
        isPending,
        items,
        sections,
        groups,
        onSetGroup,
        selectedGroup,
        onClose,
        onSetPage,
        page,
    } = props;
    return (
        <div className={"rows pad-sm gap-xs"}>
            <div className={"cols cols-middle"}>
                {onClose && (
                    <IconButton icon={"mui-arrow_back"} onClick={onClose} />
                )}
                <Search type={type} name={"search"} />
            </div>
            <div className={"cols"}>
                <div>
                    <div
                        className={cn("filter-tag-item", {
                            "is-active": !selectedGroup,
                        })}
                        onClick={() => onSetGroup(null)}
                    >
                        <div className={"filter-tag-item-label"}>Wszystkie</div>
                    </div>
                    {groups.map((group) => (
                        <div
                            className={cn("filter-tag-item", {
                                "is-active": selectedGroup === group.id,
                            })}
                            key={group.id}
                            onClick={() => onSetGroup(group.id)}
                        >
                            <div className={"filter-tag-item-label"}>
                                {group.id}
                            </div>
                            <div className={"filter-tag-item-count"}>
                                {group.items.length}
                            </div>
                        </div>
                    ))}
                </div>
                {!page && (
                    <SelectList>
                        {isPending && <div>Pending...</div>}
                        {items.map((item) => (
                            <SitemapEntryItem
                                key={item}
                                onClick={() => onSetPage(item)}
                                type={"cms.sitemap"}
                                id={item}
                                isSelected={pageId === item}
                            />
                        ))}
                    </SelectList>
                )}
                {page && (
                    <SelectList>
                        <SelectListItem onClick={() => onSetPage(null)}>
                            Powrót
                        </SelectListItem>
                        <SitemapEntryItem
                            onClick={() => onSelect(page)}
                            type={"cms.sitemap"}
                            id={page}
                            isSelected={pageId === page && !sectionId}
                        />
                        {sections.map((item) => (
                            <SectionEntryItem
                                type={"cms.sitemap.sections"}
                                id={item}
                                key={item}
                                onClick={() => onSelect(page, item)}
                                isSelected={sectionId === item}
                            />
                        ))}
                    </SelectList>
                )}
            </div>
        </div>
    );
}

SitemapSearch.propTypes = {
    items: PropTypes.array,
    isPending: PropTypes.bool,
};

SitemapSearch.defaultProps = {
    items: [],
    sections: [],
    groups: [],
};

export default SitemapSearch;
