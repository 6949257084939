import React from "react";
import DebugConsoleOverlay from "./DebugConsoleOverlay";
import { useUiFlag } from "state/hooks";
import DebugContext from "playground/debug/DebugContext";

//import PropTypes from 'prop-types';

export function DebugConsoleProvider(props) {
    const {} = props;

    const [path, setPath] = useUiFlag("debug.path");
    const onClose = () => setPath(null);

    return (
        <DebugContext.Provider value={setPath}>
            {props.children}

            <DebugConsoleOverlay path={path} onClose={onClose} />
        </DebugContext.Provider>
    );
}

DebugConsoleProvider.propTypes = {};

DebugConsoleProvider.defaultProps = {};

export default DebugConsoleProvider;
