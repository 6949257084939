import React from "react";
import { Helmet } from "react-helmet";

import { Layout } from "./Layout";
import DragLayer from "components/dnd/DragLayer";
import CommandPalette from "./Command";

import views from "./views";

import "./style.css";
import "./mui.css";
import "./list.css";
import "./form.css";

import { ClipboardContextProvider } from "playground/cms/Clipboard";
import { UserAccessProvider } from "playground/console/UserAccess";

export default function CmsLayout(props) {
    return (
        <>
            <Helmet defer={false}>
                <title>zuu.cms v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc&amp;libraries=places"></script>
                <body className={"theme-light default cms-theme"} />
            </Helmet>
            <ClipboardContextProvider>
                <UserAccessProvider>
                    <Layout views={views} />
                    <CommandPalette />
                    <DragLayer />
                </UserAccessProvider>
            </ClipboardContextProvider>
        </>
    );
}
