import React from 'react';
import PropTypes from 'prop-types';

import {Hours} from "joynt/components/TimeUtils";

import "./style.css";
import Icon from "components/Icon";
import {LiveNowBadge} from "joynt/components/LiveNow";

export function SessionItem(props) {

    const {
        name,
        onClick,
        isLive,
        start,
        end
    } = props;

    return (<div className={"track-session-item"} onClick={onClick}>
        <div className={"track-session-item__icon"}>
            <Icon>mui-schedule</Icon>
        </div>
        <div className={"track-session-item__content"}>
            <div className={"track-session-item__time"}>
                {isLive && <LiveNowBadge animate={true}/>}
                <div className={"track-session-item__hours"}>
                    <Hours start={start} end={end} />
                </div>
            </div>
            <div className={"track-session-item__name"}>
                {name}
            </div>
        </div>
    </div>);

}

SessionItem.propTypes = {
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    onClick: PropTypes.func,
    isLive: PropTypes.bool
};
SessionItem.defaultProps = {
    isLive: false
};

export default SessionItem;
