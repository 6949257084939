import React, {useEffect, useRef} from 'react';
import cn from "classnames";
import PropTypes from "prop-types";

import {useMoment} from "./../hooks";

//import PropTypes from 'prop-types';

export function HourSelectMenuItem(props) {
    const {
        value,
        format,
        onClick,
        isSelected
    } = props;

    const valueMoment = useMoment(value);
    const click = () => onClick(value);
    const ref = useRef();

    useEffect(() => {
        if (isSelected && ref.current) ref.current.scrollIntoView();
    }, [isSelected]);

    const className = cn("select-menu-item", {
        "is-selected": isSelected
    });

    return <div ref={ref} onClick={click} className={className}>
        {valueMoment.format(format)}
    </div>;
}

HourSelectMenuItem.propTypes = {
    isSelected: PropTypes.bool
}

HourSelectMenuItem.defaultProps = {
    isSelected: false
}

export default HourSelectMenuItem;
