import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SessionBrowser from "./SessionBrowser";
import {useSessionSearch} from "joynt/components/SessionBrowser/hooks";
import {getDaysInRange, selectRange} from "joynt/components/DailySchedule/util";

export function SessionBrowserContainer(props) {

    const {id} = props;

    const now = (new Date()).getTime()/1000;

    const [day, setDay] = useState(now);
    const [mode, setMode] = useState('day');
    const [search, setSearch] = useState(null);

    const {
        list,
        count,
        isPending
    } = useSessionSearch({
        id,
        day,
        mode,
        search
    });

    const range = selectRange(new Date(day*1000), mode);
    const sessionDays = Object.keys(count).map(d => d*1);
    const days = getDaysInRange(range)
        .map(d => d.getTime()/1000)
        .filter(d => sessionDays.indexOf(d) > -1);

    return (<SessionBrowser
        {...props}
        day={day}
        mode={mode}
        search={search}
        days={days}
        count={count}
        isPending={isPending}
        onChangeDay={setDay}
        onChangeMode={setMode}
        onSearch={setSearch}
        list={list}
    />);

}

SessionBrowserContainer.propTypes = {
    id: PropTypes.string
};
SessionBrowserContainer.defaultProps = {};

export default SessionBrowserContainer;