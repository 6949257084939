import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import ContactsForm from "./ContactsForm";

export function Projects(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"contact"} label={"Dane kontaktu"}>
                        <ContactsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
