import WitnessLeaveSession from "./../WitnessLeaveSession";

export const MODAL_WITNESS_LEAVE = "witness-leave";

export default {
    [MODAL_WITNESS_LEAVE]: {
        title: "Please tell us why did you leave the session?",
        type: "modal",
        component: WitnessLeaveSession
    }
}