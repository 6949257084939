import React from 'react';
import {connect} from 'react-redux';
import {CircularProgress} from "@material-ui/core";

const mapStateToProps = (store, props) => {
    return {
        pending: store.pending['schema.db/schema.db'] !== false
    }
};

const mapDispatchToProps = {};

class SchemaNotAvailable extends React.PureComponent {

    render() {
        const { pending, type, path } = this.props;
        return (<div className={'cols cols-center pad-md'}>
            { pending
                ? <CircularProgress color={'inherit'} size={24} />
                : <div>
                    <h4>{`${type} schema not available`}</h4>
                    <div className={'list-item-title-placeholder'}>{path}</div>
                </div>}
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchemaNotAvailable);