import React from "react";

import { EditorState } from "draft-js";
import BlockTypePopover from "./BlockTypePopover";
import NewBlockTypeCaret from "./NewBlockTypeCaret";
import { createToDoBlock } from "./../../Blocks/ToDo";
import { applyBlockType, insertCustomAtomicBlock } from "./../../util";

import { POST_TYPE_ALBUM, POST_TYPE_LINK } from "joynt/config";

const SLASH_REGEX = /\/[A-Za-z]*$/g;

function slashStrategy(contentBlock, callback) {
    findWithRegex(SLASH_REGEX, contentBlock, callback);
}

function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

function triggerReRender(editor) {
    editor.setEditorState(
        EditorState.set(editor.getEditorState(), {
            currentContent: editor.getEditorState().getCurrentContent(),
        })
    );
}

/**
 * @param blocks
 * @param config
 * @returns {{onBlur: onBlur, decorators: [{component: (function(*)), strategy: slashStrategy}], BlockTypeSelect: (function(*)), initialize: initialize, handleReturn: ((function(): (string))|*), onFocus: onFocus}}
 */
export function createBlockTypesPlugin(blocks, config) {
    const store = {
        data: {
            editor: {},
            show: false,
            anchor: null,
        },
        update: function (data) {
            this.data = { ...this.data, ...data };
            triggerReRender(this.editor);
        },
    };

    const hide = () => store.update({ show: false });

    function handleApplyBlockType(blockTypeId, e) {
        const createBlock = (data = {}) => {
            store.editor.setEditorState(
                applyBlockType(
                    blocks,
                    store.editor.getEditorState(),
                    blockTypeId,
                    e?.shiftKey,
                    data
                )
            );
        };
        const types = {
            "joynt-link": POST_TYPE_LINK,
            "joynt-files": POST_TYPE_ALBUM,
        };
        if (types[blockTypeId]) {
            config.onCreateContent({ subtype: types[blockTypeId] }, (data) =>
                createBlock({ post: data.id })
            );
            return;
        }
        createBlock();
    }

    function handleCreateFilesBlock(editorState, files) {
        const createBlock = (data) => {
            store.editor.setEditorState(
                insertCustomAtomicBlock(editorState, "joynt-files", data)
            );
        };
        config.onCreateContent(
            { subtype: POST_TYPE_ALBUM, media: [], files },
            (data) => createBlock({ post: data.id })
        );
    }

    function CaretDecorator(props) {
        return <NewBlockTypeCaret {...props} store={store} />;
    }

    function BlockTypePopoverDecorator(props) {
        return (
            <BlockTypePopover
                {...props}
                {...store.data}
                items={blocks.data}
                onHide={hide}
                onApplyBlockType={handleApplyBlockType}
            />
        );
    }

    return {
        initialize: (editorFns) => {
            store.editor = editorFns;
        },
        keyBindingFn: (e) => {
            if (store.data.show) {
                /** Block propagation of up down arrows which are captured
                 *  by block type selection list
                 */
                if ([38, 40].indexOf(e.keyCode) > -1) {
                    return true;
                }
            }
            return undefined;
        },
        handleDroppedFiles: (s, files) => {
            let next = EditorState.forceSelection(
                store.editor.getEditorState(),
                s
            );
            let anchorKey = s.getAnchorKey();
            let contentState = store.editor
                .getEditorState()
                .getCurrentContent();
            let b = store.editor
                .getEditorState()
                .getCurrentContent()
                .getBlockForKey(anchorKey);
            if (b.getType() === "atomic") {
                let data = contentState.getEntity(b.getEntityAt(0)).getData();
                if (data.type === "joynt-files") {
                    console.log("upload to", data);
                    config.onUpload(files, data.post);
                    return;
                }
            }
            handleCreateFilesBlock(next, files);
        },
        handlePastedFiles: (files) => {
            handleCreateFilesBlock(store.editor.getEditorState(), files);
        },
        handleReturn: () => {
            if (store.data.show) {
                /**
                 * Capture Return key when block type selection is visible
                 * preventing default editor behaviour (inserting new block)
                 * **/
                return "handled";
            }
            return "unhandled";
        },
        handleBeforeInput: (str) => {
            if (createToDoBlock(str, store.editor)) {
                return "handled";
            }
            return "unhandled";
        },
        onFocus: () => {
            if (store.data.anchor) store.update({ show: true });
        },
        onBlur: () => {
            store.update({ show: false });
        },
        decorators: [
            {
                strategy: slashStrategy,
                component: CaretDecorator,
            },
        ],
        BlockTypeSelect: BlockTypePopoverDecorator,
    };
}

export default createBlockTypesPlugin;
