import {useCallback, useEffect, useRef, useState} from "react";

export function useMouseActive(timeout = 1000, initial = false) {
    const [active, setActive] = useState(initial);
    let tm = useRef();
    const enableActive = useCallback(() => {
        if (tm.current) clearTimeout(tm.current);
        setActive(true);
        tm.current = setTimeout(()=>setActive(false), timeout);
    }, [tm, timeout, setActive]);
    useEffect(() => {
        window.addEventListener('mousemove', enableActive);
        return () => {
            if (tm.current) clearTimeout(tm.current);
            window.removeEventListener('mousemove', enableActive);
        }
    }, [enableActive, tm]);

    return {active, setActive};
}