import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";

export function FormActions(props) {
    const { onSubmit, onCancel } = props;

    return (
        <div className={"form-actions"}>
            <Button color={"default"} onClick={onCancel}>
                Anuluj
            </Button>
            <Button onClick={onSubmit}>Zapisz</Button>
        </div>
    );
}

FormActions.propTypes = {};

FormActions.defaultProps = {};

export default FormActions;
