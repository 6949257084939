import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useApiActions } from "state/hooks";
import SearchInput from "./SearchInput/SearchInput";
import RolesList from "joynt/components/Roles/RolesList";
import { updateRolesCallback } from "joynt/state/actions/members";
import Button from "components/Button";
import useFetch from "state/hooks/useFetch";
import Preloader from "components/Preloader";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { useEdges, useFeatureEnabled, useNode, useNodeData } from "joynt/hooks";
import { serializeQueryParams } from "util/uri";
import { useFetchNodeRoles, useRoleEdit } from "joynt/components/Roles/hooks";
import NodeRoles from "joynt/components/Roles/NodeRoles";
import { FEATURE_WORKSPACE_ROLES } from "joynt/config/features";

function ToggleInheritance({ id }) {
    const { parent } = useEdges(id);
    const { name: parentName } = useNodeData(parent);
    const label = `Inherit admins and collaborators from ${parentName}`;
    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
            <Field
                id={"public"}
                fieldType={"checkbox"}
                negate={false}
                label={label}
            />
        </AggregateRoot>
    );
}

export function Container(props) {
    const {
        type,
        id,
        defaultRole,
        onSubmit,
        isPending,
        scope,
        enableInheritance,
    } = props;

    const list = `node-roles.${id}`;

    const {
        onAddEmail,
        onAddRole,
        onRemoveRole,
        onRevertRole,
        onChangeRole,
    } = useRoleEdit(list, defaultRole);

    const enableWorkspaceRoles = useFeatureEnabled(FEATURE_WORKSPACE_ROLES);

    return (
        <div className={"rows"}>
            <Preloader visible={isPending} />
            <div className={"rows gap-md"}>
                {enableInheritance && <ToggleInheritance id={id} />}
                <SearchInput
                    scope={scope}
                    onAddEmail={onAddEmail}
                    onAddRole={onAddRole}
                    enableRoleSelection={!defaultRole}
                />
                <RolesList
                    id={id}
                    list={list}
                    scope={scope}
                    type={type}
                    groupBy={"workspaceGroups"}
                    onRemove={onRemoveRole}
                    onRevert={onRevertRole}
                    onChangeRole={onChangeRole}
                />
                {enableWorkspaceRoles && <NodeRoles id={id} />}
                {onSubmit && <Button onClick={onSubmit}>Submit roles</Button>}
            </div>
        </div>
    );
}

Container.defaultProps = {
    type: "app.node-roles",
    defaultRole: null,
    scope: "roles",
    isPending: false,
    enableInheritance: false,
};

Container.propTypes = {
    type: PropTypes.string,
    defaultRole: PropTypes.oneOf(["admin", "member", "guest", null]),
    scope: PropTypes.oneOf(["access", "event", "roles", "admins"]),
    enableInheritance: PropTypes.bool,
};

export default function ApiContainer(props) {
    const {
        id,
        shouldPublish,
        onSubmit: submitCallback,
        Component,
        defaultRole,
    } = props;

    const { public: inherit, workspace } = useNode(id);
    const { parent_workspace } = useEdges(id);

    const enableInheritance =
        !workspace || (!!parent_workspace && defaultRole !== "guest");

    const { onSubmit } = useApiActions({
        onSubmit: updateRolesCallback,
    });

    const submit = useCallback(() => {
        onSubmit(submitCallback, id, shouldPublish);
    }, [onSubmit, id, shouldPublish, submitCallback]);

    let url = `joynt/nodes/${id}/roles/list`;
    let query = {};

    if (enableInheritance) query.inherit = !!inherit;
    if (shouldPublish) query.publish = true;

    url += `?${serializeQueryParams(query)}`;

    const pending = useFetch({
        type: "app.node-roles",
        list: `node-roles.${id}`,
        url,
        fetchId: `node-roles.${id}`,
    });

    useFetchNodeRoles(id);

    return (
        <Component
            {...props}
            onSubmit={submit}
            isPending={pending}
            enableInheritance={enableInheritance}
        />
    );
}

ApiContainer.defaultProps = {
    type: "app.node-roles",
    defaultRole: null,
    shouldPublish: false,
    scope: "roles",
    Component: Container,
};

ApiContainer.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    shouldPublish: PropTypes.bool,
    defaultRole: PropTypes.oneOf(["admin", "member", "guest", null]),
    scope: PropTypes.oneOf(["access", "event", "roles", "admins"]),
};
