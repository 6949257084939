import React from 'react';
// import PropTypes from 'prop-types';
import cn from "classnames";

import SidebarItem from "joynt/components/Sidebar/SidebarNav/SidebarItem";
import {useMoment} from "joynt/components/DateTimePicker/hooks";

function Date({start}) {
    const startMoment = useMoment(start);
    const format = "DD MMMM";

    return <div className={"scheduled-session-item__date"}>{startMoment.format(format)}</div>;
}

export function Session(props) {

    const {start, icon, ...other} = props;

    return (<SidebarItem
        {...other}
        icon={start ? null : icon}
        className={cn("scheduled-session-item", {
            "is-scheduled": !!start
        })}
        header={start ? "Coming soon" : null}
    >
        {start && <>{icon} <Date start={start} /></>}
    </SidebarItem>);

}

Session.propTypes = {};

Session.defaultProps = {};

export default Session;