import { useFeatureEnabled } from "joynt/hooks";
import { FEATURE_OPTIMIZED_API } from "joynt/config/features";
import { useUiFlag } from "state/hooks";
import { useChannels } from "joynt/hooks/channels";
import { NODE_TYPE_EVENT } from "joynt/config";
import useFetch from "state/hooks/useFetch";
import { useCallback, useMemo } from "react";
import {
    applyMinuteInterval,
    createHourRange,
} from "joynt/components/DateTimePicker/util";
import { useSelector } from "react-redux";
import { selectSessionCountByDay } from "joynt/components/Schedule/selectors";
import { selectRange } from "joynt/components/DailySchedule/util";
import { appendQueryParams } from "util/uri";

function setHourToNow(day) {
    const date = new Date(day * 1000);
    date.setHours(new Date().getHours());
    date.setMinutes(new Date().getMinutes());
    applyMinuteInterval(date);
    return date.getTime() / 1000;
}

function rangeQuery(url, range) {
    return appendQueryParams(url, {
        start: range.start ? range.start.getTime() / 1000 : null,
        end: range.end ? range.end.getTime() / 1000 : null,
    });
}

export function useSchedule(id, type = "sessions") {
    const devApi = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const url = devApi
        ? `v2/joynt/nodes/${id}/${type}`
        : `joynt/nodes/${id}/${type}`;

    const list = `db.nodes.${type}.${id}`;

    const dayStateId =
        type === "schedule"
            ? `scheduleSelectedDay.${id}`
            : "scheduleSelectedDay";

    const [day, setDay] = useUiFlag(dayStateId);

    const range = selectRange(new Date(day * 1000), "month");
    const urlParams = rangeQuery(url, range);

    const { onCreate } = useChannels(id, list, NODE_TYPE_EVENT);

    const isPending = useFetch({
        type: "db.nodes",
        url: urlParams,
        list,
        enable: !!day,
    });

    const create = useCallback(() => {
        const range = createHourRange(setHourToNow(day), 1);
        onCreate({
            subtype: NODE_TYPE_EVENT,
            event_start: range[0],
            event_end: range[1],
            event_stage: true,
        });
    }, [onCreate, day]);

    const sessionCount = useSelector((s) => selectSessionCountByDay(s, list));

    return useMemo(
        () => ({
            selectedDay: day,
            onSelectDay: setDay,
            onCreate: create,
            count: sessionCount,
            list,
            isPending,
        }),
        [day, setDay, create, sessionCount, list, isPending]
    );
}
