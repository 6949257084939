import React from 'react';
//import PropTypes from 'prop-types';

import SpaceHeader from "joynt/components/SpaceHeader/SpaceHeader";

import DatePicker from "./DatePicker";
import DaySessions from "./DaySessions";
import EmptyState from "./EmptyState";

import Preloader from "components/Preloader";
import TextField from "components/fields/text/TextField";
import FilterToggle from "joynt/components/FilterToggle";
import RangeLabel from "joynt/components/Calendar/RangeLabel";
import RangeNav from "joynt/components/Calendar/RangeNav";

import "./style.css";

const modes = [
    {id: "day", label: "Day"},
    {id: "week", label: "Week"},
    {id: "month", label: "Month"}
];

export function SessionBrowser(props) {

    const {id} = props;

    const {
        list,
        day,
        mode,
        search,
        days,
        count,
        isPending,
        onChangeDay,
        onChangeMode,
        onSearch
    } = props;

    return (<div className={"session-browser"}>
        <SpaceHeader
            id={id}
            name={"Past sessions"}
        />
        <div className={"session-browser__layout"}>
            <div className={"session-browser__filter"}>
                <div className={"session-browser__range"}>
                    <RangeLabel
                        day={day}
                        mode={mode}
                    />
                </div>
                <TextField
                    onChange={onSearch}
                    value={search}
                    label={"Search"}
                    variant={"search"}
                />
                <DatePicker
                    value={day}
                    mode={mode}
                    count={count}
                    onChange={onChangeDay}
                />
            </div>
            <div className={"session-browser__results"}>
                <Preloader visible={isPending} />
                <div className={"session-browser__results_header"}>
                    <div>
                        <RangeNav
                            day={day}
                            mode={mode}
                            onChange={onChangeDay}
                        />
                    </div>
                    <div>
                        <FilterToggle
                            value={mode}
                            onChange={onChangeMode}
                            options={modes} />
                    </div>
                    <div />
                </div>
                <div className={"session-browser__list"}>
                    {days.length ? days.map(day => {
                        return <DaySessions list={list} day={day} />
                    }) : <EmptyState />}
                </div>
            </div>
        </div>
    </div>);

}

SessionBrowser.propTypes = {};
SessionBrowser.defaultProps = {};

export default SessionBrowser;
