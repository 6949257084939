import React from "react";
//import PropTypes from 'prop-types';

import types from "./types";

import ResponsiveDialog from "components/ResponsiveDialog";

export function RenderConfirm(props) {
    const { confirmType, isOpen, no } = props;

    const Component = types[confirmType] || types.default;

    return (
        <ResponsiveDialog open={isOpen} onClose={no}>
            <Component {...props} />
        </ResponsiveDialog>
    );
}

RenderConfirm.propTypes = {};
RenderConfirm.defaultProps = {};

export default RenderConfirm;
