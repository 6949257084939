import React from "react";
import PropTypes from "prop-types";

import IconButton from "joynt/components/IconButton";

export function SidebarSectionIcon(props) {
  return <IconButton {...props} size={"small"} />;
}

SidebarSectionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SidebarSectionIcon.defaultProps = {};

export default SidebarSectionIcon;
