import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TaskAssignees from "./TaskAssignees";
import { useEdges, useNodeData } from "joynt/hooks";
import Field from "containers/Field";
import AggregateRoot from "containers/AggregateRoot";

function RenderTaskAssignees(props) {
    const { node, value, onChange } = props;

    const { parent } = useEdges(node);
    const { name: parentName } = useNodeData(parent);

    const change = useCallback(
        (id) => {
            onChange(id ? [id] : []);
        },
        [onChange]
    );

    const clear = useCallback(
        (e) => {
            e.stopPropagation();
            onChange(null);
        },
        [onChange]
    );

    return (
        <TaskAssignees
            {...props}
            value={value}
            onChange={change}
            onClear={clear}
            id={parent}
            parentName={parentName}
        />
    );
}

export function TaskAssigneesContainer(props) {
    const { id, editable } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={editable}>
            <Field
                {...props}
                id={"task_assignees"}
                node={id}
                FieldComponent={RenderTaskAssignees}
            />
        </AggregateRoot>
    );
}

TaskAssigneesContainer.propTypes = {
    id: PropTypes.string,
};
TaskAssigneesContainer.defaultProps = {};

export default TaskAssigneesContainer;
