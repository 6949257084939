import React from "react";
import PropTypes from "prop-types";

import { IdentityAvatarContainer as IdentityAvatar } from "joynt/components/Avatar";

import "./style.css";

export function Avatar(props) {
    const { id, onClick, onMouseEnter, onMouseLeave, size } = props;

    return (
        <div
            className={"networking-table-avatar"}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <IdentityAvatar id={id} size={size || "medium"} />
        </div>
    );
}

Avatar.propTypes = {
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};
Avatar.defaultProps = {};

export default Avatar;
