import React, { useCallback, useState } from "react";
import Fetch from "containers/Fetch";
import {
    useActions,
    useApiActions,
    useClick,
    useData,
    usePending,
    useRouteParam,
} from "state/hooks";
import Preloader from "components/Preloader";
import Editor from "./Editor";
import {
    addPoint,
    deletePoint,
    cleanupShapes,
    clearShape,
    parsePoints,
    setPoints,
} from "plan-editor/util";
import { change } from "state/actions/data";
import { submitOne } from "state/actions/api";
import { useSelector } from "react-redux";
import IconButton from "components/IconButton";
import { selectEntityChanges } from "state/selectors/data";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "components/Icon";
import Checkbox from "components/fields/checkbox/Checkbox";
import PointsList from "./PointsList";
import Layers from "plan-editor/Layers";
import { pushRouteParams } from "state/actions/router";

function ResourceItem(props) {
    const { id, selected, shape } = props;
    const points = shape ? shape.points : [];
    const hasPoints = !!points[0];
    const { title } = useData(props);
    const isSelected = id === selected;
    const onClick = useClick(props);
    return (
        <ListItem button onClick={onClick} selected={isSelected}>
            <ListItemIcon>
                {hasPoints ? <Icon>mui-check</Icon> : null}
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );
}

export default function (props) {
    const { onClose } = props;
    const [id] = useRouteParam("id");

    const type = "cms.plans";
    const resourceType = "cms.plan-resources";

    const data = useData({ type: type, id });
    const pending = usePending(id);

    const { push, onChange } = useActions({
        onChange: change,
        push: pushRouteParams,
    });

    const goBack = useCallback(() => {
        if (onClose) return onClose();
        push({ view: null, resource: type, scope: null, id: null });
    }, [push, onClose, type]);

    const { onSave } = useApiActions({ onSave: submitOne });

    const [selected, selectResource] = useState(null);
    const [layer, selectLayer] = useState("default");
    const [scale, setScale] = useState(0.5);
    const [showLabels, setShowLabels] = useState(true);
    const [editOrder, setEditOrder] = useState(false);
    const [showLayers, setShowLayers] = useState(false);

    const image = data.image_url;
    const resources = data.resources_collection || [];

    const changes = useSelector((s) => selectEntityChanges(s, type, id));
    const layersData = changes.layers || data.layers || {};

    const layerData = layersData[layer] || {};
    const shapes = layerData.shapes || {};
    const shape = shapes[selected] || {};
    const layers = Object.keys(layersData);

    const onAddLayer = useCallback(
        (layerId) => {
            onChange(type, id, { layers: { ...layersData, [layerId]: {} } });
            selectLayer(layerId);
            setShowLayers(false);
        },
        [onChange, id, layersData]
    );

    const update = useCallback(
        (data) => {
            onChange(type, id, { layers: data.layers || {} });
        },
        [onChange, type, id]
    );

    const onDeletePoint = useCallback(
        (index) => {
            update(deletePoint(data, layer, selected, index));
        },
        [update, data, layer, selected]
    );

    const onAddPoint = useCallback(
        (x, y) => {
            update(addPoint(data, layer, selected, [x, y]));
        },
        [update, data, layer, selected]
    );

    const save = useCallback(() => {
        onSave(type, id);
    }, [onSave, type, id]);

    const cleanup = useCallback(() => {
        update(cleanupShapes(data));
    }, [update, data]);

    const clear = useCallback(() => {
        update(clearShape(data, layer, selected));
    }, [update, data, layer, selected]);

    const sort = useCallback(
        (points) => {
            update(setPoints(data, layer, selected, points));
        },
        [update, data, layer, selected]
    );

    const setImgWidthAndHeight = (width, height) => {
        onChange(type, id, {
            layers: {
                ...layersData,
                default: { ...layersData.default, width, height },
            },
        });
    };

    return (
        <>
            <Preloader visible={pending} />
            <div className={"rows h-100v overflow-hide"}>
                <div className={"cols gap-lg light-f10 pad-sm"}>
                    <div className={"cols gap-sm"}>
                        <IconButton
                            icon={"mui-arrow_back"}
                            label={"Go back"}
                            onClick={goBack}
                        />
                        <IconButton
                            icon={"mui-save"}
                            label={"Save"}
                            onClick={save}
                        />
                        <IconButton
                            icon={"mui-delete_sweep"}
                            label={"Clear redundand shapes"}
                            onClick={cleanup}
                        />
                    </div>
                    <div className={"cols gap-sm"}>
                        <Checkbox
                            onChange={() => setShowLabels(!showLabels)}
                            value={showLabels}
                            label={"Show labels"}
                        />
                        <IconButton
                            icon={"mui-zoom_in"}
                            label={"Zoom in"}
                            onClick={() => setScale(scale + 0.1)}
                        />
                        <IconButton
                            icon={"mui-zoom_out"}
                            label={"Zoom out"}
                            onClick={() => setScale(scale - 0.1)}
                        />
                    </div>
                    <div className={"cols gap-sm"}>
                        <IconButton
                            icon={"mui-layers"}
                            label={"Show layers"}
                            onClick={() => setShowLayers(!showLayers)}
                            checked={showLayers}
                        />
                        <IconButton
                            icon={"mui-clear"}
                            label={"Clear shape"}
                            onClick={() => clear()}
                            disabled={!selected}
                        />
                        <IconButton
                            icon={"mui-sort"}
                            label={"Points order"}
                            onClick={() => setEditOrder(!editOrder)}
                            checked={editOrder}
                            disabled={!selected}
                        />
                    </div>
                    <div className={"cols cols-right grow"}>
                        <IconButton
                            icon={"mui-close"}
                            label={"Go back"}
                            onClick={goBack}
                        />
                    </div>
                </div>
                <div className={"cols grow overflow-hide"}>
                    <div className={"rows overflow-scroll w-md"}>
                        {showLayers ? (
                            <Layers
                                items={layers}
                                onChange={selectLayer}
                                onAdd={onAddLayer}
                                value={layer}
                            />
                        ) : null}
                        {!showLayers && selected && editOrder ? (
                            <div className={"rows"}>
                                <PointsList
                                    points={parsePoints(shape.points)}
                                    sort={sort}
                                    onClose={() => setEditOrder(false)}
                                />
                            </div>
                        ) : null}
                        {!showLayers && !editOrder
                            ? resources.map((id) => {
                                  return (
                                      <ResourceItem
                                          key={id}
                                          type={resourceType}
                                          id={id}
                                          onClick={selectResource}
                                          shape={shapes[id]}
                                          selected={selected}
                                      />
                                  );
                              })
                            : null}
                    </div>
                    <div className={"rows dark"}>
                        {image ? (
                            <Editor
                                resourceType={resourceType}
                                filePreview={image}
                                shapes={shapes}
                                scale={scale}
                                showIndex={editOrder}
                                showLabels={showLabels}
                                setImgWidthAndHeight={setImgWidthAndHeight}
                                setImgPoints={onAddPoint}
                                selected={selected}
                                onCopyPoint={() => null}
                                onDeletePoint={onDeletePoint}
                                onSetScale={setScale}
                                onSelectShape={selectResource}
                            />
                        ) : (
                            <div className={"grow"}>select image</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
