import React from "react";

import WorkspaceHeader from "joynt/components/WorkspaceHeader";
import Button from "components/Button";
import SessionInfo from "../SessionInfo";
import scrollTo from "joynt/util/scrollTo";
import LoginSection from "./LoginSection";
import SessionPageLayout from "joynt/components/SessionPage/SessionPageLayout";
import DiscussionsAndMaterials from "./DiscussionsAndMaterials";

import "./style.css";

//import PropTypes from 'prop-types';

export function SessionPage(props) {
    const { id, onRegister, hasAccount, hasRole, hasAuth } = props;

    return (
        <SessionPageLayout
            hasAccount={hasAccount}
            id={id}
            header={<WorkspaceHeader id={id} />}
            content={
                <>
                    <div>
                        {!hasAuth && (
                            <div className={"session-page_join-banner"}>
                                Please register to attend this event{" "}
                                <Button
                                    variant={"outlined"}
                                    onClick={() => scrollTo("login-section")}
                                >
                                    Sign up
                                </Button>
                            </div>
                        )}
                        <SessionInfo id={id} showAttendance={false} />
                    </div>
                    {hasAccount && !hasRole && (
                        <div className={"pad-md cols cols-center"}>
                            <Button onClick={() => onRegister()} size={"large"}>
                                Join event
                            </Button>
                        </div>
                    )}
                </>
            }
        >
            {!hasAuth && (
                <>
                    <DiscussionsAndMaterials />
                    <div id={"login-section"}>
                        <LoginSection title={"Join our event now"}>
                            Sign up and setup your profile or log in if you have
                            an account.
                        </LoginSection>
                    </div>
                </>
            )}
        </SessionPageLayout>
    );
}

SessionPage.propTypes = {};
SessionPage.defaultProps = {};

export default SessionPage;
