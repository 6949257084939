import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";

import "./style.css";

export function PageActiveStatus(props) {
    const {} = props;
    const { active, archived } = useData(props);

    return (
        <div
            className={cn("page-status-bar", {
                visible: !active || archived,
            })}
        >
            {!active && <div>Not Active</div>}
            {archived && <div>Archived</div>}
        </div>
    );
}

PageActiveStatus.propTypes = {};

PageActiveStatus.defaultProps = {};

export default PageActiveStatus;
