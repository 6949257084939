import React, { useState } from "react";
//import PropTypes from 'prop-types';
import Form from "containers/Form";
import FormRenderContext from "containers/context/FormRenderContext";
import Popover from "@material-ui/core/Popover";
import Field from "containers/Field";
import Chip from "@material-ui/core/Chip";
import TextField from "components/fields/text/TextField";
import Checkbox from "components/fields/checkbox/Checkbox";

function RenderMapping(props) {
    return <EditMapping {...props} />;
}

function EditMapping({ value, onChange, mappedFieldId, mappedTo }) {
    const [showAll, setShowAll] = useState(false);
    let items = value ? value.split(",") : [];
    let matchedItem = null;
    items.forEach((m, i) => {
        if (m === mappedFieldId) matchedItem = i;
        if (m.indexOf(":" + mappedFieldId) > -1) matchedItem = i;
    });
    let matchedValue = items[matchedItem];
    let [from, to] = matchedValue ? items[matchedItem].split(":") : [];

    const add = () => {
        items.push(":" + mappedFieldId);
        onChange(items.join(","));
    };
    const remove = (i) => {
        delete items[i];
        onChange(items.filter((m) => !!m).join(","));
    };
    const change = (value) => {
        if (matchedItem === null) {
            items.push([value, mappedFieldId].join(":"));
        } else {
            items[matchedItem] = [value, to].join(":");
        }
        onChange(items.join(","));
    };

    return (
        <>
            <div>
                <TextField
                    onChange={change}
                    value={from}
                    variant={"dense"}
                    label={"from"}
                />
            </div>
            {items.length > 1 ? (
                <div>
                    <Checkbox
                        onChange={() => setShowAll(!showAll)}
                        label={"Show all mappings"}
                        value={showAll}
                        variant={"checkbox"}
                    />
                </div>
            ) : null}
            <div className={"rows gap-px"}>
                {items.map((m, i) => {
                    return showAll || matchedItem === i ? (
                        <div key={i}>
                            <Chip
                                size={"small"}
                                label={m}
                                onDelete={() => remove(i)}
                                color={
                                    matchedItem === i ? "primary" : "default"
                                }
                            />
                        </div>
                    ) : null;
                })}
            </div>
            {showAll ? (
                <div>
                    <div onClick={() => add()}>add</div>
                </div>
            ) : null}
        </>
    );
}

function FieldMapping(props) {
    const { type, id, value, fieldId } = props;

    const mappedTo = value[fieldId];

    return (
        <>
            <Field
                entityType={type}
                entityId={id}
                fieldType={"text"}
                id={"template_id"}
                mappedFieldId={fieldId}
                mappedTo={mappedTo}
            >
                {RenderMapping}
            </Field>
        </>
    );
}

export default class Edit extends React.PureComponent {
    render() {
        const { focusType, focusId, mapping, RenderField } = this.props;

        const { editMapping, anchorEl, onHideMappingEdit } = this.props;

        return (
            <div className={"form-compact pad-xs"}>
                <FormRenderContext.Provider value={RenderField}>
                    <Form type={focusType} id={focusId} />
                </FormRenderContext.Provider>
                <Popover
                    open={!!editMapping}
                    anchorEl={anchorEl}
                    onClose={onHideMappingEdit}
                >
                    <div className={"pad-sm rows gap-xs"}>
                        <FieldMapping
                            type={focusType}
                            id={focusId}
                            fieldId={editMapping}
                            value={mapping}
                        />
                    </div>
                </Popover>
            </div>
        );
    }
}

Edit.propTypes = {};
