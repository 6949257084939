import React from "react";

//import PropTypes from 'prop-types';

export function FormSection(props) {
    const { children, label } = props;
    return (
        <div className={"rows gap-xs"}>
            <div>{label}</div>
            {children}
        </div>
    );
}

FormSection.propTypes = {};

FormSection.defaultProps = {};

export default FormSection;
