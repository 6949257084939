import {useEdgesData} from "joynt/hooks";
import {useRouter} from "joynt/hooks/channels";
import {useCallback} from "react";
import {STAGE_VIEW} from "joynt/config";

export default function useSessionLink(id) {
    const {stage} = useEdgesData(id);
    const {onLink} = useRouter();

    const link = useCallback(() => {
        onLink(stage, null, STAGE_VIEW);
    }, [onLink, stage]);

    return {
        onLinkToSession: link
    }
}