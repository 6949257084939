import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Upload from "components/fields/media/Upload";
import Button from "components/Button";

//import PropTypes from 'prop-types';

function AvatarUpload(props) {
    const { src, pending } = props;
    return (
        <div className={"rows rows-center gap-xs cursor-pointer"}>
            <div
                className={
                    "avatar-lg circle rows rows-center rows-middle light"
                }
            >
                {pending ? (
                    <CircularProgress size={24} />
                ) : (
                    <Avatar src={src} className={"avatar-lg"} />
                )}
            </div>
            <div className={"cols cols-middle gap-xs"}>
                <Button
                    onClick={() => null}
                    variant={"outlined"}
                    startIcon={<Icon>mui-camera_alt</Icon>}
                >
                    {!src ? "Add photo" : "Change photo"}
                </Button>
            </div>
        </div>
    );
}

export default function AvatarUploadContainer(props) {
    return <Upload {...props} trigger={AvatarUpload} />;
}

AvatarUpload.propTypes = {};
AvatarUpload.defaultProps = {};
