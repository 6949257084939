import React, { useCallback, useContext, useState } from "react";
import { useData } from "state/hooks";
import SectionThumbnail from "./SectionThumbnail";
import ContextMenu from "components/ContextMenu";
import SectionContextMenu from "./../../ListItem/ContextMenu";
import VisibilitySensor from "react-visibility-sensor/dist/visibility-sensor";
import { useSectionsFilter } from "playground/cms/views/Sections/SectionsFilterContext";
import { ThemeRenderContext } from "playground/cms/views/Sections/ThemeContext";
import { selectEntityData } from "state/selectors/data";
import { useSelector } from "react-redux";

//import PropTypes from 'prop-types';

const SectionThumbnailRef = React.forwardRef(SectionThumbnail);

const DummySection = React.forwardRef((props, ref) => {
    const names = {
        "@NEW": "Nowa sekcja",
        "@NEW_TPL": "Nowa sekcja z szablonem",
    };
    const { className } = props;
    return (
        <div ref={ref} className={className}>
            {names[props.id] || props.id}
        </div>
    );
});

function selectPreviewFrame(store, id) {
    const data = selectEntityData(store, "cms.sections", id);
    if (!data.type === "view") return id;
    const usedIn = data.used_in || [];
    for (let i = 0; i < usedIn.length; i++) {
        if (
            selectEntityData(store, "cms.sections", usedIn[i].section_id).active
        ) {
            return usedIn[i].section_id;
        }
    }
    return usedIn?.[0]?.section_id;
}

export function SectionPreviewContainer(props, ref) {
    const { service, id, previewSection, onClick, className, Content } = props;
    const [isVisible, changeVisibility] = useState(false);

    const themeRendererReady = useContext(ThemeRenderContext);
    const visible = isVisible && themeRendererReady;

    const type = "cms.sections";
    const data = useData({ type, id });
    const passRef = ref || React.createRef();
    const { onSet } = useSectionsFilter();
    const isView = data.type === "view";

    const onExpand =
        isView && data?.used_in?.length > 1
            ? () => onSet("template", id)
            : null;

    const labelClick = (e) => {
        if (onExpand) {
            e.preventDefault();
            onExpand();
        }
    };

    //let section = isView ? data.used_in?.[0]?.section_id : null;
    let section = useSelector((store) => selectPreviewFrame(store, id));

    const click = useCallback(() => {
        if (onClick) onClick(id);
    }, [onClick, id]);

    if (id.indexOf("@") === 0)
        return <DummySection ref={passRef} id={id} className={className} />;

    return (
        <VisibilitySensor onChange={changeVisibility} partialVisibility={true}>
            <ContextMenu
                type={type}
                id={id}
                RenderMenuItems={SectionContextMenu}
            >
                {({ onMenuOpen }) => (
                    <SectionThumbnailRef
                        ref={passRef}
                        cacheKey={[service, section || id].join("/")}
                        type={type}
                        id={id}
                        section={previewSection || section}
                        data={data}
                        onClick={click}
                        onContextMenu={onMenuOpen}
                        isVisible={visible}
                        isActive={data?.active}
                        className={className}
                    >
                        {Content && (
                            <Content
                                onContextMenu={onMenuOpen}
                                onClick={labelClick}
                                data={data}
                            />
                        )}
                    </SectionThumbnailRef>
                )}
            </ContextMenu>
        </VisibilitySensor>
    );
}

// SectionPreviewContainer.propTypes = {};
// SectionPreviewContainer.defaultProps = {};

const SectionPreviewContainerRef = React.forwardRef(SectionPreviewContainer);

export default SectionPreviewContainerRef;
