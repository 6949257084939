import React from "react";
//import PropTypes from 'prop-types';

import Icon from "components/Icon";
import Field from "containers/Field";
import PreviewToggle from "playground/cms/views/Sections/PreviewToggle";
import IconButton from "components/IconButton";
import SectionTemplate from "./SectionTemplate";

export function SectionFormHeader(props) {
    const { onCancel, isPreview } = props;
    return (
        <div className={"cols cols-middle cols-spread gap-md"}>
            <div className={"cols cols-middle gap-md"}>
                <div className={"cols gap-xs cols-middle pad-xs-h"}>
                    {/*<Icon fontSize={"small"} className={"o-50"}>*/}
                    {/*    mui-comment*/}
                    {/*</Icon>*/}
                    <Field
                        id={"description"}
                        label={"Opis"}
                        className={"description-field"}
                        variant={"inline"}
                        changeMode={"change"}
                    />
                </div>
                {/*<Field*/}
                {/*    id={"cms_tags"}*/}
                {/*    fieldType={"select"}*/}
                {/*    source={"api:tags.cms"}*/}
                {/*    label={"Tagi cms"}*/}
                {/*    nullable={true}*/}
                {/*    multiple={true}*/}
                {/*    taggable={true}*/}
                {/*    variant={"dense"}*/}
                {/*/>*/}
            </div>
            <div className={"cols cols-middle gap-sm"}>
                {!isPreview && <SectionTemplate {...props} />}
                {!isPreview && <PreviewToggle />}
                <IconButton icon={"mui-close"} onClick={onCancel} />
            </div>
        </div>
    );
}

SectionFormHeader.propTypes = {};

SectionFormHeader.defaultProps = {};

export default SectionFormHeader;
