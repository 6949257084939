export default [
    {
        value: 'zoom-out',
        icon: 'mui-zoom_out'
    },
    {
        value: 'preview',
        icon: 'mui-visibility_off'
    }
];

export const devDisplayOptions = [
    {
        value: 'zoom-out',
        icon: 'mui-zoom_out'
    },
    {
        value: 'outline',
        icon: 'mui-border_outer'
    },
    {
        value: 'xray',
        icon: 'mui-gradient'
    },
    {
        value: 'preview',
        icon: 'mui-visibility_off'
    },
    {
        value: 'focus',
        icon: 'mui-filter_center_focus'
    }
];