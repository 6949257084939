import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useRouteParam} from "state/hooks";
import JitsiMeetSession from "joynt/meeting/backends/JitsiMeetSession";
import MeetingTiles from "joynt/meeting/backends/MeetingTiles";
//import PropTypes from 'prop-types';

const MeetingSessionContext = React.createContext(null);

function JitsiContainer(props) {
    const [session, setSession] = useState(null);
    const [tracks, setTracks] = useState([]);
    const [id] = useRouteParam('id');
    const s = useMemo(()=> {
        const s = new JitsiMeetSession(null, {
            setTracks
        });
        s.start(id);
        return s;
    }, [id]);
    useEffect(() => setSession(s), [s, setSession, setTracks]);
    const context = useMemo(() => ({
        session,
        tracks,
        onAttachTrack: session ? session.attachTrack : null
    }), [session, tracks]);
    return <MeetingSessionContext.Provider value={context}>
        <JitsiView />
    </MeetingSessionContext.Provider>
}

function JitsiView(props) {
    const {tracks, onAttachTrack} = useContext(MeetingSessionContext);
    return onAttachTrack ? <MeetingTiles
        isConnected={true}
        tiles={tracks}
        onAttachTile={onAttachTrack}
    /> : null;
}

export default JitsiContainer;
