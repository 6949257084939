import React, { useState } from "react";
//import PropTypes from 'prop-types';

import WebsiteDomains from "./WebsiteDomains";
import { createCallback } from "state/actions/create";
import { useActions } from "state/hooks";

export function WebsiteDomainsContainer(props) {
    const { id } = props;
    const [domainId, setDomainId] = useState(null);
    const list = `domains.${id}`;

    const { onCreate } = useActions({ onCreate: createCallback });

    const handleCreate = () => {
        const cb = ({ id }) => setDomainId(id);
        onCreate(
            cb,
            "console.domains",
            { service: id, active: true },
            list,
            -1
        );
    };
    return (
        <WebsiteDomains
            {...props}
            list={list}
            domainId={domainId}
            setDomainId={setDomainId}
            onCreate={handleCreate}
        />
    );
}

WebsiteDomainsContainer.propTypes = {};

WebsiteDomainsContainer.defaultProps = {};

export default WebsiteDomainsContainer;
