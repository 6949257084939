import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";
import Field from "containers/Field";
import Button from "components/Button";
import IconButton from "components/IconButton";

export function MediaPreview(props) {
    const {
        id,
        alt,
        title,
        file,
        onClose,
        onSave,
        onShowPreview,
        onDownload,
        open,
        preview,
        children,
    } = props;

    const baseFilename = file?.split("/")?.pop();
    const fileExtension = baseFilename?.split(".")?.pop();

    return (
        <div
            className={cn("media-preview-container", {
                "is-open": open,
            })}
        >
            <div className={"media-preview"}>
                <div className={"rows gap-md"}>
                    <div className={"cols cols-middle cols-spread gap-md"}>
                        <div>{fileExtension}</div>
                        <div className={"cols cols-right cols-middle gap-md"}>
                            <div className={"cols"}>
                                <IconButton
                                    icon={"mui-download"}
                                    onClick={onDownload}
                                />
                                <IconButton
                                    icon={"mui-fullscreen"}
                                    onClick={onShowPreview}
                                />
                            </div>
                            <IconButton icon={"mui-close"} onClick={onClose} />
                        </div>
                    </div>
                    <div className={"media-preview-img"}>{children}</div>
                    <div>{baseFilename}</div>
                    <div className={"rows gap-sm"}>
                        <Field id={"title"} label={"Title"} />
                        <Field id={"alt"} label={"ALT"} />
                        <div className={"cols cols-right"}>
                            <Button onClick={onSave}>Zapisz</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

MediaPreview.propTypes = {};

MediaPreview.defaultProps = {};

export default MediaPreview;
