import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { useComponentMapping } from "playground/designer/Zmap";
import ZmapField from "playground/designer/ComponentEditor/ZmapField";

function TemplateVars(props) {
    const { type, id } = props;
    const { zmap } = useComponentMapping();
    const [add, setAdd] = useState(null);

    const fields = (zmap || [])
        .map((f) => f.to)
        .filter((to) => !["visible"].includes(to));

    const FieldComponent = (props) => {
        return <div className={"pad-xs"}>{props.id}</div>;
    };

    return (
        <div className={"rows gap-xs"}>
            {/*<pre>{JSON.stringify(zmap, null, 2)}</pre>*/}
            <div className={"cols cols-spread"}>
                <div>Template vars</div>
                <div onClick={() => setAdd(true)}>+</div>
            </div>
            <div className={"rows"}>
                {fields.map((field) => (
                    <ZmapField id={field} FieldComponent={FieldComponent} />
                ))}
            </div>
            {add && <ZmapField id={null} FieldComponent={FieldComponent} />}
        </div>
    );
}

export function Template(props) {
    const { children, ...rest } = props;
    return (
        <>
            {children}
            <Field id={"template"} label={"Template"} fieldType={"code"} />
            <Field
                id={"template_key"}
                label={"Template from config"}
                fieldType={"select"}
                source={"api:value-store/keys?search=template."}
                textFallback={true}
                nullable={true}
            />
            <TemplateVars {...rest} />
        </>
    );
}

Template.propTypes = {};

Template.defaultProps = {};

export default Template;
