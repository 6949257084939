export const feedCreate = [
    {
        content:
            "This is an open feed, which means you can write a post here! Remember to add a title and some content. It can be a link, just text or maybe some attachment. Or it can be all of those. If you have a question to your peers, try “Create a poll” tab and see what happens! You can also scroll down to see previous posts - they are displayed in chronological order, with the newest shown on top.",
        target: ".post-create",
        position: "left",
    },
];

export const feed = [
    {
        content:
            "This is a closed feed. It means you cannot post here, but you can still view posts and engage in commenting. Posts are displayed in chronological order, with the newest shown on the top.",
        isFixed: true,
        target: ".feed-header",
    },
];
