export default [
    {
        value: 'pages',
        icon: 'mui-list'
    },
    {
        value: 'sections',
        icon: 'mui-description'
    },
    {
        value: 'media',
        icon: 'mui-photo'
    }
];