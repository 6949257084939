import WebFont from "webfontloader";

export const loadWebfont = function (data, providedContext) {
    const context = providedContext || window;

    context.__loadedFonts = context.__loadedFonts || [];

    return new Promise((resolve, reject) => {
        const { family, source } = data;
        if (context.__loadedFonts.indexOf(family) > -1) {
            //resolve();
            //return;
        }
        context.__loadedFonts.push(family);
        if (source === "google") {
            WebFont.load({
                google: {
                    families: [family],
                },
                context,
            });
        } else {
            WebFont.load({
                custom: {
                    families: [family],
                    urls: [
                        "https://s3.eu-central-1.amazonaws.com/zuu-webfonts/" +
                            family +
                            "/stylesheet.css",
                    ],
                },
                context,
            });
        }
        resolve();
    });
};
