import React from "react";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';
import "./style.css";

export function ParticipantsCount(props) {
    const { confirmed, max } = props;

    return (
        <div className={"participants-count"}>
            <div className={"participants-count__icon"}>
                <Icon>mui-check</Icon>
            </div>
            <span className={"participants-count__confirmed"}>{confirmed}</span>
            {max && (
                <span className={"participants-count__separator"}> / </span>
            )}
            {max && <>{max}</>}
        </div>
    );
}

ParticipantsCount.propTypes = {};
ParticipantsCount.defaultProps = {};

export default ParticipantsCount;
