import React from "react";

import session from "joynt/components/Session/views";
import networking from "joynt/components/SessionPage/Networking/views";
import { sessionViews as witnessSessionViews } from "joynt/components/Witness";
import webinarViews from "joynt/components/SessionPage/views";

import StageStatusMessage from "./StageStatusMessage";

export default {
    ...session,
    ...networking,
    ...witnessSessionViews,
    ...webinarViews,

    default: StageStatusMessage,
};
