import React, { useCallback } from "react";
import StageOverlayToolbar from "./StageOverlayToolbar";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";
import PostIdentity from "joynt/post/PostIdentity";
import Preloader from "components/Preloader";
import ChatNotification from "joynt/components/FloatingSession/SessionWindow/ChatNotification";
import { useUiFlag } from "state/hooks";
import { FLAG_SESSION_NAV } from "joynt/config";
//import PropTypes from 'prop-types';

const nullFn = () => null;

function Avatars(props) {
    const { id } = props;
    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );
    return <PostIdentity identities={presence} maxSize={6} size={"xs"} />;
}

export default function StageOverlay(props) {
    const { id, onClick, isPending, isDragging, children } = props;

    const { name } = useNodeData(id);
    const { session_chat } = useEdgesData(id);

    const [, setView] = useUiFlag(FLAG_SESSION_NAV);

    const onShowChat = useCallback(() => {
        onClick();
        setView("chat");
    }, [onClick, setView]);

    return (
        <>
            {!isDragging && (
                <ChatNotification id={session_chat} onClick={onShowChat} />
            )}
            <div className={"session-window"}>
                <Preloader visible={isPending} />
                <div className={"rows gap-sm grow"} onClick={onClick}>
                    <div
                        className={
                            "cols cols-spread cols-middle session-window__header"
                        }
                    >
                        <div className={"session-window__name"}>{name}</div>
                        <div>
                            <Avatars id={id} />
                        </div>
                    </div>
                    {children}
                    <div className={"cols cols-center session-window__toolbar"}>
                        {!isDragging && !isPending && <StageOverlayToolbar />}
                    </div>
                </div>
            </div>
        </>
    );
}

StageOverlay.propTypes = {};
StageOverlay.defaultProps = {};
