import React from "react";
import cn from "classnames";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

export function Integration(props) {
    const { type, id, onClick, onMenuOpen, integrationType, active } = props;

    return (
        <div
            className={cn("integration-item", {
                "is-active": !!active,
            })}
            onClick={() => onClick(id)}
        >
            <div className={"cols cols-spread"}>
                <div className={"type-lg"}>{integrationType}</div>
                {onMenuOpen && (
                    <IconButton icon={"mui-more_vert"} onClick={onMenuOpen} />
                )}
            </div>
        </div>
    );
}

Integration.propTypes = {};

Integration.defaultProps = {};

export default Integration;
