import React  from "react";
import Feed from "joynt/views/feed/Feed";
//import PropTypes from 'prop-types';

export function Threads(props) {
    return <Feed
        {...props}
        view={"discussion"}
    />
}

export default Threads;

Threads.propTypes = {};
