export const descriptions = {
    message: "New message from {identity} {inParent}",
    "message.multiple": "{count} messages from {identity} {inParent}",

    "message.dm": "New message from {identity} {inParent}",
    "message.dm.multiple": "{count} messages from {identity} {inParent}",

    "message.comment": "{identity} commented on {parent}",
    "message.comment.multiple": "{identity} added {count} comments on {parent}",
    "message.comment.group": "{count} new comments on {parent}",

    "message.question.reply": "{identity} replied to {parent}",
    "message.question.reply.multiple":
        "{identity} added {count} replies to {parent}",
    "message.question.reply.group": "{count} new replies to {parent}",

    "message.mention": "{identity} mentioned you in {parent}",
    "message.comment.mention":
        "{identity} mentioned you in a comment on {parent}",
    "message.mention.all": "{identity} mentioned everyone in {parent}",
    "message.share": null,

    "post-published": "{subject}",
    "post-published.link": "{identity} shared a link {link} in {parent}",
    "post-published.question": "{identity} posted a question in {parent}",

    "access.add": "{identity} added {subject} as an admin of {parent}",
    "access.remove": "{identity} removed {subject} from admins of {parent}",
    "access.add.admin": "{identity} added {subject} as an admin of {parent}",
    "access.remove.admin":
        "{identity} removed {subject} from admins of {parent}",
    "access.add.member":
        "{identity} added {subject} as a collaborator of {parent}",
    "access.remove.member":
        "{identity} removed {subject} from collaborators of {parent}",

    "current-event": "New event in {parent} just started",
    "session.published":
        "{identity} scheduled new session: {subject} {eventTime}",
    "session.rescheduled": "{identity} rescheduled {subject} to {eventTime}",
    "session.start": "{subject} has just started",
    "session.end": "{subject} has just ended",
    "session.reminder": "Reminder: {subject} starts {eventTimeDiff}",
    "session.presentation": "{identity} presented {subject} to {parent}",
    "session.recording-available": "{subject} recording is now available",
    "session.recording": "{identity} started recording {subject}",
    "event-role.add.speaker":
        "{identity} added {subject} as a speaker in {parent}",
    "event-role.remove.speaker":
        "{identity} removed {subject} from speakers in {parent}",
    "event-role.add.moderator":
        "{identity} added {subject} as a moderator in {parent}",
    "event-role.remove.moderator":
        "{identity} removed {subject} from moderators in {parent}",

    joined: "{identity} joined {parent}",
    left: "{identity} left {parent}",
    invite: "{identity} invited you to join {parent}",

    "reaction.add": null,
    "reaction.revoke": null,

    "witness.paired":
        "{identity} will join you as your witness in {subject} {eventTime}",
    "witness.unpaired": "{identity} can no longer do {subject} with you",
    "witness.time-available":
        "{identity} wants to do their task with you as a witness",
    "witness.reminder":
        "Your witness session {subject} is starting {eventTimeDiff}",
};
