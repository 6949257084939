export default {
    "sizes": {
        "xsmall": {"w": 48, "h": 48},
        "small": {"w": 128, "h": 128},
        "medium": {"w": 640, "h": 480},
        "wide": {"w": 640, "h": 360},
        "large": {"w": 1024, "h": 768},
        "widelarge": {"w": 1024, "h": 576},
        "xlg": {"w": 1440, "h": 1080},
        "xxs": {"w": 48, "h": 36},
        "xs": {"w": 160, "h": 120},
        "sm": {"w": 320, "h": 240},
        "md": {"w": 640, "h": 480},
        "lg": {"w": 960, "h": 720},
        "fullhd": {"w": 1920, "h": 1440},
        "xlg-square": {"w": 1440, "h": 1440},
        "xlg-vertical": {"w": 1440, "h": 1920},
        "xlg-pano": {"w": 1440, "h": 810},
        "xlg-cinema": {"w": 1440, "h": 613},
        "xlg-wide": {"w": 1440, "h": 360},
        "xlg-auto": {"w": 1440, "h": null},
        "xxs-square": {"w": 48, "h": 48},
        "xxs-vertical": {"w": 48, "h": 64},
        "xxs-pano": {"w": 48, "h": 27},
        "xxs-cinema": {"w": 48, "h": 20},
        "xxs-wide": {"w": 48, "h": 12},
        "xxs-auto": {"w": 48, "h": null},
        "xs-square": {"w": 160, "h": 160},
        "xs-vertical": {"w": 160, "h": 213},
        "xs-pano": {"w": 160, "h": 90},
        "xs-cinema": {"w": 160, "h": 68},
        "xs-wide": {"w": 160, "h": 40},
        "xs-auto": {"w": 160, "h": null},
        "sm-square": {"w": 320, "h": 320},
        "sm-vertical": {"w": 320, "h": 427},
        "sm-pano": {"w": 320, "h": 180},
        "sm-cinema": {"w": 320, "h": 136},
        "sm-wide": {"w": 320, "h": 80},
        "sm-auto": {"w": 320, "h": null},
        "md-square": {"w": 640, "h": 640},
        "md-vertical": {"w": 640, "h": 853},
        "md-pano": {"w": 640, "h": 360},
        "md-cinema": {"w": 640, "h": 272},
        "md-wide": {"w": 640, "h": 160},
        "md-auto": {"w": 640, "h": null},
        "lg-square": {"w": 960, "h": 960},
        "lg-vertical": {"w": 960, "h": 1280},
        "lg-pano": {"w": 960, "h": 540},
        "lg-cinema": {"w": 960, "h": 409},
        "lg-wide": {"w": 960, "h": 240},
        "lg-auto": {"w": 960, "h": null},
        "fullhd-square": {"w": 1920, "h": 1920},
        "fullhd-vertical": {"w": 1920, "h": 2560},
        "fullhd-pano": {"w": 1920, "h": 1080},
        "fullhd-cinema": {"w": 1920, "h": 817},
        "fullhd-wide": {"w": 1920, "h": 480},
        "fullhd-auto": {"w": 1920, "h": null}
    },
    "ratios": {"square": "1:1", "vertical": "3:4", "pano": "16:9", "cinema": "2.35:1", "wide": "4:1", "auto": "auto"},
    "default_ratio": "4:3",
    "processing_options": "crop"
}