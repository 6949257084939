import React from "react";
import { useMediaDevices } from "joynt/meeting/devices";
import DeviceOptions from "joynt/meeting/settings/DeviceOptions";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export default function DeviceSelection(props) {
    const { selectedDevices, availableDevices, onSelectDevice, pending } =
        useMediaDevices();

    return (
        <div className={"rows"}>
            <div className={"rows gap-xs"}>
                {!pending ? (
                    <DeviceOptions
                        id={"videoInput"}
                        options={availableDevices.videoInput}
                        value={selectedDevices.videoInput}
                        label={"Video input"}
                        onChange={onSelectDevice}
                        icon={"mui-videocam"}
                    />
                ) : null}
                {!pending ? (
                    <DeviceOptions
                        id={"audioInput"}
                        options={availableDevices.audioInput}
                        value={selectedDevices.audioInput}
                        label={"Audio input"}
                        onChange={onSelectDevice}
                        icon={"mui-mic"}
                    />
                ) : null}
                {/*<DeviceOptions
                    id={'audioOutput'}
                    options={availableDevices.audioOutput}
                    value={selectedDevices.audioOutput}
                    label={'Audio output'}
                    onChange={onSelectDevice}
                    icon={'mui-volume_up'}
                />*/}
            </div>
            <Preloader visible={!!pending} />
        </div>
    );
}

DeviceSelection.propTypes = {};
DeviceSelection.defaultProps = {};
