import React, {useCallback, useMemo, useRef} from 'react';
//import PropTypes from 'prop-types';
import {useEdgesData, useNode} from "joynt/hooks";
import {
    useActions,
    useClick,
    useFilter,
    useList, usePending,
    useRegisterList,
    useRouteParam, useUiFlag
} from "state/hooks";
import {NODE_TYPE_TAG, ROUTE_ID} from "joynt/config";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fetch from "containers/Fetch";
import {createCallback} from "state/actions/create";
import uuid from "uuid/v4";
import {pathAppend, pathDetach} from "state/actions/data";
import {useRootNode} from "joynt/hooks/channels";

import Checkbox from "components/fields/checkbox/Checkbox";
import Preloader from "components/Preloader";

import "./style.css";

function sanitize(value) {
    return value.replace(/,\s/m, '');
}

function Tag(props) {
    const {id} = props;
    const {name} = useNode(id);
    const onClick = useClick(props);
    return <div onClick={onClick}>{name || props.name}</div>;
}

function TagListItem(props) {
    const {id} = props;
    const {name} = useNode(id);
    const onClick = useClick(props);
    return <div onClick={onClick}>{name}</div>;
}

function TagSuggest(props) {
    const {
        anchorEl,
        open,
        onAddTag,
        list,
        value
    } = props;

    const type = "db.nodes";

    let userScope = useRootNode();
    let [workspace] = useRouteParam(ROUTE_ID);

    const [tagsScope, setTagsScope] = useUiFlag('tagsSearchScope');
    let suggestScope = tagsScope ? userScope : workspace;

    const url = `joynt/nodes/${suggestScope}/tags`;

    useRegisterList({type, list, filter: list, url});
    const {items} = useList(type, list);
    const isPending = usePending([type,list].join('/'));

    const suggestions = useMemo(() => {
        return items.filter(id => !value.includes(id));
    }, [items, value]);

    return <Fetch
        type={type}
        list={list}
        filter={list}
        url={url}
        force={open}
        enable={open}
    >
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'auto-start'}
        >
            <Paper>
                <Preloader visible={isPending} size={20} />
                <div className={"cols"}>
                    <Checkbox value={!!tagsScope} onChange={()=>setTagsScope(!tagsScope)} label={"Show all tags"} />
                </div>
                <div className={"tag-suggest__list"}>
                    {suggestions.map(tag => <TagListItem
                        key={tag}
                        id={tag}
                        onClick={onAddTag}
                    />)}
                </div>
            </Paper>
        </Popper>
    </Fetch>;
}

function TagInput(props) {

    const {
        id,
        scope,
        value,
        names,
        onAddTag,
        onRemoveTag
    } = props;

    const ref = useRef();

    const list = `tags.suggest.${id}`;
    const type = 'db.nodes';

    const {value: filterValue, onSet} = useFilter(list, type, list);

    const typingValue = filterValue.search;
    const setTypingValue = useCallback((v) => {
        onSet('search', v);
    }, [onSet]);

    const handleChange = useCallback(e => {
        setTypingValue(sanitize(e.target.value));
    }, [setTypingValue]);

    const addTag = useCallback(tagId => {
        onAddTag(tagId);
        setTypingValue('');
    }, [onAddTag, setTypingValue]);

    const {onCreateNode} = useActions({
        onCreateNode: createCallback
    });

    const createTag = useCallback(tag => {
        const tagId = uuid();
        onCreateNode(()=>addTag(tagId), "db.nodes", {
            id: tagId,
            subtype: NODE_TYPE_TAG,
            name: tag,
            parent: scope,
            public: true,
            published: true
        })
    }, [scope, onCreateNode, addTag]);

    const handleKey = useCallback(e => {
        const delimiters = [188,13];
        const key = e.keyCode;
        if (delimiters.includes(key)) {
            createTag(typingValue);
            setTypingValue('');
        }
    }, [setTypingValue, createTag, typingValue]);

    const handleKeyEmpty = useCallback(e => {
        const key = e.keyCode;
        if (key === 8 && !e.target.value && value.length) {
            onRemoveTag(value[value.length-1]);
        }
    }, [onRemoveTag, value]);

    return <div className={"tags cols gap-sm"}>
        {value.map(v => <Tag key={v} id={v} name={names?.[v]} onClick={onRemoveTag} />)}
        <div className={"tags-input"} ref={ref}>
            <input
                type={"text"}
                onChange={handleChange}
                onKeyDown={!typingValue ? handleKeyEmpty : handleKey}
                value={typingValue}
                placeholder={'Add tags'}
            />
        </div>
        <TagSuggest
            list={list}
            anchorEl={ref.current}
            search={typingValue}
            value={value}
            open={!!typingValue}
            onAddTag={addTag}
            onCreateTag={createTag}
        />
    </div>
}

TagInput.defaultProps = {
    value: [],
    names: {}
}

export function PostTagsEdit(props) {

    const {id} = props;
    const {tags} = useNode(id);
    const {tag_names} = useEdgesData(id);
    const [workspace] = useRouteParam(ROUTE_ID);
    const path = useMemo(() => ["db.nodes", id, "tags"], [id]);

    const {
        onAddTag,
        onRemoveTag
    } = useActions({
        onAddTag: pathAppend,
        onRemoveTag: pathDetach
    });

    const add = useCallback(tag => {
        onAddTag(path, tag)
    }, [onAddTag, path]);

    const remove = useCallback(tag => {
        onRemoveTag(path, tag)
    }, [onRemoveTag, path]);

    return <div>
        <TagInput
            id={id}
            scope={workspace}
            value={tags}
            names={tag_names}
            onAddTag={add}
            onRemoveTag={remove}
        />
    </div>;

}

PostTagsEdit.propTypes = {};
PostTagsEdit.defaultProps = {};

export default PostTagsEdit;
