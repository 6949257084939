// css-to-string-loader: transforms styles from css-loader to a string output

// Get the styles
var styles = require("!!../../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/css-loader/dist/cjs.js!./canvas.css");

if (typeof styles === 'string') {
  // Return an existing string
  module.exports = styles;
} else {
  // Call the custom toString method from css-loader module
  module.exports = styles.toString();
}