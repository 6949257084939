import React from 'react';
import {thumbnail} from "util/thumbnail";
//import PropTypes from 'prop-types';

export default function Image(props) {

    const {file} = props;
    let url = thumbnail(file, 'lg-auto');

    return (<img src={url} alt={file} />);

}

Image.propTypes = {};
Image.defaultProps = {};
