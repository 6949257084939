import React from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import { selectEntity } from "state/selectors/data";
import TypographyStyle from "./TypographyStyle";
import TypographyStyleSettings from "./TypographyStyleSettings";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";

import "./style.css";

function sortByFontSize(arr) {
    return arr.sort((a, b) => {
        if (a.font_size > b.font_size) {
            return -1;
        }
        if (a.font_size < b.font_size) {
            return 1;
        }
        return 0;
    });
}

const mapState = (store, props) => {
    return {
        preset: selectEntity(store, props.entityType, props.entityId),
    };
};

const mapDispatch = {};

const quickSearch = ["heading", "headline", "body", "btn"];

const defaultTypeStyle = {
    letter_spacing: 0,
    line_height: "1.6",
    font_weight: "regular",
    font: "body",
    font_size: 16,
};

class TypographyStyles extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { edit: false, anchorEl: null, search: null };
    }

    edit = (index, evt) => {
        this.setState({ edit: index, anchorEl: evt?.target });
    };

    close = () => this.setState({ edit: null, anchorEl: null });

    append = () => {
        const { onChange } = this.props;
        const next = this.props.value.slice();
        const style = { id: this.state.search, ...defaultTypeStyle };
        next.push(style);
        onChange(next);
        this.edit(next.indexOf(style));
    };

    change = (i, value) => {
        const { onChange } = this.props;
        const next = this.props.value.slice();
        next[i] = { ...next[i], ...value };
        onChange(next);
    };

    remove = (i) => {
        const { onChange, value } = this.props;
        const next = value.slice();
        delete next[i];
        onChange(next.map((e) => e));
        this.close();
    };

    search = (value) => {
        this.setState({ search: value });
    };

    render() {
        const { value, preset } = this.props;
        const { edit, anchorEl, search } = this.state;
        const isOpen = edit || edit === 0;
        const filteredValue = search
            ? value.filter((item) => item.id.indexOf(search) > -1)
            : value;
        const sortedValue = sortByFontSize([...filteredValue]);
        return (
            <div className={"cols gap-md grow overflow-hide"}>
                <div className={"rows overflow-scroll grow pad-md"}>
                    {sortedValue.map((style, index) => {
                        return (
                            <TypographyStyle
                                preset={preset.typography}
                                key={index}
                                value={style}
                                isOpen={value.indexOf(style) === edit}
                                onChange={(v) =>
                                    this.change(value.indexOf(style), v)
                                }
                                onEdit={(evt) =>
                                    this.edit(value.indexOf(style), evt)
                                }
                                onDelete={(evt) =>
                                    this.remove(value.indexOf(style))
                                }
                            />
                        );
                    })}
                </div>

                <div className={"rows gap-xs"}>
                    {isOpen ? (
                        <TypographyStyleSettings
                            value={value[edit]}
                            onDelete={(evt) => this.remove(edit)}
                            onChange={(value) => this.change(edit, value)}
                        />
                    ) : (
                        <>
                            <TextField
                                onChange={this.search}
                                variant={"search"}
                                label={"Search"}
                                value={this.state.search || ""}
                            />
                            <div className={"rows gap-xs"}>
                                {quickSearch.map((s) => {
                                    return (
                                        <div
                                            key={s}
                                            onClick={() => this.search(s)}
                                        >
                                            {s}
                                        </div>
                                    );
                                })}
                            </div>
                            <Button onClick={this.append}>create style</Button>
                        </>
                    )}
                </div>

                {/*<Popover open={isOpen} anchorEl={anchorEl} onClose={this.close}>*/}
                {/*    {isOpen ? (*/}
                {/*        <TypographyStyleSettings*/}
                {/*            value={value[edit]}*/}
                {/*            onDelete={(evt) => this.remove(edit)}*/}
                {/*            onChange={(value) => this.change(edit, value)}*/}
                {/*        />*/}
                {/*    ) : null}*/}
                {/*</Popover>*/}
            </div>
        );
    }
}

TypographyStyles.defaultProps = {
    value: [],
};

TypographyStyles.propTypes = {};

export default connect(mapState, mapDispatch)(TypographyStyles);
