import React from 'react';
import PropTypes from "prop-types";

import Popover from "components/Popover";
import InputBox from "joynt/components/DateTimePicker/InputBox";
import HourSelectMenu from "./HourSelectMenu";

import {useMoment} from "./../hooks";

import "./style.css";

export function HourSelect(props) {
    const {
        start,
        max,
        value,
        format,
        minuteInterval,
        onChange,
        icon
    } = props;

    const valueMoment = useMoment(value);

    const hourMenu = ({onClose}) => {
        const change = (value) => {
            onClose();
            onChange(value);
        }
        return <HourSelectMenu
            start={start}
            max={max}
            value={value}
            format={format}
            minuteInterval={minuteInterval}
            onChange={change}
        />;
    }

    return <div>
        <Popover
            trigger={({onClick}) => <InputBox
                onClick={onClick}
                startIcon={icon}
            >
                {valueMoment.format(format)}
            </InputBox>}
            content={hourMenu}
        />
    </div>
}

HourSelect.propTypes = {
    format: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string
}

HourSelect.defaultProps = {
    format: 'H:mm',
    icon: "mui-schedule"
}

export default HourSelect;
