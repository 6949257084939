import {useState, useEffect, useContext, useCallback} from 'react';
import {AgoraContext} from '../context/AgoraContext';
import {useActions} from "state/hooks";
import {notify} from "state/actions/ui";
import {createReport, storeReport} from "joynt/state/actions/meetings-debug";
import {leaveMeeting} from "joynt/state/actions/meetings";
import {useSessionPresence} from "joynt/meeting/hooks/useSessionPresence";

export const useRTMEvents = (props) => {

    const [events, setEventState] = useState({event: '', data: {}});
    const connection = useContext(AgoraContext);
    const {rtm: rtmClient, rtmChannel} = connection;
    const channelId = rtmChannel ? rtmChannel.channelId : null;

    const {onLeave} = useActions({onLeave: leaveMeeting});
    const {onUpdatePresence} = useSessionPresence(channelId);

    const {
        toggleAudio,
        toggleVideo,
        toggleScreenShare,
        toggleLive
    } = props;
    const {
        notify: onNotify,
        createReport: onCreateReport,
        storeReport: onStoreReport
    } = useActions({
        notify,
        createReport,
        storeReport
    });

    const setEvent = useCallback((e) => {
        setEventState(e);
    }, [setEventState]);

    useEffect(() => {
        if (rtmChannel) {
            rtmChannel.on('ChannelMessage', function (message, memberId) {
                if (message.text ==='mute-all') {
                    onNotify("You've been muted by admin");
                    toggleAudio(false);
                    return;
                }
                if (message.text === 'request-logs') {
                    onCreateReport(memberId);
                    return;
                }
                setEvent({
                    event: 'ChannelMessage',
                    data: {
                        message,
                        memberId
                    }
                });
            });

            rtmChannel.on('MemberJoined', memberId => {
                setEvent({
                    event: 'MemberJoined',
                    data: {
                        memberId
                    }
                });
            });

            rtmChannel.on('MemberLeft', memberId => {
                setEvent({
                    event: 'MemberLeft',
                    data: {
                        memberId
                    }
                });
            });

            rtmChannel.on('AttributesUpdated', attributes => {
                if (attributes.hand) {
                    let value = JSON.parse(attributes.hand.value);
                    let update = {};
                    Object.keys(value).forEach(uid => {
                        update[uid] = {hand: value[uid]}
                    });
                    console.log('session presence', update);
                    onUpdatePresence(update);
                }
                console.log('AttributesUpdated', attributes);
                setEvent({
                    event: 'AttributesUpdated',
                    data: {
                        attributes
                    }
                });
            });

            rtmChannel.on('MemberCountUpdated', memberCount => {
                setEvent({
                    event: 'MemberCountUpdated',
                    data: {
                        memberCount
                    }
                });
            });
        }

        if (rtmClient) {
            rtmClient.on('MessageFromPeer', (message, peerId) => {
                if (message.text.indexOf('{') === 0) {
                    let decodedMessage = JSON.parse(message.text);
                    switch (decodedMessage.id) {
                    case 'provide-logs':
                        onStoreReport(peerId, decodedMessage.data);
                        break;
                    default:
                        break;
                    }
                    return;
                }
                console.log(`[rtm] MessageFromPeer`, message);
                switch (message.text) {
                case 'mute-audio':
                    onNotify("You've been muted by admin");
                    toggleAudio(false);
                    break;
                case 'mute-video':
                    onNotify("Your camera was disabled by admin");
                    toggleVideo(false);
                    break;
                case 'toggle-audio':
                    onNotify("Your microphone was toggled by admin");
                    toggleAudio((prev)=>!prev);
                    break;
                case 'toggle-video':
                    onNotify("Your camera was toggled by admin");
                    toggleVideo((prev)=>!prev);
                    break;
                case 'remove-attendee':
                    onNotify("You've been removed from session by admin");
                    onLeave();
                    break;
                case 'stop-screen-share':
                    onNotify("Screen sharing ended by admin");
                    toggleScreenShare();
                    break;
                case 'stage-invite':
                    onNotify("You've been invited to stage by admin");
                    toggleLive(true, 'invite');
                    break;
                case 'stage-remove':
                    onNotify("You've been removed from stage by admin");
                    toggleLive(false, 'remove');
                    break;
                case 'dump-logs':
                    console.log('Recieved logs from peer upon request');
                    console.log(message);
                    break;
                default:
                    setEvent({
                        event: 'MessageFromPeer',
                        data: {
                            message,
                            peerId
                        }
                    });
                }
            });

            rtmClient.on('ConnectionStateChanged', (newState, reason) => {
                setEvent({
                    event: 'ConnectionStateChanged',
                    data: {
                        newState,
                        reason
                    }
                })
            });

            rtmClient.on('PeersOnlineStatusChanged', (status) => {
                setEvent({
                    event: 'PeersOnlineStatusChanged',
                    data: {
                        status
                    }
                })
            });

            rtmClient.on('TokenExpired', () => {
                setEvent({
                    event: 'TokenExpired',
                    data: null
                })
            });
        }

        return () => {
            if (rtmChannel)
                rtmChannel.removeAllListeners();

            if (rtmClient)
                rtmClient.removeAllListeners();
        }

    }, [
        setEvent,
        rtmClient,
        rtmChannel,
        toggleAudio,
        toggleVideo,
        toggleLive,
        onLeave,
        toggleScreenShare,
        onNotify,
        onCreateReport,
        onStoreReport,
        onUpdatePresence
    ]);

    return {
        events
    }
}
