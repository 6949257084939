import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import useSessionControls from "joynt/meeting/hooks/useSessionControls";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import useCloudRecording from "joynt/meeting/hooks/useCloudRecording";

import {
    useFullscreenSession,
    useMouseActive,
} from "joynt/components/SessionToolbar/hooks";

import { useNode } from "joynt/hooks";
import { TOOLBAR_MOUSE_TIMEOUT } from "joynt/meeting/agora-ng/config";
import { useRouteParam } from "state/hooks";
import { useSessionNotifications } from "joynt/meeting/notifications";

import { Toolbar } from "joynt/components/SessionFlow/Decorators";
import { useNetworkStateFlash } from "joynt/components/SessionToolbar/NetworkState";

import SessionToolbar from "./SessionToolbar";
import useScreenSize from "util/hooks/useScreenSize";

export function SessionToolbarContainer(props) {
    const {
        session,
        event,
        role,
        onLeaveSession,
        onShowSettings,
    } = useMeeting();

    const { info, onSetInfo, isLive } = useSessionState(session);

    const { isDesktop } = useScreenSize();

    const { onToggleLive } = useSessionControls(session);

    const [connectionState] = useConnectionState(session);

    const { onStartRecording, onStopRecording } = useCloudRecording();

    const flags = useFullscreenSession();
    const { workspace } = useNode(session);
    const isMainStage = !!workspace;
    const { active: mouseActive } = useMouseActive(TOOLBAR_MOUSE_TIMEOUT);

    const [hover, setHover] = useState(false);
    const onMouseOver = useCallback(() => setHover(true), [setHover]);
    const onMouseOut = useCallback(() => setHover(false), [setHover]);

    const [id] = useRouteParam("id");
    const { count: hasNotifications } = useSessionNotifications(id);

    const showNetworkQuality = useNetworkStateFlash();
    const show = !!mouseActive;

    const hidden =
        isDesktop &&
        !showNetworkQuality &&
        !show &&
        !hover &&
        !info &&
        !hasNotifications;

    return (
        <Toolbar
            isHidden={hidden}
            hasNotifications={hasNotifications}
            hover={hover}
            id={id}
            session={session}
            event={event}
            role={role}
            isMainStage={isMainStage}
            onLeaveSession={onLeaveSession}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onToggleLive={onToggleLive}
            onStartRecording={onStartRecording}
            onStopRecording={onStopRecording}
            onShowSettings={onShowSettings}
            isLive={isLive}
            info={info}
            onSetInfo={onSetInfo}
            {...flags}
            {...props}
            pending={props.pending}
            showNetworkQuality={showNetworkQuality}
            connectionState={connectionState}
            {...connectionState}
            Component={SessionToolbar}
        />
    );
}

SessionToolbarContainer.propTypes = {
    id: PropTypes.string,
};
SessionToolbarContainer.defaultProps = {};

export default SessionToolbarContainer;
