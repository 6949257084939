import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import { useSelector } from "react-redux";
import { selectTypeSchema } from "playground/designer/Zmap/selectors";
import {
    useApiActions,
    useData,
    useFetch,
    useList,
    usePending,
} from "state/hooks";
import Field from "containers/Field";
import Preloader from "components/Preloader";
import { storeTranslations } from "cms/state/actions/translations";
import Button from "components/Button";

function TranslationField(props) {
    const { type, id } = props;
    const data = useData(props);
    const fieldName = id.split(".").pop();
    return (
        <div className={"rows gap-xs"}>
            <div className={"o-40"}>{id}</div>
            <div className={"cols cols-2 gap-sm"}>
                <Field
                    entityType={type}
                    entityId={id}
                    id={"source"}
                    label={fieldName}
                    InputProps={{ readOnly: true }}
                />
                <Field
                    entityType={type}
                    entityId={id}
                    id={"target"}
                    label={fieldName !== "text" ? data.source : fieldName}
                />
            </div>
        </div>
    );
}

function makeTranslationsUrl(type, id) {
    const path = type.split(".");
    let url;

    if (path.length > 2) {
        const entryType = path.pop();
        url = path.join("/") + `/:id/translations?type=${entryType}`;
    } else {
        url = path.join("/") + `/:id/translations`;
    }

    return url.replace("/:id", id ? `/${id}` : "");
}

export function PageTranslations(props) {
    const { type, id } = props;
    const url = makeTranslationsUrl(type, id);
    const listId = [type, id].join("_");
    const fetchId = ["app.resource-translations", type, id].join("/");
    const { onStore } = useApiActions({
        onStore: storeTranslations,
    });
    const handleStore = () => onStore(type, id);
    useFetch({
        url,
        type: "app.resource-translations",
        list: listId,
        enable: true,
        yieldData: false,
        fetchId: fetchId,
    });
    const { items } = useList("app.resource-translations", listId);
    const pending = usePending(fetchId, true);
    const validItems = items.filter((i) => !!i && i.indexOf("usedIn") === -1);
    const schema = useSelector((store) => {
        return selectTypeSchema(store, type, (def) => {
            if (def?.properties?.translatable) {
                return def;
            }
            if (def.type === "collection") {
                return def;
            }
            return null;
        });
    });
    return (
        <>
            <Preloader visible={pending} />
            <div className={"rows gap-md"}>
                <div>
                    <div></div>
                </div>
                <Form
                    type={type}
                    id={id}
                    selectFields={{ translations: true }}
                />
                {validItems.map((item) => {
                    return (
                        <TranslationField
                            key={item}
                            type={"app.resource-translations"}
                            id={item}
                        />
                    );
                })}
                <pre>{JSON.stringify(schema, null, 2)}</pre>
            </div>
            <div className={"translations-form-footer"}>
                <Button onClick={handleStore}>Zapisz tłumaczenia</Button>
            </div>
        </>
    );
}

PageTranslations.propTypes = {};

PageTranslations.defaultProps = {};

export default PageTranslations;
