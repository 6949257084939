import React from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
//import MediaBrowserContainer from "containers/media-browser/MediaBrowser";
import MediaBrowserContainer from "playground/media";
import { openMediaLibrary, closeMediaLibrary } from "state/actions/media";

const mapStateToProps = (store, props) => {
    return {
        open: store.ui.media,
    };
};

const mapDispatchToProps = {
    onOpen: openMediaLibrary,
    onClose: closeMediaLibrary,
};

class MediaBrowserDrawer extends React.PureComponent {
    render() {
        const { open, onClose } = this.props;

        return (
            <Drawer open={open} onClose={onClose}>
                <MediaBrowserContainer select={true} />
            </Drawer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaBrowserDrawer);
