import React, {useCallback, useState} from 'react';
import {useUiFlag} from "state/hooks";
import {useAgoraClient, useRTMControls} from "joynt/meeting/agora-ng/hooks";
import Console from "joynt/meeting/debug-console/Console";
//import PropTypes from 'prop-types';

function AgoraDebugConsole(props) {
    const {requestLogs} = useRTMControls();
    const rtcClient = useAgoraClient();
    const [stats, setStats] = useState({});

    const refresh = useCallback(async () => {
        await requestLogs();
        const network = await rtcClient.getRemoteNetworkQuality();
        const audio = await rtcClient.getRemoteAudioStats();
        const video = await rtcClient.getRemoteVideoStats();
        setStats({network, audio, video});
    }, [requestLogs, rtcClient]);

    return <Console
        onRefresh={refresh}
        stats={stats}
        {...props}
    />;
}

export default function SessionDebugConsole(props) {
    const [showConsole, setShowConsole] = useUiFlag('meeting.debug', false);
    return showConsole ? <AgoraDebugConsole onClose={()=>setShowConsole(false)} /> : null;
}

SessionDebugConsole.propTypes = {};
SessionDebugConsole.defaultProps = {};
