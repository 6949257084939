import React from "react";
import cn from "classnames";
import Badge from "@material-ui/core/Badge";

import { MessageTime } from "joynt/components/messages";
import { PillButton } from "../../../../PillButton/PillButton";
import ContextMenu from "components/ContextMenu";
import MessageContextMenu from "joynt/components/messages/message/MessageContextMenu";
import IconButton from "components/IconButton";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

export function MessageHeaderExtended(props) {
    const {
        author,
        avatar,
        className,
        createdAt,
        id,
        isOwn,
        menu,
        notificationStatus,
        unread,
    } = props;

    return (
        <header
            data-private
            className={cn(
                "message__header message__header--extended",
                !avatar && "message__header--extended--no-avatar",
                isOwn && "message__header--own",
                className
            )}
        >
            <div className={"cols  gap-4"}>
                {avatar && (
                    <div className={"message__header__avatar col"}>
                        {avatar}
                    </div>
                )}
                <div className={"message__header__author col"}>{author}</div>
                {createdAt && (
                    <div className={cn("message__header__status")}>
                        <Badge
                            className={"message__header__badge"}
                            invisible={!unread}
                            color={"secondary"}
                            variant={"dot"}
                        >
                            <span
                                className={cn(
                                    "message__header__date",
                                    unread && "message__header__date--unread"
                                )}
                            >
                                <MessageTime
                                    time={createdAt}
                                    format={"short"}
                                />
                            </span>
                        </Badge>
                    </div>
                )}
            </div>
            {(isOwn || menu || notificationStatus) && (
                <div className={"message__header__aside cols gap-2"}>
                    {menu && (
                        <div className={"message__header__menu col"}>
                            {menu.map((menuElement) => (
                                <PillButton {...menuElement} />
                            ))}
                        </div>
                    )}
                    {notificationStatus}
                    {isOwn && (
                        <div className={"message__context-menu col"}>
                            <ContextMenu
                                id={id}
                                RenderMenuItems={MessageContextMenu}
                            >
                                {({ onMenuOpen }) => (
                                    <IconButton
                                        className={
                                            "message__context-menu__icon"
                                        }
                                        icon={"mui-more_vert"}
                                        onClick={onMenuOpen}
                                        size={"small"}
                                    />
                                )}
                            </ContextMenu>
                        </div>
                    )}
                </div>
            )}
        </header>
    );
}

MessageHeaderExtended.propTypes = {
    author: MessagePropTypes.author,
    avatar: MessagePropTypes.avatar,
    className: MessagePropTypes.className,
    createdAt: MessagePropTypes.createdAt,
    id: MessagePropTypes.id,
    isOwn: MessagePropTypes.isOwn,
    menu: MessagePropTypes.menu,
    notificationStatus: MessagePropTypes.notificationStatus,
    unread: MessagePropTypes.unread,
};

export default MessageHeaderExtended;
