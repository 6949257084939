import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { useMedia } from "containers/media";
import FileTypeIcon from "./FileTypeIcon";
import { useClick } from "state/hooks";
import Icon from "components/Icon";
import Preloader from "components/Preloader";

export default function ListFile(props) {
    const {
        file,
        extension,
        fileType,
        id,
        onDelete,
        isPending,
        className,
    } = props;

    const { Render } = useMedia();
    const onClick = useClick(props);
    const handleDelete = useClick({ onClick: onDelete, id });

    return (
        <div className={cn("file-item", className)} onClick={onClick}>
            <div className={"file-item__preview"}>
                {fileType === "image" ? (
                    <Render {...props} />
                ) : (
                    <FileTypeIcon extension={extension} showExtension={false} />
                )}
            </div>
            <div className={"file-item__name"}>{file}</div>
            {onDelete && (
                <div className={"file-item__delete"} onClick={handleDelete}>
                    <Icon>mui-close</Icon>
                </div>
            )}
            {isPending && (
                <Preloader
                    visible={isPending}
                    thickness={2}
                    size={16}
                    color={"inherit"}
                />
            )}
        </div>
    );
}

ListFile.defaultProps = {
    isPending: false,
};

ListFile.propTypes = {
    src: PropTypes.string,
    fileType: PropTypes.string,
    type: PropTypes.oneOf(["image", "video", "document"]),
    isPending: PropTypes.bool,
};
