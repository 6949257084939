import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import PageFormHeader from "cms/ui/forms/PageFormHeader";

import classNames from 'classnames';
import Field from 'containers/Field';
import EntityContext, {AggregateRootContext} from "containers/context/EntityContext";
import {SortableListItemIterator as ListItemIterator} from "components/list/items/ListItem";

import 'css/cms/page-form.css';
import {Icon, Tab, Tabs} from "@material-ui/core";
import {selectLang, selectRouteParam} from "state/selectors/router";
import {pushRouteParams, pushRoute} from "state/actions/router";
import Form from "containers/Form";
import FormButtons from "components/FormButtons";
import Fab from "@material-ui/core/Fab";
import {selectEntityChanges, selectFieldValue} from "state/selectors/data";
import ContextMenuList from "containers/ContextMenuList";
import List from '@material-ui/core/List';
import ListItemMenu from "components/context-menu/ListItemMenu";
import IconButton from "components/IconButton";
import {selectPageUrl} from "cms/state/selectors/cms";

const mapStateToProps = (store, props) => {
    const resource = selectRouteParam(store, 'resource');
    const id = selectRouteParam(store, 'id');
    return {
        tab: selectRouteParam(store, 'tab') || 'sections',
        service: selectRouteParam(store, 'service'),
        resource,
        lang: selectLang(store),
        layout: selectFieldValue(store, resource, props.id, 'layout'),
        hasChanges: Object.keys(selectEntityChanges(store, resource, props.id)).length,
        pageUrl: selectPageUrl(store, resource, id)
    }
};

const mapDispatchToProps = {
    onChangeTab: pushRouteParams,
    onOpenPreview: pushRoute,
    onEditLayout: pushRouteParams
};

const pageFormExclude = {
    'seo': false,
    'open_graph': false,
    'translations': false,
    'slug': false,
    'title': false,
    'active': false,
    'layout': false
};

export function SectionsList({value, onChange, onClick}) {
    const handleSort = useCallback((items) => {
        onChange(items);
    }, [onChange]);
    return <List><ContextMenuList
        type={'cms.sections'}
        items={value}
        onClick={onClick}
        onSortEnd={handleSort}
        Iterator={ListItemIterator}
        RenderMenuItems={ListItemMenu}
    /></List>;
}

class PageForm extends React.PureComponent {

    changeTab = (event, value) => {
        const { onChangeTab } = this.props;
        onChangeTab({tab: value, section: null});
    };

    preview = () => {
        const { preview, pageUrl, onOpenPreview, id, service, lang, resource } = this.props;
        if (preview === 'page') {
            window.open('//'+pageUrl);
            return;
        }
        onOpenPreview(`/${lang}/${service}/front?id=${id}&resource=${resource}&list=sections`);
    };

    onEditLayout = (id) => {
        const { onEditLayout } = this.props;
        onEditLayout({resource:'cms.layouts', id});
    }

    render() {
        const {
            onSubmit,
            onCancel,
            onClose,
            type,
            id,
            layout,
            tab,
            onEditSection,
            onFabClick,
            hasChanges
        } = this.props;
        const entityPath = [type, id].join('/');
        return (<>
            <AggregateRootContext.Provider value={this.submit}>
                <EntityContext.Provider value={entityPath}>
                    <PageFormHeader
                        type={type}
                        id={id}
                        onOpenPreview={this.preview}
                        onClose={onClose}
                    />
                    <div className={'default-c700'}>
                        <Tabs
                            value={tab}
                            indicatorColor={'primary'}
                            onChange={this.changeTab}
                            variant={'scrollable'}
                        >
                            <Tab value={'edit'} label={null} icon={<Icon>edit</Icon>} />
                            <Tab value={'sections'} label={'Sekcje'} />
                            <Tab value={'seo'} label={'SEO'} />
                            <Tab value={'translate'} label={null} icon={<Icon>translate</Icon>} />
                        </Tabs>
                    </div>
                    <div className={'relative grow overflow-scroll'}>
                        {tab === 'sections' ? <>
                            <div className={'pad-sm gap-sm cols cols-middle cols-right'}>
                                <Form
                                    type={type}
                                    id={id}
                                    selectFields={{'layout': true}}
                                    variant={'inline'}
                                />
                                <IconButton
                                    size={'small'}
                                    icon={'mui-edit'}
                                    label={'Edit layout'}
                                    disabled={!layout}
                                    onClick={()=>this.onEditLayout(layout)}
                                />
                            </div>
                            <Field id={'sections'} onClick={onEditSection}>
                                {(props)=><SectionsList {...props} />}
                            </Field>
                        </>: <div className={'pad-md form-regular'}>
                            { tab === 'seo' ? <Form
                                type={type}
                                id={id}
                                selectFields={{'seo': true, 'open_graph': true}}
                            /> : null }
                            { tab === 'translate' ? <Form
                                type={type}
                                id={id}
                                selectFields={{'translations': true}}
                            /> : null }
                            { tab === 'edit' ? <Form
                                type={type}
                                id={id}
                                selectFields={pageFormExclude}
                            /> : null }
                        </div>}
                    </div>
                </EntityContext.Provider>
            </AggregateRootContext.Provider>
            <div className={classNames('list-actions', {
                'hidden': !hasChanges
            })}>
                <div className={classNames('list-buttons default-c500', {
                    'hidden': !hasChanges
                })}>
                    <FormButtons
                        onCancel={onCancel}
                        onSubmit={onSubmit} />
                </div>
            </div>
            { tab === 'sections' ? <div className={classNames('list-actions', {
                'hidden': !!hasChanges
            })}>
                <div className={classNames('list-fab')}>
                    <Fab onClick={onFabClick} color={'secondary'}>
                        <Icon>add</Icon>
                    </Fab>
                </div>
            </div> : null }
        </>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageForm);