import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
//import UserResources from "containers/user-menu/UserResources";
import UserResources from "components/user-menu/UserResourcesGroups";
import ServiceList from "containers/user-menu/ServiceList";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from "@material-ui/core/AppBar";
import FilterField from "containers/FilterField";
import {searchInput} from "playground/ListFormView";
import Icon from "components/Icon";
import UserInfoContainer from "containers/user-menu/UserInfo";
import UserSettingsContainer from "containers/user-menu/UserSettings";
import IconButton from "components/IconButton";

export default function UserMenu(props) {
    const {
        tab,
        resource,
        onClick,
        onChangeTab,
        onShowConsole,
        isAdmin
    } = props;

    const changeTab = useCallback((evt, id)=>onChangeTab(id), [onChangeTab]);
    const showSettings = useCallback(()=>onChangeTab('settings'), [onChangeTab]);

    return (<>
        <div className={'desktop@overflow-hide grow rows'}>
            <AppBar position={'static'} color={'default'}>
                <div className={'cols cols-spread'}>
                    <Tabs
                        value={tab}
                        onChange={changeTab}
                    >
                        <Tab label={"Projects"}
                             icon={<Icon>mui-note</Icon>}
                             value={'projects'}
                        />
                        <Tab
                            label={"Modules"}
                            icon={<Icon>mui-extension</Icon>}
                            value={'modules'}
                        />
                    </Tabs>
                    { isAdmin ? <div className={'pad-sm rows rows-middle'}>
                        <IconButton
                            icon={'mui-build'}
                            label={'Console'}
                            onClick={onShowConsole}
                        />
                    </div> : null }
                </div>
            </AppBar>
            <div className={'grow desktop@overflow-scroll'}>
                <div className={classNames({'hide':tab!=='modules'})}>
                    <div className={'pad-sm form-shadow hide'}>
                        <FilterField
                            {...searchInput}
                            type={'app.user-menu'}
                            id={'search'}
                            label={'Search...'}
                        />
                    </div>
                    <UserResources
                        active={resource}
                        onClick={onClick}
                    />
                </div>
                <div className={classNames({'hide':tab!=='projects', 'desktop@overflow-scroll': true})}>
                    <FilterField
                        {...searchInput}
                        type={'console.services'}
                        list={'console.services'}
                        id={'search'}
                        label={'Search...'}
                    />
                    <ServiceList enable={tab==='projects'} />
                </div>
                { tab === 'settings' ? <UserSettingsContainer /> : null }
            </div>
            <div>
                <UserInfoContainer
                    onShowSettings={showSettings}
                />
            </div>
        </div>
    </>);
}

UserMenu.defaultProps = {
    tab: 0
};

UserMenu.propTypes = {
    resource: PropTypes.string,
    onClick: PropTypes.func,
    onChangeTab: PropTypes.func
};