import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";
import {useMoment} from "joynt/components/DateTimePicker/hooks";
import PostProperties from "joynt/components/Post/PostProperties";
import Html from "components/Html";

function Date({value}) {
    const m = useMoment(value);
    return m.format('DD MMM YYYY');
}

export function DiscussionThread(props) {

    const {
        title,
        children,
        onClick,
        createdAt,
        authorName,
        avatar,
        properties,
        participants
    } = props;

    return (<div className={"discussion-thread"} onClick={onClick}>
        <div className={"discussion-thread__content"}>
            <Html
                value={title}
                className={"discussion-thread__title"}
                sanitizationMode={"plain-text"}
            />
            {children}
        </div>
        <div className={"discussion-thread__info"}>
            <div className={"discussion-thread__date"}>
                <Date value={createdAt} />
            </div>
            <div className={"discussion-thread__author"}>
                {avatar && <div className={"discussion-thread__avatar"}>
                    {avatar}
                </div>}
                <div className={"discussion-thread__author_name"}>
                    {authorName}
                </div>
            </div>
            <div className={"discussion-thread__participants"}>
                {participants}
            </div>
            <PostProperties
                properties={properties}
                layout={"horizontal"}
            />
        </div>
    </div>);

}

DiscussionThread.propTypes = {
    title: PropTypes.string,
    properties: PropTypes.object,
};
DiscussionThread.defaultProps = {
    properties: {}
};

export default DiscussionThread;
