import React, { useMemo } from "react";
import { useData } from "state/hooks";
import { useSelector } from "react-redux";
import { selectAliasedEntity } from "state/selectors/data";
import { ZmapContext } from "./ZmapContext";

//import PropTypes from 'prop-types';

export function SectionContext(props) {
    const { pageType, pageId, sectionId } = props;

    const page = useData({ type: pageType, id: pageId });

    const settings = useSelector((store) => {
        return selectAliasedEntity(store, "cms.settings", "@websiteSettings");
    });

    const section = useData({ type: "cms.sections", id: sectionId });

    const context = useMemo(
        () => ({
            local: { "#schema": "cms.sections", ...section },
            global: {
                page: { "#schema": pageType, ...page },
                global: {
                    settings: { "#schema": "cms.settings", ...settings },
                },
            },
        }),
        [section, pageType, page, settings]
    );

    return (
        <ZmapContext.Provider value={context}>
            {props.children}
        </ZmapContext.Provider>
    );
}

SectionContext.propTypes = {};

SectionContext.defaultProps = {};

export default SectionContext;
