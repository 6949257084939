import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";
import MonthSelect from "joynt/components/DailySchedule/MonthSelect";
import cn from "classnames";
import DatePickerCalendar from "joynt/components/DateTimePicker/DatePicker/DatePickerCalendar";

export function DatePicker(props) {

    const {
        value,
        month,
        year,
        onChangeMonth,
        onChange,
        mode,
        renderDay
    } = props;

    return (<div className={"rows gap-md"}>
        <div className={"rows gap-sm"}>
            <MonthSelect
                value={month}
                year={year}
                onChange={onChangeMonth}
                enableNav={true}
            />
            <div className={cn("calendar", {
                [`mode-${mode}`]: true
            })}>
                <DatePickerCalendar
                    value={value}
                    onChange={onChange}
                    disablePast={false}
                    disableFuture={false}
                    renderDay={renderDay}
                />
            </div>
        </div>
    </div>);

}

DatePicker.propTypes = {};
DatePicker.defaultProps = {};

export default DatePicker;
