import React from 'react';
import PropTypes from 'prop-types';
import WitnessDashboard from "./WitnessDashboard";

export function WitnessDashboardContainer(props) {

    const {children} = props;

    return (<WitnessDashboard {...props}>
        {children}
    </WitnessDashboard>);

}

WitnessDashboardContainer.propTypes = {
    id: PropTypes.string
};
WitnessDashboardContainer.defaultProps = {};

export default WitnessDashboardContainer;