import React, {useCallback, useState} from 'react';
import {useSelector} from "react-redux";
//import PropTypes from 'prop-types';
import {DefaultCollection} from "components/fields/collection/DefaultCollection";
import List from "@material-ui/core/List";
import {selectNestedFormSchema} from "state/selectors/modal";

import 'css/fields/collection-compact.css';
import DenseField from "components/DenseField";

function ModalCollection(props) {
    const firstItem = props.items ? props.items[0] : null;
    const {entityType, entityId, id} = props;
    const [itemId, setId] = useState(firstItem);
    const click = useCallback(id => {
        setId(id)
    }, [setId]);
    const path = itemId
        ? [entityType,entityId,['cms',id].join('.'),itemId].join('|')
        : null;
    const form = useSelector(store => {
        return path
            ? selectNestedFormSchema(store, path)
            : null;
    });
    const FormComponent = form ? form.FormComponent : null;
    return <div className={'ide-type cols'}>
        <List className={'w-sm default-c600'}>
            <DefaultCollection
                {...props}
                onEdit={click}
            />
        </List>
        { itemId ? <div className={'w-md form-compact pad-md'}>
            {path && form ? <FormComponent {...form} /> : null }
        </div> : null }
    </div>;
}

export function ModalCollectionRenderer(props) {
    const { value, label } = props;

    return <DenseField value={value} type={'collection'} label={label}>
        <ModalCollection {...props}/>
    </DenseField>;
}