import React, { useContext, useMemo } from "react";

import useMockEvents from "joynt/meeting/mock/hooks/useMockEvents";
import MockSessionContext from "joynt/meeting/mock/MockSessionContext";
import ProfileInfoOverlay from "joynt/participants/ProfileInfoOverlay";
import ProfileSettings from "joynt/meeting/mock/ProfileSettings";
import Layout from "joynt/components/AppLayout";
import { AgoraStage } from "joynt/meeting/agora-ng/Session";
import VideoTileContainer from "joynt/meeting/mock/VideoTileContainer";
import { AgoraContext } from "joynt/meeting/agora-ng/context/AgoraContext";
import { SessionContext } from "joynt/meeting/agora-ng/context/SessionContext";
import { NotificationsProvider } from "joynt/meeting/notifications";
import MockSessionUtilityNav from "joynt/meeting/mock/MockSessionUtilityNav";
import { useUiFlag } from "state/hooks";
import IconButton from "components/IconButton";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import useScreenSize from "util/hooks/useScreenSize";
import SessionConfigProvider from "joynt/meeting/agora-ng/SessionConfigProvider";

import "./style.css";

function AgoraClientProvider(props) {
    const { children } = props;

    const rtm = {
        on: () => null,
        removeAllListeners: () => null,
        sendMessageToPeer: () => null,
        getChannelAttributesByKeys: () => null,
        setChannelAttributes: () => null,
    };

    const rtmChannel = {
        on: () => null,
        removeAllListeners: () => null,
        sendMessage: () => null,
    };

    const context = {
        rtm,
        rtmChannel,
        screenShare: { status: false },
    };

    return (
        <AgoraContext.Provider value={context}>
            {children}
        </AgoraContext.Provider>
    );
}

function SessionContextProvider(props) {
    const { channel, user, children } = props;

    const { users, activeSpeakers, onPublishEvent } = useMockEvents(
        channel,
        user
    );

    const context = useMemo(
        () => ({
            users,
            activeSpeakers,
            localTracks: [],
            channel,
            onPublishEvent,
        }),
        [users, activeSpeakers, channel, onPublishEvent]
    );

    return (
        <SessionContext.Provider value={context}>
            {children}
        </SessionContext.Provider>
    );
}

export default function Container(props) {
    const { channel } = props;

    return (
        <AgoraClientProvider>
            <NotificationsProvider id={channel}>
                <SessionContextProvider {...props}>
                    <MockSession {...props} />
                </SessionContextProvider>
            </NotificationsProvider>
        </AgoraClientProvider>
    );
}

function useCrossDeviceUtilityNav() {
    const mobile = useUiFlag("mobile.showUtilityNav");
    const desktop = useUtilityNav();
    const { isDesktop } = useScreenSize();
    return isDesktop ? desktop : mobile;
}

export function MockSession(props) {
    const { channel } = props;
    const { onPublishEvent, users, localTracks, activeSpeakers } =
        useContext(SessionContext);

    const [view, setView] = useCrossDeviceUtilityNav();

    return (
        <>
            <SessionConfigProvider channel={channel}>
                <MockSessionContext.Provider value={onPublishEvent}>
                    <ProfileInfoOverlay component={ProfileSettings}>
                        <Layout
                            workspace={true}
                            className={"dev-stage"}
                            utilityNav={
                                <MockSessionUtilityNav
                                    id={channel}
                                    onPublishEvent={onPublishEvent}
                                    view={view}
                                    onChange={setView}
                                />
                            }
                            bottomNav={
                                <div className={"dark-mode"}>
                                    <IconButton
                                        onClick={() => setView("settings")}
                                        icon={"mui-settings"}
                                    />
                                    <IconButton
                                        onClick={() => setView("people")}
                                        icon={"mui-people"}
                                    />
                                </div>
                            }
                        >
                            <AgoraStage
                                {...props}
                                users={users}
                                localTracks={localTracks}
                                activeSpeakers={activeSpeakers}
                                onPublishEvent={onPublishEvent}
                                TileComponent={VideoTileContainer}
                            />
                        </Layout>
                    </ProfileInfoOverlay>
                </MockSessionContext.Provider>
            </SessionConfigProvider>
        </>
    );
}
