import {useEffect} from "react";

export default function useListen(subscription, namespace, handler) {
    useEffect(() => {
        if (subscription) {
            console.log(`Listen for ${namespace} on ${subscription.name}`);
            subscription.listen(namespace, handler);
        }
        return () => {
            if (subscription) {
                console.log(`Stop listening for ${namespace} on ${subscription.name}`);
                subscription.stopListening(namespace, handler);
            }
        }
    }, [subscription, namespace, handler]);
}