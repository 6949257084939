import {useMemo} from 'react';
import moment from "moment";

//import PropTypes from 'prop-types';

export function Hour({value}) {

    const m = useMemo(() => moment.unix(value), [value]);
    return m.format('H:mm');

}

Hour.propTypes = {};
Hour.defaultProps = {};

export default Hour;
