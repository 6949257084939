import {useActions} from "state/hooks";
import {useCallback, useMemo} from "react";
import {createWorkspace} from "joynt/state/actions/nodes";

export function useCreateWorkspace(list) {
    const {onCreate} = useActions({
        onCreate: createWorkspace
    });

    const create = useCallback((parent, data = {}) => {
        onCreate(parent, data, list);
    }, [onCreate, list]);

    return useMemo(()=>({
        onCreate: create
    }), [create]);
}