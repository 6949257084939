import { takeEvery, put, select } from 'redux-saga/effects';
import {getIn} from 'lodash-redux-immutability';
import {pathChange, reject, storeOne} from "state/actions/data";
import {pushRouteParams} from "state/actions/router";
import {selectEntity, selectIsDraft} from "state/selectors/data";
import {selectRouteParam} from "state/selectors/router";
import {deleteSuccess} from "state/actions/delete";

function* handleAppend(action) {
    try {
        const { payload } = action;
        const { path, value } = payload;
        const prev = yield select(store=>getIn(store.data, path));
        const current = yield select(store=>getIn(store.changes, path));
        const use = current || prev || [];
        const next = use.slice();
        if (next.indexOf(value) === -1) {
            next.push(value);
            yield put(pathChange(path, next));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleDetach(action) {
    try {
        const { payload } = action;
        const { path, value } = payload;
        const prev = yield select(store=>getIn(store.data, path));
        const current = yield select(store=>getIn(store.changes, path));
        const use = current || prev || [];
        let next = use.slice();
        if (next.indexOf(value) > -1) {
            next = next.filter(id=>id!==value);
            yield put(pathChange(path, next));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleReject(action) {
    try {
        const { payload } = action;
        const { type, id } = payload;
        const isDraft = yield select(store=>selectIsDraft(store, type, id));
        const routeId = yield select(store=>selectRouteParam(store, 'id'));
        if (isDraft) {
            yield put(deleteSuccess(type, id));
        }
        if (isDraft && routeId === id) {
            yield put(pushRouteParams({id: null}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleRejectRequest(action) {
    try {
        const {type, id} = action.payload;
        yield handleReject(action);
        yield put(reject(type, id));
    } catch (e) {
        console.log(e);
    }
}

function* handlePatch({payload}) {
    try {
        const {type, id, data} = payload;
        const prev = yield select(store=>selectEntity(store, type, id));
        yield put(storeOne(type, id, {...prev, ...data}));
    } catch (e) {
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('DATA.PATH.DETACH', handleDetach);
    yield takeEvery('DATA.PATH.APPEND', handleAppend);
    //yield takeEvery('DATA.REJECT', handleReject);
    yield takeEvery('DATA.REJECT.REQUEST', handleRejectRequest);
    yield takeEvery('DATA.PATCH', handlePatch);
}