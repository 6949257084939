import React from "react";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import { contentViews } from "joynt/config/types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

//import PropTypes from 'prop-types';
const options = Object.values(contentViews);

export function ViewSettings(props) {
    const { id } = props;

    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton
                    label={"View"}
                    size={"small"}
                    icon={"mui-view_stream"}
                    onClick={onClick}
                />
            )}
            content={({ onClose }) => (
                <AggregateRoot type={"db.nodes"} id={id}>
                    <Field id={"content_view"}>
                        {({ onChange, value }) =>
                            options.map((opt) => (
                                <ListItem
                                    button
                                    key={opt.id}
                                    onClick={() => {
                                        onChange(opt.id);
                                        onClose();
                                    }}
                                    selected={value === opt.id}
                                >
                                    <ListItemText primary={opt.label} />
                                </ListItem>
                            ))
                        }
                    </Field>
                </AggregateRoot>
            )}
        />
    );
}

ViewSettings.propTypes = {};
ViewSettings.defaultProps = {};

export default ViewSettings;
