import {useNode} from "joynt/hooks";
import {useActions} from "state/hooks";
import {change} from "state/actions/data";
import {useCallback} from "react";
import {accessTypes} from "./../config";

function selectAccessType(data) {
    const {public: inherit, published} = data;

    const types = Object.values(accessTypes);

    for (let type of types) {
        if (
            type.data.public === !!inherit
            && type.data.published === !!published
        ) {
            return type.id;
        }
    }
    return null;
}

export function useAccessSettings(id) {

    const data = useNode(id);
    const type = selectAccessType(data);

    const {onChange} = useActions({
        onChange: change
    });

    const changeNode = useCallback((data) => {
        onChange("db.nodes", id, data);
    }, [onChange, id]);

    const toggleInherit = useCallback((value) => {
        changeNode({published: value, public: value});
    }, [changeNode]);

    const setType = useCallback((id) => {
        let type = accessTypes[id] || {};
        let data = {
            published: type.data.published || false,
            public: type.data.public || false
        };
        changeNode(data);
    }, [changeNode]);

    return {
        type,
        onChange: setType,
        onToggle: toggleInherit
    }

}