import React, { useState } from "react";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
import SelectList from "playground/theme/StyleEditor/SelectList";
import SelectListItem from "playground/theme/StyleEditor/SelectListItem";

//import PropTypes from 'prop-types';

function CreateForm(props) {
    const {
        value: initialValue,
        onSubmit,
        onCancel,
        label,
        submitLabel,
    } = props;
    const [value, setValue] = useState(initialValue);

    const handleSubmit = () => {
        onSubmit(value);
        setValue("");
        onCancel();
    };

    const disabled = !value || value === initialValue;

    return (
        <div className={"pad-sm theme-dark"}>
            <div onClick={onCancel}>Cancel</div>
            <div>
                <TextField
                    onChange={setValue}
                    value={value}
                    label={label}
                    size={"small"}
                />
                <Button disabled={disabled} onClick={handleSubmit}>
                    {submitLabel}
                </Button>
            </div>
        </div>
    );
}

CreateForm.defaultProps = {
    value: "",
    submitLabel: "Submit",
};

export function FileContextMenu(props) {
    const { id, onCreate, onClose, onRename, onDelete, isProtected } = props;
    const [action, setAction] = useState(null);
    const isDirectory = id !== false && id.indexOf(".") === -1;

    const handleCreate = (value) => {
        const path = [id, value].filter((p) => !!p).join("/");
        onCreate(path);
        setAction(null);
        onClose();
    };

    const handleRename = (value) => {
        onRename(id, value);
        setAction(null);
        onClose();
    };

    const handleDelete = () => {
        onDelete(id);
        onClose();
    };

    if (action === "create") {
        return (
            <CreateForm
                onSubmit={handleCreate}
                onCancel={() => setAction(null)}
                label={"Create file"}
                submitLabel={"Create"}
            />
        );
    }

    if (action === "rename") {
        return (
            <CreateForm
                onSubmit={handleRename}
                onCancel={() => setAction(null)}
                label={"Rename file"}
                submitLabel={"Rename"}
                value={id}
            />
        );
    }

    if (!isDirectory && isProtected) {
        return <div>Protected file</div>;
    }

    if (!isDirectory) {
        return (
            <SelectList>
                <SelectListItem onClick={handleDelete}>Delete</SelectListItem>
                <SelectListItem onClick={() => setAction("rename")}>
                    Rename
                </SelectListItem>
            </SelectList>
        );
    }

    return (
        <SelectList>
            <SelectListItem onClick={() => setAction("create")}>
                Create
            </SelectListItem>
        </SelectList>
    );
}

FileContextMenu.propTypes = {};

FileContextMenu.defaultProps = {};

export default FileContextMenu;
