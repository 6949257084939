import React  from "react";
import PropTypes from 'prop-types';

import {
    POST_TYPE_LINK,
    POST_TYPE_POLL,
    POST_TYPE_WHITEBOARD,
} from "joynt/config";

import List from "containers/List";
import { ArrayIterator } from "components/list/iterator";

import IconButton from "joynt/components/IconButton";
import UploadButton from "components/upload/UploadButton";

import { useClick } from "state/hooks";
import { postTypes } from "joynt/config/types";

const allowedPostTypes = [POST_TYPE_LINK, POST_TYPE_POLL, POST_TYPE_WHITEBOARD];

function PostTypeIcon(props) {
    const {icon, name} = postTypes[props.id];
    const click = useClick(props);
    return (
        <IconButton
            withTooltip
            onClick={click}
            size={"small"}
            icon={icon}
            label={`Add ${name}`}
        />
    );
}

const PostTypeIconsIterator = ArrayIterator(PostTypeIcon);

export default function CreateAttachment(props) {
    const { onCreate, onUpload, postTypes } = props;

    return (
        <div className={"cols"}>
            <List
                type={"db.types"}
                items={postTypes}
                onClick={onCreate}
                Iterator={PostTypeIconsIterator}
            />
            {onUpload && <UploadButton
                onUpload={onUpload}
                trigger={
                    <IconButton
                        size={"small"}
                        onClick={() => null}
                        icon={"mui-attach_file"}
                        label={"Upload files"}
                        withTooltip={true}
                    />
                }
            />}
        </div>
    );
}

CreateAttachment.propTypes = {
    onCreate: PropTypes.func,
    onUpload: PropTypes.func,
    postTypes: PropTypes.array
};
CreateAttachment.defaultProps = {
    postTypes: allowedPostTypes
};
