import React, {useCallback} from 'react';
import {useIdentity} from "joynt/hooks/participants";
import {useClick, useCover} from "state/hooks";
import Avatar from "joynt/components/Avatar";
import Button from "components/Button";
import {policies} from "./config";
import RoleSelect from "./RoleSelect";
//import PropTypes from 'prop-types';

export default function Member(props) {
    const {id, scope, identityRole, owner, onAssignRole, onClick} = props;
    const {name, media, company_name, company_role} = useIdentity(props.id);
    const bg = useCover(media, [64, 64]);

    const allowedRoles = policies[scope];

    const assign = useCallback((role) => {
        onAssignRole(id, role);
    }, [id, onAssignRole]);

    const click = useClick(props);

    const role = identityRole ? identityRole(id) : null;

    return <div className={'cols cols-middle cols-spread'}>
        <div className={'cols cols-middle gap-sm'}>
            <div>
                <Avatar
                    src={bg}
                    name={name}
                    size={'medium'} />
            </div>
            <div className={'rows rows-left'}>
                <div className={'type-lg'}>{name}</div>
                {company_name ? <div className={'type-sm o-50'}>{company_role} at {company_name}</div> : null}
            </div>
        </div>
        { onClick ? <div>
            <Button onClick={click}>Add</Button>
        </div> : null }
        { onAssignRole ? <RoleSelect
            onSelect={assign}
            value={role}
            allowedRoles={allowedRoles}
            label={'Add'}
        /> : null }
        { owner ? <div className='role-select primary'>Owner</div> : null }
    </div>;
}

Member.propTypes = {};
Member.defaultProps = {};
