import React from "react";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
//import PropTypes from 'prop-types';

export function FormGroup(props) {
    const { title, children } = props;

    return (
        <div className={"rows form-group gap-md"}>
            <div>{title}</div>
            <FieldsLayout>{children}</FieldsLayout>
        </div>
    );
}

FormGroup.propTypes = {};
FormGroup.defaultProps = {};

export default FormGroup;
