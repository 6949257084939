import React, { useCallback } from "react";
import Chat from "joynt/components/messages/chat/Chat";
import { useMessaging } from "joynt/hooks";
import { usePending, useRouteParam } from "state/hooks";
import { ROUTE_CHANNEL, ROUTE_THREAD } from "joynt/config";
import { CircularProgress } from "@material-ui/core";

export default function ChatPopup(props) {
    const channel = useRouteParam(ROUTE_CHANNEL)[0];
    const thread = useRouteParam(ROUTE_THREAD)[0];
    const pending = usePending("chat");

    const { chatThreadId: id, onShowChat: onClose } = useMessaging({});

    const close = useCallback(() => onClose(null), [onClose]);

    const show = id || pending;

    if (!show || [channel, thread].indexOf(id) > -1) return null;

    return (
        <div className={"chat-popup"}>
            {pending ? (
                <div className={"grow rows rows-center rows-middle"}>
                    <CircularProgress size={20} />
                </div>
            ) : null}
            {id && !pending ? (
                <Chat
                    id={id}
                    pending={pending}
                    onClose={close}
                    variant={"popup"}
                />
            ) : null}
        </div>
    );
}

ChatPopup.propTypes = {};
