import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import "./style.css";

export function RoleTag(props) {
    const { color, children } = props;

    return (
        <div
            className={cn("role-tag", {
                [`role-tag--${color}`]: true,
            })}
        >
            {children}
        </div>
    );
}

RoleTag.propTypes = {
    color: PropTypes.oneOf(["primary"]),
};
RoleTag.defaultProps = {
    color: "primary",
};

export default RoleTag;
