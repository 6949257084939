import React from 'react';
import {connect} from 'react-redux';
import {selectSettingsId} from "cms/state/selectors/cms";
import Form from "containers/Form";

const mapStateToProps = (store, props) => {
    return {
        type: 'cms.settings',
        id: selectSettingsId(store)
    }
};

const mapDispatchToProps = {};

class WebsiteSettings extends React.PureComponent {

    render() {
        const { type, id } = this.props;
        return (<Form type={type} id={id} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WebsiteSettings);