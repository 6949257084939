import React from 'react';
import PropTypes from 'prop-types';

import Button from "components/Button";

import "./style.css";

export function ActionBox(props) {

    const {
        buttonLabel,
        onClick,
        children
    } = props;

    return (<div className={"action-box"}>
        {onClick && <div className={"action-box__button"}>
            <Button onClick={onClick} variant={"outlined"}>
                {buttonLabel}
            </Button>
        </div>}
        <div className={"action-box__text"}>
            {children}
        </div>
    </div>);

}

ActionBox.propTypes = {
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func
};
ActionBox.defaultProps = {};

export default ActionBox;
