import React from "react";
import { connect } from "react-redux";

import List from "containers/List";
import { selectFieldValue } from "state/selectors/data";

const enumValueTypes = {
    "schema.data": "schema.data.value",
};

const mapStateToProps = (store, props) => {
    const { type, id, filter } = props;
    const values = selectFieldValue(store, type, id, "values") || [];
    return {
        type: enumValueTypes[type],
        items: filter ? filter(values) : values,
        filter: null,
    };
};

const mapDispatchToProps = {};

class EnumList extends React.PureComponent {
    render() {
        return <List {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnumList);
