import React  from "react";
import StageOverlay from "joynt/components/FloatingSession/SessionWindow/StageOverlay";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";
import {useSessionLayout} from "joynt/components/SessionLayout";

//import PropTypes from 'prop-types';

export function FloatingOverlay(props) {
    const { Component, ...other } = props;

    const flow = useSessionFlow();
    const data = flow.data || {};

    const {onToggle} = useSessionLayout();

    const link = onToggle;

    const OverlayComponent = data.floatingOverlay;

    if (OverlayComponent)
        return (
            <OverlayComponent {...other} onClick={link} Component={Component} />
        );

    return <Component {...other} onClick={link} />;
}

FloatingOverlay.propTypes = {};
FloatingOverlay.defaultProps = {
    Component: StageOverlay,
};

export default FloatingOverlay;
