import {RichUtils} from "draft-js";

export function createSoftNewlinePlugin() {
    return {
        handleReturn: (e, editorState, editor) => {
            if (e.shiftKey) {
                editor.setEditorState(
                    RichUtils.insertSoftNewline(editorState)
                );
                return "handled"
            }
            return "unhandled";
        }
    }
}