import React, { useEffect, useState } from "react";
import { Provider, ReactReduxContext } from "react-redux";
import { StyleLegacyContext } from "cms/styles/legacy";
import { renderToStaticMarkup } from "react-dom/server";
import RenderContext, { RENDER_MODE_THUMB } from "cms/context/RenderContext";
import { ApiContext } from "containers/context/ApiContext";
import Section from "cms/containers/Section";
import GlobalScripts from "cms/GlobalScripts";

//import PropTypes from 'prop-types';

const cache = {};
let prevStyles = null;

function renderCached(key, jsx) {
    if (cache[key]) return cache[key];
    // console.log("renderThumbnail");
    cache[key] = renderToStaticMarkup(jsx);
    return cache[key];
}

export function CachedPreview(props) {
    const { id, isVisible, cacheKey } = props;
    const { store } = React.useContext(ReactReduxContext);
    const styles = React.useContext(StyleLegacyContext);
    const api = React.useContext(ApiContext);
    const [dumbState, setDumbState] = useState();

    const key = cacheKey || id;

    useEffect(() => {
        if (styles) prevStyles = styles;
        if (cache[key] && styles !== prevStyles) {
            cache[key] = null;
        }
    }, [key, styles]);

    const wasCached = !!cache[key];

    if (!isVisible) return "";

    const render = () =>
        renderCached(
            key,
            <Provider store={store}>
                <StyleLegacyContext.Provider value={styles}>
                    <ApiContext.Provider value={api}>
                        <RenderContext.Provider value={RENDER_MODE_THUMB}>
                            <Section
                                {...props}
                                renderMode={RENDER_MODE_THUMB}
                            />
                        </RenderContext.Provider>
                    </ApiContext.Provider>
                </StyleLegacyContext.Provider>
            </Provider>
        );

    const clearCache = (e) => {
        e.stopPropagation();
        console.log(key);
        cache[key] = null;
        //render();
        setDumbState(Math.random());
    };

    const rendered = render();

    return (
        <GlobalScripts>
            <div
                className={"section-preview-content theme-scope"}
                dangerouslySetInnerHTML={{ __html: rendered || id }}
            />
            {wasCached && (
                <div
                    className={"section-preview-cached"}
                    onClick={clearCache}
                />
            )}
        </GlobalScripts>
    );
}

CachedPreview.propTypes = {};

CachedPreview.defaultProps = {};

export default CachedPreview;
