import React, { useCallback } from "react";
import PropTypes from "prop-types";

import {
    POST_TYPE_POLL,
    POST_TYPE_LINK,
    POST_TYPE_WHITEBOARD,
    ROUTE_POST,
} from "joynt/config";

import { FEATURE_DEV, FEATURE_TASKS } from "joynt/config/features";

import { useRouteParam } from "state/hooks";
import { usePostEdit } from "joynt/components/Post";
import { usePostCreate } from "./hooks";
import { useFeatureEnabled, useNode } from "joynt/hooks";

import PostCreate from "./PostCreate";

import Tabs from "./Tabs";
import PostCreateHeader from "./PostCreateHeader";
import PostCreateFooter from "./PostCreateFooter";
import PostCreateContent from "./PostCreateContent";

import PostTags from "joynt/components/Post/PostTags";
import CreateAttachment from "joynt/components/Post/CreateAttachment";
import ToggleTask from "joynt/components/Tasks/ToggleTask";

export function PostCreateContainer(props) {
    const { id: parent, list } = props;

    const isDev = useFeatureEnabled(FEATURE_DEV);
    const tasksEnabled = useFeatureEnabled(FEATURE_TASKS);

    const {
        id,
        type,
        onReject: onRejectTmpPost,
        onChangeType,
        posts,
        hasContent,
    } = usePostCreate(parent, list);

    const { node_role: nodeRole } = useNode(id);

    const [, setPost] = useRouteParam(ROUTE_POST);

    const openEdit = useCallback(() => {
        setPost(`edit.${id}`);
    }, [setPost, id]);

    const {
        isValid,
        onCreateContent,
        onSubmit,
        onValidate,
        onUpload,
        onReject,
        onChange,
    } = usePostEdit(id, list, type);

    const reject = useCallback(() => {
        onReject();
        onRejectTmpPost();
    }, [onReject, onRejectTmpPost]);

    const changeType = useCallback(
        (type) => {
            if (type === POST_TYPE_POLL && !posts[type]) {
                onCreateContent(type);
            }
            onChangeType(type);
        },
        [onChangeType, onCreateContent, posts]
    );

    const attach = useCallback(
        (files) => {
            onUpload(files);
        },
        [onUpload]
    );

    const userAttachmentTypes = [POST_TYPE_LINK];
    const devAttachmentTypes = [POST_TYPE_LINK, POST_TYPE_WHITEBOARD];
    const attachmentTypes = isDev ? devAttachmentTypes : userAttachmentTypes;

    return (
        <PostCreate
            tabs={<Tabs value={type} onChange={changeType} />}
            onUpload={onUpload}
        >
            <PostCreateHeader
                id={id}
                parent={parent}
                showPostUnder={isDev}
                onShowPost={openEdit}
            />

            <PostCreateContent
                type={type}
                posts={posts}
                types={attachmentTypes}
                onUpload={onUpload}
            />

            <PostTags id={id} editable={true} />

            <PostCreateFooter
                type={type}
                onReject={hasContent ? reject : null}
                onSubmit={isValid ? onSubmit : onValidate}
            >
                <div className={"cols cols-middle gap-sm"}>
                    <CreateAttachment
                        postTypes={attachmentTypes}
                        onCreate={onCreateContent}
                        onUpload={attach}
                    />
                    {tasksEnabled && (
                        <ToggleTask onChange={onChange} nodeRole={nodeRole} />
                    )}
                </div>
            </PostCreateFooter>
        </PostCreate>
    );
}

PostCreateContainer.propTypes = {
    id: PropTypes.string,
};
PostCreateContainer.defaultProps = {};

export default PostCreateContainer;
