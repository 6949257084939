export default {
    desktop: {
        id: "desktop",
        label: "Desktop",
    },
    tablet: {
        id: "tablet",
        label: "Tablet",
    },
    phone: {
        id: "phone",
        label: "Phone",
    },
};

export const icons = {
    "device.desktop": "desktop_mac",
    "device.tablet": "tablet_mac",
    "device.phone": "phone_iphone",
    "device.all": "devices",
    "device.phone-tablet": "desktop_access_disabled",
    "device.tablet-desktop": "mobile_off",
};

export const breakpointClasses = {
    1: "show-desktop",
    2: "show-phone",
    3: "show-tablet-phone",
    4: "show-tablet",
};

export const breakpoints = {
    1: { id: 1, label: "Desktop", icon: "mui-desktop_mac" },
    2: { id: 2, label: "Phone", icon: "mui-phone_iphone" },
    3: { id: 3, label: "Tablet & Phone", icon: "mui-desktop_access_disabled" },
    4: { id: 4, label: "Tablet & Desktop", icon: "mui-mobile_off" },
};
