import React from "react";
import cn from "classnames";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

export function VideoButton(props) {
    const { onClick, disabled, on, isPending, size } = props;

    return (
        <div>
            <IconButton
                size={size}
                withTooltip
                label={on ? "Disable cam" : "Enable cam"}
                icon={on ? "mui-videocam" : "mui-videocam_off"}
                onClick={!isPending ? onClick : null}
                disabled={disabled}
                isPending={isPending && !disabled}
                className={cn("session-mute", { on: !on })}
            />
        </div>
    );
}

VideoButton.propTypes = {};
VideoButton.defaultProps = {
    size: "medium",
};

export default VideoButton;
