import React from 'react';
import {useNodeData} from "joynt/hooks";
import SidebarItem from "../SidebarItem";
import {NodeTypeIcon} from "joynt/components/Sidebar/Icons";

//import PropTypes from 'prop-types';

export function Channel(props) {
    const {id} = props;
    const {name, subtype, published} = useNodeData(id);

    return <SidebarItem
        label={name}
        icon={<NodeTypeIcon
            type={subtype}
            isRestricted={!published}
        />}
        {...props}
    />;
}

Channel.propTypes = {};
Channel.defaultProps = {};

export default Channel;
