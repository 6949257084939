import React from "react";
import ConfirmDialog from "joynt/components/ConfirmDialog";

//import PropTypes from 'prop-types';

export function PublishSession(props) {
    //const {} = props;

    return (
        <ConfirmDialog {...props}>
            <h3>Are you sure you want to publish this session?</h3>
            <p>
                Publishing your session will notify{" "}
                <strong>
                    people in this space or with access to this session.
                </strong>
            </p>
        </ConfirmDialog>
    );
}

PublishSession.propTypes = {};

PublishSession.defaultProps = {
    title: "Save and publish",
    confirmText: "Publish now",
    cancelText: "Back to settings",
};

export default PublishSession;
