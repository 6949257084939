import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
//import PropTypes from 'prop-types';

import Preloader from "components/Preloader";
import interfaceConfigOverwrite from "./interface-config.js";
import SessionToolbar from "joynt/components/SessionToolbar";
import { useActions } from "state/hooks";
import { setFlag as setUiFlag } from "state/actions/ui";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useSelectedDevices } from "joynt/meeting/devices";

export default function JitsiMeet(props) {
    const { session, role, onJoinSession, onLeaveSession } = useMeeting();

    const { name, identityName, isEvent } = useMeetingSession();

    const [devices] = useSelectedDevices();

    const id = session;

    const [jitsiApi, setJitsiApi] = useState(null);
    const [pending, setPending] = useState(true);
    const { setFlag } = useActions({
        setFlag: setUiFlag,
    });
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;
        const domain = "jitsi.joynt.works";
        const options = {
            roomName: id,
            parentNode: ref.current,
            width: "100%",
            height: "100%",
            interfaceConfigOverwrite,
            configOverwrite: {
                enableWelcomePage: false,
                enableClosePage: true,
            },
            onload: () => setPending(false),
        };
        const api = new window.JitsiMeetExternalAPI(domain, options);

        api.addListener("videoConferenceLeft", () => {
            onJoinSession(null);
        });
        api.addListener("audioMuteStatusChanged", ({ muted }) => {
            setFlag("jitsiAudioMuted", muted);
        });
        api.addListener("screenSharingStatusChanged", (event) => {
            console.log("sharing", event);
        });
        api.addListener("tileViewChanged", ({ enabled }) => {
            setFlag("jitsiTileView", enabled);
        });
        api.addListener("videoMuteStatusChanged", ({ muted }) => {
            setFlag("jitsiVideoMuted", muted);
        });

        api.executeCommand("toggleFilmStrip");

        setJitsiApi(api);

        return function cleanup() {
            api.dispose();
        };
    }, [ref, id, setPending, onJoinSession, setFlag]);

    const callApi = useCallback(
        (...args) => {
            if (!jitsiApi) return;
            jitsiApi.executeCommand(...args);
        },
        [jitsiApi]
    );

    useEffect(() => {
        if (!jitsiApi) return () => null;
        console.log("adjust session setup to role");
        const allow = role !== "attendee";
        jitsiApi.isAudioMuted().then((muted) => {
            if (!muted && !allow) jitsiApi.executeCommand("toggleAudio");
        });
        jitsiApi.isVideoMuted().then((muted) => {
            if (!muted && !allow) jitsiApi.executeCommand("toggleVideo");
            if (muted && allow && window.confirm("Enable video?"))
                jitsiApi.executeCommand("toggleVideo");
        });
    }, [jitsiApi, role]);

    useEffect(() => {
        if (!jitsiApi) return () => null;
        jitsiApi.setAudioInputDevice(null, devices.audioInput);
        jitsiApi.setAudioOutputDevice(null, devices.audioOutput);
        jitsiApi.setVideoInputDevice(null, devices.videoInput);
    }, [jitsiApi, devices]);

    useEffect(() => {
        callApi("subject", name);
    }, [callApi, name]);

    useEffect(() => {
        callApi("displayName", identityName);
    }, [callApi, identityName]);

    const actions = useMemo(
        () => ({
            onToggleVideo: () => callApi("toggleVideo"),
            onToggleScreenShare: () => callApi("toggleShareScreen"),
            onToggleAudio: () => callApi("toggleAudio"),
            onToggleTileView: () => callApi("toggleTileView"),
            onLeaveSession: () => callApi("hangup"),
        }),
        [callApi]
    );

    return (
        <>
            <Preloader visible={pending} />
            <div ref={ref} className={"grow"} />
            <SessionToolbar
                role={role}
                id={id}
                onEndSession={isEvent ? onLeaveSession : null}
                isEvent={isEvent}
                {...actions}
            />
        </>
    );
}

JitsiMeet.propTypes = {};
JitsiMeet.defaultProps = {};
