import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import Icon from "components/Icon";

import "./style.css";

const icons = {
    links: 'mui-link',
    comments: 'mui-comment',
    files: 'mui-attachment',
    reactions: 'mui-add_reaction'
}

export function PostProperties({properties, layout}) {
    const ids = Object.keys(properties);
    return <div className={cn("post-properties", {
        [layout]: true
    })}>
        {ids.map(id => properties[id] ? <div className={"post-property"} data-prop={id}>
            <Icon>{icons[id]}</Icon>
            <div>{properties[id]}</div>
        </div> : null)}
    </div>
}

PostProperties.propTypes = {
    layout: PropTypes.oneOf(["vertical", "horizontal"])
};
PostProperties.defaultProps = {
    layout: "vertical"
};

export default PostProperties;
