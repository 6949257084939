import React from 'react';
//import PropTypes from 'prop-types';
import List from 'containers/List';
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import {ResourceLabeledIterator, ResourceListIterator} from "components/user-menu/UserResources";
import {classes} from "components/user-menu/UserResources";
import 'css/user-menu/user-menu-groups.css';

function UserMenuGroup(props) {
    const { id, label, onClick, variant } = props;
    return <div>
        <div className={'user-menu-group-label'}>{label}</div>
        <div className={classes[variant] || classes.default}>
            <List
                type={'app.user-menu'}
                list={id}
                onClick={onClick}
                Iterator={variant === 'list'
                    ? ResourceListIterator
                    : ResourceLabeledIterator
                } />
        </div>
    </div>;
}

UserMenuGroup.autoFillProps = [
    'label'
];

export const UserMenuGroupIterator = ArrayIterator(withData(UserMenuGroup));

export default class UserResourcesGroups extends React.PureComponent {

    render() {
        const { onClick, ...other } = this.props;
        return (<List
            type={'app.user-menu-groups'}
            list={'default'}
            onClick={onClick}
            Iterator={UserMenuGroupIterator}
            {...other}
        />);
    }

}

UserResourcesGroups.propTypes = {};