import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";

export function PropertyEmptyState(props) {
    const { showLabel, icon, children } = props;

    return (
        <div className={"property-empty"}>
            <div className={"property-empty__icon"}>
                <Icon>{icon}</Icon>
            </div>
            {showLabel && (
                <div className={"property-empty__label"}>{children}</div>
            )}
        </div>
    );
}

PropertyEmptyState.propTypes = {
    icon: PropTypes.string,
    showLabel: PropTypes.bool,
};
PropertyEmptyState.defaultProps = {
    showLabel: false,
};

export default PropertyEmptyState;
