import {
    call,
    put as putEffect
} from 'redux-saga/effects'

import { request } from 'util/api';
import { createUploader } from 'util/saga/upload';
import {authError, logout} from "state/actions/auth";
import {error} from "util/saga/feedback";

//import resourceMapping from 'config/api';
const resourceMapping = {};

const defaultMapping = (resource) => {
    if (!resource) return;

    const exclude = [
        'cms/styles',
        'cms/query'
    ];

    for (let i = 0; i < exclude.length; i++) {
        if (resource.indexOf(exclude[i]) > -1) return {
            path: resource
        }
    }

    const query = resource.split('?')[1];
    const path = resource.split('?')[0];

    let url = path.replace('entries.', 'entries/:id?type=')
            .replace('services.', 'services/:id?type=')
            .replace(/\./g, '/') // + '/:id'

    if (!query) return {path: url};

    return {
        path: [url, query].join(url.indexOf('?') > -1 ? '&' : '?')
    }
};

const resourceToApi = (resource) => {
    return resourceMapping[resource] || defaultMapping(resource);
};

const prepareRequestArgs = (method = 'get', payload = {}, resource, id, context = {}, options) => {
    let {lang, project, accessToken} = context;

    if (!resource) return {};

    let config = resource.indexOf('//') === -1
        ? resourceToApi(resource)
        : {path: resource};

    let headers = project ?
        {'X-zuu-project-id': project}
        : {};
    if (accessToken) headers["Authorization"] = "Bearer " + accessToken;
    if (!config) return;
    let query = {...config.params};
    if (lang) query.lang = lang;
    let path = config.path;
    path = path.replace('/:ctx.id', '/'+context.id);
    path = path.replace(':app', context.app);
    if (path.indexOf(':id') > -1) {
        let useId = id;
        if (useId) {
            path = path.replace(':id', useId);
        }
        path = path.replace('/:id', '');
    } else {
        if (id && method === 'delete') path = path + '/' + id;
    }
    if (method === 'get') query = {...query, ...payload};

    if (config.path.indexOf('http') === 0) {
        return {
            path,
            method,
            query: {},
            payload: {},
            headers: {},
            options
        }
    }

    return {
        path,
        method,
        query,
        payload,
        headers,
        options
    }
};

function* makeRequest(...args) {
    const a = prepareRequestArgs(...args);
    try {
        return yield call(
            request,
            a.path,
            a.method,
            a.query,
            a.payload,
            a.headers,
            a.options
        );
    } catch (e) {
        const status = e.response.status;
        if (status === 401) {
            yield putEffect(authError(e));
            yield putEffect(logout());
            yield error(e);
        }
        if (status === 500) {
            yield error(e);
        }
    }
}

export function* get(...args) {
    return yield call(makeRequest, 'get', {}, ...args);
}

export function* getArgs(...args) {
    return yield call(makeRequest, 'get', ...args);
}

export function* post(...args) {
    return yield call(makeRequest, 'post', ...args);
}

export function* put(...args) {
    return yield call(makeRequest, 'put', ...args);
}

export function* remove(...args) {
    return yield call(makeRequest, 'delete', {}, ...args);
}

export function upload(path, data, context) {
    return createUploader((progress)=>{
        const args = prepareRequestArgs('upload', data, path, null, context, {
            onUploadProgress: progress
        });
        return request(...Object.values(args));
    });
}