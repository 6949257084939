import React from "react";
import { useFeatureEnabled, useNode } from "joynt/hooks";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import SessionPublishedStatus from "./../SessionPublishedStatus";
import SessionDate from "joynt/edit/types/Session/SessionDate";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import Page from "joynt/views/Page";
import SessionActivity from "./../SessionActivity";
import { FEATURE_FOLLOW } from "joynt/config/features";
import ImageField from "joynt/components/Form/ImageField";

//import PropTypes from 'prop-types';

export function SessionSettings(props) {
    const { id } = props;
    const isDev = useFeatureEnabled(FEATURE_FOLLOW);

    const { session_type } = useNode(id);
    const isPrivateSession = session_type === "private";

    return (
        <FieldsLayout>
            {!isPrivateSession && <SessionPublishedStatus {...props} />}

            <FieldGroup label={"Date and time"}>
                <SessionDate id={id} collapsed={true} />
            </FieldGroup>

            <FieldGroup label={"Session title"}>
                <Field id={"name"} label={"Title"} />
                {/*<Field id={'description'} label={'Subtitle'}/>*/}
            </FieldGroup>
            <FieldGroup label={"Cover image"}>
                <ImageField />
            </FieldGroup>
            <FieldGroup label={"Description"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>
            {isDev && <SessionActivity {...props} />}
        </FieldsLayout>
    );
}

SessionSettings.propTypes = {};
SessionSettings.defaultProps = {};

export default SessionSettings;
