import React from "react";
//import PropTypes from 'prop-types';

import { SchemaForm as Form } from "containers/Form";

export function SchemaForm(props) {
    const { type, id } = props;
    return <Form type={type} id={id} />;
}

SchemaForm.propTypes = {};

SchemaForm.defaultProps = {};

export default SchemaForm;
