import uuid from 'uuid/v4';

export const start = (context) => (id, from, to) => ({
    type: 'CALL.START',
    payload: {id: id ? id : uuid(), from, to},
    context
});

export const leave = (context) => (id) => ({
    type: 'CALL.LEAVE',
    payload: {id},
    context
});

export const incoming = (context) => (id, from, to) => ({
    type: 'CALL.INCOMING',
    payload: {id, from, to},
    context
});

export const answer = (context) => (id) => ({
    type: 'CALL.ANSWER',
    payload: {id},
    context
});

export const answered = (context) => (id) => ({
    type: 'CALL.ANSWERED',
    payload: {id},
    context
});

export const hangup = (context) => (id) => ({
    type: 'CALL.HANGUP',
    payload: {id},
    context
});

export const reject = (context) => (id) => ({
    type: 'CALL.REJECT',
    payload: {id},
    context
});

export const end = (context) => (id) => ({
    type: 'CALL.END',
    payload: {id},
    context
});

export const ended = (context) => (id) => ({
    type: 'CALL.ENDED',
    payload: {id},
    context
});

export const event = (context) => (event) => ({
    type: 'CALL.EVENT',
    payload: {...event},
    context
});