import { renderVars } from "./renderVars";
import { renderMessage } from "./renderMessage";

export function renderSegment(segment, args) {
    let next = { ...segment };

    if (next.name) {
        next.name = renderVars(next.name, args);
    }
    if (next.message) {
        next.message = renderVars(next.message, args);
    }
    if (!next.message && next.messages) {
        next.message = renderMessage(next.messages, args);
    }
    if (next.speaker) {
        next.speaker = renderVars(next.speaker, args);
    }
    if (next.peer) {
        next.peer = renderVars(next.peer, args);
    }
    if (next.speaker === args.identity) {
        next.isSpeaker = true;
    }

    next.canProgress = next.isSpeaker || !next.speaker || next.offline;
    next.isAdmin = args.isAdmin;

    if (next.progress === "admin" && !args.isAdmin) next.canProgress = false;

    return next;
}
