import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from "./DateTimePicker";
import {applyMinuteInterval} from "./util";

function relativeHours(start, hours) {
    const date = new Date(start*1000);
    date.setHours(date.getHours()+hours);
    return date.getTime()/1000;
}

export function DateTimePickerContainer(props) {

    const {start, end} = props;

    const initialStart = start || (applyMinuteInterval(new Date(), 15)).getTime()/1000;
    const initialEnd = end || relativeHours(initialStart, 1);

    const [stateStart, setStart] = useState(initialStart);
    const [stateEnd, setEnd] = useState(initialEnd);

    const changeStart = props.onChangeStart || setStart;
    const changeEnd = props.onChangeEnd || setEnd;

    useEffect(() => {
        if (start !== undefined && start !== stateStart) setStart(start);
        if (end !== undefined && end !== stateEnd) setEnd(end);
    }, [start, end, stateStart, stateEnd, setStart, setEnd]);

    const onChangeStart = (v) => {
        const dateChanged = (new Date(v*1000)).getDate() !== (new Date(stateStart*1000)).getDate();
        if ((v >= stateEnd) || dateChanged) {
            const diff = stateEnd - stateStart;
            changeStart(v);
            changeEnd(v + diff);
            return;
        }
        changeStart(v);
    }

    return (<DateTimePicker
        {...props}
        start={stateStart}
        end={stateEnd}
        onChangeStart={onChangeStart}
        onChangeEnd={changeEnd}
    />);

}

DateTimePickerContainer.propTypes = {
    id: PropTypes.string,
    onChangeStart: PropTypes.func,
    onChangeEnd: PropTypes.func
};

DateTimePickerContainer.defaultProps = {};

export default DateTimePickerContainer;