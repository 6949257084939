import React from "react";
import ColorPicker from "./ColorPicker";
import Select from "components/fields/select/Select";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

import "./style.css";

export function ColorScheme(props) {
    const { colors, value, onChange, onImport } = props;
    return (
        <div className={"grid grid-5 grow swatch"}>
            {colors.map((id) => {
                return (
                    <ColorPicker
                        key={id}
                        value={value[id]}
                        label={id.replace("_color", "")}
                        onChange={(v) => onChange(id, v)}
                    />
                );
            })}
            {/*<IconButton icon={'mui-search'} />*/}
            <Select
                source={"api:console.presets"}
                onChange={onImport}
                ValueComponent={({ onClick }) => (
                    <div onClick={onClick}>
                        <IconButton icon={"mui-search"} onClick={() => null} />
                    </div>
                )}
            />
        </div>
    );
}

ColorScheme.propTypes = {};

ColorScheme.defaultProps = {
    colors: [],
    value: {},
};

export default ColorScheme;
