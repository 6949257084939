import React from "react";
import { useFormField } from "state/hooks";
import CodeEditor from "components/fields/code/CodeEditor";

//import PropTypes from 'prop-types';

export function HyperscriptEditor(props) {
    const { value: attrValue, onChange: attrChange } =
        useFormField("attributes");
    const value = attrValue?._ || "";
    const onChange = (v) => {
        attrChange({ ...(attrValue || {}), _: v });
    };
    return <CodeEditor value={value} onChange={onChange} />;
}

HyperscriptEditor.propTypes = {};

HyperscriptEditor.defaultProps = {};

export default HyperscriptEditor;
