const sortFiles = (a, b) => {
    const aIsDirectory = a.isDirectory;
    const bIsDirectory = b.isDirectory;
    if (aIsDirectory === bIsDirectory) {
        return a.name.localeCompare(b.name);
    }
    if (aIsDirectory && !bIsDirectory) {
        return -1;
    }
    if (!aIsDirectory && bIsDirectory) {
        return 1;
    }
    return 0;
};

const STRIP_PREFIX = "themes/";
const STRIP_PREFIX_TREE = STRIP_PREFIX + "test/";

export function relativePath(path) {
    if (!path) return path;
    return path.replace(STRIP_PREFIX, "").split("/").slice(2).join("/");
}

export function isThemeRoot(path) {
    return !relativePath(path);
}

export function pathsToTree(objects, rootName) {
    const root = {
        path: "",
        isRoot: true,
        name: rootName,
        id: "",
        children: [],
        isDirectory: true,
        expanded: true,
    }; // Initialize the root of the tree

    // Helper function to find or create a child node
    function findOrCreateNode(parent, part, isDirectory, additionalProps = {}) {
        // Check if the node already exists among the children
        let node = parent.children.find((child) => child.name === part);
        const id = additionalProps.id;
        if (!node) {
            // If it doesn't exist, create a new node
            node = {
                name: part,
                children: [],
                expanded: true,
                isDirectory,
                ...additionalProps,
                id: isDirectory ? id.split("/").slice(0, -1).join("/") : id,
            };
            parent.children.push(node);
            parent.children = parent.children.sort(sortFiles);
        }
        return node;
    }

    // Helper function to add a path to the tree
    function addPathToTree(parts, node, originalNode) {
        if (parts.length === 0) return; // Base case: no more parts in the path

        const part = parts.shift(); // Get the first part of the path
        // Determine if the current part represents a directory
        const isDirectory = parts.length > 0;
        const childNode = findOrCreateNode(
            node,
            part,
            isDirectory,
            originalNode
        );

        addPathToTree(parts, childNode, originalNode); // Recurse into the children
    }

    objects.forEach((obj) => {
        // Split the path into parts and add them to the tree, retain all original node properties
        const parts = obj.path.replace(STRIP_PREFIX_TREE, "").split("/");
        addPathToTree(parts, root, obj);
    });

    return [root]; // Return the top-level children, as root is a placeholder
}
