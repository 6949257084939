import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {FILTER_NS} from "config/list-aliases";

import Field from 'containers/Field';

const mapStateToProps = (store, props) => {
    const { type, list } = props;
    return {
        entityType: FILTER_NS,
        entityId: [type, list || 'default'].join('/'),
        submit: false
    }
};

const mapDispatchToProps = {};

class FilterField extends React.PureComponent {

    render() {
        return (<Field {...this.props} />);
    }

}

FilterField.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterField);