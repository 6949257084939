import React from "react";
import { connect } from "react-redux";
import List from "containers/List";
import { ArrayIterator } from "components/list/iterator";
import { selectLinkRef, selectNavItems } from "cms/selectors/nav";
import Link from "cms/containers/Link";

const mapStateLink = (store, props) => {
    return {
        ...selectLinkRef(store, props),
    };
};

const mapDispatchLink = {};

function NavItem(props) {
    const { className, label } = props;
    return (
        <Link type={props.type} id={props.id} className={className}>
            <span>
                <span className={"nav__item_label c-btn__label"}>{label}</span>
            </span>
        </Link>
    );
}

NavItem.autoFillProps = ["type", "resource_id"];

const ConnectedNavItem = connect(mapStateLink, mapDispatchLink)(NavItem);
const NavItemIterator = ArrayIterator(ConnectedNavItem);

const mapStateToProps = (store, props) => {
    return {
        items: selectNavItems(store, props),
    };
};

const mapDispatchToProps = {};

class Nav extends React.PureComponent {
    render() {
        const { items, classes, inspectId, element_type } = this.props;

        return (
            <div
                data-inspect={inspectId}
                data-type={element_type}
                className={classes.menu}
            >
                <List
                    type={"cms.links"}
                    items={items}
                    className={classes.menu_item}
                    Iterator={NavItemIterator}
                />
            </div>
        );
    }
}

Nav.defaultProps = {
    items: [],
};

Nav.autoFillProps = ["navigation", "template_id"];

const ConnectedNav = connect(mapStateToProps, mapDispatchToProps)(Nav);

ConnectedNav.autoFillProps = Nav.autoFillProps;
export default ConnectedNav;
