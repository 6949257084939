import React, { useMemo, useState, useEffect } from "react";

import FocusFrame, { captureClickEvent } from "./FocusFrame";
import ContextMenu from "./ContextMenu";
import { findCanvasRoot } from "./FocusFrame/dom";

import "./style.css";

function scrollSectionIntoView(section) {
    const root = findCanvasRoot();
    const sectionElement = root.querySelector(
        `[data-inspect='cms.sections/${section}']`
    );
    if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
    }
}

export function FocusManager(props) {
    const { state, setState, children } = props;

    const selected = useMemo(
        () => ({
            section: state.section,
            id: state.focus,
            s: state.style,
            item: state.item,
            iteratorRoot: state.iteratorRoot,
        }),
        [state]
    );

    const { section, scroll, mode } = state;

    useEffect(() => {
        if (!section) return;
        if (!scroll) return;
        scrollSectionIntoView(section);
        setState({ scroll: null });
    }, [section, scroll, setState]);

    const onClick = (evt) => {
        if (state.contextMenu) {
            setState({ contextMenu: null });
            return;
        }
        const result = captureClickEvent(evt, mode);
        //console.log(result);
        if (!result.section) return;
        const { section, component, item, style, iteratorRoot } = result;
        setState({
            section,
            focus: component,
            style: style,
            item,
            iteratorRoot,
            click: result,
            contextMenu: false,
        });
    };

    const onContext = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setState({ click: captureClickEvent(evt, mode), contextMenu: true });
    };

    const onDoubleClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setState({ edit: !state.edit });
    };

    return (
        <>
            {
                <div
                    id={"focus-manager"}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onContextMenu={onContext}
                >
                    {children}
                </div>
            }
            <FocusFrame value={selected} state={state} />
            {state.contextMenu && (
                <ContextMenu
                    state={state.click}
                    editorState={state}
                    setState={setState}
                />
            )}
        </>
    );
}

FocusManager.propTypes = {};

FocusManager.defaultProps = {};

export default FocusManager;
