import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";

export function CalendarIcon(props) {

    const {day} = props;

    return (<div className={"calendar-icon"}>
        <div className={"calendar-icon__edge"} />
        <div className={"calendar-icon__day"}>
            {day}
        </div>
    </div>);

}

CalendarIcon.propTypes = {};
CalendarIcon.defaultProps = {
    day: (new Date()).getDate()
};

export default CalendarIcon;
