import React from "react";
//import PropTypes from 'prop-types';

import IconButton from "components/IconButton";
import Popover from "components/Popover";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import { useActions } from "state/hooks";
import {
    clipboardCopyContent,
    clipboardPasteContent,
} from "cms/state/actions/clipboard";
import { useSelector } from "react-redux";
import { selectFlag } from "state/selectors/ui";

export function CollectionActions(props) {
    const { type, id, path, onChange } = props;

    const { onCopy, onPaste } = useActions({
        onCopy: clipboardCopyContent,
        onPaste: clipboardPasteContent,
    });

    const hasContents = useSelector((state) =>
        selectFlag(state, "clipboard.contents")
    );

    const clear = () => onChange([]);
    const copy = () => onCopy(type, id, path);
    const paste = () => onPaste(type, id, path);

    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton onClick={onClick} icon={"mui-more_horizontal"} />
            )}
            content={({ onClose }) => (
                <SelectList onClick={onClose}>
                    {hasContents && (
                        <SelectListItem onClick={paste}>Wklej</SelectListItem>
                    )}
                    <SelectListItem onClick={copy}>Kopiuj</SelectListItem>
                    <SelectListItem onClick={clear}>Usuń</SelectListItem>
                </SelectList>
            )}
        />
    );
}

CollectionActions.propTypes = {};

CollectionActions.defaultProps = {};

export default CollectionActions;
