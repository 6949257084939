import React from 'react';
import cn from "classnames";

import PropTypes from 'prop-types';

import "./style.css";

export function FilterToggle(props) {

    const {
        value,
        onChange,
        options
    } = props;

    return <div className={"filter-toggle"}>
        {options.map(o => <div
            key={o.id}
            onClick={()=>onChange(o.id)}
            className={cn({"is-selected": value === o.id})}
        >
            {o.label}
        </div>)}
    </div>

}

FilterToggle.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func
};
FilterToggle.defaultProps = {
    options: []
};

export default FilterToggle;
