import React from "react";
import ConfirmDialog from "joynt/components/ConfirmDialog";

//import PropTypes from 'prop-types';

export function UnpublishSession(props) {
    //const {} = props;

    return (
        <ConfirmDialog {...props}>
            <h3>Are you sure you want to unpublish this session?</h3>
            <p>
                Unpublishing your session will{" "}
                <strong>hide the session from the schedule.</strong> Only admins
                can see unpublished sessions.
            </p>
        </ConfirmDialog>
    );
}

UnpublishSession.propTypes = {};
UnpublishSession.defaultProps = {
    title: "Save and unpublish",
    confirmText: "Unpublish now",
    cancelText: "Back to settings",
};

export default UnpublishSession;
