import React from 'react';
import cn from 'classnames';
//import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';

import Section from 'cms/Section';
import IconButton from "components/IconButton";

export default class SectionItem extends React.Component {

    click = () => {
        const { type, id, onClick } = this.props;
        onClick(type, id);
    };

    render() {
        const {
            title,
            description,
            onChangeTemplate,
            selected,
            fieldLabel
        } = this.props;

        const hasLabel = title || description;
        const label = title || description || 'No name';

        return (<Card onClick={this.click} className={cn(
            'cols gap-sm cols-spread',
            {'primary': selected}
        )}>
            <div className={'rows gap-px pad-xs'}>
                <div className={'o-50'}>{fieldLabel}</div>
                <div>
                    {hasLabel ? label : <div className={'list-item-title-placeholder'}>
                        {label}
                    </div>}
                </div>
            </div>
            <div className={'cols cols-bottom'}>
                { onChangeTemplate
                    ? <IconButton
                        label={'Change'}
                        size={'small'}
                        icon={'mui-more_horiz'}
                        onClick={onChangeTemplate}
                    />
                    : null }
            </div>
        </Card>);
    }

}

SectionItem.propTypes = {};
SectionItem.autoFillProps = Section.autoFillProps;