import React from 'react';
import {useFeatureEnabled} from "joynt/hooks";
import {FEATURE_OPTIMIZED_API} from "joynt/config/features";
import Select from "joynt/components/Select";

//import PropTypes from 'prop-types';

export function PostUnder(props) {

    const { parent, value, onChange } = props;
    const isDev = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const url = isDev
        ? `v2/joynt/nodes/${parent}/tags?limit=10`
        : `joynt/nodes/${parent}/tags`;

    const list = `tags.${parent}`;

    return (
        <Select
            label={"Post under"}
            id={list}
            url={url}
            type={"db.nodes"}
            list={list}
            value={value}
            onChange={onChange}
            nullable={true}
            variant={"inline"}
        />
    );

}

PostUnder.propTypes = {};
PostUnder.defaultProps = {};

export default PostUnder;
