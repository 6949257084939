import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";

export function SessionControls(props) {
    const {
        isLive,
        hasEnded,
        onJoin,
        onStart,
        onEnd,
        onReset,
        canStart,
        canEnd,
        size,
    } = props;

    return (
        <div className={"cols gap-xs"}>
            {isLive && canEnd && onEnd && (
                <Button color={"default"} onClick={onEnd} size={size}>
                    End session
                </Button>
            )}
            {isLive && onJoin && (
                <Button onClick={onJoin} size={size}>
                    Join now
                </Button>
            )}
            {hasEnded && canStart && onReset && (
                <Button onClick={onReset} size={size}>
                    Schedule again
                </Button>
            )}
            {!isLive && !hasEnded && canStart && onStart && (
                <Button onClick={onStart} size={size}>
                    Start session
                </Button>
            )}
        </div>
    );
}

SessionControls.propTypes = {
    isLive: PropTypes.bool,
    isConnecting: PropTypes.bool,
    onJoin: PropTypes.func,
    onStart: PropTypes.func,
    onLeave: PropTypes.func,
    canStart: PropTypes.bool,
    canEnd: PropTypes.bool,
};

SessionControls.defaultProps = {
    isLive: false,
    isConnecting: false,
    canStart: false,
    canEnd: false,
    size: "large",
};

export default SessionControls;
