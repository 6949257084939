import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectEntity, resolveAlias, selectIsDraft} from 'state/selectors/data';
import EntityContext from "containers/context/EntityContext";
import {isPending} from "state/selectors/ui";

const getFillableProps = (keys, data) => {
    const props = {};
    if (!keys) return props;
    keys.forEach(key => {
        if (data[key]) props[key] = data[key];
    });
    return props;
};

const mapStateToProps = (store, props) => {
    const { type, alias, id, autoFillProps } = props;
    let entityId = alias ? resolveAlias(store, type, alias) : id;
    const data = selectEntity(store, type, entityId);
    return {
        id: entityId,
        data,
        ...getFillableProps(autoFillProps, data),
        isDraft: selectIsDraft(store, type, entityId),
        isPending: isPending(store, [entityId]),
        autoFillProps: null
    }
};

const mapDispatchToProps = {};

class Entity extends React.PureComponent {

    render() {
        return this.props.children(this.props);
    }

}

Entity.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    fetch: PropTypes.oneOf([
        true,
        false,
        'once'
    ])
};

Entity.defaultProps = {
    fetch: false
};

const ConnectedEntity = connect(
    mapStateToProps,
    mapDispatchToProps
)(Entity);

export default ConnectedEntity;

export function withData(Decorated, options = {}) {
    function WithData(props, ref) {
        const {type, id} = props;
        if (!type || !id) return null;
        const entity = <ConnectedEntity
            type={type}
            id={id}
            key={id}
            autoFillProps={Decorated.autoFillProps}
        >
            {(entityProps) => <Decorated
                {...props}
                {...entityProps}
                ref={ref}
            >
                {props.children}
            </Decorated>}
        </ConnectedEntity>;

        if (options.context === false) return entity;

        return <EntityContext.Provider value={[type, id].join('/')}>
            {entity}
        </EntityContext.Provider>;
    }
    return React.forwardRef(WithData);
}