import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNodeData, usePagination, useReaction } from "joynt/hooks";
import {
    useActions,
    useApiActions,
    useData,
    usePending,
    useUiFlag,
} from "state/hooks";
import uuid from "uuid/v4";
import cn from "classnames";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import { createPost, submitPost } from "joynt/state/actions/posts";
import TextInput from "joynt/components/messages/message-form/TextInput";
import { useIdentity } from "joynt/hooks/participants";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
import { usePostComments } from "joynt/components/Post/hooks";
import {
    MessagesEmptyState,
    MessagesList,
} from "joynt/components/messages/chat/MessagesList";
import { ReverseScrollContainer } from "joynt/components/ReverseScrollContainer";
import { MessageForm } from "joynt/components/messages";
import ListReload from "components/list/ListReload";
import Icon from "components/Icon";
import Button from "components/Button";
import Preloader from "components/Preloader";
import List from "containers/List";
import Fetch from "containers/Fetch";
import { registerListProps } from "state/actions/list";
import DateDiff from "components/DateDiff";
import IconButton from "components/IconButton";

import "./style.css";
import {useDeleteNode} from "joynt/hooks/channels";

//import PropTypes from 'prop-types';

function Identity({ id, className }) {
    const { name: identityName } = useIdentity(id);

    return (
        <div className={cn("cols cols-middle gap-xs", className)}>
            <IdentityAvatarContainer id={id} size={"xs"} />
            <heading className={"question__author"}>{identityName}</heading>
        </div>
    );
}

function Question(props) {
    const { id, onReply } = props;
    const { name, identity, created_at } = useData(props);

    const { total, onReaction, you } = useReaction(props);

    const upvote = useCallback(() => {
        onReaction("+1");
    }, [onReaction]);
    const reply = useCallback(() => {
        onReply(id);
    }, [id, onReply]);

    const { count } = usePostComments(id);
    const onDelete = useDeleteNode(id);
    const {can_edit} = useNodeData(id);

    return (
        <article className="question">
            <div className={"cols cols-spread cols-middle"}>
                <Identity className={"question__identity"} id={identity} />
                <div className={"question__timestamp"}>
                    <DateDiff value={created_at} />
                </div>
            </div>
            <section className={"question__content-wrapper"}>
                <p className={"question__content"}>{name}</p>
                <div className={"question__footer"}>
                    <div className={"question__reactions"}>
                        <Button
                            className={cn(
                                "question__reaction",
                                you && "question__reaction--voted"
                            )}
                            onClick={upvote}
                            startIcon={
                                you ? (
                                    <Icon>mui-done</Icon>
                                ) : (
                                    <Icon>mui-arrow_upward</Icon>
                                )
                            }
                            color={"default"}
                            size={"small"}
                        >
                            Vote{" "}
                            <span className={"question__button-counter"}>
                                {total}
                            </span>
                        </Button>
                        <Button
                            className={cn(
                                "question__reaction",
                                count > 0 && "question__reaction--replies"
                            )}
                            onClick={reply}
                            startIcon={<Icon>mui-reply</Icon>}
                            color={"default"}
                            size={"small"}
                        >
                            Reply{" "}
                            <span className={"question__button-counter"}>
                                {count}
                            </span>
                        </Button>
                    </div>

                    {can_edit && <IconButton
                        onClick={onDelete}
                        icon={"mui-delete"}
                        size={"small"}
                        label={"Delete question"}
                    />}
                </div>
            </section>
        </article>
    );
}

const PostIterator = InfiniteScrollIterator(Question);

function ReplyQuestion({ id }) {
    const { name, identity } = useNodeData(id);
    const [show, setShow] = useState(false);

    const toggleButton = (
        <Button variant={"text"} onClick={() => setShow(!show)}>
            {show ? "Hide" : "View"}
        </Button>
    );

    return (
        <div className={"rows type-default question-reply"}>
            <div className={"rows gap-xs pad-xs question-reply__content"}>
                <div className={"cols cols-middle gap-xs"}>
                    <Icon>mui-reply</Icon>
                    <div>You're replying to a question from:</div>
                </div>
                <div className={"rows gap-sm"}>
                    <div className={"cols cols-middle cols-spread"}>
                        <Identity
                            className={"question__identity"}
                            id={identity}
                        />
                        {!show ? toggleButton : null}
                    </div>
                    {show ? (
                        <div className={"rows gap-xs"}>
                            <div>{name}</div>
                            <div className={"cols cols-right"}>
                                {toggleButton}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

function Reply(props) {
    const { id, onReturn } = props;
    const isPending = usePending(id);

    const { thread, onCreateMessage } = usePostComments(id, "question");

    return (
        <div className={"relative rows grow question-replies"}>
            <div
                className={
                    "cols cols-left pad-sm-h question__back-button-wrapper"
                }
            >
                <Button
                    startIcon={<Icon>mui-arrow_back</Icon>}
                    onClick={onReturn}
                    variant={"text"}
                >
                    Back to questions
                </Button>
            </div>
            {thread ? (
                <MessagesList
                    id={thread}
                    limit={10}
                    className={"rows grow message-list"}
                    scrollPagination={true}
                />
            ) : (
                <div className={"grow pad-sm message-list"}>
                    {isPending ? (
                        <Preloader visible={true} />
                    ) : (
                        <MessagesEmptyState />
                    )}
                </div>
            )}
            <ReplyQuestion id={id} />
            <MessageForm
                messageId={id}
                parent={id}
                placeholder={"Reply"}
                onCreateMessage={onCreateMessage}
                typingIndication={true}
            />
        </div>
    );
}

export default function Questions(props) {
    const { id } = props;

    const list = ["db.posts", id].join(".");
    const url = `joynt/nodes/${id}/posts-all?limit=10`;
    const type = "db.nodes";

    //const { can_post: canCreatePosts, name } = useNode(id);

    const { onCreate, onRegisterProps } = useActions({
        onCreate: createPost,
        onRegisterProps: registerListProps,
    });
    const { onSubmit } = useApiActions({ onSubmit: submitPost });
    const [question, setQuestion] = useState("");
    const [selected, setSelected] = useUiFlag(`session.qa.reply.${id}`);
    //const [selected, setSelected] = useState(null);

    const back = useCallback(() => setSelected(null), [setSelected]);

    const create = useCallback(() => {
        const data = {
            id: uuid(),
            name: question,
            parent: id,
        };
        const cb = () => {
            setQuestion("");
            onSubmit(data.id);
        };
        onCreate(cb, data, list);
    }, [id, onCreate, onSubmit, list, question, setQuestion]);

    const { page, first, pending } = usePagination(type, list);

    const fetchProps = useMemo(
        () => ({
            type,
            list,
            filter: list,
            append: true,
            url,
        }),
        [type, list, url]
    );

    useEffect(() => {
        onRegisterProps(fetchProps);
    }, [onRegisterProps, fetchProps]);

    if (selected) {
        return <Reply id={selected} onReturn={back} />;
    }

    return (
        <div className={"questions"}>
            <div className={"relative overflow-hide rows grow"}>
                <Fetch {...fetchProps}>
                    <ReverseScrollContainer
                        item={first}
                        pending={pending}
                        className={cn("overflow-scroll grow")}
                        page={page}
                    >
                        <List
                            type={type}
                            list={list}
                            filter={list}
                            reverse={true}
                            useWindowScroll={false}
                            Iterator={PostIterator}
                            pendingState={
                                !page ? <Preloader visible={true} /> : null
                            }
                            emptyState={
                                <MessagesEmptyState text={"no questions yet"} />
                            }
                            pendingReplace={true}
                            className={"questions-list rows rows-bottom gap-sm"}
                            onReply={setSelected}
                        />
                    </ReverseScrollContainer>
                </Fetch>
                <ListReload
                    type={"db.nodes"}
                    id={list}
                    floating={true}
                    text={"New questions"}
                />
            </div>
            <TextInput
                onEnterKey={create}
                onChange={setQuestion}
                value={question}
                placeholder={"Ask a question"}
            />
        </div>
    );
}

Questions.propTypes = {};
Questions.defaultProps = {};
