import React, {useCallback, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import CalendarTimeline from "./CalendarTimeline";
import useSizeObserver from "util/hooks/useSizeObserver";
import DayTrack from "joynt/components/Calendar/DayTrack";
import {getDaysInRange, selectRange} from "joynt/components/DailySchedule/util";

export function CalendarTimelineContainer(props) {

    const {
        day,
        mode,
        list,
        startHour,
        hours
    } = props;

    const days = useMemo(() => {
        let date = new Date(day*1000);
        return getDaysInRange(selectRange(date, mode));
    }, [day, mode]);

    const hoursArray = [];

    for (let i = 0; i < hours; i++) {
        hoursArray.push(startHour+i);
    }

    const ref = useRef();

    const [width, setWidth] = useState(0);

    const onResize = useCallback((e) => {
        let w = e[0].borderBoxSize[0].inlineSize;
        setWidth(w);
    }, [setWidth]);

    useSizeObserver({
        callback: onResize,
        element: ref
    });

    return (<CalendarTimeline
        {...props}
        hours={hoursArray}
        ref={ref}
    >
        {days.map(d => <DayTrack
            key={d}
            list={list}
            time={d.getTime()/1000}
            startHour={startHour}
            hours={hoursArray}
            width={width-108}
        />)}
    </CalendarTimeline>);

}

CalendarTimelineContainer.propTypes = {
    id: PropTypes.string,
    day: PropTypes.number,
    mode: PropTypes.string,
    hours: PropTypes.number,
    startHour: PropTypes.number
};

CalendarTimelineContainer.defaultProps = {
    startHour: 7,
    hours: 12
};

export default CalendarTimelineContainer;