import React from "react";
import Popover from "components/Popover";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FontPreview from "./../FontPreview";
import Tooltip from "@material-ui/core/Tooltip";
//import PropTypes from 'prop-types';

const weightValues = [100, 200, 300, 400, 500, 600, 700, 800];

function FontWeightSelect(props) {
    const { id, value, onChange, source, family } = props;

    const change = (v) => {
        let upd = {};
        upd[id] = v;
        onChange(upd);
    };

    return (
        <Popover
            trigger={({ onClick }) => (
                <FontPreview weight={value} source={source} family={family}>
                    <div className={"preset-font-weight"} onClick={onClick}>
                        {value}
                    </div>
                </FontPreview>
            )}
            content={({ onClose }) => (
                <div className={"select-options"}>
                    {weightValues.map((value) => {
                        return (
                            <ListItem
                                key={value}
                                button
                                onClick={() => {
                                    change(value);
                                    onClose();
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <span
                                            style={{
                                                fontWeight: value,
                                                fontFamily: family,
                                            }}
                                        >
                                            {value}
                                        </span>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </div>
            )}
        />
    );
}

const weights = ["weight_light", "weight_regular", "weight_bold"];

export function FontWeights(props) {
    const { source, family, value, onChange } = props;
    return (
        <div className={"cols gap-xs"}>
            {weights.map((w) => {
                return (
                    <Tooltip key={w} title={w.replace("weight_", "")}>
                        <div>
                            <FontWeightSelect
                                id={w}
                                value={value[w]}
                                onChange={onChange}
                                source={source}
                                family={family}
                            />
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
}

FontWeights.propTypes = {};

FontWeights.defaultProps = {};

export default FontWeights;
