import {isRoleAllowedOnStage} from "joynt/meeting/MeetingContainer";
import useVideoSettings from "joynt/meeting/hooks/useVideoSettings";

export default function useMockSessionContext(channel, role) {
    const {onShowSettings} = useVideoSettings(channel);
    const policy = 'meeting';
    return {
        role,
        session: channel,
        isAllowedOnStage: isRoleAllowedOnStage(policy, role),
        policy,
        onShowSettings
    }
}