import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Tooltip from "@material-ui/core/Tooltip";
import Icon from "components/Icon";

import "./style.css";

const BaseHelperText = React.forwardRef((props, ref) => {
    const { icon, text, children, loudness, ...other } = props;

    return (
        <div
            {...other}
            ref={ref}
            className={cn("helper-text", {
                [`loudness-${loudness}`]: true,
            })}
        >
            {icon && <Icon>{icon}</Icon>}
            {text && <div>{text}</div>}
            {children && <div>{children}</div>}
        </div>
    );
});

export function HelperText(props) {
    const { tooltip, ...other } = props;
    if (!tooltip) return <BaseHelperText {...other} />;

    return (
        <Tooltip
            title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
            classes={{ tooltip: "helper-tooltip" }}
        >
            <BaseHelperText {...other} />
        </Tooltip>
    );
}

HelperText.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    loudness: PropTypes.oneOf([1, 2]),
};

HelperText.defaultProps = {
    icon: "mui-info",
    loudness: 1,
};

export default HelperText;
