import React from 'react';
//import PropTypes from 'prop-types';

import Page from "joynt/views/Page";
import AboutDashboardTile from "joynt/views/about/AboutDashboardTile";

export function About(props) {

    //const {} = props;

    return (<AboutDashboardTile
        header={"About"}
        className={"event-description"}
    >
        <Page {...props} />
    </AboutDashboardTile>);

}

About.propTypes = {};
About.defaultProps = {};

export default About;
