import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useList, useRouteParam, useData, useFetch } from "state/hooks";
import EnumForm from "./EnumForm";

import "./style.css";
import WebsiteSettings from "./WebsiteSettings";
import ContactSettings from "./ContactSettings";
import WebsiteRedirects from "./WebsiteRedirects";
import Integrations from "./../Integrations";
import Resources from "playground/cms/views/Resources";
import Navigation from "../Navigation";
import Translations from "../Translations";
import Icon from "components/Icon";
import { useCreate } from "playground/cms/hooks";

function SettingsTab(props) {
    const { label, icon, onClick, isSelected } = props;
    return (
        <div
            className={cn("settings-view-tab", {
                "is-selected": isSelected,
            })}
            onClick={onClick}
        >
            {icon && (
                <div className={"settings-view-tab-icon"}>
                    <Icon>{icon}</Icon>
                </div>
            )}
            <div className={"settings-view-tab-label"}>{label}</div>
        </div>
    );
}

SettingsTab.propTypes = {
    isSelected: PropTypes.bool,
};

function DefaultSettings({ id: type, Component }) {
    const [id, setId] = useRouteParam("id");
    const { onCreate } = useCreate({ type, setId });
    return (
        <div className={"cols overflow-hide grow"}>
            <Component
                type={type}
                id={id}
                onClick={setId}
                onClose={() => setId(null)}
                onCreate={() => onCreate()}
            />
        </div>
    );
}

DefaultSettings.defaultProps = {
    Component: Resources,
};

function NavigationSettings(props) {
    return <DefaultSettings {...props} Component={Navigation} />;
}

const views = {
    enum: EnumForm,
    website: WebsiteSettings,
    contact: ContactSettings,
    redirects: WebsiteRedirects,
    integrations: Integrations,
    "cms.enum-definitions": EnumForm,
    "cms.integrations": Integrations,
    "cms.tags": DefaultSettings,
    "cms.navigation": NavigationSettings,
    "cms.forms": DefaultSettings,
    "cms.sync-tasks": DefaultSettings,
    "cms.sync-sources": DefaultSettings,
    "cms.translations": Translations,
    "cms.presentation-endpoints": DefaultSettings,
    "cms.value-store": Translations,
};

const labels = {
    navigation: "Nawigacja",
    tags: "Tagi",
    integrations: "Integracje",
    "enum-definitions": "Udogodnienia",
    forms: "Formularze",
    "sync-tasks": "Zadania",
    "sync-sources": "Źródła",
    translations: "Tłumaczenia",
    "presentation-endpoints": "Ekrany",
    "value-store": "Konfiguracja",
};

const icons = {
    navigation: "mui-link",
    tags: "mui-local_offer",
    integrations: "mui-extension",
    "enum-definitions": "mui-ballot",
    forms: "mui-dynamic_form",
    "sync-tasks": "mui-pending_actions",
    "sync-sources": "mui-storage",
    translations: "mui-translate",
    "presentation-endpoints": "mui-tv",
    "value-store": "mui-settings",
};

function SettingsModules(props) {
    const { current, onClick } = props;
    const { items } = useList("app.user-menu", "settings");
    const visibleItems = items.filter(
        (item) => !["settings", "redirects"].includes(item)
    );
    return visibleItems.map((item) => {
        return (
            <SettingsTab
                key={item}
                onClick={() => onClick(`cms.${item}`)}
                icon={icons[item]}
                label={labels[item] || item}
                isSelected={current === `cms.${item}`}
            />
        );
    });
}

export function SettingsView(props) {
    const onClick = () => null;
    const [settingsId, setId] = useRouteParam("s");
    const tab = settingsId ? settingsId.split("/")[0] : "website";

    const ViewComponent = views[tab];

    return (
        <>
            <div className={"settings-view-menu"}>
                <SettingsTab
                    onClick={() => setId("website")}
                    label={"Ustawienia strony"}
                    isSelected={tab === "website"}
                    icon={"mui-settings"}
                />
                <SettingsTab
                    onClick={() => setId("contact")}
                    label={"Dane kontaktowe"}
                    isSelected={tab === "contact"}
                    icon={"mui-contact_mail"}
                />
                {/*<SettingsTab*/}
                {/*    onClick={() => setId("redirects")}*/}
                {/*    label={"Przekierowania"}*/}
                {/*    isSelected={tab === "redirects"}*/}
                {/*/>*/}
                <SettingsModules onClick={setId} current={tab} />
            </div>
            <div className={"rows overflow-hide grow"}>
                {ViewComponent ? <ViewComponent id={tab} /> : null}
            </div>
        </>
    );
}

SettingsView.propTypes = {};

SettingsView.defaultProps = {};

export default SettingsView;
