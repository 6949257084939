import {
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_INVITED,
    EVENT_ROLE_MODERATOR,
    EVENT_ROLE_OWNER,
    EVENT_ROLE_SPEAKER
} from "joynt/config/index";

export const canStartSession = [
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_OWNER
];

export const canEndSession = [
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_OWNER
];

export const isAllowedOnStage = [
    EVENT_ROLE_SPEAKER,
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_MODERATOR,
    EVENT_ROLE_OWNER,
    EVENT_ROLE_INVITED
];

export const canManageStage = [
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_OWNER,
    EVENT_ROLE_MODERATOR
];