import ReactDOM from "react-dom";
//import PropTypes from 'prop-types';

export function SessionToolbarPortal(props) {
    return ReactDOM.createPortal(
        props.children,
        document.fullscreenElement || document.body
    );
}

SessionToolbarPortal.propTypes = {};
SessionToolbarPortal.defaultProps = {};

export default SessionToolbarPortal;
