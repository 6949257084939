import React from "react";
import PropTypes from "prop-types";

import SelectList, { SelectListItem } from "playground/ui/SelectList";
import { useData } from "state/hooks";
import IconButton from "components/IconButton";
import { Search } from "playground/cms/List/Filter";

function ValueStoreEntry(props) {
    const { id, onClick } = props;
    const data = useData(props);
    return (
        <SelectListItem onClick={() => onClick(data.key)}>
            {data.key} {data.value}
        </SelectListItem>
    );
}

export function ConfigSearch(props) {
    const { type, isPending, items, onSelect, onClose } = props;
    return (
        <div className={"rows"}>
            <div className={"cols cols-middle"}>
                {onClose && (
                    <IconButton icon={"mui-arrow_back"} onClick={onClose} />
                )}
                <Search type={type} name={"search"} />
            </div>
            <SelectList>
                {isPending && <div>Pending...</div>}
                {items.map((item) => (
                    <ValueStoreEntry
                        key={item}
                        onClick={onSelect}
                        type={"cms.value-store"}
                        id={item}
                    />
                ))}
            </SelectList>
        </div>
    );
}

ConfigSearch.propTypes = {
    items: PropTypes.array,
    isPending: PropTypes.bool,
};

ConfigSearch.defaultProps = {
    items: [],
};

export default ConfigSearch;
