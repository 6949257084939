import React from "react";
import { usePagination } from "state/hooks";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

export function ListPagination(props) {
    const { type, list } = props;
    const {
        page,
        total,
        items,
        loadPage: onSetPage,
    } = usePagination(type, list || type);

    if (!items) return null;
    if (!total || total < 2) return <div>{items}</div>;

    return (
        <div className={"cols cols-middle"}>
            <IconButton
                icon={"mui-first_page"}
                onClick={() => onSetPage(1)}
                disabled={page <= 1}
            />
            <IconButton
                icon={"mui-keyboard_arrow_left"}
                onClick={() => onSetPage(page - 1)}
                disabled={page <= 1}
            />
            <div className={"pad-sm-h"}>
                {page}/{total}
            </div>
            <IconButton
                icon={"mui-keyboard_arrow_right"}
                onClick={() => onSetPage(page + 1)}
                disabled={page >= total}
            />
            <IconButton
                icon={"mui-last_page"}
                onClick={() => onSetPage(total)}
                disabled={page >= total}
            />
        </div>
    );
}

ListPagination.propTypes = {};
ListPagination.defaultProps = {};

export default ListPagination;
