import React, {useCallback, useContext, useMemo, useState} from 'react';
//import PropTypes from 'prop-types';

const VolumeLevelsContext = React.createContext(null);

export function useTrackVolume(id) {
    const {volume, setVolume} = useContext(VolumeLevelsContext);
    const value = volume[id] || 0;
    const set = useCallback(v => setVolume(id, v), [setVolume, id]);
    return [value, set];
}

export default function VolumeLevels(props) {

    const {children} = props;

    const [volume, setVolume] = useState({});

    const setTrackVolume = useCallback((id, level) => {
        setVolume((prev) => ({...prev, [id]: level}));
    }, [setVolume]);

    const context = useMemo(() => ({
        setVolume: setTrackVolume,
        volume
    }), [setTrackVolume, volume]);

    return (<VolumeLevelsContext.Provider value={context}>
        {children}
    </VolumeLevelsContext.Provider>);

}

VolumeLevels.propTypes = {};
VolumeLevels.defaultProps = {};
