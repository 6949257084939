import React, { useMemo } from "react";
import cn from "classnames";
import AudioTrackControl from "joynt/meeting/agora-ng/AudioTrackControl";
import { useIdentity } from "joynt/hooks/participants";
import { useTrackVolume } from "joynt/meeting/stage/VolumeLevels";
//import PropTypes from 'prop-types';

import "./style.css";
import { useSessionContext } from "joynt/meeting/agora-ng/hooks";

function AudioStream(props) {
    const { id, user, visible } = props;
    const [, setVolume] = useTrackVolume(id);
    const { name } = useIdentity(id);

    return (
        <div className={"cols gap-xs"}>
            <div>{name}</div>
            <AudioTrackControl
                id={id}
                visible={visible}
                audioTrack={user?.audioTrack}
                onSetVolume={setVolume}
            />
        </div>
    );
}

function ActiveSpeaker(props) {
    const { id, activeTimestamp } = props;
    const { name } = useIdentity(id);

    return (
        <div>
            {name} {activeTimestamp}
        </div>
    );
}

export default function AudioStreams(props) {
    const { users, user, visible } = props;

    const { activeSpeakers } = useSessionContext();

    const audioUsers = useMemo(() => {
        return users.filter((u) => {
            return u.uid !== user && !!u.audioTrack;
        });
    }, [users, user]);

    return (
        <div
            className={cn("audio-streams", {
                "is-visible": !!visible,
            })}
        >
            <div className={"cols cols-2 gap-md"}>
                <div className={"grid grid-3 gap-md"}>
                    {audioUsers.map((user) => {
                        let userId = user.uid;
                        let identity = userId
                            ? userId.replace("screen_", "")
                            : null;
                        return (
                            <AudioStream
                                key={`${userId}:audio`}
                                user={user}
                                id={identity}
                                visible={visible}
                            />
                        );
                    })}
                </div>
                <div className={"rows gap-md"}>
                    {Object.keys(activeSpeakers).map(function (id) {
                        return (
                            <ActiveSpeaker
                                id={id}
                                activeTimestamp={activeSpeakers[id]}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

AudioStreams.propTypes = {};
AudioStreams.defaultProps = {};
