const nullObj = {};

export const enforceHttps = (url) => {
    if (window.location.href.indexOf("https:") === 0) {
        return url.replace("http:", "https:");
    }
    return url;
};

export const parseQueryString = (queryString) => {
    if (!queryString) return nullObj;
    var query = {};
    var pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
};

export const serializeQueryParams = (obj, prefix) => {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            if (v !== null) {
                str.push(
                    typeof v === "object"
                        ? serializeQueryParams(v, k)
                        : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
            }
        }
    }
    return str.join("&");
};

export const appendQueryParams = (url, params) => {
    let queryString = serializeQueryParams(params);
    if (!queryString) return url;
    let urlParts = url.split("?");
    let base = urlParts[0];
    let baseParams = urlParts[1] || "";
    if (queryString) {
        baseParams = [baseParams, queryString].filter(Boolean).join("&");
    }
    return base + "?" + baseParams;
};
