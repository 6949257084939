import { useSelector } from "react-redux";

import { memoize } from "lodash";
import { selectIdentity } from "joynt/state/selectors/joint";
import { selectCurrentUserId } from "state/selectors/app";

const nullObj = {};

const merge = memoize(function (data, image, user) {
    return {
        ...data,
        isOwn: data.created_by === user,
        image,
    };
});

const selector = function (store, id) {
    const data = selectIdentity(store, id);
    if (!data) return nullObj;

    const user = selectCurrentUserId(store);

    const image =
        data.media && data.media.length
            ? store?.data?.["db.media"]?.[data.media[0]]
            : nullObj;

    return merge(data, image, user);
};

export function useIdentity(id) {
    return useSelector((s) => selector(s, id));
    // const data = useSelector((store) =>
    //     selectEntity(store, "db.identities", id)
    // );
    // const image = useSelector((store) => {
    //     return data.media ? selectEntity(store, "db.media", data.media[0]) : {};
    // });
    // return {
    //     ...data,
    //     image,
    // };
}
