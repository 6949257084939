import React, { useCallback } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import NavGroup from "joynt/components/UtilityNav/NavGroup";
import Button from "joynt/components/UtilityNav/Button";

import { availableViews } from "./config";
import ViewContainer from "./View.container";
import IconButton from "components/IconButton";

import "./style.css";
import { useNotificationCount } from "joynt/hooks/notifications";
import useScreenSize from "util/hooks/useScreenSize";
import OffStageParticipants from "./OffStageParticipants";

function NotificationButton(props) {
    const { id, onClick } = props;

    const [notifications, onSeen] = useNotificationCount(id);

    const click = useCallback(() => {
        onClick();
        onSeen();
    }, [onClick, onSeen]);

    return (
        <Button
            {...props}
            onClick={click}
            hasNotifications={!!notifications}
        />
    );
}

function ButtonContainer(props) {
    const { type, onClick } = props;

    const click = useCallback(() => {
        onClick(type)
    }, [onClick, type]);

    const config = availableViews[type];

    const label = config.label;
    const icon = config.icon;

    const Component = config.notifications
        ? NotificationButton
        : Button;

    return <Component
        {...props}
        label={label}
        icon={icon}
        onClick={click}
    />;
}

function DesktopNav({ onClose, isOpen, children }) {
    return (
        <div className={"session-nav-menu"}>
            {children}
            {isOpen && (
                <NavGroup>
                    <IconButton
                        icon={"mui-arrow_forward"}
                        onClick={onClose}
                        label={"Collapse nav"}
                    />
                </NavGroup>
            )}
        </div>
    );
}

function MobileNav({ onClose, children, view }) {
    return (
        <div className={"session-nav-menu"}>
            <NavGroup>
                <Button
                    icon={"mui-videocam"}
                    onClick={onClose}
                    label={"Video"}
                    isActive={!view}
                />
            </NavGroup>
            {children}
        </div>
    );
}

export function Session(props) {

    const { id, view, views, onOpen, onClose, policy } = props;

    const { isDesktop } = useScreenSize();
    const isOpen = !!view;

    const currentView = views.filter((v) => v.type === view)[0] || {};

    const buttons = views.map(({ type: viewType, id: viewId }) => (
        <ButtonContainer
            key={viewType}
            id={viewId}
            type={viewType}
            onClick={onOpen}
            isActive={view === viewType}
        />
    ));

    const SessionNav = isDesktop ? DesktopNav : MobileNav;

    return (
        <div className={cn("session-nav", {
            "is-open": isOpen,
        })}>
            {!isOpen && policy === null && <OffStageParticipants
                id={id}
            />}
            <div className={"session-nav-content"}>
                {currentView.type && <ViewContainer {...currentView} />}
            </div>
            <SessionNav onClose={onClose} isOpen={isOpen} view={view}>
                <NavGroup>{buttons}</NavGroup>
            </SessionNav>
        </div>
    );
}

Session.propTypes = {
    view: PropTypes.string,
    views: PropTypes.array,
};
Session.defaultProps = {
    views: [],
};

export default Session;
