import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import { useNodeData } from "joynt/hooks";
import { usePending, useRouteParam } from "state/hooks";
import { DEFAULT_CARD_COLOR, ROUTE_POST } from "joynt/config";
import Preloader from "components/Preloader";
import PostContextMenu from "joynt/components/Post/PostContextMenu";
import TaskDue from "joynt/components/Tasks/TaskDue";
import TaskCheck from "joynt/components/Tasks/TaskCheck";

import "./style.css";
import TaskAssignees from "./../TaskAssignees";
import { useSelector } from "react-redux";
import { selectPostContent } from "joynt/state/selectors/joint";
import PostProperties from "joynt/components/Post/PostProperties";

function Card(props) {
    const { className, children, color, onClick } = props;

    return (
        <div className={cn(`tile ${color}`, className)} onClick={onClick}>
            <div className={`tile-border bg-${color}`} />
            <div className={"tile-content"}>{children}</div>
        </div>
    );
}

Card.defaultProps = {
    color: DEFAULT_CARD_COLOR,
};

export function TaskCard(props) {
    const { id } = props;
    const { name, task_complete } = useNodeData(id);
    const { properties } = useSelector((s) => selectPostContent(s, id));
    const [, setPost] = useRouteParam(ROUTE_POST);
    const pending = usePending(id);
    const click = useCallback(() => {
        setPost(id);
    }, [id, setPost]);

    return (
        <Card
            className={"board-card"}
            onClick={click}
            color={task_complete ? "teal" : undefined}
        >
            <Preloader visible={pending} />
            <div className={"cols cols-spread"}>
                <div>
                    <div>{name}</div>
                    {properties && (
                        <PostProperties
                            properties={properties}
                            layout={"horizontal"}
                        />
                    )}
                </div>
                <PostContextMenu id={id} />
            </div>
            <div className={"cols cols-spread cols-middle"}>
                <div className={"cols cols-middle gap-xs"}>
                    <TaskAssignees id={id} />
                    <TaskDue id={id} />
                </div>
                <TaskCheck id={id} />
            </div>
        </Card>
    );
}

TaskCard.propTypes = {};
TaskCard.defaultProps = {};

export default TaskCard;
