import { aliases } from "cms/util/templatable";

export const SEPARATOR = ",";

const ASSIGNMENT = ":";
const ALIAS = "@";
const PATH = ".";
const NAMESPACE = "@";
const OPTIONAL = "?";
const NEGATION = "!";
const FILTER = "|";

class ZmapToken {
    constructor(token = "", source) {
        this.token = token || "";
        this.source = source || token;
    }

    toString() {
        return this.token;
    }

    isAlias() {
        return this.token.indexOf(ALIAS) === 0;
    }

    isList() {
        return this.token.indexOf(SEPARATOR) > 0;
    }

    isAssignment() {
        return this.token.indexOf(ASSIGNMENT) > 0;
    }

    isAssignable() {
        return !this.isList() && !this.isAssignment();
    }

    isValidAlias() {
        if (!this.isAlias()) return false;
        if (!this.isAssignment()) return true;
        const { from } = this.getAssignment();
        const [aliasPart] = from.split(PATH);
        const aliasToken = new ZmapToken(aliases[aliasPart]);
        if (!aliasToken.isAssignable()) {
            throw new Error(
                `Alias ${this.token} resolves to a non-assignable value`
            );
        }
        return true;
    }

    expandAlias() {
        const { from, to } = this.getAssignment();
        const [aliasPart] = from.split(PATH);
        if (!aliases[aliasPart]) return null;
        const aliasStr = from.replace(aliasPart, aliases[aliasPart]);
        const aliasToken = [aliasStr, to].filter(Boolean).join(ASSIGNMENT);
        return new ZmapToken(aliasToken, this.source);
    }

    getAssignment() {
        const [from, to] = this.token.split(ASSIGNMENT).filter(Boolean);
        if (!to && isSimplePath(from)) {
            return { from, to: from, source: this.source };
        }
        return { from, to, source: this.source };
    }

    toList() {
        return this.token
            .split(SEPARATOR)
            .map(
                (t) =>
                    new ZmapToken(
                        t,
                        t.indexOf(ALIAS) === 0 || t.indexOf(ASSIGNMENT) === -1
                            ? t
                            : this.source
                    )
            );
    }
}

function parseToken(token) {
    if (token.isList()) {
        return token.toList().map(parseToken);
    }

    // if alias resolves to a list it's always a list of assignments
    if (token.isValidAlias()) {
        const alias = token.expandAlias();
        return alias ? parseToken(alias) : token;
    }

    return token;
}

function isSimplePath(path) {
    if (!path) return false;

    if (!path.includes) {
        throw new Error(
            `isSimplePath: ${JSON.stringify(path)} is not a string`
        );
    }
    if (!path) return false;
    return (
        !path.includes(PATH) &&
        !path.includes(NAMESPACE) &&
        !path.includes(ALIAS) &&
        !path.includes(OPTIONAL) &&
        !path.includes(NEGATION)
    );
}

function flattenTokensReducer(acc, token) {
    if (token instanceof Array) {
        return acc.concat(token);
    } else {
        acc.push(token);
    }
    return acc;
}

function reduceMappings(tokens) {
    return tokens
        .reduce(flattenTokensReducer, [])
        .map((token) => token.getAssignment());
}

function parseTemplate(str) {
    return parseToken(new ZmapToken(str));
}

const ZMAP_CACHE = {};

export function parseZmap(zmap = "") {
    if (ZMAP_CACHE[zmap]) return ZMAP_CACHE[zmap];
    const result = parseTemplate(zmap);
    const asArray = result instanceof Array ? result : [result];
    const reduced = reduceMappings(asArray);
    ZMAP_CACHE[zmap] = reduced;
    return reduced;
}

function getContextPath(path, context) {
    if (!path.length || !context) return context;
    const [head, ...tail] = path;
    if (typeof context !== "object") {
        throw new Error(`${path} resolved to a non-object-value`);
    }
    if (head in context) {
        return getContextPath(tail, context[head]);
    }
    return null;
}

export function strip(path) {
    if (!path) return path;
    return path.replace(/[?!]/g, "").split("|")[0];
}

export function getPath(inputPath, context) {
    const path = strip(inputPath);

    if (path.indexOf(NAMESPACE) < 1) {
        return getContextPath(path.split(PATH), context.local);
    }

    const [ns, rest] = path.split(NAMESPACE);
    const hasPath = rest.split(PATH).filter(Boolean).length > 0;

    if (!hasPath) return context?.global[ns];
    return getContextPath(rest.split(PATH), context?.global[ns]);
}

export function stringToMap(zmapString) {
    if (!zmapString) return {};
    const parts = zmapString.split(SEPARATOR);
    return parts.reduce(
        (acc, strToken) => {
            const token = new ZmapToken(strToken);
            if (token.isAlias() && !token.isAssignment()) {
                acc["_aliases"].push(strToken);
            }
            const { to } = token.getAssignment();
            const toValue = strip(to);
            if (toValue) {
                acc[toValue] = strToken;
            }
            return acc;
        },
        { _aliases: [] }
    );
}

export function processZmap(zmap, context) {
    return zmap.reduce((acc, { from, to }) => {
        if (to) {
            acc[strip(to)] = getPath(from, context);
        }
        return acc;
    }, {});
}
