import React from "react";
import { useData } from "state/hooks";
import { FormLayout } from "playground/cms/Form";
import Form from "containers/Form";
import Field from "containers/Field";
import AggregateRoot from "containers/AggregateRoot";
import IntegrationLogs from "playground/cms/views/Integrations/IntegrationLogs";

//import PropTypes from 'prop-types';

function Properties(props) {
    const { type, id } = props;
    return (
        <AggregateRoot type={type} id={id}>
            <div className={"cols gap-sm"}>
                <Field
                    id={"properties"}
                    fieldType={"select"}
                    source={"api:properties"}
                    multiple={true}
                    nullable={true}
                    label={"Obiekty"}
                />
                <Field
                    id={"all_properties"}
                    fieldType={"checkbox"}
                    label={"Wszystkie"}
                />
            </div>
        </AggregateRoot>
    );
}
export function IntegrationForm(props) {
    const { type, id, isPending } = props;
    const { type: integrationType } = useData(props);
    return (
        <FormLayout isPending={isPending}>
            <FormLayout.Header>
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content prepend={true}>
                <FormLayout.Tab id={"integration"} label={"Dane"}>
                    <FormLayout.Fieldset pad={true}>
                        <h1>{integrationType}</h1>
                        <Properties {...props} />
                        <Form
                            type={type}
                            id={id}
                            selectFields={{
                                properties: false,
                            }}
                        />
                    </FormLayout.Fieldset>
                </FormLayout.Tab>
                <FormLayout.Tab id={"debug"} label={"Debug"}>
                    <IntegrationLogs id={id} />
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

IntegrationForm.propTypes = {};

IntegrationForm.defaultProps = {};

export default IntegrationForm;
