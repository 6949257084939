import React from 'react';
import Html from "components/Html";
//import PropTypes from 'prop-types';

export default function Paragraph(props) {

    const {text} = props;
    return <Html elementType={'p'} value={text} />;

}

Paragraph.propTypes = {};
Paragraph.defaultProps = {};
