import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useEcho from "joynt/websockets/hooks/useEcho";
import {changed} from "util/changed";
import {NetworkStateContext} from "./context";
//import PropTypes from 'prop-types';

function sessionQualityStatus(quality) {
    const {uplink, downlink} = quality;
    if (!quality.online) return 'offline';
    if (!uplink || !downlink) return null;
    if (uplink <= 2 && downlink <= 2) return 'good';
    if (uplink < 5 && downlink < 5) return 'degraded';
    return 'bad';
}

const defaultValue = {online: true};

export default function NetworkStateProvider(props) {

    const {children} = props;

    const [sessionQuality, setSessionQuality] = useState({});
    const [quality, setQuality] = useState(defaultValue);
    const [devQuality, setDevQuality] = useState(null);
    const [timeOffline, setTimeOffline] = useState(null);
    const Echo = useEcho();

    const updateQuality = useCallback((q) => {
        setQuality((prev) => {
            let next = {...defaultValue, ...prev, ...q};
            return {
                ...next,
                session: sessionQualityStatus(next)
            }
        });
    }, [setQuality]);

    /**
     * Browser api is kinda useless it tells whether
     * the system is connected to any network, not whether it's actually online
     */
    useEffect(() => {
        const onlineListener = () => updateQuality({online: true});
        const offlineListener = () => updateQuality({online: false});

        window.addEventListener('online', onlineListener);
        window.addEventListener('offline', offlineListener);

        return () => {
            window.removeEventListener('online', onlineListener);
            window.removeEventListener('offline', offlineListener);
        }
    }, [Echo, updateQuality, quality]);

    /**
     * Use websocket client to detect online/offline state
     */
    useEffect(() => {
        const onlineListener = () => {
            updateQuality({online: true});
            document.dispatchEvent(new Event('network-online'));
        }
        const offlineListener = () => updateQuality({online: false});
        const reconnectingListener = (attempt) => console.log('WS reconnecting', attempt);

        if (Echo) {
            Echo.connector.socket.on('connect', onlineListener);
            Echo.connector.socket.on('disconnect', offlineListener);
            Echo.connector.socket.on('reconnecting', reconnectingListener);
        }

        return () => {
            if (Echo) {
                Echo.connector.socket.off('connect', onlineListener);
                Echo.connector.socket.off('disconnect', offlineListener);
                Echo.connector.socket.off('reconnecting', reconnectingListener);
            }
        }
    }, [Echo, updateQuality]);

    const displayedQuality = devQuality || quality;

    useEffect(() => {
        let tm = null;
        if (displayedQuality.online) {
            setTimeOffline(null);
        } else {
            tm = !displayedQuality.online ? setInterval(() => {
                setTimeOffline(prev => (prev || 0) + 1);
            }, 1000) : null;
        }
        return () => {
            if (tm) clearInterval(tm);
        }
    }, [displayedQuality.online]);

    useEffect(() => {
        if (changed(['uplink', 'downlink'], quality, sessionQuality)) {
            updateQuality(sessionQuality)
        }
    }, [sessionQuality, quality, updateQuality]);

    const context = useMemo(() => ({
        quality: displayedQuality,
        timeOffline,
        devQuality,
        setQuality: setSessionQuality,
        setDevQuality
    }), [displayedQuality, devQuality, timeOffline]);

    return (<NetworkStateContext.Provider value={context}>
        {children || null}
    </NetworkStateContext.Provider>);

}

NetworkStateProvider.propTypes = {};
NetworkStateProvider.defaultProps = {};
