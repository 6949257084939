import { useModuleEnabled } from "playground/cms/Schema";
import { useFetch } from "state/hooks";

export function useHasProperties() {
    const hasProperties = useModuleEnabled("properties");

    useFetch({
        type: "cms.properties",
        url: "cms/properties",
        list: "cms.properties",
        enable: !!hasProperties,
    });

    return hasProperties;
}
