import React from 'react';

//import PropTypes from 'prop-types';

export function SidebarFooter(props) {

    const {children} = props;
    
    return (<div className={"sidebar-nav__footer"}>
        {children}
    </div>);

}

SidebarFooter.propTypes = {};
SidebarFooter.defaultProps = {};

export default SidebarFooter;
