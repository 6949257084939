import React from 'react';

//import PropTypes from 'prop-types';
import "./style.css";

export function NavGroup(props) {

    const {children} = props;

    return (<div className={"utility-nav-group"}>
        {children}
    </div>);

}

NavGroup.propTypes = {};
NavGroup.defaultProps = {};

export default NavGroup;
