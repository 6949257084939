import React from 'react';
import PropTypes from 'prop-types';
import SessionItem from "./SessionItem";
import {useNodeData} from "joynt/hooks";
import {useClick} from "state/hooks";

export function SessionItemContainer(props) {

    const {id} = props;

    const {
        name,
        event_start,
        event_end,
        color,
        event_state
    } = useNodeData(id);

    const click = useClick(props);

    return (<SessionItem
        {...props}
        onClick={click}
        name={name}
        start={event_start}
        end={event_end}
        color={color}
        isLive={event_state === "live"}
    />);

}

SessionItemContainer.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func
};
SessionItemContainer.defaultProps = {};

export default SessionItemContainer;