import React from "react";
//import PropTypes from 'prop-types';
import Component from "./Component";

export function SectionComponents(props) {
    const { items, ...rest } = props;
    if (!items?.length) return null;
    return (
        <div className={"grid grid-2 gap-sm"}>
            {items.map((item) => {
                return <Component id={item} key={item} {...rest} />;
            })}
        </div>
    );
}

SectionComponents.propTypes = {};

SectionComponents.defaultProps = {
    items: [],
};

export default SectionComponents;
