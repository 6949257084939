import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';
import { Spaces } from "./spaces";
import { SpaceNestingContext } from "joynt/views/index";
import { useNodeData } from "joynt/hooks";
import Fetch from "containers/Fetch";

export default function Workspaces(props) {
    const { id, className } = props;
    const { node_type } = useNodeData(id);
    const isHome = node_type === "root";
    const list = `db.nodes.${id}.workspaces`;

    const url = isHome
        ? `joynt/nodes/${id}/child-workspaces?graph-depth=1&graph-child=true`
        : `joynt/nodes/${id}/child-workspaces`;

    return (
        <SpaceNestingContext.Provider value={true}>
            <Fetch type={"db.nodes"} url={url} list={list} force={true}>
                <Spaces
                    {...props}
                    list={list}
                    className={cn(className, "spaces-grid pad-top-5")}
                    url={url}
                />
            </Fetch>
        </SpaceNestingContext.Provider>
    );
}

Workspaces.propTypes = {};
Workspaces.defaultProps = {};
