export const pending = (id, value) => {
    return {
        type: "UI.PENDING",
        payload: { id, value },
    };
};

export const setFlag = (id, value) => {
    return {
        type: "UI.SET_FLAG",
        payload: { id, value },
    };
};

export const setFlags = (data) => {
    return {
        type: "UI.SET_FLAGS",
        payload: { data },
    };
};

export const feedbackHide = () => {
    return {
        type: "UI.SET_FLAG",
        payload: { id: "snackbar", value: false },
    };
};

export const notify = (message, variant, notification) => {
    if (!variant) variant = "info";
    return {
        type: "UI.NOTIFY",
        payload: { variant, message, notification },
    };
};

export function showEventNotification(event) {
    return {
        type: "UI.EVENT.SHOW_NOTIFICATION",
        payload: {
            ...event.context,
            message: event.message,
        },
    };
}

export const feedback = (message, status) => {
    return {
        type: "UI.FEEDBACK",
        message,
        status,
    };
};

export const confirmShow = (message) => ({ type: "UI.CONFIRM.SHOW", message });
export const confirmHide = () => ({ type: "UI.CONFIRM.HIDE" });
export const confirmYes = () => ({ type: "UI.CONFIRM.YES" });
export const confirmNo = () => ({ type: "UI.CONFIRM.NO" });
export const confirmResponse = (data) => ({
    type: "UI.CONFIRM.RESPONSE",
    payload: { data },
});

export const promptShow = (id) => ({ type: "UI.PROMPT.SHOW", id });
export const promptHide = () => ({ type: "UI.PROMPT.HIDE" });
export const promptConfirm = () => ({ type: "UI.PROMPT.CONFIRM" });
export const promptCancel = () => ({ type: "UI.PROMPT.CANCEL" });

export const showNotification = (id) => ({
    type: "UI.NOTIFICATIONS.ADD",
    payload: { id },
});

export const hideNotification = (id) => ({
    type: "UI.NOTIFICATIONS.HIDE",
    payload: { id },
});

export const setScreenSize = (data) => {
    return {
        type: "UI.SET_SCREEN_SIZE",
        payload: data,
    };
};
