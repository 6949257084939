import React, {useRef} from 'react';
import {Layout} from "joynt/components/SessionFlow/Decorators";
import {StageElement} from "./StageElement";

//import PropTypes from 'prop-types';

export function Session(props) {

    const {
        id,
        session
    } = props;

    const ref = useRef();

    return (<Layout id={id}>
        <StageElement
            id={session}
            className={'stage-video grow'}
            ref={ref}
        />
    </Layout>);

}

Session.propTypes = {};
Session.defaultProps = {};

export default Session;
