import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { ZmapContext } from "./ZmapContext";
import ZmapSuggest from "./ZmapSuggest";

import "./style.css";
import { useSelector } from "react-redux";
import { NS_SCHEMA, NS_SCHEMA_FIELDS } from "state/selectors/schema";
import { useData, useFetch } from "state/hooks";
import { selectAliasedEntity, selectEntity } from "state/selectors/data";
import { parseZmap, processZmap } from "./zmap";

import { selectContext, selectTypeSchema } from "./selectors";

export function ZmapPlayground(props) {
    const [value, onChange] = useState("page@title:title");

    const type = "cms.components.query";
    const schema = useSelector((store) => selectTypeSchema(store, type));

    const pageId = "1c456640-6c9e-48f1-a803-5dafe2cfe7f4";

    useFetch({
        type: "cms.pages",
        id: pageId,
        url: "cms/pages/:id?expand=true",
    });

    const page = useData({ type: "cms.pages", id: pageId });
    const settings = useSelector((store) => {
        return selectAliasedEntity(store, "cms.settings", "@websiteSettings");
    });

    const componentId = "1cfa6fb5-a6a6-4883-b68a-d66c4f8e5820";

    const context = {
        local: {},
        global: {
            page: { "@schema": "cms.pages", ...page },
            global: {
                settings: { "@schema": "cms.settings", ...settings },
            },
        },
    };

    const componentContext = useSelector((store) => {
        try {
            return selectContext(store, context, "cms.components", componentId);
        } catch (e) {
            return { error: e.message };
        }
    });

    const mergedContext = context;

    return (
        <div className={"pad-lg theme-light"}>
            <ZmapContext.Provider value={componentContext}>
                <ZmapSuggest value={value} onChange={onChange} />
            </ZmapContext.Provider>
            <h4>componentContext</h4>
            <pre>{JSON.stringify(componentContext, null, 2)}</pre>
            {/*<pre>{JSON.stringify(mergedContext, null, 2)}</pre>*/}
        </div>
    );
}

ZmapPlayground.propTypes = {};

ZmapPlayground.defaultProps = {};

export default ZmapPlayground;
