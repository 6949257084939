import React from "react";
import { connect } from "react-redux";
import { styled, StyleLegacyContext } from "cms/styles/legacy";

import Section, { SectionPlaceholder } from "cms/Section";
import { ArrayIterator } from "components/list/iterator";

import { withData } from "containers/Entity";
import { MergeRewireContext } from "containers/context/RewireContext";
import { Layout } from "cms/components";
import SectionTemplate from "cms/containers/SectionTemplate";
import { selectEntity, selectFieldValue } from "state/selectors/data";
import { LayoutPlaceholder } from "cms/PageLayout";

const mapStateToProps = (store, props) => {
    let items = selectFieldValue(store, "cms.sections", props.id, "items");
    let hasItems = items && items.length;
    let template = props.previewTemplate || props.data.template;
    let templateData = template
        ? selectEntity(store, "cms.sections", template)
        : {};
    if (templateData.reference_id) template = templateData.reference_id;
    templateData = template
        ? selectEntity(store, "cms.sections", template)
        : {};
    return {
        display: props.template
            ? selectFieldValue(store, "cms.sections", props.template, "display")
            : props.data.display,
        template,
        parentStyles: templateData.styles,
        hasItems,
        hasContent: hasItems || template,
    };
};
const mapDispatchToProps = {};

const StyledSection = styled(Section);

function SectionLayout(props) {
    const { classes } = props;
    if (classes.embed_layout) {
        return (
            <Layout
                type={props.type}
                id={props.template || props.id}
                itemType={"cms.components"}
                classes={props.classes}
                childKey={"items"}
                layoutKey={"layout"}
                areaKey={"area"}
            />
        );
    }
    return (
        <Layout
            type={props.type}
            id={props.template || props.id}
            itemType={"cms.components"}
            classes={props.classes}
            childKey={"items"}
            layoutKey={"columns"}
            areaKey={"column"}
        />
    );
}

function SectionContainer(props, ref) {
    const { active, type, display, classes, id, template, hasContent } = props;
    //const { template } = props.data;
    const value = [type, id].join("/");
    const sectionType = props.data.type;

    if (sectionType === "placeholder") {
        return <LayoutPlaceholder ref={ref} />;
    }

    if (!id) return <div>Section ID not provided</div>;
    if (id.indexOf("@") > -1) return <SectionPlaceholder id={id} ref={ref} />;

    //if (!active) return null;

    if (sectionType === "view")
        return <SectionPlaceholder id={id} ref={ref} type={"view"} />;

    return (
        <>
            <MergeRewireContext value={value}>
                {template ? (
                    <SectionTemplate type={type} id={template}>
                        {(templateProps) => (
                            <StyleLegacyContext.Consumer>
                                {(s) => (
                                    <StyledSection
                                        {...props}
                                        hasContent={hasContent}
                                        style={
                                            props.style || templateProps.style
                                        }
                                        parentStyles={templateProps.styles}
                                        display={display}
                                        template={template}
                                        RenderContent={SectionLayout}
                                        ref={ref}
                                    />
                                )}
                            </StyleLegacyContext.Consumer>
                        )}
                    </SectionTemplate>
                ) : (
                    <StyledSection
                        {...props}
                        template={template}
                        RenderContent={SectionLayout}
                        ref={ref}
                    />
                )}
            </MergeRewireContext>
        </>
    );
}

SectionContainer.autoFillProps = Section.autoFillProps;

const SectionContainerRef = React.forwardRef(SectionContainer);
SectionContainerRef.autoFillProps = SectionContainer.autoFillProps;

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(SectionContainerRef);

ConnectedContainer.autoFillProps = SectionContainerRef.autoFillProps;

export const ConnectedSection = withData(ConnectedContainer);

ConnectedSection.defaultProps = {
    element_type: "section",
};

export const SectionIterator = ArrayIterator(ConnectedSection);

export default ConnectedSection;
