import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { Portal } from "@material-ui/core";

import IdePageLayout from "cms/ui/IdePageLayout";

import Inspector from "cms/ui/containers/Inspector";
import { getClosest } from "util/dom";
import IdeNavContainer from "cms/ui/containers/IdeNav";

import ThemeProvider from "containers/Theme";
import ThemeShadow from "cms/ui/ThemeShadow";
import ChangesSubmit from "cms/ui/containers/ChangesSubmit";

import "cms/ui/css/style.css";
import "cms/ui/css/debug.css";
import "cms/ui/css/components.css";

import IdePageContextMenu from "cms/ui/IdePageContextMenu";

const ref = React.createRef();

function getElements(evt) {
    const shadowRoot = document.getElementById("theme-shadow-root");
    const root = shadowRoot?.shadowRoot || document.body;
    const x = evt.clientX;
    const y = evt.clientY;
    const elements = root.elementsFromPoint(x, y);
    return elements.filter((e) => {
        const inspect = e.getAttribute("data-inspect");
        return !!inspect;
    });
}

export default class IdePage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { highlight: null, menu: null };
    }

    componentDidMount() {
        this.scrollListener = window.addEventListener("scroll", this.scroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollListener);
    }

    scroll = (evt) => {
        if (this.state.inspectable) {
            const rect = this.state.inspectable.getBoundingClientRect();
            this.setState({
                hx: rect.x,
                hy: rect.y,
            });
        }
    };

    click = (evt, alt) => {
        const { devMode, onFocus } = this.props;
        if (this.state.menu) {
            this.setState({ menu: false, highlight: null });
            return;
        }

        let view = "tree";
        let inspector = null;
        let el = evt.target;

        const inspectable = el.getAttribute("data-inspect")
            ? el
            : getClosest(el, "[data-inspect]");

        if (!inspectable) return;

        const inspect = inspectable.getAttribute("data-inspect");
        const sRoot = el.getAttribute("data-s")
            ? el
            : getClosest(el, "[data-s]");
        const s = sRoot ? sRoot.getAttribute("data-s") : null;
        const section =
            el.getAttribute("data-type") === "section"
                ? el
                : getClosest(el, '[data-type="section"]');
        const sectionPath = section.getAttribute("data-inspect");
        const sectionId = sectionPath.split("/")[1];

        if (!devMode) {
            onFocus(
                ["cms.sections", sectionId].join("/"),
                null,
                sectionId,
                "tree",
                "edit"
            );
            return;
        }

        if (alt) {
            let siblings = getElements(evt);
            let paths = siblings.map((e) => {
                return {
                    path: e.getAttribute("data-inspect"),
                    dataPath: e.getAttribute("data-path"),
                    type: e.getAttribute("data-type"),
                    s,
                    section: sectionId,
                };
            });
            this.setState({ menu: paths, x: evt.clientX, y: evt.clientY });
        }
        onFocus(inspect, s, sectionId, view, inspector);
    };

    focusElement = (el) => {};

    rightClick = (evt) => {
        const { devMode } = this.props;
        if (!devMode) return;
        evt.preventDefault();
        this.click(evt, true);
    };

    over = (evt) => {
        const inspectable = getClosest(evt.target, "[data-inspect]");
        if (!inspectable) return;
        this.overElement(inspectable, evt.target);
    };

    overElement = (inspectable, e) => {
        const inspect = inspectable.getAttribute("data-inspect");
        const type = inspectable.getAttribute("data-type");
        if (!inspect) return;
        const rect = inspectable.getBoundingClientRect();
        this.setState({
            inspectable,
            highlight: type + " " + e.className,
            hx: rect.x,
            hy: rect.y,
            width: rect.width,
            height: rect.height,
        });
    };

    overPath = (evt, path) => {
        const root = document.getElementById("theme-shadow-root").shadowRoot;
        const e = root.querySelector('[data-inspect="' + path + '"');
        this.overElement(e, e);
    };

    out = (evt) => {
        //evt.target.className = evt.target.prevClassName;
    };

    render() {
        const {
            inspect,
            className,
            children,
            preset,
            pending,
            service,
            onFocus,
            onSelectMedia,
        } = this.props;

        const { highlight, hx, hy, width, height } = this.state;

        return (
            <ChangesSubmit>
                <IdePageLayout
                    inspector={service ? <Inspector /> : null}
                    nav={service ? <IdeNavContainer /> : null}
                    pending={pending}
                >
                    {service ? (
                        <ThemeShadow service={service}>
                            <ThemeProvider
                                presetId={preset}
                                alias={"@websitePreset"}
                                loadFonts={true}
                                apply={false}
                                target={ref.current}
                            >
                                <div
                                    ref={ref}
                                    className={classNames(
                                        "ide-page",
                                        className
                                    )}
                                    onClick={this.click}
                                    onContextMenu={this.rightClick}
                                    onMouseOver={inspect ? this.over : null}
                                    onMouseOut={inspect ? this.out : null}
                                >
                                    {children}
                                </div>
                            </ThemeProvider>
                        </ThemeShadow>
                    ) : null}

                    {inspect || highlight ? (
                        <Portal container={document.body}>
                            <div
                                className={"primary-f30"}
                                style={{
                                    zIndex: 2,
                                    position: "fixed",
                                    top: hy + "px",
                                    left: hx + "px",
                                    width: width + "px",
                                    height: height + "px",
                                    fontSize: "9px",
                                    pointerEvents: "none",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        padding: "2px 4px",
                                        display: "block",
                                        transform: "translateY(-100%)",
                                    }}
                                    className={"primary"}
                                >
                                    {highlight}
                                </div>
                            </div>
                        </Portal>
                    ) : null}
                </IdePageLayout>
                {this.state.menu ? (
                    <Portal container={document.body}>
                        <IdePageContextMenu
                            {...this.state}
                            onSelectMedia={onSelectMedia}
                            onClose={() =>
                                this.setState({ menu: null, highlight: null })
                            }
                            onFocus={onFocus}
                            onHover={this.overPath}
                        />
                    </Portal>
                ) : null}
            </ChangesSubmit>
        );
    }
}

IdePage.propTypes = {
    inspect: PropTypes.bool,
};

IdePage.defaultProps = {
    inspect: false,
};
