import React, { useMemo } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import { FontPreview } from "components/fields/typography-scheme/TypographySchemeField";
import IconButton from "components/IconButton";

function previewStyle(typeStyle, preset) {
    const fontPreset = preset[`${typeStyle.font}_font`];
    if (!fontPreset)
        return {
            color: "red",
            opacity: 0.5,
        };
    const weight = fontPreset[`weight_${typeStyle.font_weight}`];
    return {
        fontSize: `${typeStyle.font_size}px`,
        letterSpacing: `${typeStyle.letter_spacing}px`,
        lineHeight: `${typeStyle.line_height}em`,
        fontWeight: weight,
        textTransform: typeStyle.text_transform || "none",
    };
}

export default function TypographyStyle(props) {
    const { value, preset, onEdit, isOpen } = props;
    const fontType = value ? value.font : null;
    const font = fontType ? preset[`${fontType}_font`] || {} : {};
    const style = useMemo(() => previewStyle(value, preset), [value, preset]);
    //const style = previewStyle(value, preset);
    return (
        <div className={cn({ "default-c700": isOpen })} onClick={onEdit}>
            <FontPreview
                className={"type-style-preview"}
                style={style}
                family={font.family}
                source={font.source}
            >
                Lorem ipsum dolor
            </FontPreview>
            <div className={"type-style-info cols cols-spread cols-top"}>
                <strong>
                    {value.font_size}px {value.id}
                </strong>
                <div className={"cols"}>
                    <IconButton
                        size={"small"}
                        icon={"mui-edit"}
                        onClick={onEdit}
                    >
                        edit
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

TypographyStyle.defaultProps = {
    value: {},
    preset: {},
};
