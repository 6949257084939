import React from "react";
import domainRouter from "util/domainRouter";
import ApiContext from "containers/context/ApiContext";
import Theme from "joynt/Theme";
import Snackbar from "components/Snackbar/Snackbar.container";
import { useSelector } from "react-redux";
import { isAuthenticated } from "state/selectors/auth";
import Entrypoint from "./Entrypoint";
import env from "util/env";

//import PropTypes from 'prop-types';

const config = domainRouter();
const apiContext = config.apiContext || {};
const ctx = {
    ...apiContext,
    token: env("GUEST_TOKEN"),
};

export function EntrypointPublic(props) {
    const hasAuth = useSelector((s) => isAuthenticated(s));

    if (hasAuth) return <Entrypoint>{props.children}</Entrypoint>;

    return (
        <ApiContext.Provider value={ctx}>
            <Theme>
                {props.children}
                <Snackbar />
            </Theme>
        </ApiContext.Provider>
    );
}

EntrypointPublic.propTypes = {};
EntrypointPublic.defaultProps = {};

export default EntrypointPublic;
