import {useUiFlag} from "state/hooks";
import {useProcessEvents} from "joynt/meeting/hooks/useSessionEvents";

export default function useSessionEvents(props) {

    //const [networkQuality, setNetworkQuality] = useState(null);
    const [activeSpeakers, setActiveSpeakers] = useUiFlag('stage-dev.active', {});
    const [users, setUsers] = useUiFlag('stage-dev.users', []);

    const handleEvent = useProcessEvents({
        ...props,
        setUsers,
        setActiveSpeakers
    });

    return {
        users: users || [],
        activeSpeakers: activeSpeakers || {},
        setUsers,
        setActiveSpeakers,
        handleEvent
    }
}