import Video from "./Video";
import Audio from "./Audio";
import Image from "./Image";
import Default from "./Default";

export default {
    video: Video,
    audio: Audio,
    image: Image,
    document: Default,
    default: Default,
};
