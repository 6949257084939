import AgoraRTC from "agora-rtc-sdk-ng";
import { AGORA_AREAS, AGORA_EXTENDED_CLIENTS, AGORA_LOG_LEVEL } from "./config";
import env from "util/env";
import { createInstance as createRTMInstance } from "agora-rtm-sdk";
import React from "react";
import AgoraClient from "joynt/meeting/agora-ng/AgoraClient";
import { AgoraContext } from "./context/AgoraContext";
import UnsupportedBrowser from "joynt/UnsupportedBrowser";

AgoraRTC.setLogLevel(AGORA_LOG_LEVEL);
AgoraRTC.setArea(AGORA_AREAS);

AgoraRTC.onAutoplayFailed = () => {
    console.log("[agora] audio autoplay failed");
};

const rtcConfig = {
    mode: "rtc",
    codec: "vp8",
};

function createAgoraClient() {
    const appId = env("AGORA_APP_ID");
    const config = {
        appId,
        clients: {
            main: AgoraRTC.createClient(rtcConfig),
            extended: [],
            rtm: createRTMInstance(appId, {}, ["EU"]),
        },
    };
    for (let i = 0; i < AGORA_EXTENDED_CLIENTS; i++) {
        config.clients.extended.push(AgoraRTC.createClient(rtcConfig));
    }
    return config;
}

export const AGORA_SDK_CLIENT_CONFIG = createAgoraClient();

const AGORA_CLIENT =
    // AgoraRTC.checkSystemRequirements() &&
    new AgoraClient(
        AGORA_SDK_CLIENT_CONFIG.appId,
        AGORA_SDK_CLIENT_CONFIG.clients
    );

export default function (props) {
    const { children } = props;

    //if (!AGORA_CLIENT) return <UnsupportedBrowser />;

    return (
        <AgoraContext.Provider value={AGORA_CLIENT}>
            {children}
        </AgoraContext.Provider>
    );
}
