import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { Event } from "./Features";
import FormConsent from "./FormConsent";

export function Form(props) {
    const { children } = props;
    return (
        <>
            {children}
            <Field
                id={"form_type"}
                label={"Form Type"}
                fieldType={"select"}
                source={"schema:data.form-types"}
            />
            <Field
                id={"form_definition"}
                label={"Form Definition"}
                fieldType={"select"}
                source={"api:cms.forms"}
                nullable={true}
            />
            <Field id={"target_id"} label={"Target ID"} fieldType={"text"} />
            <Field
                id={"use_query_string"}
                label={"Use Query String"}
                fieldType={"checkbox"}
            />
            <Field
                id={"enable_tracking"}
                label={"Enable Tracking"}
                fieldType={"checkbox"}
            />
            <Event />
            <FormConsent />
        </>
    );
}

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
