import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
    useFetch,
    useField,
    useList,
    usePending,
    useRouteParam,
} from "state/hooks";
import { Sortable } from "components/dnd";
import Field from "containers/Field";
import { create, pathAppend, pathDetach } from "state/actions/data";
import { useData, useActions, useForm } from "state/hooks";
import uuid from "uuid/v4";
import Icon from "components/Icon";
import Button from "components/Button";
import DragHandle from "@material-ui/icons/DragIndicator";
import Preloader from "components/Preloader";
import IconButton from "components/IconButton";
import { FormLayout } from "playground/cms/Form";

//import PropTypes from 'prop-types';

function IconPickerComponent({ onClick, onOpen, onClear, value, label }) {
    return (
        <div onClick={onOpen} className={"cols gap-xs cols-middle"}>
            {value ? (
                <Icon fontSize={"large"}>{value}</Icon>
            ) : (
                <Icon fontSize={"large"}>mui-more_horiz</Icon>
            )}
        </div>
    );
}
const EnumValue = React.forwardRef((props, ref) => {
    const { id, onClick, onDelete } = props;
    const data = useData({ type: "cms.enum-values", id });
    const { title, icon, external_id, active, fallback_translations } = data;

    return (
        <div className={"enum-value"}>
            <div className={"enum-draggable"} ref={ref}>
                <div className={"drag-handle"}>
                    <DragHandle />
                </div>
                <div className={"enum-icon"}>
                    <Field
                        entityType={"cms.enum-values"}
                        entityId={id}
                        id={"icon"}
                        fieldType={"icon"}
                        PickerComponent={IconPickerComponent}
                    />
                </div>
            </div>
            <div className={"enum-title"}>
                <Field
                    entityType={"cms.enum-values"}
                    entityId={id}
                    id={"title"}
                    type={"text"}
                    size={"small"}
                    placeholder={
                        Object.values(fallback_translations || {})[0]?.title
                    }
                />
            </div>
            <div>
                <Field
                    entityType={"cms.enum-values"}
                    entityId={id}
                    id={"external_id"}
                    type={"text"}
                    label={"external_id"}
                    size={"small"}
                />
            </div>
            <IconButton icon={"mui-clear"} onClick={() => onDelete(id)} />
        </div>
    );
});

const enumTypes = {
    "room-amenities": "Pokoje",
    "conference-amenities": "Konferencje",
};

function EnumTab(props) {
    const { id, isSelected, onClick } = props;
    const { type } = useData({ type: "cms.enum-definitions", id });

    return (
        <FormLayout.Tabs.Tab {...props}>{enumTypes[type]}</FormLayout.Tabs.Tab>
    );
}

EnumTab.propTypes = {
    isSelected: PropTypes.bool,
};

export function EnumForm(props) {
    const { onClose } = props;
    const [settingsId, setId] = useRouteParam("s");
    const enumType = "cms.enum-definitions";
    const listPendingId = [enumType, enumType].join("/");
    useFetch({
        type: enumType,
        url: "cms/enum-definitions",
        list: enumType,
    });
    const { items } = useList(enumType, enumType);
    const tab = settingsId ? settingsId.split("/")[0] : null;
    let id = settingsId ? settingsId.split("/")[1] : null;
    if (!id) id = items[0];

    const data = useData({ type: enumType, id });
    const { type, slug, values } = data;
    const { onCreateEntity, onAttach, onDelete } = useActions({
        onCreateEntity: create,
        onAttach: pathAppend,
        onDelete: pathDetach,
    });
    const onCreate = (path) => {
        const newId = uuid();
        onCreateEntity("cms.enum-values", { id: newId, active: true });
        onAttach(path, newId);
    };
    const path = [enumType, id, "values"];
    const pending = usePending([listPendingId, id], false);
    const { onSubmit, onReject } = useForm(enumType, id, () => null);
    const { onChange, value } = useField(enumType, id, "values");

    return (
        <FormLayout isPending={pending}>
            <FormLayout.Header>
                <FormLayout.Title>
                    <div className={"cols gap-sm cols-middle"}>
                        {onClose && (
                            <IconButton
                                icon={"mui-arrow_back"}
                                onClick={onClose}
                            />
                        )}
                        <div>{slug}</div>
                    </div>
                </FormLayout.Title>
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs>
                        {items.map((item) => (
                            <EnumTab
                                id={item}
                                type={"cms.enum-definitions"}
                                key={item}
                                isSelected={item === id}
                                onClick={() => setId(`enum/${item}`)}
                            />
                        ))}
                    </FormLayout.Tabs>
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                <div className={"pad-md rows"}>
                    <div className={"enum-value-list"}>
                        <Sortable
                            items={values || []}
                            onSortEnd={onChange}
                            onDelete={(item) => onDelete(path, item)}
                            render={EnumValue}
                            type={"cms.enum-values"}
                        />
                    </div>
                </div>
            </FormLayout.Content>
            <FormLayout.Footer>
                <div className={"cols cols-spread grow"}>
                    <Button color={"default"} onClick={() => onCreate(path)}>
                        Dodaj
                    </Button>
                    <div className={"cols gap-xs"}>
                        <Button onClick={() => onSubmit()} size={"large"}>
                            Zapisz
                        </Button>
                    </div>
                </div>
            </FormLayout.Footer>
        </FormLayout>
    );
}

EnumForm.propTypes = {};

EnumForm.defaultProps = {};

export default EnumForm;
