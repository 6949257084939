import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import { useClick, useData, usePending } from "state/hooks";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ContextMenu from "components/ContextMenu";
import IconButton from "components/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Icon from "components/Icon";
import SectionContextMenu from "./ContextMenu";
import SectionIcon from "playground/cms/views/Sections/SectionIcon";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import LayoutSlotItem from "./LayoutSlotItem";
import SectionContentSummary from "./SectionContentSummary";
import { oneOf, oneOfFallback } from "playground/cms/util";

import "./style.css";

function sectionBadge({ isDraft, usedIn }) {
    if (isDraft) return <Icon>mui-edit</Icon>;
    if (usedIn === 0) return <Icon>mui-close</Icon>;
    return usedIn > 1 ? usedIn : null;
}

function selectIsDynamicContent(data) {
    const meta = Object.values(data.metadata || {});
    const lists = meta.filter(
        (n) => ["list", "query"].includes(n.type) && n.entry_type
    );
    if (lists.length === 0) return false;
    return lists[0].entry_type;
}

export const SectionListItem = React.forwardRef((props, ref) => {
    const { id, type, isDragging, isDraggedInto, selected, emptyText } = props;
    const data = useData(props); //media
    const showAsDragging = isDragging || isDraggedInto;
    const isDraft = useSelector((state) => selectIsDraft(state, type, id));

    const {
        title,
        description,
        slug,
        template,
        active,
        used_in,
        type: sectionType,
        tags,
    } = data;

    const templateData = useData({ type, id: template });
    const { description: templateName } = templateData;
    //const image = useCover(media, [120, 90], 'cms');
    const onClick = (evt) => {
        props.onClick(id, evt);
    };
    const pending = usePending(id);

    const name =
        data.title || oneOfFallback(["title"], data) || data.description;

    const noName = !data.title;

    const secondary = title
        ? [description, templateName].filter(Boolean).join(" / ")
        : templateName;

    const usedIn = used_in ? used_in.length : 0;
    const isDynamicContent =
        selectIsDynamicContent(data) || selectIsDynamicContent(templateData);

    if (sectionType === "placeholder")
        return <LayoutSlotItem {...props} onClick={onClick} ref={ref} />;

    return (
        <ContextMenu type={type} id={id} RenderMenuItems={SectionContextMenu}>
            {({ onMenuOpen }) => (
                <MuiListItem
                    ref={ref}
                    button={!!props.onClick}
                    onClick={props.onClick ? onClick : null}
                    onContextMenu={props.onClick ? onMenuOpen : null}
                    className={cn({
                        "list-item": true,
                        "is-active": selected === id,
                        "is-dragging": showAsDragging,
                        "is-disabled": !active,
                        "is-pending": !!pending,
                        "no-title": noName,
                        "is-linked": usedIn > 1,
                        "is-dynamic-content": isDynamicContent,
                    })}
                >
                    <ListItemAvatar>
                        {/*<Badge*/}
                        {/*    overlap={"circular"}*/}
                        {/*    badgeContent={sectionBadge({ usedIn, isDraft })}*/}
                        {/*    color={!usedIn ? "primary" : "secondary"}*/}
                        {/*>*/}
                        <Avatar className={active ? "t-primary" : null}>
                            <SectionIcon id={id} />
                        </Avatar>
                        {usedIn > 1 && (
                            <div className={"avatar-badge"}>
                                {sectionBadge({ usedIn, isDraft })}
                            </div>
                        )}
                        {/*</Badge>*/}
                    </ListItemAvatar>
                    <ListItemText
                        primary={name || emptyText}
                        secondary={secondary}
                    />
                    <ListItemSecondaryAction className={"list-item-action"}>
                        <SectionContentSummary
                            id={id}
                            entryType={isDynamicContent}
                        />
                        {!pending && props.onClick && (
                            <IconButton
                                icon={"mui-more_vert"}
                                onClick={onMenuOpen}
                                size={"small"}
                            />
                        )}
                        {pending && (
                            <CircularProgress
                                color={"inherit"}
                                size={20}
                                thickness={4.2}
                            />
                        )}
                    </ListItemSecondaryAction>
                </MuiListItem>
            )}
        </ContextMenu>
    );
});

SectionListItem.propTypes = {};

SectionListItem.defaultProps = {
    emptyText: "No title",
    type: "cms.sections",
};
