import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import Field from "containers/Field";
import { EntryDescription } from "playground/cms/views/Entries";
import ExtendedFields from "playground/cms/fields/ExtendedFields";
import { FormLayout } from "playground/cms/Form";
import Address from "playground/cms/fields/Address";
import { Property, PropertyGrid } from "playground/cms/fields/Properties";
import DatePicker from "playground/cms/fields/DatePicker";
import { MediaField } from "playground/cms/fields/Media";

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
    headline: false,
    text: false,
    media: false,
};

export function ContactsForm(props) {
    const { type, id } = props;
    return (
        <div className={"pad-md rows gap-md"}>
            <Field id={"contact.name"} label={"Imię i nazwisko"} />
            <PropertyGrid>
                <Property label={"Telefon"}>
                    <Field id={"contact.phone"} variant={"dense"} />
                </Property>
                <Property label={"Telefon 2"}>
                    <Field id={"contact.phone_additional"} variant={"dense"} />
                </Property>
                <Property label={"E-mail"}>
                    <Field id={"contact.email.email"} variant={"dense"} />
                </Property>
                <Property label={"Stanowisko"}>
                    <Field id={"role"} variant={"dense"} />
                </Property>
            </PropertyGrid>

            <MediaField id={"media"} label={"Media"} />
            <Field id={"address"} label={"Adres"} FieldComponent={Address} />

            <div>Jeszcze kolekcji social brakuje</div>

            <FormLayout.Section name={"Custom fields"} variant={"default"}>
                <ExtendedFields {...props} />
                {/*<Form type={type} id={id} selectFields={selectFields} />*/}
            </FormLayout.Section>
            {/*<Form type={type} id={id} selectFields={selectFields} />*/}
        </div>
    );
}

ContactsForm.propTypes = {};

ContactsForm.defaultProps = {};

export default ContactsForm;
