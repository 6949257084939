import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import { CalendarIcon } from "joynt/components/Icons";

import "./style.css";

export function ScheduleSession(props) {
    const { onCreate } = props;

    return (
        <section className={"schedule-session"}>
            <div className={"schedule-session__title"}>
                <CalendarIcon />
                <div>Go ahead and schedule new session.</div>
            </div>
            <Button
                className={"schedule-session__button"}
                startIcon={<Icon>mui-today</Icon>}
                onClick={onCreate}
                variant={"outlined"}
            >
                Schedule session
            </Button>
        </section>
    );
}

ScheduleSession.propTypes = {};
ScheduleSession.defaultProps = {};

export default ScheduleSession;
