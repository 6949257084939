import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

export function PopoverOverlay(props) {
    const {
        id,
        open,
        anchorEl,
        placement,
        onMouseEnter,
        onMouseLeave,
        onClick,
        onClose,
        Component,
    } = props;

    return (
        <>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <div
                            className={cn("popper pad-sm")}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onClick={onClick}
                        >
                            <Component id={id} onClose={onClose} />
                        </div>
                    </Fade>
                )}
            </Popper>
        </>
    );
}

PopoverOverlay.propTypes = {};
PopoverOverlay.defaultProps = {};

export default PopoverOverlay;
