import React, { useCallback, useMemo } from "react";
import { selectList as selectListIds } from "state/selectors/lists";
import { useSelector } from "react-redux";
import { appendQueryParams } from "util/uri";
import {
    useApiActions,
    useFetch,
    useFilter,
    useRegisterList,
} from "state/hooks";
import List from "containers/List";
import ThemeContext from "./../ThemeContext";
import { SortableIterator } from "components/dnd";
import ListItem from "./../ListItem";
import SectionCard from "./SectionCard";
import GalleryItem from "./SectionGalleryItem";

//redux
import { Provider } from "react-redux";
import { useCurrentService } from "playground/cms/hooks";
import { store } from "./store";
import PendingState from "./PendingState";
import { dragSection } from "cms/state/actions/cms";
import ApiContext from "containers/context/ApiContext";
import { useSectionsFilter } from "playground/cms/views/Sections/SectionsFilterContext";

//import PropTypes from 'prop-types';

const CardIterator = SortableIterator(SectionCard);
const GalleryItemIterator = SortableIterator(GalleryItem);
const ItemIterator = SortableIterator(ListItem);

const selector = (state, list) => {
    return selectListIds(state, "cms.sections", list);
};

function withLogicalSectionTypes(type, items) {
    if (type === "cms.layouts")
        return ["@NEW", "@NEW_TPL", "@PLACEHOLDER", ...items];

    return ["@NEW", "@NEW_TPL", ...items];
}

function ServiceStoreProvider(props) {
    if (!props.service) return props.children;
    return (
        <Provider store={store}>
            <ApiContext.Provider value={{ project: props.service }}>
                {props.children}
            </ApiContext.Provider>
        </Provider>
    );
}

const dropPlaceholder = <div>section</div>;

function ServiceListContainer(props) {
    const { type, iterator, list, create, origin, localId, filterParams } =
        props;

    const currentWebsite = useCurrentService();
    const website = origin === "local" ? currentWebsite?.id : origin;
    const listId = list || type;

    const url = appendQueryParams(
        `v2/${type}?render=true&service=${website}&list=${listId}&libRef=${localId}`,
        filterParams
    );

    const allItems = useSelector((state) => {
        return selector(state, listId);
    });

    const items = useMemo(() => {
        return create && origin === "local"
            ? withLogicalSectionTypes(create, allItems)
            : allItems;
    }, [allItems, create, origin]);

    const fetchId = `cms.sections/${listId}`;

    const pending = useFetch({
        type,
        list: listId,
        url,
        fetchId: `cms.sections/${listId}`,
    });

    useRegisterList({ type, list, url, fetchId });

    const { onDragSection } = useApiActions({ onDragSection: dragSection });
    const onAddToList = useCallback(
        (result) => {
            onDragSection(type, null, { ...result, target: origin });
        },
        [onDragSection, type, origin]
    );

    if (pending) return <PendingState prevItems={items.length} />;

    return (
        <>
            <List
                {...props}
                service={website}
                items={items}
                Iterator={iterator}
                onAddToList={onAddToList}
                dropPlaceholder={dropPlaceholder}
            />
        </>
    );
}

export function SectionsListContainer(props) {
    const { type, view, list } = props;

    //const { value: filterValue } = useFilter(list || type, type, list || type);
    const { value: listFilterValue } = useSectionsFilter();
    const filterValue = props.filterValue || listFilterValue;
    const allFilters = { ...listFilterValue, ...props.filterValue };

    //const { service } = filterValue;
    const service = props.filterValue?.service || listFilterValue?.service;
    const localService = useCurrentService();

    let origin = "local";
    if (service) origin = service;

    const iterator = {
        grid: CardIterator,
        gallery: GalleryItemIterator,
        list: ItemIterator,
    }[view];

    const className = {
        grid: "section-grid",
        gallery: "section-gallery",
        list: "list overflow-scroll",
    }[view];

    const sectionsList = (
        <ServiceListContainer
            {...props}
            className={className}
            iterator={iterator}
            filterParams={allFilters}
            service={service}
            origin={origin}
            localId={localService?.id}
        />
    );

    if (view === "list")
        return (
            <ServiceStoreProvider service={service}>
                {sectionsList}
            </ServiceStoreProvider>
        );

    return (
        <ServiceStoreProvider service={service}>
            <ThemeContext
                service={service}
                className={"overflow-scroll"}
                PendingState={PendingState}
            >
                {sectionsList}
            </ThemeContext>
        </ServiceStoreProvider>
    );
}

SectionsListContainer.defaultProps = {
    view: "grid",
};

SectionsListContainer.propTypes = {};

SectionsListContainer.defaultProps = {
    filterValue: {},
};

export default SectionsListContainer;
