import React from "react";
import { useNode } from "joynt/hooks";
import { useActions } from "state/hooks";
import { pathAppend, pathDetach } from "state/actions/data";
import { useNodeRoles } from "./../hooks";
import { NodeRoleItem } from "joynt/components/Roles/NodeRoles/NodeRoles";

//import PropTypes from 'prop-types';

export function AssignAccessRoles(props) {
    const { id, assigned } = props;

    const { access_roles } = useNode(id);
    const { items: roles } = useNodeRoles(id);

    const { onAdd, onRemove } = useActions({
        onAdd: pathAppend,
        onRemove: pathDetach,
    });

    const path = ["db.nodes", id, "access_roles"];

    const add = (id) => onAdd(path, id);
    const remove = (id) => onRemove(path, id);

    const value = access_roles || [];
    const unassigned = roles.filter((id) => value.indexOf(id) === -1);

    return (
        <>
            {!assigned && (
                <>
                    {unassigned.map((item) => (
                        <NodeRoleItem key={item} id={item} onClick={add} />
                    ))}
                </>
            )}
            {assigned && (
                <>
                    {value.map((item) => (
                        <NodeRoleItem key={item} id={item} onRemove={remove} />
                    ))}
                </>
            )}
        </>
    );
}

AssignAccessRoles.propTypes = {};
AssignAccessRoles.defaultProps = {};

export default AssignAccessRoles;
