import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useEdges, useKeep, useNode } from "joynt/hooks";
import IconButton from "joynt/components/IconButton";
import { useClick } from "state/hooks";

export default function KeepIcon(props) {
  const { id } = props;

  const { keep } = useEdges(id);
  const { pinned } = useNode(id);
  const { onKeep } = useKeep();

  const handleKeep = useClick({ onClick: onKeep, id });

  if (pinned) return null;

  return (
    <IconButton
      size={"small"}
      label={"Bookmark"}
      withTooltip
      className={cn({ "text-primary": !!keep })}
      icon={keep ? "mui-bookmark" : "mui-bookmark_border"}
      onClick={handleKeep}
    />
  );
}

KeepIcon.propTypes = {
  id: PropTypes.string.isRequired,
};
KeepIcon.defaultProps = {};
