import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { useMessage, useReaction } from "joynt/hooks";
import useProfilePopover from "joynt/participants/hooks/useProfilePopover";
import { useIdentity } from "joynt/hooks/participants";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
import Reactions from "joynt/components/Reactions";
import NotificationStatus from "joynt/components/NotificationStatus";
import { FileList } from "joynt/components/Files";
import LinkEmbed from "joynt/components/LinkEmbed";

import Message from "./Message";

export default function MessageContainer({ id, fallbackIdentity, mode }) {
    const data = useMessage(id);

    const { media, link, content, created_at, is_deleted, isOwn } = data;
    const identity = data.identity || fallbackIdentity;

    const { onShow: showUserInfo, onHide } = useProfilePopover();
    const { name: identityName } = useIdentity(identity);
    const { total } = useReaction({ type: "db.messages", id });
    const click = useCallback((e) => showUserInfo(identity, e.currentTarget), [
        identity,
        showUserInfo,
    ]);

    const avatar = (
        <div
            onClick={click}
            onMouseLeave={onHide}
            className={"message__interactive-avatar"}
        >
            <IdentityAvatarContainer id={identity} size={"xs"} />
        </div>
    );

    const reactions = (
        <Reactions
            type={"db.messages"}
            id={id}
            hideOnEmpty
            mode={"conditional"}
        />
    );
    const notificationStatus = <NotificationStatus id={id} />;
    const messageClick = useCallback(
        (e) => {
            const id = e.target.getAttribute("data-id");
            if (!id) return;
            showUserInfo(id, e.target);
        },
        [showUserInfo]
    );

    const addReactions = (
        <Reactions type={"db.messages"} id={id} mode={"add"} />
    );

    return (
        <Message
            addReactions={addReactions}
            id={id}
            mode={mode || "chat"}
            content={content}
            createdAt={created_at}
            isDeleted={is_deleted}
            isOwn={isOwn}
            link={
                link && (
                    <LinkEmbed
                        link={link}
                        readOnly={true}
                        className={"message__link"}
                    />
                )
            }
            author={identityName}
            messageClick={messageClick}
            onHide={onHide}
            total={total}
            avatar={avatar}
            reactions={reactions}
            notificationStatus={notificationStatus}
            files={
                media &&
                media.length > 0 && <FileList value={media} type={"db.media"} />
            }
        />
    );
}

MessageContainer.propTypes = {
    id: PropTypes.string,
};
MessageContainer.defaultProps = {};
