import { selectList } from "./lists";
import { selectEntity } from "state/selectors/data";
import { FILTER_NS } from "config/list-aliases";

export const textMatch = (entity, keys, search) => {
    for (let i = 0; i < keys.length; i++) {
        let value = entity[keys[i]];
        if (value) {
            let match = value.toLowerCase().indexOf(search) > -1;
            if (match) return true;
        }
    }
    return false;
};

export const filterMedia = (item, filter) => {
    if (filter.search) {
        return textMatch(item, ["file"], filter.search.toLowerCase());
    }
    if (filter.path === "videos") {
        return item.type === "video";
    }
    if (filter.path === "pano") {
        return ["google_pano", "hosted_pano"].indexOf(item.type) > -1;
    }
    let parts = item.file.split("/");
    let root = parts[1];
    let path = parts.slice(2).slice(0, -1).join("/");
    //if (!filter.path) return path === '/';
    return root === "user" && path === filter.path;
};

const defaultTextSearchFields = ["name", "title", "description", "label"];

const textSearchFields = {
    "cms.theme.styles": ["id"],
    default: defaultTextSearchFields,
};

export const selectFilteredList = (store, type, sourceList, filter) => {
    const filterData = selectEntity(store, FILTER_NS, filter);
    const listData = selectList(store, type, sourceList);
    const query = store.router.query;
    return listData.filter((id) => {
        let entity = selectEntity(store, type, id);
        if (type === "cms.media") {
            return filterMedia(entity, {
                ...filterData,
                path: query.dir,
            });
        }
        if (!filterData.search) return true;
        let search = filterData.search.toLowerCase();
        if (!search) return false;
        //if (!entity.name) return false;
        return textMatch(
            entity,
            textSearchFields[type] || textSearchFields["default"],
            search
        );
    });
};

export const selectFilter = (store, id) => {
    return selectEntity(store, FILTER_NS, id);
};
