import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import Field from "containers/Field";
import TagsField from "playground/cms/fields/TagsField";
import CategoriesTree from "playground/cms/views/Categories/CategoriesTree";
import { MediaField } from "playground/cms/fields/Media";
import {
    PropertyGrid,
    OfferPrice,
    Property,
} from "playground/cms/fields/Properties";
import { useSelector } from "react-redux";
import { FormLayout } from "playground/cms/Form";
import ExtendedFields from "playground/cms/fields/ExtendedFields";

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
};

function CategorySelect(props) {
    const { onChange, value } = props;
    return (
        <div style={{ width: "320px", height: "400px" }}>
            <CategoriesTree onSelect={onChange} selected={value} />
        </div>
    );
}

function selectFieldSchema(store, type, id) {
    const types = store?.data?.["schema.db"];
    const typeDef = Object.values(types || {}).find((t) => {
        return t.slug === type.split(".")[1];
    });
    const fieldId = typeDef?.field_index?.[id];
    const fieldDef = store?.data?.["db.fields"]?.[fieldId];
    return fieldDef;
}

function ProductProperties(props) {
    const { entityType, id, value } = props;
    const schema = useSelector((store) => {
        return selectFieldSchema(store, entityType, id);
    });
    const properties = schema?.properties?.schema || [];

    return (
        <PropertyGrid>
            {properties.map((property) => (
                <Property key={property.id} label={property.label}>
                    <Field id={[id, property.id].join(".")} variant={"dense"} />
                </Property>
            ))}
        </PropertyGrid>
    );
}

export function ProductForm(props) {
    const { type, id } = props;

    return (
        <div className={"pad-md rows gap-md"}>
            <Field
                id={"assigned_category"}
                fieldType={"select"}
                source={"api:categories"}
                label={"Kategoria"}
                nullable={true}
                multiple={false}
                SelectMenuComponent={CategorySelect}
                // variant={"dense"}
            />
            <TagsField />
            <MediaField id={"media"} label={"Zdjęcia produktu"} />
            <Field
                id={"headline"}
                fieldType={"text"}
                label={"Podtytuł"}
                size={"large"}
            />
            <Field id={"text"} fieldType={"rich"} label={"Treść"} />
            <PropertyGrid>
                <OfferPrice {...props} />
                <Property label={"Nr kat."}>
                    <Field
                        id={"catalog_number"}
                        fieldType={"text"}
                        variant={"dense"}
                    />
                </Property>
            </PropertyGrid>
            <Field
                id={"product_properties"}
                FieldComponent={ProductProperties}
            />
            <FormLayout.Section name={"Custom fields"} variant={"default"}>
                <ExtendedFields {...props} />
                {/*<Form type={type} id={id} selectFields={selectFields} />*/}
            </FormLayout.Section>
            {/*<Form type={type} id={id} selectFields={selectFields} />*/}
        </div>
    );
}

ProductForm.propTypes = {};

ProductForm.defaultProps = {};

export default ProductForm;
