import { combineReducers } from "redux";

import auth from "./auth";
import pending from "./pending";
import progress from "./progress";
import ui from "./ui";
import data from "./data";
import changes from "./changes";
import lists from "./lists";
import listsMeta from "./lists-meta";
import notifications from "./notifications";
import router from "./router";

import joynt from "joynt/state/reducers";

export const reducers = {
    auth,
    pending,
    progress,
    ui,
    data,
    changes,
    lists,
    notifications,
    ...joynt,
    "lists-meta": listsMeta,
};

const rootReducer = (appReducers, history) =>
    combineReducers({
        ...appReducers,
        router: router(history),
    });

export default rootReducer;
