import React from "react";
import PropTypes from "prop-types";
import TaskListItem from "./TaskListItem";
import { useTask } from "joynt/components/Tasks/hooks";
import { useClick } from "state/hooks";
import { useEdgesData } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectPostContent } from "joynt/state/selectors/joint";

export function TaskListItemContainer(props) {
    const { id } = props;
    const task = useTask(id);
    const click = useClick(props);
    const { parent } = useEdgesData(id);
    const { properties } = useSelector((s) => selectPostContent(s, id));

    return (
        <TaskListItem
            {...props}
            {...task}
            onClick={click}
            parent={parent}
            properties={properties}
        />
    );
}

TaskListItemContainer.propTypes = {
    id: PropTypes.string,
};
TaskListItemContainer.defaultProps = {};

export default TaskListItemContainer;
