import React from "react";
import PropTypes from "prop-types";
import AvatarGroup from "./AvatarGroup";
import Avatar from "./AvatarGroupElement.container";
import { useSelector } from "react-redux";
import { selectNamesAsString } from "joynt/components/AvatarGroup/state/selectors";

export function AvatarGroupContainer(props) {
    const { ids, showNames, size, maxSize, ...other } = props;

    const names = useSelector((s) => {
        return showNames ? selectNamesAsString(s, ids) : null;
    });

    const label = showNames ? names : other.label;

    const displayedIds = [...ids];

    const exceedingAvatars = displayedIds.splice(maxSize).length;

    return (
        <AvatarGroup
            {...other}
            size={size}
            label={label}
            exceedingAvatars={exceedingAvatars}
            isEmpty={ids.length < 1}
        >
            {displayedIds.map((id) => (
                <div className={"avatar-group__avatar"}>
                    <Avatar key={id} id={id} size={size} />
                </div>
            ))}
        </AvatarGroup>
    );
}

AvatarGroupContainer.propTypes = {
    ids: PropTypes.array,
    showNames: PropTypes.bool,
    size: PropTypes.string,
};
AvatarGroupContainer.defaultProps = {
    ids: [],
    showNames: false,
    size: "xs",
    maxSize: 10,
};

export default AvatarGroupContainer;
