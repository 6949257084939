import React from "react";
import Field from "containers/Field";
import SelectField from "joynt/edit/SelectField";
import { viewTypes } from "joynt/config/types";
import FieldLayout from "joynt/components/Form/FieldLayout";

//import PropTypes from 'prop-types';

export function ViewType(props) {
    //const {} = props;
    return (
        <FieldLayout name={"Default view"}>
            <Field id={"default_view"}>
                {({ onChange, value }) => (
                    <SelectField
                        onChange={onChange}
                        value={value}
                        options={viewTypes}
                    />
                )}
            </Field>
        </FieldLayout>
    );
}

ViewType.propTypes = {};
ViewType.defaultProps = {};

export default ViewType;
