import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";

import SessionCover from "joynt/components/SessionCover/SessionCover";
import IconButton from "components/IconButton";
import PostIdentity from "joynt/post/PostIdentity";

import SessionControlPanel from "./SessionControlPanel";
import SessionStateLabel from "./SessionStateLabel";

import "./style.css";

function Avatars(props) {
    const { id } = props;
    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );
    return <PostIdentity identities={presence} maxSize={6} size={"xs"} />;
}

export default function SessionCoverTile(props) {
    const {
        finished,
        id,
        image,
        isLive,
        onClick,
        onEdit,
        showControls,
        showPresence,
        space,
        started,
        title,
    } = props;

    return (
        <div
            className={cn("session-cover-tile", onClick && "clickable")}
            onClick={onClick}
        >
            <SessionCover image={image}>
                <div className={"session-cover-tile__edit"}>
                    {onEdit && (
                        <IconButton
                            className={"session-cover-tile__settings"}
                            icon={"mui-settings"}
                            onClick={onEdit}
                            size={"small"}
                        />
                    )}
                </div>
                <div
                    className={cn(
                        "session-cover-tile__content",
                        isLive && "session-cover-tile__content--live",
                        {
                            centered:
                                (finished && !onEdit) || (!started && !onEdit),
                        }
                    )}
                >
                    {isLive && (
                        <section className={"session-cover-tile__info"}>
                            <div className={"session-cover-tile__live-emblem"}>
                                Live now
                            </div>
                            {title && (
                                <h3 className={"session-cover-tile__title"}>
                                    {title}
                                </h3>
                            )}
                            {space && (
                                <p className={"session-cover-tile__space"}>
                                    in {space}
                                </p>
                            )}
                            {showPresence && (
                                <div className={"session-cover-tile__presence"}>
                                    <Avatars id={id} />
                                </div>
                            )}
                        </section>
                    )}
                    {showControls ? (
                        <SessionControlPanel id={id} />
                    ) : (
                        <SessionStateLabel id={id} />
                    )}
                </div>
            </SessionCover>
        </div>
    );
}
