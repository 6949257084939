export function createHourRange(start, hours) {

    let from = start
        ? new Date(start*1000)
        : new Date();

    let to = new Date(from);

    to.setHours(from.getHours() + hours);

    return [
        from.getTime()/1000,
        to.getTime()/1000
    ];
}