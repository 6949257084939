export default [
    {
        value: 'edit',
        icon: 'mui-edit'
    },
    {
        value: 'style',
        icon: 'mui-format_shapes'
    }
]
