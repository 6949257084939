import { useUiFlag } from "state/hooks";
import { FLAG_VISIBLE_CHATS } from "joynt/config";
import { useEffect } from "react";
import { useVisibleOnScreen } from "joynt/components/ScreenVisibility";

export function useRegisterChat(id) {
    const visible = useVisibleOnScreen();
    const [, setChatView] = useUiFlag(FLAG_VISIBLE_CHATS, []);

    useEffect(() => {
        if (visible) {
            setChatView((prev = []) => [...prev, id]);
            return () =>
                setChatView((prev = []) => prev.filter((v) => v !== id));
        }
    }, [id, visible, setChatView]);
}
