import {useCallback} from "react";
import {useActions, useRouteParam} from "state/hooks";
import {createPost} from "joynt/state/actions/posts";
import {POST_TYPE_TEXT, ROUTE_POST} from "joynt/config";
import uuid from "uuid/v4";

export function useCreatePost(feedId, streamList) {
    const {onCreate} = useActions({onCreate: createPost});
    const [,setPost] = useRouteParam(ROUTE_POST);
    const create = useCallback((stemId, type, showPreview, data = {}) => {
        const posts = [];
        if (type !== POST_TYPE_TEXT) posts.push({id: uuid(), subtype: POST_TYPE_TEXT});
        posts.push({id: uuid(), subtype: type || null});
        const stem = {
            id: stemId || uuid(),
            parent: feedId,
            posts,
            ...data
        };
        const callback = (data) => {
            if (showPreview) setPost(`edit.${data.id}`);
        }
        onCreate(callback, stem, streamList);
    }, [onCreate, feedId, streamList, setPost]);
    return [create];
}