import React from "react";
import { FormLayout } from "playground/cms/Form";

//import PropTypes from 'prop-types';

export function DebugConsole(props) {
    const { path, state } = props;
    return (
        <FormLayout>
            <FormLayout.Header>path: {path}</FormLayout.Header>
            <FormLayout.Content>
                <FormLayout.Fieldset pad>
                    <pre>{JSON.stringify(state, null, 2)}</pre>
                </FormLayout.Fieldset>
            </FormLayout.Content>
        </FormLayout>
    );
}

DebugConsole.propTypes = {};

DebugConsole.defaultProps = {};

export default DebugConsole;
