import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDrop } from "react-dnd";
import cn from "classnames";
import { Draggable } from "./Draggable";
import reorder from "util/reorder";
//import PropTypes from 'prop-types';

import { NEW_ITEM_ID } from "components/dnd";

function reducer(state, { type, items, drag, hover }) {
    switch (type) {
        case "reset":
            return {
                ...state,
                newId: null,
                items: state.currentItems,
                hover: false,
            };
        case "update":
            return { items, newId: null, currentItems: items };
        case "reorder":
            if (!drag || !hover) return state;
            const dragId = drag.id === state.refId ? NEW_ITEM_ID : drag.id;
            let updatedOrder = reorder(state.items, dragId, hover.id, true);
            return { ...state, items: updatedOrder };
        case "accept":
            if (state.items.indexOf(drag.id) > -1) return state;
            let updatedList = state.items.slice();
            updatedList.push(drag.id);
            return {
                ...state,
                newId: drag.id,
                refId: drag.refId,
                items: updatedList,
                origin: drag.origin,
            };
        case "hover":
            return { ...state, hover: true };
        case "leave":
            return { ...state, hover: false };
        default:
            throw new Error();
    }
}

export function Sortable(props) {
    const {
        items,
        onSortEnd,
        onAddToList,
        onDragLeave,
        dropPlaceholder,
        type,
        className,
        children,
        watchDrag,
        ...other
    } = props;

    const [state, dispatch] = useReducer(reducer, {
        items,
        currentItems: items,
    });

    useEffect(() => {
        if (items !== state.currentItems) {
            dispatch({ type: "update", items });
        }
    });

    const handleSort = useCallback(
        (drag, hover) => {
            if (onSortEnd) {
                dispatch({ type: "reorder", drag, hover });
            }
        },
        [onSortEnd]
    );

    const handleSortEnd = useCallback(() => {
        if (onSortEnd && !state.newId) {
            onSortEnd(state.items);
            dispatch({ type: "reset" });
        }
    }, [onSortEnd, state]);

    const ref = useRef(null);

    const leaveTimeout = useRef(null);

    const [, drop] = useDrop({
        accept: type,
        hover(hoverItem, monitor) {
            if (monitor.isOver()) {
                if (leaveTimeout.current) clearTimeout(leaveTimeout.current);
                if (!state.hover) dispatch({ type: "hover" });
            }

            if (
                hoverItem.origin === other.origin &&
                hoverItem.originId === other.originId
            ) {
                return;
            }

            if (onAddToList && state.items.indexOf(hoverItem.id) === -1) {
                const newItem = {
                    ...hoverItem,
                    id: NEW_ITEM_ID,
                    refId: hoverItem.id,
                };
                dispatch({ type: "accept", drag: newItem });
            }
        },
        drop: () => {
            if (onAddToList && state.newId) {
                onAddToList(state);
                dispatch({ type: "reset" });
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            didDrop: monitor.didDrop(),
        }),
    });

    if (onAddToList || onDragLeave) drop(ref);

    let hasItems = state.items && state.items.length > 0;

    const handleLeave = useCallback(
        (evt) => {
            if (ref.current.contains(evt.target)) {
                leaveTimeout.current = setTimeout(() => {
                    if (state.hover) {
                        if (onAddToList) dispatch({ type: "reset" });
                        if (onDragLeave) {
                            dispatch({ type: "leave" });
                            onDragLeave();
                        }
                    }
                }, 50);
            }
        },
        [onDragLeave, state.hover, onAddToList]
    );

    if (!hasItems && dropPlaceholder)
        return (
            <div className={"drop-target"} ref={ref}>
                {dropPlaceholder}
            </div>
        );

    return (
        <div
            className={cn("drop-target", className, {
                "drag-over": !!state.hover,
            })}
            ref={ref}
            onDragLeave={handleLeave}
        >
            {children}
            {state.items.map((item, index) => (
                <Draggable
                    onSort={handleSort}
                    onSortEnd={handleSortEnd}
                    type={type}
                    {...other}
                    key={item}
                    id={item}
                    index={index}
                    refId={item === NEW_ITEM_ID ? state.refId : item}
                    isDraggedInto={item === NEW_ITEM_ID}
                />
            ))}
        </div>
    );
}

Sortable.propTypes = {};
Sortable.defaultProps = {};
