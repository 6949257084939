import { useCallback, useMemo } from "react";
import { useApiActions, useFilter, useRouteParam } from "state/hooks";
import { post } from "state/actions/api";

export function useGalleryActions(type) {
    const { value: filter } = useFilter(type, type, type);
    const { onPost } = useApiActions({ onPost: post });

    const [dir] = useRouteParam("dir");
    const onImport = useCallback(() => {
        onPost(
            "cms/browser/gallery",
            {
                dir,
                tags: filter.tags,
            },
            [type, type].join("/")
        );
    }, [type, dir, onPost, filter]);

    const onDeleteTag = useCallback(() => {
        if (!filter.tags) return;
        onPost(
            "cms/entries/tag-delete",
            {
                tag: filter.tags,
            },
            [type, type].join("/")
        );
    }, [type, onPost, filter]);

    return useMemo(() => ({ onImport, onDeleteTag }), [onImport, onDeleteTag]);
}
