import { takeEvery, put, select } from "redux-saga/effects";
import { selectEntity, selectFieldValue } from "state/selectors/data";
import { change, validate } from "state/actions/data";
import { isValidForm } from "state/selectors/validation";

import isValidEmail from "util/validation/isValidEmail";
import isValidEmailList from "util/validation/isValidEmailList";
import isValidUrl from "util/validation/isValidUrl";
import isValidPassword from "util/validation/isValidPassword";
import { notify } from "state/actions/ui";
import { handleSubmit } from "state/sagas/api";

const formats = {
    email: isValidEmail,
    "email-list": isValidEmailList,
    url: isValidUrl,
    password: isValidPassword,
};

function validateFormat(format, value) {
    if (formats[format]) return formats[format](value);
    return true;
}

export const NS_VALIDATION = "app.validation";

function* validateData(arg) {
    const { type, id, spec, data, changes = {}, isSubmit = false } = arg;
    let fields = {};
    let keys = Object.keys(spec);
    for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        let flags = spec[key];
        let value = data[key];

        if (flags.required && !value) {
            if (
                flags.required === true ||
                (typeof flags.required === "function" && flags.required(data))
            ) {
                fields[key] = "Required field";
            }
        }
        if (value) {
            if (flags.min && value.length < flags.min) {
                fields[key] = `Needs to be at least ${flags.min} characters`;
            }
            if (flags.max && value.length > flags.max) {
                fields[
                    key
                ] = `Needs to be no longer than ${flags.max} characters`;
            }
            if (flags.format && !validateFormat(flags.format, value)) {
                fields[key] = "Incorrect field format";
            }
            if (flags.fn) {
                yield flags.fn(arg, key, fields);
            }
            if (flags.minCount && (!value || value.length < flags.minCount)) {
                fields[key] = `Needs to have at least ${flags.minCount} items`;
            }
            if (flags.match && value !== data[flags.match]) {
                fields[key] = `Passwords do not match`;
            }
            if (flags.password && !isValidPassword(value)) {
                fields[
                    key
                ] = `Password has to be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, and a number.`;
            }
            if (flags.validType && value) {
                let valid = true;
                for (let i = 0; i < value.length; i++) {
                    valid = yield select((store) =>
                        isValidForm(store, flags.validType, value[i])
                    );
                    if (!valid) fields[key] = `All items need to be valid`;
                }
            }
        }
    }
    return { fields, errors: Object.keys(fields).length, error: null };
}

function validateEntity(config) {
    return function* (type, id, changes = {}, isSubmit = false) {
        try {
            const subtype = yield select((store) =>
                selectFieldValue(store, type, id, "subtype")
            );
            let spec = config[[type, id].join("/")];
            if (!spec && subtype) spec = config[[type, subtype].join("/")];
            if (!spec) spec = config[type];
            if (spec) {
                const data = yield select((store) =>
                    selectEntity(store, type, id)
                );
                yield put(
                    change(
                        NS_VALIDATION,
                        [type, id].join("/"),
                        yield validateData({
                            type,
                            id,
                            spec,
                            data,
                            changes,
                            isSubmit,
                        })
                    )
                );
            } else {
                yield put(
                    change(NS_VALIDATION, [type, id].join("/"), {
                        error: null,
                        errors: 0,
                    })
                );
            }
        } catch (e) {
            console.log(e);
        }
    };
}

function* handleValidateOnChange(validator, { payload }) {
    try {
        const { validate, type, id, data } = payload;
        if (validate) yield validator(type, id, data);
    } catch (e) {
        console.log(e);
    }
}

function* handleValidate(validator, { payload }) {
    try {
        const { type, id } = payload;
        yield validator(type, id);
    } catch (e) {
        console.log(e);
    }
}

function* submitValidator(validator, action) {
    const { type, id } = action.payload;

    yield validator(type, id, null, true);
    const isValid = yield select((store) => isValidForm(store, type, id));

    if (!isValid) {
        yield put(
            change(NS_VALIDATION, [type, id].join("/"), {
                submitted: true,
            })
        );
        return false;
    } else {
        yield put(
            change(NS_VALIDATION, [type, id].join("/"), {
                submitted: false,
            })
        );
    }

    return true;
}

function* handleSubmitValidate(validator, action) {
    const status = yield submitValidator(validator, action);

    if (!status) {
        yield put(notify("Validation failed", "error"));
        return;
    }

    yield handleSubmit(action);
}

function* handleSubmitValidateGate(validator, action) {
    const status = yield submitValidator(validator, action);
    yield put({ type: "DATA.VALIDATE.STATUS", payload: status });
}

function withConfig(config, fn) {
    const validator = validateEntity(config);
    return function* (...args) {
        yield fn(validator, ...args);
    };
}

export default function (config) {
    return function* () {
        yield takeEvery(
            "DATA.CHANGE",
            withConfig(config, handleValidateOnChange)
        );
        yield takeEvery("DATA.VALIDATE", withConfig(config, handleValidate));
        yield takeEvery(
            "API.SUBMIT_ONE_VALIDATE",
            withConfig(config, handleSubmitValidate)
        );
        yield takeEvery(
            "DATA.VALIDATE.REQUEST",
            withConfig(config, handleSubmitValidateGate)
        );
    };
}
