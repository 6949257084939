import React from 'react';

//import PropTypes from 'prop-types';

export function StageSchedule(props) {

    const {children} = props;

    return (<div>
        {children}
    </div>);

}

StageSchedule.propTypes = {};
StageSchedule.defaultProps = {};

export default StageSchedule;
