import React from "react";
import PropTypes from "prop-types";
import ChatButton from "./ChatButton";
import { useNotificationCount } from "joynt/hooks/notifications";
import { useUiFlag } from "state/hooks";
import { FLAG_CHAT_THREAD } from "joynt/config";
import { useNodeData } from "joynt/hooks";
import { useChatTicker, Message } from "joynt/components/ChatTicker";

export function ChatButtonContainer(props) {
    const { id } = props;

    const { thread, name } = useNodeData(id);
    const { message, show } = useChatTicker(id);

    const [notifications, onSeen] = useNotificationCount(id);
    const [, onShowChat] = useUiFlag(FLAG_CHAT_THREAD);

    const showChat = () => {
        onShowChat(id);
        onSeen();
    };

    return (
        <ChatButton
            id={thread}
            onClick={showChat}
            message={message}
            MessageComponent={Message}
            show={show}
            notifications={notifications}
        >
            {name}
        </ChatButton>
    );
}

ChatButtonContainer.propTypes = {
    id: PropTypes.string,
};
ChatButtonContainer.defaultProps = {};

export default ChatButtonContainer;
