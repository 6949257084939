import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {isPending} from "state/selectors/ui";
import {useApiActions, useCover} from "state/hooks";
import {handleUploadCallback} from "state/actions/upload";
import uuid from "uuid/v4";
import UploadButton from "components/upload/UploadButton";
//import PropTypes from 'prop-types';

export default function Upload(props) {

    const {
        entityType,
        onSubmit,
        onChange,
        value,
        trigger
    } = props;

    const mediaType = entityType.indexOf('cms.') === 0 ? 'cms.media' : 'db.media';
    const mediaId = value ? value[0] : null;
    const pending = useSelector(store=>isPending(store, mediaId));

    const {
        onUpload
    } = useApiActions({
        onUpload: handleUploadCallback
    });

    const upload = useCallback(files => {
        const mediaId = uuid();
        const callback = () => {
            if (onSubmit) onSubmit();
        };
        const file = files[0];
        onUpload(callback, mediaType, null, file, mediaId);
        if (onChange) onChange([mediaId]);
    }, [onUpload, onSubmit, onChange, mediaType]);

    const clear = useCallback(() => onChange([]), [onChange]);
    const src = useCover(value, [64,64]);

    const TriggerComponent = trigger;

    return (<UploadButton
        onUpload={upload}
        trigger={<TriggerComponent
            src={src}
            pending={pending}
            onClear={clear}
        />}
    />);

}

Upload.propTypes = {};
Upload.defaultProps = {};
