import React from "react";
import cn from "classnames";

import SectionIcon from "playground/cms/views/Sections/SectionIcon";
import MoreVert from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";

//import PropTypes from 'prop-types';

export function SectionCardLabel(props) {
    const { id, data, onContextMenu, onClick } = props;
    const { title, description, used_in, library_export, used_in_ref } = data;
    return (
        <>
            <div className={"section-card-label"}>
                <div className={"section-card-icon"}>
                    <SectionIcon id={data.id} />
                </div>
                <div onClick={onClick}>{description || title}</div>
                <div className={"section-card-menu"} onClick={onContextMenu}>
                    <MoreVert />
                </div>
            </div>
            {!library_export && used_in && used_in.length !== 1 && (
                <div
                    className={cn("section-card-usage", {
                        unused: !used_in?.length,
                    })}
                >
                    {used_in?.length || "0"}
                </div>
            )}
        </>
    );
}

SectionCardLabel.propTypes = {};

SectionCardLabel.defaultProps = {};

export default SectionCardLabel;
