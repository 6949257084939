import React from "react";
import Button from "components/Button";
import { useSessionParticipants } from "joynt/hooks/sessions";
import AvatarGroup from "joynt/components/AvatarGroup";
import SessionConfirmActions from "joynt/components/SessionParticipation/SessionConfirmActions";

//import PropTypes from 'prop-types';

export function NetworkingLandingPage(props) {
    const { id, onJoin, onToggleFull } = props;
    const { identities, max, count } = useSessionParticipants(id);

    return (
        <div className={"rows gap-md rows-center"}>
            <h1>Networking landing page</h1>
            <div className={"cols gap-xs"}>
                <Button onClick={onJoin}>Join now</Button>
                <Button onClick={() => onToggleFull()}>Full</Button>
            </div>
            <AvatarGroup ids={identities} />
            <SessionConfirmActions id={id} />
        </div>
    );
}

NetworkingLandingPage.propTypes = {};
NetworkingLandingPage.defaultProps = {};

export default NetworkingLandingPage;
