import React from "react";
import ApiContext from "containers/context/ApiContext";
import RequireAuthentication from "containers/RequireAuthentication";
import ThemeProvider from "containers/Theme";
import Bootstrap from "containers/Bootstrap";
import Modal from "containers/ModalStack";
import MediaPreview from "containers/dialogs/MediaPreview";
import ConfirmDialog from "containers/dialogs/Confirm";
import Snackbar from "components/Snackbar";
import WebsocketClient from "./WebsocketClient";
import { UserAccessProvider } from "playground/console/UserAccess";

//import PropTypes from 'prop-types';

const apiContext = { noContext: true };

export function ConsoleEntrypoint(props) {
    const { children } = props;

    return (
        <ApiContext.Provider value={apiContext}>
            <RequireAuthentication>
                <UserAccessProvider>
                    <ThemeProvider target={true} loadFonts={true}>
                        <WebsocketClient>
                            <Bootstrap
                                serviceType={"console"}
                                bootstrapId={"console"}
                            >
                                {children}
                                <Modal />
                                <MediaPreview />
                                <ConfirmDialog />
                                <Snackbar />
                            </Bootstrap>
                        </WebsocketClient>
                    </ThemeProvider>
                </UserAccessProvider>
            </RequireAuthentication>
        </ApiContext.Provider>
    );
}

ConsoleEntrypoint.propTypes = {};

ConsoleEntrypoint.defaultProps = {};

export default ConsoleEntrypoint;
