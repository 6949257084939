import {get} from 'lodash';

export const changed = (keys, prevProps, props) => {
  if (!prevProps) return false;
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let path = key.split('.');
    if (get(prevProps,path) !== get(props,path)) return true;
  }
  return false;
};

export const compare = (props, nextProps) => {
  if (!props && !nextProps) return false;
  if (!props || !nextProps) return true;
  let keysLeft = Object.keys(props);
  let keysRight = Object.keys(nextProps);
  if (keysLeft.length !== keysRight.length)
    return true;
  let allKeys = keysLeft.concat(keysRight);
  for(let i = 0; i < allKeys.length; i++) {
    if (props[allKeys[i]] !== nextProps[allKeys[i]]) {
      return true;
    }
  }
  return false;
};
