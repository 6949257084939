import React from "react";

import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import NodeAccess from "joynt/components/Roles/NodeAccess";
import Field from "containers/Field";
import Page from "joynt/views/Page";

import { AccessProfile, ParentSpace } from "./../../fields";

//import PropTypes from 'prop-types';

export function ProjectCreateForm(props) {
    const { id } = props;

    return (
        <FieldsLayout>
            <FieldGroup
                label={"1. Choose where your project room will be nested"}
            >
                <ParentSpace id={id} />
            </FieldGroup>

            <FieldGroup label={"2. Project room visibility"}>
                <AccessProfile id={id} />
            </FieldGroup>

            <FieldGroup label={"3. Who's invited"}>
                <NodeAccess id={id} />
            </FieldGroup>

            <FieldGroup label={"4. Project room title and subtitle"}>
                <Field id={"name"} label={"Title"} validate={true} />
                <Field id={"description"} label={"Subtitle"} />
            </FieldGroup>

            <FieldGroup label={"5. Appearance"}>
                <Field
                    id={"color"}
                    fieldType={"ui-color-picker"}
                    label={"Choose color"}
                />
            </FieldGroup>

            <FieldGroup label={"6. Detailed description"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>
        </FieldsLayout>
    );
}

ProjectCreateForm.propTypes = {};
ProjectCreateForm.defaultProps = {};

export default ProjectCreateForm;
