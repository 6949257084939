import React from "react";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import Page from "joynt/views/Page";

//import PropTypes from 'prop-types';

export function ProjectSettings(props) {
    const { id } = props;

    return (
        <FieldsLayout>
            <FieldGroup label={"Project room title and subtitle"}>
                <Field id={"name"} label={"Title"} validate={true} />
                <Field id={"description"} label={"Subtitle"} />
            </FieldGroup>

            <FieldGroup label={"Appearance"}>
                <Field
                    id={"color"}
                    fieldType={"ui-color-picker"}
                    label={"Choose color"}
                />
            </FieldGroup>

            <FieldGroup label={"Detailed description"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>
        </FieldsLayout>
    );
}

ProjectSettings.propTypes = {};
ProjectSettings.defaultProps = {};

export default ProjectSettings;
