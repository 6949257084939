import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import WebfontIcon from "components/Icon";

export default class Icon extends React.PureComponent {
    render() {
        const { className, classes, icon, inspectId } = this.props;

        return (
            <div
                className={cn(className, classes?.icon)}
                data-inspect={inspectId}
                data-type={"icon"}
            >
                <WebfontIcon>{icon}</WebfontIcon>
            </div>
        );
    }
}

Icon.propTypes = {};
