import React from "react";
import Field from "containers/Field";
import FieldGroup from "joynt/components/Form/FieldGroup";
import { sessionPolicies } from "joynt/config/sessions";
import CategorySelect from "joynt/edit/CategorySelect";
//import PropTypes from 'prop-types';

export function SessionType(props) {
    const { label, layout } = props;

    return (
        <FieldGroup label={label}>
            <Field id={"event_session_policy"}>
                {({ onChange, value }) => (
                    <CategorySelect
                        onChange={onChange}
                        value={value}
                        layout={layout}
                        options={sessionPolicies}
                    />
                )}
            </Field>
        </FieldGroup>
    );
}

SessionType.propTypes = {};
SessionType.defaultProps = {
    label: "Choose session type",
    layout: "grid",
};
