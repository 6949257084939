import React from "react";
import Field from "containers/Field";
import { sessionBackends } from "joynt/config/sessions";
import { AssignRoles } from "joynt/edit/access";
import Page from "joynt/views/Page";
import DateSelect from "joynt/edit/DateSelect";
import FieldGroup from "joynt/components/Form/FieldGroup/FieldGroup";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldLayout from "joynt/components/Form/FieldLayout";
import SelectField from "joynt/edit/SelectField";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import { viewTypes } from "joynt/config/types";

import {
    AdvancedSettings,
    CardAppearance,
    DangerZone,
    RolesAndAccess,
    SessionType,
} from "joynt/edit/types/fields";

import Roles from "joynt/components/Roles/Container";
import ParentWorkspace from "joynt/edit/types/fields/ParentWorkspace";

function Step1({ id, isDraft }) {
    return (
        <FieldsLayout>
            {isDraft && <ParentWorkspace id={id} />}

            <FieldGroup label={"Event title and subtitle"}>
                <Field id={"name"} label={"Title"} />
                <Field id={"description"} label={"Subtitle"} />
            </FieldGroup>

            <FieldGroup label={"Event duration"}>
                <div className={"cols cols-2 gap-sm"}>
                    <DateSelect id={"event_start"} label={"Event starts"} />
                    <DateSelect id={"event_end"} label={"Event ends"} />
                </div>
            </FieldGroup>

            <CardAppearance icon={true} />

            <SessionType />
        </FieldsLayout>
    );
}

function Step2({ id, isDraft }) {
    return (
        <FieldsLayout>
            <FieldGroup label={"Description"}>
                <div
                    className={
                        "grow text-content text-edit text-content--default"
                    }
                >
                    <Page id={id} edit={true} />
                </div>
            </FieldGroup>

            <FieldGroup
                label={"Session roles"}
                description={"Add speakers and moderators to your sessions"}
            >
                <AssignRoles id={id} scope={"event"} />
            </FieldGroup>

            <RolesAndAccess
                description={"Modify access to your event if necessary"}
            />

            <AdvancedSettings>
                <FieldLayout
                    name={"Main stage"}
                    description={"Enable main stage for this event"}
                >
                    <Field
                        id={"disable_stage"}
                        fieldType={"checkbox"}
                        negate={"true"}
                    />
                </FieldLayout>
                <FieldLayout
                    name={"Video session provider"}
                    description={"Choose service for hosting live session"}
                >
                    <Field id={"event_session_provider"}>
                        {({ onChange, value }) => (
                            <SelectField
                                onChange={onChange}
                                value={value}
                                options={sessionBackends}
                            />
                        )}
                    </Field>
                </FieldLayout>
                <FieldLayout
                    name={"Estimated max participants"}
                    description={"Choose service for hosting live session"}
                >
                    <Field id={"event_max_participants"} fieldType={"text"} />
                </FieldLayout>
                <FieldLayout
                    name={"Enable workspace notifications"}
                    description={
                        "Allow to send notifications to all workspace members"
                    }
                >
                    <Field id={"enable_notifications"} fieldType={"checkbox"} />
                </FieldLayout>
                <FieldLayout name={"Default view"}>
                    <Field id={"default_view"}>
                        {({ onChange, value }) => (
                            <SelectField
                                onChange={onChange}
                                value={value}
                                options={viewTypes}
                            />
                        )}
                    </Field>
                </FieldLayout>
                <Field
                    id={"session_type"}
                    fieldType={"text"}
                    label={"Session type"}
                />
                {!isDraft ? <DangerZone /> : null}
            </AdvancedSettings>
        </FieldsLayout>
    );
}

function Settings(props) {
    return (
        <div className={"cols cols-2 gap-lg"}>
            <div className={"rows gap-md"}>
                <Step1 {...props} />
            </div>
            <div className={"rows gap-md"}>
                <Step2 {...props} />
            </div>
        </div>
    );
}

function InvitePeople(props) {
    const { id } = props;
    return (
        <div>
            <Roles id={id} />
        </div>
    );
}

const steps = {
    step1: {
        component: Step1,
        label: "Settings",
    },
    step2: {
        component: Step2,
        label: "Description & access",
        submit: true,
    },
};

const editSteps = {
    step1: {
        component: Settings,
        label: "Settings",
    },
    step2: {
        component: InvitePeople,
        label: "Manage members",
        disableActions: true,
    },
};

export default function Event(props) {
    const { isDraft } = props;

    return <MultiStepForm {...props} steps={isDraft ? steps : editSteps} />;
}

Event.propTypes = {};
Event.defaultProps = {};
