export default {
    component: "cms.components",
    "list-element": "cms.items",
    "link-item": "cms.links",
    "enum-value": "cms.enum-values",
    "db.enum-definitions/values": "db.enum-values",
    "cms.enum-definitions/values": "cms.enum-values",
    "cms.settings/default_layouts": "cms.layout-ref",
    "cms.sections/gallery": "cms.items",
    "cms.sections/links": "cms.links",
};
