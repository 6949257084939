import UserState from "./UserState";
import EventHandler from "./EventHandler";
import Subscriptions from "./Subscriptions";
import ClientRelay from "joynt/meeting/agora-ng/AgoraClient/ClientRelay";
import { CLIENT_LOGS, DEFAULT_LQ_PRESET } from "joynt/meeting/agora-ng/config";
import AgoraRTC from "agora-rtc-sdk-ng";

export default class AgoraSdkWrapper {
    constructor(appId, clients) {
        this.appId = appId;

        this.client = clients.main;
        this.clients = clients.extended || [];
        this.screenShare = null;

        this.joined = [];

        this.events = new EventHandler(this);
        this.subscriptions = new Subscriptions(this);
        this.relay = new ClientRelay(this);
        this.users = new UserState();

        this.published = {};
        this.watchCallback = null;
        this.devices = {};
    }

    async enableDualStream() {
        this.client.setLowStreamParameter({
            width: 320,
            height: 240,
            bitrate: 300,
            framerate: 15,
        });
        await this.client.enableDualStream();
        await Promise.all(this.clients.map(async (c) => c.enableDualStream()));
    }

    async join(channel, user, access) {
        this.relay.reset();
        //this.events.reset();
        this.subscriptions.reset();
        this.users.reset();

        this.bindEvents();

        const { token, tokens } = access;

        await this.client.join(this.appId, channel, token, user);

        await Promise.all(
            tokens.map(async (t, i) => {
                if (this.clients[i]) {
                    const channelName = `${channel}_${i}`;
                    this.log(`Join ${channelName}`);
                    await this.clients[i].join(
                        this.appId,
                        channelName,
                        t,
                        user
                    );
                    this.joined.push(this.clients[i]);
                }
            })
        );
    }

    async leave() {
        this.log("SDKWrapper leave");
        await Promise.all(
            this.joined.map(async (ch, i) => {
                this.log(`Leaving ${i}`);
                await this.stopLocalTracks(ch);
                await ch.leave();
                this.joined = this.joined.filter((joined) => joined !== ch);
            })
        );

        await this.stopLocalTracks(this.client);
        await this.client.leave();

        this.removeAllListeners();
        this.log("SDKWrapper left");
    }

    async stopLocalTracks(client) {
        if (!client.localTracks) return;

        await Promise.all(
            client.localTracks.map(async (track) => {
                track && (await track.setEnabled(false));
                track && track.stop();
                track && track.close();
            })
        );
    }

    enableAudioVolumeIndicator() {
        return this.client.enableAudioVolumeIndicator();
    }

    bindEvents() {
        const isOwnScreenShare = (remoteUser) => {
            if (!this.screenShare) return false;
            return remoteUser.uid === this.screenShare.streamId;
        };

        this.on(
            "connection-state-change",
            (currentState, previousState, reason) => {
                this.events.trigger({
                    event: "connection-state-change",
                    data: {
                        channel: this.channel,
                        currentState,
                        previousState,
                        reason,
                    },
                });
                this.update();
            }
        );
        this.on("user-joined", (remoteUser) => {
            this.log("user-joined");
            this.events.trigger(
                this.users.event({
                    event: "user-joined",
                    data: { remoteUser },
                })
            );
            this.update();
        });

        this.on("user-left", (remoteUser, reason) => {
            this.log("user-left");
            this.events.trigger(
                this.users.event({
                    event: "user-left",
                    data: { remoteUser, reason },
                })
            );
            this.update();
        });

        this.on("user-published", async (channel, remoteUser, mediaType) => {
            const isScreenShare = isOwnScreenShare(remoteUser);
            this.log(`user-published ${mediaType} on ${channel}`);

            if (!isScreenShare) {
                if (mediaType === "video") {
                    this.relay.register(remoteUser, channel);
                    await this.subscriptions.publish(remoteUser);
                } else {
                    await this.subscribe(remoteUser, mediaType);
                }
            }

            this.events.trigger(
                this.users.event({
                    event: "user-published",
                    data: { remoteUser, mediaType, isScreenShare },
                })
            );
            this.update();
        });

        this.on("user-unpublished", async (channel, remoteUser, mediaType) => {
            this.log(`user-unpublished ${mediaType} on ${channel}`);
            const isScreenShare = isOwnScreenShare(remoteUser);

            if (!isScreenShare) {
                if (mediaType === "video") {
                    await this.subscriptions.unpublish(remoteUser);
                    this.relay.unregister(remoteUser);
                } else {
                    await this.unsubscribe(remoteUser, mediaType);
                }
            }

            this.events.trigger(
                this.users.event({
                    event: "user-unpublished",
                    data: { remoteUser, mediaType },
                })
            );
            this.update();
        });

        this.on("volume-indicator", (result) => {
            this.events.trigger({
                event: "volume-indicator",
                data: { result },
            });
        });

        this.on("network-quality", (stats) => {
            this.events.trigger({
                event: "network-quality",
                data: { stats },
            });
        });

        this.on("exception", (data) => {
            if (data.code === 2001) {
                this.events.trigger({
                    event: "audio-level-too-low",
                    data: { user: data.uid },
                });
            }
            if (data.code === 4001) {
                this.events.trigger({
                    event: "audio-level-too-low-recover",
                    data: { user: data.uid },
                });
            }
        });

        /** Unused events, kept for future reference **/
        this.on("channel-media-relay-event", (relayEvent) => null);
        this.on("channel-media-relay-state", (state, code) => null);
        this.on("crypt-error", () => null);
        this.on("live-streaming-error", (url, error) => null);
        this.on("live-streaming-warning", (url, warning) => null);
        this.on("media-reconnect-start", (uid) =>
            this.log(`media-reconnect-start`, uid)
        );
        this.on("media-reconnect-end", (uid) =>
            this.log(`media-reconnect-end`, uid)
        );
        this.on("stream-fallback", (uid, isFallbackOrRecover) => {
            this.log(`stream-fallback`, uid, isFallbackOrRecover);
        });
        this.on("stream-type-changed", (uid, streamType) => null);
        this.on("token-privilege-did-expire", () => null);
    }

    updateRemoteUser(remoteUser, data) {
        this.users.update(remoteUser, data);
        this.events.trigger({
            event: "update-remote-tracks",
            users: this.users.get(),
        });
    }

    bindEventHandler(handler) {
        this.events.bindEventHandler(handler);
    }

    on(eventType, listener) {
        this.log(`.on ${eventType}`);

        if (["user-published", "user-unpublished"].indexOf(eventType) > -1) {
            this.client.on(eventType, (...args) => listener("main", ...args));
            this.clients.forEach((ch, i) => {
                ch.on(eventType, (...args) => listener(i, ...args));
            });
        } else {
            this.client.on(eventType, listener);
        }
    }

    async subscribe(remoteUser, mediaType) {
        this.log(`subscribe ${mediaType}`);

        const client = this.relay.subscribe(remoteUser, mediaType);
        try {
            await client.subscribe(remoteUser, mediaType);
        } catch (e) {
            console.error(`Subscribe error: ${e.message}`);
            this.update();
            return false;
        }
        const trackName = mediaType === "audio" ? "audioTrack" : "videoTrack";
        const data = { [trackName]: remoteUser[trackName] };

        if (mediaType === "audio") {
            remoteUser[trackName].play();
        }

        this.updateRemoteUser(remoteUser, data);
        this.update();
        return true;
    }

    async unsubscribe(remoteUser, mediaType) {
        this.log(`unsubscribe ${mediaType}`);

        if (mediaType === "audio" && remoteUser.audioTrack) {
            remoteUser.audioTrack.stop();
            remoteUser.audioTrack.close();
        }

        const client = this.relay.subscribe(remoteUser, mediaType);

        if (client.remoteUsers.filter((u) => u[0] === remoteUser.id).length)
            await client.unsubscribe(remoteUser, mediaType);

        const trackName = mediaType === "audio" ? "audioTrack" : "videoTrack";
        const data = { [trackName]: remoteUser[trackName] };

        this.updateRemoteUser(remoteUser, data);
        this.update();
    }

    async publish(track) {
        const mediaType = track.trackMediaType;
        this.log(`publish ${mediaType}`);
        const client = this.relay.publish(mediaType);
        await client.publish(track);
        track.on("track-ended", async () => {
            this.log(`Unpublish ${mediaType} due to track-ended event`);
            await this.unpublish(track);
            this.broadcastTracks();
        });
        this.broadcastTracks();
        return true;
    }

    async unpublish(track) {
        const mediaType = track.trackMediaType;
        this.log(`unpublish ${mediaType}`);
        const client = this.relay.published(mediaType);
        if (!track || !client) return;
        await client.unpublish(track);
        if (mediaType === "video") this.relay.index.own = null;
        track && track.stop();
        track && track.close();
        this.broadcastTracks();
        return false;
    }

    removeAllListeners() {
        this.clients.forEach((ch) => ch.removeAllListeners());
        this.client.removeAllListeners();
    }

    async setStreamFallbackOption(user, fallbackType) {
        return await this.relay
            .subscribe({ uid: user }, "video")
            .setStreamFallbackOption(user, fallbackType);
    }

    async setRemoteVideoStreamType(user, quality) {
        return await this.relay
            .subscribe({ uid: user }, "video")
            .setRemoteVideoStreamType(user, quality);
    }

    connectionState() {
        return this.client.connectionState;
    }

    channelName() {
        return this.client.channelName;
    }

    localTracks() {
        if (this.joined[0])
            return this.client.localTracks.concat(this.joined[0].localTracks);

        return this.client.localTracks;
    }

    tracks(type) {
        if (!type) return this.localTracks();
        return this.localTracks().filter(
            (track) => track.trackMediaType === type
        );
    }

    broadcastTracks() {
        this.events.trigger({
            event: "update-local-tracks",
            data: { tracks: this.tracks() },
        });
    }

    update() {
        if (this.watchCallback) this.watchCallback();
    }

    watch(fn) {
        this.watchCallback = fn;
        if (fn) fn();
    }

    async mountTile(id) {
        await this.subscriptions.subscribe(id);
        this.update();
    }

    async unmountTile(id) {
        await this.subscriptions.unsubscribe(id);
        this.update();
    }

    async testMicrophone(deviceId, setState) {
        setState("pending");
        const cfg = {};
        if (deviceId) cfg.microphoneId = deviceId;
        try {
            const track = await AgoraRTC.createMicrophoneAudioTrack(cfg);
            const status = await AgoraRTC.checkAudioTrackIsActive(track);
            setState(status ? "success" : "fail");
            track.stop();
            track.close();
        } catch (e) {
            setState("fail");
        }
    }

    async createMicrophoneTrack(deviceId) {
        const cfg = {};
        if (deviceId) cfg.microphoneId = deviceId;
        return await AgoraRTC.createMicrophoneAudioTrack(cfg);
    }

    log(msg, ...args) {
        if (CLIENT_LOGS) console.log(`[AgoraClient] ${msg}`, ...args);
    }
}
