import React from "react";
import Icon from "components/Icon";
import Popover from "components/Popover";
import SectionUsageList from "./SectionUsageList";

//import PropTypes from 'prop-types';

export function SectionUsage(props) {
    const { type, id, isTemplate, usedIn } = props;
    return (
        <Popover
            trigger={({ onClick }) => (
                <div className={"form-info"} onClick={onClick}>
                    <Icon>mui-link</Icon>
                    {isTemplate ? (
                        <div>Szablon użyty w {usedIn} sekcjach</div>
                    ) : (
                        <div>Sekcja używana w {usedIn} miejscach / gdzie?</div>
                    )}
                </div>
            )}
        >
            <SectionUsageList type={type} id={id} />
        </Popover>
    );
}

SectionUsage.propTypes = {};

SectionUsage.defaultProps = {};

export default SectionUsage;
