import React, { useCallback, useState } from "react";
import UploadButton from "components/upload/UploadButton";
import MenuItem from "components/context-menu/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import TextField from "components/fields/text/TextField";
import ContextMenu from "components/ContextMenu";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export default function MediaLibraryCreate(props) {
    const {
        onUpload,
        onCreateDirectory,
        onDeleteDirectory,
        onImportVideo,
        onImportPano,
        onSyncMedia,
        isPending,
        path,
    } = props;

    const actions = {
        "create-directory": onCreateDirectory,
        "import-video": onImportVideo,
        "import-pano": onImportPano,
    };

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const onOpen = useCallback((id) => setOpen(id), [setOpen]);
    const onClose = useCallback(() => setOpen(false), [setOpen]);

    const block = (evt) => evt.stopPropagation();

    const upload = useCallback(
        (...args) => {
            setTimeout(() => onClose(), 100);
            onUpload(...args);
        },
        [onUpload, onClose]
    );

    const submit = () => {
        if (actions[open]) actions[open](inputValue);
        setInputValue("");
        onClose();
    };

    const items = [
        <MenuItem
            onClick={() => onOpen("create-directory")}
            primaryText={"Utwórz katalog"}
            icon={"mui-create_new_folder"}
        />,
        <MenuItem
            onClick={() => onOpen("import-video")}
            primaryText={"Importuj film"}
            icon={"mui-movie"}
        />,
        <MenuItem
            onClick={() => onOpen("import-pano")}
            primaryText={"Importuj panoramę"}
            icon={"mui-360"}
        />,
        <MenuItem
            onClick={onSyncMedia}
            primaryText={"Synchronizacja z S3"}
            icon={"mui-sync"}
        />,
    ];

    if (onDeleteDirectory)
        items.push(
            <MenuItem
                onClick={() => onDeleteDirectory()}
                primaryText={`Usuń ${path}`}
                icon={"mui-delete"}
            />
        );

    return (
        <>
            <Dialog open={open} onClose={onClose} className={"drawer-modal"}>
                <div className={"console-context pad-md rows gap-sm"}>
                    <TextField
                        onChange={setInputValue}
                        value={inputValue}
                        label={open}
                    />
                    <div className={"cols cols-right gap-xs"}>
                        <Button onClick={onClose} color={"default"}>
                            Anuluj
                        </Button>
                        <Button onClick={submit} disabled={!inputValue}>
                            Zapisz
                        </Button>
                    </div>
                </div>
            </Dialog>
            <ContextMenu items={items}>
                {({ onMenuOpen }) => {
                    return (
                        <>
                            <div className={"upload-button-group"}>
                                <UploadButton
                                    onUpload={upload}
                                    trigger={
                                        <Button
                                            className={"upload-button"}
                                            size={"large"}
                                            color={"primary"}
                                            variant={"contained"}
                                            disabled={isPending}
                                            startIcon={
                                                <Icon>mui-cloud_upload</Icon>
                                            }
                                        >
                                            Wgraj plik
                                        </Button>
                                    }
                                />
                                <Button
                                    className={"more-button"}
                                    disabled={isPending}
                                    color={"primary"}
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={onMenuOpen}
                                    startIcon={<Icon>mui-more_vert</Icon>}
                                />
                            </div>
                        </>
                    );
                }}
            </ContextMenu>
        </>
    );
}

MediaLibraryCreate.propTypes = {};
MediaLibraryCreate.defaultProps = {
    isPending: false,
};
