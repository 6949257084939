import React from "react";
import Field from "containers/Field/Field";

//import PropTypes from 'prop-types';

export function FormConsent(props) {
    const { children } = props;

    return (
        <>
            {children}
            <Field
                id={"form_consent_display"}
                label={"Wyświetlanie"}
                fieldType={"select"}
                source={"schema:data.form-consent-display"}
            />
            <Field
                id={"form_consent_type"}
                label={"Wymagane zgody"}
                fieldType={"select"}
                nullable={true}
                multiple={true}
                source={"schema:data.form-consent-types"}
            />
            <Field
                id={"form_consent_optional"}
                label={"Opcjonalne zgody"}
                fieldType={"select"}
                nullable={true}
                multiple={true}
                source={"schema:data.form-consent-types"}
            />
        </>
    );
}

FormConsent.propTypes = {};

FormConsent.defaultProps = {};

export default FormConsent;
