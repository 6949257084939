import React from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";

import ModalForm from "containers/ModalForm";
import { selectModals } from "state/selectors/modal";
import useScreenSize from "util/hooks/useScreenSize";

const mapStateToProps = (store, props) => {
  return {
    modals: selectModals(store),
  };
};

const mapDispatchToProps = {};

function ModalStack(props) {
  const { modals } = props;
  const { isMobile } = useScreenSize();

  return isMobile ? (
    <Drawer anchor={"bottom"} open={!!modals.length}>
      {modals.map((modal) => {
        return <ModalForm {...modal} />;
      })}
    </Drawer>
  ) : (
    modals.map((modal) => {
      return <ModalForm {...modal} />;
    })
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStack);
