import React from "react";
import { ScreenVisibilityContext } from "joynt/components/ScreenVisibility/ScreenVisibilityContext";

//import PropTypes from 'prop-types';

export function ScreenVisibilityProvider(props) {
    const { visible, children } = props;

    return (
        <ScreenVisibilityContext.Provider value={!!visible}>
            {children}
        </ScreenVisibilityContext.Provider>
    );
}

ScreenVisibilityProvider.propTypes = {};
ScreenVisibilityProvider.defaultProps = {};

export default ScreenVisibilityProvider;
