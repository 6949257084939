import React from 'react';
import SpaceHeader from "joynt/components/SpaceHeader/SpaceHeader";

//import PropTypes from 'prop-types';

export function Calendar(props) {

    const {filter, children} = props;
    return (<div className={"calendar-view"}>
        <SpaceHeader
            name={"Calendar"}
        />
        <div className={"calendar-view__layout"}>
            <div className={"calendar-view__filter"}>
                {filter}
            </div>
            <div className={"calendar-view__content"}>
                {children}
            </div>
        </div>
    </div>);

}

Calendar.propTypes = {};
Calendar.defaultProps = {};

export default Calendar;
