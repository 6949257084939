import React, { useCallback } from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';
import AppLayout from "joynt/components/AppLayout";
import ChannelView from "joynt/ChannelView";
import { useNodeChannels } from "joynt/hooks/channels";
import { useActions, useRouteParam, useUiFlag } from "state/hooks";
import { FLAG_SESSION_NAV, ROUTE_THREAD } from "joynt/config";
import {
    useEdgesData,
    useNodeData,
    useNodeExists,
    useThread,
} from "joynt/hooks";
import People from "joynt/components/UtilityNav/DrawerContent/views/People";
import { useSessionLayout } from "joynt/components/SessionLayout";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { pushRouteParams } from "state/actions/router";
import PhoneHeader from "joynt/components/AppLayout/PhoneAppLayout/PhoneHeader/PhoneHeader";
import { useSelector } from "react-redux";
import {
    selectPresentIdentities,
    selectUserNodeIdentity,
} from "joynt/state/selectors/joint";
import { types as viewTypes } from "joynt/views";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "components/Icon";
import AvatarGroupContainer from "joynt/components/AvatarGroup";
import usePrevious from "util/hooks/usePrevious";
import AcceptInvite from "joynt/modals/AcceptInvite";
import NoAccess from "joynt/NoAccess";
import UserSettingsDrawer from "joynt/components/UserSettings/UserSettingsDrawer";
import Badge from "@material-ui/core/Badge";
import {
    useGlobalNotifications,
    useNotificationCount,
} from "joynt/hooks/notifications";
import SessionStage from "joynt/components/SessionPage/Networking/SessionStage";
import { BackToSession } from "joynt/components/SessionLayout/PhoneSessionLayout";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import { ScreenVisibilityProvider } from "joynt/components/ScreenVisibility";
import Questions from "joynt/sidebar/Questions";

function MainChat({ id }) {
    const { session_chat } = useEdgesData(id);
    const ChatView = viewTypes.chat;
    return <ChatView id={session_chat} showHeader={false} />;
}

function ChatHeader(props) {
    const { id } = props;
    const { name } = useNodeData(id);
    const { genericName: threadName, identities } = useThread(id);
    return (
        <PhoneHeader {...props} title={name || threadName}>
            {identities && <AvatarGroupContainer ids={identities} />}
        </PhoneHeader>
    );
}

function Header(props) {
    const { id } = props;
    const { view, type, views, onChangeView } = useNodeChannels(id);
    const { name } = useNodeData(id);
    const identity = useSelector((s) => selectUserNodeIdentity(s, id));
    const [side, setSide] = useUtilityNav();
    const [thread, setThread] = useRouteParam(ROUTE_THREAD);
    const current = views.find((v) => v.id === view) || {};

    const label = ["networking-session", "webinar"].indexOf(current.id) > -1 ? name : current.label;

    const profileProps = {
        identity,
        onShowUserSettings: () => setSide("profile"),
    };

    if (view === "inbox" && thread)
        return (
            <ChatHeader
                id={thread}
                onGoBack={() => setThread(null)}
                parentName={"back to Inbox"}
                title={"Chat"}
                {...profileProps}
            />
        );

    return <PhoneHeader {...profileProps} title={label} />;
}

function InboxBottomAction(props) {
    const { onClick, icon, ...other } = props;
    const { show, count, onSeen } = useGlobalNotifications("messages");
    const click = () => {
        onSeen();
    };
    return (
        <BottomNavigationAction
            {...other}
            onClick={click}
            icon={
                <Badge
                    badgeContent={show ? count : null}
                    color={"secondary"}
                    overlap={"circular"}
                >
                    {icon}
                </Badge>
            }
        />
    );
}

function ChatBottomAction(props) {
    const { id, icon, ...other } = props;
    const { session_chat } = useEdgesData(id);
    const [notifications] = useNotificationCount(session_chat);
    return (
        <BottomNavigationAction
            {...props}
            icon={
                <Badge
                    badgeContent={notifications}
                    color={"secondary"}
                    overlay={"circular"}
                    variant={"dot"}
                >
                    {icon}
                </Badge>
            }
        />
    );
}

function PeopleNavAction(props) {
    const { workspace, icon, ...other } = props;
    const presence = useSelector((s) =>
        selectPresentIdentities(s, workspace, "nodes")
    );
    return (
        <BottomNavigationAction
            {...other}
            icon={
                <Badge
                    badgeContent={presence.length}
                    color={"primary"}
                    overlap={"circular"}
                >
                    {icon}
                </Badge>
            }
        />
    );
}

function BottomNavAction(props) {
    const { value, icon, ...other } = props;
    const id = props.value;
    const components = {
        inbox: InboxBottomAction,
        "main-chat": ChatBottomAction,
        people: PeopleNavAction,
        default: BottomNavigationAction,
    };
    const Component = components[id] || components.default;
    return (
        <Component value={id} showLabel icon={<Icon>{icon}</Icon>} {...other} />
    );
}

function BottomNav({ id, workspace, value, onChange, views }) {
    const handleChange = useCallback((e, v) => onChange(v), [onChange]);
    return (
        <BottomNavigation value={value} onChange={handleChange} showLabels>
            {views.map((v) => {
                return (
                    <BottomNavAction
                        key={v.id}
                        id={id}
                        workspace={workspace}
                        value={v.id}
                        label={v.label}
                        icon={v.icon}
                    />
                );
            })}
        </BottomNavigation>
    );
}

function UserSettings(props) {
    const { id } = props;
    const [side, setSide] = useUtilityNav();
    const identity = useSelector((s) => selectUserNodeIdentity(s, id));

    return (
        <UserSettingsDrawer
            id={identity}
            isOpen={side === "profile"}
            onClose={() => setSide(null)}
        />
    );
}

function InboxView(props) {
    const Component = viewTypes.inbox;
    return <Component {...props} showFilters={false} showParentLink={false}/>;
}

function QuestionsPhone(props) {
    const { id } = props;
    const { threads } = useEdgesData(id);
    return (
        <div className={"pad-xs rows grow overflow-hide"}>
            <Questions {...props} id={threads} />
        </div>
    );
}

function Materials(props) {
    const FeedComponent = viewTypes.feed;
    return <FeedComponent {...props} view={"cards"} showParentLink={false} showHeader={false}/>;
}

const types = {
    pending: viewTypes.pending,
    inbox: InboxView,
    "networking-session": viewTypes["networking-session"],
    people: People,
    "session-chat": MainChat,
    feed: Materials,
    webinar: viewTypes.webinar,
    qa: QuestionsPhone,
};

export function AppPhone(props) {
    const { id, channel, rootNode, pending } = props;

    const { onPushParams } = useActions({ onPushParams: pushRouteParams });
    const { view, type, views, onChangeView } = useNodeChannels(channel);

    const { session } = useMeeting();
    const { expanded, onToggle } = useSessionLayout();
    const [, setSessionNav] = useUiFlag(FLAG_SESSION_NAV);

    const previousView = usePrevious(view);
    const viewIds = views.map((v) => v.id);

    const direction =
        viewIds.indexOf(view) > viewIds.indexOf(previousView)
            ? "left"
            : "right";

    const viewChanged = previousView && previousView !== view;

    const setView = useCallback(
        (v) => {
            if (v === "inbox" && view === "inbox") {
                onPushParams({ [ROUTE_THREAD]: null });
            } else {
                onChangeView(v);
            }
        },
        [onPushParams, onChangeView, view]
    );

    const backToSession = () => {
        setSessionNav(null);
        onToggle();
    };

    const noAccessView = (
        <AppLayout>
            <NoAccess />
        </AppLayout>
    );

    const exists = useNodeExists(id);
    if (!exists && !pending) return noAccessView;

    const backgroundSession = session && !expanded;

    return (
        <>
            <AcceptInvite
                id={id}
                root={rootNode}
                noAccessView={noAccessView}
                isPending={pending}
            >
                <ScreenVisibilityProvider visible={!session || !expanded}>
                    <AppLayout
                        id={id}
                        isPending={pending || type === "pending"}
                        header={<Header id={channel} />}
                        className={cn("page-layout", {
                            "background-session": backgroundSession,
                        })}
                        bottomNav={
                            <BottomNav
                                workspace={id}
                                id={channel}
                                views={views}
                                onChange={setView}
                                value={view}
                            />
                        }
                    >
                        {type && (<>
                            <ChannelView
                                workspace={id}
                                id={channel}
                                channel={null}
                                view={type}
                                types={types}
                                transition={true}
                                slideFrom={viewChanged ? direction : null}
                            />
                        </>)}
                    </AppLayout>
                </ScreenVisibilityProvider>
                <UserSettings id={id} />
                <BackToSession
                    visible={backgroundSession}
                    onClick={backToSession}
                />
                <ScreenVisibilityProvider visible={session && expanded}>
                    <SessionStage id={id} />
                </ScreenVisibilityProvider>
            </AcceptInvite>
        </>
    );
}

AppPhone.propTypes = {};
AppPhone.defaultProps = {};

export default AppPhone;
