export default [
    {
        id: "witness-hold",
        messages: {
            "%a": "Waiting for %bName to connect",
            "%b": "Waiting for %aName to connect"
        },
        aside: "witness-welcome",
        view: "witness-hold",
        duration: 3
    }
]