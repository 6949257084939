import React from "react";
//import PropTypes from "prop-types";

import MultiStepForm from "joynt/edit/MultiStepForm";

import { SessionCreateForm } from "./create";

import {
    SessionSettings,
    ManageSessionAccess,
    //SessionFiles,
    SessionAdvancedSettings,
} from "./edit";

const createSteps = {
    step1: {
        component: SessionCreateForm,
        label: "Settings",
        title: "Schedule session",
        submit: true,
    },
};

const editSteps = {
    edit: {
        component: SessionSettings,
        label: "General",
    },
    access: {
        component: ManageSessionAccess,
        label: "Access",
    },
    // files: {
    //     label: "Files",
    //     component: SessionFiles,
    //     disableActions: true,
    // },
    advanced: {
        label: "Advanced",
        component: SessionAdvancedSettings,
    },
};

export default function Session(props) {
    const { isDraft } = props;

    return (
        <MultiStepForm
            {...props}
            steps={isDraft ? createSteps : editSteps}
            showHeader={false}
        />
    );
}

Session.propTypes = {};
Session.defaultProps = {};
