import React from "react";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";
import ComponentTree from "./ComponentTree";
import { normalizeType } from "cms/util/connect";
import useLocalStorage from "util/hooks/useLocalStorage";
import { nodeFlags } from "./util";

function nodeProps(node) {
    const type = normalizeType(node.element_type);
    return {
        id: node.id,
        type,
        label: node.style || type,
        disabled: node.disabled,
        ...nodeFlags(node),
    };
}

function selectPath(store, type, id) {
    const path = [];
    let current = selectEntity(store, type, id);
    let parent = current?.__parent?.id;

    while (parent) {
        path.unshift(parent);
        current = selectEntity(store, type, parent);
        parent = current?.__parent?.id;
    }

    return path;
}

function selectTreeNode(store, type, items, expanded, level = 0) {
    return items.map((id) => {
        let entity = selectEntity(store, type, id);
        const children = selectTreeNode(
            store,
            type,
            entity.components || [],
            expanded,
            level + 1
        );
        const isExpanded =
            expanded[id] === true || (expanded[id] !== false && level < 4);
        return {
            ...nodeProps(entity),
            expanded: isExpanded,
            children,
        };
    });
}

function selectTree(store, type, id, expanded) {
    const root = selectEntity(store, type, id);
    const children =
        type === "cms.sections" ? root.items || [] : root.components || [];
    const tree = selectTreeNode(store, "cms.components", children, expanded);
    return { tree, root };
}

function useTreeState(type, id) {
    const [state, setState] = useLocalStorage(`tree-state.${id}`, {});
    const onExpand = (id) => {
        setState({ ...state, [id]: true });
    };
    const onCollapse = (id) => {
        setState({ ...state, [id]: false });
    };
    const onToggle = (id, expand) => {
        const next = [true, false].includes(expand) ? expand : !state[id];
        setState({ ...state, [id]: next });
    };
    return { state: { ...state }, onExpand, onCollapse, onToggle };
}

function ComponentTreeContainer(props) {
    const { type, id, focusType, focusId, onFocus } = props;

    const {
        state: expandState,
        onExpand,
        onCollapse,
        onToggle,
    } = useTreeState(type, id);

    const path = useSelector((store) => selectPath(store, focusType, focusId));

    path.forEach((id) => {
        expandState[id] = true;
    });

    const { tree, root } = useSelector((store) =>
        selectTree(store, type, id, expandState)
    );

    const toggle = ({ node, expanded }) => {
        onToggle(node?.id, expanded);
    };

    return (
        <ComponentTree
            {...props}
            tree={tree}
            root={root}
            onExpand={onExpand}
            onCollapse={onCollapse}
            onClick={onFocus}
            onVisibilityToggle={toggle}
        />
    );
}

export default ComponentTreeContainer;
