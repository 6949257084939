import React from "react";
import { useCurrentUser, useData } from "state/hooks";
import { renderMentions } from "joynt/components/messages/index";

export function MessageSummary({ id }) {
    const { content, identity, media, link } = useData({
        type: "db.messages",
        id,
    });
    const { name, created_by } = useData({
        type: "db.identities",
        id: identity,
    });
    const user = useCurrentUser();
    const identityName = created_by === user ? "You" : name;
    if (link) {
        return (
            <p>
                <b>{identityName}</b> shared a link
            </p>
        );
    }
    if (media && media.length) {
        return (
            <p>
                <b>{identityName}</b> shared an image
            </p>
        );
    }
    if (!content) return null;
    return (
        <p>
            <b>{identityName}: </b>
            {renderMentions(content, true)}
        </p>
    );
}
