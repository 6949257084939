import {useApiActions} from "state/hooks";
import {startChat} from "joynt/state/actions/messages";
import {useCallback} from "react";

export default function useChat(parent, id) {
    const {onShow} = useApiActions({
        onShow: startChat
    });

    const show = useCallback(() => {
        onShow(parent, id);
    }, [onShow, parent, id]);

    return {onShow: show};
}