import React from 'react';

//import PropTypes from 'prop-types';
import Field from "containers/Field";
import {contentViews} from "joynt/config/types";
import CategorySelect from "joynt/edit/CategorySelect";

export function ContentView(props) {

    return (<Field id={"content_view"}>
        {({onChange, value}) => <CategorySelect
            onChange={onChange}
            value={value}
            options={contentViews}
        />}
    </Field>);

}

ContentView.propTypes = {};
ContentView.defaultProps = {};

export default ContentView;
