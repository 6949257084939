import { accessTypes as nodeAccessTypes } from "joynt/components/Roles/config";

export const sessionPolicies = {
    default: {
        id: null,
        label: "Training course",
        description: "Anyone can join and leave the stage freely",
        default: true,
    },
    event: {
        id: "event",
        label: "Event",
        description: "Only speakers and moderators on stage",
    },
    meeting: {
        id: "meeting",
        label: "Meeting",
        description: "Everyone always on stage",
    },
};

export const sessionBackends = {
    jitsi: {
        id: "jitsi",
        label: "Jitsi",
    },
    opentok: {
        id: "opentok",
        label: "Opentok",
    },
    agora: {
        id: "agora",
        label: "Agora",
    },
    "zoom-sdk": {
        id: "zoom-sdk",
        label: "Zoom SDK",
    },
};

export const SESSION_TYPE_STAGE = "stage";
export const SESSION_TYPE_PUBLIC = "public";
export const SESSION_TYPE_PRIVATE = "private";
export const SESSION_TYPE_NETWORKING = "networking";
export const SESSION_TYPE_NETWORKING_TABLE = "networking-table";
export const SESSION_TYPE_WITNESS = "witness";
export const SESSION_TYPE_WEBINAR = "webinar";
export const SESSION_TYPE_WEBINAR_SERIES = "webinar-series";

export const sessionTypes = {
    stage: {
        id: SESSION_TYPE_STAGE,
        label: "On-stage",
        description: "Public session on featured stage",
        data: {
            session_type: SESSION_TYPE_STAGE,
            event_stage: true,
            hidden: true,
            access_type: null,
            ...nodeAccessTypes.public.data,
            features: {
                chat: true,
                files: false,
                qa: false,
                rooms: false
            }
        },
    },
    public: {
        id: SESSION_TYPE_PUBLIC,
        label: "Public",
        description: "Open session",
        data: {
            session_type: SESSION_TYPE_PUBLIC,
            event_stage: false,
            hidden: false,
            access_type: null,
            ...nodeAccessTypes.public.data,
            features: {
                chat: true,
                files: false,
                qa: false,
                rooms: false
            }
        },
    },
    private: {
        id: SESSION_TYPE_PRIVATE,
        label: "Private",
        description: "Private meeting",
        data: {
            session_type: SESSION_TYPE_PRIVATE,
            event_stage: false,
            hidden: false,
            access_type: null,
            ...nodeAccessTypes.private.data,
            features: {
                chat: true,
                files: false,
                qa: false,
                rooms: false
            }
        },
    },
    [SESSION_TYPE_WEBINAR]: {
        id: SESSION_TYPE_WEBINAR,
        label: "Webinar",
        description: "Public webinar",
        data: {
            session_type: SESSION_TYPE_WEBINAR,
            access_type: "link",
            event_stage: false,
            hidden: false,
            ...nodeAccessTypes.public.data,
            features: {
                chat: true,
                files: true,
                qa: true,
                rooms: false
            }
        }
    },
    [SESSION_TYPE_NETWORKING]: {
        id: SESSION_TYPE_NETWORKING,
        label: "Networking",
        description: "Networking with tables",
        data: {
            session_type: SESSION_TYPE_NETWORKING,
            access_type: "link",
            event_stage: false,
            hidden: false,
            ...nodeAccessTypes.public.data,
            features: {
                chat: true,
                qa: false,
                files: false,
                rooms: true
            }
        }
    },
    [SESSION_TYPE_WEBINAR_SERIES]: {
        id: SESSION_TYPE_WEBINAR_SERIES,
        label: "Webinar series",
        data: {
            session_type: SESSION_TYPE_WEBINAR_SERIES,
            access_type: "link",
            event_stage: false,
            hidden: true,
            ...nodeAccessTypes.public.data,
            features: {
                chat: true
            }
        }
    }
};

export const accessTypes = {
    open: {
        id: "open",
        label: "Public",
        description: "Anyone can join",
    },
    invite: {
        id: "invite",
        label: "Invite only",
        description: "Only invited people",
    },
    // private: {
    //     id: 'private',
    //     label: 'Private',
    //     description: 'Only visible for the owner'
    // }
};

export const recordingModes = {
    mix: {
        id: "mix",
        label: "Mix",
    },
    web: {
        id: "web",
        label: "Web",
    },
};
