import React from "react";
//import PropTypes from 'prop-types';

import { useCurrentService } from "playground/cms/hooks";
import { useApiActions, useData, useFetch } from "state/hooks";
import { updateSchemaConfig } from "cms/state/actions/services";
import Button from "components/Button";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import Checkbox from "components/fields/checkbox/Checkbox";

const availableTypes = {
    sections: [
        "default-content",
        "content",
        "view",
        "placeholder",
        "presentation-slide",
    ],
    entries: [
        "offers",
        "reviews",
        "rooms",
        "rooms_conf",
        "attractions",
        "posts",
        "gallery",
        "contacts",
        "projects",
        "routes",
    ],
    types: [
        "categories",
        "products",
        "properties",
        "plans",
        "sync-sources",
        "sync-tasks",
        "presentation-endpoints",
    ],
};

function SchemaTypes(props) {
    const { id, value, onChange } = props;
    const values = availableTypes[id] || [];

    const toggle = (type) => {
        let next = [...(value || [])];
        if (next.includes(type)) {
            next = next.filter((n) => n !== type);
        } else {
            next.push(type);
        }
        onChange(next);
    };

    const toggleEnableFilter = (type) => {
        if (value === null) {
            onChange([]);
        } else {
            onChange(null);
        }
    };

    return (
        <div className={"rows"}>
            <div>{id}</div>
            <Checkbox
                onChange={() => toggleEnableFilter(id)}
                value={value !== null}
                label={`Enable filter ${id}`}
            />
            {value &&
                values.map((type) => (
                    <Checkbox
                        variant={"checkbox"}
                        key={type}
                        label={type}
                        value={value.includes(type)}
                        onChange={() => toggle(type)}
                    />
                ))}
        </div>
    );
}

SchemaTypes.defaultProps = {
    value: null,
};

export function SchemaSettings(props) {
    const {} = props;
    const { id } = useCurrentService();

    useFetch({
        type: "console.schema-config",
        id: id,
        url: `console/websites/:id/schema`,
    });

    const { onSubmit } = useApiActions({ onSubmit: updateSchemaConfig });

    const config = useData({ type: "console.schema-config", id: id });

    const handleSubmit = (next) => {
        onSubmit(id, next);
    };

    return (
        <div className={"pad-md"}>
            <AggregateRoot
                type="console.schema-config"
                id={id}
                disableSubmit={true}
            >
                <div className={"grid grid-3 gap-sm"}>
                    <Field id={"entries"} FieldComponent={SchemaTypes} />
                    <Field id={"types"} FieldComponent={SchemaTypes} />
                    <Field id={"sections"} FieldComponent={SchemaTypes} />
                </div>
            </AggregateRoot>
            {/*<Button onClick={() => handleSubmit(config)}>Save</Button>*/}
        </div>
    );
}

SchemaSettings.propTypes = {};

SchemaSettings.defaultProps = {};

export default SchemaSettings;
