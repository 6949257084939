import React from 'react';
import {useRootNode, useRouteNodes, useRouter} from "joynt/hooks/channels";
import useFetch from "state/hooks/useFetch";
import RenderCircles from "./mind-map";

//import PropTypes from 'prop-types';

export function Circles(props) {

    const {onClose} = props;
    const root = useRootNode();
    const url = `v2/joynt/nodes/${root}/graph`;

    const {id} = useRouteNodes();
    const {onLink} = useRouter();

    useFetch({
        type: "db.nodes",
        list: "db.nodes.graph",
        url
    });

    return (<div>
        <RenderCircles
            root={root}
            id={id}
            width={680}
            onClick={onLink}
            onHide={onClose}
        />
    </div>);

}

Circles.propTypes = {};
Circles.defaultProps = {};

export default Circles;
