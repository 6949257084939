import React from "react";
import SessionPreviewSection from "./../SessionPreviewSection";

//import PropTypes from 'prop-types';

export function RolesList(props) {
    const { children } = props;
    return (
        <SessionPreviewSection title={"Roles"}>
            {children}
        </SessionPreviewSection>
    );
}

RolesList.propTypes = {};
RolesList.defaultProps = {};

export default RolesList;
