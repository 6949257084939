import {useCurrentUser} from "state/hooks";
import {useSelector} from "react-redux";
import {selectEntity} from "state/selectors/data";

export function useTotalNotifications() {
    const user = useCurrentUser();
    const stats = useSelector(s=>selectEntity(s, 'db.notification-stats', user));
    const messages = stats.messages || {};
    const activity = stats.activity || {};

    let total = 0;
    [messages, activity].forEach((group) => {
        if (group.count && group.updated_at > group.seen_at) total += group.count;
    });

    return total;
}