import {BlockMapBuilder} from "draft-js";

export function insertNewBlockToContentState(contentState, newBlock, after) {
    let blocks = contentState.getBlocksAsArray();
    let keys = blocks.map(block => block.getKey());
    let index = keys.indexOf(after);
    let newBlocks = [].concat(
        blocks.slice(0, index+1),
        [newBlock],
        blocks.slice(index)
    );

    return contentState.merge({
        blockMap: BlockMapBuilder.createFromArray(newBlocks)
    });
}