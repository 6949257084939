export function getCaretPosition(editableDiv) {
    var caretPos = 0,
        sel,
        range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            if (range.commonAncestorContainer.parentNode == editableDiv) {
                caretPos = range.endOffset;
            }
        }
    } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() == editableDiv) {
            var tempEl = document.createElement("span");
            editableDiv.insertBefore(tempEl, editableDiv.firstChild);
            var tempRange = range.duplicate();
            tempRange.moveToElementText(tempEl);
            tempRange.setEndPoint("EndToEnd", range);
            caretPos = tempRange.text.length;
        }
    }
    return caretPos;
}

export function getCurrentToken(value, caretPosition, separator) {
    // Split the value into tokens based on the separator
    const tokens = value.split(separator);

    // Calculate the running index which will be updated as we go through the tokens
    let runningIndex = 0;

    // Iterate through tokens to find the one that includes the caret position
    for (let i = 0; i < tokens.length; i++) {
        // Add the length of the current token and the separator (except for the last token)
        const nextIndex =
            runningIndex + tokens[i].length + (i < tokens.length - 1 ? 1 : 0);

        // Check if the caret position is within the current token range
        if (caretPosition >= runningIndex && caretPosition <= nextIndex) {
            return i; // Return the current token
        }

        // Update the running index for the next token
        runningIndex = nextIndex;
    }

    // Return null if the caret position is not within any token (this should not happen in normal cases)
    return null;
}
