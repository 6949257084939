import React from 'react';
//import PropTypes from 'prop-types';
import cn from 'classnames';
import Field from 'containers/Field';

import 'cms/ui/css/style-inspector.css';
import StyleElement from 'cms/ui/style/StyleElement';
import CreateStyle from "cms/ui/CreateStyle";
import Popover from "components/Popover";
import Button from "components/Button";

export default class Styles extends React.PureComponent {

    render() {
        const {
            layer,
            type,
            style,
            styles,
            focusStyle,
            elements,
            allElements,
            defaultElements,
            onSelectLayer,
            onFocusStyle,
            onChange,
            hasBreakpoints,
            hasInline
        } = this.props;

        return (<div>
            {hasBreakpoints && <div className={'pad-xs'}>
                <Field
                    id={'breakpoints'}
                    fieldType={'select'}
                    source={'schema:data.device-visibility'}
                    variant={'dense'}
                    label={'Device visibility'}
                    nullable={true}
                />
            </div>}
            <div className={'rows pad-xs gap-sm'}>
                <div className={'cols gap-xs'}>
                    <div className={'grow'}>
                        <Field
                            id={'style'}
                            fieldType={'select'}
                            source={'api:cms.theme.styles'}
                            variant={'dense'}
                            label={'Style'}
                            disableFetch={true}
                            defaultFilterValue={`${type}.`}
                            nullable={true}
                        />
                    </div>
                    <div className={'cols cols-center cols-bottom'}>
                        <CreateStyle type={type} />
                    </div>
                </div>
                <div className={'cols'}>
                    {hasInline && <div
                        onClick={()=>onSelectLayer('inline')}
                        className={cn('style-class', {'primary': layer==='inline'})}
                    >
                        inline
                    </div>}
                    { style !== type ? <div
                        onClick={()=>onSelectLayer('style')}
                        className={cn('style-class', {'primary': layer==='style'})}
                    >
                        {style}
                    </div> : null }
                    <div
                        onClick={()=>onSelectLayer('global')}
                        className={cn('style-class', {'primary': layer==='global'})}
                    >
                        global
                    </div>

                </div>
            </div>
            { elements ? elements.map(s => {
                return <StyleElement
                    key={s}
                    id={s}
                    value={styles ? styles[s] : null}
                    selected={s===focusStyle}
                    onChange={onChange}
                    onFocus={()=>onFocusStyle(s)}
                />;
            }) : null}
            { defaultElements ? defaultElements.map(s => {
                return <StyleElement
                    key={s}
                    id={s}
                    value={styles ? styles[s] : null}
                    selected={s===focusStyle}
                    onChange={onChange}
                    onFocus={()=>onFocusStyle(s)}
                />;
            }) : null}
            { allElements ? <Popover
                trigger={({onClick})=><div className={'pad-xs cols cols-center'}>
                    <Button onClick={onClick}>Add</Button>
                </div>}
                content={()=><div className={'style-select-menu type-default'}>{allElements.map(s => {
                    return <div className={'style-select'} onClick={()=>onChange(s, [])}>{s}</div>;
                })}</div>}
            /> : null }
        </div>);
    }

}

Styles.propTypes = {};
Styles.defaultProps = {
    hasBreakpoints: true,
    hasInline: true
}