import React from 'react';
import PropTypes from 'prop-types';

import List from 'containers/List';
import Fetch from 'containers/Fetch';

import {SectionThumbnailIterator} from "cms/ui/SectionThumbnail";
import ThemeShadow from "cms/ui/ThemeShadow";
import {StylesProvider} from "cms/styles/legacy";
import {Draggable} from "components/dnd";
import PreloaderContainer from "containers/Preloader";
import SectionFilter from 'cms/ui/SectionFilter';

const NewSection = React.forwardRef((props, ref) => {
    return <div className={props.className} ref={ref}>
        {props.children}
    </div>;
});

function DummySection(props) {
    return <Draggable
        id={props.id}
        type={'cms.sections'}
        render={NewSection}
        className={'section-thumbnail t-default l-area--centered'}
    >
        {props.children}
    </Draggable>
}

export default class SectionBrowser extends React.PureComponent {

    render() {
        const {
            type,
            isLayout,
            list,
            library,
            url,
            service,
            onClick,
            create,
            section,
            origin
        } = this.props;
        return (<>
            <SectionFilter
                id={list}
                library={library}
                service={service}
            />
            <PreloaderContainer id={[type,list].join('/')}>
                <ThemeShadow
                    service={service}
                    className={'rows grow overflow-hide'}
                >
                    <div className={'sections-list'}>
                        <Fetch
                            type={type}
                            list={list}
                            url={url}
                            force={true}
                        >
                            <StylesProvider>
                                <List
                                    className={'sections-list-layout'}
                                    type={type}
                                    list={list}
                                    origin={origin}
                                    section={section}
                                    onClick={onClick}
                                    Iterator={SectionThumbnailIterator}
                                >
                                    { create ? <>
                                        <DummySection id={'@NEW'}>new section</DummySection>
                                        <DummySection id={'@NEW_TPL'}>new template</DummySection>
                                        { isLayout ? <DummySection id={'@PLACEHOLDER'}>new layout placeholder</DummySection> : null }
                                    </> : null }
                                </List>
                            </StylesProvider>
                        </Fetch>
                    </div>
                </ThemeShadow>
            </PreloaderContainer>
        </>);
    }

}

SectionBrowser.propTypes = {
    create: PropTypes.bool
};

SectionBrowser.defaultProps = {
    create: false
};