import React from 'react';
import PropTypes from 'prop-types';

import ModalOverlay from "components/modal/ModalOverlay";
import ModalBody from "components/modal/ModalBody";

import 'css/modal.css';

export default function Modal(props) {
    const { onClose } = props;

    return (<>
        <ModalOverlay onClick={onClose}/>
        <ModalBody>
            {props.children}
        </ModalBody>
    </>);

}

Modal.propTypes = {
    onClose: PropTypes.func
};