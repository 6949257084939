import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import Portal from "@material-ui/core/Portal";
import SessionCard from "joynt/components/SessionCard";
import Slider from "joynt/components/Slider";
import useScreenSize from "util/hooks/useScreenSize";
import Preloader from "components/Preloader";
import EmptyState from "./EmptyState";

import "./style.css";

function SessionCardPreviewPortal(props) {
    const { pos, children } = props;
    const style = {
        position: "absolute",
        left: pos ? pos.x : null,
        top: pos ? pos.y : null,
        width: pos ? pos.width : null,
    };
    return (
        <Portal>
            <div style={style}>{children}</div>
        </Portal>
    );
}

function SessionCardWithPreview(props) {
    const [over, setOver] = useState(false);
    const ref = useRef();
    const [pos, setPos] = useState(null);

    useLayoutEffect(() => {
        if (over && ref.current) {
            setPos(ref.current.getBoundingClientRect());
        }
    }, [over]);

    return (
        <div ref={ref}>
            <SessionCard
                {...props}
                onMouseEnter={() => setOver(true)}
                onMouseLeave={() => null}
            />
            {over && (
                <SessionCardPreviewPortal {...props} pos={pos}>
                    <SessionCard
                        expand={true}
                        {...props}
                        onMouseLeave={() => setOver(false)}
                    />
                </SessionCardPreviewPortal>
            )}
        </div>
    );
}

export function SessionsSlider(props) {
    const { items, actions, filter, isPending, enablePreview } = props;

    const { isMobile, isTablet } = useScreenSize();

    let slidesNumber = 3.5;

    if (isMobile) slidesNumber = 1;
    if (isTablet) slidesNumber = 2;

    return (
        <div className={"sessions-slider-section"}>
            <Preloader visible={isPending} />
            <div className={"sessions-slider-header"}>
                <div className={"sessions-slider-title"}>Sessions</div>
                {actions}
                {filter}
            </div>
            {items.length ? (
                <Slider
                    items={slidesNumber}
                    margin={16}
                    className={"sessions-slider"}
                    arrows
                    key={`session-slider-filter--${filter}`}
                >
                    {items.map((id) => {
                        return !isMobile && enablePreview ? (
                            <SessionCardWithPreview key={id} id={id} />
                        ) : (
                            <SessionCard key={id} id={id} />
                        );
                    })}
                </Slider>
            ) : (
                <EmptyState />
            )}
        </div>
    );
}

SessionsSlider.propTypes = {
    enablePreview: PropTypes.bool,
};
SessionsSlider.defaultProps = {
    enablePreview: false,
};

export default SessionsSlider;
