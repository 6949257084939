import React, {useCallback} from 'react';
import {push} from "connected-react-router";
import List from "containers/List";
import Fetch from "containers/Fetch";
import {ArrayIterator} from "components/list/iterator";
import {searchInput} from "playground/ListFormView";
import FilterField from "containers/FilterField";
import Card from "@material-ui/core/Card";
import Entrypoint from "containers/Entrypoint";
import useLang from "state/hooks/useLang";
import {useActions, useClick, useData} from "state/hooks";

function ProjectCard(props) {
    const {onClick} = props;
    const {service_id, name} = useData(props);
    const id = service_id || name;
    const click = useClick({onClick, id});

    return <Card onClick={click} className={'rows'}>
        <div className={'pad-sm'}>{id}</div>
    </Card>;
}

const ListItemIterator = ArrayIterator(ProjectCard);

export default function ProjectsDashboardContainer(props) {
    const lang = useLang();
    const {onOpenProject} = useActions({onOpenProject: push});
    const open = useCallback((id) => {
        onOpenProject(`${lang}/${id}`);
    }, [onOpenProject, lang]);
    return <ProjectsDashboard
        type={'console.services'}
        list={'console.services'}
        lang={lang}
        url={'console.services?type=website,db,apartments'}
        onOpenProject={open}
    />
}

function ProjectsDashboard(props) {
    const {type, list, url, onOpenProject} = props;

    return (<Entrypoint>
        <Fetch
            type={type}
            list={list}
            url={url}
            useContext={false}
        >
            <div className={'rows gap-sm pad-md'}>
                <FilterField
                    {...searchInput}
                    type={type}
                    list={list}
                    id={'search'}
                    label={'Search...'}
                />
                <div className={'grid grid-4 phone@grid-1 gap-xs'}>
                    <List
                        onClick={onOpenProject}
                        type={type}
                        list={list}
                        Iterator={ListItemIterator}
                    />
                </div>
            </div>
        </Fetch>
    </Entrypoint>);
}