import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import { EditorBlock } from "draft-js";

import "./style.css";

export const ToDo = React.forwardRef((props, ref) => {
    const { onChange, block, completedKey, readOnly } = props;
    const data = block.getData();

    const isCompleted = data.has(completedKey) ? data.get(completedKey) : false;

    const toggle = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onChange) onChange(data.set(completedKey, !isCompleted));
        },
        [onChange, completedKey, data, isCompleted]
    );

    return (
        <div
            className={cn("block-todo", {
                "is-completed": isCompleted,
            })}
            ref={ref}
            contentEditable={readOnly ? false : undefined}
        >
            <div
                className={"block-todo__checkbox"}
                onMouseDown={toggle}
                contentEditable={false}
            >
                <Checkbox onChange={() => null} checked={isCompleted} />
            </div>
            <EditorBlock {...props} />
        </div>
    );
});

ToDo.propTypes = {};

ToDo.defaultProps = {
    completedKey: "completed",
};

export default ToDo;
