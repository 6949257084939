import React from 'react';
//import PropTypes from 'prop-types';
import Icon from "components/Icon";
import Popover from '@material-ui/core/Popover';
import List from "containers/List";

import {ConnectedField as Field} from "containers/Field";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import IconButton from "components/IconButton";
import Button from '@material-ui/core/Button';

import 'playground/booking/css/guest-types.css';

export function RoomGuestType(props) {
    const { index, onDelete } = props;
    const { id, room } = props.data; //adults
    return <div className={'rows'}>
        <div className={'guest-room-label cols cols-spread cols-middle'}>
            <div>Pokój {index+1}</div>
            { index > 0 ? <div className={'cols cols-center'}>
                <IconButton
                    onClick={()=>onDelete(id)}
                    icon={'mui-clear'}
                />
            </div> : null }
        </div>
        <div>{room}</div>
        <div className={'rows gap-sm pad-sm'}>
            <Field
                min={1}
                label={'Dorośli'}
                entityType={'app.booking-rooms'}
                entityId={id}
                id={'adults'}
                fieldType={'stepper'} />
            <Field
                label={'Dzieci'}
                entityType={'app.booking-rooms'}
                entityId={id}
                id={'children'}
                fieldType={'stepper'} />
        </div>

    </div>;
}

export const RoomGuestTypeIterator = ArrayIterator(withData(RoomGuestType));

export function RoomGuestTypes(props) {
    const {
        type,
        value,
        active,
        onClick,
        onCreate,
        onDelete,
        onClose
    } = props;
    return <div className={'rows divider border-light'}>
        <List
            onDelete={onDelete}
            onClick={onClick}
            type={type}
            items={value}
            active={active}
            Iterator={RoomGuestTypeIterator} />
        <div className={'cols cols-spread'}>
            <Button onClick={onCreate}>
                dodaj kolejny pokój
            </Button>
            <Button onClick={onClose}>
                zamknij
            </Button>
        </div>
    </div>;
}

export default class GuestPicker extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }
    }

    open = (evt) => {
        this.setState({anchorEl: evt.target, open: true});
    };

    close = () => {
        this.setState({open: false});
    };

    render() {
        const { type, id } = this.props;
        const { open, anchorEl } = this.state;
        return (<>
            <div onClick={this.open} className={'booking-search-field cols cols-middle gap-sm'}>
                <Icon>mui-group</Icon>
                <div className={'date-value'}>
                    <div className={'date-primary'}>2 dorosłych</div>
                    <div className={'date-secondary'}>4 dzieci, 2 pokoje</div>
                </div>
            </div>
            <Popover open={open} anchorEl={anchorEl} onClose={this.close}>
                <div className={'rows guest-types-select'}>
                    <Field
                        entityType={type}
                        entityId={id}
                        id={'rooms'}
                        fieldType={'collection'}
                        collectionType={'app.booking-rooms'}
                        CollectionRenderer={RoomGuestTypes}
                        onClose={this.close}
                    />
                </div>
            </Popover>
        </>);
    }

}

GuestPicker.propTypes = {};