import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import { breakpointClasses } from "config/devices";
import defaultClasses from "./legacy-classes";
import cn from "classnames/dedupe";

import Scope from "cms/styles/legacy/php/Scope";
import Styles from "cms/styles/legacy/php/Styles";

export const STYLES_NS = "cms.styles";
const nullObj = {};

const mapDisplayToClass = ({ display, breakpoints }) => {
    let classes = [];

    if (display) {
        classes = classes.concat(display);
    }

    const map = {
        alt: "t-alt",
        primary: "t-primary",
        //success: "t-success",
        invert: "t-invert",
        parallax: "Background Background--image relative t-on-image",
        "t-primary": "t-primary",
        "t-default": "t-default",
        "t-invert": "t-invert",
        "t-success": "t-success",
        "t-light": "t-light",
        "t-dark": "t-dark",
        "t-alt": "t-alt",
        "t-on-image": "t-on-image",
        "t-black": "t-black",
        "t-primary-alt": "t-primary-alt",
        "t-success-alt": "t-success-alt",
        "t-dark-alt": "t-dark-alt",
        "t-light-gray": "t-light-gray",
    };

    const options = classes.map((opt) => {
        return map[opt] || null;
    });

    if (breakpoints) {
        options.push(breakpointClasses[breakpoints]);
    }

    return options.filter((opt) => {
        return !!opt;
    });
};

export const buildStyles = (styles) => {
    const s = new Styles();
    s.definitions = styles.definitions;
    s.appendOptions(styles.options);
    Object.keys(styles).forEach((k) => {
        if (k !== "definitions") {
            Object.keys(styles[k]).forEach((c) => {
                let ns = k.replace(".", ":") + "." + c;
                if (ns.indexOf("@tags") > -1) {
                    s.appendConfig(ns, styles[k][c]);
                } else {
                    s.append(ns, styles[k][c]);
                }
            });
        }
    });
    return s;
};

const adapter = (scope, styles) => {
    return {
        fork: (ns, style, styles) => {
            return adapter(scope.__call(ns, [style]), styles);
        },
        clone: () =>
            adapter(new Scope(scope.ns, scope.styles, scope.parentScope)),
        get: (ns) => {
            if (styles && styles[ns]) {
                return scope.extend(ns, styles[ns]);
            }
            //return scope.__get(ns);
            return scope.extend(ns, "");
        },
        display: (ns, data) => {
            let withClasses = mapDisplayToClass(data);
            if (styles && styles[ns])
                withClasses = styles[ns].concat(withClasses);
            let result = scope.extend(ns, withClasses);
            return result;
        },
        tag: (tags, append) => scope.tag(tags, append),
        bool: (ns) => {
            if (styles && styles[ns]) return styles[ns][0] === "true";
            return !!scope.__get(ns);
        },
        extend: (ns, withClasses) => scope.extend(ns, withClasses),
        api: () => scope.styles,
        scope: () => scope,
        rwd: (ns, { breakpoints }) => {
            if (!breakpoints) return scope.__get(ns);
            let classes = scope.__get("ns");
            return (classes += " " + breakpointClasses[breakpoints]);
        },
        classes: (prevClasses = {}, breakpoints) => {
            if (!scope.ns) return nullObj;
            let type = scope.ns.split(":")[0];
            let defs = scope.styles.definitions;
            let elementDefaults = defaultClasses[type] || {};
            let typeDef = defs ? defs[type] : {};
            let keys = typeDef ? Object.keys(typeDef) : [];
            let classes = {}; //{...prevClasses};
            let s = adapter(scope, styles);
            keys.forEach((k) => {
                classes[k] = s.get(k);
                classes[k] = cn(elementDefaults[k], classes[k]);
                if (k === type && breakpoints) {
                    classes[k] = cn(classes[k], breakpointClasses[breakpoints]);
                }
            });
            return classes;
        },
    };
};

export const computeStyles = (data, changes) => {
    let all = Object.keys(data).concat(Object.keys(changes));
    let merge = {};
    all.forEach((key) => {
        let styleData = data[key] || {};
        let styleDraft = changes[key] || {};
        merge[key] = { ...styleData, ...styleDraft };
    });
    return merge;
};

export const selectStyles = createSelector(
    [
        (store) => store.data[STYLES_NS] || nullObj,
        (store) => store.changes[STYLES_NS] || nullObj,
    ],
    (data, changes) => computeStyles(data, changes)
);

export const stylesHelper = createSelector([selectStyles], (styles) => {
    if (!styles) return null;
    const api = buildStyles(styles).scope("root");
    return adapter(api);
});

function mergeStyles(styles, merge) {
    const result = { ...styles };
    Object.keys(merge).forEach((k) => {
        if (result[k]) {
            result[k] = result[k].concat(merge[k]);
        } else {
            result[k] = merge[k] ? merge[k].slice() : [];
        }
    });
    return result;
}

const getStyles = (stylesApi, ns, style, k, ownStyles, parentStyles) => {
    if (!stylesApi) return null;
    if (!ns) return stylesApi;
    const styles = mergeStyles(parentStyles || {}, ownStyles || {});
    return stylesApi.fork(ns, style, styles);
    /*let fork = parentStyles
        ? stylesApi.fork(ns, style, parentStyles)
        : stylesApi.fork(ns, style, ownStyles);
    if (parentStyles) fork = fork.fork(ns, style, ownStyles);*/
    //let fork = stylesApi.fork(ns, style, ownStyles);
    //if (parentStyles) return fork.fork(ns, style, parentStyles);
    //return fork;
};

export const styled = createCachedSelector(
    [
        (store, props) => props.s,
        (store, props, ns) => props.element_type,
        (store, props, ns, item) => props.style,
        (store, props, ns, item, k) => k || "0",
        (store, props, ns, item) => props.styles,
        (store, props, ns, item) => props.parentStyles,
        (store, props) => props.id,
    ],
    getStyles
)((store, props) => {
    let style = props.style || props.element_type;
    let cacheKeyParts = [props.id, style];
    //cacheKeyParts.push(JSON.stringify(props.styles));
    //cacheKeyParts.push(JSON.stringify(props.parentStyles));
    return cacheKeyParts.join("/");
});

export const makeComponentClasses = (s, type, classes, breakpoints) => {
    if (!s) return nullObj;
    return s.classes(classes, breakpoints) || nullObj;
};

function makeAttributes(classes) {
    const attributes = classes["@attributes"];
    if (!attributes) return nullObj;
    const attributesArr = attributes.split(" ");
    return attributesArr.reduce((acc, attr) => {
        const [key, value] = attr.split(":");
        acc[key] = value || key;
        return acc;
    }, {});
}

export const selectClasses = createCachedSelector(
    [
        (store, props) => styled(store, props),
        (store, props) => props.element_type,
        (store, props) => props.classes,
        (store, props) => props.breakpoints,
    ],
    (s, type, classes, breakpoints) => {
        const cl = makeComponentClasses(s, type, classes, breakpoints);
        const attributes = makeAttributes(cl);
        return {
            s,
            classes: cl,
            attributes,
        };
    }
)((store, props) => props.id);
