import React from 'react';
import cn from 'classnames';
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {getIn} from "lodash-redux-immutability";
import {useApiActions, usePending} from "state/hooks";
import {reload} from "state/actions/list";
import Icon from "components/Icon";
import Button from "components/Button";
import {CircularProgress} from "@material-ui/core";

export default function ListReload(props) {

    const {url, type, id, text, floating} = props;

    const updated = useSelector(s => {
        return getIn(s, ['lists-meta', type, id, 'updated']);
    });
    const pending = usePending([type,id].join('/'));
    const {onReload} = useApiActions({onReload: reload});

    const show = updated && (floating || !pending);

    if (show) {
        return <div className={cn('list-reload pad-sm rows rows-center', {
            'is-floating': floating
        })}>
            <Button
                disabled={!!pending}
                onClick={()=>onReload(type, id, url)}
                startIcon={pending ? <CircularProgress size={16} /> : <Icon>mui-refresh</Icon>}
                color={floating ? 'primary' : 'default'}
            >
                {text}
            </Button>
        </div>;
    }
    return null;

}

ListReload.propTypes = {
   floating: PropTypes.bool
};
ListReload.defaultProps = {
    text: 'New posts',
    floating: false
};
