import React from 'react';
import cn from "classnames";
import PropTypes from 'prop-types';

export function NavGroup(props) {

    const {
        isActive,
        children
    } = props;

    return (<div className={cn("tray-group", {
        "is-active": isActive
    })}>
        {children}
    </div>);

}

NavGroup.propTypes = {
    isActive: PropTypes.bool
};
NavGroup.defaultProps = {
    isActive: false
};

export default NavGroup;
