import React from "react";
//import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";

import StageEvent from "joynt/views/stage/StageEvent";
import StageMeeting from "joynt/views/stage/StageMeeting";

import Session from "joynt/components/Session";
import { useStage } from "joynt/hooks/sessions";

import "./style.css";

export function StageContainer(props) {
    const { id, isEvent } = props;
    const stageProps = useStage(id, isEvent);

    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );

    return (
        <Stage {...props} {...stageProps} stageId={id} presence={presence} />
    );
}

export function Stage(props) {
    const { id, isEvent } = props;

    const {
        isLive,
        isPending,
        isJoining,
        isConnected,
        onJoin,
        onEndSession,
        onStartNextSession,
        error,
        session,
        role,
        eventId,
        stageId,
        presence,
    } = props;

    const DefaultStageView = !isEvent ? StageMeeting : StageEvent;
    const StageView = props.StageView || DefaultStageView;

    if (error || isConnected) {
        return <Session id={eventId || session} session={session} />;
    }

    return (
        <StageView
            id={isEvent ? eventId : id}
            stageId={stageId}
            isLive={isLive}
            isPending={isPending}
            isJoining={isJoining}
            role={role}
            onJoin={isLive || !isEvent ? onJoin : null}
            onEnd={isLive || !isEvent ? onEndSession : null}
            onResume={onStartNextSession}
            presence={presence}
        />
    );
}

Stage.propTypes = {};
Stage.defaultProps = {};

export default StageContainer;
