import uuid from "uuid/v4";
import { all, put, select, takeEvery, race, take } from "redux-saga/effects";
import { create } from "state/actions/create";
import { pushRouteParams } from "state/actions/router";
import { selectEntity } from "state/selectors/data";
import { pathAppend, pathChange, pathDetach, reject } from "state/actions/data";
import { change, dragSectionPrompt, saveCallback } from "cms/state/actions/cms";
import { denormalize } from "state/util";
import { selectRouteParam } from "state/selectors/router";
import { NEW_ITEM_ID } from "components/dnd";
import { makeMergeStyles } from "cms/state/sagas/cms";
import { getLibraryState } from "playground/cms/state/selectors/library";
import { setFlag } from "state/actions/ui";

export function* createSection(data = {}) {
    let id = uuid();
    if (!data.type) data.type = "default-content";
    const item = {
        id,
        ...data,
        active: true,
    };
    delete item.library_export;
    yield put(create("cms.sections", item));
    return item.id;
}

function copy(item, recursive) {
    const next = {
        ...item,
        id: uuid(),
        used_in: [],
    };
    if (recursive) {
        recursive.forEach((key) => {
            if (next[key]) next[key] = copyCollection(next[key], recursive);
        });
    }
    return next;
}

function copyCollection(items, keys) {
    return items.map((item) => copy(item, keys));
}

function selectCopySection(store, type, id) {
    const data = denormalize(store, type, id);
    const next = copy(data, [
        "items",
        "components",
        "links",
        "gallery",
        "media",
    ]);
    //console.log(data, next);
    return next;
}

function* handleConvertToTemplate({ payload }) {
    const { type, id } = payload;
    try {
        const data = yield select((store) =>
            selectEntity(store, "cms.sections", id)
        );
        if (data.type === "view") return id;
        const { items, attributes, description, style, styles } = data;
        const template = yield createSection({
            type: "view",
            items,
            attributes,
            description,
            style,
            styles,
        });
        yield put(
            change("cms.sections", id, {
                items: null,
                attributes: null,
                description: null,
                style: null,
                styles: null,
                template,
            })
        );
        return id;
    } catch (e) {
        console.error(e);
    }
}

export function* copySection(type, id) {
    return yield select((store) => selectCopySection(store, type, id));
}

function* createDragSection(id, refId, action) {
    const actionId = action.id || null;

    if (refId === "@NEW") {
        return yield createSection();
    }
    if (refId === "@PLACEHOLDER") {
        return yield createSection({ type: "placeholder" });
    }
    if (refId === "@NEW_TPL") {
        let templateId = yield createSection({ type: "view" });
        return yield createSection({ template: templateId });
    }

    if (id === NEW_ITEM_ID) {
        const data = yield select((store) =>
            selectEntity(store, "cms.sections", refId)
        );

        if (actionId === "clone") {
            if (data.type === "view") {
                let template = yield createSection(
                    yield copySection("cms.sections", refId)
                );
                return yield createSection({ template });
            }
            return yield createSection(
                yield copySection("cms.sections", refId)
            );
        }

        if (actionId === "clone-view") {
            let template = yield createSection(
                yield copySection("cms.sections", data.template)
            );
            return yield createSection({
                ...(yield copySection("cms.sections", refId)),
                template,
            });
        }

        if (actionId === "ref") {
            return null;
        }

        if (data.type === "view") {
            return yield createSection({ template: refId });
        }

        return refId;
    }

    return id;
}

function* copyLibrarySection(state, type, id, origin) {
    return yield select((store) => {
        return {
            ...selectCopySection(state, type, id),
            library_ref: `${origin}.${id}`,
        };
    });
}

function selectUsedStyles(data) {
    let styles = [];
    if (data.style) styles.push({ id: data.id, style: data.style });
    const reducer = (acc, item) => {
        if (item.style) acc.push({ id: item.id, style: item.style });
        if (item.components) acc = item.components.reduce(reducer, acc);
        return acc;
    };
    styles = data.items.reduce(reducer, styles);
    return styles;
}

function importStyle(state, style) {
    const value = selectEntity(state, "cms.styles", style);
    console.log("Create style", value);
    return put(change("cms.styles", style, value));
}

function* importStyles(state, styles) {
    yield all(styles.map((style) => importStyle(state, style)));
}

function inlineStyles(state, data, styles) {
    const merge = makeMergeStyles(state);
    const inline = (node) => {
        let next = { ...node };
        if (node.style && styles.includes(node.style)) {
            const styles = selectEntity(state, "cms.styles", node.style);
            next.style = null;
            next.styles = merge(styles, node.styles);
        }
        if (next.items) {
            next.items = next.items.map(inline);
        }
        if (next.components) {
            next.components = next.components.map(inline);
        }
        return next;
    };
    return inline(data);
}

function* createSectionFromLibrary(newId, refId, origin, payload, pageType) {
    const libraryState = getLibraryState(origin);
    const data = denormalize(libraryState, "cms.sections", refId);

    const stylesToImport = Object.keys(payload?.styles || {}).filter((s) => {
        return payload.styles[s].action === "import";
    });

    const stylesToInline = Object.keys(payload?.styles || {}).filter((s) => {
        return payload.styles[s].action === "inline";
    });

    let source;
    yield importStyles(libraryState, stylesToImport);

    function* copy(id) {
        return inlineStyles(
            libraryState,
            yield copyLibrarySection(libraryState, "cms.sections", id, origin),
            stylesToInline
        );
    }

    // console.log(data, origin, payload);
    // return;

    //what about a sibling website ref section?

    if (data.type === "view") {
        source = yield copy(refId);
        let template = yield createSection(source);
        if (pageType === "cms.sections") return template;
        return yield createSection({ template: template });
    }

    if (data.template) {
        //copy template
        source = yield copy(data.template);
        let template = yield createSection(source);

        //copy section
        source = yield copy(refId);
        return yield createSection({
            ...source,
            template: template,
        });
    }

    source = yield copy(refId);
    return yield createSection(source);
}

function* createLibraryReference(refId, origin, pageType) {
    const libraryState = getLibraryState(origin);
    const data = denormalize(libraryState, "cms.sections", refId);

    let template = yield createSection({
        type: "view",
        description: data.description,
        reference_id: refId,
    });

    if (pageType === "cms.sections") return template;

    const section = yield createSection({
        template,
    });

    return section;
}

function* handleDragSection({ payload, context, callback }) {
    try {
        const { pageType, pageId, result } = payload;
        const { newId, refId, origin, target } = result;

        console.log(payload);
        //yield put(reject(pageType, pageId));
        //return;

        const originId = origin || "local";
        const targetId = target || "local";

        let items = result.items.slice();
        let currentItems = result.currentItems.slice();
        let actionId = refId || newId;
        let choice = {};

        let id;

        if (origin && origin !== "local" && target) {
            actionId = [refId, origin].join(".");
        }

        const prompt = JSON.stringify({
            id: refId,
            origin: originId,
            target: targetId,
        });

        console.log(actionId, result);

        //id = yield createSectionFromLibrary(newId, refId);

        if (actionId.indexOf("@") === -1) {
            yield put(dragSectionPrompt(prompt));
            choice = yield race({
                select: take("CMS.SECTIONS.SELECT"),
                cancel: take("CMS.SECTIONS.CANCEL"),
            });
            if (!choice.select) {
                yield put(reject(pageType, pageId));
                return;
            }
        }
        let selectedAction = choice?.select?.payload || {};
        let actionData = choice?.select?.payload?.data || null;

        let selectedActionId = selectedAction.id || null;

        if (originId === targetId) {
            id = yield createDragSection(newId, refId, selectedAction);
        } else {
            if (selectedActionId === "ref") {
                id = yield createLibraryReference(refId, originId, pageType);
            } else {
                id = yield createSectionFromLibrary(
                    newId,
                    refId,
                    originId,
                    actionData,
                    pageType
                );
            }
        }

        if (!id || items.indexOf(id) > -1) {
            console.log(
                "Reject because no id or items contains id",
                id,
                currentItems
            );
            yield handlePromptCancel();
            if (pageType && pageId) {
                yield put(reject(pageType, pageId));
            }
            return;
        }

        items = id
            ? items.map((i) => (i === newId ? id : i))
            : items.filter((i) => i !== newId);

        if (pageType && pageId) {
            yield put(pathChange([pageType, pageId, "sections"], items));
        }

        if (targetId !== originId && !pageId) {
            const cb = () => {
                if (targetId === "local") alert("Imported from library");
                if (originId === "local") alert("Exported to library");
            };
            const targetContext =
                target === "local" ? context : { project: target };
            yield put(saveCallback(targetContext)(cb));
        }

        yield handlePromptCancel();

        if (callback) {
            callback({
                action: selectedAction,
                id,
                newId,
                refId,
            });
        }

        //console.log(selectedAction, id, newId, refId);

        /** Only show form if we are creating a new section and not attaching existing one */
        const shouldShowForm =
            id && id !== refId && (target === "local" || !target);

        if (shouldShowForm)
            yield put(
                pushRouteParams({
                    //focus: ["cms.sections", id].join("/"),
                    section: id,
                    list: "tree",
                })
            );
    } catch (e) {
        console.log(e);
    }
}

function* handleSectionPrompt({ payload }) {
    try {
        const { id } = payload;
        yield put(setFlag("cms.sections.prompt", { prompt: id }));
        //yield put(pushRouteParams({ prompt: id }));
    } catch (e) {
        console.log(e);
    }
}

function* handlePromptCancel(action) {
    try {
        yield put(setFlag("cms.sections.prompt", { prompt: null }));
        //yield put(pushRouteParams({ prompt: null }));
    } catch (e) {
        console.log(e);
    }
}

function* handleDetachSection({ context, payload }) {
    try {
        const { id } = payload;
        const pageType = yield select((s) => selectRouteParam(s, "resource"));
        const pageId = yield select((s) => selectRouteParam(s, "id"));
        //const typePath = pageType.split('.').slice(0,2).join('/');
        //const url = `${typePath}/${pageId}/sections/${id}`;
        yield put(pathDetach([pageType, pageId, "sections"], id));
        //alert(url);
    } catch (e) {
        console.log(e);
    }
}

function* handleCreateSection({ payload }) {
    try {
        const { pageType, pageId, data } = payload;
        yield put(create("cms.sections", data));
        yield put(pathAppend([pageType, pageId, "sections"], data.id));
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeEvery("CMS.SECTIONS.DRAG", handleDragSection);
    yield takeEvery("CMS.SECTIONS.PROMPT", handleSectionPrompt);
    yield takeEvery("CMS.SECTIONS.CANCEL", handlePromptCancel);
    yield takeEvery("CMS.SECTIONS.DETACH", handleDetachSection);
    yield takeEvery("CMS.SECTIONS.CREATE", handleCreateSection);
    yield takeEvery(
        "CMS.SECTIONS.CONVERT_TO_TEMPLATE",
        handleConvertToTemplate
    );
}
