import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import CachedPreview from "./CachedPreview";

import "./style.css";

export default function SectionThumbnail(props, ref) {
    const {
        id,
        cacheKey,
        section,
        onClick,
        onContextMenu,
        isVisible,
        isActive,
        className,
        children,
    } = props;

    const str = (
        <CachedPreview
            type={"cms.sections"}
            previewTemplate={section ? id : null}
            id={section || id}
            cacheKey={cacheKey}
            isVisible={isVisible}
        />
    );

    return (
        <div
            ref={ref}
            className={cn(className, {
                inactive: !isActive,
            })}
            onClick={onClick}
            onContextMenu={onContextMenu}
        >
            <div className={"section-preview-container"}>{str}</div>
            {children}
        </div>
    );
}

SectionThumbnail.propTypes = {};
SectionThumbnail.defaultProps = {
    data: {},
};

SectionThumbnail.autoFillProps = ["title", "description"];
