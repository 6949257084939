import React from "react";
import { useData, useFetch, useUiFlag } from "state/hooks";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";

//import PropTypes from 'prop-types';

export function WebsiteRedirects(props) {
    const {} = props;
    const [sitemapUrl, setSitemapUrl] = useUiFlag("cms.redirects.sitemapUrl");
    useFetch({
        id: sitemapUrl,
        type: "cms.target-sitemap",
        url: "cms/sitemap-redirects/browse-sitemap?url=" + sitemapUrl,
        enabled: !!sitemapUrl,
    });
    const sourceSitemap = useData({
        type: "cms.target-sitemap",
        id: sitemapUrl,
    });

    return (
        <div className={"settings-view"}>
            <div className={"settings-view-content"}>
                <div className={"cols"}>
                    <TextField
                        onChange={setSitemapUrl}
                        value={sitemapUrl}
                        label={"Sitemap URL"}
                    />
                    <Button onClick={() => null}>Load</Button>
                </div>
                <pre>{JSON.stringify(sourceSitemap, null, 2)}</pre>
            </div>
        </div>
    );
}

WebsiteRedirects.propTypes = {};

WebsiteRedirects.defaultProps = {};

export default WebsiteRedirects;
