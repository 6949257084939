import React from "react";
import Button from "components/Button";
import Profiles from "joynt/participants/Profiles";
import { useNodeData } from "joynt/hooks";
import PendingSession from "joynt/meeting/PendingSession";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export default function StageMeeting(props) {
    const { id, onJoin, onEnd, presence, isPending, isJoining } = props;

    const presentUsers = presence.map((presenceId) => {
        return { id: presenceId };
    });

    const { event_state } = useNodeData(id);
    const isLive = event_state === "live";

    return (
        <div className={"relative grow rows gap-md rows-center rows-middle"}>
            <Preloader visible={isPending} />
            <PendingSession visible={isJoining} />
            {!isPending && !isJoining ? (
                <>
                    <Profiles items={presentUsers} parent={id} />
                    <div className={"cols cols-middle gap-xs"}>
                        {onEnd && isLive ? (
                            <Button color={"default"} onClick={onEnd}>
                                End session
                            </Button>
                        ) : null}
                        <Button onClick={onJoin} size={"large"}>
                            Join session
                        </Button>
                    </div>
                </>
            ) : null}
        </div>
    );
}

StageMeeting.propTypes = {};
StageMeeting.defaultProps = {};
