import React from "react";
import cn from "classnames";
import Preloader from "components/Preloader";

//import PropTypes from 'prop-types';

export function PageLayout(props) {
    const {
        children,
        utilityNav,
        showUtilityNav,
        isStageView,
        showSidebar,
        isPending,
    } = props;

    const className = cn("joynt-layout", {
        "show-sidebar": showSidebar,
        "show-utility-nav": showUtilityNav,
        "stage-view": isStageView,
        "dark-mode": true,
        bg: true,
    });

    return (
        <div className={className}>
            <div className={"main"}>
                <div className={"content"}>{children}</div>
            </div>
            {utilityNav}
            <Preloader visible={isPending} />
        </div>
    );
}

PageLayout.propTypes = {};
PageLayout.defaultProps = {};

export default PageLayout;
