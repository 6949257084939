import React from "react";
import StageStatusMessage from "joynt/components/SessionFlow/StageStatusMessage";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import Button from "components/Button";
import { useUiFlag } from "state/hooks";

//import PropTypes from 'prop-types';

export function SessionOverlay(props) {
    const { id } = props;
    const { session } = useMeetingSession();
    const [collapsed, setCollapsed] = useUiFlag(`layout.collapse.${session}`);

    if (!collapsed) return null;

    if (id !== "networking") return <StageStatusMessage {...props} />;

    return (
        <StageStatusMessage {...props}>
            <Button onClick={() => setCollapsed(false)}>Go to tables</Button>
        </StageStatusMessage>
    );
}

SessionOverlay.propTypes = {};
SessionOverlay.defaultProps = {};

export default SessionOverlay;
