import React, {useCallback} from 'react';
import {useNodeData} from "joynt/hooks";
import SlideTransition from "joynt/components/LiveNow/SlideTransition";
import MainStageEvent from "./MainStageEvent.container";
import {useRouteParam} from "state/hooks";
import {ROUTE_CHANNEL, ROUTE_VIEW, STAGE_VIEW} from "joynt/config";
import {useRouter} from "joynt/hooks/channels";
//import PropTypes from 'prop-types';

export function LiveNowContainer(props) {
    const {id, view, Component} = props;
    const {current_event, next_event} = useNodeData(id);
    const [channel] = useRouteParam(ROUTE_CHANNEL);
    const [currentView] = useRouteParam(ROUTE_VIEW);
    const {onLink} = useRouter();
    const isActive = currentView === view && !channel;

    const click = useCallback(() => {
        onLink(id, null, view);
    }, [onLink, id, view]);

    return <SlideTransition
        id={id}
        workspace={id}
        current={current_event}
        next={next_event}
        onClick={click}
        isActive={isActive}
        Component={Component}
    />
}

LiveNowContainer.propTypes = {};

LiveNowContainer.defaultProps = {
    Component: MainStageEvent,
    view: STAGE_VIEW
};

export default LiveNowContainer;