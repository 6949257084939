import React from "react";
//import PropTypes from 'prop-types';

import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";

export function SessionActivity(props) {
    return (
        <FieldGroup
            label={"Activity notifications"}
            description={"Notify everyone about activity in session"}
            addon={
                <Field id={"unfollow"} fieldType={"checkbox"} negate={true} />
            }
        />
    );
}

SessionActivity.propTypes = {};
SessionActivity.defaultProps = {};

export default SessionActivity;
