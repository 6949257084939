import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

export function LinkStatus(props) {
    const { linkType, isBroken } = props;
    const description = {
        sitemap: "Link do podstrony",
        config: "Wartość z konfiguracji",
        media: "Link do pliku",
    }[linkType];
    return (
        <div className={"link-ref-status"}>
            <div className={"link-ref-badge"}>{isBroken ? "Błąd" : "OK"}</div>
            <div className={"link-ref-label"}>{description || linkType}</div>
        </div>
    );
}

LinkStatus.propTypes = {};

LinkStatus.defaultProps = {};

export default LinkStatus;
