import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useActions, useApiActions } from "state/hooks";
import { submitOneCallback } from "state/actions/api";
import { rejectRequest, validate } from "state/actions/data";
import { selectIsDraft } from "state/selectors/data";
import { isValidForm } from "state/selectors/validation";
import { submitNode } from "joynt/state/actions/nodes";

export function useForm(type, id, cb) {
    const { onSubmit, onSubmitNode } = useApiActions({
        onSubmit: submitOneCallback,
        onSubmitNode: submitNode,
    });
    const { onReject, onValidate } = useActions({
        onReject: rejectRequest,
        onValidate: validate,
    });

    const isDraft = useSelector((store) => selectIsDraft(store, type, id));
    const isValid = useSelector((store) => isValidForm(store, type, id));

    const formSubmit = useCallback(
        (data) => {
            if (type === "db.nodes") {
                onSubmitNode(cb, id, data);
            } else {
                onSubmit(() => cb(isDraft), type, id);
            }
        },
        [onSubmit, onSubmitNode, isDraft, type, id, cb]
    );

    const formReject = useCallback(() => {
        onReject(type, id);
        if (cb) {
            cb(false);
        }
    }, [onReject, type, id, cb]);

    const formValidate = useCallback(() => {
        onValidate(type, id);
    }, [onValidate, type, id]);

    useEffect(() => {
        if (!isDraft) formValidate();
    }, [isDraft, formValidate]);

    return useMemo(
        () => ({
            onSubmit: formSubmit,
            onReject: formReject,
            onValidate: formValidate,
            isDraft,
            isValid,
        }),
        [formSubmit, formReject, formValidate, isDraft, isValid]
    );
}
