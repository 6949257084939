import React from 'react';
//import PropTypes from 'prop-types';
import SessionAside from "./../SessionAside";

export function WitnessWelcome(props) {

    //const {} = props;

    return (<SessionAside
        title={"Congratulations and welcome to your Witness Task Session"}
    >
        <h3>First things first:</h3>
        <div>Say hi, describe your task, and why you want to do it.</div>
        <div>
            <p>Make sure the task is well defined - for example:</p>
            <p>Good description:<br />„I want to write 2 pages of my new novel.” — 2 pages is a well stated goal, easy to measure.</p>
            <p>Bad description:<br />„I want to work on my novel.” — less productive without a clear goal.</p>
        </div>
    </SessionAside>);

}

WitnessWelcome.propTypes = {};
WitnessWelcome.defaultProps = {};

export default WitnessWelcome;
