import { useActions } from "state/hooks";
import { changeRole } from "joynt/state/actions/members";
import { useCallback, useMemo } from "react";

export function useRoleEdit(list, defaultRole) {
    const { onChange } = useActions({
        onChange: changeRole,
    });

    const addEmail = useCallback(
        (email, role) => {
            onChange(
                null,
                {
                    email,
                    role: role || defaultRole,
                    action: "add",
                },
                list
            );
        },
        [onChange, list, defaultRole]
    );

    const addRole = useCallback(
        (identity, role) => {
            onChange(
                identity,
                {
                    identity,
                    role: role || defaultRole,
                    action: "add",
                },
                list
            );
        },
        [onChange, list, defaultRole]
    );

    const remove = useCallback(
        (id) => {
            onChange(id, { action: "remove" }, list);
        },
        [onChange, list]
    );

    const revert = useCallback(
        (id) => {
            onChange(id, { action: "revert" }, list);
        },
        [onChange, list]
    );

    const change = useCallback(
        (id, data = {}) => {
            onChange(id, { ...data, action: "change" }, list);
        },
        [onChange, list]
    );

    return useMemo(
        () => ({
            onAddEmail: addEmail,
            onAddRole: addRole,
            onRemoveRole: remove,
            onRevertRole: revert,
            onChangeRole: change,
        }),
        [addEmail, addRole, remove, revert, change]
    );
}
