import React, { useCallback, useState } from "react";
import { useSessionNotifications } from "./hooks/useSessionNotifications";
import { useClick, useData } from "state/hooks";
import { ArrayIterator } from "components/list/iterator";
import List from "containers/List";
import IconButton from "joynt/components/IconButton";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import Icon from "components/Icon";
import Button from "components/Button";
import useTimeout from "util/hooks/useTimeout";
//import PropTypes from 'prop-types';

function NotificationLayout(props) {
    const { icon, children } = props;
    return (
        <div className={"cols cols-middle gap-sm grow"}>
            {icon && (
                <div>
                    <Icon fontSize={"large"}>{icon}</Icon>
                </div>
            )}
            <div className={"rows grow"}>{children}</div>
        </div>
    );
}

function DeviceConnected({ deviceName, deviceType }) {
    const icons = {
        videoInput: "mui-videocam",
        audioInput: "mui-mic",
    };
    return (
        <NotificationLayout icon={icons[deviceType]}>
            {deviceName} is now available
        </NotificationLayout>
    );
}

function DeviceDisconnected({ deviceName, deviceType }) {
    const icons = {
        videoInput: "mui-videocam_off",
        audioInput: "mui-mic_off",
    };
    return (
        <NotificationLayout icon={icons[deviceType]}>
            {deviceName} is no longer available
        </NotificationLayout>
    );
}

function DefaultNotification({ id, error }) {
    const { onShowSettings } = useMeeting();

    if (error)
        return (
            <NotificationLayout icon={"mui-error"}>{error}</NotificationLayout>
        );

    const text = descriptions[id] || `Error (${id})`;

    return (
        <NotificationLayout icon={icons[id]}>
            <div className={"cols cols-spread gap-md grow cols-middle"}>
                <div>{text}</div>
                <Button
                    onClick={() => onShowSettings(true)}
                    startIcon={<Icon>mui-help</Icon>}
                    variant={"text"}
                >
                    Learn more
                </Button>
            </div>
        </NotificationLayout>
    );
}

const icons = {
    NO_CAMERA_AVAILABLE: "mui-videocam_off",
    CAMERA_ACCESS_DENIED: "mui-videocam_off",
    CAMERA_ACCESS_PROMPT: "mui-videocam_off",
    NO_INTERNET: "mui-signal_wifi_off",
    BAD_CONNECTION_QUALITY: "mui-signal_wifi_statusbar_connected_no_internet_4",
};

const descriptions = {
    NO_CAMERA_AVAILABLE: "Camera not available",
    CAMERA_ACCESS_DENIED: "Joynt is blocked from accessing your camera",
    CAMERA_ACCESS_PROMPT: "Please allow Joynt to access your camera",
    NO_INTERNET: "You're offline.",
    BAD_CONNECTION_QUALITY: "Bad network quality",
};

const types = {
    DEVICE_CONNECTED: DeviceConnected,
    DEVICE_DISCONNECTED: DeviceDisconnected,
    default: DefaultNotification,
};

function Notification(props) {
    const { id, onDismiss } = props;
    const data = useData(props);
    const dismiss = useClick({ id, onClick: onDismiss });
    const [hover, setHover] = useState(false);
    const over = useCallback(() => setHover(true), [setHover]);
    const out = useCallback(() => setHover(false), [setHover]);
    const autoHide = data.id && !data.permanent && !hover;

    useTimeout(dismiss, autoHide ? 8000 : null);

    const type = data.type || id;
    const NotificationComponent = types[type] || types.default;

    return (
        <div
            className={"session-notification cols cols-middle cols-spread"}
            onMouseEnter={over}
            onMouseLeave={out}
        >
            <NotificationComponent {...data} id={id} />
            {data.id && !data.permanent && (
                <IconButton
                    onClick={dismiss}
                    icon={"mui-close"}
                    size={"small"}
                />
            )}
        </div>
    );
}

const NotificationIterator = ArrayIterator(Notification);

export function SessionNotifications(props) {
    const { id } = props;
    const { notifications, onDismiss } = useSessionNotifications(id);

    return (
        <div className={"rows"}>
            <List
                type={"app.session-notifications"}
                items={notifications}
                onDismiss={onDismiss}
                Iterator={NotificationIterator}
            />
        </div>
    );
}

SessionNotifications.propTypes = {};
SessionNotifications.defaultProps = {};
