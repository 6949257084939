import React, {useCallback} from 'react';
import {useFilter} from "state/hooks";
import TextField from "components/fields/text/TextField";
//import PropTypes from 'prop-types';

export default function ParticipantsSearch(props) {

    const {id} = props;
    const {value, onChange} = useFilter(id);
    const change = useCallback((value) => onChange({search: value}), [onChange]);

    return (<div className={'sidebar-search'}>
        <TextField
            onChange={change}
            value={value.search}
            label={'Search people'}
            variant={'search'}
            debounce={false}
            autoFocus={false}
        />
    </div>);

}

ParticipantsSearch.propTypes = {};
ParticipantsSearch.defaultProps = {};
