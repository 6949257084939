import React from "react";

import { styled } from "cms/styles/legacy";
import { types } from "cms/components/types";
import { connectComponentWithHooks } from "cms/util/connect-hooks";
import { normalizeType } from "cms/util/connect";
//import {withRewireContext, connectComponent} from "cms/util/connect";
import { ArrayIterator } from "components/list/iterator";
import { withData } from "containers/Entity";

/*const connect = (ComponentClass) => withRewireContext(
    connectComponent(
        styled(ComponentClass)
    )
);*/

const connect = (ComponentClass) =>
    connectComponentWithHooks(styled(ComponentClass));

export const Layout = connect(types.layout);
export const Card = connect(types.card);
export const Media = connect(types.media);
export const Form = connect(types.form);

const wrapped = {
    text: connect(types.text),
    button: connect(types.button),
    list: connect(types.list),
    nav: connect(types.nav),
    template: connect(types.template),
    quickbook: connect(types.quickbook),
    value: connect(types.value),
    icon: connect(types.icon),
    media: Media,
    card: Card,
    layout: Layout,
    structured: Layout,
    form: Form,
    query: connect(types.query),
    symbol: connect(types.symbol),
    "form-input": connect(types["form-input"]),
    "form-select": connect(types["form-select"]),
    "form-select-option": connect(types["form-select-option"]),
    context: connect(types.context),
    plan: connect(types.plan),
    link: connect(types.link),
};

export default wrapped;

/**
 * Concepts
 * - embed children
 * - linkable
 * - rewire child components
 */

export function RenderComponent({ data, className, ...other }) {
    const type = normalizeType(data.element_type);
    const Component = wrapped[type];

    if (Component) {
        return (
            <Component
                {...other}
                type={"cms.components"}
                id={data.id}
                className={className}
            />
        );
    }
    return <div data-type={type}>{type}</div>;
}

export const Component = withData(RenderComponent);
export const ComponentIterator = ArrayIterator(Component);
