import React from "react";
import cn from "classnames";

//import PropTypes from 'prop-types';

export function StyleProperty(props) {
    const { label, children, isEmpty } = props;
    return (
        <div
            className={cn("style-prop", {
                "is-empty": isEmpty,
            })}
        >
            <div className={"style-prop-label"}>{label}</div>
            <div className={"style-prop-content"}>{children}</div>
        </div>
    );
}

StyleProperty.propTypes = {};

StyleProperty.defaultProps = {
    isEmpty: false,
};

export default StyleProperty;
