import React from "react";
import cn from "classnames";
import Icon from "components/Icon";
import PropTypes from "prop-types";

//import PropTypes from "prop-types";

export function TopicNav(props) {
  const { className, children } = props;

  return <div className={cn("topic-nav", className)}>{children}</div>;
}

export function TopicNavItem(props) {
  const { active, onClick, children } = props;
  return (
    <div
      onClick={onClick}
      className={cn("topic-nav-item", { "is-active": active })}
    >
      <Icon>mui-arrow_forward</Icon>
      <span>{children}</span>
    </div>
  );
}

TopicNavItem.propTypes = {
  active: PropTypes.bool,
};

TopicNavItem.defaultProps = {
  active: false,
};
