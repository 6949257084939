import React from "react";
import cn from "classnames";
import { useClick } from "state/hooks";
import Icon from "components/Icon";
import PropTypes from "prop-types";

function Category(props) {
    const { selected } = props;
    const { label, description, icon } = props;
    const click = useClick(props);

    return (
        <div
            className={cn("channel-type", {
                selected,
            })}
            onClick={click}
        >
            {icon ? <Icon>{icon}</Icon> : null}
            <div className={"rows rows-left"}>
                <div className={"type-lg channel-type__label"}>{label}</div>
                {description ? (
                    <div className={"type-sm o-50"}>{description}</div>
                ) : null}
            </div>
        </div>
    );
}

export default function CategorySelect(props) {
    const { onChange, value, options, layout } = props;
    const values = Object.values(options);
    const cols = values.length >= 3 ? 3 : values.length;

    const className =
        layout === "rows" ? "rows gap-xs" : `grid grid-${cols} gap-xs`;

    return (
        <div className={className}>
            {values.map((option) => {
                return (
                    <Category
                        key={option.id}
                        {...option}
                        selected={
                            value === option.id || (!value && option.default)
                        }
                        onClick={onChange}
                    />
                );
            })}
        </div>
    );
}

CategorySelect.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.any,
};
CategorySelect.defaultProps = {};
