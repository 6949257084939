import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";
import { useData } from "state/hooks";
import { SectionIcon } from "playground/cms/views/Sections";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import NodeFlags from "./../NodeFlags";

function SectionItem(props) {
    const { id, onClick, onDoubleClick, onContextMenu, focused, expanded } =
        props;
    const data = useData({ type: "cms.sections", id });
    const isDraft = useSelector((store) =>
        selectIsDraft(store, "cms.sections", id)
    );
    return (
        <div
            onClick={onClick}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            className={cn("section-stack-item", {
                focused,
                expanded,
            })}
        >
            <div className={"cols cols-spread cols-middle grow"}>
                <div className={"cols cols-middle grow gap-xs"}>
                    <div className={"section-stack-item-icon"}>
                        <SectionIcon id={id} />
                    </div>

                    <div className={"section-stack-item-label"}>
                        {data?.title || data?.description || data?.type}
                        {isDraft && <span>new section</span>}
                    </div>
                    {data?.used_in?.length > 1 && (
                        <div className={"section-stack-item-count"}>
                            {data?.used_in?.length}
                        </div>
                    )}
                </div>
                <NodeFlags data={data} />
            </div>
        </div>
    );
}

export function SectionsStack(props) {
    const { items, onFocus, onMenu, onEdit, focusId, treeId } = props;
    return (
        <div className={"sections-stack"}>
            {items.map((section) => (
                <SectionItem
                    key={section}
                    id={section}
                    onDoubleClick={() => onEdit(true)}
                    onClick={() => onFocus("cms.sections", section)}
                    onContextMenu={(e) => onMenu(e, section, "cms.sections")}
                    focused={focusId === section}
                    expanded={treeId === section}
                />
            ))}
        </div>
    );
}

SectionsStack.propTypes = {};

SectionsStack.defaultProps = {
    items: [],
};

export default SectionsStack;
