import React from "react";
//import PropTypes from 'prop-types';

import { useData, useFilter } from "state/hooks";
import TagsNav from "./TagsNav";
import { useSectionsFilter } from "./SectionsFilterContext";
import TextField from "components/fields/text/TextField";

export function ListFilter(props) {
    const { type, list } = props;

    //const { value, onSet } = useFilter(list, type, list);
    const { value, onSet } = useSectionsFilter();

    const { description } = useData({
        type: "cms.sections",
        id: value.template,
    });

    return (
        <div className={"sections-list-filter"}>
            {value.template && (
                <div onClick={() => onSet("template", null)}>
                    {description || value.template}
                </div>
            )}
            <TextField
                value={value.search}
                onChange={(v) => onSet("search", v)}
                label={"Szukaj"}
                variant={"search"}
            />
            <TagsNav
                onChange={(v) => onSet("cms_tags", v)}
                value={value.cms_tags}
                type={type}
                list={list}
                service={value.service || "local"}
            />
        </div>
    );
}

ListFilter.propTypes = {};

ListFilter.defaultProps = {
    type: "cms.sections",
    list: "cms.sections",
};

export default ListFilter;
