import {useEffect, useMemo, useRef} from "react";
import {useNotificationCount} from "joynt/hooks/notifications";
import {useActiveState} from "joynt/network-state";
import {useOnScreen} from "./useOnScreen";

export function useImpressionMonitor(id, on) {

    const ref = useRef();

    const isVisible = useOnScreen(ref);

    const [notifications, seen] = useNotificationCount(id);

    const active = useActiveState();

    useEffect(() => {
        let tm = null;
        if (active && on && notifications && isVisible) {
            console.log(`Post on screen ${id} (${notifications})`);
            tm = setTimeout(() => {
                console.log(`Submit impression ${id}`);
                seen();
            }, 1000);
        }
        return () => {
            if (tm) clearTimeout(tm);
        }
    }, [id, on, active, notifications, isVisible, seen]);

    return useMemo(() => ({
        ref,
        hasNotifications: !!notifications
    }), [ref, notifications])
}