import { createReducer } from "state/helpers/create-reducer";
import { setIn, getIn } from "lodash-redux-immutability";

function nodeId(id, user) {
    if (!user.node || user.node === "null") return id;
    return user.node;
}

function addUser(id, prev = {}, user) {
    let userId = user.name;
    let users = prev.users || [];
    if (users.indexOf(userId) === -1) {
        users = users.slice();
        users.push(userId);
    }
    if (users !== prev.users) {
        return { ...prev, users };
    }

    return prev;
}

function updateNodes(nodes, id, user) {
    nodes = { ...nodes };

    Object.keys(nodes).forEach((n) => {
        if (id.indexOf(n) === -1) nodes[n] = nodes[n].filter((u) => u !== user);
    });

    if (!id.length) return nodes;

    id.forEach((n) => {
        if (!nodes[n]) {
            nodes[n] = [user];
        } else {
            if (nodes[n].indexOf(user) === -1) {
                nodes[n] = nodes[n].slice();
                nodes[n].push(user);
            }
        }
    });

    return nodes;
}

function updateInfo(state, user) {
    let id = user.id;
    let userId = user.user_id;

    let prev = getIn(state, [id, "user", userId], {});
    let next = setIn(state, [id, "user", userId], {
        ...prev,
        ...user,
    });

    let prevNodes = getIn(state, [id, "nodes"], {});
    let prevSessions = getIn(state, [id, "sessions"], {});

    let nextNodes = prevNodes;
    let nextSessions = prevSessions;

    let nodeIds = [user.id, user.channel].filter((n) => !!n && n !== "null");
    let sessionIds = [user.session].filter((n) => !!n && n !== "null");

    nextNodes = updateNodes(nextNodes, nodeIds, userId);
    nextSessions = updateNodes(nextSessions, sessionIds, userId);

    //console.log("nextSessions", prevSessions, nextSessions);

    next = setIn(next, [id, "nodes"], nextNodes);
    next = setIn(next, [id, "sessions"], nextSessions);

    return next;
}

const actions = {
    "JOINT.PRESENCE.CONNECTED": (state, { payload: { id, users } }) => {
        let next = state;
        let initialData = { users: [], nodes: {}, sessions: {} };
        users.forEach((u) => {
            next = setIn(next, [id], addUser(id, next[id] || initialData, u));
            next = updateInfo(next, {
                ...u,
                id,
                user_id: u.name,
            });
        });
        return next;
    },
    "JOINT.PRESENCE.ADD_USER": (state, { payload: { id, user } }) => {
        let data = state[id] || {};
        return setIn(state, [id], addUser(id, data, user));
    },
    "JOINT.PRESENCE.REMOVE_USER": (state, { payload: { id, user } }) => {
        let prev = getIn(state, [id, "users"]) || [];
        let next = setIn(
            state,
            [id, "users"],
            prev.filter((u) => u !== user.name)
        );
        let node = nodeId(id, user);
        let prevNodes = getIn(state, [id, "nodes", node]) || [];
        return setIn(
            next,
            [id, "nodes", node],
            prevNodes.filter((u) => u !== user.name)
        );
    },
    "JOINT.PRESENCE.SESSION": (state, { payload: { id, user, meta } }) => {
        let data = state[id] || {};
        let next = getIn(data, ["session", user]) || {};
        return setIn(
            state,
            [id],
            setIn(data, ["session", user], { ...next, ...meta })
        );
    },
    "JOINT.PRESENCE.SESSION.CLEAR": (state, { payload: { id } }) => {
        return setIn(state, [id, "session"], {});
    },
    "JOINT.PRESENCE.UPDATE": (state, { payload: { data } }) => {
        return updateInfo(state, data);
    },
};

export default createReducer({}, actions);
