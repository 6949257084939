import React from 'react';
import PropTypes from 'prop-types';

import SessionItem from "./SessionItem.container";

export function DailySessionList(props) {

    const {items} = props;

    return <div className={"rows gap-sm"}>
        {items.map(sessionId => <SessionItem
            key={sessionId}
            id={sessionId}
        />)}
    </div>;

}

DailySessionList.propTypes = {
    items: PropTypes.array
};
DailySessionList.defaultProps = {
    items: []
};

export default DailySessionList;
