import React, {useCallback, useRef} from 'react';
import cn from 'classnames';
//import PropTypes from 'prop-types';

import PostIdentity from "joynt/post/PostIdentity";
import {CircularProgress} from "@material-ui/core";

export default function VideoTile(props) {
    const {
        id,
        poorConnection,
        active,
        identity,
        local,
        pinned,
        onAttachTile,
        audio,
        video
    } = props;

    const tileRef = useRef(null);
    const audioRef = useRef(null);

    const setVideoRef = useCallback(e => {
        if (id && e) onAttachTile(id, e);
        tileRef.current = e;
    }, [id, onAttachTile]);

    const setAudioRef = useCallback(e => {
        if (audio && e) onAttachTile(audio, e);
        audioRef.current = e;
    }, [audio, onAttachTile]);

    return <div className={cn('meeting-video dark', {
        'is-active': active,
        'is-local': local,
        'is-pinned': pinned
    })}>
        <video ref={setVideoRef} autoPlay={true} />
        { audio ? <audio ref={setAudioRef} autoPlay={true} /> : null }
        {poorConnection ? 'poor connection' : null}
        {!active ? <div className={'cols cols-center cols-middle meeting-video__loading fill'}>
            <CircularProgress color={'inherit'} size={20} />
        </div> : null}
        <div className={'meeting-video-info'}>
            <PostIdentity id={identity} />
        </div>
    </div>;
}

VideoTile.defaultProps = {
    local: false,
    active: false,
    pinned: false
}

VideoTile.propTypes = {};