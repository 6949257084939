import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { pushRoute } from "state/actions/router";

export function usePushRoute() {
    const dispatch = useDispatch();
    return useCallback(
        (route) => {
            dispatch(pushRoute(route));
        },
        [dispatch]
    );
}
