import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "components/IconButton";
import cn from 'classnames';
import Preloader from "components/Preloader";

const classes = {
    header: 'cols cols-right console-form-header pad-sm default-c600',
    body: 'console-form-body grow pad-md default',
    footer: 'console-form-footer pad-md default-c600 cols cols-right phone@cols-center'
};

export default class FormLayout extends React.Component {

    render() {
        const { onClose, form, footer, className, pending } = this.props;
        return (<form className={cn('h-fill rows', className)}>
            <div className={classes.header}>
                { onClose ? <IconButton icon={'mui-close'} onClick={onClose} /> : null }
            </div>
            <div className={classes.body}>
                {form}
            </div>
            {footer ? <div className={classes.footer}>
                {footer}
            </div> : null }
            <Preloader visible={pending} />
        </form>);
    }

}

FormLayout.propTypes = {
    onClose: PropTypes.func
};