import { useData } from "state/hooks";
import { useMemo } from "react";

export default function useLinkMetadata(url) {
    const id = encodeURIComponent(url);
    const data = useData({ type: "db.link-embed", id });
    return useMemo(
        () => ({
            id,
            title: data.title && data.title !== "" ? data.title : null,
            description: data.description,
            image: data.image,
            host: data.host,
            player: null,
            fetchUrl: `embed/?url=${id}`,
        }),
        [data, id]
    );
}
