import {useApiActions, useRouteParam} from "state/hooks";
import {nodeLink} from "joynt/state/actions";
import {ROUTE_PREVIEW} from "joynt/config";
import {useMemo} from "react";

export function useRouter() {
    const {link} = useApiActions({link: nodeLink});
    const onPreview = useRouteParam(ROUTE_PREVIEW)[1];
    return useMemo(()=>({
        onLink: link,
        onPreview
    }), [link, onPreview]);
}