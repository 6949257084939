import TreeView from "cms/ui/containers/TreeView";
import Styles from "cms/ui/containers/Styles";
import ResourceList from "cms/ui/containers/ResourceList";
import SectionsList from "cms/ui/SectionsList";
import Edit from "cms/ui/containers/Edit";
import Settings from "cms/ui/Settings";
import Create from "cms/ui/containers/Create";
import ChangeTemplate from "cms/ui/containers/ChangeTemplate";
import MediaBrowser from "cms/ui/MediaBrowser";
import MediaBrowserSelect from "cms/ui/MediaBrowserSelect";
import ThemeEditor from "cms/ui/theme/ThemeEditor";
import UserEdit from "cms/ui/UserEdit";

export default {
    tree: TreeView,
    style: Styles,
    pages: ResourceList,
    sections: SectionsList,
    edit: Edit,
    'user-edit': UserEdit,
    settings: Settings,
    create: Create,
    template: ChangeTemplate,
    media: MediaBrowser,
    'media-select': MediaBrowserSelect,
    theme: ThemeEditor
}