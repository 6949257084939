import React from "react";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";

import { AdvancedSettings, DangerZone, RolesAndAccess } from "./fields";

//import PropTypes from 'prop-types';

function Form() {
    return (
        <FieldsLayout>
            <FieldGroup label={"Integration name and description"}>
                <Field id={"name"} label={"Name"} validate={true} />
                <Field id={"description"} label={"Description"} />
            </FieldGroup>

            <Field
                id={"external_url"}
                label={"URL of external page"}
                validate={true}
            />

            <RolesAndAccess />

            <AdvancedSettings>
                <DangerZone />
            </AdvancedSettings>
        </FieldsLayout>
    );
}

const steps = {
    step1: {
        component: Form,
        label: "Settings",
    },
};

export default function External(props) {
    return <MultiStepForm {...props} steps={steps} showHeader={false} />;
}

External.propTypes = {};
External.defaultProps = {};
