import React, { useCallback } from "react";
import usePublicChannel from "joynt/websockets/hooks/usePublicChannel";
import { useApiActions } from "state/hooks";
import { pushEntity } from "joynt/state/actions";
import useListen from "joynt/websockets/hooks/useListen";

//import PropTypes from 'prop-types';

export function PublicChannel(props) {
    const { id } = props;

    const { handlePush } = useApiActions({
        handlePush: pushEntity,
    });

    const entityHandler = useCallback(
        (event) => {
            let data = event.entity;
            handlePush(data.type, data.id, data);
        },
        [handlePush]
    );

    const channel = `node-${id}`;
    const sub = usePublicChannel(channel);
    useListen(sub, ".entity", entityHandler);

    return null;
}

PublicChannel.propTypes = {};
PublicChannel.defaultProps = {};

export default PublicChannel;
