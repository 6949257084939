import {useCallback} from 'react';
import {useApiActions} from "state/hooks";
import {startRecording, stopRecording} from "joynt/state/actions/meetings";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useFeatureEnabled} from "joynt/hooks";
import {FEATURE_DEV} from "joynt/config/features";

export default function useCloudRecording(sessionId) {

    const {session: currentSession} = useMeeting();
    const canRecord = useFeatureEnabled(FEATURE_DEV);
    const id = sessionId || currentSession;

    const {
        onStartRecording,
        onStopRecording
    } = useApiActions({
        onStartRecording: startRecording,
        onStopRecording: stopRecording
    });

    const startRecord = useCallback(() => {
        onStartRecording(id);
    }, [onStartRecording, id]);

    const stopRecord = useCallback(() => {
        onStopRecording(id);
    }, [onStopRecording, id]);

    return {
        canRecord,
        onStartRecording: startRecord,
        onStopRecording: stopRecord
    }

}
