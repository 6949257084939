import React from "react";
import MultiStepForm from "joynt/edit/MultiStepForm";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import {
    AdvancedSettings,
    DangerZone,
    RolesAndAccess,
} from "joynt/edit/types/fields";
//import PropTypes from 'prop-types';

function Form() {
    return (
        <FieldsLayout>
            <FieldGroup label={"Name and description"}>
                <Field id={"name"} label={"Name"} />
                <Field id={"description"} label={"Description"} />
            </FieldGroup>

            <RolesAndAccess />

            <AdvancedSettings>
                <DangerZone />
            </AdvancedSettings>
        </FieldsLayout>
    );
}

const steps = {
    step1: {
        component: Form,
        label: "Settings",
    },
};

export default function Node(props) {
    return <MultiStepForm {...props} steps={steps} showHeader={false} />;
}

Node.propTypes = {};
Node.defaultProps = {};
