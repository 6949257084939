import React, { useState } from "react";
import "./style.css";
import AuthForm from "components/auth/AuthForm";

//import PropTypes from 'prop-types';

const urls = {
    tos: "https://joynt.works/terms-of-service",
    privacy: "https://joynt.works/privacy-policy",
};

const loginTitles = {
    login: "Sign in",
    signup: "Sign up",
    "password-reset": "Reset password",
};

function SessionAuthForm(props) {
    const [view, onChangeView] = useState("signup");
    const title = loginTitles[view];

    return (
        <AuthForm
            title={title}
            view={view}
            onChangeView={onChangeView}
            urls={urls}
            enableSocial={true}
        />
    );
}

export function LoginSection(props) {
    const { title, children } = props;

    return (
        <div className={"networking-section login-section"}>
            <div className={"login-section__content"}>
                <h2 className={"login-section__title"}>{title}</h2>
                <div className={"login-section__text"}>{children}</div>
            </div>
            <div className={"login-section__form"}>
                <SessionAuthForm />
            </div>
        </div>
    );
}

LoginSection.propTypes = {};
LoginSection.defaultProps = {};

export default LoginSection;
