import { useSelector } from "react-redux";
import { useData } from "state/hooks";
import { POST_TYPE_THREAD } from "joynt/config";
import {
    selectIdentitiesNames,
    selectRecipientIdentities,
} from "joynt/state/selectors/joint";
import { useEdges } from "./useEdges";
import { useNode } from "./useNode";

const nullArr = [];

export function useThread(id) {
    const data = useNode(id);
    const { post_types, identity, thread } = data;
    const identityData = useData({ type: "db.identities", id: identity });
    const nonThreadPostTypes = post_types
        ? post_types.filter((type) => type !== POST_TYPE_THREAD)
        : [];
    const hasContent = nonThreadPostTypes.length > 0;
    //const threadPost = useData({type: 'db.posts', id: thread}) || nullObj;
    const threadData = useData({ type: "db.threads", id: thread });

    const message = useData({
        type: "db.messages",
        id: threadData.last_message,
    });
    const edges_ref = useEdges(id);

    let allIdentities = useSelector((s) => selectRecipientIdentities(s, id));
    const identitiesNames = useSelector((s) =>
        selectIdentitiesNames(s, allIdentities)
    );

    return {
        description: data.description,
        parent: edges_ref.parent,
        parent_node: edges_ref.parent_node,
        post_types: data.post_types,
        subtype: data.subtype,
        name: data.name || threadData.subject,
        genericName: data.name || identitiesNames,
        hasContent,
        post: thread,
        thread: threadData.id,
        threadType: threadData.thread_type,
        participants: threadData.participants,
        messageIdentity: message.identity,
        identity: message.identity || identity,
        lastMessage: message.id,
        identityName: identityData.name,
        text: message.content,
        timestamp: message.created_at || data.created_at,
        identities: allIdentities || nullArr,
        isPrivateChat:
            threadData.thread_type === "private-chat" ||
            (!data.public && !data.published),
    };
}
