import React from 'react';
//import PropTypes from 'prop-types';
import IconButton from "components/IconButton";
import UserAvatar from "components/UserAvatar";

export default class UserInfo extends React.PureComponent {

    render() {
        const { onLogout, onShowSettings } = this.props;
        const { appVersion } = this.props;
        const { name } = this.props.data;

        return (<div className={'default-c700 cols cols-middle cols-spread pad-sm'}>
            <div className={'cols cols-middle gap-sm'}>
                <UserAvatar
                    id={this.props.data.id}
                />
                <div>{name}</div>
                <div>{appVersion}</div>
            </div>
            <div className={'cols'}>
                <IconButton
                    icon={'mui-settings'}
                    onClick={onShowSettings}
                    label={'User settings'}
                />
                <IconButton
                    icon={'mui-exit_to_app'}
                    onClick={onLogout}
                    label={'Logout'}
                />
            </div>
        </div>);
    }

}

UserInfo.propTypes = {};