import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

export default class UploadButton extends React.PureComponent {

    render() {
        const { onUpload, trigger } = this.props;
        return (<Dropzone onDrop={onUpload}>
            {({getRootProps, getInputProps, isDragActive}) => {
                return (
                    <span {...getRootProps()}>
                        <input {...getInputProps()} />
                        {trigger}
                    </span>
                )
            }}
        </Dropzone>);
    }

}

UploadButton.propTypes = {
    onUpload: PropTypes.func.isRequired
};