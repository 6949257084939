import React from "react";
import MediaBrowserContainer from "playground/media";
//import PropTypes from 'prop-types';

export function Media(props) {
    const {} = props;
    return <MediaBrowserContainer />;
}

Media.propTypes = {};

Media.defaultProps = {};

export default Media;
