import React from "react";
import Popover from "components/Popover";
import LayoutDropdown from "./LayoutDropdown";
import LayoutDropdownMenu from "./LayoutDropdownMenu";

//import PropTypes from 'prop-types';

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export function PageLayoutSettingsPopover(props) {
    const { type, id, onEdit, defaultValue, value } = props;

    return (
        <Popover
            trigger={({ onClick }) => (
                <LayoutDropdown
                    type={"cms.layouts"}
                    id={value || defaultValue}
                    onClick={onClick}
                    isDefault={!value}
                />
            )}
            anchorOrigin={anchorOrigin}
        >
            <LayoutDropdownMenu type={type} id={id} onEditLayout={onEdit} />
        </Popover>
    );
}

PageLayoutSettingsPopover.propTypes = {};

PageLayoutSettingsPopover.defaultProps = {};

export default PageLayoutSettingsPopover;
