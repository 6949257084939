import React from 'react';
import PropTypes from 'prop-types';

import TabsNav, {Tab} from "joynt/components/Tabs";
import {useClick} from "state/hooks";
import {useMoment} from "joynt/components/DateTimePicker/hooks";

function DayTab(props) {
    const {
        isSelected,
        value,
        onClick,
        format
    } = props;

    const click = useClick({onClick, id: value});
    const moment = useMoment(value);

    return <Tab
        onClick={click}
        selected={isSelected}
    >
        {moment.format(format)}
    </Tab>;
}

DayTab.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.number,
    isSelected: PropTypes.bool,
    format: PropTypes.string
}

DayTab.defaultProps = {
    isSelected: false,
    format: "DD.MM"
}

export function DaysNav(props) {

    const {days, value, onChange} = props;

    return (<div className={"cols cols-center"}>
        <TabsNav>
            {days.map(day => {
                return <DayTab
                    key={day}
                    value={day}
                    isSelected={day === value}
                    onClick={onChange}
                />;
            })}
        </TabsNav>
    </div>);

}

DaysNav.propTypes = {
    days: PropTypes.array
};
DaysNav.defaultProps = {
    days: []
};

export default DaysNav;
