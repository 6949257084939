function fallback(key, data) {
    const translations = data?.fallback_translations || {};
    const langs = Object.keys(translations);
    if (!langs.length) return null;
    const value = translations[langs[0]][key];
    if (value) return `(${langs[0]}) ${value}`;
    return null;
}

export function oneOfFallback(keys, data) {
    return keys.reduce((acc, key) => {
        if (acc) return acc;
        const fb = fallback(key, data);
        if (fb) return fb;
        return acc;
    }, null);
}
