import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";

export function SelectListItem(props) {
    const { onClick, label, icon } = props;

    return (
        <div className={"select-list-item"} onClick={onClick}>
            {icon && <Icon className={"select-list-icon"}>{icon}</Icon>}
            {label}
        </div>
    );
}

SelectListItem.propTypes = {
    onClick: PropTypes.func,
};

SelectListItem.defaultProps = {};

export default SelectListItem;
