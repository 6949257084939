import { invert } from "lodash";
import createCachedSelector from "re-reselect";

export const aliases = {
    "@link-item":
        "link_ref,link,link_label,link_action,icon,headline,tooltip_text,event_param",
    "@section-links": "links:items",
    "@section-items": "gallery:items",
    "@section-links-visible": "?links.count|bool:visible,links:items",
    "@section-items-visible": "?gallery.count|bool:visible,gallery:items",
    "@text": "title,headline,text",
    "@query-list": "query@items:items,query@id:query_id",
    "@query": "query@items:items,query@id:query_id",
    "query@items:collection": "query@items:items,query@id:query_id",
    "@children": "items:components",
    "@media-items": "media.block_items:items",
    "@sections-nav": "page@sections_nav.items:collection",
    "@menu-item": "url:link,label:link_label",
    "@settings": "global@settings",
    "@logo": "global@settings.logo.collect",
    "@logo-sign": "global@settings.logo_avatar.collect",
    "@contact": "global@settings.contact",
    "@company": "global@settings.company",
    "@locale": "page@locale",
    "@category-list":
        "page@$:data_provider.entry_category,page@link:target_page",
    "@offer-days":
        "stay_length.toBool:visible,stay_length.days.vars:stay_length",
    "@offer-nights": "stay_length.toBool:visible,stay_length.vars:stay_length",
};

export const parseTemplateMappingCached = createCachedSelector(
    [(mapping) => mapping, (invertMapping) => invertMapping],
    (mapping, invertMapping) => {
        return parseTemplateMapping(mapping, invertMapping);
    }
)((mapping, invertMapping) => {
    return mapping + "::" + invertMapping ? "true" : "false";
});

export const parseTemplateMapping = (mappingString, invertMapping) => {
    if (!mappingString) return {};
    let mapping = aliases[mappingString] || mappingString;
    let parts = mapping.split(",");
    let parsed = {};
    parts.forEach((part) => {
        let fromTo = part.split(":");
        let from = null;
        let to = null;
        from = fromTo[0];
        if (fromTo.length > 1) {
            to = fromTo[1];
        } else {
            to = from;
        }
        to = to.replace("?", "");
        from = from.replace("?", "");
        from = from.replace("context.", "context@");
        from = from.replace("query.", "query@");
        from = from.replace("media.images", "media");
        //from = from.replace(".block_items", "");
        if (!["s"].includes(to)) {
            parsed[to] = from;
        }
    });
    if (invertMapping) return invert(parsed);
    return parsed;
};

export const serializeMapping = (mapping) => {
    let pairs = [];
    Object.keys(mapping).forEach((from) => {
        let map = [];
        if (mapping[from] && mapping[from] !== from) map.push(mapping[from]);
        map.push(from);
        pairs.push(map.join(":"));
    });
    return pairs.join(",");
};
