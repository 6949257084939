import React from "react";
//import PropTypes from 'prop-types';
import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import PropertyForm from "./PropertyForm";

export function Properties(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"property"} label={"Obiekt"}>
                        <PropertyForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Properties.propTypes = {};

Properties.defaultProps = {};

export default Properties;
