import React from "react";
import PhoneHeader from "./PhoneHeader";
import BottomNav from "./BottomNav";
import cn from "classnames";

import "./mobile.css";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

export function PhoneAppLayout(props) {
    const {
        id,
        children,
        sidebarNav,
        utilityNav,
        swipeHandlers,
        showSidebar,
        showUtilityNav,
        isStageView,
        header,
        bottomNav,
        isPending,
        isPage,
        className: propsClassName,
    } = props;

    const className = cn(
        "joynt-layout",
        {
            "show-sidebar": showSidebar,
            "show-utility-nav": showUtilityNav,
            "stage-view": isStageView,
        },
        propsClassName
    );

    return (
        <div className={className} {...swipeHandlers}>
            {sidebarNav}
            <section className={"app-screen"}>
                {!isPending && !isPage && (
                    <div className={"header"}>
                        {header || <PhoneHeader id={id} />}
                    </div>
                )}
                <div className={"main"}>
                    <div className={"content"}>{children}</div>
                </div>
                {!isPending && !isPage && (
                    <div className={"bottom-nav"}>
                        {bottomNav || <BottomNav id={id} />}
                    </div>
                )}
            </section>
            {utilityNav}
            <Preloader visible={isPending} />
        </div>
    );
}

PhoneAppLayout.propTypes = {};
PhoneAppLayout.defaultProps = {};

export default PhoneAppLayout;
