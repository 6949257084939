import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TaskDue from "./TaskDue";
import Popover from "components/Popover";
import { DatePickerCalendar } from "joynt/components/DateTimePicker";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { useMoment } from "joynt/components/DateTimePicker/hooks";

const stop = (e) => e.stopPropagation();

function RenderTaskDue(props) {
    const { value, onChange } = props;

    const now = new Date().getTime() / 1000;
    const currentValue = value || now;

    const momentValue = useMoment(currentValue);

    const change = useCallback(
        (v) => {
            onChange(v.unix());
        },
        [onChange]
    );

    const clear = useCallback(
        (e) => {
            e.stopPropagation();
            onChange(null);
        },
        [onChange]
    );

    return (
        <Popover
            trigger={({ onClick }) => (
                <TaskDue onClick={onClick} onClear={clear} {...props} />
            )}
            content={({ onClose }) => {
                const ch = (...args) => {
                    change(...args);
                    onClose();
                };
                return (
                    <div onClick={stop}>
                        <DatePickerCalendar value={momentValue} onChange={ch} />
                    </div>
                );
            }}
        />
    );
}

export function TaskDueContainer(props) {
    const { id, editable, showLabel } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={editable}>
            <Field
                id={"task_due"}
                FieldComponent={RenderTaskDue}
                showLabel={showLabel}
            />
        </AggregateRoot>
    );
}

TaskDueContainer.propTypes = {
    id: PropTypes.string,
};
TaskDueContainer.defaultProps = {};

export default TaskDueContainer;
