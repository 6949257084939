import React from "react";
import PropTypes from "prop-types";
import SessionLayout from "./SessionLayout";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import useSessionLayout from "./hooks/useSessionLayout";
import SessionHeader from "joynt/components/SessionHeader";
import { useUiFlag } from "state/hooks";
import { FLAG_SESSION_NAV } from "joynt/config";

export function SessionLayoutContainer(props) {
    const { Component, HeaderComponent, ...other } = props;
    const { id } = props;

    const { session } = useMeetingSession();
    const [{ joined }] = useConnectionState(session);
    const { expanded } = useSessionLayout();
    const [nav] = useUiFlag(FLAG_SESSION_NAV);

    const Header = HeaderComponent || SessionHeader;

    return (
        <Component
            {...other}
            navOpen={!!nav}
            isExpanded={joined && expanded}
            session={session}
            header={<Header id={id} session={session} />}
        />
    );
}

SessionLayoutContainer.propTypes = {
    id: PropTypes.string,
};
SessionLayoutContainer.defaultProps = {
    Component: SessionLayout,
    HeaderComponent: SessionHeader,
};

export default SessionLayoutContainer;
