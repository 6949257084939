import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import PillButton from "joynt/components/PillButton";
import {useNodeData} from "joynt/hooks";
import {useClick} from "state/hooks";
import {types} from "joynt/config/types";
import {useNotificationCount} from "joynt/hooks/notifications";
import Icon from "components/Icon";

function EditIcon(props) {
    const {children, enable, onClick} = props;

    const click = useCallback((e) => {
        e.stopPropagation();
        onClick();
    }, [onClick]);

    return <div className={"edit-icon-addon"}>
        {enable && <div className={"edit-icon-addon__icon"} onClick={click}>
            <Icon>mui-settings</Icon>
        </div>}
        {children}
    </div>;
}

EditIcon.propTypes = {
    enable: PropTypes.bool,
    onClick: PropTypes.func
}

EditIcon.defaultProps ={
    enable: false
}

export function NodeTab(props) {

    const {id, onClick, onEdit, selected} = props;
    const {name, subtype, can_edit} = useNodeData(id);
    const [count, seen] = useNotificationCount(id);

    const clickSeen = useCallback((id) => {
        onClick(id);
        seen();
    }, [onClick, seen]);

    const click = useClick({id, onClick: clickSeen});
    const edit = useClick({id, onClick: onEdit});

    return (<EditIcon
        enable={can_edit}
        onClick={edit}
    >
        <PillButton
            label={name}
            onClick={click}
            icon={types[subtype]?.icon}
            isActive={selected === id}
            hasNotifications={!!count}
        />
    </EditIcon>);

}

NodeTab.propTypes = {
    id: PropTypes.string
};
NodeTab.defaultProps = {};

export default NodeTab;
