import {useNode} from "joynt/hooks";
import {useActions} from "state/hooks";
import {change} from "state/actions/data";
import {useCallback} from "react";
import {accessScopes} from "./config";

function toggle(arr, value, desired) {
    let next = arr ? arr.slice() : [];
    if (next.indexOf(value) === -1) {
        if (desired === false) return arr || [];
        next.push(value)
    } else {
        if (desired === true) return arr || [];
        next = next.filter(v=>v!==value);
    }
    return next;
}

function setUserRole(scope, node, identity, role) {
    const next = {...node};
    const roles = accessScopes[scope];

    // Reset current roles for identity
    Object.values(roles).forEach(key => {
        next[key] = toggle(next[key], identity, false);
    });

    // Assign requested role if defined within scope
    const targetKey = roles[role];
    if (targetKey) {
        next[targetKey] = toggle(next[targetKey], identity, true);
    }

    // Special owner role handling
    if (role === 'owner') {
        // Assign previous owner as an admin
        let adminKey = accessScopes.access.admin;
        next[adminKey] = toggle(next[adminKey], next.identity, true);

        // Assign new owner
        next.identity = identity;
    }
    return next;
}

function usersForScope(node, scope) {
    const roles = accessScopes[scope];
    let users = [];
    Object.values(roles).forEach(key => {
        if (node[key]) users = users.concat(node[key]);
    });
    return users;
}

export function useAssignRoles(scope, id) {
    const data = useNode(id);
    const {onChange} = useActions({
        onChange: change
    });
    const handleAssignRole = useCallback((identity, role) => {
        let next = setUserRole(scope, data, identity, role);
        onChange('db.nodes', id, next);
    }, [id, onChange, scope, data]);

    const users = usersForScope(data, scope);
    const userRole = useCallback(identity => {
        const roles = accessScopes[scope];
        if (scope === 'access' && data.identity === identity) return 'owner';
        let userRole = null;
        Object.keys(roles).forEach(role => {
            let key = roles[role];
            if (data[key] && data[key].indexOf(identity) > -1) {
                userRole = role;
            }
        });
        return userRole;
    }, [data, scope]);

    return {
        onAssignRole: handleAssignRole,
        users,
        userRole
    }
}