import {useUiFlag} from "state/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FLAG_NEW_POST_ID, POST_TYPE_TEXT} from "joynt/config";
import {useCreatePost} from "joynt/hooks";
import uuid from "uuid/v4";
import {useSelector} from "react-redux";
import {selectPostContentStructured, selectPostHasContent} from "joynt/state/selectors/joint";

export function usePostCreate(parent, list) {

    const [id, setId] = useUiFlag(FLAG_NEW_POST_ID, null);
    const [type, setType] = useState(POST_TYPE_TEXT);
    const [onCreatePost] = useCreatePost(parent, null);

    const reject = useCallback(() => {
        setId(null);
        setType(POST_TYPE_TEXT);
    }, [setId, setType]);

    useEffect(() => {
        if (!id) {
            let newPostId = uuid();
            setId(newPostId);
            onCreatePost(newPostId, POST_TYPE_TEXT);
        }
    }, [id, onCreatePost, setId]);

    const posts = useSelector((s) => selectPostContentStructured(s, id));
    const hasContent = useSelector(s => selectPostHasContent(s, id));

    return useMemo(() => ({
        id,
        type,
        onReject: reject,
        onChangeType: setType,
        posts,
        hasContent
    }), [
        id,
        type,
        reject,
        setType,
        posts,
        hasContent
    ]);
}