import React, {useCallback, useState} from 'react';
import cn from "classnames";
import {ArrayIterator} from "components/list/iterator";
import {useData, usePending, useRegisterList} from "state/hooks";
import DateDiff from "components/DateDiff";
import Fetch from "containers/Fetch";
import List from "containers/List";
import {types} from "joynt/config/types"
import Icon from "components/Icon";
import PostIdentity from "joynt/post/PostIdentity";
import {useNodeData} from "joynt/hooks";
import Preloader from "components/Preloader";
import {NODE_TYPE_CHAT} from "joynt/config";

function groupActivity(activity) {
    let seen = true;
    const types = {};

    activity.forEach(item => {
        const notifications = item.notifications_list || [];
        const type = item.notification_type;
        notifications.forEach(notification => {
            if (!notification.seen) seen = false;
        });
        if (!types[type]) types[type] = {
            items: [],
            identities: [],
            count: 0
        }
        types[type].items.push(item);
        if (types[type].identities.indexOf(item.identity) === -1)
            types[type].identities.push(item.identity);
        types[type].count++;
    })

    return {
        seen,
        types
    }
}

function ActivityTime({value, seen}) {
    return <div className={cn('activity-time', {'is-new': !seen})}>
        <DateDiff value={value} />
    </div>
}

function ActivityGroup(props) {
    const {id, onLink} = props;
    //const {onLink} = useRouter();
    const activityType = 'activity_nodes';
    const nodeData = useData(props);
    const activityNodes = nodeData[activityType];
    const {activity, node_type, name, subtype} = nodeData
    const nodeType = types[subtype] || {};
    const activityStat = activity ? groupActivity(activity) : {};
    const nestedCount = activityNodes ? activityNodes.length : 0;

    const link = useCallback((evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        onLink(id, null, 'activity');
    }, [onLink, id]);

    const header = (<div className={'grow rows'}>
        <div className={'cols cols-top cols-spread'}>
            <div className={'cols cols-middle gap-xs'}>
                <div className={'activity-type'}>
                    <Icon>{nodeType.icon}</Icon>
                    {nodeType.label}
                    {!nodeType.id && node_type === 'stem' ? 'Post' : null}
                </div>
                <div className={'activity-node'}>{name}</div>
            </div>
            <ActivityTime
                value={activity ? activity[0].created_at : null}
                seen={activityStat.seen} />
        </div>
    </div>);

    return <div className={cn('rows gap-sm', {
        'activity-group': true
    })} onClick={link}>
        { nestedCount ? header : null }
        {nestedCount ? <div className={'rows gap-px'}>
            <List
                type={'db.nodes.activity'}
                items={activityNodes}
                onLink={onLink}
                Iterator={ActivityNodeIterator}
            />
        </div> : null}
        { !nestedCount ? header : null }
    </div>;
}

function ActivityNode(props) {
    const {id, onLink} = props;
    //const {onLink} = useRouter();
    const nodeData = useData(props);
    const {activity, node_type, name, subtype} = nodeData
    const nodeType = types[subtype] || {};
    const activityStat = activity ? groupActivity(activity) : {};
    const groups = activityStat.types || {};
    const isStem = node_type === 'stem' || subtype === NODE_TYPE_CHAT;

    const link = useCallback((evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        onLink(id, null, 'activity');
    }, [onLink, id]);

    const header = (<div className={'grow rows'}>
        <div className={'cols cols-top cols-spread'}>
            <div className={'cols cols-middle gap-xs'}>
                <div className={'activity-type'}>
                    <Icon>{nodeType.icon}</Icon>
                    {nodeType.label}
                    {!nodeType.id && node_type === 'stem' ? 'Post' : null}
                </div>
                <div className={'activity-node'}>{name}</div>
            </div>
            <ActivityTime
                value={activity ? activity[0].created_at : null}
                seen={activityStat.seen} />
        </div>
    </div>);

    return <div className={cn('activity-tile rows gap-sm', {
        'tile': true,
        'general-activity': !isStem
    })} onClick={link}>
        {header}
        <div className={'rows'}>
            {Object.keys(groups).map(group => {
                let act = groups[group].items[0];
                let msg = act.resource_messages && act.resource_messages.length ? act.resource_messages[0] : null;
                return <div key={group}>
                    {msg ? <div>{msg.content}</div> : null}
                    <div className={'cols cols-middle gap-xs'}>
                        <PostIdentity identities={groups[group].identities} />
                        <div>{group} {groups[group].count}</div>
                    </div>
                </div>
            })}
        </div>
    </div>;
}

const ActivityNodeIterator = ArrayIterator(ActivityNode);
const ActivityGroupIterator = ArrayIterator(ActivityGroup);

// function FilterNode(props) {
//     const {id} = props;
//     const {name} = useNodeData(id);
//     const onClick = useClick(props);
//
//     return <div onClick={onClick}>{name}</div>
// }

//const FilterNodeIterator = ArrayIterator(FilterNode);

export default function ActivityFeed(props) {

    const {id, type, list} = props;

    const [filterId, setFilterId] = useState(null);
    const showId = filterId || id;

    const {
        workspace,
        node_type
    } = useNodeData(showId);

    let aggregateBy = null;
    if (workspace) aggregateBy = 'parent_space';
    if (node_type === 'root') aggregateBy = 'workspace';

    let url = `joynt/nodes/${showId}/activity`;
    if (aggregateBy) url += `?aggregateBy=${aggregateBy}`;

    //const items = useList(type, list);
    useRegisterList({type, list, filter: list, url});

    const pending = usePending([type,list].join('/'));

    return <div className={'cols gap-md'}>
        {/*<div className={'w-xs'}>*/}
        {/*    <Fetch*/}
        {/*        url={`joynt/nodes/${id}/tags`}*/}
        {/*        type={'db.nodes'}*/}
        {/*        list={`db.nodes.spaces`}*/}
        {/*    />*/}
        {/*    <div className={'rows gap-sm'}>*/}
        {/*        <FilterNode id={id} onClick={()=>setFilterId(null)} />*/}
        {/*        <List*/}
        {/*            type={'db.nodes'}*/}
        {/*            list={'db.nodes.spaces'}*/}
        {/*            Iterator={FilterNodeIterator}*/}
        {/*            onClick={setFilterId}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*</div>*/}
        <Fetch type={'db.nodes'} id={showId} />
        <div className={'grow'}>
            <Fetch
                type={type}
                list={list}
                url={url}
                force={true}
            >
                <Preloader visible={pending} />
                <div className={"rows gap-sm"}>
                    <List
                        type={type}
                        list={list}
                        onLink={setFilterId}
                        Iterator={aggregateBy ? ActivityGroupIterator : ActivityNodeIterator}
                    />
                </div>
            </Fetch>
        </div>
    </div>;

}

ActivityFeed.propTypes = {};
ActivityFeed.defaultProps = {};
