import CreateWorkspace from "./CreateWorkspace";
import NodeSettings from "./NodeSettings";
import Invite from "./Invite";
import WorkspaceWelcome from "./WorkspaceWelcome";
import CreateSpace from "./CreateSpace";
import AboutWorkspace from "joynt/modals/info/AboutWorkspace";
import AboutEvent from "joynt/modals/info/AboutEvent";
import AdminsAndCollaborators from "joynt/modals/info/AdminsAndCollaborators";
import SessionPreview from "joynt/components/SessionPreview";
import StageSchedule from "joynt/components/StageSchedule";

import witness from "joynt/components/Witness/modals";

export default {
    "invite-admins": {
        title: "Invite admins and collaborators",
        component: Invite,
        type: "page",
        props: { defaultRole: "admin", scope: "admins" },
        infoLeft: AdminsAndCollaborators,
    },
    "invite-members": {
        title: "Invite members",
        component: Invite,
        type: "page",
        props: { defaultRole: "guest", shouldPublish: true },
    },
    "workspace-welcome": {
        title: "Congratulations!",
        component: WorkspaceWelcome,
        type: "modal",
    },
    invite: {
        title: "Invite people",
        component: Invite,
        type: "page",
        props: {},
        infoLeft: AdminsAndCollaborators,
    },
    "create-workspace": {
        title: "Create workspace",
        component: CreateWorkspace,
        type: "page",
        props: {},
        infoLeft: AboutWorkspace,
        infoRight: AboutEvent,
    },
    "create-space": {
        title: "Select type",
        type: "modal",
        component: CreateSpace,
    },
    edit: {
        component: NodeSettings,
        type: "container",
        props: { pageType: "edit" },
    },
    create: {
        component: NodeSettings,
        type: "container",
        props: { pageType: "create" },
    },
    session: {
        component: SessionPreview,
        type: "drawer",
    },
    schedule: {
        title: "Stage schedule",
        component: StageSchedule,
        type: "modal",
    },
    ...witness,
};
