import React from 'react';
// import PropTypes from 'prop-types';
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import {CardAppearance, Description} from "joynt/edit/types/fields";
import ImageField from "joynt/components/Form/ImageField";
import SessionDate from "joynt/edit/types/Session/SessionDate";
import {HelperText} from "joynt/components/HelperText";
import SessionPublishedStatus from "joynt/edit/types/Session/SessionPublishedStatus";

export function CreateForm(props) {

    const {id} = props;

    return (<FieldsLayout>
        <SessionPublishedStatus id={id} />
        <FieldGroup label={"1. Webinar series title"}>
            <Field id={"name"} label={"Title"} validate={true} />
            {/*<Field id={'description'} label={'Subtitle'}/>*/}
        </FieldGroup>
        <FieldGroup label={"2. Choose date and time"}>
            <SessionDate
                collapsed={true}
                expandLabel={"Add dates"}
                placeholderText={"Do you want to add dates?"}
            />
            <HelperText loudness={2}>
                You can skip this if you don't know the exact duration time frame.
            </HelperText>
        </FieldGroup>
        <FieldGroup label={"3. Description"}>
            <Description id={id} />
        </FieldGroup>
        <CardAppearance
            icon={true}
            label={"4. Choose color and icon"}
            variant={"workspace"}
            showCard={false}
        />
        <FieldGroup label={"5. Cover image"}>
            <ImageField />
        </FieldGroup>
        <Field id={'public'} label={'Public'} fieldType={"checkbox"} />
        <Field id={'published'} label={'Published'} fieldType={"checkbox"} />
    </FieldsLayout>);

}

CreateForm.propTypes = {};

CreateForm.defaultProps = {};

export default CreateForm;