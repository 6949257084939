import React from 'react';
import {connect} from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import {selectEntity} from "state/selectors/data";

const mapStateToProps = (store, props) => {
    return {
        data: selectEntity(store, 'db.users', props.id)
    }
};

const mapDispatchToProps = {};

function UserAvatar(props) {
    const { profile } = props.data;
    return <Avatar
        size={props.size}
        src={profile ? profile.avatar : null}
    />;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAvatar);