import React from 'react';
import PropTypes from 'prop-types';

import Link from "./Link";
import {usePost} from "joynt/components/Post";
import LinkEmbed from "joynt/components/LinkEmbed";

export function LinkContainer(props) {
    const { id, editable, onDelete } = props;
    const { link } = usePost(id);

    return <Link>
        <LinkEmbed
            link={link}
            readOnly={!editable}
            onDelete={onDelete}
        />
    </Link>;
}

LinkContainer.propTypes = {
    id: PropTypes.string,
    editable: PropTypes.bool,
    onDelete: PropTypes.func
};
LinkContainer.defaultProps = {
    editable: false
};

export default LinkContainer;