import React, { useEffect, useMemo } from "react";
import { useFetch, useRouteParam } from "state/hooks";
import ThemeShadow from "cms/ui/ThemeShadow";
import { StylesProvider } from "cms/styles/legacy";

// eslint-disable-next-line import/no-webpack-loader-syntax
import css from "!!css-to-string-loader!css-loader!./sections.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionCardCss from "!!css-to-string-loader!css-loader!./SectionBrowser/SectionCard/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionGalleryCss from "!!css-to-string-loader!css-loader!./SectionBrowser/SectionGalleryItem/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionPreviewCss from "!!css-to-string-loader!css-loader!./SectionBrowser/SectionPreview/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import canvas from "!!css-to-string-loader!css-loader!./canvas.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import editorCss from "!!css-to-string-loader!css-loader!./../../../designer/canvas.css";

import { useSelector, useDispatch } from "react-redux";
import {
    selectAliasedEntity,
    selectEntity,
    selectEntityData,
} from "state/selectors/data";
import { loadWebfont } from "cms/state/actions/theme";

//import PropTypes from 'prop-types';

export const ThemeRenderContext = React.createContext();

export function ThemeContext(props) {
    const {
        PendingState,
        className,
        loadFonts,
        hostType,
        customCss,
        children,
    } = props;
    const [routeService] = useRouteParam("service");
    const service = props.service || routeService;

    const dispatch = useDispatch();

    const stylesPending = useFetch({
        list: "default",
        url: `v2/cms/styles?service=${service}`,
        useContext: false,
        fetchId: "front.page",
    });

    const serviceData = useSelector((state) =>
        selectAliasedEntity(state, "console.services", service)
    );

    useFetch({
        type: "console.services",
        url: `console/services/${service}?type=website`,
        id: service,
        enable: !serviceData?.id,
    });

    const theme = useSelector((state) => {
        return selectEntityData(state, "console.themes", serviceData?.theme);
    });

    const preset = useSelector((state) => {
        return selectEntityData(state, "console.presets", theme?.preset);
    });

    const typography = preset?.typography;

    useEffect(() => {
        if (typography && loadFonts) {
            console.log("Load fonts", typography);
            Object.values(typography).forEach((font) => {
                if (font?.source && font?.family) {
                    dispatch(loadWebfont(font.source, font.family));
                }
            });
        }
    }, [dispatch, typography, loadFonts]);

    if (!serviceData?.id)
        return PendingState ? (
            <PendingState status={"Fetching service"} />
        ) : (
            <div>Fetching service</div>
        );

    let effectiveService = service;
    if (!serviceData?.theme) effectiveService = routeService;

    //return <pre>{JSON.stringify(preset, null, 2)}</pre>;

    return (
        <ThemeShadow
            service={effectiveService}
            className={className}
            hostType={hostType}
            PendingState={PendingState}
            customThemeCss={!!customCss}
        >
            <style>{canvas}</style>
            <style>{css}</style>
            <style>{sectionPreviewCss}</style>
            <style>{sectionGalleryCss}</style>
            <style>{sectionCardCss}</style>
            <style>{editorCss}</style>
            <style>{customCss}</style>
            <ThemeRenderContext.Provider value={!stylesPending}>
                <StylesProvider>{children}</StylesProvider>
            </ThemeRenderContext.Provider>
        </ThemeShadow>
    );
}

ThemeContext.propTypes = {};

ThemeContext.defaultProps = {
    loadFonts: true,
};

export default ThemeContext;
