import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import { ListContainer, ListLayout } from "playground/cms/List";
//import ListItem from "playground/console/views/Domains/ListItem";
import { OverlayForm } from "playground/cms/Form";
import { ResourceForm } from "playground/cms/views/Resources";
import WebsiteDomain from "./WebsiteDomain";

export function WebsiteDomains(props) {
    const { id, list, domainId, setDomainId, onCreate } = props;

    return (
        <>
            <div>
                Domain name / ssl / www / cache / language / slug / aliases /
                primary
            </div>
            <ListLayout>
                <ListLayout.Content>
                    <ListContainer
                        type={"console.domains"}
                        list={list}
                        id={domainId}
                        onClick={setDomainId}
                        url={`console/domains?service=${id}`}
                        className={"list pad-md overflow-scroll rows gap-md"}
                        Item={WebsiteDomain}
                    />
                </ListLayout.Content>
                <ListLayout.Footer>
                    <ListLayout.Footer.Actions>
                        <Button onClick={onCreate} disabled={!!domainId}>
                            Create
                        </Button>
                    </ListLayout.Footer.Actions>
                </ListLayout.Footer>
            </ListLayout>
            <OverlayForm open={!!domainId}>
                {domainId && (
                    <ResourceForm
                        type={"console.domains"}
                        id={domainId}
                        disableFetch={true}
                        onClose={() => setDomainId(null)}
                        // onSubmit={() => setDomainId(null)}
                    />
                )}
            </OverlayForm>
        </>
    );
}

WebsiteDomains.propTypes = {};

WebsiteDomains.defaultProps = {};

export default WebsiteDomains;
