import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";
import Icon from "components/Icon";

const SelectionCard = ({
  title,
  icon,
  description,
  subtitle,
  children,
  isSelected,
  onSelect,
}) => {
  return (
    <section
      onClick={onSelect}
      className={cn("selection-card", isSelected && "selection-card--selected")}
    >
      <div className={"selection-card__info"}>
        <header>
          <h3 className={"selection-card__title"}> {title}</h3>
          <p className={"selection-card__subtitle"}>{subtitle}</p>
        </header>
        <div className={"selection-card__icon-wrapper"}>
          <Icon className={"selection-card__icon"}>{icon}</Icon>
        </div>
      </div>
      {(description || children) && (
        <div className={"selection-card__content"}>
          {children || description}
        </div>
      )}
    </section>
  );
};

SelectionCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default SelectionCard;
