import React, { useState } from "react";
import cn from "classnames";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

function IconButton(props) {
    const { icon, onClick } = props;
    return (
        <div className={"style-icon-btn"} onClick={onClick}>
            <Icon>{icon}</Icon>
        </div>
    );
}

export function ElementsGrid(props) {
    const { items, allItems, selected, onChange, onRemove } = props;
    const [showAll, setShowAll] = useState(false);

    const hiddenItems = allItems.filter((item) => !items.includes(item));

    const visibleItems = allItems.filter((item, index) => {
        if (showAll) return true;
        return items.includes(item) || item === selected || index === 0;
    });

    // let visibleItems = showAll ? [...items, ...hiddenItems] : items;
    // if (!visibleItems.includes(selected) && !!selected)
    //     visibleItems = [...visibleItems, selected];

    const change = (item) => {
        if (onChange) onChange(item);
        setShowAll(false);
    };

    const remove = (item) => {
        const index = items.indexOf(item);
        if (onRemove) onRemove(item);
        onChange(items[Math.max(index - 1, 0)]);
        setShowAll(false);
    };

    return (
        <div className={"cols gap-xs"}>
            <div className={"style-elements-select"}>
                {visibleItems.map((item) => (
                    <div
                        title={item}
                        key={item}
                        className={cn("style-element-item", {
                            "is-selected": selected === item,
                            "is-empty": !items.includes(item),
                        })}
                        onClick={() => change(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className={"rows"}>
                <IconButton
                    icon={"mui-more_horiz"}
                    onClick={() => setShowAll(!showAll)}
                />
                {showAll && (
                    <IconButton
                        icon={"mui-close"}
                        onClick={() => remove(selected)}
                    />
                )}
            </div>
        </div>
    );
}

ElementsGrid.propTypes = {};

ElementsGrid.defaultProps = {
    items: [],
    allItems: [],
};

export default ElementsGrid;
