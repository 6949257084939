import React from 'react';
//import PropTypes from 'prop-types';

import {useActions, useUiFlag} from "state/hooks";
import {feedbackHide} from "state/actions/ui";
import {selectSnackbarNotification} from "state/selectors/ui";
import {useSelector} from "react-redux";

import Snackbar from "./Snackbar";

export function SnackbarContainer(props) {

    const {onClose} = useActions({onClose: feedbackHide});
    const [isOpen] = useUiFlag('snackbar');
    const notification = useSelector(s=>selectSnackbarNotification(s));
    const {message} = notification;

    return <Snackbar
        isOpen={isOpen}
        onClose={onClose}
        {...notification}
        message={<span>{message}</span>}
    />;

}

SnackbarContainer.propTypes = {};
SnackbarContainer.defaultProps = {};

export default SnackbarContainer;