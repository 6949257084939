import React, { useState } from "react";
import useMockParticipants from "joynt/meeting/mock/hooks/useMockParticipants";
import Button from "components/Button";
import TextField from "components/fields/text/TextField";
import useMockNetworkQuality from "joynt/meeting/mock/hooks/useMockNetworkQuality";
import Settings from "joynt/meeting/debug-console/Settings";

const settingsStyle = {};

export default function SessionSettings(props) {
    const { channel, onPublishEvent } = props;
    const [streams, setStreams] = useState(30);
    const { identities, onStoreIdentities } = useMockParticipants(streams);
    const networkQuality = useMockNetworkQuality(onPublishEvent);

    const join = () => {
        identities.forEach((id) => {
            onPublishEvent("user-joined", { remoteUser: { uid: id.id } });
        });
    };

    const audio = () => {
        identities.forEach((id) => {
            onPublishEvent("user-published", {
                remoteUser: { uid: id.id, audioTrack: {} },
                mediaType: "audio",
            });
        });
    };

    const video = () => {
        identities.forEach((id) => {
            onPublishEvent("user-published", {
                remoteUser: { uid: id.id, videoTrack: {} },
                mediaType: "video",
            });
        });
    };

    return (
        <div style={settingsStyle}>
            <div className={"rows shadow pad-sm"}>
                <Settings id={channel} />
                <div className={"rows gap-xs"}>
                    <TextField
                        onChange={setStreams}
                        value={streams}
                        variant={"dense"}
                    />
                    <div className={"cols gap-px"}>
                        <Button onClick={() => onStoreIdentities(streams)}>
                            Update
                        </Button>
                        <Button onClick={() => join()}>Join</Button>
                        <Button onClick={() => audio()}>Audio</Button>
                        <Button onClick={() => video()}>Video</Button>
                    </div>
                </div>
                <div className={"cols gap-xs"}>
                    <div className={"o-50"}>Network:</div>
                    <div onClick={() => networkQuality(1)}>excellent</div>
                    <div onClick={() => networkQuality(3)}>ok</div>
                    <div onClick={() => networkQuality(5)}>bad</div>
                </div>
            </div>
        </div>
    );
}
