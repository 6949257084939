import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import UploadButtonContainer from "components/upload/UploadButton";

//import PropTypes from 'prop-types';

export function UploadButton(props) {
    const { onUpload } = props;
    return (
        <UploadButtonContainer
            onUpload={onUpload}
            trigger={
                <Button
                    endIcon={<Icon>mui-cloud_upload</Icon>}
                    variant={"text"}
                    color={"default"}
                >
                    Wgraj
                </Button>
            }
        />
    );
}

UploadButton.propTypes = {};

UploadButton.defaultProps = {};

export default UploadButton;
