import React from "react";
import { useClick, useData } from "state/hooks";
import resourceIcons from "config/icons";
import cn from "classnames";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export function Resource(props) {
    const { active } = props;
    const { label, path } = useData(props);
    const { onClick } = props;
    const click = useClick({ onClick, id: path });
    const icon = resourceIcons[path];
    return (
        <div
            className={cn("resource-link", {
                active: path === active,
            })}
            onClick={click}
        >
            <Icon defaultType={"mui"}>{icon}</Icon> {label}
        </div>
    );
}

Resource.propTypes = {};
Resource.defaultProps = {};
