import React from "react";
import PropTypes from "prop-types";
import Slider from "joynt/components/Slider";

import "./style.css";

export function Slide(props) {
    const { image, title, children } = props;

    const style = {
        backgroundImage: `url('${image}')`,
    };

    return (
        <div className={"tutorial-slide"}>
            <div className={"tutorial-slide__heading type-heading-xs"}>
                {title}
            </div>
            <div className={"tutorial-slide__image"} style={style} />
            <div className={"tutorial-slide__content rows rows-left gap-md"}>
                {children}
            </div>
        </div>
    );
}

export function TutorialSlider(props) {
    const { children, title } = props;
    return (
        <div className={"tutorial-slider type-default"}>
            {title ? (
                <div className={"brand-bg-alt tutorial-slider__header"}>
                    <div className={"type-heading-sm"}>{title}</div>
                </div>
            ) : null}
            <div className={"tutorial-slider__nav"}>
                {React.Children.map(children, (child, index) => (
                    <div key={index} />
                ))}
            </div>
            <Slider className={"grow relative"} dots={true}>
                {children}
            </Slider>
        </div>
    );
}

TutorialSlider.defaultProps = {};

TutorialSlider.propTypes = {
    title: PropTypes.string,
};
