import React from 'react';
import {connect} from 'react-redux';

import {selectListViews} from "state/selectors/app";
import List from "containers/List";
import Fetch from "containers/Fetch";

import {appendQueryParams} from "util/uri";
import {selectEntity} from "state/selectors/data";
import {ListItemIterator, SortableListItemIterator} from "components/list/items/ListItem";
import {registerListProps} from "state/actions/list";

import {changed} from "util/changed";
import ContextMenuList from "containers/ContextMenuList";
import ListFilterView from "containers/ListFilterView";
import ListViewSelect from "containers/ListViewSelect";


const resolveViewIterator = (store, props, viewData) => {
    const { viewType } = props;
    if (viewType) return resolveIterator(store, viewType, props);
    const { resource, resource_name, view_type } = viewData;
    if (resource_name) return resolveIterator(store, [resource_name, view_type].join('.'), props);
    const entityType = selectEntity(store, 'db.types', resource).name;
    const id = [entityType, view_type].join('.');
    return resolveIterator(store, id, props);
};

const resolveIterator = (store, id, {sortable}) => {
    if (id.indexOf('.list') > -1) {
        if (sortable) return SortableListItemIterator;
        return ListItemIterator;
    }
};

const findListViews = (store, type, id) => {
    const views = selectListViews(store, type);
    const found = views.filter(view => {
        let viewData = selectEntity(store, 'db.list_views', view);
        return viewData.id === id || viewData.name === id
    });
    return found.length ? found : views;
};

const resolveViewData = (store, id, type, required) => {
    const viewData = selectEntity(store, 'db.list_views', id);
    if (viewData.id) return viewData;
    if (required) return {};
    return {
        id: 'default',
        view_type: 'list',
        resource_name: type
    }
};

const mapStateToProps = (store, props) => {
    const views = findListViews(store, props.type, props.view);
    const view = views[0];
    const viewData = resolveViewData(store, view, props.type, props.requireView);
    let url = props.url || props.type.replace('.', '/');
    let queryParams = {};
    if (view) queryParams.view = viewData.id;
    if (props.view) queryParams.view = props.view;
    let useView = viewData.id;
    if (!props.requireView && viewData.id) useView = viewData.id;
    return {
        views: props.views
            ? selectListViews(store, props.type)
            : null,
        viewType: props.viewType || viewData.view_type,
        filterId: viewData.filters || props.filter,
        view: useView,
        url: appendQueryParams(url, queryParams),
        Iterator: props.Iterator || resolveViewIterator(store, props, viewData)
    }
};

const mapDispatchToProps = {
    registerListProps: registerListProps
};

class ListView extends React.PureComponent {

    static whyDidYouRender = true;

    componentDidMount() {
        this.registerListProps()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (changed(['type', 'list', 'filter', 'url'], prevProps, this.props)) {
            this.registerListProps();
        }
    }

    registerListProps = () => {
        const { registerListProps } = this.props;
        registerListProps(this.fetchProps());
    };

    fetchProps = () => {
        const { type, list, filter, filterFetch, url, yieldData, force } = this.props;
        const fetchProps =  {type, list, url, yieldData, force};
        if (filterFetch !== false) fetchProps.filter = filter;
        return fetchProps;
    };

    render() {
        const {
            view,
            onClick,
            type,
            list,
            filter,
            url,
            append,
            emptyState,
            pendingState,
            enable,
            filterId,
            views,
            onChangeView,
            requireView,
            ...other
        } = this.props;

        if (requireView && !view) return null;

        const Iterator = this.props.Iterator || ListItemIterator;
        const ListComponent = this.props.context
            ? ContextMenuList
            : List;

        return (<Fetch
            {...this.fetchProps()}
            append={append}
            enable={!!view && enable !== false}
        >
            { views
                ? <ListViewSelect
                    onChange={onChangeView}
                    items={views}
                    value={view}
                />
                : null }
            { filter && filterId ? <ListFilterView
                type={'db.filters'}
                id={filterId}
                filter={filter}
            /> : null }
            <ListComponent
                onClick={onClick}
                type={type}
                list={list}
                filter={filter}
                emptyState={emptyState}
                pendingState={pendingState}
                Iterator={Iterator}
                {...other}
            />
        </Fetch>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListView);
