import React from "react";
import { useSelector } from "react-redux";

//import PropTypes from 'prop-types';

function Notification(props) {
    const { message, status, created_at, log } = props;
    return (
        <div>
            <div>
                <strong>
                    {created_at} {status} {message}
                </strong>
            </div>
            <div>
                {Object.values(log).map((item, index) => {
                    return (
                        <div key={item.task_id}>
                            {item.name} {item.status} {item.message}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

Notification.defaultProps = {
    log: {},
};

export function NotificationsList(props) {
    const {} = props;
    const notifications =
        useSelector((state) => {
            return state.notifications.notifications;
        }) || {};
    const list = Object.values(notifications).reverse();
    const last = list[0] || null;

    if (!last) {
        return <div>Nothing new</div>;
    }
    return (
        <div className={"overflow-scroll rows gap-sm pad-md"}>
            {list.map((n) => (
                <Notification key={n.id} {...n} />
            ))}
            <pre>{JSON.stringify(last, null, 2)}</pre>
        </div>
    );
}

NotificationsList.propTypes = {};

NotificationsList.defaultProps = {};

export default NotificationsList;
