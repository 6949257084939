import React from 'react';
//import PropTypes from 'prop-types';
import SidebarGroup from "./SidebarGroup";
import {useSelector} from "react-redux";
import {selectChannelGroup} from "joynt/hooks/channels/useChannelGroups";

export function SidebarGroups(props) {

    const {id, ...other} = props;
    const nodes = useSelector(s => selectChannelGroup(s, null, id, "spaces"));

    return nodes.map(n => <SidebarGroup
        key={n}
        id={n}
        {...other}
    />);

}

SidebarGroups.propTypes = {};

SidebarGroups.defaultProps = {
    nodes: []
};

export default SidebarGroups;