import React from "react";
import PropTypes from "prop-types";
import SessionControls from "./SessionControls";
import { useSessionState } from "joynt/hooks/sessions";

export function SessionControlsContainer(props) {
    const { id } = props;

    const {
        onJoinSession,
        onEndSession,
        onStartSession,
        onResetSession,
        isLive,
        isConnecting,
        canStart,
        canEnd,
        hasEnded,
    } = useSessionState(id);

    return (
        <SessionControls
            {...props}
            hasEnded={hasEnded}
            isLive={isLive}
            isConnecting={isConnecting}
            onJoin={onJoinSession}
            onEnd={onEndSession}
            onReset={onResetSession}
            onStart={onStartSession}
            canStart={canStart}
            canEnd={canEnd}
        />
    );
}

SessionControlsContainer.propTypes = {
    id: PropTypes.string,
};
SessionControlsContainer.defaultProps = {};

export default SessionControlsContainer;
