import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import IconButton from "components/IconButton";
import { useIdentity } from "joynt/hooks/participants";

const stopPropagation = (fn) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    fn();
};

export function StagePagination(props) {
    const { id, onPrev, onNext, page, pages } = props;
    const { name } = useIdentity(id);

    const prev = useCallback(stopPropagation(onPrev), [onPrev]);
    const next = useCallback(stopPropagation(onNext), [onNext]);

    return (
        <div className={"stage-pagination"}>
            <div className={"stage-pagination__pages"}>
                {page} / {pages}
            </div>
            <div className={"stage-pagination__arrows"}>
                <IconButton
                    onClick={prev}
                    icon={"mui-arrow_left"}
                    disabled={!onPrev}
                />
                <IconButton
                    onClick={next}
                    icon={"mui-arrow_right"}
                    disabled={!onNext}
                />
            </div>
            {/*<div>{name}</div>*/}
        </div>
    );
}

StagePagination.propTypes = {};
StagePagination.defaultProps = {};

export default StagePagination;
