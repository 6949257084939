import React, { useState } from "react";
//import PropTypes from 'prop-types';
import { useClick, useData } from "state/hooks";
import { RoleItem } from "./../RoleItem/RoleItem";
import { useNodeRoles } from "./../hooks";
import { ResponsiveModal } from "components/Modal";
import ManageNodeRoles from "joynt/components/Roles/NodeRoles/ManageNodeRoles";
import Button from "components/Button";
import Icon from "components/Icon";

export function NodeRoleItem(props) {
    const { id, onClick, onRemove } = props;
    const { name } = useData({ type: "db.node-roles", id });
    const click = useClick(props);
    const remove = useClick({ onClick: onRemove, id });

    return (
        <RoleItem
            name={name}
            onClick={onClick ? click : null}
            onRemove={onRemove ? remove : null}
        />
    );
}

export function NodeRoles(props) {
    const { id } = props;

    const [showManage, setShowManage] = useState(false);
    const { items, list } = useNodeRoles(id);

    return (
        <div>
            <div className={"cols cols-spread cols-middle"}>
                <div>Workspace roles ({items.length})</div>
                <div>
                    <Button
                        onClick={() => setShowManage(true)}
                        variant={"text"}
                        startIcon={<Icon>mui-settings</Icon>}
                    >
                        Manage
                    </Button>
                </div>
            </div>
            <ResponsiveModal
                open={showManage}
                onClose={() => setShowManage(false)}
                maxWidth={false}
            >
                <div className={"manage-roles-modal"}>
                    <ManageNodeRoles id={id} list={list} />
                </div>
            </ResponsiveModal>
        </div>
    );
}

NodeRoles.propTypes = {};
NodeRoles.defaultProps = {};

export default NodeRoles;
