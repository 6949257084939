import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {Icon} from "@material-ui/core";

const buttonProps = {
    color: 'primary',
    variant: 'outlined',
    size: 'small',
    className: 'stepper-button'
};

export default class Stepper extends React.Component {

    increment = () => {
        const { value, onChange } = this.props;
        onChange(value+1);
    };

    decrement = () => {
        const { value, onChange } = this.props;
        onChange(value-1);
    };

    render() {
        const { label, value, min, max } = this.props;

        const disableDec = value === min;
        const disableInc = value === max;

        return (<div className={'grow cols cols-spread cols-middle'}>
            <div>{label}</div>
            <div className={'cols cols-middle'}>
                <Button
                    {...buttonProps}
                    onClick={this.decrement}
                    disabled={disableDec}
                >
                    <Icon>remove</Icon>
                </Button>
                <div className={'stepper-value'}>{value}</div>
                <Button
                    {...buttonProps}
                    onClick={this.increment}
                    disabled={disableInc}
                >
                    <Icon>add</Icon>
                </Button>
            </div>
        </div>);
    }

}

Stepper.propTypes = {
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number
};
Stepper.defaultProps = {
    value: 0,
    min: 0,
    max: 99
};