import React, { useCallback } from "react";
import PostPreview from "./PostPreview";
import PostView from "./../PostView";
import { useRouteParam } from "state/hooks";
import { ROUTE_POST } from "joynt/config";
import CreatePostPrompt from "joynt/components/Post/CreatePostPrompt";

//import PropTypes from 'prop-types';

export function PostPreviewContainer(props) {
    const [routeId, setId] = useRouteParam(ROUTE_POST);

    const id =
        routeId && routeId.split(".")[0] === "edit"
            ? routeId.split(".")[1]
            : routeId;

    const close = useCallback(() => setId(null), [setId]);

    return (
        <>
            <PostPreview {...props} isOpen={!!id} onClose={close}>
                {id && (
                    <PostView
                        id={id}
                        editable={routeId && routeId.split(".")[0] === "edit"}
                        onClose={close}
                    />
                )}
            </PostPreview>
            <CreatePostPrompt />
        </>
    );
}

PostPreviewContainer.propTypes = {};
PostPreviewContainer.defaultProps = {};

export default PostPreviewContainer;
