import React from "react";

//import PropTypes from 'prop-types';

export function CollectionItemForm(props) {
    const {} = props;
    return <div>CollectionItemForm</div>;
}

CollectionItemForm.propTypes = {};

CollectionItemForm.defaultProps = {};

export default CollectionItemForm;
