import { selectEntityData } from "state/selectors/data";

export function selectDynamicContentType(store, type, id) {
    function isDynamicContent(data) {
        const meta = Object.values(data.metadata || {});
        const lists = meta.filter(
            (n) => ["list", "query"].includes(n.type) && n.entry_type
        );
        if (lists.length === 0) return false;
        return lists[0]; //.entry_type;
    }
    const section = selectEntityData(store, type, id);
    const template = selectEntityData(store, type, section.template);
    return isDynamicContent(section) || isDynamicContent(template);
}
