import React, { useEffect, useMemo } from "react";
//import PropTypes from 'prop-types';
import ApiContext from "containers/context/ApiContext";
import Theme from "joynt/Theme";
import { useSelector } from "react-redux";
import { isPending } from "state/selectors/ui";
import { isAuthenticated } from "state/selectors/auth";
import Preloader from "components/Preloader";
import { useActions, useApiActions } from "state/hooks";
import { requireLogin } from "state/actions/auth";
import LoginLayout from "joynt/screens/Login";
import Modal from "containers/ModalStack";
import MediaPreview from "joynt/components/MediaPreview";
import ConfirmDialog from "containers/dialogs/Confirm";
import Snackbar from "joynt/components/Snackbar";
import LinkShare from "components/dialogs/LinkShare";
import { useRouteParam } from "state/hooks";
import domainRouter from "util/domainRouter";
import { bootstrap } from "joynt/state/actions";
import { RenderConfirm } from "joynt/modals/confirm";

const apiContext = domainRouter().apiContext;
//const themeProps = domainRouter().themeProps;

function useRequireAuth() {
    const hasAuth = useSelector((store) => isAuthenticated(store));
    const { redirect } = useActions({ redirect: requireLogin });
    return [hasAuth, redirect];
}

function Bootstrap({ id, enable, children }) {
    const { onBootstrap } = useApiActions({ onBootstrap: bootstrap });
    const [join] = useRouteParam("join");
    useEffect(() => {
        if (enable) onBootstrap(id, join);
    }, [enable, onBootstrap, join, id]);
    return children;
}

export default function Entrypoint(props) {
    const { children } = props;
    const pending = useSelector((store) =>
        isPending(store, ["bootstrap"], true)
    );
    const [hasAuth] = useRequireAuth(); //redirect
    const [id] = useRouteParam("id");

    const token = useSelector((s) => s.router.query.token);

    const context = useMemo(
        () => ({
            ...apiContext,
            token,
        }),
        [token]
    );

    return (
        <ApiContext.Provider value={context}>
            <Theme>
                <Preloader visible={hasAuth && pending} />
                {hasAuth ? (
                    <Bootstrap enable={pending !== false} id={id}>
                        {!pending ? (
                            <>
                                {children}
                                <Modal />
                                <MediaPreview />
                                <ConfirmDialog Component={RenderConfirm} />
                                <LinkShare />
                            </>
                        ) : null}
                    </Bootstrap>
                ) : (
                    <LoginLayout />
                )}
                <Snackbar />
            </Theme>
        </ApiContext.Provider>
    );
}

Entrypoint.propTypes = {};
Entrypoint.defaultProps = {};
