import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {useCreatePost} from "joynt/hooks";
import {POST_TYPE_TEXT} from "joynt/config";
import Button from "components/Button";
import CreateContextMenu from "./CreateContextMenu";

export function CreatePost(props) {

    const {id, postTypes, buttonLabel} = props;

    //const list = ["db.posts", id].join(".");
    const [onCreate] = useCreatePost(id);

    const multipleTypes = postTypes.length > 1;

    const createType = useCallback(() => {
        onCreate(null, postTypes[0], true);
    }, [onCreate, postTypes]);

    if (multipleTypes) return <CreateContextMenu
        types={postTypes}
        onCreate={onCreate}
    >
        {({onMenuOpen: onClick}) => <Button
            onClick={onClick}
        >
            {buttonLabel}
        </Button>}
    </CreateContextMenu>

    return (<Button onClick={createType}>
        {buttonLabel}
    </Button>);

}

CreatePost.propTypes = {
    id: PropTypes.string,
    buttonLabel: PropTypes.string,
    postTypes: PropTypes.array
};
CreatePost.defaultProps = {
    buttonLabel: "Create post",
    postTypes: [POST_TYPE_TEXT]
};

export default CreatePost;
