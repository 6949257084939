import React from "react";
import { useEdgesData } from "joynt/hooks";
import Feed from "joynt/views/feed/Feed";

//import PropTypes from 'prop-types';

export function SessionFiles(props) {
    const { id } = props;
    const { feed } = useEdgesData(id);

    if (!feed) {
        return <div>Not available</div>;
    }

    return <Feed id={feed} />;
}

SessionFiles.propTypes = {};
SessionFiles.defaultProps = {};

export default SessionFiles;
