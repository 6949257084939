import React from "react";
import FormLayout from "components/auth/FormLayout";
import Field from "containers/Field";
import SocialLogin from "components/auth/SocialLogin";
//import PropTypes from 'prop-types';

export default function LoginForm(props) {
    const { onLogin, onChangeView, enableSocial } = props;

    return (
        <FormLayout
            {...props}
            onSubmit={onLogin}
            submitLabel={"Login"}
            footer={
                <div className={"rows rows-center gap-sm"}>
                    {enableSocial ? <SocialLogin /> : null}
                    <div className={"grow cols cols-center gap-xs"}>
                        <div>You don't have an account?</div>
                        <div
                            className={"link"}
                            onClick={() => onChangeView("signup")}
                        >
                            Sign up
                        </div>
                    </div>
                    <div className={"grow cols cols-center gap-xs"}>
                        <div>Forgot password?</div>
                        <div
                            className={"link"}
                            onClick={() => onChangeView("password-reset")}
                        >
                            Reset password
                        </div>
                    </div>
                </div>
            }
        >
            <Field
                label={"E-mail"}
                id={"username"}
                fieldType={"text"}
                validate={true}
            />
            <Field
                label={"Password"}
                id={"password"}
                fieldType={"text"}
                type={"password"}
                validate={true}
            />
        </FormLayout>
    );
}

LoginForm.propTypes = {};
LoginForm.defaultProps = {};
