import React from "react";

import List from "containers/List";
import PageLayout from "cms/containers/PageLayout";
import { SectionIterator as DefaultSectionIterator } from "cms/containers/Section";
import { SectionsPlaceholder } from "cms/Section";

export default class Page extends React.PureComponent {
    render() {
        const {
            type,
            layout,
            defaultLayout,
            className,
            bodyClassName,
            onSortEnd,
            onDragSection,
            onCreateSection,
            SectionIterator,
        } = this.props;

        const { sections } = this.props.data;

        const iterator = SectionIterator || DefaultSectionIterator;

        const isLayout = type === "cms.layouts";
        const parentLayout = isLayout ? layout : layout || defaultLayout;

        if (!parentLayout)
            return (
                <List
                    type={"cms.sections"}
                    items={sections}
                    onSortEnd={onSortEnd}
                    onAddToList={onDragSection}
                    disableSort={!onSortEnd}
                    dropPlaceholder={
                        <SectionsPlaceholder onClick={onCreateSection} />
                    }
                    Iterator={iterator}
                />
            );

        return (
            <PageLayout
                type={"cms.layouts"}
                id={parentLayout}
                className={className}
                bodyClassName={bodyClassName}
                isLayout={isLayout}
                withWrapper={true}
            >
                <List
                    type={"cms.sections"}
                    items={sections}
                    onSortEnd={onSortEnd}
                    onAddToList={onDragSection}
                    disableSort={!onSortEnd}
                    dropPlaceholder={
                        <SectionsPlaceholder onClick={onCreateSection} />
                    }
                    Iterator={iterator}
                />
            </PageLayout>
        );
    }
}

Page.autoFillProps = ["layout"];
