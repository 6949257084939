import JitsiMeet from "joynt/meeting/backends/JitsiMeet";
import OpenTok from "joynt/meeting/backends/OpenTok";
import Agora from "joynt/meeting/agora-ng";
import ZoomSdkSession from "joynt/meeting/backends/zoom-sdk";

export const RECORDING_IDENTITY = "e271608c-bb98-11eb-a37e-0242ac170007";

export const sessionBackends = {
    'jitsi': JitsiMeet,
    'opentok': OpenTok,
    'agora': Agora,
    'default': Agora,
    'zoom-sdk': ZoomSdkSession
}