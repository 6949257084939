import React from "react";
import RoleSelectMenu from "./../RoleSelectMenu";
import Role from "./Role";

//import PropTypes from 'prop-types';

export function RoleSelect(props) {
    const { onSelect, scope, value } = props;
    return (
        <RoleSelectMenu
            scope={scope}
            trigger={({ onClick }) => <Role label={value} onClick={onClick} />}
            onSelect={onSelect}
        />
    );
}

RoleSelect.propTypes = {};
RoleSelect.defaultProps = {};

export default RoleSelect;
