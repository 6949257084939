import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from "./DatePicker";
import HourSelect from "./HourSelect";

import {startOfDay, addDays} from "./util";

import "./style.css";

export function DateTimePicker(props) {

    const {
        start,
        end,
        minuteInterval,
        onChangeStart,
        onChangeEnd
    } = props;

    const now = (new Date()).getTime()/1000;
    const startDate = new Date(start*1000);
    const today = startOfDay(new Date());
    const startDay = startOfDay(startDate);

    const startMin = startDay.getTime() > today.getTime()
        ? startDay.getTime()/1000
        : now;

    const startMax = addDays(startDate, 1).getTime()/1000;

    return (<div className={"cols cols-2 gap-sm"}>
        <DatePicker start={start} end={end} onChange={onChangeStart}/>
        <div className={"cols cols-2 gap-xs"}>
            <HourSelect
                start={startMin}
                max={startMax}
                value={start}
                minuteInterval={minuteInterval}
                onChange={onChangeStart}
            />
            <HourSelect
                start={start}
                value={end}
                minuteInterval={minuteInterval}
                onChange={onChangeEnd}
            />
        </div>
    </div>);

}

DateTimePicker.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    minuteInterval: PropTypes.number,
    onChangeStart: PropTypes.func,
    onChangeEnd: PropTypes.func
};
DateTimePicker.defaultProps = {
    minuteInterval: 15
};

export default DateTimePicker;
