import React from "react";
import {useEdgesData, useNodeData, usePublicView} from "joynt/hooks";
import NetworkingTables from "./NetworkingTables";
import SessionOverviewHeader from "joynt/components/SessionPage/SessionOverviewHeader";

import SessionInfo from "../SessionInfo";
import SessionStage from "./SessionStage";
import { SessionFlowControls } from "./SessionFlowControls";
import ChatButton from "./ChatButton";
import useFetch from "state/hooks/useFetch";
import useScreenSize from "util/hooks/useScreenSize";
import SessionPageLayout from "joynt/components/SessionPage/SessionPageLayout";

//import PropTypes from 'prop-types';

export function SessionView(props) {
    const { id } = props;

    const { sessions, session_chat: chatId } = useEdgesData(id);
    const { session_flow_segment, event_state } = useNodeData(id);

    const networkingIsLive = ["networking", "after-party"].includes(
        session_flow_segment
    );

    const isIntro = !session_flow_segment || session_flow_segment === "start";
    const showSessionInfo = event_state !== "live" || isIntro;

    const { isMobile } = useScreenSize();
    const { isPublicView } = usePublicView(id);

    useFetch({
        url: `v2/joynt/nodes/${id}/networking`,
        fetchId: "workspace",
        once: false,
        enable: !isPublicView,
    });

    return <SessionPageLayout
        id={id}
        header={<SessionOverviewHeader id={id}>
            {chatId && <ChatButton id={chatId} />}
        </SessionOverviewHeader>}
        footer={!isMobile && <SessionStage id={id} />}
        content={<>
            {showSessionInfo && <SessionInfo id={id} />}

            <SessionFlowControls id={id} />

            {sessions ? (
                <div>
                    <NetworkingTables
                        id={sessions}
                        isLive={networkingIsLive}
                    />
                </div>
            ) : (
                <div>Sessions node doesn't exist</div>
            )}
        </>}
    />;
}

SessionView.propTypes = {};
SessionView.defaultProps = {};

export default SessionView;
