import React, {useMemo} from 'react';
//import {useMeetingSession} from "joynt/MeetingSession";
import cn from "classnames";
import VideoTile from "joynt/meeting/backends/VideoTile";
//import PropTypes from 'prop-types';

function findStageTile(tiles) {
    for (let i = 0; i < tiles.length; i++) {
        if (tiles[i].content) return i;
    }
    for (let i = 0; i < tiles.length; i++) {
        if (!tiles[i].local) return i;
    }
    return 0;
}

export default function MeetingTiles(props) {

    //const { onAttachTile, isConnected, tiles } = useMeetingSession();
    const { onAttachTile, isConnected, tiles } = props;

    const [stage, other] = useMemo(() => {
        if (tiles.length === 1) return [tiles[0], []];
        let stageTileIndex = findStageTile(tiles);
        let other = tiles.slice();
        other.splice(stageTileIndex, 1);
        return [tiles[stageTileIndex], other];
    }, [tiles]);

    return isConnected ? <div className={'rows gap-sm grow'}>
        <div id="meeting-grid" className={cn('meeting-grid grow', {
            'is-empty': tiles.length === 0
        }, `count-${tiles.length}`)}>
            { stage ? <VideoTile
                key={stage.id}
                {...stage}
                pinned={true}
                onAttachTile={onAttachTile}
            /> : null }
            {other && other.length ? other.map(tile => {
                return <VideoTile
                    key={tile.id}
                    {...tile}
                    pinned={false}
                    onAttachTile={onAttachTile}
                />;
            }) : null}
        </div>
    </div> : null;
}

MeetingTiles.propTypes = {};
MeetingTiles.defaultProps = {};
