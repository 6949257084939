import React from "react";
import { FormLayout } from "playground/cms/Form";
import UploadButton from "./UploadButton";
import Button from "components/Button";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function FormSection(props) {
    const { label, onOpenLibrary, onUpload, children } = props;
    return (
        <FormLayout.Section
            name={label}
            onAdd={onOpenLibrary}
            action={
                <div className={"cols gap-sm cols-middle"}>
                    <UploadButton onUpload={onUpload} />
                    <Button
                        variant={"outlined"}
                        endIcon={<Icon>mui-search</Icon>}
                        onClick={onOpenLibrary}
                    >
                        Wybierz
                    </Button>
                </div>
            }
        >
            {children}
        </FormLayout.Section>
    );
}

FormSection.propTypes = {};

FormSection.defaultProps = {};

export default FormSection;
