import React from 'react';
import {connect} from 'react-redux';
import {changed} from 'util/changed';
import {updateFilter} from "state/actions/filters";
import {selectList} from "state/selectors/lists";

const mapStateToProps = (store, props) => {
    return {
        query: store.router.query,
        ids: selectList(store, props.type, props.list)
    }
};

const mapDispatchToProps = {
    onUpdateFilter: updateFilter
};

class Filter extends React.PureComponent {

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (changed(
            this.props.watchQuery,
            prevProps.query,
            this.props.query
        )) {
            return this.update();
        }
        if (changed(['ids'], prevProps, this.props)) {
            return this.update();
        }
    }

    update = () => {
        const { onUpdateFilter, type, list } = this.props;
        onUpdateFilter(type, list);
    };

    render() {
        return this.props.children;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);