import usePresenceChannel from "joynt/websockets/hooks/usePresenceChannel";
//import PropTypes from 'prop-types';

export default function PresenceChannel(props) {

    const {id} = props;
    usePresenceChannel(id);

    return null;
}

PresenceChannel.propTypes = {};
PresenceChannel.defaultProps = {};
