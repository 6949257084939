import React from "react";
import Field from "containers/Field";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

function AddressPopoverContent(props) {
    const { id } = props;

    return (
        <div className={"cols cols-2 gap-sm pad-md"}>
            <div className={"rows gap-sm"}>
                <div className={"cols gap-sm"}>
                    <Field id={`${id}.street`} label={"Ulica"} size={"small"} />
                    <Field id={`${id}.street_no`} label={"Nr"} size={"small"} />
                </div>
                <div className={"cols gap-sm"}>
                    <Field
                        id={`${id}.zip`}
                        label={"Kod pocztowy"}
                        size={"small"}
                    />
                    <Field
                        id={`${id}.city`}
                        label={"Miejscowość"}
                        size={"small"}
                    />
                </div>
                <div className={"cols gap-sm"}>
                    <Field
                        id={`${id}.region`}
                        label={"Województwo / region"}
                        size={"small"}
                    />
                </div>
            </div>
            {/*<Field id={`${id}.geolocation`} fieldType={"geolocation"} />*/}
        </div>
    );
}

function renderAddress(value) {
    const { street, street_no, zip, city, region, geolocation } = value;
    const line1 = [street, street_no].filter((v) => !!v).join(" ");
    const line2 = [zip, city].filter((v) => !!v).join(" ");
    const line3 = [region].filter((v) => !!v).join(" ");
    return [line1, line2, line3].filter((v) => !!v).join(", ");
}

function GeoLocationComponent(props) {
    const { value, onSearch } = props;
    const str = [value?.lat, value?.lng].filter((v) => !!v).join(", ");
    return (
        <div className={"cols"}>
            <div>{str}</div>
            <IconButton
                icon={"mui-room"}
                onClick={onSearch}
                tooltip={"Zaznacz na mapie"}
            />
        </div>
    );
}

GeoLocationComponent.defaultProps = {
    value: {},
};

const anchorOrigin = {
    vertical: "top",
    horizontal: "left",
};

export function Address(props) {
    const { id, value, label, onChange } = props;
    const str = renderAddress(value);

    return (
        <Popover
            anchorOrigin={anchorOrigin}
            trigger={({ onClick }) => (
                <div className={"form-section variant-contained"}>
                    <div className={"cols cols-spread"}>
                        <div onClick={onClick}>
                            <div>{label}</div>
                            <div>{str}</div>
                        </div>
                        <Field
                            id={`${id}.geolocation`}
                            fieldType={"geolocation"}
                            GeoLocationComponent={GeoLocationComponent}
                        />
                    </div>
                </div>
            )}
        >
            <AddressPopoverContent {...props} />
        </Popover>
    );
}

Address.propTypes = {};

Address.defaultProps = {
    value: {},
};

export default Address;
