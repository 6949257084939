import React from 'react';
import {connect} from 'react-redux';
import SectionBrowser from 'cms/ui/SectionBrowser';
import {pushRouteParams} from "state/actions/router";
import {change} from "state/actions/data";
import {selectRouteParam} from "state/selectors/router";

const ENDPOINT = process.env.REACT_APP_NODE_API_ENDPOINT;

const mapStateToProps = (store, props) => {
    const service = selectRouteParam(store, 'service');
    const list = ['cms.sections', 'library'].join('.');
    return {
        section: selectRouteParam(store, 'section'),
        type: 'cms.sections',
        list,
        url: `${ENDPOINT}/sections/${service}?type=view`,
        service
    }
};

const mapDispatchToProps = {
    pushRouteParams: pushRouteParams,
    onChange: change
};

class ChangeTemplate extends React.PureComponent {

    select = (template) => {
        const {
            pushRouteParams,
            onChange,
            section
        } = this.props;

        onChange('cms.sections', section, {
            template
        });

        pushRouteParams({list: 'tree'});
    };

    render() {
        return (<SectionBrowser
            {...this.props}
            onClick={this.select}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeTemplate);