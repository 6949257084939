import {useMeetingSession} from "joynt/meeting/MeetingContainer";
import {useUiFlag} from "state/hooks";
import {FLAG_LAYOUT_COLLAPSE, STAGE_VIEW} from "joynt/config";
import {useCallback, useMemo} from "react";
import {useRouter} from "joynt/hooks/channels";
import {useEdgesData, useNodeData} from "joynt/hooks";
import {SESSION_TYPE_NETWORKING_TABLE} from "joynt/config/sessions";

export default function useSessionLayout() {
    const {session} = useMeetingSession();
    const {session_type} = useNodeData(session);
    const {stage, parent_space: space} = useEdgesData(session);
    const [collapsed, setCollapsed] = useUiFlag([FLAG_LAYOUT_COLLAPSE,session].join('.'));
    const parent = session_type === SESSION_TYPE_NETWORKING_TABLE
        ? space || session
        : stage || session;

    const { onLink } = useRouter();

    const onToggleCollapsed = useCallback(() => {
        setCollapsed(!collapsed);
        if (!collapsed) {
            onLink(parent, null, STAGE_VIEW);
        }
    }, [collapsed, setCollapsed, onLink, parent]);

    return useMemo(() => ({
        expanded: !!collapsed,
        onToggle: onToggleCollapsed
    }), [collapsed, onToggleCollapsed]);
}