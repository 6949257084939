import React from 'react';
import IconButton from "components/IconButton";

export default class IconButtonGroup extends React.PureComponent {

    checked = (chk) => {
        const { value, multiple } = this.props;
        if (!multiple) return chk===value;
        if (!value) return false;
        return value.split(',').indexOf(chk)>-1;
    };

    render() {
        const { items, onToggle } = this.props;
        return <div className={'ui-icon-btn-group'}>{items.map(item => {
            return <IconButton
                onClick={()=>onToggle(item.value)}
                key={item.value}
                checked={this.checked(item.value)}
                {...item}
            />
        })}</div>;
    }

}

IconButtonGroup.defaultProps = {
    items: []
};