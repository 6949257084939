import React from "react";
import Entrypoint from "joynt/Entrypoint";
import useProgramaticJoin from "joynt/meeting/hooks/useProgramaticJoin";
import MeetingContainer from "joynt/meeting/MeetingContainer";
import { NetworkState } from "joynt/network-state";
import { useRouteParam } from "state/hooks";
//import PropTypes from 'prop-types';

import "./style.css";

function Join({ id }) {
    useProgramaticJoin(id);
    return null;
}

export function SessionRecordView(props) {
    const [id] = useRouteParam("id");

    return (
        <div className={"session-record dark-mode"}>
            <Entrypoint>
                <NetworkState>
                    <MeetingContainer id={id} recordMode={true}>
                        <Join id={id} />
                    </MeetingContainer>
                </NetworkState>
            </Entrypoint>
        </div>
    );
}

SessionRecordView.propTypes = {};
SessionRecordView.defaultProps = {};

export default SessionRecordView;
