import React from 'react';
import PropTypes from 'prop-types';

import MuiSwitch from '@material-ui/core/Switch';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default class Switch extends React.PureComponent {

    handleChange = event => {
        const { onChange, value } = this.props;
        onChange(!!value);
    };

    render() {
        const { label, value, negate } = this.props;

        if (!label) return <MuiSwitch checked={negate ? !value : !!value} onChange={this.handleChange} />;

        return (<FormGroup row>
            <FormControlLabel control={
                <MuiSwitch checked={negate ? !value : !!value} onChange={this.handleChange} />
            } label={label} />
        </FormGroup>);
    }

}

Switch.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string
};