import React, {useCallback} from 'react';
// import PropTypes from 'prop-types';
import SidebarSection, {CreateChannelIcon} from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import SidebarNodeItem from "../SidebarItem/SidebarItem.container";
import {selectChannelGroup} from "joynt/hooks/channels/useChannelGroups";
import {useSelector} from "react-redux";
import {NODE_TYPE_EVENT, NODE_TYPE_PROJECT} from "joynt/config";

const nullObj = {};

const names = {
    projects: "Projects",
    bookmarks: "Bookmarks",
    pinned: "Pinned",
    upcoming: "Upcoming sessions"
}

const variants = {
    projects: "pinned",
    pinned: "pinned"
}

const emptyStates = {
    upcoming: "There are no upcoming sessions"
}

const newNodeData = {
    projects: {subtype: NODE_TYPE_PROJECT},
    upcoming: {subtype: NODE_TYPE_EVENT}
}

export function NodesGroup(props) {

    const {id, type, onClick, selected, path, isAdmin, onCreate} = props;
    const nodes = useSelector(s => selectChannelGroup(s, null, id, type));

    const data = newNodeData[type] || nullObj;
    const create = useCallback(() => onCreate(data), [onCreate, data]);

    if (!nodes.length && !emptyStates[type]) return null;

    const canCreate = !["bookmarks", "pinned"].includes(type);

    return (<SidebarSection
        name={names[type]}
        icon={canCreate && isAdmin && <CreateChannelIcon
            onClick={create}
        />}
        emptyState={emptyStates[type]}
    >
        {nodes.map(n => <SidebarNodeItem
            key={n}
            id={n}
            onClick={onClick}
            isActive={selected === n}
            isHighlighted={path && path.indexOf(n) > -1}
            variant={variants[type] || undefined}
        />)}
    </SidebarSection>);

}

NodesGroup.propTypes = {};

NodesGroup.defaultProps = {};

export default NodesGroup;