import React from "react";
import { OverlayForm } from "playground/cms/Form";
import DebugConsole from "./DebugConsole.container";

//import PropTypes from 'prop-types';

export function DebugConsoleOverlay(props) {
    const { path, onClose } = props;
    return (
        <OverlayForm open={!!path} onClose={onClose} zIndex={6}>
            <DebugConsole {...props} />
        </OverlayForm>
    );
}

DebugConsoleOverlay.propTypes = {};

DebugConsoleOverlay.defaultProps = {};

export default DebugConsoleOverlay;
