import React from 'react';
//import PropTypes from 'prop-types';

export default class ProductDetails extends React.Component {

    render() {
        return (<div>ProductDetail</div>);
    }

}

ProductDetails.propTypes = {};