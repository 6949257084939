import React, { useEffect } from "react";

import FeedEmptyState from "./FeedEmptyState";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import PostCard from "joynt/components/Post/PostCard";
import Fetch from "containers/Fetch";
import List from "containers/List";
import { useActions, useFilter, useRegisterList } from "state/hooks";
import ListReload from "components/list/ListReload";
import { useFeatureEnabled, useKeep, useNode, useNodeData } from "joynt/hooks";
import ViewSettings from "joynt/views/feed/ViewSettings";
import KeepIcon from "joynt/components/KeepIcon";
import FeedFilter from "joynt/views/feed/FeedFilter";
import PostCreate from "joynt/components/Post/PostCreate";
import FeedPost from "joynt/components/Post/FeedPost";
import { setPage } from "state/actions/pagination";
import { CircularProgress } from "@material-ui/core";
import PostTags from "joynt/components/Post/PostTags";
import DiscussionThread from "joynt/components/DiscussionThread";
import SettingsIconButton from "joynt/components/SettingsIcon";
import Joyride from "joynt/components/Help/Joyride";
import MaterialPostCard from "joynt/components/Post/MaterialPostCard";

import "./style.css";
import { FEATURE_OPTIMIZED_API } from "joynt/config/features";
import useScreenSize from "util/hooks/useScreenSize";

const PostIterator = InfiniteScrollIterator(FeedPost);
const PostCardIterator = InfiniteScrollIterator(PostCard);
const DiscussionIterator = InfiniteScrollIterator(DiscussionThread);
const MaterialPostIterator = InfiniteScrollIterator(MaterialPostCard);

const iterators = {
    cards: PostCardIterator,
    discussion: DiscussionIterator,
    default: PostIterator,
    material: MaterialPostIterator,
};

function Header(props) {
    const { name, actions, tags, description } = props;
    return (
        <div className={"feed-header rows gap-2"}>
            <div className={"row"}>
                <div className={"cols cols-spread cols-middle gap-sm"}>
                    <div className={"type-heading-sm"}>{name}</div>
                    <div className={"cols cols-middle"}>{actions}</div>
                </div>
            </div>
            <div className="row feed-header__description">{description}</div>
            {tags && <div className="row">{tags}</div>}
        </div>
    );
}

function HeaderContainer(props) {
    const { id, list, filter } = props;
    const { onKeep } = useKeep();
    const { name, can_edit, description } = useNodeData(id);

    return (
        <Header
            name={name}
            actions={
                <>
                    {can_edit && <ViewSettings id={id} />}
                    <KeepIcon id={id} onKeep={onKeep} />
                    <FeedFilter id={id} list={list} filter={filter} />
                    {can_edit && <SettingsIconButton id={id} />}
                </>
            }
            description={description}
            tags={<PostTags id={id} />}
        />
    );
}

function FeedContainer(props) {
    const { id, view, postTypes, showHeader, showParentLink, children } = props;
    const { isDesktop } = useScreenSize();
    const type = "db.nodes";
    const useDevApi = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const filter = ["db.posts", id].join(".");
    const { content_view, can_post } = useNode(id);
    const {
        value: { id: parent },
    } = useFilter(filter);
    const { onSetPage } = useActions({ onSetPage: setPage });
    const postView = view || content_view;

    const list = ["db.posts", id].join(".");
    const tag = parent || id;

    let url = useDevApi
        ? `v2/joynt/nodes/${tag}/posts`
        : `joynt/nodes/${tag}/posts`;

    if (postTypes.length) url += `?postType=${postTypes.join(",")}`;

    useEffect(() => {
        return () => onSetPage(filter, 1);
    }, [onSetPage, filter]);

    const fetchProps = {
        type,
        list,
        filter,
        url,
        append: true,
        enable: true,
        force: true,
    };

    useRegisterList(fetchProps);

    const Iterator = iterators[postView] || iterators.default;

    return (
        <>
            {showHeader && (
                <>
                    <HeaderContainer id={id} list={list} filter={filter} />
                    {!can_post && isDesktop && <Joyride id={"feed"} />}
                </>
            )}
            {showHeader && can_post && (
                <>
                    <PostCreate id={id} list={list} />
                    {isDesktop && <Joyride id={"feedCreate"} />}
                </>
            )}
            {children}
            <ListReload type={"db.nodes"} id={list} />
            <Fetch {...fetchProps}>
                <List
                    className={"feed-list"}
                    type={"db.nodes"}
                    list={list}
                    filter={filter}
                    useWindowScroll={false}
                    Iterator={Iterator}
                    emptyState={<FeedEmptyState />}
                    pendingReplace={false}
                    showParentLink={showParentLink}
                    pendingState={
                        <div className={"cols cols-center pad-md"}>
                            <CircularProgress size={32} />
                        </div>
                    }
                />
            </Fetch>
        </>
    );
}

FeedContainer.defaultProps = {
    postTypes: [],
    showHeader: true,
};

export default FeedContainer;
