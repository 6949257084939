import React from "react";
//import PropTypes from 'prop-types';

import QueryForm from "playground/designer/ComponentEditor/Types/Query";
import ComponentBox from "./../ComponentBox";
import { useData, useFetch, useList } from "state/hooks";
import { takeProps } from "util/takeProps";
import { appendQueryParams } from "util/uri";
import { useComponentMapping } from "playground/designer/Zmap";
import { pageTypeToCmsType } from "cms/util";

const listProps = [
    "id",
    "query_type",
    "query_filter",
    "entry_type",
    "filter",
    "repository",
    "integration",
    "tags",
    "enum_definition",
    "query_spec.tags",
    "list",
];

function listDataUrl(props) {
    if (!props.entry_type) return null;
    const params = takeProps(listProps, props);
    if (params["query_spec.tags"]) {
        params.tags = params["query_spec.tags"];
        delete params["query_spec.tags"];
    }
    return appendQueryParams("cms/query", params);
}

function useListQuery(type, id) {
    const mapping = useComponentMapping(type, id);
    const data = useData({ type, id });
    const resourceType = pageTypeToCmsType(data.entry_type);
    const listId = [type, id].join(".");
    const fullListId = [resourceType, listId].join("/");
    const url = listDataUrl({ ...data, ...mapping.result, list: fullListId });
    return {
        type: resourceType,
        list: listId,
        url,
    };
}

export function Query(props) {
    const { type, id } = props;
    const { entry_type } = useData(props);

    const fetchProps = useListQuery(type, id);

    useFetch(fetchProps);

    const { items } = useList(fetchProps.type, fetchProps.list);
    const queryResult = useData({ type: "cms.query", id });

    return (
        <ComponentBox
            settings={
                <QueryForm
                    id={id}
                    queryEntityType={fetchProps.type}
                    queryListId={fetchProps.list}
                />
            }
        >
            <span>
                Query of <strong>{entry_type}</strong> {items?.length}
            </span>
            {/*<pre>{JSON.stringify(queryResult, null, 2)}</pre>*/}
        </ComponentBox>
    );
}

Query.propTypes = {};

Query.defaultProps = {};

export default Query;
