import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function FormSelectOption(props) {
    const { children } = props;
    return (
        <>
            {children}
            <Field
                id={"option_label"}
                label={"Option label"}
                fieldType={"text"}
            />
            <Field
                id={"option_value"}
                label={"Option value"}
                fieldType={"text"}
            />
        </>
    );
}

FormSelectOption.propTypes = {};

FormSelectOption.defaultProps = {};

export default FormSelectOption;
