import React from "react";
import MultiStepForm from "joynt/edit/MultiStepForm";

import { ProjectCreateForm } from "./create";
import {
    ProjectAccess,
    ProjectSettings,
    ProjectAdvancedSettings,
} from "./edit";

const createSteps = {
    step1: {
        component: ProjectCreateForm,
        label: "General",
        submit: true,
    },
};

const editSteps = {
    general: {
        component: ProjectSettings,
        label: "General",
    },
    access: {
        component: ProjectAccess,
        label: "Access",
    },
    advanced: {
        component: ProjectAdvancedSettings,
        label: "Advanced",
    },
};

export default function Project(props) {
    const { isDraft } = props;
    return (
        <MultiStepForm
            {...props}
            steps={isDraft ? createSteps : editSteps}
            showHeader={false}
        />
    );
}

Project.propTypes = {};
Project.defaultProps = {};
