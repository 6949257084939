import React from 'react';
import {connect} from 'react-redux';
import {resolveAlias} from "state/selectors/data";

import Form from "containers/Form";

const mapStateToProps = (store, props) => {
    return {
        id: resolveAlias(store, 'console.presets', '@websitePreset')
    }
};

const mapDispatchToProps = {};

class TypographyContainer extends React.PureComponent {

    fields = {'typography': true, 'typography.styles': true};

    render() {
        const { id } = this.props;
        return (<div className={'pad-sm overflow-scroll'}>
            <Form type={'console.presets'} id={id} selectFields={this.fields} />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypographyContainer);