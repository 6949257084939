import { useCallback, useMemo } from "react";
import { useActions, useFilter } from "state/hooks";
import { createCallback } from "state/actions/create";

export function useCreate({ type, filter, setId }) {
    const filterId = filter || type;
    const { onCreate } = useActions({ onCreate: createCallback });
    const { value: filterValue } = useFilter(type, type, filterId);
    const handleCreate = useCallback(
        (inputData = {}) => {
            const cb = ({ id }) => setId(id);
            let entryType =
                type.indexOf("cms.entries") === 0
                    ? type.replace("cms.entries.", "")
                    : null;
            if (type === "cms.properties") entryType = "properties";
            if (type === "cms.products") entryType = "products";
            if (type === "cms.categories") entryType = "categories";

            const data = { active: true, ...inputData };
            if (entryType) data.type = entryType;
            if (filterValue.tags) data.tags = [filterValue.tags];
            if (filterValue.property) data.property = filterValue.property;
            onCreate(cb, type, data, type);
        },
        [onCreate, setId, type, filterValue]
    );
    return useMemo(() => ({ onCreate: handleCreate }), [handleCreate]);
}
