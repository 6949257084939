import { useSelector } from "react-redux";
import { selectEntityData } from "state/selectors/data";

const nullObj = {};

export function useEdgesData(id) {
    return (
        useSelector((s) => selectEntityData(s, "db.nodes", id)).edges_ref ||
        nullObj
    );
}
