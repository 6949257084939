import React from 'react';
//import PropTypes from 'prop-types';
import IconButton from "cms/ui/IconButton";

export default class InspectorLayout extends React.PureComponent {

    render() {
        const {
            onExit,
            primaryNav,
            secondaryNav,
            primary,
            secondary,
            pending
        } = this.props;

        return (<div className={'grow overflow-hide cols cols-2'}>
            <div className={'rows overflow-hide'}>
                <div className={'ide-panel-nav cols gap-sm'}>
                    { onExit ? <IconButton
                        onClick={onExit}
                        value={'back'}
                        icon={'mui-arrow_back'}
                    /> : null }
                    { primaryNav ? primaryNav : null }
                </div>
                <div className={'rows overflow-hide grow'}>
                    { primary && pending !== null ? primary : null }
                </div>
            </div>
            { secondary && pending !== null ? <div className={'default-c700 rows overflow-hide'}>
                <div className={'ide-panel-nav'}>
                    { secondaryNav ? secondaryNav : null }
                </div>
                <div className={'overflow-scroll grow'}>
                    { secondary ? secondary : null }
                </div>
            </div> : null }
        </div>);
    }

}

InspectorLayout.propTypes = {};