import React from "react";
//import PropTypes from 'prop-types';

import PostTitle from "joynt/components/Post/PostTitle";
import TaskCheck from "./TaskCheck";

export function TaskPostTitle(props) {
    const { id } = props;

    return (
        <div className={"cols gap-sm"}>
            <TaskCheck id={id} />
            <PostTitle {...props} placeholder={"Add task name"} />
        </div>
    );
}

TaskPostTitle.propTypes = {};
TaskPostTitle.defaultProps = {};

export default TaskPostTitle;
