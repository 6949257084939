import React, {useState} from 'react';
import {useSelector} from "react-redux";
import IconButton from "components/IconButton";
import LogsList from "./LogsList";
import {IdentityAttendee} from "joynt/participants/Attendee";
import './style.css';
import Tab from "joynt/components/Tabs/Tab";
import Settings from "./Settings";
import Client from "./Client";
//import PropTypes from 'prop-types';

const views = [
    'client', 'settings', 'info', 'stats', 'logs', 'state'
];

function Snapshot({data, datapoint}) {
    const point = datapoint || data.points[data.points.length-1];
    const contents = data.snapshots[point];

    return <pre>
        {JSON.stringify(contents, null, 2)}
    </pre>
}

export default function Console(props) {
    const {onRefresh, onClose, stats} = props;

    const [selected, setSelected] = useState(null);
    const [selectedView, setView] = useState(views[0]);
    const [minimized, setMinimized] = useState(false);

    const logs = useSelector(s=>s.data['app.session-debug'] || {});
    const peers = Object.keys(logs);
    return <div className={'rows session-debug-console'}>
        <div className={'cols overflow-hide'}>
            {!minimized && false ? <div className={'overflow-scroll'}>
                {peers.map(function(peer) {
                    return <IdentityAttendee
                        id={peer}
                        key={peer}
                        onClick={()=>setSelected(peer)}
                    />;
                })}
            </div> : null }
            <div className={'rows grow overflow-hide'}>
                <div className={'cols cols-middle cols-spread gap-sm'}>
                    <div className={'cols cols-middle gap-sm'}>
                        <div>
                            <IconButton icon={'mui-refresh'} onClick={onRefresh} />
                        </div>
                        {views.map(view => {
                            return <Tab
                                key={view}
                                selected={selectedView === view}
                                onClick={()=>setView(view)}
                            >{view}</Tab>
                        })}
                    </div>
                    <div className={'cols'}>
                        <IconButton
                            onClick={()=>setMinimized(!minimized)}
                            icon={minimized ? 'mui-open_in_full' : 'mui-minimize'}
                        />
                        <IconButton onClick={onClose} icon={'mui-close'} />
                    </div>
                </div>
                { !minimized ? <div className={'rows grow overflow-scroll'}>
                    {selected ? <>
                        {selectedView === 'info' ? <pre>
                            {JSON.stringify(logs[selected].info, null, 2)}
                        </pre> : null }
                        {selectedView === 'stats' ? <pre>
                            {JSON.stringify(stats, null, 2)}
                        </pre> : null }
                        {selectedView === 'logs' ? <>
                            <LogsList logs={logs[selected].logs.slice().reverse()} />
                        </> : null }
                        {selectedView === 'state' ? <Snapshot data={logs[selected]} /> : null }
                    </> : <div className={'o-50'}>Select participant</div>}
                    {selectedView === 'client' ? <>
                        <Client />
                    </> : null }
                    {selectedView === 'settings' ? <Settings /> : null }
                </div> : null }
            </div>
        </div>
    </div>
}

Console.propTypes = {};
Console.defaultProps = {
    stats: {}
};
