import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import IconButton from "components/IconButton";
import FormActions from "joynt/components/Form/FormActions";
import Preloader from "components/Preloader";

import "./style.css";

export function ConfirmDialog(props) {
    const {
        yes,
        no,
        title,
        confirmText,
        cancelText,
        actions,
        isPending,
        children,
    } = props;

    return (
        <div
            className={cn("confirm-dialog", {
                "is-pending": isPending,
            })}
        >
            {no && !isPending && (
                <div className={"confirm-dialog__close"}>
                    <IconButton icon={"mui-close"} onClick={no} />
                </div>
            )}
            <div className={"confirm-dialog__body"}>
                <div className={"confirm-dialog__title"}>{title}</div>
                <div className={"confirm-dialog__content"}>{children}</div>
                {actions || (
                    <FormActions
                        onSubmit={yes}
                        onReject={no}
                        submitLabel={confirmText}
                        rejectLabel={cancelText}
                    />
                )}
            </div>
            <Preloader visible={isPending} />
        </div>
    );
}

ConfirmDialog.propTypes = {
    isPending: PropTypes.bool,
    title: PropTypes.string,
    yes: PropTypes.func,
    no: PropTypes.func,
};
ConfirmDialog.defaultProps = {
    isPending: false,
};

export default ConfirmDialog;
