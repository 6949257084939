import {useCallback} from "react";
import {useApiActions} from "state/hooks";
import {banUser, removeUser} from "joynt/state/actions";

export function useMemberManagement(node) {
    const {
        onRemove,
        onBan
    } = useApiActions({
        onRemove: removeUser,
        onBan: banUser
    });

    const remove = useCallback((identity) => {
        onRemove(node, identity);
    }, [node, onRemove]);

    const ban = useCallback((identity) => {
        onBan(node, identity);
    }, [node, onBan]);

    return {
        onRemove: remove,
        onBan: ban
    }
}