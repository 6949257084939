import React, { useCallback, useState } from "react";
import { useRouteParam } from "state/hooks";
import { NODE_TYPE_EVENT, NODE_TYPE_SPACE, ROUTE_ID } from "joynt/config";
import { useCreateWorkspace, useRootNode } from "joynt/hooks/channels";
import WorkspaceTypeSelect from "joynt/edit/WorkspaceTypeSelect";
import { ResponsiveModal } from "components/Modal";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { selectExtendableWorkspaces } from "joynt/state/selectors/joint";
import { SelectionCard } from "joynt/components/SelectionCard";
//import PropTypes from 'prop-types';

import "./style.css";

function SubworkspacePrompt({ onSubmit, ...other }) {
    return (
        <div className={"pad-side-10 pad-vert-9 rows gap-8 rows-center"}>
            <h5 className={"type-heading-sm"}>Create workspace</h5>
            <CardSelect {...other} />
            <Button onClick={onSubmit} size={"large"}>
                Continue
            </Button>
        </div>
    );
}

function CardSelect({ onSelect, value }) {
    return (
        <div className={"card-select"}>
            <SelectionCard
                onSelect={() => onSelect(false)}
                title={"New Community / Organisation / Project"}
                subtitle={"Workspace"}
                icon={"mui-home_work"}
                isSelected={!value}
            >
                This will be your main space where you can build up the
                structure with sub-workspaces, channels, rooms, chats and posts.
            </SelectionCard>
            <SelectionCard
                onSelect={() => onSelect(true)}
                title={"New Department / Team / Group project"}
                subtitle={"Sub-workspace"}
                icon={"mui-business"}
                isSelected={!!value}
            >
                This will be sub-workspace related to your main space where you
                can work in side projects, departments with particular groups of
                members.
            </SelectionCard>
        </div>
    );
}

export default function CreateWorkspace(props) {
    const { onClose } = props;
    const [id] = useRouteParam(ROUTE_ID);
    const root = useRootNode();
    const [workspaceTypeSelect, showWorkspaceTypeSelect] = useState(false);
    const [subworkspace, setSubworkspace] = useState(false);
    const { onCreate } = useCreateWorkspace("workspaces");
    const workspaces = useSelector((s) => selectExtendableWorkspaces(s));
    const parent = subworkspace ? id || workspaces[0] : root;
    const hasWorkspaces = workspaces.length > 0;

    const createEvent = useCallback(() => {
        onCreate(id || parent, { subtype: NODE_TYPE_EVENT });
    }, [onCreate, parent, id]);

    const createWorkspace = useCallback(() => {
        onCreate(parent, {
            subtype: NODE_TYPE_SPACE,
            subworkspace,
            published: !subworkspace,
        });
    }, [onCreate, parent, subworkspace]);

    const create = useCallback(
        (type) => {
            if (parent && type === NODE_TYPE_SPACE) {
                if (hasWorkspaces) {
                    showWorkspaceTypeSelect(true);
                } else {
                    createWorkspace();
                }
                return;
            }
            createEvent();
        },
        [createEvent, createWorkspace, parent, hasWorkspaces]
    );

    const workspaceOptions = hasWorkspaces
        ? [NODE_TYPE_SPACE, NODE_TYPE_EVENT]
        : [NODE_TYPE_SPACE];

    return (
        <div>
            <div className={"rows gap-lg rows-center"}>
                <WorkspaceTypeSelect
                    onChange={create}
                    options={workspaceOptions}
                />
                <Button onClick={onClose} size={"large"} color={"default"}>
                    Cancel
                </Button>
            </div>
            <ResponsiveModal
                open={workspaceTypeSelect}
                onClose={() => showWorkspaceTypeSelect(false)}
                maxWidth={"sm"}
            >
                <SubworkspacePrompt
                    value={subworkspace}
                    onSelect={setSubworkspace}
                    onSubmit={createWorkspace}
                />
            </ResponsiveModal>
        </div>
    );
}

CreateWorkspace.propTypes = {};
CreateWorkspace.defaultProps = {};
