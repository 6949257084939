import React, {useCallback} from 'react';
import Center from './Center';
import {useData} from "state/hooks";

export default function ApartmentName(props) {
    const {id, label, points, scale, isActive, onClick} = props;
    const {title} = useData(props);
    const center = Center.getCenter({imgPoints: points});
    const apartmentNameShowStyle = {
        position: 'absolute',
        left: center.x*scale + 'px',
        top: center.y*scale + 'px',
        padding: '4px 6px',
        backgroundColor: 'rgb(176, 124, 151)',
        fontSize: '12px',
        fontWeight: 'normal',
        opacity: '0.8',
        borderRadius: '25px',
        pointerEvents: onClick ? 'all': 'none',
        cursor: onClick ? 'pointer' : 'default',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '120px',
        overflow: 'hidden '
    };
    const apartmentNameHideStyle = {
        display: 'none',
    };

    const click = useCallback(() => onClick(id), [onClick, id]);

    return (
        <div onClick={onClick ? click : null } style={isActive ? apartmentNameShowStyle : apartmentNameHideStyle}>
            {title || label}
        </div>
    )
};