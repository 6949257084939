import { setIn } from "lodash-redux-immutability";
import { createReducer } from 'state/helpers/create-reducer';

const actions = {
    'AUTH.SET_API_KEY': (state, {payload}) => {
        const { api_key } = payload;
        return setIn(state, ['api_key'], api_key);
    },
    'AUTH.LOGOUT': (state) => {
        return setIn(state, ['api_key'], null);
    },
    'AUTH.ERROR': (state) => {
        return setIn(state, ['error'], true);
    }
};

export default createReducer({}, actions);