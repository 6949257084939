import React from 'react';
import PropTypes from 'prop-types';

import TextField from "components/fields/text/TextField";

export function SelectSearch(props) {

    const {
        value,
        onChange
    } = props;

    return (<TextField
        value={value.search}
        onChange={value => onChange('search', value)}
        variant={"search"}
    />);

}

SelectSearch.propTypes = {
    value: PropTypes.object
};
SelectSearch.defaultProps = {
    value: {}
};

export default SelectSearch;
