import {ContentState, convertFromHTML, EditorState} from "draft-js";

/**
 * @param text
 * @returns {EditorState}
 */

export function createEditorStateFromText(text) {
    const blocks = convertFromHTML(text);
    const state = ContentState.createFromBlockArray(
        blocks.contentBlocks,
        blocks.entityMap
    );
    return EditorState.createWithContent(state);
}