import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import IconButton from "components/IconButton";
import { useListReload, useUiFlag } from "state/hooks";
import { extendChildren, findRoot, mergeChildren } from "playground/cms/util";

import "./style.css";

function findFilter(children) {
    return React.Children.toArray(children).find(
        (child) => child.type === ListLayout.Filter
    );
}

function findChildren(children) {
    return React.Children.toArray(children).filter(
        (child) => child.type !== ListLayout.Filter
    );
}

function findLayout(children) {
    return findRoot(children, ListLayout);
}

const childUtils = {
    findLayout,
};

function filterId(type) {
    return `cms.list.${type}.filter`;
}

export function BaseListLayout(props) {
    const { children: inputChildren, type, grow, className } = props;
    const [expanded] = useUiFlag(filterId(type));

    const children = mergeChildren(inputChildren, ListLayout);

    const filter = extendChildren(ListLayout.Filter, children);
    const header = extendChildren(ListLayout.Header, children);
    const list = extendChildren(ListLayout.Content, children);
    const footer = extendChildren(ListLayout.Footer, children);

    if (filter) {
        return (
            <div
                className={cn("list-filter-container", {
                    "is-expanded": expanded,
                    grow: grow,
                })}
            >
                <div className={"list-filter"}>{expanded && filter}</div>
                <div className={cn("list-container grow rows", className)}>
                    {header}
                    {list}
                    {footer}
                </div>
            </div>
        );
    }
    return (
        <div className={cn("list-container", className)}>
            {header}
            {list}
            {footer}
        </div>
    );
}

function ListHeader(props) {
    const { children } = props;
    const icons = extendChildren(ListLayout.Header.Icons, children);
    const addon = extendChildren(ListLayout.Header.Addon, children);
    return (
        <div className={"list-header cols cols-spread"}>
            <div className={"cols"}>{icons}</div>
            {addon}
        </div>
    );
}

function Icons(props) {
    const { children } = props;
    return <div className={"list-header-icons"}>{children}</div>;
}

function HeaderAddon(props) {
    const { children } = props;
    return <div className={"list-header-addon"}>{children}</div>;
}

function Reload(props) {
    const { type, list } = props;
    const [reload] = useListReload(type, list || type);
    return <IconButton icon={"mui-refresh"} onClick={reload} />;
}

function ToggleFilter(props) {
    const { type } = props;
    const [expanded, setExpanded] = useUiFlag(filterId(type));
    return (
        <IconButton
            icon={expanded ? "mui-menu_open" : "mui-filter_list"}
            onClick={() => setExpanded(!expanded)}
        />
    );
}

function ListContent(props) {
    const { children } = props;
    return <div className={"rows grow overflow-hide"}>{children}</div>;
}

function ListFooter(props) {
    const { children } = props;
    return <div className={"list-footer cols cols-right"}>{children}</div>;
}

function ListFooterActions(props) {
    const { children } = props;
    return <div className={"list-footer-actions cols gap-sm"}>{children}</div>;
}

function ListFilter(props) {
    const { children } = props;
    return <div className={"list-filter-content"}>{children}</div>;
}

function ListForm(props) {
    const { children, open } = props;
    return (
        <div
            className={cn("list-form", {
                "is-open": open,
            })}
        >
            {children}
        </div>
    );
}

export const ListLayout = BaseListLayout;

ListLayout.Header = ListHeader;

ListLayout.Header.Reload = Reload;
ListLayout.Header.Filter = ToggleFilter;
ListLayout.Header.Icons = Icons;
ListLayout.Header.Addon = HeaderAddon;

ListLayout.Content = ListContent;
ListLayout.Footer = ListFooter;
ListLayout.Filter = ListFilter;
ListLayout.Form = ListForm;

ListLayout.Footer.Actions = ListFooterActions;

ListLayout.Children = childUtils;

ListLayout.propTypes = {};
ListLayout.defaultProps = {};

export default ListLayout;
