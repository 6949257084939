import React from "react";
//import PropTypes from 'prop-types';

import TaskDue from "joynt/components/Tasks/TaskDue";
import TaskAssignees from "joynt/components/Tasks/TaskAssignees";

import "./style.css";

function TaskProperty(props) {
    const { label, children } = props;

    return (
        <div className={"task-property"}>
            <div className={"task-property__label"}>{label}</div>
            <div className={"task-property__content"}>{children}</div>
        </div>
    );
}

export function TaskProperties(props) {
    const { id, editable } = props;
    return (
        <div className={"task-properties"}>
            <TaskProperty label={"Assigned to:"}>
                <TaskAssignees id={id} editable={editable} showLabel={true} />
            </TaskProperty>
            <TaskProperty label={"Due date:"}>
                <TaskDue id={id} editable={editable} showLabel={true} />
            </TaskProperty>
        </div>
    );
}

TaskProperties.propTypes = {};
TaskProperties.defaultProps = {};

export default TaskProperties;
