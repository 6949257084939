import React, { useCallback } from "react";
import { useApiActions, useData, useFilter } from "state/hooks";
import { useSelector } from "react-redux";
import cn from "classnames";
import Select from "components/fields/select/Select";
import { createTagCallback } from "cms/state/actions/cms";
import Button from "components/Button";
import { selectListTags } from "playground/cms/state/selectors/list";

//import PropTypes from 'prop-types';

function TagItem(props) {
    const { count, onClick, isActive } = props;
    const { title } = useData(props);

    return (
        <div
            className={cn("filter-tag-item", {
                "is-active": isActive,
            })}
            onClick={onClick}
        >
            <div className={"filter-tag-item-label"}>{title}</div>
            <div className={"filter-tag-item-count"}>{count}</div>
        </div>
    );
}

function Addon(props) {
    const { type, list, items, onChange } = props;
    const { value, onSet } = useFilter([type, list].join("/"));
    const { onCreate } = useApiActions({ onCreate: createTagCallback });
    const create = () => {
        const cb = (result) => onChange(result.id);
        onCreate(cb, { title: value.search, active: true }, list);
        onSet("search", null);
    };
    if (!value?.search) return null;

    return (
        <div>
            <Button onClick={create}>Create {value.search}</Button>
        </div>
    );
}

export function Tags(props) {
    const { type, list, name } = props;

    const listId = list || type;
    const { value, onSet } = useFilter(listId, type); //listId
    const id = ["tags", type].join(".");
    const counts = useSelector((store) => selectListTags(store, type, listId));

    const onToggle = useCallback(
        (id) => {
            onSet(name, value[name] === id ? null : id);
        },
        [onSet, name, value]
    );

    const onSetTag = useCallback((v) => onSet(name, v), [onSet, name]);

    return (
        <>
            <Select
                label={"Tag"}
                id={id}
                list={id}
                type={"cms.tags"}
                url={name === "cms_tags" ? "cms/tags/cms" : "cms/tags"}
                value={value[name]}
                nullable={true}
                onChange={onSetTag}
                variant={"dense"}
                SelectListAddon={Addon}
            />
            <div className={"rows overflow-scroll"}>
                {counts &&
                    counts.map((tag) => (
                        <TagItem
                            kye={tag.id}
                            type={"cms.tags"}
                            id={tag.id}
                            count={tag.count}
                            onClick={() => onToggle(tag.id)}
                            isActive={value[name] === tag.id}
                        />
                    ))}
            </div>
        </>
    );
}

Tags.propTypes = {};

Tags.defaultProps = {
    name: "tags",
};

export default Tags;
