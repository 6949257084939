import React from 'react';

//import PropTypes from 'prop-types';

import "./style.css";

export function WitnessDashboard(props) {

    const {children} = props;

    return (<div className={"witness-dashboard"}>
        {children}
    </div>);

}

WitnessDashboard.propTypes = {};
WitnessDashboard.defaultProps = {};

export default WitnessDashboard;
