import {useEffect, useState} from "react";
import {notify} from "state/actions/ui";
import {useActions} from "state/hooks";
import env from "util/env";
import useEcho from "joynt/websockets/hooks/useEcho";

export default function usePrivateChannel(channelId) {
    const [subscription, setSubscription] = useState(null);
    const {onNotify} = useActions({onNotify: notify});
    const service = env('SERVICE_ID');
    const channel = channelId ? `${channelId}@${service}` : null;
    const echo = useEcho();

    useEffect(() => {
        let sub = null;
        if (echo && channel) {
            console.log(`Subscribe to ${channel}`);
            sub = echo.private(channel);
            sub.listen('.subscription_error', code => {
                onNotify(`WS auth error ${channel} got ${code}`, 'error');
            });
            setSubscription(sub);
        }
        return () => {
            if (echo && channel) {
                console.log(`Unsubscribe from ${channel}`);
                echo.leave(channel);
                setSubscription(null);
            }
        }
    }, [echo, channel, setSubscription, onNotify]);

    return subscription;
}