import React from "react";
import { connect } from "react-redux";
import {
    stylesHelper,
    STYLES_NS,
    selectClasses,
} from "cms/styles/legacy/selectors";
import { changed } from "util/changed";

export const StyleLegacyContext = React.createContext(null);

const mapState = (store, props) => ({
    stylesData: store.data[STYLES_NS],
    stylesChanges: store.changes[STYLES_NS],
    s: stylesHelper(store),
});

const mapDispatch = {};

class StylesProviderRoot extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = this.buildStyles();
    }

    componentDidMount() {
        this.updateStyles();
    }

    componentDidUpdate(prevProps) {
        if (changed(["stylesData", "stylesChanges"], prevProps, this.props)) {
            setTimeout(() => this.updateStyles(), 1);
            //this.updateStyles();
        }
    }

    updateStyles() {
        // console.log("buildStyles");
        this.setState(this.buildStyles());
    }

    buildStyles() {
        const { stylesData, stylesChanges } = this.props;
        const stylesState = {
            data: {},
            changes: {},
        };
        stylesState.data[STYLES_NS] = stylesData;
        stylesState.changes[STYLES_NS] = stylesChanges;
        return { s: stylesHelper(stylesState) };
    }

    render() {
        const { s } = this.state;
        return (
            <StyleLegacyContext.Provider value={s}>
                {this.props.children}
            </StyleLegacyContext.Provider>
        );
    }
}

export const StylesProvider = connect(
    mapState,
    mapDispatch
)(StylesProviderRoot);

export const styled = (Decorated) => {
    class Styles extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = selectClasses(null, props);
        }
        componentDidUpdate(prevProps) {
            if (
                changed(
                    ["s", "style", "styles", "breakpoints"],
                    prevProps,
                    this.props
                )
            ) {
                this.setState(selectClasses(null, this.props));
            }
        }
        render() {
            const { s, forwardedRef, ...other } = this.props;
            const styleProps = this.state || {};
            const passRef = forwardedRef ? forwardedRef : null;
            return (
                <StyleLegacyContext.Provider value={styleProps.s}>
                    <Decorated
                        {...other}
                        classes={styleProps.classes}
                        attributes={styleProps.attributes}
                        ref={passRef}
                    />
                </StyleLegacyContext.Provider>
            );
        }
    }
    const ConnectedDecorator = Styles;
    const StyledDecorator = React.forwardRef((props, ref) => {
        return (
            <StyleLegacyContext.Consumer>
                {(s) => (
                    <ConnectedDecorator s={s} {...props} forwardedRef={ref} />
                )}
            </StyleLegacyContext.Consumer>
        );
    });
    StyledDecorator.autoFillProps = Decorated.autoFillProps;
    return StyledDecorator;
};
