import React from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { order } from './util/Geometry';
import Button from "components/Button";

const itemStyle = {
    padding: '5px', backgroundColor: 'white', textAlign: 'center',
    borderBottom: '1px solid', borderLeft: '1px solid', borderRight: '1px solid'
};


const SortableItem = SortableElement(({value}) =>
    <div style={itemStyle}>{value}</div>);

const SortableList = SortableContainer(({items}) => {
    return <div>
        {items.map((value, index) => (
            <SortableItem key={index} index={index} value={index + 1}/>
        ))}
    </div>;
});

export default class PointsList extends React.Component {

    onSortEnd = ({oldIndex, newIndex}) => {
        const imgPoints = this.props.points;
        const sortedImgPoints = arrayMove(imgPoints, oldIndex, newIndex);
        this.props.sort(sortedImgPoints);

    };

    handleOnClickSort = () => {
        this.props.sort(order(this.props.points));
    }

    render() {
        const {points, onClose} = this.props;

        return (
            <div>
                <SortableList
                    items={points}
                    onSortEnd={this.onSortEnd}
                    autoScrollBodyContent={true}
                />
                <div className={'cols pad-sm cols-center gap-xs'}>
                    <Button color={'default'} onClick={this.handleOnClickSort}>
                        Auto
                    </Button>
                    <Button onClick={onClose}>
                        OK
                    </Button>
                </div>
            </div>
        )
    }
}