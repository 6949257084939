import React from 'react';
import PropTypes from 'prop-types';
import StageSession from "./StageSession";
import {useNodeData} from "joynt/hooks";
import {useSession, useSessionState} from "joynt/hooks/sessions";

export function StageSessionContainer(props) {

    const {id} = props;

    const {
        name,
        event_start,
        event_started,
        can_edit
    } = useNodeData(id);

    const {
        onJoinSession,
        onStartSession,
        onEndSession,
        isLive,
        isConnecting,
        isConnected,
        isPending
    } = useSessionState(id);

    const {
        onShow,
        onEdit
    } = useSession(id);

    return (<StageSession
        {...props}
        name={name}
        isLive={isLive}
        isConnecting={isConnecting}
        isPending={isPending}
        isConnected={isConnected}
        onJoin={onJoinSession}
        onStart={onStartSession}
        onEnd={onEndSession}
        start={event_start}
        started={event_started}
        onShow={onShow}
        onEdit={onEdit}
        canEdit={can_edit}
    />);

}

StageSessionContainer.propTypes = {
    id: PropTypes.string
};
StageSessionContainer.defaultProps = {};

export default StageSessionContainer;