import React, {useEffect, useState} from 'react';
import cn from "classnames";
import {useNetworkState} from "joynt/network-state";
//import PropTypes from 'prop-types';

const descriptions = {
    good: 'Good connection',
    degraded: 'Weak connection',
    bad: 'Bad connection',
    offline: 'No internet connection'
}

export function useNetworkStateFlash() {
    const [show, setShow] = useState(false);
    const {quality: {session}} = useNetworkState();

    useEffect(() => {
        setShow(session);
        setTimeout(() => setShow(false), 3000);
    }, [session]);

    return show;
}

export default function NetworkState({show}) {

    const {quality: {session, uplink, downlink}} = useNetworkState();
    const tooltip = `Up: ${uplink} / Down: ${downlink}`;
    const showStates = ['good', 'degraded'];
    const visible = session && showStates.indexOf(session) > -1 && show;

    return (<div className={cn('session-network-state', {
        [`is-visible`]: visible
    })} title={tooltip}>
        <div className={cn('network-status', {
            [`network-status--${session}`]: !!session
        })}>
            {descriptions[session]}
        </div>
    </div>);

}

NetworkState.propTypes = {};
NetworkState.defaultProps = {};
