import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import { useNodeExists } from "joynt/hooks";
import NoAccess from "joynt/NoAccess";

import { types as viewTypes } from "joynt/views";
import { useTracking } from "joynt/tracking";

import Slide from "@material-ui/core/Slide";

const classes = {
    feed: "feed",
    chat: "padded",
    "workspace-feed": "padded",
    space: "mobile-wide",
    "networking-session": "full mobile-wide dark-mode bg",
    webinar: "mobile-wide dark-mode bg",
    "webinar-series-overview": "full mobile-wide dark-mode bg",
    "webinar-series": "full mobile-wide dark-mode bg",
};

const nativeViews = ["inbox"];

export default function ChannelViewContainer(props) {
    return <ChannelView {...props} />;
}

ChannelViewContainer.defaultProps = {
    types: viewTypes,
};

function ChannelView(props) {
    const {
        channel,
        subchannel,
        view,
        id,
        types,
        slideFrom,
        transition,
        ...other
    } = props;

    let nodeId = channel || id;

    const exists = useNodeExists(channel);

    useTracking({ event: `view:${view}`, trackView: true });

    if (channel && !exists) return <NoAccess />;

    const ViewComponent = types[view];

    if (!ViewComponent) return <div>No view for type {view}</div>;

    const viewProps = {
        id: nodeId,
        channel: subchannel,
        ...other,
    };

    if (view === "meeting") viewProps.isEvent = true;

    if (nativeViews.indexOf(view) > -1)
        return (
            <ViewComponent
                {...viewProps}
                className={cn("view", classes[view])}
            />
        );

    if (transition)
        return (
            <Slide
                direction={slideFrom}
                in={true}
                appear={slideFrom}
                key={view}
            >
                <div className={cn("view", classes[view])}>
                    <ViewComponent {...viewProps} />
                </div>
            </Slide>
        );

    return (
        <div className={cn("view", classes[view])}>
            <ViewComponent {...viewProps} />
        </div>
    );
}

ChannelView.propTypes = {
    id: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
};
