import Geometry from './util/Geometry';

export default class Center {
    static getCenter(room) {

        let points = room.imgPoints.map(function (point) {
            return [point.x * 1, point.y * 1];
        });

        let geometry = new Geometry(points);

        let center = geometry.center();

        return center;
    }
}