import React from 'react';
import Day from "./Day";
//import PropTypes from 'prop-types';

export default function DaysSelect({days, onClick, value}) {
    return days.map(day => <Day
        key={day.day}
        day={day.day}
        onClick={onClick}
        selected={day.day === value}
    />);
}

DaysSelect.propTypes = {};
DaysSelect.defaultProps = {};
