import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import useMeeting from "joynt/meeting/hooks/useMeeting";

import * as config from "joynt/meeting/agora-ng/config";
import { useMemo } from "react";
import {
    EVENT_ROLE_RECORDING,
    FLAG_SESSION_FLOATING,
    FLAG_SESSION_OVERRIDES,
    FLAG_SESSION_STAGE_LAYOUT,
} from "joynt/config";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";
import { useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectFlag } from "state/selectors/ui";
import useScreenSize from "util/hooks/useScreenSize";
import { useUiFlag } from "state/hooks";
import { SESSION_LAYOUT } from "joynt/meeting/agora-ng/config";

const options = [
    "SHOW_SESSION_TOOLBAR",
    "SHOW_TILES_FOR_DISCONNECTED_USERS",
    "MAX_TILES_FOCUSED",
    "MAX_TILES_GRID",
    "MAX_TILES_MINIMIZED",
    "QUALITY_OPTIMIZE_ABOVE",
    "PRIORITIZE_ACTIVE_STREAMS",
    "SHOW_TILES_FOR_DISCONNECTED_USERS",
    "SESSION_LAYOUT",
    "SESSION_LAYOUT_MINIMIZED",
    "SHOW_AUDIO_STREAMS",
    "TRACK_VOLUME_SPEAKING_THRESHOLD",
    "TRACK_VOLUME_SPEAKING_TIMEOUT",
    "ACTIVE_SPEAKER_THRESHOLD",
];

function filterOverridable(overrides, options) {
    let filtered = {};
    Object.keys(overrides).forEach((k) => {
        if (
            options.includes(k) &&
            ![null, undefined, ""].includes(overrides[k])
        ) {
            filtered[k] = overrides[k];
        }
    });
    return filtered;
}

const nullObj = {};

export function useSessionConfig(channel, includeOverrides) {
    const [isMinimized] = useUiFlag(FLAG_SESSION_FLOATING);

    const [layout] = useUiFlag(FLAG_SESSION_STAGE_LAYOUT, SESSION_LAYOUT);

    const { role, policy } = useMeeting();
    const { data: flowConfig } = useSessionFlow();
    const { dev_settings } = useNodeData(channel);
    const { isMobile } = useScreenSize();

    const localOverrides = useSelector((s) =>
        selectFlag(s, FLAG_SESSION_OVERRIDES)
    );

    const overrides =
        includeOverrides &&
        dev_settings &&
        (dev_settings?.enabled || localOverrides)
            ? dev_settings
            : nullObj;

    const defaults = useMemo(() => {
        let effectiveLayout = isMinimized
            ? config.SESSION_LAYOUT_MINIMIZED
            : layout;

        return {
            SESSION_LAYOUT: effectiveLayout,
            SHOW_SESSION_TOOLBAR: role !== EVENT_ROLE_RECORDING,
            SHOW_TILES_FOR_DISCONNECTED_USERS:
                policy === "meeting" && role !== EVENT_ROLE_RECORDING,
            PRIORITIZE_ACTIVE_STREAMS: effectiveLayout !== "grid",
            SHOW_AUDIO_STREAMS: false,
        };
    }, [isMinimized, layout, role, policy]);

    return useMemo(() => {
        let withOverrides = {
            ...config,
            ...defaults,
            ...filterOverridable(flowConfig, options),
            ...filterOverridable(overrides, options),
        };
        if (isMobile && overrides && overrides.mobile) {
            withOverrides = {
                ...withOverrides,
                ...filterOverridable(overrides.mobile, options),
            };
        }
        return withOverrides;
    }, [defaults, flowConfig, overrides, isMobile]);
}
