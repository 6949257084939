import {useEffect, useState} from "react";

function loadScript(url, id, callback) {
    const scriptSrc = url;
    const scriptId = id;

    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.id = scriptId;

        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        }
    }

    if (existingScript && callback) callback();
}

export default function useLoadScript(url, id) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadScript(url,id, ()=>setLoaded(true));
    });

    return loaded;
}