import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import OfferForm from "./OfferForm";

export function Offers(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"offer"} label={"Oferta"}>
                        <OfferForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Offers.propTypes = {};

Offers.defaultProps = {};

export default Offers;
