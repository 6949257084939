import React from 'react';
import PropTypes from 'prop-types';

import Files from "./Files";
import FieldContainer from "containers/Field";
import {Field as FilesField} from "joynt/components/Files";
import AggregateRoot from "containers/AggregateRoot";
import UploadPlaceholder from "joynt/components/Files/UploadPlaceholder";

export function FilesContainer(props) {

    const {id, onUpload, showPlaceholder, editable} = props;

    if (!id && onUpload && showPlaceholder)
        return <UploadPlaceholder onUpload={onUpload} />

    return <Files>
        <AggregateRoot type={"db.posts"} id={id} disableSubmit={true}>
            <FieldContainer id={'media'} readOnly={!editable}>
                {FilesField}
            </FieldContainer>
        </AggregateRoot>
    </Files>;

}

FilesContainer.propTypes = {
    onUpload: PropTypes.func,
    showPlaceholder: PropTypes.bool,
    editable: PropTypes.bool
};
FilesContainer.defaultProps = {
    showPlaceholder: false,
    editable: false
};

export default FilesContainer;