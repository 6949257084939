import React from "react";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
//import PropTypes from 'prop-types';

export function AdvancedSettings(props) {
    const { children } = props;

    return (
        <FieldGroup label={"Advanced settings"} collapsible={true}>
            {children}
            <Field id={"position"} label={"Position"} />
            <Field id={"access_type"} label={"Access type"} />
        </FieldGroup>
    );
}

AdvancedSettings.propTypes = {};
AdvancedSettings.defaultProps = {};
