import React from 'react';
import cn from 'classnames';
//import PropTypes from 'prop-types';

import List from 'containers/List';
import {ListIterator} from "cms/components/List";

export class FormSelectOption extends React.PureComponent {
    render() {
        const {option_value, option_label} = this.props;
        return (<option value={option_value}>
            {option_label}
        </option>);
    }
}

FormSelectOption.autoFillProps = [
    'option_value',
    'option_label',
    'placeholder',
    'nullable'
];

export default class FormSelect extends React.Component {

    render() {
        const { components, items, classes, nullable, placeholder } = this.props;
        return (<select className={cn('select', classes.input)}>
            { nullable ? <option value="">{placeholder}</option> : null }
            <List
                type={'cms.items'}
                items={items}
                components={components}
                itemType={'template'}
                embed={true}
                Iterator={ListIterator} />
        </select>);
    }

}

FormSelect.autoFillProps = [
    'components',
    'items'
];

FormSelect.propTypes = {};