import { useCallback, useEffect, useState } from "react";

export default function useKeyboardListFocus(onEnter, onHide, items) {
    const [focus, setFocus] = useState(items[0]);

    const handleKey = useCallback(
        (event) => {
            const { key } = event;

            const handlers = {
                ArrowDown: () =>
                    setFocus(
                        items[
                            Math.min(items.indexOf(focus) + 1, items.length - 1)
                        ]
                    ),
                ArrowUp: () =>
                    setFocus(items[Math.max(items.indexOf(focus) - 1, 0)]),
                Enter: () => onEnter(focus, event),
                Escape: () => onHide(),
            };

            if (!handlers[key]) return;

            event.preventDefault();
            event.stopPropagation();

            handlers[key]();
        },
        [items, focus, onEnter, onHide]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKey);
        return () => {
            window.removeEventListener("keydown", handleKey);
        };
    }, [handleKey]);

    useEffect(() => {
        if (items.indexOf(focus) === -1) setFocus(items[0]);
    }, [focus, setFocus, items]);

    return [focus, setFocus];
}
