import React from "react";
import cn from "classnames";
import { useData, useFetch, useList } from "state/hooks";
import { useSelector } from "react-redux";
import { selectListTags } from "playground/cms/state/selectors/list";

//import PropTypes from 'prop-types';
import "./style.css";
import { getLibraryState } from "playground/cms/state/selectors/library";

function TagItem(props) {
    const { onClick, count, selected, children } = props;
    const { title } = useData({ type: "cms.tags", id: props.id });
    return (
        <div
            className={cn("tags-nav-item", {
                "is-selected": selected,
            })}
            onClick={onClick}
        >
            <div>{title || children}</div>
            {count && <div className={"tags-nav-item-count"}>{count}</div>}
        </div>
    );
}

export function TagsNav(props) {
    const { onChange, value, service } = props;
    const type = "cms.tags";
    const list = "tags.cms.sections";
    useFetch({
        type,
        list,
        url: "cms/tags/cms",
    });
    const { items: tags } = useList(type, list);

    const state = getLibraryState(service);

    const counts = useSelector((store) => {
        const counts = selectListTags(state, props.type, props.list);
        return counts.reduce((acc, tag) => {
            acc[tag.id] = tag.count;
            return acc;
        }, {});
    });
    const used = tags.filter((t) => {
        return counts[t] > 0 || t === value;
    });
    return (
        <div className={"tags-nav"}>
            <TagItem onClick={() => onChange(null)} selected={!value}>
                All
            </TagItem>
            {used.map((tag) => (
                <TagItem
                    key={tag}
                    id={tag}
                    count={counts[tag]}
                    onClick={() => onChange(tag)}
                    selected={value === tag}
                />
            ))}
        </div>
    );
}

TagsNav.propTypes = {};

TagsNav.defaultProps = {};

export default TagsNav;
