import React from 'react';
import {ParticipantList} from "joynt/participants";

//import PropTypes from 'prop-types';

export function People(props) {

    const {workspace, id} = props;

    return (
        <ParticipantList
            parent={workspace}
            id={id}
        />
    );

}

People.propTypes = {};
People.defaultProps = {};

export default People;
