import React from "react";
import ConfirmDialog from "joynt/components/ConfirmDialog";
import FormActions from "joynt/components/Form/FormActions";
import { HelperText } from "joynt/components/HelperText";

//import PropTypes from 'prop-types';

export function CreatePublishedSession(props) {
    const { response } = props;

    const submitPublished = () => response({ hidden: false });
    const submitDraft = () => response({ hidden: true });

    return (
        <ConfirmDialog
            {...props}
            title={"Publishing session"}
            actions={
                <FormActions
                    onSubmit={submitPublished}
                    onReject={submitDraft}
                    submitLabel={"Publish now"}
                    rejectLabel={"Save as draft"}
                />
            }
        >
            <div className={"rows rows-center gap-sm"}>
                <h3>Do you want to publish your session now?</h3>
                <HelperText loudness={2}>
                    Publishing your session will notify people in this space or
                    with access to this session.
                </HelperText>
            </div>
            <div className={"rows rows-center gap-sm"}>
                <h3>
                    You can save your scheduled session as draft
                    <br />
                    and publish it later
                </h3>
                <HelperText loudness={2}>
                    Only admins will see unpublished sessions
                </HelperText>
            </div>
        </ConfirmDialog>
    );
}

CreatePublishedSession.propTypes = {};
CreatePublishedSession.defaultProps = {};

export default CreatePublishedSession;
