import { AtomicBlockUtils, EditorState } from "draft-js";
import { getCurrentBlock } from "joynt/components/ContentEditor/util/getCurrentBlock";

export function insertCustomAtomicBlock(editorState, blockTypeId, data = {}) {
    let nextState = editorState;

    const contentState = nextState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
        blockTypeId,
        "IMMUTABLE",
        { type: blockTypeId, ...data }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    nextState = EditorState.set(nextState, {
        currentContent: contentStateWithEntity,
    });

    nextState = AtomicBlockUtils.insertAtomicBlock(nextState, entityKey, " ");

    const block = getCurrentBlock(nextState);
    console.log("current block", block);

    return nextState;
}
