import React from "react";

import ContextMenu from "components/ContextMenu";
import MessageContextMenu from "joynt/components/messages/message/MessageContextMenu";
import IconButton from "components/IconButton";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

import "./style.css";

export function MessageFooterExtended(props) {
    const { reactions, isOwn, id, addReactions } = props;
    return (
        <div className={"message__footer message__footer--extended"}>
            {reactions && (
                <div className={"message__reactions"}>{reactions}</div>
            )}
            <div className={"message__footer__info"}>
                {addReactions}
                {isOwn && (
                    <div className={"message__context-menu"}>
                        <ContextMenu
                            id={id}
                            RenderMenuItems={MessageContextMenu}
                        >
                            {({ onMenuOpen }) => (
                                <IconButton
                                    icon={"mui-more_vert"}
                                    onClick={onMenuOpen}
                                    size={"small"}
                                />
                            )}
                        </ContextMenu>
                    </div>
                )}
            </div>
        </div>
    );
}

MessageFooterExtended.propTypes = {
    id: MessagePropTypes.id,
    reactions: MessagePropTypes.reactions,
    isOwn: MessagePropTypes.isOwn,
    addReactions: MessagePropTypes.addReactions,
};

export default MessageFooterExtended;
