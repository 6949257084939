import React, { useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

function BlockTypeIcon({ icon }) {
    if (icon && icon.indexOf("mui-") > -1) return <Icon>{icon}</Icon>;

    return (
        <div className={`block-type-icon block-type-icon--${icon}`}>
            {icon.toUpperCase()}
        </div>
    );
}

export function BlockTypeItem(props) {
    const {
        id,
        name,
        icon,
        onClick,
        onMouseEnter,
        onFitToView,
        isFocused,
        isKeyboardFocused,
    } = props;
    const ref = React.useRef();

    useEffect(() => {
        if (ref.current && isKeyboardFocused) {
            onFitToView(ref.current);
        }
    }, [ref, onFitToView, isKeyboardFocused]);

    return (
        <div
            ref={ref}
            onMouseDown={onClick}
            onMouseEnter={onMouseEnter}
            className={cn("block-type-item", {
                "is-focused": isFocused,
            })}
        >
            <div className={"block-type-item__icon"}>
                {icon && <BlockTypeIcon icon={icon} />}
            </div>
            <div className={"block-type-item__label"}>{name || id}</div>
        </div>
    );
}

BlockTypeItem.propTypes = {
    id: PropTypes.string,
    isFocused: PropTypes.bool,
};

BlockTypeItem.defaultProps = {
    isFocused: false,
};

export default BlockTypeItem;
