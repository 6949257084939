import React from 'react';
import HourSelectMenuItem from "./HourSelectMenuItem";
import {applyMinuteInterval} from "joynt/components/DateTimePicker/util";

//import PropTypes from 'prop-types';

function generateHourSelect(start, interval, max) {
    const startDate = applyMinuteInterval(new Date(start*1000));
    const options = [];
    const intervals = (24*60)/interval;
    let date = startDate;
    for (let i = 0; i < intervals; i++) {
        if (!max || date.getTime()/1000 < max) {
            options.push(date.getTime() / 1000);
            date.setMinutes(date.getMinutes() + interval);
        }
    }
    return options;
}

export function HourSelectMenu(props) {
    const {
        start,
        value,
        format,
        minuteInterval,
        onChange,
        max
    } = props;

    const options = generateHourSelect(start, minuteInterval, max);

    return <div className={"select-menu"}>{options.map(timestamp => {
        return <HourSelectMenuItem
            key={timestamp}
            value={timestamp}
            format={format}
            onClick={onChange}
            isSelected={value === timestamp}
        />
    })}
    </div>;
}

HourSelectMenu.propTypes = {};
HourSelectMenu.defaultProps = {};

export default HourSelectMenu;
