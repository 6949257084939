import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import Session from "joynt/components/UtilityNav/Session";

import "./style.css";

export function SessionLayout(props) {
    const { id, header, isExpanded, isDarkTheme, leftInfo, navOpen, children } =
        props;

    return (
        <div
            className={cn("session-layout", {
                "is-expanded": isExpanded,
                "dark-mode": isDarkTheme,
                "nav-open": navOpen,
            })}
        >
            {header}
            <div className={"session-view"}>
                {leftInfo}
                {children || null}
            </div>
            <Session id={id} />
        </div>
    );
}

SessionLayout.propTypes = {
    isExpanded: PropTypes.bool,
    isDarkTheme: PropTypes.bool,
};
SessionLayout.defaultProps = {
    isExpanded: false,
    isDarkTheme: true,
};

export default SessionLayout;
