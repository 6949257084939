import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import CameraPreview from "joynt/meeting/settings/CameraPreview";
import DeviceSelection from "joynt/meeting/settings/DeviceSelection";
import Layout from "./Layout";
import DeviceAccessGate from "joynt/meeting/settings/DeviceAccessGate";

import { useMediaDevices } from "joynt/meeting/devices";
import MicrophoneTest from "./MicrophoneTest";
import MicrophoneLevel from "./MicrophoneLevel";

import "./style.css";

function Settings({ onSubmit }) {
    const { microphoneAvailable } = useMediaDevices();

    return (
        <Layout
            title={"Camera and mic settings"}
            description={
                "Please test your camera and mic before joining the meeting"
            }
        >
            <CameraPreview showPreview={true} />
            <DeviceSelection />
            {microphoneAvailable && <MicrophoneLevel />}
            {microphoneAvailable && <MicrophoneTest />}
            <div className={"cols cols-center"}>
                <Button size={"large"} onClick={onSubmit}>
                    Confirm settings
                </Button>
            </div>
        </Layout>
    );
}

export default function DeviceSettings(props) {
    const { onClose, onSubmit } = props;

    return (
        <DeviceAccessGate onClose={onClose}>
            <Settings onSubmit={onSubmit} />
        </DeviceAccessGate>
    );
}

DeviceSettings.defaultProps = {
    selectedDevices: {},
    availableDevices: {},
    confirmed: false,
};
