import React from "react";
import { useNotificationStatus } from "joynt/hooks/notifications";
import { useNotificationStatusOverlay } from "joynt/notifications/NotificationStatusOverlay";
import NotificationStatus from "./NotificationStatus";
import PropTypes from "prop-types";

export default function NotificationStatusContainer(props) {
    const { id } = props;
    const status = useNotificationStatus(id) || {};
    const { onShow, onHide } = useNotificationStatusOverlay();
    const recipients = status.recipients || {};
    const count = Object.keys(recipients).length;
    const recipientsWhoHaveSeen = Object.keys(recipients).filter(
        (recipient) => {
            return recipients[recipient]?.seen;
        }
    );

    const seenCount = recipientsWhoHaveSeen.length;

    return (
        <NotificationStatus
            {...{ count, seenCount, onHide, onShow, recipientsWhoHaveSeen }}
            {...props}
        />
    );
}

NotificationStatusContainer.propTypes = {
    id: PropTypes.string,
};
