import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {Icon} from "@material-ui/core";

import 'css/controls/input-checker.css';

export default class Check extends React.PureComponent {

    render() {
        const { className, value, onChange } = this.props;

        return (<div
            className={classNames(className, {
                'input-checker': true,
                'checked': !!value,
            })}
            onClick={onChange}
        >
            <Icon fontSize={'inherit'}>check</Icon>
        </div>);
    }

}

Check.propTypes = {
    onChange: PropTypes.func
};