import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';
import Button from "components/Button";
import {useApiActions, useData} from "state/hooks";
import {initOAuthFlow} from "state/actions/api";
import {isPending} from "state/selectors/ui";
import {useSelector} from "react-redux";
import Preloader from "components/Preloader";

export default function OAuthField(props) {

    const {entityType, entityId} = props;
    const pending = useSelector(s=>isPending(s, 'oauth-init'));
    const {id, has_access_token} = useData({type: entityType, id: entityId});
    const {
        onAuthorize
    } = useApiActions({
        onAuthorize: initOAuthFlow
    });

    const authUrl = `cms/integrations/${id}/oauth`;

    const openAuth = useCallback(() => {
        onAuthorize(authUrl);
    }, [onAuthorize, authUrl]);

    return (<div className={'rows gap-sm'}>
        <Preloader visible={pending} />
        <div>{has_access_token ? 'has token' : 'no token'}</div>
        <Button onClick={openAuth}>
            {has_access_token ? 'Authorize again' : 'Authorize'}
        </Button>
    </div>);

}

OAuthField.propTypes = {};
OAuthField.defaultProps = {};
