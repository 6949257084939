import React, { useState } from "react";

import uuid from "uuid/v4";
import { pathChange, pathDetach } from "state/actions/data";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "components/Icon";
import {
    copyComponent,
    createComponent,
    importFromLibrary,
    pasteComponent,
    saveToLibrary,
} from "cms/state/actions/cms";
import { NS_COMPONENTS } from "cms/components/types";
import { useActions, useApiActions, useData } from "state/hooks";
import SelectComponentType from "./../SelectComponentType";
import { typeToField } from "cms/state/selectors/cms";
import SelectList from "playground/theme/StyleEditor/SelectList";
import SelectListItem from "playground/theme/StyleEditor/SelectListItem";
import { useSelector } from "react-redux";

function ComponentContextMenu(props) {
    const { rootType, rootId, type, id, onClose, onEdit, onFocus } = props;

    const data = useData({ type: NS_COMPONENTS, id });
    const parent = data.__parent || {};
    const path =
        parent.id === rootId
            ? [rootType, parent.id, parent.key].join("/")
            : [NS_COMPONENTS, parent.id, "components"].join("/");

    const clipboard = useSelector((state) => !!state.ui.clipboard);
    const { disabled } = data;

    const childrenPath = [type, id, typeToField(type)];

    const { onExport, onImport } = useApiActions({
        onExport: saveToLibrary,
        onImport: importFromLibrary,
    });
    const { onChange, onDetach, onCopy, onPaste, onCreate } = useActions({
        onChange: pathChange,
        onDetach: pathDetach,
        onCopy: copyComponent,
        onPaste: pasteComponent,
        onCreate: createComponent,
    });

    const [create, setCreate] = useState(false);

    const handleDetach = () => {
        onDetach(path.split("/"), id);
        onClose();
    };

    const handleToggleDisabled = () => {
        onChange([NS_COMPONENTS, id, "disabled"], !disabled);
        onClose();
    };

    const handleCopy = () => {
        onCopy(NS_COMPONENTS, id);
    };

    const handlePaste = () => {
        const path = [type, id, typeToField(type)].join("/");
        onPaste(path, null, null);
    };

    const handleExport = () => {
        onExport(NS_COMPONENTS, id);
    };

    const handleImport = (type, libraryId) => {
        onImport(type, libraryId, NS_COMPONENTS, id);
    };

    const handleCreate = (newComponentType) => {
        const newId = uuid();
        onCreate(type, id, typeToField(type), {
            id: newId,
            element_type: newComponentType,
        });
        onFocus(NS_COMPONENTS, newId);
        onClose();
    };

    if (create) {
        return (
            <>
                <MenuItem dense={true} onClick={() => setCreate(false)}>
                    Cancel
                </MenuItem>
                <SelectComponentType
                    onSelect={handleCreate}
                    onImport={handleImport}
                />
            </>
        );
    }

    if (type === "cms.sections")
        return (
            <SelectList onClick={onClose}>
                <SelectListItem onClick={() => onEdit(true)}>
                    Edit
                </SelectListItem>
                <SelectListItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setCreate(true);
                    }}
                >
                    Create
                </SelectListItem>
            </SelectList>
        );

    return (
        <SelectList onClick={onClose}>
            <SelectListItem onClick={() => onEdit(true)}>Edit</SelectListItem>
            <SelectListItem
                onClick={(e) => {
                    e.stopPropagation();
                    setCreate(true);
                }}
            >
                Create
            </SelectListItem>
            <SelectList.Divider />
            <SelectListItem
                onClick={handleDetach}
                icon={<Icon>mui-delete</Icon>}
            >
                Delete
            </SelectListItem>
            <SelectListItem
                onClick={handleToggleDisabled}
                icon={
                    disabled ? (
                        <Icon>mui-visibility</Icon>
                    ) : (
                        <Icon>mui-visibility_off</Icon>
                    )
                }
            >
                {disabled ? "Activate" : "Deactivate"}
            </SelectListItem>
            <SelectList.Divider />
            <SelectListItem onClick={handleCopy} icon={<Icon>mui-copy</Icon>}>
                Copy
            </SelectListItem>
            {clipboard && (
                <SelectListItem
                    onClick={handlePaste}
                    icon={<Icon>mui-paste</Icon>}
                >
                    Paste
                </SelectListItem>
            )}
            <SelectList.Divider />
            <SelectListItem onClick={() => null}>
                Convert to symbol
            </SelectListItem>
            <SelectListItem onClick={handleExport}>
                Save to library
            </SelectListItem>
            <SelectListItem onClick={null}>
                Wrap / Unwrap / Unlink
            </SelectListItem>
            <SelectListItem onClick={null}>Duplicate / Cut</SelectListItem>
        </SelectList>
    );
}

export default ComponentContextMenu;
