import React from "react";
import {useNode} from "joynt/hooks";
import {types as nodeTypes} from "joynt/config/types";
import {SpaceAvatar} from "joynt/components/SpaceAvatar";
//import PropTypes from "prop-types";

export function NodeAvatar(props) {
    const { id, ...other } = props;
    const { icon, workspace_abbr, name, color, subtype, workspace } = useNode(
        id
    );

    let nodeIcon = icon;

    if (!nodeIcon && !workspace) nodeIcon = nodeTypes[subtype]?.icon;

    return (
        <SpaceAvatar
            icon={nodeIcon}
            name={name}
            abbr={workspace_abbr}
            color={color}
            {...other}
        />
    );
}

NodeAvatar.defaultProps = {};
NodeAvatar.propTypes = {};

export default NodeAvatar;