import React from 'react';
import cn from "classnames";
import PropTypes from 'prop-types';

import Icon from "components/Icon";
import {LiveNowBadge} from "joynt/components/LiveNow";

import "./style.css";

export function MainStageEvent(props) {

    const {
        isActive,
        isLive,
        onClick,
        onMouseEnter,
        onMouseLeave,
        timer,
        name,
        label
    } = props;

    const className = cn('sidebar-stage-item',{
        'is-active': isActive,
        'is-live': isLive
    });

    const events = {onClick, onMouseEnter, onMouseLeave};

    return (<div
        className={className}
        {...events}
    >
        <div className={"sidebar-stage-item__header"}>
            <div className={"sidebar-stage-item__name"}>
                {isLive
                    ? <LiveNowBadge animate={true} />
                    : <Icon>mui-subscriptions</Icon> }
                <div className={"sidebar-stage-item__stage_name"}>
                    {label}
                </div>
            </div>
            <div className={"sidebar-stage-item__timer"}>
                {isLive ? timer : "Up next"}
            </div>
        </div>
        <div className={"sidebar-stage-item__event_name"}>
            {name}
        </div>
    </div>);

}

MainStageEvent.propTypes = {
    isActive: PropTypes.bool,
    isLive: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    name: PropTypes.string,
    timer: PropTypes.element,
    label: PropTypes.string
};
MainStageEvent.defaultProps = {
    isActive: false,
    isLive: false,
    label: "Workspace stage"
};

export default MainStageEvent;
