import React from 'react';
import {connect} from 'react-redux';
import {selectRouteParam} from "state/selectors/router";
import Dialog from "@material-ui/core/Dialog";
import {dragSectionPromptCancel, dragSectionPromptSelect} from "cms/state/actions/cms";
import {selectEntity} from "state/selectors/data";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ListItemText} from "@material-ui/core";

const mapStateToProps = (store, props) => {
    const id = selectRouteParam(store, 'prompt');
    return {
        open: id,
        data: selectEntity(store, 'cms.sections', id)
    }
};

const mapDispatchToProps = {
    onClose: dragSectionPromptCancel,
    onSelect: dragSectionPromptSelect
};

function choices(data) {
    const { type, template, library_export } = data;
    const result = [];

    if (type === 'view') {
        result.push({
            id: 'attach',
            text: 'Utwórz nową sekcję z tym szablonem'
        });
        result.push({
            id: 'clone',
            text: 'Utwórz nową sekcję i kopię szablonu'
        })
    } else {
        result.push({
            id: 'attach',
            text: 'Dołącz tą samą sekcję w tym miejscu'
        });
        result.push({
            id: 'clone',
            text: 'Utwórz kopię sekcji'
        });
        if (template) {
            result.push({
                id: 'clone-view',
                text: 'Utwórz kopię sekcji i szablonu'
            });
        }
    }

    if (library_export) {
        result.push({
            id: 'ref',
            text: 'Dołącz referencje do sekcji z biblioteki'
        })
    }

    return result;
}

class DragSectionPrompt extends React.PureComponent {

    render() {
        const { data, open, onClose, onSelect } = this.props;
        //const { title, description, type, library_ref } = data;
        return (<Dialog open={!!open} onClose={onClose}>
            {/*<div>{title || description} {type} {library_ref}</div>
            <div>choose action</div>*/}
            <List>
                {choices(data).map((data,i)=><ListItem
                    onClick={()=>onSelect(data.id)}
                    key={i} button>
                    <ListItemText
                        primary={data.text} />
                </ListItem>)}
                <ListItem onClick={onClose} button>
                    <ListItemText
                        primary={'Anuluj'} />
                </ListItem>
            </List>
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DragSectionPrompt);