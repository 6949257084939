import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default class Confirm extends React.PureComponent {
    render() {
        const {
            isOpen,
            yes,
            no,
            message,
            description,
            cancelText,
            confirmText,
        } = this.props;

        return (
            <Dialog open={isOpen} onClose={no}>
                {message ? (
                    <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
                ) : null}
                <DialogContent>
                    {description ? (
                        <DialogContentText>{description}</DialogContentText>
                    ) : null}
                </DialogContent>
                <DialogActions
                    style={{ justifyContent: "center", padding: "1rem" }}
                >
                    {cancelText && (
                        <Button color="default" size="large" onClick={no}>
                            {cancelText}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={yes}
                    >
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

Confirm.defaultProps = {
    cancelText: "Cancel",
    confirmText: "Delete",
    description: "This cannot be undone.",
};
