function toggleFullscreen() {
    const e = document.querySelector('.meeting-overlay');
    if (!e) return;
    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else {
        e.requestFullscreen();
    }
}


export const useFullscreenSession = () => {
    return {
        isFullscreen: !!document.fullscreenElement,
        onToggleFullscreen: toggleFullscreen
    };
}