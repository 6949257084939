import PropTypes from "prop-types";

export const MessagePropTypes = {
    addReactions: PropTypes.node,
    author: PropTypes.string,
    avatar: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.string,
    createdAt: PropTypes.object, // moment
    customRenderers: PropTypes.objectOf(PropTypes.string),
    files: PropTypes.node,
    id: PropTypes.string,
    isDeleted: PropTypes.bool,
    isOwn: PropTypes.bool,
    link: PropTypes.node,
    menu: PropTypes.node,
    messageClick: PropTypes.func,
    mode: PropTypes.oneOf(["chat", "channel", "popup", "sidebar"]),
    notificationStatus: PropTypes.node,
    onHide: PropTypes.func,
    reactions: PropTypes.node,
    unread: PropTypes.bool,
    variant: PropTypes.string,
};

export default MessagePropTypes;
