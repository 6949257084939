import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {useCallback, useMemo} from "react";

export function useSession(id) {

    const [,onShow] = useRouteParam(ROUTE_PAGE);

    const show = useCallback(() => onShow(`session.${id}`), [id, onShow]);
    const edit = useCallback(() => onShow(`edit.${id}`), [id, onShow]);

    return useMemo(() => ({
        onShow: show,
        onEdit: edit
    }), [
        show,
        edit
    ]);
}