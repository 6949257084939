import React from 'react';
import {useNodeData} from "joynt/hooks";
import Timer from "joynt/components/Timer";

//import PropTypes from 'prop-types';

export function EventTimer({id, className}) {
    const {event_state, event_start, event_started} = useNodeData(id);

    if (event_state === 'live') return <Timer
        className={className}
        start={event_started}
    />;

    return <Timer
        className={className}
        humanTimeThreshold={3600}
        start={event_start}
        countdown
    />;
}

EventTimer.propTypes = {};
EventTimer.defaultProps = {};

export default EventTimer;
