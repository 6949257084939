import React from "react";

import SessionPageLayout from "joynt/components/SessionPage/SessionPageLayout";
import SessionOverviewHeader from "joynt/components/SessionPage/SessionOverviewHeader";
import SessionStage from "./Networking/SessionStage";
import SessionInfo from "./SessionInfo";

import Discussions from "joynt/components/SessionPage/Discussions";
import Materials from "joynt/components/SessionPage/Materials";

import "./style.css";
import SessionParticipants from "joynt/components/SessionPage/SessionParticipants";

export function SessionPage(props) {
    const { id, isMobile, showStage } = props;

    return (
        <SessionPageLayout
            header={<SessionOverviewHeader id={id} />}
            footer={!isMobile && <SessionStage />}
        >
            <SessionParticipants id={id} />
            <SessionInfo id={id} />
            {!showStage && !isMobile && (
                <div className={"session-page__features"}>
                    <Materials id={id} />
                    <Discussions id={id} />
                </div>
            )}
        </SessionPageLayout>
    );
}

SessionPage.propTypes = {};
SessionPage.defaultProps = {};

export default SessionPage;
