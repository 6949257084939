import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import Calendar from "./Calendar";
import CalendarTimeline from "./CalendarTimeline";

import {useSessionSearch} from "joynt/components/SessionBrowser/hooks";

import FilterToggle from "joynt/components/FilterToggle";
import RangeLabel from "joynt/components/Calendar/RangeLabel";
import RangeNav from "joynt/components/Calendar/RangeNav";
import {useChannels, useRootNode} from "joynt/hooks/channels";
import Select from "joynt/components/Select/Select.container";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";
import Icon from "components/Icon";
import {NODE_TYPE_EVENT} from "joynt/config";

import "./style.css";

const modes = [
    {id: "day", label: "Day"},
    {id: "week", label: "Week"},
    {id: "month", label: "Month"},
    //{id: "year", label: "Year"}
];

function SelectWorkspace(props) {

    const {value, onChange} = props;
    const id = useRootNode();
    const list = "calendar-workspaces";
    const url = `v2/joynt/nodes/${id}/workspaces`;

    return <Select
        label={"Post under"}
        id={list}
        url={url}
        type={"db.nodes"}
        list={list}
        value={value}
        onChange={onChange}
        nullable={true}
        variant={"inline"}
    />

}

export function CalendarContainer(props) {

    // const {
    //     id
    // } = props;

    const date = new Date();

    const root = useRootNode();

    const [day, setDay] = useState(date.getTime()/1000);
    const [mode, setMode] = useState("week");
    const [parent, setParent] = useState(root);
    const [search, setSearch] = useState(null);

    const {
        list
    } = useSessionSearch({
        id: parent,
        day,
        mode,
        search
    });

    const {onCreate} = useChannels(parent, list, NODE_TYPE_EVENT);
    const create = useCallback(() => {
        onCreate({subtype: NODE_TYPE_EVENT});
    }, [onCreate]);

    return (<Calendar
        {...props}
        filter={<>
            <div>
                <SelectWorkspace
                    value={parent}
                    onChange={setParent}
                />
            </div>
            <div>
                <TextField
                    onChange={setSearch}
                    value={search}
                    label={"Search"}
                    variant={"search"}
                />
            </div>
            <div>
                <Button
                    onClick={create}
                    variant={"outlined"}
                    startIcon={<Icon>mui-today</Icon>}
                    disabled={parent === root}
                >
                    Schedule session
                </Button>
            </div>
        </>}
    >
        <CalendarTimeline
            day={day}
            mode={mode}
            list={list}
            header={<>
                <div>
                    <RangeLabel day={day} mode={mode} />
                </div>
                <div>
                    <FilterToggle
                        value={mode}
                        onChange={setMode}
                        options={modes}
                    />
                </div>
                <div>
                    <RangeNav
                        day={day}
                        mode={mode}
                        onChange={setDay}
                    />
                </div>
            </>}
        />
    </Calendar>);

}

CalendarContainer.propTypes = {
    id: PropTypes.string
};

CalendarContainer.defaultProps = {};

export default CalendarContainer;