import {useContext, useCallback, useMemo} from 'react';
import {AgoraContext} from "../context/AgoraContext";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";

export const useJoinCall = (options) => {

    const {channel, user, access} = options;
    const [,setState] = useConnectionState(channel);
    const connection = useContext(AgoraContext);

    const joinCall = useCallback(async () => {
        try {
            setState({
                joined: false,
                loading: true
            });
            await connection.join({
                channel,
                user,
                access
            });
            setState({
                loading: false,
                joined: true,
                error: false
            });
        } catch (error) {
            await connection.leave();
            setState({
                loading: false,
                joined: false,
                error
            });
        }
    }, [
        setState,
        connection,
        channel,
        user,
        access
    ]);

    const retryConnect = useCallback(() => {
        joinCall().then();
    }, [joinCall]);

    return useMemo(() => ({
        retryConnect,
        startCall: joinCall,
    }), [retryConnect, joinCall]);
}
