import React, { useCallback, useState } from "react";

import BlockTypeItem from "./BlockTypeItem";
import useKeyboardListFocus from "./useKeyboardListFocus";

import "./style.css";

function scrollToItem(list, item, padding = 0) {
    let maxVisible = list.offsetHeight + list.scrollTop;
    let itemPos = item.offsetTop + item.offsetHeight;

    if (itemPos > maxVisible) {
        list.scrollTop += itemPos - maxVisible + padding;
    }
    if (item.offsetTop < list.scrollTop) {
        list.scrollTop -= list.scrollTop - item.offsetTop + padding;
    }
}

export default function BlockTypeList(props) {
    const { onApplyBlockType, items, search, onHide } = props;

    const ref = React.useRef();

    const types = items.filter(
        (type) =>
            !search ||
            type.id.indexOf(search) === 0 ||
            type.name.toLowerCase().indexOf(search.toLowerCase()) === 0 ||
            (type.keywords &&
                type.keywords.indexOf(`|${search.toLowerCase()}`) > -1)
    );

    const typeIds = types.map((type) => type.id);

    const [mouseFocus, setMouseFocus] = useState(null);

    const [focus, setFocus] = useKeyboardListFocus(
        onApplyBlockType,
        onHide,
        typeIds
    );

    const fitToView = useCallback(
        (item) => {
            if (!ref.current) return;
            scrollToItem(ref.current, item, 10);
        },
        [ref]
    );

    const focused = mouseFocus || focus;

    return (
        <div
            className={"block-types-list"}
            ref={ref}
            onMouseLeave={() => setMouseFocus(null)}
        >
            {types.map((type) => {
                return (
                    <BlockTypeItem
                        key={type.id}
                        id={type.id}
                        name={type.name}
                        icon={type.icon}
                        isFocused={focused === type.id}
                        isKeyboardFocused={focus === type.id && !mouseFocus}
                        onFitToView={fitToView}
                        onClick={(e) => onApplyBlockType(type.id, e)}
                        onMouseEnter={() => {
                            setMouseFocus(type.id);
                            setFocus(type.id);
                        }}
                    />
                );
            })}
        </div>
    );
}

BlockTypeList.defaultProps = {
    items: [],
};
BlockTypeList.propTypes = {};
