import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import CreateLayout from "./CreateLayout";
import { useData } from "state/hooks";
import { useRouteParams } from "state/hooks/useRouteParams";
import { useOperationProgress } from "./hooks";
import ProgressLog from "./ProgressLog";
import StatusImage from "./StatusImage";

export function CreateProgress(props) {
    const { onClose } = props;
    const { website, domain } = useData(props);
    const { hostname } = useData({ type: "console.domains", id: domain });
    const push = useRouteParams();

    const { status, log } = useOperationProgress(`create-website.${website}`);
    const isSuccess = status === "success";

    const settings = () => {
        push({ resource: "console.services.website", id: website });
        onClose();
    };

    const open = () => {
        window.open(hostname);
    };

    const title = isSuccess ? "Success!" : "Creating website...";

    return (
        <CreateLayout title={title} className={"rows-center"}>
            <div className={"rows gap-md"}>
                <div className={"cols cols-center"}>
                    <StatusImage status={status} />
                </div>
                <ProgressLog log={log} />
                {isSuccess && (
                    <>
                        <div className={"cols cols-center gap-md"}>
                            <Button onClick={settings} variant={"text"}>
                                Settings
                            </Button>
                            {hostname && (
                                <Button onClick={open} variant={"text"}>
                                    {hostname}
                                </Button>
                            )}{" "}
                        </div>
                        <div className={"cols cols-center"}>
                            <Button onClick={onClose} color={"default"}>
                                OK
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </CreateLayout>
    );
}

CreateProgress.propTypes = {};

CreateProgress.defaultProps = {};

export default CreateProgress;
