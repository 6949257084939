import React from "react";
import PropTypes from "prop-types";
import Reactions from "./Reactions";
import { useReaction } from "joynt/hooks";
import { useReactionsOverlay } from "./ReactionsOverlay";

export function ReactionsContainer(props) {
    const { enabled, you, reactions, pending, onReaction } = useReaction(props);
    const { onShow, onHide } = useReactionsOverlay(props);

    return (
        <Reactions
            {...props}
            enabled={enabled}
            you={you}
            reactions={reactions}
            pending={pending}
            onReaction={onReaction}
            onShow={onShow}
            onHide={onHide}
        />
    );
}

ReactionsContainer.propTypes = {
    id: PropTypes.string,
};
ReactionsContainer.defaultProps = {};

export default ReactionsContainer;
