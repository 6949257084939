import React from 'react';
import Avatar from "./Avatar";

//import PropTypes from 'prop-types';

export function PeopleList(props) {

    const {items} = props;
    return items.map(id => <Avatar key={id} id={id} />);

}

PeopleList.propTypes = {};
PeopleList.defaultProps = {
    items: []
};

export default PeopleList;
