//import React from 'react';
import {withData} from "containers/Entity";
import {styled} from "cms/styles/legacy";

function SectionTemplate(props) {
    return props.children(props);
}

SectionTemplate.autoFillProps = [
    'style',
    'styles',
    'classes',
    'display'
];

const SectionTemplateContainer = withData(styled(SectionTemplate));
SectionTemplateContainer.defaultProps = {
    element_type: 'section'
};
export default SectionTemplateContainer;