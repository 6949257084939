import React, {useCallback, useState} from 'react';

import cn from 'classnames';
import { colors } from 'config/colors';
import Tooltip from '@material-ui/core/Tooltip';
import {Popover} from "@material-ui/core";

import 'css/fields/ui-color-picker.css';
import Button from "components/Button";

function ColorPicker(props) {
    const { onChange } = props;
    return <div className={'pad-sm grid grid-6 phone@grid-8'}>
        {Object.keys(colors).map(color=>{
            return (<Tooltip key={color} title={color}>
                <div
                    onClick={()=>onChange(color)}
                    className={cn("bg-"+color, 'ui-color-swatch')}
                />
            </Tooltip>);
        })}
    </div>
}

export default function UiColorPicker(props) {
    const {onChange, value, defaultValue, label, variant} = props;

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = useCallback((evt) => {
        setIsOpen(true);
        setAnchorEl(evt.target);
    }, [setIsOpen, setAnchorEl]);

    const close = useCallback(() => {
        setIsOpen(false);
        setAnchorEl(null)
    }, [setIsOpen, setAnchorEl]);

    const change = useCallback(value => {
        close();
        onChange(value);
    }, [close, onChange]);

    const selectedValue = value || defaultValue;

    if (variant === 'inline')
        return <ColorPicker onChange={change} />;

    return (<>
        <div onClick={open} className={'cols gap-xs cols-middle'}>
            <div
                className={cn('ui-color-value', `bg-${selectedValue}`)}
            />
            <Button variant={'text'} onClick={()=>null}>
                {label || 'Change color'}
            </Button>
        </div>
        <Popover onClose={close} open={isOpen} anchorEl={anchorEl}>
            <ColorPicker onChange={change} />
        </Popover>
    </>);
}

UiColorPicker.defaultProps = {
    defaultValue: 'deep-purple'
}