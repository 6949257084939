export default {
    "ui.forms/login": {
        username: {
            required: true,
            format: "email",
        },
        password: {
            required: true,
            min: 8,
            max: 64,
        },
    },
    "ui.forms/signup": {
        username: {
            required: true,
            format: "email",
        },
        password: {
            password: true,
            required: true,
            min: 8,
            max: 64,
        },
        password_confirm: {
            required: true,
            match: "password",
        },
        accept_terms: {
            required: true,
        },
    },
    "ui.forms/password-reset": {
        username: {
            required: true,
            format: "email",
        },
    },
};
