import React from "react";
import GlobalStyles from "./GlobalStyles";
import ComponentStyles from "./ComponentStyles";
import { useActions, useRouteParam } from "state/hooks";
import { fileSearch } from "cms/state/actions/theme";

//import PropTypes from 'prop-types';

export function StyleEditor(props) {
    const { state, setState } = props;

    const { onSearch } = useActions({ onSearch: fileSearch });
    const [file, setFile] = useRouteParam("themeFile");

    const handleSearch = (query) => {
        onSearch(query, (result) => {
            if (result.length) {
                setState({ files: true });
                setFile(result[0].id);
            }
        });
    };

    if (state.editStyle || state.editDefaultStyle) {
        return (
            <GlobalStyles
                style={state.editStyle}
                id={state.editDefaultStyle || state.editStyle}
                onExit={() =>
                    setState({ editStyle: null, editDefaultStyle: null })
                }
                onEditDefault={(v) => setState({ editDefaultStyle: v })}
                element={state.style}
                onSelectElement={(e) => setState({ style: e })}
                onSearch={handleSearch}
            />
        );
    }
    return <ComponentStyles {...props} onSearch={handleSearch} />;
}

StyleEditor.propTypes = {};

StyleEditor.defaultProps = {};

export default StyleEditor;
