import React from "react";
//import PropTypes from 'prop-types';

import "./style.css";

export default function NotificationArea(props) {
    const { children } = props;
    if (!children.length) return null;

    return <div className={"notifications-top"}>{children}</div>;
}

NotificationArea.propTypes = {};
NotificationArea.defaultProps = {};
