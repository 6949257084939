import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import Badge from "@material-ui/core/Badge";
//import PropTypes from 'prop-types';

export default function TabButton(props) {
    const { label, icon, onClick, active, count, variant } = props;
    return (
        <Badge
            invisible={!count}
            color={"secondary"}
            variant={"dot"}
            overlap={"circular"}
        >
            <Button
                className={"inbox-btn"}
                startIcon={<Icon>{icon}</Icon>}
                onClick={onClick}
                color={active && variant === "button" ? "primary" : "default"}
                variant={!active && variant === "link" ? "text" : "contained"}
            >
                {label}
            </Button>
        </Badge>
    );
}

TabButton.propTypes = {};
TabButton.defaultProps = {
    variant: "button",
};
