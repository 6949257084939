import React from "react";
import { useMediaDevices } from "joynt/meeting/devices";
import AllowAccessNoPermissionsApi from "./AllowAccessNoPermissionsApi";
import AllowAccessInfo from "./AllowAccessInfo";
import DevicesAccessError from "./DeviceAccessError";
import { useActions } from "state/hooks";
import { deny } from "joynt/state/actions/meetings";
//import PropTypes from 'prop-types';

export default function DeviceAccessGate({ onClose, children }) {
    const {
        cameraAccess,
        microphoneAccess,
        permissionsApiNotAvailable,
        onRequestDevices,
        status,
    } = useMediaDevices();

    const { onDeny } = useActions({
        onDeny: deny,
    });

    if (status === "prompt" && permissionsApiNotAvailable)
        return (
            <AllowAccessNoPermissionsApi
                onRetry={onRequestDevices}
                onDeny={onDeny}
            />
        );

    if (status === "prompt")
        return <AllowAccessInfo onRetry={onRequestDevices} onDeny={onDeny} />;

    if (status === "denied")
        return (
            <DevicesAccessError
                camera={cameraAccess}
                microphone={microphoneAccess}
                onClose={onClose || onDeny}
            />
        );

    return children;
}

DeviceAccessGate.propTypes = {};
DeviceAccessGate.defaultProps = {};
