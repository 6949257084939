export const createMuiThemeFromPreset = (preset, dark) => {
    if (!preset.id) return {};
    let mapping = dark ? 'dark' : preset.mapping;
    return {
        palette: {
            type: mapping === 'dark' ? 'dark' : 'light',
            primary: { main: preset.color_scheme.primary_color },
            secondary: { main: preset.color_scheme.success_color }
        },
        typography: {
          fontSize: 12,
          fontFamily: [preset.typography.body_font.family].join(',')
        },
        overrides: {
            MuiIconButton: {
                root: {
                    padding: '8px'
                },
                label: {
                    fontSize: '24px',
                }
            },
            MuiIcon: {
                root: {
                    fontSize: '24px'
                }
            },
            MuiTextField: {
                root: {
                    color: 'red'
                }
            }
        }
    }
};