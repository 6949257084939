import React from 'react';
import PropTypes from 'prop-types';

import {useEdges, useNode} from "joynt/hooks";

import PostHeader from "./PostHeader";
import PostAuthor from "./../PostAuthor";
import DateDiff from "components/DateDiff";
import NodeLink from "joynt/components/Post/NodeLink";
import PostContextMenu from "joynt/components/Post/PostContextMenu";
import PostTitle from "joynt/components/Post/PostTitle";
import IconButton from "components/IconButton";
import {useRouteParam} from "state/hooks";
import {ROUTE_ID} from "joynt/config";
import TaskPostTitle from "joynt/components/Tasks/TaskPostTitle";

export function PostHeaderContainer(props) {

    const {id, onClose, editable} = props;

    const {
        name,
        identity,
        created_at,
        node_role
    } = useNode(id);

    const isTask = node_role === "task";

    const {parent} = useEdges(id);
    const [currentWorkspace] = useRouteParam(ROUTE_ID);

    const TitleComponent = isTask ? TaskPostTitle : PostTitle;

    return (<PostHeader
        author={identity ? <PostAuthor id={identity} /> : null}
        date={<DateDiff value={created_at} />}
        parent={parent !== currentWorkspace && <NodeLink id={parent} />}
        actions={<>
            <PostContextMenu id={id} />
            {onClose && <IconButton icon={"mui-close"} onClick={onClose} />}
        </>}
    >
        <TitleComponent
            id={id}
            readOnly={!editable}
            className={"post-title"}
        >
            {name}
        </TitleComponent>
    </PostHeader>);

}

PostHeaderContainer.propTypes = {
    id: PropTypes.string,
    editable: PropTypes.bool
};
PostHeaderContainer.defaultProps = {
    editable: false
};

export default PostHeaderContainer;