import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import RoomStage from "./RoomStage";

import StageSession from "./StageSession";
import ScheduleSession from "./ScheduleSession";
import Meetings from "./Meetings";
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {useNodeData} from "joynt/hooks";

export function RoomStageContainer(props) {
    const {id} = props;
    const [,setPage] = useRouteParam(ROUTE_PAGE);
    const {can_edit} = useNodeData(id);

    const edit = useCallback(() => {
        setPage(`schedule.${id}`);
    }, [setPage, id]);

    return (<RoomStage id={id}>
        <ScheduleSession id={id} />
        <StageSession
            {...props}
            onShowSettings={can_edit ? edit : null}
        />
        <Meetings id={id} />
    </RoomStage>);
}

RoomStageContainer.propTypes = {
    id: PropTypes.string
};
RoomStageContainer.defaultProps = {};

export default RoomStageContainer;