import React from "react";
import { useData } from "state/hooks";
import cn from "classnames";
//import PropTypes from 'prop-types';

function LayoutPathItem(props) {
    const { onClick, isSelected } = props;
    const { title, description } = useData(props);
    return (
        <div
            className={cn("cursor-pointer", {
                "text-primary": isSelected,
            })}
            onClick={onClick}
        >
            {title || description}
        </div>
    );
}

export function LayoutsPath(props) {
    const { items, onClick, value } = props;
    return (
        <div className={"cols gap-xs"}>
            {items.map((item, index) => {
                return (
                    <LayoutPathItem
                        key={index}
                        type={"cms.layouts"}
                        id={item}
                        onClick={() => onClick(item)}
                        isSelected={value === item}
                    />
                );
            })}
        </div>
    );
}

LayoutsPath.propTypes = {};

LayoutsPath.defaultProps = {};

export default LayoutsPath;
