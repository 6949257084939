import React from "react";
import Children from "react-children-utilities";

export function extendChildren(Component, children) {
    const filterFn = (child) => child.type === Component;
    const matchingChildren = Children.filter(children, filterFn);
    function concatChildren(acc, child) {
        if (child.props.replace) {
            return child.props.children;
        }
        if (child.props.prepend) {
            return [].concat(child.props.children).concat(acc.children);
        }
        return acc.children.concat(child.props.children);
    }
    const passedProps = matchingChildren.reduce(
        (acc, child) => {
            return {
                ...acc,
                ...child.props,
                children: concatChildren(acc, child),
            };
        },
        { children: [] }
    );

    if (!matchingChildren.length) {
        return null;
    }

    return <Component {...passedProps} />;
}
