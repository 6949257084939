import React from 'react';
import moment from "moment";
import {isPhone} from "util/device";
//import PropTypes from 'prop-types';

export default function Scale({min, max}) {
    let markers = [];

    let step = isPhone() ? 60 : 30;
    let markerLength = 60 * step;
    let markersCount = ((max-min) / markerLength);

    for(let i = 0; i < markersCount; i++) {
        let markerTime = min+(i*markerLength);
        if (markerTime <= max-markerLength) markers.push(markerTime);
    }

    const style = {width: (100/markersCount)+'%'};

    return <div className={'timeline-scale'}>{markers.map(m => {
        return <div className={'timeline-scale-marker'} style={style}>
            {moment.unix(m).format('H:mm')}
        </div>
    })}</div>;
}

Scale.propTypes = {};
Scale.defaultProps = {};
