import React from 'react';
//import PropTypes from 'prop-types';

import {ChildComponents} from "cms/components/Layout";

export default class Context extends React.PureComponent {

    render() {
        const { components } = this.props;
        return <ChildComponents
            itemType={'cms.components'}
            items={components}
        />;
    }

}

Context.propTypes = {};

Context.autoFillProps = [
    'components'
];