import React from "react";
import { MenuItems } from "playground/cms/views/Resources";
import { MediaItem } from "playground/media";
import ContextMenu from "components/ContextMenu";

//import PropTypes from 'prop-types';

export const GalleryItem = React.forwardRef((props, ref) => {
    const { entityType, id } = props;

    return (
        <ContextMenu type={entityType} id={id} RenderMenuItems={MenuItems}>
            {({ onMenuOpen }) => (
                <MediaItem {...props} ref={ref} onContextMenu={onMenuOpen} />
            )}
        </ContextMenu>
    );
});

GalleryItem.propTypes = {};
GalleryItem.defaultProps = {};

export default GalleryItem;
