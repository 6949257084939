import React, { useCallback, useState } from "react";

import { usePostComments } from "joynt/components/Post/hooks";
import { Message, MessageForm } from "joynt/components/messages";
import { MessagesList } from "joynt/components/messages/chat/MessagesList";
import Icon from "components/Icon";
import Reactions from "joynt/components/Reactions";

function ShowMore(props) {
    const { onClick, icon, children } = props;
    return (
        <div onClick={onClick} className={"comments-toggle"}>
            {icon ? (
                <span className={"comments-toggle__icon"}>
                    <Icon>{icon}</Icon>
                </span>
            ) : null}
            <span className={"comments-toggle__label"}>{children}</span>
        </div>
    );
}

export default function PostComments(props) {
    const { id } = props;

    const { lastMessage, onCreateMessage, count, thread } = usePostComments(id);

    const [showMessages, setShowMessages] = useState(false);

    const toggle = useCallback(() => {
        setShowMessages((v) => !v);
    }, [setShowMessages]);

    return (
        <div
            className={"post-thread variant-comment rows gap-sm"}
            onClick={null}
        >
            <div className={"post-interactions cols cols-middle gap-sm"}>
                <Reactions type={"db.nodes"} id={id} />
                <div onClick={toggle} className={"link-text"}>
                    {count} {count === 1 ? "comment" : "comments"}
                </div>
            </div>

            {count > 1 && (
                <ShowMore onClick={toggle}>
                    {showMessages ? "Hide comments" : "Show previous comments"}
                </ShowMore>
            )}
            <section className={"rows gap-2 post-comments__list"}>
                {showMessages && (
                    <MessagesList
                        id={thread}
                        limit={5}
                        className={"post-comments"}
                        scrollPagination={false}
                        mode={"channel"}
                    />
                )}

                {lastMessage && !showMessages && (
                    <div>
                        <Message id={lastMessage} mode={"channel"} />
                    </div>
                )}
            </section>
            <MessageForm
                messageId={id}
                parent={id}
                placeholder={"Write a comment..."}
                onCreateMessage={onCreateMessage}
                typingIndication={false}
                submitMode={"button"}
                submitLabel={"Comment"}
            />
        </div>
    );
}
