import React, { useRef } from "react";
import cn from "classnames";

import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import Portal from "@material-ui/core/Portal";

/**
 * Setting vars on :root causes performance issues
 * when dragging floating session window
 */
function setVars(e, x, y, w, h) {
    const r = (v) => Math.round(v);

    e.style.setProperty("--video-x", `${r(x)}px`);
    e.style.setProperty("--video-y", `${r(y)}px`);
    e.style.setProperty("--video-width", `${r(w)}px`);
    e.style.setProperty("--video-height", `${r(h)}px`);
}

function fitElement(e, fitTo) {
    if (!e) return;

    const rect = fitTo.getBoundingClientRect();
    const root = document.getElementById("root").getBoundingClientRect();

    const x = rect.x - root.x;
    const y = rect.y;

    setVars(e, x, y, rect.width, rect.height);
    e.classList.add("fit");

    setTimeout(() => {
        e.classList.add("is-initialized");
    }, 100);
}

export function bindVideoToStage(entry) {
    let stage = document.querySelector(".stage-video");
    let floating = document.querySelector(".floating-video");
    let video = document.querySelector(".meeting-overlay");
    let toolbar = document.querySelector(".meeting-toolbar-area");

    function reset(e) {
        setVars(e, null, null, null, null);
        e.classList.remove("fit");
    }

    if (!video) {
        if (video) reset(video);
        return true;
    }
    if (stage) {
        fitElement(video, stage);
        fitElement(toolbar, stage);
        return true;
    }
    if (floating) {
        fitElement(video, floating);
        return false;
    }

    reset(video);
    return true;
}

export default function MeetingOverlay(props) {
    const ref = useRef();
    const { id, portal, children } = props;
    const [{ joined, loading }] = useConnectionState(id);

    const overlay = (
        <div
            ref={ref}
            className={cn("meeting-overlay rows", {
                "session-joined": joined,
                "session-pending": loading,
            })}
        >
            {children}
        </div>
    );

    if (!portal) return overlay;

    return <Portal>{overlay}</Portal>;
}

MeetingOverlay.defaultProps = {
    portal: true,
};
MeetingOverlay.propTypes = {};
