import React from 'react';
import {connect} from 'react-redux';
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
//import PropTypes from 'prop-types';

import List from 'containers/List';
import ContextMenu from "components/ContextMenu";
import {entityDelete} from "state/actions/delete";

const mapState = (store, props) => ({});

const mapDispatch = bindApiActions({
    onDeleteItem: entityDelete
});

class ContextMenuList extends React.PureComponent {

    handleDeleteItem = (item) => {
        const { onDelete, onDeleteItem, type, list } = this.props;
        let deleteFn = onDelete || onDeleteItem;
        //console.log('ContextMenuList.handleDeleteItem', item);
        deleteFn(type, item, list);
    };

    renderList = (props)  => {
        return <List
            {...this.props}
            selected={props.id}
            onContextMenu={props.onMenuOpen}
        />
    };

    render() {
        const {
            id,
            RenderMenuItems,
            ...other
        } = this.props;

        return (<ContextMenu
            {...other}
            selected={id}
            onDelete={this.handleDeleteItem}
            RenderMenuItems={RenderMenuItems}
        >
            {this.renderList}
        </ContextMenu>);
    }

}

export default withApiContext()(
    connect(mapState, mapDispatch)(ContextMenuList)
);

ContextMenuList.propTypes = {};