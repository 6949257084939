import uuid from 'uuid/v4';

export const create = (type, item = {}, list, position) => {
    if (!item.id) item.id = uuid();
    return {
        type: 'ENTITY.CREATE',
        payload: {
            type,
            item,
            list,
            position
        }
    }
};

export const createCallback = (callback, ...args) => {
    const createAction = create(...args);
    createAction.callback = callback;
    return createAction;
};