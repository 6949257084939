import React, {useCallback} from 'react';

import UserMenu from 'components/user-menu/UserMenu'
import {pushRouteParams} from "state/actions/router";
import {useActions, useHasScope, useRouteParam} from "state/hooks";

export default function UserMenuContainer(props) {
    const {MenuComponent, defaultTab} = props;
    const {pushRoute} = useActions({pushRoute: pushRouteParams});
    const showResource = useCallback((resource)=>pushRoute({resource, nav: null}), [pushRoute]);
    const [tab, changeTab] = useRouteParam('um');
    const isAdmin = useHasScope('apps.console');
    const showConsole = () => window.location.href = `/pl/_`;

    return <MenuComponent
        {...props}
        tab={tab || defaultTab}
        onChangeTab={changeTab}
        onClick={showResource}
        onShowConsole={showConsole}
        isAdmin={isAdmin}
    />;
}

UserMenuContainer.defaultProps = {
    MenuComponent: UserMenu,
    defaultTab: 'modules'
}