import { useActions, useFilter, useRegisterList } from "state/hooks";
import { setPage } from "state/actions/pagination";
import { useEffect, useMemo } from "react";
import useFetch from "state/hooks/useFetch";
import { appendQueryParams } from "util/uri";

export function useTaskList(id) {
    const list = [id, ".tasks"].join(".");

    const {
        value: { id: parent },
    } = useFilter(list);

    const tag = parent || id;
    const { onSetPage } = useActions({ onSetPage: setPage });

    useEffect(() => {
        return () => onSetPage(list, 1);
    }, [onSetPage, list]);

    const url = `v2/joynt/nodes/${tag}/tasks-board`;
    const query = {};

    const fetchProps = {
        type: "db.nodes",
        list,
        filter: list,
        url: appendQueryParams(url, query),
        append: true,
        enable: true,
        force: true,
    };

    useRegisterList(fetchProps);

    const pending = useFetch(fetchProps);

    return useMemo(
        () => ({
            list,
            pending,
        }),
        [list, pending]
    );
}
