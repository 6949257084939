import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import DateRangePicker from "playground/booking/DateRangePicker";
import Button from "cms/components/Button";
import Icon from "cms/components/Icon";

function DateFormat(props) {
    const { icon, classes, value } = props;

    if (!value) return null;

    const dayFormat = classes.format_day || "DD";
    const monthFormat = classes.format_month || "MMM";
    const yearFormat = classes.format_year || null;

    const { date_value, day, month, year, date_icon } = classes;

    return (
        <div className={date_value}>
            <div className={day}>{value.format(dayFormat)}</div>
            <div className={month}>{value.format(monthFormat)}</div>
            {yearFormat ? (
                <div className={year}>{value.format(yearFormat)}</div>
            ) : null}
            {/*{ icon ? <div className={date_icon}>{icon}</div> : null }*/}
            {icon && <Icon className={date_icon} icon={icon} />}
        </div>
    );
}

function QuickbookDatePicker(props) {
    const { dateFrom, dateTo, onOpenDateFrom, onOpenDateTo } = props;

    const {
        date_icon,
        show_promo_code,
        promo_code_icon,
        promo_code_label,
        promo_code_placeholder,
        promo_code_value,
        classes,
    } = props;

    const {
        layout,
        quickbook_form,
        dates,
        date,
        date_label,
        promo_code,
        promo_code_input,
    } = classes;

    return (
        <div className={layout}>
            <div className={cn("l-area", quickbook_form)}>
                <div className={cn("l-area", dates)}>
                    <div
                        onClick={onOpenDateFrom}
                        className={cn("l-area", date)}
                    >
                        <div className={cn(date_label)}>Przyjazd</div>
                        <DateFormat
                            value={dateFrom}
                            icon={date_icon}
                            classes={classes}
                        />
                    </div>
                    <div onClick={onOpenDateTo} className={cn("l-area", date)}>
                        <div className={cn(date_label)}>Wyjazd</div>
                        <DateFormat
                            value={dateTo}
                            icon={date_icon}
                            classes={classes}
                        />
                    </div>
                </div>
                {show_promo_code ? (
                    <div className={cn("l-area", promo_code)}>
                        {promo_code_label ? (
                            <div className={cn(classes.promo_code_label)}>
                                {promo_code_label}
                            </div>
                        ) : null}
                        <div className={cn(promo_code)}>
                            <input
                                className={cn(promo_code_input)}
                                placeholder={promo_code_placeholder || null}
                                value={promo_code_value || null}
                            />
                            {promo_code_icon ? (
                                <div className={cn(classes.promo_code_icon)}>
                                    {promo_code_icon}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
            <Button {...props} />
        </div>
    );
}

export default class Quickbook extends React.PureComponent {
    render() {
        return <DateRangePicker {...this.props} render={QuickbookDatePicker} />;
    }
}

Quickbook.propTypes = {};
Quickbook.autoFillProps = [
    "date_icon",
    "nullable",
    "placeholder_icon",
    "show_promo_code",
    "promo_code_icon",
    "promo_code_label",
    "promo_code_placeholder",
    "promo_code_value",
];
