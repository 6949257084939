import React, {Component} from 'react';

export default class Shape extends Component {

    render() {
        const canvasStyle = {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '9999px',
            height: '9999px',
            pointerEvents: 'none',
            zIndex: 1
        };

        const shapeStyle = {
            fill: 'red',
            opacity: this.props.isActive ? 0.5 : 0.2
        };

        const edgeStyle = {
            stroke: 'blue',
            strokeWidth: '2px'
        };

        const scale = this.props.scale;

        const points = this.props.points.map((point) => {
            return point.x*scale + ',' + point.y*scale;
        }).join(' ');

        const edgePoints = this.props.edge ? this.props.edge.map((point) => {
            return point.x*scale + ',' + point.y*scale;
        }).join(' ') : null;

        return (<svg
            style={canvasStyle}>
            <polyline points={points} style={shapeStyle} />
            {edgePoints ? (
                <polyline points={edgePoints} style={edgeStyle} />
            ) : null}
        </svg>);
    }
}