import React from 'react';
//import PropTypes from 'prop-types';

const style = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
    fontFamily: 'Arial'
}

export default function AuthPending(props) {

    return (<div style={style}>Just a sec...</div>);
}
AuthPending.propTypes = {};