/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
export function getClosest( elem, selector ) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get closest match
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        if ( !elem || !elem.matches) return null;
        if ( elem.matches( selector ) ) return elem;
    }

    return null;

}

export function find(selector) {
    return document.querySelectorAll(selector);
}

export function getParents(el, selector, parentSelector /* optional */) {

    // If no parentSelector defined will bubble up all the way to *document*
    if (parentSelector === undefined) {
        parentSelector = document;
    }

    var parents = [];
    var p = el.parentNode;

    if (!p) return [];

    while (p && p !== parentSelector) {
        var o = p;
        if (o.matches && o.matches(selector)) {
            parents.push(o);
        }
        p = o.parentNode;
    }

    /*if (parentSelector.matches(selector)) {
        parents.push(parentSelector); // Push that parentSelector you wanted to stop at
    }*/

    return parents;
}

export function getSiblings(el, selector) {
    let parents = getParents(el, selector);
    let targetEl = el.parentElement;
    let children = Array.from(
        targetEl.querySelectorAll(selector)
    );
    return children.concat(parents);
}