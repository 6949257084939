import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import "./style.css";

export function LiveNowBadge(props) {
    const { animate } = props;

    const className = cn("text-badge live-now-badge", {
        animate: animate,
    });

    return (
        <div className={className}>
            <div className={"text-badge__text"}>Live</div>
        </div>
    );
}

LiveNowBadge.propTypes = {
    animate: PropTypes.bool,
};
LiveNowBadge.defaultProps = {
    animate: false,
};

export default LiveNowBadge;
