import React from "react";
import Field from "containers/Field";
import DateTimePicker from "joynt/components/DateTimePicker/DateTimePicker.container";
import PropTypes from "prop-types";
import { CollapsedField } from "joynt/components/Form";
import { useNodeData } from "joynt/hooks";
import { useMoment } from "joynt/components/DateTimePicker/hooks";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

function SessionDateField(props) {
    const { startField, endField } = props;

    return (
        <Field id={startField}>
            {({ onChange: onChangeStart, value: start }) => (
                <Field id={endField}>
                    {({ onChange: onChangeEnd, value: end }) => {
                        return (
                            <DateTimePicker
                                start={start}
                                end={end}
                                onChangeStart={onChangeStart}
                                onChangeEnd={onChangeEnd}
                            />
                        );
                    }}
                </Field>
            )}
        </Field>
    );
}

function DateTimeLabel(props) {
    const { start, end, dateFormat, timeFormat } = props;
    const startMoment = useMoment(start);
    const endMoment = useMoment(end);

    return (
        <div className={"cols cols-middle gap-md"}>
            <div className={"cols cols-middle gap-xs"}>
                <Icon>mui-today</Icon>
                <div>{startMoment.format(dateFormat)}</div>
            </div>
            <div className={"cols cols-middle gap-xs"}>
                <Icon>mui-schedule</Icon>
                <div>
                    {startMoment.format(timeFormat)} -{" "}
                    {endMoment.format(timeFormat)}
                </div>
            </div>
        </div>
    );
}

DateTimeLabel.defaultProps = {
    dateFormat: "DD MMMM",
    timeFormat: "HH:mm",
};

export function SessionDate(props) {
    const {
        id,
        startField,
        endField,
        collapsed,
        expandLabel,
        placeholderText
    } = props;

    const { [startField]: start, [endField]: end } = useNodeData(id);

    if (!collapsed) return <SessionDateField {...props} />;

    return (
        <CollapsedField
            label={start
                ? <DateTimeLabel start={start} end={end} />
                : placeholderText}
            expandLabel={expandLabel}
            variant={"contained"}
        >
            <SessionDate />
        </CollapsedField>
    );
}

SessionDate.propTypes = {
    startField: PropTypes.string.isRequired,
    endField: PropTypes.string.isRequired,
    collapsed: PropTypes.bool,
};

SessionDate.defaultProps = {
    startField: "event_start",
    endField: "event_end",
    expandLabel: "Reschedule",
    collapsed: false,
};

export default SessionDate;
