import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import FollowButton from "./FollowButton";
import {useApiActions, usePending} from "state/hooks";
import {toggleFollow} from "joynt/state/actions";
import {useEdgesData} from "joynt/hooks";

export function FollowButtonContainer(props) {

    const {id} = props;

    const {onToggle} = useApiActions({
        onToggle: toggleFollow
    });

    const {follow} = useEdgesData(id);
    const isPending = usePending(`${id}.follow`);

    const toggle = useCallback(() => {
        onToggle(null, id);
    }, [onToggle, id]);

    return (<FollowButton
        {...props}
        onClick={toggle}
        isPending={isPending}
        isFollowing={!!follow}
    />);

}

FollowButtonContainer.propTypes = {
    id: PropTypes.string
};
FollowButtonContainer.defaultProps = {};

export default FollowButtonContainer;