import React from "react";
//import PropTypes from 'prop-types';

import FeedEmptyState from "joynt/views/feed/FeedEmptyState";
import { CircularProgress } from "@material-ui/core";
import List from "containers/List";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import TaskListItem from "./../TaskListItem";
import Button from "components/Button";
import Icon from "components/Icon";

import "./style.css";

const TasksIterator = InfiniteScrollIterator(TaskListItem);

export function TaskList(props) {
    const { list, onClick, onCreate } = props;

    return (
        <>
            <div className={"cols cols-center pad-4"}>
                <Button
                    onClick={onCreate}
                    variant={"outlined"}
                    startIcon={<Icon>mui-add</Icon>}
                >
                    Create task
                </Button>
            </div>
            <List
                className={"tasks-list"}
                type={"db.nodes"}
                list={list}
                filter={list}
                useWindowScroll={false}
                Iterator={TasksIterator}
                emptyState={<FeedEmptyState />}
                pendingReplace={false}
                onClick={onClick}
                pendingState={
                    <div className={"cols cols-center pad-md"}>
                        <CircularProgress size={32} />
                    </div>
                }
            />
        </>
    );
}

TaskList.propTypes = {};
TaskList.defaultProps = {};

export default TaskList;
