import React from 'react';
// import PropTypes from 'prop-types';
import SessionPageSection from "joynt/components/SessionPage/SessionPageSection";

export function DiscussionsAndMaterials(props) {

    // const {} = props;

    return (<SessionPageSection>
        <div className={"discussions-and-materials-promo"}>
            <div className={"discussions-and-materials-promo__content"}>
                <h2>Discussions and materials</h2>
                <p><strong>
                    Sign up or log in to connect with other attendees and:
                </strong></p>
                <ul>
                    <li>Take part in discussions before and after the meeting</li>
                    <li>Ask questions, upvote and reply</li>
                    <li>Check out interesting materials provided by our guests</li>
                    <li>Comment on the materials</li>
                </ul>
            </div>
            <div className={"discussions-and-materials-promo__image"}>
                <img alt={"Discussions and materials"} src={"https://joynt.works/thumb?file=joynt-website/user/app/sessions/webinar-page-materials.png"} />
            </div>
        </div>
    </SessionPageSection>);

}

DiscussionsAndMaterials.propTypes = {};

DiscussionsAndMaterials.defaultProps = {};

export default DiscussionsAndMaterials;