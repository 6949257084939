import React from "react";
import { useFilter } from "state/hooks";
import Select from "components/fields/select/Select";

//import PropTypes from 'prop-types';

export function PropertyFilter(props) {
    const { type } = props;
    const { value, onSet } = useFilter(type);
    return (
        <Select
            type={"cms.properties"}
            value={value.property}
            onChange={(v) => onSet("property", v)}
            source={"api:cms.properties"}
            nullable={true}
            label={"Obiekt"}
            variant={"dense"}
        />
    );
}

PropertyFilter.propTypes = {};

PropertyFilter.defaultProps = {};

export default PropertyFilter;
