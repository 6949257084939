import React from 'react';
import PropTypes from 'prop-types';

import Schedule from "./Schedule";

import {useNodeData} from "joynt/hooks";
import {useSchedule} from "./hooks";

export function ScheduleContainer(props) {

    const {id} = props;

    const {
        selectedDay,
        onSelectDay,
        onCreate,
        count,
        list,
        isPending
    } = useSchedule(id);

    const {can_edit} = useNodeData(id);

    return (<Schedule
        {...props}
        list={list}
        onChangeDay={onSelectDay}
        selectedDay={selectedDay}
        onCreate={onCreate}
        canEdit={can_edit}
        sessionCount={count}
        hasSessions={count[selectedDay] > 0}
        isPending={isPending}
    />);
}

ScheduleContainer.propTypes = {
    id: PropTypes.string
};
ScheduleContainer.defaultProps = {};

export default ScheduleContainer;