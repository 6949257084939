import React from 'react';
import {connect} from 'react-redux';
import EntityContext from "containers/context/EntityContext";
import Field from 'containers/Field';
import {selectEntity} from "state/selectors/data";
import Button from "components/Button";
import {reset} from "state/actions/data";

const mapStateToProps = (store, props) => {
    return {
        data: selectEntity(store, '@app.forms', 'new-file')
    }
};

const mapDispatchToProps = {
    reset: reset
};

class FileCreate extends React.Component {

    submit = () => {
        const { onSubmit, data } = this.props;
        if (data.name) onSubmit(data.name);
        this.cancel();
    };

    cancel = () => {
        const { onClose, reset } = this.props;
        reset('@app.forms', 'new-file');
        onClose();
    };

    render() {
        return (<EntityContext.Provider value={'@app.forms/new-file'}>
            <div className={'pad-sm rows gap-sm'}>
                <Field
                    id={'name'}
                    fieldType={'text'}
                    variant={'dense'}
                    label={'File name'}
                />
                <div className={'cols gap-xs cols-right'}>
                    <Button onClick={this.cancel}>Cancel</Button>
                    <Button onClick={this.submit}>Submit</Button>
                </div>
            </div>
        </EntityContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileCreate);