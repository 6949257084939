import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "components/Icon";
import IconButton from "joynt/components/IconButton";
import DateTimePicker from "components/fields/datepicker/DateTimePicker";
import Field from "containers/Field";
//import PropTypes from 'prop-types';

const FieldContext = React.createContext(null);

const generateDateInputProps = ({ value, onChange }) => ({
  className: "cursor-pointer",
  startAdornment: (
    <InputAdornment position={"start"}>
      <Icon className={"o-30"} fontSize={"default"}>
        mui-today
      </Icon>
    </InputAdornment>
  ),
  endAdornment: value ? (
    <InputAdornment position={"end"}>
      <IconButton
        size={"medium"}
        onClick={(e) => {
          e.stopPropagation();
          onChange(null);
        }}
        icon={"mui-close"}
      />
    </InputAdornment>
  ) : null,
});

function RenderDateField(props) {
  const { value, onChange } = props;
  const inputProps = generateDateInputProps({ value, onChange });
  return (
    <div>
      <FieldContext.Provider value={onChange}>
        <DateTimePicker
          {...props}
          className={"cursor-pointer"}
          fullWidth={true}
          InputProps={inputProps}
        />
      </FieldContext.Provider>
    </div>
  );
}

export default function DateSelect(props) {
  const { id, label } = props;
  return (
    <Field id={id} label={label}>
      {RenderDateField}
    </Field>
  );
}

DateSelect.propTypes = {};
DateSelect.defaultProps = {};
