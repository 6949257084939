import React, { useEffect } from "react";
//import PropTypes from 'prop-types';

import { useDispatch } from "react-redux";
import { loadWebfont } from "cms/state/actions/theme";

export function FontPreview(props) {
    const { source, family, size, weight, style, ...other } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if (source && family) dispatch(loadWebfont(source, family));
    }, [source, family, dispatch]);
    let previewStyle = style ? { ...style } : {};
    previewStyle.fontFamily = family;
    if (size) previewStyle.fontSize = size;
    if (weight) previewStyle.fontWeight = weight;
    return (
        <div style={previewStyle} {...other}>
            {props.children || family}
        </div>
    );
}

FontPreview.propTypes = {};

FontPreview.defaultProps = {};

export default FontPreview;
