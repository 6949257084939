import React, { useCallback } from "react";
import { useTrackVolume } from "joynt/meeting/stage/VolumeLevels";
import { useSampleTrackVolume } from "joynt/meeting/hooks/useSampleTrackVolume";

function AudioLevel({ id, max }) {
    const [value] = useTrackVolume(id);

    const percent = Math.round(((value * 100) / max) * 100) / 100;
    const style = {
        width: `${percent}%`,
    };

    return (
        <div>
            {value}
            <div className={"audio-level"}>
                <div className={"audio-level-meter"} style={style} />
            </div>
        </div>
    );
}

AudioLevel.defaultProps = {
    max: 1,
};

export default function AudioTrackControl(props) {
    const { audioTrack, id, visible } = props;

    const [, onSetVolume] = useTrackVolume(id);

    useSampleTrackVolume(audioTrack, onSetVolume);

    const toggle = useCallback(() => {
        if (audioTrack) {
            if (audioTrack.isPlaying) {
                audioTrack.stop();
            } else {
                audioTrack.play();
            }
        }
    }, [audioTrack]);

    return (
        <div className={"audio-track"} onClick={toggle}>
            {!audioTrack ? (
                "No audio track"
            ) : (
                <>{audioTrack.isPlaying ? "playing" : "stopped"}</>
            )}
            {visible ? <AudioLevel id={id} /> : null}
        </div>
    );
}
