import {
    NODE_TYPE_CHAT,
    NODE_TYPE_EVENT,
    NODE_TYPE_EXTERNAL,
    NODE_TYPE_FEED,
    NODE_TYPE_PAGE,
    NODE_TYPE_SESSIONS,
    NODE_TYPE_SPACE,
    NODE_TYPE_SPACES,
    NODE_TYPE_THREADS,
    POST_TYPE_ALBUM,
    POST_TYPE_TEXT,
    POST_TYPE_POLL,
    POST_TYPE_LINK,
    POST_TYPE_WHITEBOARD,
    NODE_TYPE_TAG,
    NODE_TYPE_PROJECT,
} from "joynt/config";
import { WORKSPACE_PLAN_DEFAULT, WORKSPACE_PLAN_PRO } from "joynt/config/plans";

export const types = {
    [NODE_TYPE_CHAT]: {
        id: NODE_TYPE_CHAT,
        label: "Chat",
        icon: "mui-chat",
        description: "Slack-like conversation",
    },
    [NODE_TYPE_FEED]: {
        id: NODE_TYPE_FEED,
        label: "Feed",
        icon: "mui-art_track",
        description: "Post updates, links and photos",
    },
    [NODE_TYPE_EVENT]: {
        id: NODE_TYPE_EVENT,
        label: "Session",
        icon: "mui-event",
        description: "Live video session",
        childTypes: [
            NODE_TYPE_FEED,
            NODE_TYPE_CHAT,
            NODE_TYPE_THREADS,
            NODE_TYPE_SESSIONS,
        ],
    },
    [NODE_TYPE_SPACE]: {
        id: NODE_TYPE_SPACE,
        label: "Room",
        icon: "mui-web",
        description: "Organize channels, arrange meeting space",
        childTypes: [NODE_TYPE_CHAT, NODE_TYPE_FEED, NODE_TYPE_EXTERNAL],
    },
    [NODE_TYPE_PROJECT]: {
        id: NODE_TYPE_PROJECT,
        label: "Project room",
        icon: "mui-work",
        childTypes: [NODE_TYPE_CHAT, NODE_TYPE_FEED, NODE_TYPE_EXTERNAL],
    },
    [NODE_TYPE_THREADS]: {
        id: NODE_TYPE_THREADS,
        label: "Q&A",
        icon: "mui-forum",
        description: "Forum with threads",
    },
    [NODE_TYPE_EXTERNAL]: {
        id: NODE_TYPE_EXTERNAL,
        label: "Link",
        icon: "mui-open_in_new",
        description: "Link to external page",
    },
    [NODE_TYPE_SESSIONS]: {
        id: NODE_TYPE_SESSIONS,
        label: "Breakout rooms",
        icon: "mui-group_work",
        description: "Group of parallel sessions",
        childTypes: [NODE_TYPE_SPACE],
        limitTypes: true,
    },
    [NODE_TYPE_PAGE]: {
        id: NODE_TYPE_PAGE,
        label: "Page",
        icon: "mui-description",
        description: "Wiki page",
    },

    [NODE_TYPE_SPACES]: {
        id: NODE_TYPE_SPACES,
        label: "Spaces",
        icon: "mui-view_module",
        description: "Collection of spaces",
    },
    [NODE_TYPE_TAG]: {
        id: NODE_TYPE_TAG,
        label: "Tags",
        icon: "mui-tag",
        description: "Tag",
    },
};

export const workspaceTypes = {
    [NODE_TYPE_SPACE]: {
        id: NODE_TYPE_SPACE,
        label: "Workspace",
        description:
            "Create your workspace with channels. Invite people, add chats, feeds and tasks. Start networking!",
        image: "https://joynt.works/thumb?file=joynt-website/user/app/create-type-workspace.svg",
        childTypes: [NODE_TYPE_CHAT, NODE_TYPE_FEED, NODE_TYPE_EVENT],
        childTypesPlans: {
            [WORKSPACE_PLAN_DEFAULT]: [
                NODE_TYPE_CHAT,
                NODE_TYPE_FEED,
                NODE_TYPE_EVENT,
            ],
            [WORKSPACE_PLAN_PRO]: [
                NODE_TYPE_CHAT,
                NODE_TYPE_FEED,
                NODE_TYPE_PROJECT,
                NODE_TYPE_EVENT,
            ],
        },
    },
    [NODE_TYPE_EVENT]: {
        id: NODE_TYPE_EVENT,
        label: "Event",
        description:
            "Create your event space. Invite people, add speakers and moderators to the sessions",
        image: "https://joynt.works/thumb?file=joynt-website/user/app/create-type-event.svg",
        childTypes: [
            NODE_TYPE_EVENT,
            NODE_TYPE_CHAT,
            NODE_TYPE_FEED,
            NODE_TYPE_SPACE,
        ],
    },
};

export const spaceTypes = {
    [NODE_TYPE_EVENT]: {
        id: NODE_TYPE_EVENT,
        label: "Session",
    },
    [NODE_TYPE_SPACE]: {
        id: NODE_TYPE_SPACE,
        label: "Room",
    },
    [NODE_TYPE_PROJECT]: {
        id: NODE_TYPE_PROJECT,
        label: "Project",
    },
};

export const viewTypes = {
    default: {
        id: "default",
        label: "Default",
    },
    webinar: {
        id: "webinar",
        label: "Webinar session",
    },
    none: {
        id: "none",
        label: "None",
    },
    about: {
        id: "about",
        label: "About",
    },
    agenda: {
        id: "agenda",
        label: "Event agenda",
    },
    "child-spaces": {
        id: "child-spaces",
        label: "Child spaces",
    },
    all: {
        id: "all",
        label: "All",
    },
    topic: {
        id: "topic",
        label: "Topic",
    },
    workspace: {
        id: "workspace",
        label: "Workspace",
    },
    "project-room": {
        id: "project-room",
        label: "Project room",
    },
};

export const postTypes = {
    [POST_TYPE_TEXT]: {
        id: POST_TYPE_TEXT,
        name: "Post",
        icon: "",
    },
    [POST_TYPE_ALBUM]: {
        id: POST_TYPE_ALBUM,
        name: "Album",
        icon: "mui-attach_file",
    },
    [POST_TYPE_LINK]: {
        id: POST_TYPE_LINK,
        name: "Link",
        icon: "mui-link",
    },
    [POST_TYPE_POLL]: {
        id: POST_TYPE_POLL,
        name: "Poll",
        icon: "mui-poll",
    },
    [POST_TYPE_WHITEBOARD]: {
        id: POST_TYPE_WHITEBOARD,
        name: "Whiteboard",
        icon: "mui-draw",
    },
};

export const contentViews = {
    feed: {
        id: "feed",
        label: "Feed",
    },
    cards: {
        id: "cards",
        label: "Cards",
    },
    discussion: {
        id: "discussion",
        label: "Discussions",
    },
};
