import React from "react";
//import PropTypes from 'prop-types';

export function Header(props) {
    const { children } = props;

    return <div className={"app-header"}>{children}</div>;
}

function HeaderTitle(props) {
    return (
        <div className={"app-header-title"} {...props}>
            {props.children}
        </div>
    );
}

function HeaderActions(props) {
    return (
        <div className={"app-header-actions"} {...props}>
            {props.children}
        </div>
    );
}

Header.Title = HeaderTitle;
Header.Actions = HeaderActions;

Header.propTypes = {};
Header.defaultProps = {};
