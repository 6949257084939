import React from "react";
import OwlCarousel from "react-owl-carousel2";

import { changed } from "util/changed";

const owlOptions = {
    nav: true,
    navText: [],
    dots: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false,
    loop: true,
};

export default class LockSlider extends React.PureComponent {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return changed(
            ["options.items", "options.margin", "items", "className", "s"],
            this.props,
            nextProps
        );
    }
    render() {
        const { items, ...other } = this.props;
        return (
            <OwlCarousel
                {...other}
                options={{ ...owlOptions, ...this.props.options }}
            />
        );
    }
}
