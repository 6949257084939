import React from 'react';
import PropTypes from 'prop-types';

export function EmptyState(props) {

    const {text} = props;

    return (<div className={"avatar-group__empty-state"}>
        {text}
    </div>);

}

EmptyState.propTypes = {
    text: PropTypes.string
};
EmptyState.defaultProps = {
    text: "No one joined yet"
};

export default EmptyState;
