import React, { useCallback } from "react";
import EchoProvider from "joynt/websockets/EchoProvider";
import useListen from "joynt/websockets/hooks/useListen";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "state/selectors/app";
import usePublicChannel from "joynt/websockets/hooks/usePublicChannel";
import { useActions } from "state/hooks";
import { addConsoleNotification } from "cms/state/actions/notifications";

//import PropTypes from 'prop-types';

function Channel(props) {
    const { onAdd } = useActions({
        onAdd: addConsoleNotification,
    });
    const handler = useCallback(
        (e) => {
            onAdd(e);
        },
        [onAdd]
    );
    const user = useSelector(selectCurrentUserId);
    const channel = `user-${user}`;
    const namespace = ".notification";
    const sub = usePublicChannel(channel);
    useListen(sub, namespace, handler);
    return props.children;
}

export function WebsocketClient(props) {
    const { children } = props;

    return (
        <EchoProvider>
            <Channel {...props} />
        </EchoProvider>
    );
}

WebsocketClient.propTypes = {};

WebsocketClient.defaultProps = {};

export default WebsocketClient;
