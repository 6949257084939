import React from 'react';
import {connect} from 'react-redux';

import PageLayout from 'cms/PageLayout';
import {withData} from "containers/Entity";
import {styled} from "cms/styles/legacy";

const mapStateToProps = (store, props) => {
    const style = ['page-layout',props.data.description].join('.');
    return {
        element_type: 'page-layout',
        style,
        label: props.title || props.description
    }
};

const mapDispatchToProps = {};

class PageLayoutContainer extends React.PureComponent {

    render() {
        return (<PageLayout {...this.props}>
            {this.props.children}
        </PageLayout>);
    }

}

PageLayoutContainer.autoFillProps = [
    'style',
    'styles',
    'layout',
    'sections',
    'description',
    'title'
];

export default withData(connect(
    mapStateToProps,
    mapDispatchToProps
)(styled(PageLayoutContainer)));