import React from "react";
import PropTypes from "prop-types";
import PostTitle from "joynt/components/Post/PostTitle";
import PostUnder from "./PostUnder";
import { POST_TYPE_ALBUM, POST_TYPE_POLL, POST_TYPE_TEXT } from "joynt/config";
import IconButton from "components/IconButton";

const titleLabels = {
    [POST_TYPE_TEXT]: "Add post title",
    [POST_TYPE_POLL]: "Add poll title",
    [POST_TYPE_ALBUM]: "Add post title",
};

export function PostCreateHeader(props) {
    const { id, parent, type, showPostUnder, onShowPost } = props;

    return (
        <div className={"cols cols-spread cols-middle"}>
            <div className={"grow"}>
                <PostTitle key={id} id={id} placeholder={titleLabels[type]} />
            </div>
            <div>
                <PostUnder id={id} parent={parent} show={showPostUnder} />
            </div>
            <div>
                <IconButton
                    size={"small"}
                    onClick={onShowPost}
                    icon={"mui-fullscreen"}
                />
            </div>
        </div>
    );
}

PostCreateHeader.propTypes = {
    id: PropTypes.string,
    parent: PropTypes.string,
    showPostUnder: PropTypes.bool,
    onShowPost: PropTypes.func,
};
PostCreateHeader.defaultProps = {
    showPostUnder: false,
};

export default PostCreateHeader;
