import React, { useCallback } from "react";
import SidebarTray from "./SidebarTray";
import { useWorkspaces } from "joynt/hooks/channels";
import { useNodeData } from "joynt/hooks";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup";
import { useClick, useRouteParam } from "state/hooks";
import { ROUTE_NAV, ROUTE_PAGE } from "joynt/config";
import IconButton from "joynt/components/IconButton";

//import PropTypes from 'prop-types';

function NavGroupContainer(props) {
    const { id, onClick, selected } = props;

    const sub = useWorkspaces(id);
    const itemProps = (id) => ({ onClick, isActive: id === selected });

    if (!sub.length) return <NavItemContainer id={id} {...itemProps(id)} />;

    return (
        <NavGroup isActive={[id, ...sub].indexOf(selected) > -1}>
            <NavItemContainer id={id} {...itemProps(id)} />
            {sub.map((s) => (
                <NavItemContainer key={s} id={s} {...itemProps(s)} />
            ))}
        </NavGroup>
    );
}

function NavItemContainer(props) {
    const { id } = props;

    const { name, icon, color, workspace_abbr } = useNodeData(id);

    const click = useClick(props);

    return (
        <NavItem
            name={name}
            icon={icon}
            color={color}
            abbr={workspace_abbr}
            {...props}
            onClick={click}
        />
    );
}

export function SidebarTrayContainer(props) {
    const workspaces = useWorkspaces(null);
    const { onClick, selected, ...other } = props;

    const [map, showMap] = useRouteParam(ROUTE_NAV);
    const [page, setPage] = useRouteParam(ROUTE_PAGE);
    const isCreateWorkspaceOpen = page === "create-workspace";

    const create = useCallback(() => {
        setPage("create-workspace");
    }, [setPage]);
    const { node_type } = useNodeData(selected);

    const homeClick = useCallback(
        (e) => {
            if (e.altKey || map) {
                showMap(!map || null);
                return;
            }
            onClick(null);
        },
        [onClick, showMap, map]
    );

    return (
        <SidebarTray
            {...other}
            footer={
                <IconButton
                    disabled={isCreateWorkspaceOpen}
                    size={"medium"}
                    onClick={create}
                    icon={"mui-add_circle"}
                />
            }
        >
            <NavItem
                icon={map ? "mui-close" : "mui-home"}
                color={"teal"}
                isActive={node_type === "root"}
                onClick={homeClick}
            />
            {workspaces.map((id) => (
                <NavGroupContainer
                    key={id}
                    id={id}
                    onClick={onClick}
                    selected={selected}
                />
            ))}
        </SidebarTray>
    );
}

SidebarTrayContainer.propTypes = {};
SidebarTrayContainer.defaultProps = {};

export default SidebarTrayContainer;
