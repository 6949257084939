import {useCallback, useRef, useState} from "react";
import useSizeObserver from "util/hooks/useSizeObserver";

export function useOverflowToggle() {
    const contentRef = useRef();

    const [showContent, setShowContent] = useState(false);
    const [hasMoreContent, setHasMoreContent] = useState(false);

    const resizeCallback = useCallback(() => {
        setHasMoreContent(
            contentRef.current &&
            contentRef.current.scrollHeight >
            contentRef.current.offsetHeight
        );
    }, [contentRef]);

    useSizeObserver({
        callback: resizeCallback,
        element: contentRef,
    });

    const toggleShowContent = () => setShowContent(!showContent);

    return {
        onToggle: toggleShowContent,
        hasMoreContent,
        showContent,
        ref: contentRef
    }
}