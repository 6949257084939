import React from "react";
import Icon from "components/Icon";
import cn from 'classnames';

import 'cms/ui/css/style-select.css';

const icons = {
    'text--left': 'mui-format_align_left',
    'text--center': 'mui-format_align_center',
    'text--right': 'mui-format_align_right',
    'text--centered': 'mui-subject',
    'text--auto': 'mui-sync_alt',
    'l-cols': 'mui-view_column',
    'l-rows': 'mui-view_stream',
    'l-grid': 'mui-view_comfy',
    'l-layers': 'mui-layers',
    'l-cols-text': 'hide',
    'l-masonry': 'mui-dashboard'
};

export function IconToggle(props) {
    const { value, values, onChange } = props;
    return <div className={'grid grid-8 gap-px pad-xs'}>
        {values.map(id => {
            const checked = value && value.indexOf(id) > -1;
            const icon = icons[id];
            return icon !== 'hide' || checked ? <div
                className={cn('color-swatch',id, {checked})}
                onClick={()=>onChange(id)}
                key={id}
            >
                <Icon>{icon}</Icon>
            </div> : null;
        })}
    </div>;
}