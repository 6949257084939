import React from "react";
import "./style.css";
import Button from "components/Button";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export function StageOverlay(props) {
    const { onClick } = props;
    return (
        <div className={"back-to-session-overlay dark-mode"} onClick={onClick}>
            <Button
                onClick={() => null}
                variant={"outlined"}
                startIcon={<Icon>mui-open_in_full</Icon>}
            >
                Full session view
            </Button>
            <div className={"back-to-session-overlay__icon"}>
                <Icon>mui-open_in_full</Icon>
            </div>
        </div>
    );
}

StageOverlay.propTypes = {};
StageOverlay.defaultProps = {};

export default StageOverlay;
