import React from 'react';
import Button from "components/Button";

//import PropTypes from 'prop-types';

import "./style.css";
import {LiveSessionIcon} from "joynt/components/Sidebar/Icons";
import Icon from "components/Icon";

export function NodeInfo(props) {

    const {
        name,
        stageName,
        onJoin,
        onLink,
        isConnected,
        //isConnecting,
        isLive,
        timer,
        avatars
    } = props;

    return (<div className={"session-info-popover"}>
        <div className={"session-info-popover__header"}>
            <div className={"session-info-popover__stage"}>
                {isLive && <div className={"session-info-popover__icon"}>
                    <LiveSessionIcon size={"sm"} isConnected={isConnected} />
                </div>}
                <div>{stageName}</div>
            </div>
            <div className={"session-info-popover__timer"}>
                {timer}
            </div>
        </div>
        <div className={"session-info-popover__session_name"}>
            {name}
        </div>
        <div>{avatars}</div>
        <div className={"session-info-popover__buttons"}>
            {isLive && !isConnected && <Button onClick={onJoin}>
                Join now
            </Button>}
            <Button
                onClick={onLink}
                variant={"text"}
                endIcon={<Icon>mui-arrow_forward</Icon>}
            >
                Go to session page
            </Button>
        </div>
    </div>);

}

NodeInfo.propTypes = {};
NodeInfo.defaultProps = {};

export default NodeInfo;
