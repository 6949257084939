import React from "react";
import FormLayout from "components/auth/FormLayout";
import Field from "containers/Field";
import SocialLogin from "components/auth/SocialLogin";
//import PropTypes from 'prop-types';

export default function SignupForm(props) {
    const { onSignup, onChangeView, urls, enableSocial } = props;

    return (
        <FormLayout
            {...props}
            onSubmit={onSignup}
            submitLabel={"Signup"}
            footer={
                <>
                    {enableSocial ? <SocialLogin /> : null}
                    <div className={"grow cols cols-center gap-xs"}>
                        <div>Joined us before?</div>
                        <div
                            className={"link"}
                            onClick={() => onChangeView("login")}
                        >
                            Login here
                        </div>
                    </div>
                </>
            }
        >
            <Field
                label={"E-mail"}
                id={"username"}
                fieldType={"text"}
                validate={true}
            />
            <Field
                label={"Password"}
                id={"password"}
                fieldType={"text"}
                type={"password"}
                validate={true}
            />
            <Field
                label={"Confirm password"}
                id={"password_confirm"}
                fieldType={"text"}
                type={"password"}
                validate={true}
            />
            {urls ? (
                <div className={"rows rows-center"}>
                    <Field
                        label={
                            <span>
                                I accept{" "}
                                <a
                                    href={urls.tos}
                                    className={"link"}
                                    target={"_blank"}
                                >
                                    Terms of service
                                </a>{" "}
                                and{" "}
                                <a
                                    href={urls.privacy}
                                    className={"link"}
                                    target={"_blank"}
                                >
                                    Privacy policy
                                </a>{" "}
                                (required)
                            </span>
                        }
                        id={"accept_terms"}
                        fieldType={"checkbox"}
                        variant={"checkbox"}
                    />
                </div>
            ) : null}
        </FormLayout>
    );
}

SignupForm.propTypes = {};
SignupForm.defaultProps = {};
