import React from "react";
//import PropTypes from 'prop-types';

import SessionPageSection from "joynt/components/SessionPage/SessionPageSection";
import SessionCoverTile from "joynt/components/SessionCoverTile";
import SessionSummary from "joynt/components/SessionPage/SessionSummary";

import "./style.css";

export function SessionInfo(props) {
    const { id, showAttendance } = props;

    return (
        <SessionPageSection className={"networking-session-info"}>
            <SessionSummary id={id} showAttendance={showAttendance} />
            <SessionCoverTile id={id} showPresence={true} />
        </SessionPageSection>
    );
}

SessionInfo.propTypes = {};
SessionInfo.defaultProps = {
    showAttendance: true
};

export default SessionInfo;
