import React from "react";
import PropTypes from "prop-types";
import EmptyState from "./EmptyState";

import "./style.css";

export function SidebarSection(props) {
    const { name, icon, onClick, emptyState, children } = props;
    const count = React.Children.count(children);

    return (
        <div className={"sidebar-nav-section"} id={name && `nav-${name}`}>
            {name && (
                <div className={"sidebar-nav-section__header"}>
                    <div className={"sidebar-nav-section__name"} onClick={onClick}>{name}</div>
                    <div className={"sidebar-nav-section__icon_area"}>
                        {icon}
                    </div>
                </div>
            )}
            {count || !emptyState ? children : <EmptyState>
                {emptyState}
            </EmptyState>}
        </div>
    );
}

SidebarSection.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.node,
};

SidebarSection.defaultProps = {};

export default SidebarSection;
