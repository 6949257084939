import React from "react";
import PropTypes from "prop-types";
import SessionHeader from "./SessionHeader";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useSessionLayout } from "joynt/components/SessionLayout";
import { EventTimer } from "../LiveNow/EventTimer";
import SpaceAvatar from "joynt/components/SpaceAvatar";
import RaisedHand from "./RaisedHand";
import useScreenSize from "util/hooks/useScreenSize";

export function SessionHeaderContainer(props) {
    const { id, session } = props;
    const { name } = useNodeData(id);

    const { parent: parentId } = useEdgesData(id);
    const { name: parentName } = useNodeData(parentId);
    const { expanded, onToggle } = useSessionLayout();

    const { isDesktop, isMobile } = useScreenSize();

    return (
        <SessionHeader
            isDesktop={isDesktop}
            isMobile={isMobile}
            isExpanded={expanded}
            onToggleExpand={onToggle}
            timer={<EventTimer id={id} />}
            avatar={<SpaceAvatar id={id} noTooltip={true} />}
            controls={<RaisedHand id={session} />}
            {...{ name, parentName }}
            {...props}
        />
    );
}

SessionHeaderContainer.propTypes = {
    id: PropTypes.string,
};
SessionHeaderContainer.defaultProps = {};

export default SessionHeaderContainer;
