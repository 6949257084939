export default class DebugReport {
    constructor(client) {
        this.client = client;
        this.rtc = client.rtc;
        this.prev = {};
    }
    clientReport = (id, client) => {
        const users = client.remoteUsers;
        return {
            id,
            joined: id === 'main' || this.rtc.joined.indexOf(client) > -1,
            state: client.connectionState,
            users: users.length,
            audio: users.filter(u=>!!u._audio_added_).length,
            video: users.filter(u=>!!u._video_added_).length,
            audioUsers: users.filter(u=>!!u._audio_added_).map(u=>u.uid),
            videoUsers: users.filter(u=>!!u._video_added_).map(u=>u.uid),

        };
    }
    users = () => {
        let users = Object.values(this.rtc.users.get());
        users = users.map(u => {
            let id = u.uid;
            let pub = this.rtc.subscriptions.published[id];
            return {
                id,
                audio: !!u.audioTrack,
                video: !!u.videoTrack,
                client: this.rtc.relay.index[id],
                published: !!pub,
                publishedAudio: pub && pub._audio_added_,
                publishedVideo: pub && pub._video_added_,
                videoStats: pub && !!pub.videoTrack ? pub.videoTrack.getStats() : null,
                subscribed: this.rtc.subscriptions.subscribed[id] || this.rtc.subscriptions.subscribedQueue[id],
                subError: this.rtc.subscriptions.errors[id]
            }
        });
        return users;
    }
    generate = () => {
        const clients = [];

        clients.push(this.clientReport('main', this.rtc.client));

        this.rtc.clients.forEach((client, i) => {
            clients.push(this.clientReport(i, client));
        });

        const audio = clients.reduce((ids, c) => ids.concat(c.audioUsers), []);
        const video = clients.reduce((ids, c) => ids.concat(c.videoUsers), []);

        const users = this.users().map(u => {
            u.publishedAudio = audio.indexOf(u.id) > -1;
            u.publishedVideo = video.indexOf(u.id) > -1;
            return u;
        });

        return {
            clients,
            users,
            ts: Date.now()
        };
    }
    get() {
        try {
            let next = this.generate();
            this.prev = next;
            return next;
        } catch (e) {
            return {
                ...this.prev,
                error: e.message
            }
        }
    }
}