import React from "react";
import { useActions, useCover, useData } from "state/hooks";
import cn from "classnames";
//import PropTypes from 'prop-types';

import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ContextMenu from "components/ContextMenu";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import MenuItem from "components/context-menu/MenuItem";
import { change, pathDetach } from "state/actions/data";

function MenuItems(props) {
    const { type, id, onDeleteItem } = props;
    const { active } = useData(props);
    const { onChange } = useActions({
        onChange: change,
        onDetach: pathDetach,
    });
    const menu = [
        <MenuItem
            key={"ctx-active"}
            onClick={() => onChange(type, id, { active: !active })}
            icon={active ? "mui-close" : "mui-check"}
            primaryText={active ? "Disable" : "Enable"}
        />,
    ];
    if (onDeleteItem)
        menu.push(
            <MenuItem
                key={"ctx-delete"}
                onClick={() => onDeleteItem(id)}
                icon={"mui-delete"}
                primaryText={"Delete"}
            />
        );

    return menu;
}

export const CollectionItem = React.forwardRef((props, ref) => {
    const { type, id, onClick, isDragging, onDelete } = props;
    const data = useData(props);
    const { media } = data;
    const image = useCover(media, [200, 150], "cms");
    const noTitle = !data.title;

    const primaryText =
        type === "cms.links"
            ? data.label || "Nienazwany element"
            : data.title || "Bez tytułu";

    const secondaryText = data.url || data.headline;

    return (
        <ContextMenu
            type={type}
            id={id}
            onDeleteItem={onDelete}
            RenderMenuItems={MenuItems}
        >
            {({ onMenuOpen }) => (
                <MuiListItem
                    onClick={() => onClick(id)}
                    onContextMenu={onMenuOpen}
                    ref={ref}
                    className={cn({
                        "list-item": true,
                        "is-dragging": isDragging,
                        "no-title": noTitle,
                        "is-disabled": !data.active,
                    })}
                    button
                >
                    <ListItemAvatar>
                        <Avatar
                            className={data.active ? "t-primary" : null}
                            src={image}
                        >
                            <Icon>{data.icon}</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={JSON.stringify(primaryText)}
                        secondary={JSON.stringify(secondaryText)}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            icon={"mui-more_vert"}
                            onClick={onMenuOpen}
                            size={"small"}
                        />
                    </ListItemSecondaryAction>
                </MuiListItem>
            )}
        </ContextMenu>
    );
});

CollectionItem.propTypes = {};

CollectionItem.defaultProps = {};

export default CollectionItem;
