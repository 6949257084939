import React from "react";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

import "./style.css";

export function RaisedHand(props) {
    const { children, count } = props;

    if (!count) return null;

    return (
        <div className={"session-header-hand"}>
            <div className={"session-header-hand__avatars"}>{children}</div>
            <div className={"session-header-hand__icon"}>
                <Icon>mui-pan_tool</Icon>
            </div>
        </div>
    );
}

RaisedHand.propTypes = {};
RaisedHand.defaultProps = {};

export default RaisedHand;
