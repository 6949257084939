import React from "react";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
import { useFilter } from "state/hooks";
import Select from "components/fields/select/Select";
import TextField from "components/fields/text/TextField";
import Chip from "@material-ui/core/Chip";
//import PropTypes from 'prop-types';

const orderOptions = [
    { id: "created_at desc", label: "Newest" },
    { id: "published_at desc,created_at desc", label: "Recent comments" },
];

function Filters(props) {
    const { id, list, filter } = props;
    const { value, onSet } = useFilter(filter, "db.nodes", list);

    const url = `joynt/nodes/${id}/tags`;
    const tagsFilterId = `tags.${id}`;

    return (
        <div className={"rows gap-sm pad-sm"}>
            <TextField
                onChange={(v) => onSet("search", v)}
                label={"Add post title"}
                value={value.search}
                variant={"search"}
            />
            <div className={"cols gap-xs"}>
                {orderOptions.map((option) => {
                    return (
                        <Chip
                            key={option.id}
                            onClick={() => onSet("order", option.id)}
                            label={option.label}
                            color={
                                value.order === option.id
                                    ? "primary"
                                    : "default"
                            }
                        />
                    );
                })}
            </div>
            <Select
                label={"Tag"}
                id={tagsFilterId}
                url={url}
                type={"db.nodes"}
                list={tagsFilterId}
                value={value["id"]}
                onChange={(v) => onSet("id", v)}
                nullable={true}
            />
            <Select
                label={"Posted by"}
                type={"db.identities"}
                list={`participants.${id}`}
                value={value["identity"]}
                onChange={(v) => onSet("identity", v)}
                nullable={true}
            />
        </div>
    );
}

export default function FeedFilter(props) {
    //const {} = props;

    return (
        <Popover
            trigger={({ onClick }) => (
                <IconButton
                    label={"Filter"}
                    icon={"mui-filter_list"}
                    onClick={onClick}
                />
            )}
            content={({ onClose }) => <Filters {...props} />}
        />
    );
}

FeedFilter.propTypes = {};
FeedFilter.defaultProps = {};
