import React, { useEffect, useRef } from "react";
import { useJoyrideProvider } from "./hooks/useJoyrideProvider";

import Joyride from "./Joyride";
import * as types from "./types";

function JoyrideProvider() {
    const {
        currentJoyrideId: id,
        currentJoyrideRun: run,
        setJoyride,
        setJoyrideShouldRun,
    } = useJoyrideProvider();

    const prevJoyride = useRef();

    useEffect(() => {
        if (prevJoyride?.id !== id) {
            setJoyrideShouldRun(false);
        }
        if (id) {
            prevJoyride.current = { id, run };
            setJoyrideShouldRun(true);
        }
    }, [id, run, setJoyrideShouldRun]);

    const handleJoyrideState = (data) => {
        const { action } = data;

        if (action === "reset") {
            setJoyrideShouldRun(false);
            setJoyride(null);
        }
    };

    if (!id) {
        return null;
    }

    const steps = types[id];

    return (
        <Joyride
            steps={steps}
            key={id}
            run={run}
            callback={handleJoyrideState}
        />
    );
}

export default JoyrideProvider;

JoyrideProvider.propTypes = {};
