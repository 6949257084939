import React from 'react';
//import PropTypes from 'prop-types';

import {useEntity} from "joynt/edit/hooks";
import CategorySelect from "joynt/edit/CategorySelect";
import {sessionTypes} from "joynt/config/sessions";

export function SessionType(props) {

    const {data, onChange} = useEntity();
    const {session_type: value} = data;

    const change = (type) => {
        let update = sessionTypes[type]?.data || {};
        onChange(update);
    }

    return <CategorySelect
        options={Object.values(sessionTypes)}
        onChange={change}
        value={value}
    />;
}

SessionType.propTypes = {};
SessionType.defaultProps = {};

export default SessionType;
