import React from 'react';
//import PropTypes from 'prop-types';

export function EmptyState(props) {

    //const {} = props;

    return (<div className={"cols cols-center o-50"}>
        No sessions in selected range
    </div>);

}

EmptyState.propTypes = {};
EmptyState.defaultProps = {};

export default EmptyState;
