import React from "react";
import cn from "classnames";

//import PropTypes from 'prop-types';

export function Property(props) {
    const { onClick, label, placeholder, isEmpty, children } = props;
    return (
        <div
            onClick={onClick}
            className={cn("property", {
                "is-empty": isEmpty || !children,
            })}
        >
            <div className={"property-label"}>{label}</div>
            <div className={"property-value"}>{children || placeholder}</div>
        </div>
    );
}

Property.defaultProps = {
    placeholder: "Wybierz",
};

Property.propTypes = {};

export default Property;
