import React from 'react';
import {connect} from 'react-redux';
import ApiContext from "containers/context/ApiContext";
import RequireAuthentication from "containers/RequireAuthentication";
import ThemeProvider from "containers/Theme";
import Bootstrap from "containers/Bootstrap";
import ListFormView from "console/ListFormView";
import Modal from "containers/ModalStack";
import MediaPreview from "containers/dialogs/MediaPreview";
import ConfirmDialog from "containers/dialogs/Confirm";
import Snackbar from "components/Snackbar";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

const apiContext = {noContext: true};

class ConsoleView extends React.Component {

    render() {
        return (<ApiContext.Provider value={apiContext}>
            <RequireAuthentication>
                <ThemeProvider target={true} loadFonts={true}>
                    <Bootstrap serviceType={'console'}>
                        <ListFormView />
                        <Modal />
                        <MediaPreview />
                        <ConfirmDialog />
                        <Snackbar/>
                    </Bootstrap>
                </ThemeProvider>
            </RequireAuthentication>
        </ApiContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsoleView);