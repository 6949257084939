import React, { useCallback, useContext, useMemo, useState } from "react";
import Popper, { usePopper } from "joynt/components/Popper";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
//import PropTypes from 'prop-types';

import "./style.css";

export const NotificationStatusContext = React.createContext(null);

export function useNotificationStatusOverlay() {
    return (
        useContext(NotificationStatusContext) || {
            onShow: () => null,
            onHide: () => null,
        }
    );
}

export default function NotificationStatusOverlay(props) {
    const { children } = props;

    const [ids, setIds] = useState(null);
    const { onShow, onHide, state } = usePopper();

    const show = useCallback(
        (evt, ids) => {
            setIds(ids);
            onShow(evt);
        },
        [setIds, onShow]
    );

    const context = useMemo(
        () => ({
            onShow: show,
            onHide,
        }),
        [show, onHide]
    );

    return (
        <NotificationStatusContext.Provider value={context}>
            {children}
            <Popper {...state} onHide={onHide} placement={"top"}>
                <div className={"notification-status-tooltip"}>
                    {ids?.map((id) => {
                        return <IdentityAvatarContainer id={id} size={"xs"} />;
                    })}
                </div>
            </Popper>
        </NotificationStatusContext.Provider>
    );
}

NotificationStatusOverlay.propTypes = {};
NotificationStatusOverlay.defaultProps = {};
