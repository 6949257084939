import React from 'react';
import PropTypes from 'prop-types';

export default class ModalOverlay extends React.PureComponent {

    render() {
        const { onClick } = this.props;
        return (<div
            onClick={onClick}
            className={'modal__overlay'} />);
    }

}

ModalOverlay.propTypes = {
    onClick: PropTypes.func
};