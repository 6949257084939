import React from "react";
import cn from "classnames";
import Html from "components/Html";

import { renderMentions } from "joynt/components/messages";

import { MessageBodyDeleted } from "joynt/components/messages/message/subcomponents";
import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

export function MessageBodyWide(props) {
    const {
        messageClick,
        onHide,
        content,
        isDeleted,
        files,
        link,
        addReactions,
        isOwn,
    } = props;

    if (isDeleted) return <MessageBodyDeleted />;

    return (
        <section
            data-private
            className={cn(
                "message__body",
                `message__body--wide`,
                isOwn && "message__body--own"
            )}
        >
            <div className={"message__content"}>
                {content && (
                    <Html
                        onClick={messageClick}
                        onMouseLeave={onHide}
                        className={"message__text"}
                        value={renderMentions(content)}
                    />
                )}
                {link}
                {files}
                <div className={"message__content-menu"}>{addReactions}</div>
            </div>
        </section>
    );
}

MessageBodyWide.propTypes = {
    messageClick: MessagePropTypes.messageClick,
    onHide: MessagePropTypes.onHide,
    content: MessagePropTypes.content,
    isDeleted: MessagePropTypes.isDeleted,
    files: MessagePropTypes.files,
    link: MessagePropTypes.link,
    addReactions: MessagePropTypes.addReactions,
    isOwn: MessagePropTypes.isOwn,
};

export default MessageBodyWide;
