import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

import "./style.css";
import SessionControls from "joynt/components/SessionControls/SessionControls.container";
import { useMoment } from "joynt/components/DateTimePicker/hooks";
import Preloader from "components/Preloader";

export function WitnessSession(props) {
    const {
        onStartSession,
        hasSession,
        session,
        start,
        isDraft,
        isPaired,
        isWitness,
        isPending,
    } = props;

    const m = useMoment(start);

    return (
        <div className={"witness-session"}>
            <Preloader visible={isPending} />
            {isWitness ? (
                <div className={"witness-session__content"}>
                    <div>{m.format("DD MMM, HH:mm")}</div>
                    {!isDraft && isPaired && (
                        <div className={"witness-session__controls"}>
                            {hasSession ? (
                                <SessionControls id={session} />
                            ) : (
                                <Button onClick={onStartSession}>
                                    Start witness session
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div>TODO: Implement enabling witness for private tasks</div>
            )}
        </div>
    );
}

WitnessSession.propTypes = {
    hasSession: PropTypes.bool,
    isDraft: PropTypes.bool,
    isPaired: PropTypes.bool,
    isWitness: PropTypes.bool,
    isPending: PropTypes.bool,
    start: PropTypes.number,
};
WitnessSession.defaultProps = {
    hasSession: false,
    isDraft: false,
    isWitness: false,
    isPaired: false,
};

export default WitnessSession;
