import React from "react";

//import PropTypes from 'prop-types';

import "./style.css";

export const PostView = React.forwardRef((props, ref) => {
    const { children } = props;

    return (
        <div ref={ref} className={"post-view"}>
            {children || null}
        </div>
    );
});

PostView.propTypes = {};
PostView.defaultProps = {};

export default PostView;
