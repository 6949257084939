export default class ClientRelay {
    constructor(client) {
        this.client = client;
        this.reset();
    }

    publish(mediaType, clientId) {
        if (mediaType === 'audio')
            return this.getClient('main');

        this.index.own = this.assignClient();
        console.log(`[ClientRelay] Assigned client`, this.index.own);
        return this.getClient(this.index.own);
    }

    published(mediaType) {
        if (mediaType === 'audio')
            return this.getClient('main');

        return this.getClient(this.index.own);
    }

    subscribe(remoteUser, mediaType, clientId) {
        if (mediaType === 'audio' || this.isScreenShare(remoteUser))
            return this.getClient('main');

        let id = this.index[remoteUser.uid];
        console.log(`[ClientRelay] select client for ${remoteUser.uid}`, id);
        return this.getClient(id);
    }

    isScreenShare(remoteUser) {
        return remoteUser.uid.indexOf('screen_') === 0;
    }

    register(remoteUser, clientId) {
        this.index[remoteUser.uid] = clientId;
        console.log(`[ClientRelay] register ${remoteUser.uid}`, clientId);
    }

    unregister(remoteUser) {
        this.index[remoteUser.uid] = null;
        console.log(`[ClientRelay] unregister ${remoteUser.uid}`);
    }

    assignClient() {
        if (!this.client.joined.length) return 'main';
        let max = this.client.joined.length+1;
        let random = Math.floor(Math.random() * max);
        if (random === 0) return 'main';
        return random-1;
    }

    getClient(id) {
        if ([undefined, null].indexOf(id) > -1)
            throw new Error("[ClientRelay] Client id not provided");

        if (id === 'main') return this.client.client;
        return this.client.joined[id];
    }

    reset() {
        this.index = {};
    }
}