import { selectList } from "state/selectors/data";
import { createSelector } from "reselect";

const nullArr = [];
const nullObj = {};

export const isPending = (store, ids, strict) => {
    if (!Array.isArray(ids)) {
        if (strict) return store.pending[ids] !== false;
        return store.pending[ids];
    }
    for (let i = 0; i < ids.length; i++)
        if (isPending(store, ids[i], strict)) return true;

    return false;
};

export const selectProgress = (store, id) => {
    return store.progress[id] || null;
};

export const selectFlag = (store, id) => {
    if (!store.ui) return null;
    return store.ui[id];
};

export const pinnedResourcesSelector = (types) => {
    //const types = selectList(store, 'db.types', 'db.types');
    const filtered = Object.values(types).filter((item) => {
        return !item.subtype_of && !!item.quick_access && item.type !== "job";
    });
    const result = filtered.map((item) => item.id);
    if (result.length === 0) return nullArr;
    return result;
};

export const userResourcesSelector = (types) => {
    const filtered = Object.values(types).filter((item) => {
        return !item.subtype_of && item.type !== "job";
    });
    const result = filtered.map((item) => item.id);
    if (result.length === 0) return nullArr;
    return result;
};

export const selectPinnedResources = createSelector(
    [(store) => selectList(store, "app.user-menu", "app.user-menu")],
    (types) => {
        return pinnedResourcesSelector(types);
    }
);

export const selectUserResources = createSelector(
    [(store) => selectList(store, "app.user-menu", "app.user-menu")],
    (types) => {
        return userResourcesSelector(types);
    }
);

export const selectSnackbarNotification = (store) => {
    let notifications = store.ui.snackbarNotifications;
    if (notifications && notifications.length) return notifications[0];
    return nullObj;
};
