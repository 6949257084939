import React, { useCallback, useEffect } from "react";
import Icon from "components/Icon";
import Button from "components/Button";
import { useRouter } from "joynt/hooks/channels";
//import PropTypes from 'prop-types';

export function ErrorPage(props) {
    const { text, action } = props;

    useEffect(() => {
        document.body.classList.add("no-access");
        return () => {
            document.body.classList.remove("no-access");
        };
    }, []);

    return (
        <figure className={"grow rows rows-center rows-middle fill pad-lg"}>
            <div className={"rows rows-center gap-md"}>
                <picture className={"brand-no-access"} />
                <figcaption className={"type-heading text-center o-60"}>
                    {text}
                </figcaption>
                {action}
            </div>
        </figure>
    );
}

export default function NoAccess({ text }) {
    const { onLink } = useRouter();
    const home = useCallback(() => onLink(null), [onLink]);

    return (
        <ErrorPage
            text={text}
            action={
                <Button
                    startIcon={<Icon>mui-arrow_back</Icon>}
                    onClick={home}
                    variant={"text"}
                >
                    Back to Home
                </Button>
            }
        />
    );
}

NoAccess.propTypes = {};
NoAccess.defaultProps = {
    text: (
        <span>
            It seems that this space doesn't exist
            <br />
            or you don't have access
        </span>
    ),
};
