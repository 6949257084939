import React from "react";
import { ComponentMappingContext, useZmapContext } from "./ZmapContext";
import {
    parseZmap,
    processZmap,
    stringToMap,
} from "playground/designer/Zmap/zmap";
import { useData } from "state/hooks";

function useComponentZmap(type, id) {
    const context = useZmapContext();
    const data = useData({ type, id });
    const zmapString = data?.template_id;

    let zmap, result, error;

    try {
        if (zmapString) {
            zmap = parseZmap(zmapString);
            result = processZmap(zmap, context);
        }
    } catch (e) {
        error = e.message;
    }

    return {
        zmap,
        result,
        error,
        fields: stringToMap(zmapString),
    };
}

export function ComponentMappingProvider(props) {
    const mapping = useComponentZmap(props.type, props.id);
    return (
        <ComponentMappingContext.Provider value={mapping}>
            {props.children}
        </ComponentMappingContext.Provider>
    );
}

export default ComponentMappingProvider;
