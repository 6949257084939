import React, {useCallback} from 'react';
import moment from "moment";
import {Tab} from "joynt/components/Tabs";
//import PropTypes from 'prop-types';

export default function Day({onClick, day, selected}) {
    const click = useCallback(()=>onClick(day), [onClick, day]);

    return <Tab selected={selected} onClick={click}>
        {moment.unix(day).format('DD.MM')}
    </Tab>
}

Day.propTypes = {};
Day.defaultProps = {};
