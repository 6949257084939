import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectFlag } from "state/selectors/ui";
import { ResponsiveModal } from "components/Modal";
import { useActions, useData } from "state/hooks";
import { promptCancel, promptConfirm } from "state/actions/ui";
import Button from "components/Button";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { POST_TYPE_ALBUM, POST_TYPE_LINK } from "joynt/config";
import { isValidForm } from "state/selectors/validation";
import { validate } from "state/actions/data";
//import PropTypes from 'prop-types';

import "./style.css";

function useUiFlag(id) {
    const value = useSelector((store) => selectFlag(store, id));
    return [value];
}

function useUiPrompt() {
    const { onConfirm, onCancel } = useActions({
        onConfirm: promptConfirm,
        onCancel: promptCancel,
    });
    return [onConfirm, onCancel];
}

function useFormValidation(type, id) {
    const isValid = useSelector((store) => isValidForm(store, "db.posts", id));
    const { onValidate } = useActions({
        onValidate: validate,
    });
    useEffect(() => {
        onValidate(type, id);
    }, [onValidate, type, id]);
    return [isValid];
}

export default function CreatePostPrompt(props) {
    const [id] = useUiFlag("posts.create");
    const [confirm, reject] = useUiPrompt();
    const { subtype } = useData({ type: "db.posts", id });
    const [isValid] = useFormValidation("db.posts", id);

    return (
        <ResponsiveModal open={!!id} onClose={reject}>
            <div className={"rows pad-md gap-sm create-post-prompt"}>
                <AggregateRoot
                    type={"db.posts"}
                    id={id}
                    disableSubmit={true}
                    validate={true}
                >
                    {subtype === POST_TYPE_LINK && (
                        <>
                            <Field
                                id={"link"}
                                type={"text"}
                                label={"Paste url"}
                                validate={true}
                            />
                        </>
                    )}
                    {subtype === POST_TYPE_ALBUM && (
                        <>
                            <Field
                                id={"media"}
                                fieldType={"media"}
                                label={"Files"}
                                validate={true}
                            />
                        </>
                    )}
                </AggregateRoot>
                <div className={"cols cols-right gap-xs"}>
                    <Button color={"default"} onClick={reject}>
                        Cancel
                    </Button>
                    <Button onClick={confirm} disabled={!isValid}>
                        Save
                    </Button>
                </div>
            </div>
        </ResponsiveModal>
    );
}

CreatePostPrompt.propTypes = {};
CreatePostPrompt.defaultProps = {};
