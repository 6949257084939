export default [
    {
        value: 'files',
        icon: 'mui-folder'
    },
    {
        value: 'preset',
        icon: 'mui-palette'
    },
    {
        value: 'typography',
        icon: 'mui-format_size'
    },
    {
        value: 'vcs',
        icon: 'github'
    }
]