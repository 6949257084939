export function selectSessionsByStages(store, sessions) {
    const data = store.data['db.nodes'] || {};
    const index = {};
    sessions.forEach(id => {
        let item = data[id] || {};
        let edges = item.edges_ref || {};
        let stage = edges.parent_session || edges.workspace;
        if (!index[stage]) index[stage] = [];
        index[stage].push(id);
    });
    return index;
}