import React from "react";
import PropTypes from "prop-types";
import { useCover, useData } from "state/hooks";
import { ArrayIterator } from "components/list/iterator";

import Avatar, { AvatarSizeProps } from "joynt/components/Avatar";

import { IdentityBox } from "joynt/components/IdentityBox/index";

const IdentityBoxContainer = ({
    id,
    role,
    avatarClassName,
    isOnline,
    isAway,
    size,
    ...other
}) => {
    const { name, media, company_name, company_role } = useData({
        type: "db.identities",
        id,
    });

    const src = useCover(media, [64, 64]);

    const AvatarProps = {
        name,
        src,
        isOnline,
        isAway,
        size,
        avatarClassName,
    };

    const IdentityBoxProps = {
        ...other,
        role,
        name,
        description: company_role
            ? company_role + (!!company_name ? " at " + company_name : "")
            : "",
        avatar: <Avatar {...AvatarProps} size={"small"} />,
    };

    return <IdentityBox {...IdentityBoxProps} />;
};

IdentityBoxContainer.propTypes = {
    size: AvatarSizeProps,
    id: PropTypes.string.isRequired,
    avatarClassName: PropTypes.string,
    isOnline: PropTypes.bool,
    isAway: PropTypes.bool,
};

export const IdentityBoxIterator = ArrayIterator(IdentityBoxContainer);

export default IdentityBoxContainer;
