import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import IconButton from "joynt/components/IconButton";

export default function ExternalLink(props) {
    const { link, title, image, onDelete, readOnly, className } = props;

    return (
        <div
            className={cn(
                "link-embed link-embed--compact gap-sm",
                !image && "link-embed--no-image",
                className
            )}
        >
            {image && (
                <a
                    href={link}
                    target={"_blank"}
                    className={"link-embed__thumbnail"}
                >
                    <img src={image} alt={title} className={"img-cover"} />
                </a>
            )}
            <div className={"link-embed__description"}>
                {title && (
                    <a
                        href={link}
                        className={"link-embed__title"}
                        target={"_blank"}
                    >
                        {title}
                    </a>
                )}
                <a href={link} target={"_blank"} className={"link-embed__host"}>
                    {link}
                </a>
            </div>
            {onDelete && !readOnly && (
                <div>
                    <IconButton
                        size={"small"}
                        onClick={onDelete}
                        icon={"mui-close"}
                    />
                </div>
            )}
        </div>
    );
}

ExternalLink.defaultProps = {
    readOnly: false,
};

ExternalLink.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    host: PropTypes.string,
    link: PropTypes.string,
    readOnly: PropTypes.bool,
    onDelete: PropTypes.func,
};
