import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { useFetchNodeRoles, useFetchRoles, useRolesCount } from "./../hooks";
import { useEdges } from "joynt/hooks";
import { ResponsiveModal } from "components/Modal";
import { selectAccessProfile } from "./../state/selectors";

import NodeAccess from "./NodeAccess";
import SearchInput from "./../SearchInput";
import ManageRoles from "./ManageRoles";

export function NodeAccessContainer(props) {
    const { id } = props;

    const { parent } = useEdges(id);

    const accessProfile = useSelector((s) => selectAccessProfile(s, id));
    const list = `node-roles.${id}`;

    const rolesCount = useRolesCount(list);
    const pending = useFetchRoles(id, list);
    useFetchNodeRoles(id);

    const [showManage, setShowManage] = useState(false);

    return (
        <div className={"rows gap-sm"}>
            {accessProfile === "private" && <SearchInput id={id} list={list} />}
            <NodeAccess
                {...props}
                node={accessProfile === "private" ? id : parent}
                rolesCount={rolesCount}
                isPending={pending}
                onShowSettings={() => setShowManage(true)}
            />
            <ResponsiveModal
                open={showManage}
                onClose={() => setShowManage(false)}
                maxWidth={false}
            >
                <div className={"manage-roles-modal"}>
                    <ManageRoles id={id} list={list} />
                </div>
            </ResponsiveModal>
        </div>
    );
}

NodeAccessContainer.propTypes = {
    id: PropTypes.string,
};
NodeAccessContainer.defaultProps = {};

export default NodeAccessContainer;
