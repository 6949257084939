export default [
    {
        value: 'tree',
        icon: 'mui-account_tree'
    },
    {
        value: 'settings',
        icon: 'mui-settings'
    },
    {
        value: 'theme',
        icon: 'mui-palette'
    }
]