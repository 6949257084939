import React from "react";
import PropTypes from "prop-types";

import RolesList from "./RolesList";
import { useRoleGroups } from "joynt/components/Roles/hooks";
import { RoleListItem } from "joynt/components/IdentityListItem";

export function RolesListContainer(props) {
    const { id } = props;
    const list = `node-roles.${id}`;
    const groups = useRoleGroups(id, list, "adminRoles");
    const items = groups[0]?.items || [];

    return (
        <RolesList {...props}>
            <div className={"rows gap-4"}>
                {items.map((id) => (
                    <RoleListItem key={id} id={id} />
                ))}
            </div>
        </RolesList>
    );
}

RolesListContainer.propTypes = {
    id: PropTypes.string,
};
RolesListContainer.defaultProps = {};

export default RolesListContainer;
