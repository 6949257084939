import React from "react";
import cn from "classnames";

import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";
import "./style.css";

export function MessageHeaderCompact(props) {
    const { avatar, className, isOwn } = props;

    if (!avatar) return null;

    return (
        <sectionw
            className={cn("message__header", isOwn && "message__header--own")}
            data-private
        >
            <header
                className={cn(
                    "message__header message__header--compact",
                    !avatar && "message__header--chat--no-avatar",
                    className
                )}
            >
                <div className={"message__header__avatar"}>{avatar}</div>
            </header>
        </sectionw>
    );
}

MessageHeaderCompact.propTypes = {
    avatar: MessagePropTypes.avatar,
    className: MessagePropTypes.className,
    isOwn: MessagePropTypes.isOwn,
};

export default MessageHeaderCompact;
