import React, { useCallback, useMemo, useState } from "react";
import { useData, useFormField } from "state/hooks";
import { useSelector } from "react-redux";
import { isValidField } from "state/selectors/validation";
import { debounce } from "lodash";
import Field from "containers/Field/Field";
import TextField from "components/fields/text/TextField";
import slugify from "slugify";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

const titleNames = {
    "cms.pages": "Tytuł podstrony",
    "cms.entries.offers": "Nazwa oferty",
    default: "Tytuł",
};

function generateSlug(title, trim = true) {
    if (!title) return null;
    const input = title.replace(/[.|/\\]/g, "-");
    return slugify(input, {
        locale: "pl",
        lower: true,
        strict: true,
        trim: trim,
        replace: /[|*+~.()'"!:@]/g,
    });
}

export function PageTitleSlug(props) {
    const { type, id } = props;
    const { title, slug } = useData(props);
    const suggestedSlug = generateSlug(title);
    const { onChange: onChangeSlug } = useFormField("slug");
    const [focused, setFocused] = useState(false);
    const [tempSlug, setTempSlug] = useState(null);
    const [slugChangedManually, setSlugChangedManually] = useState(
        slug && slug !== suggestedSlug
    );
    const error = useSelector((store) => {
        return isValidField(store, type, id, "slug");
    });
    const updateSlug = () => {
        onChangeSlug(suggestedSlug, true);
    };

    const changeSlug = useCallback(
        (next) => {
            onChangeSlug(next, true);
            setTempSlug(null);
        },
        [onChangeSlug]
    );

    const updateSlugDebounce = useMemo(
        () => debounce(changeSlug, 500),
        [changeSlug]
    );

    const updateSlugOnBlur = () => {
        if (!slug || !slugChangedManually) {
            changeSlug(suggestedSlug, true);
        }
    };

    const fromTitle = () => {
        setSlugChangedManually(false);
        changeSlug(suggestedSlug, true);
    };

    const changeTempSlug = (next) => {
        const nextSlug = generateSlug(next, false);
        setTempSlug(nextSlug);
        setSlugChangedManually(true);
        updateSlugDebounce(nextSlug, true);
    };

    const inputProps = {
        placeholder: suggestedSlug || "adres-url-podstrony",
        startAdornment: "/",
        endAdornment: slugChangedManually && (
            <IconButton onClick={fromTitle} icon={"mui-sync"} />
        ),
    };

    return (
        <div className={"rows gap-md"}>
            <Field
                id={"title"}
                validateOnSubmit={true}
                label={titleNames[props.type] || titleNames.default}
                onBlur={updateSlugOnBlur}
                onFocus={() => setFocused(title)}
            />
            <TextField
                key={"slug"}
                value={tempSlug || slug}
                onChange={changeTempSlug}
                label={"Adres url"}
                error={!!error}
                helperText={error}
                InputProps={inputProps}
            />
        </div>
    );
}

PageTitleSlug.propTypes = {};

PageTitleSlug.defaultProps = {};

export default PageTitleSlug;
