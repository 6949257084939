import { takeEvery, put } from "redux-saga/effects";
import { post } from "state/actions/api";

const endpoints = {
    push: `console/themes/:id/vcs/push`,
    pull: `console/themes/:id/vcs/pull`,
    init: `console/themes/:id/vcs/init`,
    commit: `console/themes/:id/vcs/commit`,
};

const NS_THEME_VCS = "theme.vcs";

function* handlePush({ context, payload }) {
    try {
        const { theme } = payload;
        let url = endpoints.push.replace(":id", theme);
        let data = {};
        yield put(post(context)(url, data, `${theme}.vcs`, NS_THEME_VCS));
    } catch (e) {
        throw e;
    }
}

function* handlePull({ context, payload }) {
    try {
        const { theme } = payload;
        let url = endpoints.pull.replace(":id", theme);
        yield put(post(context)(url, {}, `${theme}.vcs`, NS_THEME_VCS));
    } catch (e) {
        throw e;
    }
}

function* handleCommit({ context, payload }) {
    try {
        const { theme, message } = payload;
        let url = endpoints.commit.replace(":id", theme);
        let data = { commit_message: message };
        yield put(post(context)(url, data, `${theme}.vcs`, NS_THEME_VCS));
    } catch (e) {
        throw e;
    }
}

function* handleInit({ context, payload }) {
    try {
        const { theme } = payload;
        let url = endpoints.init.replace(":id", theme);
        yield put(post(context)(url, {}, `${theme}.vcs`));
    } catch (e) {
        throw e;
    }
}

export default function* () {
    yield takeEvery("THEME.VCS.PUSH", handlePush);
    yield takeEvery("THEME.VCS.PULL", handlePull);
    yield takeEvery("THEME.VCS.COMMIT", handleCommit);
    yield takeEvery("THEME.VCS.INIT", handleInit);
}
