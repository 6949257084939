import React from 'react';
//import PropTypes from 'prop-types';

import ComponentTree from 'cms/ui/containers/ComponentTree';
import Popover from "components/Popover";
import SelectComponentType from "cms/ui/SelectComponentType";
import IconButton from 'components/IconButton';
import SectionItem from "cms/ui/SectionItem";
import {withData} from "containers/Entity";

const Section = withData(SectionItem);

export default class TreeView extends React.PureComponent {

    render() {
        const {
            type,
            id,
            pageId,
            pageType,
            section,
            template,
            focusId,
            onFocus,
            onCreate,
            onImport,
            onChangeTemplate,
            canHaveChildren
        } = this.props;

        return (<>
            {pageType === "cms.layouts" && <div onClick={()=>onFocus("cms.layouts", pageId)}>layout</div>}
            { id ? <div className={'rows'}>
                { type === 'cms.sections' ? <div className={'rows gap-px pad-xs'}>
                    <Section
                        fieldLabel={'Section'}
                        type={'cms.sections'}
                        id={section.id}
                        onClick={onFocus}
                        selected={section.id === focusId}
                    />
                    { template.id
                        ? <Section
                            fieldLabel={'Template'}
                            type={'cms.sections'}
                            id={template.id}
                            onClick={onFocus}
                            onChangeTemplate={onChangeTemplate}
                            selected={template.id === focusId}
                        />
                        : <div onClick={onChangeTemplate}>
                            click to assign template
                        </div> }
                    { template.reference_id
                        ? <Section
                            fieldLabel={'Library template'}
                            type={'cms.sections'}
                            id={template.reference_id}
                            onClick={onFocus}
                            selected={template.reference_id === focusId} />
                        : null }
                </div> : null }
                { type === 'cms.components' ? <div onClick={()=>onFocus(type, id)}>
                    Symbol {id}
                </div> : null }
                <div className={'cols cols-center'}>
                    <Popover
                        trigger={({onClick})=><IconButton
                            onClick={onClick}
                            icon={'mui-add'}
                            disabled={!canHaveChildren}
                        />}
                        content={({onClose})=><SelectComponentType
                            onSelect={onCreate}
                            onClose={onClose}
                            onImport={onImport}
                        />}
                    />
                </div>
            </div> : null }
            <ComponentTree {...this.props} />
        </>);
    }

}

TreeView.propTypes = {};