import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Attendee from "./Attendee";

export default function ParticipantGroup(props) {
    const {
        items,
        isOnline,
        label,
        parent,
        workspace,
        session,
        event
    } = props;

    const classes = {};
    if (label) classes[label.toLowerCase()] = true;

    return items.length ? <div className={"participant-group"}>
        {label && <div className={"participant-group__label"}>{label}</div>}
        <div className={cn('participant-group-list', classes)}>
            {items.map(id=>
                <Attendee
                    id={id}
                    parent={parent}
                    session={session}
                    event={event}
                    workspace={workspace}
                    isOnline={isOnline}
                    key={id}
                />)}
        </div>
    </div> : null
}

ParticipantGroup.propTypes = {
    isOnline: PropTypes.bool
};
ParticipantGroup.defaultProps = {
    isOnline: false
};
