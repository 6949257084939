import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import IconButton from "components/IconButton";
import "./style.css";
import Icon from "components/Icon";

function CertificateStatus(props) {
    const { status, icon, color, validTo } = props;
    return (
        <div className={cn("cols gap-xs cols-middle", `text-${color}`)}>
            {icon && <Icon>{icon}</Icon>}
            <div>{status}</div>
        </div>
    );
}

export function WebsiteDomain(props) {
    const {
        type,
        id,
        name,
        onClick,
        isPrimary,
        onTogglePrimary,
        onMenuOpen,
        isActive,
        certificate,
    } = props;

    const { statusId, status } = certificate;
    const certStatus = status || {};

    return (
        <div
            className={cn("website-domain cols cols-spread", {
                "is-disabled": !isActive,
            })}
        >
            <div className={"rows gap-sm"}>
                <div onClick={() => onClick(id)}>{name}</div>
                <div className={"cols gap-sm cols-middle"}>
                    {isPrimary && <div>Primary</div>}
                    <CertificateStatus status={statusId} {...certStatus} />
                </div>
            </div>
            <IconButton icon={"mui-more_vert"} onClick={onMenuOpen} />
        </div>
    );
}

WebsiteDomain.propTypes = {};

WebsiteDomain.defaultProps = {
    certificate: {},
};

export default WebsiteDomain;
