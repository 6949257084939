import flows from "./../flows";

const nullObj = {};

export function selectFlowSegment(store, flowId, segmentPath) {
    const flow = flows[flowId] || flows.default;
    if (!segmentPath) return nullObj;

    const [segmentId, stateId] = segmentPath.split(':');

    const data = flow.filter(segment => {
        return segment.id === segmentId;
    })[0] || nullObj;

    if (!stateId || !data.steps) return data;

    if (stateId) return data.steps.filter(segment => {
        return segment.id === stateId;
    })[0] || nullObj;
}