import React from "react";
//import PropTypes from 'prop-types';

import ConfigSearch from "./ConfigSearch";
import { useFetch, useList } from "state/hooks";

export function ConfigSearchContainer(props) {
    const {} = props;

    const type = "cms.value-store";

    const pending = useFetch({
        type,
        url: "cms/value-store",
        list: type,
        filter: type,
    });

    const { items } = useList(type, type, type);

    return <ConfigSearch {...props} items={items} isPending={pending} />;
}

ConfigSearchContainer.propTypes = {};

ConfigSearchContainer.defaultProps = {};

export default ConfigSearchContainer;
