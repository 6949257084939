import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "components/Icon";

export default function DeviceOptions(props) {
    const { id, icon, label, value, options, onChange } = props;
    const change = useCallback((evt)=>onChange(id, evt.target.value), [id, onChange]);
    const defaultValue = options[0] ? options[0].deviceId : '';
    return <div className={'cols'}>
        <div className={'grow'}>
            <div className={'cols cols-middle gap-sm'}>
                <Icon>{icon}</Icon>
                { options && options.length ? <FormControl fullWidth={true}>
                    {/*<InputLabel id={`label-${id}`}>{label}</InputLabel>*/}
                    <Select labelId={`label-${id}`} onChange={change} value={value || defaultValue}>
                        {options.map(function(option) {
                            return <MenuItem key={option.deviceId} value={option.deviceId}>{option.label}</MenuItem>
                        })}
                    </Select>
                </FormControl> : <div>{label}: no devices available</div> }
            </div>
        </div>
    </div>
}

DeviceOptions.defaultProps = {
    options: [],
    value: null
}