import React from 'react';
import {useEdges, useNode} from "joynt/hooks";
import GroupAccess from "joynt/edit/access/GroupAccess";
import Field from "containers/Field";
import AssignRoles from "joynt/edit/access/AssignRoles";
import Member from "joynt/edit/access/Member";
//import PropTypes from 'prop-types';

export default function NodeAccess(props) {

    const {id, userRoles} = props;
    const {identity, edit_lock, node_type} = useNode(id);
    const {workspace, root} = useEdges(id);
    const parent = workspace !== id ? workspace : root;
    const {name: workspaceName} = useNode(parent);
    const isRoot = node_type === 'root';
    const locked = !!edit_lock || isRoot;

    return (<div className={'rows gap-sm grow'}>
        { !locked ? <GroupAccess
            name={`Workspace: ${workspaceName}`}
            description={'Give access to workspace members according to their inherited roles'}
        >
            <Field id={'public'} fieldType={'checkbox'} />
        </GroupAccess> : null }
        { !locked ? <GroupAccess
            name={`Anyone`}
            description={'Give non-admin access to anyone who can see this'}
        >
            <Field id={'published'} fieldType={'checkbox'} />
        </GroupAccess> : null }
        { userRoles ? <AssignRoles id={id} scope={'access'} label={'Channel roles'}>
            <Member
                type={'db.identities'}
                id={identity}
                owner={true}
            />
        </AssignRoles> : null }
    </div>);

}

NodeAccess.propTypes = {};
NodeAccess.defaultProps = {
    userRoles: true
};
