import { connectRouter } from 'connected-react-router';
import { createReducer } from "state/helpers/create-reducer";
import {parseQueryString} from "util/uri";

export const enhancedRouter = history => {
    const reduxRouterReducer = connectRouter(history);
    return (state, action) => {
        let currentState = reduxRouterReducer(state, action);
        return routerReducer(currentState, action);
    }
};

const syncRouteParams = (state, {location}) => {
    let query = parseQueryString(location.search);
    return {...state, query};
};

const actions = {
    '@@router/LOCATION_CHANGE': (state, {payload}) => syncRouteParams(state, payload)
};

const routerReducer = createReducer({}, actions);

export default enhancedRouter;