import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import { useApiActions, useUiFlag } from "state/hooks";
import { handleUploadCallback } from "state/actions/upload";
import Button from "components/Button";
import MessageInputIcons from "./MessageInputIcons";
import TextInput from "./TextInput";
import { TypingIndicator, useTypingIndication } from "./TypingIndicator";

import "./style.css";
import Icon from "components/Icon";

export default function MobileMessageForm(props) {
    const {
        messageId,
        parent,
        placeholder,
        onCreateMessage,
        typingIndication,
    } = props;

    const [id, setId] = useState(uuid());
    const tmpId = messageId || parent || id;
    const stateKey = `message.${tmpId}`;
    const [message, setMessage] = useUiFlag(stateKey);

    const messageBoxRef = useRef();
    const inputRef = useRef();

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const create = useCallback(
        (data) => {
            const messageData = {
                id,
                content: message,
                ...data,
            };
            onCreateMessage(messageData);
            setMessage(null);
            setId(uuid());
            focusInput();
        },
        [id, message, onCreateMessage, setMessage]
    );

    const submit = useCallback(() => {
        create({});
    }, [create]);

    const { onUpload } = useApiActions({
        onUpload: handleUploadCallback,
    });

    const upload = (files) => {
        files.forEach((file) => {
            onUpload(
                (file) => onCreateMessage({ media: [file] }),
                "db.media",
                null,
                file
            );
        });
    };

    const addLink = useCallback(
        (url) => {
            create({ link: url });
        },
        [create]
    );

    const value = message || "";

    let [typing, onKeyPress] = useTypingIndication(parent);

    const onChange = useCallback(
        (...args) => {
            setMessage(...args);
            onKeyPress();
        },
        [onKeyPress, setMessage]
    );

    const [isFocused, setIsFocused] = useState(false);

    const handleClick = (e) => {
        const target = e.target;
        if (messageBoxRef.current) {
            messageBoxRef.current.contains(target)
                ? setIsFocused(true)
                : setIsFocused(false);
        }
    };

    useEffect(() => {
        console.log("Using effect");
        if (window && messageBoxRef.current) {
            console.log("Adding listener");
            window.addEventListener("touchend", handleClick);
        }

        return () => {
            window.removeEventListener("touchend", handleClick);
        };
    }, [messageBoxRef]);

    return (
        <div className={"rows message-form message-form--mobile"}>
            {typingIndication && typing && typing.identity && (
                <div className={"message-typing"}>
                    <TypingIndicator identity={typing.identity} />
                </div>
            )}
            <div className={cn("gap-sm rows")}>
                <div
                    className={cn("message-box", isFocused && "is-focused")}
                    ref={messageBoxRef}
                >
                    <TextInput
                        onBlur={(e) => {
                            e.preventDefault();
                        }}
                        onFocus={(e) => {
                            e.preventDefault();
                            setIsFocused(true);
                        }}
                        inputRef={inputRef}
                        placeholder={placeholder}
                        parent={parent}
                        onChange={onChange}
                        value={value}
                        onUpload={upload}
                        isFocused={isFocused}
                    />
                    <div className={"message-box__icons"}>
                        <MessageInputIcons
                            onAddLink={addLink}
                            value={value}
                            onChange={(value) => {
                                setMessage(value);
                                focusInput();
                            }}
                            onUpload={upload}
                        />
                    </div>

                    <Button
                        disabled={!value}
                        onClick={submit}
                        buttonClassName={"message-input__button"}
                    >
                        <Icon size={"md"}>mui-send</Icon>
                    </Button>
                </div>
            </div>
        </div>
    );
}

MobileMessageForm.propTypes = {
    onCreateMessage: PropTypes.func,
    submitMode: PropTypes.oneOf(["enter", "button"]),
    submitLabel: PropTypes.string,
};

MobileMessageForm.defaultProps = {
    placeholder: "Write something...",
    submitMode: "enter",
    submitLabel: "Send",
    typingIndication: true,
};
