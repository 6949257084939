import React from "react";
import PropTypes from "prop-types";
import SearchInput from "./SearchInput";
import { useAccessSettings, useRoleEdit } from "./../hooks";
import { useEdges, useFeatureEnabled, useNodeData } from "joynt/hooks";
import NodeRoleGroup from "./../NodeAccess/NodeRoleGroup";
import AssignAccessRoles from "./../NodeRoles/AssignAccessRoles";
import { FEATURE_WORKSPACE_ROLES } from "joynt/config/features";

function ParentAdminGroup(props) {
    const { id, ...other } = props;
    const { parent } = useEdges(id);

    return (
        <NodeRoleGroup
            id={parent}
            nameTemplate={"Admins and collaborators of :name"}
            {...other}
        />
    );
}

export function SearchInputContainer(props) {
    const { id, list, children } = props;

    const { type, onChange } = useAccessSettings(id);
    const { parent } = useEdges(id);
    const { name: parentName } = useNodeData(parent);

    const addAdmins = () => onChange("admins");

    const {
        // onRemoveRole,
        // onRevertRole,
        // onChangeRole,
        onAddEmail,
        onAddRole,
    } = useRoleEdit(list, "member");

    const enableWorkspaceRoles = useFeatureEnabled(FEATURE_WORKSPACE_ROLES);

    return (
        <SearchInput
            {...props}
            node={parent}
            placeholder={`Add people from ${parentName}`}
            onAddEmail={onAddEmail}
            onAddRole={onAddRole}
            enableRoleSelection={false}
            minInput={0}
            allowEmail={false}
        >
            {type !== "admins" && (
                <ParentAdminGroup id={id} onClick={addAdmins} />
            )}
            {enableWorkspaceRoles && (
                <AssignAccessRoles id={id} assigned={false} />
            )}
            {children}
        </SearchInput>
    );
}

SearchInputContainer.propTypes = {
    id: PropTypes.string,
};
SearchInputContainer.defaultProps = {};

export default SearchInputContainer;
