import React, {useCallback, useState} from 'react';
import UploadButton from "components/upload/UploadButton";
import MenuItem from "components/context-menu/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import TextField from "components/fields/text/TextField";
import ContextMenu from "components/ContextMenu";
import Button from "components/Button";
//import PropTypes from 'prop-types';

export default function MediaLibraryCreate(props) {
    const {
        onUpload,
        onCreateDirectory,
        onImportVideo,
        onImportPano
    } = props;

    const actions = {
        'create-directory': onCreateDirectory,
        'import-video': onImportVideo,
        'import-pano': onImportPano
    };

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const onOpen = useCallback((id) => setOpen(id), [setOpen]);
    const onClose = useCallback(() => setOpen(false), [setOpen]);

    const block = (evt) => evt.stopPropagation();

    const upload = useCallback((...args) => {
        setTimeout(()=>onClose(), 100);
        onUpload(...args);
    }, [onUpload, onClose]);

    const submit = () => {
        if (actions[open]) actions[open](inputValue);
        setInputValue('');
        onClose();
    }

    const items = [
        <div onClick={block}>
            <UploadButton onUpload={upload} trigger={<MenuItem primaryText={'Wgraj plik'} icon={'mui-cloud_upload'} />} />
        </div>,
        <MenuItem onClick={()=>onOpen('create-directory')} primaryText={'Utwórz katalog'} icon={'mui-create_new_folder'} />,
        <MenuItem onClick={()=>onOpen('import-video')} primaryText={'Importuj film'} icon={'mui-movie'} />,
        <MenuItem onClick={()=>onOpen('import-pano')} primaryText={'Importuj panoramę'} icon={'mui-360'} />
    ];
    return <>
        <Dialog open={open} onClose={onClose}>
            <div className={'console-context pad-md rows gap-sm'}>
                <TextField
                    onChange={setInputValue}
                    value={inputValue}
                    label={open}
                />
                <div className={'cols cols-right gap-xs'}>
                    <Button onClick={onClose} color={'default'}>Anuluj</Button>
                    <Button onClick={submit} disabled={!inputValue}>Zapisz</Button>
                </div>
            </div>
        </Dialog>
        <ContextMenu items={items}>
            {({onMenuOpen})=><Button size={'large'} onClick={onMenuOpen}>Nowy</Button>}
        </ContextMenu>
    </>;
}

MediaLibraryCreate.propTypes = {};
MediaLibraryCreate.defaultProps = {};
