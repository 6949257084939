export default {
    owner: {
        label: "Owner",
        color: "primary",
    },
    admin: {
        label: "Admin",
        color: "primary",
    },
    member: {
        label: "Collaborator",
        color: "secondary",
    },
    guest: {
        label: "Attendee",
    },
    speaker: {
        label: "Speaker",
        color: "speaker",
    },
    moderator: {
        label: "Moderator",
        color: "moderator",
    },
    confirmed: {
        label: "Confirmed",
        color: "confirmed",
    },
    denied: {
        label: "Declined",
        color: "secondary",
    },
    pending: {
        label: "Pending",
        color: "primary",
    },
};
