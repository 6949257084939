import React from "react";
import { useCurrentIdentity, useNodeData } from "joynt/hooks";
import { useIdentity } from "joynt/hooks/participants";

//import PropTypes from 'prop-types';

import "./style.css";

export function HeaderWelcome({ id }) {
    const { name } = useNodeData(id);
    const identity = useCurrentIdentity(id);
    const { name: identityName } = useIdentity(identity);
    const [firstname] = identityName ? identityName.split(" ") : [];

    return (
        <header className={"workspace-header__welcome rows rows-middle gap-xs"}>
            <h3 className={"workspace-header__welcome-heading"}>
                Hi {firstname}!
            </h3>
            <p className={"workspace-header__welcome-subtitle"}>
                Welcome to {name}
            </p>
        </header>
    );
}

HeaderWelcome.propTypes = {};
HeaderWelcome.defaultProps = {};

export default HeaderWelcome;
