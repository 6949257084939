import React from 'react';
//import PropTypes from 'prop-types';

export default class RoomDetails extends React.Component {

    render() {
        return (<div>RoomDetails</div>);
    }

}

RoomDetails.propTypes = {};