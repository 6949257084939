import React from 'react';
//import PropTypes from 'prop-types';
import cn from 'classnames';
import FormControl from "@material-ui/core/FormControl";
import IconButton from "components/IconButton";
import Modal from "components/Modal";

const controlStyle = {
    width: '100%'
};

const textPreviewStyle = {
    whiteSpace: 'no-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '140px',
    height: '1em'
}

function checkHasValue(value, type) {
    if (type === 'rich') {
        if (!value || value === '') return false;
    }
    if (type === 'code') {
        if (!value || value === '') return false;
    }
    if (type === 'collection') {
        if (!value || !value.length) return false;
    }
    return !!value;
}

function RenderValue({value, type}) {
    if (type === 'rich') {
        if (!value || value === '') return null;
        return <div style={textPreviewStyle} dangerouslySetInnerHTML={{__html:value}} />;
    }
    if (type === 'code') {
        if (!value || value === '') return null;
        return <pre style={textPreviewStyle}>{value}</pre>;
    }
    if (type === 'collection') {
        if (!value || !value.length) return null;
        return <div>{value.length} elementów</div>;
    }
    return null;
}

export default function(props) {
    const { value, label, type } = props;

    const hasValue = checkHasValue(value, type);
    const valueElement = hasValue ? <RenderValue value={value} type={type} /> : null;

    return <Modal
        trigger={({onClick})=><FormControl style={controlStyle}>
            { valueElement ? <div className={'input-dense__label'}>
                {label}
            </div> : null }
            <div onClick={onClick} className={cn('input-dense cols', {'is-empty': !valueElement})}>
                <div className={cn('input-dense__value')}>
                    { valueElement ? valueElement : label}
                </div>
                <IconButton
                    size={'small'}
                    icon={'mui-open_in_new'}
                />
            </div>
        </FormControl>}
        content={()=>props.children}
    />
}