import React from "react";
//import PropTypes from 'prop-types';
import FileManager from "./FileManager";
import { useCurrentService } from "playground/cms/hooks";

export function FileManagerContainer(props) {
    const {} = props;
    const { theme: themeId } = useCurrentService();

    return <FileManager {...props} themeId={themeId} />;
}

FileManagerContainer.propTypes = {};

FileManagerContainer.defaultProps = {};

export default FileManagerContainer;
