import React from 'react';
import {connect} from 'react-redux';
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import {submitOne} from "state/actions/api";
import EntityContext, {AggregateRootContext} from "containers/context/EntityContext";
import {validate} from "state/actions/data";
import {ValidationContext} from "containers/context/ValidationContext";
import {isValidForm} from "state/selectors/validation";

const mapStateToProps = (store, props) => {
    return {
        path: [props.type, props.id].join('/'),
        isValid: props.validate
            ? isValidForm(store, props.type, props.id)
            : true
    }
};

const mapDispatchToProps = bindApiActions({
    defaultSubmit: submitOne
}, {
    onValidate: validate
});

class AggregateRoot extends React.PureComponent {

    submit = () => {
        const { type, id, isValid } = this.props;
        const {onSubmit, defaultSubmit} = this.props;
        const submit = onSubmit || defaultSubmit;
        if (isValid) submit(type, id);
    };

    validate = () => {
        const { type, id } = this.props;
        const { validate, onValidate } = this.props;
        if (validate) {
            onValidate(type, id);
        }
    };

    render() {
        const { path, disableSubmit } = this.props;

        if (disableSubmit) return <EntityContext.Provider value={path}>
            <ValidationContext.Provider value={this.validate}>
                {this.props.children || null}
            </ValidationContext.Provider>
        </EntityContext.Provider>;

        return (<AggregateRootContext.Provider value={this.submit}>
            <EntityContext.Provider value={path}>
                <ValidationContext.Provider value={this.validate}>
                    {this.props.children || null}
                </ValidationContext.Provider>
            </EntityContext.Provider>
        </AggregateRootContext.Provider>);
    }

}

export const ConnectedAggregateRoot = connect(
    mapStateToProps,
    mapDispatchToProps
)(AggregateRoot);

export default withApiContext()(ConnectedAggregateRoot);