import React from "react";

//import PropTypes from 'prop-types';

export function SelectListDivider(props) {
    const {} = props;
    return <div className={"select-list-divider"} />;
}

SelectListDivider.propTypes = {};

SelectListDivider.defaultProps = {};

export default SelectListDivider;
