import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import App from "containers/App";
import Fetch from "containers/Fetch";
import { selectAliasedEntity } from "state/selectors/data";
import ApiContext from "containers/context/ApiContext";
import ThemeProvider from "containers/Theme";
import Preloader from "components/Preloader";
import RequireAuthentication from "containers/RequireAuthentication";
import Bootstrap from "containers/Bootstrap";
import { isPending } from "state/selectors/ui";
import { selectRouteParam } from "state/selectors/router";
import { Broadcasting } from "util/broadcasting";
import WebsocketClient from "playground/console/WebsocketClient";
import { UserAccessProvider } from "playground/console/UserAccess";
import ErrorPage from "playground/console/ErrorPage";

const nullObj = {};

const mapStateToProps = (store, props) => {
    const serviceAlias = props.service || selectRouteParam(store, "service");
    const service = selectAliasedEntity(
        store,
        "console.services",
        serviceAlias
    );
    const bootstrapId = "app";
    return {
        serviceAlias: serviceAlias,
        service: service.id,
        serviceType: service.type,
        isPending: isPending(store, [
            "@currentUser",
            "@currentPreset",
            serviceAlias,
            //service.id,
            "app",
            bootstrapId,
        ]),
        bootstrapId,
    };
};

const mapDispatchToProps = {};

function ServiceStatus(props) {
    const { id, isPending } = props;
    if (isPending) return <div>Loading...</div>;
    return <div>Looks like you don't have access to {id}</div>;
}

class Entrypoint extends React.PureComponent {
    render() {
        const {
            isPending,
            serviceAlias,
            service,
            serviceType,
            authRedirect,
            bootstrapId,
            userPreset,
            themePreset,
            className,
        } = this.props;

        const AuthBackend = this.props.AuthBackend || RequireAuthentication;
        const serviceUrl = "console/services/:id?type=db,website&expand=true";

        return (
            <ApiContext.Provider value={nullObj}>
                <Preloader visible={isPending} />
                <AuthBackend redirect={authRedirect}>
                    <WebsocketClient>
                        <ThemeProvider
                            target={true}
                            loadFonts={true}
                            userPreset={userPreset}
                            className={className}
                            presetId={themePreset}
                        >
                            <Fetch
                                type={"console.services"}
                                id={serviceAlias}
                                alias={serviceAlias}
                                url={serviceUrl}
                                fetch={!service}
                                useContext={false}
                            />
                            <UserAccessProvider>
                                {!serviceAlias ? this.props.children : null}
                                {/*{!isPending && service ? (*/}
                                {serviceAlias && (
                                    <>
                                        {isPending || service ? (
                                            <App service={service}>
                                                <Bootstrap
                                                    id={service}
                                                    serviceType={serviceType}
                                                    bootstrapId={bootstrapId}
                                                >
                                                    {!isPending &&
                                                        this.props.children}
                                                </Bootstrap>
                                            </App>
                                        ) : (
                                            <ErrorPage
                                                title={"Nie znaleziono"}
                                                description={`Wygląda na to że projekt ${serviceAlias} nie istnieje lub nie masz do niego dostępu`}
                                            />
                                        )}
                                    </>
                                )}
                            </UserAccessProvider>
                            {/*) : null}*/}
                        </ThemeProvider>
                    </WebsocketClient>
                </AuthBackend>
            </ApiContext.Provider>
        );
    }
}

Entrypoint.defaultProps = {
    userPreset: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(Entrypoint);
