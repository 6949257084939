import React, { useEffect, useRef, useState } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames/dedupe";
import Media from "cms/components/Media";

/*const defaultClasses = {
    section: 'section',
    wrapper: 'container',
    columns: 'cols'
};*/

export function SectionsPlaceholder({ onClick }) {
    return (
        <div
            className={
                "l-section t-alt text--center type--heading-md l-rows l-rows--center l-rows--gap-md"
            }
        >
            <div className={"l-area"}>No sections</div>
            <button
                onClick={onClick}
                className={
                    "l-area c-btn c-btn--btn c-btn--md c-btn--primary type--btn"
                }
            >
                <span>Create section</span>
            </button>
        </div>
    );
}

export class SectionBackground extends React.PureComponent {
    render() {
        const { classes, media } = this.props;
        return (
            <>
                <div className={cn(classes.image_outer)}>
                    {media[0] ? (
                        <Media
                            classes={classes}
                            type={"cms.media"}
                            id={media[0]}
                        />
                    ) : null}
                </div>
                <div className={cn(classes.overlay)} />
            </>
        );
    }
}

SectionBackground.defaultProps = {
    media: [],
};

export const SectionPlaceholder = React.forwardRef((props, ref) => {
    const { type, id } = props;
    const inspectId = ["cms.sections", id].join("/");

    const descriptions = {
        empty: "empty section",
        view: "section template",
        default: "create new section here",
    };

    return (
        <section
            ref={ref}
            data-inspect={inspectId}
            data-type={"section"}
            data-s={"section"}
            className={"l-section t-alt l-area--centered"}
        >
            <div className={"l-rows l-rows--gap-sm text--center"}>
                <h1 className={"type--heading-md l-area"}>
                    {descriptions[type] || descriptions.default}
                </h1>
                <div className={"l-area type--body-sm opacity50"}>{id}</div>
            </div>
        </section>
    );
});

function useIntersectionObserver(ref, callback) {
    const [observer, setObserver] = useState(null);

    useEffect(() => {
        if (ref.current && !observer) {
            const observer = new IntersectionObserver(callback, {
                root: null,
                rootMargin: "0px",
                threshold: 0,
            });

            observer.observe(ref.current);
            setObserver(observer);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [ref, observer, callback]);
}

function Section(props, ref) {
    const {
        type,
        id,
        classes,
        attributes,
        display,
        className,
        media,
        hasContent,
        RenderContent,
    } = props;

    const inspectId = [type, id].join("/");

    const hasBackground =
        classes.background || (display && display.indexOf("parallax") > -1);

    const hasMedia = media && media.length > 0;

    const backgroundClasses = {
        Background: hasBackground,
    };

    const sectionRef = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const visible = isVisible?.[0]?.isIntersecting;
    const height = isVisible?.[0]?.boundingClientRect?.height;
    const renderContent = visible || !height;

    useIntersectionObserver(sectionRef, setIsVisible);

    if (!hasContent && !hasBackground) {
        return <SectionPlaceholder id={id} ref={ref} type={"empty"} />;
    }

    const style = {};
    if (!visible && height) style.height = `${height}px`;

    return (
        <section
            ref={sectionRef}
            data-inspect={inspectId}
            data-type={"section"}
            data-s={"section"}
            className={cn(classes.section, className, backgroundClasses)}
            style={style}
            {...attributes}
        >
            {hasBackground && hasMedia ? (
                <SectionBackground classes={classes} media={media} />
            ) : null}

            {renderContent && (
                <div data-s={"wrapper"} className={cn(classes.wrapper)}>
                    {RenderContent ? (
                        <RenderContent {...props} />
                    ) : (
                        props.children
                    )}
                </div>
            )}
        </section>
    );
}

Section.propTypes = {};
Section.defaultProps = {};
Section.autoFillProps = [
    "style",
    "styles",
    "media",
    "active",
    "template",
    "description",
    "title",
    "breakpoints",
];

const SectionRef = React.forwardRef(Section);
SectionRef.autoFillProps = Section.autoFillProps;

export default SectionRef;
