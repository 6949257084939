import React, { Component } from "react";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import QuillToggleFullscreenButton from "quill-toggle-fullscreen-button";
//import ReactQuill, { Quill } from "react-quill";

import "css/quill.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const ReactQuill = require("react-quill");
const Quill = ReactQuill.Quill;

//var Delta = Quill.import("delta");
let Break = Quill.import("blots/break");
let Embed = Quill.import("blots/embed");

/*function lineBreakMatcher() {
    var newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
}*/

class SmartBreak extends Break {
    length() {
        return 1;
    }
    value() {
        return "\n";
    }

    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
    }
}

SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";

Quill.register(SmartBreak);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
Quill.register("modules/toggleFullscreen", QuillToggleFullscreenButton);

const normalizeNative = (nativeRange) => {
    if (nativeRange) {
        const range = nativeRange;

        if (range.baseNode) {
            range.startContainer = nativeRange.baseNode;
            range.endContainer = nativeRange.focusNode;
            range.startOffset = nativeRange.baseOffset;
            range.endOffset = nativeRange.focusOffset;

            if (range.endOffset < range.startOffset) {
                range.startContainer = nativeRange.focusNode;
                range.endContainer = nativeRange.baseNode;
                range.startOffset = nativeRange.focusOffset;
                range.endOffset = nativeRange.baseOffset;
            }
        }

        if (range.startContainer) {
            return {
                start: {
                    node: range.startContainer,
                    offset: range.startOffset,
                },
                end: { node: range.endContainer, offset: range.endOffset },
                native: range,
            };
        }
    }

    return null;
};

function lineBreakHandler(range) {
    let currentLeaf = this.quill.getLeaf(range.index)[0];
    let nextLeaf = this.quill.getLeaf(range.index + 1)[0];

    this.quill.insertEmbed(range.index, "break", true, "user");

    // Insert a second break if:
    // At the end of the editor, OR next leaf has a different parent (<p>)
    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
        this.quill.insertEmbed(range.index, "break", true, "user");
    }

    // Now that we've inserted a line break, move the cursor forward
    this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

const filterBreaks = (quill) => {
    return null;
    /*if (!quill) return null;

    var length = quill.getLength();
    var text = quill.getText(length - 2, 2);

    // Remove extraneous new lines
    if (text === "\n\n") {
        quill.deleteText(quill.getLength() - 2, 2);
    }*/
};

function getRange(quill) {
    const root = quill.root.getRootNode();
    if (!root.getSelection) return null;
    const selection = root.getSelection();
    if (selection == null || selection.rangeCount <= 0) return null;
    const nativeRange = selection.getRangeAt(0);
    if (nativeRange == null) return null;
    return normalizeNative(nativeRange);
}

function setupShadowSelection(quill) {
    quill.selection.getNativeRange = () => {
        return getRange(quill);
    };
    quill.selection.hasFocus = function () {
        const dom = quill.root.getRootNode();
        return (
            dom.activeElement === this.root ||
            (dom.activeElement != null && this.root.contains(dom.activeElement))
        );
    };

    document.addEventListener("selectionchange", (...args) => {
        //const dom = quill.root.getRootNode();
        //const selection = dom.getSelection();
        //const range = quill.selection.normalizeNative(selection);
        if (!quill.hasFocus()) {
            quill.theme.tooltip.hide();
            return;
        }

        try {
            quill.selection.update();
        } catch (e) {
            console.error(e);
        }

        const r = getRange(quill);
        if (r?.start?.offset === r?.end?.offset) {
            quill.theme.tooltip.hide();
        }
    });
}

export default class SmartBreakQuill extends Component {
    constructor(props) {
        super(props);
        this.quillRef = null; // Quill instance
        this.reactQuillRef = null; // ReactQuill component
        this.state = { hasError: false, value: props.value };
    }

    componentDidMount() {
        this.attachQuillRefs();
        this.setState({ value: this.props.value });
        filterBreaks(this.quillRef);
        if (this.props.shadow) setupShadowSelection(this.quillRef);
    }

    componentDidUpdate(prevProps) {
        this.attachQuillRefs();
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
            filterBreaks(this.quillRef);
        }
    }

    componentWillUnmount() {
        this.quillRef = null;
        this.reactQuillRef = null;
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {}

    attachQuillRefs = () => {
        if (!this.reactQuillRef) return;
        if (typeof this.reactQuillRef.getEditor !== "function") return;
        this.quillRef = this.reactQuillRef.getEditor();
    };

    handleChange = (value, delta, source) => {
        const { onChange, commitOnBlur } = this.props;
        if (source !== "user") return;
        this.setState({ value });

        if (!commitOnBlur) {
            onChange(value);
        }
    };

    blur = () => {
        const { commitOnBlur, onChange } = this.props;
        if (commitOnBlur) {
            onChange(this.state.value);
        }
    };

    handlePasteImage = (...args) => {
        const { onUpload } = this.props;
        if (onUpload) onUpload([args[2].toFile("clipboard.png")]);
    };

    render() {
        if (this.state.hasError) {
            return (
                <div dangerouslySetInnerHTML={{ __html: this.props.value }} />
            );
        }

        const { modules } = this.props || {};

        modules.keyboard = {
            bindings: {
                linebreak: {
                    key: 13,
                    shiftKey: true,
                    handler: lineBreakHandler,
                },
            },
        };

        modules.imageDropAndPaste = {
            handler: this.handlePasteImage,
        };

        modules.toggleFullscreen = true;

        /*
            modules.clipboard = {
                matchVisual: false,
                matchers: [["BR", lineBreakMatcher]]
            };
        */

        const props = {
            ...this.props,
            modules: modules,
            ref: (el) => {
                this.reactQuillRef = el;
            },
        };

        props.value = this.state.value || "";
        //delete props.value;

        return (
            <>
                <ReactQuill
                    {...props}
                    onBlur={this.blur}
                    onChange={this.handleChange}
                />
            </>
        );
    }
}
