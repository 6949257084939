import React from 'react';
//import PropTypes from 'prop-types';
import Fetch from "containers/Fetch";
import List from "containers/List";
import {RoomIterator, RoomSkeleton} from "playground/booking/Room";

export default class OfferSelection extends React.PureComponent {

    select = (id) => {
        const { onSelect } = this.props;
        onSelect({room: id});
    };

    render() {
        const { url } = this.props;

        return (<>
            <div className={'cols gap-md'}>
                <div>filtr targety rodzina / we dwoje / biznes etc</div>
                <div>warunki oferty z wyżywieniem / najlepsza cena / elastyczne warunki</div>
            </div>
            <Fetch
                type={'cms.entries.rooms'}
                url={url}
                list={'booking-rooms'}
            />
            <List
                type={'cms.entries.rooms'}
                list={'booking-rooms'}
                onSelect={this.select}
                pendingState={<RoomSkeleton />}
                Iterator={RoomIterator}
            />
        </>);
    }

}

OfferSelection.propTypes = {};