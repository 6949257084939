import React from 'react';
import {connect} from 'react-redux';
import Fetch from "containers/Fetch";
import List from "containers/List";
import {ProductCardIterator} from "playground/booking/ProductCard";
import {ProductItemIterator} from "playground/booking/ProductItem";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ProductSelection extends React.PureComponent {

    select = (id) => {
        const { onSelect } = this.props;
        onSelect(id);
    };

    show = (id) => {
        const { onShow } = this.props;
        onShow(id);
    };

    render() {
        return (<>
            <div>filtr targety rodzina / we dwoje / biznes etc</div>
            <Fetch
                type={'cms.products'}
                url={'cms/products'}
                list={'booking-products'}
            />
            <div className={'grid gap-md grid-3'}>
                <List
                    type={'cms.products'}
                    list={'booking-products'}
                    onSelect={this.select}
                    onClick={this.show}
                    Iterator={ProductCardIterator}
                />
            </div>
            <div className={'grid gap-md grid-3'}>
                <List
                    type={'cms.products'}
                    list={'booking-products'}
                    onSelect={this.select}
                    onClick={this.show}
                    Iterator={ProductItemIterator}
                />
            </div>
        </>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductSelection);