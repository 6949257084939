import React from "react";
import PropTypes from "prop-types";
import ContentEditable from "components/ContentEditable";
import { quillToolbar } from "config/quill";
import { isBrowser } from "util/ssr";
import DenseField from "components/DenseField";

const nullFn = () => null;

const quillOptions = {
    toolbar: quillToolbar,
    clipboard: {
        matchVisual: false,
    },
};

export default class RichText extends React.Component {
    constructor(props) {
        super(props);
        if (isBrowser()) {
            this.ReactQuill =
                require("components/smart-break-quill/Quill").default;
        }
    }

    handleChange = (value) => {
        const { onChange } = this.props;
        onChange(value);
    };

    render() {
        const {
            label,
            value,
            onChange,
            onUpload,
            readOnly,
            placeholder,
            changeMode,
            className,
            backend,
            variant,
        } = this.props;

        const Quill = this.ReactQuill;

        const quillProps = {
            className,
            theme: variant === "inline" ? "bubble" : "snow",
            shadow: variant === "inline",
            modules: quillOptions,
            value: value,
            commitOnBlur: changeMode !== "change" && variant === "inline",
            onChange,
            placeholder: label,
            "data-private": "lipsum",
            "data-s": this.props["data-s"],
            "data-sub": this.props["data-sub"],
            "data-path": this.props["data-path"],
        };

        if (readOnly || !isBrowser() || !Quill)
            return value ? (
                <div
                    data-private
                    className={className}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            ) : null;

        if (variant === "dense") {
            return (
                <DenseField label={label} type={"rich"} value={value}>
                    <Quill {...quillProps} onUpload={onUpload} />
                </DenseField>
            );
        }

        if (backend !== "contenteditable") {
            return <Quill {...quillProps} onUpload={onUpload} />;
        }

        return (
            <ContentEditable
                {...this.props}
                onKeyPress={nullFn}
                tagName={"div"}
                onChange={this.handleChange}
                value={value}
                label={placeholder}
                className={className}
                commitOnBlur={changeMode !== "change"}
                mode={"html"}
                data-private="lipsum"
            />
        );
    }
}

RichText.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
};
RichText.defaultProps = {
    placeholder: "Type here...",
    variant: "editor",
};
