import React, { useEffect, useRef } from "react";

//import PropTypes from 'prop-types';

export function NewBlockTypeCaret(props) {
    const { children, decoratedText, store } = props;
    const ref = useRef();

    useEffect(() => {
        store.update({ show: true, anchor: ref });
        return () => {
            store.update({ show: false, anchor: null });
        };
    }, [store]);

    useEffect(() => {
        store.update({ search: decoratedText.replace("/", "") });
    }, [store, decoratedText]);

    return (
        <span ref={ref} className={"new-block-caret"}>
            {children}
        </span>
    );
}

NewBlockTypeCaret.propTypes = {};
NewBlockTypeCaret.defaultProps = {};

export default NewBlockTypeCaret;
