import React from 'react';
import PropTypes from 'prop-types';
import {parseTemplateMapping} from "cms/util/templatable";

import TextField from './text/TextField';

export default class Rewire extends React.PureComponent {

    render() {
        const { value } = this.props;
        const parsed = parseTemplateMapping(value);
        const keys = Object.keys(parsed);
        return (<div className={'rows gap-sm'}>
            <div className={'rows gap-xs'}>
                {keys.map(key=>{
                    return <div className={'cols gap-xs rewire-mapping'} key={key}>
                        <div className={'rewire-pointer'}>
                            {key}
                        </div>
                        <div className={'rewire-pointer'}>
                            ctx.{parsed[key]}
                        </div>
                    </div>;
                })}
            </div>
            <TextField {...this.props} />
        </div>);
    }

}

Rewire.propTypes = {
    value: PropTypes.any
};