import React from 'react';
import {connect} from 'react-redux';
import {selectCurrentUserId} from "state/selectors/app";
import {isAuthenticated} from "state/selectors/auth";
import {isPending} from "state/selectors/ui";
import {pushRoute} from "state/actions/router";
import {parseHash, checkSession, fetchUser, requireLogin} from "state/actions/auth";
import {changed} from "util/changed";
import {DefaultLogin as LoginLayout} from "components/layouts/LoginLayout";
import AuthPending from "components/auth/AuthPending";

const mapStateToProps = (store, props) => {
    let user = selectCurrentUserId(store);
    let pending = ['login', '@currentUser'];
    if (user) pending.push(user.id);
    return {
        authError: store.auth.error,
        user,
        auth: isAuthenticated(store),
        userFetched: store.pending['@currentUser'] === false,
        pending: isPending(store, pending, false)
    }
};

const mapDispatchToProps = {
    pushRoute: pushRoute,
    parseHash: parseHash,
    checkSession: checkSession,
    fetchUser: fetchUser,
    requireLogin: requireLogin
};

class RequireAuthentication extends React.PureComponent {

    componentDidMount() {
        this.check();
        this.fetchUser();
    }

    componentDidUpdate(prevProps) {
        if (changed(['auth'], prevProps, this.props)) {
            this.check();
            this.fetchUser();
        }
    }

    check() {
        const { auth, requireLogin, redirect } = this.props;
        if (redirect === false) return;
        if (!auth) requireLogin();
    }

    fetchUser() {
        const { fetchUser, auth } = this.props;
        if (auth) fetchUser();
    }

    render() {

        const {
            auth,
            user,
            children,
            parseHash,
            userFetched,
            redirect
        } = this.props;

        if (window.location.hash.includes("access_token=")) {
            parseHash();
            return null;
        }

        if (window.location.hash.includes("code=")) {
            parseHash();
            return null;
        }

        if (!auth || !user) {
            if (redirect === false) {
                if (!userFetched && auth) return <AuthPending />;
                return <LoginLayout />;
            }
            return null;
        }

        return children;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequireAuthentication);