import React from 'react';
import {connect} from 'react-redux';
import {logout} from 'state/actions/auth';
import {jointAddFragment, jointAddPost} from 'state/actions/chrome-extension';
import {resolveAlias, selectAliasedEntity} from "state/selectors/data";
import {isPending, selectFlag} from 'state/selectors/ui';
import {setFlag} from "state/actions/ui";
import {bindApiActions, withApiContext} from "containers/context/ApiContext";
import {currentTabUrl, getSelection} from "joynt/chrome-extension/chrome";

import JointExtension from "joynt/chrome-extension/JointExtension";

const EXTENSION_TAB = 'extension.tab';
const EXTENSION_NODE = 'extension.node';

const mapStateToProps = (store, props) => {
    const userId = resolveAlias(store, 'db.users', '@currentUser');
    const user = selectAliasedEntity(store, 'db.users', '@currentUser');
    const rootNode = user.app_access_profile
        ? user.app_access_profile.root_node_id
        : null;
    const path = ['db.nodes/db.nodes.graph'];
    return {
        root: rootNode,
        type: 'db.nodes',
        list: 'db.nodes.graph',
        view: '6b157968-cdc4-4471-9b3c-c5e67670350c',
        url: 'joynt/nodes',
        userId,
        isAuthenticated: true, //isAuthenticated(store),
        isPending: isPending(store, ['@currentUser', 'add-post']),
        searchPending: isPending(store, [path]),
        tab: selectFlag(store, EXTENSION_TAB) || 'search',
        id: selectFlag(store, EXTENSION_NODE) || rootNode
    }
};

const mapDispatchToProps = bindApiActions({
    onAddPost: jointAddPost,
    onAddFragment: jointAddFragment
},{
    onLogout: logout,
    onSetFlag: setFlag
});

class JointExtensionContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {id: props.id, selection: null};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.id !== this.state.id)
            && (!this.props.searchPending
                && prevProps.searchPending !== this.props.searchPending
            )) {
            this.setState({id: this.props.id});
        }
    }

    componentDidMount() {
        currentTabUrl()
            .then((url) => {
                getSelection()
                    .then((text) => {
                        this.setState({url, selection: text});
                    })
            })
    }

    handleSubmitFragment = (stem) => {
        const { onAddFragment } = this.props;
        onAddFragment({
            content: this.state.selection
        }, stem);
    };

    handleSubmit = (id) => {
        this.props.onAddPost(id);
    };

    handleChangeTab = (tab) => {
        const { onSetFlag } = this.props;
        onSetFlag(EXTENSION_TAB, tab);
    };

    gotoNode = (id) => {
        const { onSetFlag } = this.props;
        onSetFlag(EXTENSION_NODE, id);
    };

    render() {
        return <JointExtension
            {...this.props}
            selectionText={this.state.selection}
            currentTabUrl={this.state.url}
            stateId={this.state.id}
            onNavigateToNode={this.gotoNode}
            onSubmitPost={this.handleSubmit}
            onSubmitFragment={this.handleSubmitFragment}
        />
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(JointExtensionContainer));