import { useField } from "containers/Field";
import { usePostEdit } from "joynt/components/Post";
import { useCallback, useMemo } from "react";

export function useContentEditor(id) {
    const [content, setContent] = useField("content", "db.nodes", id);

    const { onUpload } = usePostEdit(id);

    const onSave = useCallback(
        (content) => {
            setContent({ ...content, version: "draft-js.raw" });
        },
        [setContent]
    );

    return useMemo(
        () => ({
            value: content,
            onChange: onSave,
            onUpload,
        }),
        [content, onSave, onUpload]
    );
}
