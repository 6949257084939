import React, {useState} from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Button from "components/Button";

import "./style.css";
import Icon from "components/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import useScreenSize from "util/hooks/useScreenSize";

const stopPropagation = (e) => e.stopPropagation();

function ConfirmButton(props) {
    const {
        onConfirm,
        onRevoke,
        isConfirmed,
        isPending
    } = props;

    const [hover, setHover] = useState(false);
    const {isDesktop} = useScreenSize();
    const hovered = hover && isDesktop;

    let icon = null;
    if (isConfirmed || (!isConfirmed && hovered)) icon = "mui-check";
    if (isConfirmed && hovered) icon = "mui-close";

    return <Button
        onClick={!isConfirmed ? onConfirm : onRevoke}
        className={cn({
            "session-confirm-button": true,
            "button--green": (isConfirmed && !hovered) || (!isConfirmed && hovered),
            "button--red": isConfirmed && hovered
        })}
        startIcon={icon && <Icon>{icon}</Icon>}
        variant={"outlined"}
        onMouseEnter={()=>setHover(true)}
        onMouseLeave={()=>setHover(false)}
        disabled={isPending}
    >
        {isConfirmed ? <>
            {hovered ? "Can't go" : "Confirmed"}
        </> : <>
            {hovered ? "Confirm" : "I'm going"}
        </>}
    </Button>
}

export function SessionConfirmActions(props) {
    const {
        onConfirm,
        onDecline,
        isConfirmed,
        isDeclined,
        isPending,
        allowDecline
    } = props;

    return (
        <div
            className={cn("session-confirm-buttons", {
                "is-pending": isPending,
            })}
            onClick={stopPropagation}
        >
            <div className={"session-confirm-buttons__buttons"}>
                {allowDecline ? <>
                    {!isDeclined && (
                        <Button
                            onClick={onDecline}
                            className={cn({
                                "button--green": isConfirmed,
                            })}
                            variant={!isConfirmed ? "outlined" : "contained"}
                            startIcon={isConfirmed ? <Icon>mui-check</Icon> : null}
                            disabled={isPending}
                        >
                            {isConfirmed ? "Confirmed" : "Decline"}
                        </Button>
                    )}
                    {!isConfirmed && (
                        <Button
                            onClick={onConfirm}
                            className={cn({
                                "button--red": isDeclined,
                            })}
                            startIcon={isDeclined ? <Icon>mui-close</Icon> : null}
                            disabled={isPending}
                        >
                            {isDeclined ? "Declined" : "I'm going"}
                        </Button>
                    )}
                </> : <>
                    <ConfirmButton {...props} />
                </>}
            </div>
            {isPending && (
                <div className={"session-confirm-buttons__pending"}>
                    <CircularProgress size={24} />
                </div>
            )}
        </div>
    );
}

SessionConfirmActions.propTypes = {
    onConfirm: PropTypes.func,
    onDecline: PropTypes.func,
    isConfirmed: PropTypes.bool,
    isDeclined: PropTypes.bool,
    isPending: PropTypes.bool,
    allowDecline: PropTypes.bool
};
SessionConfirmActions.defaultProps = {
    allowDecline: false
};

export default SessionConfirmActions;
