import React from 'react';
import {connect} from 'react-redux';
import Styles from 'cms/ui/Styles';
import {selectEntity} from "state/selectors/data";
import {normalizeType} from "cms/util/connect";
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";
import {change} from "state/actions/data";

const nullObj = {};
const nullArr = [];

function normalizeEntityStyleName(type) {
    if (type === "cms.layouts") return "page_layout";
    if (type === "cms.sections") return "section";
    return null;
}

function selectElements(store, componentType) {
    let style = selectEntity(store, 'cms.styles', 'definitions');
    if (!style || !style[componentType]) return nullArr;
    return Object.keys(style[componentType]);
}

function selectStyles(store, type, id, layer) {
    let data = selectEntity(store, type, id);
    let elementType = normalizeType(data.element_type) || normalizeEntityStyleName(type);

    if (layer === 'inline')
        return data.styles;

    if (layer === 'style')
        return selectEntity(store, 'cms.styles', data.style);

    if (layer === 'global')
        return selectEntity(store, 'cms.styles', elementType);
}

const legacy = {
    section: [
        'heading',
        'title',
        'headline',
        'title_tag'
    ],
    list: [
        'title',
        'headline',
        'text',
        'card',
        'image',
        'image_outer',
        'image_size',
        'image_size_full',
        'media',
        'overlay',
        'content',
        'icon',
        'show_description'
    ],
    card: [
        'title',
        'text',
        'show_description'
    ]
};

const defaultElementsConfig = {
    list: [
        'list',
        'item',
        'item_type',
        'embed',
        'list_type'
    ],
    layout: [
        'layout',
        'area',
        'embed'
    ],
    text: [
        'text',
        'title',
        'headline'
    ],
    card: [
        'item',
        'overlay',
        'image',
        'image_size'
    ],
    value: [
        'value'
    ],
    section: [
        'section',
        'background',
        'image_size',
        'wrapper',
        'overlay'
    ],
    button: [
        'button',
        'button_icon'
    ]
}

/*const groups = {
    list: [
        {
            name: 'Slider',
            children: [
                'slider_items',
                'slider_items_phone',
                'slider_items_tablet',
                'slider_margin',
                'slider_auto_height',
                'slider_mode',
                'slider_auto'
            ]
        },
        {
            name: 'Filter',
            children: [
                'filter_layout',
                'filter_menu',
                'filter_style'
            ]
        }
    ]
}*/

const mapStateToProps = (store, props) => {
    const { focusType, focusId, focusStyle } = props;
    let data = props.focusType && props.focusId
        ? selectEntity(store, focusType, focusId)
        : nullObj;
    let type = normalizeType(data.element_type) || normalizeEntityStyleName(focusType);
    let layer = selectRouteParam(store, 'sl', 'inline');
    if (!data.style && layer === 'style') layer = 'inline';
    let styles = focusType && focusId
        ? selectStyles(store, focusType, focusId, layer)
        : {};
    let elements = styles ? Object.keys(styles) : nullArr;
    let elementDefinitions = selectElements(store, type);
    let legacyFilter = legacy[type] || [];
    let defaultElements = elementDefinitions.filter(s=>{
        let config = defaultElementsConfig[type] || [];
        return config.indexOf(s) > -1 && elements.indexOf(s)===-1 && legacyFilter.indexOf(s)===-1;
    });
    let allElements = elementDefinitions.filter(s=>{
        return defaultElements.indexOf(s) === -1 && elements.indexOf(s) === -1;
    });
    return {
        style: data.style || type,
        elements,
        defaultElements,
        allElements,
        styles,
        type,
        layer,
        options: selectEntity(store, 'cms.styles', 'options'),
        roles: selectEntity(store, 'cms.styles', 'element_roles')[focusStyle],
        hasBreakpoints: type !== "page_layout",
        hasInline: type !== "page_layout"
    }
};

const mapDispatchToProps = {
    onFocusStyle: pushRouteParams,
    onSelectLayer: pushRouteParams,
    onChange: change
};

class StylesContainer extends React.PureComponent {

    focusStyle = (id) => {
        const { onFocusStyle } = this.props;
        onFocusStyle({s: id});
    };

    selectLayer = (id) => {
        const { onSelectLayer } = this.props;
        onSelectLayer({sl: id});
    };

    change = (id, value) => {
        const {
            layer,
            focusId,
            focusType,
            style,
            onChange
        } = this.props;

        let styles = this.props.styles || {};
        let update = {};
        update[id] = value;

        //return;

        if (layer === 'global') {
            onChange('cms.styles', style, update);
            return;
        }

        if (layer === 'style' && style) {
            onChange('cms.styles', style, update);
            return;
        }

        if (layer === 'inline') {
            onChange(focusType, focusId, {
                styles: {
                    ...styles,
                    ...update
                }
            });
        }
    };

    render() {
        return (<Styles
            {...this.props}
            onFocusStyle={this.focusStyle}
            onSelectLayer={this.selectLayer}
            onChange={this.change}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StylesContainer);