import {useSelector} from "react-redux";
import {selectList} from "state/selectors/lists";
import {useMemo} from "react";

const nullObj = {};
const nullArr = [];

export default function useParticipants(id, list, session) {
    const all = useSelector(store=>selectList(store, 'db.identities', list));

    const presence = useSelector(store=>store.presence[id]) || nullObj;
    const sessionPresence = useSelector(store=>store.presence[session]) || nullObj;
    const identities = useSelector(store=>store.data['db.identities']);
    const onlineUsers = presence.users || nullArr;
    const connectedIdentities = sessionPresence.session || nullObj;

    const connected = useMemo(() => {
        return Object.keys(connectedIdentities).filter(id => {
            return connectedIdentities[id].connected;
        });
    }, [connectedIdentities]);

    const online = useMemo(() => {
        return all.filter(identity => {
            if (identities[identity]) {
                return onlineUsers.indexOf(identities[identity].created_by) > -1;
            }
            return true;
        });
    }, [all, identities, onlineUsers]);

    return useMemo(() => ({
        connected,
        online: online.filter(id=>connected.indexOf(id) === -1),
        offline: all.filter(id=>connected.concat(online).indexOf(id) === -1),
        total: all.length
    }), [connected, online, all]);
}