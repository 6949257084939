import React from "react";
import WorkspaceHeaderDesktop from "./WorkspaceHeader";
import WorkspaceHeaderMobile from "./WorkspaceHeader.mobile";
import useScreenSize from "util/hooks/useScreenSize";

function WorkspaceHeaderContainer(props) {
    const { isMobile, isSmallTablet } = useScreenSize();

    if (isMobile || isSmallTablet) {
        return <WorkspaceHeaderMobile {...props} />;
    }

    return <WorkspaceHeaderDesktop {...props} />;
}

export default WorkspaceHeaderContainer;
