import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from "classnames";
//import PropTypes from 'prop-types';

export default function ScrollFade({className, hasMore, children}) {
    const ref = useRef();
    const [scrollState, setScrollState] = useState('start');

    const scrollWatch = useCallback((evt) => {
        const e = evt.target;
        if (!e) return;
        const contentElement = e.querySelector('.scroll-content');
        if (!contentElement) return;
        const innerHeight = contentElement.offsetHeight;
        const height = e.offsetHeight;
        const max = innerHeight - height;
        const s = e.scrollTop;
        if (s >= max) {
            setScrollState('end');
            return;
        }
        if (s === 0) {
            setScrollState('start');
            return;
        }
        setScrollState('scrolling');
    }, [setScrollState]);

    useEffect(() => {
        const e = ref.current;
        if (e) e.addEventListener('scroll', scrollWatch);
        return () => e.removeEventListener('scroll', scrollWatch);
    }, [scrollWatch]);

    let s = scrollState === 'end' && hasMore
        ? 'scrolling'
        : scrollState;

    return <div className={cn('scroll-fade', `scroll-fade--${s}`)}>
        <div className={cn(className, 'overflow-scroll')} ref={ref}>
            {children}
        </div>
    </div>;
}

ScrollFade.propTypes = {};
ScrollFade.defaultProps = {};
