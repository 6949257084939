import React from 'react';
//import PropTypes from 'prop-types';
import Fetch from "containers/Fetch";
import Stage from "joynt/views/stage/Stage";
import {useMeetingSession} from "joynt/meeting/MeetingContainer";
import useProgramaticJoin from "joynt/meeting/hooks/useProgramaticJoin";
import {useEdgesData, useNodeData} from "joynt/hooks";

export default function MainStage(props) {

    const {id} = props;
    const {session} = useMeetingSession();
    const {parent_session} = useEdgesData(session);
    const {current_event} = useNodeData(id);

    const outsideSession = session
        && session !== id
        && current_event && (parent_session === current_event);

    useProgramaticJoin(id);

    return (<>
        <Fetch
            type={"db.nodes"}
            id={current_event}
            enable={!!current_event}
        />
        <Stage
            {...props}
            id={outsideSession ? session : id}
            isEvent={!outsideSession}
        />
    </>);

}

MainStage.propTypes = {};
MainStage.defaultProps = {};