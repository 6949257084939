import React from "react";
import { useNodeData } from "joynt/hooks";
import { useCover } from "state/hooks";
import cn from "classnames";

import UploadOverlay from "./UploadOverlay";

//import PropTypes from 'prop-types';

export function HeaderImage({ id, field, children }) {
    const { [field]: media, can_edit } = useNodeData(id);
    const img = useCover(media, [1200, 320]);

    if (!img && !can_edit)
        return (
            <div className={"workspace-header__cover"}>
                {children && (
                    <div className={"workspace-header__content"}>
                        {children}
                    </div>
                )}
            </div>
        );

    return (
        <div
            className={cn("workspace-header__cover", {
                "is-empty": !img,
            })}
        >
            <img
                className={"workspace-header__cover-image"}
                src={img}
                alt={""}
            />
            {can_edit && <UploadOverlay variant={"button"} field={field} />}
            {children && (
                <div className={"workspace-header__content"}>{children}</div>
            )}
        </div>
    );
}

HeaderImage.propTypes = {};
HeaderImage.defaultProps = {
    field: "media_header",
};

export default HeaderImage;
