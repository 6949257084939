import { createReducer } from "state/helpers/create-reducer";
import { setIn } from "lodash-redux-immutability";

const actions = {
    "DATA.STORE.LIST": (state, { payload }) => {
        const { type, list, meta } = payload;
        return setIn(state, [type, list], meta);
    },
    "DATA.LIST.RESET": (state, { payload }) => {
        const { type, list } = payload;
        const prevCounts = state?.[type]?.[list]?.pagination?.tag_counts;
        let next = setIn(state, [type, list], {
            pagination: { tag_counts: prevCounts || {} },
        });
        return setIn(next, ["_props", type, list], {});
    },
    "PAGINATION.SET_PAGE": (state, { payload }) => {
        const { type, list, page } = payload;
        return setIn(state, [type, list, "current"], page);
    },
    "LIST.UPDATED": (state, { payload }) => {
        const { type, list, timestamp } = payload;
        return setIn(state, [type, list, "updated"], timestamp);
    },
    "LIST.REGISTER_PROPS": (state, { payload }) => {
        const { type, list } = payload;
        return setIn(state, ["_props", type, list], payload);
    },
    "DATA.STORE.BATCH": (state, { payload }) => {
        const { meta } = payload;
        if (meta.lists) {
            Object.keys(meta.lists).forEach((listId) => {
                if (Object.keys(meta.lists[listId]).includes("meta")) {
                    let [type, list] = listId.split("/");
                    state = setIn(state, [type, list], meta.lists[listId].meta);
                }
            });
        }
        return state;
    },
    /*'DATA.STORE.LIST_IDS': (state, {payload}) => {
        const { type, list, meta } = payload;
        return setIn(state, [type, list], meta);
    }*/
};

export default createReducer({}, actions);
