import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Icon from "components/Icon";

export function SelectValue(props) {
    const { icon, value, label, onClick } = props;
    return (
        <div
            className={cn("select-value", {
                "is-empty": !value,
            })}
            onClick={onClick}
        >
            {icon && <Icon className={"select-list-icon"}>{icon}</Icon>}
            {value ? value : label}
        </div>
    );
}

SelectValue.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};

SelectValue.defaultProps = {};

export default SelectValue;
