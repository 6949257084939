import {selectEntity} from "state/selectors/data";
import {NODE_TYPE_EVENT} from "joynt/config";

export function selectAccessProfile(store, id) {
    const { subtype, session_type, workspace, published } = selectEntity(
        store,
        "db.nodes",
        id
    );

    if (subtype === NODE_TYPE_EVENT && !workspace)
        return session_type === "private" ? "private" : "public";

    return published ? "public" : "private";
}