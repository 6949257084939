import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Slide from "@material-ui/core/Slide";
import Icon from "components/Icon";
import IconButton from "joynt/components/IconButton";
import NotificationAvatar from "joynt/notifications/Avatar";

import "./style.css";

export default function Toast(props) {
    const { id, invert, variant, onHide, onClick } = props;
    const { icon, description } = props;

    const [visible, setVisible] = useState(true);

    const hide = useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            setVisible(false);
        },
        [setVisible]
    );

    const click = useCallback(
        (evt) => {
            if (onHide) hide(evt);
            onClick(id);
        },
        [onClick, onHide, id, hide]
    );

    const onExited = useCallback(() => {
        if (onHide) onHide(id);
    }, [id, onHide]);

    return (
        <Slide
            direction="down"
            in={visible}
            mountOnEnter
            unmountOnExit
            onExited={onExited}
        >
            <div
                className={cn("toast cols", {
                    invert: invert,
                })}
                onClick={click}
            >
                {variant === "identity" ? (
                    <div className={"toast-icon pad-sm rows rows-middle"}>
                        <NotificationAvatar id={id} icon={icon} />
                    </div>
                ) : (
                    <div
                        className={"toast-icon pad-sm rows rows-middle bg-teal"}
                    >
                        <Icon>{icon}</Icon>
                    </div>
                )}
                <div
                    className={
                        "grow cols cols-middle pad-sm gap-md cols-spread"
                    }
                >
                    {description}
                    {onHide ? (
                        <IconButton
                            size={"small"}
                            icon={"mui-close"}
                            onClick={hide}
                        />
                    ) : null}
                </div>
            </div>
        </Slide>
    );
}

Toast.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onHide: PropTypes.func,
    invert: PropTypes.bool,
};

Toast.defaultProps = {
    count: 1,
    invert: false,
};
