import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useData } from "state/hooks";

const LayoutSlotItem = React.forwardRef((props, ref) => {
    const { onClick } = props;
    const { placeholder_id } = useData(props);
    return (
        <div
            ref={ref}
            className={cn({
                "list-item": true,
                "layout-slot-item": true,
                "is-dragging": props.isDragging || props.isDraggedInto,
            })}
            onClick={onClick}
        >
            <div className={"layout-slot-item-content"}>
                {placeholder_id || "Treść podstrony"}
            </div>
        </div>
    );
});

LayoutSlotItem.propTypes = {};

LayoutSlotItem.defaultProps = {};

export default LayoutSlotItem;
