import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Badge from "@material-ui/core/Badge";
import MuiAvatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { abbreviation } from "joynt/util";
import { colors as joyntAvatarColors } from "config/colors";

const colors = {
    online: "#44b700",
    away: "#f7ac16",
};

const avatarColorList = Object.keys(joyntAvatarColors).sort();

function nameToColor(name) {
    let firstLetterCode = name.charCodeAt(0);
    const seed = firstLetterCode % avatarColorList.length;

    const colorName = avatarColorList[seed];
    return joyntAvatarColors[colorName];
}

const StyledBadge = withStyles((theme) => ({
    badge: (props) => ({
        backgroundColor: props.isAway ? colors.away : colors.online,
        color: props.isAway ? colors.away : colors.online,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            //animation: '$ripple 1.2s infinite ease-in-out',
            border: "1px solid currentColor",
            content: '""',
        },
    }),
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            alignItems: "center",
            "& > *": {
                margin: 0, //theme.spacing(1),
            },
        },
        xs: (props) => ({
            backgroundColor: nameToColor(props.name || ""),
            width: "24px",
            height: "24px",
            fontSize: "10px",
        }),
        small: (props) => ({
            backgroundColor: nameToColor(props.name || ""),
            width: theme.spacing(4),
            height: theme.spacing(4),
            fontSize: "14px",
        }),
        medium: (props) => ({
            backgroundColor: nameToColor(props.name || ""),
            width: theme.spacing(5),
            height: theme.spacing(5),
            fontSize: "14px",
        }),
        large: (props) => ({
            backgroundColor: nameToColor(props.name || ""),
            width: theme.spacing(8),
            height: theme.spacing(8),
            fontSize: "14px",
        }),
    };
});

const badgeAnchor = {
    vertical: "bottom",
    horizontal: "right",
};

export default function Avatar(props) {
    const { name, src, isOnline, isAway, size, className } = props;

    const classes = useStyles(props);

    const avatar = (
        <MuiAvatar
            alt={name}
            src={src}
            className={cn(classes[size], className)}
        >
            {!src && abbreviation(name)?.substring(0, 2)}
        </MuiAvatar>
    );

    return (
        <div className={cn(classes.root)}>
            {isOnline ? (
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={badgeAnchor}
                    variant="dot"
                    isAway={isAway}
                >
                    {avatar}
                </StyledBadge>
            ) : (
                avatar
            )}
        </div>
    );
}

Avatar.defaultProps = {
    size: "small",
};

export const AvatarSizeProps = PropTypes.oneOf([
    "xs",
    "small",
    "medium",
    "large",
]);

Avatar.propTypes = {
    size: AvatarSizeProps,
};
