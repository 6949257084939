import Cookies from "js-cookie";
import {isAuthenticated} from "util/auth/check";

export function setSession(result) {
    // Set the time that the access token will expire at
    const accessToken = result.accessToken || result.access_token;
    const expiresIn = result.expiresIn || result.expires_in;
    let expiresAt = JSON.stringify(
        expiresIn * 1000 + new Date().getTime()
    );
    localStorage.removeItem("auth_state");
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("expires_at", expiresAt);
    Cookies.set('access_token', accessToken);
    const evt = new Event('access-token-changed');
    document.dispatchEvent(evt);
}


export function removeSession() {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        Cookies.remove('access_token');
        const evt = new Event('access-token-changed');
        document.dispatchEvent(evt);
        resolve();
    });
}

export function token() {
    if (!isAuthenticated()) return null;
    return localStorage.getItem("access_token");
}

export function tokenExpiresSoon() {
    const expiresAt = localStorage.getItem("expires_at");
    const expiresIn = expiresAt - (new Date()).getTime();
    return expiresIn < 60
}