import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const MoreContentButton = ({ count, label, children, onClick }) => {
    return (
        <button className={"more-content-button"} onClick={onClick}>
            {typeof count === "number" && (
                <span className={"more-content-button__count"}>
                    {count > 0 && "+"}
                    {count}
                </span>
            )}
            {(children || label) && (
                <p className={"more-content-button__label"}>
                    {children || label}
                </p>
            )}
        </button>
    );
};

MoreContentButton.propTypes = {
    count: PropTypes.number,
    label: PropTypes.string,
    children: PropTypes.node,
};

export default MoreContentButton;
