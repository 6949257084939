import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';

import themeViews from "cms/ui/theme/views";
import themeOptions from "cms/ui/config/theme-options";
import IconButtonGroup from "cms/ui/IconButtonGroup";
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";
import IconButton from "components/IconButton";
import {compileStyles} from "cms/state/actions/theme";
import Fetch from "containers/Fetch";

const mapState = (store, props) => {
    return {
        view: selectRouteParam(store, 'theme', themeOptions[0].value)
    }
};

const mapDispatch = {
    onChangeView: pushRouteParams,
    onReload: compileStyles
};

class ThemeEditor extends React.PureComponent {

    switch = (view) => {
        const { onChangeView } = this.props;
        onChangeView({theme: view});
    };

    render() {
        const { view, onReload } = this.props;
        const View = themeViews[view];

        return (<div className={'grow rows overflow-hide'}>
            <div className={'pad-xs cols cols-spread'}>
                <div>
                    <IconButtonGroup
                        value={view}
                        items={themeOptions}
                        onToggle={this.switch} />
                </div>
                <div>
                    <IconButton size={'small'} icon={'mui-replay'} onClick={onReload} />
                </div>
            </div>
            <div className={'rows grow overflow-scroll form-regular'}>
                {View ? <View /> : null}
            </div>
            <Fetch
                type={'theme.files'}
                url={'cms/theme/files?v3=true&v4=true'}
                list={'default'} />
        </div>);
    }

}

ThemeEditor.propTypes = {};

export default connect(mapState, mapDispatch)(ThemeEditor);