import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import Radio from "@material-ui/core/Radio";
import Field from 'containers/Field';

import Card from '@material-ui/core/Card';

import 'playground/booking/css/confirmation-methods.css';

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

function ConfirmationMethod(props) {
    const { name, id, onSelect, selected } = props;
    const onClick = useCallback(() => onSelect(id), [onSelect, id]);
    return <Card onClick={!selected ? onClick : null} className={'confirmation-method pad-sm cols gap-sm'}>
        <div>
            <Radio checked={selected} />
        </div>
        <div>
            {name}
        </div>
    </Card>;
}

function RenderMethodList({onChange, value}) {

    return <div className={'rows gap-xs'}>
        <ConfirmationMethod
            id={'online'}
            onSelect={onChange}
            selected={value === 'online'}
            name={'Płatność kartą kredytową / przelewem online'}
        />
        <ConfirmationMethod
            id={'gpay'}
            onSelect={onChange}
            selected={value === 'gpay'}
            name={'Google Payments'}
        />
        <ConfirmationMethod
            id={'blik'}
            onSelect={onChange}
            selected={value === 'blik'}
            name={'BLIK'}
        />
    </div>
}

class Payment extends React.Component {

    render() {
        return <Field id={'confirmation_method'}>
            {RenderMethodList}
        </Field>;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payment);