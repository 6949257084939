import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';
import editorCss from "./editorCss";

import HostElement from "./HostElement";
import { StylesProvider } from "cms/styles/legacy";
import { loadWebfont } from "util/theme/font-loader";
import { selectEntity, selectEntityData } from "state/selectors/data";
import { useSelector } from "react-redux";
import { memoize } from "lodash";
import { selectWebsiteCanonicalHost } from "cms/state/selectors/cms";
import Preloader from "components/Preloader";
import { useFrame } from "react-frame-component";
import { useFetch } from "state/hooks";
import version from "util/version";

const CACHE_BYPASS_PORT = process.env.REACT_APP_CACHE_BYPASS_PORT;
const CACHE_BYPASS_PORT_HTTPS = process.env.REACT_APP_CACHE_BYPASS_PORT_HTTPS;
const PROTOCOL = window.location.protocol;
const ENDPOINT = process.env.REACT_APP_CONSOLE_ASSETS_URL;

const keys = ["body_font", "decorative_font", "heading_font"];

const consoleAssetUrl = (asset) => {
    const url = PROTOCOL + "//" + [ENDPOINT, asset].join("/");
    return url.replace("%version", version());
};

function selectFamilies(typography) {
    return keys.reduce((acc, key) => {
        const font = typography[key];
        if (font?.family) {
            acc.push({ family: font.family, source: font.source });
        }
        return acc;
    }, []);
}

function familiesCacheKey(typography) {
    const families = selectFamilies(typography);
    return families.map((f) => f.family).join(",");
}

const selectPresetFontFamilies = memoize(selectFamilies, familiesCacheKey);

function useFontLoader(presetTypography) {
    const families = selectPresetFontFamilies(presetTypography);
    return useCallback(
        (frame, setState) => {
            (async function () {
                console.log("Load fonts", families);
                if (setState) setState(true);
                await Promise.all(
                    families.map((font) => loadWebfont(font, frame))
                );
                if (setState) setState(false);
            })();
        },
        [families]
    );
}

function selectWebsiteTheme(state, websiteId) {
    const website = selectEntity(state, "console.services", websiteId);
    const theme = selectEntity(state, "console.themes", website?.theme);
    const preset = selectEntity(state, "console.presets", theme?.preset);
    const host = selectWebsiteCanonicalHost(state, websiteId);
    const port =
        host?.indexOf("https") === 0
            ? CACHE_BYPASS_PORT_HTTPS
            : CACHE_BYPASS_PORT;
    return { theme, preset, themeAssetsHost: `${host}:${port}` };
}

function selectCompiledCss(store) {
    const file = selectEntityData(store, "theme.files", "_compiled");
    return file?.content;
}

function FrameLoader(props) {
    const { css, children, onLoadFonts, host, setState, state } = props;
    const { window, document } = useFrame();

    const isReady = state.fonts && (state.styles || css);

    useEffect(() => {
        onLoadFonts(window);
    }, [css, onLoadFonts, window]);

    useEffect(() => {
        (async function () {
            await document.fonts.ready;
            setState((prev) => ({ ...prev, fonts: true }));
        })();
    }, [document, setState]);

    return (
        <>
            {/*<link rel="stylesheet" href={consoleAssetUrl("quill.core.css")} />*/}
            {/*<link rel="stylesheet" href={consoleAssetUrl("quill.bubble.css")} />*/}
            <style>{editorCss}</style>
            {!css && host && (
                <link
                    rel={"stylesheet"}
                    href={`${host}/assets/styles/all.min.css`}
                    onLoad={() =>
                        setState((prev) => ({ ...prev, styles: true }))
                    }
                />
            )}
            <link
                rel={"stylesheet"}
                href={"https://cdn.zuu.systems/fh-default/fh-default.css"}
            />
            {css && <style>{css}</style>}
            {isReady ? children : <div>Loading theme assets...</div>}
        </>
    );
}

export function ThemeShadow(props) {
    const { children, websiteId, fetchStyles, options } = props;

    const { preset, themeAssetsHost } = useSelector((store) =>
        selectWebsiteTheme(store, websiteId)
    );

    const css = useSelector(selectCompiledCss);
    const fontLoader = useFontLoader(preset?.typography);

    useFetch({
        list: "default",
        url: `v2/cms/styles?service=${websiteId}`,
        useContext: false,
        fetchId: "front.page",
        enable: fetchStyles,
    });

    const [loadingState, setLoadingState] = useState({
        fonts: false,
        styles: !!css,
    });
    const isReady = loadingState.fonts && (loadingState.styles || css);

    return (
        <>
            <Preloader visible={!isReady} />
            <HostElement {...props}>
                <FrameLoader
                    css={css}
                    onLoadFonts={fontLoader}
                    host={themeAssetsHost}
                    state={loadingState}
                    setState={setLoadingState}
                >
                    <StylesProvider>{children}</StylesProvider>
                </FrameLoader>
            </HostElement>
        </>
    );
}

ThemeShadow.propTypes = {};

ThemeShadow.defaultProps = {
    fetchStyles: false,
    options: {},
};

export default ThemeShadow;
