import React from "react";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";

import "./style.css";

export function ProjectCard(props) {
    const { onClick } = props;
    const data = useData(props);
    const domainId = data.canonical_domain;
    const themeId = data.theme;
    const domain = useData({ type: "console.domains", id: domainId });
    const theme = useData({ type: "console.themes", id: themeId });
    const presetId = theme.preset;
    const preset = useData({ type: "console.presets", id: presetId });
    const color = preset?.color_scheme?.primary_color;
    const lightColor = preset?.color_scheme?.light_color;
    const style = {
        border: `solid 1px ${lightColor}`,
        borderLeft: `solid 4px ${color}`,
    };
    const click = () => onClick(data.name);

    return (
        <div className={"project-card"} onClick={click} style={style}>
            <div className={"project-card-name"}>{data.name}</div>
            <div className={"project-card-url"}>{domain?.hostname}</div>
            <div>{}</div>
        </div>
    );
}

ProjectCard.propTypes = {};

ProjectCard.defaultProps = {};

export default ProjectCard;
