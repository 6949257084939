import React, { useContext } from "react";
import { strip } from "playground/designer/Zmap/zmap";

export const ZmapContext = React.createContext({});

export const ComponentMappingContext = React.createContext({});

export function useZmapContext() {
    return useContext(ZmapContext);
}

export function useComponentMapping(type, id) {
    return useContext(ComponentMappingContext);
}

export function useFieldMapping(type, id, field, mapAs = []) {
    const mapping = useComponentMapping(type, id);
    const tokens = mapping?.zmap || [];
    const result = mapping?.result || {};
    const byField = tokens.reduce((acc, token) => {
        acc[strip(token.to)] = token;
        return acc;
    }, {});
    let mapped = byField[field];
    if (mapped) {
        return { ...mapped, value: result[field] };
    }
    if (!mapAs.length) return {};
    return mapAs.reduce((acc, as) => {
        let mapped = byField[as];
        if (mapped) {
            acc = { ...mapped, value: result[as] };
        }
        return acc;
    }, {});
}
