import React from 'react';
//import PropTypes from 'prop-types';

import "joynt/css/typing-dot.css";

export default function TypingDots(props) {

    return (<div className={'cols'}>
        <div className={'typing-dot'} />
        <div className={'typing-dot'} />
        <div className={'typing-dot'} />
    </div>);

}

TypingDots.propTypes = {};
TypingDots.defaultProps = {};
