import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import { SpaceAvatar } from "joynt/components/SpaceAvatar";

export function NavItem(props) {
    const { isActive, onClick, ...other } = props;

    return (
        <div
            onClick={onClick}
            className={cn("tray-item", {
                "is-active": isActive,
            })}
        >
            <div>
                <SpaceAvatar {...other} />
            </div>
        </div>
    );
}

NavItem.propTypes = {
    isActive: PropTypes.bool,
};

NavItem.defaultProps = {
    isActive: false,
};

export default NavItem;
