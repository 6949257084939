import { useMemo } from "react";
import { RECORDING_IDENTITY } from "joynt/meeting/config";

export default function useStreams(props) {
    const {
        user,
        localTracks,
        users,
        activeSpeakers,
        showAll,
        prioritizeActive,
    } = props;

    /**
     * Create local user object from localTracks
     * @type {{uid: *, videoTrack: *, count: *, audioTrack: *}}
     */
    const local = useMemo(
        () => ({
            uid: user,
            count: localTracks.length,
            audioTrack: localTracks.filter(
                (track) => track.trackMediaType === "audio"
            )[0],
            videoTrack: localTracks.filter(
                (track) => track.trackMediaType === "video"
            )[0],
        }),
        [localTracks, user]
    );

    let remote = useMemo(() => {
        let filtered = users.filter((u) => !!u.uid);
        filtered = filtered.reduce((activeStreams, stream) => {
            let { audioTrack, videoTrack } = stream;
            let streamId = stream.uid.toString();
            let videoStatus = !!(stream.hasVideo || videoTrack);
            let includeStream = audioTrack || videoStatus || showAll;
            let isLocal = streamId.indexOf(user) > -1;
            let isScreen = streamId.indexOf("screen_") > -1;
            if (streamId === RECORDING_IDENTITY) includeStream = false;
            if (includeStream && !isLocal && !isScreen) {
                activeStreams[streamId] = stream;
            }
            return activeStreams;
        }, {});
        return Object.values(filtered);
    }, [users, user, showAll]);

    const remoteScreenShare = useMemo(() => {
        return users.reduce((value, stream) => {
            const id = stream.uid.toString();
            if (id.indexOf("screen_") > -1 && id.indexOf(user) === -1)
                return stream;
            return value;
        }, null);
    }, [users, user]);

    let sort = prioritizeActive || !!remoteScreenShare;
    let all = [];

    if (local.uid && (local.audioTrack || local.videoTrack || showAll)) {
        remote = [local].concat(remote);
    }
    if (remoteScreenShare) all.push(remoteScreenShare);

    /**
     * Order tracks by activity
     */
    remote = sort
        ? remote.sort(function (a, b) {
              let aTime = activeSpeakers[a.uid.toString()] || 0;
              let bTime = activeSpeakers[b.uid.toString()] || 0;

              return bTime - aTime;
          })
        : remote;

    let active = remote.filter((s) => s.audioTrack);
    let inactive = remote.filter((s) => !s.audioTrack);

    all = sort ? all.concat(active).concat(inactive) : remote;

    /**
     * Find out which track should be in focus
     */
    let focus = null;

    if (remoteScreenShare) focus = remoteScreenShare.uid.toString();
    if (!focus && active.length) focus = active[0].uid.toString();
    if (!focus) focus = Object.keys(activeSpeakers)[0];
    if (!focus && remote.length) focus = remote[0].uid.toString();
    if (!focus) focus = user;

    return {
        tracks: all.slice(),
        remote: remote.length,
        screen: !!remoteScreenShare,
        active: active.length,
        focus,
    };
}
