import React from "react";
import PropTypes from "prop-types";

import { useNode } from "joynt/hooks";
import { useClick } from "state/hooks";
import cn from "classnames";
import IconButton from "joynt/components/IconButton";
import Button from "components/Button";
import moment from "moment";
import Icon from "components/Icon";
import KeepIcon from "joynt/components/KeepIcon";
import { useCover } from "state/hooks";
import PostIdentity from "joynt/post/PostIdentity";
import RenderContent from "joynt/content";
import { DEFAULT_CARD_COLOR, NODE_TYPE_EVENT } from "joynt/config";
import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";
import Skeleton from "joynt/components/Skeleton";
import Preloader from "components/Preloader";

export function CardSkeleton() {
    return (
        <Skeleton className={"tile-skeleton"} width={200} height={200}>
            <rect x={0} y={0} width={200} height={200} />
        </Skeleton>
    );
}

export function duration(start, end) {
    const startMoment = moment.unix(start);
    const endMoment = moment.unix(end);
    const duration = moment.duration(endMoment.diff(startMoment));
    const totalMinutes = duration.as("minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours && minutes) return `${hours}h ${minutes} min`;
    if (hours) return `${hours}h`;
    return `${minutes} min`;
}

function DateFormat({ value, format }) {
    return moment.unix(value).format(format || "ddd, DD MMM");
}

export function EventDuration({ id }) {
    const { event_start, event_end } = useNode(id);

    return (
        <>
            <DateFormat value={event_start} format={"H:mm"} /> -{" "}
            <DateFormat value={event_end} format={"H:mm"} />,{" "}
            <DateFormat value={event_start} />
        </>
    );
}

export default function Card(props) {
    const {
        id,
        onClick,
        onPreview,
        onKeep,
        onEdit,
        className,
        buttonLabel,
        namePlaceholder,
        durationPlaceholder,
        info,
        buttons,
        tileImageOverlay,
        isPending,
    } = props;

    const {
        name,
        can_edit,
        media,
        content,
        event_speakers,
        event_start,
        event_end,
        color,
        subtype,
        description: subtitle,
    } = useNode(id);

    const bg = useCover(media, [640, "auto"]);
    const isLogo = bg && (bg.indexOf(".svg&") > -1 || bg.indexOf(".png&") > -1);

    const click = useClick({ onClick, id });
    const preview = useClick({ onClick: onPreview, id });
    const edit = useClick({ onClick: onEdit, id });

    const isEvent = subtype === NODE_TYPE_EVENT;

    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );
    const hasImg = !!bg || !!tileImageOverlay;
    const hasContent = !!content;

    const classes = {
        "with-img": hasImg,
        "cols-2": hasImg || hasContent,
        "with-content": hasContent,
    };

    classes[color || DEFAULT_CARD_COLOR] = true;

    const borderClass = `bg-${color || DEFAULT_CARD_COLOR}`;

    let clickHandler = onClick ? click : null;
    let actionHandler = onPreview ? preview : null;

    const EventStatusInfo = () => {
        return (
            <div className={"cols cols-spread cols-middle gap-sm"}>
                <div>
                    <PostIdentity
                        identities={isEvent ? event_speakers : presence}
                        size={!isEvent && "md"}
                    />
                </div>
                <div>
                    {!!presence.length && (
                        <div className={"cols cols-middle gap-px"}>
                            <Icon fontSize={"small"}>mui-person</Icon>
                            <div>{presence.length}</div>
                        </div>
                    )}
                </div>
                {buttons || (
                    <div className={"cols cols-middle gap-sm"}>
                        {can_edit && onEdit && (
                            <IconButton
                                icon={"mui-settings"}
                                size={"small"}
                                onClick={edit}
                            />
                        )}
                        {actionHandler && (
                            <Button
                                variant={"outlined"}
                                onClick={actionHandler}
                            >
                                {buttonLabel}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={cn("tile cols gap-sm", className, classes)}
            onClick={!isPending ? clickHandler : null}
        >
            <div className={cn("tile-border", borderClass)} />
            <div className={"tile-content rows rows-spread fill"}>
                <div className={"cols grow cols-spread cols-top overflow-hide"}>
                    <div className={"rows gap-xs"}>
                        {event_start && event_end && (
                            <div>
                                <EventDuration id={id} />
                            </div>
                        )}
                        {(!event_start || !event_end) &&
                            durationPlaceholder && (
                                <div>{durationPlaceholder}</div>
                            )}
                        <div className={"tile-name"}>
                            {name || namePlaceholder}
                        </div>
                        {subtitle && (
                            <div className={"tile-subtitle"}>{subtitle}</div>
                        )}
                        {content && (
                            <div className={"tile-description"}>
                                <RenderContent
                                    size={"sm"}
                                    content={content}
                                    maxLength={240}
                                />
                            </div>
                        )}
                    </div>
                    {onKeep && <KeepIcon id={id} onKeep={onKeep} />}
                </div>
                {info ? (
                    <div className={"cols cols-bottom"}>
                        <div className={"o-50"}>{info}</div>
                    </div>
                ) : (
                    <EventStatusInfo />
                )}
            </div>
            {hasImg && (
                <div className={"tile-img"}>
                    {bg && (
                        <img
                            src={bg}
                            alt={name}
                            className={cn("img", {
                                "img-cover": !isLogo,
                                "img-contain": isLogo,
                            })}
                        />
                    )}
                    {tileImageOverlay && (
                        <div className={"tile-img-overlay"}>
                            {tileImageOverlay}
                        </div>
                    )}
                </div>
            )}
            {isPending && <Preloader visible={true} />}
        </div>
    );
}

Card.propTypes = {
    buttonLabel: PropTypes.string,
};
Card.defaultProps = {
    buttonLabel: "Info",
};
