import { takeEvery, put, select } from 'redux-saga/effects';
import {selectModals} from "state/selectors/modal";
import {pushRouteParams} from "state/actions/router";

function serializeModals(modalsObj) {
    if (!modalsObj.length) return null;
    let modals = modalsObj.map(modal=>{
        return modal.path.join('|');
    });
    return modals.join(',');
}

function* modalPush(action) {
    try {
        const { path } = action.payload;
        const modals = yield select(store=>selectModals(store));
        modals.push({path: path.split('|')});
        let modalsStr = serializeModals(modals);
        yield put(pushRouteParams({modals: modalsStr}));
    } catch (e) {
        console.log(e);
    }
}

function* modalPop(action) {
    try {
        const modals = yield select(store=>selectModals(store));
        modals.pop();
        let modalsStr = serializeModals(modals);
        yield put(pushRouteParams({modals: modalsStr}));
    } catch (e) {
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('MODAL.PUSH', modalPush);
    yield takeEvery('MODAL.POP', modalPop);
}