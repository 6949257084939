import React from 'react';
import cn from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
//import PropTypes from 'prop-types';

export default function NetworkStateInfo(props) {

    const {isVisible, isOnline} = props;

    return (<div className={cn('network-state', {
        'network-state--online': isOnline,
        'network-state--offline': !isOnline,
        'is-visible': isVisible
    })}>
        {!isOnline ? <div className={'cols cols-middle gap-sm'}>
            <CircularProgress color={'inherit'} size={20} />
            <div>You have no internet. Trying to reconnect...</div>
        </div> : null }
    </div>);

}

NetworkStateInfo.propTypes = {};
NetworkStateInfo.defaultProps = {
    isVisible: false,
    isOnline: true
};