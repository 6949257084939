import React from "react";
//import PropTypes from 'prop-types';
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";

const classes = {
    root: "input-dense",
};

const classesEmpty = {
    root: "input-dense",
    input: "value-placeholder",
};

export default function TextDense(props) {
    const {
        onChange,
        label,
        value,
        autoFocus,
        onBlur,
        addonComponent,
        InputProps,
        placeholder,
        ...rest
    } = props;

    const handleChange = (evt) => {
        onChange(evt.target.value);
    };

    return (
        <FormControl style={{ width: "100%" }}>
            {value ? <div className={"input-dense__label"}>{label}</div> : null}
            <InputBase
                {...rest}
                onChange={handleChange}
                value={value}
                placeholder={placeholder || label}
                fullWidth={true}
                type="text"
                autoFocus={autoFocus}
                onBlur={onBlur}
                classes={value ? classes : classesEmpty}
                endAdornment={addonComponent || InputProps?.endAdornment}
                startAdornment={InputProps?.startAdornment}
                readOnly={InputProps?.readOnly || props.readOnly}
            />
        </FormControl>
    );
}

TextDense.propTypes = {};
