import React from 'react';
//import PropTypes from 'prop-types';

import {ChildComponents} from "cms/components/Layout";

export default class Query extends React.PureComponent {

    render() {
        const { components } = this.props;
        return <ChildComponents
            itemType={'cms.components'}
            items={components}
        />;
    }

}

Query.autoFillProps = [
    'components',
    'alias'
];

Query.propTypes = {
};