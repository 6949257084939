import React, { useRef, useState } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import Icon from "components/Icon";
import Popper from "@material-ui/core/Popper";

import "./style.css";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import LocaleSelect from "playground/cms/Layout/LocaleSelect/LocaleSelect.container";
import UserMenu from "playground/cms/Layout/UserMenu";

function ToolbarButtonGroup(props) {}

function ToolbarIcon(props) {
    const { icon, isActive, onClick, ...rest } = props;
    return (
        <div
            className={cn("toolbar-icon", {
                "is-active": isActive,
            })}
            onClick={onClick}
            {...rest}
        >
            <Icon>{icon}</Icon>
        </div>
    );
}

const ToolbarButton = React.forwardRef((props, ref) => {
    const { icon, isActive, onClick, children, ...rest } = props;
    return (
        <div
            ref={ref}
            className={cn("toolbar-button", {
                "is-active": isActive,
            })}
            onClick={onClick}
            {...rest}
        >
            <div className={"toolbar-button-icon"}>
                <Icon>{icon}</Icon>
            </div>
            {React.Children.count(children) > 0 && (
                <div className={"toolbar-button-label"}>{children}</div>
            )}
        </div>
    );
});

const breakpoints = {
    phone: { id: "phone", icon: "mui-phone_iphone" },
    tablet: { id: "tablet", icon: "mui-tablet_android" },
    desktop: { id: "desktop", icon: "mui-desktop_mac" },
    none: { id: null, icon: "mui-clear" },
};

const editModes = {
    content: { id: "content", icon: "mui-text_fields" },
    design: { id: "design", icon: "mui-brush" },
    preview: { id: "preview", icon: "mui-visibility" },
};

const userEditModes = {
    content: editModes.content,
    preview: editModes.preview,
};

function ToolbarOptionGroup(props) {
    const { options, value, onChange } = props;
    return (
        <div className={"toolbar-group"}>
            {Object.keys(options).map((key) => {
                const active = options[key].id === value;
                if (key === "none" && !value) return null;
                return (
                    <ToolbarIcon
                        key={key}
                        icon={options[key].icon}
                        isActive={active}
                        onClick={() => onChange(options[key].id)}
                    />
                );
            })}
        </div>
    );
}

function Breakpoints(props) {
    const { value, onChange } = props;
    return <ToolbarOptionGroup {...props} options={breakpoints} />;
}

function EditModes(props) {
    const { value, onChange, devAccess } = props;
    return (
        <ToolbarOptionGroup
            {...props}
            options={devAccess ? editModes : userEditModes}
        />
    );
}

function DevSettings(props) {
    const { state, setState } = props;

    const settingsRef = useRef();
    const [settings, showSettings] = useState(false);

    return (
        <>
            <ToolbarButton
                ref={settingsRef}
                onClick={() => showSettings(!settings)}
                icon={"mui-settings"}
                isActive={settings}
            />
            <Popper open={settings} anchorEl={settingsRef.current}>
                <div className={"settings-popover pad-sm rows"}>
                    <SelectList>
                        <SelectListItem
                            onClick={() =>
                                setState({
                                    showNav: !state.options.showNav,
                                })
                            }
                            isSelected={state.options.showNav}
                        >
                            show-nav
                        </SelectListItem>
                        <SelectListItem
                            onClick={() =>
                                setState({
                                    hideOverlays: !state.options.hideOverlays,
                                })
                            }
                            isSelected={state.options.hideOverlays}
                        >
                            Hide overlays
                        </SelectListItem>
                        <SelectListItem
                            onClick={() =>
                                setState({
                                    domXray: !state.options.domXray,
                                })
                            }
                            isSelected={state.options.domXray}
                        >
                            DOM: Xray
                        </SelectListItem>
                        <SelectListItem
                            onClick={() =>
                                setState({
                                    domOutline: !state.options.domOutline,
                                })
                            }
                            isSelected={state.options.domOutline}
                        >
                            DOM: Outline
                        </SelectListItem>
                        <SelectListItem
                            onClick={() => setState({ host: "iframe" })}
                            isSelected={state.options.host === "iframe"}
                        >
                            Host: Iframe
                        </SelectListItem>
                        <SelectListItem
                            onClick={() => setState({ host: "shadow" })}
                            isSelected={state.options.host === "shadow"}
                        >
                            Host: ShadowDOM
                        </SelectListItem>
                        <SelectListItem
                            onClick={() =>
                                setState({
                                    inlineEdit: !state.options.inlineEdit,
                                })
                            }
                            isSelected={!!state.options.inlineEdit}
                        >
                            Inline edit
                        </SelectListItem>
                    </SelectList>
                </div>
            </Popper>
        </>
    );
}

export function Toolbar(props) {
    const { onGoBack, state, setState, pageUrl, serviceId, devAccess } = props;

    const url = `${pageUrl.host}/${pageUrl.slug}`;
    const setMode = (mode) => setState({ mode });
    const setBreakpoint = (breakpoint) => setState({ breakpoint });
    const copyUrl = () => {
        console.log(state);
    };

    return (
        <div className={"theme-dark designer-header cols"}>
            <div className={"header-left cols-spread"}>
                <div className={"cols"}>
                    <ToolbarIcon icon={"mui-arrow_back"} onClick={onGoBack} />

                    <ToolbarButton
                        icon={"mui-list"}
                        onClick={() =>
                            setState({
                                outline: true,
                                sections: null,
                            })
                        }
                        isActive={state.outline}
                    >
                        Lista sekcji
                    </ToolbarButton>
                    <ToolbarButton
                        icon={"mui-edit_square"}
                        onClick={() =>
                            setState({
                                outline: false,
                                sections: null,
                            })
                        }
                        isActive={!state.outline}
                    >
                        Edycja
                    </ToolbarButton>
                </div>
                <ToolbarIcon
                    icon={"mui-add_circle"}
                    onClick={() =>
                        setState({
                            outline: !state.sections,
                            sections: !state.sections,
                        })
                    }
                    isActive={state.sections}
                />
            </div>
            <div className={"header-center cols-spread"}>
                <div className={"cols cold-middle"}>
                    <div
                        onClick={() => window.open(url)}
                        className={"toolbar-url"}
                    >
                        {url}
                    </div>
                    <ToolbarIcon icon={"mui-share"} onClick={() => copyUrl()} />
                </div>

                <div className={"cols gap-sm"}>
                    <div className={"rows rows-middle"}>
                        <LocaleSelect id={serviceId} />
                    </div>
                    {devAccess && (
                        <ToolbarButton
                            onClick={() => setState({ files: !state.files })}
                            icon={"mui-folder"}
                            isActive={state.files}
                        >
                            Theme
                        </ToolbarButton>
                    )}

                    <EditModes
                        value={state.mode}
                        onChange={setMode}
                        devAccess={devAccess}
                    />
                    <Breakpoints
                        value={state.breakpoint}
                        onChange={setBreakpoint}
                    />

                    {devAccess && (
                        <DevSettings state={state} setState={setState} />
                    )}
                </div>
            </div>
            <div className={"header-right cols-right"}>
                <UserMenu />
            </div>
        </div>
    );
}

Toolbar.propTypes = {};

Toolbar.defaultProps = {
    pageUrl: {},
};

export default Toolbar;
