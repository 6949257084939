import { useSelector } from "react-redux";
import useLocalStorage from "util/hooks/useLocalStorage";
import { useHasScope } from "playground/console/UserAccess";
import { SCOPE_WEBSITES_DEV } from "config/access-scopes";
import { useEffect } from "react";

const defaultState = {
    focus: null,
    focusType: null,
    focusId: null,
    style: null,
    mode: "design",
    click: null,
    contextMenu: false,
    options: {
        host: "iframe",
        domXray: false,
        domOutline: false,
        showNav: false,
        hideOverlays: false,
        inlineEdit: false,
    },
};

export function useCreateEditorState() {
    const query = useSelector((store) => store?.router?.query);
    const pageId = query?.id;

    const [state, setState] = useLocalStorage(
        `editor-state.${pageId}`,
        defaultState
    );

    const devAccess = useHasScope(SCOPE_WEBSITES_DEV);

    const result = {
        pageType: query?.resource,
        pageId: query?.id,
        ...state,
        mode: state.mode === "design" && !devAccess ? "content" : state.mode,
    };

    useEffect(() => {
        console.log(`devAccess`, devAccess);
    }, [devAccess]);

    const optionKeys = Object.keys(defaultState.options);

    const reducer = (next = {}) => {
        setState((prev) => {
            const result = { ...prev, ...next };
            if (next.focus) {
                const [item, focus] = next.focus.split("@");
                const [focusType, focusId] = (focus || next.focus).split("/");
                result.focus = [focusType, focusId].join("/");
                result.focusType = focusType;
                result.focusId = focusId;
                result.editStyle = null;
                result.editDefaultStyle = null;
                //result.item = item || next.item;
            }
            Object.keys(next).forEach((key) => {
                if (optionKeys.includes(key)) {
                    result.options[key] = next[key];
                    delete result[key];
                }
            });
            return result;
        });
    };

    return [result, reducer];
}
