import React from 'react';
import Button from "components/Button";
import {enforceHttps} from "util/uri";
import env from "util/env";
//import PropTypes from 'prop-types';

export default function Default(props) {

    const {
        onRequestOpen,
        onRequestDownload,
        fileExtension,
        file
    } = props;

    const host = enforceHttps(env('THUMBNAIL_ENDPOINT'));
    const url = `${host}/download?file=${encodeURIComponent(file)}`;

    if (fileExtension === 'pdf')
        return <iframe title={file} src={url} />;

    return <div className={'grow rows rows-middle rows-center gap-sm'}>
        <div>Preview not available ({fileExtension})</div>
        <div className={'cols gap-sm'}>
            { onRequestOpen ? <Button onClick={onRequestOpen} icon={'open_in_new'}>
                Open
            </Button> : null }
            { onRequestDownload ? <Button onClick={onRequestDownload} icon={'cloud_download'}>
                Download
            </Button> : null }
        </div>
    </div>;

}

Default.propTypes = {};
Default.defaultProps = {};
