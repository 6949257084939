import React from 'react';
import PropTypes from 'prop-types';

import {useSelector} from "react-redux";

import DailySessionList from "./DailySessionList";
import StagesList from "./StagesList";

import {selectDailySessions, selectSessionsByStages} from "./../selectors";

export function SessionListContainer(props) {

    const {
        list,
        tracks,
        selectedDay
    } = props;

    const sessions = useSelector(s => selectDailySessions(s, list, selectedDay));
    const stages = useSelector(s => selectSessionsByStages(s, sessions));
    const stageIds = Object.keys(stages);

    if (stageIds.length > 1 || tracks)
        return <StagesList items={stages} />;

    return <DailySessionList items={sessions} />;

}

SessionListContainer.propTypes = {
    id: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    selectedDay: PropTypes.number,
    tracks: PropTypes.bool
};

SessionListContainer.defaultProps = {
    tracks: false
};

export default SessionListContainer;