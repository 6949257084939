import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { sanitize } from "dompurify";
import { getCaretPosition } from "./../util";
import LibContentEditable from "react-contenteditable";

import "./style.css";

function sanitizeValue(value) {
    return sanitize(value, { ALLOWED_TAGS: [] });
}

export function ZmapInput(props) {
    const {
        value,
        onChange,
        onType,
        onAccept,
        onArrowUp,
        onArrowDown,
        onFocus,
        onBlur,
        suggestion,
        suggestionIndex,
    } = props;

    const change = (e) => {
        if (e.key === "Enter") {
            return;
        }
        onChange(sanitizeValue(e.target.value));
    };

    const ref = useRef();

    ref.current = {
        value,
        suggestion,
        suggestionIndex,
    };

    const keyup = (e) => {
        //console.log(e, e.key);
        if (
            ["Enter", "Tab", "ArrowUp", "ArrowDown", "Escape"].includes(e.key)
        ) {
            if (["Enter", "Tab"].includes(e.key)) {
                onAccept(ref.current);
                //onChange(e.target.value + suggestion);
            }
            if (["Tab"].includes(e.key)) {
                //onAccept(ref.current);
                //onChange(e.target.value + suggestion);
            }
            if (e.key === "ArrowUp" && onArrowUp)
                onArrowUp(ref.current.suggestionIndex - 1);
            if (e.key === "ArrowDown" && onArrowDown)
                onArrowDown(ref.current.suggestionIndex + 1);
            if (e.key === "Escape") {
                onBlur();
                onChange("");
            }
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        const pos = getCaretPosition(e.target);
        onType(pos);
    };

    const sanitizedValue = sanitizeValue(value);
    const empty = !sanitizedValue;

    return (
        <div className={"cols"}>
            <div className={"zmap-input"}>
                <LibContentEditable
                    html={sanitizedValue || ""}
                    onChange={change}
                    onKeyDown={keyup}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    // data-suggest={suggestion}
                />
                {(suggestion || empty) && (
                    <div className={"suggestion"}>
                        {empty ? "Start typing" : suggestion}
                    </div>
                )}
            </div>
        </div>
    );
}

ZmapInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onType: PropTypes.func,
    onAccept: PropTypes.func,
    onArrowUp: PropTypes.func,
    onArrowDown: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    suggestion: PropTypes.string,
};

ZmapInput.defaultProps = {
    suggestionIndex: 0,
};

export default ZmapInput;
