import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import IconButton from "components/IconButton";
import KeepIcon from "joynt/components/KeepIcon";
import Button from "components/Button";
import { LiveNowBadge } from "joynt/components/LiveNow";
import { Duration, Hour } from "joynt/components/TimeUtils";

import "./style.css";
import { DEFAULT_CARD_COLOR } from "joynt/config";

function TextBadge({ children }) {
    return (
        <div className={"text-badge"}>
            <div className={"text-badge__text"}>{children}</div>
        </div>
    );
}

export function SessionListItem(props) {
    const {
        id,
        name,
        start,
        end,
        color,
        onClick,
        onEdit,
        onShow,
        isLive,
        isScheduled,
        isHidden,
        isSelected,
        image,
        children,
    } = props;

    const showInfo = useCallback(
        (e) => {
            e.stopPropagation();
            onShow();
        },
        [onShow]
    );

    const className = cn("session-list-item card-border", {
        "is-live": isLive,
        "is-selected": isSelected,
        "is-draft": isHidden,
    });

    return (
        <div className={className} onClick={onClick}>
            <div className={`session-list-item__color bg-${color}`} />
            <div
                className={cn("session-list-item__time", {
                    "under-construction-bg": isHidden,
                })}
            >
                {start && (
                    <div className={"session-list-item__hour"}>
                        <Hour value={start} />
                    </div>
                )}
                {start && end && (
                    <div className={"session-list-item__duration"}>
                        <Duration start={start} end={end} />
                    </div>
                )}
                {isLive && <LiveNowBadge />}
                {isHidden && <TextBadge>Draft</TextBadge>}
                {!isLive && isScheduled && <div>Up next</div>}
            </div>
            <div className={"session-list-item__content"}>
                <div className={"session-list-item__header"}>
                    <div className={"session-list-item__name"}>{name}</div>
                    <div className={"session-list-item__actions"}>
                        <div className={"session-list-item__icons"}>
                            {onEdit && (
                                <IconButton
                                    icon={"mui-settings"}
                                    onClick={onEdit}
                                    size={"small"}
                                />
                            )}
                            <KeepIcon id={id} />
                        </div>
                        {onShow && (
                            <Button onClick={showInfo} variant={"text"}>
                                View info
                            </Button>
                        )}
                    </div>
                </div>
                <div className={"session-list-item__footer"}>{children}</div>
            </div>
            <div className={"session-list-item__image"}>{image}</div>
        </div>
    );
}

SessionListItem.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    isPrivate: PropTypes.bool,
    isLive: PropTypes.bool,
    isSelected: PropTypes.bool,
    isScheduled: PropTypes.bool,
    isHidden: PropTypes.bool,
    start: PropTypes.number,
    end: PropTypes.number,
    onEdit: PropTypes.func,
    onShow: PropTypes.func,
    onClick: PropTypes.func,
};

SessionListItem.defaultProps = {
    isPrivate: false,
    isLive: false,
    isSelected: false,
    color: DEFAULT_CARD_COLOR,
};

export default SessionListItem;
