import createBlockTypes from "./../Blocks";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import createLinkPlugin from "@draft-js-plugins/anchor";
import createFocusPlugin from "@draft-js-plugins/focus";
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin";

import { createSoftNewlinePlugin } from "./createSoftNewlinePlugin";
import createBlockRendererPlugin from "./createBlockRendererPlugin";
import createBlockTypesPlugin from "./CreateBlockPlugin";
import { createMiscEditingPlugin } from "./createMiscEditingPlugin";
import { composeDecorators } from "@draft-js-plugins/editor";

import toolbarStyles from "./../InlineToolbar/toolbar.module.css";
import buttonStyles from "./../InlineToolbar/button.module.css";

export function createPlugins(config) {
    const { readOnly, onCreateContent } = config;

    const blockTypes = createBlockTypes();
    const linkPlugin = createLinkPlugin({
        linkTarget: "_blank",
    });
    const inlineToolbarPlugin = createInlineToolbarPlugin({
        theme: { toolbarStyles, buttonStyles },
    });
    const miscEditingPlugin = createMiscEditingPlugin();

    const mentionPlugin = createMentionPlugin();

    const focusPlugin = createFocusPlugin();

    const decorator = composeDecorators(focusPlugin.decorator);

    const blockRenderer = createBlockRendererPlugin({
        blocks: blockTypes,
        readOnly,
        decorator,
    });

    const blockTypesPlugin = createBlockTypesPlugin(blockTypes, config);
    const softNewlinePlugin = createSoftNewlinePlugin();
    const emojiPlugin = createEmojiPlugin();
    const blockBreakoutPlugin = createBlockBreakoutPlugin();

    const plugins = [
        blockRenderer,
        blockTypesPlugin,
        focusPlugin,
        inlineToolbarPlugin,
        linkPlugin,
        emojiPlugin,
        mentionPlugin,
        blockBreakoutPlugin,
        softNewlinePlugin,

        /**
         * This can break other plugins key commands
         * (ie mentions up/down arrows) hence last position
         **/
        miscEditingPlugin,
    ];
    return {
        plugins,
        MentionSuggestionsRender: mentionPlugin.MentionSuggestions,
        InlineToolbar: inlineToolbarPlugin.InlineToolbar,
        BlockTypeSelect: blockTypesPlugin.BlockTypeSelect,
        EmojiSuggestions: emojiPlugin.EmojiSuggestions,
        EmojiSelect: emojiPlugin.EmojiSelect,
        LinkButton: linkPlugin.LinkButton,
        MiscEditing: miscEditingPlugin.MiscEditing,
    };
}

export default createPlugins;
