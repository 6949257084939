import React, { useCallback } from "react";
import PropTypes from "prop-types";

import SearchInput from "joynt/components/Roles/SearchInput/SearchInput";
import AvatarGroup from "joynt/components/AvatarGroup";
import Popover from "components/Popover";

import "./style.css";
import IconButton from "components/IconButton";
import PropertyEmptyState from "./../TaskProperties/PropertyEmptyState";

export function TaskAssignees(props) {
    const { id, onChange, onClear, value, showLabel } = props;
    const hasValue = value && value.length > 0;

    const addRole = useCallback(
        (close) => {
            return function (...args) {
                onChange(...args);
                close();
            };
        },
        [onChange]
    );

    return (
        <div>
            <Popover
                trigger={({ onClick }) => (
                    <div className={"task-assignees"} onClick={onClick}>
                        {hasValue ? (
                            <AvatarGroup ids={value} showNames={showLabel} />
                        ) : (
                            <PropertyEmptyState
                                icon={"mui-person_add"}
                                showLabel={showLabel}
                            >
                                Unassigned
                            </PropertyEmptyState>
                        )}
                        {hasValue && showLabel && (
                            <IconButton
                                onClick={onClear}
                                icon={"mui-close"}
                                size={"small"}
                            />
                        )}
                    </div>
                )}
                content={({ onClose }) => (
                    <SearchInput
                        node={id}
                        onAddRole={addRole(onClose)}
                        allowEmail={false}
                        enableRoleSelection={false}
                        minSearch={1}
                        minInput={1}
                        popover={false}
                    />
                )}
            />
        </div>
    );
}

TaskAssignees.propTypes = {
    value: PropTypes.array,
    showLabel: PropTypes.bool,
};
TaskAssignees.defaultProps = {
    value: [],
    showLabel: false,
};

export default TaskAssignees;
