import React from "react";

//import PropTypes from 'prop-types';

function Divider() {
    return <div className={"style-ui-divider"} />;
}

export function SelectList(props) {
    const { children, onClick } = props;
    return (
        <div className={"style-ui-options"} onClick={onClick}>
            {children}
        </div>
    );
}

SelectList.Divider = Divider;

SelectList.propTypes = {};

SelectList.defaultProps = {};

export default SelectList;
