import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import CirclesLayout from "./CirclesLayout";
import {useRouteParam} from "state/hooks";
import {ROUTE_NAV} from "joynt/config";
import Circles from "joynt/components/Circles/Circles";

export function CirclesLayoutContainer(props) {

    //const {} = props;

    const [open, setOpen] = useRouteParam(ROUTE_NAV);
    const close = useCallback(() => setOpen(null), [setOpen]);

    return (<CirclesLayout
        {...props}
        isOpen={open}
        circles={open && <Circles onClose={close} />}
    />);

}

CirclesLayoutContainer.propTypes = {
    id: PropTypes.string
};
CirclesLayoutContainer.defaultProps = {};

export default CirclesLayoutContainer;