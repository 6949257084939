import React from "react";
import PropTypes from "prop-types";
import ParticipantsCount from "./ParticipantsCount";
import { useSessionParticipants } from "joynt/hooks/sessions";

export function ParticipantsCountContainer(props) {
    const { id } = props;
    const { count, max } = useSessionParticipants(id);
    return <ParticipantsCount {...props} confirmed={count} max={max} />;
}

ParticipantsCountContainer.propTypes = {
    id: PropTypes.string,
};
ParticipantsCountContainer.defaultProps = {};

export default ParticipantsCountContainer;
