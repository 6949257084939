import React from "react";
import { useMessaging } from "joynt/hooks";
import { MessageForm } from "joynt/components/messages";
import Field from "containers/Field";
import AggregateRoot from "containers/AggregateRoot";
//import PropTypes from 'prop-types';

export default function CreateThread(props) {
    const { thread, parent } = props;
    const { onCreateMessage } = useMessaging({ thread, parent });

    return (
        <div className={"grow rows gap-md pad-md"}>
            <div className={"grow rows gap-sm"}>
                <div>New message</div>
                <AggregateRoot
                    type={"db.nodes"}
                    id={parent}
                    disableSubmit={true}
                >
                    <Field
                        fieldType={"select"}
                        id={"recipient_identities"}
                        multiple={true}
                        label={"To"}
                        source={"api:db.identities"}
                    />
                </AggregateRoot>
                <MessageForm
                    onCreateMessage={onCreateMessage}
                    submitMode={"button"}
                    parent={parent}
                />
            </div>
        </div>
    );
}

CreateThread.propTypes = {};
CreateThread.defaultProps = {};
