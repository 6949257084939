import React from 'react';
import Stage from "joynt/views/stage/Stage";
//import PropTypes from 'prop-types';

export default function Meeting(props) {

    return (<Stage {...props} isEvent={false} />);

}

Meeting.propTypes = {};
Meeting.defaultProps = {};
