import { services, hosts } from "config/domains";
import env from "util/env";

export default function () {
    if (!window.location) return services().zuu;
    if (window.location.pathname === "/console") return services().zuuConsole;
    const id =
        env("APP_SERVICE_ID") || env("SERVICE_ID") || window.location.hostname;
    return hosts[id] ? services()[hosts[id]] : services().zuu;
}
