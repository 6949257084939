import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import "./style.css";

const DashboardTile = ({
    header,
    content,
    className,
    children,
    ctaLabel,
    onCtaClick,
    ctaHref,
    footer,
    variant,
}) => {
    return (
        <section
            className={cn(
                "dashboard-tile",
                `dashboard-tile--${variant}`,
                className
            )}
        >
            {header && <h3 className={"dashboard-tile__header"}>{header}</h3>}
            {(children || content) && (
                <div className={"dashboard-tile__content"}>
                    {children || content}
                </div>
            )}
            {ctaLabel && (
                <div onClick={onCtaClick} className={"dashboard-tile__cta"}>
                    <a href={ctaHref}>{ctaLabel}</a>
                </div>
            )}
            {footer || null}
        </section>
    );
};

DashboardTile.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    children: PropTypes.node,
    ctaLabel: PropTypes.string,
    onCtaClick: PropTypes.func,
    ctaHref: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant: PropTypes.oneOf(["default", "outlined"]),
};

DashboardTile.defaultProps = {
    variant: "default",
};

export default DashboardTile;
