import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import AddReaction from "./AddReaction";
import emojis from "./emojis";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

export function Reactions(props) {
    const {
        mode,
        onShow,
        onHide,
        enabled,
        you,
        reactions,
        pending,
        onReaction,
        hideOnEmpty,
    } = props;

    if (!enabled) return null;

    if (mode === "add") {
        return <AddReaction onSelect={onReaction} />;
    }
    const isEmpty = !reactions || Object.keys(reactions).length <= 0;

    return (
        <div
            className={cn(
                "cols gap-2 cols-middle reactions",
                isEmpty && "reactions--empty",
                hideOnEmpty && "reactions--hide"
            )}
        >
            {mode === "full" && <AddReaction onSelect={onReaction} />}
            {reactions &&
                Object.keys(reactions).map((reactionType) => {
                    return (
                        <div
                            className={cn("reaction", {
                                own: reactionType === you,
                            })}
                            onClick={() => onReaction(reactionType)}
                            key={reactionType}
                            onMouseEnter={(e) => onShow(e, reactionType)}
                            onMouseLeave={onHide}
                        >
                            <div className={"reaction__icon"}>
                                {emojis[reactionType]}
                            </div>
                            <div className={"reaction__count"}>
                                {reactions[reactionType].count}
                            </div>
                        </div>
                    );
                })}
            {mode === "conditional" && !isEmpty && (
                <AddReaction onSelect={onReaction} />
            )}
            {pending ? <CircularProgress size={16} /> : null}
        </div>
    );
}

Reactions.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    mode: PropTypes.oneOf(["add", "full", "conditional", "list"]),
};
Reactions.defaultProps = {
    mode: "full",
};

export default Reactions;
