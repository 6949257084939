import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import Button from "components/Button";
import Icon from "components/Icon";
import AdvancedSettings from "./AdvancedSettings";
import CreateProgress from "./CreateProgress";
import CreateError from "./CreateError";
import ThemePreset from "./ThemePreset";
import CreateLayout from "./CreateLayout";
import { FormLayout } from "playground/cms/Form";

function ToggleAdvanced({ value, onClick }) {
    return (
        <Button
            variant={"text"}
            onClick={onClick}
            startIcon={<Icon>mui-settings</Icon>}
        >
            {value ? "Hide" : "Show"} advanced
        </Button>
    );
}

export function CreateWebsite(props) {
    const { type, id, onClose, status, isPending, onExport, onSubmit } = props;

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [mode, setMode] = useState("website");

    if (status === "success") {
        return <CreateProgress type={type} id={id} onClose={onClose} />;
    }

    return (
        <FormLayout>
            <FormLayout.Content>
                <CreateLayout title={"Create website"} isPending={isPending}>
                    {status === "error" && <CreateError />}
                    <Field id={"website.name"} label={"Project name"} />

                    <div className={"cols gap-sm"}>
                        <div onClick={() => setMode("website")}>website</div>
                        <div onClick={() => setMode("library")}>library</div>
                    </div>

                    {mode === "website" && (
                        <div className={"rows gap-xs"}>
                            <Field
                                id={"form.from_website"}
                                fieldType={"select"}
                                label={"Template project"}
                                source={"api:console.services?type=website"}
                                onChange={(v) => onExport(v)}
                            />
                            <div className={"cols cols-right"}>
                                <ToggleAdvanced
                                    value={showAdvanced}
                                    onClick={() =>
                                        setShowAdvanced(!showAdvanced)
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {showAdvanced && mode === "website" && (
                        <AdvancedSettings type={type} id={id} />
                    )}
                    {mode === "website" && <ThemePreset />}
                    <Field
                        id={"website.access.user_grants"}
                        fieldType={"select"}
                        label={"User access"}
                        source={"api:console.users"}
                        multiple={true}
                        taggable={true}
                        nullable={true}
                    />
                </CreateLayout>
            </FormLayout.Content>
            <FormLayout.Footer>
                <FormLayout.Actions onCancel={onClose} onSubmit={onSubmit} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

CreateWebsite.propTypes = {};

CreateWebsite.defaultProps = {};

export default CreateWebsite;
