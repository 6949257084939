import React, { useCallback } from "react";
import PropTypes from "prop-types";
import List from "containers/List";
import {
    IdentityBoxIterator,
    IdentityBoxContainer,
} from "joynt/components/IdentityBox";
import { ArrayIterator } from "components/list/iterator";
import { useProfilePopover } from "joynt/participants/index";

const IdentityCard = ({ id: person, ...other }) => {
    const { id, role } = person;
    const { onShow, onHide } = useProfilePopover();
    const click = useCallback((e) => onShow(id, e.currentTarget), [id, onShow]);

    return (
        <div
            onClick={click}
            onMouseLeave={onHide}
            style={{ cursor: "pointer" }}
        >
            <IdentityBoxContainer id={id} role={role} {...other} />
        </div>
    );
};

const IdentityCardIterator = ArrayIterator(IdentityCard);

export default function Profiles({ items, variant, ...other }) {
    const Iterator =
        variant === "avatar-name" ? IdentityBoxIterator : IdentityCardIterator;
    return (
        <div className={"rows gap-5"}>
            <List
                {...other}
                type={"db.identities"}
                items={items}
                Iterator={Iterator}
            />
        </div>
    );
}

Profiles.propTypes = {
    variant: PropTypes.oneOf(["tiles", "avatar-name"]),
};
Profiles.defaultProps = {
    variant: "tiles",
};
