import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import IconButton from "components/IconButton";
import "css/media-preview.css";
import fileTypes from "components/media-preview/fileTypes";

export default function MediaPreview(props) {
  const {
    file,
    fileType,
    onClose,
    onRequestOpen,
    onRequestDownload,
    onEdit,
    open,
  } = props;

  let classes = { dark: true, gallery: true, "console-context": true }; //FIXME: why is that defined separately?
  classes["gallery--open"] = open;
  let fileParts = file ? file.split("/") : [];
  let filename = fileParts[fileParts.length - 1];
  const TypePreview = fileTypes[fileType] || fileTypes.default;

  useEffect(() => {
    const handleKeyPress = ({ keyCode }) => {
      if (keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [onClose]);

  return (
    <div className={classNames(classes)}>
      <div className={"console-context gallery__toolbar pad-sm"}>
        <div className={"cols gap-sm gallery__info"}>
          <div className={"cols cols-middle gap-sm"}>
            <div>{filename}</div>
            {onEdit ? (
              <IconButton size={"small"} icon={"mui-edit"} onClick={onEdit} />
            ) : null}
          </div>
          <div className={"cols"}>
            {onRequestDownload ? (
              <IconButton icon={"cloud_download"} onClick={onRequestDownload} />
            ) : null}
            {onRequestOpen ? (
              <IconButton icon={"open_in_new"} onClick={onRequestOpen} />
            ) : null}
          </div>
        </div>
        <div className={"cols"}>
          <IconButton icon={"mui-download"} onClick={onRequestDownload} />
          <IconButton icon={"mui-close"} onClick={onClose} />
        </div>
      </div>
      <div className={"gallery__preview"} data-private>
        <TypePreview {...props} />
      </div>
    </div>
  );
}

MediaPreview.propTypes = {
  file: PropTypes.string,
  onClose: PropTypes.func,
  onRequestOpen: PropTypes.func,
  onRequestDownload: PropTypes.func,
};
