import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";

export function StageCover(props) {

    const {
        title,
        layout,
        transparent,
        variant,
        isPending,
        children
    } = props;

    return (<div className={cn("stage-cover", {
        "dark-mode": true,
        "stage-cover--transparent": transparent,
        [`stage-cover--${layout}`]: true,
        [`stage-cover--${variant}`]: true,
        "is-pending": isPending
    })}>
        <div className={"stage-cover__content"}>
            {title && <div className={"stage-cover__title"}>
                {title}
            </div>}
            {children}
        </div>
    </div>);

}

StageCover.propTypes = {
    transparent: PropTypes.bool,
    title: PropTypes.string,
    layout: PropTypes.oneOf(['full', 'centered']),
    variant: PropTypes.oneOf(['default', 'landscape']),
    isPending: PropTypes.bool
};
StageCover.defaultProps = {
    transparent: false,
    isPending: false,
    layout: 'centered',
    variant: 'default'
};

export default StageCover;
