import {useActions} from "state/hooks";
import {storeList} from "state/actions/data";
import {useCallback} from "react";
import fakeIdentities from "joynt/meeting/mock/data/identities";
import {useSelector} from "react-redux";
import {selectList} from "state/selectors/data";

export default function useMockParticipants() {
    const {onStoreList} = useActions({onStoreList: storeList});

    const onStoreIdentities = useCallback((count) => {
        const data = fakeIdentities.slice(0, count);
        onStoreList('db.identities', 'participants.stage-dev', data);
    }, [onStoreList]);

    const identities = useSelector(s=>selectList(s, 'db.identities', 'participants.stage-dev'));

    return {
        identities,
        onStoreIdentities
    }
}