import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";
import Field from "containers/Field";

export function SectionSettings(props) {
    const { nav_enable, used_in, type } = useData(props);

    return (
        <>
            <FormLayout.Section name={"Responsywność"}>
                <Field
                    id={"breakpoints"}
                    fieldType={"select"}
                    source={"schema:data.device-visibility"}
                    label={"Urządzenia"}
                    variant={"filter"}
                />
            </FormLayout.Section>
            <FormLayout.Section name={"Nawigacja"}>
                <Field
                    id={"nav_enable"}
                    fieldType={"checkbox"}
                    label={"Pokaż w menu sekcji"}
                />
                {nav_enable && (
                    <>
                        <Field
                            id={"nav_label"}
                            fieldType={"text"}
                            label={"Nazwa w menu"}
                        />
                        <Field id={"slug"} fieldType={"text"} label={"Slug"} />
                    </>
                )}
                <div className={"cols cols-2 gap-sm"}>
                    <Field
                        id={"anchor_before"}
                        fieldType={"text"}
                        label={"Anchor before"}
                    />
                    <Field
                        id={"anchor_after"}
                        fieldType={"text"}
                        label={"Anchor after"}
                    />
                </div>
            </FormLayout.Section>
            <FormLayout.Section name={"Wersje językowe"}>
                <Field
                    id={"translations.disable_export"}
                    fieldType={"checkbox"}
                    label={"Nie eksportuj do tłumaczenia"}
                />
            </FormLayout.Section>
            <FormLayout.Section name={"Dev"}>
                <FormLayout.Fieldset>
                    <Field id={"template_id"} label={"template_id"} />

                    {type === "placeholder" ? (
                        <Field
                            id={"placeholder_id"}
                            label={"placeholder_id"}
                            nullable={true}
                        />
                    ) : (
                        <Field
                            id={"target_placeholder_id"}
                            label={"target_placeholder_id"}
                            nullable={true}
                        />
                    )}

                    {type === "view" && (
                        <Field
                            id={"reference_id"}
                            fieldType={"select"}
                            source={"api:sections.library"}
                            label={"Szablon z biblioteki"}
                            nullable={true}
                        />
                    )}
                    <Field
                        id={"type"}
                        fieldType={"select"}
                        source={"schema:sections.types"}
                        label={"Typ"}
                        nullable={false}
                    />
                </FormLayout.Fieldset>
            </FormLayout.Section>
            <FormLayout.Section name={"Section usage"}>
                <pre>{JSON.stringify(used_in, null, 2)}</pre>
            </FormLayout.Section>
        </>
    );
}

SectionSettings.propTypes = {};

SectionSettings.defaultProps = {};

export default SectionSettings;
