import {renderVars} from "./renderVars";

export function renderMessage(messages, args) {
    let perUser = {};
    Object.keys(messages).forEach(k => {
        let newKey = renderVars(k, args);
        let newValue = messages[k] ? renderVars(messages[k], args) : null;
        perUser[newKey] = newValue;
    });
    return perUser[args.identity];
}