import React from "react";
import { useData } from "state/hooks";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

function contentStats(data) {
    const items = data?.gallery?.length || 0;
    const links = data?.links?.length || 0;
    const media = data?.media?.length || 0;
    const components = 0; //data?.items?.length || 0;
    return { items, media, links, components };
}

const icons = {
    items: "mui-recent_actors",
    links: "mui-link",
    media: "mui-photo",
    components: "mui-account_tree",
};

export function SectionContentSummary(props) {
    const { id, entryType } = props;
    const data = useData({ type: "cms.sections", id });
    const stats = contentStats(data);
    return (
        <div className={"section-content-summary"}>
            {Object.keys(stats).map((key) => {
                if (!stats[key]) return null;
                return (
                    <div
                        key={key}
                        title={key}
                        className={"content-summary-stat"}
                    >
                        <Icon>{icons[key]}</Icon>
                        {stats[key]}
                    </div>
                );
            })}
            {entryType && (
                <div
                    className="content-summary-tag"
                    onClick={() => alert(entryType)}
                >
                    {entryType}
                </div>
            )}
        </div>
    );
}

SectionContentSummary.propTypes = {};

SectionContentSummary.defaultProps = {};

export default SectionContentSummary;
