export default [
    {
        value: 'phone',
        icon: 'mui-phone_iphone'
    },
    {
        value: 'tablet',
        icon: 'mui-tablet_mac'
    },
    {
        value: 'laptop',
        icon: 'mui-laptop_mac'
    },
    {
        value: 'desktop',
        icon: 'mui-desktop_mac'
    }
];