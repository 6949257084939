import React from "react";
import SessionHeader from "./SessionHeader";

export default {
    networking: [
        {
            id: "start",
            message: "Start the tables when ready",
            name: "Introduction to Networking",
            next_label: "Start now",
            view: "networking",
            progress: "admin",
            visible: "admin",
            pull: {
                description: "Introduction to Networking has started",
                descriptionIcon: "mui-videocam",
                message: "Do you want to join now?",
                confirmText: "Join",
                cancelText: "Not now",
                confirmType: "networking",
            }
        },
        {
            id: "networking",
            // overlay: false,
            message: "Networking tables available",
            name: "Networking tables",
            next_label: "Start wrap-up",
            view: "networking",
            progress: "admin",
            disconnect: true,
            confirm: {
                title: "You're about to start",
                description: "Networking tables",
                descriptionIcon: "mui-videocam",
                message: "This will end current session.",
                cancelText: "Not now",
                confirmText: "Start",
                confirmType: "networking",
            },
            notify: {
                description: "Introduction to networking has ended",
                descriptionIcon: "mui-videocam",
                message: (
                    <>
                        The tables are ready! <br /> Join the tables to start
                        networking.
                    </>
                ),
                cancelText: false,
                confirmText: "Got it",
                confirmType: "networking",
            }
        },
        {
            id: "wrap-up",
            name: "Wrap-up session",
            next_label: "End session",
            progress: "admin",
            visible: "admin",
            state: "last",
            pull: {
                description: "Wrap-up has started",
                descriptionIcon: "mui-videocam",
                message: "Do you want to join now?",
                confirmText: "Join",
                cancelText: "Not now",
                confirmType: "networking",
            },
            confirm: {
                title: "You're about to start",
                description: "Wrap-up",
                descriptionIcon: "mui-videocam",
                message: "This will end current session.",
                cancelText: "Not now",
                confirmText: "Start",
                confirmType: "networking",
            }
        },
        {
            id: "after-party",
            name: "Networking has ended",
            disconnect: true,
            state: "end",
            confirm: {
                title: "You're about to end",
                description: "Wrap-up",
                descriptionIcon: "mui-videocam",
                message: "This will end current session.",
                cancelText: "Not now",
                confirmText: "End",
                confirmType: "networking",
            },
            notify: {
                description: "Wrap-up has ended.",
                descriptionIcon: "mui-videocam",
                message: "The tables will be closed soon.",
                cancelText: false,
                confirmText: "Got it",
                confirmType: "networking",
            },
        },
        {
            id: "end",
            name: "Networking has ended",
            state: "end",
            notify: {
                title: "Tables are closed",
                message: "Thank you for participation",
                cancelText: false,
                confirmText: "Got it",
                confirmType: "networking",
            },
        },
    ],
    "networking-table": [
        {
            id: "start",
            overlay: false,
            header: SessionHeader,
        },
    ],
};
