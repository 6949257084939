import React, { useRef, useState } from "react";
//import PropTypes from 'prop-types';

import {
    useData,
    useFetch,
    usePending,
    useRouteParam,
    useUiFlag,
} from "state/hooks";
import { selectChanges, selectList } from "state/selectors/data";
import { useSelector } from "react-redux";
//import CodeEditor from "components/fields/code/CodeEditor";
import FilesTree from "playground/theme/FileManager/FilesTree";
import { CircularProgress } from "@material-ui/core";
import useFileActions from "./useFileActions";
import { pathsToTree } from "./tree";
import { selectTask } from "state/selectors/notifications";
import AceEditor from "react-ace-cdn";

import "./style.css";
import FileSearch from "./FileSearch";
import Checkbox from "components/fields/checkbox/Checkbox";

const startWith = ["less/custom.less", "less/custom/work.less"];

const fileTypes = {
    js: "javascript",
};

const exclude = ["framework", "_compiled"];

const protectedPaths = [
    "assets",
    "assets/less",
    "assets/js",
    "assets/less/custom",
    ".htaccess-generated",
    "assets/less/custom.less",
    "assets/less/imports.less",
    "schema.json",
    "_compiled",
];

const MODE_SAFE = "SAFE";
const MODE_UNSAFE = "UNSAFE";

function selectFiles(store, mode) {
    const files = selectList(store, "theme.files", "default");
    const changed = selectChanges(store, "theme.files");
    const getId = (file) => file.rename || file.id;

    return files
        .filter((f) => {
            return !exclude.includes(f.id) && !f.deleted && !!f?.id;
        })
        .filter((f) => {
            if (mode === MODE_UNSAFE) return true;
            return f.id.includes("assets/less/");
        })
        .map((file) => ({
            id: file.id,
            path: getId(file),
            changed: changed.includes(file.id),
        }));
}

const options = {
    tabSize: 4,
};

const editorProps = { $blockScrolling: Infinity };

function CodeEditor(props) {
    const { id, onChange } = props;

    const ref = useRef();
    const markers = props.markers;
    const handleChange = (value) => {
        if (id) {
            onChange(id, value);
        } else {
            onChange(value);
        }
    };
    return (
        <>
            <AceEditor
                id={props.id}
                onLoad={(e) => {
                    ref.current = e;
                    if (markers.length) {
                        e.gotoLine(markers[0].startRow + 1);
                    }
                }}
                fontSize={"14px"}
                theme={"solarized_dark"}
                width={"100%"}
                height={props.height || "370px"}
                mode={props.mode}
                onChange={handleChange}
                value={props.value}
                name={props.id}
                debounceChangePeriod={1000}
                wrapEnabled={props.wrapEnabled}
                setOptions={options}
                editorProps={editorProps}
                markers={props.markers}
            />
        </>
    );
}

export function FileManager(props) {
    const { themeId } = props;

    //const [selected, setSelected] = useState(null);

    const [selected, setSelected] = useRouteParam("themeFile");
    const [lastFiles, setLastFiles] = useState([]);

    const [mode, setMode] = useUiFlag("theme.fileManager.mode", MODE_SAFE);

    const { slug } = useData({ type: "console.themes", id: themeId });

    const isPending = useFetch({
        type: "theme.files",
        url: "cms/theme/files?v3=true&v4=true",
        list: "default",
        fetchId: "theme-files",
    });

    const isSaving = usePending("cms.save");

    const files = useSelector((store) => selectFiles(store, mode));
    const tree = pathsToTree(files, slug);

    const buildStatus = useSelector((store) => {
        return selectTask(store, `theme-build.${themeId}`);
    });

    const initial = files.filter((f) => startWith.includes(f.id))[0];
    const changedFiles = files.filter((f) => f.changed).length;
    const current = selected || initial?.id;
    const currentFile = files.filter((f) => f.id === current)[0];
    const last = lastFiles
        .map((file) => {
            return files.filter((f) => f.id === file)[0];
        })
        .filter((f) => !!f);
    const fileType = current?.split(".")[1];

    const { onChange, onRename, onDelete, onCreate } = useFileActions();

    const select = (file, addToHistory) => {
        if (addToHistory && !lastFiles.includes(file)) {
            let nextLast = lastFiles.filter((f) => f !== file);
            nextLast.unshift(file);
            nextLast = nextLast.slice(0, 5);
            setLastFiles(nextLast);
        }
        setSelected(file);
    };

    const handleCreate = (path) => {
        onCreate(path);
        select(path, true);
    };

    const data = useData({ type: "theme.files", id: current });
    const isChanged = currentFile?.changed;
    const pending = isPending || isSaving || buildStatus?.status === "pending";

    const [search, setSearch] = useUiFlag("theme.fileManager.search", {});
    const currentFileSearch = search.result?.find((f) => f.id === current);
    const searchMarkers = currentFileSearch
        ? [
              {
                  startRow: currentFileSearch.line - 1,
                  startCol: currentFileSearch.index,
                  endRow: currentFileSearch.line - 1,
                  endCol: currentFileSearch.index + search.query.length,
                  className: "search-highlight",
                  type: "background",
              },
          ]
        : [];

    return (
        <div className={"file-manager cols gap-md"}>
            <div className={"rows"}>
                <div style={{ height: "800px", width: "240px" }}>
                    {/*<pre>{JSON.stringify(tree, null, 2)}</pre>*/}
                    <Checkbox
                        onChange={() =>
                            setMode(
                                mode === MODE_SAFE ? MODE_UNSAFE : MODE_SAFE
                            )
                        }
                        value={mode === MODE_UNSAFE}
                        label={"Unsafe mode (all files)"}
                    />
                    <FilesTree
                        tree={tree}
                        onSelect={select}
                        onCreate={handleCreate}
                        onRename={onRename}
                        onDelete={onDelete}
                        selected={current}
                        protectedPaths={protectedPaths}
                    />
                </div>
            </div>
            <div className={"rows grow"}>
                <div>
                    <FileSearch onShowFile={setSelected} />
                </div>
                <div className={"cols gap-sm"}>
                    {last.map((f) => (
                        <div key={f.id} onClick={() => select(f.id, false)}>
                            {f.id} {f.changed ? "*" : ""}
                        </div>
                    ))}
                </div>
                <div className={"grow"}>
                    {data.id && (
                        <CodeEditor
                            key={data.id}
                            id={data.id}
                            value={data.content || ""}
                            height={"100%"}
                            mode={fileTypes[fileType] || fileType}
                            onChange={onChange}
                            markers={searchMarkers}
                        />
                    )}
                </div>
                <div className={"pad-sm cols gap-sm cols-middle"}>
                    {pending && (
                        <CircularProgress size={16} color={"inherit"} />
                    )}
                    {buildStatus.status && (
                        <div>Building theme ({buildStatus.status})</div>
                    )}
                    {isSaving && <div>Saving...</div>}
                    {isPending && <div>Fetching files...</div>}
                    {changedFiles > 0 && (
                        <div>{`${changedFiles} unsaved files`}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

FileManager.propTypes = {};

FileManager.defaultProps = {};

export default FileManager;
