import React from 'react';
import Button from "components/Button";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export default function LearnMoreButton({url}) {

    const showHelp = () => window.open(url);

    return <Button
        onClick={showHelp}
        startIcon={<Icon>mui-help</Icon>}
        variant={'text'}
    >
        Learn more
    </Button>
}

LearnMoreButton.propTypes = {};
LearnMoreButton.defaultProps = {};
