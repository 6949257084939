import React from "react";
import { FormLayout } from "playground/cms/Form";
import Resources from "playground/cms/views/Resources";
import ImportForm from "./ImportForm";

//import PropTypes from 'prop-types';

export function ImportResources(props) {
    const {} = props;
    return (
        <Resources {...props}>
            <FormLayout>
                <FormLayout.Content replace={true}>
                    <FormLayout.Tab id={"resource-bindings"} label={"Import"}>
                        <ImportForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Resources>
    );
}

ImportResources.propTypes = {};

ImportResources.defaultProps = {};

export default ImportResources;
