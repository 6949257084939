import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import DiscussionThread from "./DiscussionThread";
import {useNodeData} from "joynt/hooks";
import {usePostComments} from "joynt/components/Post/hooks";
import {Message} from "joynt/components/messages";
import {useClick, useRouteParam} from "state/hooks";
import {ROUTE_POST} from "joynt/config";
import {useIdentity} from "joynt/hooks/participants";
import {IdentityAvatarContainer as Avatar} from "joynt/components/Avatar";
import {useSelector} from "react-redux";
import {selectPostContent} from "joynt/state/selectors/joint";
import AvatarGroup from "joynt/components/AvatarGroup";

export function DiscussionThreadContainer(props) {

    const {id} = props;
    const {name, created_at, identity} = useNodeData(id);
    const {name: authorName} = useIdentity(identity);
    const {participants, lastMessage} = usePostComments(id);
    const [,showPost] = useRouteParam(ROUTE_POST);
    const click = useClick({onClick: showPost, id});
    const {properties} = useSelector(s => selectPostContent(s, id));

    const participantsWithoutAuthor = useMemo(() => {
        return participants.filter(p => p !== identity)
    }, [participants, identity]);

    return (<DiscussionThread
        {...props}
        title={name}
        onClick={click}
        createdAt={created_at}
        authorName={authorName}
        avatar={<Avatar id={identity} size={"xs"} />}
        properties={properties}
        participants={<AvatarGroup ids={participantsWithoutAuthor} />}
    >
        {lastMessage && <Message id={lastMessage} mode={"channel"} />}
    </DiscussionThread>);

}

DiscussionThreadContainer.propTypes = {
    id: PropTypes.string
};
DiscussionThreadContainer.defaultProps = {};

export default DiscussionThreadContainer;