import { useData } from "state/hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";
import { normalizeType } from "cms/util/connect";
import { parseTemplateMapping } from "cms/util/templatable";
import { oneOfFallback } from "playground/cms/util";

const contentFields = {
    title: "string",
    headline: "string",
    text: "string",
    media: "array",
    gallery: "array",
    links: "array",
    tags: "array",
};

const hasContent = (fieldId, data) => {
    const field = contentFields[fieldId];
    if (!field) return false;
    const value = data[fieldId] || oneOfFallback([fieldId], data);
    if (!value) return false;
    if (field === "string") return !!value;
    if (field === "array") return !!value.length;
    return false;
};

const hasField = (fieldId, structure) => {
    return !!structure.fields[fieldId];
};

const hasBg = (store, data) => {
    const styles = data?.styles || {};
    if (!!styles.background) return true;
    if (!data?.style) return false;
    const style = store.data?.["cms.styles"]?.[data.style];
    if (!!style?.background) return true;
    return false;
};

function selectTree(store, items, type, context) {
    return items.map((id) => {
        let entity = selectEntity(store, type, id);
        let componentType = normalizeType(entity.element_type);
        let newContext = ["list", "query"].includes(componentType)
            ? entity?.id
            : context;
        return {
            id: entity.id,
            label: entity.style || componentType,
            type: componentType,
            disabled: entity.disabled,
            mapping: entity.template_id,
            context,
            children: entity.components
                ? selectTree(store, entity.components, type, newContext)
                : [],
        };
    });
}

function reduceMapping(acc, node) {
    if (!node.disabled && node.mapping) {
        const mapped = parseTemplateMapping(node.mapping, false);
        Object.keys(mapped).forEach((to) => {
            const from = mapped[to];
            if (to === "items") {
                acc.lists[node.id] = from;
            }
            if (to === "collection") {
                //acc.lists[node.id] = from;
            }
            if (node.context) {
                let target = acc.lists[node.context];
                if (!acc.schemas[target]) acc.schemas[target] = {};
                acc.schemas[target][from] = { id: node.id, to };
            } else {
                acc.fields[from] = { id: node.id, to };
            }
        });
    }
    if (
        !node.disabled &&
        ["list", "nav", "query"].includes(node.type) &&
        !acc.lists[node.id]
    ) {
        acc.components.push(node.id);
    }
    if (!node.disabled && node.children) {
        node.children.reduce(reduceMapping, acc);
    }
    return acc;
}

function selectSectionStructure(store, id) {
    const data = selectEntity(store, "cms.sections", id);
    let templateId = data?.template || id;
    let template = selectEntity(store, "cms.sections", templateId);
    if (template.reference_id) {
        templateId = template.reference_id;
        template = selectEntity(store, "cms.sections", templateId);
    }
    const structure = data?.items?.length ? data.items : template?.items;
    const tree = selectTree(store, structure || [], "cms.components");
    const mapping = tree.reduce(reduceMapping, {
        fields: {},
        lists: {},
        components: [],
        hasComponents: data?.items?.length > 0,
        schemas: {},
        background: hasBg(store, data) || hasBg(store, template),
    });
    return mapping;
}

export function useAdaptiveContent(id, isDraft) {
    const data = useData({ type: "cms.sections", id });
    const alwaysVisible = [];
    const structure = useSelector((store) => selectSectionStructure(store, id));
    const withContent = Object.keys(contentFields).filter((k) => {
        return hasContent(k, data);
    });
    const [visible, setVisible] = useState(withContent);
    const staticFields = Object.keys(contentFields).filter((k) => {
        return (
            alwaysVisible.includes(k) ||
            hasContent(k, data) ||
            (hasField(k, structure) && isDraft)
        );
    });
    const optional = Object.keys(contentFields).filter(
        (k) => !staticFields.includes(k) && hasField(k, structure)
    );
    const all = Object.keys(contentFields).filter((k) => {
        return !hasField(k, structure);
    });
    return {
        static: staticFields,
        visible,
        optional,
        all,
        setVisible: (field) => {
            if (visible.includes(field)) {
                setVisible(visible.filter((f) => f !== field));
            } else {
                setVisible([...visible, field]);
            }
        },
        schemas: structure.schemas,
        background: structure.background,
        lists: structure.lists,
        components: structure.components,
        hasComponents: structure.hasComponents,
    };
}
