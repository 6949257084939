import React from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import { useOperationProgress } from "./hooks";
import CreateLayout from "./CreateLayout";
import ProgressLog from "./ProgressLog";
import StatusImage from "./StatusImage";

export function DestroyProgress(props) {
    const { id, onClose } = props;
    const { status, log } = useOperationProgress(`purge-website.${id}`);
    const isSuccess = status === "success";
    const title = isSuccess ? "Success!" : "Removing website...";
    return (
        <CreateLayout title={title} className={"rows-center"}>
            <div className={"cols cols-center"}>
                <StatusImage status={status} />
            </div>
            <ProgressLog log={log} />
            {isSuccess && (
                <div className={"cols cols-center"}>
                    <Button onClick={onClose} color={"default"}>
                        OK
                    </Button>
                </div>
            )}
        </CreateLayout>
    );
}

DestroyProgress.propTypes = {};

DestroyProgress.defaultProps = {};

export default DestroyProgress;
