import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import Modal from "components/modal/Modal";
import EntityContext from "containers/context/EntityContext";
import FormLayout from "components/layouts/FormLayout";
import FormButtons from "components/FormButtons";
import {pop} from "state/actions/modal";
import {submitOne} from "state/actions/api";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import {AggregateRootContext} from "containers/context/EntityContext";
import {reject} from "state/actions/data";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = bindApiActions({
    onSubmit: submitOne
}, {
    onPopModal: pop,
    onCancel: reject
});

class ModalForm extends React.Component {

    close = () => {
        const { onCancel, onPopModal, type, id } = this.props;
        onCancel(type, id);
        onPopModal();
    };

    submit = () => {
        const { root, rootId, onSubmit } = this.props;
        onSubmit(root, rootId, root.replace('.', '/'));
    };

    submitClose = () => {
        this.submit();
        this.props.onPopModal();
    };

    render() {
        const { type, id } = this.props;
        const { FormComponent, ...other } = this.props;
        let entityPath = [type,id].join('/');
        return <Modal key={id} onClose={this.close}>
            <AggregateRootContext.Provider value={null}>
                <EntityContext.Provider value={entityPath}>
                    <FormLayout
                        className={'form-regular'}
                        onClose={this.close}
                        form={<FormComponent {...other} />}
                        footer={<FormButtons
                            onCancel={this.close}
                            onSubmit={this.submitClose}
                        />}
                    />
                </EntityContext.Provider>
            </AggregateRootContext.Provider>
        </Modal>
    }

}

ModalForm.propTypes = {
    root: PropTypes.string,
    rootId: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string
};

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalForm));