export const createWorkspace = (parent, data = {}, list) => ({
    type: "JOINT.WORKSPACE.CREATE",
    payload: { parent, data, list },
});

export const submitNode = (context) => (callback, id, data) => ({
    type: "JOINT.NODE.SUBMIT",
    payload: { id, data },
    callback,
    context,
});

export const createNode = (callback, parent, list, data = {}) => ({
    type: "JOINT.NODE.CREATE",
    payload: { parent, data, list },
    callback,
});
