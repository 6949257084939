import React, {useCallback, useEffect, useMemo} from 'react';
import BrowserPermissions from "./BrowserPermissions";
import {BrowserMediaDevicesContext, useBrowserPermissions} from "./context"
import useMediaDevicesAccess from "./hooks/useMediaDevicesAccess";
//import PropTypes from 'prop-types';

function DeviceAccess(props) {

    const {on, children} = props;
    const permissions = useBrowserPermissions();
    const access = useMediaDevicesAccess();

    let {
        cameraAccess,
        microphoneAccess,
        permissionsApiNotAvailable,
        onCheckPermissions
    } = permissions;

    const {onRequestDevices} = access;

    const requestDevicesAndPerm = useCallback(() => {
        onCheckPermissions();
        onRequestDevices();
    }, [onCheckPermissions, onRequestDevices]);

    useEffect(() => {
        if (on && cameraAccess && microphoneAccess) onRequestDevices();
    }, [on, onRequestDevices, cameraAccess, microphoneAccess]);

    useEffect(() => {
        const update = () => {
            onRequestDevices();
        }
        const {mediaDevices} = navigator;

        if (on) {
            mediaDevices.addEventListener('devicechange', update);
        }
        return () => {
            mediaDevices.removeEventListener('devicechange', update);
        }
    }, [on, onRequestDevices]);

    const cameraAvailable = ['granted', 'unknown'].indexOf(cameraAccess) > -1
        && access.hasCamera;

    const microphoneAvailable = ['granted', 'unknown'].indexOf(microphoneAccess) > -1
        && access.hasMicrophone;

    if (permissionsApiNotAvailable && !cameraAvailable) cameraAccess = 'prompt';
    if (permissionsApiNotAvailable && !microphoneAvailable) cameraAccess = 'prompt';

    let status = null;

    if ([cameraAccess, microphoneAccess].indexOf('prompt') > -1) status = 'prompt';
    if ([cameraAccess, microphoneAccess].indexOf('denied') > -1) status = 'denied';
    if (cameraAccess === 'granted' && microphoneAccess === 'granted') status = 'granted';

    const context = useMemo(() => ({
        ...access,
        ...permissions,
        onRequestDevices: requestDevicesAndPerm,
        cameraAvailable,
        microphoneAvailable,
        cameraAccess,
        microphoneAccess,
        status
    }), [
        access,
        permissions,
        cameraAvailable,
        microphoneAvailable,
        cameraAccess,
        microphoneAccess,
        status,
        requestDevicesAndPerm
    ])

    return <BrowserMediaDevicesContext.Provider value={context}>
        {children}
    </BrowserMediaDevicesContext.Provider>
}

DeviceAccess.defaultProps = {
    on: false
}

export default function BrowserDeviceAccess(props) {

    const {on, children} = props;
    return <BrowserPermissions>
        <DeviceAccess on={on}>
            {children}
        </DeviceAccess>
    </BrowserPermissions>;

}

BrowserDeviceAccess.propTypes = {};
BrowserDeviceAccess.defaultProps = {};
