import React from "react";
import FormActions from "joynt/components/Form/FormActions";
import Field from "containers/Field";
import TextInput from "joynt/components/messages/message-form/TextInput";
import Checkbox from "components/fields/checkbox/Checkbox";

//import PropTypes from 'prop-types';

const reportReasons = [
    { id: "abuse", label: "The Witness was abusive / rude / disrespectful" },
    { id: "not-following-rules", label: "Witness was not following the rules" },
    {
        id: "inappropriate",
        label: "The witness behaved inappropriately in front of camera",
    },
];

function CheckboxSelect(props) {
    const { value, onChange, options } = props;

    return (
        <div className={"rows"}>
            {options.map((option) => {
                return (
                    <Checkbox
                        key={option.id}
                        variant={"checkbox"}
                        label={option.label}
                        onChange={() => onChange(option.id)}
                        value={value === option.id}
                    />
                );
            })}
        </div>
    );
}

export function WitnessReport(props) {
    const { onSetAction } = props;

    return (
        <div>
            <div>
                We apologize for any inconvenience you've experienced during
                Witness Task session.
            </div>
            <Field
                id={"issue"}
                FieldComponent={CheckboxSelect}
                options={reportReasons}
            />
            <div style={{ width: "300px" }}>
                <Field
                    id={"message"}
                    placeholder={"Write your reasons"}
                    FieldComponent={TextInput}
                />
            </div>
            <FormActions
                submitLabel={"Send feedback"}
                onSubmit={() => alert("send")}
                rejectLabel={"Cancel"}
                onReject={() => onSetAction(null)}
            />
        </div>
    );
}

WitnessReport.propTypes = {};
WitnessReport.defaultProps = {};

export default WitnessReport;
