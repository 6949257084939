import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";
import {DEFAULT_CARD_COLOR} from "joynt/config";

export function SessionTrack(props) {

    const {
        name,
        color,
        avatar,
        children
    } = props;

    return (<div className={"session-track"}>
        <div className={cn("session-track__header", {
            [`var-${color}`]: true
        })}>
            <div className={"session-track__avatar"}>
                {avatar}
            </div>
            <div className={"session-track__name"}>
                {name}
            </div>
        </div>
        <div className={"session-track__list"}>
            {children}
        </div>
    </div>);

}

SessionTrack.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string
};

SessionTrack.defaultProps = {
    color: DEFAULT_CARD_COLOR
};

export default SessionTrack;