import React from 'react';


import 'react-dates/initialize';

import './css'

import Router from "containers/Router";
import PageHead from "containers/PageHead";
import domainRouter from "util/domainRouter";
import JoyntApp from "joynt/Main";

const router = domainRouter().router;

function App() {
    if (router === 'joynt') return <JoyntApp />;
    return (
      <>
          <PageHead />
          <Router />
      </>
    );
}

export default App;
