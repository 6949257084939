import React from 'react';
import PropTypes from 'prop-types';
import SelectListItem from "./SelectListItem";

export function SelectList(props) {

    const {
        type,
        items,
        onSelect,
        filter
    } = props;

    return (<div className={"select-list"}>
        {filter}
        <div className={"select-options"}>
            {items.map(id => <SelectListItem
                key={id}
                type={type}
                id={id}
                onClick={onSelect}
            />)}
        </div>
    </div>);

}

SelectList.propTypes = {
    items: PropTypes.array,
    isPending: PropTypes.bool
};

SelectList.defaultProps = {
    items: [],
    isPending: false
};

export default SelectList;
