import React from "react";
//import PropTypes from 'prop-types';

import SessionDescription from "./SessionDescription";

import "./style.css";

export function SessionSummary(props) {
    const { id, showAttendance } = props;

    return (
        <div className={"networking-session-info__description"}>
            <SessionDescription
                id={id}
                truncate={"collapse"}
                showAttendance={showAttendance}
            />
        </div>
    );
}

SessionSummary.propTypes = {};
SessionSummary.defaultProps = {
    showAttendance: true
};

export default SessionSummary;
