import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import Icon from "components/Icon";
import "./style.css";

export function Button(props) {

    const {
        label,
        icon,
        onClick,
        isActive,
        hasNotifications,
        color,
        className: classNameProp
    } = props;

    const className = cn("utility-nav-btn", classNameProp, {
        "has-notifications": hasNotifications,
        [`utility-nav-btn--${color}`]: true,
        "is-active": isActive,
    });

    return (<button className={className} onClick={onClick}>
        <div className={"utility-nav-btn__icon"}>
            <Icon>{icon}</Icon>
        </div>
        <div className={"utility-nav-btn__label"}>
            {label}
        </div>
    </button>);

}

Button.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    icon: PropTypes.string,
    hasNotifications: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "link"])
};

Button.defaultProps = {
    isActive: false,
    hasNotifications: false,
    color: "primary"
};

export default Button;
