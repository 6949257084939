import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { EntryDescription } from "playground/cms/views/Entries";
import {
    PropertyGrid,
    Property,
    PropertyPopover,
} from "playground/cms/fields/Properties";
import { useData } from "state/hooks";
import Address from "playground/cms/fields/Address";
import EnumValues from "playground/cms/fields/EnumValues";
import BookingIntegration from "playground/cms/fields/BookingIntegration";
import ExtendedFields from "playground/cms/fields/ExtendedFields";
import { FormLayout } from "playground/cms/Form";

function RoomArea(props) {
    const { area } = useData(props);
    const value = area;
    return (
        <PropertyPopover label={"Powierzchnia"} value={value}>
            <div className={"rows pad-md gap-sm"}>
                <div className={"cols cols-2 gap-sm"}>
                    <Field
                        label={"Od"}
                        id={"area_from"}
                        fieldType={"text"}
                        size={"small"}
                    />
                    <Field
                        label={"Do"}
                        id={"area_to"}
                        fieldType={"text"}
                        size={"small"}
                    />
                </div>
                <Field
                    label={"Powierzchnia"}
                    id={"area"}
                    fieldType={"text"}
                    size={"small"}
                />
            </div>
        </PropertyPopover>
    );
}

function RoomPrice(props) {
    const { price_min, price_info } = useData(props);
    const str = [price_min, price_info].join(", ");
    return (
        <PropertyPopover label={"Cena od"} {...props} value={str}>
            <div className={"rows pad-md gap-sm"}>
                <Field
                    label={"Cena"}
                    id={"price_min"}
                    fieldType={"text"}
                    size={"small"}
                />
                <Field
                    label={"Informacja dot ceny"}
                    id={"price_info"}
                    fieldType={"text"}
                    size={"small"}
                />
            </div>
        </PropertyPopover>
    );
}

function RoomPricelist(props) {
    const { price, price_high, couple_price, couple_price_high } =
        useData(props);
    const str = [price, price_high, couple_price, couple_price_high]
        .filter((v) => !!v)
        .join(", ");
    return (
        <PropertyPopover label={"Cennik"} {...props} value={str}>
            <div
                className={"grid grid-3 gap-sm pad-md"}
                style={{ width: "360px" }}
            >
                <div></div>
                <div>1 os.</div>
                <div>2 os.</div>
                <div>Sezon niski</div>
                <Field id={"price"} fieldType={"text"} size={"small"} />
                <Field id={"couple_price"} fieldType={"text"} size={"small"} />
                <div>Sezon wysoki</div>
                <Field id={"price_high"} fieldType={"text"} size={"small"} />
                <Field
                    id={"couple_price_high"}
                    fieldType={"text"}
                    size={"small"}
                />
            </div>
        </PropertyPopover>
    );
}

export function RoomsForm(props) {
    const { type, id } = props;
    return (
        <div className={"pad-md rows gap-md"}>
            <EntryDescription {...props} mediaVariant={"form-section"} />
            <PropertyGrid>
                <RoomArea {...props} />
                <RoomPrice {...props} />
                <Property label={"L.osób"}>
                    <Field
                        label={null}
                        id={"capacity"}
                        fieldType={"text"}
                        variant={"dense"}
                    />
                </Property>
                <RoomPricelist {...props} />
            </PropertyGrid>
            <Field
                label={"Udogodnienia"}
                id={"amenities"}
                enumType={"rooms-amenities"}
                FieldComponent={EnumValues}
            />
            <BookingIntegration {...props}>
                <div className={"cols cols-2 gap-md"}>
                    <Field
                        id={"online_booking_disabled"}
                        fieldType={"checkbox"}
                        label={"Wyłącz rezerwację online"}
                    />
                    <Field
                        id={"archived"}
                        fieldType={"checkbox"}
                        label={"Archiwalny"}
                    />
                </div>
            </BookingIntegration>
            <Field id={"address"} label={"Adres"} FieldComponent={Address} />
            <FormLayout.Section name={"Custom fields"} variant={"default"}>
                <ExtendedFields {...props} />
            </FormLayout.Section>
        </div>
    );
}

RoomsForm.propTypes = {};

RoomsForm.defaultProps = {};

export default RoomsForm;
