import React from "react";
import { useApiActions, useData, usePending, useRouteParam } from "state/hooks";
import { buildTheme } from "playground/cms/state/actions/buildTheme";
import { syncTheme } from "playground/cms/state/actions/syncTheme";
import { FormContainer, FormLayout } from "playground/cms/Form";
import Icon from "components/Icon";
import { ThemeGit } from "playground/console/views/Themes/ThemeGit";
import Button from "components/Button";

//import PropTypes from 'prop-types';

export function ThemeFormActions(props) {
    const { id } = props;

    const { slug, parent, preset, vcs_repository } = useData({
        type: "console.themes",
        id,
    });
    const { slug: parentSlug } = useData({
        type: "console.themes",
        id: parent,
    });
    const [, setView] = useRouteParam("view");
    const { onBuild, onSync } = useApiActions({
        onBuild: buildTheme,
        onSync: syncTheme,
    });
    const buildPending = usePending("buildTheme");
    const syncPending = usePending("syncTheme");

    return (
        <FormContainer type={"console.themes"} id={id} disableFetch={true}>
            <FormLayout.Section
                name={"Theme"}
                icon={"mui-style"}
                action={
                    <div className={"cols cols-middle gap-md"}>
                        <div className={"cols cols-middle"}>{slug}</div>
                        <div className={"cols cols-middle gap-xs"}>
                            <Icon>mui-mediation</Icon> <div>{parentSlug}</div>
                        </div>
                    </div>
                }
            >
                <FormLayout.Fieldset>
                    <ThemeGit id={id} />
                    <div className={"cols gap-md"}>
                        <Button
                            onClick={() => onSync(id)}
                            disabled={syncPending}
                        >
                            Sync
                        </Button>
                        <Button
                            onClick={() => onBuild(id)}
                            disabled={buildPending}
                        >
                            Build
                        </Button>
                    </div>
                    <div>{preset}</div>
                    <div onClick={() => setView("theme")}>
                        files schema.json assets.json
                    </div>
                </FormLayout.Fieldset>
            </FormLayout.Section>
        </FormContainer>
    );
}

ThemeFormActions.propTypes = {};

ThemeFormActions.defaultProps = {};

export default ThemeFormActions;
