import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { FormField } from "./Features";

export function FormInput(props) {
    const { children } = props;
    return (
        <>
            {children}
            <Field
                id={"input_type"}
                label={"Input type"}
                fieldType={"select"}
                source={"schema:data.form-field-type"}
            />
            <FormField />
            <Field id={"required"} label={"Required"} fieldType={"checkbox"} />
        </>
    );
}

FormInput.propTypes = {};

FormInput.defaultProps = {};

export default FormInput;
