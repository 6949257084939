import React from 'react';
import ChatContainer from "joynt/components/messages/chat/Chat";
//import PropTypes from 'prop-types';

export function Chat(props) {

    const {id} = props;

    return (<ChatContainer
        id={id}
        variant={"sidebar"}
        showHeader={false}
    />);

}

Chat.propTypes = {};
Chat.defaultProps = {};

export default Chat;
