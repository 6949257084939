import React from "react";
import PropTypes from "prop-types";
import { ResponsivePopover } from "components/Popover";
import CustomMuiSelect from "components/fields/select/CustomMuiSelect";
import Icon from "components/Icon";

import "css/fields/select-inline.css";

function InlineValueRenderer(props) {
    const { onClick, value } = props;
    return (
        <div onClick={onClick} className={"select-inline"}>
            <div>{value}</div>
            <div className={"select-inline__arrow"}>
                <Icon>mui-arrow_drop_down</Icon>
            </div>
        </div>
    );
}

function DenseValueRenderer(props) {
    const { onClick, onClear, value, label, nullable, hasValue } = props;
    return (
        <>
            {hasValue ? (
                <div className={"input-dense__label"}>{label}</div>
            ) : null}
            <div
                onClick={onClick}
                className={"input-dense input-dense--select"}
            >
                <div className={"select-value"}>{value}</div>
                {hasValue && nullable && onClear ? (
                    <div className={"select-inline__arrow"} onClick={onClear}>
                        <Icon>mui-close</Icon>
                    </div>
                ) : null}
                <div className={"select-inline__arrow"}>
                    <Icon>mui-arrow_drop_down</Icon>
                </div>
            </div>
        </>
    );
}

const variants = {
    inline: InlineValueRenderer,
    dense: DenseValueRenderer,
    filter: InlineValueRenderer,
    default: CustomMuiSelect,
};

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export default class SelectLayout extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    open = (evt) =>
        this.setState({
            isOpen: true,
            anchorEl: evt.currentTarget,
        });

    close = () => this.setState({ isOpen: false });

    render() {
        const {
            id,
            label,
            value,
            hasValue,
            selectMenu,
            variant,
            nullable,
            onClear,
            error,
            helperText,
            ValueComponent,
        } = this.props;

        const { isOpen, anchorEl } = this.state;

        const ValueRenderer =
            variant && variants[variant] ? variants[variant] : ValueComponent;

        return (
            <div className={"select select--" + variant}>
                <ValueRenderer
                    onClick={this.open}
                    onClear={onClear}
                    id={id}
                    value={value}
                    hasValue={hasValue}
                    label={label}
                    nullable={nullable}
                    error={error}
                    helperText={helperText}
                />
                <ResponsivePopover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={this.close}
                    anchorOrigin={anchorOrigin}
                >
                    <div>{selectMenu(this.close)}</div>
                </ResponsivePopover>
            </div>
        );
    }
}

SelectLayout.propTypes = {
    value: PropTypes.any,
    //selectMenu: PropTypes.element,
    ValueComponent: PropTypes.elementType,
};

SelectLayout.defaultProps = {
    ValueComponent: variants.default,
};
