import React from "react";
import cn from "classnames";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

import Upload from "components/upload/Upload";
import Preloader from "components/Preloader";
import MediaItem from "./MediaItem";

import "./style.css";
import TextField from "components/fields/text/TextField";

const directoryIcons = {
    files: "mui-folder",
    videos: "mui-movie",
    pano: "mui-panorama",
};

function FolderIcon({ isOpen }) {
    return isOpen ? <Icon>mui-folder_open</Icon> : <Icon>mui-folder</Icon>;
}

FolderIcon.defaultProps = {
    isOpen: false,
};

function ExpandIcon({ isOpen }) {
    return isOpen ? (
        <Icon>mui-expand_more</Icon>
    ) : (
        <Icon>mui-chevron_right</Icon>
    );
}

ExpandIcon.defaultProps = {
    isOpen: false,
};

function Directory(props) {
    const { id, name, path, onClick, depth, currentPath, children } = props;
    const isSelected = currentPath.indexOf(path) === 0;
    const isOpen = isSelected;
    const isCurrent = currentPath === path;
    const hasChildren = depth > 0 && children.length > 0;
    const typeIcon = directoryIcons[id];

    return (
        <div
            className={cn("media-browser-directory", {
                "is-current": isCurrent,
                "is-open": isOpen,
                "has-children": hasChildren,
            })}
            style={{ "--depth": depth }}
        >
            <div
                className={"media-browser-directory-item"}
                onClick={() => onClick(path)}
                title={`${path} / ${currentPath}`}
            >
                {hasChildren && (
                    <div className={"media-browser-directory-expand"}>
                        <ExpandIcon isOpen={isOpen} />
                    </div>
                )}

                {typeIcon ? (
                    <Icon>{typeIcon}</Icon>
                ) : (
                    <FolderIcon isOpen={isOpen} />
                )}

                <div className={"media-browser-directory-label"}>
                    {name || id}
                </div>
            </div>
            {isOpen && children && children.length > 0 && (
                <DirectoryList
                    directories={children}
                    onClick={onClick}
                    depth={depth + 1}
                    currentPath={currentPath}
                />
            )}
        </div>
    );
}

Directory.defaultProps = {
    currentPath: "",
    children: [],
};

function DirectoryList({ directories, onClick, currentPath, depth }) {
    return directories.map((dir) => (
        <Directory
            key={dir.id}
            {...dir}
            onClick={onClick}
            depth={depth}
            currentPath={currentPath}
        />
    ));
}

DirectoryList.defaultProps = {
    depth: 0,
};

export function MediaBrowser(props) {
    const {
        isPending,
        directories,
        onChangePath,
        currentPath,
        media,
        onUpload,
        createButton,
        onClick,
        select,
        filterValue,
        onSetFilter,
    } = props;

    return (
        <div
            className={cn("media-browser-layout", {
                "is-select": select,
            })}
        >
            <Preloader visible={isPending} />
            <div className={"media-browser-nav"}>
                <div className={"cols cols-left pad-sm"}>{createButton}</div>
                <div className={"media-browser-directories"}>
                    <DirectoryList
                        directories={directories}
                        onClick={onChangePath}
                        currentPath={currentPath}
                    />
                </div>
            </div>
            <Upload
                className={"media-browser-upload-area"}
                onFileUpload={onUpload}
            >
                <div className={"media-browser-search"}>
                    <TextField
                        variant={"search"}
                        label={"Szukaj..."}
                        value={filterValue?.search}
                        onChange={(v) => onSetFilter("search", v)}
                    />
                </div>
                <div className={"media-browser-grid"}>
                    {media.map((item) => (
                        <MediaItem
                            key={item.id}
                            {...item}
                            active={true}
                            onClick={() => onClick(item.id)}
                        />
                    ))}
                </div>
            </Upload>
        </div>
    );
}

MediaBrowser.propTypes = {};

MediaBrowser.defaultProps = {
    isPending: false,
    media: [],
    directories: [],
};

export default MediaBrowser;
