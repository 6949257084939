import React, { useCallback } from "react";
import Button from "./Button";
import { useGlobalNotifications } from "joynt/hooks/notifications";

//import PropTypes from 'prop-types';

export function NotificationButton(props) {
    const { Component, group, label, onClick, ...other } = props;

    const { show, count, onSeen } = useGlobalNotifications(group);

    const click = useCallback(
        (...args) => {
            onSeen();
            onClick(...args);
        },
        [onClick, onSeen]
    );

    return (
        <Component
            {...other}
            onClick={click}
            hasNotifications={!!show}
            label={count ? `${label} (${count})` : label}
        />
    );
}

NotificationButton.propTypes = {};
NotificationButton.defaultProps = {
    Component: Button,
};

export default NotificationButton;
