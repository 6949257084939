import React from "react";
//import PropTypes from 'prop-types';
import { MakeWebsiteForm } from "playground/console/views/Websites/Websites";
import "./ServiceSettings.css";
import { useCurrentService } from "playground/cms/hooks";
import { ResourceForm } from "playground/cms/views/Resources";
import { useApiActions, useData } from "state/hooks";
import { submitWebsite } from "cms/state/actions/services";
export function ServiceSettings(props) {
    const website = useCurrentService();
    const formProps = {
        type: "console.services",
        id: website.id,
        onClose: props.onClose,
    };
    const { onSubmit } = useApiActions({
        onSubmit: submitWebsite,
    });
    const { name } = useData(props);
    formProps.onSubmit = onSubmit;
    formProps.name = name;
    return (
        <ResourceForm {...formProps} disableFetch={true}>
            {MakeWebsiteForm(formProps)}
        </ResourceForm>
    );
}

ServiceSettings.propTypes = {};

ServiceSettings.defaultProps = {};

export default ServiceSettings;
