import AgoraRTM, { RtmClient } from "agora-rtm-sdk";
import AgoraRTC from "agora-rtc-sdk-ng";
import { CLIENT_LOGS, DEFAULT_HQ_PROFILE } from "joynt/meeting/agora-ng/config";

import AgoraSdkWrapper from "./AgoraSdkWrapper";
import ScreenShareClient from "./ScreenShareClient";
import MediaDevices from "./MediaDevices";
import DebugReport from "./DebugReport";
import EventHandler from "joynt/meeting/agora-ng/AgoraClient/EventHandler";

export default class AgoraClient {
    constructor(appId, clients) {
        this.appId = appId;

        this.rtc = new AgoraSdkWrapper(appId, {
            main: clients.main,
            extended: clients.extended,
        });

        this.events = new EventHandler(this.rtc);
        this.devices = new MediaDevices(this);

        this.rtm = clients.rtm;
        this.rtmChannel = null;
        this.uid = null;

        this.publishLock = false;
        this.channel = null;

        this.switchLock = false;
    }

    bindEventHandler = (handler) => {
        this.rtc.bindEventHandler(handler);
        this.events.bindEventHandler(handler);
    };

    trigger = (event) => {
        this.events.trigger({ channel: this.channel, ...event });
    };

    setChannel = (channel) => {
        const prevChannel = this.channel;
        this.channel = channel;
        if (prevChannel !== channel) {
            this.trigger({
                event: "channel",
                data: {
                    channel: this.channel,
                },
            });
        }
    };

    switch = async (config) => {
        if (this.switchLock) return;
        if (config.channel === this.channel) {
            return;
        }
        this.switchLock = true;
        if (this.rtm.connectionState === "CONNECTED") {
            await this.leave(true);
        }
        await this.join(config);
        this.switchLock = false;
    };

    join = async (config) => {
        if (this.leavePromise) {
            console.log(`Hold joining until previous channel is left`);
            await this.leavePromise;
        }

        this.setChannel(config.channel);
        this.devices.setInitial(true);

        const { channel, user, access } = config;
        const { rtmToken } = access;

        const rtmChannelId = `${channel}`;

        this.trigger({
            event: "joining",
            data: {
                channel: this.channel,
                joined: false,
                loading: true,
                description: "rtm",
                error: null,
                videoStatus: null,
                videoError: null,
                audioStatus: null,
                audioError: null,
            },
        });

        try {
            // if (
            //     this.rtm.connectionState !==
            //     AgoraRTM.ConnectionState.DISCONNECTED
            // ) {
            //     await this.rtm.logout();
            // }

            if (
                this.rtm.connectionState !== AgoraRTM.ConnectionState.CONNECTED
            ) {
                await this.rtm.login({ token: rtmToken, uid: user });
            }

            this.rtmChannel = this.rtm.createChannel(rtmChannelId);
            await this.rtmChannel.join();

            this.trigger({
                event: "joining",
                data: {
                    description: "rtc",
                },
            });

            /** Only in streaming mode **/
            //await this.rtc.setClientRole('host');

            try {
                await this.rtc.enableDualStream();
            } catch (e) {
                // Already on or unavailable - ignore
            }

            this.uid = await this.rtc.join(channel, user, access);
            this.rtc.enableAudioVolumeIndicator();

            this.screenShare = new ScreenShareClient({
                appId: this.appId,
                ...config,
            });

            this.rtc.screenShare = this.screenShare;
        } catch (e) {
            try {
                await this.leave();
            } catch (e) {}
            this.setChannel(null);
            this.trigger({
                event: "joining",
                data: {
                    joined: false,
                    loading: false,
                    error: e.message,
                    description: "error",
                },
            });
            return false;
        }

        this.trigger({
            event: "joining",
            data: {
                joined: true,
                loading: false,
                description: "ok",
            },
        });
    };

    leave = async (isSwitching) => {
        this.leavePromise = this.doLeave(isSwitching);
        await this.leavePromise;
        this.leavePromise = null;
    };

    doLeave = async (isSwitching) => {
        console.log("Leaving rtc & rtm connection");

        this.leaveLock = true;

        this.trigger({
            event: "leaving",
            data: {
                loading: true,
            },
        });

        const rtcClient = this.rtc;
        const rtmClient = this.rtm;
        const rtmChannel = this.rtmChannel;

        try {
            if (this.screenShare) await this.screenShare.stop();

            if (
                rtmClient.connectionState === AgoraRTM.ConnectionState.CONNECTED
            ) {
                await rtmChannel.leave();
            }

            if (!isSwitching) await rtmClient.logout();

            if (!rtcClient || !rtcClient.channelName()) {
                this.trigger({
                    event: "leaving",
                    data: {
                        status: "ok",
                    },
                });
                return;
            }

            this.trigger({
                event: "leaving",
                data: {
                    loading: true,
                },
            });

            //await new Promise((r) => setTimeout(r, 2000));
            await rtcClient.leave();
        } catch (error) {
            console.error(error);
            this.trigger({
                event: "leaving",
                data: {
                    loading: false,
                    joined: false,
                    error: error.message,
                },
            });
            this.setChannel(null);
            return error;
        }

        this.trigger({
            event: "leaving",
            data: {
                loading: false,
                joined: false,
            },
        });

        if (!isSwitching) this.setChannel(null);
        console.log("Bye.");
    };

    publishVideoTrack = async () => {
        if (this.rtc.tracks("video").length > 0) {
            this.log("[publish] Already publishing video track");
            return true;
        }

        if (this.publishLock) return false;

        this.trigger({
            event: "track-publish",
            type: "video",
            data: {
                videoStatus: "pending",
                videoError: null,
            },
        });

        try {
            this.publishLock = true;

            const track = await AgoraRTC.createCameraVideoTrack({
                cameraId: this.devices.videoInput(),
                encoderConfig: DEFAULT_HQ_PROFILE,
            });

            try {
                let status = await this.rtc.publish(track);
                this.publishLock = false;

                this.trigger({
                    event: "track-publish",
                    type: "video",
                    data: {
                        videoStatus: "success",
                    },
                });

                this.devices.setInitial(false);

                return status;
            } catch (e) {
                track.close();
                throw e;
            }
        } catch (e) {
            this.publishLock = false;
            this.trigger({
                event: "track-publish",
                type: "video",
                data: {
                    videoStatus: "error",
                    videoError: e.message,
                },
            });
            return false;
        }
    };

    unpublishVideoTrack = async () => {
        const track = this.rtc.tracks("video")[0];

        if (!track) {
            this.log("[unpublish] No video track");
            return false;
        }

        try {
            this.trigger({
                event: "track-publish",
                type: "video",
                data: {
                    videoStatus: "pending",
                },
            });

            const status = await this.rtc.unpublish(track);

            this.trigger({
                event: "track-publish",
                type: "video",
                data: {
                    videoStatus: null,
                    videoError: null,
                },
            });

            return status;
        } catch (e) {
            this.log(e.message);
        }
    };

    publishAudioTrack = async () => {
        if (this.rtc.tracks("audio") > 0) {
            this.log("[publish] Already publishing audio track");
            return false;
        }

        this.trigger({
            event: "track-publish",
            type: "audio",
            data: {
                audioStatus: "pending",
                audioError: null,
            },
        });

        try {
            const track = await AgoraRTC.createMicrophoneAudioTrack({
                microphoneId: this.devices.audioInput(),
                encoderConfig: DEFAULT_HQ_PROFILE,
            });

            try {
                const status = await this.rtc.publish(track);

                this.trigger({
                    event: "track-publish",
                    type: "audio",
                    data: {
                        audioStatus: "success",
                        audioError: null,
                    },
                });

                return status;
            } catch (e) {
                track.close();
                throw e;
            }
        } catch (e) {
            this.trigger({
                event: "track-publish",
                type: "audio",
                data: {
                    audioStatus: "error",
                    audioError: e.message,
                },
            });

            return false;
        }
    };

    unpublishAudioTrack = async () => {
        const track = this.rtc.tracks("audio")[0];

        if (!track) {
            this.log("[unpublish] No audio track");
            return false;
        }

        this.trigger({
            event: "track-publish",
            type: "audio",
            data: {
                audioStatus: "pending",
                audioError: null,
            },
        });

        const status = await this.rtc.unpublish(track);

        this.trigger({
            event: "track-publish",
            type: "audio",
            data: {
                audioStatus: null,
                audioError: null,
            },
        });

        return status;
    };

    setDevices = async (devices) => {
        this.devices.update(devices);
    };

    startScreenShare = async () => {
        try {
            await this.screenShare.start();
        } catch (e) {
            this.trigger({
                event: "session-error",
                data: {
                    error: e.message,
                },
            });
        }
    };

    stopScreenShare = async () => {
        await this.screenShare.stop();
    };

    peerCmd = async (user, msg) => {
        try {
            this.log(`[rtm] sendMessageToPeer ${msg} ${user}`);
            return await this.rtm.sendMessageToPeer(
                {
                    text: msg,
                },
                user
            );
        } catch (e) {
            console.log(e);
            return e;
        }
    };

    log = (msg, ...args) => {
        if (CLIENT_LOGS) console.log(`[AgoraSdkWrapper] ${msg}`, ...args);
    };

    watch(fn) {
        if (!fn) {
            this.rtc.watch(null);
            return;
        }
        this.rtc.watch(() => {
            fn(this.debug());
        });
    }

    debug = () => {
        return new DebugReport(this).get();
    };
}
