import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import SelectList from "./SelectList";
import SelectSearch from "./SelectSearch";

import useFetch from "state/hooks/useFetch";
import {useList, useRegisterList} from "state/hooks";

export function SelectListContainer(props) {

    const {
        onSelect,
        onClose,
        type,
        list,
        url
    } = props;

    const fetchProps = {
        type,
        list,
        url,
        filter: list,
        enable: true
    };

    const pending = useFetch(fetchProps);
    const {items} = useList(type, list);

    useRegisterList(fetchProps);

    const select = useCallback(id => {
        onSelect(id);
        onClose();
    }, [onSelect, onClose]);

    return (<SelectList
        {...props}
        filter={<SelectSearch
            type={type}
            list={list}
            isPending={pending}
        />}
        isPending={pending}
        onSelect={select}
        items={items}
    />);

}

SelectListContainer.propTypes = {
    id: PropTypes.string,
    onSelect: PropTypes.func,
    onClose: PropTypes.func
};
SelectListContainer.defaultProps = {};

export default SelectListContainer;