import React from "react";
import Resources from "playground/cms/views/Resources";
import NavigationForm from "./NavigationForm";

//import PropTypes from 'prop-types';

export function Navigation(props) {
    const {} = props;
    return <Resources {...props} FormComponent={NavigationForm} />;
}

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;
