import React, { useCallback } from "react";

import { useNodeData } from "joynt/hooks";
import { useRouter } from "joynt/hooks/channels";
import cn from "classnames";

import "./style.css";

export default function SessionRecordingState(props) {
    const { id } = props;
    const { event_record_state: value } = useNodeData(id);
    const { onLink } = useRouter();
    const link = useCallback(() => onLink(id), [onLink, id]);

    const className = cn("stage-recording", {
        [`stage-recording--${value}`]: !!value,
    });

    if (value === "recording")
        return <div className={className}>Recording</div>;
    if (value === "processing")
        return (
            <div className={className}>Recording will be available soon</div>
        );
    if (value === "ready")
        return (
            <div onClick={link} className={className}>
                Recording available
            </div>
        );

    return null;
}
