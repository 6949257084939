import React from "react";
// import PropTypes from 'prop-types';
import SidebarSection from "./../SidebarSection";
import { useNodeData } from "joynt/hooks";
import { useList } from "state/hooks";
import SidebarSectionIcon from "./../SidebarSection/SidebarSectionIcon";
import SidebarItem from "./../SidebarItem/SidebarItem.container";
import { useChannels, useEditPage } from "joynt/hooks/channels";

export function SidebarGroup(props) {
    const { id, onClick, selected, path } = props;
    const { name, can_edit } = useNodeData(id);
    const isAdmin = !!can_edit;
    const list = `db.nodes.${id}.channels`;
    const { items } = useList("db.nodes", list);
    const { onCreate } = useChannels(id, list);
    const onEdit = useEditPage();

    return (
        <SidebarSection
            name={name}
            onClick={() => onClick(id)}
            icon={
                <>
                    {isAdmin && (
                        <SidebarSectionIcon
                            icon={"mui-settings"}
                            onClick={() => onEdit(id)}
                        />
                    )}
                    {isAdmin && (
                        <SidebarSectionIcon
                            icon={"mui-add_circle"}
                            onClick={() => onCreate({})}
                        />
                    )}
                </>
            }
        >
            {items.map((n) => (
                <SidebarItem
                    key={n}
                    id={n}
                    onClick={onClick}
                    isActive={selected === n}
                    isHighlighted={path && path.indexOf(n) > -1}
                />
            ))}
        </SidebarSection>
    );
}

SidebarGroup.propTypes = {};

SidebarGroup.defaultProps = {};

export default SidebarGroup;
