import Text from "./Text";
import Files from "./Files";
import Link from "./Link";
import Whiteboard from "./Whiteboard";
import Poll from "./Poll";

import {
    POST_TYPE_ALBUM,
    POST_TYPE_LINK,
    POST_TYPE_TEXT,
    POST_TYPE_WHITEBOARD,
    POST_TYPE_POLL
} from "joynt/config";

export default {
    [POST_TYPE_TEXT]: Text,
    [POST_TYPE_ALBUM]: Files,
    [POST_TYPE_LINK]: Link,
    [POST_TYPE_WHITEBOARD]: Whiteboard,
    [POST_TYPE_POLL]: Poll
}