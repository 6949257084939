import React from "react";

import SessionPage from "./SessionPage";
import useScreenSize from "util/hooks/useScreenSize";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import { useUiFlag } from "state/hooks";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import useFetch from "state/hooks/useFetch";

function SessionPageContainer(props) {
    const { id } = props;

    const { isMobile } = useScreenSize();
    const { session } = useMeetingSession();
    const [sessionView] = useUiFlag(`layout.collapse.${session}`);
    const [{ joined }] = useConnectionState(session);
    const showStage = (sessionView || isMobile) && id && joined;

    const isPage = window.location.pathname.indexOf("/public") > -1;

    useFetch({
        url: `v2/joynt/nodes/${id}/networking`,
        fetchId: "workspace",
        once: false,
        enable: !isPage,
    });

    return <SessionPage {...{ isMobile, showStage, id }} />;
}

export default SessionPageContainer;
