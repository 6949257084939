import React from "react";
//import PropTypes from 'prop-types';

import "./style.css";
import IconButton from "components/IconButton";
import { CircularProgress } from "@material-ui/core";
import Icon from "components/Icon";
import { OverlayForm } from "playground/cms/Form";
import { useRouteParam } from "state/hooks";
import ThemeGitConsole from "playground/console/views/Themes/ThemeGit/ThemeGitConsole";
import Button from "components/Button";

function GitStatus({ status }) {
    if (status === 99) return <div>Not connected to github</div>;
    if (status === 0) return <div>In sync</div>;
    if (status === 1) return <div>Uncommited changes</div>;
    return null;
}

function GitActions(props) {
    const { onShowConsole, onReload, onPush, onPull, isPending } = props;
    return (
        <div>
            {isPending && <CircularProgress size={16} />}
            <IconButton onClick={onShowConsole} icon={"mui-settings"} />
            {/*<IconButton onClick={onPull} icon={"mui-cloud_download"} />*/}
            {/*<IconButton onClick={onPush} icon={"mui-cloud_upload"} />*/}
            <IconButton onClick={onReload} icon={"mui-refresh"} />
        </div>
    );
}

export function ThemeGit(props) {
    const { data, onInit, isPending } = props;
    const [showGit, setShowGit] = useRouteParam("vcs");
    const open = () => {
        window.open(data.url);
    };
    let message = data.message || "";
    message = message.replace(/<br \/>/g, "");

    const isConnected = data.status !== 99;

    return (
        <>
            <div className={"rows"}>
                <div className={"cols cols-spread cols-middle"}>
                    <div className={"cols gap-md"}>
                        {data.url && (
                            <div
                                onClick={open}
                                className={"cols cols-middle gap-xs"}
                            >
                                <Icon>github</Icon>
                                <div>{data.repository}</div>
                            </div>
                        )}
                        <GitStatus status={data.status} />
                    </div>
                    {isConnected ? (
                        <GitActions
                            {...props}
                            onShowConsole={() => setShowGit(true)}
                        />
                    ) : (
                        <Button
                            onClick={onInit}
                            startIcon={<Icon>github</Icon>}
                            endIcon={
                                isPending && <CircularProgress size={16} />
                            }
                            disabled={isPending}
                        >
                            Connect
                        </Button>
                    )}
                </div>
                {/*{message && <div>{message}</div>}*/}
            </div>
            <OverlayForm
                open={showGit}
                onClose={() => setShowGit(null)}
                position={"center"}
            >
                {showGit && <ThemeGitConsole {...props} />}
            </OverlayForm>
        </>
    );
}

ThemeGit.propTypes = {};

ThemeGit.defaultProps = {
    data: {},
};

export default ThemeGit;
