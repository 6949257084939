import React from 'react';
import {connect} from 'react-redux';
import {pathChange,pathDetach} from "state/actions/data";

import MenuItem from "@material-ui/core/MenuItem";
import Icon from "components/Icon";
import {selectEntity} from "state/selectors/data";
import {copyComponent, importFromLibrary, pasteComponent, saveToLibrary} from "cms/state/actions/cms";
import {NS_COMPONENTS} from "cms/components/types";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";

const mapStateToProps = (store, props) => {
    let data = selectEntity(store, NS_COMPONENTS, props.id);
    let parent = data.__parent || {};
    let path = parent.id === props.rootId
        ? [props.rootType, parent.id, parent.key].join('/')
        : [NS_COMPONENTS, parent.id, 'components'].join('/');
    return {
        data,
        disabled: data.disabled,
        path,
        clipboard: !!store.ui.clipboard
    }
};

const mapDispatchToProps = bindApiActions({
    onExport: saveToLibrary,
    onImport: importFromLibrary
},{
    onChange: pathChange,
    onDetach: pathDetach,
    onCopy: copyComponent,
    onPaste: pasteComponent
});

class ComponentContextMenu extends React.PureComponent {

    detach = () => {
        console.log(this.props.rootType, this.props.rootId);
        const { onDetach, onClose, path, id } = this.props;
        onDetach(path.split('/'), id);
        onClose();
    };

    toggleDisabled = () => {
        const { onChange, onClose, id, disabled } = this.props;
        onChange([NS_COMPONENTS,id,'disabled'], !disabled);
        onClose();
    };

    copy = () => {
        const { onCopy, id } = this.props;
        onCopy(NS_COMPONENTS, id);
    };

    paste = () => {
        const { onPaste, id } = this.props;
        const path = [NS_COMPONENTS, id, 'components'].join('/');
        onPaste(path, null, null);
    };

    libraryExport = () => {
        const { onExport, id } = this.props;
        onExport(NS_COMPONENTS, id);
    }

    libraryImport = (type, libraryId) => {
        const { onImport, id } = this.props;
        onImport(type, libraryId, NS_COMPONENTS, id);
    }

    render() {
        const {
            disabled,
            clipboard
        } = this.props;

        return (<>
            <MenuItem dense={true} onClick={this.detach}>
                <Icon fontSize={'small'}>mui-delete</Icon>
                Delete
            </MenuItem>
            <MenuItem dense={true} onClick={this.toggleDisabled}>
                <Icon fontSize={'small'}>
                    { disabled ? 'mui-visibility' : 'mui-visibility_off' }
                </Icon>
                { disabled ? 'Activate' : 'Deactivate' }
            </MenuItem>
            <MenuItem dense={true} onClick={this.copy}>
                <Icon fontSize={'small'}>mui-copy</Icon>
                Copy
            </MenuItem>
            { clipboard ? <MenuItem dense={true} onClick={this.paste}>
                <Icon fontSize={'small'}>mui-paste</Icon>
                Wklej
            </MenuItem> : null }
            <MenuItem dense={true}>
                Convert to symbol
            </MenuItem>
            <MenuItem dense={true} onClick={this.libraryExport}>
                Save to library
            </MenuItem>
        </>);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentContextMenu));