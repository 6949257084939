import React, { useEffect, useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Icon from "components/Icon";

import cn from "classnames";

import "./styles.css";

const Accordion = ({
  children,
  title,
  className,
  onChange,
  expanded,
  id,
  content,
}) => {
  const [isOpen, setOpen] = useState(expanded);

  useEffect(() => {
    if (expanded !== isOpen) setOpen(expanded);
  }, [expanded, isOpen]);

  return (
    <div className={cn("accordion__wrapper", isOpen && "accordion--open")}>
      <MuiAccordion
        expanded={expanded}
        className={cn(className, "accordion", isOpen && "accordion--open")}
        onChange={(event, isExpanded) => {
          setOpen(!isOpen);
          if (typeof onChange === "function") onChange(isExpanded, id);
        }}
      >
        <MuiAccordionSummary className={"accordion__title"}>
          <Icon className={"accordion__title-icon"}>
            {isOpen ? "mui-remove" : "mui-add"}
          </Icon>
          {title}
        </MuiAccordionSummary>
        <MuiAccordionDetails className={"accordion__content"}>
          {content || children}
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default Accordion;
