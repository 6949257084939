import React, { useContext } from "react";
import { connect } from "react-redux";
import { changed, compare } from "util/changed";
import { fetch, fetchOne } from "state/actions/api";
import { change, resetList } from "state/actions/data";
import { selectEntity } from "state/selectors/data";
import { FILTER_NS } from "config/list-aliases";
import ApiContext, { bindApiActions } from "containers/context/ApiContext";
import { debounce } from "lodash";
import { useNetworkState } from "joynt/network-state";
import { selectFlag } from "state/selectors/ui";
import Button from "components/Button";

const mapStateToProps = (store, props) => {
    const { fetchId, critical } = props;
    const filterId = props.filter;
    const id = props.id;

    const pendingId = id ? id : [props.type, props.list].join("/");
    const pendingValue = store.pending[pendingId];

    return {
        filterId,
        filterData: selectEntity(store, FILTER_NS, filterId),
        pendingValue,
        error:
            critical && fetchId
                ? selectFlag(store, `failedRequest.${fetchId}`)
                : null,
    };
};

const mapDispatch = bindApiActions(
    {
        fetch,
        fetchOne,
    },
    {
        change,
        resetList,
    }
);

class Fetch extends React.PureComponent {
    static whyDidYouRender = true;

    watchProps = ["type", "list", "id", "url", "view", "enable", "online"];

    componentDidMount() {
        const { pendingValue, append, force } = this.props;

        if (pendingValue !== false || append || force) {
            this.query();
            this.fetch();
        }
    }

    componentWillUnmount() {
        const { type, list, filter, append } = this.props;
        if (!append) return;
        this.props.resetList(type, list, filter);
    }

    componentDidUpdate(prevProps, prevState, snapshots) {
        if (changed(["query"], prevProps, this.props)) {
            //this.query();
            //return;
        }
        if (
            this.props.filter &&
            compare(prevProps.filterData, this.props.filterData)
        ) {
            this.fetchDebounce();
            return;
        }
        if (
            this.props.useContext &&
            changed(["apiContext"], prevProps, this.props)
        ) {
            this.fetch(true);
            return;
        }
        if (changed(this.watchProps, prevProps, this.props)) {
            this.fetch();
        }
    }

    fetch = (force) => {
        const {
            type,
            list,
            id,
            url,
            view,
            filter,
            alias,
            enable,
            append,
            fetchId,
            yieldData,
            online,
        } = this.props;
        const { useContext, apiContext } = this.props;

        if (useContext && !apiContext.project && !apiContext.noContext) return;

        if (!force && enable === false) return;
        if (!online) return;

        if (list) {
            this.props.fetch(
                type,
                list,
                url,
                view,
                filter,
                append,
                fetchId,
                yieldData
            );
        } else {
            this.props.fetchOne(type, id, url, alias, fetchId);
        }
    };

    fetchDebounce = debounce(this.fetch, 500, {
        leading: true,
        trailing: true,
    });

    query = () => {
        const { query, filterId } = this.props;
        if (!query) return null;

        let update = { ...query };
        this.props.change(FILTER_NS, filterId, update);
    };

    render() {
        const ErrorComponent = this.props.ErrorComponent;
        if (this.props.critical && this.props.error) {
            return (
                <ErrorComponent
                    error={this.props.error}
                    id={this.props.fetchId}
                />
            );
        }
        return this.props.children || null;
    }
}

Fetch.defaultProps = {
    useContext: true,
};

const ConnectedFetch = connect(mapStateToProps, mapDispatch)(Fetch);

export default function FetchApiContext(props) {
    const value = useContext(ApiContext);
    const networkState = useNetworkState();
    const online = networkState ? networkState.quality.online : true;

    if (!value) return <div>Api context not provided</div>;

    return <ConnectedFetch {...props} online={online} apiContext={value} />;
}
