import React, { useCallback, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import { useApiActions, useUiFlag } from "state/hooks";
import { handleUploadCallback } from "state/actions/upload";
import Button from "components/Button";
import MessageInputIcons from "./MessageInputIcons";
import TextInput from "./TextInput";
import { TypingIndicator, useTypingIndication } from "./TypingIndicator";
import useScreenSize from "util/hooks/useScreenSize";

import "./style.css";
import Icon from "components/Icon";

export default function MessageForm(props) {
    const {
        messageId,
        parent,
        placeholder,
        submitMode,
        onCreateMessage,
        typingIndication,
        submitLabel,
    } = props;

    const { isMobile } = useScreenSize();

    const [id, setId] = useState(uuid());
    const tmpId = messageId || parent || id;
    const stateKey = `message.${tmpId}`;
    const [message, setMessage] = useUiFlag(stateKey);

    const create = useCallback(
        (data) => {
            const messageData = {
                id,
                content: message,
                ...data,
            };
            onCreateMessage(messageData);
            setMessage(null);
            setId(uuid());
        },
        [id, message, onCreateMessage, setMessage]
    );

    const submit = useCallback(() => {
        create({});
    }, [create]);

    const { onUpload } = useApiActions({
        onUpload: handleUploadCallback,
    });

    const upload = (files) => {
        files.forEach((file) => {
            onUpload(
                (file) => onCreateMessage({ media: [file] }),
                "db.media",
                null,
                file
            );
        });
    };

    const addLink = useCallback(
        (url) => {
            create({ link: url });
        },
        [create]
    );

    const value = message || "";

    let [typing, onKeyPress] = useTypingIndication(parent);

    const onChange = useCallback(
        (...args) => {
            setMessage(...args);
            onKeyPress();
        },
        [onKeyPress, setMessage]
    );

    return (
        <div className={"rows message-form"}>
            {typingIndication && typing && typing.identity && (
                <div className={"message-typing"}>
                    <TypingIndicator identity={typing.identity} />
                </div>
            )}
            <div
                className={cn({
                    "cols cols-middle": !isMobile,
                    "gap-sm": true,
                    rows: isMobile,
                })}
            >
                <div className={"message-box"}>
                    <TextInput
                        placeholder={placeholder}
                        parent={parent}
                        onChange={onChange}
                        value={value}
                        onUpload={upload}
                        onAddLink={submitMode === "enter" ? addLink : null}
                        onEnterKey={submitMode === "enter" ? submit : null}
                    />
                    <div className={"message-box__icons"}>
                        <MessageInputIcons
                            onAddLink={addLink}
                            value={value}
                            onChange={setMessage}
                            onUpload={upload}
                        />
                    </div>
                    {submitMode === "button" && (
                        <Button
                            disabled={!value}
                            onClick={submit}
                            buttonClassName={"message-input__button"}
                        >
                            {isMobile ? (
                                <Icon size={"md"}>mui-send</Icon>
                            ) : (
                                submitLabel
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

MessageForm.propTypes = {
    onCreateMessage: PropTypes.func,
    submitMode: PropTypes.oneOf(["enter", "button"]),
    submitLabel: PropTypes.string,
};

MessageForm.defaultProps = {
    placeholder: "Write something...",
    submitMode: "enter",
    submitLabel: "Send",
    typingIndication: true,
};
