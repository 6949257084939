import React, {useCallback} from "react";

import {useSelector} from "react-redux";
import {selectEntities} from "state/selectors/data";
import PropTypes from "prop-types";

import {File} from "./AdaptiveFileList";
import AdaptiveFileList from "./AdaptiveFileList";
import * as fileUtils from "util/file";
import {usePending, useRouteParam} from "state/hooks";
import {ROUTE_MEDIA} from "joynt/config";

const fileProps = (file) => ({
    id: file.id,
    fileType: file.type,
    file: file.file,
    extension: fileUtils.extension(file.file)
});

function FileContainer(props) {
    const {Render, ...other} = props;
    const isPending = usePending(props.id);
    return <Render {...other} isPending={isPending} />;
}

export default function Container(props) {
    const {
        id: fieldId,
        entityType,
        entityId,
        value,
        type,
        onDelete
    } = props;

    const data = useSelector(s => selectEntities(s, type, value));

    const [,showPreview] = useRouteParam(ROUTE_MEDIA);

    const onClick = useCallback(id => {
        //showPreview([type, id].join('/'));
        showPreview([[entityType,entityId,fieldId].filter(v => !!v).join('/'),id].join(':'))
    }, [type, entityType, entityId, fieldId, showPreview]);

    return <AdaptiveFileList FileContainer={FileContainer}>
        {data.map(file => <File
            key={file.id}
            type={type}
            onClick={onClick}
            onDelete={onDelete}
            {...fileProps(file)}
        />)}
    </AdaptiveFileList>;
}

Container.defaultProps = {
    type: 'db.media',
    mode: 'list',
    readOnly: true
};

Container.propTypes = {
    mode: PropTypes.oneOf(['list', 'grid']),
    readOnly: PropTypes.bool
};