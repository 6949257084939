import { deleteIn, setIn } from "lodash-redux-immutability";
import { createReducer } from "../helpers/create-reducer";
import { normalize } from "state/util";

function importState() {
    const themeData = window.CONSOLE_THEME;
    if (themeData) {
        const theme = actions["DATA.STORE.ONE"](
            {},
            {
                payload: {
                    type: "console.themes",
                    id: themeData.id,
                    data: themeData,
                },
            }
        );
        const alias = actions["DATA.STORE.ALIAS"](theme, {
            payload: {
                type: "console.presets",
                alias: "@currentPreset",
                id: themeData.preset.id,
            },
        });
        console.log(alias);
        return alias;
    }

    return {};
}

function storeBatchStyles(state, styles) {
    return {
        ...state,
        "cms.styles": { ...(state["cms.styles"] || {}), ...styles },
    };
}

const actions = {
    "DATA.STORE.ONE": (state, { payload }) => {
        const { type, id, data } = payload;
        return normalize(state, type, [{ id, ...data }]);
    },
    "DATA.STORE.LIST": (state, { payload }) => {
        const { type, data, yieldData } = payload;
        return normalize(state, type, data, yieldData);
    },
    "DATA.STORE.BATCH": (state, { payload }) => {
        const { data, meta } = payload;
        const types = Object.keys(data);
        types.forEach((type) => {
            if (type === "cms.styles") {
                state = storeBatchStyles(state, data[type]);
            } else {
                if (data[type]) state = normalize(state, type, data[type]);
            }
        });
        if (meta.aliases) {
            Object.keys(meta.aliases).forEach((aliasKey) => {
                let [type, alias] = aliasKey.split("/");
                state = setIn(state, [type, alias], meta.aliases[aliasKey]);
            });
        }
        return state;
    },
    "DATA.STORE.ALIAS": (state, { payload }) => {
        const { type, alias, id } = payload;
        return setIn(state, [type, alias], id);
    },
    "DATA.RESET.TYPES": (state, { payload }) => {
        if (!payload.resetData) return next;
        const { types } = payload;
        let next = { ...state };
        types.forEach((type) => {
            next[type] = {};
        });
        return next;
    },
    "DELETE.SUCCESS": (state, { payload }) => {
        const { type, id } = payload;
        return deleteIn(state, [type, id]);
    },
    "DELETE.SUCCESS.BATCH": (state, { payload }) => {
        let next = { ...state };
        Object.keys(payload).forEach((type) => {
            payload[type].forEach((id) => {
                next = deleteIn(next, [type, id]);
            });
        });
        return next;
    },
    "CMS.STYLES.STORE": (state, { payload: { styles } }) => {
        return { ...state, "cms.styles": styles };
    },
};

const initialState = importState();
export default createReducer(initialState, actions);
