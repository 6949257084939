// eslint-disable-next-line import/no-webpack-loader-syntax
import css from "!!css-to-string-loader!css-loader!playground/cms/views/Sections/sections.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionCardCss from "!!css-to-string-loader!css-loader!playground/cms/views/Sections/SectionBrowser/SectionCard/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionGalleryCss from "!!css-to-string-loader!css-loader!playground/cms/views/Sections/SectionBrowser/SectionGalleryItem/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import sectionPreviewCss from "!!css-to-string-loader!css-loader!playground/cms/views/Sections/SectionBrowser/SectionPreview/style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import canvas from "!!css-to-string-loader!css-loader!playground/cms/views/Sections/canvas.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import editorCss from "!!css-to-string-loader!css-loader!playground/designer/canvas.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import themeComponents from "!!css-to-string-loader!css-loader!./themeComponents.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import debugCss from "!!css-to-string-loader!css-loader!./debug.css";

export default [
    css,
    sectionCardCss,
    sectionGalleryCss,
    sectionPreviewCss,
    canvas,
    editorCss,
    themeComponents,
    debugCss,
].join("\n\n\n");
