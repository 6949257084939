import React from "react";
//import PropTypes from 'prop-types';
import CreateWebsite from "./CreateWebsite";
import { useData } from "state/hooks";
import { useCreateWebsite } from "playground/console/views/Websites/CreateWebsite/hooks";
import { FormContainer } from "playground/cms/Form";

export function CreateWebsiteContainer(props) {
    const { onClose } = props;
    const id = "create-website";
    const type = "app.create.website";
    const data = useData({ type, id });

    const { onExport, onSubmit, onReset, status, isPending } = useCreateWebsite(
        { type, id }
    );

    const handleClose = () => {
        onReset();
        onClose();
    };

    const formProps = {
        onExport,
        onSubmit,
        onClose: handleClose,
        status,
        isPending,
    };

    return (
        <FormContainer type={type} id={id} disableFetch={true}>
            <CreateWebsite type={type} id={id} {...formProps} />
        </FormContainer>
    );
}

CreateWebsiteContainer.propTypes = {};

CreateWebsiteContainer.defaultProps = {};

export default CreateWebsiteContainer;
