import { deleteIn, getIn, setIn } from "lodash-redux-immutability";
import Geometry from "plan-editor/util/Geometry";

export function parsePoints(pointsStr) {
    if (!pointsStr) return [];
    if (!pointsStr[0]) return [];
    let points = [];
    let pointsList = pointsStr[0].split(" ");
    pointsList.forEach((pointStr) => {
        let xy = pointStr.split(",");
        points.push({ x: parseInt(xy[0]), y: parseInt(xy[1]) });
    });
    return points;
}

export function serializePoints(points) {
    return [
        points
            .map((p) => {
                return [p.x * 1, p.y * 1].join(",");
            })
            .join(" "),
    ];
}

function pointsPath(layer, shape) {
    return shapePath(layer, shape).concat(["points"]);
}

function shapePath(layer, shape) {
    return ["layers", layer, "shapes", shape];
}

function getPoints(data, layer, shape) {
    return parsePoints(getIn(data, pointsPath(layer, shape)));
}

function pointsArray(points) {
    return points.map((p) => [p.x, p.y]);
}

export function setPoints(data, layer, shape, points) {
    return setIn(data, pointsPath(layer, shape), serializePoints(points));
}

export function setCenter(data, layer, shape, center) {
    return setIn(data, shapePath(layer, shape).concat(["center"]), center);
}

export function addPoint(data, layer, shape, point) {
    let points = getPoints(data, layer, shape).slice();
    points.push({ x: point[0], y: point[1] });
    const geometry = new Geometry(pointsArray(points));
    const center = geometry.center();
    return setCenter(
        setPoints(data, layer, shape, points),
        layer,
        shape,
        center
    );
}

export function deletePoint(data, layer, shape, pointIndex) {
    let points = getPoints(data, layer, shape);
    let nextPoints = points.filter((p, index) => {
        return index !== pointIndex;
    });
    return setPoints(data, layer, shape, nextPoints);
}

export function cleanupShapes(data) {
    let next = { ...data };
    let layers = Object.keys(data.layers);
    layers.forEach((layer) => {
        let layerData = data.layers[layer];
        let shapes = Object.keys(layerData.shapes);
        shapes.forEach((shape) => {
            let points = layerData.shapes[shape].points || [];
            let hasPoints = points[0];
            let path = ["layers", layer, "shapes", shape];
            if (!hasPoints) {
                next = deleteIn(next, path);
            }
        });
    });
    return next;
}

export function clearShape(data, layer, shape) {
    return deleteIn(data, shapePath(layer, shape));
}
