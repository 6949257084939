import React from 'react';
import PropTypes from 'prop-types';
import MuiIcon from "@material-ui/core/Icon/Icon";

export default class WebfontIcon extends React.PureComponent {

    render() {
        const { onClick, color } = this.props;
        let icon = this.props.children || this.props.icon;

        if (!icon) return null;
        icon = icon.replace('fa-', '');

        if (icon.indexOf("fh-") > -1) {
            return <i onClick={onClick} className={"fh " + icon}/>;
        }

        if (icon.indexOf("mui-") === 0) {
            return <MuiIcon
                onClick={onClick}
                color={color||'inherit'}
                size="inherit">
                {icon.replace('mui-','')}
            </MuiIcon>;
        }

        return <i onClick={onClick} className={"fa fa-" + icon} />;
    }

}

WebfontIcon.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string
};