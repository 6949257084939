import {useEffect, useState} from "react";
import useEcho from "joynt/websockets/hooks/useEcho";

export default function usePublicChannel(channel) {
    const [subscription, setSubscription] = useState(null);
    const echo = useEcho();

    useEffect(() => {
        console.log(`Subscribe to ${channel}`);
        let sub = null;
        if (echo) {
            sub = echo.channel(channel);
            setSubscription(sub);
        }
        return () => {
            console.log(`Unsubscribe from ${channel}`);
            if (echo) {
                echo.leave(channel);
                setSubscription(null);
            }
        }
    }, [channel, echo, setSubscription]);

    return subscription;
}