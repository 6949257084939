import React from "react";
import ConfirmDialog from "joynt/components/ConfirmDialog";

//import PropTypes from 'prop-types';

export function SessionUnpublished(props) {
    //const {} = props;

    return (
        <ConfirmDialog
            {...props}
            title={
                <>
                    Your session has been <br />
                    successfully unpublished!
                </>
            }
        >
            <p>You may want to notify the users about this.</p>
        </ConfirmDialog>
    );
}

SessionUnpublished.propTypes = {};
SessionUnpublished.defaultProps = {};

export default SessionUnpublished;
