import { useUiFlag } from "state/hooks";
import { useCallback, useMemo } from "react";
import { useSwipeable } from "react-swipeable";

export default function useSidebar({ useSwipe }) {
    const [showSidebar, onShowSidebar] = useUiFlag("mobile.showSidebar");

    const [showUtilityNav, onShowUtilityNav] = useUiFlag(
        "mobile.showUtilityNav"
    );

    const hide = useCallback(() => {
        onShowSidebar(false);
        onShowUtilityNav(false);
    }, [onShowSidebar, onShowUtilityNav]);

    const swipe = useCallback(
        (evt) => {
            //prevent opening sidebars while writing messages
            const sourceName = evt.event?.srcElement?.nodeName;
            if (sourceName === "TEXTAREA") {
                return;
            }

            const { dir } = evt;
            if (dir === "Left" && showSidebar) {
                onShowSidebar(false);
                return;
            }
            if (dir === "Right" && showUtilityNav) {
                return onShowUtilityNav(false);
            }
            if (dir === "Left" && !showUtilityNav) {
                onShowUtilityNav(true);
                return;
            }
            if (dir === "Right" && !showSidebar) {
                onShowSidebar(true);
                return;
            }
        },
        [showSidebar, showUtilityNav, onShowSidebar, onShowUtilityNav]
    );

    const handlers = useSwipeable({
        onSwiped: swipe,
    });

    return useMemo(
        () => ({
            onHide: hide,
            swipeHandlers: useSwipe ? handlers : null,
            showSidebar,
            showUtilityNav,
        }),
        [hide, useSwipe, handlers, showSidebar, showUtilityNav]
    );
}
