import React from 'react';
import PropTypes from 'prop-types';

import Whiteboard from "./Whiteboard";
import {usePost} from "joynt/components/Post";

export function WhiteboardContainer(props) {

    const {id} = props;
    const {link} = usePost(id);

    return <Whiteboard
        {...props}
        link={link}
    />

}

WhiteboardContainer.propTypes = {
    id: PropTypes.string
};
WhiteboardContainer.defaultProps = {};

export default WhiteboardContainer;