/**
 * Overload native console methods with custom handler
 */
export default function() {
    if (window.console && console) {
        ['log', 'warn', 'error'].forEach(function(name) {
            if (typeof console[name] === 'function') {
                console[name] = makeHandler(name, console[name]);
            }
        });
    }
}

let consoleLogHistory = [];

/**
 * Return logs from history
 * @param cleanup - Resets history
 * @returns {[]}
 */
export function getLogs(cleanup = false) {
    if (!cleanup) {
        return consoleLogHistory;
    }

    const logs = consoleLogHistory;
    consoleLogHistory = [];
    return logs;
}

function makeHandler(name, originalFunction) {
    return function(...args) {
        /** Append to our own history **/
        if (typeof args[0] === 'string') {
            if (args[0] && args[0].indexOf('Agora-SDK') > -1) {
                consoleLogHistory.push({
                    type: name,
                    data: ['[agora-sdk]'].concat(args.slice(2)),
                    ts: Date.now()
                });
            } else {
                consoleLogHistory.push({
                    type: name,
                    data: args,
                    ts: Date.now()
                });
            }
        }

        /** Call native console handler **/
        originalFunction.apply(this, args);
    }
}