/* eslint-disable */
const strpos = (haystack, needle) => {
    return haystack.indexOf(needle);
};
const array_intersect_loc = require("locutus/php/array/array_intersect");
//const array_diff = require('locutus/php/array/array_diff');
const array_merge = require("locutus/php/array/array_merge");
const array_intersect = (arr1, arr2) => {
    let loc = array_intersect_loc(arr1, arr2);
    if ("object" === typeof loc) {
        loc = Object.values(loc);
    }
    return loc.length > 0;
};
const array_diff = (arr1, arr2) => {
    return arr1.filter((item) => arr2.indexOf(item) === -1);
};

class CssClassNameBag {
    constructor(classNames = Array()) {
        this.names = Array();
        this.rules = Array();
        this.add(classNames);
    }

    add(className) {
        if (className instanceof CssClassNameBag) {
            return this.add(className.getClassNames());
        }

        if (
            !Array.isArray(className) &&
            "string" === typeof className &&
            strpos(className, " ") > -1
        ) {
            className = className.split(" ");
        }

        if (Array.isArray(className)) {
            className.forEach((className) => {
                if (className) this.add(className.trim());
            });
            return this;
        }

        if (this.hasAny(className)) return this;

        if (className === "@all") {
            this.names.unshift(className);
        } else {
            this.names.push(className);
        }
        return this;
    }

    rule(condition, className) {
        if (!(undefined !== this.rules[condition]))
            this.rules[condition] = Array();
        this.rules[condition].push(className);
        return this;
    }

    __toString() {
        this.applyRules();
        var names = this.names.filter(
            (
                name //strpos($name, '@') === -1;
            ) => {
                return true;
            }
        );
        return names.join(" ");
    }

    hasAny(classNames) {
        if (!Array.isArray(classNames)) classNames = [classNames];

        for (var className of Object.values(this.names)) {
            if (-1 !== classNames.indexOf(className)) return true;
        }

        return false;
    }

    hasAnyLike(pattern) {
        for (var className of Object.values(this.names)) {
            if (
                "string" === typeof className &&
                strpos(className, pattern) === 0
            ) {
                return className;
            }
        }

        return false;
    }

    extend(classes, options) {
        var base = this.getClassNames();
        var extend = classes.getClassNames();

        for (var key in options) {
            var values = options[key];

            if (array_intersect(values, extend)) {
                base = array_diff(base, values);
            }
        }

        var result = new CssClassNameBag(base);
        return result.add(classes);
    }

    replace(className, replaceWith, options) {
        var existing = this.getClassNames();
        var replace = replaceWith.getClassNames();
        var merge = new CssClassNameBag();

        for (var name of Object.values(existing)) {
            if (name === className) {
                for (var replName of Object.values(replace)) {
                    merge = merge.extend(
                        new CssClassNameBag(replName),
                        options
                    );
                }
            } else {
                merge = merge.extend(new CssClassNameBag(name), options);
            }
        }

        return merge;
    }

    getClassNames() {
        return this.names;
    }

    applyRules() {
        {
            let _tmp_2 = this.rules;

            for (var condition in _tmp_2) {
                var ruleClassNames = _tmp_2[condition];

                if (-1 !== this.names.indexOf(condition)) {
                    this.names = array_merge(this.names, ruleClassNames);
                }
            }
        }
    }
}

export default CssClassNameBag;
