export default [
    {
        id: "witness-solo-1",
        name: "Intro1",
        message:
            "It is recommended you close any distracting apps (YT, FB, Twitter, Tiktok, Instagram, Discord, Slack etc), and mute your phone.",
        offline: true,
        duration: 30,
        speaker: "%identity",
        aside: "witness-solo",
    },
    {
        id: "witness-solo-2",
        name: "Intro2",
        message:
            "Please try to visualise the task in your mind. Be specific — a good example of a specific task: „I wish to write 2 new pages of my novel”. Try to visualise successfully completing the task — in the example case, visualise the 2 new pages, successfully written.",
        offline: true,
        duration: 60,
        speaker: "%identity",
        aside: "witness-solo",
    },
    {
        id: "witness-solo-3",
        name: "Intro3",
        message:
            "If during the task you’ll be away from your computer, set a an additional timer for 54 min on your phone to remind you when to come back for the final evaluation.",
        offline: true,
        duration: 30,
        speaker: "%identity",
        aside: "witness-solo",
    },
    {
        id: "witness-solo-4",
        name: "Intro4",
        message:
            "A timer will keep track of time until session ends. Good luck and let’s get started",
        offline: true,
        speaker: "%identity",
        aside: "witness-solo",
    },
    {
        id: "in-progress",
        name: "Task in progress",
        duration: 45 * 60,
        offline: true,
        view: "witness-task-progress",
        isSolo: true,
        steps: [
            {
                id: "complete",
                name: "Congratulations",
                message:
                    "Congratulations for your efforts! It's ok if you didn't accomplish your goal - trying hard is like training that leads to success. If you did - even better!",
                offline: true,
                speaker: "%identity",
                aside: "witness-solo",
            },
        ],
    },
    {
        id: "give-badge",
        name: "Give badge to %aName",
        speaker: "%identity",
        peer: "%identity",
        offline: true,
        message:
            "%aName, please rate your progress for our Witness Task. You have 4 options",
        view: "witness-badge",
        aside: "witness-solo",
    },
    {
        id: "end",
        name: "End",
        offline: true,
        message:
            "Thank you for participating in Witness Task session! We hope you’ve enjoy it and manage to fulfill your goals. See you next time!",
        aside: "witness-solo",
        next_label: "End session",
    },
    {
        id: "end",
    },
];
