import {
    selectById,
    selectDataById,
    selectEntity,
    selectEntityChangeDiff,
    selectEntityData,
    selectEntityDiff,
    selectFieldValue,
    selectFilteredItems,
    selectIsDraft,
} from "state/selectors/data";
import { selectFilteredListData, selectList } from "state/selectors/lists";
import { selectList as selectListData } from "state/selectors/data";
import { selectCurrentUserId, selectUserProfile } from "state/selectors/app";
import { intersection } from "lodash";
import { selectRouteParam } from "state/selectors/router";
import createCachedSelector from "re-reselect";
import { selectFlag } from "state/selectors/ui";
import {
    EVENT_ROLE_ATTENDEE,
    EVENT_ROLE_INVITED,
    EVENT_ROLE_MODERATOR,
    EVENT_ROLE_RECORDING, EVENT_ROLE_SPEAKER,
    FLAG_SESSION_ID,
    NODE_TYPE_EVENT,
    NODE_TYPE_SPACE,
    POST_TYPE_ALBUM,
    POST_TYPE_LINK,
    POST_TYPE_POLL,
    POST_TYPE_TEXT,
    ROUTE_CHANNEL,
} from "joynt/config";
import { memoize } from "lodash";
import { WORKSPACE_PLAN_PRO } from "joynt/config/plans";

const nullObj = {};
const nullArr = [];

export function selectEdges(store, id) {
    const edges = selectFieldValue(store, "db.nodes", id, "edges_ref");
    return edges || nullObj;
}

export function selectMeetingId(store, id) {
    const node = selectEntity(store, "db.nodes", id);
    const meetingPost = selectEntity(store, "db.posts", node.meeting);
    return meetingPost.meeting;
}

export function selectNodeIdentity(store, id) {
    let identities = selectList(store, "db.identities", `participants.${id}`);
    identities = identities.slice();

    let identitiesData = selectDataById(store, "db.identities", identities);
    let user = selectCurrentUserId(store);
    let userIdentity = identitiesData.filter(function (item) {
        return item.created_by === user;
    });

    return userIdentity[0];
}

export function selectUserNodeIdentity(store, id) {
    const node = selectEntity(store, "db.nodes", id);
    const edges = node.edges_ref || {};
    if (edges.identity) {
        return edges.identity_node.identity;
    }

    /** FIXME: Remove after reducer identity lookup has been deployed **/
    const user = selectCurrentUserId(store);
    const nodeIdentity = selectEntityData(
        store,
        "db.identities",
        node.identity
    );
    if (nodeIdentity.created_by === user) return node.identity;
    const parent = edges.root_joined_node || {};
    return parent.identity;
}

const nodeIdentitiesSelector = createCachedSelector(
    [
        (store, id) => selectEntity(store, "db.nodes", id).identity,
        (store, id) => selectEntity(store, "db.nodes", id).recipient_identities,
    ],
    (identity, recipient_identities) => {
        if (!identity) return recipient_identities || [];
        return [identity].concat(recipient_identities || []);
    }
)((store, id) => id);

export const selectRecipientIdentities = createCachedSelector(
    [
        nodeIdentitiesSelector,
        selectCurrentUserId,
        (store) => store.data["db.identities"],
    ],
    (identities, user, data) => {
        return identities.filter((identity) => {
            return (
                data &&
                data?.[identity] &&
                data?.[identity]?.created_by !== user
            );
        });
    }
)((store, id) => id);

function selectRouteParamFast(store, id) {
    return store?.router?.query?.[id];
}

const usersToIdentitiesIndex = memoize(function (data, list) {
    let index = {};
    list.forEach((id) => {
        if (data?.[id]?.created_by) {
            index[data[id].created_by] = data[id].id;
        }
    });
    return index;
});

const presentUsersToIdentities = memoize(function (users, presence, index) {
    const presentUsers = users.filter((u) => presence.indexOf(u) > -1);
    return presentUsers.map((u) => index?.[u]).filter((u) => !!u);
});

export function selectPresentIdentities(store, id, k = "nodes") {
    let root = selectRouteParamFast(store, "id");
    let presence = store.presence[root] || nullObj;
    let users = presence[k] ? presence[k][id] || nullArr : nullArr;
    if (!users.length) return nullArr;

    let identities = selectList(store, "db.identities", `participants.${root}`);

    return presentUsersToIdentities(
        users,
        presence.users,
        usersToIdentitiesIndex(
            store.data?.["db.identities"] || nullObj,
            identities
        )
    );
}

export function selectUserPresence(store, id) {
    let root = selectRouteParamFast(store, "id");
    let presence = store.presence[root] || nullObj;
    let users = presence.users || nullArr;
    if (users.indexOf(id) === -1) return nullObj;
    if (!presence.user) return nullObj;
    return presence.user[id] || nullObj;
}

export function selectParticipants(store, id, channel) {
    const channelNode = selectEntity(store, "db.nodes", channel);

    const speakers = channelNode.event_speakers || [];
    const moderators = channelNode.event_moderators || [];

    let identities = selectList(store, "db.identities", `participants.${id}`);
    identities = identities.slice();

    let identitiesData = selectById(store, "db.identities", identities);

    let nodePresence = store.presence[id] || {};
    let presence = nodePresence.users || [];
    let nodes = nodePresence.nodes || {};
    let channelPresence = nodes[channel] || [];

    let attendees = identitiesData
        .filter((item) => {
            return (
                channelPresence.indexOf(item.created_by) > -1 &&
                speakers.indexOf(item.id) === -1 &&
                moderators.indexOf(item.id) === -1
            );
        })
        .map((item) => item.id);

    let onlineIdentities = identitiesData
        .filter((item) => {
            return presence.indexOf(item.created_by) > -1;
        })
        .map((item) => item.id);

    let participants = onlineIdentities.filter((id) => {
        return (
            speakers.indexOf(id) === -1 &&
            moderators.indexOf(id) === -1 &&
            attendees.indexOf(id) === -1
        );
    });

    let offline = identities.filter((item) => {
        return (
            onlineIdentities.indexOf(item) === -1 &&
            participants.indexOf(item) === -1 &&
            speakers.indexOf(item) === -1 &&
            moderators.indexOf(item) === -1
        );
    });

    return {
        speakers,
        moderators,
        identities,
        online: onlineIdentities,
        attendees,
        participants,
        offline,
    };
}

export function selectIdentitiesNames(store, ids = []) {
    const user = selectCurrentUserId(store);
    const f = ({ created_by }) => {
        return created_by !== user;
    };
    const data = selectFilteredItems(store, "db.identities", ids, f);
    return data.map((id) => id.name).join(", ");
}

export function selectEventRole(store, id) {
    if (!id) return null;

    const event = selectEntity(store, "db.nodes", id);
    const user = selectCurrentUserId(store);
    const { name } = selectEntity(store, "db.users", user);

    if (name === "agora-io") return EVENT_ROLE_RECORDING;

    const { event_speakers, event_moderators, user_role } = event;

    const userIdentitiesFilter = (item) => {
        return item.created_by === user;
    };
    const userIdentities = selectFilteredListData(
        store,
        "db.identities",
        userIdentitiesFilter
    );
    const match = (ids) => {
        let result = intersection(userIdentities, ids);
        return result.length > 0;
    };

    if (["owner", "admin"].indexOf(user_role) > -1) return user_role;

    if (event_speakers && match(event_speakers)) return EVENT_ROLE_SPEAKER;
    if (event_moderators && match(event_moderators)) return EVENT_ROLE_MODERATOR;

    const inviteFlag = selectFlag(store, `meeting.${id}.stageInvite`);
    if (inviteFlag) return EVENT_ROLE_INVITED;

    return EVENT_ROLE_ATTENDEE;
}

export function selectSessionEvent(store, id) {
    const session = selectEntity(store, "db.nodes", id);
    return session.current_event || id;
}

export function selectSessionChat(store) {
    const sessionId = selectFlag(store, FLAG_SESSION_ID);
    if (!sessionId) return null;
    const event = selectSessionEvent(store, sessionId);
    const edges = selectEdges(store, event);
    return edges.session_chat || event;
}

export function isOwnMessage(store, id) {
    const { created_by } = selectEntity(store, "db.messages", id);
    const user = selectCurrentUserId(store);
    return user === created_by;
}

export function selectDatabases(store) {
    const services = Object.values(store.data["console.services"] || {});
    return services.map((s) => s.name).filter((s) => !!s);
}

function filterWorkspaces(k, parent, items) {
    return items
        .filter((node) => {
            return (
                (node.edges_ref.parent_workspace === parent ||
                    node.edges_ref.parent_workspace === node.id ||
                    (!parent && node.edges_ref.joined)) &&
                node.node_type !== "root" &&
                node.subtype === NODE_TYPE_SPACE
            );
        })
        .map((item) => item.id);
}

const filterWorkspacesMemo = memoize(filterWorkspaces);

export function selectWorkspaces(store, parent) {
    const items = selectListData(store, "db.nodes", "workspaces");
    const key = [
        parent,
        Math.max(
            ...items.map((item) => item.updated_at || item.created_at || 0)
        ),
        items.length,
    ].join("|");
    return filterWorkspacesMemo(key, parent, items);
}

function filterEvents(parent, items) {
    return items
        .filter((node) => {
            return (
                (node.edges_ref.parent_workspace === parent ||
                    node.edges_ref.parent_workspace === node.id ||
                    (!parent && node.edges_ref.joined)) &&
                node.node_type !== "root" &&
                node.subtype === NODE_TYPE_EVENT
            );
        })
        .map((node) => node.id);
}

const filterEventsMemo = memoize(filterEvents);

export function selectEvents(store, parent) {
    const nodes = selectListData(store, "db.nodes", "workspaces");
    return filterEventsMemo(parent, nodes);
}

export function selectExtendableWorkspaces(store, parent) {
    const nodes = selectListData(store, "db.nodes", "workspaces");
    return nodes
        .filter((node) => {
            return (
                node.workspace &&
                node.subtype === NODE_TYPE_SPACE &&
                node.can_edit &&
                node.node_type !== "root" &&
                node.workspace_plan === WORKSPACE_PLAN_PRO
            );
        })
        .map((node) => node.id);
}

function postContent(posts) {
    return posts.reduce((data = {}, post) => {
        let next = { ...data };
        if (post.subtype === POST_TYPE_TEXT) next.content = post.content;
        if (post.subtype === POST_TYPE_ALBUM && post.media)
            next.media = [...(post?.media || [])];
        if (post.subtype === POST_TYPE_LINK)
            next.links = [...(next?.links || []), post.link];
        return next;
    }, []);
}

const postContentMemo = memoize(postContent);

function postProperties(node, content, thread, media) {
    let result = { ...content };

    if (content.links) result.link = content.links[0];
    if (content.media) result.image = media[0];

    result.properties = {
        comments: thread.message_count >= 1 ? thread.message_count : 0,
        files: content.media ? content.media.length : 0,
        links: content.links ? content.links.length : 0,
        reactions: node.reactions
            ? node.reactions.reduce((count = 0, reaction) => {
                return count + reaction.count;
            }, 0)
            : 0,
    };

    return result;
}

const postPropertiesMemo = memoize(postProperties);

export function selectPostContent(store, id) {
    const node = selectEntityData(store, "db.nodes", id);
    const thread = selectEntityData(store, "db.threads", node.thread);
    const posts = selectDataById(store, "db.posts", node.posts || nullArr);

    const content = postContentMemo(posts);
    const media = selectById(store, "db.media", content.media || nullArr);

    return postPropertiesMemo(node, content, thread, media);
}

function postContentStructured(posts) {
    const SINGLE_TYPES = [POST_TYPE_TEXT, POST_TYPE_ALBUM, POST_TYPE_POLL];
    const struct = {};
    posts.forEach((post) => {
        let { id: postId, subtype } = post;
        if (SINGLE_TYPES.indexOf(subtype) > -1) {
            struct[subtype] = postId;
        } else {
            if (!struct[subtype]) struct[subtype] = [];
            struct[subtype].push(postId);
        }
    });
    return struct;
}

const postContentStructuredMemo = memoize(postContentStructured);

export function selectPostContentStructured(store, id) {
    const { posts } = selectEntity(store, "db.nodes", id);
    const postsData = selectById(store, "db.posts", posts || nullArr);
    return postContentStructuredMemo(postsData);
}

export function selectPostHasContent(store, id) {
    const { posts, name } = selectEntity(store, "db.nodes", id);
    if (name) return true;
    if (!posts) return false;
    if (posts.length > 1) return true;
    for (let i = 0; i < posts.length; i++) {
        let { content } = selectEntity(store, "db.posts", posts[i]);
        if (content) return true;
    }
    return false;
}

export function selectNewTags(store, id) {
    const { tags } = selectEntity(store, "db.nodes", id);
    const tagNodes = selectById(store, "db.nodes", tags || nullArr);
    const newTags = tagNodes.filter((node) => {
        return selectIsDraft(store, "db.nodes", node.id);
    });
    return newTags;
}

export function selectRootNodeId(store) {
    const { root_node_id: root } = selectUserProfile(store);
    return root;
}

export function selectRequestIntents(store, type, id, data) {
    if (type !== "db.nodes") return null;

    let intents = [];

    const entityData = selectEntity(store, type, id);
    const isDraft = selectIsDraft(store, type, id);

    const isSession =
        entityData.subtype === NODE_TYPE_EVENT && !entityData.workspace;

    if (!isSession) return ["create-node"];

    if (isDraft) {
        if (entityData.session_type === "private") {
            intents.push("create-private-session");
            return intents;
        } else {
            intents.push("create-session");
            if (data && data.hidden) {
                intents.push("create-unpublished");
            } else {
                intents.push("create-published");
            }
        }
        return intents;
    }

    const diff = data
        ? selectEntityDiff(store, type, id, data)
        : selectEntityChangeDiff(store, type, id);

    if (!Object.keys(diff).length) return intents;

    if (!diff.hidden) return intents;

    if (diff.hidden.current && !diff.hidden.next)
        intents.push("session-publish");

    if (!diff.hidden.current && diff.hidden.next)
        intents.push("session-unpublish");

    return intents;
}

export function selectRequiredSubmitConfirmation(store, type, id) {
    if (type !== "db.nodes") return false;

    const intents = selectRequestIntents(store, type, id);

    const confirms = {
        "create-session": "session-create-publish",
        "session-publish": "session-publish",
        "session-unpublish": "session-unpublish",
    };

    for (let i = 0; i < intents.length; i++) {
        if (confirms[intents[i]]) return confirms[intents[i]];
    }

    return false;
}

export function selectFollowupConfirms(store, type, id, data) {
    if (type !== "db.nodes") return false;

    const intents = selectRequestIntents(store, type, id, data);

    const confirms = {
        "create-published": "session-publish-status",
        "create-private-session": "session-publish-status",
        "session-publish": "session-publish-status",
        "session-unpublish": "session-publish-status",
    };

    for (let i = 0; i < intents.length; i++) {
        if (confirms[intents[i]]) return confirms[intents[i]];
    }

    return false;
}

export function isSessionChat(s, id, channel) {
    const ch = channel || selectRouteParamFast(s, ROUTE_CHANNEL);
    const { session_chat } = selectEdges(s, ch);
    return id === session_chat;
}

export function selectIdentity(s, id) {
    return s?.data?.["db.identities"]?.[id] ?? nullObj;
}

export function isUserIdentity(s, identity) {
    const user = selectCurrentUserId(s);
    const { created_by } = selectIdentity(s, identity);
    return created_by === user;
}
