import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { useChannels, useNodeChannels, useRouter } from "joynt/hooks/channels";
import { useNodeData } from "joynt/hooks";
import { useClick } from "state/hooks";

import Fetch from "containers/Fetch";
import Tabs from "./Tabs";

export function TabsContainer(props) {
    const { id, channel, list, url, type } = props;

    const { onLink } = useRouter();
    const { onCreate, onEdit } = useChannels(id, list);
    const create = useCallback(() => onCreate(), [onCreate]);
    const { can_edit } = useNodeData(id);
    const { view, views, onChangeView } = useNodeChannels(id, channel);

    const linkToRoot = useClick({ onClick: onLink, id });

    const changeTab = useCallback(
        (id) => {
            linkToRoot();
            onChangeView(id);
        },
        [onChangeView, linkToRoot]
    );

    return (
        <>
            <Fetch type={type} list={list} url={url} enable={true} />
            <Tabs
                {...props}
                list={list}
                view={view}
                onChangeView={changeTab}
                onClick={onLink}
                views={views}
                canEdit={can_edit}
                onEdit={onEdit}
                onCreate={create}
            />
        </>
    );
}

TabsContainer.propTypes = {
    id: PropTypes.string,
};
TabsContainer.defaultProps = {};

export default TabsContainer;
