import React from "react";
//import PropTypes from 'prop-types';

import Pages from "./Pages";
import { ListLayout } from "playground/cms/List";
import { Search } from "playground/cms/List/Filter";

import {
    useHasProperties,
    PropertyFilter,
} from "playground/cms/views/Properties";

export function PagesView(props) {
    const { type } = props;

    const hasProperties = useHasProperties();

    return (
        <Pages {...props}>
            <ListLayout>
                <ListLayout.Filter>
                    <Search type={type} name={"search"} />
                    {hasProperties && <PropertyFilter type={type} />}
                </ListLayout.Filter>

                <ListLayout.Header>
                    <ListLayout.Header.Icons prepend={true}>
                        <ListLayout.Header.Filter />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
            </ListLayout>
        </Pages>
    );
}

PagesView.propTypes = {};

PagesView.defaultProps = {};

export default PagesView;
