import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import {
    AudioButton,
    VideoButton,
} from "joynt/components/SessionToolbar/Buttons";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import useSessionControls from "joynt/meeting/hooks/useSessionControls";
import { useMediaDevices } from "joynt/meeting/devices";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import { SESSION_POLICY_MEETING } from "joynt/config";

export function AudioVideo(props) {
    const { size } = props;

    const { session, policy } = useMeeting();
    const isMeeting = policy === SESSION_POLICY_MEETING;
    const { isLive } = useSessionState(session);

    const { onToggleAudio, onToggleVideo, onGoLive } =
        useSessionControls(session);

    const [{ audioStatus, videoStatus }] = useConnectionState(session);
    const audio = audioStatus === "success";
    const video = videoStatus === "success";

    const enableStreamControls = isLive;
    const { cameraAvailable, microphoneAvailable } = useMediaDevices();
    const goLive = useCallback(
        () => onGoLive(true, "join-request"),
        [onGoLive]
    );

    if (!isMeeting && !isLive) return null;

    if (isMeeting && !isLive) {
        return (
            <>
                <VideoButton
                    disabled={!cameraAvailable}
                    on={false}
                    isPending={false}
                    onClick={goLive}
                    size={size}
                />
                <AudioButton
                    disabled={!microphoneAvailable}
                    on={false}
                    isPending={false}
                    onClick={goLive}
                    size={size}
                />
            </>
        );
    }

    return (
        <>
            <VideoButton
                disabled={!enableStreamControls || !cameraAvailable}
                on={video}
                isPending={videoStatus === "pending"}
                onClick={onToggleVideo}
                size={size}
            />
            <AudioButton
                disabled={!enableStreamControls || !microphoneAvailable}
                on={audio}
                isPending={audioStatus === "pending"}
                onClick={onToggleAudio}
                size={size}
            />
        </>
    );
}

AudioVideo.propTypes = {};

AudioVideo.defaultProps = {};

export default AudioVideo;
