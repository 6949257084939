import {
    takeEvery,
    take,
    put,
    select,
    race,
    all,
    call,
} from "redux-saga/effects";
import {
    fetchOne,
    submitBatchCallback,
    submitOneCallback,
} from "state/actions/api";
import { notify } from "state/actions/ui";
import { selectEntity } from "state/selectors/data";
import { change, reject } from "state/actions/data";
import { dragSectionPrompt } from "cms/state/actions/cms";
import { copySection, createSection } from "cms/state/sagas/drag-section";
import { pushRouteParams } from "state/actions/router";

function* handleCreatePage({ payload, context }) {
    try {
        const { cb, type, id } = payload;

        yield put({
            type: "DATA.VALIDATE.REQUEST",
            payload: { type, id, cb },
            context,
        });

        const { payload: status } = yield take("DATA.VALIDATE.STATUS");

        if (!status) {
            yield put(notify("Validation failed", "error"));
            return;
        }

        const data = yield select((store) => selectEntity(store, type, id));

        if (!data.create_from) {
            yield put(submitOneCallback(context)(cb, type, id));
            return;
        }

        const pageUrl = `${type}/:id?expand=true`;
        yield put(fetchOne(context)(type, data.create_from, pageUrl));

        const pagePath = [type, data.create_from].join("/");
        yield put(dragSectionPrompt(JSON.stringify({ page: pagePath })));

        const choice = yield race({
            select: take("CMS.SECTIONS.SELECT"),
            cancel: take("CMS.SECTIONS.CANCEL"),
        });

        yield put(pushRouteParams({ prompt: null }));

        if (!choice.select) {
            //yield put(reject(type, id));
            return;
        }

        const sections = choice.select.payload.data;
        const ids = Object.keys(sections);
        const targetSections = [];

        let sectionId;

        for (let i = 0; i < ids.length; i++) {
            if (sections[ids[i]] === 1) {
                targetSections.push(ids[i]);
            }
            if (sections[ids[i]] === 2) {
                sectionId = yield createSection(
                    yield copySection("cms.sections", ids[i])
                );
                targetSections.push(sectionId);
            }
        }

        const currentPage = yield select((store) =>
            selectEntity(store, type, id)
        );
        const sourcePage = yield select((store) =>
            selectEntity(store, type, data.create_from)
        );
        const update = {
            ...sourcePage,
            ...currentPage,
            sections: targetSections,
            active: false,
        };
        yield put(change(type, id, update));

        const url = "cms/batch";

        const paths = [
            ...targetSections.map((s) => `cms.sections/${s}`),
            `${type}/${id}`,
        ];
        yield put(submitBatchCallback(context)(cb, url, paths, id));
    } catch (e) {
        console.log(e.message);
    }
}

export default function* () {
    yield takeEvery("CMS.PAGES.CREATE", handleCreatePage);
}
