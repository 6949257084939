import React from "react";
import cn from "classnames";
import Icon from "components/Icon";

import "./style.css";

function ArrowsNav(props) {
    const { count, current, onPrev, onNext } = props;

    return (
        <div className={"slider-arrows"}>
            <button
                className={cn(
                    "slider-arrows__button",
                    current === 1 && "slider-arrows__button--disabled"
                )}
                onClick={onPrev}
            >
                <Icon fontSize={"medium"}>mui-chevron_left</Icon>
            </button>
            <p className={"slider-arrows__values"}>
                <span className={cn("slider-arrows__current-value")}>
                    {current}
                </span>
                /<span>{count}</span>
            </p>
            <button
                className={cn(
                    "slider-arrows__button",
                    (count === 0 || current === count) &&
                        "slider-arrows__button--disabled"
                )}
                onClick={onNext}
            >
                <Icon fontSize={"medium"}>mui-chevron_right</Icon>
            </button>
        </div>
    );
}

export default ArrowsNav;
