import React, { useMemo } from "react";
//import PropTypes from 'prop-types';

import { useForm, usePending, useFetch } from "state/hooks";
import AggregateRoot from "containers/AggregateRoot";
import { FormContext } from "playground/cms/Form/FormContext";
import { useSelector } from "react-redux";
import { selectExists } from "state/selectors/data";

export function FormContainer(props) {
    const {
        type,
        id,
        disableFetch,
        disableSubmit,
        validate,
        onClose,
        FormComponent,
    } = props;

    const {
        onSubmit: onSubmitAndClose,
        onReject,
        isDraft,
        isValid,
    } = useForm(type, id, onClose, validate);

    const { onSubmit } = useForm(type, id, validate);
    const exists = useSelector((store) => selectExists(store, type, id));

    const pending = usePending(id, false);
    const path = type.split(".").slice(0, 2).join("/");
    const wasFetched = pending === false;

    useFetch({
        type,
        id,
        url: `${path}/:id?expand=true`,
        enable: !disableFetch && (!isDraft || (!wasFetched && !exists)),
    });

    const context = useMemo(
        () => ({
            onSave: props.onSave || onSubmit,
            onSubmit: props.onSubmit || onSubmitAndClose,
            onCancel: props.onCancel || onReject,
            isPending: props.pending || pending === true,
            isDraft,
            isValid: props.isValid || isValid,
        }),
        [onSubmit, onSubmitAndClose, onReject, isValid, isDraft, pending, props]
    );

    const notFound =
        !isDraft && !pending && !exists && type.split(".")[0] !== "app";

    if (pending && !exists && !wasFetched) return <div>Loading...</div>;
    if (notFound) return <div>{type} not found</div>;

    const status = {
        id,
        isDraft,
        pending,
        exists,
        notFound,
        wasFetched,
    };

    // return (
    //     <div>
    //         <pre>{JSON.stringify(status, null, 2)}</pre>
    //     </div>
    // );

    return (
        <AggregateRoot type={type} id={id} disableSubmit={disableSubmit}>
            <FormContext.Provider value={context}>
                {/*<div>*/}
                {/*    <pre>{JSON.stringify(status, null, 2)}</pre>*/}
                {/*</div>*/}
                {FormComponent ? (
                    <FormComponent {...context} {...props} />
                ) : (
                    props.children
                )}
            </FormContext.Provider>
        </AggregateRoot>
    );
}

FormContainer.propTypes = {};

FormContainer.defaultProps = {
    disableFetch: false,
    disableSubmit: true,
    validate: true,
};

export default FormContainer;
