export const entityDelete = (context) => (type, id, list, url) => ({
    type: "ENTITY.DELETE",
    payload: {
        type,
        id,
        list,
        url,
    },
    context,
});

export const entityDeleteCallback =
    (context) =>
    (callback, ...args) => {
        const deleteAction = entityDelete(context)(...args);
        deleteAction.callback = callback;
        return deleteAction;
    };

export const deleteSuccess = (type, id) => ({
    type: "DELETE.SUCCESS",
    payload: {
        type,
        id,
    },
});

export const deleteFailed = (type, id) => ({
    type: "DELETE.FAILED",
    payload: {
        type,
        id,
    },
});

export const deleteSuccessBatch = (resources) => ({
    type: "DELETE.SUCCESS.BATCH",
    payload: resources,
});
