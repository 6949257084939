import React from 'react';
import {connect} from 'react-redux';
import FileManager from "cms/ui/theme/FileManager";
import {pushRouteParams} from "state/actions/router";
import {selectRouteParam} from "state/selectors/router";
import {selectEntity} from "state/selectors/data";
import {change, create, reset} from "state/actions/data";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import {submitOne} from "state/actions/api";
import {isPending} from "state/selectors/ui";
import {compileStyles} from "cms/state/actions/theme";

function isChanged(store, type, id) {
    if (!id) return false;
    if (!store.changes[type]) return false;
    if (!store.changes[type][id]) return false;
    return true;
}

const mapStateToProps = (store, props) => {
    const selected = selectRouteParam(store, 'file');
    const type = 'theme.files';
    return {
        selected: selected,
        id: selected,
        type,
        data: selectEntity(store, type, selected),
        dirty: isChanged(store, type, selected),
        pending: isPending(store, [selected]),
        pendingApply: isPending(store, ['less-render'])
    }
};

const mapDispatchToProps = bindApiActions({
    onSubmit: submitOne
},{
    onSelectFile: pushRouteParams,
    onCreate: create,
    onChange: change,
    onRevert: reset,
    onApply: compileStyles
});

class FileManagerContainer extends React.PureComponent {

    file = (id) => {
        const { onSelectFile } = this.props;
        onSelectFile({file:id});
    };

    change = (field, content) => {
        const { onChange, type, id } = this.props;
        onChange(type, id, {
            content
        });
    };

    revert = () => {
        const { onRevert, type, id } = this.props;
        onRevert(type, id);
    };

    submit = () => {
        const { onSubmit, type, id } = this.props;
        onSubmit(type, id, 'cms/theme/files');
    };

    create = (name) => {
        const { type, onCreate } = this.props;
        onCreate(type, {id: name}, 'default');
    };

    render() {
        const {
            onApply
        } = this.props;

        return (<>
            <FileManager
                {...this.props}
                onSelectFile={this.file}
                onChange={this.change}
                onRevert={this.revert}
                onSubmit={this.submit}
                onApply={onApply}
                onCreate={this.create}
            />
        </>);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(FileManagerContainer));