import React, {useCallback} from "react";
import PropTypes from "prop-types";

import {parseQueryString} from "util/uri";
import useFetch from "state/hooks/useFetch";
import {types} from "joynt/config/types";
import {useNodeData} from "joynt/hooks";
import InternalLink from "./InternalLink";
import {POST_TYPE_POLL, ROUTE_POST} from "joynt/config";
import {useRouter} from "joynt/hooks/channels";
import {useClick, useRouteParam} from "state/hooks";

function nodeType(subtype, post_types) {
    let type = types[subtype];

    if (post_types && post_types.indexOf(POST_TYPE_POLL) > -1) {
        type = {
            id: 'poll',
            icon: 'mui-poll',
            label: 'Poll'
        }
    }

    return type;
}

function nodeIdFromUrl(url) {
    if (!url) return null;
    const params= parseQueryString(url.split('?')[1]);
    const {id, ch, post} = params;
    return post || ch || id;
}

export default function InternalLinkContainer(props) {

    const {link: url} = props;

    const id = nodeIdFromUrl(url);
    const metadataUrl = `joynt/nodes/:id`;
    const pending = useFetch({type: 'db.nodes', id, url: metadataUrl});

    const {name, subtype, created_at, post_types} = useNodeData(id);
    let type = nodeType(subtype, post_types);

    const {onLink} = useRouter();
    const setPost = useRouteParam(ROUTE_POST)[1];
    const click = useClick({onClick: onLink, id});
    const showPost = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setPost(id);
    }, [setPost, id]);

    const onClick = type && type.id === 'poll' ? showPost : click;

    if (!id) return null;

    return <InternalLink
        name={name}
        typeName={type?.label}
        typeIcon={type?.icon}
        createdAt={created_at}
        isPending={pending}
        onClick={onClick}
    />;
}

InternalLinkContainer.defaultProps = {};

InternalLinkContainer.propTypes = {
    link: PropTypes.string.isRequired
};