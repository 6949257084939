import React from "react";
import PropTypes from "prop-types";

import { useUserProfile } from "joynt/hooks";

import AggregateRoot from "containers/AggregateRoot";
import FieldLayout from "joynt/components/Form/FieldLayout";
import Field from "containers/Field";

const flags = [
    { id: "feature_flag_witness", name: "Witness" },
    { id: "feature_flag_tasks", name: "Tasks" },
];

export function Features(props) {
    const { type } = props;
    const { id } = useUserProfile();

    return (
        <AggregateRoot type={type} id={id}>
            {flags.map((flag) => (
                <FieldLayout name={flag.name} description={"Beta feature"}>
                    <Field id={flag.id} fieldType={"checkbox"} />
                </FieldLayout>
            ))}
        </AggregateRoot>
    );
}

Features.propTypes = {
    type: PropTypes.string,
};
Features.defaultProps = {
    type: "db.user-profiles",
};

export default Features;
