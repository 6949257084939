import React from "react";
import cn from "classnames";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

export function AudioButton(props) {
    const { onClick, disabled, on, isPending, size } = props;

    return (
        <IconButton
            withTooltip
            size={size}
            label={on ? "Mute" : "Unmute"}
            icon={on ? "mui-mic" : "mui-mic_off"}
            onClick={!isPending ? onClick : null}
            disabled={disabled}
            isPending={isPending && !disabled}
            className={cn("session-mute", { on: !on })}
        />
    );
}

AudioButton.propTypes = {};
AudioButton.defaultProps = {
    size: "medium",
};

export default AudioButton;
