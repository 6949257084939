import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";
import Button from "components/Button";
import Icon from "components/Icon";
import IconButton from "joynt/components/IconButton";

function GoBackLink({ children, onClick }) {
    const label = children ? <>Back to {children}</> : "Back to Home";

    return (
        <Button
            onClick={onClick}
            variant={"text"}
            startIcon={<Icon>mui-arrow_back</Icon>}
        >
            {label}
        </Button>
    );
}

export function FormHeader(props) {

    const {title, onReject, parentName, children, showBackLink} = props;

    return (<div className={"form-header"}>
        <div className={"form-header__back"}>
            {showBackLink && <GoBackLink onClick={onReject}>{parentName}</GoBackLink>}
        </div>
        <div className={"form-header__content"}>
            <div className={"form-header__title"}>{title}</div>
            {children || null}
        </div>
        <div className={"form-header__close"}>
            <IconButton icon={"mui-close"} onClick={onReject} size={"medium"} />
        </div>
    </div>);

}

FormHeader.propTypes = {
    title: PropTypes.string,
    parentName: PropTypes.string,
    onReject: PropTypes.func,
    showBackLink: PropTypes.bool
};
FormHeader.defaultProps = {
    showBackLink: false
};

export default FormHeader;
