import React from "react";
import Icon from "components/Icon";
import cn from "classnames";
import PropTypes from "prop-types";

import "./style.css";

export default function NotificationStatus(props) {
    const { count, seenCount, onHide, onShow, recipientsWhoHaveSeen } = props;

    if (!seenCount) return null;

    const seenByAll = count <= seenCount;

    return (
        <div
            className={cn("notification-status", seenByAll && "seen")}
            onMouseEnter={(evt) => onShow(evt, recipientsWhoHaveSeen)}
            onMouseLeave={onHide}
        >
            <Icon className={"notification-status__icon"}>mui-visibility</Icon>
            {count > 1 && (
                <p className={"notification-status__count"}>
                    {seenCount}/{count}
                </p>
            )}
        </div>
    );
}

NotificationStatus.propTypes = {
    id: PropTypes.string,
    count: PropTypes.number,
    seenCount: PropTypes.number,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
};
