import React from "react";
//import PropTypes from 'prop-types';
import ResourceForm from "./ResourceForm";
import { FormContainer } from "playground/cms/Form";

export function ResourceFormContainer(props) {
    const {} = props;

    return <FormContainer {...props} FormComponent={ResourceForm} />;
}

ResourceFormContainer.propTypes = {};

ResourceFormContainer.defaultProps = {};

export default ResourceFormContainer;
