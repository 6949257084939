import React from "react";
//import PropTypes from 'prop-types';
import ColorScheme from "./ColorScheme";
import { useActions } from "state/hooks";
import { importColorScheme } from "cms/state/actions/theme";

const colors = ["primary_color", "success_color", "light_color", "dark_color"];

export function ColorSchemeContainer(props) {
    const { onChange, value, entityType, entityId } = props;
    const { onImport } = useActions({ onImport: importColorScheme });

    const handleChange = (id, nextValue) => {
        onChange({
            ...value,
            [id]: nextValue,
        });
    };

    const handleImport = (from) => {
        onImport(from, entityId, entityType);
    };

    return (
        <ColorScheme
            colors={colors}
            onChange={handleChange}
            onImport={handleImport}
            value={value}
        />
    );
}

ColorSchemeContainer.propTypes = {};

ColorSchemeContainer.defaultProps = {
    value: {},
};

export default ColorSchemeContainer;
