import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import CategoryForm from "./CategoryForm";
import { ListLayout } from "playground/cms/List";
import { Search, Tags } from "playground/cms/List/Filter";
import CategoriesTree from "./CategoriesTree";

export function Categories(props) {
    const { type } = props;
    return (
        <Entries {...props}>
            <ListLayout>
                <ListLayout.Filter>
                    <div className={"rows gap-sm"}>
                        <Search type={type} name={"search"} />
                        <Tags type={type} name={"tags"} />
                        <div style={{ height: "800px" }}>
                            <CategoriesTree />
                        </div>
                    </div>
                </ListLayout.Filter>
                <ListLayout.Header>
                    <ListLayout.Header.Icons prepend={true}>
                        <ListLayout.Header.Filter />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
            </ListLayout>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"category"} label={"Kategoria"}>
                        <CategoryForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Categories.propTypes = {};

Categories.defaultProps = {};

export default Categories;
