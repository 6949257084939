import React from 'react';
import {connect} from 'react-redux';

import Quickbook from 'cms/components/Quickbook';
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";

const mapStateToProps = (store, props) => {
    return {
        startDate: selectRouteParam(store, 'checkin'),
        endDate: selectRouteParam(store, 'checkout')
    }
};

const mapDispatchToProps = {
    pushRouteParams: pushRouteParams
};

class QuickbookContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            date_from: props.startDate,
            date_to: props.endDate
        };
    }

    change = (update) => {
        const { pushRouteParams } = this.props;
        this.setState(update);

        const dates = {};

        const date_from = update.date_from || this.state.date_from;
        const date_to = update.date_to;

        if (date_from && date_to) {
            dates.checkin = date_from;
            dates.checkout = date_to;
            pushRouteParams(dates);
        }
    };

    render() {
        return (<Quickbook
            {...this.props}
            startDate={this.state.date_from}
            endDate={this.state.date_to}
            onChange={this.change}
        />);
    }

}

const ConnectedQuickbookContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickbookContainer);

ConnectedQuickbookContainer.autoFillProps = Quickbook.autoFillProps;

export default ConnectedQuickbookContainer;