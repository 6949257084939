export const roles = {
    member: {
        id: 'member',
        label: 'Collaborator',
        description: 'View, post, comment'
    },
    admin: {
        id: 'admin',
        label: 'Admin',
        description: 'Full edit access'
    },
    guest: {
        id: 'guest',
        label: 'Member'
    },
    owner: {
        id: 'owner',
        label: 'Owner',
        description: 'Delete, move, change ownership'
    },
    speaker: {
        id: 'speaker',
        label: 'Speaker'
    },
    moderator: {
        id: 'moderator',
        label: 'Moderator'
    },
    remove: {
        id: 'remove',
        label: 'Remove'
    }
}

export const policies = {
    access: {
        owner: roles.owner,
        admin: roles.admin,
        member: roles.member,
        remove: roles.remove
    },
    event: {
        speaker: roles.speaker,
        moderator: roles.moderator,
        remove: roles.remove
    },
    roles: {
        admin: roles.admin,
        member: roles.member,
        guest: roles.guest
    },
    admins: {
        admin: roles.admin,
        member: roles.member
    }
}

export const accessScopes = {
    access: {
        admin: 'recipient_identities',
        member: 'visible_for'
    },
    event: {
        speaker: 'event_speakers',
        moderator: 'event_moderators'
    }
}