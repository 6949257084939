import React from 'react';
// import PropTypes from 'prop-types';

import {usePublicView} from "joynt/hooks";

import "./style.css";

export function PublicPageToggle(props) {

    const {id, description, buttonLabel} = props;

    const {
        hasPublicView,
        isPublicView,
        onTogglePublicView,
        // workspaceUrl,
        // publicUrl
    } = usePublicView(id);

    if (isPublicView || !hasPublicView) return null;

    return (<div className={"public-view-toggle"}>
        <div className={"public-view-toggle__description"}>
            {description}
        </div>
        <div className={"public-view-toggle__button"} onClick={onTogglePublicView}>
            {buttonLabel}
        </div>
    </div>);

}

PublicPageToggle.propTypes = {};

PublicPageToggle.defaultProps = {
    description: "Your webinar series is published.",
    buttonLabel: "Go to user view"
};

export default PublicPageToggle;