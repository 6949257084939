import React from 'react';
import {SideHelperList, SideHelperSection} from "joynt/components/SideHelperList";
//import PropTypes from 'prop-types';

const data = [
    {
        id: 1,
        title: 'Admin',
        content: 'Admins can edit, move/delete content and channels, as well as ban others or assign new roles, including more admins. They have same permissions as the owner of the workspace.'
    },
    {
        id: 2,
        title: 'Collaborator',
        content: 'Collaborator role can access sections of workspace closed to the public, but holds no admin permissions. Collaborators can also help you test te workspace or event before opening it to the public.'
    }
];

export default function AdminsAndCollaborators(props) {

    return (<SideHelperList
        header={"Admin and collaborator roles"}
        variant={3}
    >
        {data.map(section => <SideHelperSection
            key={section.id}
            title={section.title}
            content={section.content}
        />)}
    </SideHelperList>);

}

AdminsAndCollaborators.propTypes = {};
AdminsAndCollaborators.defaultProps = {};
