import React, { useContext, useState } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames/dedupe";
import { withData } from "containers/Entity";
import { thumbnail } from "util/thumbnail";
import Icon from "components/Icon";
import VisibilitySensor from "react-visibility-sensor/dist/visibility-sensor";
import RenderContext, {
    RENDER_MODE_EDIT,
    RENDER_MODE_THUMB,
} from "cms/context/RenderContext";

export const MediaPlaybackContext = React.createContext({});

export const MediaPlaceholder = React.forwardRef(function (props, ref) {
    const { className, ...other } = props;
    return (
        <div
            className={cn(className, "media-placeholder")}
            ref={ref}
            {...other}
        >
            <div className={"media-placeholder-icon"}>
                <Icon>image</Icon>
            </div>
        </div>
    );
});

export class Image extends React.PureComponent {
    render() {
        const { className, src } = this.props;
        return (
            <img className={className} src={src} alt={src} loading={"lazy"} />
        );
    }
}

const pixelPlaceholder =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export function LazyLoad(props) {
    const [isVisible, changeVisibility] = useState(false);

    if (isVisible) return props.children;

    return (
        <VisibilitySensor onChange={changeVisibility}>
            <div className={props.className}>
                <img src={pixelPlaceholder} alt={"nope"} />
            </div>
        </VisibilitySensor>
    );
}

export function NFHotelImage(props) {
    const { file, className } = props; //size
    let preset = "w500";
    let url = `https://api.nfhotel.pl/imagefly/${preset}/${file}`;
    return <img src={url} alt={file} className={className} />;
}

const VideoLoader = React.memo(function VideoLoader(props) {
    const { src, className, autoPlay } = props;
    const events = useContext(MediaPlaybackContext);
    return (
        <video
            muted={true}
            autoPlay={autoPlay}
            {...events}
            loop={!events.onEnded ? "loop" : false}
            className={cn("video-background-mp4", className)}
        >
            <source src={src} type={"video/mp4"} />
        </video>
    );
});

VideoLoader.defaultProps = {
    autoPlay: false,
};

function VideoType(props) {
    const { file, className, ...other } = props;

    if (file.indexOf("youtube") > -1) {
        return <div>youtube {file}</div>;
    }

    const url = "https://zuucdn.b-cdn.net/zuu-websites/" + file;

    /*return <ReactPlayer
        url={url}
        className={cn("video-background-mp4",className)}
        width={'100%'}
        height={'100%'}
        playing={autoPlay}
        progressInterval={100}
        onProgress={p=>console.log(p)}
        onEnded={()=>alert('ended')}
    />;*/

    return <VideoLoader src={url} className={className} {...other} />;
}

class Media extends React.PureComponent {
    render() {
        const { classes, file, type, style, renderMode } = this.props;

        const size = classes.image_size || "md";
        const thumb =
            renderMode === RENDER_MODE_THUMB
                ? thumbnail(file, [200, 150])
                : thumbnail(file, size);
        const imageClasses = cn("c-img", classes.image);
        const autoPlay = ![RENDER_MODE_EDIT, RENDER_MODE_THUMB].includes(
            renderMode
        );

        //return null;

        if (type === "image" || type === "svg")
            return <Image src={thumb} className={imageClasses} style={style} />;

        if (type === "video") {
            return (
                <VideoType
                    file={file}
                    className={imageClasses}
                    autoPlay={autoPlay}
                />
            );
        }

        if (type === "image-nfhotel") {
            return (
                <LazyLoad className={imageClasses}>
                    <NFHotelImage
                        file={file}
                        className={imageClasses}
                        size={size}
                    />
                </LazyLoad>
            );
        }

        if (type === "link") {
            return <Image src={file} className={imageClasses} style={style} />;
        }

        return <div>media/{type}</div>;
    }
}

Media.autoFillProps = ["file", "type", "url"];

function ProvideRenderMode(props) {
    const mode = React.useContext(RenderContext);
    return <Media {...props} renderMode={mode} />;
}

ProvideRenderMode.autoFillProps = Media.autoFillProps;

export default withData(ProvideRenderMode);

Media.propTypes = {};
