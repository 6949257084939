import { configureStore } from "state";
import { reducers } from "state/reducers";
import coreSagas from "state/sagas";
import cmsSagas from "cms/state/sagas";

function createStore(domains) {
    const { store } = configureStore(
        {},
        {
            reducers,
            sagas: [...coreSagas, ...cmsSagas],
        }
    );
    return store;
}

export const store = createStore();
