import React from "react";
import cn from "classnames";
import Timer from "joynt/components/Timer";
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';

import "./style.css";

function SegmentState(props) {
    const { isActive, isPast, isNext, isLive, hasEnded, state, start } = props;

    if (state === "end") return <div>Finish</div>;
    if (hasEnded) return <div>Ended</div>;
    if (isActive && isLive) return <div>Live now</div>;
    if (isActive)
        return (
            <p className={"session-flow-segment__state"}>
                Starting in{" "}
                <Timer
                    countdown
                    bareValue
                    start={start}
                    humanTimeThreshold={86400}
                />
            </p>
        );
    if (isPast) return <div>Ended</div>;
    if (isNext) return <div>Up next</div>;

    return "Soon";
}

export function Segment(props) {
    const {
        name,
        onClick,
        isActive,
        isLive,
        action,
        started,
        isPast,
        isNext,
        state,
        hasEnded,
        isPending,
        start,
        centered,
    } = props;

    return (
        <div
            onClick={onClick}
            className={cn("session-flow-segment", {
                centered,
                "is-active": isActive && !hasEnded,
                "is-live": isLive,
            })}
        >
            <div className={cn("session-flow-segment__content")}>
                <div className={"session-flow-segment__state"}>
                    <SegmentState
                        {...{
                            isActive,
                            isPast,
                            isNext,
                            isLive,
                            hasEnded,
                            state,
                            start,
                        }}
                    />
                </div>
                {name && (
                    <div className={"session-flow-segment__name"}>
                        {name}
                        {isActive && isLive ? " has started" : null}
                    </div>
                )}
            </div>
            {(isLive || action) && (
                <div className={"session-flow-segment__details"}>
                    {isLive && isActive && (
                        <div className={"session-flow-segment__timer"}>
                            <Timer start={started} />
                        </div>
                    )}
                    {action && (
                        <div className={"session-flow-segment__action"}>
                            {action}
                        </div>
                    )}
                </div>
            )}
            <Preloader visible={isPending} />
        </div>
    );
}

Segment.propTypes = {};
Segment.defaultProps = {};

export default Segment;
