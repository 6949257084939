import React from 'react';
// import PropTypes from 'prop-types';

import Breadcrumbs from "./Breadcrumbs";
import BreadcrumbsLink from "./BreadcrumbsLink";

import {useEdgesData} from "joynt/hooks";
import {useRootNode, useRouter} from "joynt/hooks/channels";

export function BreadcrumbsContainer(props) {

    const {id} = props;
    const {path} = useEdgesData(id);
    const root = useRootNode();

    const nodes = path.slice(path.indexOf(root) + 1).concat([id]);
    const {onLink} = useRouter()

    return (<Breadcrumbs {...props}>
        {nodes.map(n => <BreadcrumbsLink
            key={n}
            id={n}
            onClick={onLink}
            isActive={n === id}
        />)}
    </Breadcrumbs>);

}

BreadcrumbsContainer.propTypes = {};

BreadcrumbsContainer.defaultProps = {};

export default BreadcrumbsContainer;