import React, { useState } from "react";

import ElementsGrid from "./ElementsGrid";

import "./style.css";
import { useSelector } from "react-redux";
import { selectElements } from "./selectors";
import Atomic from "playground/theme/StyleEditor/Atomic";
import StyleSelect from "playground/theme/StyleEditor/StyleSelect";
import { selectEntity } from "state/selectors/data";
import ComponentBreakpoints from "./ComponentBreakpoints";
import { useActions } from "state/hooks";
import { fileSearch } from "cms/state/actions/theme";

function selectParentStyles(store, type, id) {
    const typeMap = {
        "cms.sections": "section",
        "cms.layouts": "page-layout",
    };
    const { element_type, style } = selectEntity(store, type, id);
    const styleType = typeMap[type] || element_type;

    let baseStyle = selectEntity(store, "cms.styles", styleType);
    let parentStyle = style ? selectEntity(store, "cms.styles", style) : null;

    return [baseStyle, parentStyle].filter(Boolean);
}

function getValue(styles, key) {
    if (!styles) return [];
    if (!Object.getOwnPropertyNames(styles).includes(key)) return [];
    return styles[key] || [];
}

export function StyleEditor(props) {
    const {
        entityType,
        entityId,
        id,
        element,
        onSelectElement,
        onChange,
        onEditGlobalStyle,
        value: inputValue,
        header,
        onSearch,
    } = props;

    const [type, style] = id ? id.split(".") : [];
    const value = inputValue || {};

    const parent = useSelector((store) =>
        selectParentStyles(store, entityType, entityId)
    );

    const { defined, definedClassList, available, classList } = useSelector(
        (store) => selectElements(store, id, value)
    );

    const currentElement =
        defined.includes(element) || classList.includes(element)
            ? element
            : definedClassList[0] || classList[0];

    const classes = getValue(value, currentElement);

    const availableOptions = available.filter((o) => !classList.includes(o));

    const handleAddClass = (name) => {
        if (classes.includes(name)) return;
        const next = { ...value };
        next[currentElement] = [...classes, name].sort();
        onChange(next);
    };

    const handleRemoveClass = (name) => {
        if (!classes.includes(name)) return;
        const next = { ...value };
        next[currentElement] = classes.filter((cn) => cn !== name);
        onChange(next);
    };

    const handleChangeClasses = (nextClasses) => {
        const next = { ...value };
        next[currentElement] = nextClasses;
        onChange(next);
    };

    const handleChangeElement = (name) => {
        if (!value[name]) {
            const next = { ...value };
            next[name] = [];
            onChange(next);
        }
        onSelectElement(name);
    };

    const handleChangeOption = (name, optionValue) => {
        const next = { ...value, [name]: optionValue };
        onChange(next);
    };

    const handleRemoveElement = (name) => {
        const next = { ...value, [name]: null };
        onChange(next);
    };

    return (
        <div className={"rows style-editor"}>
            {header && header}
            {entityType && entityId && (
                <div className={"cols gap-sm"}>
                    <div className={"cols grow"}>
                        <StyleSelect
                            type={entityType}
                            id={entityId}
                            onEdit={onEditGlobalStyle}
                        />
                    </div>
                    <ComponentBreakpoints type={entityType} id={entityId} />
                </div>
            )}
            <ElementsGrid
                items={definedClassList}
                allItems={classList}
                onChange={handleChangeElement}
                onRemove={handleRemoveElement}
                selected={currentElement}
            />
            {/*<ClassesInput*/}
            {/*    value={classes}*/}
            {/*    onAdd={handleAddClass}*/}
            {/*    onRemove={handleRemoveClass}*/}
            {/*    inheritance={!!style}*/}
            {/*/>*/}
            <div className={"style-properties atomic"}>
                <Atomic
                    type={type}
                    element={currentElement}
                    options={availableOptions}
                    values={value}
                    classes={classes}
                    onAddClass={handleAddClass}
                    onRemoveClass={handleRemoveClass}
                    onChangeClasses={handleChangeClasses}
                    onChangeOption={handleChangeOption}
                    onSearch={onSearch}
                    style={style}
                    parentStyles={parent}
                />
                {/*<Layout />*/}
            </div>
            {/*<pre>{JSON.stringify(available, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(value, null, 2)}</pre>*/}
            {/*<div>*/}
            {/*    <pre>{JSON.stringify(classList, null, 2)}</pre>*/}
            {/*    <pre>{JSON.stringify(defined, null, 2)}</pre>*/}
            {/*</div>*/}
        </div>
    );
}

StyleEditor.propTypes = {};

StyleEditor.defaultProps = {
    value: {},
};

export default StyleEditor;
