import {useSelector} from "react-redux";
import {selectList} from "state/selectors/data";
import {NODE_TYPE_EVENT, NODE_TYPE_PROJECT, NODE_TYPE_SPACES} from "joynt/config";
import {memoize} from "lodash";

function addToGroup(groups, group, node) {
    if (!groups[group]) groups[group] = {nodes: []};
    groups[group].nodes.push(node);
    return groups;
}

const qualifiers = {
    pinned: n => n.pinned,
    bookmarks: n => n?.edges_ref?.keep,
    projects: n => n.subtype === NODE_TYPE_PROJECT,
    spaces: n => n.subtype === NODE_TYPE_SPACES,
    sessions: (n, parent) => n.subtype === NODE_TYPE_EVENT
        && !n.workspace
        && n?.edges_ref?.stage !== parent
        && n.event_state === "live",
    upcoming: (n, parent) => n.subtype === NODE_TYPE_EVENT
        && !n.workspace
        && n?.edges_ref?.stage !== parent
        && n.event_state !== "live"
        && n.event_state !== "ended"
}

function assignToGroup(n, parent) {
    const keys = Object.keys(qualifiers);
    let match = false;
    for (let i = 0; i < keys.length; i++) {
        match = qualifiers[keys[i]](n, parent);
        if (match) return keys[i];
    }
    return null;
}

function selectGroups(list, items, parent) {
    const nodes = {};
    const ids = [];

    items.forEach(item => {
        ids.push(item.id);
        nodes[item.id] = item
    });

    let groups = {};

    ids.forEach(id => {
        let group = assignToGroup(nodes[id], parent);
        groups = addToGroup(groups, group, id);
    });

    return groups;
}

const selectGroupsMemo = memoize(selectGroups);

function listKey(list, items) {
    return [
        list,
        Math.max(...items.map(item => item.updated_at || item.created_at || 0)),
        items.length
    ].join('|');
}

function filterChannelsByType(list, items, id) {
    const groups = {};
    items.forEach(n => {
        if (!assignToGroup(n, id)) addToGroup(groups, n.subtype, n.id)
    });
    return groups;
}

const filterChannelsByTypeMemo = memoize(filterChannelsByType);

export function selectChannelsByType(store, list, id) {
    const items = selectList(store, 'db.nodes', `db.nodes.${id}.channels`);
    return filterChannelsByTypeMemo(listKey(list, items), items, id);
}

export function selectChannelGroups(store, list, id) {
    const items = selectList(store, 'db.nodes', list);
    return selectGroupsMemo(listKey(list, items), items, id);
}

function selectGroup(key, items, id, group) {
    return items.filter(n => qualifiers[group](n, id)).map(n => n.id);
}

const selectGroupMemo = memoize(selectGroup);

export function selectChannelGroup(store, list, id, group) {
    const items = selectList(store, 'db.nodes', `db.nodes.${id}.channels`);
    const key = [group, listKey(list, items)].join(':');
    return selectGroupMemo(key, items, id, group);
}

export function useChannelGroups(list, id) {
    return useSelector(s => selectChannelGroups(s, list, id));
}