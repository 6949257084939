import React from "react";
import PropTypes from "prop-types";

import AppLayout from "./AppLayout";
import PhoneAppLayout from "./PhoneAppLayout";
import { useSidebar } from "./hooks";
import useScreenSize from "util/hooks/useScreenSize";
import { useIsSessionView } from "joynt/hooks/sessions";

import { useSelector } from "react-redux";
import PageLayout from "./PageLayout";

export function AppLayoutContainer(props) {
    const { isMobile } = useScreenSize();
    const swipeProps = useSidebar({ useSwipe: isMobile });
    const path = useSelector((s) => s.router.location.pathname);

    const isStage = useIsSessionView();

    if (isMobile) {
        return (
            <PhoneAppLayout {...props} {...swipeProps} isStageView={isStage} />
        );
    }

    if (["/page", "/public"].includes(path)) {
        return <PageLayout {...props} />;
    }

    return <AppLayout {...props} {...swipeProps} isStageView={isStage} />;
}

AppLayoutContainer.propTypes = {
    id: PropTypes.string,
};

AppLayoutContainer.defaultProps = {};

export default AppLayoutContainer;
