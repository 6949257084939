import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import LiveSession from "./LiveSession";
import Session from "./Session";
import {useNodeData} from "joynt/hooks";
import {useRouteParam} from "state/hooks";
import {ROUTE_CHANNEL, ROUTE_VIEW, STAGE_VIEW} from "joynt/config";
import {useNodePopover} from "joynt/components/NodeInfo/hooks";
import AvatarGroup from "joynt/components/AvatarGroup";
import {NodeTypeIcon} from "joynt/components/Sidebar/Icons";
import EventTimer from "joynt/components/LiveNow/EventTimer";
import {useSessionState} from "joynt/hooks/sessions";

export function SessionContainer(props) {

    const {id} = props;

    const {name, subtype, event_state, event_start} = useNodeData(id);
    const isLive = event_state === "live";

    const {
        stage,
        isConnected,
        isConnecting,
        onGoToStage,
        presence
    } = useSessionState(id);

    const [routeChannel] = useRouteParam(ROUTE_CHANNEL);
    const [routeView] = useRouteParam(ROUTE_VIEW);

    const {onShow, onHide} = useNodePopover();

    const click = useCallback(() => {
        onHide();
        onGoToStage();
    }, [onGoToStage, onHide]);

    const show = useCallback(e => {
        onShow(id, e.currentTarget)
    }, [onShow, id]);

    const isHighlighted = isConnected;
    const isActive = (routeChannel === stage) && (!routeView || routeView === STAGE_VIEW);

    const itemProps = {
        label: name,
        isHighlighted,
        isActive,
        onClick: click,
    }

    if (!isLive) return <Session
        {...props}
        {...itemProps}
        icon={<NodeTypeIcon type={subtype} />}
        start={event_start}
    />;

    return (<LiveSession
        {...props}
        {...itemProps}
        icon={<NodeTypeIcon type={subtype} />}
        isLive={isLive}
        isConnected={isConnected}
        isConnecting={isConnecting}
        onMouseEnter={show}
        onMouseLeave={onHide}
        avatars={isLive && <AvatarGroup
            ids={presence}
            showEmptyState={true}
        />}
        timer={isLive && <EventTimer id={id} />}
    />);

}

SessionContainer.propTypes = {
    id: PropTypes.string
};
SessionContainer.defaultProps = {};

export default SessionContainer;