import React, { useState } from "react";
import { useActions, useApiActions, useList } from "state/hooks";
import { NodeRoleItem } from "joynt/components/Roles/NodeRoles/NodeRoles";
import { create } from "state/actions/data";
import { entityDelete } from "state/actions/delete";
import uuid from "uuid/v4";
import { submitOne } from "state/actions/api";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import Button from "components/Button";
import Icon from "components/Icon";
import FormActions from "joynt/components/Form/FormActions";

//import PropTypes from 'prop-types';

function NodeRoleEdit(props) {
    const { id, onCancel } = props;
    const { onSubmit } = useApiActions({
        onSubmit: submitOne,
    });
    const submit = () => {
        onSubmit("db.node-roles", id);
        onCancel();
    };

    return (
        <div className={"rows gap-md"}>
            <AggregateRoot type={"db.node-roles"} id={id}>
                <Field id={"name"} fieldType={"text"} />
            </AggregateRoot>
            <FormActions onReject={onCancel} onSubmit={submit} />
        </div>
    );
}

export function ManageNodeRoles(props) {
    const { id, list } = props;
    const type = "db.node-roles";

    const [edit, setEdit] = useState(null);

    const { items } = useList(type, list);

    const { onCreate } = useActions({
        onCreate: create,
    });
    const { onRemove } = useApiActions({
        onRemove: entityDelete,
    });

    const add = () => {
        let data = { id: uuid(), type: "node-roles", node: id };
        onCreate(type, data, list);
        setEdit(data.id);
    };

    const removeRole = (id) => {
        onRemove(type, id, null, `joynt/node-roles/:id`);
    };

    if (edit) return <NodeRoleEdit id={edit} onCancel={() => setEdit(false)} />;

    return (
        <div className={"rows gap-md"}>
            <div className={"cols cols-spread"}>
                <div>Workspace roles</div>
            </div>
            <div className={"rows gap-xs"}>
                {items.map((item) => (
                    <NodeRoleItem
                        key={item}
                        id={item}
                        onClick={setEdit}
                        onRemove={removeRole}
                    />
                ))}
            </div>
            <div className={"cols cols-right"}>
                <div>
                    <Button
                        onClick={add}
                        variant={"text"}
                        endIcon={<Icon>mui-add_circle</Icon>}
                    >
                        Create
                    </Button>
                </div>
            </div>
        </div>
    );
}

ManageNodeRoles.propTypes = {};
ManageNodeRoles.defaultProps = {};

export default ManageNodeRoles;
