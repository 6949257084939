import React from "react";
import FormActions from "joynt/components/Form/FormActions";
import Field from "containers/Field";
import TextInput from "joynt/components/messages/message-form/TextInput";

//import PropTypes from 'prop-types';

export function WitnessMessage(props) {
    const { onSetAction } = props;

    return (
        <div>
            <div style={{ width: "300px" }}>
                <Field
                    id={"message"}
                    placeholder={"Write your reasons"}
                    FieldComponent={TextInput}
                />
            </div>
            <FormActions
                submitLabel={"Send message"}
                onSubmit={() => alert("message")}
                rejectLabel={"Cancel"}
                onReject={() => onSetAction(null)}
            />
        </div>
    );
}

WitnessMessage.propTypes = {};
WitnessMessage.defaultProps = {};

export default WitnessMessage;
