import React from 'react';
import {connect} from 'react-redux';

import UserInfo from "components/user-menu/UserInfo";
import {logout} from "state/actions/auth";
import {selectAliasedEntity} from "state/selectors/data";
import version from "util/version";

const mapStateToProps = (store, props) => {
    return {
        data: selectAliasedEntity(store, 'db.users', '@currentUser'),
        appVersion: version()
    }
};

const mapDispatchToProps = {
    onLogout: logout
};

class UserInfoContainer extends React.Component {

    render() {
        return (<UserInfo {...this.props} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfoContainer);