import React from 'react';
// import PropTypes from 'prop-types';
import MultiStepForm from "joynt/edit/MultiStepForm";
import Field from "containers/Field";
import {CreateForm} from "./create";
import Roles from "joynt/components/Roles";
import {ManageSessionAccess} from "joynt/edit/types/Session/edit";
import CategorySelect from "joynt/edit/CategorySelect";
import {accessTypes} from "joynt/config/sessions";

function AccessSettings(props) {
    return <>
        {/*<Field id={"access_type"}>*/}
        {/*    {({ onChange, value }) => (*/}
        {/*        <CategorySelect*/}
        {/*            onChange={onChange}*/}
        {/*            value={value || "open"}*/}
        {/*            options={Object.values(accessTypes)}*/}
        {/*        />*/}
        {/*    )}*/}
        {/*</Field>*/}
        <Roles {...props} />
    </>
}

const createSteps = {
    step1: {
        component: CreateForm,
        label: "Create",
        title: "Create webinar series",
        submit: true
    },
    // access: {
    //     component: AccessSettings,
    //     label: "Access",
    // },
};
const editSteps = {
    edit: {
        component: CreateForm,
        label: "Settings",
        title: "Webinar series settings"
    },
    access: {
        component: AccessSettings,
        label: "Manage members",
        disableActions: true,
    },
};

export function WebinarSeries(props) {

    const { isDraft } = props;

    return (
        <MultiStepForm
            {...props}
            steps={isDraft ? createSteps : editSteps}
            showHeader={false}
        />
    );

}

WebinarSeries.propTypes = {};

WebinarSeries.defaultProps = {};

export default WebinarSeries;