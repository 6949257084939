import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Value(props) {
    return (
        <>
            {props.children}
            <Field id={"value"} label={"Value"} fieldType={"text"} />
        </>
    );
}

Value.propTypes = {};

Value.defaultProps = {};

export default Value;
