import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";

export function RoomStage(props) {

    const {children} = props;

    return (<div className={"room-stage"}>
        {children}
    </div>);

}

RoomStage.propTypes = {};
RoomStage.defaultProps = {};

export default RoomStage;
