import React, {useCallback} from 'react';
//import PropTypes from 'prop-types';

import StageStatusMessage from "joynt/components/SessionFlow/StageStatusMessage";
import StageCover from "joynt/components/SessionFlow/StageCover";
import {useApiActions, usePending} from "state/hooks";
import {giveBadge} from "./../../actions";
import uuid from "uuid/v4";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useIdentity} from "joynt/hooks/participants";
import {selectCurrentUserId} from "state/selectors/app";
import {useSelector} from "react-redux";

import "./style.css";
import BadgeButton from "joynt/components/Witness/SessionViews/WitnessBadge/BadgeButton";
import BadgeReceived from "joynt/components/Witness/SessionViews/WitnessBadge/BadgeReceived";

function selectBadge(store, session, forUser) {
    const user = forUser || selectCurrentUserId(store);
    const badges = store.data['db.badges'] || {};
    return Object.values(badges).filter(data => {
        return data.user === user && data.origin === session;
    }).map(data => data.id)[0];
}

export function WitnessBadge(props) {

    const {
        isSpeaker,
        isPending,
        message,
        peer,
        speaker,
        offline,
        onProgress
    } = props;

    const {session} = useMeeting();
    const {created_by: peerUser} = useIdentity(peer);

    const {onGiveBadge} = useApiActions({
        onGiveBadge: giveBadge
    });

    const sendBadge = useCallback((value) => {
        onGiveBadge({
            id: uuid(),
            value,
            origin: session,
            user: peerUser
        })
    }, [onGiveBadge, peerUser, session]);

    const badgeId = useSelector(s => selectBadge(s, session));
    const peerBadge = useSelector(s => selectBadge(s, session, peerUser));
    const pendingBadge = usePending(`badge.${peerUser}`);

    const pending = pendingBadge || isPending;

    if (badgeId && peer === speaker)
        return <BadgeReceived
            offline={offline}
            isPending={pending}
            onProgress={onProgress}
        />

    if (!isSpeaker && badgeId) {
        return <BadgeReceived
            offline={offline}
            isPending={pending}
            onProgress={onProgress} />
    }

    if (!isSpeaker) {
        return <StageStatusMessage
            {...props}
        />
    }

    const badgeValues = [0, 0.25, 0.5, 0.75, 1];

    return (<StageCover
        transparent={!offline}
        title={message}
        layout={"centered"}
        variant={offline ? "landscape" : "default"}
        isPending={pending}
    >
        {!peerBadge ? <div>
            <div className={"cols cols-center gap-sm"}>
                {badgeValues.map(value => {
                    return <BadgeButton
                        key={value}
                        value={value}
                        onClick={()=>sendBadge(value)}
                    />
                })}
            </div>
        </div> : <div>Thanks!</div>}
    </StageCover>);

}

WitnessBadge.propTypes = {};
WitnessBadge.defaultProps = {};

export default WitnessBadge;
