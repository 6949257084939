import React from "react";

export function ArrayIterator(ItemRenderer) {
    return function({items, reverse, ...other}) {
        return reverse ? items.map((item, index)=><ItemRenderer
            {...other}
            key={item}
            id={item}
            index={index}
        />).reverse() : items.map((item, index)=><ItemRenderer
            {...other}
            key={item}
            id={item}
            index={index}
        />);
    }
}