import Text from "cms/components/Text";
import Button from "cms/components/Button";
import Section from "cms/Section";
import List from "cms/containers/List";
import Layout from "cms/components/Layout";
import Nav from "cms/components/Nav";
import Card from "cms/components/Card";
import MediaElement from "cms/components/MediaElement";
import Form from "cms/components/Form";
import Template from "cms/components/Template";
import Quickbook from "cms/containers/Quickbook";
import Value from "cms/components/Value";
import Icon from "cms/components/Icon";
import Query from "cms/containers/Query";
import FormInput from "cms/components/FormInput";
import FormSelect, { FormSelectOption } from "cms/components/FormSelect";
import Symbol from "cms/components/Symbol";
import Context from "cms/containers/Context";
import Plan from "cms/components/Plan";
import Link from "cms/components/Link";

export const NS_COMPONENTS = "cms.components";
export const NS_MEDIA = "cms.media";

export const types = {
    text: Text,
    button: Button,
    section: Section,
    list: List,
    structured: Layout,
    layout: Layout,
    nav: Nav,
    card: Card,
    media: MediaElement,
    form: Form,
    template: Template,
    quickbook: Quickbook,
    value: Value,
    icon: Icon,
    query: Query,
    symbol: Symbol,
    "form-input": FormInput,
    "form-select": FormSelect,
    "form-select-option": FormSelectOption,
    context: Context,
    plan: Plan,
    link: Link,
};
