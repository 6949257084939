import React from "react";
import PropTypes from "prop-types";

import Field from "containers/Field";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Card from "joynt/components/Card";
import ButtonTrigger from "components/fields/media/ButtonTrigger";
import { useData, useEntity } from "state/hooks";
import { abbreviation } from "joynt/util";

const nullFn = () => null;

const VariantData = {
    event: {
        namePlaceholder: "Event name",
        durationPlaceholder: "Event duration",
    },
    workspace: {
        namePlaceholder: "Workspace name",
        durationPlaceholder: null,
    },
};

export function CardAppearance(props) {
    const { icon, label, variant, showCard } = props;
    const { id, type } = useEntity();
    const { name } = useData({ type, id });

    return (
        <FieldGroup label={label}>
            <div className={"rows gap-md"}>
                <div className={"cols cols-top gap-md"}>
                    <Field
                        id={"color"}
                        fieldType={"ui-color-picker"}
                        label={"Choose color"}
                    />
                    {icon && (
                        <div className={"cols gap-xs"}>
                            <div style={{ width: "100px" }}>
                                <Field
                                    id={"workspace_abbr"}
                                    fieldType={"text"}
                                    label={abbreviation(name, 3)}
                                    validate={true}
                                    helperText={"1-3 letters"}
                                />
                            </div>
                        </div>
                    )}
                    {icon && (
                        <div className={"cols gap-xs"}>
                            <Field
                                id={"icon"}
                                fieldType={"icon"}
                                label={"Choose icon"}
                            />
                        </div>
                    )}
                </div>
                {showCard && (
                    <Card
                        id={id}
                        className={"h-xs"}
                        onClick={nullFn}
                        onPreview={nullFn}
                        namePlaceholder={VariantData[variant]?.namePlaceholder}
                        durationPlaceholder={
                            VariantData[variant]?.durationPlaceholder
                        }
                        info={
                            "For best results use an image size of at least 900 x 600px (with a 3:2 aspect ratio)"
                        }
                        tileImageOverlay={
                            <div
                                className={"fill rows rows-middle rows-center"}
                            >
                                <Field
                                    id={"media"}
                                    label={"Image"}
                                    fieldType={"upload"}
                                    trigger={ButtonTrigger}
                                />
                            </div>
                        }
                    />
                )}
            </div>
        </FieldGroup>
    );
}

CardAppearance.propTypes = {
    icon: PropTypes.bool,
    label: PropTypes.string,
};
CardAppearance.defaultProps = {
    icon: false,
    label: "Add image and color",
    variant: "event",
};
