import React from "react";
import SuccessCheck from "animations/SuccessCheck";
import { CircularProgress } from "@material-ui/core";

//import PropTypes from 'prop-types';

export function StatusImage({ status }) {
    if (status === "success") return <SuccessCheck />;
    return <CircularProgress />;
}

StatusImage.propTypes = {};

StatusImage.defaultProps = {};

export default StatusImage;
