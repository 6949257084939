import { NS_SCHEMA, NS_SCHEMA_FIELDS } from "state/selectors/schema";
import { selectEntity } from "state/selectors/data";
import { parseZmap, processZmap } from "playground/designer/Zmap/zmap";

function uiTypeToDomainType(uiType) {
    const [prefix, type, subtype] = uiType.split(".");
    return [type, subtype];
}

function expandObject(obj) {
    const result = {};

    Object.keys(obj).forEach((key) => {
        // Split the key based on the dot notation to get individual property names
        const keys = key.split(".");
        // Start with the root of our result object
        let current = result;

        // Process each part of the key except the last one for nested objects
        for (let i = 0; i < keys.length - 1; i++) {
            const part = keys[i];
            // If the part doesn't exist, create it as an empty object
            if (!current[part] || typeof current[part] !== "object")
                current[part] = {};
            // Move our reference down to the nested object
            current = current[part];
        }

        // Set the value at the end of the path
        const lastKey = keys[keys.length - 1];
        current[lastKey] = obj[key];
    });

    return result;
}

function expandFieldTypes(store, schemaType, fieldReducer) {
    const fields = schemaType?.field_index || {};
    const typed = Object.keys(fields).reduce((acc, field) => {
        const fieldId = fields[field];
        const fieldDef = store.data?.[NS_SCHEMA_FIELDS]?.[fieldId];
        // {
        //     name: fieldDef?.label,
        //         type: fieldDef?.type,
        // }
        if (fieldReducer) {
            let processedFieldDef = fieldReducer(fieldDef);
            if (processedFieldDef) acc[field] = fieldReducer(fieldDef);
        } else {
            acc[field] = fieldDef?.type;
        }
        return acc;
    }, {});
    return expandObject(typed);
}

export function selectTypeSchema(store, typePath, fieldReducer) {
    const types = store?.data?.[NS_SCHEMA] || {};
    const [type, subtype] = uiTypeToDomainType(typePath);
    const typeId = Object.keys(types).find((id) => {
        return types[id].slug === type;
    });
    if (!subtype) {
        return expandFieldTypes(store, types[typeId], fieldReducer);
    }
    const subtypeId = Object.keys(types).find((id) => {
        return types[id].slug === subtype && types[id].subtype_of === typeId;
    });
    return expandFieldTypes(store, types[subtypeId], fieldReducer);
}

function selectComponent(store, id) {
    const result = selectEntity(store, "cms.components", id);
    if (!result.id) return null;
    return result;
}

function traverseUpwards(store, component, reducer, initialValue) {
    let result = initialValue;
    let currentComponent = component;

    while (currentComponent) {
        // Apply the reducer function to the current result and component
        result = reducer(result, currentComponent);

        // Move up to the parent component if it exists
        if (currentComponent.__parent) {
            currentComponent = selectComponent(
                store,
                currentComponent.__parent.id
            );
        } else {
            // No more parents, break the loop
            break;
        }
    }

    return result;
}

function zmap(mapping, context) {
    return processZmap(parseZmap(mapping), context);
}

function listContext(store, context, component) {
    const items =
        zmap(component.template_id, context)?.items || component.items || [];
    return {
        id: component.id,
        entry_type: component.entry_type,
        context: {
            local: items[0],
        },
    };
}

function queryContext(store, context, component) {
    const ns = component.alias ? component.alias : "query";
    const type = ["cms.entries", component.entry_type].join(".");
    return {
        id: component.id,
        entry_type: type,
        context: {
            global: {
                [ns]: {
                    "#schema": "cms.components.query",
                    ...component,
                    items: [
                        {
                            "#schema": type,
                            //...selectTypeSchema(store, type),
                        },
                    ],
                },
            },
        },
    };
}

function providedContext(store, context, component) {
    const type = component.element_type;
    if (type === "list") return listContext(store, context, component);
    if (type === "query") return queryContext(store, context, component);
    return null;
}

function mergeContext(acc, ctx) {
    return {
        ...acc,
        ...ctx,
        local: { ...acc.local, ...(ctx?.local || {}) },
        global: { ...acc.global, ...(ctx?.global || {}) },
    };
}

export function selectContext(store, rootContext, type, id) {
    const component = selectEntity(store, type, id);
    const components = traverseUpwards(
        store,
        component,
        (acc, component) => {
            return [component, ...acc];
        },
        []
    );
    return components.reduce((acc, component) => {
        const context = providedContext(store, acc, component);
        if (!context) return acc;
        return mergeContext(acc, context?.context || {});
    }, rootContext);
}
