import React from "react";
import Icon from "components/Icon";
import PropTypes from "prop-types";

export function FieldLayout(props) {
    const { name, icon, description, children } = props;
    return (
        <div className={"cols cols-middle cols-spread gap-md"}>
            <div className={"cols gap-sm cols-middle"}>
                {icon ? (
                    <div className={"icon-lg o-50"}>
                        <Icon fontSize={"large"}>{icon}</Icon>
                    </div>
                ) : null}
                <div className={"rows rows-left"}>
                    <div className={"type-default"}>{name}</div>
                    {description ? (
                        <div className={"type-sm o-50"}>{description}</div>
                    ) : null}
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
}

FieldLayout.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
};
FieldLayout.defaultProps = {};

export default FieldLayout;
