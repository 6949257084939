import React from 'react';
import PropTypes from 'prop-types';

export default class Price extends React.PureComponent {

    render() {
        const { price } = this.props;
        return price + ' zł';
    }

}

Price.propTypes = {
    price: PropTypes.number
};