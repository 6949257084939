import React from "react";

import SessionCoverTile from "./SessionCoverTile";
import { useNodeData } from "joynt/hooks";
import { useCover } from "state/hooks";
import { SESSION_TYPE_WEBINAR_SERIES } from "joynt/config/sessions";
import { useSession } from "joynt/hooks/sessions";

export default function SessionCoverTileContainer(props) {
    const { id } = props;

    const {
        can_edit,
        event_ended: finished,
        event_started: started,
        event_state,
        media,
        name,
        session_type,
    } = useNodeData(id);

    const image = useCover(media);
    const { onEdit } = useSession(id);

    return (
        <SessionCoverTile
            {...props}
            finished={finished}
            image={image}
            isLive={event_state === "live"}
            onEdit={can_edit ? onEdit : null}
            showControls={session_type !== SESSION_TYPE_WEBINAR_SERIES}
            started={started}
            title={name}
        />
    );
}
