import React from "react";

import { MessagePropTypes } from "./MessagePropTypes";

import {
    ChannelMessage,
    ChatMessage,
    PopupMessage,
    SidebarMessage,
} from "./modes";

import "./style.css";

export default function Message(props) {
    const { mode } = props;

    if (!mode) return <ChannelMessage {...props} />;

    const modes = {
        channel: ChannelMessage,
        chat: ChatMessage,
        popup: PopupMessage,
        sidebar: SidebarMessage,
    };

    return modes[mode](props);
}

Message.propTypes = MessagePropTypes;
