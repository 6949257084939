import React, {useCallback} from 'react';
import {useRouteParam} from "state/hooks";
import Dialog from "@material-ui/core/Dialog";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
//import PropTypes from 'prop-types';

export default function MediaEdit(props) {

    const [media, setMedia] = useRouteParam('media');
    const close = useCallback(()=>setMedia(null), [setMedia]);

    return <Dialog open={!!media} onClose={close}>
        <AggregateRoot type={'cms.media'} id={media}>
            <div className={'rows gap-sm pad-md'}>
                <Field id={'alt'} label={'Alternate text'} />
                <Field id={'title'} label={'Title'} />
            </div>
        </AggregateRoot>
    </Dialog>;

}

MediaEdit.propTypes = {};
MediaEdit.defaultProps = {};
