import React, { useMemo } from "react";
//import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import Avatar from "joynt/components/SessionPage/Networking/Avatar";

import "./style.css";

const nullObj = {};

export function OffStageParticipants(props) {
    const { id } = props;
    const presence = useSelector((store) => store.presence[id]) || nullObj;
    const session = presence.session || nullObj;

    const connected = useMemo(() => {
        const ids = Object.keys(session);
        return ids.filter((i) => session[i]?.connected);
    }, [session]);

    return (
        <div className={"off-stage-participants"}>
            {connected.map((p) => (
                <Avatar id={p} />
            ))}
        </div>
    );
}

OffStageParticipants.propTypes = {};

OffStageParticipants.defaultProps = {};

export default OffStageParticipants;
