import React from 'react';
//import PropTypes from 'prop-types';
import {Controller,Scene} from 'scrollmagic';

const scenesConfig = [
    {
        selector: '.section-header',
        duration: 355,
        triggerHook: 'onLeave'
    },
    {
        selector: '.website-scroll',
        duration: 3000,
        triggerHook: 'onLeave',
        onProgress: function(progress, doc) {
            const screenshot = doc.querySelector('.website-screenshot-image');
            let offset = 0-(progress*1400);
            if (!screenshot) return;
            screenshot.style.transform = 'translateY('+offset+'px)';
        }
    }
];

function configureScenes(doc, controller, config) {
    config.forEach(sceneConfig => {
        let element = doc.querySelector(sceneConfig.selector);
        let scene = new Scene({
            duration: sceneConfig.duration,
            triggerElement: element,
            triggerHook: sceneConfig.triggerHook
        })
            .setPin(element, {pushFollowers: sceneConfig.pushFollowers})
            .addTo(controller);
        if (sceneConfig.onProgress)
            scene.on('progress', (e)=>sceneConfig.onProgress(e.progress, doc));
    })
}

export default class ScrollManager extends React.PureComponent {

    setup() {
        const shadow = document.getElementById('theme-shadow-root');

        const doc = shadow
            ? shadow.shadowRoot
            : document;

        const options = shadow
            ? {container: doc.querySelector('.ide-page')}
            : {};

        if (this.controller) this.controller.destroy(true);

        this.controller = new Controller(options);

        configureScenes(doc, this.controller, scenesConfig);
    }

    componentDidMount() {
        setTimeout(this.setup, 300);
    }

    render() {
        return this.props.children;
    }

}

ScrollManager.propTypes = {};