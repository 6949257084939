import {PAGE_PREFIX_CREATE, ROUTE_CHANNEL, ROUTE_PAGE, ROUTE_THREAD} from "joynt/config";
import {useActions, useApiActions, useRouteParam} from "state/hooks";
import {entityDeleteCallback} from "state/actions/delete";
import {useCallback} from "react";
import {useRouter} from "./useRouter";
import {useEditPage} from "joynt/hooks/channels/useEditPage";
import {createNode} from "joynt/state/actions/nodes";
import {useNodePage} from "joynt/hooks/channels/useNodePage";

export function useChannels(parent, list, defaultType, param = ROUTE_PAGE) {

    const [channel] = useRouteParam(ROUTE_CHANNEL);
    const [post] = useRouteParam(ROUTE_THREAD);
    const selected = post || channel;
    const {onLink: link} = useRouter();

    const showEdit = useEditPage(param);
    const {onShow: showCreate} = useNodePage(PAGE_PREFIX_CREATE);

    const {
        onCreate
    } = useActions({
        onCreate: createNode
    });

    const {
        onDelete
    } = useApiActions({
        onDelete: entityDeleteCallback
    });

    const create = useCallback((data = {}) => {
        onCreate(showCreate, parent, list, data);
    }, [onCreate, showCreate, parent, list]);

    const remove = useCallback((id) => {
        const cb = () => {
            showEdit(null);
            if (selected === id) link(parent);
        }
        onDelete(cb, 'db.nodes', id, null, `joynt/nodes/:id`);
    }, [onDelete, link, selected, showEdit, parent]);

    return {
        selected,
        onCreate: create,
        onDelete: remove,
        onShow: link,
        onEdit: showEdit
    };

}