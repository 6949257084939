import React from "react";
import { useData } from "state/hooks";
import { PropertyPopover } from "./PropertyPopover";
import Field from "containers/Field";

//import PropTypes from 'prop-types';

function renderOfferPrice(data = {}, currency = "zł") {
    let result = [];
    const { price_base, price, price_unit } = data;
    if (price_base) {
        result.push(
            `<span class="line-through">${price_base} ${currency}</span>`
        );
    }
    if (price) result.push(price + ` ${currency}`);
    if (price_unit) result.push(price_unit);
    return result.join(" ");
}

export function OfferPrice(props) {
    const data = useData(props);
    const value = renderOfferPrice(data);

    return (
        <PropertyPopover label={"Cena"} placeholder={"Ustaw"} value={value}>
            <div className={"pad-md rows gap-sm"}>
                <Field
                    id={"price"}
                    fieldType={"text"}
                    label={"Cena"}
                    size={"small"}
                    InputProps={{ endAdornment: <div>zł</div> }}
                />
                <Field
                    id={"price_base"}
                    fieldType={"text"}
                    label={"Cena bez rabatu"}
                    size={"small"}
                    InputProps={{ endAdornment: <div>zł</div> }}
                />
                <Field
                    id={"price_unit"}
                    fieldType={"text"}
                    label={"Jednostka (np / os.)"}
                    size={"small"}
                />
            </div>
        </PropertyPopover>
    );
}

OfferPrice.propTypes = {};

OfferPrice.defaultProps = {};

export default OfferPrice;
