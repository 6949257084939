import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';
import Fade from "@material-ui/core/Fade";

export default function FeedEmptyState(props) {
    const { text, image } = props;

    return (
        <Fade in={true}>
            <div className={"feed-empty-state"}>
                {image ? (
                    <picture
                        className={cn("empty-state-illustration", image)}
                    />
                ) : null}
                <div className={"feed-empty-state__heading"}>{text}</div>
            </div>
        </Fade>
    );
}

FeedEmptyState.propTypes = {};
FeedEmptyState.defaultProps = {
    text: "No posts here yet",
};
