import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
//import PropTypes from 'prop-types';

import "joynt/css";
import themeConfig from "joynt/config/mui-theme";
const muiTheme = createTheme(themeConfig);

export default function Theme(props) {
    const { children } = props;

    return <ThemeProvider theme={muiTheme}>{children || null}</ThemeProvider>;
}

Theme.propTypes = {};
Theme.defaultProps = {};
