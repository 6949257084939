import { useSelector } from "react-redux";
import { selectList } from "state/selectors/data";

function selectDirectoryTree(store) {
    const allDirs = selectList(store, "cms.media.directories", "default");
    const tree = allDirs.reduce(
        (tree, item) => {
            const path = item.path.split("/");
            let current = tree;
            path.forEach((dir, index) => {
                if (dir === "") return;
                if (!current.children) current.children = [];
                let node = current.children.find((node) => node.id === dir);
                if (!node) {
                    node = {
                        id: dir,
                        path: path.slice(0, index).concat([dir]).join("/"),
                    };
                    current.children.push(node);
                }
                current = node;
            });
            return tree;
        },
        { id: "root", path: "", children: [] }
    );
    const libraries = selectList(store, "cms.media.libraries", "default");
    return libraries.map((library) => {
        if (library.id === "files") {
            return { ...library, children: tree.children };
        }
        return library;
    });
}

export function useDirectories() {
    return useSelector((store) => selectDirectoryTree(store));
}
