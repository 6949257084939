import React from "react";
import DefaultHeader from "joynt/components/SessionHeader";
import { useSessionLayout } from "joynt/components/SessionLayout";
import Button from "components/Button";
import Icon from "components/Icon";
import useScreenSize from "util/hooks/useScreenSize";

//import PropTypes from 'prop-types';

export function SessionHeader(props) {
    const { onToggle } = useSessionLayout();
    const { isMobile } = useScreenSize();

    return (
        <DefaultHeader
            {...props}
            centered={!isMobile}
            avatar={null}
            back={
                <Button
                    onClick={onToggle}
                    startIcon={<Icon>mui-arrow_back</Icon>}
                >
                    Back to tables
                </Button>
            }
        />
    );
}

SessionHeader.propTypes = {};
SessionHeader.defaultProps = {};

export default SessionHeader;
