import React, { useCallback } from "react";
import FieldGroup from "joynt/components/Form/FieldGroup";
import Field from "containers/Field";
import SelectField from "components/fields/select/Select";
import { useEdges } from "joynt/hooks";
import { useActions } from "state/hooks";
import { change } from "state/actions/data";
import { useSelector } from "react-redux";
import { selectExtendableWorkspaces } from "joynt/state/selectors/joint";
//import PropTypes from 'prop-types';

export default function ParentWorkspace(props) {
    const { id } = props;
    const edges = useEdges(id);
    const { parent_workspace: value } = edges;
    //const workspaces = useWorkspaces(null);
    const workspaces = useSelector((s) => selectExtendableWorkspaces(s));
    const { onChange } = useActions({ onChange: change });
    const changeParent = useCallback(
        (newParent) => {
            onChange(
                "db.nodes",
                id,
                {
                    parent: newParent,
                    edges_ref: {
                        ...edges,
                        parent: newParent,
                        parent_workspace: newParent,
                    },
                },
                true
            );
        },
        [onChange, edges, id]
    );
    return (
        <FieldGroup label={"Choose where your sub-workspace will be nested"}>
            <Field id={"parent"} validate={true}>
                {({ error, helperText }) => (
                    <SelectField
                        id={"parent"}
                        type={"db.nodes"}
                        label={"Select parent workspace"}
                        items={workspaces}
                        value={value}
                        onChange={changeParent}
                        error={error}
                        helperText={helperText}
                    />
                )}
            </Field>
        </FieldGroup>
    );
}

ParentWorkspace.propTypes = {};
ParentWorkspace.defaultProps = {};
