import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Icon(props) {
    return (
        <>
            {props.children}
            <Field id={"icon"} label={"Icon"} fieldType={"icon"} />
        </>
    );
}

Icon.propTypes = {};

Icon.defaultProps = {};

export default Icon;
