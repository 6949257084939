import React from "react";

import {
    useSessionFlowSchedule,
    useSetSessionFlowSegment,
} from "joynt/components/SessionFlow/hooks";
import Button from "components/Button";
import { useSessionState } from "joynt/hooks/sessions";
import { useNodeData } from "joynt/hooks";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';
import "./style.css";

import { useSessionLayout } from "joynt/components/SessionLayout";
import { usePending } from "state/hooks";
import Segment from "./Segment";

function SessionConnected() {
    return <div>You're in session</div>;
}

function StartSegment(props) {
    const { session } = props;
    const { event_start: start } = useNodeData(session);

    const { isConnected } = useSessionState(session);

    const { onToggle } = useSessionLayout();

    return (
        <Segment
            {...props}
            start={start}
            onClick={isConnected ? onToggle : null}
        />
    );
}

function NetworkingSegment(props) {
    const { onSetSegment, isLive, isAdmin, isNext, id } = props;

    let action =
        isAdmin && isLive && isNext ? (
            <Button onClick={() => onSetSegment(id)} variant={"outlined"}>
                Start now
            </Button>
        ) : null;

    return <Segment {...props} action={action} />;
}

function WrapupSegment(props) {
    const { id, isNext, session, next, isActive, isAdmin, onSetSegment } =
        props;

    const { onJoinSession, isConnected } = useSessionState(session);

    const { onToggle } = useSessionLayout();

    let action = null;

    if (isConnected && isActive) action = <SessionConnected />;

    if (isNext && isAdmin)
        action = (
            <Button onClick={() => onSetSegment(id)} variant={"outlined"}>
                Start session
            </Button>
        );

    if (isActive && !isConnected)
        action = <Button onClick={onJoinSession}>Join session</Button>;

    if (isActive && isConnected && isAdmin)
        action = (
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    onSetSegment(next);
                }}
                variant={"outlined"}
            >
                End session
            </Button>
        );

    return (
        <Segment
            {...props}
            onClick={isConnected ? onToggle : null}
            action={<div>{action}</div>}
        />
    );
}

function EndSegment(props) {
    const { isAdmin, isLive, session } = props;
    const { onEndSession } = useSessionState(session);
    return (
        <Segment
            {...props}
            isLive={false}
            action={
                isAdmin &&
                isLive && (
                    <Button onClick={onEndSession} size={"large"}>
                        Close tables
                    </Button>
                )
            }
        />
    );
}

function SingleSegment(props) {
    const { session, isAdmin, isLive, onSetSegment } = props;
    const {
        onJoinSession,
        onStartSession,
        isConnected,
        hasEnded,
        onResetSession,
    } = useSessionState(session);
    return (
        <Segment
            {...props}
            centered
            action={
                <>
                    {isAdmin && hasEnded && (
                        <Button onClick={onResetSession} size={"large"}>
                            Schedule again
                        </Button>
                    )}
                    {isAdmin && !isLive && !hasEnded && (
                        <Button onClick={onStartSession} size={"large"}>
                            Start session
                        </Button>
                    )}
                    {isAdmin && isLive && (
                        <Button
                            onClick={() => onSetSegment("end")}
                            variant={"outlined"}
                            size={"large"}
                        >
                            End session
                        </Button>
                    )}
                    {isLive && !isConnected && (
                        <Button onClick={onJoinSession} size={"large"}>
                            Join session
                        </Button>
                    )}
                </>
            }
        />
    );
}

const itemTypes = {
    start: StartSegment,
    networking: NetworkingSegment,
    "wrap-up": WrapupSegment,
    "after-party": EndSegment,
};

export function SessionFlowControls(props) {
    const { id } = props;
    const { items, current, next, started } = useSessionFlowSchedule(id);
    const steps = items.map((item) => item.id);
    // const currentStep = items.find((item) => item.id === current);
    const onSetSegment = useSetSessionFlowSegment(id);
    const { event_state, event_started, event_start, can_edit } =
        useNodeData(id);
    const isLive = event_state === "live";
    const hasEnded = event_state === "ended";
    const isPending = usePending(`${id}.flow`);

    if (items.length < 3) {
        return (
            <div className={"session-flow-timeline"}>
                <SingleSegment
                    session={id}
                    isLive={isLive}
                    isActive={true}
                    isAdmin={!!can_edit}
                    hasEnded={hasEnded}
                    started={event_started}
                    start={event_start}
                    next={next}
                    onSetSegment={onSetSegment}
                />
            </div>
        );
    }

    return (
        <div className={"session-flow-timeline"}>
            {items.map((item) => {
                let isPast = steps.indexOf(current) > steps.indexOf(item.id);
                let isFuture = steps.indexOf(item.id) > current;
                let isNext = item.id === next;
                let isActive = item.id === current;
                let SegmentComponent = itemTypes[item.id];
                if (!SegmentComponent) return null;
                if (item.state === "end" && !isActive && !hasEnded) return null;
                return (
                    <>
                        {!isPast && !isActive && (
                            <div className={"session-flow-timeline__midstep"}>
                                <div className={"session-flow-timeline__arrow"}>
                                    <Icon
                                        className={
                                            "session-flow-timeline__arrow-icon"
                                        }
                                        fontSize={"m"}
                                    >
                                        mui-double_arrow
                                    </Icon>
                                </div>
                            </div>
                        )}
                        <SegmentComponent
                            key={item.id}
                            {...item}
                            session={id}
                            started={started}
                            next={next}
                            onSetSegment={onSetSegment}
                            onStartNext={() => onSetSegment(next)}
                            isNext={isNext}
                            isPending={isPending && (isActive || isNext)}
                            isActive={isActive}
                            isPast={isPast}
                            isFuture={isFuture}
                            isLive={isLive}
                            isAdmin={!!can_edit}
                            hasEnded={hasEnded}
                        />
                    </>
                );
            })}
        </div>
    );
}

SessionFlowControls.propTypes = {};
SessionFlowControls.defaultProps = {};

export default SessionFlowControls;
