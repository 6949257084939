import React from "react";
import cn from "classnames";
import LinearProgress from "@material-ui/core/LinearProgress";
//import PropTypes from 'prop-types';

export default function PendingSession({ text, visible }) {
    return (
        <figure
            className={cn("session-loader grow rows rows-center rows-middle", {
                "is-visible": !!visible,
            })}
        >
            <div className={"rows rows-center gap-md"}>
                <picture className={"session-pending-illustration"} />
                <figcaption className={"type-heading text-center o-60"}>
                    {text}
                </figcaption>
                <div className={"w-xs"}>
                    <LinearProgress />
                </div>
            </div>
        </figure>
    );
}

PendingSession.propTypes = {};
PendingSession.defaultProps = {
    text: <span>Joining session...</span>,
};
