import React from "react";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";

//import PropTypes from 'prop-types';

export function Toolbar(props) {
    const { Component, isHidden, ...other } = props;

    const flow = useSessionFlow();
    const data = flow.data || {};

    const { hideToolbar } = data;

    return <Component {...other} isHidden={hideToolbar || isHidden} />;
}

Toolbar.propTypes = {};
Toolbar.defaultProps = {};

export default Toolbar;
