import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "joynt/css/content.css";
import Heading from "joynt/content/Heading";
import Paragraph from "joynt/content/Paragraph";
import List from "joynt/content/List";
import Html from "components/Html";

const types = {
  heading: Heading,
  paragraph: Paragraph,
  list: List,
};

function RenderBlock(props) {
  const { type, data } = props;
  const BlockType = types[type] ?? null;

  if (BlockType) return <BlockType {...data} />;

  return null;
}

export default function RenderContent({ content, maxLength, size }) {
  if (!content) return null;

  let currentLength = 0;

  const classes = {
    "text-content": true,
  };

  classes[`text-content--${size}`] = true;

  if (!maxLength)
    return (
      <div className={cn(classes)}>
        {content.blocks.map((block, i) => (
          <RenderBlock key={i} {...block} />
        ))}
      </div>
    );

  const blocks = content.blocks.map((block) => {
    if (block.type === "paragraph") {
      if (maxLength && currentLength > maxLength) return null;
      let text = block.data.text;
      let len = text && text.length;
      if (maxLength && currentLength + len > maxLength) {
        currentLength += len;
        return (
          <div className={"content-block"}>
            <Html
              value={text
                .split("")
                .slice(0, maxLength - currentLength)
                .join("")}
            />
            <span>...</span>
          </div>
        );
      }
      currentLength += len;
      return <Html className={"content-block"} value={block.data.text} />;
    }
    return null;
  });

  const truncated = !!maxLength;
  classes["text-content--truncated"] = truncated;

  return <div className={cn(classes)}>{blocks}</div>;
}

RenderContent.propTypes = {
  size: PropTypes.oneOf(["default", "sm"]),
};

RenderContent.defaultProps = {
  maxLength: 0,
  size: "default",
};
