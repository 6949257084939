import React, { useCallback } from "react";
import Popover from "components/Popover";
import cn from "classnames";
import { SketchPicker } from "react-color";

//import PropTypes from 'prop-types';

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export function ColorPicker(props) {
    const { value, label, onChange } = props;
    let style = {};
    if (value) style.backgroundColor = value;

    const change = useCallback(
        (value) => {
            onChange(value.hex);
        },
        [onChange]
    );

    const sketchPicker = ({ onClose }) => (
        <SketchPicker color={value || "#ffffff"} onChange={change} />
    );

    const trigger = ({ onClick }) => (
        <div
            style={style}
            onClick={onClick}
            className={cn("swatch-color", `swatch-color--${label}`)}
        >
            <div className={"swatch-color__name"}>{label}</div>
            <div className={"swatch-color__hex"}>{value}</div>
        </div>
    );

    return (
        <Popover
            anchorOrigin={anchorOrigin}
            trigger={trigger}
            content={sketchPicker}
        />
    );
}

ColorPicker.propTypes = {};

ColorPicker.defaultProps = {};

export default ColorPicker;
