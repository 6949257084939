import React, { useState } from "react";
import { useCover, useData, useEntity } from "state/hooks";
import { useEdges, useNode, useNodeData, useWorkspacePlan } from "joynt/hooks";
import Field from "containers/Field";
import { AssignRoles } from "joynt/edit/access";
import Member from "joynt/edit/access/Member";
import FieldGroup from "joynt/components/Form/FieldGroup";
import GroupAccess from "joynt/edit/access/GroupAccess";
import Fetch from "containers/Fetch";
import Dialog from "@material-ui/core/Dialog";
import { useIdentity } from "joynt/hooks/participants";
import Avatar from "joynt/components/Avatar";
import Button from "components/Button";
import Icon from "components/Icon";
import { WORKSPACE_PLAN_PRO } from "joynt/config/plans";
import FieldLayout from "joynt/components/Form/FieldLayout";
//import PropTypes from 'prop-types';

const contextDescriptions = {
    joined: "Joined",
    owner: "Owner of",
    assigned: "Assigned in",
};

function Role(props) {
    const { identity, node, role, context } = props;
    const { name: nodeName } = useNodeData(node);
    const { name, media } = useIdentity(identity);
    const bg = useCover(media, [64, 64]);
    const desc = contextDescriptions[context];

    return (
        <div className={"cols cols-middle cols-spread gap-md"}>
            <div className={"cols cols-middle gap-sm"}>
                <div>
                    <Avatar src={bg} name={name} size={"medium"} />
                </div>
                <div className={"rows rows-left"}>
                    <div className={"type-lg"}>{name}</div>
                    <div className={"type-sm o-50"}>
                        {desc} "{nodeName}"
                    </div>
                </div>
            </div>
            <div>{role !== "guest" ? role : null}</div>
        </div>
    );
}

function NodeRoles({ id }) {
    const data = useData({ type: "app.node-roles", id });
    const { stats } = data;
    const roles = data.roles || [];
    const [open, setOpen] = useState(false);
    if (!stats) return null;
    return (
        <>
            <Button
                variant={"text"}
                onClick={() => setOpen(true)}
                startIcon={<Icon>mui-account_circle</Icon>}
            >
                {stats.all}
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <div className={"rows gap-xs pad-md"}>
                    {roles.map((role) => {
                        return <Role {...role} key={role.identity} />;
                    })}
                </div>
            </Dialog>
        </>
    );
}

function RolesAndAccessAdvanced(props) {
    const { label, description } = props;

    const { id } = useEntity();
    const { identity } = useNode(id);
    const { parent } = useEdges(id);
    const { name: parentName } = useNodeData(parent);
    const { id: nodeId } = useNodeData(id);
    const showRoles = !!nodeId;

    return (
        <Fetch
            type={"app.node-roles"}
            url={`joynt/nodes/${id}/roles`}
            enable={showRoles}
        >
            <FieldGroup
                label={label}
                description={description}
                addon={showRoles ? <NodeRoles id={id} /> : null}
            >
                <GroupAccess
                    name={`Make public`}
                    description={`Allow anyone who can see "${parentName}" to see this`}
                    icon={"mui-supervisor_account"}
                >
                    <Field
                        id={"published"}
                        fieldType={"checkbox"}
                        negate={false}
                    />
                </GroupAccess>
                <GroupAccess
                    name={`Inherit admins and collaborators from parent`}
                    description={`All admins and collaborators from "${parentName}"`}
                    icon={"mui-verified_user"}
                >
                    <Field
                        id={"public"}
                        fieldType={"checkbox"}
                        negate={false}
                    />
                </GroupAccess>
                <AssignRoles id={id} scope={"access"}>
                    <Member type={"db.identities"} id={identity} owner={true} />
                </AssignRoles>
            </FieldGroup>
        </Fetch>
    );
}

function RolesAndAccessBasic(props) {
    return (
        <FieldLayout
            name={"Make admin only"}
            description={
                "Only workspace admins and collaborators will be able to see this space"
            }
        >
            <Field id={"published"} fieldType={"checkbox"} negate={true} />
        </FieldLayout>
    );
}

export function RolesAndAccess(props) {
    const { id } = props;
    const plan = useWorkspacePlan(id);

    if (plan === WORKSPACE_PLAN_PRO)
        return <RolesAndAccessAdvanced {...props} />;

    return <RolesAndAccessBasic {...props} />;
}

RolesAndAccess.propTypes = {};
RolesAndAccess.defaultProps = {
    label: "Privacy settings",
    description: "Modify access if necessary",
};
