import React from "react";
import { Helmet } from "react-helmet";

import ConsoleEntrypoint from "./ConsoleEntrypoint";
import { Layout } from "playground/cms/Layout";
import DragLayer from "components/dnd/DragLayer";
import CommandPalette from "playground/cms/Command";
import Nav from "./Nav";
import views from "./views";

import "./../cms/style.css";
import "./../cms/mui.css";
import "./../cms/list.css";
import "./../cms/form.css";

function ConsoleLayout(props) {
    return (
        <>
            <Helmet defer={false}>
                <title>zuu.cms v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc&amp;libraries=places"></script>
                <body className={"theme-light default cms-theme"} />
            </Helmet>
            <Layout views={views} NavComponent={Nav} />
            <CommandPalette />
            <DragLayer />
        </>
    );
}

export default function (props) {
    return (
        <ConsoleEntrypoint>
            <ConsoleLayout {...props} />
        </ConsoleEntrypoint>
    );
}
