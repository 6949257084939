export default [
    {
        id: "start",
        name: "Describe task %aName intro",
        message: "You have 1:30 min each. %aName is up first.",
        speaker: "%a",
        next_label: "Start",
        aside: "witness-welcome",
    },
    {
        id: "describe-task-a",
        name: "Describe task %aName",
        duration: 90,
        messages: {
            "%a": null,
            "%b":
                "%bName, please listen to %aName’s task, you can ask questions to clarify, but avoid giving advice",
        },
        speaker: "%a",
        next_label: "Done",
        aside: "witness-welcome",
    },
    {
        id: "describe-task-b-intro",
        name: "Describe task %bName intro",
        message:
            "%bName, it is your turn now - please tell %aName what is your task and why you want to do it.",
        speaker: "%b",
        next_label: "Start",
        aside: "witness-welcome",
    },
    {
        id: "describe-task-b",
        name: "Describe task %bName",
        duration: 90,
        messages: {
            "%a":
                "%aName, please listen to %bName’s task, you can ask questions to clarify, but avoid giving advice",
            "%b": null,
        },
        speaker: "%b",
        next_label: "Done",
        aside: "witness-welcome",
    },
    {
        id: "pre-task",
        name: "Task described",
        message:
            "Good job! Now wish each other good luck, and let’s get started!",
        aside: "witness-welcome",
    },
    {
        id: "offline-in-progress",
        name: "Task in progress",
        duration: 45 * 60,
        offline: true,
        view: "witness-task-progress",
        steps: [
            {
                id: "complete",
                name: "Summary %aName intro",
                speaker: "%a",
                messages: {
                    "%a":
                        "%aName, please tell %bName how it went and if you accomplished your task. You have 30 sec",
                    "%b":
                        "%bName, please listen how %aName’s task went and award her/him with the proper BADGE.",
                },
                aside: "witness-final",
                next_label: "Start",
            },
        ],
    },
    {
        id: "summary-a",
        name: "Summary %aName",
        duration: 30,
        speaker: "%a",
        next_label: "Done",
        messages: {
            "%a": null,
            "%b":
                "%bName, please listen and rate %aName’s task, award her/him with the proper Joynt badge.",
        },
        aside: "witness-final",
    },
    {
        id: "give-badge-a",
        name: "Give badge to %aName",
        speaker: "%b",
        peer: "%a",
        messages: {
            "%a": "Time's up! %aName please wait for %bName's decision",
            "%b":
                "%bName please rate %aName’s progress for her/his task basing on her/his description. You have 5 options.",
        },
        view: "witness-badge",
        aside: "witness-final",
    },
    {
        id: "summary-b-intro",
        name: "Summary %bName intro",
        speaker: "%b",
        next_label: "Start",
        messages: {
            "%a":
                "%aName, please listen how %bName’s task went and award her/him with the proper BADGE.",
            "%b":
                "%bName, please tell %aName how it went and if you accomplished your task. You have 30 sec",
        },
        aside: "witness-final",
    },
    {
        id: "summary-b",
        name: "Summary %bName",
        duration: 30,
        speaker: "%b",
        next_label: "Done",
        messages: {
            "%a":
                "%aName, please listen and rate %bName’s task, award her/him with the proper Joynt badge.",
            "%b": null,
        },
        aside: "witness-final",
    },
    {
        id: "give-badge-b",
        name: "Give badge to %bName",
        speaker: "%a",
        peer: "%b",
        messages: {
            "%a":
                "%aName please rate %bName’s progress for her/his task basing on her/his description. You have 5 options.",
            "%b": "Time's up! %bName please wait for %aName's decision",
        },
        view: "witness-badge",
        aside: "witness-final",
    },
    {
        id: "wrap-up",
        name: "Wrap up",
        duration: 3 * 60,
        message:
            "You now have 3 minutes to wrap up your session, you can use to to chat if you wish, or end it now.",
        aside: "witness-final",
        next_label: "End session",
    },
    {
        id: "end",
        name: "Feedback",
    },
];
