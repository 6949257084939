import React from "react";
import PropTypes from "prop-types";
import FormLayout from "./FormLayout";
import useScreenSize from "util/hooks/useScreenSize";

export function FormLayoutContainer(props) {
    const { isMobile } = useScreenSize();

    return <FormLayout {...props} isMobile={isMobile} />;
}

FormLayoutContainer.propTypes = {
    id: PropTypes.string,
};
FormLayoutContainer.defaultProps = {};

export default FormLayoutContainer;
