import React from "react";
//import PropTypes from 'prop-types';

import ChannelView from "joynt/ChannelView";
import { NODE_TYPE_VIEWS as nodeTypeViews } from "joynt/config";
import { useNode, useNodeData } from "joynt/hooks";
import SpaceHeader from "joynt/components/SpaceHeader";
import { useNodeChannels } from "joynt/hooks/channels";
import { SpaceNestingContext } from "joynt/views";
import { useSelector } from "react-redux";
import { selectList } from "state/selectors/lists";

import "./style.css";

export default function Space(props) {
    const { id, channel } = props;

    const { views, type } = useNodeChannels(id, channel);
    const channels = useSelector((s) =>
        selectList(s, "db.nodes", "db.nodes.subchannels")
    );
    const ch = views.length || channel ? channel : channels[0];

    const { session_type } = useNodeData(id);
    const { subtype } = useNode(ch);
    const channelView = nodeTypeViews[subtype] || "feed";

    const renderView = ch ? channelView : type;
    const showHeader = session_type !== "witness";

    return (
        <div className={"space-view"}>
            {showHeader && <SpaceHeader id={id} channel={ch} tabs={true} />}
            <SpaceNestingContext.Provider value={true}>
                {renderView && (
                    <div className={"space-content"}>
                        <ChannelView id={id} channel={ch} view={renderView} />
                    </div>
                )}
            </SpaceNestingContext.Provider>
        </div>
    );
}

Space.propTypes = {};
Space.defaultProps = {};
