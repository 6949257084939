import React from "react";
import IconButton from "components/IconButton";

export function ToggleTask(props) {
    const {nodeRole, onChange} = props;
    const isTask = nodeRole === "task";

    const toggle = () => {
        let nextRole = isTask ? "post" : "task";
        onChange({node_role: nextRole});
    }

    return <IconButton
        checked={isTask}
        size={"small"}
        onClick={toggle}
        icon={"mui-assignment_turned_in"}
    />
}

export default ToggleTask;