import {useRouter} from "joynt/hooks/channels";
import {useCallback, useMemo} from "react";
import {useEdges} from "./useEdges";
import {useWorkspaceId} from "./useWorkspaceId";

export function useNodeParent(id) {
    const {path} = useEdges(id);
    const root = useWorkspaceId();
    const {onLink} = useRouter();

    let nodePath = useMemo(() => {
        if (!path) return [];
        if (path.indexOf(root) === -1) return [];
        let rootIndex = path.indexOf(root);
        return path.slice(rootIndex+1);
    }, [root, path]);

    let pathRoot = nodePath[0];
    let pathParent = nodePath[nodePath.length-1];

    const onLinkToParent = useCallback(() => {
        onLink(pathRoot, pathParent);
    }, [onLink, pathRoot, pathParent]);

    const pathProps = useMemo(() => ({
        root: pathRoot,
        parent: pathParent,
        onLinkToParent,
    }), [pathRoot, pathParent, onLinkToParent]);

    if (id === root) return {};
    return pathProps;
}