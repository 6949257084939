import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from "./SelectSearch";
import {useFilter} from "state/hooks";

export function SelectSearchContainer(props) {

    const {
        type,
        list
    } = props;

    const {value, onSet} = useFilter(list, type, list);

    return (<SelectSearch
        {...props}
        value={value}
        onChange={onSet}
    />);

}

SelectSearchContainer.propTypes = {
    type: PropTypes.string,
    list: PropTypes.string
};
SelectSearchContainer.defaultProps = {};

export default SelectSearchContainer;