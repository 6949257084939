import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import PostsForm from "./PostsForm";

export function Posts(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"post"} label={"Wpis"}>
                        <PostsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Posts.propTypes = {};

Posts.defaultProps = {};

export default Posts;
