import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet/es/Helmet";
import {selectAliasedEntity} from "state/selectors/data";
import {selectRouteParam} from "state/selectors/router";
import domainRouter from "util/domainRouter";

const domain = domainRouter().params || {};

const mapStateToProps = (store, props) => {
    const serviceName = selectRouteParam(store, 'service');
    const service = selectAliasedEntity(store, 'console.services', serviceName);
    return {
        title: service.id && service.service_id !== domain.service
            ? service.name || 'zuu'
            : null
    }
};

const mapDispatchToProps = {};

class PageHead extends React.PureComponent {

    componentDidMount() {
        this.updateTitle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateTitle();
    }

    updateTitle() {
        const { title } = this.props;
        if (title) {
            document.querySelector('title').innerText = title;
        }
    }

    render() {
        return (<Helmet>
            <link type="text/css" rel="stylesheet" href="https://cdn.joynt.works/atoms/style.min.css" />
        </Helmet>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHead);