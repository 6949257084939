import React from 'react';
//import PropTypes from 'prop-types';
import {useNode} from "joynt/hooks";
import Icon from "components/Icon";
import {useClick} from "state/hooks";

export default function ParentLink({id, channel, icon, onClick}) {
    const { name } = useNode(id);
    const click = useClick({onClick, id: channel || id});

    return <div className={'space-parent-link'} onClick={click}>
        <div className={'space-parent-link__label'}>
            {name}
        </div>
        <div className={'space-parent-link__icon'}>
            <Icon>{icon}</Icon>
        </div>
    </div>;
}

ParentLink.propTypes = {};

ParentLink.defaultProps = {
    icon: 'mui-keyboard_arrow_right'
}