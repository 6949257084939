import React from 'react';
import {connect} from 'react-redux';

import forms from "config/forms";
import EntityContext from "containers/context/EntityContext";
import FormLayout from "components/layouts/FormLayout";
import FormButtons from "components/FormButtons";
import Field from "containers/Field";
import {reject} from "state/actions/data";
import {isPending} from "state/selectors/ui";

const mapStateToProps = (store, props) => {
    const form = forms[props.id] || {};
    return {
        ...form,
        type: 'app.forms',
        pending: isPending(store, [props.id])
    }
};

const mapDispatchToProps = {
    onCancel: reject
};

class FormStatic extends React.PureComponent {

    close = () => {
        const { type, id, onCancel, onClose } = this.props;
        onCancel(type, id);
        if (onClose) onClose();
    };

    submit = () => {
        const { type, id, url, onSubmit } = this.props;
        onSubmit(()=>this.close(), type, id, url);
    };

    render() {
        const { type, id, fields, pending } = this.props;
        return (<EntityContext.Provider value={[type, id].join('/')}>
            <FormLayout
                pending={pending}
                className={'form-regular'}
                form={<div className={'fields'}>
                    {Object.keys(fields).map(k => {
                        let properties = fields[k].properties || {};
                        return <Field
                            key={k}
                            fieldType={fields[k].type}
                            label={fields[k].label}
                            id={fields[k].id}
                            {...properties}
                        />;
                    })}
                </div>}
                footer={<FormButtons
                    onCancel={this.close}
                    onSubmit={this.submit}
                />}
            />
        </EntityContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormStatic);