import React, { useEffect, useRef } from "react";
import {
    useClick,
    useData,
    usePending,
    useCover,
    useApiActions,
    useActions,
} from "state/hooks";
import cn from "classnames";
//import PropTypes from 'prop-types';

import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ContextMenu from "components/ContextMenu";
import IconButton from "components/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
import resourceIcons from "config/icons";
import ContextMenuItems from "./ContextMenuItems";
import { oneOf, oneOfFallback } from "playground/cms/util";

import "./style.css";
import { useDrop } from "react-dnd";
import { useModuleEnabled } from "playground/cms/Schema";

const noAvatar = {
    "cms.pages": true,
    "cms.layouts": true,
};

function prefix(type) {
    if (!type) return null;
    return type.split(".")[0];
}

function useDragOver(onDragOver, id) {
    const [isOver, setOver] = React.useState(false);
    const [, drop] = useDrop({
        accept: "cms.sections",
        hover(hoverItem, monitor) {
            if (monitor.isOver({ shallow: true })) {
                if (!isOver) {
                    setOver(true);
                    if (onDragOver) onDragOver(id);
                }
            } else {
                if (isOver) {
                    setOver(false);
                    if (onDragOver) onDragOver(null);
                }
            }
        },
    });
    return drop;
}

function CustomListItemText(props) {
    return (
        <>
            {props.children ? (
                <div className={"custom-list-item-text"}>
                    <ListItemText {...props} />
                    {props.children}
                </div>
            ) : (
                <ListItemText {...props} />
            )}
        </>
    );
}

function Property({ id }) {
    const { title } = useData({ type: "cms.properties", id });
    return <div className={"tag"}>{title}</div>;
}

export const ListItem = React.forwardRef((props, ref) => {
    const { id, type, MenuItems, children } = props;
    const { onDragOver, ...rest } = props;
    const data = useData(props); //media
    const { property, slug, media, active, external_id } = data;
    const image = useCover(media, [200, 150], prefix(type));
    const onClick = useClick(props);
    const pending = usePending(id);

    const { isDragging, selected, emptyText } = props;
    const dragOver = useDragOver(onDragOver, id);

    if (onDragOver) dragOver(ref);

    const primaryText = oneOf(["title", "name", "description"], data);
    const primaryTextFallback = oneOfFallback(
        ["title", "name", "description"],
        data
    );

    const hasProperties = useModuleEnabled("properties");

    let visiblePrimaryText = primaryText || primaryTextFallback || emptyText;

    return (
        <ContextMenu type={type} id={id} RenderMenuItems={MenuItems}>
            {({ onMenuOpen }) => (
                <MuiListItem
                    ref={ref}
                    button={!!props.onClick}
                    onClick={props.onClick ? onClick : null}
                    onContextMenu={props.onClick ? onMenuOpen : null}
                    className={cn({
                        "list-item": true,
                        "is-active": selected === id,
                        "is-dragging": isDragging,
                        "is-disabled": !active,
                        "is-pending": !!pending,
                        "no-title": !primaryText,
                    })}
                >
                    {!noAvatar[type] && (
                        <ListItemAvatar>
                            <Avatar
                                src={image}
                                className={active ? "t-primary" : null}
                            >
                                <Icon>{`mui-${resourceIcons[type]}`}</Icon>
                            </Avatar>
                        </ListItemAvatar>
                    )}
                    <CustomListItemText
                        primary={visiblePrimaryText}
                        secondary={slug ? `/${slug}` : null}
                    >
                        {((hasProperties && property) || external_id) && (
                            <div className={"list-item-tags"}>
                                {property && <Property id={property} />}
                                {external_id && (
                                    <div className={"tag"}>{external_id}</div>
                                )}
                            </div>
                        )}
                    </CustomListItemText>
                    {children}
                    <ListItemSecondaryAction className={"list-item-action"}>
                        {props.onClick && !pending && (
                            <IconButton
                                icon={"mui-more_vert"}
                                onClick={onMenuOpen}
                                size={"small"}
                            />
                        )}
                        {pending && (
                            <CircularProgress
                                color={"inherit"}
                                size={20}
                                thickness={4.2}
                            />
                        )}
                    </ListItemSecondaryAction>
                </MuiListItem>
            )}
        </ContextMenu>
    );
});

ListItem.propTypes = {};

ListItem.defaultProps = {
    emptyText: "No title",
    MenuItems: ContextMenuItems,
};

export default ListItem;
