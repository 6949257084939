import React from 'react';
import PropTypes from 'prop-types';
import {CalendarIcon} from "joynt/components/Icons";
import Html from "components/Html";

export function EmptyState(props) {

    const {text} = props;
    return (<div className={"rows rows-center gap-sm pad-sm"}>
        <CalendarIcon />
        <div className={"text-center o-50"}>
            <Html value={text} />
        </div>
    </div>);

}

EmptyState.propTypes = {
    text: PropTypes.string
};
EmptyState.defaultProps = {
    text: "There are no sessions scheduled for this day.<br />Go ahead and schedule new session."
};

export default EmptyState;
