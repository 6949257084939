import React from 'react';
//import PropTypes from 'prop-types';
import "./style.css";
import {GhostIcon} from "joynt/components/Icons";

export function EmptyState(props) {

    const {children} = props;

    return (<div className={"sidebar-nav-section-empty-state"}>
        <GhostIcon />
        {children}
    </div>);

}

EmptyState.propTypes = {};

EmptyState.defaultProps = {};

export default EmptyState;