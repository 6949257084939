import React from 'react';
import PropTypes from 'prop-types';
import RangeLabel from "./RangeLabel";
import {selectRange} from "joynt/components/DailySchedule/util";

export function RangeLabelContainer(props) {

    const {
        day,
        mode
    } = props;

    const range = selectRange(new Date(day*1000), mode);

    return (<RangeLabel
        {...props}
        start={range.start.getTime()/1000}
        end={range.end.getTime()/1000}
    />);

}

RangeLabelContainer.propTypes = {
    id: PropTypes.string
};
RangeLabelContainer.defaultProps = {};

export default RangeLabelContainer;