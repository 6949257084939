import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import TextField from "components/fields/text/TextField";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import isValidEmail from "util/validation/isValidEmail";
import RoleSelectMenu from "./../RoleSelectMenu";
import IdentitySearch from "./IdentitySearch";
import IconButton from "joynt/components/IconButton";
import throttle from "lodash/debounce";
import Icon from "components/Icon";
//import PropTypes from "prop-types";

function EmailSuggestion({ email, onClick }) {
    const valid = useMemo(() => isValidEmail(email), [email]);

    return (
        <div className="role-item link" onClick={valid ? onClick : null}>
            <div>
                <Icon>mui-add</Icon> Invite with email <strong>{email}</strong>
            </div>
        </div>
    );
}

export default function SearchInput(props) {
    const {
        onAddEmail,
        enableRoleSelection,
        onAddRole,
        scope,
        minInput,
        minSearch,
        menuPosition,
        node,
        placeholder,
        allowEmail,
        popover,
        children,
    } = props;

    const [value, setValue] = useState("");
    const [searchValue, setSearchValue] = useState(value);
    const [focused, setFocused] = useState(false);

    const throttled = useRef(throttle((v) => setSearchValue(v), 500));

    useEffect(() => throttled.current(value), [value]);

    const ref = useRef();
    const isEmail = value && value.indexOf("@") > -1;
    const isValid = value && value.length >= minInput;
    const isOpen = isValid || (!minInput && focused);
    const search = isValid && value.length >= minSearch;

    const addEmail = useCallback(
        (role) => {
            onAddEmail(value, role);
            setValue(null);
        },
        [setValue, value, onAddEmail]
    );

    const addRole = useCallback(
        (identity, role) => {
            onAddRole(identity, role);
            setValue(null);
        },
        [setValue, onAddRole]
    );

    const clear = useCallback(() => setValue(""), [setValue]);
    const focus = useCallback(() => setFocused(true), [setFocused]);

    const blur = useCallback(
        (e) => {
            setTimeout(() => setFocused(false), 200);
        },
        [setFocused]
    );

    return (
        <div className={"rows"}>
            <div ref={ref} className={"cols"}>
                <TextField
                    label={placeholder}
                    onChange={setValue}
                    value={value}
                    helperText={
                        minInput
                            ? `Type at least ${minInput} characters to choose from list`
                            : null
                    }
                    InputProps={{
                        onFocus: focus,
                        onBlur: blur,
                        endAdornment: value && (
                            <IconButton
                                size={"small"}
                                icon={"mui-clear"}
                                onClick={clear}
                            />
                        ),
                    }}
                />
                {/*<Button onClick={addEmail}>Add emails</Button>*/}
            </div>
            {popover ? (
                <Popper
                    open={isOpen}
                    anchorEl={ref.current}
                    anchorOrigin={menuPosition}
                    onClose={clear}
                >
                    <Paper elevation={8} className={"identity-seach-popover"}>
                        <div className={"identity-search-results"}>
                            {children}
                            {search && (
                                <IdentitySearch
                                    scope={scope}
                                    onAddRole={addRole}
                                    search={searchValue}
                                    node={node}
                                    minSearch={minSearch}
                                    enableRoleSelection={enableRoleSelection}
                                />
                            )}
                            {allowEmail && search && isEmail && (
                                <>
                                    {enableRoleSelection ? (
                                        <RoleSelectMenu
                                            scope={scope}
                                            onSelect={addEmail}
                                            trigger={({ onClick }) => (
                                                <EmailSuggestion
                                                    email={value}
                                                    onClick={onClick}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <EmailSuggestion
                                            email={value}
                                            onClick={() => addEmail(null)}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Paper>
                </Popper>
            ) : (
                <>
                    {search && (
                        <IdentitySearch
                            scope={scope}
                            onAddRole={addRole}
                            search={searchValue}
                            node={node}
                            minSearch={minSearch}
                            enableRoleSelection={enableRoleSelection}
                        />
                    )}
                </>
            )}
        </div>
    );
}

SearchInput.defaultProps = {
    enableRoleSelection: false,
    minInput: 3,
    minSearch: 3,
    menuPosition: { vertical: "bottom" },
    placeholder: "Find by name or email",
    allowEmail: true,
    popover: true,
};

SearchInput.propTypes = {
    onAddEmail: PropTypes.func,
    onAddRole: PropTypes.func,
    enableRoleSelection: PropTypes.bool,
    minInput: PropTypes.number,
    minSearch: PropTypes.number,
    placeholder: PropTypes.string,
    allowEmail: PropTypes.bool,
    popover: PropTypes.bool,
};
