import { useSelector } from "react-redux";
import { selectList } from "state/selectors/data";

export function useRolesCount(list, changesList) {
    return useSelector((s) => {
        let data = selectList(s, "app.node-roles", list);
        let changes =
            list !== changesList
                ? selectList(s, "app.node-roles", changesList)
                : [];
        let all = data
            .concat(changes)
            .filter((item) => item.action !== "remove");

        return all.length;
    });
}
