import React, { useCallback } from "react";
import PropTypes from "prop-types";
import PostContextMenu from "./PostContextMenu";
import { usePostActions, usePostEdit } from "joynt/components/Post/hooks";
import { useFeatureEnabled, useNode } from "joynt/hooks";
import { useActions } from "state/hooks";
import { postUpgradeEditor } from "joynt/state/actions/posts";
import { FEATURE_DRAFTJS } from "joynt/config/features";

export function PostContextMenuContainer(props) {
    const { id } = props;

    const { onDelete } = usePostEdit(id);

    const { onUpgrade } = useActions({
        onUpgrade: postUpgradeEditor,
    });

    const upgrade = useCallback(() => {
        onUpgrade(id);
    }, [onUpgrade, id]);

    const { onPresent, onMove, onEdit } = usePostActions(id);

    const { can_edit } = useNode(id);
    const canUpgrade = useFeatureEnabled(FEATURE_DRAFTJS);

    return (
        <PostContextMenu
            onPresent={onPresent}
            onEdit={can_edit ? onEdit : null}
            onDelete={can_edit ? onDelete : null}
            onMove={can_edit ? onMove : null}
            onUpgrade={canUpgrade && can_edit ? upgrade : null}
        />
    );
}

PostContextMenuContainer.propTypes = {
    id: PropTypes.string,
};
PostContextMenuContainer.defaultProps = {};

export default PostContextMenuContainer;
