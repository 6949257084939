import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from "./ProgressBar";
import {useTimerProgress} from "joynt/components/Timer/hooks";

export function TimerProgressBar(props) {

    const {
        start,
        target
    } = props;

    const value = useTimerProgress(start, target);

    return (<ProgressBar value={value} />);

}

TimerProgressBar.propTypes = {
    start: PropTypes.number,
    target: PropTypes.number
};
TimerProgressBar.defaultProps = {};

export default TimerProgressBar;
