import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTimerProgress } from "joynt/components/Timer/hooks";

import WitnessTaskProgress from "./WitnessTaskProgress";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import { endSession } from "./../../actions";
import { useApiActions, useUiFlag } from "state/hooks";

export function WitnessTaskProgressContainer(props) {
    const { id, duration, started } = props;

    const value = useTimerProgress(started, started + duration);
    const durationMinutes = duration / 60;
    const minutes = Math.floor(
        durationMinutes - durationMinutes * (value / 100)
    );

    const { session } = useMeeting();

    const [devMinutes, setDevMinutes] = useState(null);
    const [, setStateStep] = useUiFlag(`session.${session}.flowSegment`);

    const minutesLeft = devMinutes !== null ? devMinutes : minutes;
    const isOver = minutesLeft <= 0;

    const { onEndSession } = useApiActions({
        onEndSession: endSession,
    });

    const handleEnd = useCallback(() => {
        onEndSession(session);
    }, [session, onEndSession]);

    useEffect(() => {
        if (isOver) setStateStep(`${id}:complete`);
    }, [isOver, setStateStep, id]);

    useEffect(() => {
        setStateStep(null);
    }, [started, setStateStep]);

    return (
        <WitnessTaskProgress
            {...props}
            progress={value}
            minutesLeft={minutesLeft}
            onEndSession={handleEnd}
        >
            <div onClick={() => setDevMinutes(0)}>fast forward {value}</div>
        </WitnessTaskProgress>
    );
}

WitnessTaskProgressContainer.propTypes = {
    id: PropTypes.string,
};
WitnessTaskProgressContainer.defaultProps = {};

export default WitnessTaskProgressContainer;
