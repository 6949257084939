export const WORKSPACE_PLAN_DEFAULT = "default";
export const WORKSPACE_PLAN_PRO = "pro";
export const WORKSPACE_PLAN_LITE = "lite";
export const WORKSPACE_PLAN_WEBINAR = "webinar";

export const workspacePlans = {
    [WORKSPACE_PLAN_DEFAULT]: {
        id: WORKSPACE_PLAN_DEFAULT,
        label: "Default"
    },
    [WORKSPACE_PLAN_PRO]: {
        id: WORKSPACE_PLAN_PRO,
        label: "Pro"
    },
    [WORKSPACE_PLAN_LITE]: {
        id: WORKSPACE_PLAN_LITE,
        label: "Lite"
    },
    [WORKSPACE_PLAN_WEBINAR]: {
        id: WORKSPACE_PLAN_WEBINAR,
        label: "Webinars"
    }
}