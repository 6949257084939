import { takeEvery, put, select, all, call } from "redux-saga/effects";
import { selectList } from "state/selectors/lists";
import { selectListChanges } from "state/selectors/data";
import { request as apiRequest } from "util/api/client";
import { resetBatch, resetTypes, storeBatch } from "state/actions/data";
import { pending } from "state/actions/ui";

function makeTranslationsUrl(type, id) {
    const path = type.split(".");
    let url;

    if (path.length > 2) {
        const entryType = path.pop();
        url = path.join("/") + `/:id/translations?type=${entryType}`;
    } else {
        url = path.join("/") + `/:id/translations`;
    }

    return url.replace("/:id", id ? `/${id}` : "");
}

function* handleStoreTranslations({ payload, context }) {
    const { type, id } = payload;

    const listId = [type, id].join("_");
    const fetchId = ["app.resource-translations", type, id].join("/");
    const translationsType = "app.resource-translations";

    try {
        const changes = yield select((store) =>
            selectListChanges(store, translationsType, listId)
        );

        if (!changes.length) return;

        const url = makeTranslationsUrl(type, id);

        yield put(pending(fetchId, true));

        yield apiRequest({
            url,
            method: "POST",
            data: changes,
            context,
        });

        const paths = changes.map((change) =>
            [translationsType, change.id].join("/")
        );
        yield put(storeBatch({ [translationsType]: changes }, {}));
        yield put(resetBatch(paths));

        yield put(pending(fetchId, false));

        console.log(changes, url);
    } catch (e) {
        yield put(pending(fetchId, false));
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("CMS.TRANSLATIONS.STORE", handleStoreTranslations);
}
