import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import { createPortal } from "react-dom";
import Sections from "playground/cms/views/Sections";

import "./style.css";

export function SectionBrowser(props) {
    const { open, pageType, onClose } = props;

    return createPortal(
        <div
            className={cn("section-browser", {
                "is-open": open,
            })}
        >
            {open && (
                <Sections
                    type={"cms.sections"}
                    list={"browser"}
                    create={pageType || false}
                    isBrowser={true}
                    onCloseSections={onClose}
                />
            )}
        </div>,
        document.body
    );
}

SectionBrowser.propTypes = {};

SectionBrowser.defaultProps = {};

export default SectionBrowser;
