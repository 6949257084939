import {useSelector} from "react-redux";
import {selectCurrentUserId} from "state/selectors/app";
import {selectEntity} from "state/selectors/data";
import {useApiActions} from "state/hooks";
import {useCallback} from "react";
import {markAsSeen} from "joynt/state/actions/notifications";

/**
 * Notifications count per node
 *
 * @param id
 * @param group
 * @returns {(number|(function(): void))[]}
 */
export function useNotificationCount(id, group = 'all') {
    const user = useSelector(store=>selectCurrentUserId(store));
    const notificationStats = useSelector(store=>selectEntity(store, 'db.notification-stats', user));
    const {onMarkAsSeen} = useApiActions({onMarkAsSeen: markAsSeen});
    let stats = notificationStats[group] || {};
    let mentionStats = notificationStats.mentions || {};
    let count = 0;
    let mentionCount;
    if (stats.nodes) count = stats.nodes[id];
    if (mentionStats.nodes) mentionCount = mentionStats.nodes[id];

    const seen = useCallback(() => {
        if (count) onMarkAsSeen(id);
    }, [id, count, onMarkAsSeen]);

    return [count, seen, mentionCount];
}