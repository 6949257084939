import React from "react";
import Timer from "joynt/components/Timer";

function SessionStateLabel(props) {
    const { isLive, hasEnded, state, start, started } = props;

    if (state === "end") return <div>Finish</div>;

    if (hasEnded)
        return (
            <div className={"session-control-panel__state"}>Session ended</div>
        );

    if (isLive)
        return (
            <div className={"session-control-panel__state"}>
                <Timer start={started} />
            </div>
        );

    return (
        <p className={"session-control-panel__state"}>
            <Timer
                countdown
                countdownText={"Starting in"}
                bareValue
                start={start}
                humanTimeThreshold={86400}
            />
        </p>
    );
}

export default SessionStateLabel;
