import React, { useState, useEffect } from "react";
import cn from "classnames";

import { useAgoraClient } from "joynt/meeting/agora-ng/hooks";
import { useMediaDevices } from "joynt/meeting/devices";

import Icon from "components/Icon";
import Button from "components/Button";

export function MicrophoneTest(props) {
    const rtc = useAgoraClient();

    const { microphoneAvailable, selectedDevices } = useMediaDevices();
    const { audioInput } = selectedDevices;

    const [micState, setMicState] = useState(null);

    useEffect(() => {
        setMicState(null);
    }, [setMicState, audioInput]);
    const checkMicrophone = () => {
        rtc.testMicrophone(audioInput, setMicState);
    };

    const btnText = {
        [null]: "Check microphone",
        fail: "Check again",
        success: "Check again",
        pending: "Checking...",
    };

    const statusText = {
        [null]: "Test your microphone",
        fail: "We can't hear you",
        success: "Your mic is working",
        pending: "Say something",
    };

    const icons = {
        fail: "mui-error",
        success: "mui-check",
    };

    if (!microphoneAvailable) return <div>Microphone not available</div>;

    return (
        <div className={"cols cols-middle gap-sm cols-spread"}>
            <div
                className={cn(
                    "mic-status-text cols cols-middle gap-xs",
                    micState ? `state-${micState}` : null
                )}
            >
                {icons[micState] && <Icon>{icons[micState]}</Icon>}
                <div>{statusText[micState]}</div>
            </div>
            <Button
                onClick={checkMicrophone}
                color={"default"}
                disabled={micState === "pending"}
            >
                {btnText[micState]}
            </Button>
        </div>
    );
}

export default MicrophoneTest;
