import React from 'react';
import PropTypes from 'prop-types';
import {GhostIcon} from "joynt/components/Icons";

export function EmptyState(props) {

    const {text} = props;

    return (<div className={"rows rows-center gap-sm"}>
        <GhostIcon />
        <div className={"o-50"}>{text}</div>
    </div>);

}

EmptyState.propTypes = {
    text: PropTypes.string
};
EmptyState.defaultProps = {
    text: "There are no live sessions now"
};

export default EmptyState;
