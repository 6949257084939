import React from "react";
import ExternalLink from "./ExternalLink";
//import PropTypes from "prop-types";

import {useFetchLinkData} from "./hooks/useFetchLinkData";

export default function ExternalLinkContainer(props) {
    const {link} = props;
    const {pending, ...linkData} = useFetchLinkData(link);

    return <ExternalLink
        {...props}
        {...linkData}
        isPending={pending}
    />;
}

ExternalLinkContainer.defaultProps = {};
ExternalLinkContainer.propTypes = {};