import React, {useEffect} from 'react';
import {useData, useUiFlag} from "state/hooks";
import {useRTMClient} from "joynt/meeting/agora-ng/hooks";
import SessionDebugConsole from "joynt/meeting/agora-ng/SessionDebugConsole";
//import PropTypes from 'prop-types';

export default function SessionDebug(props) {

    const {children} = props;
    const [reportId, setReportId] = useUiFlag('meeting.pendingDebugReport');
    const rtmClient = useRTMClient();
    const report = useData({type: 'app.session-debug-report', id: reportId});

    useEffect(() => {
        if (reportId) {
            rtmClient.sendMessageToPeer({
                text: JSON.stringify({
                    id: 'provide-logs',
                    data: report
                })
            }, report.requested_by);
            setReportId(null);
        }
    }, [reportId, setReportId, report, rtmClient]);

    return <>
        {children}
        <SessionDebugConsole />
    </>

}

SessionDebug.propTypes = {};
SessionDebug.defaultProps = {};
