import React from "react";

//import PropTypes from 'prop-types';

import "./style.css";

export function PostHeader(props) {
    const { author, date, parent, actions, children } = props;

    return (
        <div className={"post-header rows gap-sm"}>
            <div className={"cols cols-top cols-spread"}>
                {children || null}
                {actions && <div className={"cols cols-middle"}>{actions}</div>}
            </div>
            <div className={"post-header__info"}>
                <div className={"post-header__author"}>{author}</div>
                <div className={"post-header__date"}>{date}</div>
                <div className={"post-header__parent"}>{parent}</div>
            </div>
        </div>
    );
}

PostHeader.propTypes = {};
PostHeader.defaultProps = {};

export default PostHeader;
