import React from 'react';
import PropTypes from 'prop-types';

import Text from "./Text";
import {useField} from "state/hooks";

export function TextContainer(props) {

    const { id } = props;

    const { value, onChange } = useField(
        "db.posts",
        id,
        "content"
    );

    return <Text
        {...props}
        value={value}
        onChange={onChange}
    />;

}

TextContainer.propTypes = {
    id: PropTypes.string
};
TextContainer.defaultProps = {};

export default TextContainer;