import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import Section from "cms/containers/Section";
import List from "containers/List";
import { selectFieldValue } from "state/selectors/data";
import { ArrayIterator } from "components/list/iterator";
import PageLayoutContainer from "cms/containers/PageLayout";
//import PropTypes from 'prop-types';

const mapState = (store, props) => {
    const type = selectFieldValue(store, props.type, props.id, "type");
    return {
        isPlaceholder: type === "placeholder",
    };
};

function SectionRenderer(props, ref) {
    const { sections, isPlaceholder, children, ...other } = props;
    /*if (isPlaceholder) return <Section {...other}>
        <h1>placeholder section</h1>
    </Section>;*/
    if (isPlaceholder) return sections;
    return <Section {...other} />;
}

const ConnectedSectionRenderer = connect(mapState)(SectionRenderer);
const SectionIterator = ArrayIterator(ConnectedSectionRenderer);

export const LayoutSectionIterator = SectionIterator;

export const LayoutPlaceholder = React.forwardRef((props, ref) => {
    return (
        <div
            className={"l-section t-alt type--heading-md text--center"}
            ref={ref}
        >
            <h1>layout placeholder</h1>
        </div>
    );
});

export default class PageLayout extends React.PureComponent {
    sections = () => {
        const { isLayout } = this.props;
        const { sections } = this.props.data;

        if (isLayout) return this.props.children;

        return (
            <List
                type={"cms.sections"}
                items={sections}
                sections={this.props.children}
                Iterator={SectionIterator}
            />
        );
    };

    render() {
        const { type, className, bodyClassName, layout, withWrapper } =
            this.props;
        const classes = this.props.classes || {};

        const layoutSections = layout ? (
            <PageLayoutContainer type={type} id={layout}>
                {this.sections()}
            </PageLayoutContainer>
        ) : (
            this.sections()
        );

        if (withWrapper) {
            return (
                <body className={cn(bodyClassName, classes.body)}>
                    <div
                        id="wrapper"
                        className={cn(
                            className,
                            classes.page_wrapper,
                            classes["page-wrapper"]
                        )}
                    >
                        {layoutSections}
                    </div>
                </body>
            );
        }

        return layoutSections;
    }
}

PageLayout.propTypes = {};
PageLayout.defaultProps = {
    withWrapper: false,
};

PageLayout.autoFillProps = [
    "style",
    "styles",
    "layout",
    "sections",
    "description",
    "title",
];
