import React from "react";

//import PropTypes from 'prop-types';

export function PageSectionsList(props) {
    const { children } = props;
    return children;
}

PageSectionsList.propTypes = {};

PageSectionsList.defaultProps = {};

export default PageSectionsList;
