import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";
import Icon from "components/Icon";

export function InputBox(props) {

    const {children, startIcon, onClick} = props;

    return (
        <div className={"input"} onClick={onClick}>
            <div className={"input__content"}>
                {startIcon && <div className={"input__start-icon"}>
                    <Icon>{startIcon}</Icon>
                </div>}
                <div className={"input__value"}>
                    {children}
                </div>
            </div>
        </div>
    );

}

InputBox.propTypes = {};
InputBox.defaultProps = {};

export default InputBox;
