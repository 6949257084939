import {setupSchema} from "state/util/normalize";
import {setupEnv} from "util/env";

export function bootstrap(config) {
    setupEnv(config.env);

    setupSchema(
        config.schema,
        config.langProvider
    );
}