import polylabel from 'polylabel';

export function order(inputPoints) {
    let points = inputPoints.map((point) => {
        return [point.x * 1, point.y * 1];
    });
    let geometry = new Geometry(points);
    const sorted = geometry.sort();
    const sortedImgPoints = sorted.map((point) => {
        return {x: point[0], y: point[1]}
    });
    return sortedImgPoints;
}

export function distance(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y ,2));
}

export function lineDistance(line, point) {

    let t1 = Math.abs((point.x * (line[1].y - line[0].y)) - (point.y * (line[1].x - line[0].x)) + (line[1].x * line[0].y) - (line[1].y * line[0].x));
    let t2 = distance(line[0], line[1]);
    return t1/t2;

}

export function distanceToLine(p1, p2, p) {
    var A = p2.x-p1.x,
        B = p2.y-p1.y,
        p3;
    var u = (A*(p.x-p1.x)+B*(p.y-p1.y))/(Math.pow(A,2)+Math.pow(B,2));
    if (u<=0) {
        p3 = p1;
    }
    else if (u>=1) {
        p3 = p2;
    }
    else {
        p3 = {
            x: p1.x + u * A,
            y: p1.y + u * B
        };
    }
    return Math.sqrt(Math.pow(p.x-p3.x,2)+Math.pow(p.y-p3.y,2));
}

export function lineCenterDistance(line, point) {

    let center = {
        x: (line[0].x + line[1].x)/2,
        y: (line[0].y + line[1].y)/2
    };

    return distance(center, point);

}

export function closestLine(point, points) {
    const lines = [];
    for (let i = 0; i < points.length; i++) {
        if (i+1 >= points.length) {
            lines.push([points[i], points[0], i, 0]);
        } else {
            lines.push([points[i], points[i + 1], i, i+1]);
        }
    }
    let minDistance = 999999;
    let cLine = null;
    for (let i = 0; i < lines.length; i++) {
        //let lDistance = lineDistance(lines[i], point);
        let lDistance = distanceToLine(lines[i][0], lines[i][1], point);
        if (lDistance < minDistance) {
            minDistance = lDistance;
            cLine = lines[i];
        }
    }
    return cLine;
}

export function closestPoint(point, points) {
    let minDistance = 99999;
    let minIndex = null;

    points.forEach((pointToCompare, index) => {
        let d = distance(point, pointToCompare);
        if (d < minDistance) {
            minDistance = d;
            minIndex = index;
        }
    });

    return minIndex;
}

export function findPointIndex(points, point) {
    const closestIndex = closestPoint(point, points);
    return closestIndex;
}

export function insertBetween(points, point) {
    if (!points.length) {
        return [point];
    }

    if (points.length < 2) {
        points.push(point);
        return points;
    }

    let line = closestLine(point, points);
    let index = line[3] === 0 ? 0 : Math.min(line[2],line[3])+1;

    points.splice(index, 0, point);
    return points;
}

export default class Geometry {

    constructor(points) {

        if (!points) {
            points = [];
        }

        this.points = points;

    }

    sort() {

        if (!this.points.length) {
            return this.points;
        }

        var centroid = this.centroid();
        var input = this.translate([0 - centroid[0], 0 - centroid[1]]);

        var base = Math.atan2(input[0][1], input[0][0]);
        var sorted = input.sort(function (a, b) {
            return Math.atan2(b[1], b[0]) - Math.atan2(a[1], a[0]) + (Math.atan2(b[1], b[0]) > base ? -2 * Math.PI : 0) + (Math.atan2(a[1], a[0]) > base ? 2 * Math.PI : 0);
        });

        this.points = sorted;
        this.points = this.translate(centroid);

        return this.points;

    }

    center() {

        if (!this.points.length) {
            return {x: 0, y: 0};
        }

        var point = polylabel([this.points]);

        return {
            x: point[0] * 1,
            y: point[1] * 1
        };

    }

    centroid() {

        var points = this.points;

        return this.points.reduce(function (x, y) {
            return [x[0] + y[0] / points.length, x[1] + y[1] / points.length]
        }, [0, 0]);

    }

    translate(delta) {

        return this.points.map(function (point) {
            return [point[0] + delta[0], point[1] + delta[1]];
        });

    }

}