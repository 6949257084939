import React from 'react';
//import PropTypes from 'prop-types';

import {FloatingSessionContext} from "./../context";
import {useViewportArea} from "./../hooks";

export function Viewport(props) {
    const {children} = props;

    const [pos, dropRef] = useViewportArea()

    return <div ref={dropRef} className={"floating-viewport"}>
        <FloatingSessionContext.Provider value={pos}>
            {children}
        </FloatingSessionContext.Provider>
    </div>
}

Viewport.propTypes = {};
Viewport.defaultProps = {};

export default Viewport;
