import {useCallback, useMemo} from "react";
import {useApiActions} from "state/hooks";
import {joinNode, leaveNode} from "joynt/state/actions/members";

export function useJoin() {
    const {
        onJoin,
        onLeave
    } = useApiActions({
        onJoin: joinNode,
        onLeave: leaveNode
    });

    const handleJoin = useCallback((from, to) => {
        onJoin(to, from);
    }, [onJoin]);

    return useMemo(()=>({
        onJoin: handleJoin,
        onLeave
    }), [handleJoin, onLeave]);
}