function makeDevicesList(mediaDevices) {
    const devices = {
        audioInput: [],
        audioOutput: [],
        videoInput: []
    };
    mediaDevices.forEach(device => {
        let kind = device.kind
            .replace('input', 'Input')
            .replace('output', 'Output');
        devices[kind].push({
            deviceId: device.deviceId,
            label: device.label
        })
    });
    return devices;
}

export async function enumerateDevices() {
    const stream = await navigator.mediaDevices
        .getUserMedia({audio: true, video: true});
    const devices = await navigator.mediaDevices
        .enumerateDevices();
    stream.getTracks()[0].stop();
    stream.getVideoTracks()[0].stop();
    stream.getAudioTracks()[0].stop();
    return makeDevicesList(devices);
}