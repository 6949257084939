import { getIn, setIn } from "lodash-redux-immutability";
import { createReducer } from "../helpers/create-reducer";

const actions = {
    "UI.SET_FLAG": (state, { payload }) => {
        const { id, value } = payload;
        if (typeof value === "function") {
            return setIn(state, [id], value(getIn(state, [id])));
        }
        return setIn(state, [id], value);
    },
    "UI.SET_FLAGS": (state, { payload: { data }}) => {
        return {...state, ...data};
    },
    "UI.NOTIFY": (state, { payload }) => {
        const { message, variant, notification: id } = payload;
        const notification = { message, status: variant, id };
        let nextState = setIn(state, ["snackbarNotifications"], [notification]);
        nextState = setIn(nextState, ["snackbar"], true);
        return nextState;
    },
    "UI.NOTIFICATIONS.ADD": (state, { payload }) => {
        const { id } = payload;
        let next = state.notifications.slice();
        if (next.indexOf(id) > -1) return state;
        next.push(id);
        return setIn(state, ["notifications"], next);
    },

    "UI.NOTIFICATIONS.HIDE": (state, { payload }) => {
        const { id } = payload;
        let next = state.notifications.filter(
            (activityId) => activityId !== id
        );
        return setIn(state, ["notifications"], next);
    },
    "UI.CONFIRM.SHOW": (state, { message }) => ({
        ...state,
        confirmOpen: true,
        confirmMessage: message,
    }),
    "UI.CONFIRM.HIDE": (state) => ({ ...state, confirmOpen: false }),
    "UI.PROMPT.SHOW": (state, { id }) => ({ ...state, prompt: id }),
    "UI.PROMPT.HIDE": (state) => ({ ...state, prompt: false }),
    "CMS.MEDIA.OPEN": (state) => ({ ...state, media: true }),
    "CMS.MEDIA.CLOSE": (state) => ({ ...state, media: false }),
    "UI.SET_SCREEN_SIZE": (state, { payload }) => {
        const prev = state.screenSize || {};
        return setIn(state, ["screenSize"], { ...prev, ...payload });
    },
};

export default createReducer(
    {
        snackbarNotifications: [],
        snackbar: false,
        confirmOpen: false,
        confirmMessage: "",
        media: false,
        progress: {},
        notifications: [],
        screenSize: {},
        joyride: {},
    },
    actions
);
