export const ENTITY_TYPE_NODE = "19309117-9b0f-4c13-902e-88f69ac76507";

export const NODE_TYPE_CHAT = "4396544c-b0dc-4e21-8114-df0a01f61b5d";
export const NODE_TYPE_FEED = "5a76b829-13b9-48ec-b221-cb59d2b7a2e5";
export const NODE_TYPE_THREADS = "c4b5f03d-1a79-4e5a-ac68-0842788f18f7";
export const NODE_TYPE_SPACES = "3984f510-4d27-4b4f-84da-e747f59d1c34";
export const NODE_TYPE_EVENT = "6eee17e8-0bcd-4f57-b7fc-e547eb4f0d40";
export const NODE_TYPE_PAGE = "1c2c3008-df4d-4882-93f9-9dcbcb7998be";
export const NODE_TYPE_SPACE = "5a941b4d-b53e-4e0d-ae74-ec6fb4eb1c43";
export const NODE_TYPE_EXTERNAL = "bc8b7cb3-66bb-4c31-967d-7dbef7f8e812";
export const NODE_TYPE_SESSIONS = "851ee7a6-acfc-40a9-a5cc-80cfdb8e7985";
export const NODE_TYPE_TAG = "4e348c82-111b-45be-9da6-5b5f6b2accab";
export const NODE_TYPE_PROJECT = "5fa8b606-795e-4e04-a0bf-c0c89dc587fb";

export const SPACE_TYPES = [
    NODE_TYPE_SPACE,
    NODE_TYPE_PROJECT,
    NODE_TYPE_EVENT,
];

export const POST_TYPE_THREAD = "b5faebe5-b5f6-47ec-9e66-2919a3edbae3";
export const POST_TYPE_QUOTE = "3a26ea82-8b67-43f3-aea8-3f8e305b61e6";
export const POST_TYPE_TEXT = "029b428e-eaa1-4bf2-95a8-0552c1f5f26b";
export const POST_TYPE_LINK = "cea8ad00-3d13-428f-a57d-96ee19758d4a";
export const POST_TYPE_POLL = "804d8e64-d59a-49f8-b67b-7b4d0b320232";
export const POST_TYPE_ALBUM = "353bb599-3fb1-4871-b2ed-0dd5b2f871a5";
export const POST_TYPE_POST = "87e5903b-2d01-45b6-a497-42933c9a8f83";
export const POST_TYPE_TODO = "f1f986da-7364-419f-8f00-83dd23b367c2";
export const POST_TYPE_WHITEBOARD = "7d4618ab-6cee-4e28-b7f1-5654fd741102";

export const LIST_VIEW_NODES = "6b157968-cdc4-4471-9b3c-c5e67670350c";
export const LIST_VIEW_STREAM = "5d13034b-4ef1-43db-bcfb-463a7ef3e313";
export const LIST_VIEW_INBOX = "bf6e1188-9381-4e70-b6a0-8f810f856918";
export const LIST_VIEW_ACTIVITY = "c3578339-d925-4a3d-91e0-e59dbc61582b";
export const LIST_VIEW_NOTIFICATIONS = "e6864810-f85d-4f04-beb9-1028a9328369";
export const LIST_VIEW_NODE_SEARCH = "5a8cf510-6470-4ffb-952b-611271b5f648";
export const LIST_VIEW_THREADS = "9de74f03-9566-4807-99ce-664b98204a3f";

export const POST_TYPE_FILTER_ID = "postType";

export const ROUTE_NODE_EDIT = "edit";
export const ROUTE_THREAD = "thread";
export const ROUTE_POST = "post";
export const ROUTE_SUBCHANNEL = "sch";
export const ROUTE_CHANNEL = "ch";
export const ROUTE_PREVIEW = "info";
export const ROUTE_IDENTITY_EDIT = "profile";
export const ROUTE_VIEW = "view";
export const ROUTE_MEDIA = "preview";
export const ROUTE_NAV = "nav";
export const ROUTE_PAGE = "page";
export const ROUTE_ID = "id";
export const ROUTE_UTILITY_NAV = "side";

export const PAGE_PREFIX_CREATE = "create";
export const PAGE_PREFIX_EDIT = "edit";

export const EVENT_ROLE_SPEAKER = "speaker";
export const EVENT_ROLE_ATTENDEE = "attendee";
export const EVENT_ROLE_ADMIN = "admin";
export const EVENT_ROLE_MODERATOR = "moderator";
export const EVENT_ROLE_OWNER = "owner";
export const EVENT_ROLE_RECORDING = "recording";
export const EVENT_ROLE_INVITED = "invited-on-stage";

export const SESSION_POLICY_DEFAULT = null;
export const SESSION_POLICY_EVENT = "event";
export const SESSION_POLICY_MEETING = "meeting";
export const SESSION_POLICY_OFFLINE = "offline";

export const EDGE_JOIN = "joined";

export const FLAG_CHAT_THREAD = "chat.thread";
export const FLAG_VISIBLE_CHATS = "chat.visibleChats";
export const FLAG_LAYOUT_COLLAPSE = "layout.collapse";
export const FLAG_UTILITY_NAV = "nav.utilityNavView";

export const FLAG_NEW_POST_ID = "newPostId";
export const FLAG_NEW_POST_TYPE = "newPostType";

export const FLAG_SESSION_ID = "meetingSessionId";
export const FLAG_SESSION_PENDING = "meetingPendingSession";
export const FLAG_SESSION_FLOATING = "session.isFloatingSession";
export const FLAG_SESSION_CONFIRM = "session.confirm";
export const FLAG_SESSION_RAISED_HAND = "session.hand";
export const FLAG_SESSION_STAGE_LAYOUT = "session.stageLayout";
export const FLAG_SESSION_NAV = "sessionUtilityNav";
export const FLAG_SESSION_OVERRIDES = "session.enableOverrides";

export const SCOPE_MASTER = "master";
export const SCOPE_SUPERADMIN = "apps.db";

const nodeTypes = {};

nodeTypes[NODE_TYPE_CHAT] = "chat";
nodeTypes[NODE_TYPE_FEED] = "feed";
nodeTypes[NODE_TYPE_THREADS] = "messages";
nodeTypes[NODE_TYPE_SPACES] = "spaces";
nodeTypes[NODE_TYPE_PAGE] = "page";
nodeTypes[NODE_TYPE_EVENT] = "event";
nodeTypes[NODE_TYPE_SPACE] = "space";
nodeTypes[NODE_TYPE_EXTERNAL] = "external";
nodeTypes[NODE_TYPE_SESSIONS] = "sessions";
nodeTypes[NODE_TYPE_TAG] = "feed";
nodeTypes[NODE_TYPE_PROJECT] = "space";

export const NODE_TYPE_VIEWS = nodeTypes;

export const STAGE_VIEW = "stage";

const listViews = {
    graph: LIST_VIEW_NODES,
    stream: LIST_VIEW_STREAM,
    inbox: LIST_VIEW_INBOX,
};

export const LIST_VIEWS = listViews;

export const DEFAULT_CARD_COLOR = "deep-purple";

export const DEFAULT_NETWORKING_TABLE_LIMIT = 4;
