import { selectEntity } from "state/selectors/data";
import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import RewireContext from "containers/context/RewireContext";
import RenderContext from "cms/context/RenderContext";
import { useActions, useData } from "state/hooks";
import { parseTemplateMapping } from "cms/util/templatable";
import { change } from "cms/state/actions/cms";

export const normalizeType = (type) => {
    if (type === "structured") return "layout";
    if (type === "contact-form") return "form";
    return type;
};

export const normalizeContextPath = (path) => {
    let [type, id] = path.split("/");
    let key = null;
    if (type.indexOf("@") > -1) {
        let parts = type.split("@");
        if (parts.length > 1) {
            key = parts[0];
            type = parts[1];
        }
    }
    return [type, id, key];
};

export const selectContexts = (store, context) => {
    let paths = context.split(":");
    let data = {};
    paths.forEach((path) => {
        let [type, id, key] = normalizeContextPath(path);
        let useKey = key || "default";
        data[useKey] = {
            ...selectEntity(store, type, id),
            __contextPath: [type, id].join("/"),
        };
    });
    return data;
};

function selectPaths(store, paths) {
    return paths.map((path) => {
        let [type, id] = normalizeContextPath(path);
        return selectEntity(store, type, id);
    });
}

function useRewireContexts(context) {
    let paths = useMemo(() => context.split(":"), [context]);
    let entities = useSelector((store) => selectPaths(store, paths));

    return useMemo(() => {
        let data = {};
        paths.forEach((path, i) => {
            let [type, id, key] = normalizeContextPath(path);
            let useKey = key || "default";
            data[useKey] = {
                ...entities[i],
                __contextPath: [type, id].join("/"),
            };
        });
        return data;
        // eslint-disable-next-line
    }, entities);
}

function useRewire(props) {
    const { type, id, itemId, onDelete, onDeleteItem, ...other } = props;
    const rewireContext = useContext(RewireContext);
    const renderMode = useContext(RenderContext);
    const data = useData(props);
    const rewire = props.rewire || data.template_id;

    const contexts = useRewireContexts(rewireContext);
    const mapping = useMemo(() => parseTemplateMapping(rewire), [rewire]);

    const mappedProps = useMemo(() => {
        const keys = Object.keys(mapping);
        const data = {};
        const rewireMap = {};
        keys.forEach((to) => {
            let from = mapping[to];
            let fromParts = from.split("@");
            let key = "default";
            if (fromParts.length > 1) {
                key = fromParts[0];
                from = fromParts[1];
            }
            if (contexts[key]) {
                /** Protect from recursion when rendering template section **/
                if (from === "items" && to === "components") {
                    if (contexts[key].template) {
                        data[to] = contexts[key][from];
                    }
                } else {
                    if (from.indexOf(".at.") > -1) {
                        let [atKey, atValue] = from.split(".at.");
                        if (
                            contexts[key][atKey] &&
                            contexts[key][atKey][atValue]
                        ) {
                            data[to] = [contexts[key][atKey][atValue]];
                        }
                        rewireMap[to] = [
                            contexts[key].__contextPath,
                            [atKey, atValue].join("/"),
                        ].join("/");
                    } else {
                        data[to] = contexts[key][from];
                        rewireMap[to] = [
                            contexts[key].__contextPath,
                            from,
                        ].join("/");
                    }
                }
                if (to === "items") {
                    data.item_type = from === "links" ? "cms.links" : null;
                }
            }
        });
        return {
            data,
            rewireMap,
        };
    }, [contexts, mapping]);

    const elementType = normalizeType(props.element_type || data.element_type);

    let inspectId = [type, id].join("/");
    if (itemId) inspectId = `${itemId}@${inspectId}`;

    const { onChange } = useActions({ onChange: change });
    const handleChange = useCallback(
        (key, value) => {
            let update = {};
            update[key] = value;

            if (onChange) onChange(type, id, update, rewire, rewireContext);
        },
        [rewireContext, rewire, type, id, onChange]
    );

    const components = useSelector((store) => {
        const input = data.components || [];
        return input.filter((id) => {
            const entity = selectEntity(store, "cms.components", id);
            return entity && !entity.disabled;
        });
    });

    const rewireProps = {
        entityType: type,
        entityId: id,
        ...other,
        ...data,
        ...mappedProps.data,
        components,
        rewireMap: mappedProps.rewireMap,
        element_type: elementType,
        rewire,
        rewireContext: rewire ? rewireContext : null,
        inspectId,
        renderMode,
    };

    return [rewireProps, renderMode === "edit" ? handleChange : null];
}

export const connectComponentWithHooks = (Decorated) => {
    const RewireDecorator = React.memo((props) => {
        const [rewireProps, onChange] = useRewire(props);
        if (rewireProps.renderMode !== "edit" && rewireProps.disabled)
            return null;
        return <Decorated {...props} {...rewireProps} onChange={onChange} />;
    });
    RewireDecorator.autoFillProps = Decorated.autoFillProps;
    return RewireDecorator;
};
