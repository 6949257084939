import React from "react";
//import PropTypes from 'prop-types';

import Resources from "playground/cms/views/Resources";
import { FormLayout } from "playground/cms/Form";
import PlanForm from "./PlanForm";

export function Plans(props) {
    const {} = props;
    return (
        <Resources {...props}>
            <FormLayout>
                <FormLayout.Content replace={true}>
                    <FormLayout.Tab id={"plan"} label={"Plan"}>
                        <PlanForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Resources>
    );
}

Plans.propTypes = {};

Plans.defaultProps = {};

export default Plans;
