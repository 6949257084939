import React from "react";
import IconButton from "joynt/components/IconButton";
//import PropTypes from 'prop-types';

export default function ProfileIcons(props) {
  const { twitter, linkedin } = props;
  return (
    <div className={"cols icons-inline"}>
      {linkedin ? (
        <IconButton
          size={"medium"}
          icon={"linkedin"}
          onClick={() => window.open(linkedin)}
        />
      ) : null}

      {twitter ? (
        <IconButton
          size={"medium"}
          icon={"twitter"}
          onClick={() => window.open(twitter)}
        />
      ) : null}
    </div>
  );
}

ProfileIcons.propTypes = {};
ProfileIcons.defaultProps = {};
