import React, {useContext} from "react";
import MockSessionContext from "joynt/meeting/mock/MockSessionContext";
import {audioTrack, videoTrack} from "joynt/meeting/mock/mocks";

function user(uid, media) {
    let user = {remoteUser: {uid}}
    if (!media) return user;

    if (media === "audio") user.remoteUser.audioTrack = audioTrack();
    if (media === "video") user.remoteUser.videoTrack = videoTrack();
    user.mediaType = media;

    return user;
}

export default function ProfileSettings(props) {
    const {id} = props;
    const event = useContext(MockSessionContext);
    const fullJoin = () => {
        event('user-joined', user(id));
        event('user-published', user(id, 'audio'));
        event('user-published', user(id, 'video'));
    }

    return <div className={'rows gap-sm'}>
        <div className={'cols gap-xs'}>
            <div onClick={fullJoin}>join</div>
            <div onClick={()=>event('user-left', user(id))}>leave</div>
        </div>
        <div className={'cols gap-xs'}>
            <div>audio:</div>
            <div onClick={()=>event('user-published', user(id, 'audio'))}>on</div>
            <div onClick={()=>event('user-unpublished', {remoteUser: {uid: id, audioTrack: null}, mediaType: 'audio'})}>off</div>
        </div>
        <div className={'cols gap-xs'}>
            <div>video:</div>
            <div onClick={()=>event('user-published', user(id, 'video'))}>on</div>
            <div onClick={()=>event('user-unpublished', {remoteUser: {uid: id, videoTrack: null}, mediaType: 'video'})}>off</div>
        </div>
        <div className={'cols gap-xs'}>
            <div>video:</div>
            <div onClick={()=>event('volume-indicator', {result: [{uid: id, level: 1.1}]})}>speak</div>
        </div>
    </div>
}