import React from "react";

//import PropTypes from 'prop-types';

export function DrawerNavGroup(props) {
    const { children } = props;

    return <div className={"drawer-nav-group"}>{children}</div>;
}

DrawerNavGroup.propTypes = {};
DrawerNavGroup.defaultProps = {};

export default DrawerNavGroup;
