import React from "react";
import { Search } from "playground/cms/List/Filter";

//import PropTypes from 'prop-types';

export function ProjectsFilter(props) {
    const {} = props;
    return (
        <div>
            <Search type={"console.services"} name={"search"} />
        </div>
    );
}

ProjectsFilter.propTypes = {};

ProjectsFilter.defaultProps = {};

export default ProjectsFilter;
