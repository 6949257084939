import React, { useEffect } from "react";
//import PropTypes from 'prop-types';

import Fetch from "containers/Fetch";

import ChannelView from "joynt/ChannelView";
import AcceptInvite from "joynt/modals/AcceptInvite";
import ChatPopup from "joynt/components/messages/chat/ChatPopup";
import MeetingContainer from "joynt/meeting/MeetingContainer";
import ToastNotifications from "joynt/notifications";

import { useFeatureEnabled, useNodeExists } from "joynt/hooks";

import NodeSelectContainer from "joynt/modals/NodeSelect";
import { usePending } from "state/hooks";
import NoAccess from "joynt/NoAccess";
import { useNodeChannels, useRouteNodes } from "joynt/hooks/channels";

import { PresenceChannel, UserChannel } from "joynt/websockets";

import Joyride from "joynt/components/Help/Joyride";
import ProfileInfoOverlay from "joynt/participants/ProfileInfoOverlay";
import Tracking from "joynt/tracking";
import UploadOverlay from "components/upload/UploadOverlay";
import ReactionsOverlay from "joynt/components/Reactions/ReactionsOverlay";
import Snackbar from "joynt/components/Snackbar";
import PresenceState from "joynt/websockets/presence/PresenceState";
import NotificationStatusOverlay from "joynt/notifications/NotificationStatusOverlay";
import { NetworkState, ActiveState } from "joynt/network-state";
import EchoProvider from "joynt/websockets/EchoProvider";
import MediaProvider from "joynt/media";
import SettingsPage from "joynt/modals/SettingsPage";
import { UserSettingsDrawer } from "joynt/components/UserSettings";
import Sidebar from "joynt/components/Sidebar";
import { PostPreview } from "joynt/components/Post";
import UtilityNav from "joynt/components/UtilityNav";
import FloatingSession from "joynt/components/FloatingSession";
import AppLayout from "joynt/components/AppLayout";
import { NodeInfoOverlay } from "joynt/components/NodeInfo";
import { JoyrideProvider } from "joynt/components/Help/Joyride";
import { usePresenceId } from "joynt/hooks";
import { FEATURE_OPTIMIZED_API } from "joynt/config/features";
import CirclesLayout from "joynt/components/Circles";
import useScreenSize from "util/hooks/useScreenSize";
import RealTimeChannel from "joynt/websockets/RealTimeChannel";
import ErrorPage from "joynt/ErrorPage";
import { ScreenVisibilityProvider } from "joynt/components/ScreenVisibility";

function composeWrappers(wrappers) {
    return wrappers.reduceRight((Acc, Current) => {
        return (props) =>
            React.createElement(
                Current,
                props,
                React.createElement(Acc, props)
            );
    });
}

const JoyntWrapper = composeWrappers([
    Tracking,
    ActiveState,
    EchoProvider,
    NetworkState,
    MediaProvider,
    (props) => (
        <>
            <PresenceChannelContainer />
            <UserChannel />
            {/*<RealTimeChannel id={props.id} />*/}
            <UploadOverlay />
            {props.children}
        </>
    ),
    ReactionsOverlay,
    NotificationStatusOverlay,
    (props) => (
        <MeetingContainer id={props.id}>{props.children}</MeetingContainer>
    ),
    ProfileInfoOverlay,
    NodeInfoOverlay,
    (props) => (
        <>
            {props.children}
            <PresenceState />
            <ToastNotifications />
            <PostPreview />
            <Snackbar />
        </>
    ),
]);

function PresenceChannelContainer(props) {
    const presenceId = usePresenceId();
    return <PresenceChannel id={presenceId}>{props.children}</PresenceChannel>;
}

function FetchNode({ id, url, fetchId, ...other }) {
    return (
        <Fetch
            type={"db.nodes"}
            url={url}
            enable={!!id}
            fetchId={fetchId}
            {...other}
        />
    );
}

function JoyntAppContainer(props) {
    const { Component, url, ...other } = props;
    const routeNodes = useRouteNodes();
    const isPending = usePending(["pending", "workspace", "logout"]);

    const { id, channel } = routeNodes;

    const workspaceUrl = url || `v2/joynt/nodes/${id}/workspace`;
    const isDev = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const channelUrl = isDev ? `v2/joynt/nodes/${channel}` : `joynt/nodes/:id`;

    return (
        <>
            <FetchNode
                id={id}
                url={workspaceUrl}
                fetchId={"workspace"}
                critical={true}
                ErrorComponent={ErrorPage}
            >
                {!props.disableFetch && (
                    <>
                        <FetchNode
                            id={channel}
                            url={channelUrl}
                        />
                    </>
                )}
                <JoyntWrapper id={id}>
                    <Component
                        {...routeNodes}
                        {...other}
                        pending={isPending}
                    />
                </JoyntWrapper>
            </FetchNode>
        </>
    );
}

JoyntAppContainer.defaultProps = {
    Component: JoyntApp,
};

export default JoyntAppContainer;

function JoyntApp(props) {
    const { id, rootNode, channel, subchannel, pending, requireProfile } =
        props;

    const { type: view } = useNodeChannels(id, channel);

    const exists = useNodeExists(id);

    const { isDesktop } = useScreenSize();

    useEffect(() => {
        if (requireProfile) {
            let welcomeUrl = `/welcome`;
            if (id) welcomeUrl += `?id=${id}`;
            if (window.location.href.indexOf("welcome") > -1) return;
            if (!rootNode) window.location.href = welcomeUrl;
        }
    }, [rootNode, id, requireProfile]);

    if (!rootNode && requireProfile) return null;

    const noAccessView = (
        <AppLayout>
            <NoAccess />
        </AppLayout>
    );

    if (!exists && !pending) return noAccessView;

    let currentNode = subchannel || channel || id;

    return (
        <>
            <NodeSelectContainer />
            <AcceptInvite
                id={id}
                root={rootNode}
                noAccessView={noAccessView}
                isPending={pending}
            >
                <ScreenVisibilityProvider visible={true}>
                    {isDesktop && <Joyride id={"welcome"} />}
                    {isDesktop && <JoyrideProvider />}
                    <ChatPopup />
                    <UserSettingsDrawer />
                    <SettingsPage />
                    <FloatingSession>
                        <CirclesLayout>
                            <AppLayout
                                id={id}
                                isPending={pending || view === "pending"}
                                sidebarNav={
                                    <Sidebar id={id} channel={channel} />
                                }
                                utilityNav={
                                    <UtilityNav
                                        workspace={id}
                                        id={currentNode}
                                    />
                                }
                            >
                                <ChannelView
                                    id={id}
                                    channel={channel}
                                    subchannel={subchannel}
                                    view={view}
                                />
                            </AppLayout>
                        </CirclesLayout>
                    </FloatingSession>
                </ScreenVisibilityProvider>
            </AcceptInvite>
        </>
    );
}

JoyntApp.defaultProps = {
    requireProfile: true,
};
JoyntApp.propTypes = {};
