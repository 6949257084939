import React from "react";
//import PropTypes from 'prop-types';

import Attribute from "./Attribute";
import AttributeLayout from "./AttributeLayout";
import AttributeInput from "./AttributeInput";

import "./style.css";

function CreateAttribute(props) {
    const { onCreate } = props;
    return (
        <AttributeLayout
            name={
                <AttributeInput
                    value={""}
                    onChange={onCreate}
                    edit={true}
                    placeholder={"name"}
                />
            }
        />
    );
}

export function Attributes(props) {
    const { items, onCreate, ...rest } = props;
    return (
        <div className={"attr-table"}>
            {items.map((attr) => (
                <Attribute
                    key={[attr.name, attr.index].join(":")}
                    {...rest}
                    {...attr}
                />
            ))}
            <CreateAttribute onCreate={onCreate} />
        </div>
    );
}

Attributes.propTypes = {};

Attributes.defaultProps = {
    items: [],
};

export default Attributes;
