import React from "react";
import { RENDER_MODE_EDIT } from "cms/context/RenderContext";
//import PropTypes from 'prop-types';

export function renderTemplate(template, props) {
    if (typeof template !== "string") return null;
    if (!template) return "";
    return template.replace(/{([a-zA-z.-]*)}/gm, (match, p1, p2, p3) => {
        return props[p1] || null;
    });
}

export default class Template extends React.PureComponent {
    render() {
        const { template, renderMode } = this.props;
        const edit = renderMode === RENDER_MODE_EDIT;

        if (template && template.indexOf("iframe") !== -1) {
            return <div>iframe</div>;
        }

        if (template && template.indexOf("script") !== -1) {
            return <div>script</div>;
        }

        return edit ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: template,
                }}
            />
        ) : (
            <div
                dangerouslySetInnerHTML={{
                    __html: renderTemplate(template, this.props),
                }}
            />
        );
    }
}

Template.propTypes = {};
Template.autoFillProps = ["template"];
