import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";
import Icon from "components/Icon";

export function CalendarEvent(props) {

    const {
        left,
        width,
        name,
        onClick
    } = props;

    return (<div
        className={"calendar-event"}
        style={{left}}
        onClick={onClick}
    >
        <div className={"calendar-event__track"} style={{width}} />
        <div className={"calendar-event__label"}>
            <Icon>mui-videocam</Icon>
            <span>{name}</span>
        </div>
    </div>);

}

CalendarEvent.propTypes = {};
CalendarEvent.defaultProps = {};

export default CalendarEvent;
