import React from "react";
//import PropTypes from 'prop-types';

import ConfirmJoin from "./ConfirmJoin";
import { useActions, useUiFlag } from "state/hooks";
import { confirm, deny } from "joynt/state/actions/meetings";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import useScreenSize from "util/hooks/useScreenSize";

export function ConfirmJoinContainer(props) {
    const [message] = useUiFlag(`session.confirm`);

    const messageProps = message ? message : {};

    const actions = useActions({
        onConfirm: confirm,
        onDeny: deny,
    });

    const { onLeaveSession } = useMeeting();
    const { isMobile } = useScreenSize();

    const { reason } = messageProps;

    const leaveAction = ["invite", "join-request"].includes(reason)
        ? actions.onDeny
        : onLeaveSession;

    return (
        <ConfirmJoin
            {...props}
            {...actions}
            {...messageProps}
            onLeave={leaveAction}
            showPreview={!isMobile}
        />
    );
}

ConfirmJoinContainer.propTypes = {};
ConfirmJoinContainer.defaultProps = {};

export default ConfirmJoinContainer;
