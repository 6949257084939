import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import SpaceHeader from "joynt/components/SpaceHeader/SpaceHeader";
import StageSchedule from "./StageSchedule";

import "./style.css";

export function WorkspaceStage(props) {

    const {
        id,
        //isPending,
        isJoining,
        isConnected,
        canEdit,
        onShowSettings,
        children
    } = props;

    return (<div className={cn("workspace-stage", {
        "is-joining": isJoining || isConnected,
        "is-joined": isConnected
    })}>
        <SpaceHeader
            id={id}
            name={"Workspace stage"}
            tabs={false}
            onEdit={onShowSettings}
            canEdit={canEdit}
        />
        <StageSchedule id={id} />
        {children}
    </div>);

}

WorkspaceStage.propTypes = {
    id: PropTypes.string,
    isPending: PropTypes.bool,
    isJoining: PropTypes.bool,
    canEdit: PropTypes.bool,
    onShowSettings: PropTypes.func
};
WorkspaceStage.defaultProps = {
    isPending: false,
    isJoining: false,
    canEdit: false,
};

export default WorkspaceStage;
