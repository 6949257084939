import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {useActions, useData} from "state/hooks";
import {ArrayIterator} from "components/list/iterator";
import List from "containers/List";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import Icon from "components/Icon";
import {uploadCancel} from "state/actions/upload";
import IconButton from "components/IconButton";
import filesize from "filesize";
//import PropTypes from 'prop-types';

const statuses = {
    'in-progress': 'Uploading...',
    'init': 'Initializing...',
    'cancelled': 'Cancelled',
    'failed': 'Failed'
};

function UploadStatus(props) {
    const {id} = props;
    const {media, file, size, status} = useData({type: 'app.uploads', id});
    const progress = useSelector(s=>s.progress[media]);
    const {cancel} = useActions({cancel: uploadCancel});
    const cancelClick = useCallback(()=>cancel(id), [id, cancel]);
    const ended = ['cancelled', 'failed'].indexOf(status) > -1;

    return <div className={'cols gap-sm pad-sm w-lg'}>
        <div>
            <Icon>mui-cloud_upload</Icon>
        </div>
        <div className={'rows grow gap-xs'}>
            <div>Uploading {file}</div>
            { !ended ? <LinearProgress
                value={progress || 0}
                variant={'determinate'}
            /> : null }
            <div className={'type-sm cols gap-xs'}>
                {progress ? <div>{progress}% of</div> : null}
                <div>{filesize(size)} {size}</div>
                <div>{statuses[status]}</div>
            </div>
        </div>
        { status === 'in-progress' ? <IconButton onClick={cancelClick} icon={'mui-close'} /> : null }
    </div>
}

const UploadStatusIterator = ArrayIterator(UploadStatus);

export default function UploadOverlay(props) {

    /**
     * In bytes, only show if any of the files is bigger than this value
     * @type {number}
     */
    const minSize = 4 * 1000000;

    const [uploads, show] = useSelector(s=>{
        let all = s.data['app.uploads'] || {};
        let show = false;
        let totalSize = 0;
        Object.values(all).forEach(upload => {
            totalSize += upload.size;
        })
        if (totalSize > minSize) show = true;
        return [Object.keys(all), show];
    });

    return (<Dialog open={show}>
        <List
            type={'app.uploads'}
            items={uploads}
            Iterator={UploadStatusIterator}
        />
    </Dialog>);

}

UploadOverlay.propTypes = {};
UploadOverlay.defaultProps = {};
