import React from "react";
import IconButton from "joynt/components/IconButton";
//import PropTypes from 'prop-types';
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";

export default function PhoneHeader(props) {
    const {
        onGoBack,
        onToggleMenu,
        onShowUserSettings,
        isOpen,
        parentName,
        title,
        identity,
        children,
    } = props;

    return (
        <div className={"phone-header cols cols-middle cols-spread"}>
            <div className={"cols cols-middle gap-xs"}>
                {onGoBack && (
                    <IconButton
                        size={"medium"}
                        icon={"mui-arrow_back"}
                        onClick={onGoBack}
                    />
                )}
                {!onGoBack && onToggleMenu && (
                    <IconButton
                        onClick={onToggleMenu}
                        size={"medium"}
                        icon={isOpen ? "mui-menu_open" : "mui-menu"}
                    />
                )}
                {children}
                <div className={"rows gap-px"}>
                    {parentName && (
                        <div className={"type-sm o-50"}>{parentName}</div>
                    )}
                    <div className={"type-default phone-header__display-name"}>
                        {title}
                    </div>
                </div>
            </div>
            {identity && (
                <div className={"cols"}>
                    <button onClick={onShowUserSettings}>
                        <Avatar id={identity} />
                    </button>
                </div>
            )}
        </div>
    );
}

PhoneHeader.propTypes = {};
PhoneHeader.defaultProps = {};
