import React from "react";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

import Note from "@material-ui/icons/Note";
import BorderInner from "@material-ui/icons/BorderInner";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Link from "@material-ui/icons/Link";
import CropFree from "@material-ui/icons/CropFree";
import CloudDone from "@material-ui/icons/CloudDone";
import LibraryAddCheck from "@material-ui/icons/LibraryAddCheck";
import CloudUpload from "@material-ui/icons/CloudUpload";

import { selectEntity } from "state/selectors/data";
import { useSelector } from "react-redux";

const icons = {
    "mui-note": Note,
    "mui-border_inner": BorderInner,
    "mui-library_books": LibraryBooks,
    "mui-link": Link,
    "mui-crop_free": CropFree,
    "mui-cloud_done": CloudDone,
    "mui-library_add_check": LibraryAddCheck,
    "mui-cloud_upload": CloudUpload,
    "library-imported": CloudDone,
};

function selectSectionIcon(store, id) {
    const data = selectEntity(store, "cms.sections", id);
    const {
        type: sectionType,
        template,
        reference_id,
        library_export,
        used_in_ref,
    } = data;

    let templateData = {};
    if (template) templateData = selectEntity(store, "cms.sections", template);

    let icon = "mui-note";
    if (sectionType === "view") icon = "mui-border_inner";
    if (sectionType === "placeholder") icon = "mui-crop_free";
    if (template) icon = "mui-library_books";
    if (template && templateData.reference_id) icon = "mui-library_add_check";
    if (reference_id) icon = "mui-cloud_done";
    if (library_export) icon = "mui-cloud_upload";
    if (library_export && used_in_ref) icon = "library-imported";

    return icon;
}

export function SectionIcon(props) {
    const { id } = props;
    const icon = useSelector((store) => selectSectionIcon(store, id));

    const IconComponent = icons[icon] || icons["mui-note"];

    if (icon === "library-imported") {
        return (
            <div className={"section-imported-ref"}>
                <IconComponent />
            </div>
        );
    }

    return <IconComponent />;
}

SectionIcon.propTypes = {};

SectionIcon.defaultProps = {
    data: {},
};

export default SectionIcon;
