import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import PropTypes from "prop-types";

import { getDaysInMonthUTC } from "./util";
import Day from "./Day";
import MonthSelect from "./MonthSelect";

import "./style.css";
import useSizeObserver from "util/hooks/useSizeObserver";
import Icon from "components/Icon";
import RangeNav from "joynt/components/Calendar/RangeNav";

function OutlinedIconButton(props) {
    const { icon, onClick } = props;

    return (
        <div className={"icon-button icon-button--outlined"} onClick={onClick}>
            <Icon>{icon}</Icon>
        </div>
    );
}

function findDay(days, day) {
    let index = 0;
    days.forEach((d, i) => {
        if (d.getTime() === day) index = i;
    });
    return index;
}

function DailyTimeline(props) {
    const { month, year, value, count, onChange } = props;

    const days = getDaysInMonthUTC(month, year);
    const currentDayIndex = findDay(days, value);

    const dayWidth = 51;

    const outerRef = useRef();
    const innerRef = useRef();

    const [sizeOuter, setSizeOuter] = useState(0);
    const [sizeInner, setSizeInner] = useState(0);
    const [page, setPage] = useState(0);

    const resizeOuter = useCallback(
        (s) => {
            setSizeOuter(s[0].contentRect.width);
        },
        [setSizeOuter]
    );

    useSizeObserver({ callback: resizeOuter, element: outerRef });

    const resizeInner = useCallback(
        (s) => {
            setSizeInner(s[0].contentRect.width);
        },
        [setSizeInner]
    );

    useSizeObserver({ callback: resizeInner, element: innerRef });

    const pages = Math.ceil(sizeInner / sizeOuter);
    const sizeOuterNavPadding = 120;
    const sizeOuterWithNav =
        page + 1 === pages ? sizeOuter : sizeOuter - sizeOuterNavPadding;

    const offset =
        page + 1 === pages
            ? 0 - (sizeInner - sizeOuterWithNav)
            : 0 - page * sizeOuterWithNav;

    const dayPage = Math.min(
        Math.max(
            0,
            Math.ceil(
                (dayWidth * currentDayIndex) / (sizeOuter - sizeOuterNavPadding)
            ) - 1
        ),
        pages - 1
    );

    useEffect(() => {
        setPage(dayPage);
    }, [sizeOuter, sizeInner, setPage, dayPage]);

    const s = useMemo(() => ({ transform: `translateX(${offset}px` }), [
        offset,
    ]);

    return (
        <div>
            <div ref={outerRef} className={"timeline-days"}>
                <div
                    ref={innerRef}
                    className={"cols timeline-days__content"}
                    style={s}
                >
                    {days.map((day) => (
                        <Day
                            key={day.toString()}
                            date={day}
                            isSelected={day.getTime() === value}
                            onClick={(d) => onChange(d.getTime())}
                            count={count[day.getTime() / 1000]}
                        />
                    ))}
                </div>
                <div className={"timeline-days__nav"}>
                    {page > 0 && (
                        <div
                            className={"prev"}
                            onClick={() => setPage(page - 1)}
                        >
                            <OutlinedIconButton icon={"mui-chevron_left"} />
                        </div>
                    )}
                    {page + 1 < pages && (
                        <div
                            className={"next"}
                            onClick={() => setPage(page + 1)}
                        >
                            <OutlinedIconButton icon={"mui-chevron_right"} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export function DailySchedule(props) {
    const { value, count, onChange } = props;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const [currentDay, setDay] = useState(
        value ? value * 1000 : today.getTime()
    );

    const setDayTs = useCallback((d) => setDay(d * 1000), [setDay]);

    const current = new Date(currentDay);
    const currentMonth = current.getMonth();
    const currentYear = current.getFullYear();

    useEffect(() => {
        if (onChange) onChange(currentDay / 1000);
    }, [onChange, currentDay]);

    const setMonth = useCallback(
        (m, y) => {
            let newDate = new Date();
            const currentMonth = newDate.getMonth();
            const currentDay = newDate.getDate();

            newDate.setFullYear(y);

            if (currentMonth === m) {
                newDate.setDate(currentDay);
            } else {
                newDate.setDate(1);
            }
            newDate.setMonth(m);
            newDate.setHours(0, 0, 0, 0);
            setDay(newDate.getTime());
        },
        [setDay]
    );

    return (
        <div className={"rows gap-sm"}>
            <div className={"grid grid-3"}>
                <div className={"cols cols-left cols-middle"}>
                    <RangeNav
                        day={currentDay / 1000}
                        mode={"day"}
                        onChange={setDayTs}
                    />
                </div>
                <div className={"cols cols-center cols-middle"}>
                    <MonthSelect value={currentMonth} onChange={setMonth} />
                </div>
            </div>
            <DailyTimeline
                month={currentMonth}
                year={currentYear}
                value={currentDay}
                onChange={setDay}
                count={count}
            />
        </div>
    );
}

DailySchedule.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
};
DailySchedule.defaultProps = {
    count: {},
};

export default DailySchedule;
