import React from 'react';
import PropTypes from 'prop-types';
import SidebarBreadcrumbs from "../SidebarBreadcrumbs";
import SidebarNodeItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/SidebarItem.container";
import SidebarHeader from "../SidebarHeader";
import {useEdgesData, useNodeData} from "joynt/hooks";

export function Header(props) {

    const {id, view, viewId, onClick} = props;
    const {description} = useNodeData(id);
    const {parent_workspace} = useEdgesData(id);
    const isSubworkspace = !!parent_workspace;

    return (<SidebarHeader
        subtitle={description}
    >
        {isSubworkspace && <SidebarBreadcrumbs
            id={id}
            onClick={onClick}
        />}
        <SidebarNodeItem
            id={id}
            onClick={onClick}
            isActive={viewId && view === viewId}
            showArrow={false}
        />
    </SidebarHeader>);

}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;