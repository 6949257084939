import React, { useContext, useEffect, useMemo } from "react";
import { SessionContext } from "joynt/meeting/agora-ng/context/SessionContext";
import useSessionEvents from "joynt/meeting/hooks/useSessionEvents";
import { AgoraContext } from "joynt/meeting/agora-ng/context/AgoraContext";
import { useMediaDevices } from "joynt/meeting/devices";
import useSessionSync from "joynt/meeting/hooks/useSessionSync";
import useAutoJoin from "joynt/meeting/hooks/useAutoJoin";
import SessionConfigProvider from "joynt/meeting/agora-ng/SessionConfigProvider";
//import PropTypes from 'prop-types';

function hasTracks(tracks, type) {
    return tracks.filter((t) => t.trackMediaType === type).length;
}

function SessionSync(props) {
    const { joined, user, channel, children } = props;
    const connection = useContext(AgoraContext);

    const { selectedDevices, availableDevices, onSelectDevice } =
        useMediaDevices();

    useEffect(() => {
        if (connection)
            connection.setDevices({
                selected: selectedDevices,
                available: availableDevices,
                select: onSelectDevice,
            });
    }, [connection, selectedDevices, availableDevices, onSelectDevice]);

    useAutoJoin(channel);

    useSessionSync({
        channel,
        user,
        joined,
    });

    return children;
}

function SessionContextProvider(props) {
    const { joined, access, error, children } = props;
    const { user, channel } = access;

    const connection = useContext(AgoraContext);

    const cfg = useMemo(
        () => ({
            channel: access.channel,
            user: access.user,
            access: access,
        }),
        [access]
    );

    const { localTracks, users, activeSpeakers, handleEvent } =
        useSessionEvents({ user, channel });

    useEffect(() => {
        connection.bindEventHandler(handleEvent);
    }, [connection, handleEvent]);

    useEffect(() => {
        if (!error) connection.switch(cfg);
    }, [connection, error, cfg]);

    useEffect(() => {
        return () => {
            connection.leave();
        };
    }, [connection]);

    const context = useMemo(
        () => ({
            audio: hasTracks(localTracks, "audio"),
            video: hasTracks(localTracks, "video"),
            localTracks,
            users,
            activeSpeakers,
            channel,
        }),
        [localTracks, users, activeSpeakers, channel]
    );

    return (
        <SessionContext.Provider value={context}>
            {joined ? (
                <SessionSync {...props} user={user} channel={channel}>
                    {children}
                </SessionSync>
            ) : (
                children
            )}
        </SessionContext.Provider>
    );
}

SessionContextProvider.propTypes = {};
SessionContextProvider.defaultProps = {};

export default function SessionContextConfigProvider(props) {
    const { access } = props;
    const { channel } = access;

    return (
        <SessionConfigProvider channel={channel}>
            <SessionContextProvider {...props} />
        </SessionConfigProvider>
    );
}
