import React from "react";
import { connect } from "react-redux";

import Notifications from "containers/Notifications";
import ApiContext from "containers/context/ApiContext";
import Modal from "containers/ModalStack";
import MediaPreview from "containers/dialogs/MediaPreview";
import ConfirmDialog from "containers/dialogs/Confirm";
import { selectAppApiContext } from "state/selectors/app";
import { withApiContext } from "containers/context/ApiContext";
import Snackbar from "components/Snackbar";
//import Messaging from "containers/messages/Messaging";
import Activity from "containers/Activity";
import LinkShare from "components/dialogs/LinkShare";
import Calls from "containers/Calls";
import MediaEdit from "components/dialogs/MediaEdit";
import MediaBrowserDrawer from "cms/media-browser/MediaBrowserDrawer";
import ApartmentsImportDialog from "cms/apartments/ApartmentsImportDialog";

const mapState = (store, props) => {
    return {
        apiContext: selectAppApiContext(store, props),
    };
};

const mapDispatch = {};

class App extends React.PureComponent {
    render() {
        const { apiContext, children } = this.props;
        return (
            <ApiContext.Provider value={apiContext}>
                <Notifications namespace={".notification"}>
                    {children}
                </Notifications>
                <Calls />
                <Modal />
                <MediaPreview />
                <ConfirmDialog />
                <Snackbar />
                <Activity />
                <LinkShare />
                <MediaBrowserDrawer />
                <MediaEdit />
                <ApartmentsImportDialog />
            </ApiContext.Provider>
        );
    }
}

export default withApiContext()(connect(mapState, mapDispatch)(App));
