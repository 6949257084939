import { useCallback } from "react";
import { useRouter } from "joynt/hooks/channels";
import { useEdgesData, useMessaging } from "joynt/hooks";
import { useActions, useData } from "state/hooks";
import useSessionLink from "joynt/meeting/hooks/useSessionLink";
import { setFlag } from "state/actions/ui";

/**
 * Handle notification action
 *
 * @param id
 * @returns {{onClick: (function(): void)}}
 */
export function useNotificationAction(id) {
    const { onLink } = useRouter();
    const { onShowChat } = useMessaging({});
    const data = useData({ type: "db.notifications", id });
    const activity = useData({ type: "db.activity", id: data.activity });
    const { resource_id: subject } = activity;
    const { parent_node, notification_type } = data;
    const parent = parent_node ? parent_node.id : null;
    const { onLinkToSession } = useSessionLink(subject);
    const { onSetUiFlag } = useActions({ onSetUiFlag: setFlag });
    const parentEdges = useEdgesData(parent);
    const { parent: stemParent } = parentEdges;

    const messageAction = onShowChat; //onLink

    const click = useCallback(() => {
        switch (notification_type) {
            case "message":
                messageAction(parent);
                break;
            case "message.dm":
                messageAction(parent);
                break;
            case "message.mention.all":
                messageAction(parent);
                break;
            case "message.mention":
                messageAction(parent);
                break;
            case "message.comment":
                onLink(parent);
                break;
            case "message.comment.mention":
                onLink(parent);
                break;
            case "post-published":
                onLink(subject);
                break;
            case "post-published.link":
                onLink(subject);
                break;
            case "session.start":
                onLinkToSession();
                break;
            case "post-published.question":
                onSetUiFlag(`session.qa.reply.${parent}`, null);
                onSetUiFlag("session.sidebarTab", "qa");
                break;
            case "message.question.reply":
                onSetUiFlag(`session.qa.reply.${stemParent}`, parent);
                onSetUiFlag("session.sidebarTab", "qa");
                break;
            case "joined":
                break;
            case "session.reminder":
                break;
            default:
                onLink(parent);
                break;
        }
    }, [
        notification_type,
        parent,
        messageAction,
        onLink,
        subject,
        onLinkToSession,
        onSetUiFlag,
        stemParent,
    ]);

    return {
        onClick: click,
    };
}
