import React, { useState } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import { useData } from "state/hooks";
import { createPortal } from "react-dom";

import "./style.css";

import { Helmet } from "react-helmet";

import SectionForm from "playground/cms/views/Sections/SectionForm";
import { useRouteParams } from "state/hooks/useRouteParams";
import ChangesSubmit from "cms/ui/containers/ChangesSubmit";
import { SectionBrowser } from "playground/cms/views/Sections";
import ComponentTree from "./ComponentTree";
import EntityContext from "containers/context/EntityContext";
import { useSelector } from "react-redux";
import { findRoot } from "state/selectors/tree";
import Toolbar from "playground/designer/Toolbar";
import ComponentEditor from "./ComponentEditor";

import { useEditorState } from "./EditorState";
import { OverlayForm } from "playground/cms/Form";
import SectionsList from "playground/designer/SectionsList";
import DragSectionPrompt from "playground/cms/views/Sections/DragSectionPrompt";
import { useCurrentService } from "playground/cms/hooks";
import CommandPalette from "playground/cms/Command/Command";
import ThemeSettings from "playground/designer/ThemeSettings";
import PageLayout from "./PageLayout";
import StyleEditorContainer from "./StyleEditor";
import { DebugConsoleProvider } from "playground/debug";

export function Designer(props) {
    const { onClose, isVisible } = props;

    const website = useCurrentService();

    const [state, setState] = useEditorState();
    const { focus, focusType, focusId, mode, section, outline, style } = state;

    const pushRoute = useRouteParams();
    const [showSections, setShowSections] = useState(false);

    const data = useData({ type: "cms.sections", id: section });
    const templateData = useData({ type: "cms.sections", id: data.template });
    //const treeSection = templateData.reference_id || data.template || section;

    const handleGoBack = () => pushRoute({ view: null });

    const stack = [];
    stack.push(section);
    if (data.template) stack.push(data.template);
    if (templateData.reference_id) stack.push(templateData.reference_id);

    const root = useSelector((store) => findRoot(store, focusId, focusType));
    let rootSection = root.id || section;
    if (focusType === "cms.sections") rootSection = focusId;

    const focusSection = (id) =>
        setState({ focusType: `cms.sections`, focusId: id });

    const handleFocus = (type, id) => {
        setState({
            focus: [type, id].join("/"),
            iteratorRoot: null,
            item: null,
        });
    };

    const handleEdit = () => setState({ edit: true });
    const handleCancel = () => setState({ edit: false });

    if (!isVisible) return null;

    return (
        <>
            <Helmet defer={false}>
                <title>zuu.designer v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc&amp;libraries=places"></script>
                <body
                    className={`theme-${
                        mode === "design" ? "dark" : "light"
                    } default cms-theme designer-page`}
                />
            </Helmet>

            <ChangesSubmit>
                {createPortal(
                    <div className={`designer-layout ${mode}`}>
                        <Toolbar
                            state={state}
                            setState={setState}
                            onGoBack={handleGoBack}
                        />
                        <div className={"designer-content"}>
                            <div
                                className={cn("designer-left", {
                                    outline: outline,
                                })}
                            >
                                {outline ? (
                                    <>
                                        <SectionsList
                                            state={state}
                                            setState={setState}
                                        />
                                        {section &&
                                            mode === "design" &&
                                            state.edit && (
                                                <ComponentEditor
                                                    state={state}
                                                    setState={setState}
                                                    onClose={handleCancel}
                                                />
                                            )}
                                    </>
                                ) : (
                                    <>
                                        {section && mode === "design" && (
                                            <>
                                                <ComponentTree
                                                    section={section}
                                                    type={"cms.sections"}
                                                    id={rootSection}
                                                    focusType={focusType}
                                                    focusId={focusId}
                                                    onFocus={handleFocus}
                                                    onEdit={handleEdit}
                                                />
                                                {state.edit && (
                                                    <ComponentEditor
                                                        state={state}
                                                        setState={setState}
                                                        onClose={handleCancel}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {section && mode === "content" && (
                                            <SectionForm
                                                type={"cms.sections"}
                                                id={section}
                                                disableFetch={true}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <div
                                className={cn("designer-canvas", {
                                    [`bp-${state.breakpoint}`]:
                                        state.breakpoint,
                                })}
                            >
                                <PageLayout
                                    state={state}
                                    setState={setState}
                                    website={website?.id}
                                />
                            </div>
                            {mode === "design" && (
                                <div className={"designer-right"}>
                                    <EntityContext.Provider
                                        value={[focusType, focusId].join("/")}
                                    >
                                        {state.focusType && state.focusId && (
                                            <StyleEditorContainer
                                                state={state}
                                                setState={setState}
                                            />
                                        )}
                                    </EntityContext.Provider>
                                </div>
                            )}
                        </div>
                    </div>,
                    document.body
                )}
            </ChangesSubmit>
            <SectionBrowser
                open={state.sections}
                onClose={() => setState({ sections: false })}
                pageType={state.pageType}
            />
            <DragSectionPrompt />
            <OverlayForm
                open={state.files}
                onClose={() => setState({ files: false })}
                position={"right"}
                width={"lg"}
            >
                {state.files && <ThemeSettings />}
            </OverlayForm>
            <CommandPalette />
        </>
    );
}

Designer.propTypes = {};

Designer.defaultProps = {
    isVisible: true,
};

export default Designer;
