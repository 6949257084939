import React from 'react';
import {connect} from 'react-redux';
import ListView from "containers/ListView";
import IconButtonGroup from "components/IconButtonGroup";
import IconButton from "components/IconButton";
import {selectEdges} from "joynt/state/selectors/joint";
import {isPending} from "state/selectors/ui";
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";
import {ListItemIterator} from "joynt/modals/NodeListItem";

const mapStateToProps = (store, props) => {
    const list = props.list || 'db.nodes.graph';
    return {
        graphView: selectRouteParam(store, 'g') || 'graph',
        parentId: selectEdges(store, props.selected || props.id).parent,
        list: list,
        graphPending: isPending(store, ['db.nodes',list].join('/'))
    }
};

const mapDispatchToProps = {
    pushRouteParams: pushRouteParams
};

const nodesViewOptions = [
    {
        value: 'nodes.graph',
        icon: 'mui-device_hub'
    },
    {
        value: 'nodes.tiles',
        icon: 'mui-view_module'
    },
    {
        value: 'nodes.list',
        icon: 'mui-view_list'
    }
];

class NodesView extends React.PureComponent {

    toggleGraph = () => {
        const { graphView, pushRouteParams } = this.props;
        pushRouteParams({g: graphView === 'graph' ? 'list' : null});
    };

    nodeClick = (nodeId) => {
        const { id, onNavigateToNode, graphView } = this.props;

        if (id === nodeId) {
            this.toggleGraph();
            return;
        }

        onNavigateToNode(nodeId);
        if (graphView === 'list') this.toggleGraph();
    };

    /**
     * Only drafts can be deleted, after navigating
     * to parent new list is fetched and draft nodes
     * disappear
     */
    nodeDelete = () => {
        const {
            onNavigateToNode,
            parentId
        } = this.props;
        onNavigateToNode(parentId);
    };

    render() {
        const {
            id,
            parentId,
            graphPending,
            onNavigateToNode,
            onEditNode,
            onDeleteNode,
            onChangeListView,
            list,
            nodesView,
            view,
            onNavigateHome,
            showFilter,
            boundary,
            isDraftNode,
            depth,
            ...other
        } = this.props;

        let graphUrl = `joynt/nodes/${id}/find?`;
        if (boundary) graphUrl += `&graph-boundary=${boundary}`;
        if (depth) graphUrl += `&graph-depth=${depth}`;

        return <>
            { showFilter ? <div className={'cols cols-spread'}>
                <IconButton icon={'mui-home'} onClick={onNavigateHome} />
                <IconButtonGroup
                    items={nodesViewOptions}
                    onToggle={onChangeListView}
                    value={nodesView}
                />
            </div> : null }
            <ListView
                type={'db.nodes'}
                view={view}
                filter={nodesView !== 'nodes.graph'
                    ? 'nodes.graph'
                    : null}
                viewType={nodesView}
                list={list}
                onClick={this.nodeClick}
                requireView={!view}
                selected={id}
                parent={parentId}
                pendingNode={graphPending ? id : null}
                url={graphUrl}
                onEdit={onEditNode}
                onDelete={this.nodeDelete}
                {...other}
                views={null}
                Iterator={ListItemIterator}
            />
        </>
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NodesView);