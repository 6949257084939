import React, { useContext } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import Page from "cms/containers/Page";
import FetchPageData from "cms/FetchPageData";
import ThemeShadow from "playground/theme/ThemeShadow";
import RenderContext, {
    RENDER_MODE_PREVIEW,
    RENDER_MODE_EDIT,
} from "cms/context/RenderContext";
import FocusManager from "./FocusManager";
import { ArrayIterator } from "components/list/iterator";
import ConnectedSection from "cms/containers/Section";
import { SortableIterator } from "components/dnd";
import { useEditorState } from "playground/designer/EditorState";

function EditableSection(props) {
    const [state] = useEditorState();
    const mode = useContext(RenderContext);
    const isSectionFocused = state.section === props.id;
    const targetMode =
        mode === RENDER_MODE_EDIT && isSectionFocused
            ? RENDER_MODE_EDIT
            : RENDER_MODE_PREVIEW;
    return (
        <RenderContext.Provider value={mode}>
            <ConnectedSection {...props} />
        </RenderContext.Provider>
    );
}

export const SortableSectionIterator = SortableIterator(ConnectedSection);
const ArraySectionIterator = ArrayIterator(EditableSection);

export function PageLayout(props) {
    const { state, setState, website } = props;
    const { pageType, pageId, options } = state;

    const sort = (result) => console.log(result);
    const drag = (result) => console.log(result);

    const bodyClassName = cn("theme-scope ready", {
        "debug-x-ray": options.domXray,
        "debug-outline": options.domOutline,
        "debug-hide-overlays": options.hideOverlays,
        "show-nav": options.showNav,
        "cms-page": true,
    });

    const mode = options.inlineEdit ? RENDER_MODE_EDIT : RENDER_MODE_PREVIEW;

    const page = (
        <Page
            id={pageId}
            type={pageType}
            editable={true}
            // onSortEnd={sort}
            // onDragSection={drag}
            // onCreateSection={drag}
            bodyClassName={bodyClassName}
            SectionIterator={ArraySectionIterator}
        />
    );

    return (
        <FetchPageData>
            <ThemeShadow
                hostType={options.host}
                websiteId={website}
                options={options}
            >
                <RenderContext.Provider value={mode}>
                    <FocusManager state={state} setState={setState}>
                        {page}
                    </FocusManager>
                </RenderContext.Provider>
            </ThemeShadow>
        </FetchPageData>
    );
}

PageLayout.propTypes = {};

PageLayout.defaultProps = {};

export default PageLayout;
