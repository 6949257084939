import React from "react";
import { useActions, useData } from "state/hooks";
import cn from "classnames";
import Icon from "components/Icon";
import { change } from "state/actions/data";

//import PropTypes from 'prop-types';

const MediaThumbnail = React.forwardRef((props, ref) => {
    const { type, id, onRemove, onClick, FilePreview, isDragging } = props;
    const { file, type: mediaType, embed } = useData(props);
    const remove = (e) => {
        e.stopPropagation();
        onRemove(id);
    };
    const click = () => onClick([type, id].join("/"));
    const { onChange } = useActions({ onChange: change });
    const toggleEmbed = (e) => {
        e.stopPropagation();
        onChange(type, id, { embed: !embed });
    };
    return (
        <div
            ref={ref}
            className={cn("media-thumbnail", {
                "is-dragging": isDragging,
            })}
            onClick={click}
        >
            <FilePreview file={file} mediaType={mediaType} />
            <div className={"action-btns"}>
                <div onClick={remove} className={"action-btn remove-btn"}>
                    <Icon>mui-close</Icon>
                </div>
                {/*<div*/}
                {/*    onClick={toggleEmbed}*/}
                {/*    className={cn("action-btn embed-btn", {*/}
                {/*        "is-active": embed,*/}
                {/*    })}*/}
                {/*>*/}
                {/*    <Icon>mui-download</Icon>*/}
                {/*</div>*/}
            </div>
        </div>
    );
});

export default MediaThumbnail;
