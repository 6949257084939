import React, {useContext, useEffect, useState} from 'react';
import {useMouseActive} from "util/hooks/useMouseActive";
//import PropTypes from 'prop-types';

const ActiveStateContext = React.createContext(null);

export function useActiveState() {
    return useContext(ActiveStateContext);
}

function useDocumentVisibility() {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        const listener = () => {
            setVisible(document.visibilityState === 'visible');
        }
        document.addEventListener('visibilitychange', listener);
        return () => {
            document.removeEventListener('visibilitychange', listener);
        };
    });
    return visible;
}

export function ActiveState(props) {

    const {children} = props;

    const visible = useDocumentVisibility();
    const {active} = useMouseActive(3000);

    return (<ActiveStateContext.Provider value={visible && active}>
        {children || null}
    </ActiveStateContext.Provider>);

}

ActiveState.propTypes = {};
ActiveState.defaultProps = {};
