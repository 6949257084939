import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import UserSettings from "./UserSettings";
import { useActions, useHasScope, useRouteParam } from "state/hooks";
import { logout } from "state/actions/auth";
import {useFeatureEnabled, useJoin, usePublicView} from "joynt/hooks";
import { ROUTE_ID, SCOPE_SUPERADMIN } from "joynt/config";
import { FEATURE_DEV } from "joynt/config/features";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";

export function UserSettingsContainer(props) {
    const [workspace] = useRouteParam(ROUTE_ID);
    const [view, setView] = useState(null);
    const isAdmin = useHasScope(SCOPE_SUPERADMIN);
    const isDev = useFeatureEnabled(FEATURE_DEV);
    const {isPublicView} = usePublicView();
    const [, setUtilityNavView] = useUtilityNav();

    const { onLogout } = useActions({
        onLogout: logout,
    });

    const { onLeave } = useJoin();

    const handleLeave = useCallback(() => {
        setUtilityNavView(null);
        onLeave(workspace);
    }, [workspace, onLeave, setUtilityNavView]);

    const handleLogout = useCallback(() => {
        setUtilityNavView(null);
        onLogout();
    }, [onLogout, setUtilityNavView]);

    return (
        <UserSettings
            {...props}
            isAdmin={isAdmin}
            isPublicView={isPublicView}
            isDev={isDev}
            workspaceId={workspace}
            view={view}
            onLogout={handleLogout}
            onLeave={handleLeave}
            onChangeView={setView}
        />
    );
}

UserSettingsContainer.propTypes = {
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
};
UserSettingsContainer.defaultProps = {
    isAdmin: false,
};

export default UserSettingsContainer;
