import React from "react";
import { connect } from "react-redux";
import Query from "cms/components/Query";
import Fetch from "containers/Fetch";
import { takeProps } from "util/takeProps";
import { serializeQueryParams } from "util/uri";
import { MergeRewireContext } from "containers/context/RewireContext";
import { selectEntity } from "state/selectors/data";

export const queryProps = [
    "id",
    "query_type",
    "query_filter",
    "entry_type",
    "filter",
    "repository",
    "integration",
    "tags",
    "enum_definition",
    "query_spec.tags",
];

function getSectionIdFromCtx(ctx) {
    return ctx
        ?.split(":")
        ?.find((s) => s.startsWith("cms.sections"))
        ?.split("/")
        ?.pop();
}

const mapStateToProps = (store, props) => {
    const params = takeProps(queryProps, props);
    const ctxId = getSectionIdFromCtx(props.rewireContext) + "-" + props.id;
    params.id = ctxId;
    if (params["query_spec.tags"]) {
        params.tags = params["query_spec.tags"];
        delete params["query_spec.tags"];
    }
    const alias = props.alias || "query";
    const result = selectEntity(store, "cms.query", ctxId);
    const isFind = params.query_type === "find" || !Array.isArray(result.items);
    let resultId = result.items ? result.items[0] : null;
    if (isFind && !Array.isArray(result.items)) resultId = result.items;

    const path = isFind
        ? resultId
            ? [alias, ["cms.items", resultId].join("/")].join("@")
            : null
        : [alias, ["cms.query", ctxId].join("/")].join("@");

    return {
        url: "cms/query?" + serializeQueryParams(params),
        type: "cms.tags",
        contextPath: path,
    };
};

const mapDispatchToProps = {};

class QueryContainer extends React.PureComponent {
    render() {
        const { url, contextPath } = this.props;

        return (
            <Fetch type={"cms.query"} url={url}>
                <MergeRewireContext value={contextPath}>
                    <Query {...this.props} />
                </MergeRewireContext>
            </Fetch>
        );
    }
}

const ConnectedQuery = connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryContainer);

QueryContainer.autoFillProps = Query.autoFillProps;
ConnectedQuery.autoFillProps = QueryContainer.autoFillProps;

export default ConnectedQuery;
