import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import Icon from "components/Icon";

import "cms/ui/css/ui-icon-button.css";
import { Tooltip } from "@material-ui/core";

export default class IconButton extends React.PureComponent {
  render() {
    const { icon, value, checked, onClick } = this.props;
    return (
      <Tooltip title={value}>
        <div
          onClick={onClick}
          className={cn("ui-icon-btn", {
            checked: checked,
          })}
        >
          <Icon>{icon}</Icon>
        </div>
      </Tooltip>
    );
  }
}

IconButton.propTypes = {};
