import React, {useCallback, useMemo} from 'react';
//import PropTypes from 'prop-types';

import moment from "moment";
import Icon from '@material-ui/core/Icon';
import {useData} from "state/hooks";
import {useApiActions} from "state/hooks";
import {post} from "state/actions/api";
import Button from "components/Button";
import {ListItem} from "components/list/items/ListItem";
import Avatar from "components/Avatar";

const statuses = {
    valid: {
        color: 'light-green',
        icon: 'https'
    },
    expires_soon: {
        color: 'amber',
        icon: 'warning'
    },
    expired: {
        color: 'red',
        icon: 'no_encryption'
    },
    'no-certificate': {
        color: 'red',
        icon: 'error'
    },
    none: {
        color: null
    }
};

const calculateStatus = (validToTimestamp, enable) => {
    if (!enable) return statuses.none;

    let statusId = 'valid';
    const now = moment().format('X') * 1;
    const validTo = validToTimestamp * 1;
    if (!validTo) return statuses['no-certificate'];
    const warning = moment().add('days', 5).format('X')*1;
    if (validTo < warning) {
        statusId = 'expires_soon';
    }
    if (validTo <= now) {
        statusId = 'expired'
    }
    return statuses[statusId];
};

function useDomainCertificate(id) {
    const {
        certificate_valid_to,
        secure_free
    } = useData({type: 'console.domains', id});

    const {
        onRenew
    } = useApiActions({
        onRenew: post
    });

    const renewUrl = `console/domains/${id}/renew`;

    const renew = useCallback(() => {
        onRenew(renewUrl, null, id);
    }, [onRenew, renewUrl, id]);

    const status = useMemo(() => {
        return calculateStatus(certificate_valid_to, secure_free)
    }, [certificate_valid_to, secure_free]);

    const validTo = secure_free && certificate_valid_to
        ? moment(certificate_valid_to * 1000)
        : null;

    return {
        status,
        validTo,
        onRenew: renew
    }
}

export function DomainListItem(props) {
    return <ListItem
        {...props}
        Avatar={DomainAvatar}
        subtitle={<DomainInfo id={props.id} />}
    />;
}

export function DomainAvatar({active, id, icon}) {
    const {status} = useDomainCertificate(id);

    return <Avatar
        active={active}
        color={status.color}
        icon={status.icon || icon}
    />;
}

export function DomainInfo({id}) {
    const {status, validTo} = useDomainCertificate(id);
    if (!validTo) return null;
    return <span className={status.color}>{validTo.fromNow()}</span>;
}

export default function DomainCertificate(props) {

    const {
        status,
        validTo,
        onRenew
    } = useDomainCertificate(props.id);

    if (!status) return null;

    const icon = <Icon fontSize="inherit" className={status.color}>
        {status.icon}
    </Icon>;

    return (<div className={'cols cols-middle cols-spread'}>
        <div className={'cols gap-sm'}>
            <div>{icon}</div>
            <div className={'o-50'}>
                Certificate expires: {validTo ? validTo.fromNow() : 'no certificate'}
            </div>
        </div>
        <div>
            <Button onClick={onRenew}>Renew certificate</Button>
        </div>
    </div>);

}

DomainCertificate.propTypes = {};
DomainCertificate.defaultProps = {};
