import React from "react";
import PropTypes from "prop-types";

import DashboardTile from "./../DashboardTile";
import "./style.css";
import { useMoment } from "joynt/components/DateTimePicker/hooks";
import Button from "components/Button";
import Slider from "joynt/components/Slider";
import Icon from "components/Icon";

function TaskTimeSlot(props) {
    const { onClick, time, format } = props;
    const m = useMoment(time);

    return (
        <div className={"task-time-slot"}>
            <div className={"task-time-slot__label"}>
                <div className={"task-time-slot__icon"}>
                    <Icon>mui-person_pin</Icon>
                </div>
                <div className={"task-time-slot__time"}>{m.format(format)}</div>
            </div>
            <div className={"task-time-slot__button"}>
                <Button color={"default"} onClick={onClick}>
                    Create
                </Button>
            </div>
        </div>
    );
}

TaskTimeSlot.defaultProps = {
    format: "HH:mm",
};

export function TaskSlots(props) {
    const { title, subtitle, items, onCreate } = props;

    return (
        <DashboardTile color={"success"} className={"witness-time-slots"}>
            <div className={"witness-time-slots__header"}>
                <div className={"witness-time-slots__title"}>{title}</div>
                <div className={"witness-time-slots__subtitle"}>{subtitle}</div>
            </div>
            <div className={"witness-time-slots__list"}>
                <Slider arrows={true} items={2.5}>
                    {items.map((time) => {
                        return (
                            <TaskTimeSlot
                                key={time}
                                time={time}
                                onClick={() => onCreate(time)}
                            />
                        );
                    })}
                </Slider>
            </div>
        </DashboardTile>
    );
}

TaskSlots.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onCreate: PropTypes.func,
};

TaskSlots.defaultProps = {
    items: [],
    title: "Today's available Witness Task slots",
    subtitle: "Pick most suitable time slot and create paired Witness Task",
};

export default TaskSlots;
