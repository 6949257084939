import React from "react";
import Popover from "components/Popover";
import Icon from "components/Icon";
import SelectList, { SelectListItem } from "playground/ui/SelectList";

//import PropTypes from 'prop-types';

export function ComponentModeSelect(props) {
    const { value, onChange, options } = props;

    return (
        <Popover
            trigger={({ onClick }) => (
                <div
                    onClick={onClick}
                    className={"input-dense cols cursor-pointer"}
                >
                    <div className={"cols cols-middle cols-spread grow"}>
                        <div className={"grow"}>{options[value]}</div>
                        <div>
                            <Icon>mui-more_vert</Icon>
                        </div>
                    </div>
                </div>
            )}
            content={({ onClose }) => (
                <SelectList onClick={onClose}>
                    {Object.keys(options).map((m) => (
                        <SelectListItem key={m} onClick={() => onChange(m)}>
                            {options[m]}
                        </SelectListItem>
                    ))}
                </SelectList>
            )}
        />
    );
}

ComponentModeSelect.propTypes = {};

ComponentModeSelect.defaultProps = {
    options: {},
};

export default ComponentModeSelect;
