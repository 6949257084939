import { useNodeData } from "joynt/hooks";
import { useMemo } from "react";

export function useSessionParticipants(id) {
    const { roles_data, event_max_participants } = useNodeData(id);

    const confirmed = roles_data?.confirmed || {};
    const identities = confirmed.identities || [];

    return useMemo(
        () => ({
            identities,
            count: identities.length,
            max: event_max_participants,
        }),
        [identities, event_max_participants]
    );
}
