import React from "react";

export function MessageTime({ time, format }) {
    let timeToRender = time.fromNow();

    if (format === "short") {
        if (timeToRender === "a few seconds ago") {
            timeToRender = "now";
        } else {
            timeToRender = timeToRender
                .replace("ago", "")
                .replace("minutes", "min")
                .replace("hours", "h")
                .replace("a day", "1 day")
                .replace("a month", "1 month")
                .replace("an hour", "1 h")
                .trim();
        }
    }

    return <span title={time.format("D MMM, H:mm")}>{timeToRender}</span>;
}

export function renderMentions(content, asText) {
    if (!content?.replace) return content;

    let parsed = content;
    parsed = parsed.replace(/\n/g, "<br />");
    parsed = parsed.replace(
        // eslint-disable-next-line
        /@\[([^\[\]]+)]\(([A-Za-z\d-]+)\)/g,
        function (...m) {
            if (asText) return "@" + m[1];
            if (m[2] === "all") return "<span data-mention>@all</span>";
            return (
                '<span data-mention data-id="' + m[2] + '">@' + m[1] + "</span>"
            );
        }
    );
    return parsed;
}
