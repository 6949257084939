import {useSessionPresence} from "joynt/meeting/hooks/useSessionPresence";
import {useEffect} from "react";
import {useCallControls, useRTMControls} from "joynt/meeting/agora-ng/hooks";
import {useSessionState} from "joynt/meeting/hooks/useSessionState";

export default function useSessionSync(props) {
    const {channel, user, joined} = props;

    const {
        publishAudio: audio,
        publishVideo: video,
        setAudio,
        setVideo,
        raisedHand
    } = useSessionState(channel);

    const {toggleVideo, toggleAudio} = useCallControls();
    const {onUpdatePresence} = useSessionPresence(channel);
    const {setChannelUserAttribute} = useRTMControls();

    useEffect(() => {
        async function fn() {
            return await toggleAudio(audio);
        }
        if (joined) fn().then(() => null);
    }, [joined, audio, toggleAudio, setAudio]);

    useEffect(() => {
        async function fn() {
            return await toggleVideo(video);
        }
        if (joined) fn().then(() => null);
    }, [joined, video, toggleVideo, setVideo]);

    useEffect(() => {
        const userData = {
            connected: !!joined,
            audio: joined && !!audio,
            video: joined && !!video
        }
        if (joined && (audio || video)) {
            userData.timestamp = Date.now();
        }
        onUpdatePresence({
            [user]: userData
        })
    }, [
        user,
        joined,
        audio,
        video,
        onUpdatePresence
    ]);

    useEffect(() => {
        if (joined) {
            setChannelUserAttribute(user, 'hand', !!raisedHand)
                .then(() => null);
        }
    }, [setChannelUserAttribute, joined, user, raisedHand]);
}