import { useNodeData } from "joynt/hooks";
import { useCallback, useMemo } from "react";
import { useField } from "state/hooks";

export function useTask(id) {
    const { name } = useNodeData(id);
    const { value: isCompleted, onChange } = useField(
        "db.nodes",
        id,
        "task_complete"
    );
    const onToggleCompleted = useCallback(() => {
        onChange(!isCompleted);
    }, [onChange, isCompleted]);

    return useMemo(
        () => ({
            name,
            isCompleted,
            onToggleCompleted,
        }),
        [name, isCompleted, onToggleCompleted]
    );
}
