import React from 'react';
// import PropTypes from 'prop-types';
import Icon from "components/Icon";

export function SessionInfoDate({ start, finish }) {
    if (!start) return null;

    const date = new Date(start);

    const day = `${date.getDate()} ${date.toLocaleString("default", {
        month: "long",
    })} ${date.getFullYear()}`;
    let time = `${date.getHours()}:${
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
    }`;

    if (finish) {
        const finishDate = new Date(finish);
        time += ` — ${finishDate.getHours()}:${
            (finishDate.getMinutes() < 10 ? "0" : "") + finishDate.getMinutes()
        }`;
    }

    return (
        <div className={"session-description__date"}>
            <div>
                <Icon>mui-event</Icon> {day}
            </div>
            <div>
                <Icon>mui-schedule</Icon> {time}
            </div>
        </div>
    );
}

SessionInfoDate.propTypes = {};

SessionInfoDate.defaultProps = {};

export default SessionInfoDate;