import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export function SessionPreviewSection(props) {
    const { title, addon, children } = props;

    return (
        <div className={"session-preview-section"}>
            <div className={"session-preview-section__header"}>
                <div className={"session-preview-section__title"}>{title}</div>
                {addon && (
                    <div className={"session-preview-section__addon"}>
                        {addon}
                    </div>
                )}
            </div>
            <div className={"session-preview-section__content"}>{children}</div>
        </div>
    );
}

SessionPreviewSection.propTypes = {
    title: PropTypes.string,
    addon: PropTypes.element,
};
SessionPreviewSection.defaultProps = {};

export default SessionPreviewSection;
