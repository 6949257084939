import { select, put } from "redux-saga/effects";
import { selectRouteParam } from "state/selectors/router";
import { selectAliasedEntity } from "state/selectors/data";
import { request } from "util/api/client";
import { pending } from "state/actions/ui";

function* validatePageSlug(config, field, validationResult) {
    const { id, data, changes, isSubmit } = config;

    const changed = Object.keys(changes || {}).includes(field);
    const slug = data[field];

    if (!changed && !isSubmit) return;
    const requestId = isSubmit ? id : `${id}/validate`;
    yield put(pending(requestId, true));
    try {
        const service = yield select((store) => {
            const name = selectRouteParam(store, "service");
            const service = selectAliasedEntity(
                store,
                "console.services",
                name
            );
            return service.id;
        });
        const result = yield request({
            url: "cms/sitemap?slug=" + slug,
            method: "get",
            context: { project: service },
        });
        const isValid =
            result.data.data.length === 0 || result.data.data[0].id === id;
        if (!isValid) {
            validationResult[field] = "Strona o takim adresie już istnieje";
        }
    } catch (e) {
        yield put(pending(requestId, false));
        return false;
    }
    yield put(pending(requestId, false));
    return false;
}

const pages = {
    title: {
        required: true,
    },
    slug: {
        required: true,
        fn: validatePageSlug,
    },
};

export default {
    "cms.pages": { ...pages },
    "cms.entries.offers": { ...pages },
    "cms.entries.posts": { ...pages },
    "cms.entries.rooms": { ...pages },
};
