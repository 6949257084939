import React, {useCallback, useRef, useState} from 'react';
//import PropTypes from 'prop-types';

import {Player, Video, DefaultUi} from "@vime/react";
import useSizeObserver from "util/hooks/useSizeObserver";

// Default theme. ~960B
import '@vime/core/themes/default.css';

// Optional light theme (extends default). ~400B
import '@vime/core/themes/light.css';

import "./style.css";

function useVideoSize(ref) {

    const [size, setSize] = useState(null);

    const resize = useCallback(s => {
        setSize(`${s[0].contentRect.width}:${s[0].contentRect.height}`);
    }, [setSize]);

    useSizeObserver({
        callback: resize,
        element: ref
    });

    return size;

}

export default function VideoPlayer(props) {

    const { url } = props;
    const ref = useRef();

    const size = useVideoSize(ref);

    return <div ref={ref} className={"video-player"}>
        <Player autopause icons={"material"} aspectRatio={size}>
            <Video>
                <source src={url} />
            </Video>
            <DefaultUi
                noCaptions={true}
                noSettings={true}
            />
        </Player>
    </div>;

}

VideoPlayer.propTypes = {};
VideoPlayer.defaultProps = {};
