import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Fetch from "containers/Fetch";
import ParticipantGroup from "joynt/participants/ParticipantGroup";
import useParticipants from "joynt/participants/hooks/useParticipants";
import ParticipantsSearch from "joynt/participants/ParticipantsSearch";
import {useFilter} from "state/hooks";
import {useSelector} from "react-redux";

import "./style.css";
import {useSessionParticipants} from "joynt/hooks/sessions";
import {usePublicView} from "joynt/hooks";

export default function ParticipantListContainer(props) {
    const {parent} = props;
    const list = `participants.${parent}`;

    const enable = useSelector(s=>{
        return false; //!s.lists || s.lists['db.identities'][list] === undefined;
    });

    return <>
        <Fetch
            type={'db.identities'}
            list={list}
            url={`joynt/nodes/${parent}/participants`}
            enable={enable}
        />
        <ParticipantList {...props} list={list} />
    </>;
}

export function ParticipantList(props) {
    const {
        list,
        parent,
        event,
        id,
        showOffline,
        searchThreshold
    } = props;

    const filterId = ['db.identities', list].join('/');
    const {value: {search}} = useFilter(filterId);
    const resultList = search ? [list,'filter'].join('/') : list;
    const {connected, online, offline, total} = useParticipants(parent, resultList, id);
    const {identities} = useSessionParticipants(id);
    const {isPublicView} = usePublicView(id);

    const confirmed = useMemo(() => {
        return identities.filter(i => offline.includes(i));
    }, [identities, offline]);

    const visibleOffline = isPublicView && identities.length && !search ? confirmed : offline;

    const groupProps = {
        workspace: parent,
        parent: id,
        event
    }

    return <div className={"participant-list"}>
        {total > searchThreshold || search ? <ParticipantsSearch id={filterId} /> : null }
        <div className={"participant-list__content"}>
            <ParticipantGroup
                items={connected}
                label={`Session (${connected.length})`}
                isOnline={true}
                {...groupProps}
                session={id}
            />
            <ParticipantGroup
                items={online}
                label={'Online'}
                isOnline={true}
                {...groupProps}
            />
            {showOffline && <ParticipantGroup
                items={visibleOffline}
                label={'Offline'}
                isOnline={false}
                {...groupProps}
            />}
        </div>
    </div>;
}

ParticipantList.propTypes = {
    id: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
    showOffline: PropTypes.bool,
    searchThreshold: PropTypes.number,
};

ParticipantList.defaultProps = {
    showOffline: true,
    searchThreshold: 16
};
