import React from "react";
import { connect } from "react-redux";

import Page from "cms/Page";
import { withData } from "containers/Entity";
import { selectAliasedEntity, selectFilteredItems } from "state/selectors/data";
import { MergeRewireContext } from "containers/context/RewireContext";

const selectPageLayout = (store, type, page) => {
    if (type === "cms.layouts") return null;

    let settings = selectAliasedEntity(
        store,
        "cms.settings",
        "@websiteSettings"
    );
    let typeLayoutsIds = settings.default_layouts || [];
    let matchingLayouts = selectFilteredItems(
        store,
        "cms.layout-ref",
        typeLayoutsIds,
        (layout) => {
            return layout.entry_type === page.type;
        }
    );
    if (matchingLayouts.length) return matchingLayouts[0].layout;
    if (page.type === "offers" && settings.default_layout_offers) {
        return settings.default_layout_offers;
    }
    return settings.default_layout;
};

const mapStateToProps = (store, props) => {
    return {
        defaultLayout: selectPageLayout(store, props.type, props.data),
        layout: props.type === "cms.layouts" ? props.id : props.layout,
    };
};

const mapDispatchToProps = {};

class PageContainer extends React.Component {
    sortEnd(...args) {
        console.log("sortEnd", args);
    }

    dragSection(...args) {
        console.log("dragSection", args);
    }

    render() {
        const { onSortEnd, onDragSection, type, id, editable, ...other } =
            this.props;

        if (!id) return null;

        let path = "page@" + [type, id].join("/");

        return (
            <MergeRewireContext value={path}>
                <Page
                    {...other}
                    type={type}
                    id={id}
                    onSortEnd={editable ? onSortEnd : null}
                    onDragSection={editable ? onDragSection : null}
                />
            </MergeRewireContext>
        );
    }
}

PageContainer.autoFillProps = Page.autoFillProps;

export default withData(
    connect(mapStateToProps, mapDispatchToProps)(PageContainer)
);
