import React from 'react';
//import PropTypes from 'prop-types';
import emojis from "./emojis";
import {useClick} from "state/hooks";

function Emoji(props) {
    const {id} = props;
    const click = useClick(props);
    return <div className={'emoji-list__emoji'} onClick={click}>
        {emojis[id]}
    </div>
}

export default function EmojiPicker(props) {
    const {onSelect} = props;
    return <div className={'emoji-list'}>
        {Object.keys(emojis).map(key => {
            return <Emoji key={key} id={key} onClick={onSelect} />
        })}
    </div>
}

EmojiPicker.propTypes = {};
EmojiPicker.defaultProps = {};
