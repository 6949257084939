import React from "react";

import MuiIconButton from "@material-ui/core/IconButton";
import cn from "classnames";
import Icon from "components/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.css";

const IconSizeMapper = (size) => {
    switch (size) {
        case "inherit":
            return size;
        case "small":
            return 18;
        case "medium":
            return 24;
        case "large":
            return 30;
        default:
            return "inherit";
    }
};

const IconButton = ({
    icon,
    label,
    size,
    onClick,
    className,
    children,
    selected,
    isPending,
    withTooltip,
    selectable,
    disabled,
    ...other
}) => {
    const hasLabel = !withTooltip && label;

    let iconSize;

    if (typeof size === "number") iconSize = size;
    else iconSize = IconSizeMapper(size);

    const ButtonBody = () => (
        <div
            onClick={onClick}
            className={cn(
                "icon-button",
                selected && "icon-button--selected",
                !hasLabel && "icon-button--round",
                hasLabel && "icon-button--labeled",
                selectable && "icon-button--selectable",
                disabled && "icon-button--disabled",
                className
            )}
        >
            <div
                className={"icon-button__icon-wrapper"}
                style={{ fontSize: iconSize }}
            >
                <MuiIconButton
                    className={"icon-button__mui-button"}
                    color={"inherit"}
                    {...other}
                >
                    {isPending && (
                        <div className={"icon-button__loader"}>
                            <CircularProgress
                                thickness={2}
                                disableShrink={true}
                                size={
                                    typeof iconSize === "number"
                                        ? iconSize + 16
                                        : 40
                                }
                            />
                        </div>
                    )}
                    <Icon fontSize={"inherit"}>{icon}</Icon>
                </MuiIconButton>
            </div>
            {hasLabel && (
                <p className={"icon-button__label"}>{children || label}</p>
            )}
        </div>
    );

    return withTooltip ? (
        <Tooltip title={label}>
            <div>
                <ButtonBody />
            </div>
        </Tooltip>
    ) : (
        <ButtonBody />
    );
};

IconButton.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    /**
    Icon size (width and height) in pixels or string values:
   inherit, small, medium, large
 */
    size: PropTypes.oneOf([
        PropTypes.number,
        "inherit",
        "small",
        "medium",
        "large",
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    /**
   When true, Icon will be opaque 50% opaque unless hovered or active.
   */
    selectable: PropTypes.bool,
};

IconButton.defaultProps = {
    size: "inherit",
    round: false,
};

export default IconButton;
