import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {useEdgesData, useFeatureEnabled, useJoin, useNodeData} from "joynt/hooks";
import {usePending} from "state/hooks";
import Dialog from '@material-ui/core/Dialog';
import Button from "components/Button";
import {useRouter} from "joynt/hooks/channels";
import {EDGE_JOIN, EVENT_ROLE_OWNER} from "joynt/config";
import Preloader from "components/Preloader";
import {FEATURE_DEV} from "joynt/config/features";

function AcceptInviteModal(props) {
    const { data, open, onJoin, joinIn, isPending } = props;
    const { name } = data; //user_role

    const isDev = useFeatureEnabled(FEATURE_DEV);
    const {onLink} = useRouter();
    //const {name: joinInName} = useNodeData(joinIn);

    const join = useCallback(() => onJoin(joinIn), [onJoin, joinIn]);
    const select = useCallback(() => onJoin(null), [onJoin]);

    return <Dialog open={open} width={'md'} fullWidth={true}>
        <Preloader visible={isPending} />
        <div className={'pad-lg rows rows-center gap-md brand-bg-alt brand-bg--rev'}>
            <div className={'type-heading-sm text-center'}>Invitation</div>
            <div>You've been invited to join <strong>{name}</strong></div>
            <div className={'cols cols-center gap-xs'}>
                { isDev && joinIn ? <Button
                    variant={'text'}
                    onClick={()=>onLink(joinIn)}
                >
                    Later
                </Button> : null }
                { isDev
                    ? <Button
                        onClick={select}
                        color={'default'}
                        size={'large'}
                    >
                        Join in
                    </Button>
                    : null }
                { joinIn
                    ? <Button
                        onClick={join}
                        size={'large'}
                    >Join now</Button>
                    : null }
            </div>
        </div>
    </Dialog>
}

export default function AcceptInvite(props) {

    const { id, isPending, root, children, noAccessView } = props;

    const data = useNodeData(id);
    const {root_joined} = useEdgesData(id);
    const {user_role, can_view, can_join} = data;
    const pending = usePending(['edge',EDGE_JOIN, id].join('.'));

    const {onJoin} = useJoin();

    const join = useCallback((from)=>{
        onJoin(from, id);
    }, [onJoin, id]);

    const hasAccess = !!can_view && (!!root_joined || user_role === EVENT_ROLE_OWNER);
    const joinIn = root; //nodeRoot === id ? root : parent || root;

    if (!hasAccess && !can_join && isPending) return <Preloader
        visible={true}
    />;

    if (!hasAccess && !can_join) return <div>
        {noAccessView}
    </div>;

    return <>
        <AcceptInviteModal
            id={id}
            joinIn={joinIn}
            data={data}
            open={!hasAccess}
            onJoin={join}
            isPending={pending}
        />
        {hasAccess ? children : null }
    </>;

}

AcceptInvite.propTypes = {
    id: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired
};