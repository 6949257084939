import { useActions } from "state/hooks";
import { openMediaSelectCallback } from "state/actions/media";

export default function useSelectMedia(onChange) {
    const { onShowMedia } = useActions({
        onShowMedia: openMediaSelectCallback,
    });

    return () => {
        onShowMedia((result) => {
            const selectedMedia = result?.payload?.id;
            if (selectedMedia)
                onChange({
                    link_ref: {
                        type: "media",
                        resource_id: selectedMedia,
                        url: null,
                        link_anchor: null,
                    },
                });
        });
    };
}
