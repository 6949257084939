import React, {useEffect, useState, useCallback} from 'react';
import {connect, useDispatch} from 'react-redux';
import {importTypography, loadWebfont} from "cms/state/actions/theme";
import Popover from "components/Popover";
import fontSources from 'config/font-sources';
import Fetch from "containers/Fetch";
import List from "containers/List";
import {VirtualizedListIterator} from "components/list/virtualized";
import {withData} from "containers/Entity";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "components/fields/select/Select";
import IconButton from "components/IconButton";
import Modal from "components/Modal";

export function FontPreview(props) {
    const {source, family, size, weight, style, ...other} = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if (source && family)
            dispatch(loadWebfont(source, family));
    }, [source, family, dispatch]);
    let previewStyle = style ? {...style} : {};
    previewStyle.fontFamily = family;
    if (size) previewStyle.fontSize = size;
    if (weight) previewStyle.fontWeight = weight;
    return <div style={previewStyle} {...other}>
        {props.children || family}
    </div>;
}

function FontPreviewListItem({source, id, onClick, style}) {
    const click = useCallback(()=>onClick(id), [onClick, id]);

    return <ListItem button onClick={click} style={style}>
        <ListItemText
            primary={<FontPreview
                source={source}
                family={id}
                size={'24px'}
            >{id}</FontPreview>}
            secondary={id}
        />
    </ListItem>;
}

FontPreviewListItem.autoFillProps = [
    'source',
    'id'
];

const FontListIterator = VirtualizedListIterator(withData(FontPreviewListItem));

function FontSelectList(props) {
    const { onSelect } = props;
    const [source, setSource] = useState(props.source || 'google');
    const type = 'cms.fonts';
    const list = [type, source].join('.');
    const uri = fontSources[source];
    const select = useCallback((id)=>onSelect({
        source,
        family: id
    }), [source, onSelect]);
    return <Fetch type={list} list={list} url={uri}>
        <div className={'cols'}>
            <div onClick={()=>setSource('google')}>google</div>
            <div onClick={()=>setSource('zuu')}>zuu</div>
        </div>
        <List
            width={400}
            height={600}
            type={list}
            list={list}
            onClick={select}
            Iterator={FontListIterator} />
    </Fetch>;
}

function FontFamilySelect(props) {
    const { source, family, onChange } = props;
    return <Modal
        trigger={({onClick})=><FontPreview
            onClick={onClick}
            source={source}
            family={family}
        />}
        content={({onClose})=><FontSelectList
            onSelect={(v)=>{onChange(v);onClose()}}
            source={source}
        />}/>;
}

const weightValues = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800
];

function FontWeightSelect(props) {
    const {
        id,
        value,
        onChange,
        source,
        family
    } = props;

    const change = (v) => {
        let upd = {}; upd[id] = v;
        onChange(upd);
    };

    return <Popover
        trigger={({onClick})=>
            <FontPreview weight={value} source={source} family={family}>
                <div onClick={onClick}>{value}</div>
            </FontPreview>}
        content={({onClose})=>
            <div className={'select-options'}>
                {weightValues.map(value => {
                return <ListItem key={value} button onClick={()=>{
                    change(value);
                    onClose();
                }}>
                    <ListItemText primary={<span style={{
                        fontWeight: value,
                        fontFamily: family
                    }}>
                        {value}
                    </span>} />
                </ListItem>
            })}
        </div>}
    />;
}

const weights = [
    'weight_light',
    'weight_regular',
    'weight_bold'
];

function FontWeights(props) {
    const { source, family, value, onChange } = props;
    return <div className={'cols gap-xs'}>
        {weights.map(w=> {
            return <Tooltip key={w} title={w.replace('weight_', '')}>
                    <div>
                        <FontWeightSelect
                            id={w}
                            value={value[w]}
                            onChange={onChange}
                            source={source}
                            family={family}
                        />
                    </div>
                </Tooltip>;
        })}
    </div>;
}

function FontFamilyField(props) {
    const { label, onChange } = props;
    const value = props.value || {};
    const { source, family } = value;

    const change = useCallback((nextValue) => {
        onChange({...value, ...nextValue});
    }, [onChange, value]);

    return <div className={'cols grow dark-c700'}>
        <div className={'w-xxs rows rows-middle pad-sm o-50'}>{label}</div>
        <div className={'grow cols'}>
            <div className={'pad-sm cols grow'}>
                <div className={'w-xs'}>
                    <FontFamilySelect
                        source={source}
                        family={family}
                        onChange={change}
                    />
                </div>
                <FontWeights
                    value={value}
                    source={source}
                    family={family}
                    onChange={change} />
            </div>
        </div>
    </div>
}

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {
    onImport: importTypography
};

const fonts = [
    'body_font',
    'heading_font',
    'decorative_font'
];

class TypographySchemeField extends React.PureComponent {

    change = (id, nextValue) => {
        const value = this.props || {};
        const { onChange } = this.props;
        const next = {...value};
        next[id] = nextValue;
        onChange(next);
    };

    importFromPreset = (from) => {
        const { entityType, entityId, onImport } = this.props;
        onImport(from, entityId, entityType);
    };

    render() {
        const value = this.props.value || {};
        return (<div className={'cols grow'}>
            <div className={'rows gap-px grow'}>
                {fonts.map(id => {
                    return <FontFamilyField
                        key={id}
                        label={id.replace('_font', '')}
                        value={value[id]}
                        onChange={(v)=>this.change(id, v)}
                    />;
                })}
            </div>
            <Select
                source={'api:console.presets'}
                onChange={this.importFromPreset}
                ValueComponent={({onClick})=><div onClick={onClick}>
                    <IconButton icon={'mui-search'} onClick={()=>null} />
                </div>}
            />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypographySchemeField);