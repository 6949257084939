export default class Subscriptions {

    constructor(client) {
        this.client = client;
        this.reset();
    }

    reset() {
        /**
         * Available tracks
         * @type {{}}
         */
        this.published = {};

        /**
         * Track are subscribed
         * @type {{}}
         */
        this.subscribed = {};

        /**
         * Tracks which should be subscribed when available
         * @type {{}}
         */
        this.subscribedQueue = {};

        /**
         *
         */
        this.shouldSubscribe = {};

        this.errors = {};
    }

    /**
     * Remote user has published track (user-published event)
     * @param user
     * @returns {Promise<void>}
     */
    async publish(user) {
        this.published[user.uid] = user;

        if (this.subscribed[user.uid] || this.subscribedQueue[user.uid])
            await this.subscribe(user.uid);
    }

    /**
     * Remote user has unpublished track (user-unpublished event)
     * @param user
     * @returns {Promise<void>}
     */
    async unpublish(user) {
        if (this.published[user.uid]) {
            this.published[user.uid] = false;
            await this.client.unsubscribe(user, "video");
        }
    }

    async subscribe(id) {
        this.shouldSubscribe[id] = true;

        if (this.published[id]) {
            if (await this.client.subscribe(this.published[id], "video")) {
                if (this.shouldSubscribe[id]) {
                    this.shouldSubscribe[id] = false;
                    this.subscribed[id] = true;
                    this.subscribedQueue[id] = false;
                    this.errors[id] = null;
                } else {
                    await this.unsubscribe(id);
                }
            } else {
                this.shouldSubscribe[id] = false;
                this.subscribedQueue[id] = false;
                this.errors[id] = "Unable to subscribe";
            }
        } else {
            this.shouldSubscribe[id] = false;
            this.subscribedQueue[id] = true;
            this.errors[id] = "Subscribe to unpublished track";
        }
    }

    async unsubscribe(id) {
        this.shouldSubscribe[id] = false;

        if (this.published[id] && this.subscribed[id])
            await this.client.unsubscribe(this.published[id], "video");

        this.subscribed[id] = false;
        this.subscribedQueue[id] = false;
    }
}