import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { useMoment } from "joynt/components/DateTimePicker/hooks";
import PropertyEmptyState from "./../TaskProperties/PropertyEmptyState";

import IconButton from "components/IconButton";
import { startOfDay } from "joynt/components/DateTimePicker/util";

import "./style.css";

const today = startOfDay(new Date()).getTime() / 1000;
const daySeconds = 24 * 60 * 60;

const ranges = {
    today: [today, today + daySeconds],
    tomorrow: [today + daySeconds, today + 2 * daySeconds],
    yesterday: [today - daySeconds, today],
};

const rangeDescriptions = {
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
};

function dueDateRange(timestamp) {
    const rangesKeys = Object.keys(ranges);

    for (let i = 0; i < rangesKeys.length; i++) {
        let range = ranges[rangesKeys[i]];
        if (timestamp >= range[0] && timestamp < range[1]) {
            return rangesKeys[i];
        }
    }

    if (timestamp < today) return "past";
}

export function TaskDue(props) {
    const { onClick, onClear, value, showLabel } = props;
    const moment = useMoment(value);
    const range = value ? dueDateRange(value) : null;

    const content = value
        ? rangeDescriptions[range] || moment.format("DD MMM")
        : null;

    return (
        <div
            className={cn("task-due", {
                [`task-due--${range}`]: !!range,
            })}
            onClick={onClick}
        >
            {value ? (
                <>{content}</>
            ) : (
                <PropertyEmptyState icon={"mui-today"} showLabel={showLabel}>
                    No due date
                </PropertyEmptyState>
            )}
            {value && showLabel && (
                <IconButton
                    icon={"mui-clear"}
                    size={"small"}
                    onClick={onClear}
                />
            )}
        </div>
    );
}

TaskDue.propTypes = {
    showLabel: PropTypes.bool,
};
TaskDue.defaultProps = {
    showLabel: false,
};

export default TaskDue;
