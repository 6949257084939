import React from "react";
//import PropTypes from 'prop-types';

import Popover from "components/Popover";
import IconButton from "components/IconButton";
import SelectList, { SelectListItem } from "playground/ui/SelectList";

import "./style.css";

const labels = {
    title: "Tytuł",
    headline: "Podtytuł",
    text: "Tekst",
    media: "Media",
    links: "Odnośniki",
    gallery: "Lista elementów",
    tags: "Tagi",
};

export function SectionContentTypes(props) {
    const { optional, all, onToggle } = props;

    return (
        <div className={"content-types"}>
            {optional.length > 0 ? (
                <div className={"content-types-list"}>
                    <div className={"content-types-label"}>Dodaj treść:</div>
                    {optional.map((type) => {
                        return (
                            <div
                                key={type}
                                onClick={() => onToggle(type)}
                                className={"content-type-btn"}
                            >
                                {labels[type] || type}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div />
            )}
            {all.length > 0 && (
                <Popover
                    trigger={({ onClick }) => (
                        <IconButton
                            onClick={onClick}
                            icon={"mui-more_horizontal"}
                        />
                    )}
                >
                    <SelectList>
                        {all.map((type) => {
                            return (
                                <SelectListItem
                                    key={type}
                                    onClick={() => onToggle(type)}
                                >
                                    {labels[type] || type}
                                </SelectListItem>
                            );
                        })}
                    </SelectList>
                </Popover>
            )}
        </div>
    );
}

SectionContentTypes.propTypes = {};

SectionContentTypes.defaultProps = {
    optional: [],
    all: [],
};

export default SectionContentTypes;
