import {FILTER_NS} from "config/list-aliases";

export const subscribe = (filter, fetchFn) => {
    return {
        type: 'FILTERS.SUBSCRIBE',
        payload: { filter, fetchFn }
    }
};

export const unsubscribe = (filter) => {
    return {
        type: 'FILTERS.UNSUBSCRIBE',
        payload: { filter }
    }
};

/**
 * @param type Entity type
 * @param id List id
 * @returns {{payload: {id: *, type: *}, type: string}}
 */
export const updateFilter = (type, id) => {
    return {
        type: 'FILTERS.UPDATE',
        payload: {
            type,
            id
        }
    }
};

export const setFilter = (filter, data) => {
    return {
        type: 'DATA.CHANGE',
        payload: {
            type: FILTER_NS,
            id: filter,
            data
        }
    }
};