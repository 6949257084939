import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStylesImport } from "playground/cms/state/selectors/styles";
import Button from "components/Button";

//import PropTypes from 'prop-types';

function defaultAction(style) {
    const [type] = style.style.split(".");
    let action = ["button", "text"].includes(type) ? "import" : "inline";
    if (Object.keys(style?.local || {}).length) action = "skip";
    return action;
}

function defaultState(styles) {
    const state = {};
    styles.forEach((s) => {
        if (!state[s.style]) {
            state[s.style] = {
                action: defaultAction(s),
            };
        }
    });
    return state;
}

export function StylesImport(props) {
    const { id, target, origin, onSelect, onSetState } = props;
    const styles = useSelector((state) => {
        return selectStylesImport(target, origin, id);
    });
    const [state, setState] = useState(defaultState(styles));
    useEffect(() => {
        if (onSetState) onSetState(state);
    }, [onSetState, state]);
    const onSubmit = () => {
        onSelect("clone", { styles: state });
    };
    return (
        <div>
            {Object.keys(state).map((style) => {
                return (
                    <div key={style} className={"cols cols-2 cols-spread"}>
                        <div>{style}</div>
                        <div>
                            <select
                                value={state[style].action}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        [style]: {
                                            action: e.target.value,
                                        },
                                    });
                                }}
                            >
                                <option value="import">import</option>
                                <option value="inline">inline</option>
                                <option value="skip">skip</option>
                            </select>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

StylesImport.propTypes = {};

StylesImport.defaultProps = {
    styles: [],
};

export default StylesImport;
