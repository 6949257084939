import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { useData } from "state/hooks";
import TagsField from "playground/cms/fields/TagsField";

function IntegrationDataTypes(props) {
    const data = useData({
        type: "cms.integrations",
        id: props.integration,
    });

    return (
        <Field
            id={"entry_type"}
            label={"Entry Type"}
            fieldType={"text"}
            source={"schema:entries.types"}
            nullable={true}
        />
    );
}

export function Query(props) {
    const { queryEntityType, queryListId } = props;
    const { integration, query_type } = useData(props);

    return (
        <>
            {props.children}
            <div className={"rows gap-md"}>
                <div className={"rows gap-xs"}>
                    <div>Data source</div>
                    <Field
                        id={"query_type"}
                        label={"Query Type"}
                        fieldType={"select"}
                        source={"schema:data.query-type"}
                    />
                    {query_type === "integration" && (
                        <Field
                            id={"integration"}
                            label={"Integration"}
                            fieldType={"select"}
                            source={"api:integrations"}
                            nullable={true}
                        />
                    )}
                    {query_type === "repository" && (
                        <Field
                            id={"query_repository"}
                            label={"Repository"}
                            fieldType={"select"}
                            source={"schema:data.query-repositories"}
                            nullable={true}
                        />
                    )}
                    {query_type === "enum" && (
                        <Field
                            id={"enum_definition"}
                            label={"Enum Definition"}
                            fieldType={"select"}
                            source={"api:cms.enum-definitions"}
                            nullable={true}
                        />
                    )}
                    {query_type === "entries" && (
                        <Field
                            id={"entry_type"}
                            label={"Entry Type"}
                            fieldType={"select"}
                            source={"schema:entries.types"}
                            nullable={true}
                        />
                    )}
                    {query_type === "integration" && integration && (
                        <IntegrationDataTypes integration={integration} />
                    )}
                </div>
                {/*<Field*/}
                {/*    id={"data_source"}*/}
                {/*    label={"Data Source"}*/}
                {/*    fieldType={"select"}*/}
                {/*    source={"schema:data.list-data-source"}*/}
                {/*    nullable={true}*/}
                {/*/>*/}
                <div className={"rows gap-xs"}>
                    <div>Query spec</div>
                    {/*<Field*/}
                    {/*    id={"tags"}*/}
                    {/*    label={"Tags"}*/}
                    {/*    fieldType={"select"}*/}
                    {/*    source={"api:tags"}*/}
                    {/*    multiple={true}*/}
                    {/*    taggable={true}*/}
                    {/*    nullable={true}*/}
                    {/*    zmapAs={["tags", "query_spec.tags"]}*/}
                    {/*/>*/}
                    <TagsField
                        id={"tags"}
                        label={"Tags"}
                        entityType={queryEntityType}
                        listId={queryListId}
                        zmapAs={["tags", "query_spec.tags"]}
                    />
                    {query_type === "entries" && (
                        <Field
                            id={"category"}
                            label={"Category"}
                            fieldType={"select"}
                            source={"api:categories"}
                            nullable={true}
                        />
                    )}
                    <Field
                        id={"query_filter"}
                        label={"Query Filter"}
                        fieldType={"select"}
                        source={"query"}
                        multiple={true}
                    />
                    <Field
                        id={"order"}
                        label={"Order"}
                        fieldType={"select"}
                        source={"schema:data.query-order"}
                    />
                </div>
                <Field
                    id={"target_page.resource_id"}
                    label={"Target Page"}
                    fieldType={"select"}
                    source={"api:sitemap"}
                    nullable={true}
                />
                <Field id={"alias"} label={"Alias (for nested queries)"} />
            </div>
        </>
    );
}

Query.propTypes = {};

Query.defaultProps = {
    type: "cms.components",
};

export default Query;
