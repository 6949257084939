import React from "react";
import cn from "classnames";

import Popover from "components/Popover";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function Select(props) {
    const { options, value, onChange, nullable, placeholder } = props;

    const handleSetToNull = (e) => {
        e.stopPropagation();
        onChange(null);
    };

    return (
        <Popover
            className={"style-ui-popover"}
            trigger={({ onClick }) => (
                <div
                    className={cn("style-ui-select style-box", {
                        "is-empty": !value,
                    })}
                    onClick={onClick}
                >
                    <div className={"style-select-value"}>
                        {value || placeholder || "Select"}
                    </div>
                    {nullable && value && (
                        <span
                            className={"style-select-clear"}
                            onClick={handleSetToNull}
                        >
                            <Icon>mui-close</Icon>
                        </span>
                    )}
                </div>
            )}
            content={({ onClose }) => (
                <SelectList onClick={onClose}>
                    {options.map((option) => (
                        <SelectListItem
                            key={option}
                            onClick={() => onChange(option)}
                        >
                            {option}
                        </SelectListItem>
                    ))}
                </SelectList>
            )}
        />
    );
}

Select.propTypes = {};

Select.defaultProps = {
    options: [],
};

export default Select;
