import React from "react";
//import PropTypes from 'prop-types';

import { ListLayout, ListPagination } from "playground/cms/List";
import SectionsFilter from "./SectionsFilter";
import { useFilter, useRouteParam, useUiFlag } from "state/hooks";
import IconButton from "components/IconButton";
import Button from "components/Button";
import SectionsListContainer from "./SectionBrowser/SectionsListContainer";
import LibraryFilter from "./SectionBrowser/LibraryFilter";
import ListViewFilter from "./ListViewFilter";

import "./sections.css";
import ListFilter from "playground/cms/views/Sections/ListFilter";
import SectionOverlayForm from "playground/cms/views/Sections/SectionOverlayForm";
import { SectionsFilterContext } from "./SectionsFilterContext";

export function Sections(props) {
    const {
        type,
        id,
        onClose,
        onCloseSections,
        create,
        isBrowser,
        filterValue,
    } = props;
    const [view, setView] = useUiFlag(`${type}.view`, "grid");

    const [browser, setBrowser] = useRouteParam("sectionBrowser");
    const list = props.list || type;
    const filter = useFilter(list, type, list);

    return (
        <>
            <SectionsFilterContext.Provider value={filter}>
                <ListLayout {...props}>
                    {!filterValue && (
                        <ListLayout.Filter>
                            <SectionsFilter type={type} list={list} />
                        </ListLayout.Filter>
                    )}
                    <ListLayout.Header>
                        <ListLayout.Header.Icons>
                            {!filterValue && (
                                <ListLayout.Header.Filter type={type} />
                            )}
                            <ListLayout.Header.Reload type={type} list={list} />
                        </ListLayout.Header.Icons>
                        <ListLayout.Header.Icons>
                            <ListViewFilter value={view} onChange={setView} />
                        </ListLayout.Header.Icons>
                        <ListLayout.Header.Addon>
                            {!isBrowser && !filterValue ? (
                                <Button
                                    onClick={() =>
                                        setBrowser(!browser ? true : null)
                                    }
                                    variant={browser ? "contained" : "outlined"}
                                    size={"small"}
                                >
                                    Biblioteka
                                </Button>
                            ) : (
                                <LibraryFilter list={list} />
                            )}
                            {onCloseSections && (
                                <IconButton
                                    icon={"mui-close"}
                                    onClick={onCloseSections}
                                />
                            )}
                        </ListLayout.Header.Addon>
                    </ListLayout.Header>
                    <ListLayout.Content>
                        <ListFilter list={list} />
                        <SectionsListContainer
                            type={type}
                            list={list}
                            filterValue={props.filterValue}
                            onClick={props.onClick}
                            previewSection={props.previewSection}
                            view={view}
                            create={create}
                        />
                    </ListLayout.Content>
                    <ListLayout.Footer>
                        <ListPagination id={type} type={type} list={list} />
                    </ListLayout.Footer>
                </ListLayout>
                <SectionOverlayForm
                    onClose={onClose}
                    id={id}
                    isBrowser={isBrowser}
                    onCloseBrowser={() => setBrowser(null)}
                    browser={browser}
                />
            </SectionsFilterContext.Provider>
        </>
    );
}

Sections.propTypes = {};

Sections.defaultProps = {
    type: "cms.sections",
};

export default Sections;
