import React from "react";
//import PropTypes from 'prop-types';
import DragSectionPrompt from "./DragSectionPrompt";
import { useActions, useData, useRouteParam, useUiFlag } from "state/hooks";
import {
    dragSectionPromptCancel,
    dragSectionPromptSelect,
} from "cms/state/actions/cms";
import { getLibraryStore } from "playground/cms/state/selectors/library";
import MultipleSections from "./MultipleSections";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";

export function DragSectionPromptContainer(props) {
    const {} = props;
    //const [prompt] = useRouteParam("prompt");
    const [promptFlag] = useUiFlag("cms.sections.prompt");
    const prompt = promptFlag?.prompt ? JSON.parse(promptFlag?.prompt) : {};
    const { id, sections, origin, target, page } = prompt || {};
    //const [id, origin] = prompt?.split(".") || [];
    const [pageType, pageId] = page ? page.split("/") : [];
    //const data = useData({ type: "cms.sections", id });
    const dispatch = getLibraryStore(target).dispatch;
    const { data, templateData } = useSelector(() => {
        const store = getLibraryStore(origin);
        const state = store.getState();
        const data = selectEntity(state, "cms.sections", id);
        const templateData = data.template
            ? selectEntity(state, "cms.sections", data.template)
            : null;
        return { data, templateData };
    });
    const { onClose, onSelect } = useActions(
        {
            onClose: dragSectionPromptCancel,
            onSelect: dragSectionPromptSelect,
        },
        dispatch
    );

    const View = sections || page ? MultipleSections : DragSectionPrompt;
    const open = !!id || !!sections || !!page;

    return (
        <View
            id={id}
            origin={origin}
            target={target}
            open={open}
            data={data}
            templateData={templateData}
            sections={sections}
            pageType={pageType}
            pageId={pageId}
            onClose={onClose}
            onSelect={onSelect}
        />
    );
}

DragSectionPromptContainer.propTypes = {};

DragSectionPromptContainer.defaultProps = {};

export default DragSectionPromptContainer;
