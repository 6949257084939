import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import {weekdays} from "./../data";
import {isToday} from "./../util";

import "./style.css";

export function Day(props) {

    const {date, isSelected, count, onClick} = props;
    const dayOfWeek = date.getDay();
    const click = useCallback(() => onClick(date), [onClick, date]);

    return (<div className={cn("timeline-day", {
        "is-today": isToday(date),
        "is-sunday": dayOfWeek === 0,
        "is-selected": isSelected
    })} onClick={click}>
        <div className={"timeline-day__date"}>
            <div className={"timeline-day__day"}>{date.getDate()}</div>
            <div className={"timeline-day__dow"}>{weekdays[dayOfWeek]}</div>
        </div>
        {count && <div className={"timeline-day__badge"}>
            {count}
        </div>}
    </div>);

}

Day.propTypes = {
    date: PropTypes.instanceOf(Date),
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    count: PropTypes.number
};

Day.defaultProps = {
    isSelected: false
};

export default Day;
