import React from "react";
import Button from "components/Button";
import { workspaceTypes } from "joynt/config/types";
import { useClick } from "state/hooks";
import useScreenSize from "util/hooks/useScreenSize";
//import PropTypes from 'prop-types';

function WorkspaceTypeContainer(props) {
    const { id, ...other } = props;
    const data = workspaceTypes[id];
    const onClick = useClick(props);

    return <WorkspaceType {...data} {...other} onClick={onClick} />;
}

function WorkspaceType(props) {
    const { isMobile } = useScreenSize();
    const { image, label, description, onClick } = props;

    return (
        <div className={"workspace-type default pad-md rows gap-sm"}>
            {image && !isMobile && <img src={image} alt={label} />}
            <div className={"type-lg"}>{description}</div>
            <div className={"cols cols-right"}>
                <Button onClick={onClick} size={"large"}>
                    Create {label}
                </Button>
            </div>
        </div>
    );
}

function WorkspaceTypeSelect({ onChange, options }) {
    return (
        <div className={"rows gap-lg"}>
            {options.map((option) => (
                <WorkspaceTypeContainer
                    key={option}
                    id={option}
                    onClick={onChange}
                />
            ))}
        </div>
    );
}

export default function WorkspaceTypeSelectContainer(props) {
    const { options } = props;

    return (
        <WorkspaceTypeSelect
            {...props}
            options={options || Object.keys(workspaceTypes)}
        />
    );
}

WorkspaceTypeSelect.propTypes = {};
WorkspaceTypeSelect.defaultProps = {};
