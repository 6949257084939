import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";
import { FormLayout } from "playground/cms/Form";
import Select from "components/fields/select/Select";
import IconButton from "components/IconButton";
import { useData } from "state/hooks";

function AddLanguageButton(props) {
    return (
        <IconButton
            icon={"mui-add"}
            onClick={props.onClick}
            title={"Dodaj język"}
        />
    );
}

function LanguageItem(props) {
    const { id, onToggleVisible, onRemove, isVisible } = props;
    const { label } = useData({
        type: "schema.data.value",
        id: `data.languages.${id}`,
    });
    return (
        <div
            className={cn("lang-item", {
                "is-visible": isVisible,
            })}
        >
            <div className={"lang-item-label"}>
                <IconButton
                    icon={isVisible ? "mui-visibility" : "mui-visibility_off"}
                    onClick={() => onToggleVisible(id)}
                    size={"small"}
                />
                <div>{label || id}</div>
            </div>
            <div className={"lang-item-actions"}>
                <IconButton
                    icon={"mui-clear"}
                    onClick={() => onRemove(id)}
                    size={"small"}
                />
            </div>
        </div>
    );
}

export function Languages(props) {
    const {
        languages,
        visibleLanguages,
        onAdd,
        onRemove,
        onToggleVisible,
        children,
    } = props;

    return (
        <FormLayout.Section
            name={"Wersje językowe"}
            icon={"mui-language"}
            action={
                <Select
                    onChange={onAdd}
                    source={"schema:data.languages"}
                    label={"Add language"}
                    ValueComponent={AddLanguageButton}
                />
            }
        >
            <FormLayout.Fieldset>
                {languages && languages.length > 0 && (
                    <div className={"cols gap-md"}>
                        <div className={"grid grid-3 gap-sm grow"}>
                            {languages &&
                                languages.map((l) => (
                                    <LanguageItem
                                        key={l}
                                        id={l}
                                        onRemove={onRemove}
                                        onToggleVisible={onToggleVisible}
                                        isVisible={visibleLanguages.includes(l)}
                                    />
                                ))}
                        </div>
                    </div>
                )}
                {children}
            </FormLayout.Fieldset>
        </FormLayout.Section>
    );
}

Languages.propTypes = {};

Languages.defaultProps = {};

export default Languages;
