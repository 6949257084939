import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames/dedupe";
import Media, { MediaPlaceholder } from "cms/components/Media";
import { ChildComponents } from "cms/components/Layout";
import { NS_COMPONENTS, NS_MEDIA } from "cms/components/types";
import { RENDER_MODE_EDIT } from "cms/context/RenderContext";

export default class Card extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    render() {
        const {
            inspectId,
            classes,
            className,
            media,
            components,
            renderMode,
            rewireContext,
            rewireMap,
            children,
            attributes,
            ...other
        } = this.props;

        const hasMedia = media && media.length > 0;
        const edit = renderMode === RENDER_MODE_EDIT;

        if (!edit && !hasMedia && !children && !components)
            return <div>hidden item</div>;

        return (
            <div
                data-inspect={inspectId}
                data-type={"card"}
                className={cn(className, classes.item)}
                data-rewire={rewireContext}
                data-item={other["data-item"]}
                {...attributes}
            >
                {hasMedia ? (
                    <div
                        ref={this.ref}
                        data-inspect={inspectId}
                        data-type={"media"}
                        data-rewire={rewireContext}
                        data-path={rewireMap.media}
                        className={classes.image_outer}
                    >
                        <Media
                            type={NS_MEDIA}
                            id={media[0]}
                            classes={classes}
                        />
                        <div className={classes.overlay} />
                    </div>
                ) : null}
                {edit && !hasMedia ? (
                    <div className={classes.image_outer}>
                        <MediaPlaceholder
                            className={classes.image}
                            data-inspect={inspectId}
                            data-type={"media"}
                            data-rewire={rewireContext}
                            data-path={rewireMap.media}
                        />
                    </div>
                ) : null}

                <div className={classes.content}>
                    <ChildComponents
                        itemType={NS_COMPONENTS}
                        items={components}
                    />
                    {children}
                </div>
            </div>
        );
    }
}

Card.propTypes = {};
Card.defaultProps = {
    element_type: "card",
};
Card.autoFillProps = ["components"];
