import moment from "moment/moment";
import { useApiActions, useData } from "state/hooks";
import { post } from "state/actions/api";
import { useCallback, useMemo } from "react";

const statuses = {
    valid: {
        color: "green",
        icon: "mui-https",
    },
    expires_soon: {
        color: "amber",
        icon: "mui-warning",
    },
    expired: {
        color: "red",
        icon: "mui-no_encryption",
    },
    "no-certificate": {
        color: "red",
        icon: "mui-error",
    },
    disabled: {
        color: null,
        icon: "mui-no_encryption",
    },
};

const calculateStatusId = (validToTimestamp, enable) => {
    if (!enable) return "disabled";

    let statusId = "valid";
    const now = moment().format("X") * 1;
    const validTo = validToTimestamp * 1;
    if (!validTo) return "no-certificate";
    const warning = moment().add("days", 5).format("X") * 1;
    if (validTo < warning) {
        statusId = "expires_soon";
    }
    if (validTo <= now) {
        statusId = "expired";
    }
    return statusId;
};

const calculateStatus = (validToTimestamp, enable) => {
    const statusId = calculateStatusId(validToTimestamp, enable);
    return statuses[statusId];
};

export function useDomainCertificate(id) {
    const { certificate_valid_to, secure_free } = useData({
        type: "console.domains",
        id,
    });

    const { onRenew } = useApiActions({
        onRenew: post,
    });

    const renewUrl = `console/domains/${id}/renew`;

    const renew = useCallback(() => {
        onRenew(renewUrl, null, id);
    }, [onRenew, renewUrl, id]);

    const statusId = useMemo(() => {
        return calculateStatusId(certificate_valid_to, secure_free);
    }, [certificate_valid_to, secure_free]);

    const status = statuses[statusId];

    const validTo =
        secure_free && certificate_valid_to
            ? moment(certificate_valid_to * 1000)
            : null;

    return {
        status,
        statusId,
        validTo,
        onRenew: renew,
    };
}
