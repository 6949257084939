import React from 'react';
import {connect} from 'react-redux';
import IconButton from "cms/ui/IconButton";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class IconButtonGroup extends React.Component {

    checked = (chk) => {
        const { value, multiple } = this.props;
        if (!multiple) return chk===value;
        if (!value) return false;
        return value.split(',').indexOf(chk)>-1;
    };

    render() {
        const { items, onToggle } = this.props;
        return <div className={'ui-icon-btn-group'}>{items.map(item => {
            return <IconButton
                onClick={()=>onToggle(item.value)}
                key={item.value}
                checked={this.checked(item.value)}
                {...item}
            />
        })}</div>;
    }

}

IconButtonGroup.defaultProps = {
    items: []
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IconButtonGroup);