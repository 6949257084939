import React from "react";
//import PropTypes from 'prop-types';
import { useData } from "state/hooks";
import ListItem from "./Integration";
import ContextMenuItems from "./../../Resources/ListItem/ContextMenuItems";
import ContextMenu from "components/ContextMenu";

export function IntegrationContainer(props) {
    const { type, id } = props;
    const { type: integrationType, active } = useData(props);

    if (!id) return <ListItem {...props} />;

    return (
        <ContextMenu type={type} id={id} RenderMenuItems={ContextMenuItems}>
            {({ onMenuOpen }) => (
                <ListItem
                    integrationType={integrationType}
                    active={active}
                    onMenuOpen={onMenuOpen}
                    {...props}
                />
            )}
        </ContextMenu>
    );
}

IntegrationContainer.propTypes = {};

IntegrationContainer.defaultProps = {};

export default IntegrationContainer;
