import React from "react";
import PropTypes from "prop-types";
import SessionStatus from "./SessionStatus";
import { useSessionParticipants } from "joynt/hooks/sessions";
import { useNodeData } from "joynt/hooks";

export function SessionStatusContainer(props) {
    const { id } = props;

    const { event_stage } = useNodeData(id);
    const { count, max } = useSessionParticipants(id);

    if (event_stage) return <SessionStatus {...props} text={"On stage"} />;

    if (!max) {
        return (
            <SessionStatus {...props} text={"You're invited"} color={"green"} />
        );
    }

    const left = max - count;
    const text = left === 1 ? `${left} spot left` : `${left} spots left`;

    return <SessionStatus {...props} text={text} color={"secondary"} />;
}

SessionStatusContainer.propTypes = {
    id: PropTypes.string,
};
SessionStatusContainer.defaultProps = {};

export default SessionStatusContainer;
