import React, { useState } from "react";
import cn from "classnames";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import { useForm } from "joynt/hooks";
import Button from "components/Button";
import SelectField from "joynt/edit/SelectField";
import {
    useSessionConfig,
    useSessionContext,
} from "joynt/meeting/agora-ng/hooks";
import { useField, useFormField, useUiFlag } from "state/hooks";
import { FLAG_SESSION_OVERRIDES } from "joynt/config";
import Checkbox from "components/fields/checkbox/Checkbox";
import TabsNav, { Tab } from "joynt/components/Tabs";

//import PropTypes from 'prop-types';
const sessionLayouts = {
    grid: { id: "grid", label: "Grid" },
    meeting: { id: "meeting", label: "Meeting" },
};

const boolOptions = {
    true: { id: "true", label: "true" },
    false: { id: "false", label: "false" },
};

function addNull(options) {
    return {
        default: { id: "default", label: "default" },
        ...options,
    };
}

const handleChange = (onChange) => {
    return (v) => {
        if (v === "true") return onChange(true);
        if (v === "false") return onChange(false);
        if (v === "default") return onChange(null);
        if (v === "") return onChange(null);
        return onChange(v);
    };
};

const convertValue = (v) => {
    if (v === true) return "true";
    if (v === false) return "false";
    if (v === "null") return null;
    if (v === undefined) return null;
    return v;
};

function Select(options) {
    return function ({ label, value, onChange }) {
        return (
            <div className={"rows"}>
                <div
                    className={cn("type-sm", {
                        "o-50": convertValue(value) === null,
                    })}
                >
                    {label}
                </div>
                <SelectField
                    value={convertValue(value)}
                    options={addNull(options)}
                    onChange={handleChange(onChange)}
                />
            </div>
        );
    };
}

const options = {
    SESSION_LAYOUT: { type: "select", options: sessionLayouts },
    SESSION_LAYOUT_MINIMIZED: { type: "select", options: sessionLayouts },
    MAX_TILES_FOCUSED: { type: "text" },
    MAX_TILES_GRID: { type: "text" },
    MAX_TILES_MINIMIZED: { type: "text" },
    SHOW_TILES_FOR_DISCONNECTED_USERS: { type: "checkbox" },
    PRIORITIZE_ACTIVE_STREAMS: { type: "checkbox" },
    SHOW_AUDIO_STREAMS: { type: "checkbox" },
    SHOW_SESSION_TOOLBAR: { type: "checkbox" },
    TRACK_VOLUME_SPEAKING_THRESHOLD: { type: "text" },
    TRACK_VOLUME_SPEAKING_TIMEOUT: { type: "text" },
    ACTIVE_SPEAKER_THRESHOLD: { type: "text" },
};

function RenderField(props) {
    const { id, type, options, defaults, path } = props;

    let renderFn = null;

    if (type === "select") renderFn = Select(options);
    if (type === "checkbox") renderFn = Select(boolOptions);

    return (
        <Field
            id={`${path}.${id}`}
            fieldType={type}
            label={`${id} (${defaults[id]})`}
        >
            {renderFn}
        </Field>
    );
}

function SessionInfo(props) {
    const { activeSpeakers } = useSessionContext();
    return (
        <div>
            <pre>{JSON.stringify(activeSpeakers, null, 2)}</pre>
        </div>
    );
}

export function DevSessionSettings(props) {
    const { id, onSubmit } = props;

    const defaults = useSessionConfig(id, false);
    const [overrides, setOverrides] = useUiFlag(FLAG_SESSION_OVERRIDES);
    const { onChange: reset } = useField("db.nodes", id, "dev_settings");
    const onReset = () => reset({});

    const [tab, setTab] = useState("global");
    const path = tab === "mobile" ? "dev_settings.mobile" : "dev_settings";

    return (
        <div className={"rows gap-sm pad-md overflow-scroll"}>
            <div className={"cols gap-md"}>
                <Field
                    id={"dev_settings.enabled"}
                    fieldType={"checkbox"}
                    label={"Enable"}
                />
                <Checkbox
                    onChange={setOverrides}
                    value={overrides}
                    label={"Local"}
                />
                <div onClick={onReset}>reset</div>
            </div>
            <TabsNav>
                <Tab
                    onClick={() => setTab("global")}
                    selected={tab === "global"}
                >
                    Global
                </Tab>
                <Tab
                    onClick={() => setTab("mobile")}
                    selected={tab === "mobile"}
                >
                    Mobile
                </Tab>
                <Tab onClick={() => setTab("info")} selected={tab === "info"}>
                    Info
                </Tab>
            </TabsNav>
            {["global", "mobile"].includes(tab) && (
                <>
                    {Object.keys(options).map((k) => {
                        return (
                            <RenderField
                                key={k}
                                id={k}
                                path={path}
                                {...options[k]}
                                defaults={defaults}
                            />
                        );
                    })}
                    <Button onClick={() => onSubmit()}>Submit</Button>
                </>
            )}
            {tab === "info" && <SessionInfo />}
        </div>
    );
}

export function Dev(props) {
    const { id } = props;
    const { onSubmit } = useForm("db.nodes", id);

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
            <DevSessionSettings id={id} onSubmit={onSubmit} />
        </AggregateRoot>
    );
}

Dev.propTypes = {};
Dev.defaultProps = {};

export default Dev;
