import React, { useState } from "react";
import cn from "classnames";
import { ListLayout, ListContainer } from "playground/cms/List";
import { FormContainer, FormLayout, OverlayForm } from "playground/cms/Form";
import { useActions, useData, usePending, useRouteParam } from "state/hooks";
import Preloader from "components/Preloader";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { createCallback } from "state/actions/create";
import ListItem from "./ListItem";
import IntegrationForm from "./IntegrationForm";
//import PropTypes from 'prop-types';

function selectIntegrationTypes(state) {
    const values =
        state?.data?.["schema.data"]?.["types.integrations"]?.values || [];
    return values.map((type) => type.replace("types.integrations.", ""));
}

function CreateIntegration(props) {
    const { className, onCreate, onClose } = props;
    const types = useSelector(selectIntegrationTypes);
    return (
        <div className={"settings-view grow overflow-hide"}>
            <ListLayout className={"overflow-hide grow"}>
                <ListLayout.Content>
                    <div className={className}>
                        {types.map((t) => (
                            <ListItem
                                key={t}
                                integrationType={t}
                                onClick={() => onCreate(t)}
                            />
                        ))}
                    </div>
                </ListLayout.Content>
                <ListLayout.Footer>
                    <ListLayout.Footer.Actions>
                        <Button onClick={onClose}>Anuluj</Button>
                    </ListLayout.Footer.Actions>
                </ListLayout.Footer>
            </ListLayout>
        </div>
    );
}

export function Integrations(props) {
    const {} = props;
    const [id, setId] = useRouteParam("id");
    const [create, setCreate] = useState(false);
    const type = "cms.integrations";
    const isPending = usePending([type, type].join("/"));
    const { onCreate } = useActions({ onCreate: createCallback });
    const handleCreate = (integrationType) => {
        const cb = ({ id }) => {
            setId(id);
            setCreate(null);
        };
        onCreate(
            cb,
            type,
            {
                type: integrationType,
                active: true,
            },
            type
        );
    };

    const className = "grid grid-3 overflow-scroll gap-md pad-md grow";

    return (
        <>
            {create && (
                <CreateIntegration
                    onCreate={handleCreate}
                    onClose={() => setCreate(false)}
                    className={className}
                />
            )}
            <div
                className={cn("settings-view grow overflow-hide", {
                    hide: !!create,
                })}
            >
                <ListLayout className={"grow"}>
                    <ListLayout.Header>
                        <ListLayout.Header.Icons>
                            <ListLayout.Header.Reload type={type} />
                        </ListLayout.Header.Icons>
                    </ListLayout.Header>
                    <ListLayout.Content>
                        <ListContainer
                            type={type}
                            onClick={setId}
                            Item={ListItem}
                            className={className}
                        />
                    </ListLayout.Content>
                    <ListLayout.Footer>
                        <ListLayout.Footer.Actions>
                            <Button onClick={() => setCreate(true)}>
                                Dodaj integrację
                            </Button>
                        </ListLayout.Footer.Actions>
                    </ListLayout.Footer>
                </ListLayout>
                <OverlayForm open={!!id} onClose={() => setId(null)}>
                    {id && (
                        <FormContainer
                            type={type}
                            id={id}
                            onClose={() => setId(null)}
                            FormComponent={IntegrationForm}
                        />
                    )}
                </OverlayForm>
            </div>
            <Preloader visible={isPending} />
        </>
    );
}

Integrations.propTypes = {};

Integrations.defaultProps = {};

export default Integrations;
