import React from "react";
//import PropTypes from 'prop-types';
import ControlledSelect from "components/fields/select/ControlledSelect";

import "./style.css";
import Icon from "components/Icon";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";

function SelectValue(props) {
    const { onClick, label, value } = props;

    const lang = useSelector((state) => {
        return selectEntity(
            state,
            "schema.data.value",
            `data.languages.${value}`
        );
    });

    return (
        <div onClick={onClick} className={"lang-select"}>
            {/*<div className={"lang-select-flag"}>fl</div>*/}
            <div className={"lang-select-label"}>{lang?.label || value}</div>
            <div className={"lang-select-icon"}>
                <Icon>mui-arrow_drop_down</Icon>
            </div>
        </div>
    );
}

export function LocaleSelect(props) {
    return (
        <ControlledSelect
            {...props}
            nullable={false}
            ValueComponent={SelectValue}
        />
    );
}

LocaleSelect.propTypes = {};

LocaleSelect.defaultProps = {
    options: [],
};

export default LocaleSelect;
