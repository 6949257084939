import React from "react";
import cn from "classnames";

import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

import {
    MessageBodyWide,
    MessageFooterSimple,
    MessageHeaderExtended,
} from "joynt/components/messages/message/subcomponents";

import "./style.css";

export function ChannelMessage(props) {
    const {
        addReactions,
        author,
        avatar,
        className,
        content,
        createdAt,
        files,
        id,
        isDeleted,
        isOwn,
        link,
        menu,
        messageClick,
        onHide,
        reactions,
        unread,
        notificationStatus,
    } = props;

    return (
        <article
            className={cn(
                "message",
                { "own-message": isOwn },
                `message--channel`,
                className
            )}
        >
            <MessageHeaderExtended
                {...{
                    avatar,
                    author,
                    createdAt,
                    menu,
                    notificationStatus,
                    className,
                    unread,
                    id,
                    isOwn,
                }}
            />
            <MessageBodyWide
                {...{
                    messageClick,
                    onHide,
                    content,
                    isDeleted,
                    link,
                    files,
                    addReactions,
                }}
            />
            <MessageFooterSimple {...{ reactions }} />
        </article>
    );
}

ChannelMessage.propTypes = MessagePropTypes;
