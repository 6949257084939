import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import TextField from "components/fields/text/TextField";
import IconButton from "components/IconButton";
import Select from "components/fields/select/Select";

function round(value) {
    return Math.round(value * 100) / 100;
}

function StepperField(props) {
    const { value, step, onChange } = props;
    const increment = useCallback(() => {
        onChange(round(value+step));
    }, [value, step, onChange]);
    const decrement = useCallback(() => {
        onChange(round(value-step));
    }, [value, step, onChange]);
    return <TextField
        {...props}
        addonComponent={<div className={'cols gap-xs type-lg'}>
            <div onClick={decrement}>-</div>
            <div onClick={increment}>+</div>
        </div>}
    />;
}

StepperField.defaultProps = {
    step: 1
}

export default function TypographyStyleSettings(props) {
    const { value, onChange, onDelete } = props;
    return <div className={'w-sm type-default pad-sm rows gap-xs'}>
        <TextField
            label={'Name'}
            variant={'dense'}
            value={value.id}
            onChange={(value)=>onChange({id: value})}
        />
        <Select
            label={'Family'}
            variant={'dense'}
            value={value.font}
            onChange={(value)=>onChange({font: value})}
            source={'schema:data.type-family'} />
        <StepperField
            label={'Font size'}
            variant={'dense'}
            value={value.font_size}
            onChange={(value)=>onChange({font_size: value})}
            step={2}
        />
        <StepperField
            label={'Letter spacing'}
            variant={'dense'}
            value={value.letter_spacing}
            onChange={(value)=>onChange({letter_spacing: value})}
            step={0.1}
        />
        <StepperField
            label={'Line height'}
            variant={'dense'}
            value={value.line_height}
            onChange={(value)=>onChange({line_height: value})}
            step={0.1}
        />
        <Select
            label={'Weight'}
            variant={'dense'}
            value={value.font_weight}
            onChange={(value)=>onChange({font_weight: value})}
            source={'schema:data.type-weight'} />
        <Select
            label={'Case'}
            variant={'dense'}
            value={value.text_transform}
            onChange={(value)=>onChange({text_transform: value})}
            source={'schema:data.type-case'} />
        <div className={'cols cols-spread'}>
            <IconButton icon={'mui-delete'} onClick={onDelete} />
        </div>
    </div>
}

TypographyStyleSettings.defaultProps = {
    value: {}
};

TypographyStyleSettings.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
}