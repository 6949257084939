import React from "react";
import CategorySelect from "joynt/edit/CategorySelect";
import Field from "containers/Field";
import { useEdges, useFeatureEnabled, useNode } from "joynt/hooks";
import { types as nodeTypes, workspaceTypes } from "joynt/config/types";
import FormLayout from "joynt/components/Form/FormLayout/FormLayout";
import FieldGroup from "joynt/components/Form/FieldGroup/FieldGroup";
import FieldsLayout from "joynt/components/Form/FieldsLayout";
import WorkspaceTypeSelect from "joynt/edit/WorkspaceTypeSelect";
import { FEATURE_DEV } from "joynt/config/features";
//import PropTypes from 'prop-types';

export default function TypeSelect(props) {
    const { id, onReject } = props;
    const { workspace } = useNode(id);
    const { parent } = useEdges(id);
    const isDev = useFeatureEnabled(FEATURE_DEV);

    const { subtype: parentType, workspace: parentIsWorkspace } = useNode(
        parent
    );

    const types = workspace ? workspaceTypes : nodeTypes;

    const parentTypes = parentIsWorkspace ? workspaceTypes : nodeTypes;

    const options = Object.values(types);
    const parentTypeDefinition = parentTypes[parentType] || {};

    const parentChildTypes = parentTypeDefinition.childTypes || [];
    const showAll = isDev && !workspace && !parentTypeDefinition.limitTypes;

    const suggestedOptions = !workspace
        ? parentChildTypes.map((id) => {
              return nodeTypes[id];
          })
        : options;

    const suggestedIds = suggestedOptions.map((type) => type.id);
    const otherOptions = options.filter(
        (type) => suggestedIds.indexOf(type.id) === -1
    );

    const Component = workspace ? WorkspaceTypeSelect : CategorySelect;

    return (
        <FormLayout
            onReject={onReject}
            title={"Choose space type"}
            showHeader={!!workspace}
        >
            <FieldsLayout>
                <Field id={"subtype"}>
                    {({ onChange, value }) => (
                        <Component
                            onChange={onChange}
                            value={value}
                            options={suggestedOptions}
                        />
                    )}
                </Field>
                {showAll ? (
                    <FieldGroup label={"Show more types"} collapsible={true}>
                        <Field id={"subtype"}>
                            {({ onChange, value }) => (
                                <Component
                                    onChange={onChange}
                                    value={value}
                                    options={otherOptions}
                                />
                            )}
                        </Field>
                    </FieldGroup>
                ) : null}
            </FieldsLayout>
        </FormLayout>
    );
}

TypeSelect.propTypes = {};
TypeSelect.defaultProps = {};
