import React from "react";
import PropTypes from "prop-types";
import cn from "classnames/dedupe";

import ContentEditable from "components/ContentEditable";
import RichText from "components/fields/RichText";
import { RENDER_MODE_EDIT } from "cms/context/RenderContext";

const defaultClasses = {
    text: "component-root text",
    heading: "heading",
    title: "title",
    headline: "headline",
    text_outer: "text-outer",
};

const defaultProps = {
    //title: 'Title',
    //headline: 'Headline',
    //text: "<p>Some html content</p>",
    classes: defaultClasses,
    //rewire: 'title',
    titleTag: "h2",
};

/**
 * Concerns:
 * - truncate text
 * - data-zuu-reveal's
 * - link ?
 */

function md(value) {
    if (!value) return value;
    return value.replace("|", "<br />").replace(/\*(.*)\*/gm, (match, p1) => {
        return ` <em>${p1}</em>`;
    });
}

function trimText(value) {
    if (!value) return value;
    return value.replace("\n", "").trim();
}

function hasValue(value) {
    if (!value) return false;
    if (typeof value !== "string") return false;
    const trimmed = trimText(value);
    if (trimmed.length === 0) return false;
    if (trimmed === "<p></p>") return false;
    if (trimmed === "<p> </p>") return false;
    return true;
}

function DomNode(props) {
    const { value, className, nodeType, ...other } = props;

    if (!value) return null;

    const htmlValue = { __html: md(value) };
    const nodeProps = {
        ...other,
        className,
    };

    if (nodeType === "h2")
        return <h2 {...nodeProps} dangerouslySetInnerHTML={htmlValue} />;
    return <div {...nodeProps} dangerouslySetInnerHTML={htmlValue} />;
}

function Editable(props) {
    const { nodeType, value, className, isHtml, edit, id, onChange, ...other } =
        props;

    if (!edit && !hasValue(value)) return null;

    if (!edit)
        return (
            <DomNode
                {...other}
                nodeType={nodeType}
                value={value}
                className={className}
            />
        );

    const editProps = {
        onChange: (v) => onChange(id, v),
        value: trimText(value),
    };

    const dataProps = {
        "data-s": id,
        "data-sub": props["data-sub"],
        "data-path": props["data-path"],
        className,
    };

    return isHtml ? (
        <div {...dataProps}>
            <RichText {...editProps} variant={"inline"} changeMode={"change"} />
        </div>
    ) : (
        <ContentEditable
            {...editProps}
            {...dataProps}
            label={id}
            tagName={nodeType}
            commitOnBlur={true}
        />
    );
}

Editable.propTypes = {
    edit: PropTypes.bool,
    isHtml: PropTypes.bool,
};

Editable.defaultProps = {
    edit: false,
    isHtml: false,
};

export default class Text extends React.PureComponent {
    handleChange = (key, value) => {
        const { onChange } = this.props;
        if (onChange) onChange(key, value);
    };

    render() {
        const {
            inspectId,
            className,
            classes,
            title,
            headline,
            text,
            renderMode,
            children,
            rewireContext,
            rewireMap,
        } = this.props;

        const { titleTag } = this.props;
        const edit = renderMode === RENDER_MODE_EDIT;
        const editable = false;

        const showTitle = hasValue(title) || editable;
        const showHeadline = hasValue(headline) || editable;
        const showHeading = showTitle || showHeadline;
        const showText = hasValue(text);

        return (
            <div
                data-inspect={inspectId}
                data-type={"text"}
                data-s={"text"}
                data-rewire={rewireContext}
                className={cn(className, classes.text)}
            >
                {showHeading ? (
                    <div data-s={"heading"} className={cn(classes.heading)}>
                        {showTitle && (
                            <Editable
                                data-s={"title"}
                                data-sub={"title"}
                                data-path={rewireMap.title}
                                id={"title"}
                                edit={edit}
                                value={title}
                                nodeType={titleTag}
                                className={cn(classes.title)}
                                onChange={this.handleChange}
                            />
                        )}
                        {showHeadline && (
                            <Editable
                                data-s={"headline"}
                                data-sub={"headline"}
                                data-path={rewireMap.headline}
                                id={"headline"}
                                edit={edit}
                                value={headline}
                                nodeType={"div"}
                                className={cn(classes.headline)}
                                onChange={this.handleChange}
                            />
                        )}
                    </div>
                ) : null}
                {showText && (
                    <Editable
                        data-s={"text_outer"}
                        data-sub={"text_outer"}
                        data-path={rewireMap.text}
                        id={"text"}
                        edit={edit}
                        value={text || ""}
                        nodeType={"div"}
                        className={cn(classes.text_outer)}
                        isHtml={true}
                        onChange={this.handleChange}
                    />
                )}
                {children}
            </div>
        );
    }
}

Text.propTypes = {
    title: PropTypes.string,
    headline: PropTypes.string,
    text: PropTypes.string,
    classes: PropTypes.object,
    onChange: PropTypes.func,
};
Text.defaultProps = defaultProps;
