import React, { useMemo } from "react";
import useMeeting from "joynt/meeting/hooks/useMeeting";
//import PropTypes from 'prop-types';
import cn from "classnames";
import {
    OTPublisher,
    OTSubscriber,
    createSession,
    preloadScript,
} from "opentok-react";
import env from "util/env";
import SessionToolbar from "joynt/components/SessionToolbar";
import { changed } from "util/changed";

const OPENTOK_API_KEY = env("OPENTOK_API_KEY");

const roles = {
    speaker: {
        publishVideo: true,
        publishAudio: true,
    },
    owner: {
        publishVideo: true,
        publishAudio: true,
    },
    moderator: {
        publishVideo: true,
        publishAudio: true,
    },
    attendee: {
        publishVideo: false,
        publishAudio: false,
    },
};

function roleState(role) {
    return roles[role] || roles.attendee;
}

class OpenTokSession extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            connection: "Connecting",
            publishVideo: false,
            publishAudio: false,
            streams: [],
            videoSource: undefined,
            ...roleState(props.role),
        };

        this.sessionEventHandlers = {
            sessionConnected: () => {
                this.setState({ connection: "Connected" });
            },
            sessionDisconnected: () => {
                this.setState({ connection: "Disconnected" });
            },
            sessionReconnected: () => {
                this.setState({ connection: "Reconnected" });
            },
            sessionReconnecting: () => {
                this.setState({ connection: "Reconnecting" });
            },
        };

        this.publisherEventHandlers = {
            accessDenied: () => {
                console.log("User denied access to media source");
            },
            streamCreated: () => {
                console.log("Publisher stream created");
            },
            streamDestroyed: ({ reason }) => {
                console.log(`Publisher stream destroyed because: ${reason}`);
            },
        };

        this.subscriberEventHandlers = {
            videoEnabled: () => {
                console.log("Subscriber video enabled");
            },
            videoDisabled: () => {
                console.log("Subscriber video disabled");
            },
        };
    }

    componentWillMount() {
        const { credentials } = this.props;
        this.sessionHelper = createSession({
            ...credentials,
            eventHandlers: this.sessionEventHandlers,
            onError: this.onSessionError,
            onStreamsUpdated: (streams) => {
                this.setState({ streams });
            },
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (changed(["role"], prevProps, this.props)) {
            this.setState(roleState(this.props.role));
        }
    }

    componentWillUnmount() {
        this.sessionHelper.disconnect();
    }

    applyRole = (role) => {
        this.setState({ publishVideo: false, publishAudio: false });
    };

    onSessionError = (error) => {
        this.setState({ error });
    };

    onPublish = () => {
        console.log("Publish Success");
    };

    onPublishError = (error) => {
        this.setState({ error });
    };

    onSubscribe = () => {
        console.log("Subscribe Success");
    };

    onSubscribeError = (error) => {
        this.setState({ error });
    };

    toggleVideo = () => {
        this.setState((state) => ({
            publishVideo: !state.publishVideo,
        }));
    };

    toggleAudio = () => {
        this.setState((state) => ({
            publishAudio: !state.publishAudio,
        }));
    };

    shareScreen = () => {
        this.setState((state) => ({
            videoSource: state.videoSource === "screen" ? undefined : "screen",
        }));
    };

    render() {
        //const { role } = this.props;
        const {
            streams,
            error,
            publishVideo,
            videoSource,
            publishAudio,
        } = this.state;
        let streamsCount = publishVideo || publishAudio ? 1 : 0;
        streamsCount += streams.length;

        const classes = {};
        classes[`tiles-${streamsCount}`] = true;
        return (
            <>
                <div className={cn("meeting-grid grow", classes)}>
                    {error ? (
                        <div className="error">
                            <strong>Error:</strong> {error}
                        </div>
                    ) : null}
                    {publishVideo || publishAudio ? (
                        <OTPublisher
                            properties={{
                                publishVideo,
                                publishAudio,
                                videoSource,
                            }}
                            onPublish={this.onPublish}
                            onError={this.onPublishError}
                            eventHandlers={this.publisherEventHandlers}
                            session={this.sessionHelper.session}
                            className={"video-tile"}
                        />
                    ) : null}
                    {streams.map((stream) => {
                        return (
                            <OTSubscriber
                                key={stream.id}
                                session={this.sessionHelper.session}
                                stream={stream}
                                onSubscribe={this.onSubscribe}
                                onError={this.onSubscribeError}
                                eventHandlers={this.subscriberEventHandlers}
                                className={"video-tile"}
                            />
                        );
                    })}
                </div>
                <SessionToolbar
                    onToggleVideo={this.toggleVideo}
                    onToggleAudio={this.toggleAudio}
                    onToggleScreenShare={this.shareScreen}
                    audioMuted={!publishAudio}
                    videoMuted={!publishVideo}
                />
            </>
        );
    }
}

const OpentTokLoader = preloadScript(OpenTokSession);

export default function OpenTok(props) {
    const { access, role } = useMeeting();

    //const {name, identityName} = useMeetingSession();

    const credentials = useMemo(
        () => ({
            apiKey: OPENTOK_API_KEY,
            sessionId: access.session_id,
            token: access.token,
        }),
        [access]
    );
    return credentials.token ? (
        <OpentTokLoader
            role={role}
            credentials={credentials}
            loadingDelegate={<div>Loading...</div>}
            opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"
        />
    ) : null;
}

OpenTok.propTypes = {};
OpenTok.defaultProps = {};
