import React from 'react';
import {connect} from 'react-redux';
import {selectAppApiContext} from "state/selectors/app";
import cn from 'classnames';
import ThemeProvider from "containers/Theme";
import {selectAliasedEntity, selectEntity} from "state/selectors/data";
import RenderPage from "cms/RenderPage";
import IdePage from "cms/ui/containers/IdePage";
import {selectRouteParam} from "state/selectors/router";
import {isPending} from "state/selectors/ui";
import Bootstrap from "containers/Bootstrap";
import RequireAuthentication from "containers/RequireAuthentication";
import {dragSection} from "cms/state/actions/cms";
import LinkerContext from "cms/context/LinkerContext";
import RenderContext, {RENDER_MODE_EDIT} from "cms/context/RenderContext";
import {pushRouteParams} from "state/actions/router";
import {makeLink} from "cms/PageService";
import ConnectedSection from "cms/containers/Section";
import ApiContext from "containers/context/ApiContext";
import Modal from "containers/ModalStack";
import MediaPreview from "containers/dialogs/MediaPreview";
import ConfirmDialog from "containers/dialogs/Confirm";
import Snackbar from "components/Snackbar";
import DragSectionPrompt from "cms/ui/containers/DragSectionPrompt";
import {change} from "state/actions/data";
import MediaBrowserDrawer from "cms/media-browser/MediaBrowserDrawer";
import GlobalScripts from "cms/GlobalScripts";
import {SortableIterator} from "components/dnd";
import MediaEdit from "components/dialogs/MediaEdit";
import FetchPageData from "cms/FetchPageData";

export const SortableSectionIterator = SortableIterator(ConnectedSection);

const mapStateToProps = (store, props) => {
    const serviceAlias = props.service || props.match.params.service;
    const service = selectAliasedEntity(store, 'console.services', serviceAlias);
    const theme = selectEntity(store, 'console.themes', service.theme);
    const display = selectRouteParam(store, 'display', '').split(',');
    const listView = selectRouteParam(store, 'list');
    const resource = selectRouteParam(store, 'resource');
    return {
        serviceAlias,
        service: service.id,
        page: null,
        resource,
        preset: theme.preset,
        apiContext: selectAppApiContext(store, {service: service.id}),
        flagFocus: display.indexOf('focus') > -1,
        flagOutline: display.indexOf('outline') > -1,
        flagXray: display.indexOf('xray') > -1,
        flagZoomOut: display.indexOf('zoom-out') > -1 || listView === 'create',
        renderMode: display.indexOf('preview') === -1 ? RENDER_MODE_EDIT : null,
        pending: isPending(store, 'front.page'),
        id: selectRouteParam(store, 'id'),
        uri: selectRouteParam(store, 'uri')
    }
};

const mapDispatchToProps = {
    onDragSection: dragSection,
    pushRouteParams: pushRouteParams,
    onCreateSection: pushRouteParams,
    onChange: change
};

class PageService extends React.PureComponent {

    handleDragSection = (...args) => {
        const { resource, id, onDragSection } = this.props;
        onDragSection(resource, id, ...args);
    };

    handleChangeOrder = (sections) => {
        const { onChange, resource, id } = this.props;
        let update = {};
        update.sections = sections;
        onChange(resource, id, update);
    };

    createSection = () => {
        const { onCreateSection } = this.props;
        onCreateSection({list: 'create'});
    };

    link = (props) => {
        const {
            serviceAlias,
            pushRouteParams,
            renderMode
        } = this.props;

        if (renderMode === RENDER_MODE_EDIT) return;

        return makeLink(
            null,
            pushRouteParams,
            serviceAlias,
            props
        );
    };

    render() {
        const {
            apiContext,
            serviceAlias,
            uri,
            preset,
            pending,
            service,
            flagFocus,
            flagXray,
            flagOutline,
            flagZoomOut,
            renderMode
        } = this.props;

        return (<ApiContext.Provider value={apiContext}>
            <RequireAuthentication>
                <Bootstrap requireService={true} id={service} serviceType={'website'}>
                    <FetchPageData service={service}>
                    <ThemeProvider
                        target={true}
                        loadFonts={true}
                        apply={true}
                        dark={true}
                        alias={'@currentPreset'}
                    >
                        <IdePage
                            pending={pending}
                            preset={preset}
                            inspect={flagFocus}
                            service={service}
                        >
                            <RenderContext.Provider value={renderMode}>
                                <LinkerContext.Provider value={this.link}>
                                    <GlobalScripts>
                                        <RenderPage
                                            apiContext={apiContext}
                                            uri={uri}
                                            service={serviceAlias}
                                            editable={flagZoomOut}
                                            SectionIterator={flagZoomOut
                                                ? SortableSectionIterator
                                                : SortableSectionIterator}
                                            onSortEnd={this.handleChangeOrder}
                                            onDragSection={this.handleDragSection}
                                            onCreateSection={this.createSection}
                                            className={cn('theme-scope default',{
                                                'debug-outline': flagOutline,
                                                'debug-x-ray': flagXray,
                                                'zoom-out': flagZoomOut
                                            })}
                                        />
                                    </GlobalScripts>
                                </LinkerContext.Provider>
                            </RenderContext.Provider>
                        </IdePage>
                        <Modal />
                        <MediaPreview />
                        <ConfirmDialog />
                        <Snackbar/>
                        <DragSectionPrompt />
                        <MediaBrowserDrawer />
                        <MediaEdit />
                    </ThemeProvider>
                    </FetchPageData>
                </Bootstrap>
            </RequireAuthentication>
        </ApiContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageService);