import React, { useState } from "react";

//import PropTypes from 'prop-types';

export function AttributeInput(props) {
    const { value, onChange, edit, onEdit, placeholder } = props;
    const [inputState, setInputState] = useState(null);

    const change = () => {
        if (inputState === null || inputState === value) {
            if (onEdit) onEdit(false);
            setInputState(null);
            return;
        }
        onChange(inputState);
        setInputState(null);
    };

    const handleKeyDown = (evt) => {
        if (evt.key === "Enter") {
            change();
        }
        if (evt.key === "Escape") {
            setInputState(null);
            if (onEdit) onEdit(false);
        }
    };

    if (!edit) {
        return <div onClick={() => onEdit(true)}>{value || "edit"}</div>;
    }

    let inputValue = inputState || value || "";
    if (inputState === "") inputValue = "";

    return (
        <input
            value={inputValue}
            onChange={(e) => setInputState(e.target.value)}
            onBlur={change}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            autoFocus={true}
        />
    );
}

AttributeInput.propTypes = {};

AttributeInput.defaultProps = {};

export default AttributeInput;
