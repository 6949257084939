import React from "react";
//import PropTypes from 'prop-types';

import useMeeting from "joynt/meeting/hooks/useMeeting";
import { useUiFlag } from "state/hooks";
import Portal from "@material-ui/core/Portal";
import Button from "components/Button";
import { ResponsiveModal } from "components/Modal";
import Icon from "components/Icon";

export function WebinarWelcomeMessage(props) {
    const { children } = props;
    const { session, onShowSettings } = useMeeting();
    const [confirmed, setConfirmed] = useUiFlag(
        `meeting.${session}.welcomeConfirmed`
    );
    const dismiss = () => {
        setConfirmed(true);
    };
    return (
        <>
            {children}
            <Portal>
                <ResponsiveModal open={!confirmed} onClose={dismiss}>
                    <div className={"pad-md rows gap-md"}>
                        <h2 className={"text-center"}>Welcome to the session</h2>
                        <h4 className={"text-center"}>
                            A few tips for better experience:
                        </h4>
                        <div className={"rows gap-sm"}>
                            <div>
                                <div className={"welcome-message-icon"}>
                                    <Icon>mui-pan_tool</Icon>
                                </div> Make sure Joynt is allowed to <span className={"link"} onClick={()=>onShowSettings(true)}>
                                access camera and mic</span> by your browser.</div>
                            <div>
                                <div className={"welcome-message-icon"}>
                                    <Icon>mui-lock</Icon>
                                </div> If you are in the audience and want to speak on stage, click "Raise Hand" and wait to be allowed on stage.
                            </div>
                            <div>
                                <div className={"welcome-message-icon"}>
                                    <Icon>mui-signal_wifi_off</Icon>
                                </div> In case of weak internet connection please close
                                unneeded tabs and apps that use your internet data.
                            </div>
                        </div>
                        <h4 className={"text-center"}>Enjoy the session!</h4>
                        <div className={"cols cols-center gap-4"}>
                            <Button onClick={dismiss} size={"large"}>
                                Got it
                            </Button>
                        </div>
                    </div>
                </ResponsiveModal>
            </Portal>
        </>
    );
}

WebinarWelcomeMessage.propTypes = {};

WebinarWelcomeMessage.defaultProps = {};

export default WebinarWelcomeMessage;
