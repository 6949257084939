import React from "react";
import { connect } from "react-redux";
import { selectRouteParam } from "state/selectors/router";
import Page from "cms/containers/Page";
import { StylesProvider } from "cms/styles/legacy";
import { isPending } from "state/selectors/ui";
import { resolveAlias } from "state/selectors/data";
import env from "util/env";

const ENDPOINT = env("ZUU_CONSOLE_API_ENDPOINT");

function pageSlugFromUri(uri) {
    let parts = uri.split("/");
    return parts[parts.length - 1];
}

function pageDataUrl(service, id, uri, type) {
    if (uri) return `${ENDPOINT}/page/${service}?uri=${uri}`;
    if (id)
        return `${ENDPOINT}/v2/page/?service=${service}&id=${id}&type=${type}`;
    return null;
}

const mapStateToProps = (store, props) => {
    const id = selectRouteParam(store, "id"); //'87e3966a-c174-452b-8402-bb3bdfd4422f';
    const uri = props.uri;
    const slug = uri ? pageSlugFromUri(uri) : null;
    const resolvedId = slug
        ? resolveAlias(store, "cms.pages", "@slug." + slug)
        : id;
    const service = props.service;
    const type = selectRouteParam(
        store,
        "resource",
        selectRouteParam(store, "type")
    );
    return {
        id: resolvedId,
        router: store.router,
        url: pageDataUrl(service, id, uri, type),
        pending: isPending(store, ["front.page"]),
        type: type && type.indexOf(".") > -1 ? type : "cms.pages",
    };
};

const mapDispatchToProps = {};

class RenderPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id };
    }

    componentDidUpdate(prevProps) {
        const { pending } = this.props;
        if (!pending && pending !== prevProps.pending) {
            this.setState({ id: this.props.id });
        }
    }

    render() {
        const { url, type, className, uri, ...other } = this.props;

        const renderId = this.state.id || this.props.id;

        return (
            <StylesProvider>
                <Page
                    {...other}
                    type={type}
                    id={renderId}
                    className={className}
                />
            </StylesProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderPage);
