import React, { useCallback } from "react";
import useFetch from "state/hooks/useFetch";
import { useData, useList } from "state/hooks";
import RoleSelectMenu from "./../../RoleSelectMenu";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { appendQueryParams } from "util/uri";
//import PropTypes from 'prop-types';

export default function IdentitySearch(props) {
    const {
        onAddRole,
        enableRoleSelection,
        search,
        scope,
        node,
        minSearch,
    } = props;

    const type = "db.identities";
    const list = "role-search";
    const enable =
        !!(search && search.length >= minSearch) && search.indexOf("@") === -1;

    const endpoint = node
        ? `v2/joynt/nodes/${node}/participants`
        : `joynt/identities`;

    const url = appendQueryParams(endpoint, { search });

    const pending = useFetch({
        type: type,
        url,
        list: list,
        enable: enable,
    });

    const { items } = useList(type, list);
    const hasResults = items.length > 0;

    return (
        <div className={"rows"}>
            {hasResults && enable && (
                <MuiList>
                    {items.map((id) => (
                        <IdentityContainer
                            scope={scope}
                            type={type}
                            id={id}
                            onAddRole={onAddRole}
                            enableRoleSelection={enableRoleSelection}
                        />
                    ))}
                </MuiList>
            )}
            {!hasResults && !pending ? (
                <div className={"pad-sm text-center"}>No results</div>
            ) : null}
        </div>
    );
}

function IdentityContainer(props) {
    const { enableRoleSelection, onAddRole, scope } = props;
    const data = useData(props);
    const { id, name } = data;
    const selectRole = useCallback(
        (role) => {
            onAddRole(id, role || null);
        },
        [onAddRole, id]
    );
    const addRole = useCallback(() => onAddRole(id), [onAddRole, id]);
    const identityProps = { id, name };

    if (enableRoleSelection) {
        return (
            <RoleSelectMenu
                scope={scope}
                onSelect={selectRole}
                trigger={({ onClick }) => {
                    return <Identity {...identityProps} onClick={onClick} />;
                }}
            />
        );
    }

    return <Identity {...identityProps} onClick={addRole} />;
}

function Identity(props) {
    const { id, name, onClick } = props;
    return (
        <MuiListItem button={true} onClick={onClick}>
            <ListItemAvatar>
                <Avatar id={id} />
            </ListItemAvatar>
            <ListItemText primary={name} />
        </MuiListItem>
    );
}

IdentitySearch.propTypes = {};
IdentitySearch.defaultProps = {
    minSearch: 2,
};
