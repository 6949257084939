import uuid from "uuid/v4";

export const change = (type, id, update, rewire, rewireContext) => {
    return {
        type: "CMS.COMPONENT.CHANGE",
        payload: { type, id, update, rewire, rewireContext },
    };
};

export const createComponent = (type, id, key, item) => {
    return {
        type: "CMS.COMPONENT.CREATE",
        payload: {
            type,
            id,
            key,
            item,
        },
    };
};

export const moveComponent = (prev, next) => {
    return {
        type: "CMS.COMPONENT.MOVE",
        payload: {
            prev,
            next,
        },
    };
};

export const copyComponent = (type, id) => ({
    type: "CMS.COMPONENT.COPY",
    payload: { type, id },
});

export const pasteComponent = (appendPath, type, id) => ({
    type: "CMS.COMPONENT.PASTE",
    payload: { appendPath, type, id },
});

export const dragSectionCallback =
    (context) =>
    (cb, ...args) => {
        const dragAction = dragSection(context)(...args);
        dragAction.callback = cb;
        return dragAction;
    };

export const dragSection = (context) => (pageType, pageId, result) => {
    return {
        type: "CMS.SECTIONS.DRAG",
        payload: {
            pageType,
            pageId,
            result,
        },
        context,
    };
};

export const createSection = (pageType, pageId, data = {}) => ({
    type: "CMS.SECTIONS.CREATE",
    payload: {
        pageType,
        pageId,
        data: {
            id: uuid(),
            active: true,
            type: "default-content",
            draft: true,
            ...data,
        },
    },
});

export const dragSectionPrompt = (id, refId) => ({
    type: "CMS.SECTIONS.PROMPT",
    payload: { id },
});

export const dragSectionPromptCancel = () => ({
    type: "CMS.SECTIONS.CANCEL",
});

export const dragSectionPromptSelect = (id, data) => ({
    type: "CMS.SECTIONS.SELECT",
    payload: { id, data },
});

export const createStyle = (context) => () => {
    return {
        type: "CMS.STYLES.CREATE",
        context,
    };
};

export const createStyleFrom = (context) => (type, id, styleName) => ({
    type: "CMS.STYLES.CREATE_FROM",
    payload: { type, id, styleName },
    context,
});

export const inlineStyle = (context) => (type, id) => ({
    type: "CMS.STYLES.INLINE",
    payload: { type, id },
    context,
});

export const changeSections = () => {
    return {
        type: "CMS.SECTIONS.CHANGE",
    };
};

export const detachSection = (context) => (id) => ({
    type: "CMS.SECTIONS.DETACH",
    payload: { id },
    context,
});

export const save = (context) => () => {
    return {
        type: "CMS.SAVE",
        context,
    };
};

export const saveCallback = (context) => (callback) => {
    const saveAction = save(context)();
    saveAction.callback = callback;
    return saveAction;
};

export const saveToLibrary = (context) => (type, id) => ({
    type: "CMS.LIBRARY.SAVE",
    payload: { type, id },
    context,
});

export const importFromLibrary =
    (context) => (type, id, targetType, targetId) => ({
        type: "CMS.LIBRARY.IMPORT",
        payload: { type, id, targetType, targetId },
        context,
    });

export const toggleProperty = (context) => (type, id, property) => ({
    type: "CMS.TOGGLE_PROPERTY",
    payload: { type, id, property },
    context,
});

export const fetchStyles = (context) => () => ({
    type: "CMS.STYLES.FETCH",
    payload: {},
    context,
});

export const storeStyles = (styles) => ({
    type: "CMS.STYLES.STORE",
    payload: { styles },
});

export const deleteUnusedSections = (context) => () => ({
    type: "CMS.SECTIONS.DELETE_UNUSED",
    payload: {},
    context,
});

export const exportToLibrary = (context) => (id) => ({
    type: "CMS.LIBRARY.EXPORT",
    payload: { id },
    context,
});

export const createTag = (context) => (data, list) => ({
    type: "CMS.CREATE_TAG",
    context,
    payload: { data, list },
});

export const convertToTemplate = (type, id) => ({
    type: "CMS.SECTIONS.CONVERT_TO_TEMPLATE",
    payload: { type, id },
});

export const createTagCallback =
    (context) =>
    (cb, ...args) => {
        const createAction = createTag(context)(...args);
        createAction.callback = cb;
        return createAction;
    };
