import React from 'react';
//import PropTypes from 'prop-types';

import MediaBrowserContainer from "containers/media-browser/MediaBrowser";

export default class MediaBrowser extends React.PureComponent {

    render() {
        const {
            onSelect,
            onSelectionRemove,
            selection
        } = this.props;

        return (<MediaBrowserContainer
            onSelect={onSelect}
            onSelectionRemove={onSelectionRemove}
            selection={selection}
        />);
    }

}

MediaBrowser.propTypes = {};