import React from 'react';
import cn from "classnames";

import PropTypes from 'prop-types';

import "./style.css";

export function ProgressBar(props) {

    const {
        value,
        label,
        className
    } = props;

    const style = {width: `${value}%`};

    return <div className={cn("progress-bar", {
        "progress-bar--labeled": !!label
    }, className)}>
        <div
            className={"progress-bar__bar"}
            style={style}
        />
        {label && <div className={"progress-bar__label"}>
            {label}
        </div>}
    </div>;

}

ProgressBar.propTypes = {
    value: PropTypes.number,
    label: PropTypes.string
};

ProgressBar.defaultProps = {
    value: 0
};

export default ProgressBar;
