import fontAwesomeIcons from "resources/font-awesome.json";
import fhDefaultIcons from "resources/fh-default.json";
import muiIcons from "resources/material-ui.json";

const all= {
    'fh-default': Object.entries(fhDefaultIcons.icons).map(([key, value]) => key),
    'font-awesome': Object.entries(fontAwesomeIcons.icons).map(([key, value]) => {
        return value.toLowerCase().split(" ").join("-");
    }),
    'material-ui': Object.entries(muiIcons.icons).map(([key, value]) => 'mui-'+key),
};

export const mui = {
    'material-ui': all['material-ui']
};

export default all;