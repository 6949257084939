function replaceStringWithObject(str, vars) {
    if (!str) return str;
    return str.replace(/\{(\w+)\}/g, (match, key) => {
        // Check if the key exists in the 'vars' object.
        if (key in vars) {
            // Replace with the value from the 'vars' object.
            return vars[key];
        }
        // If the key does not exist, do not replace it.
        return match;
    });
}

export default function (source, data) {
    return replaceStringWithObject(source, data);
}
