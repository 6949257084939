import React from 'react';
import PropTypes from 'prop-types';

import Preloader from "components/Preloader";
import Grow from "@material-ui/core/Grow";
import Button from "components/Button";
import StageCover from "joynt/components/SessionFlow/StageCover";

import Lottie from "react-lottie";
import animationData from "./animation.json";

function Animation(props) {
    const options = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    return <Lottie
        options={options}
        height={'100%'}
        width={'100%'}
    />;
}

export function BadgeReceived(props) {

    const {
        isPending,
        onProgress,
        show,
        offline
    } = props;
    return (<StageCover
        transparent={!offline}
        title={"You got badge"}
        isPending={isPending}
        variant={offline ? "landscape" : "default"}
    >
        <Preloader visible={isPending} />
        <div className={"badge-animation-bg"}>
            <Animation />
        </div>
        <div className={"badge-animation"}>
            <Grow in={show}>
                <div className={"witness-badge"} />
            </Grow>
        </div>
        <Button onClick={onProgress} variant={"outlined"}>
            Got it
        </Button>
    </StageCover>);

}

BadgeReceived.propTypes = {
    isPending: PropTypes.bool,
    show: PropTypes.bool
};
BadgeReceived.defaultProps = {
    isPending: false,
    show: true
};

export default BadgeReceived;
