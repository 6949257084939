import React from "react";
import PropTypes from "prop-types";

import Drawer from "@material-ui/core/Drawer";
import UserSettings from "./../UserSettings.container";
import PhoneHeader from "joynt/components/AppLayout/PhoneAppLayout/PhoneHeader/PhoneHeader";

export function UserSettingsDrawer(props) {
    const { isOpen, onClose } = props;

    return (
        <Drawer open={isOpen} onClose={onClose} anchor={"right"}>
            <PhoneHeader onGoBack={onClose} title={"User settings"} />
            <UserSettings {...props} />
        </Drawer>
    );
}

UserSettingsDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

UserSettingsDrawer.defaultProps = {
    isOpen: false,
};

export default UserSettingsDrawer;
