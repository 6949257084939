import { useNodeData } from "joynt/hooks";
import { useNotificationCount } from "joynt/hooks/notifications";
import { useEffect, useMemo, useState } from "react";
import { useData } from "state/hooks";
import useTimeout from "util/hooks/useTimeout";

export function useChatTicker(id) {
    const { thread } = useNodeData(id);

    const [notifications] = useNotificationCount(id);

    const [time, setTime] = useState(new Date().getTime() / 1000);
    const [message, setMessage] = useState(null);
    const [show, setShow] = useState(false);

    const { updated_at, last_message } = useData({
        type: "db.threads",
        id: thread,
    });

    useEffect(() => {
        if (updated_at >= time) {
            setTime(updated_at);
            setMessage(last_message);
        }
    }, [updated_at, time, last_message, setMessage, setTime]);

    useEffect(() => {
        setShow(!!(message && notifications));
    }, [message, notifications, setShow]);

    useTimeout(
        () => {
            setShow(false);
        },
        show ? 10000 : null,
        last_message
    );

    return useMemo(
        () => ({
            message,
            show,
        }),
        [message, show]
    );
}
