import React from "react";
import cn from "classnames";
import { useRouteParam } from "state/hooks";
import HelperCard from "./HelperCard";
import { useJoyrideProvider } from "../Joyride/hooks/useJoyrideProvider";
import { ROUTE_VIEW } from "joynt/config";
import "./styles.css";
import useScreenSize from "util/hooks/useScreenSize";

export default function HelperBar() {
    const { setJoyride, setJoyrideShouldRun } = useJoyrideProvider();
    const [, setView] = useRouteParam(ROUTE_VIEW);
    const { isMobile } = useScreenSize();

    if (isMobile) return null;

    const cardData = [
        {
            title: "Posting on feed",
            id: "post",
            icon: "mui-chat",
        },
        {
            title: "Discussing on a chat",
            id: "chat",
            icon: "mui-send",
        },
        {
            title: "Starting video session",
            id: "video",
            icon: "mui-videocam",
            onClick: () => {
                setView("workspace-schedule");
            },
        },
        {
            title: "Messages in inbox",
            id: "inbox",
            icon: "mui-inbox",
        },
    ];

    return (
        <section>
            <h2 className={"helper-bar__heading"}>
                Learn more about Joynt features
            </h2>
            <div className={cn("helper-bar__cards gap-3", !isMobile && "cols")}>
                {cardData.map((card) => {
                    return (
                        <div className={"col"}>
                            <HelperCard
                                icon={card.icon}
                                title={card.title}
                                onClick={() => {
                                    if (card.onClick) {
                                        card.onClick();
                                    } else {
                                        setJoyride(card.id);
                                        setJoyrideShouldRun(true);
                                    }
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
