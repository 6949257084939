import React from "react";
import { nodeFlags } from "./util";

//import PropTypes from 'prop-types';

export function NodeFlags(props) {
    const { data, node: argNode } = props;
    const node = argNode || nodeFlags(data);

    const flags = [
        node.hasAttributes && "{ }",
        node.hasHyperscript && "_",
        node.hasMapping && "@",
    ].filter(Boolean);

    return (
        <div className={"node-flags"}>
            {flags.map((flag) => (
                <span key={flag} className={"flag"}>
                    {flag}
                </span>
            ))}
        </div>
    );
}

NodeFlags.propTypes = {};

NodeFlags.defaultProps = {
    data: {},
};

export default NodeFlags;
