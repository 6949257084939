import React, { useEffect } from "react";
import useWhisper from "joynt/websockets/hooks/useWhisper";
import usePrivateChannel from "joynt/websockets/hooks/usePrivateChannel";

//import PropTypes from 'prop-types';

const handler = (...e) => console.log(e);

export function RealTimeChannel(props) {
    const { id } = props;
    const channel = `rt-${id}`;
    const sub = usePrivateChannel(channel);
    const send = useWhisper(sub, id, handler);

    useEffect(() => {
        const l = (e) => {
            if (send) send(e.clientX);
        };
        window.addEventListener("mousemove", l);
        return () => {
            window.removeEventListener("mousemove", l);
        };
    }, [send]);

    return null;
}

RealTimeChannel.propTypes = {};
RealTimeChannel.defaultProps = {};

export default RealTimeChannel;
