import React from "react";

export function DeletedMessage(props) {
    return (
        <div className={"message__body"}>
            <div className={"message__text o-30"}>message deleted</div>
        </div>
    );
}

export default DeletedMessage;
