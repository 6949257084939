import React from "react";
import { FormContainer, FormLayout } from "playground/cms/Form";
import Field from "containers/Field";
import { useApiActions, useData, usePending, useRouteParam } from "state/hooks";
import Languages from "./Languages";
import { ThemeGit } from "playground/console/views/Themes";
import { destroyWebsite } from "cms/state/actions/services";
import Button from "components/Button";
import Icon from "components/Icon";
import { buildTheme } from "playground/cms/state/actions/buildTheme";
import { ThemeFormActions } from "playground/console/views/Themes";

//import PropTypes from 'prop-types';

function WebsiteFormContainer(props) {
    const { content_source, theme } = useData(props);
    return (
        <FormContainer {...props} disableFetch={true}>
            <WebsiteForm {...props} cmsId={content_source} themeId={theme} />
        </FormContainer>
    );
}

function DestroyWebsite(props) {
    const { id } = props;
    const { onDestroy } = useApiActions({
        onDestroy: destroyWebsite,
    });

    return (
        <FormLayout.Section
            name={"Usuń"}
            icon={"mui-delete"}
            description={"Usuń witrynę i wszystkie zależności"}
            action={
                <Button color={"danger"} onClick={() => onDestroy(id)}>
                    Usuń
                </Button>
            }
        />
    );
}

export function WebsiteForm(props) {
    const { type, id, themeId, cmsId } = props;
    return (
        <FormLayout>
            <FormLayout.Content>
                <FormLayout.Fieldset pad>
                    {/*<Field id={"name"} label={"Nazwa"} />*/}
                    {/*<Field id={"description"} label={"Opis"} />*/}
                    {cmsId && (
                        <FormContainer
                            type={"console.services.cms"}
                            id={cmsId}
                            disableFetch={true}
                        >
                            <Languages>
                                <div className={"cols gap-sm"}>
                                    <div>Waluta</div>
                                    <Field
                                        id={"currency"}
                                        label={"Wybierz"}
                                        fieldType={"select"}
                                        source={"schema:data.currencies"}
                                        variant={"inline"}
                                        nullable={true}
                                    />
                                </div>
                            </Languages>
                            <FormLayout.Section
                                name={`Media`}
                                icon={"mui-photo_library"}
                            >
                                <FormLayout.Fieldset>
                                    <div className={"cols cols-2 gap-md"}>
                                        <Field
                                            id={"storage_id"}
                                            label={"Storage id"}
                                            size={"small"}
                                        />
                                        <Field
                                            id={"disable_auto_library_import"}
                                            label={"Disable s3 sync"}
                                            fieldType={"checkbox"}
                                        />
                                    </div>
                                </FormLayout.Fieldset>
                            </FormLayout.Section>
                        </FormContainer>
                    )}
                    {themeId && (
                        <ThemeFormActions
                            {...props}
                            type={"console.themes"}
                            id={themeId}
                        />
                    )}

                    <FormContainer type={type} id={id} disableFetch={true}>
                        <FormLayout.Section
                            name={"Biblioteka sekcji"}
                            icon={"mui-file_copy"}
                            action={
                                <Field
                                    label={"wybierz"}
                                    id={"library"}
                                    fieldType={"select"}
                                    source={"api:console.services?type=website"}
                                    nullable={true}
                                    variant={"inline"}
                                />
                            }
                        ></FormLayout.Section>

                        <FormLayout.Section
                            name={"Mapa witryny"}
                            description={"sitemap.xml"}
                            icon={"mui-account_tree"}
                            action={
                                <Field
                                    id={"disable_sitemap"}
                                    fieldType={"checkbox"}
                                    negate={true}
                                />
                            }
                        />
                    </FormContainer>
                    {id && <DestroyWebsite {...props} />}
                </FormLayout.Fieldset>
            </FormLayout.Content>
        </FormLayout>
    );
}

WebsiteForm.propTypes = {};

WebsiteForm.defaultProps = {
    type: "console.services.website",
};

export default WebsiteFormContainer;
