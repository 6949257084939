import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import uuid from 'uuid/v4';

import OfferSelection from "playground/booking/OfferSelection";
import SearchForm from "playground/booking/SearchForm";

import 'playground/booking/css/style.css';
import 'playground/booking/css/date-picker.css';
import {selectEntity} from "state/selectors/data";
import {change, create} from "state/actions/data";
import {bindApiActions} from "containers/context/ApiContext";
import ProductSelection from "playground/booking/ProductSelection";
import Checkout from "playground/booking/Checkout";
import {Stepper} from "@material-ui/core";
import Step from "@material-ui/core/Step";
//import StepLabel from "@material-ui/core/StepLabel";
import StepButton from '@material-ui/core/StepButton';
import Confirmation from "playground/booking/Confirmation";
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";
import {RoomsNav} from "playground/booking/RoomsNav";
import {ConnectedField as Field} from "containers/Field";
import Dialogs from "playground/booking/Dialogs";
import Button from "@material-ui/core/Button";
import {submitOne} from "state/actions/api";

const mapStateToProps = (store, props) => {
    const type = 'app.booking';
    const id = '@bookingForm';
    const data = selectEntity(store, type, id);
    let room = selectRouteParam(store, 'room')*1 || 0;
    if (data.rooms && room > data.rooms.length-1) room = data.rooms.length-1;
    return {
        type,
        id,
        data,
        room,
        step: selectRouteParam(store, 'step')*1 || 0,
        roomsUrl: 'cms/entries?type=rooms&expand=true'
    }
};

const mapDispatchToProps = bindApiActions({
    onSubmit: submitOne
},{
    onChange: change,
    onChangeStep: pushRouteParams,
    onChangeRoom: pushRouteParams,
    onShowProduct: pushRouteParams,
    onShowRoom: pushRouteParams,
    onCreate: create
});

function initialBooking() {
    const startDate = moment().startOf('day');
    const endDate = startDate.clone();
    endDate.add(1, 'days');
    return {
        id: '@bookingForm',
        date_from: startDate.format('YYYY-MM-DD'),
        date_to: endDate.format('YYYY-MM-DD'),
        confirmation_method: 'online',
        rooms: [{
            id: uuid(),
            adults: 2
        }]
    }
}

class Booking extends React.PureComponent {

    componentDidMount() {
        const { onCreate, type } = this.props;
        onCreate(type, initialBooking());
    }

    change = (data) => {
        const { onChange, onChangeStep, type, id, step} = this.props;
        onChange(type, id, data);

        if (data.date_from || data.date_to) {
            onChangeStep({step: 0});
        }

        if (data.room && !step) {
            onChangeStep({step: 1});
        }
    };

    selectOffer = (update) => {
        console.log(update);
        const { onChange, data, room } = this.props;
        const roomId = data.rooms[room];
        onChange('app.booking-rooms', roomId, update);
        if (room === data.rooms.length-1) {
            this.step(1);
            return;
        }
        this.room(room+1);
    };

    selectProduct = (id) => {

    };

    showProduct = (id) => {
          const { onShowProduct } = this.props;
          onShowProduct({show: 'cms.products', id});
    };

    showRoom = (id) => {
        const { onShowRoom } = this.props;
        onShowRoom({show: 'cms.entries.rooms', id});
    };

    room = (index) => {
        const { onChangeRoom } = this.props;
        onChangeRoom({room: index});
    };

    step = (step) => {
        const { onChangeStep } = this.props;
        onChangeStep({step});
    };

    next = () => {
        const { step } = this.props;
        if (step === 2) {
            this.submit();
            return;
        }
        this.step(step+1);
    };

    submit = () => {
        const url = 'some/api/endpoint';
        const { onSubmit, type, id } = this.props;
        onSubmit(type, id, url)  ;
    };

    render() {
        const { type, id, data, step, room, roomsUrl } = this.props;
        const { rooms } = data;

        return (<div className={'section-contain default'}>
            <Dialogs />
            <div className={'container'}>
                <div className={'default rows'}>
                    <div className={'section dark cols cols-center cols-middle'}>
                        <h1>Page header</h1>
                    </div>
                    <div className={'booking-search pad-sm cols cols-spread phone@rows phone@pad-sm primary'}>
                        <div className={'cols gap-sm'}>
                            <SearchForm
                                type={type}
                                id={id}
                                data={data}
                                onChange={this.change}
                            />
                        </div>
                        <div>kwota</div>
                    </div>
                    <div className={'pad-md phone@pad-sm rows gap-md'}>
                        { step !== 0 ? <Stepper activeStep={step} nonLinear>
                            <Step completed={true}>
                                <StepButton onClick={()=>this.step(0)}>Wybór oferty</StepButton>
                            </Step>
                            <Step>
                                <StepButton onClick={()=>this.step(1)}>Dodatki</StepButton>
                            </Step>
                            <Step>
                                <StepButton onClick={()=>this.step(2)}>Płatność</StepButton>
                            </Step>
                            <Step>
                                <StepButton onClick={()=>this.step(3)}>Potwierdzenie</StepButton>
                            </Step>
                        </Stepper> : null }
                        { step === 0 && rooms && rooms.length > 1 ? <Field
                            entityType={type}
                            entityId={id}
                            id={'rooms'}
                            fieldType={'collection'}
                            collectionType={'app.booking-rooms'}
                            onClick={this.room}
                            active={room}
                            CollectionRenderer={RoomsNav}
                        /> : null }
                        { step === 0 ? <OfferSelection
                            type={type}
                            id={id}
                            data={data}
                            onChange={this.change}
                            onSelect={this.selectOffer}
                            url={roomsUrl}
                        /> : null }
                        { step === 1 ? <ProductSelection
                            data={data}
                            onShow={this.showProduct}
                            onSelect={this.selectProduct}
                        /> : null }
                        { step === 2 ? <Checkout
                            type={type}
                            id={id}
                        /> : null }
                        { step === 3 ? <Confirmation data={data}/> : null }
                        { step > 0 ? <div className={'cols cols-right'}>
                            <Button onClick={this.next} size={'large'} color={'primary'} variant={'contained'}>Dalej</Button>
                        </div> : null }
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Booking);