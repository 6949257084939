import React from "react";
//import PropTypes from 'prop-types';

export default function Layout(props) {
    const { title, description, children } = props;

    return (
        <div className={"device-settings dark-mode bg rows gap-sm pad-md"}>
            <div className={"text-center type-heading-sm"}>{title}</div>
            <div className={"text-center type-default"}>{description}</div>
            {children}
        </div>
    );
}

Layout.propTypes = {};
Layout.defaultProps = {};
