export const submitPost = (context) => (id, share) => ({
    type: "JOINT.POST.SUBMIT",
    payload: { id, share },
    context,
});

export const resetPost = (id) => ({
    type: "JOINT.POST.RESET",
    payload: { id },
});

export const createPost = (callback, data, list) => ({
    type: "JOINT.POST.CREATE",
    payload: { callback, data, list },
});

export const createPostContent = (context) => (data, cb) => ({
    type: "JOINT.POST.ADD",
    payload: {
        data,
    },
    cb,
    context,
});

export const createPostSubmit = (context) => (id, list, type) => ({
    type: "JOINT.POST.CREATE.SUBMIT",
    payload: { id, list, type },
    context,
});

export const createPostReject = (id) => ({
    type: "JOINT.POST.CREATE.REJECT",
    payload: { id },
});

export const postUpload = (context) => (parent, id, file) => ({
    type: "JOINT.POST.UPLOAD",
    payload: { parent, id, file },
    context,
});

export const postUploadMultiple = (context) => (parent, id, files, post) => ({
    type: "JOINT.POST.UPLOAD.MULTIPLE",
    payload: { parent, id, files, post },
    context,
});

export const postUpgradeEditor = (id) => ({
    type: "JOINT.POST.UPGRADE_EDITOR",
    payload: { id },
});
