import React, { useState } from "react";
//import PropTypes from 'prop-types';
import Attributes from "./Attributes";

function attributeMapReducer(attrMap, index, update) {
    const arr = Object.keys(attrMap).map((name, i) => {
        return { name, value: attrMap[name] };
    });

    if (!arr[index]) arr[index] = {};
    const next = { ...arr[index], ...update };

    if (JSON.stringify(next) === JSON.stringify(arr[index])) return attrMap;

    arr[index] = next;

    return arr
        .filter((a) => !!a.name)
        .reduce((acc, attr) => {
            acc[attr.name] = attr.value;
            return acc;
        }, {});
}

function mapToList(attrMap) {
    return Object.keys(attrMap).map((name, index) => {
        return { name, value: attrMap[name], index };
    });
}

export function AttributesContainer(props) {
    const { hiddenAttributeNames, value, onChange } = props;
    const [edit, setEdit] = useState(null);

    const attributes = mapToList(value);

    const handleChange = (nextValue) => {
        const [index] = edit.split(":");
        const next = attributeMapReducer(value, index * 1, nextValue);
        if (onChange && next !== value) onChange(next);
        setEdit(null);
    };

    const handleCreate = (newAttributeName) => {
        if (!newAttributeName) return;
        if (Object.keys(value).includes(newAttributeName)) return;
        const next = attributeMapReducer(value, Object.keys(value).length, {
            name: newAttributeName,
            value: "",
        });
        if (onChange) onChange(next);
    };

    const visible = attributes.filter(
        (a) => !hiddenAttributeNames.includes(a.name)
    );

    return (
        <Attributes
            focusId={edit}
            onEdit={setEdit}
            onChange={handleChange}
            onCreate={handleCreate}
            items={visible}
        />
    );
}

AttributesContainer.propTypes = {};

AttributesContainer.defaultProps = {
    value: {},
    hiddenAttributeNames: [],
};

export default AttributesContainer;
