import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import NotificationsList from "./../NotificationsList";

import "./style.css";

export function NotificationsPopup(props) {
    const { open, onClose } = props;
    return (
        <div
            className={cn("notifications-popup", {
                "is-open": open,
            })}
        >
            {open && <NotificationsList />}
            <div onClick={onClose}>close</div>
        </div>
    );
}

NotificationsPopup.propTypes = {};

NotificationsPopup.defaultProps = {};

export default NotificationsPopup;
