import React from 'react';
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import Button from "components/Button";
//import PropTypes from 'prop-types';

export default function PollSettings(props) {

    const {id, onToggleSettings} = props;

    return (<div className={'rows pad-md gap-md'}>
        <AggregateRoot type={'db.posts'} id={id} disableSubmit={true}>
            <Field id={'poll_anonymous'} fieldType={'checkbox'} label={'Anonymous voting'} />
        </AggregateRoot>
        <div className={'cols cols-center'}>
            <Button onClick={onToggleSettings}>Close</Button>
        </div>
    </div>);

}

PollSettings.propTypes = {};
PollSettings.defaultProps = {};
