import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';

import Edit from "cms/ui/Edit";
import {selectFieldValue} from "state/selectors/data";
import {parseTemplateMapping} from "cms/util/templatable";
import {RenderFieldType} from "containers/Field";

import 'cms/ui/css/field-mapping.css';

const MappingContext = React.createContext(null);
const MappingEditContext = React.createContext(null);

const hideFields = [
    'components',
    'style',
    'breakpoints'
];

/*const hideFieldsUser = [
    'components',
    'style',
    'breakpoints',
    'items',
    'template_id',
    'type',
    'element_type',
    'symbol'
];*/

const layoutFields = [
    'tab'
];

const disableMapping = [
    'element_type',
    'type',
    'template_id',
    'style'
];

function RenderField(props) {
    if (hideFields.indexOf(props.id) > -1) return null;

    const layoutField = layoutFields.indexOf(props.fieldType) > -1;
    const noMapping = disableMapping.indexOf(props.id) > -1;

    if (layoutField || noMapping) {
        return <RenderFieldType
            {...props}
            variant={'dense'}
        />
    }

    return <>
        <MappingContext.Consumer>
            {(mapping) =>  <MappingEditContext.Consumer>
                {(onEditMapping) =><RenderMappedField
                    {...props}
                    mapping={mapping}
                    onEditMapping={onEditMapping}
                />}
            </MappingEditContext.Consumer>}
        </MappingContext.Consumer>
    </>;
}

function RenderMappedField(props) {
    let variant = props.variant || 'dense';

    const { id } = props;
    const { mapping, onEditMapping, ...other } = props;

    const isMapped = mapping[id] || false;

    const click = useCallback(
        (evt) => onEditMapping(evt, id),
        [id, onEditMapping]);

    return <div
        onClick={isMapped ? click : null}
        onContextMenu={click}
        className={cn('field-mapping', 'w-fill', {
            'is-mapped': isMapped
        })}
    >
        <div className={'field-content w-fill'}>
            <RenderFieldType
                {...other}
                variant={variant}
            />
        </div>
    </div>
}

const mapStateToProps = (store, props) => {
    let mapping = selectFieldValue(store, props.focusType, props.focusId, 'template_id');
    return {
        mapping: parseTemplateMapping(mapping)
    }
};

const mapDispatchToProps = {};

class EditContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            editMapping: null,
            anchorEl: null
        }
    }

    editMapping = (evt, id) => {
        evt.stopPropagation();
        evt.preventDefault();
        this.openMappingEdit(id, evt.target);
    };

    openMappingEdit = (id, anchor) => {
        this.setState({editMapping: id, anchorEl: anchor})
    };

    hideMappingEdit = () => {
        this.setState({editMapping: null, anchorEl: null});
    };

    render() {
        const { mapping } = this.props;
        return (
            <MappingContext.Provider value={mapping}>
                <MappingEditContext.Provider value={this.editMapping}>
                    <Edit
                        {...this.props}
                        {...this.state}
                        onHideMappingEdit={this.hideMappingEdit}
                        RenderField={RenderField}
                    />
                </MappingEditContext.Provider>
            </MappingContext.Provider>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditContainer);