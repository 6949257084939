export default {
    webinar: [
        {
            id: "start",
            overlay: true,
            pull: {
                description: "Session has started",
                descriptionIcon: "mui-videocam",
                message: "Do you want to join now?",
                confirmText: "Join",
                cancelText: "Not now",
                confirmType: "networking",
            },
            view: "webinar-welcome-message"
        },
        {
            id: "end",
            name: "Session has ended",
            state: "end",
            event_state: "ended",
            notify: {
                title: "Session has ended",
                message: "Thank you for participation",
                cancelText: false,
                confirmText: "Got it",
                confirmType: "networking",
            },
        },
    ]
}