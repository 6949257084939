import React from 'react';
import PropTypes from "prop-types";
import cn from "classnames";

import MuiSnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import MuiIcon from "@material-ui/core/Icon";

import Icon from "components/Icon";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

export function SnackbarContent(props) {
    const { classes, icon, message, onClose, variant, ...other } = props;

    return (
        <MuiSnackbarContent
            className={cn(classes[variant], variant)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={cn(classes.icon, classes.iconVariant)}>
                        {icon}
                    </Icon>
                    {message}
                </span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <MuiIcon className={classes.icon}>close</MuiIcon>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

SnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

SnackbarContent.defaultProps = {};

export const StyledSnackbarContent = withStyles(styles)(SnackbarContent);

export default StyledSnackbarContent;
