import createCachedSelector from "re-reselect";
import { createTheme } from "@material-ui/core/styles";
import { createMuiThemeFromPreset } from "util/theme/mui-theme";
import { selectAliasedEntity } from "state/selectors/data";

export const muiThemeSelector = (preset, dark) => {
    const fromPreset = createMuiThemeFromPreset(preset, dark);
    return createTheme(fromPreset);
};

export const selectMuiTheme = createCachedSelector(
    [
        (store, alias) => selectAliasedEntity(store, "console.presets", alias),
        (store, alias, dark) => dark,
    ],
    muiThemeSelector
)((store, preset, alias, dark) => {
    let id = preset ? preset.id : "undefined";
    return [id, alias, dark].join("/");
});
