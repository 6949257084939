import React from "react";
import { useGlobalNotifications } from "joynt/hooks/notifications";
import cn from "classnames";
import Icon from "components/Icon";
import Button from "components/Button";
import NotificationList from "./NotificationList";
//import PropTypes from 'prop-types';

export default function NotificationCenter(props) {
    const { onClose, group } = props;
    const { count, onReset } = useGlobalNotifications(group);

    return (
        <>
            <div className={"pad-xs cols cols-spread cols-middle"}>
                <div className={cn("cols cols-middle gap-px pad-sm-h")}>
                    <Icon
                        fontSize={"medium"}
                        className={cn({
                            "text-success": count > 0,
                        })}
                    >
                        mui-notifications
                    </Icon>
                    {count ? (
                        <div className={"type-strong"}>
                            {count < 100 ? count : "99+"}
                        </div>
                    ) : null}
                </div>
                <div>
                    <Button variant={"text"} onClick={onReset}>
                        Mark all as read
                    </Button>
                </div>
            </div>
            <div className={"select-options notifications-list"}>
                <NotificationList onClose={onClose} />
            </div>
        </>
    );
}

NotificationCenter.propTypes = {};
NotificationCenter.defaultProps = {
    group: "activity",
};
