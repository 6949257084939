import React from "react";
// import PropTypes from 'prop-types';

import "./style.css";

function makeSteps(steps) {
    let stepsArr = [];
    for (let i = 0; i < steps; i++) stepsArr.push(i);
    return stepsArr;
}

function opacity(threshold, value) {
    if (value > threshold) return 1;
    return 0;
}
export function MicrophoneLevel(props) {
    const { volume } = props;

    const steps = 12;
    const stepValue = 100 / steps;
    const arr = makeSteps(steps);

    return (
        <div className={"audio-volume-meter"}>
            {arr.map((s) => (
                <div
                    key={s}
                    style={{ "--o": `${opacity(stepValue * (s + 1), volume)}` }}
                />
            ))}
        </div>
    );
}

MicrophoneLevel.propTypes = {};

MicrophoneLevel.defaultProps = {};

export default MicrophoneLevel;
