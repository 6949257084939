export const push = (path) => {
    return {
        type: 'MODAL.PUSH',
        payload: {
            path
        }
    }
};

export const pop = () => {
    return {
        type: 'MODAL.POP'
    }
};