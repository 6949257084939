import React from "react";
import Field from "containers/Field";
import MediaContainer from "./Media.container";
//import PropTypes from 'prop-types';

export function MediaField(props) {
    const {} = props;
    return (
        <Field fieldType={"media"} {...props} FieldComponent={MediaContainer} />
    );
}

MediaField.propTypes = {};

MediaField.defaultProps = {};

export default MediaField;
