import React from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";
import { useCover, useData, useEntity } from "state/hooks";
import UploadOverlay from "joynt/components/WorkspaceHeader/UploadOverlay";

import "./style.css";

export function ImageField(props) {
    //const {} = props;
    const { type, id } = useEntity();
    const { media } = useData({ type, id });
    const img = useCover(media, [640, 480]);
    const style = {
        backgroundImage: img ? `url("${img}")` : null,
    };
    return (
        <div
            className={cn("image-field", {
                "is-empty": !img,
            })}
            style={style}
        >
            <UploadOverlay variant={"button"} field={"media"} />
        </div>
    );
}

ImageField.propTypes = {};
ImageField.defaultProps = {};

export default ImageField;
