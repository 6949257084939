import React from "react";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

export function EndCallButton(props) {
    const { onClick, size } = props;

    return (
        <IconButton
            size={size}
            icon={"mui-call_end"}
            onClick={onClick}
            disabled={!onClick}
            className={"session-end"}
        />
    );
}

EndCallButton.propTypes = {};
EndCallButton.defaultProps = {
    size: "medium",
};

export default EndCallButton;
