import React from 'react';
//import PropTypes from 'prop-types';

import {Component} from "cms/components";

export default class Symbol extends React.Component {

    render() {
        const { id, template_component } = this.props;

        if (id === template_component)
            return <div>!!!recursion</div>;

        if (template_component)
            return <Component type={'cms.components'} id={template_component} />

        return (<div>Symbol</div>);
    }

}

Symbol.propTypes = {};
Symbol.autoFillProps = [
    'template_component'
];