import Chat from "./Chat";
import People from "./People";
import Files from "./Files";
import Polls from "./Polls";
import QA from "./QA";
import Rooms from "./Rooms";
import Settings from "./Settings";
import Flow from "./Flow";
import Dev from "./Dev";

export default {
    chat: Chat,
    people: People,
    files: Files,
    polls: Polls,
    qa: QA,
    rooms: Rooms,
    settings: Settings,
    flow: Flow,
    dev: Dev,
};
