export const icons = {
    message: 'mui-comments',
    'message.dm': 'mui-comments',
    'message.comment': 'mui-comments',
    'message.question.reply': 'mui-comments',
    'session.start': 'mui-videocam',
    'session.end': 'mui-videocam',
    joined: 'mui-flag',
    'access.add': 'mui-verified_user',
    'access.remove': 'mui-verified_user',
    'event-role.add.moderator': 'mui-admin_panel_settings',
    'event-role.remove.moderator': 'mui-admin_panel_settings',
    'event-role.add.speaker': 'mui-record_voice_over',
    'event-role.remove.speaker': 'mui-record_voice_over',
    'post-published': 'mui-article',
    'post-published.link': 'mui-link',
    'post-published.question': 'mui-help',
    'app.node-roles-result': 'mui-manage_accounts'
}

export const variants = {
    message: 'identity',
    'message.dm': 'identity',
    'message.mention': 'identity',
    'message.comment': 'identity',
    'message.comment.mention': 'identity',
    'message.mention.all': 'identity',
    'message.share': 'identity',
    joined: 'identity',
    invite: 'identity',
    'post-published': 'identity',
    'post-published.link': 'identity',
    'post-published.question': 'identity',
    'message.question.reply': 'identity'
}

export const hideToasts = [
    'create-post',
    'left',
    'session.start',
    'session.end'
];

export const showSnackbar = [
    'session.start',
    'session.end'
]