import React, { useCallback, useMemo } from "react";
//import PropTypes from 'prop-types';

import { withSchema } from "containers/Field/withSchema";
import CodeEditor from "components/fields/code/CodeEditor";
import DenseField from "components/DenseField";

function JsonEditor({ onChange, value, ...other }) {
    const change = useCallback(
        (value) => {
            try {
                let parsed = JSON.parse(value);
                onChange(parsed);
            } catch (e) {
                onChange(value);
            }
        },
        [onChange]
    );

    let editorValue = useMemo(() => {
        return value && typeof value !== "string"
            ? JSON.stringify(value, null, 2)
            : value;
    }, [value]);

    return <Code {...other} onChange={change} value={editorValue} />;
}

class Code extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    open = () => this.setState({ open: true });

    close = () => this.setState({ open: false });

    change = (id, value) => {
        const { onChange } = this.props;
        onChange(value);
    };

    render() {
        const { id, value, label, variant, format } = this.props;

        let editorValue = value || "";

        if (variant === "dense")
            return (
                <DenseField label={label} type={"code"} value={editorValue}>
                    <div
                        style={{ width: "600px" }}
                        className={"file-manager theme-dark"}
                    >
                        <CodeEditor
                            id={id}
                            value={editorValue}
                            onChange={this.change}
                            height={"500px"}
                            format={format}
                            wrapEnabled={true}
                        />
                    </div>
                </DenseField>
            );

        return (
            <div className={"rows gap-xs fill theme-dark"}>
                <div className={"type-sm o-50"}>{label}</div>
                <CodeEditor
                    id={id}
                    value={editorValue}
                    onChange={this.change}
                    height={"300px"}
                    format={format}
                />
            </div>
        );
    }
}

Code.propTypes = {};

export default withSchema()(function (props) {
    const schema = props.schema || {};
    const properties = schema.properties || {};
    const format = properties.format;

    if (format === "json") return <JsonEditor {...props} format={"json"} />;

    return <Code format={properties.format} {...props} />;
});
