import React from "react";

//import PropTypes from 'prop-types';

export function SelectListItem(props) {
    const { children, icon, onClick } = props;
    return (
        <div onClick={onClick} className={"style-ui-option"}>
            {icon} {children}
        </div>
    );
}

SelectListItem.propTypes = {};

SelectListItem.defaultProps = {};

export default SelectListItem;
