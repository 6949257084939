import React from "react";
import { Route } from "react-router";
import JointExtension from "joynt/chrome-extension/Container";
import JitsiDev from "joynt/meeting/backends/JitsiDev";
import MockSession from "joynt/meeting/mock";
import ExampleConsole from "joynt/meeting/debug-console/ExampleConsole";
import { NotificationDevConsoleContainer } from "joynt/dev/notifications";
import PublicPage from "joynt/playground/PublicPage";
import Entrypoint from "joynt/Entrypoint";
//import PropTypes from 'prop-types';

export default function (props = {}) {
    return [
        <Route exact path="/extension">
            <JointExtension />
        </Route>,
        <Route exact path="/jitsi">
            <JitsiDev />
        </Route>,
        <Route exact path="/dev/stage">
            <MockSession />
        </Route>,
        <Route exact path="/dev/public">
            <Entrypoint>
                <PublicPage />
            </Entrypoint>
        </Route>,
        <Route exact path="/dev/console">
            <ExampleConsole />
        </Route>,
        <Route exact path="/dev/notifications">
            <NotificationDevConsoleContainer />
        </Route>,
    ];
}
