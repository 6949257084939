import { useCurrentService } from "playground/cms/hooks/useCurrentService";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";

export function useCurrentCanonicalDomain() {
    const service = useCurrentService();
    return useSelector((store) =>
        selectEntity(store, "console.domains", service?.canonical_domain)
    );
}
