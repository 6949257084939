import { select, takeEvery, put } from "redux-saga/effects";
import { selectEntity } from "state/selectors/data";
import { request } from "util/api/client";
import { pending } from "state/actions/ui";
import { storeOne } from "state/actions/data";

function* handleApartmentsImport({ context, payload }) {
    const { id, commit } = payload;
    try {
        const data = yield select((s) =>
            selectEntity(s, "apartments.resource-bindings", id)
        );
        yield put(pending(id, true));
        const response = yield request({
            method: commit ? "put" : "get",
            url: `apartments/resource-bindings/${id}/sync`,
            context,
        });
        yield put(pending(id, false));
        yield put(
            storeOne("apartments.resource-bindings.data", id, {
                ...response.data,
            })
        );
        console.log("Import", data);
    } catch {
        yield put(pending(id, false));
    }
}

export default function* () {
    yield takeEvery("APARTMENTS.IMPORT", handleApartmentsImport);
}
