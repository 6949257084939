import React from "react";
import { useData } from "state/hooks";

//import PropTypes from 'prop-types';

export function Nav(props) {
    const { template_id, navigation } = useData(props);
    const nav = useData({ type: "cms.navigation", id: navigation });
    return (
        <div>
            nav {nav?.name} {template_id}
        </div>
    );
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
