import React, { useState } from "react";
import { useData, useFetch, useField } from "state/hooks";
import { useSelector } from "react-redux";
import { selectDefaultPageLayout } from "playground/cms/state/selectors/layout";
import cn from "classnames";
import { selectEntity, selectList } from "state/selectors/data";
import SelectList, { SelectListItem } from "playground/ui/SelectList";

//import PropTypes from 'prop-types';

function selectParentLayoutOptions(store, type, id) {
    const items = selectList(store, "cms.layouts", "layout-select");
    const page = selectEntity(store, type, id);
    const pageTypeId = type.replace("cms.", "");
    const pageType = type === "cms.layouts" ? page.page_type : pageTypeId;

    const allowedTypes = ["pages", pageType];

    return items
        .filter((item) => {
            // Don't allow to select itself as a parent
            if (item.id === id) return false;

            return allowedTypes.includes(item.page_type) || !item.page_type;
        })
        .sort((a, b) => {
            // Matching page types go first
            return (b.page_type === pageType) - (a.page_type === pageType);
        })
        .map((item) => item.id);
}

function LayoutListItem(props) {
    const { onClick, isSelected } = props;
    const { title, description } = useData(props);
    return (
        <SelectListItem onClick={onClick} isSelected={isSelected}>
            {title || description}
        </SelectListItem>
    );
}

function LayoutSelect(props) {
    const { value, onChange, onClose, pageType, pageId } = props;
    useFetch({
        type: "cms.layouts",
        url: "cms/layouts",
        list: "layout-select",
        yieldData: true,
    });
    const items = useSelector((store) =>
        selectParentLayoutOptions(store, pageType, pageId)
    );
    return (
        <SelectList>
            <SelectListItem onClick={onClose}>Anuluj</SelectListItem>
            {items.map((item) => (
                <LayoutListItem
                    key={item}
                    onClick={() => onChange(item)}
                    isSelected={value === item}
                    type={"cms.layouts"}
                    id={item}
                />
            ))}
        </SelectList>
    );
}

export function LayoutDropdownMenu(props) {
    const { type, id, onEditLayout } = props;

    const { value, onChange } = useField(type, id, "layout");
    const [change, setChange] = useState(false);
    const defaultLayout = useSelector((store) => {
        return selectDefaultPageLayout(store, type, id);
    });
    const { title, description } = useData({
        type: "cms.layouts",
        id: defaultLayout,
    });
    const defaultLayoutName = title || description;

    if (change) {
        return (
            <LayoutSelect
                value={value}
                onChange={onChange}
                pageType={type}
                pageId={id}
                onClose={() => setChange(false)}
            />
        );
    }

    return (
        <SelectList>
            <SelectListItem>
                {type} {id}
            </SelectListItem>
            <SelectListItem onClick={() => setChange(true)}>
                Wybierz szablon
            </SelectListItem>
            {value && defaultLayout && (
                <SelectListItem onClick={() => onChange(null)}>
                    Ustaw domyślny ({defaultLayoutName})
                </SelectListItem>
            )}
            <SelectListItem
                onClick={() => onEditLayout(value || defaultLayout)}
            >
                Pokaż sekcje szablonu
            </SelectListItem>
            {/*<SelectListItem>Duplicate template</SelectListItem>*/}
            {/*<SelectListItem>Extend template</SelectListItem>*/}
            {/*<SelectListItem>Ustawienia</SelectListItem>*/}
        </SelectList>
    );
}

LayoutDropdownMenu.propTypes = {};

LayoutDropdownMenu.defaultProps = {};

export default LayoutDropdownMenu;
