import React from "react";
import { connect } from "react-redux";
import { selectEntity } from "state/selectors/data";
import { thumbnail } from "util/thumbnail";

import AvatarGroup from "joynt/components/AvatarGroup";

const nullArr = [];

const mapStateToProps = (store, props) => {
    const data = selectEntity(store, "db.identities", props.id);
    const media = data.media || [];
    let image = selectEntity(store, "db.media", media[0]);
    const size = props.size === "md" ? 48 : 24;
    image = image?.file || null;
    return {
        name: data.name,
        avatar: image ? thumbnail(image, [size, size]) : null,
        identities: props.identities || nullArr,
    };
};

const mapDispatchToProps = {};

class PostIdentity extends React.PureComponent {
    render() {
        const { name, id, avatar, identities, secondaryText, maxSize, size } =
            this.props;
        return (
            <div className={"node-identity cols cols-middle gap-xs"}>
                {avatar && (
                    <div className={"identity-avatar"}>
                        <img src={avatar} alt={name} className={"img-cover"} />
                    </div>
                )}
                {id && (
                    <div>
                        <div>{name || id}</div>
                        {secondaryText && (
                            <div className={"node-identity-secondary"}>
                                {secondaryText}
                            </div>
                        )}
                    </div>
                )}
                <AvatarGroup
                    size={size}
                    showNames={false}
                    ids={identities}
                    maxSize={maxSize}
                />
            </div>
        );
    }
}

PostIdentity.defaultProps = {
    size: "xs",
};

export default connect(mapStateToProps, mapDispatchToProps)(PostIdentity);
