import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "components/IconButton";
import List from "containers/List";

import {ResourceIconIterator} from "components/user-menu/ResourceIcon";

export default class SideNav extends React.PureComponent {

    render() {
        const {
            onClick,
            onShowUserMenu,
            onPushRouteParams,
            showUserMenu,
            items,
            menuItems
        } = this.props;
        return (<>
            <IconButton
                onClick={onShowUserMenu}
                icon={showUserMenu ? 'close' : 'menu'}
                color={'inherit'}
                defaultIconType={'mui'}
            />
            <div>&nbsp;</div>
            { menuItems ? menuItems : null }
            <List
                onClick={onClick}
                type={'app.user-menu'}
                items={items}
                Iterator={ResourceIconIterator}
            />
            <div>&nbsp;</div>
            <IconButton
                onClick={()=>onPushRouteParams({view: 'media'})}
                icon={'mui-photo'}
                color={'inherit'}
                label={'Media library'} />
        </>);
    }

}

SideNav.defaultProps = {
    items: []
};

SideNav.propTypes = {
    onClick: PropTypes.func,
    onShowUserMenu: PropTypes.func,
    items: PropTypes.array.isRequired
};