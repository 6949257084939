import React from "react";
//import PropTypes from 'prop-types';

import { useNode } from "joynt/hooks";
import { useRouteParam } from "state/hooks";
import AuthForm from "components/auth/AuthForm";
import Fetch from "containers/Fetch";

import "./style.css";
import "./mobile.css";

const logo =
    "https://joynt.works/thumb?file=joynt-website%2Fuser%2Fbrand%2FJoynt_logo_solo.svg&w=320";

function SpaceInfo() {
    const [id] = useRouteParam("id");
    const { name } = useNode(id);
    if (!id) return null;
    return (
        <Fetch
            type={"db.nodes"}
            id={id}
            url={`joynt/nodes/${id}/public`}
            useContext={false}
        >
            <div className={"rows"}>
                <div className={"pad-md gap-xs"}>
                    <div className={"type-heading"}>{name}</div>
                </div>
            </div>
        </Fetch>
    );
}

function JoyntLogo() {
    return <img className={"joynt-logo"} alt={"joynt.works"} src={logo} />;
}

function PoweredByJoynt() {
    return (
        <div className={"rows rows-center gap-sm"}>
            <div className={"type-sm"}>Powered by</div>
            <img
                className={"joynt-powered-by"}
                alt={"joynt.works"}
                src={logo}
            />
        </div>
    );
}

const urls = {
    tos: "https://joynt.works/terms-of-service",
    privacy: "https://joynt.works/privacy-policy",
};

export default function Login(props) {
    return (
        <div className={"console-login"}>
            <div className={"login-splash primary"} />
            <div className={"relative login-content"}>
                <AuthForm {...props} urls={urls} header={<JoyntLogo />} />
            </div>
        </div>
    );
}

Login.propTypes = {};
