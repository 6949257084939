import Chat from "./Chat";
import Channel from "./Channel";
import Event from "./Event";
import Session from "./Session";
import Workspace from "./Workspace";
import Home from "./Home";

export default {
    chat: Chat,
    channel: Channel,
    event: Event,
    session: Session,
    workspace: Workspace,
    home: Home
}