import React from "react";
import PropTypes from "prop-types";

import SessionPreview from "./SessionPreview";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useRouter } from "joynt/hooks/channels";
import { useClick, useCover } from "state/hooks";
import { useFetchRoles } from "joynt/components/Roles/hooks";

export function SessionPreviewContainer(props) {
    const { id } = props;

    const {
        name,
        color,
        event_state,
        event_start,
        event_end,
        media,
    } = useNodeData(id);

    const { parent } = useEdgesData(id);
    const image = useCover(media, [640, 480]);

    useFetchRoles(id, `node-roles.${id}`);

    const { onLink } = useRouter();
    const link = useClick({ id, onClick: onLink });

    return (
        <SessionPreview
            {...props}
            {...{ name, color, parent }}
            onLink={link}
            start={event_start}
            end={event_end}
            isLive={event_state === "live"}
            image={image}
        />
    );
}

SessionPreviewContainer.propTypes = {
    id: PropTypes.string,
};
SessionPreviewContainer.defaultProps = {};

export default SessionPreviewContainer;
