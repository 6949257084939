import React from 'react';
//import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";

export function MoreLink(props) {

    const {
        label,
        onClick,
        isActive
    } = props;

    return (<div
        className={cn("more-link", {
            "is-active": isActive
        })}
        onClick={onClick}
    >
        <div className={"more-link__link"}>
            {label}
        </div>
    </div>);

}

MoreLink.propTypes = {};
MoreLink.defaultProps = {};

export default MoreLink;
