import React from "react";

//import PropTypes from 'prop-types';

export function PropertyGrid(props) {
    const { children } = props;
    return <div className={"properties-grid"}>{children}</div>;
}

PropertyGrid.propTypes = {};

PropertyGrid.defaultProps = {};

export default PropertyGrid;
