import { useCallback, useMemo, useReducer } from "react";
import { enumerateDevices } from "../util";

function resetDevicesIfNotAvailable(available, selected) {
    const types = ["videoInput", "audioInput"];
    let next = selected;
    types.forEach((type) => {
        let typeAvailable = (available[type] || []).map(
            (device) => device.deviceId
        );
        if (typeAvailable.indexOf(selected[type]) === -1) {
            next = { ...next, [type]: typeAvailable[0] || null };
        }
    });
    return next;
}

function init() {
    return {
        availableDevices: {},
        selectedDevices: JSON.parse(localStorage.getItem("mediaDevices")) || {},
        pending: false,
    };
}

function reducer(state, { type, payload }) {
    let next = state;
    switch (type) {
        case "PENDING":
            return {
                ...state,
                pending: payload,
            };
        case "UPDATE":
            next = {
                ...state,
                availableDevices: payload,
                selectedDevices: resetDevicesIfNotAvailable(
                    payload,
                    state.selectedDevices
                ),
                pending: false,
            };
            localStorage.setItem(
                "mediaDevices",
                JSON.stringify(next.selectedDevices)
            );
            return next;
        case "SELECT":
            next = {
                ...state,
                selectedDevices: {
                    ...state.selectedDevices,
                    [payload.type]: payload.id,
                },
            };
            console.log("SELECT", payload, next);
            localStorage.setItem(
                "mediaDevices",
                JSON.stringify(next.selectedDevices)
            );
            return next;
        default:
            return state;
    }
}

export default function useMediaDevicesAccess() {
    const [state, dispatch] = useReducer(reducer, {}, init);
    const { selectedDevices, availableDevices, pending } = state;

    const onSelect = useCallback(
        (kind, id) => {
            dispatch({ type: "SELECT", payload: { type: kind, id } });
        },
        [dispatch]
    );

    const onRequestDevices = useCallback(() => {
        const request = async () => {
            dispatch({ type: "PENDING", payload: true });
            const devices = await enumerateDevices();
            dispatch({ type: "UPDATE", payload: devices });
        };
        request().catch((error) => {
            dispatch({ type: "UPDATE", payload: {} });
            console.log(error);
        });
    }, [dispatch]);

    return useMemo(
        () => ({
            availableDevices,
            selectedDevices,
            onSelectDevice: onSelect,
            onRequestDevices,
            pending,
            hasCamera: !!availableDevices.videoInput,
            hasMicrophone: !!availableDevices.audioInput,
        }),
        [pending, availableDevices, selectedDevices, onSelect, onRequestDevices]
    );
}
