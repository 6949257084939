import React from 'react';
//import PropTypes from 'prop-types';
import ContentLoader from "react-content-loader";

export default function Skeleton({width, height, children, className}) {
    return <ContentLoader
        className={className}
        width={width}
        height={height}
        primaryColor={'#2e146d'}
        secondaryColor={'#2e146d'}
        primaryOpacity={0.05}
        secondaryOpacity={0}
    >
        {children}
    </ContentLoader>
}

Skeleton.propTypes = {};