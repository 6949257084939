import { eventChannel, END } from "redux-saga";
import {call, put, take} from "redux-saga/effects";

export function createUploader(upload) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = upload(uploadProgressCb);
  return [uploadPromise, chan];
}

export function* uploadProgressWatcher(chan, id, progressAction, progressCallback) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    if (progressAction) {
      yield put(progressAction(id, progress));
    }
    if (progressCallback) {
      yield call(progressCallback, id, progress);
    }
  }
}
