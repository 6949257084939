import { normalize, denormalize, schema } from "normalizr";
import { getIn, setIn } from "lodash-redux-immutability";
import { deepMerge } from "./deepMerge";
import intl from "./intl";

let jsonSchema, schemaMap, denormalizeSchema, selectLang;

function shallowMergeSchema(schema) {
    return Object.keys(schema).reduce((acc, key) => {
        if (schema[key].override) {
            acc[key] = schema[key].override;
        }
        return acc;
    }, {});
}

export function mergeEntity(type, data, changes) {
    let result = deepMerge({}, data, changes);
    const shallowMergeConfig = shallowMergeSchema(jsonSchema);

    if (!Object.keys(shallowMergeConfig).includes(type)) {
        return result;
    }

    const shallowMergeKeys = shallowMergeConfig[type];
    const changesKeys = Object.keys(changes);
    shallowMergeKeys.forEach((key) => {
        if (changesKeys.includes(key)) {
            return (result = {
                ...result,
                [key]: changes[key],
            });
        }
    });

    return result;
}

function mergeState(data, changes) {
    let result = deepMerge({}, data, changes);
    const shallowTypes = Object.keys(shallowMergeSchema(jsonSchema));
    Object.keys(changes).forEach((type) => {
        if (shallowTypes.includes(type)) {
            Object.keys(changes?.[type] || {}).forEach((id) => {
                result[type][id] = mergeEntity(
                    type,
                    data?.[type]?.[id] || {},
                    changes?.[type]?.[id] || {}
                );
            });
        }
    });
    return result;
}

export function setupSchema(schema, langProvider) {
    jsonSchema = schema;
    schemaMap = fromJson(schema, true);
    denormalizeSchema = fromJson(schema, false);
    if (langProvider) selectLang = langProvider;
}

function indexBy(items, key = "id") {
    const indexed = {};
    items.forEach((item) => {
        indexed[item[key]] = item;
    });
    return indexed;
}

function update(state, type, items, yieldData) {
    let ids = Object.keys(items);
    ids.forEach((id) => {
        if (!yieldData) {
            state = setIn(state, [type, id], items[id]);
        } else {
            let exists = getIn(state, [type, id]);
            if (!exists || (exists.lang && exists.lang !== items[id]?.lang)) {
                state = setIn(state, [type, id], items[id]);
            }
        }
    });
    return state;
}

function stripFields(items, fields) {
    const keys = Object.keys(items);
    keys.forEach((key) => {
        let item = items[key];
        fields.forEach((field) => {
            delete item[field];
        });
        items[key] = item;
    });
    return items;
}

export const normalizeEntities = (state, type, items, yieldData) => {
    const lang = selectLang();
    if (type === "cms.styles") {
        return setIn(state, [type], items);
    }
    const typeSchema = schemaMap[type] || null;
    if (typeSchema) {
        let result = normalize(items, [typeSchema]);
        let types = Object.keys(result.entities);
        let entities = result.entities;
        types.forEach((type) => {
            let intldEntities = intl(type, entities[type], lang);
            intldEntities = stripFields(intldEntities, ["item_type"]);
            state = update(state, type, intldEntities, yieldData);
        });
        return state;
    }
    state = update(state, type, indexBy(items), yieldData);
    return state;
};

export const denormalizeEntity = (state, type, id) => {
    const data = mergeState(state.data, state.changes); //({}, state.data, state.changes);

    Object.keys(data).forEach((type) => {
        Object.keys(data[type]).forEach((id) => {
            let entity = data[type][id];
            if (entity && entity.__parent) {
                delete entity.__parent;
                data[type][id] = entity;
            }
        });
    });
    const input = {};
    const schema = {};
    if (!denormalizeSchema[type]) return data[type][id];
    schema[type] = [denormalizeSchema[type]];
    input[type] = [id];
    const result = denormalize(input, schema, data);
    const entity = result[type][0];
    if (jsonSchema[type] && jsonSchema[type].override) {
        let changes = state?.changes?.[type]?.[id] || {};
        jsonSchema[type].override.forEach((ovrField) => {
            if (changes[ovrField]) entity[ovrField] = changes[ovrField];
        });
        //console.log(entity);
    }
    return entity;
};

const processWithParent = {
    processStrategy: (value, parent, key) => {
        //console.log(value, parent, key);
        if (value.__parent) return value;
        let p = parent.parent ? parent.parent.id : parent.id;
        return {
            ...value,
            __parent: p ? { id: p, key } : null,
        };
    },
};

const fromJson = (source, includeRefs) => {
    const map = {};
    const types = Object.keys(source);

    const resolveType = (key, id) => {
        return id === "@self" ? map[key] : map[id];
    };

    types.forEach((type) => {
        let options = source[type].options || {};
        let process =
            options.processStrategy === "@parent" ? processWithParent : null;
        if (process) {
            map[type] = new schema.Entity(type, {}, process);
        } else {
            map[type] = new schema.Entity(type, {});
        }
    });

    types.forEach((type) => {
        let definition = {};
        let fields = source[type].fields || {};
        let refs = source[type].refs || {};
        if (includeRefs && refs) fields = { ...fields, ...refs };
        let keys = Object.keys(fields);
        keys.forEach((key) => {
            let fieldDefinition = null;
            if (Array.isArray(fields[key])) {
                fieldDefinition = fields[key].map((id) =>
                    resolveType(type, id)
                );
                definition[key] = fieldDefinition;
            } else {
                definition[key] = resolveType(type, fields[key]);
            }
        });
        map[type].define(definition);
    });

    return map;
};
