import React from "react";
import Icon from "components/Icon";
import PropTypes from "prop-types";

export default function InternalLink(props) {

    const {
        onClick,
        createdAt,
        name,
        isPending,
        typeIcon,
        typeName
    } = props;

    const nodeExists = !!createdAt;

    return <>
        { nodeExists ? <div  onClick={onClick} className={'card-border card-border--link'}>
            <div className={'rows'}>
                { typeName && <div className={'cols gap-px cols-middle o-50 type-sm'}>
                    <Icon>{typeIcon}</Icon>
                    <div>{typeName}</div>
                </div> }
                <div className={'cols cols-top grow'}>
                    <div className={'grow'}>{name}</div>
                </div>
            </div>
        </div> : null }
        { !nodeExists && !isPending && <div className={'o-50'}>
            Link no longer available
        </div> }
    </>;
}

InternalLink.defaultProps = {
    isPending: false
};

InternalLink.propTypes = {
    name: PropTypes.string,
    createdAt: PropTypes.number,
    isPending: PropTypes.bool,
    onClick: PropTypes.func,
    typeId: PropTypes.string,
    typeName: PropTypes.string,
    typeIcon: PropTypes.string
};