import React from "react";
import PropTypes from "prop-types";
import List from "containers/List";
import cn from "classnames/dedupe";
import { ArrayIterator } from "components/list/iterator";
import { Card, Component, Media } from "cms/components";
import { withData } from "containers/Entity";
import Preloader from "components/Preloader";
import { MergeRewireContext } from "containers/context/RewireContext";
import Slider from "cms/components/Slider";

function resolveItemTemplate(repeat, index, components) {
    if (!components) return null;
    let templates = components.length;
    if (repeat === "loop") {
        if (index + 1 > templates) {
            return components[index % templates];
        }
        return components[index];
    }
    if (repeat === "start") {
        if (index + 1 > templates) {
            return components[templates - 1];
        }
        return components[index];
    }
    return components[0];
}

function ListItemContext(props) {
    const { type, id, ...other } = props;

    const path = [type, id].join("/");

    return (
        <MergeRewireContext value={path}>
            <ListArea type={type} id={id} itemId={id} {...other} />
        </MergeRewireContext>
    );
}

ListItemContext.autoFillProps = [];

function ListArea(props) {
    const { embed, className, ...other } = props;
    if (embed) return <ListItem {...other} className={className} />;
    return (
        <div className={className}>
            <ListItem {...other} />
        </div>
    );
}

function ListItem(props) {
    const {
        itemType,
        className,
        components,
        classes,
        embed,
        index,
        itemRepeat,
        ...other
    } = props;

    const renderType = itemType || "card";
    const hasChildren = components && components.length;

    if (["card", "gallery"].indexOf(renderType) > -1)
        return <Card className={className} element_type={"card"} {...other} />;

    if (hasChildren && renderType === "template-content")
        return (
            <Card className={className} {...other}>
                <Component
                    type={"cms.components"}
                    id={resolveItemTemplate(itemRepeat, index, components)}
                />
            </Card>
        );

    if (["media-element", "media"].indexOf(renderType) > -1)
        return (
            <Media
                className={className}
                {...other}
                rewire={"icon,title,headline,text"}
            />
        );

    if (hasChildren && renderType === "template")
        return (
            <Component
                {...other}
                // data-item={props.id}
                type={"cms.components"}
                id={resolveItemTemplate(itemRepeat, index, components)}
                className={className}
            />
        );

    return <div className={props.className}>{itemType}</div>;
}

ListItem.defaultProps = {
    itemType: "card",
};

export const ListIterator = ArrayIterator(withData(ListItemContext));

function SliderItem(props) {
    return (
        <div className={"owl-item active"}>
            <ListItemContext {...props} />
        </div>
    );
}

const SliderItemIterator = ArrayIterator(withData(SliderItem));

export default class ListComponent extends React.PureComponent {
    render() {
        const {
            classes,
            attributes,
            inspectId,
            element_type,
            item_type,
            items,
            components,
            entry_type,
            pending,
            rewireMap,
        } = this.props;

        const ownItems = !rewireMap.items && !entry_type;
        const ownPath = `cms.components/${this.props.id}`;
        const path = ownItems ? `${ownPath}/items` : rewireMap.items;

        const wrapperProps = {
            "data-inspect": inspectId,
            "data-type": element_type,
            "data-s": "list",
            "data-path": path,
            "data-items": items?.length || 0,
            "data-query": entry_type ? ownPath : null,
            "data-item-type": item_type,
            className: cn(classes.list),
            ...attributes,
        };

        const listType = classes.list_type;
        const itemType = classes.item_type || "card";
        const embed = !!classes.embed || !["template"].includes(itemType);

        if (listType === "slider") {
            const sliderItemsCount = classes.slider_items * 1 || 1;
            let renderItems =
                items && items.length ? items.slice(0, sliderItemsCount) : [];
            return items && items.length ? (
                <Slider {...wrapperProps} classes={classes} items={renderItems}>
                    <List
                        data-s={"item"}
                        type={item_type}
                        items={renderItems}
                        components={components}
                        className={cn("l-area", classes.item)}
                        itemType={itemType}
                        itemRepeat={classes.item_repeat}
                        embed={embed}
                        classes={classes}
                        Iterator={SliderItemIterator}
                    />
                </Slider>
            ) : (
                <div className={cn("l-area", classes.item)}>
                    <h4>
                        <div>slider no items</div>
                        <div>{this.props.item_type}</div>
                        <div>{this.props.list}</div>
                        <div>{this.props.url}</div>
                    </h4>
                </div>
            );
        }

        if (listType === "select") {
            return (
                <select className={classes.list} {...wrapperProps}>
                    <List
                        data-s={"item"}
                        type={item_type}
                        items={items}
                        components={components}
                        className={cn("l-area", classes.item)}
                        itemType={classes.item_type}
                        itemRepeat={classes.item_repeat}
                        embed={true}
                        classes={classes}
                        Iterator={ListIterator}
                    />
                </select>
            );
        }

        if (listType === "loop") {
            return <div>TODO: listType === loop</div>;
        }

        return (
            <>
                <Div {...wrapperProps}>
                    <List
                        data-s={"item"}
                        type={item_type}
                        items={items}
                        components={components}
                        className={cn("l-area", classes.item)}
                        itemType={classes.item_type || "card"}
                        itemRepeat={classes.item_repeat}
                        embed={embed}
                        classes={classes}
                        Iterator={ListIterator}
                    />
                </Div>
                {/*{pending && <Preloader visible={pending} />}*/}
            </>
        );
    }
}

function Div(props) {
    return <div {...props} />;
}

ListComponent.autoFillProps = [
    "template_id",
    "entry_type",
    "data_source",
    "style",
    "styles",
    "items",
    "collection",
    "components",
];

ListComponent.propTypes = {
    inspectId: PropTypes.string,
};
