import React from "react";
import PropTypes from "prop-types";
import SpaceHeader from "./SpaceHeader";
import { useFeatureEnabled, useNodeData, useNodeParent } from "joynt/hooks";
import { useChannels, useNodeChannels } from "joynt/hooks/channels";
import Tabs from "./Tabs";
import { useClick } from "state/hooks";
import ParentLink from "joynt/components/SpaceHeader/ParentLink";
import FollowButton from "joynt/components/FollowButton";
import { FEATURE_FOLLOW, FEATURE_OPTIMIZED_API } from "joynt/config/features";
import SelectSubchannel from "./SelectSubchannel";
import useScreenSize from "util/hooks/useScreenSize";

export function SpaceHeaderContainer(props) {
    const { id, channel, tabs } = props;
    const { name, can_edit } = useNodeData(id);
    const { onEdit } = useChannels(id);
    const { root, parent, onLinkToParent } = useNodeParent(id);

    const edit = useClick({ id, onClick: onEdit });
    const enableFollow = useFeatureEnabled(FEATURE_FOLLOW);

    const { view, views, onChangeView } = useNodeChannels(id, channel);
    const { isMobile } = useScreenSize();
    const subchannelsList = `db.nodes.${id}.subchannels`;

    const devApi = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    let url = devApi
        ? `v2/joynt/nodes/${id}/channels`
        : `joynt/nodes/${id}/channels`;

    const listType = "db.nodes";

    return (
        <SpaceHeader
            {...props}
            name={props.name || name}
            canEdit={can_edit}
            onEdit={edit}
            userOptions={enableFollow ? <FollowButton id={id} /> : null}
            parentLink={
                root && (
                    <ParentLink
                        id={root}
                        channel={parent}
                        onClick={onLinkToParent}
                    />
                )
            }
        >
            {tabs &&
                (isMobile ? (
                    <SelectSubchannel
                        label={channel}
                        url={url}
                        type={listType}
                        list={subchannelsList}
                        value={view}
                        onChange={onChangeView}
                        options={views}
                    />
                ) : (
                    <Tabs
                        type={listType}
                        id={id}
                        channel={channel}
                        list={subchannelsList}
                        url={url}
                    />
                ))}
        </SpaceHeader>
    );
}

SpaceHeaderContainer.propTypes = {
    id: PropTypes.string,
};
SpaceHeaderContainer.defaultProps = {};

export default SpaceHeaderContainer;
