import React, { useState } from "react";
import cn from "classnames";

import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-full-node-drag";
import Menu from "@material-ui/core/Menu";
import FileContextMenu from "./FileContextMenu";
import { isThemeRoot, relativePath } from "playground/theme/FileManager/tree";
//import PropTypes from 'prop-types';

function nodeTitle({ node, onClick, onContextMenu, focused }) {
    const click = !node.isDirectory ? () => onClick(node.id) : null;
    const dblClick = !node.isDirectory ? () => onClick(node.id, true) : null;
    const ctxClick = (evt) => onContextMenu(evt, node.id);
    return (
        <div
            className={cn("rstcustom__rowTitleContent", {
                "is-focused": focused && !node.isDirectory,
            })}
            onClick={click}
            onDoubleClick={dblClick}
            onContextMenu={ctxClick}
        >
            {node.changed && "* "} {node.name}
        </div>
    );
}

function Placeholder() {
    return <div>placeholder</div>;
}

const contextMenuOrigin = {
    horizontal: "left",
    vertical: "bottom",
};

export function FilesTree(props) {
    const {
        tree,
        selected,
        onSelect,
        onCreate,
        onRename,
        onDelete,
        protectedPaths,
    } = props;
    const [contextMenuState, setContextMenuState] = useState({
        contextMenu: false,
    });
    const { contextMenu, contextMenuAnchor } = contextMenuState;

    const showContext = (evt, id) => {
        evt.preventDefault();
        setContextMenuState({ contextMenu: id, contextMenuAnchor: evt.target });
    };

    const hideContext = (id) => {
        setContextMenuState({ contextMenu: false });
    };

    const canHaveChildren = (node) => {
        return node.isDirectory;
    };

    const isNodeProtected = (node) => {
        if (node.isRoot) return true;
        const path = relativePath(node.id);
        if (!path) return true;
        return protectedPaths.includes(path);
    };

    const onClick = onSelect;
    const nodeProps = ({ node }) => ({
        title: nodeTitle({
            node,
            onClick,
            onContextMenu: showContext,
            focused: selected === node.id,
        }),
        lowerSiblingCounts: [],
    });
    const canDrag = ({ node }) => {
        return !isNodeProtected(node);
    };
    const canDrop = ({ node }) => {
        return true; //node.isDirectory;
    };
    const moveNode = ({ node, nextParentNode }) => {
        const id = node.id;
        const parent = nextParentNode?.id;
        const target = [nextParentNode?.id, node?.name]
            .filter((p) => !!p)
            .join("/");
        if (id === target) return;
        console.log(`rename ${id} to ${target}`);
        onRename(id, target);
    };

    return (
        <>
            <SortableTree
                scaffoldBlockPxWidth={14}
                theme={FileExplorerTheme}
                onlyExpandSearchedNodes={false}
                canNodeHaveChildren={canHaveChildren}
                generateNodeProps={nodeProps}
                onMoveNode={moveNode}
                rowHeight={24}
                onChange={() => null}
                canDrag={canDrag}
                canDrop={canDrop}
                treeData={tree}
                placeholderRenderer={Placeholder}
            />
            <Menu
                open={contextMenu !== false}
                anchorEl={contextMenuAnchor}
                anchorOrigin={contextMenuOrigin}
                onClose={hideContext}
                className={"style-ui-popover"}
            >
                <FileContextMenu
                    id={contextMenu}
                    isProtected={isNodeProtected({ id: contextMenu })}
                    onClose={hideContext}
                    onCreate={onCreate}
                    onRename={onRename}
                    onDelete={onDelete}
                />
            </Menu>
        </>
    );
}

FilesTree.propTypes = {};

FilesTree.defaultProps = {
    protectedPaths: [],
    tree: [],
};

export default FilesTree;
