import {reload} from "state/actions/list";
import {useCallback} from "react";
import {useApiActions} from "state/hooks/useApiActions";

export function useListReload(type, list) {
    const {
        onReload
    } = useApiActions({
        onReload: reload
    });

    const rel = useCallback(() => {
        onReload(type, list)
    }, [onReload, type, list]);

    return [rel];
}