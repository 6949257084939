import React from 'react';
import PropTypes from 'prop-types';
import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "components/Popover";

export default function SelectField(props) {
    const {onChange, value, options, label} = props;

    let currentValue = value ? options[value] || {} : {};
    if (!currentValue.id && options['default']) currentValue = options['default'];
    const valueLabel = currentValue.label || label;

    const trigger = ({onClick}) => <div className={'select-box'} onClick={onClick}>
        <div className={'select-label'}>
            {valueLabel}
        </div>
    </div>;

    const content = ({onClose}) => <MuiList onClick={onClose}>
        {Object.keys(options).map(opt => {
            return <ListItem
                key={`option-${opt}`}
                onClick={()=>onChange(options[opt].id)}
                button
            >
                <ListItemText
                    primary={options[opt].label}
                    secondary={options[opt].description} />
            </ListItem>
        })}
    </MuiList>;

    return <Popover trigger={trigger} content={content} />;
}

SelectField.propTypes = {
    label: PropTypes.string
};
SelectField.defaultProps = {
    label: "choose"
};
