import React from "react";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import Session from "joynt/meeting/agora-ng/Session";
import useMeeting from "joynt/meeting/hooks/useMeeting";
//import PropTypes from 'prop-types';

export default function Agora(props) {
    const { access } = useMeeting();
    const { identity: user } = useMeetingSession();

    return <Session access={access} user={user} />;
}

Agora.propTypes = {};
Agora.defaultProps = {};
