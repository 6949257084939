import React, { useCallback, useRef } from "react";
import cn from "classnames";
import Portal from "@material-ui/core/Portal";
import "./style.css";
import { useSessionLayout } from "joynt/components/SessionLayout";
import IconButton from "components/IconButton";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {
    AudioButton,
    EndCallButton,
    VideoButton,
} from "joynt/components/SessionToolbar/Buttons";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import { useUiFlag } from "state/hooks";
import {FLAG_SESSION_NAV, SESSION_POLICY_MEETING} from "joynt/config";
import { useSessionUtils } from "joynt/components/UtilityNav/Session/hooks";
import ViewContainer from "joynt/components/UtilityNav/Session/View.container";
import { availableViews } from "joynt/components/UtilityNav/Session/config";
import PhoneHeader from "joynt/components/AppLayout/PhoneAppLayout/PhoneHeader/PhoneHeader";
import { useEdgesData, useNodeData } from "joynt/hooks";
import Timer from "joynt/components/Timer";
import { useSelector } from "react-redux";
import { selectPresentIdentities } from "joynt/state/selectors/joint";
import useSessionControls from "joynt/meeting/hooks/useSessionControls";
import { useNotificationCount } from "joynt/hooks/notifications";
import Badge from "@material-ui/core/Badge";
import { useSessionFlow } from "joynt/components/SessionFlow/hooks";
import { Message, useChatTicker } from "joynt/components/ChatTicker";
import Popper from "@material-ui/core/Popper";
import { Grow } from "@material-ui/core";
import SlideTransition from "joynt/components/LiveNow/SlideTransition";
import {useMediaDevices} from "joynt/meeting/devices";
import { PillButton } from "../../PillButton/PillButton";
import {useSessionState} from "joynt/meeting/hooks/useSessionState";
import AudioVideo from "joynt/components/SessionToolbar/AudioVideo";
import RaiseHand from "joynt/components/SessionToolbar/Buttons/RaiseHand";
//import PropTypes from 'prop-types';

function TopControls(props) {
    const { event, onLeaveSession } = useMeeting();
    const { onToggle } = useSessionLayout();
    const { name, event_started: start } = useNodeData(event);

    return (
        <div className={"phone-session-controls-top"}>
            <IconButton
                onClick={onToggle}
                icon={"mui-expand_more"}
                size={"large"}
            />
            <div className={"phone-session-controls-top__text"}>
                <div className={"phone-session-controls-top__name"}>{name}</div>
                <div className={"phone-session-controls-top__timer"}>
                    <Timer
                        start={start}
                        humanTimeThreshold={7200}
                        countdown={false}
                    />
                </div>
            </div>
            <EndCallButton onClick={onLeaveSession} size={"large"} />
        </div>
    );
}

function ChatPopper(props) {
    const { id, message, open, anchorEl, onClick } = props;

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"top-end"}
            transition
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <div className={"chat-popper"} onClick={onClick}>
                        {message && (
                            <SlideTransition
                                id={id}
                                current={message}
                                Component={Message}
                            />
                        )}
                    </div>
                </Grow>
            )}
        </Popper>
    );
}

function PeopleButton(props) {
    const { id, onClick } = props;
    const presence = useSelector((s) =>
        selectPresentIdentities(s, id, "sessions")
    );
    return (
        <Badge
            badgeContent={presence.length}
            color={"primary"}
            overlap={"circular"}
        >
            <IconButton onClick={onClick} size={"large"} icon={"mui-people"} />
        </Badge>
    );
}

function ChatButton(props) {
    const { id, onClick, showTicker } = props;

    const [notifications, onSeen] = useNotificationCount(id);
    const { message, show } = useChatTicker(id);
    const ref = useRef();

    const click = useCallback(() => {
        onSeen();
        onClick();
    }, [onClick, onSeen]);

    return (
        <div ref={ref}>
            <Badge
                badgeContent={notifications || 0}
                color={"secondary"}
                variant={"dot"}
                overlap={"circular"}
            >
                <IconButton
                    size={"large"}
                    onClick={click}
                    tooltip={"Chat"}
                    icon={"mui-chat"}
                />
            </Badge>
            <ChatPopper
                onClick={click}
                anchorEl={ref.current}
                open={show && showTicker}
                message={message}
                id={id}
            />
        </div>
    );
}

function BottomControls(props) {
    const { session, event, policy, onShowSettings } = useMeeting();
    const isMeeting = policy === SESSION_POLICY_MEETING;

    const {isLive} = useSessionState(session);
    // const presence = useSelector((s) =>
    //     selectPresentIdentities(s, session, "sessions")
    // );
    const [connectionState] = useConnectionState(session);
    const { session_chat } = useEdgesData(event);

    const { videoStatus, audioStatus } = connectionState;
    const { cameraAvailable, microphoneAvailable } = useMediaDevices();

    const { onToggleAudio, onToggleVideo } = useSessionControls(session);
    const [nav, setView] = useUiFlag(FLAG_SESSION_NAV);

    return (
        <div className={"phone-session-controls-bottom"}>
            <div className={"cols gap-sm"}>
                <RaiseHand isMobile={true} />
                <AudioVideo />
                {/*<VideoButton*/}
                {/*    onClick={onToggleVideo}*/}
                {/*    on={videoStatus === "success"}*/}
                {/*    isPending={videoStatus === "pending"}*/}
                {/*    disabled={!cameraAvailable}*/}
                {/*    size={"large"}*/}
                {/*/>*/}
                {/*<AudioButton*/}
                {/*    onClick={onToggleAudio}*/}
                {/*    on={audioStatus === "success"}*/}
                {/*    isPending={audioStatus === "pending"}*/}
                {/*    disabled={!microphoneAvailable}*/}
                {/*    size={"large"}*/}
                {/*/>*/}
                <IconButton
                    icon={"mui-settings"}
                    onClick={onShowSettings}
                />
            </div>
            <div className={"cols gap-sm"}>
                {/*<div onClick={() => setView("people")}>*/}
                {/*    <AvatarGroup ids={presence} maxSize={4} size={"small"} />*/}
                {/*</div>*/}
                <PeopleButton id={session} onClick={() => setView("people")} />
                <ChatButton
                    id={session_chat}
                    onClick={() => setView("chat")}
                    showTicker={nav !== "chat"}
                />
            </div>
        </div>
    );
}

export function PhoneSessionLayout(props) {
    const { children } = props;

    const { expanded } = useSessionLayout();
    const { session, event } = useMeeting();
    const [{ joined }] = useConnectionState(session);
    const [nav, setNav] = useUiFlag(FLAG_SESSION_NAV);
    const views = useSessionUtils(event);
    const current = views.find((v) => v.type === nav) || {};
    const viewDefinition = availableViews[current.type] || {};
    const { data } = useSessionFlow();
    const showControls = !data.hideToolbar;
    const sessionView = !nav;
    const utilsView = !!nav;

    return (
        <>
            <Portal>
                <div
                    className={cn("sliding-view", {
                        open: nav,
                    })}
                >
                    <div className={"screens"}>
                        <div className={"screen-view"}>
                            {expanded && sessionView && (
                                <div className={"phone-session-controls"}>
                                    <TopControls />
                                    {joined && showControls && (
                                        <BottomControls />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={"screen-view"}>
                            <div className={"session-nav-screen dark-mode bg"}>
                                {expanded && utilsView && (
                                    <div
                                        className={"session-nav-screen-header"}
                                    >
                                        <PhoneHeader
                                            onGoBack={() => setNav(null)}
                                            title={viewDefinition.label}
                                            parentName={"back to session"}
                                        />
                                        <div className={"session-nav-screen-header-buttons"}>
                                            {views.map((v) => (
                                                <PillButton
                                                    key={v.type}
                                                    onClick={() =>
                                                        setNav(v.type)
                                                    }
                                                    isActive={v.type === nav}
                                                    label={
                                                        availableViews[v.type]
                                                            .label
                                                    }
                                                    icon={
                                                        availableViews[v.type]
                                                            .icon
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {expanded && utilsView && current.type && (
                                    <ViewContainer {...current} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Portal>
        </>
    );
}

PhoneSessionLayout.propTypes = {};
PhoneSessionLayout.defaultProps = {};

export default PhoneSessionLayout;
