import React from 'react';
import {TutorialAccordionList} from "joynt/components/TutorialAccordionList";
//import PropTypes from 'prop-types';

const content = [
    {
        id: 1,
        title: 'Speaker session (a guest speaker presentation, panel discussion, etc.)',
        content: 'If you’re just starting out, invite a speaker who is a leader in their industry to give a presentation. They should be speaking about a topic which is valuable to your customers and prospects.'
    },
    {
        id: 2,
        title: 'Networking sessions',
        content: 'Lorem ipsum'
    },
    {
        id: 3,
        title: 'Conference',
        content: 'Lorem ipsum'
    },
    {
        id: 4,
        title: 'Seminar or half-day event',
        content: 'Lorem ipsum'
    },
    {
        id: 5,
        title: 'Workshops and classes',
        content: 'Lorem ipsum'
    }
]

export default function AboutEvent(props) {

    return <TutorialAccordionList
        header={'About the Event'}
        variant={2}
        data={content}
    />;

}

AboutEvent.propTypes = {};
AboutEvent.defaultProps = {};
