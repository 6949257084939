import React from "react";
//import PropTypes from 'prop-types';

import { ListLayout, ListContainer } from "playground/cms/List";
import { FormLayout, FormContainer, OverlayForm } from "playground/cms/Form";
import GalleryItem from "./GalleryItem.container";
import GalleryForm from "./GalleryForm";
import Button from "components/Button";
import { Tags } from "playground/cms/List/Filter";
import { useGalleryActions } from "./hooks";

function Form(props) {
    return (
        <FormLayout {...props}>
            <FormLayout.Header>
                <h3>Gallery item</h3>
            </FormLayout.Header>
            <FormLayout.Content>
                <div className={"pad-md rows gap-sm"}>
                    <GalleryForm />
                </div>
            </FormLayout.Content>
            <FormLayout.Footer>
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
        </FormLayout>
    );
}

export function Gallery(props) {
    const { type, onClose, onCreate } = props;
    const { id, ...rest } = props;
    const { onImport, onDeleteTag } = useGalleryActions(type);
    return (
        <>
            <ListLayout {...props} className={"grow"} grow={true}>
                <ListLayout.Filter>
                    <Tags type={type} name={"tags"} />
                </ListLayout.Filter>
                <ListLayout.Header>
                    <ListLayout.Header.Icons>
                        <ListLayout.Header.Filter type={type} />
                        <ListLayout.Header.Reload type={type} />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
                <ListLayout.Content>
                    <ListContainer
                        {...props}
                        className={"media-browser-grid"}
                        Item={GalleryItem}
                        sortable={true}
                        selected={id}
                    />
                </ListLayout.Content>
                <ListLayout.Footer>
                    <Button onClick={onCreate}>Create</Button>
                </ListLayout.Footer>
            </ListLayout>
            <OverlayForm open={!!id} onClose={onClose}>
                {id && <FormContainer {...props} FormComponent={Form} />}
            </OverlayForm>
        </>
    );
}

Gallery.propTypes = {};

Gallery.defaultProps = {};

export default Gallery;
