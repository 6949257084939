import React from "react";

import PropTypes from "prop-types";

import StageCover from "joynt/components/SessionFlow/StageCover";
import ProgressBar from "joynt/components/ProgressBar/ProgressBar";
import ProgressClock from "./ProgressClock";
import ActionBox from "joynt/components/ActionBox";

import "./style.css";

export function WitnessTaskProgress(props) {
    const {
        progress,
        minutesLeft,
        text,
        onEndSession,
        isSolo,
        onProgress,
        children,
    } = props;

    const showNext = minutesLeft < 1;

    return (
        <StageCover variant={"landscape"}>
            <div className={"witness-task-progress"}>
                <ProgressClock progress={progress} minutesLeft={minutesLeft} />
                <ProgressBar
                    value={progress}
                    className={"witness-task-progress__bar"}
                />
                {text && (
                    <div className={"witness-task-progress__text"}>{text}</div>
                )}

                {children}

                {showNext && (
                    <ActionBox onClick={onProgress} buttonLabel={"Next"}>
                        Start final session
                    </ActionBox>
                )}

                {!isSolo && !showNext && (
                    <ActionBox
                        onClick={onEndSession}
                        buttonLabel={"End session"}
                    >
                        If you need to quit session now inform your Witness via
                        message.
                    </ActionBox>
                )}
            </div>
        </StageCover>
    );
}

WitnessTaskProgress.propTypes = {
    progress: PropTypes.number,
    minutesLeft: PropTypes.number,
    text: PropTypes.string,
    isSolo: PropTypes.bool,
    onProgress: PropTypes.func,
    onEndSession: PropTypes.func,
};
WitnessTaskProgress.defaultProps = {
    progress: 0,
    minutesLeft: 60,
    isSolo: false,
};

export default WitnessTaskProgress;
