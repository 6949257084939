import uuid from "uuid/v4";

export const handleUpload = context => (resource, path, file, id) => {
    return {
        type: "FILES.UPLOAD",
        payload: {
            resource,
            path,
            data: {
                id: id || uuid(),
                file
            }
        },
        context
    }
};

export const handleUploadCallback = context => (callback, ...args) => {
    const action = handleUpload(context)(...args);
    action.callback = callback;
    return action;
};

export const handleBrowserUpload = context => (type, list, path, files) => {
    return {
        type: 'FILES.UPLOAD.BROWSER',
        payload: {
            type,
            list,
            path,
            files
        },
        context
    }
};

export const uploadProgress = (id, progress) => {
    return {
        type: "FILES.UPLOAD.PROGRESS",
        payload: {
            id,
            progress
        }
    };
};

export const uploadSuccess = (id, data, resource = 'cms.media') => {
    return {
        type: "FILES.UPLOAD.SUCCESS",
        payload: {
            resource,
            id,
            data
        }
    };
};

export const uploadCancel = (id) => ({
    type: "FILES.UPLOAD.CANCEL",
    payload: {id}
});