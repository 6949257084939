import React from "react";
import Feed from "joynt/views/feed/Feed";
import {
    POST_TYPE_ALBUM,
    POST_TYPE_LINK,
    POST_TYPE_WHITEBOARD,
} from "joynt/config";
import { useNodeData } from "joynt/hooks";
import CreatePost from "./../CreatePost";

//import PropTypes from 'prop-types';

const postProps = {
    postSize: "sm",
    showComments: false,
};

const types = [POST_TYPE_ALBUM, POST_TYPE_LINK, POST_TYPE_WHITEBOARD];

export function Files(props) {
    const { id } = props;
    const { can_post } = useNodeData(id);

    return (
        <Feed
            {...props}
            id={id}
            variant={"compact"}
            postProps={postProps}
            postTypes={[]}
            showHeader={false}
            view={"material"}
        >
            {can_post && <CreatePost id={id} postTypes={types} />}
        </Feed>
    );
}

Files.propTypes = {};
Files.defaultProps = {};

export default Files;
