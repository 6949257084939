import React from 'react';

//import PropTypes from 'prop-types';

import "./style.css";

export function PostContent(props) {

    const {children} = props;
    return (<div data-private className={"post-content"}>{children}</div>);

}

PostContent.propTypes = {};
PostContent.defaultProps = {};

export default PostContent;
