import { useCallback } from "react";

import { useEdges } from "joynt/hooks";
import { useActions } from "state/hooks";
import { change } from "state/actions/data";

export function useNodeParent(id) {
    const edges = useEdges(id);
    const { parent } = edges;

    const { onChange } = useActions({ onChange: change });
    const changeParent = useCallback(
        (newParent) => {
            onChange(
                "db.nodes",
                id,
                {
                    parent: newParent,
                    edges_ref: {
                        ...edges,
                        parent: newParent,
                    },
                },
                true
            );
        },
        [onChange, edges, id]
    );

    return [parent, changeParent];
}
