import React from "react";

import { Helmet } from "react-helmet";
import AuthForm from "components/auth/AuthForm";
import ZuuLogo from "zuu/ZuuLogo";

//import PropTypes from 'prop-types';

export function AuthLayout(props) {
    return (
        <>
            <Helmet defer={false}>
                <title>zuu.cms v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <body className={"theme-light default cms-theme"} />
            </Helmet>
            <div className={"console-login"}>
                <div className={"login-splash primary"}></div>
                <div className={"relative login-content"}>
                    <AuthForm
                        {...props}
                        enableSocial={true}
                        header={<ZuuLogo />}
                        urls={{
                            tos: "https://zuu.works",
                            privacy: "https://zuu.works",
                        }}
                    />
                </div>
            </div>
        </>
    );
}

AuthLayout.propTypes = {};

AuthLayout.defaultProps = {};

export default AuthLayout;
