import { takeEvery, put, putResolve, select } from "redux-saga/effects";
import { removeCallback } from "state/actions/api";
import { selectIsDraft } from "state/selectors/data";
import { deleteSuccess } from "state/actions/delete";
import { confirm } from "util/saga/feedback";
import { selectRouteParam } from "state/selectors/router";
import { pushRouteParams } from "state/actions/router";
import { ROUTE_ID, ROUTE_PAGE } from "joynt/config";

function* handleDelete({ payload, context, callback }) {
    const { type, id, url } = payload;
    try {
        const isDraft = yield select((store) => selectIsDraft(store, type, id));
        if (yield confirm("Are you sure?")) {
            if (!isDraft) {
                yield putResolve(
                    removeCallback(context)(callback, type, id, url)
                );
            } else {
                yield put(deleteSuccess(type, id));
                if (callback) callback();
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleDeleteSuccess({ payload }) {
    const { type, id } = payload;
    if (type === "db.meetings-access") return;
    try {
        const currentId = yield select((store) =>
            selectRouteParam(store, ROUTE_ID)
        );
        const currentEditId = yield select((store) =>
            selectRouteParam(store, ROUTE_PAGE)
        );
        if (currentId === id && window.location.pathname !== "/public") {
            yield put(pushRouteParams({ [ROUTE_ID]: null }));
            return;
        }
        if (currentEditId === `edit.${id}`) {
            yield put(pushRouteParams({ [ROUTE_PAGE]: null }));
        }
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeEvery("ENTITY.DELETE", handleDelete);
    yield takeEvery("DELETE.SUCCESS", handleDeleteSuccess);
}
