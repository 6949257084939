import React from 'react';
import {connect} from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import {selectRouteParam} from "state/selectors/router";
import {selectEntity} from "state/selectors/data";
import {isPending} from "state/selectors/ui";
import {pushRouteParams} from "state/actions/router";
import Preloader from "components/Preloader";

const mapStateToProps = (store, props) => {
    let id = selectRouteParam(store, 'share');
    let data = selectEntity(store, 'ui.link-share', id);
    return {
        open: !!id,
        data: data,
        shareUrl: data.url,
        pending: isPending(store, id)
    }
};

const mapDispatchToProps = {
    onClose: pushRouteParams
};

class LinkShare extends React.Component {

    close = () => {
        const { onClose } = this.props;
        onClose({share: null});
    };

    render() {
        const { open, pending, shareUrl } = this.props;
        return (<Dialog open={open} onClose={this.close}>
            <div className={'pad-md'}>
                {shareUrl}
            </div>
            <Preloader visible={pending} />
        </Dialog>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkShare);