import React from "react";
import cn from "classnames";

import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";
import { MessageTime } from "joynt/components/messages";

export function MessageFooterCompact(props) {
    const { notificationStatus, reactions, isOwn, createdAt } = props;
    return (
        <section
            className={cn(
                "message__footer message__footer--compact",
                isOwn && "message__footer--own"
            )}
        >
            {reactions && (
                <div className={"message__reactions"}>{reactions}</div>
            )}
            <div className={"message__footer__info"}>
                {createdAt && (
                    <p className={"message__footer__date"}>
                        <MessageTime time={createdAt} format={"short"} />
                    </p>
                )}
                {isOwn && notificationStatus}
            </div>
        </section>
    );
}

MessageFooterCompact.propTypes = {
    notificationStatus: MessagePropTypes.notificationStatus,
    reactions: MessagePropTypes.reactions,
    isOwn: MessagePropTypes.isOwn,
    createdAt: MessagePropTypes.createdAt,
};

export default MessageFooterCompact;
