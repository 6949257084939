import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import List from "containers/List";
import { Component } from "cms/components/index";
import { ArrayIterator } from "components/list/iterator";

import Link from "cms/containers/Link";

function Area(props) {
    const { className, embed, ...other } = props;

    if (embed) return <Component className={className} {...other} />;

    return (
        <div data-s={"area"} className={className}>
            <Component {...other} />
        </div>
    );
}

const LayoutIterator = ArrayIterator(Area);

function ChildComponent(props) {
    return <Component {...props} />;
}

const ChildIterator = ArrayIterator(ChildComponent);

export function ChildComponents(props) {
    const { itemType, items } = props;
    return <List type={itemType} items={items} Iterator={ChildIterator} />;
}

export default class Layout extends React.Component {
    render() {
        const {
            itemType,
            layoutKey,
            areaKey,
            inspectId,
            childKey,
            className,
            link,
            attributes,
        } = this.props;

        const classes = this.props.classes || {};
        const items = this.props[childKey];

        const layoutClasses = classes ? classes[layoutKey] : null;

        if (classes.embed_layout) {
            return (
                <List
                    type={itemType}
                    items={items}
                    embed={true}
                    className={cn(classes[areaKey])}
                    Iterator={LayoutIterator}
                />
            );
        }

        return (
            <Link
                href={link}
                data-inspect={inspectId}
                className={cn(className, layoutClasses)}
                data-type={"layout"}
                data-s={"layout"}
                {...attributes}
            >
                <List
                    type={itemType}
                    items={items}
                    embed={classes.embed}
                    className={cn(classes[areaKey])}
                    Iterator={LayoutIterator}
                />
            </Link>
        );
    }
}

Layout.propTypes = {
    inspectId: PropTypes.string,
    childKey: PropTypes.string,
};

Layout.autoFillProps = ["link"];

Layout.defaultProps = {
    itemType: "cms.components",
    childKey: "components",
    layoutKey: "layout",
    areaKey: "area",
};
