import { useEffect, useRef } from "react";

export default function useTimeout(callback, timeout, key = "timeout") {
    const ref = useRef();
    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            ref.current();
        }
        if (timeout !== null) {
            let id = setTimeout(tick, timeout);
            return () => clearTimeout(id);
        }
    }, [timeout, key]);
}
