import React from "react";
import PropTypes from "prop-types";
import SessionListItem from "./SessionListItem";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useClick } from "state/hooks";
import {
    ParticipantsCount,
    SessionConfirmActions,
} from "joynt/components/SessionParticipation";
import { useSessionParticipants } from "joynt/hooks/sessions";
import AvatarGroup from "joynt/components/AvatarGroup";
import SessionCover from "joynt/components/SessionCover";

export function SessionListItemContainer(props) {
    const { id, onEdit, onShow } = props;

    const {
        name,
        event_start,
        event_end,
        event_stage,
        event_state,
        can_edit,
        color,
        hidden,
    } = useNodeData(id);

    const { parent } = useEdgesData(id);
    const { name: parentName } = useNodeData(parent);

    const onClick = useClick(props);
    const edit = useClick({ id, onClick: onEdit });
    const show = useClick({ id, onClick: onShow });

    const { identities, max, count } = useSessionParticipants(id);

    return (
        <SessionListItem
            {...props}
            color={color}
            start={event_start}
            end={event_end}
            onEdit={can_edit ? edit : null}
            onShow={onShow ? show : null}
            isLive={event_state === "live"}
            isScheduled={event_state === "pending"}
            isPrivate={!event_stage}
            isHidden={hidden}
            canEdit={can_edit}
            parent={<div>in {parentName}</div>}
            image={<SessionCover id={id} />}
            {...{ name, onClick }}
        >
            <div className={"rows rows-middle"}>
                {max || count ? <ParticipantsCount id={id} /> : null}
                <div className={"cols cols-spread"}>
                    <div className={"cols cols-middle gap-xs"}>
                        <AvatarGroup ids={identities} />
                    </div>
                    <SessionConfirmActions id={id} />
                </div>
            </div>
        </SessionListItem>
    );
}

SessionListItemContainer.propTypes = {
    id: PropTypes.string,
};
SessionListItemContainer.defaultProps = {};

export default SessionListItemContainer;
