import React from "react";

import UnderConstructionBar from "joynt/components/UnderConstructionBar";
import { useNode, useNodeData } from "joynt/hooks";
import { CollapsedField } from "joynt/components/Form";
import Field from "containers/Field";
import { HelperText } from "joynt/components/HelperText";

//import PropTypes from 'prop-types';

export function SessionPublishedStatus(props) {
    const { id } = props;

    const { hidden: currentStatus } = useNodeData(id);
    const { hidden: nextStatus } = useNode(id);

    const currentlyPublished = !currentStatus;
    const willBePublished = !nextStatus && !currentlyPublished;
    const willBeUnpublished = nextStatus && currentlyPublished;

    return (
        <CollapsedField
            label={
                currentlyPublished
                    ? `This session is published`
                    : `This session is unpublished`
            }
            expandLabel={"Change"}
            collapsed={currentlyPublished}
            helperText={
                !currentlyPublished && (
                    <HelperText loudness={2}>
                        Only admins can see unpublished sessions
                    </HelperText>
                )
            }
        >
            <div className={"rows gap-sm"}>
                {currentlyPublished && (
                    <HelperText loudness={3}>
                        Note that any changes to the published sessions may
                        affect your schedule and confuse the users.
                    </HelperText>
                )}
                <UnderConstructionBar>
                    <div className={"grow cols cols-spread cols-middle"}>
                        <strong>
                            {!currentlyPublished
                                ? "I want to publish this session"
                                : "I want to unpublish this session"}
                        </strong>
                    </div>
                    <div className={"cols"}>
                        <Field
                            id={"hidden"}
                            fieldType={"checkbox"}
                            negate={!currentlyPublished}
                        />
                    </div>
                </UnderConstructionBar>
                {willBePublished && (
                    <HelperText loudness={2}>
                        Publishing this session will notify{" "}
                        <strong>
                            people in this space or with access to this session
                        </strong>
                    </HelperText>
                )}
                {willBeUnpublished && (
                    <HelperText loudness={2}>
                        Unpublishing session will{" "}
                        <strong>hide it from the schedule.</strong> Only admins
                        can see unpublished sessions.
                    </HelperText>
                )}
            </div>
        </CollapsedField>
    );
}

SessionPublishedStatus.propTypes = {};
SessionPublishedStatus.defaultProps = {};

export default SessionPublishedStatus;
