import {selectRouteParam} from "state/selectors/router";
import {selectResourceId} from "state/selectors/app";
import Form, {Form as SchemaForm} from "containers/Form";
import {selectEntity} from "state/selectors/data";
import {findSchema} from "state/selectors/schema";
import itemTypes from 'config/item-types';
import {NS_SCHEMA, NS_SCHEMA_FIELDS, NS_SCHEMA_TYPES, DB_SYSTEM_TYPES} from "state/selectors/schema";

export const selectNestedFormSchema = (store, path) => {
    let parts = path.split('|');
    let root = parts[0];
    let app = root.split('.')[0];
    let schemaPath = null;

    if (app === 'cms' || DB_SYSTEM_TYPES.indexOf(root) > -1) {
        let nestedType = parts[2] ? parts[2].replace(app+'.', '') : null;
        let rootSchemaId = root && parts[1] ? findSchema(store, root, parts[1]) : null;

        let rootSchema = nestedType && rootSchemaId
            ? selectEntity(store, NS_SCHEMA, rootSchemaId)
            : null;

        let nestedSchemaId = rootSchema && rootSchema.field_index
            ? rootSchema.field_index[nestedType]
            : null;

        let schemaType = nestedSchemaId ? NS_SCHEMA_FIELDS : NS_SCHEMA;
        let schemaId = nestedSchemaId ? nestedSchemaId : rootSchemaId;
        let schema = selectEntity(store, schemaType, schemaId);
        let itemType = parts[parts.length-2];
        let schemaItemType = schema.properties ? schema.properties.item_type : null;
        let schemaTypePath = [parts[0], itemType.split('.')[1]].join('/');

        let formItemType = itemTypes[schemaItemType] || itemTypes[schemaTypePath] || itemType;
        let formItemId = parts[parts.length-1];

        let subtypeSchema = findSchema(store, formItemType, formItemId);

        if (subtypeSchema) {
            schemaId = subtypeSchema;
            schemaType = NS_SCHEMA;
        }

        return {
            formEntity: schemaType,
            fieldType: NS_SCHEMA_FIELDS,
            viewField: 'fields',
            viewId: schemaId,
            type: formItemType,
            id: formItemId,
            root: parts[0],
            rootId: parts[1],
            FormComponent: SchemaForm
        }
    }

    schemaPath = [NS_SCHEMA_TYPES];
    schemaPath.push(selectResourceId(store, parts[0], parts[1]));

    if (parts[2]) {
        schemaPath.push('fields_index');
        schemaPath.push(parts[2].split('.')[1]);
    }

    console.log(schemaPath);

    return {
        schemaPath: schemaPath,
        path: parts,
        type: parts[parts.length-2],
        id: parts[parts.length-1],
        root: parts[0],
        rootId: parts[1],
        FormComponent: app === 'db'
            ? Form
            : SchemaForm
    }
};

export const selectModals = (store) => {
    const modalsString = selectRouteParam(store, 'modals');
    if (!modalsString) return [];
    const modals = modalsString.split(',');
    return modals.map(modal=>{
        return selectNestedFormSchema(store, modal);
    });
};