import { selectEntity } from "state/selectors/data";
import { parseTemplateMapping } from "cms/util/templatable";
import { change } from "cms/state/actions/cms";
import React from "react";
import { connect } from "react-redux";
import RewireContext from "containers/context/RewireContext";
import RenderContext from "cms/context/RenderContext";

const nullObj = {};

export const normalizeType = (type) => {
    if (type === "structured") return "layout";
    if (type === "contact-form") return "form";
    return type;
};

export const normalizeContextPath = (path) => {
    let [type, id] = path.split("/");
    let key = null;
    if (type.indexOf("@") > -1) {
        let parts = type.split("@");
        if (parts.length > 1) {
            key = parts[0];
            type = parts[1];
        }
    }
    return [type, id, key];
};

export const selectContexts = (store, context) => {
    let paths = context.split(":");
    let data = {};
    paths.forEach((path) => {
        let [type, id, key] = normalizeContextPath(path);
        let useKey = key || "default";
        data[useKey] = {
            ...selectEntity(store, type, id),
            __contextPath: [type, id].join("/"),
        };
    });
    return data;
};

const mapState = (store, props) => {
    const { id, type, rewireContext, ...other } = props;
    let data = selectEntity(store, type, id);
    let rewire = props.rewire || data.template_id;
    let rewireMap = {};
    if (rewire && rewireContext) {
        let parsed = parseTemplateMapping(rewire);
        let rewiredKeys = Object.keys(parsed);
        let rewireFrom = selectContexts(store, rewireContext);
        data = { ...data };
        rewiredKeys.forEach((to) => {
            let from = parsed[to];
            let fromParts = from.split("@");
            let key = "default";
            if (fromParts.length > 1) {
                key = fromParts[0];
                from = fromParts[1];
            }
            if (rewireFrom[key]) {
                /** Protect from recursion when rendering template section **/
                if (from === "items" && to === "components") {
                    if (rewireFrom[key].template) {
                        data[to] = rewireFrom[key][from];
                    }
                } else {
                    data[to] = rewireFrom[key][from];
                    rewireMap[to] = [rewireFrom[key].__contextPath, from].join(
                        "/"
                    );
                }
                if (to === "items") {
                    data.item_type = from === "links" ? "cms.links" : null;
                }
            }
        });
    }
    const elementType = normalizeType(props.element_type || data.element_type);
    let inspectId = [type, id].join("/");

    return {
        entityType: type,
        entityId: id,
        ...other,
        ...data,
        element_type: elementType,
        rewire,
        rewireMap: nullObj,
        rewireContext: rewire ? rewireContext : null,
        inspectId,
    };
};

const mapDispatch = {
    onChange: change,
};

export const connectComponent = (Decorated) => {
    class Decorator extends React.PureComponent {
        handleChange = (key, value) => {
            const { props } = this;

            const { rewireContext, rewire, entityType, entityId, onChange } =
                props;

            let update = {};
            update[key] = value;

            if (onChange)
                onChange(entityType, entityId, update, rewire, rewireContext);
        };

        handleClick = () => {
            //alert(this.props.rewireContext);
        };

        render() {
            const props = this.props;

            if (props.disabled) return null;

            const { entityType, entityId, ...other } = props;
            return (
                <RenderContext.Consumer>
                    {(renderMode) => (
                        <Decorated
                            {...other}
                            renderMode={renderMode}
                            onChange={
                                renderMode === "edit" ? this.handleChange : null
                            }
                            onClick={this.handleClick}
                        />
                    )}
                </RenderContext.Consumer>
            );
        }
    }
    Decorator.autoFillProps = Decorated.autoFillProps;
    return connect(mapState, mapDispatch)(Decorator);
};

export const withRewireContext = (Decorated) => {
    return function RewireContextDecorator(props) {
        return (
            <RewireContext.Consumer>
                {(value) => {
                    return <Decorated {...props} rewireContext={value} />;
                }}
            </RewireContext.Consumer>
        );
    };
};
