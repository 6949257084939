import {ThemeSelect} from "cms/ui/style/options/ThemeSelect";
import {IconToggle} from "cms/ui/style/options/IconToggle";

export const config = {
    theme: {
        type: 'theme'
    },
    'text-align': {
        type: 'toggle'
    },
    layout: {
        type: 'toggle'
    }
};

export const types = {
    theme: ThemeSelect,
    toggle: IconToggle
};