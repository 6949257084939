import React from 'react';
import PropTypes from 'prop-types';

import {SessionDate} from "joynt/components/TimeUtils";

import "./style.css";
import HeaderImage from "joynt/components/WorkspaceHeader/HeaderImage";

export function EventHeader(props) {

    const {
        id,
        name,
        description,
        start,
        end
    } = props;

    return (<div className={"event-header"}>
        <div className={"event-header__content"}>
            {start && end && <div className={"event-header__dates"}>
                <SessionDate start={start} end={end} />
            </div>}
            <div className={"event-header__name"}>{name}</div>
            <div className={"event-header__description"}>{description}</div>
        </div>
        <HeaderImage id={id} />
    </div>);

}

EventHeader.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number
};
EventHeader.defaultProps = {};

export default EventHeader;
