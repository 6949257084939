import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Meetings from "./Meetings";
import {useApiActions, useList} from "state/hooks";
import {createSession} from "joynt/state/actions/meetings";
import useFetch from "state/hooks/useFetch";
import {SessionItem} from "joynt/components/Schedule";
import {useFeatureEnabled} from "joynt/hooks";
import {FEATURE_OPTIMIZED_API} from "joynt/config/features";

export function MeetingsContainer(props) {

    const {id} = props;

    const {onCreateSession} = useApiActions({
        onCreateSession: createSession
    });

    const create = useCallback(() => {
        onCreateSession(id);
    }, [onCreateSession, id]);

    const list = `nodes.${id}.live`;
    const isDevApi = useFeatureEnabled(FEATURE_OPTIMIZED_API);

    const url = isDevApi
        ? `joynt/nodes/${id}/live-sessions?event_stage=false`
        : `joynt/nodes/${id}/live-sessions?event_stage=false`;

    const pending = useFetch({
        type: "db.nodes",
        url,
        list
    });

    const {items: sessions} = useList("db.nodes", list);

    return (<Meetings
        {...props}
        onCreate={create}
        isPending={pending}
    >
        {sessions.map(session => <SessionItem key={session} id={session} />)}
    </Meetings>);

}

MeetingsContainer.propTypes = {
    id: PropTypes.string
};
MeetingsContainer.defaultProps = {};

export default MeetingsContainer;