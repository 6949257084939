import React from "react";
//import PropTypes from 'prop-types';
import DebugConsole from "./DebugConsole";
import { useData } from "state/hooks";
import { useEditorState } from "playground/designer/EditorState";

export function DebugConsoleContainer(props) {
    const { path } = props;
    const [type, id] = path ? path.split("/") : [];
    const state = useData({ type, id });
    const [editorState] = useEditorState();

    return (
        <DebugConsole
            {...props}
            state={path === "editorState" ? editorState : state}
        />
    );
}

DebugConsoleContainer.propTypes = {};

DebugConsoleContainer.defaultProps = {};

export default DebugConsoleContainer;
