import React from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';
import Field from 'containers/Field';
import Entity from 'containers/Entity';
import List from 'containers/List';

//import {SortableIterator} from "components/dnd";
import {withData} from "containers/Entity";
import {selectRouteParam} from "state/selectors/router";
import {selectFormViews} from "state/selectors/app";
import {ArrayIterator} from "components/list/iterator";
import {selectFields} from "state/selectors/schema";
import SchemaNotAvailable from "components/form/SchemaNotAvailable";
import CustomField from "components/fields/custom/CustomField";

const mapStateToProps = (store, props) => {
    let type = props.type || selectRouteParam(store, 'resource');
    let id = props.id || selectRouteParam(store, 'id');
    let views = selectFormViews(store, type, id);
    if (!views.length && !props.schemaPath) return mapStateToPropsSchema(store, props);
    let schemaProps = {};
    if (props.schemaPath && props.schemaPath.length > 2) {
        let field = get(store.data, props.schemaPath) || {};
        if (!field.uuid) console.log(props.schemaPath);
        schemaProps = {
            formEntity: 'db.fields',
            fieldType: 'db.fields',
            viewField: 'fields',
            viewId: field.uuid || null,
            Iterator: FormFieldIterator
        };
    } else {
        schemaProps = {
            formEntity: 'db.form_views',
            fieldType: 'db.view_fields',
            viewField: 'view_fields',
            viewId: props.view && views.indexOf(props.view) > -1
                ? props.view
                : views[0],
            Iterator: FormViewFieldIterator
        }
    }
    return {
        ...schemaProps,
        type,
        id
    }
};

const mapStateToPropsSchema = (store, props) => {
    let type = props.type || selectRouteParam(store, 'resource');
    let id = props.id || selectRouteParam(store, 'id');
    let schemaProps = {
        formEntity: 'schema.db',
        fieldType: 'db.fields',
        viewField: 'fields',
        Iterator: FormFieldIterator
    };
    return {
        ...schemaProps,
        fields: selectFields(
            store,
            type,
            id,
            schemaProps,
            props.selectFields
        ),
        type,
        id
    }
};

const mapDispatchToProps = {};

export class Form extends React.PureComponent {

    render() {
        const {
            type,
            id,
            formEntity,
            viewId,
            fieldType,
            viewField,
            fields,
            variant,
            Iterator
        } = this.props;

        if (fields) {
            return (<div className={'fields'}>
                {fields && fields.length ? <List
                    formEntityType={type}
                    formEntityId={id}
                    type={fieldType}
                    items={fields}
                    variant={variant}
                    Iterator={Iterator || FormFieldIterator}
                /> : <SchemaNotAvailable
                    type={type}
                    path={[formEntity, viewId, viewField].join('/')}
                />}
            </div>);
        }

        return (<div className={'fields'}>
            { viewId ? <Field entityType={formEntity} entityId={viewId} id={viewField}>
                {({value}) => {
                    return value && value.length ? <List
                        formEntityType={type}
                        formEntityId={id}
                        type={fieldType}
                        items={value}
                        variant={variant}
                        Iterator={Iterator || FormFieldIterator}
                    /> : <SchemaNotAvailable
                        type={type}
                        path={[formEntity,viewId,viewField].join('/')}
                    />
                }}
            </Field> : null }
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form);

export const SchemaForm = connect(mapStateToPropsSchema, mapDispatchToProps)(Form);

class Layout extends React.PureComponent {
    render() {
        return <div className={'cols cols-flex gap-sm layout'}>
            <FormViewField {...this.props} />
        </div>;
    }
}

class Tab extends React.PureComponent {
    render() {
        return <div className={'rows gap-sm tab'}>
            tab
            <FormViewField {...this.props} />
        </div>;
    }
}

class SchemaFieldReference extends React.PureComponent {
    render() {
        const {
            data,
            formEntityType,
            formEntityId
        } = this.props;

        return <div className={'cols field'}>
            {data.source ?
                <Entity type={'db.fields'} id={data.source}>
                    {({data}) => data.id ? <Field
                        fieldEntityType={'db.fields'}
                        fieldEntityId={data.id}
                        entityType={formEntityType}
                        entityId={formEntityId}
                        id={data.slug}
                        label={data.label}
                        fieldType={data.type}
                    /> : null}
                </Entity>
            : null}
        </div>;
    }
}

class FormViewField extends React.PureComponent {
    render() {
        const { type, id, formEntityType, formEntityId } = this.props;
        return <Field entityType={type} entityId={id} id={'view_fields'}>
            {({value}) => {
                return value && value.length ?
                    <List
                        formEntityType={formEntityType}
                        formEntityId={formEntityId}
                        type={'db.view_fields'}
                        items={value}
                    >
                        {FormViewFieldIterator}
                    </List>
                    : null;
            }}
        </Field>;
    }
}

const formViewFieldTypes = {
    layout: Layout,
    tab: Tab,
    field: SchemaFieldReference,
    'custom-view': CustomField
};

class RenderFormViewField extends React.PureComponent {
    render() {
        const { data } = this.props;
        const { type } = data;
        const FieldComponent = formViewFieldTypes[type];
        if (!FieldComponent) return <div>Unknown field type {type}</div>;
        return <FieldComponent {...this.props} />
    }
}

class RenderFormField extends React.PureComponent {
    render() {
        const { formEntityType, formEntityId, variant, data } = this.props;
        const { id, slug, type, label } = data;
        const fieldEntityType = this.props.type;
        return (<div className={'cols field'}>
            <Field
                id={slug}
                fieldType={type}
                label={label}
                entityType={formEntityType}
                entityId={formEntityId}
                fieldEntityType={fieldEntityType}
                fieldEntityId={id}
                variant={variant}
            />
        </div>);
    }
}

export function FilterIterator(ItemRenderer) {
    return function({items, filter, ...other}) {
        items = filter ? items.filter(filter) : items;
        return items.map((item, index)=><ItemRenderer
            {...other}
            key={item}
            id={item}
            index={index}
        />);
    }
}

const ConnectedFormViewField = withData(RenderFormViewField);
const FormViewFieldIterator = ArrayIterator(ConnectedFormViewField);

const ConnectedFormField = withData(RenderFormField);
export const FormFieldIterator = ArrayIterator(ConnectedFormField);