import React from "react";
import PropTypes from "prop-types";
import MuiIcon from "@material-ui/core/Icon";
import cn from "classnames";

function detectType(icon, defaultType) {
  if (!icon) return null;
  if (icon.indexOf("mui-") === 0) return "mui";
  if (icon.indexOf("fh-") === 0) return "fh";
  if (icon.indexOf("fa-") === 0) return "fa";
  return defaultType;
}

export default function Icon(props) {
  const { className, defaultType, fontSize } = props;
  const icon = props.children;
  const type = detectType(icon, defaultType);

  if (!icon || !type) return null;

  if (type === "fh") {
    return <i className={"fh " + icon} />;
  }

  if (type === "mui") {
    let normalizedIcon = icon ? icon.replace(/mui-/g, "") : null;

    return (
      <MuiIcon className={className} color={"inherit"} fontSize={fontSize}>
        {normalizedIcon}
      </MuiIcon>
    );
  }

  if (type === "fa") {
    return <i className={cn("fa fa-" + icon, "size-" + fontSize)} />;
  }

  return null;
}

Icon.propTypes = {
  children: PropTypes.string,
  defaultType: PropTypes.string,
};

Icon.defaultProps = {
  defaultType: "fa",
  fontSize: "inherit",
};
