import React from "react";
import { ConfigContext } from "joynt/meeting/agora-ng/context/ConfigContext";
import { useSessionConfig } from "joynt/meeting/agora-ng/hooks";

//import PropTypes from 'prop-types';

export function SessionConfigProvider(props) {
    const { channel, children } = props;
    const config = useSessionConfig(channel, true);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
}

SessionConfigProvider.propTypes = {};
SessionConfigProvider.defaultProps = {};

export default SessionConfigProvider;
