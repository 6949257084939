import React from 'react';
import SelectField from "joynt/edit/SelectField";
//import PropTypes from 'prop-types';

export default function RoleSelect(props) {
    const {onSelect, value, allowedRoles, label} = props;

    return <SelectField
        onChange={onSelect}
        value={value}
        options={allowedRoles}
        label={label}
    />;
}

RoleSelect.propTypes = {};
RoleSelect.defaultProps = {};
