import React from "react";
import Resources from "playground/cms/views/Resources";
import ListItem from "./ListItem";
import { ListLayout } from "playground/cms/List";
import { Search } from "playground/cms/List/Filter";

//import PropTypes from 'prop-types';

export function Domains(props) {
    const { type } = props;
    return (
        <Resources {...props} ListItem={ListItem}>
            <ListLayout>
                <ListLayout.Filter>
                    <Search type={type} name={"search"} />
                </ListLayout.Filter>
                <ListLayout.Header>
                    <ListLayout.Header.Icons prepend={true}>
                        <ListLayout.Header.Filter />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
            </ListLayout>
        </Resources>
    );
}

Domains.propTypes = {};

Domains.defaultProps = {};

export default Domains;
