import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";

export function GhostIcon(props) {

    return (<div className={"ghost-icon"} />);

}

GhostIcon.propTypes = {};
GhostIcon.defaultProps = {};

export default GhostIcon;
