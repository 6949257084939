import React, {useCallback, useMemo} from 'react';
import {parsePoints} from "plan-editor/util";
import Point from "./Point";
import Shape from "./Shape";
import Label from "./ApartmentName";

//import PropTypes from 'prop-types';

export default function RenderShape(props) {

    const {
        shape,
        scale,
        onDeletePoint,
        onSelectPoint,
        onCopyPoint,
        isSelected,
        showLabels,
        id,
        type,
        index,
        onSelectShape,
        ...other
    } = props;

    const mouseDown = useCallback(() => {
        onSelectPoint(index, true);
    }, [onSelectPoint, index]);

    const mouseUp = useCallback(() => {
        onSelectPoint(index, false);
    }, [onSelectPoint, index]);

    const points = useMemo(
        ()=>parsePoints(shape.points),
        [shape.points]
    );

    return <>
        {points.map((point, index) => {
            return <Point
                key={index}
                point={point}
                deletePoint={onDeletePoint}
                x={point.x*scale}
                y={point.y*scale}
                index={index}
                selected={isSelected}
                onMouseDown={mouseDown}
                onMouseUp={mouseUp}
                copyPoint={onCopyPoint}
                {...other}
            />
        })}
        <Shape
            scale={scale}
            points={points}
            isActive={isSelected}
        />
        {showLabels && points.length > 3
            ? <Label
                onClick={onSelectShape}
                points={points}
                scale={scale}
                id={id}
                type={type}
                isActive={true}
            />
            : null }
    </>;

}

RenderShape.propTypes = {};
RenderShape.defaultProps = {};
