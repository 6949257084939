import React from 'react';
import {connect} from 'react-redux';
import {isPending} from "state/selectors/ui";
import Preloader from 'components/Preloader';

const mapStateToProps = (store, props) => {
    return {
        isPending: isPending(store, props.id)
    }
};

const mapDispatchToProps = {};

class PreloaderContainer extends React.PureComponent {

    render() {
        const { children, isPending, variant } = this.props;

        return (<React.Fragment>
            <Preloader visible={isPending} variant={variant} />
            {children}
        </React.Fragment>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreloaderContainer);