import React from "react";
import cn from "classnames";

//import PropTypes from 'prop-types';

import "./style.css";

export function SessionPageSection(props) {
    const { title, actions, children, className } = props;

    return (
        <div className={cn("session-page-section", className)}>
            {(title || actions) && <div className={"session-page-section__header"}>
                {title && (
                    <h5 className={"session-page-section__title"}>{title}</h5>
                )}
                {actions && (
                    <div className={"session-page-sections__actions"}>
                        {actions}
                    </div>
                )}
            </div>}
            <div className={"session-page-section__content"}>{children}</div>
        </div>
    );
}

SessionPageSection.propTypes = {};
SessionPageSection.defaultProps = {};

export default SessionPageSection;
