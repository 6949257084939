import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";
import Icon from "components/Icon";

export function BadgeButton(props) {

    const {
        value,
        onClick,
        nullIcon,
        nullLabel
    } = props;

    const percentValue = Math.round(value*100);
    const style = {"--p": percentValue};
    const isIcon = [0,1].indexOf(value) > -1;

    const label = value === 0
        ? nullLabel
        : `${percentValue}%`;

    return (<div
        className={cn("badge-button", {
            "badge-button--part": !isIcon,
            "badge-button--icon": isIcon,
            "badge-button--success": value === 1,
            "badge-button--null": value === 0
        })}
        onClick={onClick}
    >
        <div className={"badge-button__pie"} style={style}>
            {value === 1 && <Icon>mui-done</Icon>}
            {value === 0 && <Icon>{nullIcon}</Icon>}
        </div>
        <div className={"badge-button__label"}>
            {label}
        </div>
    </div>);

}

BadgeButton.propTypes = {
    value: PropTypes.number,
    onClick: PropTypes.func,
    nullLabel: PropTypes.string,
    nullIcon: PropTypes.string
};
BadgeButton.defaultProps = {
    value: 0,
    nullLabel: "Peer",
    nullIcon: "mui-thumb_up_alt"
};

export default BadgeButton;
