import React from 'react';
import PropTypes from 'prop-types';

import Icon from "components/Icon";

export function EventIcon(props) {

    const {icon} = props;

    return (<div className={"event-icon"}>
        <Icon>{icon}</Icon>
    </div>);

}

EventIcon.propTypes = {
    icon: PropTypes.string
};
EventIcon.defaultProps = {
    icon: "mui-videocam"
};

export default EventIcon;
