import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Section(props) {
    const {} = props;
    return (
        <>
            {props.children}
            <Field id={"description"} label={"Opis"} />
            <Field id={"title"} label={"Title"} />
            <Field id={"headline"} label={"Headline"} />
            <Field id={"text"} label={"Text"} fieldType={"rich"} />
        </>
    );
}

Section.propTypes = {};

Section.defaultProps = {};

export default Section;
