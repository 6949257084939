import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useKeep, useMessaging, useNodeData, usePagination } from "joynt/hooks";
import { useRouteParam, useUiFlag } from "state/hooks";
import { NODE_TYPE_CHAT, ROUTE_THREAD } from "joynt/config";
import ListView from "containers/ListView";
import Button from "components/Button";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
import ScrollFade from "joynt/components/ScrollFade";
import Icon from "components/Icon";
import Chat from "joynt/components/messages/chat/Chat";
import CreateThread from "./CreateThread";
import { ThreadSkeleton } from "./Thread";
import InboxEmptyState from "./EmptyState";
import {
    InboxThreadCardContainer,
    InboxFilters,
} from "joynt/components/Inbox/";
import { useRootNode } from "joynt/hooks/channels";

import "./inbox.css";
import PostViewContainer from "joynt/components/Post/PostView";

const ThreadCardIterator = InfiniteScrollIterator(InboxThreadCardContainer);

function ThreadsSkeleton(props) {
    return (
        <>
            <ThreadSkeleton />
            <ThreadSkeleton />
            <ThreadSkeleton />
        </>
    );
}

export default function Inbox(props) {
    const { id: node, showFilters, showParentLink } = props;

    const root = useRootNode();
    const [parent] = useState(null);
    const [filter, setFilter] = useUiFlag("inboxFilter", "all");
    const [thread, onShowThread] = useRouteParam(ROUTE_THREAD);
    const { onKeep } = useKeep();
    const { onCreateThread } = useMessaging({ parent: node });

    const id = parent || root || node;

    const list = ["db.posts", id, "threads", filter].join(".");

    const { page, hasMore } = usePagination("db.nodes", list);
    const {
        subtype,
        recipient_identities,
        public: isPublic,
        draft: isDraft,
    } = useNodeData(thread);

    const participants = recipient_identities ? recipient_identities.length : 0;
    let url = `joynt/nodes/${id}/inbox`;

    if (filter === "all") url += `?subtype=${NODE_TYPE_CHAT}`;
    if (filter === "private")
        url += `?subtype=${NODE_TYPE_CHAT}&public=false&published=false`;
    if (filter === "channels") url += `?subtype=${NODE_TYPE_CHAT}&public=true`;
    if (filter === "comments") url += `?subtype=null&public=true`;

    const isChat = participants === 1 && !isPublic;
    const isPost = !subtype && isPublic;

    const create = useCallback(() => onCreateThread(), [onCreateThread]);

    const enableCreate = false;

    return (
        <div className={cn("inbox", !!thread && "is-open")}>
            <aside className={"inbox-list"}>
                {showFilters && (
                    <div className={"inbox-header"}>
                        <div className={"inbox-nav grow cols"}>
                            <InboxFilters onChange={setFilter} value={filter} />
                        </div>
                    </div>
                )}
                <ScrollFade className={"inbox-threads"} hasMore={hasMore}>
                    <ListView
                        type={"db.nodes"}
                        view={null}
                        list={list}
                        filter={list}
                        url={url}
                        append={true}
                        useWindowScroll={false}
                        onClick={onShowThread}
                        Iterator={ThreadCardIterator}
                        className={"rows gap-5"}
                        selected={thread}
                        pendingState={!page ? <ThreadsSkeleton /> : null}
                        pendingReplace={true}
                        emptyState={<InboxEmptyState onCreate={create} />}
                        showParentLink={showParentLink}
                    />
                </ScrollFade>
                {enableCreate && onCreateThread && (
                    <div className={"cols cols-center pad-md"}>
                        <Button
                            onClick={create}
                            startIcon={<Icon>mui-edit</Icon>}
                            variant={"outlined"}
                        >
                            New message
                        </Button>
                    </div>
                )}
            </aside>
            <section className="inbox-main">
                {thread && (
                    <>
                        {isPost && (
                            <div className={cn("inbox-post")}>
                                <PostViewContainer
                                    id={thread}
                                    editable={false}
                                />
                            </div>
                        )}
                        {!isPost && (
                            <div
                                className={cn("inbox-chat rows", {
                                    "brand-bg": !thread,
                                    "overflow-hide": !thread,
                                })}
                            >
                                {!isDraft ? (
                                    <Chat
                                        node={id}
                                        id={thread}
                                        onKeep={onKeep}
                                        variant={isChat ? "chat" : "channel"}
                                    />
                                ) : (
                                    <CreateThread parent={thread}>
                                        new thread form
                                    </CreateThread>
                                )}
                            </div>
                        )}
                    </>
                )}
            </section>
        </div>
    );
}

Inbox.propTypes = {
    id: PropTypes.string.isRequired,
    showFilters: PropTypes.bool,
};
Inbox.defaultProps = {
    showFilters: true,
    showParentLink: true
};
