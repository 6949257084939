import React from "react";
//import PropTypes from 'prop-types';
import LocaleSelect from "./LocaleSelect";
import { useSelector } from "react-redux";
import { selectAliasedEntity, selectEntity } from "state/selectors/data";
import { useActions, useRouteParam } from "state/hooks";
import { resetAll } from "state/actions/data";

function selectLanguages(state, websiteId) {
    const website = selectAliasedEntity(state, "console.services", websiteId);
    const cmsId = website.content_source;
    if (!cmsId) return [];
    const cms = selectEntity(state, "console.services.cms", cmsId);
    const languages = cms?.languages || [];
    return languages
        .map((lang) => {
            return selectEntity(
                state,
                "schema.data.value",
                `data.languages.${lang}`
            );
        })
        .map((lang) => ({
            ...lang,
            id: lang.value,
        }));
}

export function LocaleSelectContainer(props) {
    const { id } = props;
    const options = useSelector((state) => {
        return selectLanguages(state, id);
    });
    const [value, setValue] = useRouteParam("lang");
    const { onReset } = useActions({
        onReset: resetAll,
    });
    const change = (v) => {
        onReset();
        setValue(v);
    };
    return <LocaleSelect options={options} value={value} onChange={change} />;
}

LocaleSelectContainer.propTypes = {};

LocaleSelectContainer.defaultProps = {};

export default LocaleSelectContainer;
