import React, { useContext } from "react";
import cn from "classnames";
import Draft, { EditorBlock } from "draft-js";
import { moveBlock, updateBlockData } from "./../util";
import { Draggable } from "components/dnd";
import { DraftDndContext } from "joynt/components/ContentEditor/Editor";

function resolveBlockType(contentState, block) {
    const type = block.getType();
    if (type !== "atomic") return type;

    /** Render atomic block based on entity type **/
    const entityKey = block.getEntityAt(0);
    if (!entityKey) return type;
    const entity = contentState.getEntity(entityKey);
    return entity.getType();
}

const DragHandle = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <g id="baseline-drag_indicator-24px" opacity="0.45">
                <path
                    id="Path_242"
                    data-name="Path 242"
                    d="M0,0H16V16H0Z"
                    fill="none"
                />
                <path
                    id="Path_243"
                    data-name="Path 243"
                    d="M9.667,13.333A1.333,1.333,0,1,1,8.333,12,1.337,1.337,0,0,1,9.667,13.333ZM8.333,8A1.333,1.333,0,1,0,9.667,9.333,1.337,1.337,0,0,0,8.333,8Zm0-4A1.333,1.333,0,1,0,9.667,5.333,1.337,1.337,0,0,0,8.333,4Zm4,2.667A1.333,1.333,0,1,0,11,5.333,1.337,1.337,0,0,0,12.333,6.667Zm0,1.333a1.333,1.333,0,1,0,1.333,1.333A1.337,1.337,0,0,0,12.333,8Zm0,4a1.333,1.333,0,1,0,1.333,1.333A1.337,1.337,0,0,0,12.333,12Z"
                    transform="translate(-2.333 -1.333)"
                    fill="#380f67"
                />
            </g>
        </svg>
    );
};

const BlockWrapper = React.forwardRef((props, ref) => {
    const { id, hover } = props;
    return (
        <div ref={ref} className={cn("block-wrapper", { hover: !!hover })}>
            <div className={"block-tool"} contentEditable={false}>
                <div className={"block-drag-handle"}>
                    <DragHandle />
                </div>
            </div>
            <div className={"block-content"}>{props.children}</div>
        </div>
    );
});

function DraggableBlock(props) {
    const { id, onMove } = props;
    const { hover, setHover } = useContext(DraftDndContext);

    return (
        <Draggable
            render={BlockWrapper}
            id={id}
            type={"draft-blocks"}
            onSort={(drag, hover) => {
                //console.log('onSort', drag, hover);
                setHover(hover.id);
            }}
            onSortEnd={(target) => {
                //console.log('onSortEnd', target);
                if (hover) {
                    onMove(target.id, hover);
                    setHover(null);
                }
            }}
            hover={id === hover}
        >
            {props.children}
        </Draggable>
    );
}

const BlockDecorator = (Component, store, readOnly) =>
    React.forwardRef((props, ref) => {
        const { editor } = store;
        const { setHover, block, blockProps } = props;

        const change = (data) => {
            editor.setEditorState(
                updateBlockData(editor.getEditorState(), block, data)
            );
        };

        const move = (block, target) => {
            editor.setEditorState(
                moveBlock(editor.getEditorState(), block, target)
            );
        };

        const contentComponent = (
            <Component
                {...props}
                {...blockProps}
                onChange={change}
                readOnly={readOnly}
                ref={ref}
            />
        );

        if (readOnly)
            return (
                <div className={"block-wrapper"}>
                    <div className={"block-content"}>{contentComponent}</div>
                </div>
            );

        return (
            <DraggableBlock
                render={BlockWrapper}
                id={block.getKey()}
                setHover={setHover}
                onMove={move}
            >
                {contentComponent}
            </DraggableBlock>
        );
    });

export function createBlockRendererPlugin(config) {
    const { blocks, readOnly, decorator } = config;

    const store = {
        editor: null,
    };

    const components = {};

    function decorate(Component, id) {
        if (["joynt-todo", "joynt-callout"].indexOf(id) > -1) return Component;
        return decorator ? decorator(Component) : Component;
    }

    return {
        initialize: (editorFns) => {
            store.editor = editorFns;

            blocks.data.forEach((block) => {
                components[block.id] = decorate(
                    BlockDecorator(block.component, store, readOnly, block.id),
                    block.id
                );
            });

            components["default"] = BlockDecorator(
                EditorBlock,
                store,
                readOnly
            );
        },
        blockRendererFn: function (contentBlock, editor) {
            const type = resolveBlockType(
                editor.getEditorState().getCurrentContent(),
                contentBlock
            );
            const config = blocks.find(type);

            if (!config || !config?.component)
                return {
                    ...config,
                    component: components.default,
                };

            return {
                ...config,
                component: components[type],
            };
        },
        blockStyleFn: function (block) {
            return cn(`content-block block-${block.getType()}`, {
                placeholder: !block.getLength(),
            });
        },
        blockRenderMap: Draft.DefaultDraftBlockRenderMap.merge({
            atomic: {
                element: "section",
            },
        }),
    };
}

export default createBlockRendererPlugin;
