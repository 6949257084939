import React from "react";
//import PropTypes from 'prop-types';

import ProjectCard from "./../ProjectCard";

import "./style.css";
import ProjectsFilter from "./ProjectsFilter";
import ZuuLogo from "zuu/ZuuLogo";
import version from "util/version";
import UserMenu from "playground/cms/Layout/UserMenu";

export function Projects(props) {
    const { type, onClick, items } = props;
    return (
        <div className={"home-layout"}>
            {/*<div className={"home-header"}>header</div>*/}
            <div className={"projects-layout"}>
                <div className={"projects-filter"}>
                    <ProjectsFilter />
                    <div className={"cols gap-sm cols-middle cols-spread"}>
                        <div className={"cols gap-sm cols-middle"}>
                            <ZuuLogo className={"home-logo"} />
                            <div className={"o-30"}>{`${version()}`}</div>
                        </div>
                        <UserMenu />
                    </div>
                </div>
                <div className={"projects-grid"}>
                    {items.map((id) => (
                        <ProjectCard
                            key={id}
                            type={type}
                            id={id}
                            onClick={onClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

Projects.propTypes = {};

Projects.defaultProps = {
    items: [],
};

export default Projects;
