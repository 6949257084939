import React from "react";
import { isBrowser } from "util/ssr";
import Macy from "macy";
//import PropTypes from 'prop-types';

export default class GlobalScripts extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!isBrowser()) {
            this.Macy = require("macy");
        } else {
            this.Macy = Macy;
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.init();
    }

    init = () => {
        if (!this.Macy) return;
        const root = document.getElementById("theme-shadow-root")?.shadowRoot;
        if (!root) return;
        const macy = root?.querySelectorAll(".l-masonry");
        if (!macy) return;
        const options = {
            margin: 16,
            columns: 3,
            breakAt: {
                992: {
                    columns: 2,
                },
                768: {
                    columns: 1,
                },
            },
        };
        Array.from(macy).forEach((e) => {
            this.Macy({
                ...options,
                container: e,
            });
        });
    };

    render() {
        return this.props.children;
    }
}

GlobalScripts.propTypes = {};
