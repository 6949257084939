import React, { useState } from "react";
//import PropTypes from 'prop-types';
import { FormLayout, OverlayForm } from "playground/cms/Form";

import "./style.css";
import { useData, useFetch } from "state/hooks";
import Icon from "components/Icon";
import Button from "components/Button";
import { useSelector } from "react-redux";
import Checkbox from "components/fields/checkbox/Checkbox";
import SettingsEnumForm from "playground/cms/views/Settings/EnumForm";

function selectEnumDefinition(store, id) {
    const all = store?.data?.["cms.enum-definitions"] || {};
    const def = Object.values(all).filter((d) => d.slug === id);
    return def[0] ?? {};
}

function EnumValueItem(props) {
    const { id, onClick, checkbox } = props;
    const { title, icon } = useData({ type: "cms.enum-values", id });
    return (
        <div className={"enum-value-item"} onClick={onClick}>
            {checkbox && checkbox}
            <div className={"enum-value-item-icon"}>
                <Icon>{icon}</Icon>
            </div>
            <div className={"enum-value-item-name"}>
                <span>{title}</span>
            </div>
            <div class={"enum-value-item-actions"}></div>
        </div>
    );
}

function SelectableEnumValueItem(props) {
    const { onToggle, isSelected, ...other } = props;
    const checkbox = (
        <Checkbox
            variant={"checkbox"}
            value={isSelected}
            onChange={() => null}
        />
    );
    return <EnumValueItem {...other} checkbox={checkbox} onClick={onToggle} />;
}

function EnumForm(props) {
    const [edit, setEdit] = useState(false);
    const { id, value, onClose, onChange, enumType } = props;
    const def = useSelector((store) => selectEnumDefinition(store, id));
    useFetch({
        type: "cms.enum-definitions",
        url: `cms/enum-definitions/${id}`,
    });
    const { values } = def;
    const toggle = (v) => {
        let next = [...(value || [])];
        if (next.includes(v)) {
            next = next.filter((i) => i !== v);
        } else {
            next.push(v);
        }
        onChange(next);
    };
    return !edit ? (
        <FormLayout>
            <FormLayout.Content>
                <div className={"grid grid-2 gap-xs pad-md"}>
                    {values?.length
                        ? values.map((v) => (
                              <SelectableEnumValueItem
                                  key={v}
                                  id={v}
                                  isSelected={value?.includes(v)}
                                  onToggle={() => toggle(v)}
                              />
                          ))
                        : "No values defined"}
                </div>
            </FormLayout.Content>
            <FormLayout.Footer>
                <Button
                    onClick={() => setEdit(true)}
                    variant={"text"}
                    startIcon={<Icon>mui-settings</Icon>}
                >
                    Zmień / dodaj opcje
                </Button>
            </FormLayout.Footer>
        </FormLayout>
    ) : (
        <SettingsEnumForm id={id} onClose={() => setEdit(false)} />
    );
}

function ExpandButton({ onClick, isExpanded, hidden }) {
    return (
        <div onClick={onClick} className={"expand-button"}>
            <div className={"expand-button-label"}>
                {!isExpanded ? `+${hidden} więcej` : "Zwiń listę"}
            </div>
            <div className={"expand-button-icon"}>
                {isExpanded ? (
                    <Icon>mui-expand_less</Icon>
                ) : (
                    <Icon>mui-expand_more</Icon>
                )}
            </div>
        </div>
    );
}

export function EnumValues(props) {
    const { label, value, onChange, enumType } = props;
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);

    const maxRows = 4;
    const cols = 3;
    const show = cols * maxRows - 1;
    const hidden = value?.length - show;

    const collapse = value?.length > cols * maxRows;
    const collapsed = collapse && !expand ? value?.slice(0, show) : value || [];

    return (
        <FormLayout.Section
            name={label}
            action={
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    endIcon={<Icon>mui-edit</Icon>}
                >
                    Zmień
                </Button>
            }
        >
            <div className={"grid grid-3 gap-xs"}>
                {collapsed.map((v) => (
                    <EnumValueItem key={v} id={v} />
                ))}
                {collapse && (
                    <ExpandButton
                        onClick={() => setExpand(!expand)}
                        isExpanded={expand}
                        hidden={hidden}
                    />
                )}
            </div>
            <OverlayForm
                open={open}
                onClose={() => setOpen(false)}
                title={"Dodaj wartość"}
            >
                {open && (
                    <EnumForm
                        id={enumType}
                        value={value}
                        onClose={() => setOpen(false)}
                        onChange={onChange}
                    />
                )}
            </OverlayForm>
        </FormLayout.Section>
    );
}

EnumValues.propTypes = {};

EnumValues.defaultProps = {
    value: [],
};

export default EnumValues;
