export default {
    section: {
        wrapper: "PageSection__wrapper section-wrapper",
        // columns: "section-columns",
        // column: "section-column",
        image_outer: "section-background",
        image: "c-img block-image",
        overlay: "Background__overlay",
    },
    button: {
        button: "c-btn aligned hover",
        button_heading: "c-btn__heading",
        button_label: "c-btn__label",
        button_headline: "c-btn__headline",
        button_icon: "c-btn__icon",
        badge: "c-btn__badge Badge",
    },
    text: {
        text: "text",
        heading: "section-heading aligned l-area",
        title: "heading-title aligned",
        headline: "headline aligned",
        text_outer: "text-outer",
    },
    layout: {
        area: "l-area",
    },
    "page-layout": {
        page_wrapper: "wrapper", // enable-reveal-animations
    },
    nav: {
        menu_item: "nav__item hover",
    },
    card: {
        item: "card gallery-item",
        content: "card__content",
        image_outer: "card__image_outer",
        image: "card__image",
        overlay: "overlay card__overlay",
    },
    list: {
        //item: "card gallery-item",
        content: "card__content",
        image_outer: "card__image_outer",
        image: "card__image",
        overlay: "overlay card__overlay",
    },
};
