import React from "react";

import Layout from "./Layout";
import LearnMoreButton from "./LearnMoreButton";

import Button from "components/Button";
import Icon from "components/Icon";

import config from "./config";
//import PropTypes from 'prop-types';

export default function AllowAccessNoPermissionsApi(props) {
    const { onRetry, onDeny } = props;
    const { safariHelpUrl: url } = config;

    return (
        <Layout>
            <div>
                <div className={"allow-media"} />
            </div>
            <div className={"text-center type-default"}>
                Please make sure that the devices are connected.
            </div>
            <div className={"cols cols-center gap-xs"}>
                <LearnMoreButton url={url} />
                <Button
                    onClick={onRetry}
                    startIcon={<Icon>mui-refresh</Icon>}
                    color={"default"}
                >
                    Check again
                </Button>
            </div>
            <div className={"cols cols-center"}>
                <div onClick={onDeny} className={"link"}>
                    Join without audio/video
                </div>
            </div>
        </Layout>
    );
}

AllowAccessNoPermissionsApi.propTypes = {};
AllowAccessNoPermissionsApi.defaultProps = {};
