import React from "react";
//import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "components/Icon";

export default function MenuItem({ onClick, primaryText, icon, ...other }) {

    return (
        <ListItem button={!!onClick} onClick={onClick} {...other}>
            <ListItemIcon>
                <Icon fontSize={"medium"}>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={primaryText} />
        </ListItem>
    );
}

MenuItem.propTypes = {};
