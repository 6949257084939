import React from "react";

import DailyCalendar from "./DailyCalendar";
import { useSessionSearch } from "joynt/components/SessionBrowser/hooks";
import SessionItem from "joynt/components/SessionTrack/SessionItem";
import DaySessions from "joynt/components/SessionBrowser/DaySessions";
import SpaceAvatar from "joynt/components/SpaceAvatar";
import { useNodeData } from "joynt/hooks";
import { startOfDay } from "joynt/components/DateTimePicker/util";
import { useUiFlag } from "state/hooks";

export default function DailyCalendarContainer(props) {
    const { id, workspace } = props;
    const { name: roomName } = useNodeData(id);
    const { name: workspaceName } = useNodeData(workspace);

    const now = startOfDay(new Date()).getTime() / 1000;

    const [day, setDay] = useUiFlag("scheduleSelectedDay", now);

    const { list } = useSessionSearch({
        id,
        day,
        mode: "day",
    });

    return (
        <DailyCalendar
            {...props}
            workspaceHeader={
                id !== workspace && (
                    <>
                        <SpaceAvatar id={workspace} size={"xs"} />{" "}
                        <div>{workspaceName}</div>
                    </>
                )
            }
            onChange={(newDate) => {
                setDay(newDate / 1000);
            }}
            day={day}
            name={roomName}
            avatar={<SpaceAvatar id={id} size={"xs"} />}
        >
            <DaySessions day={day} list={list} Component={SessionItem} />
        </DailyCalendar>
    );
}
