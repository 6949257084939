import React from 'react';
import {useClick} from "state/hooks";
import cn from "classnames";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function Tab(props) {
    const { isSelected, label, icon } = props;
    const click = useClick(props);
    return (
        <div
            className={cn("box-tab", { "is-selected": isSelected })}
            onClick={click}
        >
            {icon && <Icon>{icon}</Icon>}
            <div>{label}</div>
        </div>
    );
}

Tab.propTypes = {};

Tab.defaultProps = {
    isSelected: false,
};

export default Tab;
