import React, { useCallback } from "react";
import { Slide, TutorialSlider } from "joynt/components/TutorialSlider";
import { Topic, TutorialSection } from "joynt/components/TutorialSection";
import { useCurrentIdentity } from "joynt/hooks";
import { useIdentity } from "joynt/hooks/participants";
import { PromoTile } from "joynt/components/PromoTile";
import { useRouteParam } from "state/hooks";
import { ROUTE_PAGE } from "joynt/config";
//import PropTypes from "prop-types";

import "./style.css";

export default function HomeView(props) {
    const { id } = props;

    const identity = useCurrentIdentity(id);
    const { name: identityName } = useIdentity(identity);
    const [firstname] = identityName ? identityName.split(" ") : [];
    const [, setPage] = useRouteParam(ROUTE_PAGE);
    const create = useCallback(() => setPage("create-workspace"), [setPage]);

    return (
        <div className={"home-content rows gap-sm"}>
            <TutorialSlider title={`Welcome ${firstname}!`}>
                <Slide
                    title={"Communicate with members"}
                    image={
                        "https://cms.zuu.tools/thumb?file=joynt-website/user/app/tutorial-slider-1.svg"
                    }
                >
                    <p>
                        Create Chat channel straight from the sidebar by
                        clicking „plus” icon.
                    </p>
                </Slide>
            </TutorialSlider>
            <div className={"rows gap-sm"}>
                <div className={"rows gap-sm"}>
                    <TutorialSection
                        title={"Build your community"}
                        variant={"secondary"}
                        sideContent={
                            <PromoTile
                                label={
                                    "Start building your community / organisation / project"
                                }
                                variant={"secondary"}
                                buttonLabel={"Create space"}
                                className={"tutorial-section__cta"}
                                onButtonClick={create}
                            />
                        }
                    >
                        <Topic title={"Creating community / organisation"}>
                            <p>
                                A Community Space is made of chat and post
                                channels, rooms for distinct topics or private
                                spaces for selected community members. It can
                                also host video sessions or even full blown
                                webinars with external invite lists, depending
                                on what is needed.
                            </p>
                            <p>
                                A single Community Space can be a mix of private
                                and public locations, for example an admin-only
                                backoffice room, alongside public event space
                                accessible to everyone.
                            </p>
                        </Topic>
                        <Topic title={"Community workspace"}>
                            <p>
                                Use the Create button in the upper right corner.
                                It will take through the steps to create your
                                first Community space. You can later add
                                channels to your space to make it suit your
                                exact needs.
                            </p>
                            <p>
                                When ready, invite people through a mailing
                                list, or by adding existing Joynt users to your
                                community.
                            </p>
                        </Topic>
                        <Topic title={"Networking / collaboration"}>
                            <p>
                                You can network with others by searching for
                                people through a set of criteria, matching
                                wanted and offered skills or services offered.
                            </p>
                            <p>
                                You can booking meetings via common calendars.
                                Dedicated networking sections can be created, be
                                it demo tables, speed dating sessions or topic
                                rooms that are part of the main community space.
                            </p>
                            <p>
                                Tasks and kanban boards let you collaborate on
                                actual stuff, enabling for a functional
                                community space, not just empty talk.
                            </p>
                        </Topic>
                        <Topic title={"Channel types"}>
                            <p>
                                Every community space can have many flexible
                                channel types, allowing you to configure your
                                spaces in a bespoke manner.
                            </p>
                            <p>
                                Channels can be chats, feeds, Q&A’s, Rooms,
                                Sessions, Wiki’s, Whiteboards, Networking
                                tables. What’s more, you can use them together -
                                create a Room with a whiteboard, a chat channel
                                and networking tables inside.
                            </p>
                            <p>
                                Joynt will map this structure in the navigation
                                circles on the left of the purple sidebar.
                            </p>
                        </Topic>
                    </TutorialSection>
                </div>
                <div className={"rows gap-sm"}>
                    <TutorialSection
                        title={"Events and sessions"}
                        variant={"primary"}
                        sideContent={
                            <PromoTile
                                label={
                                    "Start building your event space with sessions and other features!"
                                }
                                variant={"primary"}
                                buttonLabel={"Create event"}
                                className={"tutorial-section__cta"}
                                onButtonClick={create}
                            />
                        }
                    >
                        <Topic title={"Joynt events"}>
                            <p>
                                Joynt events happen in event workspaces that are
                                permanent. Nothing ever gets lost, contacts and
                                knowledge stay alive, with everything neatly
                                kept in a structured space.
                            </p>
                            <p>
                                Everything from a simple one-off meeting,
                                through a multi-session webinar to full blown
                                multi-track conference can be held in Joynt with
                                ease, with different access levels for different
                                users.
                            </p>
                        </Topic>
                        <Topic title={"Event networking and breakout sessions"}>
                            <p>
                                Every event, session or meeting can be equipped
                                with custom networking spaces, breakout rooms,
                                demo tables and mingle areas, all with regulated
                                access.
                            </p>
                            <p>
                                These areas can be customised, and tailored to
                                exact needs of every event imaginable.
                            </p>
                        </Topic>
                        <Topic title={"Channel types"}>
                            <p>
                                Every event space can have many flexible channel
                                types, allowing you to configure your spaces in
                                a bespoke manner.
                            </p>
                            <p>
                                Channels can be chats, feeds, Q&A’s, Rooms,
                                Sessions, Wiki’s, Whiteboards, Networking
                                tables.
                            </p>
                            <p>
                                What’s more, you can use them together - create
                                a Room with a whiteboard, a chat channel and
                                networking tables inside.
                            </p>
                        </Topic>
                    </TutorialSection>
                </div>
            </div>
        </div>
    );
}

HomeView.defaultProps = {};
HomeView.propTypes = {};
