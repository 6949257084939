import React from "react";
//import PropTypes from 'prop-types';

import { useSelector } from "react-redux";

import Icon from "components/Icon";
import Popover from "components/Popover";

import RadioButtonGroup, { RadioButton } from "playground/ui/RadioButtonGroup";
import SelectList, { SelectListItem } from "playground/ui/SelectList";

function selectSchemaData(store, id) {
    const data = store?.data?.["schema.data"]?.[id];
    if (!data) return null;
    const options = data.values.map((v) => {
        return store?.data?.["schema.data.value"]?.[v];
    });
    return options;
}

const actionIcons = {
    link: "mui-link",
    call: "mui-phone",
    email: "mui-email",
    media: "mui-photo",
    sitemap: "mui-account_tree",
};

const mainOptions = ["link", "call", "email"];

export function LinkAction(props) {
    const { value, onChange, variant } = props;
    const items = useSelector((store) =>
        selectSchemaData(store, "data.buttonActions")
    );

    function optionLabel(option) {
        return items.find((i) => i.value === option)?.label;
    }
    const rest = items.filter((item) => {
        return !mainOptions.includes(item.value);
    });
    const otherOptionSelected = !mainOptions.includes(value);
    return (
        <RadioButtonGroup variant={variant}>
            {otherOptionSelected && (
                <RadioButton isActive={true}>{optionLabel(value)}</RadioButton>
            )}
            {!otherOptionSelected &&
                mainOptions.map((o) => (
                    <RadioButton
                        key={o}
                        onClick={() => onChange(o)}
                        icon={actionIcons[o] && <Icon>{actionIcons[o]}</Icon>}
                        isActive={value === o}
                    >
                        {optionLabel(o)}
                    </RadioButton>
                ))}
            {otherOptionSelected && (
                <RadioButton
                    onClick={() => onChange("link")}
                    icon={<Icon>mui-close</Icon>}
                />
            )}
            <Popover
                trigger={({ onClick }) => (
                    <RadioButton
                        onClick={onClick}
                        icon={<Icon>mui-more_vert</Icon>}
                    />
                )}
                content={({ onClose }) => (
                    <SelectList onClick={onClose}>
                        {rest.map((item) => (
                            <SelectListItem
                                key={item.value}
                                onClick={() => onChange(item.value)}
                            >
                                {item.label}
                            </SelectListItem>
                        ))}
                    </SelectList>
                )}
            />
        </RadioButtonGroup>
    );
}

LinkAction.propTypes = {};

LinkAction.defaultProps = {};

export default LinkAction;
