import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import AggregateRoot from "containers/AggregateRoot";

import HeaderWelcome from "./HeaderWelcome";
import HeaderLogo from "./HeaderLogo";
import HeaderImage from "./HeaderImage";

import "./style.css";

export function WorkspaceHeaderMobile(props) {
    const { id, children, className } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id}>
            <div
                className={cn(
                    "workspace-header workspace-header-mobile rows",
                    className
                )}
            >
                <div className={"workspace-header-mobile__cover"}>
                    <HeaderImage id={id}>{children}</HeaderImage>
                    <HeaderLogo id={id} />
                </div>
                <HeaderWelcome id={id} />
            </div>
        </AggregateRoot>
    );
}

WorkspaceHeaderMobile.propTypes = {
    id: PropTypes.string,
};
WorkspaceHeaderMobile.defaultProps = {};

export default WorkspaceHeaderMobile;
