import React from "react";
//import PropTypes from 'prop-types';

import useMeeting from "joynt/meeting/hooks/useMeeting";

import Viewport from "./Viewport";
import SessionWindow from "./SessionWindow";

import "./style.css";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import { useNodeData } from "joynt/hooks";
import { FLAG_SESSION_FLOATING, NODE_TYPE_SPACE } from "joynt/config";
import { useUiFlag } from "state/hooks";
import { useSessionLayout } from "joynt/components/SessionLayout";

export function FloatingSession(props) {
    const { children } = props;
    const { session } = useMeeting();
    const [{ joined, loading }] = useConnectionState(session);
    const { subtype } = useNodeData(session);
    const isSpace = subtype === NODE_TYPE_SPACE;
    const { expanded } = useSessionLayout();
    const isMinimized = !expanded;
    const show = isMinimized && session && (joined || (loading && isSpace));

    return (
        <Viewport>
            {show && <SessionWindow id={session} isPending={loading} />}
            {children}
        </Viewport>
    );
}

FloatingSession.propTypes = {};
FloatingSession.defaultProps = {};

export default FloatingSession;
