import React from 'react';
//import PropTypes from 'prop-types';

export default class Default extends React.PureComponent {

    render() {
        const { fieldType, label } = this.props;
        return (<div>Default {fieldType} {label}</div>);
    }

}

Default.propTypes = {};