import {useEdges} from "joynt/hooks";
import {useCallback, useMemo} from "react";
import {useApiActions, useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {entityDeleteCallback} from "state/actions/delete";
import {useRouter} from "./useRouter";
import {useRouteNodes} from "./useRouteNodes";

export function useDeleteNode(id) {

    const {parent} = useEdges(id);
    const routeNodes = useRouteNodes();
    const routeNodesIds = useMemo(() => {
        return Object.values(routeNodes);
    }, [routeNodes]);
    const [edit, setEdit] = useRouteParam(ROUTE_PAGE);
    const {onLink} = useRouter();

    const {
        onDelete
    } = useApiActions({
        onDelete: entityDeleteCallback
    });

    return useCallback(() => {
        const cb = () => {
            /** Exit node edit **/
            if (edit === `edit:${id}`) setEdit(null);

            /** Link to parent if node id is present in route nodes **/
            if (routeNodesIds.indexOf(id) > -1) onLink(parent);
        }
        onDelete(cb, 'db.nodes', id, null, `joynt/nodes/:id`);
    }, [
        id,
        onDelete,
        onLink,
        parent,
        edit,
        setEdit,
        routeNodesIds
    ]);
}