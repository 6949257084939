import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";

export function SessionCard(props) {
    const {
        name,
        image,
        date,
        text,
        participants,
        actions,
        onClick,
        onMouseEnter,
        onMouseLeave,
        addon,
        expand,
    } = props;
    const [show, setShow] = useState(false);

    return (
        <div
            className={cn("session-card", {
                "session-card--show": expand,
            })}
            onMouseEnter={onMouseEnter ? onMouseEnter : () => setShow(true)}
            onMouseLeave={onMouseLeave ? onMouseLeave : () => setShow(false)}
            onClick={onClick}
        >
            <div className={"session-card__image"}>{image}</div>
            <div className={"session-card__content"}>
                <div className={"session-card__info"}>
                    {date && <div className={"session-card__date"}>{date}</div>}
                    {addon && (
                        <div className={"session-card__addon"}>{addon}</div>
                    )}
                </div>
                <div className={"session-card__name"}>{name}</div>
            </div>
            <div className={"session-card__full-content"}>
                {text && <div className={"session-card__text"}>{text}</div>}
                {participants && (
                    <div className={"session-card__participants"}>
                        {participants}
                    </div>
                )}
            </div>
            {actions && (
                <div className={"session-card__actions"}>{actions}</div>
            )}
        </div>
    );
}

SessionCard.propTypes = {
    name: PropTypes.string,
    image: PropTypes.element,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    maxParticipants: PropTypes.number,
    confirmedParticipants: PropTypes.number,
    text: PropTypes.string,
};
SessionCard.defaultProps = {};

export default SessionCard;
