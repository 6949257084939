import React from "react";
//import PropTypes from 'prop-types';
import LockSlider from "cms/components/LockSlider";
import cn from "classnames";

const sliderClasses = "owl-carousel owl-theme l-slider";

function sliderOptions(classes) {
    return {
        items: classes.slider_items * 1 || 1,
        margin: classes.slider_margin * 1 || 0,
        autoHeight: !!classes.slider_auto_height,
    };
}

function SliderPreview(props) {
    const { className, options, ...other } = props;
    return (
        <div className={cn(className, "fake-owl")} {...other}>
            <div className={"owl-stage-outer"}>
                <div
                    className={"owl-stage"}
                    style={{ display: "flex", gap: `${options.margin}px` }}
                >
                    {props.children}
                </div>
            </div>
            <div className="owl-nav">
                <div className="owl-prev"></div>
                <div className="owl-next"></div>
            </div>
            {/*<h4>Slider</h4>*/}
        </div>
    );
}

export default class Slider extends React.PureComponent {
    render() {
        const { className, classes, items, ...other } = this.props;
        return (
            <SliderPreview
                items={items}
                className={cn(sliderClasses, className)}
                options={sliderOptions(classes)}
                {...other}
            >
                {this.props.children}
            </SliderPreview>
        );
    }
}

Slider.propTypes = {};
