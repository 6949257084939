import React, {useState} from "react";
import PropTypes from "prop-types";
import RaisedHand from "./RaisedHand";

import { IdentityAvatarContainer as IdentityAvatar } from "joynt/components/Avatar";
import { useRaisedHandList } from "joynt/meeting/hooks/useRaisedHandList";
import { useRTMControls } from "joynt/meeting/agora-ng/hooks";
import { ResponsivePopover } from "components/Popover";
import Button from "components/Button";
import {useIdentity} from "joynt/hooks/participants";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {canManageStage} from "joynt/config/roles";

function Avatar(props) {
    const { id, onClick } = props;

    return <div onClick={onClick ? (e) => onClick(e, id) : null}>
        <IdentityAvatar id={id} />
    </div>
}

function Popover(props) {
    const { id, anchorEl, onClose, onAccept, onDeny } = props;
    const { name } = useIdentity(id);

    return <ResponsivePopover
        open={!!id}
        anchorEl={anchorEl}
        onClose={onClose}
    >
        <div className={"pad-md rows gap-xs"}>
            <div className={"cols gap-xs cols-middle"}>
                <div>
                    <IdentityAvatar id={id} />
                </div>
                <div>
                    {name}
                </div>
            </div>
            <div className={"cols gap-xs"} onClick={onClose}>
                <Button onClick={() => onDeny(id)}>Deny</Button>
                <Button onClick={() => onAccept(id)}>Accept</Button>
            </div>
        </div>
    </ResponsivePopover>
}

export function RaisedHandContainer(props) {
    const { id } = props;
    const participants = useRaisedHandList(id);
    const { inviteOnStage, removeFromStage } = useRTMControls();
    const { role } = useMeeting();
    const canManage = canManageStage.includes(role);

    const [show, setShow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const close = () => {
        setShow(null);
    }

    const showInfo = (evt, identity) => {
        setAnchorEl(evt.currentTarget);
        setShow(identity);
    }

    return (<>
        <RaisedHand {...props} count={participants.length}>
            {participants.map((p) => (
                <Avatar
                    key={p}
                    id={p}
                    onClick={canManage ? showInfo : null}
                />
            ))}
        </RaisedHand>
        {canManage && <Popover
            id={show}
            anchorEl={anchorEl}
            onClose={close}
            onAccept={inviteOnStage}
            onDeny={removeFromStage}
        />}
    </>);
}

RaisedHandContainer.propTypes = {
    id: PropTypes.string,
};
RaisedHandContainer.defaultProps = {};

export default RaisedHandContainer;
