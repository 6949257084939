import React from "react";
import cn from "classnames";
import Icon from "components/Icon";
import PropTypes from "prop-types";

export default function FormSection(props) {
    const { name, icon, description, action, children } = props;
    return (
        <div
            className={cn("form-section", {
                [`variant-${props.variant}`]: props.variant !== "default",
            })}
        >
            <div className={"form-section-header"}>
                <div className={"form-section-heading"}>
                    <div className={"form-section-title"}>
                        {icon && (
                            <div className={"form-section-icon"}>
                                <Icon>{icon}</Icon>
                            </div>
                        )}
                        <div className={"form-section-name"}>{name}</div>
                    </div>
                    {description && (
                        <div className={"form-section-description"}>
                            {description}
                        </div>
                    )}
                </div>
                {action && (
                    <div className={"form-section-action"}>{action}</div>
                )}
            </div>
            {React.Children.count(children) > 0 && <div>{children}</div>}
        </div>
    );
}

FormSection.propTypes = {
    variant: PropTypes.oneOf(["contained", "default"]),
};
FormSection.defaultProps = {
    variant: "contained",
};
