import React from 'react';
import SessionSidebar from "joynt/sidebar/SessionSidebar";

//import PropTypes from 'prop-types';

export function Settings(props) {

    const {id} = props;

    return (
        <SessionSidebar id={id} />
    );

}

Settings.propTypes = {};
Settings.defaultProps = {};

export default Settings;
