import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./style.css";
import Html from "components/Html";
import DateDiff from "components/DateDiff";
import Preloader from "components/Preloader";
import PostProperties from "joynt/components/Post/PostProperties";
import useScreenSize from "util/hooks/useScreenSize";

function truncate(html, len) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.textContent || "";
    if (!len) return text;
    return text.split("").slice(0, len).join("");
}

const PostCard = React.forwardRef((props, ref) => {
    const { isMobile } = useScreenSize();

    const {
        title,
        content,
        postedAt,
        postedIn,
        author,
        tags,
        image,
        properties,
        maxContentLength,
        hasNotifications,
        onClick,
        isPending,
    } = props;

    return (
        <div
            className={cn("post-card", {
                "is-new": hasNotifications,
            })}
            onClick={onClick}
            ref={ref}
        >
            <div className={"post-card__layout"}>
                <div className={cn("post-card__image", { "is-empty": !image })}>
                    {image ? <img src={image} alt={title} /> : null}
                </div>
                <div className={"post-card__content"}>
                    <div className={"post-card__title"}>
                        <Html value={title} sanitizationMode={"plain-text"} />
                    </div>
                    {content && (
                        <div className={"post-card__text post-text clamp-6"}>
                            <Html value={truncate(content, maxContentLength)} />
                        </div>
                    )}
                    {tags && <div className={"post-card__tags"}>{tags}</div>}
                    <div className={"post-card__footer"}>
                        {author}
                        {postedIn && (
                            <div className={"post-card__parent"}>
                                {postedIn}
                            </div>
                        )}
                    </div>
                </div>
                <div className={"post-card__details"}>
                    <div className={"post-card__posted_at"}>
                        <DateDiff value={postedAt} />
                    </div>
                    {properties && (
                        <PostProperties
                            properties={properties}
                            layout={isMobile ? "horizontal" : "vertical"}
                        />
                    )}
                </div>
            </div>
            <Preloader visible={isPending} />
        </div>
    );
});

PostCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    postedAt: PropTypes.number,
    image: PropTypes.string,
    onClick: PropTypes.func,
    author: PropTypes.element,
    tags: PropTypes.element,
    postedIn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    properties: PropTypes.object,
    maxContentLength: PropTypes.number,
    hasNotifications: PropTypes.bool,
    isPending: PropTypes.bool,
};
PostCard.defaultProps = {
    author: null,
    maxContentLength: 320,
    hasNotifications: false,
    isPending: false,
};

export default PostCard;
