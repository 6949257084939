import React, {useCallback} from 'react';
import MenuItem from "components/context-menu/MenuItem";
import {useApiActions} from "state/hooks";
import {entityDelete} from "state/actions/delete";
//import PropTypes from 'prop-types';

export default function MessageContextMenu(props) {

    const {id} = props;

    const {onDelete} = useApiActions({onDelete: entityDelete});

    const remove = useCallback(() => {
        onDelete('db.messages', id, null, `joynt/messages/:id`);
    }, [onDelete, id]);

    return <>
        <MenuItem
            icon={'mui-delete'}
            primaryText={'Delete message'}
            onClick={remove}
        />
    </>;

}

MessageContextMenu.propTypes = {};
MessageContextMenu.defaultProps = {};
