import React, { useCallback } from "react";
import LayoutSectionsList from "playground/cms/views/Pages/PageForm/PageSectionsList/LayoutSectionsList";

//import PropTypes from 'prop-types';

export function SectionsList(props) {
    const { state, setState } = props;
    const { pageType, pageId } = state;

    const callbackFn = useCallback(
        (result) => {
            console.log("DROPPED", result);
            setState({
                sections: false,
                outline: false,
                section: result.id,
                focusType: "cms.sections",
                focusId: result.id,
                edit: true,
            });
        },
        [setState]
    );

    const onClick = (id, evt) => {
        setState({
            focusType: "cms.sections",
            focusId: id,
            section: id,
            outline: evt.shiftKey,
            scroll: true,
        });
    };

    return (
        <LayoutSectionsList
            type={pageType}
            id={pageId}
            callbackFn={callbackFn}
            selected={state.section}
            onClick={onClick}
            onEditStyle={(layout) =>
                setState({ focusType: "cms.layouts", focusId: layout })
            }
        />
    );
}

SectionsList.propTypes = {};

SectionsList.defaultProps = {};

export default SectionsList;
