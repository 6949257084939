import React from 'react';
import PropTypes from 'prop-types';

import {types as nodeTypes} from "joynt/config/types";
import Icon from "components/Icon";

export function NodeTypeIcon(props) {

    const {type, isRestricted} = props;

    let icon = nodeTypes[type]?.icon;
    if (isRestricted) icon = "mui-lock";

    return icon
        ? <Icon>{icon}</Icon>
        : null;

}

NodeTypeIcon.propTypes = {
    type: PropTypes.string,
    isRestricted: PropTypes.bool
};

NodeTypeIcon.defaultProps = {
    isRestricted: false
};

export default NodeTypeIcon;
