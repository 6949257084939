import React from "react";
//import PropTypes from 'prop-types';

import { FormField } from "./Features";

export function FormSelect(props) {
    const { children } = props;
    return (
        <>
            {children}
            <FormField />
            <div>select options (items field)</div>
        </>
    );
}

FormSelect.propTypes = {};

FormSelect.defaultProps = {};

export default FormSelect;
