import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import ContentEditable from "components/ContentEditable";
import Html from "components/Html";

import "./style.css";
import Icon from "components/Icon";

function RenderPostTitle(props) {
    const { className, value, label, error, helperText, onChange } = props;

    return (
        <>
            <div className={"post-title__edit"}>
                <Icon className={"post-title__edit-icon"}>mui-edit</Icon>
                <ContentEditable
                    className={cn(className, "post-title__edit-field")}
                    value={value}
                    label={label}
                    onChange={onChange}
                />
            </div>
            <div
                className={cn("post-title__helper-text", {
                    "is-invalid": error,
                })}
            >
                {helperText}
            </div>
        </>
    );
}

function EditablePostTitle(props) {
    const { placeholder, readOnly, id, className } = props;

    return (
        <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
            <Field
                id={"name"}
                validate={true}
                label={placeholder}
                className={className}
                readOnly={readOnly}
            >
                {RenderPostTitle}
            </Field>
        </AggregateRoot>
    );
}

export default function PostTitle(props) {
    const { readOnly, className, children } = props;
    if (!readOnly) return <EditablePostTitle {...props} />;
    return (
        <Html
            className={className}
            value={children}
            sanitizationMode={"plain-text"}
        />
    );
}

PostTitle.propTypes = {
    placeholder: PropTypes.string,
};
PostTitle.defaultProps = {
    placeholder: "Add post title",
};
