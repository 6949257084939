import React, {useCallback, useMemo, useState} from 'react';
import MuiPopper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import cn from "classnames";
//import PropTypes from 'prop-types';

export function usePopper(options = {}) {
    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const {event} = options;

    const onShow = useCallback(evt => {
        evt.stopPropagation();
        setAnchor(evt.currentTarget);
        setOpen(true);
    }, [setOpen, setAnchor]);

    const onHide = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onToggle = useCallback(evt => {
        if (open) return onHide();
        return onShow(evt);
    }, [open, onShow, onHide]);

    return useMemo(() => ({
        handlers: event === 'hover' ? {
            onMouseEnter: onShow,
            onMouseLeave: onHide
        } : {
            onClick: onToggle
        },
        state: {
            open,
            anchor
        },
        onShow,
        onHide
    }), [event, onToggle, onShow, onHide, open, anchor]);
}

export default function Popper(props) {
    const {open, onHide, anchor, children, ...other} = props;

    return <ClickAwayListener
        onClickAway={onHide}
        mouseEvent={open && onHide ? 'onMouseDown' : false}
    >
        <MuiPopper
            open={open}
            anchorEl={anchor}
            transition
            {...other}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <div className={cn('popper pad-sm')}>
                        {children}
                    </div>
                </Fade>
            )}
        </MuiPopper>
    </ClickAwayListener>;
}

Popper.propTypes = {};
Popper.defaultProps = {};
