import {useCurrentIdentity} from "joynt/hooks";
import {useRouteParam} from "state/hooks";
import {ROUTE_IDENTITY_EDIT} from "joynt/config";
import {useCallback, useMemo} from "react";

export function useUserSettings(workspace) {
    const identity = useCurrentIdentity(workspace);
    const [id,] = useRouteParam(ROUTE_IDENTITY_EDIT);
    const [,showUtilityNav] = useRouteParam('side');
    const toggle = useCallback(() => {
        //setId(id ? null : identity);
        showUtilityNav('utility');
    }, [showUtilityNav]);
    return useMemo(() => ({
        id,
        identity,
        onToggle: toggle
    }), [id, identity, toggle])
}