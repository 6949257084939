import React from 'react';
import {connect} from 'react-redux';
import {push} from "state/actions/modal";
import {createCallback} from "state/actions/create";
import {get} from 'lodash';
import uuid from "uuid/v4";
import itemTypes from 'config/item-types';

const mapStateToProps = (store, props) => {
    const schemaItemType = get(props, [
        'schema',
        'properties',
        'item_type'
    ]);

    /** Resolve namespace from parent entity type **/
    const [ns] = props.entityType.split('.');

    const type = props.collectionType
        || [ns, props.id].join('.');

    const itemType = itemTypes[schemaItemType]
        || itemTypes[[props.entityType, props.id].join('/')]
        || props.collectionType
        || [ns, props.id].join('.');

    return {
        type,
        itemType
    }
};

const mapDispatchToProps = {
    onEdit: push,
    onCreate: createCallback
};

class CollectionContainer extends React.PureComponent {

    edit = (id) => {
        const { entityType, entityId, type } = this.props;
        this.props.onEdit([entityType, entityId, type, id].join('|'));
    };

    create = () => {
        const { onCreate, create, onChange, value, itemType } = this.props;
        let id = uuid();
        let newValue = value ? value.slice() : [];
        newValue.push(id);
        let item = create ? create({id}) : {id, active: true};
        onCreate(()=>onChange(newValue), itemType, item);
    };

    remove = (type, id, list) => {
        console.log('CollectionContainer.remove', type, id, list);
        const { onChange, value } = this.props;
        let newValue = value.filter(item=>item!==id);
        onChange(newValue);
    };

    sort = (order) => {
        const { onChange, onSortEnd } = this.props;
        if (onSortEnd) onSortEnd(order);
        onChange(order);
    };

    render() {
        const { readOnly } = this.props;
        const readOnlyProps = !readOnly
            ? {
                onEdit: this.edit,
                onCreate: this.create,
                onDelete: this.remove,
                onSortEnd: this.sort
            } : {
                onSortEnd: null,
                onDelete: null,
                onCreate: null
            };
        return this.props.children({
            ...this.props,
            type: this.props.itemType,
            items: this.props.value,
            ...readOnlyProps
        });
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionContainer);