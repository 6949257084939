import React from 'react';
import PropTypes from 'prop-types';
import Entity from "containers/Entity";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {SortableIterator} from "components/dnd";
import List from "containers/List";
import Fetch from "containers/Fetch";

const SimpleListItem = React.forwardRef((props, ref) => {
    const { type, id, onClick } = props;
    return <Entity type={type} id={id}>
        {({data})=>{
            const click = (evt) => {
                onClick(data.service_id || data.name, data.type)
            };
            return <ListItem button ref={ref} onClick={click}>
                <ListItemText primary={data.name} secondary={data.type} />
            </ListItem>
        }}
    </Entity>;
});

const ListItemIterator = SortableIterator(SimpleListItem);

export default class ServiceList extends React.PureComponent {

    render() {
        const { enable, onClick } = this.props;
        return (<Fetch
            type={'console.services'}
            list={'console.services'}
            url={'console/services?type=website,db,apartments'}
            enable={enable}
        >
            <List
                onClick={onClick}
                type={'console.services'}
                list={'console.services'}
                Iterator={ListItemIterator}
            />
        </Fetch>);
    }

}

ServiceList.propTypes = {
    onClick: PropTypes.func
};