import React from 'react';
import Schedule from "joynt/components/Schedule";
import SpaceHeader from "joynt/components/SpaceHeader";

//import PropTypes from 'prop-types';

export function WorkspaceSchedule(props) {

    const {id} = props;

    return (<div className={"rows overflow-hide"}>
        <SpaceHeader id={id} />
        <Schedule {...props} />
    </div>);

}

WorkspaceSchedule.propTypes = {};
WorkspaceSchedule.defaultProps = {};

export default WorkspaceSchedule;
