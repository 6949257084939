import { get } from "lodash";
import { selectAll, selectEntity } from "state/selectors/data";
const nullArr = [];

export const selectList = (store, resource, id) => {
    //console.log(`lists/listSelector ${resource} ${id}`);
    if (!resource) return nullArr;
    if (!id) {
        let path = resource.split("/");
        resource = path[0];
        id = path[1];
    }
    return get(store.lists, [resource, id], nullArr);
};

export const selectFilteredListData = (store, type, filter) => {
    const all = selectAll(store, type);
    return all.filter(filter).map((item) => item.id);
};

export function selectGroups(store, items, config, useDraftState) {
    const { type, groups } = config;
    const qualified = [];
    const data = store.data[type];
    return groups.map((group) => {
        const qualifiedGroup = {
            id: group.id,
            label: group.label,
            items: [],
        };
        qualifiedGroup.items = items.reduce((value, item) => {
            if (!data[item] && !useDraftState) return value;
            if (qualified.indexOf(item) > -1) return value;
            let itemData = !useDraftState
                ? data[item]
                : selectEntity(store, type, item);
            if (!group.qualifier || group.qualifier(store, itemData)) {
                value.push(group.valueMap ? group.valueMap(itemData) : item);
                qualified.push(item);
                return value;
            }
            return value;
        }, []);
        return qualifiedGroup;
    });
}
