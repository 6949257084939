import React from "react";
import { usePending, useRouteParam } from "state/hooks";
import { ROUTE_ID } from "joynt/config";
import Page from "joynt/components/ContentEditor/Page";
import useFetch from "state/hooks/useFetch";
import Preloader from "components/Preloader";
import { useNodeData } from "joynt/hooks";

//import PropTypes from 'prop-types';

export function PublicPage(props) {
    const [id] = useRouteParam(ROUTE_ID);
    const pending = usePending(["bootstrap", id]);
    const { id: stateId } = useNodeData(id);

    useFetch({
        type: "db.nodes",
        id,
        url: "v2/joynt/nodes/:id",
    });

    //return <Feed id={id} />;
    return (
        <>
            {stateId && <Page id={id} />}
            <Preloader visible={pending} />
        </>
    );
}

PublicPage.propTypes = {};
PublicPage.defaultProps = {};

export default PublicPage;
