import { getClosest } from "util/dom";

export function getClosestOrSelf(el, selector) {
    return el.matches(selector) ? el : getClosest(el, selector);
}

export function findRootElement() {
    const shadowRoot = document.getElementById("theme-shadow-root");
    const iframeRoot = document.getElementById("theme-iframe");
    return shadowRoot || iframeRoot;
}

export function findCanvasRoot() {
    const shadowRoot = document.getElementById("theme-shadow-root");
    const iframeRoot = document.getElementById("theme-iframe");
    const root = shadowRoot
        ? shadowRoot.shadowRoot
        : iframeRoot?.contentWindow?.document || document;
    return root;
}

export function getElements(evt) {
    const root = findCanvasRoot();
    if (!root) {
        console.log("no root");
        return [];
    }
    const x = evt.clientX;
    const y = evt.clientY;
    const elements = root.elementsFromPoint(x, y);
    return elements.filter((e) => {
        const inspect = e.getAttribute("data-inspect");
        return !!inspect;
    });
}

export const captureClickEvent = (evt, selectionMode) => {
    const el = evt.target;
    const inspectable = getClosestOrSelf(el, "[data-inspect]");
    if (!inspectable) return {};
    const listRoot = getClosestOrSelf(el, "[data-inspect*='@']");
    const inspect = inspectable.getAttribute("data-inspect");
    const sRoot = getClosestOrSelf(el, "[data-s]");
    const s = sRoot ? sRoot.getAttribute("data-s") : null;
    const section = getClosestOrSelf(el, '[data-type="section"]');
    const sectionPath = section.getAttribute("data-inspect");
    const sectionId = sectionPath.split("/")[1];
    const rewire = getClosestOrSelf(el, "[data-rewire]");
    const rewirePath = rewire?.getAttribute("data-rewire");
    const itemEl = getClosestOrSelf(el, "[data-item]");
    const item = itemEl?.getAttribute("data-item");
    const itemPathEl = getClosestOrSelf(el, "[data-path]");
    const itemPath =
        item && itemPathEl ? itemPathEl.getAttribute("data-path") : null;
    const iteratorItem = listRoot?.getAttribute("data-inspect");
    const pathEl = getClosestOrSelf(el, "[data-path]");
    const path = pathEl?.getAttribute("data-path");
    const root = findRootElement();

    const siblings = getElements(evt);

    const paths = siblings
        .map((e) => ({
            type: e.getAttribute("data-type"),
            component: e.getAttribute("data-inspect"),
            content: e.getAttribute("data-path"),
            query: e.getAttribute("data-query"),
            itemType: e.getAttribute("data-item-type"),
            section: sectionId,
            style: s,
        }))
        .map((p) => {
            if (!p.component.includes("@")) return p;
            const [item, component] = p.component.split("@");
            return { ...p, item, component };
        });

    const iterators = paths.filter((p) => ["list"].includes(p.type));
    const contentPaths = paths.filter((p) =>
        ["section", "list", "text", "button"].includes(p.type)
    );

    const scrollY = root.contentWindow?.scrollY;

    const contentMode = ["content", "preview"].includes(selectionMode);

    const inspectTarget = contentMode ? contentPaths[0]?.component : inspect;

    const sTarget = contentMode ? null : s;

    return {
        item: iteratorItem ? iteratorItem.split("@")[0] : null,
        iteratorRoot: iterators[0]?.component || false,
        list: iterators[0],
        paths,
        section: sectionId,
        sectionPath,
        component: inspectTarget,
        style: sTarget,
        rewirePath,
        itemPath,
        path,
        x: evt.clientX,
        y: evt.clientY + scrollY,
    };
};

export function calculateFramePosition(el, root) {
    const rect = el.getBoundingClientRect();
    const rootRect = root.getBoundingClientRect();
    return {
        x: rect.x, // + rootRect.x,
        y: rect.y + root.contentWindow?.scrollY, // + rootRect.y,
        width: rect.width,
        height: rect.height,
    };
}

/** Prevents from selecting invisible slider elements */
export function findSliderElement(root, selector) {
    //first try to find active slider element
    const activeSlide = root.querySelector(`.owl-item.active ${selector}`);
    if (activeSlide) return activeSlide;
    return root.querySelector(selector);
}

export function findFrameTargetElement(root, value) {
    const { section, id, s, item, iteratorRoot } = value;

    const sectionRoot = root.querySelector(
        `[data-inspect="cms.sections/${section}"]`
    );
    if (!sectionRoot) return null;

    const iteratorRootEl = iteratorRoot
        ? sectionRoot.querySelector(`[data-inspect="${iteratorRoot}"]`)
        : null;

    const itemRoot =
        item && iteratorRootEl
            ? findSliderElement(iteratorRootEl, `[data-inspect^="${item}@"]`)
            : sectionRoot.querySelector(`[data-inspect="${id}"]`);

    if (!itemRoot) return null;

    let el = findSliderElement(itemRoot, `[data-inspect="${id}"]`) || itemRoot;

    if (!el) return null;

    if (el.getAttribute("data-s") !== s) {
        el = el.querySelector(`[data-sub="${s}"]`) || el;
    }

    return el;
}
