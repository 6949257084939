import React from 'react';
import PropTypes from 'prop-types';
import Price from "playground/booking/Price";
import Button from "cms/components/Button";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import RoomGallery from "playground/booking/RoomGallery";
import Skeleton from "@material-ui/lab/Skeleton";
import Icon from "components/Icon";
import Amenities from "playground/booking/Amenities";
import Translate from "playground/booking/Translate";

const buttonClasses = {
    button: 'btn btn-flat btn-center primary hover-this@success'
};

export function RoomSkeleton() {
    return <div className={'cols gap-md'}>
        <Skeleton variant={'rect'} width={330} height={200} />
        <div className={'grow'}>
            <Skeleton height={28} width={'60%'} />
            <Skeleton height={19} width={'40%'} />
            <Skeleton height={19} width={'40%'} />
        </div>
    </div>;
}

class Room extends React.PureComponent {
    select = () => {
        const { onSelect, id } = this.props;
        onSelect(id);
    };

    render() {
        const {
            title,
            headline,
            price_min,
            area,
            capacity,
            media,
            amenities
        } = this.props.data;

        return <div className={'cols phone@rows phone@gap-sm gap-md'}>
            <div className={'room-gallery relative overflow-hide'}>
                <RoomGallery media={media} />
                <div className={'ribbon ribbon-lt success'}>
                    <div>Bestseller</div>
                </div>
            </div>
            <div className={'rows gap-sm grow'}>
                <div className={'cols cols-spread'}>
                    <div className={'rows gap-xs'}>
                        <h2><Translate>{title}</Translate></h2>
                        <div><Translate>{headline}</Translate></div>
                    </div>
                    <div className={'rows rows-right gap-xs'}>
                        <div>Ostatni pokój</div>
                        <div className={'cols cols-middle gap-md'}>
                            <div className={'cols cols-middle gap-xs'}>
                                <Icon>mui-group</Icon>
                                <div>{capacity}</div>
                            </div>
                            <div className={'cols cols-middle gap-xs'}>
                                <Icon>mui-aspect_ratio</Icon>
                                <div>{area} m<sup>2</sup></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'cols gap-md'}>
                    <Amenities items={amenities.slice(0,3)} />
                </div>
                <div className={'rows gap-xs grow cols-spread'}>
                    <div className={'cols cols-spread cols-middle phone@rows gap-sm'}>
                        <div className={'grow'}>
                            <h4>Oferta standardowa</h4>
                        </div>
                        <div className={'offer-price'}>
                            <Price price={price_min} />
                        </div>
                        <Button
                            classes={buttonClasses}
                            label={'Wybierz'}
                            onClick={this.select} />
                        </div>
                    </div>
            </div>
        </div>;
    }
}

export const RoomIterator = ArrayIterator(withData(Room));

Room.propTypes = {
    id: PropTypes.string
};