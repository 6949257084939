import React, { useEffect } from "react";
import { bootstrap } from "state/actions/bootstrap";
import { useApiActions, usePending } from "state/hooks";

export default function Bootstrap(props) {
    const { id, serviceType, bootstrapId, children } = props;
    const { onBootstrap } = useApiActions({ onBootstrap: bootstrap });
    const pending = usePending(bootstrapId, true);

    useEffect(() => {
        onBootstrap(serviceType, bootstrapId);
    }, [id, serviceType, bootstrapId, onBootstrap]);

    if (pending !== false) return null;

    return children;
}
