import { createEditorStateFromText } from "./createEditorStateFromText";
import { EditorState, convertFromRaw } from "draft-js";

export function createInitialEditorState(content) {
    if (!content || content?.version !== "draft-js.raw") {
        return createEditorStateFromText("");
    }

    const state = convertFromRaw(content);
    return EditorState.createWithContent(state);
}
