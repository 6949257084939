import React from "react";
import { useData } from "state/hooks";
import { SelectListItem } from "playground/ui/SelectList";

//import PropTypes from 'prop-types';

export function SitemapEntryItem(props) {
    const { onClick, Component, ...rest } = props;
    const data = useData(props);
    return (
        <Component
            onClick={onClick}
            subtitle={data.title}
            {...rest}
        >{`/${data.slug}`}</Component>
    );
}

SitemapEntryItem.propTypes = {};

SitemapEntryItem.defaultProps = {
    Component: SelectListItem,
};

export default SitemapEntryItem;
