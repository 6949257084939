import React from 'react';
import {ArrayIterator} from "components/list/iterator";
import List from "containers/List";
import Event from "./Event";
//import PropTypes from 'prop-types';

const EventIterator = ArrayIterator(Event);

export default function Track(props) {
    const {items, min, max, onClick} = props;
    return <div className={'timeline-track'}>
        <List
            min={min}
            max={max}
            type={'db.nodes'}
            items={items}
            onClick={onClick}
            Iterator={EventIterator}
        />
    </div>
}

Track.propTypes = {};
Track.defaultProps = {};
