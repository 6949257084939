import React from 'react';
import {connect} from 'react-redux';
import {selectAppApiContext} from "state/selectors/app";
import ApiContext from "containers/context/ApiContext";
import ThemeProvider from "containers/Theme";
import {selectAliasedEntity, selectEntity} from "state/selectors/data";
import Fetch from "containers/Fetch";
import Booking from "playground/booking/Booking";

const mapStateToProps = (store, props) => {
    const serviceAlias = props.service || props.match.params.service;
    const service = selectAliasedEntity(store, 'console.services', serviceAlias);
    //const preset = selectAliasedEntity(store, 'console.presets', '@currentPreset');
    const theme = selectEntity(store, 'console.themes', service.theme);
    return {
        serviceAlias,
        service: service.id,
        preset: theme.preset,
        apiContext: selectAppApiContext(store, {service: service.id})
    }
};

const mapDispatchToProps = {};

class BookingService extends React.PureComponent {

    render() {
        const { apiContext, serviceAlias, service, preset } = this.props;

        return (<ApiContext.Provider value={apiContext}>
            <Fetch
                type={'console.services'}
                id={serviceAlias}
                alias={serviceAlias}
                url={'console/services/:id?type=db,website&expand=true'}
                fetch={!service}
                useContext={false}
            />
            <ThemeProvider
                presetId={serviceAlias}
                target={document.body}
                apply={true}
                loadFonts={true}
            >
                { service && preset ? <Booking apiContext={apiContext} /> : null }
            </ThemeProvider>
        </ApiContext.Provider>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingService);