import React, { useContext } from "react";

import Inbox from "joynt/inbox";
import Space from "./space/Space";
import Page from "joynt/components/ContentEditor/Page";
import Feed from "./feed/Feed";
import Chat from "joynt/components/messages/chat/Chat";
import { Spaces } from "./spaces";
import Agenda from "./Agenda";
import About from "./about";
import ChildSpaces from "./ChildSpaces";
import Meeting from "./Meeting";
import MainStage from "./MainStage";
import External from "./External";
import Activity from "./activity";
import Sessions from "./Sessions";
import WorkspaceOverview from "./WorkspaceOverview";
import Workspaces from "./Workspaces";
import HomeView from "./home/HomeView";
import Pending from "./Pending";
import RoomSchedule from "./RoomSchedule";
import WorkspaceSchedule from "./WorkspaceSchedule";
import RoomStage from "joynt/components/RoomStage";
import Threads from "./Threads";
import WorkspaceOverviewFeed from "./WorkspaceOverviewFeed";
import EventOverview from "joynt/components/EventOverview";
import EventSchedule from "./EventSchedule";
import WorkspaceStage from "joynt/components/WorkspaceStage";
import SessionBrowser from "joynt/components/SessionBrowser";
import Calendar from "joynt/components/Calendar";
import Tasks from "joynt/components/Tasks";
import Witness from "joynt/components/Witness";
import SessionView from "joynt/components/SessionPage/Networking/SessionView";
import PublicSessionPage from "joynt/components/SessionPage/PublicSessionPage";
import SessionPage from "joynt/components/SessionPage";
import WebinarSeriesPage from "joynt/components/SessionPage/WebinarSeries";

export const SpaceNestingContext = React.createContext(null);

export function useSpaceNesting() {
    const nested = useContext(SpaceNestingContext);
    return { nested };
}

export const types = {
    inbox: Inbox,
    messages: Threads,
    overview: Agenda,
    event: Space,
    space: Space,
    spaces: Spaces,
    page: Page,
    meeting: Meeting,
    feed: Feed,
    chat: Chat,
    agenda: Agenda,
    about: About,
    "child-spaces": ChildSpaces,
    "main-stage": MainStage,
    activity: Activity,
    external: External,
    sessions: Sessions,
    workspace: WorkspaceOverview,
    workspaces: Workspaces,
    home: HomeView,
    pending: Pending,
    "room-stage": RoomStage,
    "room-about": About,
    "room-schedule": RoomSchedule,
    "workspace-schedule": WorkspaceSchedule,
    "workspace-feed": WorkspaceOverviewFeed,
    "workspace-stage": WorkspaceStage,
    "event-overview": EventOverview,
    "event-schedule": EventSchedule,
    "session-browser": SessionBrowser,
    calendar: Calendar,
    tasks: Tasks,
    "witness-board": Witness,
    "networking-session": SessionView,
    "public-session-page": PublicSessionPage,
    webinar: SessionPage,
    "webinar-series-overview": WebinarSeriesPage,
    "webinar-series": WebinarSeriesPage
};
