import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import LayoutSectionsList from "./PageSectionsList/LayoutSectionsList";
import Field from "containers/Field";
import PageFormHeader from "./PageFormHeader";
import PageSeo from "./PageSeo";
import PageTranslations from "./PageTranslations";
import Button from "components/Button";
import CreatePageForm from "./CreatePageForm";
import PageSettings from "./PageSettings";

import "./style.css";

export function PageForm(props) {
    const { type, id, isDraft } = props;

    const {
        children,
        sectionsCount,
        createSection,
        onCreateSection,
        section,
        hasSections,
        ...rest
    } = props;

    if (isDraft && hasSections) return <CreatePageForm {...rest} />;

    return (
        <FormLayout {...rest}>
            <FormLayout.Header>
                <PageFormHeader key={rest.id} {...rest} />
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                    <div className={"cols cols-middle gap-xs"}>
                        {!hasSections && (
                            <Field
                                id={"layout"}
                                fieldType={"select"}
                                source={"api:layouts"}
                                label={"Układ podstrony"}
                                nullable={true}
                                variant={"inline"}
                            />
                        )}
                    </div>
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                {hasSections && (
                    <FormLayout.Tab
                        id={"sections"}
                        badge={sectionsCount}
                        label={"Sekcje"}
                    >
                        <LayoutSectionsList
                            {...rest}
                            selected={section}
                            createSection={createSection}
                            onCreateSection={onCreateSection}
                        />
                    </FormLayout.Tab>
                )}
                <FormLayout.Tab id={"seo"} label={"SEO"}>
                    <div className={"pad-md"}>
                        <PageSeo {...rest} />
                    </div>
                </FormLayout.Tab>
                <FormLayout.Tab id={"settings"} label={"Ustawienia"}>
                    <div className={"pad-md"}>
                        <PageSettings {...rest} />
                    </div>
                </FormLayout.Tab>
                <FormLayout.Tab id={"translations"} label={"Tłumaczenia"}>
                    <div className={"pad-md"}>
                        <PageTranslations {...rest} />
                    </div>
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                {hasSections && (
                    <Button onClick={onCreateSection}>
                        {createSection ? "Anuluj" : "Dodaj sekcję"}
                    </Button>
                )}
                {!createSection && <FormLayout.Actions {...rest} />}
            </FormLayout.Footer>
            {children}
        </FormLayout>
    );
}

PageForm.propTypes = {};

PageForm.defaultProps = {};

export default PageForm;
