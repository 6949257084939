import React from "react";
import IconButton from "components/IconButton";
import useDebug from "./useDebug";

//import PropTypes from 'prop-types';

export function DebugIconButton(props) {
    const { type, id } = props;
    const path = [type, id].join("/");
    const onDebug = useDebug();
    if (!onDebug) return null;
    return (
        <IconButton
            icon={"mui-data_object"}
            onClick={() => onDebug(path)}
            size={"small"}
            label={path}
        />
    );
}

DebugIconButton.propTypes = {};

DebugIconButton.defaultProps = {};

export default DebugIconButton;
