import React from 'react';
import {connect} from 'react-redux';
import Fetch from "containers/Fetch";
import Github from "cms/ui/theme/Github";
import {selectAliasedEntity, selectEntity} from "state/selectors/data";
import {selectRouteParam} from "state/selectors/router";
import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import {fetchOne} from "state/actions/api";
import {reset} from "state/actions/data";
import {isPending} from "state/selectors/ui";
import {commit, initVcs, pull, push} from "cms/state/actions/theme-vcs";

const mapStateToProps = (store, props) => {
    let serviceName = selectRouteParam(store, 'service');
    let service = selectAliasedEntity(store, 'console.services', serviceName);
    let theme = selectEntity(store, 'console.themes', service.theme);
    let data = selectEntity(store, 'theme.vcs', theme.id);
    let formData = selectEntity(store, '@app.forms', 'theme-commit');
    return {
        formType: '@app.forms',
        formId: 'theme-commit',
        type: 'theme.vcs',
        theme: theme.id,
        ...data,
        formData,
        pending: isPending(store, theme.id)
    }
};

const mapDispatchToProps = bindApiActions({
    fetch: fetchOne,
    onPush: push,
    onPull: pull,
    onCommit: commit,
    onInit: initVcs
},{
    reset
});

class GithubContainer extends React.PureComponent {

    commit = () => {
        const { formData, theme, onCommit, reset, formType, formId } = this.props;
        const { commit_message } = formData;
        if (!commit_message) {
            alert('Please enter commit message');
            return;
        }
        onCommit(theme, commit_message);
        reset(formType, formId);
    };

    fetch = () => {
        const { fetch, theme } = this.props;
        fetch('theme.vcs', theme, `console/themes/:id/vcs`);
    };

    openRepository = () => {
        const { repository } = this.props;
        window.open(`https://github.com/${repository}`);
    };

    openCommit = (id) => {
        //const { repository } = this.props;
    };

    push = () => {
        const { theme, onPush } = this.props;
        onPush(theme);
    };

    pull = () => {
        const { theme, onPull } = this.props;
        onPull(theme);
    };

    init = () => {
        const { theme, onInit } = this.props;
        onInit(theme);
    };

    render() {
        const { type, theme } = this.props;

        if (!theme) return null;

        return (<Fetch
            type={type}
            id={theme}
            url={`console/themes/:id/vcs`}
        >
            <Github
                {...this.props}
                onReload={this.fetch}
                onSubmit={this.commit}
                onOpenCommit={this.openCommit}
                onOpenRepository={this.openRepository}
                onPush={this.push}
                onPull={this.pull}
                onInit={this.init}
            />
        </Fetch>);
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(GithubContainer));