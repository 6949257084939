import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AgoraContext} from "../context/AgoraContext";

export const useScreenShare = () => {

    const connection = useContext(AgoraContext);
    const [status, setStatus] = useState(false);

    const clientStatus = connection.screenShare?.status || false;

    useEffect(() => {
        setStatus(clientStatus);
    }, [setStatus, clientStatus]);

    const start = useCallback(async () => {
        await connection.startScreenShare();
    }, [connection]);

    const stop = useCallback(async () => {
        await connection.stopScreenShare();
    }, [connection]);

    const toggle = useCallback(async (enable) => {
        if (enable && !status) await start();
        if (!enable && status) await stop();
    }, [start, stop, status]);

    return useMemo(() => ({
        startScreenShare: start,
        stopScreenShare: stop,
        toggleScreenShare: toggle,
        isSharingScreen: status
    }), [start, stop, toggle, status]);

}