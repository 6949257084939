import {useCallback, useContext, useMemo} from "react";
import useLogger from "joynt/meeting/hooks/useLogger";
import {AgoraContext} from "../context/AgoraContext";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import {useSessionContext} from "joynt/meeting/agora-ng/hooks/useSessionContext";

export const useCallControls = () => {

    const logger = useLogger();
    const connection = useContext(AgoraContext);
    const {session} = useMeeting();
    const [,setState] = useConnectionState(session);
    const {audio, video} = useSessionContext();

    const toggleVideo = useCallback(async (enable) => {
        if (enable) return await connection.publishVideoTrack();
        return await connection.unpublishVideoTrack();
    }, [connection]);

    const toggleAudio = useCallback(async (enable) => {
        if (enable) return await connection.publishAudioTrack();
        return await connection.unpublishAudioTrack();
    }, [connection]);

    const leaveCall = useCallback(async () => {
        logger(`[rtm] Leave rtc & rtm`);
        await connection.leave();
        setState(null);
    }, [logger, connection, setState]);

    return useMemo(() => ({
        audio,
        video,
        toggleAudio,
        toggleVideo,
        leaveCall
    }), [
        audio,
        video,
        toggleAudio,
        toggleVideo,
        leaveCall
    ]);

}
