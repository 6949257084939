import React, { useState } from "react";
//import PropTypes from 'prop-types';

import { SortableIterator } from "components/dnd";
import List from "containers/List";
import CollectionItem from "./CollectionItem";
import { FormLayout, OverlayForm } from "playground/cms/Form";
import Button from "components/Button";
import Icon from "components/Icon";
import MuiList from "@material-ui/core/List";
import CollectionItemForm from "./CollectionItemForm";

const CollectionItemRef = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <CollectionItem {...props} />
        </div>
    );
});

const ItemIterator = SortableIterator(CollectionItemRef);

export function Collection(props) {
    const {
        label,
        itemType,
        value,
        onCreate,
        FormComponent,
        onSortEnd,
        onDelete,
        ...rest
    } = props;
    const [id, setId] = useState(null);
    return (
        <>
            <FormLayout.Section
                name={`${label} / ${itemType}`}
                action={
                    <Button
                        variant={"text"}
                        onClick={() => onCreate()}
                        endIcon={<Icon>mui-add_circle</Icon>}
                    >
                        Dodaj
                    </Button>
                }
            >
                <MuiList>
                    <List
                        type={itemType}
                        items={value}
                        Iterator={ItemIterator}
                        onSortEnd={onSortEnd}
                        onClick={setId}
                        onDelete={onDelete}
                    />
                </MuiList>
            </FormLayout.Section>
            <OverlayForm
                open={!!id}
                onClose={() => setId(null)}
                position={"center"}
                fullHeight={false}
                width={"md"}
            >
                {id ? (
                    <FormComponent
                        {...rest}
                        type={itemType}
                        id={id}
                        onClose={() => setId(null)}
                    />
                ) : null}
            </OverlayForm>
        </>
    );
}

Collection.propTypes = {};

Collection.defaultProps = {
    FormComponent: CollectionItemForm,
};

export default Collection;
