import React from 'react';
import {useMoment} from "joynt/components/DateTimePicker/hooks";

//import PropTypes from 'prop-types';

export function SessionDate({start, end}) {
    const startMoment = useMoment(start);
    const endMoment = useMoment(end);
    const format = "DD MMM, HH:mm";

    return <div>
        {startMoment.format(format)} - {endMoment.format('HH:mm')}
    </div>
}

SessionDate.propTypes = {};
SessionDate.defaultProps = {};

export default SessionDate;
