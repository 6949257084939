import React, {useEffect} from 'react';
import {useReaction} from "joynt/hooks";
import Preloader from "components/Preloader";
import List from "containers/List";
import Button from "components/Button";
import Answer from "./Answer";
import AnswerEditable from "./AnswerEditable";
import {ArrayIterator} from "components/list/iterator";
import {SortableIterator} from "components/dnd";
//import PropTypes from 'prop-types';

const AnswerIterator = ArrayIterator(Answer);
const AnswerEditIterator = SortableIterator(AnswerEditable);

export default function AnswerList(props) {
    const {
        value,
        postId,
        onCreate,
        onDelete,
        onDeletePost,
        onToggleSettings,
        readOnly,
        enableVoting,
        enableDelete,
        anonymousVoting
    } = props;

    const reactions = useReaction({type: 'db.posts', id: postId});
    const iterator = readOnly ? AnswerIterator : AnswerEditIterator;

    /**
     * Require at least 2 options
     */
    useEffect(() => {
        if (!readOnly && (!value || (value.length < 2))) onCreate();
    }, [readOnly, value, onCreate]);

    return <div>
        <Preloader visible={reactions.pending} />
        <div className={'rows gap-sm'}>
            <div className={'rows gap-sm'}>
                <List
                    type={'db.answers'}
                    items={value}
                    readOnly={readOnly}
                    onDelete={onDelete}
                    onClick={enableVoting ? reactions.onReaction : null}
                    className={'rows gap-sm'}
                    answers={reactions.reactions}
                    totalVotes={reactions.total}
                    anonymousVoting={anonymousVoting}
                    enableVoting={enableVoting}
                    Iterator={iterator}
                    postId={postId}
                />
            </div>
            {!readOnly ? <div className={'cols cols-middle gap-sm'}>
                <Button onClick={onCreate} variant={'text'} icon={'mui-add_circle'}>Add another option</Button>
                <Button onClick={onToggleSettings} variant={'text'} icon={'mui-settings'}>Poll settings</Button>
                {onDeletePost && enableDelete ? <Button onClick={onDeletePost}>Delete poll</Button> : null }
            </div> : null }
        </div>
    </div>;
}

AnswerList.propTypes = {};
AnswerList.defaultProps = {
    enableVoting: false,
    enableDelete: false
};
