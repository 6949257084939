import React from "react";
//import PropTypes from 'prop-types';

import { FormContainer } from "playground/cms/Form";
import NavigationForm from "./NavigationForm";

export function NavigationFormContainer(props) {
    const {} = props;
    return <FormContainer {...props} FormComponent={NavigationForm} />;
}

NavigationFormContainer.propTypes = {};

NavigationFormContainer.defaultProps = {};

export default NavigationFormContainer;
