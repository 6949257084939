import {FILTER_NS} from "config/list-aliases";

export const setPage = (filter, page) => {
    return {
        type: 'DATA.CHANGE',
        payload: {
            type: FILTER_NS,
            id: filter,
            data: {page}
        }
    }
};