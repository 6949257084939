import React from 'react';
import {connect} from 'react-redux';
import Select from "components/fields/select/Select";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class ListViewSelect extends React.Component {

    render() {
        const { items, value, onChange } = this.props;
        return (<div className={'pad-sm cols cols-left'}>
            <Select
                label={'Select view'}
                type={'db.list_views'}
                variant={'dense'}
                items={items}
                value={value}
                onChange={onChange} />
        </div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListViewSelect);