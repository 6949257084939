import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";

export function ProfileInfo(props) {

    const {
        avatar,
        name,
        role,
        children
    } = props;

    return (<div className={"profile-info brand-bg-alt rows rows-center gap-md pad-6"}>
        <div>{avatar}</div>
        <div className={"rows rows-center gap-xs"}>
            <div>{name}</div>
            <div>{role}</div>
        </div>
        { children && <div>{children}</div>}
    </div>);

}

ProfileInfo.propTypes = {
    name: PropTypes.string,
    role: PropTypes.string
};

ProfileInfo.defaultProps = {};

export default ProfileInfo;
