import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Preloader from "components/Preloader";
import Icon from "components/Icon";
import Button from "components/Button";
import SessionRecordingState from "joynt/meeting/SessionRecordingState";
import Dialog from "@material-ui/core/Dialog";

const debugStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "400px",
    padding: "1rem",
    color: "white",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1,
};

function StageError(props) {
    const { onRetry, onLeave } = props;
    return (
        <div className={"rows gap-md rows-center pad-md"}>
            <div className={"o-20 type-heading"}>
                <Icon>mui-error_outline</Icon>
            </div>
            <div className={"type-heading-sm"}>Something went wrong</div>
            <div>{props.children}</div>
            <Button
                onClick={onRetry}
                startIcon={<Icon>mui-refresh</Icon>}
                size={"large"}
            >
                Try again
            </Button>
            <Button onClick={onLeave} color={"default"}>
                Cancel
            </Button>
        </div>
    );
}

function StageInfo(props) {
    return <div className={"type-heading o-30"}>{props.children}</div>;
}

function StageContent(props) {
    const { children, count, error, pending, onRetry, onLeave } = props;

    if (error)
        return (
            <Dialog open={!pending}>
                <StageError onRetry={onRetry} onLeave={onLeave}>
                    {error}
                </StageError>
            </Dialog>
        );

    if (count === 0) return <StageInfo>There's no one on stage yet.</StageInfo>;

    return children;
}

export default function Stage(props) {
    const {
        id,
        count,
        focused,
        pending,
        status,
        children,
        onClick,
        onRetry,
        onLeave,
        //onOverlayClick,
        error,
        isMinimized,
        overlay,
        debug,
    } = props;

    const isOneParticipant = count === 1;

    const classes = {
        "video-stage": true,
        "meeting-grid": !focused || isOneParticipant,
        "meeting-focused": focused && !isOneParticipant,
        "is-minimized": isMinimized,
        [`tiles-${count}`]: true,
        debug: debug,
    };
    return (
        <>
            <Preloader visible={pending} text={status} />

            <SessionRecordingState id={id} />

            <div className={cn("grow", classes)} onClick={onClick}>
                {!pending || error ? (
                    <StageContent
                        pending={pending}
                        error={error}
                        count={count}
                        onRetry={onRetry}
                        onLeave={onLeave}
                    >
                        {children}
                    </StageContent>
                ) : null}
            </div>
            {overlay && overlay}
            {props.debug ? <div style={debugStyle}>{props.debug}</div> : null}
        </>
    );
}

Stage.propTypes = {
    screen: PropTypes.bool,
    count: PropTypes.number,
    pending: PropTypes.bool,
};
Stage.defaultProps = {
    screen: false,
    count: 0,
    pending: false,
};
