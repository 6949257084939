//import React from 'react';
//import PropTypes from 'prop-types';

export function Files(props) {

    const {children} = props;
    return children;

}

Files.propTypes = {};
Files.defaultProps = {};

export default Files;
