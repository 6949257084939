import React from 'react';
import Preloader from "components/Preloader";
//import PropTypes from 'prop-types';
import cn from 'classnames';

export default class IdePageLayout extends React.Component {

    render() {
        const { pending, inspector, nav, children } = this.props;

        return (<div className={'ide-layout cols'}>
            <div className={'ide-inspect rows default default-c800'}>
                {inspector}
            </div>
            <div className={'ide-nav default default-c700 cols cols-middle'}>
                {nav}
            </div>
            <div id="page-container" className={'page-preview'}>
                {children}

            </div>
            <div className={cn('page-preloader',{visible: pending})}>
                <Preloader visible={pending} />
            </div>
        </div>);
    }

}

IdePageLayout.propTypes = {};