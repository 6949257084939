import React from "react";
import IconButton from "components/IconButton";
import { useFullscreenSession } from "./../hooks";
//import PropTypes from 'prop-types';

export function FullscreenButton(props) {
    const { onToggleFullscreen } = useFullscreenSession();

    return (
        <IconButton
            size={"medium"}
            withTooltip
            label={"Fullscreen"}
            icon={"mui-fullscreen"}
            onClick={onToggleFullscreen}
            disabled={!onToggleFullscreen}
        />
    );
}

FullscreenButton.propTypes = {};
FullscreenButton.defaultProps = {};

export default FullscreenButton;
