import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import EnumList from "containers/EnumList";
import { ArrayIterator } from "components/list/iterator";
import { withData } from "containers/Entity";
import icons from "config/icons";
import Icon from "components/Icon";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import LibraryComponents from "cms/ui/LibraryComponents";

import "./style.css";
import SelectList from "playground/theme/StyleEditor/SelectList";
import SelectListItem from "playground/theme/StyleEditor/SelectListItem";

function Type(props) {
    const { value, onClick } = props;
    return (
        <SelectListItem
            onClick={() => onClick(value)}
            icon={
                <Icon defaultType={"mui"}>
                    {icons["cms.components." + value]}
                </Icon>
            }
        >
            {value}
        </SelectListItem>
    );
}

Type.autoFillProps = ["label", "value"];

const TypeIterator = ArrayIterator(withData(Type));

const groups = {
    basic: [
        "structured",
        "text",
        "button",
        "list",
        "template",
        "card",
        "query",
        "link",
        "value",
    ],
    all: [
        "booking-engine",
        "button",
        "text",
        "card",
        "contact-form",
        "context",
        "form-input",
        "form-select-option",
        "form-select",
        "form-consent",
        "form",
        "icon",
        "list",
        "map",
        "media-element",
        "nav",
        "newsletter",
        "plan",
        "quickbook",
        "slider-nav",
        "structured",
        "symbol",
        "tags",
        "template",
        "value",
    ],
};

function filterComponents(types, group) {
    return types.filter((type) => {
        let id = type.replace("types.components.", "");
        return (groups[group] || []).indexOf(id) > -1;
    });
}

export function SelectComponentType(props) {
    const { onClose, onSelect, onImport } = props;
    const [state, setState] = useState({ group: "basic" });
    const { group } = state;
    const handleSelect = (value) => {
        onSelect(value);
        if (onClose) onClose();
    };
    const filter = (types) => {
        return groups[group]; //filterComponents(types, group);
    };
    const setGroup = (evt, group) => setState({ group });
    const types = groups[group];

    return (
        <div className={"rows"}>
            <Tabs value={group} onChange={setGroup} className={"default-c600"}>
                <Tab value={"basic"} label={"Podstawowe"} />
                <Tab value={"all"} label={"Wszystkie"} />
                <Tab value={"library"} label={"Library"} />
            </Tabs>
            <div className={"rows"}>
                {group === "library" ? (
                    <LibraryComponents onClick={onImport} />
                ) : (
                    <SelectList>
                        {/*<EnumList*/}
                        {/*    type={"schema.data"}*/}
                        {/*    id={"types.components"}*/}
                        {/*    onClick={handleSelect}*/}
                        {/*    Iterator={TypeIterator}*/}
                        {/*    filter={filter}*/}
                        {/*/>*/}
                        {types.map((type) => (
                            <Type value={type} onClick={handleSelect} />
                        ))}
                    </SelectList>
                )}
            </div>
        </div>
    );
}

SelectComponentType.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default SelectComponentType;
