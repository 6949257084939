import React from 'react';
import {connect} from 'react-redux';
import {selectRouteParam} from "state/selectors/router";
import root from 'react-shadow';
import styles from 'cms/ui/css/style-select.js';
import {selectWebsiteCanonicalHost} from "cms/state/selectors/cms";

const mapStateToProps = (store, props) => {
    const service = selectRouteParam(store, 'service');
    const host = selectWebsiteCanonicalHost(store, service);
    return {
        themeCss: `${host}/assets/styles/all.min.css`
    }
};

const mapDispatchToProps = {};

class ThemeStyles extends React.PureComponent {

    render() {
        const { themeCss, className } = this.props;
        return (<root.div className={className}>
            <link rel={'stylesheet'} href={themeCss} />
            <style type={'text/css'}>{styles}</style>
            {this.props.children}
        </root.div>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemeStyles);