import React from "react";
import { useCurrentService } from "playground/cms/hooks";
import { useApiActions } from "state/hooks";
import { postCallback } from "state/actions/api";
import Button from "components/Button";

//import PropTypes from 'prop-types';

export function ApiPlayground(props) {
    const {} = props;
    const service = useCurrentService();
    const cmsId = service?.content_source;
    const url = `console/cms/${cmsId}/copy`;

    const { onPost } = useApiActions({ onPost: postCallback });

    const makeRequest = () => {
        const cb = () => alert("done");
        onPost(cb, url, {});
    };

    return (
        <div>
            <Button onClick={makeRequest}>Make Request</Button>
        </div>
    );
}

ApiPlayground.propTypes = {};

ApiPlayground.defaultProps = {};

export default ApiPlayground;
