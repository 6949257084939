import React, { useCallback, useContext } from "react";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

import Board from "@asseinfo/react-kanban";
import TaskCard from "joynt/components/Tasks/TaskCard";
import { BoardContext } from "./context";

import "./style.css";

function RenderColumnHeader(column) {
    const { id, title } = column;
    return <ColumnHeader id={id} title={title} />;
}

function ColumnHeader(props) {
    const { id, title } = props;
    const onCreate = useContext(BoardContext);
    const create = useCallback(() => {
        onCreate(id);
    }, [onCreate, id]);
    return (
        <div className={"board-column-header"}>
            <div className={"board-column-header__name"}>{title}</div>
            <div className={"board-column-header__actions"}>
                <IconButton onClick={create} icon={"mui-add"} />
            </div>
        </div>
    );
}

export function TasksBoard(props) {
    const { onDragEnd, board } = props;

    return (
        <Board
            renderCard={TaskCard}
            renderColumnHeader={RenderColumnHeader}
            onCardDragEnd={onDragEnd}
        >
            {board}
        </Board>
    );
}

TasksBoard.propTypes = {};
TasksBoard.defaultProps = {};

export default TasksBoard;
