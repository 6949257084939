export default {
    "console.domains": {
        fields: {},
    },
    "console.presets": {
        fields: {
            //'color_scheme': 'console.colors',
            //'typography': 'console.typography'
        },
    },
    "console.colors": {
        fields: {},
    },
    "console.typography": {
        fields: {},
    },
    "console.themes": {
        fields: {},
        refs: {
            parent: "@self",
            preset: "console.presets",
        },
    },
    "console.services.cms": {
        fields: {},
    },
    "console.services.db": {
        fields: {},
        refs: {
            service_domain: "console.domains",
            api_domain: "console.domains",
        },
    },
    "console.services.website": {
        refs: {
            canonical_domains: ["console.domains"],
            canonical_domain: "console.domains",
            theme: "console.themes",
            content_source: "console.services.cms",
            library: "console.services.cms",
        },
    },
    "console.services.cluster": {
        refs: {
            canonical_domains: ["console.domains"],
            canonical_domain: "console.domains",
            primary_service: "console.services.website",
            services: ["console.services.website"],
        },
    },
    "console.services.internal": {
        refs: {
            database_service: "console.services.db",
        },
    },
    "console.services": {
        refs: {
            canonical_domains: ["console.domains"],
            canonical_domain: "console.domains",
            theme: "console.themes",
            content_source: "console.services.cms",
            library: "console.services.cms",
        },
    },
    "console.user_settings": {
        fields: {},
        refs: {
            theme_preset: "console.presets",
        },
    },
    "console.users": {},
    "console.api_keys": {
        refs: {
            user: "console.users",
        },
    },
    "console.user-service-access": {
        refs: {
            user: "console.users",
        },
    },
    "theme.vcs-log": {
        fields: {},
    },
    "theme.vcs": {
        fields: {
            log: ["theme.vcs-log"],
        },
    },
};
