import React from 'react';
import PropTypes from 'prop-types';

import "./style.css";

export function SessionAside(props) {

    const {
        title,
        children
    } = props;

    return (<div className={"witness-session-aside"}>
        <div className={"witness-session-aside__header"}>
            {title}
        </div>
        <div className={"witness-session-aside__content"}>
            {children}
        </div>
    </div>);

}

SessionAside.propTypes = {
    title: PropTypes.string
};
SessionAside.defaultProps = {};

export default SessionAside;
