import React from "react";

import SessionAside from "./../SessionAside";

export function WitnessFinal() {
    return (
        <SessionAside title={"Congratulations everyone for your efforts!"}>
            <h3>Welcome to the final session</h3>
            <div>
                Tell about your task, how it went and if it’s accomplished.
            </div>
            <div>
                It’s ok if you didn’t accomplish your goal — trying hard is like
                training that leads to success. If you did — even better!
            </div>
        </SessionAside>
    );
}

WitnessFinal.propTypes = {};
WitnessFinal.defaultProps = {};

export default WitnessFinal;
