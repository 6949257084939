import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import "./style.css";

const roles = {
    owner: {
        label: "Owner",
        color: "primary",
    },
    admin: {
        label: "Admin",
        color: "primary",
    },
    collaborator: {
        label: "Collaborator",
        color: "secondary",
    },
    speaker: {
        label: "Speaker",
        color: "speaker",
    },
    moderator: {
        label: "Moderator",
        color: "moderator",
    },
    confirmed: {
        label: "Confirmed",
        color: "confirmed",
    },
    denied: {
        label: "Declined",
        color: "declined",
    },
};

function IdentityBox(props) {
    const { avatar, name, description, className, role } = props;
    return (
        <div className={cn("identity-box", className)}>
            {avatar}
            <div className={"identity-box__details"}>
                <div className={"cols identity-box__person"}>
                    {name && (
                        // <div className={"col"}>
                        <p className={"identity-box__name"}>{name}</p>
                        // </div>
                    )}
                    {role && (
                        <div
                            className={cn(
                                "col",
                                "identity-box__role",
                                "identity-box__role--" + roles[role].color
                            )}
                        >
                            {roles[role].label}
                        </div>
                    )}
                </div>
                {description && (
                    <p className={"identity-box__description"}>{description}</p>
                )}
            </div>
        </div>
    );
}

IdentityBox.propTypes = {
    avatar: PropTypes.node,
    name: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    role: PropTypes.oneOf(Object.keys(roles)),
};

export default IdentityBox;
