export function getDaysInRange({start, end}) {
    const date = new Date(start);
    date.setHours(0,0,0,0);
    const days = [];
    while (date.getTime() < end.getTime()) {
        let newDate = new Date(date);
        newDate.setHours(0,0,0,0);
        days.push(newDate);
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}