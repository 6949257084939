import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Button from "components/Button";

import "./style.css";

export const PromoTileHeader = ({ children, big }) => {
    return big ? (
        <h2 className={"promo-tile__title"}>{children}</h2>
    ) : (
        <h3 className={"promo-tile__title"}>{children}</h3>
    );
};

export const PromoTile = ({
    onTileClick,
    variant,
    onButtonClick,
    children,
    label,
    buttonLabel,
    buttonProps,
    className,
    big,
}) => {
    return (
        <div
            onClick={onTileClick}
            className={cn(
                "promo-tile__wrapper",
                variant && `variant-${variant}`,
                onTileClick && "clickable",
                className
            )}
        >
            <div
                className={cn(
                    "promo-tile",
                    variant && `variant-${variant}`,
                    big && `promo-tile--big`
                )}
            >
                {children}
                {label && <PromoTileHeader big={big}>{label}</PromoTileHeader>}
                {buttonLabel && (
                    <Button
                        {...buttonProps}
                        buttonClassName={cn(
                            "promo-tile__button",
                            buttonProps?.buttonClassName
                        )}
                        onClick={onButtonClick}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};

PromoTile.propTypes = {
    variant: PropTypes.oneOf(["primary", "secondary", "white"]),
};

export default PromoTile;
