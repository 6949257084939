import {selectNodeFlowPath} from "./selectNodeFlowPath";
import {selectFlag} from "state/selectors/ui";
import {FLAG_SESSION_CONFIRM} from "joynt/config";
import {selectFlowSchedule} from "./selectFlowSchedule";
import {selectFlowSegment} from "./selectFlowSegment";
import {selectEdges} from "joynt/state/selectors/joint";

function identityToUser(store, id) {
    const identity = store.data['db.identities']?.[id] || {};
    return identity.created_by;
}

function selectPeers(store, session) {

    const presence = store.presence[session] || {};
    const {workspace} = selectEdges(store, session);

    const workspacePresence = store.presence[workspace] || {};
    const userWorkspacePresence = workspacePresence.user || {};

    return presence && presence.session ? Object.keys(presence.session)
        .filter(id => {
            return presence.session[id].connected
                && userWorkspacePresence[identityToUser(store, id)]?.live;
        }) : [];
}

const requiredPeersConfig = {
    witness: 2
}

function selectIsPeersReqMet(store, id, flow) {
    const override = selectFlag(store, `dev.session.overridePeersReq`);
    if (override) return true;

    const requiredPeers = requiredPeersConfig[flow] || 0;
    if (!requiredPeers) return true;

    const {stage: session} = selectEdges(store, id);

    const peers = selectPeers(store, session);
    return peers.length >= requiredPeers;
}

function selectValidStep(store, flow, step) {

    const schedule = selectFlowSchedule(store, flow);
    const steps = [];
    schedule.forEach(s => {
        steps.push(s.id);
        if (s.steps) s.steps.forEach(sub => {
            steps.push(`${s.id}:${sub.id}`);
        });
    });

    if (!step) return steps[0];

    if (steps.indexOf(step) > -1) return step;
    return steps[0];

}

export function selectEffectiveFlowPath(store, id) {

    if (!!selectFlag(store, FLAG_SESSION_CONFIRM))
        return ["joining",selectValidStep(store, "joining")].join('.');

    const [flow, segmentId] = selectNodeFlowPath(store, id).split('.');

    const stateOverride = selectFlag(store, `session.${id}.flowSegment`);

    const stepId = stateOverride && stateOverride.indexOf(segmentId) === 0
        ? stateOverride
        : segmentId;

    const step = selectValidStep(store, flow, stepId);

    const config = selectFlowSegment(store, flow, step);
    const flowPath = [flow, step].join('.');

    if (config.offline) return flowPath;

    if (!selectIsPeersReqMet(store, id, flow)) {
        return ["witness-hold", selectValidStep(store, "witness-hold")].join('.');
    }

    return flowPath;

}