import React from 'react';
import Fetch from "containers/Fetch";
import TextField from "components/fields/text/TextField";
import List from "containers/List";
import Preloader from "components/Preloader";
import {ArrayIterator} from "components/list/iterator";
import Member from "./Member";
import {useUiFlag} from "state/hooks";
import {useEdges} from "joynt/hooks";
//import PropTypes from 'prop-types';

const MemberIterator = ArrayIterator(Member);

export default function IdentitySearch(props) {
    const {id, onSelect, identityRole} = props;

    const {draft, parent} = useEdges(id);
    const identityScopeId = draft ? parent : id;

    const [search, setSearch] = useUiFlag('identities.search', null);

    let url = `joynt/nodes/${identityScopeId}/participants`;
    if (search) url = `joynt/identities?search=${search}`;

    const type = 'db.identities';
    const list = `identities.search`;

    return <div className={'rows gap-xs relative'}>
        <Fetch
            type={'db.identities'}
            list={`identities.search`}
            url={url}
            enable={true}
        />
        <TextField
            onChange={setSearch}
            value={search}
            label={'Type user name to search'}
            variant={'search'}
        />
        <div className={'identity-search-results rows pad-xs gap-xs overflow-scroll'}>
            <List
                type={type}
                list={list}
                Iterator={MemberIterator}
                pendingState={<Preloader visible={true} />}
                emptyState={<div>found nothing</div>}
                onClick={onSelect}
                identityRole={identityRole}
            />
        </div>
    </div>;
}

IdentitySearch.propTypes = {};
IdentitySearch.defaultProps = {};
