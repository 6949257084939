import {useCallback, useState} from "react";
import useInterval from "util/hooks/useInterval";

export function useTimerProgress(start, target) {

    const getValue = useCallback(() => {
        const now = (new Date()).getTime()/1000;
        const diff = target-start;
        const progress = now-start;
        return Math.max(0, Math.min(
            Math.round((progress*100)/diff),
            100
        ));
    }, [start, target]);

    const [value, setValue] = useState(getValue());

    useInterval(() => {
        setValue(getValue());
    });

    return value;
}