import {
    selectEntity,
    selectEntityChanges,
    selectList,
} from "state/selectors/data";

export function selectWebsiteData(store, type, id) {
    let website = selectEntity(store, type, id);
    const theme = selectEntity(store, "console.themes", website.theme);
    const cms = selectEntity(
        store,
        "console.services.cms",
        website.content_source
    );
    const domains = selectList(store, "console.domains", `domains.${id}`);
    const schemaConfig = selectEntity(store, "console.schema-config", id);

    return {
        [type]: website,
        "console.themes": theme,
        "console.services.cms": cms,
        "console.domains": domains,
        "console.schema-config": schemaConfig,
    };
}

export function selectWebsiteChanges(store, type, id) {
    const data = selectWebsiteData(store, type, id);
    return Object.keys(data).reduce((acc, key) => {
        if (Array.isArray(data[key])) {
            const changed = data[key]
                .map((item) => {
                    const changes = selectEntityChanges(store, key, item.id);
                    if (!Object.keys(changes).length) return null;
                    return { id: item.id, ...changes };
                })
                .filter((item) => !!item);
            if (changed.length) acc[key] = changed;
            return acc;
        }
        if (!data[key]?.id) return acc;
        const changes = selectEntityChanges(store, key, data[key].id);
        if (Object.keys(changes).length) {
            acc[key] = { id: data[key].id, ...changes };
        }
        return acc;
    }, {});
}
