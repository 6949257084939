import React from 'react';
import {connect} from 'react-redux';

import {withApiContext, bindApiActions} from "containers/context/ApiContext";
import * as actions from "state/actions/calls";
import {selectList} from "state/selectors/lists";
import cn from 'classnames';

import List from "containers/List";

import "css/calls.css";
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import Notifications from "containers/Notifications";
import Icon from "components/Icon";
import {LinearProgress} from "@material-ui/core";
import {listRemove} from "state/actions/list";
import PostIdentity from "joynt/post/PostIdentity";

function CallButton(props) {
    const { onClick, icon, className } = props;
    return <div onClick={onClick} className={cn('call-btn', className)}>
        <Icon>{icon}</Icon>
    </div>
}

function CallListItem(props) {
    const {
        id,
        call_status,
        participants,
        subject,
        onHangup,
        onAnswer,
        onClose
    } = props;
    const status = call_status;
    return <div className={'call-window'}>
        {status !== 'ended' && status !== 'in-progress' ? <LinearProgress /> : null }
        <div className={'pad-sm gap-sm cols cols-middle'}>
            <div>{subject}</div>
            <PostIdentity identities={participants} />
        </div>
        {status === 'in-progress' ? <div className={'video'}>
            <video id={'local-media-stream'} autoPlay={true} muted={true} />
            <video id={'remote-media-stream'} autoPlay={true} muted={false} />
        </div> : null }
        <div className={'call-info'}>
            { status === 'ended' ? <div onClick={()=>onClose(id)}>
                Call ended
            </div> : null}
            <div className={'call-actions cols cols-center gap-xs'}>
                {status !== 'ended'
                    ? <CallButton
                        onClick={()=>onHangup(id)}
                        icon={'mui-call_ended'}
                        className={'bg-red'}
                    />
                    : null }
                {status === 'pending'
                    ? <CallButton
                        onClick={()=>onAnswer(id)}
                        icon={'mui-call'}
                        className={'bg-green'}
                    />
                    : null }
            </div>
        </div>
    </div>;
}

CallListItem.autoFillProps = [
    'from',
    'to',
    'subject',
    'call_status',
    'incoming',
    'stem',
    'participants'
];

const CallListIterator = ArrayIterator(withData(CallListItem));

const mapStateToProps = (store, props) => {
    return {
        calls: selectList(store, 'db.threads', 'active-calls')
    }
};

const mapDispatchToProps = bindApiActions({
    onHangup: actions.hangup,
    onHandleEvent: actions.event,
    onAnswer: actions.answer
}, {
    onClose: listRemove
});

class Calls extends React.PureComponent {

    handler = (event) => {
        const { onHandleEvent } = this.props;
        onHandleEvent(event.context);
    };

    close = (id) => {
        const { onClose } = this.props;
        onClose('db.threads', 'active-calls', id);
    };

    render() {
        const {
            calls,
            onHangup,
            onAnswer
        } = this.props;

        return <Notifications namespace={'.call'} handler={this.handler}>
            {calls && calls.length ? <div className={'calls-widget'}>
                <List
                    type={'db.threads'}
                    items={calls}
                    onHangup={onHangup}
                    onAnswer={onAnswer}
                    onClose={this.close}
                    Iterator={CallListIterator} />
            </div> : null}
        </Notifications>;
    }

}

export default withApiContext()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Calls));