import React from 'react';
import cn from "classnames";
import PropTypes from 'prop-types';

import "./style.css";

export function DashboardTile(props) {

    const {children, color, className} = props;

    return (<div className={cn("witness-dashboard-tile", className, {
        [`color-${color}`]: !!color,
        "dark-mode": color === "dark"
    })}>
        {children}
    </div>);

}

DashboardTile.propTypes = {
    color: PropTypes.oneOf(["default", "dark", "success", "light"])
};
DashboardTile.defaultProps = {
    color: "default"
};

export default DashboardTile;
