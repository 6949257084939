import React from "react";
import PropTypes from "prop-types";
import SessionsSlider from "./SessionsSlider";
import { useList, useUiFlag } from "state/hooks";
import useFetch from "state/hooks/useFetch";
import { useChannels } from "joynt/hooks/channels";
import { NODE_TYPE_EVENT } from "joynt/config";
import { SESSION_TYPE_WEBINAR } from "joynt/config/sessions";
import { useNodeData } from "joynt/hooks";
import Icon from "components/Icon";
import Button from "components/Button";
import RadioButton from "joynt/components/RadioButton";

export function SessionsSliderContainer(props) {
    const { id } = props;

    const list = `${id}.sessions`;
    const type = "db.nodes";
    const [filter, setFilter] = useUiFlag(`${id}.sessionsFilter`);
    const endpoint = filter || "upcoming-sessions";
    const { onCreate } = useChannels(id, list);
    const { can_edit } = useNodeData(id);

    const create = () =>
        onCreate({
            subtype: NODE_TYPE_EVENT,
            session_type: SESSION_TYPE_WEBINAR,
        });

    const pending = useFetch({
        url: `v2/joynt/nodes/${id}/${endpoint}`,
        type,
        list,
        fetchId: `${id}.sessions`,
    });

    const { items } = useList(type, list);

    return (
        <SessionsSlider
            {...props}
            items={items}
            isPending={pending}
            actions={
                can_edit && !filter ? (
                    <Button
                        className={"sessions-slider__create"}
                        onClick={create}
                        variant={"text"}
                        startIcon={<Icon>mui-add</Icon>}
                    >
                        Create session
                    </Button>
                ) : null
            }
            filter={
                <div className={"sessions-slider-filter"}>
                    <RadioButton
                        isSelected={!filter}
                        onClick={() => setFilter(null)}
                    >
                        Upcoming sessions
                    </RadioButton>
                    <RadioButton
                        isSelected={filter === "past-sessions"}
                        onClick={() => setFilter("past-sessions")}
                    >
                        Past sessions
                    </RadioButton>
                </div>
            }
        />
    );
}

SessionsSliderContainer.propTypes = {};

SessionsSliderContainer.defaultProps = {};

export default SessionsSliderContainer;
