import React from "react";
//import PropTypes from 'prop-types';
import SectionForm from "./SectionForm";
import { FormContainer } from "playground/cms/Form";
import {
    useActions,
    useApiActions,
    usePending,
    useRouteParam,
} from "state/hooks";
import { saveCallback } from "cms/state/actions/cms";
import { resetAllExcept } from "state/actions/data";

export function SectionFormContainer(props) {
    const { onClose } = props;
    const { onSubmit } = useApiActions({
        onSubmit: saveCallback,
    });
    const { onReject } = useActions({
        onReject: resetAllExcept,
    });
    const [isPreview] = useRouteParam("sectionPreview");
    const pending = usePending("cms.save");
    const submit = () => {
        onSubmit((result) => {
            if (onClose && result) onClose();
        });
    };
    const reject = () => {
        onReject(["app.filters"]);
        if (onClose) onClose();
    };
    return (
        <FormContainer
            {...props}
            FormComponent={SectionForm}
            onSubmit={submit}
            onCancel={reject}
            pending={pending}
            isPreview={isPreview}
        />
    );
}

SectionFormContainer.propTypes = {};

SectionFormContainer.defaultProps = {};

export default SectionFormContainer;
