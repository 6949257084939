import React, { useCallback } from "react";
import { useData } from "state/hooks";
import ListView from "containers/ListView";
import { LIST_VIEW_ACTIVITY } from "joynt/config";
import { useIdentity } from "joynt/hooks/participants";
import { useProfilePopover } from "joynt/participants";
import { MessageTime } from "components/DateDiff";
import NotificationStatus from "joynt/components/NotificationStatus";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
import NodeLink from "joynt/components/Post/NodeLink";
import { InfiniteScrollIterator } from "containers/list/ScrollPagination";
//import PropTypes from 'prop-types';

function Activity(props) {
    const { id } = props;

    const {
        activity_type,
        notification_type,
        created_at,
        identity,
        parent,
    } = useData(props);

    const { name } = useIdentity(identity);

    const {
        onShow: onShowProfile,
        onHide: onHideProfile,
    } = useProfilePopover();
    const show = useCallback(
        (e) => {
            onShowProfile(identity, e.currentTarget);
        },
        [onShowProfile, identity]
    );

    return (
        <div className={"cols cols-top gap-sm"}>
            <div className={"o-50"}>
                <MessageTime value={created_at} />
            </div>
            <div className={"activity grow rows gap-xs"}>
                <div className={"cols cols-spread"}>
                    <div>
                        {activity_type} {notification_type}
                    </div>
                    <NotificationStatus id={id} />
                </div>
                <div className={"cols gap-xs type-sm cols-middle"}>
                    <div
                        className={"cols gap-xs cols-middle"}
                        onClick={show}
                        onMouseLeave={onHideProfile}
                    >
                        <IdentityAvatarContainer id={identity} size={"xs"} />
                        <div>{name}</div>
                    </div>
                    <div className={"inline"}>
                        <span>in</span>
                        <NodeLink id={parent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const ActivityIterator = InfiniteScrollIterator(Activity);

export default function ActivityLog(props) {
    const { id, type, list } = props;
    const url = `joynt/nodes/${id}/activity-log`;

    return (
        <>
            <div className={"activity-feed"}></div>
            <ListView
                className={"activity-feed rows gap-sm"}
                type={type}
                view={LIST_VIEW_ACTIVITY}
                list={list}
                url={url}
                filter={type}
                append={true}
                useWindowScroll={false}
                Iterator={ActivityIterator}
            />
        </>
    );
}

ActivityLog.propTypes = {};
ActivityLog.defaultProps = {};
