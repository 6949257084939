import React, {useCallback, useState} from 'react';
import StageCover from "joynt/components/SessionFlow/StageCover";
import useInterval from "util/hooks/useInterval";
import Button from "components/Button";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useApiActions} from "state/hooks";
import {switchToSoloSession} from "joynt/components/Witness/actions";

//import PropTypes from 'prop-types';

export function WitnessHold(props) {

    const {
        message,
        duration
    } = props;

    const [elapsed, setElapsed] = useState(0);
    const {session} = useMeeting();

    useInterval(() => {
        setElapsed((v) => v+1);
    }, 1000);

    const showOptions = elapsed > duration;

    const {
        onSolo
    } = useApiActions({
        onSolo: switchToSoloSession
    });

    const solo = useCallback(() => {
        onSolo(session);
    }, [onSolo, session]);

    return (<StageCover
        title={message}
        transparent={true}
    >
        After some time ({duration}) allow to end or switch to solo
        {showOptions && <div className={"cols cols-center"}>
            <Button onClick={()=>null} variant={"outlined"} size={"large"}>
                Reschedule
            </Button>
            <Button onClick={solo} color={"primary"} size={"large"}>
                Continue
            </Button>
        </div>}
    </StageCover>);

}

WitnessHold.propTypes = {};
WitnessHold.defaultProps = {};

export default WitnessHold;
