export default class MediaDevices {
    constructor(client) {
        this.client = client;
        this.available = {};
        this.selected = {};
        this.initial = true;
    }

    setInitial = (state) => {
        this.initial = state;
    }

    audioInput = () => this.selected.audioInput;
    videoInput = () => this.selected.videoInput;

    update = (data) => {
        this.generateEvents(data);
        this.available = data.available;
        this.switchDevices(this.selected, data.selected).then(() => {
        });
    }

    switchDevices = async (from, to) => {
        if (from.videoInput !== to.videoInput) {
            if (this.client.rtc.tracks("video").length > 0) {
                await this.client.unpublishVideoTrack();
                this.selected.videoInput = to.videoInput;
                if (to.videoInput) {
                    await this.client.publishVideoTrack();
                }
            } else {
                this.selected.videoInput = to.videoInput;
            }
        }

        if (from.audioInput !== to.audioInput) {
            if (this.client.rtc.tracks("audio").length > 0) {
                await this.client.unpublishAudioTrack();
                this.selected.audioInput = to.audioInput;
                if (to.audioInput) {
                    await this.client.publishAudioTrack();
                }
            } else {
                this.selected.audioInput = to.audioInput;
            }
        }
    }

    generateEvents = (data) => {
        const names = this.deviceNames(data);

        ['videoInput', 'audioInput'].forEach(type => {
            let diff = this.diff(type, data);
            if (diff.changed) {
                if (diff.disconnected.indexOf(this.selected[type]) > -1) {
                    this.client.trigger({
                        event: 'media-device-changed',
                        id: [type,this.selected[type]].join('-'),
                        type: 'DEVICE_DISCONNECTED',
                        deviceType: type,
                        deviceName: names[this.selected[type]]
                    });
                }
                if (diff.connected.length && !this.initial) {
                    this.client.trigger({
                        event: 'media-device-changed',
                        id: [type,diff.connected[0]].join('-'),
                        type: 'DEVICE_CONNECTED',
                        deviceType: type,
                        deviceName: names[diff.connected[0]]
                    });
                }
            }
        });
    }

    diff = (type, next) => {
        const prevIds = (this.available[type] || []).map(d => d.deviceId);
        const nextIds = (next.available[type] || []).map(d => d.deviceId);

        const connected = nextIds.filter(id => prevIds.indexOf(id) === -1);
        const disconnected = prevIds.filter(id => nextIds.indexOf(id) === -1);

        return {
            connected,
            disconnected,
            changed: !!(connected.length || disconnected.length)
        }
    }

    deviceNames = (data) => {
        let all = {};
        let sources = [
            data.available.videoInput,
            data.available.audioInput,
            this.available.videoInput,
            this.available.audioInput
        ];
        sources.forEach(source => {
            if (source) {
                source.forEach(device => {
                    all[device.deviceId] = device.label;
                })
            }
        });
        return all;
    }
}