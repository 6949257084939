import Echo from "laravel-echo";
import {authHeaders} from "util/api";
import {useEffect, useMemo} from "react";
import env from "util/env";

window.__subscriptions = {};

function useWebsocketAuthHeaders() {
    const auth = authHeaders({});
    const {Authorization, 'X-zuu-api-key': apiKey} = auth;
    return useMemo(() => ({
        Authorization: Authorization,
        'X-zuu-api-key': apiKey
    }), [Authorization, apiKey]);
}

export function Broadcasting(props) {
    const auth = useWebsocketAuthHeaders();
    useEffect(() => {
        if (window.Echo) delete window.Echo;
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: env('ECHO_SERVER'),
            auth: {
                headers: auth
            },
            disableStats: true,
            enabledTransports: ['ws', 'wss', 'websocket']
        });
    }, [auth]);

    return props.children;
}

export const configureBroadcasting = (server) => {
    window.io = require('socket.io-client');
};

export const subscribe = (channel, namespace, handler) => {
    const id = [channel, namespace].join('/');
    if (window.__subscriptions[id]) return;

    if (!window.Echo) return;
    window.Echo.channel(channel)
        .listen(namespace, (e) => {
            handler(e)
        });
    window.__subscriptions[id] = true;
};