import {useEffect, useMemo} from 'react';
import {useRouteNodes} from "joynt/hooks/channels";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {useApiActions, useUiFlag} from "state/hooks";
import {publishState} from "joynt/state/actions/presence";
import {useMouseActive} from "util/hooks/useMouseActive";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import {usePresenceId, useUserProfile} from "joynt/hooks";
//import PropTypes from 'prop-types';

export default function PresenceState(props) {

    const {channel} = useRouteNodes();
    const id = usePresenceId();
    const {session} = useMeeting();
    const [{joined}] = useConnectionState(session);
    const {onPublish} = useApiActions({
        onPublish: publishState
    });
    const {active} = useMouseActive(1000 * 60 * 10, true);
    const {away_mode} = useUserProfile();

    const isActive = active && !away_mode;

    const joinedSession = joined && session ? session : null;
    const [live] = useUiFlag(`meeting.${joinedSession}.live`);

    const data = useMemo(() => ({
        id,
        channel,
        session: joinedSession,
        live,
        away: !isActive && !joinedSession
    }), [
        id,
        channel,
        joinedSession,
        live,
        isActive
    ]);

    useEffect(() => {
        onPublish(data);
    }, [onPublish, data]);

    const {children} = props;
    return children || null;

}

PresenceState.propTypes = {};
PresenceState.defaultProps = {};
