export default {
    "console.services.website": "desktop_windows",
    "console.services.cms": "description",
    "console.servers": "dns",
    "console.domains": "public",
    "console.typography": "format_color_text",
    "console.colors": "color_lens",
    "console.themes": "style",
    "console.presets": "tune",
    "console.services": "storage",
    "console.users": "person",
    "console.services.apartments": "domain",
    "console.services.internal": "settings",
    "console.services.cluster": "group_work",
    "console.api_keys": "vpn_key",
    "console.services.db": "storage",
    "console.cron_tasks": "schedule",
    "console.user-service-access": "how_to_reg",
};
