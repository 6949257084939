import { useSelector } from "react-redux";
import { selectEntity, selectList } from "state/selectors/data";
import { selectList as selectListItems } from "state/selectors/lists";
import { FILTER_NS } from "config/list-aliases";

export function useMediaList(path, isSearch) {
    const list = useSelector((store) => {
        const filterValue = selectEntity(store, FILTER_NS, "cms.media/browser");
        const ids = selectListItems(store, "cms.media", "browser/filter");
        const data = selectList(store, "cms.media", "browser");
        if (!filterValue.search) return data;
        return data.filter((item) => ids.includes(item.id));
    });

    if (isSearch && !path) return list;

    return list.filter((item) => {
        if (path === "videos") {
            return item.type === "video";
        }
        if (path === "pano") {
            return item.type === "pano";
        }
        const filePath = item.file?.split("/");
        if (!filePath) return true;
        const dir = filePath.slice(2, filePath.length - 1).join("/");
        if (!dir && !path) return true;
        return dir === path;
    });
}
