import React from "react";
import PropTypes from "prop-types";

import { SessionItem } from "joynt/components/Schedule/SessionList";
import "./style.css";

export function DaySessions(props) {
    const { items, Component } = props;

    return (
        <div className={"day-group"}>
            <div className={"day-group__list"}>
                {items.map((session) => {
                    return (
                        <SessionItem
                            key={session}
                            id={session}
                            Component={Component}
                        />
                    );
                })}
            </div>
        </div>
    );
}

DaySessions.propTypes = {
    items: PropTypes.array,
};
DaySessions.defaultProps = {
    items: [],
};

export default DaySessions;
