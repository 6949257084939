import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {VideoTile} from "joynt/meeting/stage";
import {useAgoraClient} from "./hooks";
import {useTrackVolume} from "joynt/meeting/stage/VolumeLevels";

function Subscriber(props) {
    const {id, client} = props;

    useEffect(() => {
        client.mountTile(id).then(()=>console.log(`Subscribe to ${id}`));
        return () => {
            client.unmountTile(id).then(()=>console.log(`Unsubscribe from ${id}`));
        }
    }, [client, id]);

    return null;
}

export default function VideoTileContainer(props) {
    const {user, local, quality, ...other} = props;

    const id = user.uid.toString() + '';
    const elementId = `stream-${id}`;

    const {
        audioTrack,
        videoTrack
    } = user;

    const audio = !!audioTrack;
    const video = !!videoTrack;
    const [volume] = useTrackVolume(id);
    const isScreen = id ? id.indexOf('screen_') > -1 : false;
    const identity = id ? id.replace('screen_', '') : null;
    const manageQuality = !local && !isScreen && video;

    const client = useAgoraClient();

    useEffect(() => {
        const setQuality = async function() {
            await client.setStreamFallbackOption(id, 2);
            await client.setRemoteVideoStreamType(id, quality);
        }
        if (manageQuality) setQuality().then(()=>null);
    }, [id, quality, manageQuality, client]);

    if (!id) return null;

    return <VideoTile
        {...other}
        identity={identity}
        audio={audio}
        video={video}
        own={local}
        volume={audio && volume}
        screen={isScreen}
    >
        <Subscriber id={id} client={client} />

        {user && videoTrack ? <VideoElement
            id={elementId}
            videoTrack={videoTrack}
        /> : null}

    </VideoTile>
}

VideoTileContainer.propTypes = {
    quality: PropTypes.oneOf([0, 1])
};
VideoTileContainer.defaultProps = {
    quality: 0
};

function VideoElement({id, videoTrack}) {
    const ref = e => {
        if (!e) return;
        const videoEl = e.querySelector('div');
        if (!videoEl && videoTrack) videoTrack.play(id);
    }

    return <div
        ref={ref}
        id={id}
        className={'video-stream'}
    />
}
