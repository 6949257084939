import {selectList} from "state/selectors/lists";

function timeToDay(time) {
    const date = new Date(time*1000);
    date.setHours(0,0,0,0);
    return date.getTime()/1000;
}


export function selectSessionCountByDay(store, list) {
    const ids = selectList(store, "db.nodes", list) || [];
    const data = store.data['db.nodes'] || {};
    const index = {};
    ids.forEach(id => {
        let item = data[id] || {};
        let start = timeToDay(item.event_start);
        if (!index[start]) index[start] = 0;
        index[start]++;
    });
    return index;
}