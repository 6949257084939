import React, { useCallback } from "react";
import PropTypes from "prop-types";

import UtilityNav from "./UtilityNav";
import { useUtilityNav } from "./hooks";
import { useIsSessionView } from "joynt/hooks/sessions";
import { useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";

export function UtilityNavContainer(props) {
    //const {id} = props;

    const data = useNodeData(props.id);
    const isHome = data?.node_type === "root";

    const [view, setView] = useUtilityNav();

    const show = useCallback(
        (id) => {
            setView(id);
        },
        [setView]
    );

    const isStage = useIsSessionView();

    const isPage = useSelector((s) => s.router.location.pathname === "/public");

    return (
        <>
            <UtilityNav
                {...props}
                view={view}
                onShow={show}
                isHome={isHome}
                isHiddenNav={!!isStage}
                isPage={isPage}
            />
        </>
    );
}

UtilityNavContainer.propTypes = {
    id: PropTypes.string,
};
UtilityNavContainer.defaultProps = {};

export default UtilityNavContainer;
