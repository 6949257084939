import { store as libraryStore } from "playground/cms/views/Sections/SectionBrowser/store";
import { selectEntity } from "state/selectors/data";

export function getLibraryStore(id) {
    if (id && id !== "local") {
        return libraryStore;
    }
    return require("store").store;
}

export function getLibraryState(id) {
    return getLibraryStore(id).getState();
}

export function selectLibrarySection(state, id) {
    return selectEntity(libraryStore.getState(), "cms.sections", id);
}
