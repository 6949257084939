import React from "react";
import { bindApiActions as actions } from "util/api/redux";

export const ApiContext = React.createContext("");

export default ApiContext;
export const bindApiActions = actions;

export const withApiContext = () => {
    return function (Decorated) {
        return class ApiContextDecorator extends React.PureComponent {
            render() {
                return (
                    <ApiContext.Consumer>
                        {(value) => {
                            return (
                                <Decorated {...this.props} apiContext={value} />
                            );
                        }}
                    </ApiContext.Consumer>
                );
            }
        };
    };
};

export const withApi = (apiConfig) => {
    return function (Decorated) {
        return class ApiContextDecorator extends React.PureComponent {
            render() {
                return (
                    <ApiContext.Provider value={apiConfig}>
                        <Decorated {...this.props} />
                    </ApiContext.Provider>
                );
            }
        };
    };
};
