import React, { useEffect } from "react";
import Session from "joynt/components/Session";
import { useUiFlag } from "state/hooks";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import useConnectionState from "joynt/meeting/hooks/useConnectionState";
import PendingSession from "joynt/meeting/PendingSession";
import useScreenSize from "util/hooks/useScreenSize";

//import PropTypes from 'prop-types';

export function SessionStage() {
    const { session } = useMeetingSession();
    const [sessionView] = useUiFlag(`layout.collapse.${session}`);
    const [{ joined, loading }] = useConnectionState(session);

    const [, setFloating] = useUiFlag(`session.isFloatingSession`);

    useEffect(() => {
        setFloating(!sessionView);
    }, [sessionView, setFloating]);

    const { isMobile } = useScreenSize();

    const showLoader = sessionView && session && loading;
    const showStage = (sessionView || isMobile) && session && joined;

    return (
        <>
            <PendingSession visible={showLoader} />
            {showStage && <Session id={session} session={session} />}
        </>
    );
}

SessionStage.propTypes = {};
SessionStage.defaultProps = {};

export default SessionStage;
