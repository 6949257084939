import React from "react";
import { createPortal } from "react-dom";
import IconButton from "components/IconButton";
import { useUiFlag } from "state/hooks";
import { NotificationsPopup } from "playground/cms/Notifications";

//import PropTypes from 'prop-types';

export function NotificationsIcon(props) {
    const {} = props;
    const [open, setOpen] = useUiFlag("console.notifications.open");
    return (
        <>
            <IconButton
                icon={"mui-notifications"}
                onClick={() => setOpen(!open)}
            />
            {createPortal(
                <NotificationsPopup
                    open={open}
                    onClose={() => setOpen(false)}
                />,
                document.body
            )}
        </>
    );
}

NotificationsIcon.propTypes = {};

NotificationsIcon.defaultProps = {};

export default NotificationsIcon;
