import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import Field from "containers/Field";

export function SectionContentLegacy({ id }) {
    return (
        <>
            <Field
                id={"title"}
                label={"Section title"}
                className={"page-title"}
            />
            <FormLayout.Section name={"Elementy"}>
                <Field
                    id={"items"}
                    fieldType={"collection"}
                    fieldEntityType={"cms.sections"}
                    fieldEntityId={id}
                />
            </FormLayout.Section>
        </>
    );
}

SectionContentLegacy.propTypes = {};

SectionContentLegacy.defaultProps = {};

export default SectionContentLegacy;
