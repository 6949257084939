import React, {useState, useCallback} from 'react';
//import PropTypes from 'prop-types';

import Section from 'cms/containers/Section';
import VisibilitySensor from "react-visibility-sensor/dist/visibility-sensor";
import {withData} from "containers/Entity";
import {SortableIterator} from "components/dnd";

export default function SectionThumbnail(props, ref) {

    const { id, section, onClick, onContextMenu } = props;
    const { title, description } = props.data;
    const [isVisible, changeVisibility] = useState(false);

    const click = useCallback(() => {
        if (onClick) onClick(id);
    }, [onClick, id]);

    const context = useCallback(() => {
        if (onContextMenu) onContextMenu(id);
    }, [onContextMenu, id]);

    return (<VisibilitySensor onChange={changeVisibility}>
        <div
            ref={ref}
            className={'section-thumbnail'}
            onClick={click}
            onContextMenu={context}
        >
            { isVisible ? <div className={'section-thumbnail-content'}>
                <Section
                    {...props}
                    previewTemplate={section ? id : null}
                    id={section || id}
                />
            </div> : null }
            <div className={'section-thumbnail-label'}>
                {description || title}
            </div>
        </div>
    </VisibilitySensor>);

}

const SectionThumbnailRef = React.forwardRef(
    SectionThumbnail
);

SectionThumbnail.propTypes = {};

SectionThumbnail.autoFillProps = [
    'title',
    'description'
];

export const SectionThumbnailIterator = SortableIterator(
    withData(SectionThumbnailRef)
);