import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import StylePanel from "./StylePanel";
import StyleProperty from "./StyleProperty";
import Select from "./Select";
import ClassesInput from "./ClassesInput";
import Switch from "@material-ui/core/Switch";
import Attributes from "playground/theme/StyleEditor/Attributes";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

function selectConfig(store) {
    const styles = store.data?.["cms.styles"] || {};

    return {
        options: styles.options || {},
        option_types: styles.option_types,
        option_roles: styles.option_roles,
        element_roles: styles.element_roles,
        element_groups: styles.element_groups,
    };
}

function generateOptionValuesIndex(options) {
    return Object.keys(options).reduce((acc, option) => {
        if (option === "inherit") return acc;
        options[option].forEach((value) => {
            acc[value] = option;
        });
        return acc;
    }, {});
}

function useMatchClasses(config) {
    const valuesIndex = useMemo(
        () => generateOptionValuesIndex(config.options),
        [config.options]
    );

    const match = (classes) => {
        const optionsMatchedFromClasses = classes.reduce((acc, className) => {
            const option = valuesIndex[className];
            if (option && option !== "inherit") {
                acc[option] = className;
            }
            return acc;
        }, {});

        const unmatchedClasses = classes.filter((className) => {
            return !valuesIndex[className];
        });

        return {
            matched: optionsMatchedFromClasses,
            unmatched: unmatchedClasses,
        };
    };

    return useCallback(match, [valuesIndex]);
}

function OptionSelect(props) {
    const { options, value, defaultValue, onChange } = props;
    return (
        <Select
            options={options}
            value={value}
            onChange={onChange}
            placeholder={defaultValue}
            nullable={true}
        />
    );
}

function OptionInput(props) {
    const { value, onChange } = props;
    const [state, setState] = useState(null);
    const val = value?.[0];
    let displayedValue = val;
    if (state !== null) displayedValue = state;

    const change = (e) => setState(e.target.value);

    const submit = () => {
        if (state === "" && val !== null) {
            onChange(null);
            setState(null);
            return;
        }
        if (state === value?.[0]) return;
        onChange(state);
        setState(null);
    };

    return (
        <input
            type={"text"}
            value={displayedValue || ""}
            onChange={change}
            onBlur={submit}
        />
    );
}

function OptionCheckbox(props) {
    const { onChange, value } = props;
    const val = !!value?.[0];
    const change = () => {
        onChange(val ? null : "true");
    };
    return (
        <div className={"cols cols-right grow"}>
            <Switch checked={val} onChange={change} />
        </div>
    );
}

const optionTypeComponents = {
    select: OptionSelect,
    value: OptionInput,
    boolean: OptionCheckbox,
};

function OptionValue(props) {
    const { type, options } = props;
    const Component = optionTypeComponents[type] || OptionInput;
    return <Component {...props} />;
}

OptionValue.defaultProps = {
    options: [],
    type: "select",
};

function optionProps(config, id) {
    const options = config.options[id];
    const type = config.option_types[id]?.type;
    return {
        options,
        type,
    };
}

function OptionGroup(props) {
    const { id, config, options, values, onChange } = props;

    const [expanded, setExpanded] = useState(false);

    const change = (option, value) => {
        onChange(option, value !== null ? [value] : null);
    };
    const definedOptions = options.filter((o) => {
        return ![null, undefined].includes(values?.[o]);
    });
    const undefinedOptions = options.filter((o) => {
        return !definedOptions.includes(o);
    });
    const visibleOptions = expanded
        ? [...definedOptions, ...undefinedOptions]
        : definedOptions;

    return (
        <StylePanel
            name={`${id} options`}
            action={
                expanded ? (
                    <Icon>mui-expand_less</Icon>
                ) : (
                    <Icon>mui-expand_more</Icon>
                )
            }
            onHeaderClick={() => setExpanded(!expanded)}
        >
            {visibleOptions.map((option) => {
                return (
                    <StyleProperty
                        key={option}
                        label={option}
                        isEmpty={!values[option]}
                    >
                        <OptionValue
                            id={option}
                            {...optionProps(config, option)}
                            value={values[option]}
                            onChange={(v) => change(option, v)}
                        />
                    </StyleProperty>
                );
            })}
        </StylePanel>
    );
}

OptionGroup.defaultProps = {
    options: [],
    config: {},
};

function selectParentStyles(store, type, style) {
    return [type, style].join("/");
}

export function Atomic(props) {
    const {
        type,
        element,
        options,
        classes,
        values,
        style,
        onAddClass,
        onRemoveClass,
        onChangeClasses,
        onChangeOption,
        onSearch,
        parentStyles,
    } = props;

    const config = useSelector(selectConfig);

    const valuesIndex = useMemo(
        () => generateOptionValuesIndex(config.options),
        [config.options]
    );

    const match = useMatchClasses(config);
    //if classes is array

    const { matched, unmatched } = Array.isArray(classes)
        ? match(classes)
        : { matched: {}, unmatched: {} };

    const optionGroupsIndex = useMemo(() => {
        const groups = config.element_groups || {};
        return Object.keys(groups).reduce((acc, group) => {
            const options = groups[group];
            options.forEach((option) => {
                acc[option] = group;
            });
            return acc;
        }, {});
    }, [config.element_groups]);

    const handleAddClass = (name) => {
        if (valuesIndex[name]) {
            return handleChangeClass(valuesIndex[name], name);
        }
        onAddClass(name);
    };

    const handleChangeClass = (key, value) => {
        const next = { ...matched };
        next[key] = value;
        const nextClasses = [...Object.values(next), ...unmatched].filter(
            (v) => !!v
        );
        onChangeClasses(nextClasses);
    };

    const roles = config?.element_roles?.[element] || [];

    const elementClassOptions = roles.reduce((acc, role) => {
        const options = config?.option_roles?.[role] || [];
        return [...acc, ...options];
    }, []);

    const matchedParent = parentStyles
        .map((s) => {
            const value = s[element];
            if (!value) return null;
            return match(value);
        })
        .filter(Boolean)
        .reduce(
            (acc, s) => {
                acc.matched = { ...acc.matched, ...(s.matched || {}) };
                acc.unmatched = [...acc.unmatched, ...(s.unmatched || [])];
                return acc;
            },
            {
                matched: {},
                unmatched: [],
            }
        );

    const visibleClassOptions = Object.keys(matched)
        .concat(Object.keys(matchedParent.matched))
        .concat(elementClassOptions)
        .filter((option, index, arr) => {
            return arr.indexOf(option) === index;
        });

    const avClassOptions = Object.keys(config.options).filter((option) => {
        return (
            !elementClassOptions.includes(option) &&
            !config.option_types[option]
        );
    });

    const componentOptionGroups = options.reduce((acc, option) => {
        const group = optionGroupsIndex[option] || type;
        acc[group] = acc[group] || [];
        if (option !== "@attributes") acc[group].push(option);
        return acc;
    }, {});

    const hasAttributes = options.includes("@attributes");

    return (
        <>
            {/*{JSON.stringify(matchedParent.unmatched)}*/}
            <ClassesInput
                value={unmatched}
                onAdd={handleAddClass}
                onRemove={onRemoveClass}
                onSearch={onSearch}
                inheritance={!!style}
            />
            <div className={"style-properties atomic"}>
                <StylePanel name={`${element} classes`}>
                    {visibleClassOptions.map((option) => {
                        return (
                            <StyleProperty
                                key={option}
                                label={option}
                                isEmpty={!matched[option]}
                            >
                                <OptionValue
                                    id={option}
                                    {...optionProps(config, option)}
                                    value={matched[option]}
                                    defaultValue={matchedParent.matched[option]}
                                    onChange={(v) =>
                                        handleChangeClass(option, v)
                                    }
                                />
                            </StyleProperty>
                        );
                    })}
                </StylePanel>
                {Object.keys(componentOptionGroups).map((group) => {
                    return componentOptionGroups[group]?.length ? (
                        <OptionGroup
                            config={config}
                            id={group}
                            options={componentOptionGroups[group]}
                            values={values}
                            onChange={onChangeOption}
                        />
                    ) : null;
                })}

                {hasAttributes && (
                    <StylePanel name={"@attributes"}>
                        <Attributes
                            value={values["@attributes"]}
                            onChange={(v) => onChangeOption("@attributes", v)}
                        />
                    </StylePanel>
                )}
            </div>
        </>
    );
}

Atomic.propTypes = {};

Atomic.defaultProps = {
    options: [],
    values: {},
    classes: [],
    parentStyles: [],
};

export default Atomic;
