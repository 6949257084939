import React, { useCallback } from "react";

import About from "./about";
import Agenda from "./Agenda";
import { useNodeData } from "joynt/hooks";
import { useRouteParam } from "state/hooks";
import { ROUTE_PAGE } from "joynt/config";
import UnderConstructionBar from "joynt/components/UnderConstructionBar";
import Button from "components/Button";
import WorkspaceHeader from "joynt/components/WorkspaceHeader";
import SessionsSlider from "joynt/components/SessionsSlider";

function UnpublishedWorkspace(props) {
    const [, setPage] = useRouteParam(ROUTE_PAGE);
    const click = useCallback(() => setPage("invite-members"), [setPage]);

    return (
        <UnderConstructionBar animated={true}>
            <div className={"cols cols-middle gap-sm"}>
                <div className={"text-center type-sm"}>
                    Your workspace is under construction.
                    <br />
                    Publish it and invite members when you'll be ready
                </div>
                <Button onClick={click} variant={"outlined"}>
                    Publish and invite
                </Button>
            </div>
        </UnderConstructionBar>
    );
}

export default function WorkspaceOverview(props) {
    const { id } = props;
    const { published, can_edit } = useNodeData(id);
    return (
        <div className={"rows gap-md"}>
            {!published && can_edit && (
                <div className={"pad-sm default rows rows-center"}>
                    <UnpublishedWorkspace />
                </div>
            )}
            <WorkspaceHeader {...props} />
            <SessionsSlider {...props} />
            <About {...props} />
            <Agenda {...props} showTimeline={false} />
        </div>
    );
}

WorkspaceOverview.propTypes = {};
WorkspaceOverview.defaultProps = {};
