import React from 'react';
import {connect} from 'react-redux';
import {setPage} from "state/actions/pagination";
import {selectPage, selectPageCount} from "state/selectors/pagination";
import cn from 'classnames';

import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from "@material-ui/core/CircularProgress";
import {isPending} from "state/selectors/ui";

const mapStateToProps = (store, props) => {
    const page = selectPage(store, props.type, props.list);
    const total = selectPageCount(store, props.type, props.list);
    return {
        page,
        total,
        hasMore: page < total,
        pending: isPending(store, props.list)
    }
};

const mapDispatchToProps = {
    setPage: setPage
};

function ScrollLoader(props) {
    const { page, total } = props;
    return <div className={'scroll-loader cols gap-sm cols-center'}>
        <div style={{ color: 'gray' }}>
            <CircularProgress size={20} color={'inherit'} />
        </div>
        <div>{page}/{total}</div>
    </div>;
}

class ScrollPagination extends React.PureComponent {

    loadNextPage = () => {
        console.log('Load next page');
        const { page } = this.props;
        this.loadPage(page ? page+1 : 1);
    };

    loadPage = (page) => {
        const { setPage, filter } = this.props;
        setPage(filter, page);
    };

    render() {
        const {
            hasMore,
            page,
            total,
            children,
            reverse,
            useWindowScroll,
            pending,
            className
        } = this.props;

        return (<InfiniteScroll
            pageStart={0}
            threshold={1}
            initialLoad={false}
            useWindow={!!useWindowScroll}
            hasMore={hasMore}
            isReverse={reverse}
            loadMore={this.loadNextPage}
            loader={<ScrollLoader
                key={'loader'}
                page={page}
                total={total}
                pending={pending}
            />}
            className={cn(
                className,
                {
                    'scroll-content': !reverse,
                    'is-pending': pending
                }
            )}
        >
            {children}
        </InfiniteScroll>);
    }

}

const ConnectedScrollPagination =  connect(
    mapStateToProps,
    mapDispatchToProps
)(ScrollPagination);

export default ConnectedScrollPagination;

export const InfiniteScrollIterator = (ItemRenderer) => {
    return function({items, reverse, className, ...other}) {
        return <ConnectedScrollPagination
            reverse={reverse}
            className={className}
            {...other}
        >
            {reverse
                ? items.map(item=><ItemRenderer key={item} id={item} {...other} />).reverse()
                : items.map(item=><ItemRenderer key={item} id={item} {...other} />)}
        </ConnectedScrollPagination>;
    }
};