import React from "react";
import TextField from "components/fields/text/TextField";
import { useFilter } from "state/hooks";

//import PropTypes from 'prop-types';

export function Search(props) {
    const { type, name } = props;
    const { value, onSet } = useFilter(type);

    return (
        <TextField
            label={"Search"}
            variant={"search"}
            value={value[name]}
            onChange={(v) => onSet(name, v)}
            debounce={true}
        />
    );
}

Search.propTypes = {};

Search.defaultProps = {
    name: "search",
};

export default Search;
