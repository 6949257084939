import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import WorkspaceStage from "./WorkspaceStage";
import {useStage} from "joynt/hooks/sessions";
import Session from "joynt/components/Session";
import {useRouteParam, useUiFlag} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {useNodeData} from "joynt/hooks";

export function WorkspaceStageContainer(props) {

    const {id} = props;

    const {
        isJoining,
        isPending,
        isConnected,
        error,
        eventId,
        session,
        id: stageSessionId
    } = useStage(id);

    const [,setDay] = useUiFlag(`scheduleSelectedDay`);

    useEffect(() => {
        if (isJoining) {
            const today = (new Date());
            today.setHours(0,0,0, 0);
            setDay(today.getTime()/1000);
        }
    }, [isJoining, setDay]);

    const [,setPage] = useRouteParam(ROUTE_PAGE);
    const {can_edit} = useNodeData(id);

    const edit = useCallback(() => {
        setPage(`schedule.${id}`);
    }, [setPage, id]);

    // if (isConnected || error)
    //     return <Session
    //         id={eventId || session}
    //         session={session}
    //     />;

    return <>
        <WorkspaceStage
            id={id}
            stageSessionId={stageSessionId}
            isJoining={isJoining}
            isConnected={isConnected}
            isPending={isPending}
            canEdit={can_edit}
            onShowSettings={edit}
        >
            {(isConnected || error) && <div className={"workspace-session"}>
                <Session
                    id={eventId || session}
                    session={session}
                />
            </div>}
        </WorkspaceStage>
    </>

}

WorkspaceStageContainer.propTypes = {
    id: PropTypes.string
};
WorkspaceStageContainer.defaultProps = {};

export default WorkspaceStageContainer;