import React from 'react';
import PropTypes from 'prop-types';

export function EmptyState(props) {

    const {text} = props;
    return (<div className={"rows gap-sm pad-sm rows-center"}>
        <div className={"joynt-cloud-circle"} />
        <div className={"o-50 text-center"}>
            {text}
        </div>
    </div>);

}

EmptyState.propTypes = {
    text: PropTypes.string
};
EmptyState.defaultProps = {
    text: "There are no featured sessions scheduled"
};

export default EmptyState;
