import React from "react";
import Field from "containers/Field/Field";

//import PropTypes from 'prop-types';

export function Map(props) {
    const {} = props;
    return (
        <>
            {props.children}
            <Field
                id={"properties"}
                label={"Obiekty"}
                fieldType={"select"}
                multiple={true}
                source={"api:cms.properties"}
            />
        </>
    );
}

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
