import React, { useCallback } from "react";
import PropTypes from "prop-types";
import PhoneHeader from "./PhoneHeader";
import { useUserSettings } from "joynt/components/UserSettings";
import usePhoneNav from "../../hooks/usePhoneNav";
import { useRouteParam, useUiFlag } from "state/hooks";
import { NODE_TYPE_CHAT, ROUTE_THREAD } from "joynt/config";
import { useRouteNodes } from "joynt/hooks/channels";
import { useNode, useNodeParent, useThread } from "joynt/hooks";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import PostIdentity from "joynt/post/PostIdentity";

export function PhoneHeaderContainer(props) {
    const { id } = props;

    const { identity } = useUserSettings(id);

    const { value: view } = usePhoneNav();
    const [thread, onShowThread] = useRouteParam(ROUTE_THREAD);
    const { channel } = useRouteNodes();
    const currentId = channel || id;
    const { root, onLinkToParent } = useNodeParent(currentId);
    const [showSidebar, setShowSidebar] = useUiFlag("mobile.showSidebar");
    const { name, subtype } = useNode(currentId);
    const { name: parentName } = useNode(root);
    const currentThread = thread ? thread : currentId;
    const { genericName: threadName, identities } = useThread(currentThread);
    const [, setView] = useUtilityNav();
    const { value, onChange } = usePhoneNav();
    const openSideMenu = useCallback(
        (newValue) => onChange(newValue),
        [onChange]
    );
    const isInboxThread = view === "inbox" && thread;
    const isThread = isInboxThread || subtype === NODE_TYPE_CHAT;

    let displayName = name || threadName;
    let displayParentName = parentName;

    let parentReturn = null;
    if (root) parentReturn = onLinkToParent;

    if (view === "inbox") displayName = "Inbox";
    if (view === "people") displayName = "Menu";

    if (isThread) {
        displayName = threadName;
    }

    if (isInboxThread) {
        displayParentName = "Inbox";
        parentReturn = () => onShowThread(null);
    }

    const showProfile = () => {
        setView("profile");
        openSideMenu(!!value ? null : "people");
    };

    const toggleMenu = () => {
        setShowSidebar(!showSidebar);
        openSideMenu(null);
    };

    return (
        <PhoneHeader
            {...props}
            identity={identity}
            onGoBack={parentReturn}
            parentName={displayParentName}
            title={displayName}
            onShowUserSettings={showProfile}
            onToggleMenu={toggleMenu}
            isOpen={showSidebar}
        >
            {isThread && identities && <PostIdentity identities={identities} />}
        </PhoneHeader>
    );
}

PhoneHeaderContainer.propTypes = {
    id: PropTypes.string,
};
PhoneHeaderContainer.defaultProps = {};

export default PhoneHeaderContainer;
