import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const tabs = [
    { id: "properties", label: "Props", isDefault: true },
    { id: "attributes", label: "Attributes" },
    { id: "hyperscript", label: "Hyperscript" },
];

export function Tabs(props) {
    const { value, onChange } = props;

    return (
        <div className={"style-box style-btn-group"}>
            {tabs.map((tab) => {
                return (
                    <button
                        key={tab.id}
                        className={cn("style-btn", {
                            "is-active":
                                value === tab.id || (!value && tab.isDefault),
                        })}
                        onClick={() => onChange(tab.id)}
                    >
                        {tab.label}
                    </button>
                );
            })}
        </div>
    );
}

Tabs.propTypes = {};

Tabs.defaultProps = {};

export default Tabs;
