import React, { useCallback } from "react";
import { useActions, useData } from "state/hooks";
import { change } from "state/actions/data";
import IconButton from "components/IconButton";
import StyleEditor from "playground/theme/StyleEditor";
import cn from "classnames";

//import PropTypes from 'prop-types';

export function GlobalStyles(props) {
    const { id, style, onExit, onEditDefault } = props;
    const data = useData({ type: "cms.styles", id });
    const { onChange } = useActions({ onChange: change });
    const handleChange = useCallback(
        (value) => {
            return onChange("cms.styles", id, value);
        },
        [id, onChange]
    );

    const isGlobal = style.split(".").length === 1;
    const styleType = style.split(".")[0];

    return (
        <div>
            <div className={"global-styles-return"} onClick={onExit}>
                <IconButton icon={"mui-arrow_back"} size={"small"} />
                <div>Back to component styles</div>
            </div>
            <StyleEditor
                {...props}
                value={data}
                onChange={handleChange}
                header={
                    <div className={"global-styles-header"}>
                        <div className={"global-styles-name"}>{id}</div>
                        <div className={"style-box style-btn-group"}>
                            <button
                                className={cn({
                                    "is-active": id === style,
                                })}
                                onClick={() => onEditDefault(null)}
                            >
                                {style}
                            </button>
                            {!isGlobal && (
                                <button
                                    className={cn({
                                        "is-active": id !== style,
                                    })}
                                    onClick={() => onEditDefault(styleType)}
                                >
                                    default
                                </button>
                            )}
                        </div>
                        {id === style && (
                            <div className={"global-styles-info"}>
                                Style definition {id}. Applies to all components
                                using this style.
                            </div>
                        )}
                        {id !== style && (
                            <div className={"global-styles-info"}>
                                Global defaults. May apply to all {styleType}s
                            </div>
                        )}
                    </div>
                }
            />
        </div>
    );
}

GlobalStyles.propTypes = {};

GlobalStyles.defaultProps = {};

export default GlobalStyles;
