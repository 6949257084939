import React from 'react';
import {connect} from 'react-redux';
import Entrypoint from "containers/Entrypoint";
import ListView from "containers/ListView";
import FilterField from "containers/FilterField";
import {searchInput} from "playground/ListFormView";

const mapStateToProps = (store, props) => {
    return {}
};

const mapDispatchToProps = {};

class Performance extends React.Component {

    render() {
        return (<Entrypoint service={'atomic-playground'}>
            performance playground
            <div className={'dark rows gap-md'}>
                <FilterField
                    {...searchInput}
                    type={'cms.something'}
                    id={'search'}
                    label={'Search...'}
                />
                <ListView
                    type={'cms.pages'}
                    list={'cms.pages'}
                />
            </div>
        </Entrypoint>);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Performance);