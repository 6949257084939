import React from 'react';
import PostAuthor from "./PostAuthor";
import {useIdentity} from "joynt/hooks/participants";
import {IdentityAvatarContainer as Avatar} from "joynt/components/Avatar";

//import PropTypes from 'prop-types';

export function PostAuthorContainer(props) {

    const {id} = props;
    const {name} = useIdentity(id);
    return (<PostAuthor
        name={name}
        avatar={<Avatar id={id} size={"xs"}/>}
        {...props}
    />);

}

PostAuthorContainer.propTypes = {};
PostAuthorContainer.defaultProps = {};

export default PostAuthorContainer;