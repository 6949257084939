import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export function DotsNav(props) {
    const { count, current, onChange } = props;
    const items = [];

    for (let i = 0; i < count; i++) {
        items.push(
            <div
                key={i}
                onClick={() => onChange(i)}
                className={cn({ "is-active": i === current })}
            />
        );
    }

    return <div className={"slider-dots"}>{items}</div>;
}

DotsNav.propTypes = {
    count: PropTypes.number,
    current: PropTypes.number,
    onChange: PropTypes.func,
};
DotsNav.defaultProps = {
    count: 0,
};

export default DotsNav;
