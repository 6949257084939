import React from "react";
import PropTypes from 'prop-types';

import cn from "classnames";

import "./style.css";
import "./mobile.css";

export function Sidebar(props) {
    const { collapsed, children } = props;

    return <div className={cn("sidebar-layout", {
        "sidebar-layout--collapsed": collapsed
    })}>
        {children}
    </div>;
}

Sidebar.propTypes = {
    collapsed: PropTypes.bool
};
Sidebar.defaultProps = {
    collapsed: false
};

export default Sidebar;
