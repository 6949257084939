import React, { useMemo } from "react";
import { useSessionState } from "joynt/meeting/hooks/useSessionState";
import Webcam from "react-webcam";
import IconButton from "joynt/components/IconButton";
import cn from "classnames";
import { useMediaDevices } from "../devices";
//import PropTypes from 'prop-types';

export default function CameraPreview({ showPreview }) {
    const { audio, video, setAudio, setVideo } = useSessionState();

    const { selectedDevices } = useMediaDevices();

    const videoConstraints = useMemo(
        () => ({
            deviceId: selectedDevices.videoInput,
        }),
        [selectedDevices]
    );

    return (
        <div
            className={cn("cam-preview rows gap-md rows-bottom", {
                "no-webcam": !showPreview,
            })}
        >
            {showPreview && (
                <>
                    {video ? (
                        <Webcam
                            className={"cam-preview-video"}
                            videoConstraints={videoConstraints}
                        />
                    ) : (
                        <div className={"cols cols-center text-dark o-50"}>
                            Camera disabled
                        </div>
                    )}
                </>
            )}
            <div className={"cam-preview-controls cols cols-center gap-xs"}>
                <IconButton
                    withTooltip
                    size={"large"}
                    label={!video ? "Enable cam" : "Disable cam"}
                    icon={!video ? "mui-videocam_off" : "mui-videocam"}
                    onClick={() => setVideo(!video)}
                    disabled={false}
                    className={cn("session-mute", { on: !video })}
                />
                <IconButton
                    withTooltip
                    size={"large"}
                    label={!audio ? "Unmute" : "Mute"}
                    icon={!audio ? "mui-mic_off" : "mui-mic"}
                    onClick={() => setAudio(!audio)}
                    disabled={false}
                    className={cn("session-mute", { on: !audio })}
                />
            </div>
        </div>
    );
}

CameraPreview.propTypes = {};
CameraPreview.defaultProps = {};
