import React from 'react';
import {useActions} from "state/hooks";
import {notify} from "state/actions/ui";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function LinkCopyToClipboard(props) {
    const {value, feedbackText, icon, label} = props;

    const {notify: onNotify} = useActions({notify});

    const copy = () => {
        navigator.clipboard.writeText(value)
            .then(()=>onNotify(feedbackText));
    }

    return <div onClick={copy} className={'cols gap-sm cursor-pointer'}>
        <TextField
            value={value}
            label={label}
            variant={'outlined'}
            fullWidth={true}
            InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position={'end'}>
                    <Icon
                        className={'cursor-pointer'}
                        fontSize={'small'}
                    >
                        {icon}
                    </Icon>
                </InputAdornment>
            }}
        />
    </div>;
}

LinkCopyToClipboard.defaultProps = {
    feedbackText: 'Copied to clipboard!',
    icon: 'mui-content_paste',
    label: 'Link'
}

export default LinkCopyToClipboard;
