import React from "react";
import TagsField from "playground/cms/fields/TagsField";
import { MediaField } from "playground/cms/fields/Media";
import Field from "containers/Field";

//import PropTypes from 'prop-types';

export function EntryDescription(props) {
    const { mediaVariant } = props;

    return (
        <>
            <TagsField id={"tags"} label={"Tagi"} />
            <MediaField id={"media"} label={"Media"} variant={mediaVariant} />
            <Field
                id={"headline"}
                fieldType={"text"}
                label={"Podtytuł"}
                size={"large"}
            />
            <Field id={"text"} fieldType={"rich"} label={"Treść"} />
        </>
    );
}

EntryDescription.propTypes = {};

EntryDescription.defaultProps = {};

export default EntryDescription;
