import React from "react";
//import PropTypes from 'prop-types';

import Entries from "./../Entries";
import { FormLayout } from "playground/cms/Form";
import AttractionsForm from "./AttractionsForm";

export function Attractions(props) {
    const {} = props;
    return (
        <Entries {...props}>
            <FormLayout>
                <FormLayout.Content prepend={true}>
                    <FormLayout.Tab id={"attraction"} label={"Atrakcja"}>
                        <AttractionsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Entries>
    );
}

Attractions.propTypes = {};

Attractions.defaultProps = {};

export default Attractions;
