import {useCallback, useEffect} from "react";

export default function useWhisper(subscription, id, handler) {
    useEffect(() => {
        if (subscription && id) {
            subscription.listenForWhisper(id, handler);
        }
        return () => {
            if (subscription && id) subscription.stopListeningForWhisper(id);
        }
    }, [subscription, id, handler]);

    const share = useCallback((data) => {
        if (subscription) subscription.whisper(id, data);
    }, [subscription, id]);

    if (subscription && id) return share;
    return null;
}