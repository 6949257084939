import { TREE_EXPAND } from "config/list-aliases";

import { selectFieldValue, selectList } from 'state/selectors/data';
import { selectList as selectListIds } from 'state/selectors/lists';
import { getTreeFromFlatData } from "react-sortable-tree";

const nullObj = {};

export const selectExpanded = (store, type, list) => {
    let listId = [list, TREE_EXPAND].join('/');
    return selectListIds(store, type, listId);
};

export const selectIsExpanded = (store, type, list, id) => {
    return selectExpanded(store, type, list).indexOf(id) > -1;
};

export const selectTree = (store, type, list) => {
    const listData = selectList(store, type, list);
    const expanded = selectExpanded(store, type, list);
    const expandedList = listData.map(item => {
        return {...item, expanded: expanded.indexOf(item.id) > -1 }
    });
    const treeData = getTreeFromFlatData({
        flatData: expandedList,
        getKey: node => node.id,
        getParentKey: node => node.parent,
        rootKey: null
    });
    return treeData;
};

const childTypes = {
    'cms.items': 'cms.components',
    'cms.consents': 'cms.components'
}

export function findRoot(store, id, type) {
    if (!type || !id) return nullObj;
    function getParent(type, id) {
        return selectFieldValue(store, type, id, '__parent') || {};
    }
    let key = null;
    let parent = getParent(type, id);
    key = parent.key;
    parent = parent.id;
    let foundParent = parent;
    let i = 0;
    let childType = childTypes[type] || type;
    while(parent && i < 20) {
        parent = getParent(childType, parent);
        if (parent.id) {
            key = parent.key;
            parent = parent.id;
            foundParent = parent;
        } else {
            parent = null;
        }
        i++;
    }
    if (!foundParent) return nullObj;
    return {id:foundParent, key};
}