import React from "react";
//import PropTypes from "prop-types";

import "./list.css";
import "./list-dark.css";

export default function List(props) {
    const {children} = props;

    return <div className={'files-list'}>
        {children}
    </div>;
}

List.defaultProps = {};
List.propTypes = {};