import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

import {useApiActions} from "state/hooks";
import {post} from "state/actions/api";

import {
    selectEffectiveFlowPath,
    selectNodeFlowPath
} from "./../selectors";

import allFlows from "./../flows";

export function useDevSessionFlow(session) {

    const path = useSelector(s => selectNodeFlowPath(s, session));
    const effectivePath = useSelector(s => selectEffectiveFlowPath(s, session));

    const [flow] = path.split('.');
    const [forcedFlow] = effectivePath.split('.');

    const flows = useMemo(() => {
        return Object.keys(allFlows);
    }, []);

    const {onPost} = useApiActions({onPost: post});

    const onChangeFlow = useCallback((flow) => {
        const url = `joynt/nodes/${session}`;
        const data = {
            id: session,
            session_flow: flow
        }
        onPost(url, data, session, "db.nodes");
    }, [onPost, session]);

    return useMemo(() => ({
        flow,
        forcedFlow,
        flows,
        onChangeFlow
    }), [
        flow,
        forcedFlow,
        flows,
        onChangeFlow,
    ])

}