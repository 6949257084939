import { aliases } from "cms/util/templatable";
import { getPath, strip } from "./zmap";
import { NS_SCHEMA } from "state/selectors/schema";
import { selectTypeSchema } from "playground/designer/Zmap/selectors";

const blacklist = [
    "display",
    "used_in",
    "metadata",
    "component_metadata",
    "translations",
    "template_id",
    "style",
    "styles",
    "template_component",
    "custom_view",
    "custom_css_class",
    "__parent",
];

function makeCompletion(completion, token) {
    return {
        key: completion,
        completion: completion.replace(token, ""),
    };
}

function matchWithSearch(suggestions, str) {
    return suggestions
        .filter((suggestion) => !blacklist.includes(suggestion))
        .filter((suggestion) => suggestion.indexOf("#") === -1)
        .filter((suggestion) => !str || suggestion.indexOf(str) === 0)
        .map((suggestion) => makeCompletion(suggestion, str))
        .filter((suggestion) => !!suggestion.completion);
}

function getSearchPath(path) {
    if (path.indexOf(".") > 0) {
        return path.split(".").slice(0, -1).join(".");
    }
    if (path.indexOf("@") > 0) {
        return path.split("@")[0] + "@";
    }
    return path;
}

function valueToString(value) {
    const typeName = value === null ? "null" : typeof value;
    const typeStr = "<" + typeName + ">";
    if (["string", "number"].includes(typeof value)) {
        return [typeStr, value].join(" ");
    }
    return [typeStr, JSON.stringify(value)].join(" ");
}

function makeSuggestions(source, searchPath) {
    const result = matchWithSearch(Object.keys(source), searchPath);
    return result.map((item) => {
        return { ...item, value: valueToString(source[item.key]) };
    });
}

function makeSuggestionsWithSchema(store, source, searchPath) {
    if (!source["#schema"]) return makeSuggestions(source, searchPath);
    const schema = selectTypeSchema(
        store,
        source["#schema"],
        (field) => field.type
    );
    return makeSuggestions({ ...schema, ...source }, searchPath);
}

export function getSuggestions(store, inputValue, tokens, context) {
    if (!tokens) return [];
    if (tokens.length > 1) return [];

    const token = tokens[0];
    const inputFromValue = token.from;

    //if (!inputFromValue) return [];

    const fromValue = strip(inputFromValue) || "";
    const value = strip(inputValue) || "";

    if (fromValue.indexOf("@") === 0) {
        return makeSuggestions(aliases, fromValue);
    }

    const searchPath = getSearchPath(fromValue);
    const valueSearchPath = getSearchPath(value);
    const next = value.replace(valueSearchPath, "");

    const currentObjectContext = getPath(searchPath, context);

    if (currentObjectContext && typeof currentObjectContext === "object") {
        // console.log(
        //     `${valueSearchPath} resolved to `,
        //     currentObjectContext,
        //     `following with ${next}`
        // );
        //2 cases: value ends with . or value ends with @

        const pathEnd = value.split("").pop();
        const completionPath = next.split(".").filter(Boolean).join(".");
        if (!["@", "."].includes(pathEnd) && !completionPath) return [];

        return makeSuggestionsWithSchema(
            store,
            currentObjectContext,
            completionPath
        );
    }

    return makeSuggestionsWithSchema(store, context.local, fromValue);

    return [];
}
