import React from "react";
import PropTypes from "prop-types";
import ProfileInfo from "./ProfileInfo";
import NotificationSettings from "./NotificationSettings";
import ProfileSettings from "./ProfileSettings";
import Button from "components/Button";
import Icon from "components/Icon";
import IconButton from "joynt/components/IconButton";

import version from "util/version";
import { useNodeData } from "joynt/hooks";
import SpaceAvatarContainer from "joynt/components/SpaceAvatar";
import DevSettings from "joynt/components/UserSettings/DevSettings";
import SettingsGroup from "joynt/components/UserSettings/SettingsGroup";
import Features from "joynt/components/UserSettings/Features";

import "./style.css";

function ProfileView(props) {
    const { onChangeView } = props;
    return (
        <div className={"user-settings grow rows"}>
            <div
                className={"user-settings__header cols cols-middle gap-sm"}
                onClick={() => onChangeView(null)}
            >
                <Icon>mui-arrow_back</Icon>
                <div>Go back</div>
            </div>
            <div className={"user-settings__content rows gap-lg grow pad"}>
                <ProfileSettings {...props} />
            </div>
        </div>
    );
}

function Workspace(props) {
    const { id, onLeave } = props;
    const { name, user_role } = useNodeData(id);
    const isOwner = user_role === "owner";

    return (
        <div className={"user-settings__workspace pad rows"}>
            <div className={"rows gap-sm"}>
                <div className={"o-50"}>
                    {isOwner ? "You're owner of:" : "You are now joined to:"}
                </div>
                <div className={"cols cols-spread cols-middle"}>
                    <div className={"cols cols-middle gap-sm"}>
                        <SpaceAvatarContainer id={id} />
                        <div>{name}</div>
                    </div>
                    {!isOwner && onLeave && (
                        <Button
                            onClick={onLeave}
                            variant={"text"}
                            endIcon={<Icon>mui-logout</Icon>}
                        >
                            Leave
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

function MainSettingsView(props) {
    const {
        id,
        workspaceId,
        isAdmin,
        isDev,
        isPublicView,
        onLeave,
        onLogout,
        onChangeView
    } = props;

    const appVersion = version();

    return (
        <div className={"user-settings grow rows"}>
            <div className={"user-settings__content grow rows"}>
                <ProfileInfo id={id}>
                    <Button
                        onClick={() => onChangeView("profile")}
                        variant={"outlined"}
                    >
                        Edit profile
                    </Button>
                </ProfileInfo>
                {workspaceId && !isPublicView && (
                    <Workspace id={workspaceId} onLeave={onLeave} />
                )}
                {!isPublicView && <div className={"user-settings__settings-list"}>
                    <SettingsGroup name={"Notification settings"}>
                        <NotificationSettings isAdmin={isDev} />
                    </SettingsGroup>
                    <SettingsGroup name={"Beta features"}>
                        <Features />
                    </SettingsGroup>
                    {isAdmin && (
                        <SettingsGroup name={"Experimental features"}>
                            <DevSettings isDev={isDev} />
                        </SettingsGroup>
                    )}
                </div>}
            </div>
            {onLogout && (
                <div
                    className={
                        "user-settings__footer cols cols-spread cols-middle"
                    }
                >
                    <div>
                        Logout from Joynt{" "}
                        <span className={"app-version"}>{appVersion}</span>
                    </div>
                    <div>
                        <IconButton
                            className={"outlined"}
                            icon={"mui-logout"}
                            size={"medium"}
                            onClick={onLogout}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export function UserSettings(props) {
    const { id, view, onChangeView } = props;

    if (view === "profile")
        return <ProfileView id={id} onChangeView={onChangeView} />;

    return <MainSettingsView {...props} />;
}

UserSettings.propTypes = {
    id: PropTypes.string,
    workspaceId: PropTypes.string,
    onLeave: PropTypes.func,
    onLogout: PropTypes.func,
    view: PropTypes.oneOf(["profile"]),
    onChangeView: PropTypes.func,
};

UserSettings.defaultProps = {};

export default UserSettings;
