import React, { useState } from "react";
//import PropTypes from 'prop-types';
import TypographyFonts from "./TypographyFonts";
import { useActions } from "state/hooks";
import { importTypography } from "cms/state/actions/theme";
import { OverlayForm } from "playground/cms/Form";
import FontBrowser from "playground/theme/FontBrowser";

const fonts = ["body_font", "heading_font", "decorative_font"];

export function TypographyFontsContainer(props) {
    const { entityType, entityId, onChange, value } = props;

    const [select, setSelect] = useState(null);
    const { onImport } = useActions({ onImport: importTypography });

    const handleChange = (id, nextValue) => {
        onChange({ ...value, [id]: nextValue });
    };

    const handleImport = (from) => onImport(from, entityId, entityType);
    const handleCloseBrowser = () => setSelect(null);

    const handleSelectFont = (source, family) => {
        if (!select) return null;
        let prev = value[select] || {};
        let next = { ...prev, source, family };
        onChange({ ...value, [select]: next });
        setSelect(null);
    };

    return (
        <>
            <TypographyFonts
                onImport={handleImport}
                onChange={handleChange}
                onBrowse={(v) => setSelect(v)}
                value={value}
                fonts={fonts}
            />

            <OverlayForm open={!!select} onClose={handleCloseBrowser}>
                {select && (
                    <FontBrowser
                        onSelect={handleSelectFont}
                        initialSource={value[select]?.source || null}
                    />
                )}
            </OverlayForm>
        </>
    );
}

TypographyFontsContainer.propTypes = {};

TypographyFontsContainer.defaultProps = {
    value: {},
};

export default TypographyFontsContainer;
