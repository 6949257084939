import React from "react";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";
import RoleLabel from "joynt/components/Roles/NodeRoles/RoleLabel";

import "./style.css";

export function UserNodeRoles(props) {
    const { id } = props;
    const { roles } = useData({ type: "app.node-roles", id });
    if (!roles || !roles.length) return null;

    return (
        <div className={"role-labels"}>
            {roles.map((role) => (
                <RoleLabel key={role} id={role} />
            ))}
        </div>
    );
}

UserNodeRoles.propTypes = {};
UserNodeRoles.defaultProps = {};

export default UserNodeRoles;
