import React from "react";
import { useData } from "state/hooks";
import { normalizeType } from "cms/util/connect";
import useDebug from "playground/debug";
import { useSelector } from "react-redux";
import { selectEntity } from "state/selectors/data";
import { pageTypeToCmsType } from "cms/util";

//import PropTypes from 'prop-types';

function Separator() {
    return <div className={"focus-context-menu-separator"} />;
}

function MenuItem(props) {
    return <div className={"focus-context-menu-item"} {...props} />;
}

function ComponentMenuItem(props) {
    const { onMouseOver, onClick } = props;
    const [type, id] = props.component.split("/");
    const data = useData({ type, id });
    const nodeType = normalizeType(data.element_type) || "section";

    return (
        <MenuItem onMouseOver={onMouseOver} onClick={onClick}>
            {data?.style || nodeType}
        </MenuItem>
    );
}

function selectItemType(store, list) {
    if (!list) return null;

    const [, listId] = list.component.split("/");

    if (list?.content) {
        if (list.content.startsWith("cms.query")) {
            const [type, id] = list.content.split("/");
            const data = selectEntity(store, type, id);
            return data.type;
        }
        const [type, id, prop] = list.content.split("/", 4);
        if (id === listId) return "cms.items";
        if (type === "cms.sections") {
            return (
                {
                    gallery: "cms.items",
                    links: "cms.links",
                }[prop] || null
            );
        }
        return null;
    }
    if (list?.query) {
        const [type, id] = list.component.split("/");
        const data = selectEntity(store, type, id);
        return pageTypeToCmsType(data.entry_type);
    }
    return "List item";
}

export function ContextMenu(props) {
    const { state, setState } = props;
    const onDebug = useDebug();

    const paths = state?.paths || [];
    const list = state?.list || {};

    //const itemType = useSelector((store) => selectItemType(store, list));

    const pos = state?.x * 1;
    const style = {
        left: state?.x + "px",
        top: state?.y + "px",
    };

    if (!props.state) return null;

    const onClick = (path) => {
        setState({
            section: path.section,
            focus: path.component,
            style: path.style,
            item: state.item,
            click: null,
        });
    };

    const onHover = (path) => {
        setState({
            section: path.section,
            focus: path.component,
            style: null,
            item: path.item,
        });
    };

    const focusContent = () => {
        if (!state.path) return;
        const parts = state.path.split("/");
        const p = [parts[0], parts[1]].join("/");
        console.log(p);
        setState({
            section: state.section,
            focus: p,
        });
    };

    const debugLog = () => {
        onDebug("editorState");
    };

    const media = paths.find((p) => p.type === "media");

    const editItem = () => {
        focusContent();
        setState({ itemId: state.item, itemPath: list?.content, click: null });
    };

    // list.content - mapped items
    // list.query - query

    return (
        <div style={style} className={"focus-context-menu"}>
            {state.item && (
                <>
                    <MenuItem>
                        {list?.itemType}/{state.item}
                    </MenuItem>
                    <MenuItem onClick={editItem}>Edytuj element listy</MenuItem>
                </>
            )}
            {media && <MenuItem>Media</MenuItem>}
            {/*{state.path && (*/}
            {/*    <MenuItem onClick={focusContent}>{state.path}</MenuItem>*/}
            {/*)}*/}
            <Separator />
            {paths.map((path) => {
                return (
                    <ComponentMenuItem
                        key={path.id}
                        onMouseOver={() => onHover(path)}
                        onClick={() => onClick(path)}
                        {...path}
                    />
                );
            })}
            <Separator />
            <MenuItem onClick={debugLog}>Debug</MenuItem>
            {/*<Separator />*/}
            {/*<MenuItem>{state.rewirePath}</MenuItem>*/}
            {/*<MenuItem>{state.itemPath}</MenuItem>*/}
            {/*<Separator />*/}
            {/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
        </div>
    );
}

ContextMenu.propTypes = {};

ContextMenu.defaultProps = {
    state: {},
};

export default ContextMenu;
