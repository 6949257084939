import React from 'react';
import ActionBox from "joynt/components/ActionBox";

//import PropTypes from 'prop-types';

export function WitnessLeaveSession(props) {

    const {
        onSetAction,
    } = props;

    return (<div>
        <ActionBox onClick={()=>onSetAction('message')} buttonLabel={"Cannot finnish task"}>
            Inform your Witness via message
        </ActionBox>
        <ActionBox onClick={()=>onSetAction('report')} buttonLabel={"Abusive behavior"}>
            Report this session
        </ActionBox>
    </div>);

}

WitnessLeaveSession.propTypes = {};
WitnessLeaveSession.defaultProps = {};

export default WitnessLeaveSession;
