import React from "react";
//import PropTypes from 'prop-types';
import ThemeGit from "./ThemeGit";
import { useThemeGit } from "playground/console/views/Themes/ThemeGit/useThemeGit";
import { useFetch } from "state/hooks";

export function ThemeGitContainer(props) {
    const { id } = props;
    const git = useThemeGit(id);

    useFetch({
        type: "theme.vcs",
        id,
        url: `console/themes/:id/vcs`,
        fetchId: `${id}.vcs`,
    });

    return <ThemeGit {...git} />;
}

ThemeGitContainer.propTypes = {};

ThemeGitContainer.defaultProps = {};

export default ThemeGitContainer;
