import React from "react";
import Button from "@material-ui/core/Button";

//import PropTypes from 'prop-types';

import "./style.css";
import Icon from "components/Icon";

export function NetworkingFlowDialog(props) {
    const {
        yes,
        no,
        message,
        description,
        descriptionIcon,
        cancelText,
        confirmText,
        title,
    } = props;

    return (
        <div className={"networking-dialog"}>
            {title && <h2 className={"networking-dialog__title"}>{title}</h2>}
            {description && (
                <div className={"networking-dialog__description"}>
                    {descriptionIcon && (
                        <Icon fontSize={"medium"}>{descriptionIcon}</Icon>
                    )}
                    <p>{description}</p>
                </div>
            )}
            {message && (
                <p className={"networking-dialog__message"}>{message}</p>
            )}
            <div className={"networking-dialog__actions"}>
                {cancelText && (
                    <Button
                        color="primary"
                        variant={"outlined"}
                        size="large"
                        onClick={no}
                    >
                        {cancelText}
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={yes}
                >
                    {confirmText}
                </Button>
            </div>
        </div>
    );
}

NetworkingFlowDialog.propTypes = {};

NetworkingFlowDialog.defaultProps = {
    cancelText: "Cancel",
    confirmText: "Ok",
};

export default NetworkingFlowDialog;
