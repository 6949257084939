import React, { useCallback } from "react";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import ToggleMenuItem from "components/context-menu/ToggleMenuItem";
import MenuItem from "components/context-menu/MenuItem";
import List from "@material-ui/core/List";
import { useActions, useApiActions } from "state/hooks";
import { detachSection } from "cms/state/actions/cms";
import { pushRouteParams } from "state/actions/router";
//import PropTypes from 'prop-types';

const types = {
    default: ["activate", "delete"],
    "cms.sections": ["activate", "detach", "delete"],
    "cms.plans": ["plan-editor", "activate", "delete"],
    "apartments.resource-bindings": ["apartments-import", "delete"],
};

function DetachSection({ id }) {
    const { onDetach } = useApiActions({ onDetach: detachSection });
    const detach = useCallback(() => onDetach(id), [id, onDetach]);
    return (
        <MenuItem
            onClick={detach}
            primaryText={"Detach"}
            icon={"mui-link_off"}
        />
    );
}

function PlanEditor({ id }) {
    const { push } = useActions({ push: pushRouteParams });
    const click = useCallback(() => {
        push({ view: "plan-editor", id, scope: "cms" });
    }, [push, id]);
    return (
        <MenuItem
            onClick={click}
            primaryText={"Edytuj plan"}
            icon={"mui-edit"}
        />
    );
}

function ApartmentsImport({ id }) {
    const { push } = useActions({ push: pushRouteParams });
    const click = useCallback(() => {
        push({ "apartments-import": id });
    }, [push, id]);
    return <MenuItem onClick={click} primaryText={"Import"} />;
}

const itemTypes = {
    activate: ({ value, onChange }) => {
        return (
            <ToggleMenuItem
                value={!!value.active}
                id={"active"}
                onChange={onChange}
                primaryText={"Activate"}
            />
        );
    },
    detach: DetachSection,
    delete: ({ onDelete, id }) => {
        return onDelete ? (
            <MenuItem
                onClick={() => onDelete(id)}
                primaryText={"Delete"}
                icon={"mui-delete"}
            />
        ) : null;
    },
    "plan-editor": PlanEditor,
    "apartments-import": ApartmentsImport,
};

export default function ListItemMenu({ onDelete, type, id }) {
    const items = types[type] || types.default;

    return (
        <AggregateRoot type={type} id={id}>
            <List>
                <Field>
                    {({ value, onChange }) => (
                        <>
                            {items.map((item) => {
                                const ItemComponent = itemTypes[item];
                                return (
                                    <ItemComponent
                                        id={id}
                                        key={item}
                                        value={value}
                                        onChange={onChange}
                                        onDelete={onDelete}
                                    />
                                );
                            })}
                        </>
                    )}
                </Field>
            </List>
        </AggregateRoot>
    );
}

ListItemMenu.propTypes = {};
