import React, {useCallback, useContext, useEffect, useState} from 'react';
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import env from "util/env";
import {useCurrentUser, useData, useHasScope} from "state/hooks";
import {SCOPE_SUPERADMIN} from "joynt/config";
//import PropTypes from 'prop-types';

const MixpanelContext = React.createContext(null);

export function useTracking(options) {
    const {event, trackView} = options;

    const mp = useContext(MixpanelContext);

    useEffect(() => {
        if (mp && trackView) {
            mp.track(event);
        }
    }, [mp, event, trackView]);

    const track = useCallback(() => {
        if (mp) console.log(`Tracking click:${event}`);
    }, [mp, event]);

    return {onTrack: track};
}

function useMixpanel(user) {
    const [mp, setMp] = useState(null);

    useEffect(() => {
        if (user) {
            if (env('MIXPANEL_PROJECT_ID')) {
                mixpanel.init(
                    env('MIXPANEL_PROJECT_ID'),
                    {api_host: 'https://api-eu.mixpanel.com'}
                );
                mixpanel.identify(user);
                setMp(mixpanel);
            }
        }
    }, [setMp, user]);

    return mp;
}

export function useLogrocket(user) {
    const userData = useData({type: 'db.users', id: user});

    useEffect(() => {
        if (userData.id) {
            if (env('LOGROCKET_PROJECT_ID')) {
                LogRocket.init(env('LOGROCKET_PROJECT_ID'));
                LogRocket.identify(userData.id, {
                    name: userData.name,
                    email: userData.name
                });
            }
        }
    }, [userData])
}

function TrackingEnabled(props) {

    const {children} = props;

    const user = useCurrentUser();

    const mp = useMixpanel(user);
    useLogrocket(user);

    return <MixpanelContext.Provider value={mp}>
        {children}
    </MixpanelContext.Provider>;

}

const noopTracking = null;

export default function Tracking(props) {
    const {children} = props;
    const isAdmin = useHasScope(SCOPE_SUPERADMIN);
    const envId = env('ENV');
    const shouldTrack = !isAdmin && envId === "production";

    if (!shouldTrack)
        return <MixpanelContext.Provider value={noopTracking}>
            {children}
        </MixpanelContext.Provider>;

    return <TrackingEnabled {...props} />;
}

Tracking.propTypes = {};
Tracking.defaultProps = {};
