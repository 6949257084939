import React from 'react';
import SessionFlowSchedule from "joynt/components/SessionFlow/SessionFlowSchedule";
//import PropTypes from 'prop-types';

export function Flow(props) {

    //const {} = props;
    return (<SessionFlowSchedule {...props} />);

}

Flow.propTypes = {};
Flow.defaultProps = {};

export default Flow;
