import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
import List from 'containers/List';
import ListView from "containers/ListView";
import {UserMenuGroupIterator} from "components/user-menu/UserResourcesGroups";
import {selectRouteParam} from "state/selectors/router";
import {pushRouteParams} from "state/actions/router";
import {push} from "state/actions/modal";
import PendingState from "components/list/PendingState";
import Fab from "@material-ui/core/Fab";
import {Icon} from "@material-ui/core";
import {create} from "state/actions/data";
import MuiList from '@material-ui/core/List';
import uuid from "uuid/v4";
import WebsiteSettings from "cms/ui/WebsiteSettings";

const groups = ['settings'];

function SettingsView(props) {
    const { type, onClick } = props;

    if (type === 'cms.settings') {
        return <div className={'pad-md form-regular'}>
            <WebsiteSettings />
        </div>;
    }

    return <MuiList>
        <ListView
            type={type}
            list={'default'}
            onClick={onClick}
            onContextMenu={(...args)=>alert(args)}
            pendingState={<PendingState />}
        />
    </MuiList>;
}

function SettingsViewLayout({type, onClick, onCreate, onExit}) {
    return <div className={'rows gap-sm h-fill'}>
        <div className={'cols cols-middle gap-sm user-menu-group-label pad-xs default-c700'}>
            <div onClick={onExit}>Ustawienia</div>
            <div> > </div>
            <div>{type}</div>
        </div>
        <div className={'grow overflow-scroll'}>
            <SettingsView onClick={onClick} type={type} />
        </div>

        { type !== 'cms.settings' ? <div className={'list-actions'}>
            <div className={'list-fab'}>
                <Fab onClick={onCreate} color={'secondary'}>
                    <Icon>add</Icon>
                </Fab>
            </div>
        </div> : null }
    </div>
}

const mapState = (store, props) => ({
    type: selectRouteParam(store, 'settings')
});

const mapDispatch = {
    onClick: pushRouteParams,
    onEdit: push,
    onCreate: create
};

class Settings extends React.PureComponent {

    click = (id) => {
        const { onClick } = this.props;
        onClick({settings: id});
    };

    back = () => {
        this.click(null);
    };

    edit = (id) => {
        const { onEdit, type } = this.props;
        onEdit([type, id].join('|'));
    };

    create = () => {
        const { onCreate, type } = this.props;
        const list = 'default';
        const newId = uuid();
        onCreate(type, {id: newId, active: true, draft: true}, list);
        this.edit(newId);
    };

    render() {
        const { type } = this.props;
        return type ? <SettingsViewLayout
            type={type}
            onExit={this.back}
            onClick={this.edit}
            onCreate={this.create}
        /> : <List
            type={'app.user-menu-groups'}
            items={groups}
            onClick={this.click}
            Iterator={UserMenuGroupIterator}
        />;
    }

}

Settings.propTypes = {};

export default connect(mapState, mapDispatch)(Settings);