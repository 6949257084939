import React from "react";
import cn from "classnames";

import { MessagePropTypes } from "joynt/components/messages/message/MessagePropTypes";

export function MessageFooterSimple(props) {
    const { reactions, isOwn } = props;

    return (
        <section
            className={cn(
                "message__footer message__footer--simple",
                isOwn && "message__footer--own"
            )}
        >
            {reactions && (
                <div className={"message__reactions"}>{reactions}</div>
            )}
        </section>
    );
}

MessageFooterSimple.propTypes = {
    reactions: MessagePropTypes.reactions,
    isOwn: MessagePropTypes.isOwn,
};

export default MessageFooterSimple;
