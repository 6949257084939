import React from "react";
import PropTypes from "prop-types";
import { withSchema } from "containers/Field/withSchema";
import Select from "components/fields/select/Select";

const nullArr = [];

function SingleRef(props) {
    const { schema, id, value, onChange, entityType } = props;
    const source = props.source || schema.properties?.source;
    return (
        <div className={"cols gap-xs"}>
            <Select
                id={`${id}.resource`}
                source={source}
                value={value?.resource_type}
                entityType={entityType}
                label={"Type"}
                onChange={(v) => onChange({ ...value, resource_type: v })}
            />
            {value?.resource_type && (
                <Select
                    id={`${id}.resource_id`}
                    source={`api:${value.resource_type}`}
                    value={value.resource_id}
                    entityType={entityType}
                    label={"Resource"}
                    onChange={(v) => onChange({ ...value, resource_id: v })}
                />
            )}
        </div>
    );
}

SingleRef.defaultProps = {
    schema: {},
    value: {},
};

class ReferenceField extends React.PureComponent {
    render() {
        const { resource_type, multiple } = this.props;
        const source = "api:" + resource_type;

        if (!multiple) return <SingleRef {...this.props} />;

        return <Select {...this.props} source={source} />;
    }
}

ReferenceField.propTypes = {
    resource_type: PropTypes.string.isRequired,
};

ReferenceField.defaultProps = {
    value: nullArr,
};

export default withSchema()(ReferenceField);
