import React from "react";

import { Route, Switch } from "react-router";
import Dashboard from "views/Dashboard";
import Entrypoint from "containers/Entrypoint";
import Booking from "playground/booking/BookingService";
import PageEditor from "cms/ui/PageEditorService";
import Page from "cms/PageServiceClient";
import ConsoleView from "console/ConsoleView";
import Performance from "playground/Performance";
import FrontComponents from "playground/FrontComponents";
import ListFormView from "playground/ListFormView";
import BookingSearch from "playground/booking-search/BookingSearch";
import authRoutes from "components/auth/routes";
import MediaLibrary from "views/MediaLibrary";
import PlanEditor from "plan-editor";
import CmsLayout from "playground/cms";
import ConsoleLayout from "playground/console";
import Designer from "playground/designer";
import ThemePlayground from "playground/theme";
import Home from "playground/home";
import { AuthLayout } from "playground/auth";

export default function Router(props) {
    return (
        <Switch>
            {authRoutes({ LoginLayout: AuthLayout })}
            <Route exact path="/">
                <Entrypoint>
                    <Home />
                </Entrypoint>
            </Route>
            <Route exact path="/:service/booking" component={Booking} />
            <Route
                exact
                path="/:service/booking-search"
                component={BookingSearch}
            />
            <Route
                exact
                path="/:lang/:service/editor-legacy"
                component={PageEditor}
            />
            {/*<Route exact path="/:lang/:service/designer" component={Designer} />*/}
            <Route
                exact
                path="/:lang/:service/theme"
                component={ThemePlayground}
            />
            <Route exact path="/:lang/:service/plan-editor">
                <Entrypoint>
                    <PlanEditor />
                </Entrypoint>
            </Route>
            <Route exact path="/:lang/_/:resource?" component={ConsoleView} />
            <Route exact path={"/console"}>
                <ConsoleLayout />
            </Route>
            <Route path="/:lang/:service/:resource?">
                <Entrypoint>
                    <Switch>
                        <Route exact path="/perf" component={Performance} />
                        <Route
                            exact
                            path="/front"
                            component={FrontComponents}
                        />
                        <Route
                            exact
                            path="/:lang/:service/designer"
                            component={Designer}
                        />
                        <Route
                            exact
                            path="/:lang/:service/cms"
                            component={ListFormView}
                        />
                        <Route
                            exact
                            path="/:lang/:service/apartments"
                            component={ListFormView}
                        />
                        <Route
                            exact
                            path="/:lang/:service/media"
                            component={MediaLibrary}
                        />
                        <Route path="/:lang/:service" component={CmsLayout} />
                        <Route>No route matched</Route>
                    </Switch>
                    {props.children}
                </Entrypoint>
            </Route>
        </Switch>
    );
}
