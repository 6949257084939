import React from 'react';
import PropTypes from 'prop-types';
import DaySessions from "./DaySessions";
import {useSelector} from "react-redux";
import {selectDailySessions} from "joynt/components/Schedule/selectors";
import {useMoment} from "joynt/components/DateTimePicker/hooks";

export function DaySessionsContainer(props) {

    const {list, day} = props;
    const sessions = useSelector(s => selectDailySessions(s, list, day));
    const dayMoment = useMoment(day);

    return (<DaySessions
        {...props}
        items={sessions}
        day={dayMoment}
    />);

}

DaySessionsContainer.propTypes = {
    id: PropTypes.string
};
DaySessionsContainer.defaultProps = {};

export default DaySessionsContainer;