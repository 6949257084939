import React from 'react';
//import PropTypes from 'prop-types';

import Icon from "components/Icon";
import Button from "@material-ui/core/Button";

export function ToggleButton(props) {

    const {on, onClick} = props;

    return (<Button
        onClick={onClick}
        variant={"text"}
        color={"primary"}
        startIcon={
            on ? (
                <Icon>mui-keyboard_arrow_up</Icon>
            ) : (
                <Icon>mui-info</Icon>
            )
        }
    >
        {on ? "Hide" : "Read more"}
    </Button>);

}

ToggleButton.propTypes = {};

ToggleButton.defaultProps = {};

export default ToggleButton;