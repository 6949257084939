import React, { useState } from "react";
import { useData, useFetch, useFormField } from "state/hooks";
import ArrayTextField from "playground/console/views/Websites/CreateWebsite/ArrayTextField";
import { FormLayout, OverlayForm } from "playground/cms/Form";
import IconButton from "components/IconButton";
import Checkbox from "@material-ui/core/Checkbox";

//import PropTypes from 'prop-types';

function CollectionsList(props) {
    const { items, value, onChange } = props;
    const collections = Object.keys(items);
    const toggle = (collection) => {
        if (value.includes(collection)) {
            onChange(value.filter((v) => v !== collection));
        } else {
            onChange([...value, collection]);
        }
    };
    return (
        <div className={"pad-md rows "}>
            {collections.map((collection) => {
                return (
                    <div
                        className={"cols cols-spread cols-middle gap-sm"}
                        key={collection}
                    >
                        <div className={"cols cols-middle"}>
                            <div>
                                <Checkbox
                                    checked={!value.includes(collection)}
                                    onClick={() => toggle(collection)}
                                />
                            </div>
                            <div>{collection}</div>
                        </div>
                        <div>{items[collection]}</div>
                    </div>
                );
            })}
        </div>
    );
}

export function ContentSelect(props) {
    const {} = props;
    const data = useData(props);
    const { options } = data;
    const cmsId = options?.content_from;
    const { name } = useData({ type: "console.services.cms", id: cmsId });
    const stats = useData({ type: "console.cms-stats", id: cmsId });
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);

    const { value: exclude, onChange: setExclude } = useFormField(
        "options.content_exclude"
    );

    useFetch({
        type: "console.cms-stats",
        url: `console/cms/${cmsId}/stats`,
        id: cmsId,
        enable: !!cmsId,
    });

    return (
        <>
            <ArrayTextField
                id={"options.content_exclude"}
                label={"Exclude content"}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            icon={"mui-search"}
                            onClick={() => setOpen(true)}
                        />
                    ),
                }}
            />
            <OverlayForm
                open={open}
                onClose={onClose}
                position={"center"}
                width={"sm"}
                fullHeight={false}
            >
                <FormLayout>
                    <FormLayout.Header>
                        <span>
                            Copy from <strong>{name}</strong>
                        </span>
                    </FormLayout.Header>
                    <FormLayout.Content>
                        <CollectionsList
                            items={stats?.stats || []}
                            value={exclude || []}
                            onChange={setExclude}
                        />
                    </FormLayout.Content>
                    <FormLayout.Footer>
                        <FormLayout.Actions
                            onCancel={onClose}
                            onSubmit={() => null}
                        />
                    </FormLayout.Footer>
                </FormLayout>
            </OverlayForm>
        </>
    );
}

ContentSelect.propTypes = {};

ContentSelect.defaultProps = {};

export default ContentSelect;
