import React  from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";

const typeIcons = {
    pdf: 'mui-picture_as_pdf',
    mp4: 'mui-movie',
    default: 'mui-insert_drive_file'
}

export default function FileTypeIcon({size, extension, showExtension}) {

    const s = `${size}px`;
    const icon = typeIcons[extension] || typeIcons.default;

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative",
        width: s,
        height: s
    }}>
        { icon ? <Icon color="inherit" fontSize="large">
            {icon}
        </Icon> : null }
        { extension && showExtension && <div
            style={{
                width: s,
                height: "25px",
                fontSize: "11px",
                textTransform: "uppercase",
                textAlign: "center",
                paddingTop: '5px'
            }}
        >
            {extension}
        </div> }
    </div>
}

FileTypeIcon.defaultProps = {
    size: 48,
    showExtension: true
}

FileTypeIcon.propTypes = {
    size: PropTypes.number,
    extension: PropTypes.string,
    showExtension: PropTypes.bool
}