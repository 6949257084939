export function nodeFlags(node) {
    const hasHyperscript = !!node?.attributes?.["_"];
    const attrCount = Object.keys(node.attributes || {}).length;
    const hasAttributes = hasHyperscript ? attrCount > 1 : attrCount > 0;

    return {
        hasAttributes,
        hasHyperscript,
        hasMapping: !!node.template_id,
    };
}
