import React, { useState } from "react";
//import PropTypes from 'prop-types';
import PageUrl from "./PageUrl";
import { usePageUrl } from "playground/cms/state/hooks";

export function PageUrlContainer(props) {
    const {} = props;
    const url = usePageUrl(props.type, props.id);
    const [edit, setEdit] = useState(false);
    const open = () => window.open(`${url.host}/${url.slug}`);
    return (
        <PageUrl
            {...props}
            {...url}
            edit={edit}
            onEdit={setEdit}
            onOpen={open}
        />
    );
}

PageUrlContainer.propTypes = {};

PageUrlContainer.defaultProps = {};

export default PageUrlContainer;
