import React from "react";
import useSessionSettings from "joynt/meeting/hooks/useSessionSettings";
import { useNetworkState } from "joynt/network-state";
import { DevSessionSettings } from "joynt/components/UtilityNav/Session/views/Dev";
import AggregateRoot from "containers/AggregateRoot";
import ApiContext from "containers/context/ApiContext";
import { useNode } from "joynt/hooks";
import { useActions } from "state/hooks";
import { storeOne } from "state/actions/data";
//import PropTypes from 'prop-types';

export default function Settings(props) {
    const { id } = props;
    const { settings, onChange } = useSessionSettings();
    const { setDevQuality } = useNetworkState(); //devQuality

    const data = useNode(id);
    const { onStore } = useActions({
        onStore: storeOne,
    });
    const onSubmit = () => onStore("db.nodes", id, data);

    return (
        <div className={"rows gap-xs"}>
            <div className={"cols gap-sm"}>
                <div onClick={() => setDevQuality(null)}>none</div>
                <div
                    onClick={() =>
                        setDevQuality({ online: true, session: "good" })
                    }
                >
                    good
                </div>
                <div
                    onClick={() =>
                        setDevQuality({ online: true, session: "degraded" })
                    }
                >
                    degraded
                </div>
                <div
                    onClick={() =>
                        setDevQuality({ online: true, session: "bad" })
                    }
                >
                    bad
                </div>
                <div
                    onClick={() =>
                        setDevQuality({ online: false, session: "offline" })
                    }
                >
                    offline
                </div>
            </div>

            <ApiContext.Provider value={{}}>
                <AggregateRoot type={"db.nodes"} id={id} disableSubmit={true}>
                    <DevSessionSettings id={id} onSubmit={onSubmit} />
                </AggregateRoot>
            </ApiContext.Provider>
        </div>
    );
}

Settings.propTypes = {};
Settings.defaultProps = {};
