import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import TaskCheck from "./TaskCheck";
import Field from "containers/Field";
import AggregateRoot from "containers/AggregateRoot";

function RenderTaskCheck(props) {
    const {
        value,
        onChange
    } = props;

    const toggle = useCallback(() => {
        onChange(!value);
    }, [onChange, value]);

    return <TaskCheck
        onClick={toggle}
        value={value}
    />;
}

export function TaskCheckContainer(props) {

    const {id} = props;

    return (<AggregateRoot type={"db.nodes"} id={id}>
        <Field
            id={"task_complete"}
            fieldType={"checkbox"}
            FieldComponent={RenderTaskCheck}
        />
    </AggregateRoot>);

}

TaskCheckContainer.propTypes = {
    id: PropTypes.string
};
TaskCheckContainer.defaultProps = {};

export default TaskCheckContainer;