import React from "react";

import ResourceList from "./ResourceList";
import ResourceForm from "./ResourceForm.container";
import { ListLayout } from "playground/cms/List";
import { FormLayout } from "playground/cms/Form";

//import PropTypes from 'prop-types';

export function Resources(props) {
    const { id, children, ListItem } = props;
    const { FormComponent, ...rest } = props;
    return (
        <>
            <ResourceList {...rest} ListItem={ListItem}>
                {ListLayout.Children.findLayout(children)}
            </ResourceList>
            <ListLayout.Form open={!!id}>
                {id && (
                    <FormComponent {...rest}>
                        {FormLayout.Children.findLayout(children)}
                    </FormComponent>
                )}
            </ListLayout.Form>
        </>
    );
}

Resources.propTypes = {};

Resources.defaultProps = {
    FormComponent: ResourceForm,
};

export default Resources;
