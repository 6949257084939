import React from 'react';
import cn from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
//import PropTypes from 'prop-types';

export default function OfflineOverlay(props) {

    const {isVisible} = props;

    return (
        <div className={cn('offline-overlay rows rows-center rows-middle gap-md', {
            'is-visible': isVisible
        })}>
            <div className={'cols cols-center'}>
                <CircularProgress color={'inherit'} />
            </div>
            <div className={'type-heading-sm'}>
                You have no internet. Trying to reconnect...
            </div>
        </div>
    );

}

OfflineOverlay.propTypes = {};
OfflineOverlay.defaultProps = {
    isVisible: false
};
