import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

export default class FormButtons extends React.PureComponent {

    render() {
        const { onCancel, onSubmit } = this.props;
        return (<div className={'cols gap-xs'}>
            { onCancel ? <Button
                size={'large'}
                color={'default'}
                onClick={onCancel}
            >
                Cancel
            </Button> : null }
            { onSubmit ? <Button
                size={'large'}
                onClick={onSubmit}
            >
                Submit
            </Button> : null }
        </div>);
    }

}

FormButtons.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};