export const bootstrap = (context) => (id, join) => ({
    type: "JOINT.BOOTSTRAP",
    payload: { id, join },
    context,
});

export const nodeLink = (context) => (id, channel, view) => {
    return {
        type: "JOINT.NODE_LINK",
        payload: { id, channel, view },
        context,
    };
};

export const toggleSubscription = (context) => (from, to) => {
    return {
        type: "JOINT.TOGGLE_SUBSCRIPTION",
        payload: {
            from,
            to,
        },
        context,
    };
};

export const toggleKeep = (context) => (from, to) => ({
    type: "JOINT.KEEP",
    payload: { from, to },
    context,
});

export const toggleFollow = (context) => (from, to) => ({
    type: "JOINT.FOLLOW",
    payload: { from, to },
    context,
});

export const nodeSelect = (id) => ({
    type: "JOINT.NODE_SELECT",
    payload: { id },
});
export const nodeSelectCancel = (id) => ({
    type: "JOINT.NODE_SELECT_CANCEL",
    payload: { id },
});

export const moveNode = (context) => (id, target) => ({
    type: "JOINT.MOVE_NODE",
    payload: { id, target },
    context,
});

export const createEdge = (context) => (edgeType, from, to, id) => ({
    type: "JOINT.CREATE_EDGE",
    payload: { edgeType, from, to, id },
    context,
});

export const assignRole = (context) => (parent, identity, role) => {
    return {
        type: "JOINT.ASSIGN_ROLE",
        payload: {
            parent,
            identity,
            role,
        },
        context,
    };
};

export const setCurrentEvent = (context) => (parent, id) => {
    return {
        type: "JOINT.SET_EVENT",
        payload: {
            parent,
            id,
        },
        context,
    };
};

export const pushEntity = (context) => (type, id, data) => ({
    type: "JOINT.PUSH_ENTITY",
    payload: {
        type,
        id,
        data,
    },
    context,
});

export const shareNode = (context) => (id, to) => ({
    type: "JOINT.SHARE",
    payload: { id, to },
    context,
});

export const invite = (context) => (id, emails) => ({
    type: "JOINT.INVITE",
    payload: { id, emails },
    context,
});

export const removeUser = (context) => (id, identity) => ({
    type: "JOINT.USER.REMOVE",
    payload: { id, identity },
    context,
});

export const banUser = (context) => (id, identity) => ({
    type: "JOINT.USER.BAN",
    payload: { id, identity },
    context,
});
