import React from "react";
import { useFetch, useForm, usePending } from "state/hooks";
import { selectSettingsId } from "cms/state/selectors/cms";
import { useSelector } from "react-redux";
import AggregateRoot from "containers/AggregateRoot";
import Field from "containers/Field";
import Button from "components/Button";
import Preloader from "components/Preloader";

//import PropTypes from 'prop-types';

function AddressField(props) {
    const { id } = props;

    return (
        <div className={"cols cols-2 gap-md"}>
            <div className={"rows gap-sm"}>
                <div className={"cols gap-md"}>
                    <Field id={`${id}.street`} label={"Ulica"} />
                    <Field id={`${id}.street_no`} label={"Nr"} />
                </div>
                <div className={"cols gap-md"}>
                    <Field id={`${id}.zip`} label={"Kod pocztowy"} />
                    <Field id={`${id}.city`} label={"Miejscowość"} />
                </div>
            </div>
            <Field id={`${id}.geolocation`} fieldType={"geolocation"} />
        </div>
    );
}

export function ContactSettings(props) {
    const {} = props;

    useFetch({
        type: "cms.settings",
        url: "cms/settings",
    });

    const id = useSelector((store) => selectSettingsId(store));
    const { onSubmit } = useForm("cms.settings", id, () => null);
    const isPending = usePending(id, false);

    return (
        <AggregateRoot type={"cms.settings"} id={id} disableSubmit={true}>
            <div className={"settings-view"}>
                <div className={"settings-view-content"}>
                    <div className={"rows gap-lg"}>
                        <div className={"rows gap-sm"}>
                            <h3>Dane kontaktowe</h3>
                            <div className={"cols cols-2 gap-md"}>
                                <Field id={"contact.name"} label={"Nazwa"} />
                                <Field
                                    id={"contact.short_name"}
                                    label={"Krótka nazwa"}
                                />
                            </div>
                            <div>
                                <Field
                                    id={"contact.email.email"}
                                    label={"Adres e-mail"}
                                />
                            </div>
                            <div className={"cols gap-md"}>
                                <Field id={"contact.phone"} label={"Telefon"} />
                                <Field
                                    id={"contact.phone_additional"}
                                    label={"Telefon 2"}
                                />
                            </div>
                            <AddressField id={"contact.address"} />
                        </div>
                        <div className={"rows gap-sm"}>
                            <h3>Dane firmy</h3>
                            <Field id={"company.name"} label={"Nazwa firmy"} />
                            <div className={"cols cols-2 gap-md"}>
                                <Field
                                    id={"company.tax_number"}
                                    label={"NIP"}
                                />
                                <Field
                                    id={"company.bank_account"}
                                    label={"Nr konta"}
                                />
                            </div>
                            <AddressField id={"company.address"} />
                        </div>
                    </div>
                </div>
                <div className={"settings-view-footer cols-right"}>
                    <Button onClick={() => onSubmit()} size={"large"}>
                        Zapisz
                    </Button>
                </div>
            </div>
            <Preloader visible={isPending} />
        </AggregateRoot>
    );
}

ContactSettings.propTypes = {};

ContactSettings.defaultProps = {};

export default ContactSettings;
