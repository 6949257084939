import React, { useEffect, useState } from "react";
import cn from "classnames";
import ContentEditable from "components/ContentEditable";
import { sanitize } from "dompurify";

//import PropTypes from 'prop-types';

const inheritIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 4V5.4C4 8.76031 4 10.4405 4.65396 11.7239C5.2292 12.8529 6.14708 13.7708 7.27606 14.346C8.55953 15 10.2397 15 13.6 15H20M20 15L15 10M20 15L15 20"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

function Input(props) {
    const { onAdd, onBackspace } = props;
    const [value, setValue] = useState("");
    const add = (evt) => {
        console.log(value);
        evt.preventDefault();
        if (value) {
            onAdd(value);
            setValue(null);
        }
    };
    const handleKeyDown = (evt, val) => {
        if (evt.key === "Enter") {
            add(evt);
        }
        if (evt.key === " ") {
            add(evt);
        }
        if (evt.key === "Escape") {
            setValue(null);
        }
        if (evt.key === "Backspace" && !value) {
            evt.stopPropagation();
            onBackspace(evt);
            console.log("Backspace");
        }
    };
    return (
        <ContentEditable
            label={"Add class"}
            className={"style-classname-input"}
            onKeyDown={handleKeyDown}
            value={value}
            onChange={setValue}
        />
    );
}

export function ClassesInput(props) {
    const { value, onRemove, onAdd, onSearch, inheritance } = props;
    const [focus, setFocus] = useState(null);
    useEffect(() => {
        const click = () => setFocus(null);
        const key = (evt) => {
            if (evt.key === "Escape") {
                setFocus(null);
            }
            if (evt.key === "Delete" || evt.key === "Backspace") {
                onRemove(focus);
                setFocus(null);
            }
        };
        window.addEventListener("click", click);
        window.addEventListener("keydown", key);
        return () => {
            window.removeEventListener("click", click);
            window.removeEventListener("keydown", key);
        };
    }, [onRemove, focus]);
    const handleSetFocus = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        setFocus(item);
        if (e.ctrlKey) {
            onSearch("." + item);
        }
    };
    const backspace = (e) => {
        setFocus(value[value.length - 1] || null);
    };
    const inherit = value.includes("@all");
    const toggleInherit = () => {
        if (inherit) {
            onRemove("@all");
        } else {
            onAdd("@all");
        }
    };
    const visible = value.filter((item) => item !== "@all");
    return (
        <div className={"style-classes-pillbox"}>
            {inheritance && (
                <div
                    onClick={toggleInherit}
                    className={cn("style-classname", {
                        disabled: !inherit,
                    })}
                >
                    {inheritIcon}
                </div>
            )}
            {visible.map((item) => (
                <div
                    key={item}
                    className={cn("style-classname", {
                        focus: focus === item,
                    })}
                    onClick={(e) => handleSetFocus(e, item)}
                >
                    {item}
                </div>
            ))}
            <Input onAdd={onAdd} onBackspace={backspace} />
        </div>
    );
}

ClassesInput.propTypes = {};

ClassesInput.defaultProps = {
    value: [],
};

export default ClassesInput;
