import React from "react";
import Button from "components/Button";

//import PropTypes from 'prop-types';

export function MobileBar(props) {
    const { onClick } = props;
    return (
        <div className={"session-window__mobile-bar"}>
            <Button onClick={onClick}>Back to session</Button>
        </div>
    );
}

MobileBar.propTypes = {};
MobileBar.defaultProps = {};

export default MobileBar;
