import {useCallback} from "react";
import {useApiActions, useRouteParam} from "state/hooks";
import {toggleKeep} from "joynt/state/actions";

export function useKeep(id) {
    const [root] = useRouteParam('id');
    const {toggle} = useApiActions({toggle: toggleKeep});
    const onKeep = useCallback((keepId, keepIn) => {
        toggle(keepIn || id || root, keepId);
    }, [toggle, id, root]);
    return {onKeep};
}