import React, { useCallback, useContext } from "react";
//import PropTypes from 'prop-types';
import env from "util/env";

import {
    Grid, // our UI Component to display the results
    SearchBar, // the search bar the user will type into
    SearchContext, // the context that wraps and connects our components
    SearchContextManager, // the context manager, includes the Context.Provider
    SuggestionBar, // an optional UI component that displays trending searches and channel / username results
} from "@giphy/react-components";

const GIPHY_API_KEY = env("GIPHY_API_KEY");

// the search experience consists of the manager and its child components that use SearchContext
const SearchExperience = (props) => (
    <SearchContextManager apiKey={GIPHY_API_KEY}>
        <Components {...props} />
    </SearchContextManager>
);

// define the components in a separate function so we can
// use the context hook. You could also use the render props pattern
const Components = (props) => {
    const { fetchGifs, searchKey } = useContext(SearchContext);
    const { onSelect } = props;

    return (
        <>
            <SearchBar />
            <SuggestionBar />
            <Grid
                key={searchKey}
                columns={2}
                width={288}
                fetchGifs={fetchGifs}
                noLink={true}
                onGifClick={onSelect}
            />
        </>
    );
};

export default function GiphyPicker(props) {
    const { onSelect } = props;

    const select = useCallback(
        (g) => {
            onSelect(g.images.downsized_large.url);
        },
        [onSelect]
    );

    return (
        <div className={"w-sm h-md"}>
            <SearchExperience onSelect={select} />
        </div>
    );
}

GiphyPicker.propTypes = {};
GiphyPicker.defaultProps = {};
