import React from 'react';
import PropTypes from 'prop-types';

import {useSelector} from "react-redux";
import {selectDailySessions} from "joynt/components/Schedule/selectors";
import {selectTracks} from "./../selectors";

import DayTrack from "./DayTrack";
import CalendarEvent from "./../CalendarEvent";

function DayEvents(props) {
    const {
        day,
        list,
        start,
        end,
        width,
        EventComponent
    } = props;

    const sessions = useSelector(s => selectDailySessions(s, list, day));
    const tracks = useSelector(s => selectTracks(s, sessions));

    return <>
        {tracks.map((track,i) => {
            return <div className={"day-track__lane"} key={i}>
                {track.map(session => <EventComponent
                    id={session}
                    key={session}
                    start={start}
                    end={end}
                    width={width}
                />)}
            </div>
        })}
    </>;
}

DayEvents.defaultProps = {
    EventComponent: CalendarEvent
}

export function DayTrackContainer(props) {

    const {
        list,
        time,
        startHour,
        hours,
        width,
        ...other
    } = props;

    const day = new Date(time*1000);

    const rangeStart = new Date(day);
    rangeStart.setHours(startHour,0,0,0);
    const rangeEnd = new Date(rangeStart);
    rangeEnd.setHours(startHour+hours.length);

    return (<DayTrack
        time={time}
        {...other}
    >
        <DayEvents
            list={list}
            day={day.getTime()/1000}
            start={rangeStart.getTime()/1000}
            end={rangeEnd.getTime()/1000}
            width={width}
        />
    </DayTrack>);

}

DayTrackContainer.propTypes = {
    id: PropTypes.string,
    list: PropTypes.string,
    day: PropTypes.number,
    startHour: PropTypes.number,
    hours: PropTypes.number,
    width: PropTypes.number
};
DayTrackContainer.defaultProps = {};

export default DayTrackContainer;