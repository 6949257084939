import React, { useState } from "react";
import { FormLayout, OverlayForm, useFormContext } from "playground/cms/Form";
import Field from "containers/Field";
import { MediaField } from "playground/cms/fields/Media";
import { useApiActions, useData } from "state/hooks";
import Button from "components/Button";
import PlanEditor from "plan-editor";
import { submitOneCallback } from "state/actions/api";

//import PropTypes from 'prop-types';

const PLAN_TYPE_CMS_ENTRIES = 5;
const PLAN_TYPE_CMS_TAGS = 7;

export function PlanForm(props) {
    const { id } = props;
    const { plan_type, resources_type } = useData(props);
    const isEntries = plan_type === PLAN_TYPE_CMS_ENTRIES;
    const sourceMap = {
        [PLAN_TYPE_CMS_ENTRIES]: "api:cms.entries?type={resources_type}",
        [PLAN_TYPE_CMS_TAGS]: "api:cms.tags",
    };
    const [planEditor, showPlanEditor] = useState();
    const { isDraft } = useFormContext();

    const { onSave } = useApiActions({
        onSave: submitOneCallback,
    });

    const showEditor = () => {
        const cb = () => showPlanEditor(true);
        onSave(cb, "cms.plans", id);
    };

    return (
        <>
            <FormLayout.Fieldset pad>
                <Field id={"title"} label={"Nazwa"} />
                <MediaField id={"media"} label={"Plan"} />
                <Field
                    id={"plan_type"}
                    fieldType={"select"}
                    source={"schema:data.plan-types"}
                    label={"Typ planu"}
                />
                {isEntries && (
                    <Field
                        id={"resources_type"}
                        fieldType={"select"}
                        source={"schema:entries.types"}
                        label={"Typ obiektów"}
                    />
                )}
                {plan_type && (!isEntries || resources_type) && (
                    <Field
                        id={"resources"}
                        fieldType={"select"}
                        label={"Przypisane obiekty"}
                        sourceMap={sourceMap}
                        sourceKey={"plan_type"}
                        multiple={true}
                        source={"cms.plan-resources"}
                    />
                )}
                <div>
                    <Button onClick={showEditor}>Edytuj plan</Button>
                </div>
            </FormLayout.Fieldset>
            <OverlayForm
                open={planEditor}
                onClose={() => showPlanEditor(false)}
                width={"wide"}
            >
                {planEditor && (
                    <PlanEditor onClose={() => showPlanEditor(false)} />
                )}
            </OverlayForm>
        </>
    );
}

PlanForm.propTypes = {};

PlanForm.defaultProps = {};

export default PlanForm;
