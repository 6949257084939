import React, { useRef, useState } from "react";
//import PropTypes from 'prop-types';

import ZmapInput from "./../ZmapInput";
import { getSuggestions } from "./../zmap-suggest";
import { parseZmap, processZmap } from "./../zmap";
import { useZmapContext } from "./../ZmapContext";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

import "./style.css";
import { useSelector } from "react-redux";

function SuggestionList(props) {
    const { items, onAccept, selected } = props;
    return (
        <SelectList>
            {items.map((item, i) => (
                <SelectListItem
                    key={i}
                    onClick={() => onAccept(i)}
                    subtitle={item.value}
                    isSelected={selected === i}
                >
                    {item.key}
                </SelectListItem>
            ))}
        </SelectList>
    );
}

SuggestionList.defaultProps = {
    items: [],
};

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export function ZmapSuggest(props) {
    const { value, onChange, onAccept: onAcceptProps } = props;

    const context = useZmapContext();
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [focused, setFocused] = useState(false);

    let zmap, result, suggestions, error;

    useSelector((store) => {
        try {
            zmap = parseZmap(value);
            result = processZmap(zmap, context);
            suggestions = getSuggestions(store, value, zmap, context);
        } catch (e) {
            console.log(e);
            error = e.message;
        }
    });

    const selectedSuggestion = Math.min(
        suggestionIndex,
        Math.max(0, suggestions?.length - 1)
    );

    const setIndex = (i) => {
        if (i > -1) setSuggestionIndex(i);
    };

    const onAccept = ({ value, suggestion }) => {
        if (value && suggestion) {
            onChange(value + suggestion);
            setSuggestionIndex(0);
        }
        if (onAcceptProps) {
            onAcceptProps([value, suggestion].filter((v) => !!v).join(""));
            setFocused(false);
        }
    };

    const onAcceptSuggestion = (i) => {
        const suggestion = suggestions[i];
        if (!suggestion) return;
        onChange(value + suggestion.completion);
        if (onAcceptProps) {
            onAcceptProps(
                [value, suggestion.completion].filter((v) => !!v).join("")
            );
            setFocused(false);
        }
    };

    const onType = () => {
        setFocused(true);
    };
    const currentSuggestion = suggestions?.[suggestionIndex]?.completion;
    const showSuggestions = focused && suggestions && suggestions.length > 0;

    const suggestionsRef = useRef();

    return (
        <div className={"zmap-suggest"}>
            <div ref={suggestionsRef}>
                <ZmapInput
                    value={value || ""}
                    onChange={onChange}
                    onAccept={onAccept}
                    onType={onType}
                    onArrowUp={setIndex}
                    onArrowDown={setIndex}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    suggestionIndex={selectedSuggestion}
                    suggestion={currentSuggestion}
                />
                {error && <div className={"error"}>{error}</div>}
                {/*<pre>{JSON.stringify(suggestions, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(result, null, 2)}</pre>*/}
            </div>
            <Popper
                open={showSuggestions}
                anchorEl={suggestionsRef.current}
                anchorOrigin={anchorOrigin}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                        <div className={"suggestions-list"}>
                            <SuggestionList
                                items={suggestions}
                                onAccept={onAcceptSuggestion}
                                selected={suggestionIndex}
                            />
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

ZmapSuggest.propTypes = {};

ZmapSuggest.defaultProps = {
    value: "",
};

export default ZmapSuggest;
