import React from "react";
import Html from "components/Html";

export default function List(props) {
  const { items, style } = props;

  const ListType = (props) =>
    style === "unordered" ? <ul {...props} /> : <ol {...props} />;

  return (
    <ListType>
      {items.map((item, i) => (
        <Html elementType={"li"} key={i} value={item} />
      ))}
    </ListType>
  );
}

List.propTypes = {};
List.defaultProps = {};
