import React from "react";

import Field from "containers/Field";
import SelectField from "components/fields/select/Select";
import { useNodeParent, useWorkspaces } from "joynt/hooks/channels";

//import PropTypes from 'prop-types';

export function ParentSpace(props) {
    const { id } = props;

    const workspaces = useWorkspaces(null);
    //const workspaces = useSelector((s) => selectExtendableWorkspaces(s));
    const [parent, onChange] = useNodeParent(id);

    return (
        <Field id={"parent"} validate={true}>
            {({ error, helperText }) => (
                <SelectField
                    id={"parent"}
                    type={"db.nodes"}
                    label={"Select parent workspace"}
                    items={workspaces}
                    value={parent}
                    onChange={onChange}
                    error={error}
                    helperText={helperText}
                />
            )}
        </Field>
    );
}

ParentSpace.propTypes = {};
ParentSpace.defaultProps = {};

export default ParentSpace;
