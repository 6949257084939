import { convertToRaw } from "draft-js";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash";

const serializeToRaw = (state) => {
    return convertToRaw(state.getCurrentContent());
};

export function useHandleEditorChange(config) {
    const { onSave, setEditorState, serialize, wait: debounceWait } = config;

    const serializer = serialize || serializeToRaw;
    const wait = debounceWait || 1000;

    const save = useCallback(
        (state) => {
            let serializedContent = serializer(state);
            onSave(serializedContent);
        },
        [serializer, onSave]
    );

    const debounceSave = useMemo(() => {
        return debounce(save, wait, { trailing: true });
    }, [wait, save]);

    return useCallback(
        (newEditorState) => {
            let contentChanged = false;
            setEditorState((prev) => {
                contentChanged =
                    prev.getCurrentContent() !==
                    newEditorState.getCurrentContent();
                return newEditorState;
            });
            if (contentChanged) debounceSave(newEditorState);
        },
        [debounceSave, setEditorState]
    );
}
