import React from "react";
import {
    SideHelperList,
    SideHelperSection,
} from "joynt/components/SideHelperList";
//import PropTypes from 'prop-types';

const data = [
    {
        id: 1,
        title: "1. Access",
        content:
            "Public means that everyone who knows the link to this workspace will be able to join your community, view posts and participate in discussions. Invite only means members will have to be invited - just provide their emails and we will invite them for you.",
    },
    {
        id: 2,
        title: "2. Title & subtitle",
        content:
            "Name your workspace using a short meaningful title, so your guests know where they are. Names that are too long can clutter your view, so keep this down to 2-3 words max. A subtitle can explain the workspace’s main purpose, and is displayed like a tagline under the title - one short sentence should do the trick.",
    },
    {
        id: 3,
        title: "3. Workspace Avatar",
        content:
            "This will be displayed in the navigation sidebar, on the left side of the screen. Use 2-3 letter signature to be your workspace avatar, or pick an icon from our library. You can also set the avatar background color.",
    },
];

export default function CreateWorkspaceLeft() {
    return (
        <SideHelperList header={"Create your space with ease"} variant={3}>
            {data.map((section) => (
                <SideHelperSection
                    key={section.id}
                    title={section.title}
                    content={section.content}
                />
            ))}
        </SideHelperList>
    );
}

CreateWorkspaceLeft.propTypes = {};
CreateWorkspaceLeft.defaultProps = {};
