import googleFonts from './google-fonts';
import zuuFonts from './zuu-fonts';

export const adapters = {
    'cms.fonts.google': googleFonts,
    'cms.fonts.zuu': zuuFonts,
    'console.cms.snapshots': (data) => ({
        data: { data: Object.values(data.data.data) }
    })
};

export default function parseResponse(resource, response) {
    if (adapters[resource]) return adapters[resource](response);
    return response;
}

export function requestMiddleware(context, request) {
    let {url, data, query} = request;

    if (!query) query = {};
    if (!data) data = {};

    let [path, queryStr] = url.split('?');

    path = path.replace('entries.', 'entries/:id?type=')
        .replace('services.', 'services/:id?type=')
        .replace(/\./g, '/') // + '/:id'

    if (path.indexOf(':id') > -1) {
        if (!query.id && data.id) {
            path = path.replace('/:id', '/'+data.id);
        }
        let {id, ...other} = query;
        path = path.replace('/:id', id ? '/'+id : '');
        query = other;
    }

    if (queryStr) {
        if (path.indexOf('?') > -1) {
            path += `&${queryStr}`;
        } else {
            path += `?${queryStr}`;
        }
    }


    return {...request, url: path, query};
}