import React from "react";
import PropTypes from "prop-types";

import ExternalLinkContainer from "./ExternalLinkContainer";
import InternalLinkContainer from "./InternalLinkContainer";
import Giphy from "./Giphy";

const types = {
    internal: InternalLinkContainer,
    external: ExternalLinkContainer,
    gif: Giphy
}

function isInternalUrl(url) {
    const hostname = window.location.hostname;
    const pattern = `(http|https)://${hostname}(.*)`;
    const regex = new RegExp(pattern, 'gmi');
    return regex.test(url);
}

function linkType(url) {
    if (!url) return 'external';
    if (url.indexOf('giphy.com') > -1) return 'gif';
    if (isInternalUrl(url)) return 'internal';
    return 'external';
}

export default function LinkEmbed(props) {
    const {link} = props;
    const type = linkType(link);
    const Component = types[type];

    return <Component {...props} />;
}

LinkEmbed.defaultProps = {};
LinkEmbed.propTypes = {
    link: PropTypes.string.isRequired
};