import React from "react";
import PropTypes from "prop-types";

import { Dialog } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

import "./style.css";

export function PostPreview(props) {
    const { children, isOpen, onClose } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Slide}
            className={"post-preview-container"}
        >
            <div className={"post-preview"}>{children || null}</div>
        </Dialog>
    );
}

PostPreview.propTypes = {
    isOpen: PropTypes.bool,
};
PostPreview.defaultProps = {
    isOpen: false,
};

export default PostPreview;
