import { getIn } from "lodash-redux-immutability";

const nullObj = {};

export const selectPagination = (state, type, list) => {
    if (!type || !list) return nullObj;
    return getIn(state["lists-meta"], [type, list, "pagination"]) || nullObj;
};

export const selectPage = (state, type, list) => {
    return selectPagination(state, type, list).current;
};

export const selectPageCount = (state, type, list) => {
    return selectPagination(state, type, list).pages_count;
};
