import React from "react";
import SessionStateLabel from "./SessionStateLabel";
import { useNodeData } from "joynt/hooks";
import { useSessionState } from "joynt/hooks/sessions";

function SessionStateLabelContainer(props) {
    const { id } = props;

    const {
        event_state,
        event_started: started,
        event_start: start,
    } = useNodeData(id);

    const { hasEnded } = useSessionState(id);
    const isLive = event_state === "live";

    return (
        <SessionStateLabel
            {...{
                state: event_state,
                isLive,
                hasEnded,
                start,
                started,
            }}
        />
    );
}

export default SessionStateLabelContainer;
