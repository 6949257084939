import useFetch from "state/hooks/useFetch";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSessionCountByDay } from "joynt/components/Schedule/selectors";
import { selectRange } from "joynt/components/DailySchedule/util";
import { appendQueryParams } from "util/uri";

export function useSessionSearch(props) {
    const {
        id,
        day,
        //mode,
        search,
    } = props;

    const date = new Date(day * 1000);

    const queryRange = selectRange(date, "month");
    const queryStart = queryRange.start.getTime() / 1000;
    const queryEnd = queryRange.end.getTime() / 1000;

    const params = {
        start: queryStart,
        end: queryEnd,
        search: search || null,
    };

    const url = appendQueryParams(`joynt/nodes/${id}/sessions`, params);

    const list = `nodes.sessions.${id}`;

    const isPending = useFetch({
        url,
        type: "db.nodes",
        list,
    });

    const count = useSelector((s) => selectSessionCountByDay(s, list));

    return useMemo(
        () => ({
            list,
            count,
            isPending,
        }),
        [list, count, isPending]
    );
}
