import {useNodeData} from "joynt/hooks/useNodeData";
import {useActions} from "state/hooks";
import {pushRoute} from "state/actions/router";
import {useEdgesData} from "joynt/hooks/useEdgesData";
import {useCallback, useMemo} from "react";

export function usePublicView(id) {
    const {access_type} = useNodeData(id);
    const {workspace} = useEdgesData(id);

    const isPage = window.location.href.indexOf('/public') > -1;
    const host = window.location.hostname;

    const publicAccess = access_type === "link";
    const {onPushRoute} = useActions({onPushRoute: pushRoute});

    const workspaceUrl = `https://${host}/?id=${workspace}`;

    const routeLocal = workspace !== id
        ? `/?id=${workspace}&ch=${id}`
        : `/?id=${workspace}`;

    const routePublic = workspace !== id
        ? `/public?id=${workspace}&ch=${id}`
        : `/public?id=${workspace}`;

    const publicUrl = `https://${host}${routePublic}`;
    const targetRoute = isPage ? routeLocal : routePublic;

    const togglePublicView = useCallback(() => {
        onPushRoute(targetRoute);
    }, [targetRoute, onPushRoute]);

    return useMemo(() => ({
        hasPublicView: !!publicAccess,
        onTogglePublicView: togglePublicView,
        isPublicView: isPage,
        workspaceUrl,
        publicUrl
    }), [
        publicAccess,
        togglePublicView,
        isPage,
        workspaceUrl,
        publicUrl
    ]);
}