import React from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import PropTypes from "prop-types";

export function RadioButton(props) {
    const { onClick, children, isActive, icon } = props;
    return (
        <div
            onClick={onClick}
            className={cn("radio-btn", { "is-active": isActive })}
        >
            {icon && <div className={"radio-btn-icon"}>{icon}</div>}
            {React.Children.count(children) > 0 && (
                <div className={"radio-btn-label"}>{children}</div>
            )}
        </div>
    );
}

RadioButton.propTypes = {
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    icon: PropTypes.node,
};

RadioButton.defaultProps = {
    isActive: false,
};

export default RadioButton;
