import {selectEntityData} from "state/selectors/data";
import {SESSION_TYPE_WEBINAR_SERIES} from "joynt/config/sessions";
import {WORKSPACE_PLAN_WEBINAR} from "joynt/config/plans";
import {memoize} from "lodash";

const workspace = {
    header: [
        'sidebar-nav-header'
    ],
    content: [
        'schedule',
        'workspaces',
        'sessions',
        'projects',
        'events',
        'listings',
        'pinned',
        'bookmarks',
        'spaces',
        'channels'
    ]
}

const webinarWorkspace = {
    header: [
        'sidebar-nav-header'
    ],
    content: [
        'sessions',
        'webinar-series',
        'upcoming'
    ]
}

const webinarSeries = {
    header: [
        'sidebar-nav-header'
    ],
    content: [
        'sessions',
        'upcoming'
    ]
}

function createConfig(sections) {
    return {sections};
}

const createConfigMemo = memoize(createConfig);

export function selectSidebarNav(store, id) {
    const {session_type, workspace_plan} = selectEntityData(store, "db.nodes", id);

    if (workspace_plan === WORKSPACE_PLAN_WEBINAR)
        return createConfigMemo(webinarWorkspace);

    const sections = session_type === SESSION_TYPE_WEBINAR_SERIES
        ? webinarSeries
        : workspace;

    return createConfigMemo(sections);
}