import React from "react";
import Feed from "joynt/views/feed/Feed";
import SessionPageSection from "joynt/components/SessionPage/SessionPageSection";
import Button from "components/Button";
import Icon from "components/Icon";
//import PropTypes from 'prop-types';

export function Materials(props) {
    const { id, onCreate } = props;

    return (
        <SessionPageSection
            title={"Materials"}
            actions={
                onCreate ? (
                    <Button
                        onClick={onCreate}
                        variant={"text"}
                        startIcon={<Icon>mui-add</Icon>}
                    >
                        Create post
                    </Button>
                ) : null
            }
        >
            {id && <Feed id={id} showHeader={false} view={"cards"} />}
        </SessionPageSection>
    );
}

Materials.propTypes = {};
Materials.defaultProps = {};

export default Materials;
