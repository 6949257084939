export const pull = (context) => (theme) => ({
    type: 'THEME.VCS.PULL',
    payload: { theme },
    context
});

export const push = (context) => (theme) => ({
    type: 'THEME.VCS.PUSH',
    payload: { theme },
    context
});

export const commit = (context) => (theme, message) => ({
    type: 'THEME.VCS.COMMIT',
    payload: { theme, message },
    context
});

export const initVcs = (context) => (theme) => ({
    type: 'THEME.VCS.INIT',
    payload: { theme },
    context
});