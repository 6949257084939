import { useRouteParam } from "state/hooks";
import { useSelector } from "react-redux";
import { selectAliasedEntity } from "state/selectors/data";

export function useCurrentService() {
    const [serviceName] = useRouteParam("service");
    return useSelector((store) =>
        selectAliasedEntity(store, "console.services", serviceName)
    );
}
