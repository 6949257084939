import React, { useMemo } from "react";
import { useCreateEditorState } from "./useCreateEditorState";
import EditorStateContext from "./EditorStateContext";

//import PropTypes from 'prop-types';

export function EditorStateProvider(props) {
    const [state, setState] = useCreateEditorState();

    const context = useMemo(() => [state, setState], [state, setState]);

    return (
        <EditorStateContext.Provider value={context}>
            {props.children}
        </EditorStateContext.Provider>
    );
}

EditorStateProvider.propTypes = {};

EditorStateProvider.defaultProps = {};

export default EditorStateProvider;
