import React from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import List from "containers/List";

import 'playground/booking/css/rooms-nav.css';

export function RoomsNav({type, value, active, onClick}) {
    return <div className={'cols gap-sm'}>
        <List
            onClick={onClick}
            type={type}
            items={value}
            active={active}
            Iterator={RoomsNavIterator} />
    </div>;
}

export class RoomsNavItem extends React.PureComponent {

    click = () => this.props.onClick(this.props.index);

    render() {
        const { index, active } = this.props;
        const isActive = active === index;
        const { room, adults } = this.props.data;
        return (<div onClick={this.click} className={classNames('rooms-nav-item', {
            active: isActive
        })}>
            <div>Pokój {index+1}</div>
            <div>{room || 'WYBIERZ POKÓJ'} {adults} dorosłych</div>
        </div>);
    }

}

export const RoomsNavIterator = ArrayIterator(withData(RoomsNavItem));

RoomsNavItem.propTypes = {};