import React from 'react';
//import PropTypes from 'prop-types';
import {ChildComponents} from "cms/components/Layout";
import {NS_COMPONENTS} from "cms/components/types";

export default class Plan extends React.PureComponent {

    render() {
        const {
            components,
            classes,
            inspectId
        } = this.props;

        return <div
            data-inspect={inspectId}
            data-type={'plan'}
            data-s={'plan'}
            className={classes.plan}
        >
            <ChildComponents
                itemType={NS_COMPONENTS}
                items={components}
            />
        </div>;
    }

}

Plan.propTypes = {};
Plan.autoFillProps = [
    'components'
];