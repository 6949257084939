import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { StaticLink } from "./Features";

export function Card(props) {
    const { children } = props;
    return (
        <>
            {children}
            <Field id={"media"} label={"Media"} fieldType={"media"} />
            <StaticLink />
        </>
    );
}

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
