import React from 'react';
import Feed from "joynt/views/feed/Feed";
import {useNodeData} from "joynt/hooks";
import {POST_TYPE_POLL} from "joynt/config";
import CreatePost from "joynt/components/UtilityNav/Session/CreatePost";

//import PropTypes from 'prop-types';

const postProps = {
    postSize: 'sm',
    showComments: false
}

const types = [POST_TYPE_POLL];

export function Polls(props) {

    const {id} = props;
    const {can_post} = useNodeData(id);

    return (
        <Feed
            {...props}
            id={id}
            variant={'compact'}
            postProps={postProps}
            postTypes={types}
            showHeader={false}
        >
            {can_post && <CreatePost
                id={id}
                postTypes={types}
            />}
        </Feed>
    );

}

Polls.propTypes = {};
Polls.defaultProps = {};

export default Polls;
