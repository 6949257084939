import React from "react";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";
import types from "./Types";
import ComponentContext from "./ComponentContext";

export function Component(props) {
    const { id, sectionId } = props;
    const data = useData(props);

    const type = data.element_type;
    const TypeComponent = types[type];

    if (!TypeComponent) return <div>Unknown type: {type}</div>;

    return (
        <ComponentContext id={id} sectionId={sectionId}>
            <TypeComponent {...props} />
        </ComponentContext>
    );
}

Component.propTypes = {};

Component.defaultProps = {
    type: "cms.components",
};

export default Component;
