import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import Popover from 'components/Popover';
import {config, types} from 'cms/ui/config/style-options';





function selectValues(store, id) {
    let styles = store.data['cms.styles'];
    return styles.options[id] || [];
}

const mapStateToProps = (store, props) => {
    let values = selectValues(store, props.id);
    let label = props.value
        ? props.value.find(k=>values.includes(k))
        : null;
    return {
        values,
        label
    }
};

const mapDispatchToProps = {};

function StyleOptionValue({id, onSelect, onClose}) {
    const select = useCallback(() => {
        onClose();
        setTimeout(()=>onSelect(id), 1);
    }, [id, onSelect, onClose]);

    return <div className={'style-select'} onClick={select}>
        {id ? id : 'unset'}
    </div>;
}

function StyleOption(props) {

    const {
        id,
        label,
        value,
        values,
        onChange
    } = props;

    const change = useCallback((val) => {
        let nextValue = value ? value.slice() : [];
        nextValue = nextValue.filter(v=>values.indexOf(v)===-1);
        if (val) nextValue.push(val);
        onChange(nextValue);
    }, [value, values, onChange]);

    const cfg = config[id];

    if (cfg && cfg.type) {
        let Component = types[cfg.type];
        return <Component {...props} onChange={change} />;
    }

    return <div className={'style-option style-option--select'}>
        <Popover
            trigger={({onClick}) => <div
                className={'cols cols-spread'}
                onClick={onClick}
            >
                <div>{id}</div>
                <div className={'style-class'}>{label}</div>
            </div>}
            content={({onClose}) => <div className={'style-select-menu'}>
                <StyleOptionValue
                    key={'reset'}
                    id={null}
                    onSelect={change}
                    onClose={onClose}
                    />
                {values.map(v=><StyleOptionValue
                    key={v}
                    id={v}
                    onSelect={change}
                    onClose={onClose}
                />)}
            </div>}
        />
    </div>;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StyleOption);