import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import DatePicker from "./DatePicker";
import {useMoment} from "joynt/components/DateTimePicker/hooks";
import {selectRange} from "joynt/components/DailySchedule/util";

export function DatePickerContainer(props) {

    const {
        value,
        mode,
        count,
        onChange
    } = props;

    // const today = new Date();
    // today.setDate(today.getDate()+1);
    // today.setHours(0,0,0,0);
    // const todayTs = today.getTime()/1000;

    const valueMoment = useMoment(value);
    const date = new Date(value*1000);
    const range = selectRange(date, mode);
    const start = range.start.getTime()/1000;
    const end = range.end.getTime()/1000;
    const month = date.getMonth();
    const year = date.getFullYear();

    const changeMonth = useCallback((m, y) => {
        const next = new Date(value*1000);
        next.setHours(0,0,0,0);
        next.setFullYear(y, m);
        onChange(next.getTime()/1000);
    }, [onChange, value]);

    const changeDay = useCallback(d => {
        onChange(d.unix());
    }, [onChange]);

    const renderDay = useCallback((day, selected, dayInCurrentMonth, dayComponent) => {
        const ts = day.unix();
        const className = cn("day", {
            "other-month": !dayInCurrentMonth,
            "is-selected": ts >= start && ts <= end,
            "has-sessions": count[ts] > 0,
            //"is-disabled": ts > todayTs
        });
        return <div className={className}>
            {day.format('DD')}
        </div>;
    }, [count, start, end]);

    return (<DatePicker
        {...props}
        value={valueMoment}
        renderDay={renderDay}
        onChangeMonth={changeMonth}
        onChange={changeDay}
        month={month}
        year={year}
    />);

}

DatePickerContainer.propTypes = {
    id: PropTypes.string
};
DatePickerContainer.defaultProps = {};

export default DatePickerContainer;