import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import ScheduleSession from "./ScheduleSession";
import {useChannels} from "joynt/hooks/channels";
import {NODE_TYPE_EVENT} from "joynt/config";

export function ScheduleSessionContainer(props) {

    const {id} = props;
    const {onCreate} = useChannels(id, `db.nodes.sessions.${id}`);

    const create = useCallback(()=>onCreate({
        subtype: NODE_TYPE_EVENT
    }), [onCreate]);

    return (<ScheduleSession
        {...props}
        onCreate={create}
    />);

}

ScheduleSessionContainer.propTypes = {
    id: PropTypes.string
};
ScheduleSessionContainer.defaultProps = {};

export default ScheduleSessionContainer;