import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import WitnessMessage from "./WitnessMessage";
import {useRouteParam} from "state/hooks";
import {ROUTE_PAGE} from "joynt/config";
import {MODAL_WITNESS_LEAVE} from "./../modals";

export function WitnessMessageContainer(props) {

    //const {} = props;

    const [,setPage] = useRouteParam(ROUTE_PAGE);

    const onCancel = useCallback(() => {
        setPage(MODAL_WITNESS_LEAVE);
    }, [setPage]);

    return (<WitnessMessage
        {...props}
        onCancel={onCancel}
    />);

}

WitnessMessageContainer.propTypes = {
    id: PropTypes.string
};
WitnessMessageContainer.defaultProps = {};

export default WitnessMessageContainer;