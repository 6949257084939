import React, { useCallback, useEffect, useMemo } from "react";
//import PropTypes from 'prop-types';

import MeetingOverlay from "joynt/meeting/MeetingOverlay";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { useSelector } from "react-redux";
import {
    selectEventRole,
    selectUserNodeIdentity,
} from "joynt/state/selectors/joint";
import { useApiActions, useData, useRouteParam, useUiFlag } from "state/hooks";
import {
    endSession,
    resetSession,
    startNextSession,
} from "joynt/state/actions/meetings";
import {
    EVENT_ROLE_ADMIN,
    EVENT_ROLE_MODERATOR,
    EVENT_ROLE_OWNER,
    EVENT_ROLE_SPEAKER,
    EVENT_ROLE_RECORDING,
    NODE_TYPE_EVENT,
    ROUTE_VIEW,
    SESSION_POLICY_MEETING,
    SESSION_POLICY_OFFLINE,
    STAGE_VIEW,
} from "joynt/config";
import { joinMeeting } from "joynt/state/actions/meetings";
import { selectEntityData } from "state/selectors/data";
import Dialog from "components/ResponsiveDialog";
import DeviceSettings from "joynt/meeting/settings/DeviceSettings";
import { sessionBackends } from "joynt/meeting/config";
import { useRouteNodes, useRouter } from "joynt/hooks/channels";
import { ErrorBoundary } from "joynt/meeting/agora-ng/ErrorBoundary";
import AgoraClientProvider from "joynt/meeting/agora-ng/AgoraClientProvider";
import SessionDebug from "joynt/meeting/agora-ng/SessionDebug";
import BrowserDeviceAccess from "joynt/meeting/devices/BrowserDeviceAccess";
import useVideoSettings from "joynt/meeting/hooks/useVideoSettings";
import { isAllowedOnStage } from "joynt/config/roles";
import { NotificationsProvider } from "joynt/meeting/notifications";
import { MeetingContext } from "joynt/meeting/MeetingContext";
import useMeeting from "./hooks/useMeeting";
import { SessionFlowProvider } from "joynt/components/SessionFlow";
import {
    selectEffectiveFlowPath,
    selectFlowSegment,
} from "joynt/components/SessionFlow/selectors";

export function isRoleAllowedOnStage(policy, role) {
    if (policy === SESSION_POLICY_OFFLINE) return false;

    if (role === EVENT_ROLE_RECORDING) return false;
    if (policy === SESSION_POLICY_MEETING) return true;

    return isAllowedOnStage.indexOf(role) > -1;
}

export function useMeetingSession() {
    const { access, session: currentSession, event } = useMeeting();
    const [pendingSession] = useUiFlag("meetingPendingSession");
    const session = pendingSession || currentSession;

    const { name: spaceName } = useNodeData(session);
    const { name: sessionName, subtype } = useNodeData(event);
    const isEvent = subtype === NODE_TYPE_EVENT;
    const name = sessionName || spaceName;
    const { id: workspace } = useRouteNodes();
    const { current_event: workspaceEvent } = useNodeData(workspace);
    const userIdentity = useSelector((s) =>
        selectUserNodeIdentity(s, workspace)
    );
    const { name: identityName } = useData({
        type: "db.identities",
        id: userIdentity,
    });
    const { path, parent_session } = useEdgesData(session);
    const isEventSession =
        (path && path.indexOf(workspaceEvent) > -1) ||
        event === workspaceEvent ||
        session === workspace;
    const parentEvent = isEventSession ? workspaceEvent : parent_session;

    return useMemo(
        () => ({
            session,
            parentEvent: parentEvent,
            event,
            pending: pendingSession,
            switching: pendingSession && pendingSession !== currentSession,
            current: currentSession,
            name,
            identityName,
            identity: userIdentity || access?.user_id,
            isEvent,
            isEventSession,
        }),
        [
            session,
            parentEvent,
            event,
            pendingSession,
            currentSession,
            name,
            identityName,
            userIdentity,
            access,
            isEvent,
            isEventSession,
        ]
    );
}

export default function MeetingContainer(props) {
    const { children, recordMode } = props;

    const [session] = useUiFlag("meetingSessionId", null);

    const { setSession } = useApiActions({ setSession: joinMeeting });

    const onJoinSession = setSession;
    const { name, workspace, current_event } = useNodeData(session);
    const event = current_event || session;
    const eventRole = useSelector((s) => selectEventRole(s, event));
    const access = useSelector((s) =>
        selectEntityData(s, "db.session-access", session)
    );

    const { event_session_policy: nodeSessionPolicy, session_flow_segment } =
        useNodeData(event);

    const flowPath = useSelector((s) => selectEffectiveFlowPath(s, event));
    const [flow, step] = flowPath.split(".");
    const flowConfig = useSelector((s) => selectFlowSegment(s, flow, step));
    const isOffline = flowConfig.offline;

    const sessionPolicy = isOffline
        ? SESSION_POLICY_OFFLINE
        : nodeSessionPolicy;

    const view = useRouteParam(ROUTE_VIEW)[0];

    useEffect(() => {
        if (session && workspace && !current_event) onJoinSession(null);
    }, [workspace, current_event, session, onJoinSession]);

    const { showSettings, onShowSettings, onAcceptSettings } =
        useVideoSettings(session);

    const { onStartNextSession, onEndSession, onResetSession } = useApiActions({
        onEndSession: endSession,
        onStartNextSession: startNextSession,
        onResetSession: resetSession,
    });

    const onLeaveSession = useCallback(() => {
        onJoinSession(null);
    }, [onJoinSession]);

    const { onLink } = useRouter();

    const sessionLink = useCallback(() => {
        onLink(session, null, STAGE_VIEW);
    }, [onLink, session]);

    const overrideFlagId = `session.${session}.${session_flow_segment}.policy`;
    const [overridePolicy] = useUiFlag(overrideFlagId);
    const effectivePolicy = overridePolicy || sessionPolicy;

    const hideSettings = useCallback(() => {
        onShowSettings(false);
    }, [onShowSettings]);

    const meetingContext = useMemo(() => {
        return {
            session,
            role: eventRole,
            policy: effectivePolicy,
            event: event,
            access,
            isAllowedOnStage: isRoleAllowedOnStage(effectivePolicy, eventRole),
            onJoinSession,
            onLeaveSession,
            onStartNextSession,
            onEndSession,
            onShowSettings,
            onResetSession,
            onShowSession: sessionLink,
        };
    }, [
        session,
        event,
        effectivePolicy,
        eventRole,
        access,
        onLeaveSession,
        onJoinSession,
        onStartNextSession,
        onEndSession,
        onShowSettings,
        onResetSession,
        sessionLink,
    ]);

    const StageBackend =
        sessionBackends[access.type] || sessionBackends.default;

    return (
        <MeetingContext.Provider value={meetingContext}>
            <AgoraClientProvider>
                <BrowserDeviceAccess
                    on={(!!session || showSettings) && !recordMode}
                >
                    <NotificationsProvider id={session}>
                        <SessionFlowProvider
                            id={event}
                            session={session}
                            path={flowPath}
                        >
                            <SessionDebug>
                                {children}
                                {session && access.token ? (
                                    <ErrorBoundary>
                                        <MeetingOverlay
                                            id={session}
                                            view={view}
                                            portal={!recordMode}
                                        >
                                            {name === "debug" ? (
                                                <div>dev</div>
                                            ) : (
                                                <StageBackend
                                                    {...meetingContext}
                                                />
                                            )}
                                        </MeetingOverlay>
                                    </ErrorBoundary>
                                ) : null}
                                {!recordMode ? (
                                    <Dialog
                                        open={showSettings}
                                        onClose={hideSettings}
                                        className={
                                            "dark-mode device-settings-dialog"
                                        }
                                    >
                                        <DeviceSettings
                                            onClose={hideSettings}
                                            onSubmit={onAcceptSettings}
                                        />
                                    </Dialog>
                                ) : null}
                            </SessionDebug>
                        </SessionFlowProvider>
                    </NotificationsProvider>
                </BrowserDeviceAccess>
            </AgoraClientProvider>
        </MeetingContext.Provider>
    );
}

MeetingContainer.propTypes = {};
MeetingContainer.defaultProps = {};
