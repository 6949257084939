import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet";
import { useTotalNotifications } from "joynt/hooks/notifications";
import { useRouteParam, useUiFlag } from "state/hooks";
import { useNodeData } from "joynt/hooks";

import useScreenQuery from "util/hooks/useScreenQuery";
import useScreenSize from "util/hooks/useScreenSize";
import { FLAG_SESSION_FLOATING, FLAG_SESSION_NAV } from "joynt/config";

//import PropTypes from 'prop-types';

export default function PageHead(props) {
    //register screen query listener, so that window size is available via global state
    useScreenQuery();

    const {
        height,
        isMobile,
        isDesktop,
        isLargeDesktop,
        isTablet,
        isSmallTablet,
        isTouchscreen,
        isHorizontalPhone,
    } = useScreenSize();
    const [showSidebar] = useUiFlag("mobile.showSidebar");
    const [showSecSidebar] = useUiFlag("mobile.showUtilityNav");
    const [isFloatingSession] = useUiFlag(FLAG_SESSION_FLOATING);
    const [sessionUtilityNav] = useUiFlag(FLAG_SESSION_NAV);

    const [id] = useRouteParam("id");
    const { name } = useNodeData(id);
    const count = useTotalNotifications();
    const headTitle = name ? `${name} - joynt` : `joynt.works`;
    const title = count ? `(${count}) ${headTitle}` : headTitle;

    return (
        <Helmet defer={false}>
            <body
                className={cn("theme-light default joynt-theme", {
                    "joynt-mobile": isMobile,
                    "joynt-tablet": isTablet,
                    "joynt-small-tablet": isSmallTablet,
                    "joynt-desktop": isDesktop,
                    "joynt-large-desktop": isLargeDesktop,
                    "show-sidebar": showSidebar || showSecSidebar,
                    "active-session": isFloatingSession,
                    "session-nav": sessionUtilityNav,
                })}
            />
            <style>
                {`:root {
                    --viewport-height: ${height}px;
                }`}
            </style>
            <title>{title}</title>
            <link
                type="text/css"
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
            />
            <link
                type="text/css"
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <link
                type="text/css"
                rel="stylesheet"
                href="https://cdn.joynt.works/atoms/style.min.css"
            />
        </Helmet>
    );
}

PageHead.propTypes = {};
PageHead.defaultProps = {};
