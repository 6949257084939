import { useCallback, useMemo } from "react";
import { useActions, useApiActions, useCurrentUser } from "state/hooks";
import usePrivateChannel from "joynt/websockets/hooks/usePrivateChannel";
import useListen from "joynt/websockets/hooks/useListen";
import { pushEntity } from "joynt/state/actions";
import {
    pushActivityEvent,
    pushNotification,
} from "joynt/state/actions/activity";
import { storeOne } from "state/actions/data";
import { pushMessage } from "joynt/state/actions/messages";

function useHandlers() {
    const { handleNotification, onUpdateNotifications, onPushMessage } =
        useActions({
            handleNotification: pushNotification,
            onUpdateNotifications: storeOne,
            onPushMessage: pushMessage,
        });

    const { onPushActivity, handlePush } = useApiActions({
        onPushActivity: pushActivityEvent,
        handlePush: pushEntity,
    });

    const entityHandler = useCallback(
        (event) => {
            let data = event.entity;
            handlePush(data.type, data.id, data);
        },
        [handlePush]
    );

    const activityHandler = useCallback(
        (msg) => {
            onPushActivity(msg.context);
        },
        [onPushActivity]
    );

    const notificationStatHandler = useCallback(
        (event) => {
            const data = event.context;
            onUpdateNotifications("db.notification-stats", data.id, data);
        },
        [onUpdateNotifications]
    );

    const messageHandler = useCallback(
        (msg) => {
            onPushMessage(msg.context);
        },
        [onPushMessage]
    );

    return useMemo(
        () => ({
            entity: entityHandler,
            activity: activityHandler,
            notifications: notificationStatHandler,
            notification: handleNotification,
            message: messageHandler,
        }),
        [
            entityHandler,
            activityHandler,
            notificationStatHandler,
            handleNotification,
            messageHandler,
        ]
    );
}

export default function UserChannel(props) {
    const id = useCurrentUser();
    const sub = usePrivateChannel(`user-${id}`);

    const handlers = useHandlers();

    useListen(sub, ".entity", handlers.entity);
    useListen(sub, ".activity", handlers.activity);
    useListen(sub, ".notifications", handlers.notifications);
    useListen(sub, ".notification", handlers.notification);
    useListen(sub, ".message", handlers.message);

    return null;
}
