export default {
    "db.view_fields": {
        fields: {
            view_fields: ["@self"],
        },
    },
    "db.fields": {
        options: {
            processStrategy: "@parent",
        },
        fields: {
            fields: ["@self"],
            view_fields: ["db.view_fields"],
        },
    },
    "db.types": {
        fields: {
            fields: ["db.fields"],
            subtype_of: "@self",
        },
    },
    "db.form_views": {
        fields: {
            view_fields: ["db.view_fields"],
        },
    },
    "db.filters": {
        fields: {
            filter_params: ["db.filter_params"],
            filters_config: ["db.filters_config"],
        },
    },
    "db.filters_config": {},
    "db.filter_params": {},
    "db.list-views": {
        fields: {
            view_fields: ["db.view_fields"],
        },
        refs: {
            filters: "db.filters",
        },
    },
    "schema.db": {
        fields: {
            fields: ["db.fields"],
            subtype_of: "@self",
        },
    },
    "schema.data.value": {},
    "schema.data": {
        fields: {
            values: ["schema.data.value"],
        },
    },
    "db.media": {
        fields: {},
    },
    "db.tasks": {
        fields: {},
    },
    "db.identities": {
        fields: {
            media: ["db.media"],
        },
    },
    "db.edges": {
        refs: {
            from: "db.nodes",
            to: "db.nodes",
            nodes: ["db.nodes"],
        },
    },
    "db.sessions": {},
    "db.nodes": {
        refs: {
            thread: "db.threads",
            identity: "db.identities",
            recipient_identities: ["db.identities"],
            event_speakers: ["db.identities"],
            event_moderators: ["db.identities"],
            parent: "@self",
            current_session: "db.sessions",
            tags: ["db.nodes"],
            session_access: "db.session-access",
        },
        fields: {
            posts: ["db.posts"],
            meeting: "db.posts",
            edges: ["db.edges"],
            media: ["db.media"],
            media_header: ["db.media"],
            media_logo: ["db.media"],
            media_avatar: ["db.media"],
        },
    },
    "db.nodes.activity": {},
    "db.nodes.activity.spaces": {
        fields: {
            activity_nodes: ["db.nodes.activity"],
            activity_posts: ["db.nodes.activity"],
            activity_chats: ["db.nodes.activity"],
            activity_other: ["db.nodes.activity"],
            activity_all: ["db.nodes.activity"],
        },
    },
    "db.posts": {
        fields: {
            tasks: ["db.tasks"],
            media: ["db.media"],
            answers: ["db.answers"],
        },
        refs: {
            identity: "db.identities",
            parent: "db.nodes",
            thread: "db.threads",
            meeting: "db.meetings",
        },
    },
    "db.answers": {},
    "db.activity": {
        fields: {
            notification_status: "app.notification-status",
        },
        refs: {
            identity: "db.identities",
            parent: "db.nodes",
            notifications: ["db.notifications"],
        },
    },
    "db.messages": {
        fields: {
            media: ["db.media"],
            notification_status: "app.notification-status",
        },
        refs: {
            identity: "db.identities",
        },
    },
    "db.threads": {
        fields: {},
        refs: {
            participants: ["db.identities"],
            last_message: "db.messages",
        },
    },
    "db.meetings": {
        fields: {},
        refs: {},
    },
    "db.tags": {
        fields: {},
    },
    "db.enum-values": {
        fields: {},
    },
    "db.enum-definitions": {
        fields: {
            values: ["db.enum-values"],
        },
    },
    "db.user-profiles": {},
    "db.users": {
        refs: {
            app_access_profile: "db.user-profiles",
        },
    },
    "db.notifications": {
        refs: {
            identity: "db.identities",
            activity: "db.activity",
            parent: "db.nodes",
        },
    },
    "db.notifications-tag": {
        refs: {
            notifications: ["db.notifications"],
        },
    },
    "db.identity-info": {
        refs: {
            last_thread_data: "db.nodes",
        },
    },
    "app.notification-status": {},
    "db.session-access": {},
};
