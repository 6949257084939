import React from 'react';
//import PropTypes from 'prop-types';
import Field from 'containers/Field';

export default class UserSettings extends React.PureComponent {

    render() {
        return (<div className={'pad-md'}>
            <Field
                id={'theme_preset'}
                fieldType={'select'}
                label={'Theme preset'}
                source={'api:console.presets'}
            />
        </div>);
    }

}

UserSettings.propTypes = {};