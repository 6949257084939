import React, {useEffect, useMemo, useRef, useState} from 'react';
import circlePack from './d3-circle-pack';

import {useSelector} from "react-redux";
import {selectList as selectListData} from "state/selectors/data";
import {useEdges, useNode} from "joynt/hooks";
import {useRouteParam} from "state/hooks";
import {selectList} from "state/selectors/lists";

import "./style.css";

function denormalizeTree(list) {
    let map = {},
        node,
        roots = [],
        i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent) {
            // if you have dangling branches check that map[node.parentId] exists
            if (Object.keys(map).indexOf(node.parent) > -1) {
                list[map[node.parent]].children.push(node);
                if (node.expand) list[map[node.parent]].expand = true;
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
};

function useGraph(rootNodeId, selected) {
    const [id] = useRouteParam('id');
    const root = rootNodeId || id;
    const ids = useSelector(store=>selectList(store, 'db.nodes', 'db.nodes.graph'));
    const hash = ids.join(':');
    const data = useSelector(store=>selectListData(store, 'db.nodes', 'db.nodes.graph'));
    return useMemo(() => {
        let nodes = data.map(n => {
            let parent = n.id === root ? null : n.edges_ref.parent;
            return {
                id: n.id,
                name: n.name,
                type: n.subtype,
                size: 1,
                parent
            }
        });
        return denormalizeTree(nodes);
    // eslint-disable-next-line
    }, [hash]);
}

function useCircles(root, id, onClick, w, h) {

    const [hover, onHover] = useState(null);
    const graph = useGraph(root, id);
    const {children, parent} = useEdges(id);

    const selected = !children ? parent : id;

    const events = useMemo(() => ({
        onClick,
        onHover
    }), [onClick, onHover]);

    const ref = useRef(null);

    const [node, zoom] = useMemo(() => {
        if (!graph || !graph.length) return [];
        return circlePack(graph[0], events.onClick, events.onHover, w, h);
    }, [graph, events, w, h]);

    useEffect(() => {
        const element = ref.current;
        if (node && element) {
            element.appendChild(node);
        }
        return () => {
            if (element) {
                while (element.lastElementChild) {
                    element.removeChild(element.lastElementChild);
                }
            }
        }
    }, [node, ref]);

    useEffect(() => {
        if (zoom && selected) zoom(selected);
    }, [zoom, selected]);

    return {hover, zoom, ref};

}

function NodeInfo({id, onHide}) {
    const {name} = useNode(id);
    return <div onClick={onHide} className={'absolute-b default shadow pad-md cols cols-center'}>
        {name}
    </div>;
}

export default function(props) {
    const {root, id} = props;
    const {onClick, onHide} = props;

    const w = props.width || window.innerWidth-100;
    const h = props.height || window.innerHeight; //-200;

    const {hover, ref} = useCircles(root, id, onClick, w, h);

    return <div className={'rows grow'}>
        <div ref={ref} className={'grow'} />
        <NodeInfo id={hover} onHide={onHide} />
    </div>;
}

