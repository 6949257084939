import React from "react";
//import PropTypes from 'prop-types';
import Form from "containers/Form";

import { FormLayout } from "playground/cms/Form";

export function ResourceForm(props) {
    const { children } = props;
    return (
        <FormLayout {...props}>
            <FormLayout.Header>
                <FormLayout.Header.Nav>
                    <FormLayout.Tabs />
                </FormLayout.Header.Nav>
            </FormLayout.Header>
            <FormLayout.Content>
                <FormLayout.Tab id={"main"} label={"Dane"}>
                    <FormLayout.Fieldset pad>
                        <Form {...props} />
                    </FormLayout.Fieldset>
                </FormLayout.Tab>
            </FormLayout.Content>
            <FormLayout.Footer>
                <FormLayout.Actions {...props} />
            </FormLayout.Footer>
            {children}
        </FormLayout>
    );
}

ResourceForm.propTypes = {};

ResourceForm.defaultProps = {};

export default ResourceForm;
