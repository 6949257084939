import React from "react";
import ReactJoyride from "react-joyride";
import PropTypes from "prop-types";

const JoyntJoyrideStyles = {
    tooltip: {
        borderRadius: 10,
        paddingTop: 32,
        color: "#380F67",
        lineHeight: "1.5em",
    },
    tooltipContent: {
        padding: 8,
    },
    buttonClose: {
        height: 12,
    },
    buttonNext: {
        background: "rgb(95, 50, 165)",
        borderRadius: 24,
        padding: "8px 16px",
        margin: "0 auto",
    },
};

function Joyride(props) {
    const { steps, run, callback, ...other } = props;

    return (
        <ReactJoyride
            steps={steps}
            run={run}
            disableScrollParentFix={true}
            callback={callback}
            styles={JoyntJoyrideStyles}
            {...other}
        />
    );
}

export default Joyride;

Joyride.propTypes = {
    steps: PropTypes.array,
    run: PropTypes.bool,
    callback: PropTypes.func,
};
