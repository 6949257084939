import React from "react";
import {
    RichUtils,
    getDefaultKeyBinding,
    EditorState,
    Modifier,
    SelectionState
} from "draft-js";
import {
    getCurrentBlock,
    moveFocusToBlockEnd,
    insertNewBlockToContentState,
    createBlock
} from "joynt/components/ContentEditor/util";

const DEFAULT_BLOCK_TYPE = "unstyled";

function addBlock(nextState) {
    const newBlock = createBlock(DEFAULT_BLOCK_TYPE, "");
    const lastBlock = nextState.getCurrentContent().getLastBlock();
    let isEmptyBlock = lastBlock.getLength() === 0;

    if (isEmptyBlock) return moveFocusToBlockEnd(nextState, lastBlock);

    nextState = EditorState.set(nextState, {
        currentContent: insertNewBlockToContentState(
            nextState.getCurrentContent(),
            newBlock,
            lastBlock.getKey()
        ),
    });
    return moveFocusToBlockEnd(nextState, newBlock);
}

export function createMiscEditingPlugin() {

    const store = {
        editor: null,
        focus: false
    };

    function MiscEditing(props) {
        const add = () => {
            let state = store.editor.getEditorState();
            let block = state.getCurrentContent().getLastBlock();
            if (block.getType() === DEFAULT_BLOCK_TYPE && block.getLength() === 0) {
                store.editor.setEditorState(moveFocusToBlockEnd(state, block));
                store.focus = true;
                return;
            }

            store.editor.setEditorState(addBlock(state));
            store.focus = true;
        };
        const key = store.editor ? getCurrentBlock(store.editor.getEditorState()).getKey() : null;
        return <>
            {store.focus && key && <style>
                {`.placeholder[data-offset-key="${key}-0-0"] { --o: 0.2; }`}
            </style>}
            <div className={"editor-fill"} onClick={add} />
        </>;
    }

    return {
        initialize: (editorFns) => {
            store.editor = editorFns;
        },
        keyBindingFn: (e) => {
            if (e.keyCode === 9) {
                return { command: "tab", e };
            }
            return getDefaultKeyBinding(e);
        },
        handleKeyCommand: (command, editorState, ts, { setEditorState }) => {
            if (command === "backspace") {
                let block = getCurrentBlock(editorState);
                let prevBlock = editorState
                    .getCurrentContent()
                    .getBlockBefore(block.getKey());
                let prevIsAtomic = prevBlock ? prevBlock.getType() === "atomic" : false;

                if (block.getLength() === 0 && prevIsAtomic) {
                    const selection = new SelectionState({
                        anchorKey: prevBlock.getKey(),
                        anchorOffset: prevBlock.getLength(),
                        focusKey: block.getKey(),
                        focusOffset: 0,
                        isBackward: false
                    });
                    setEditorState(moveFocusToBlockEnd(EditorState.push(
                        editorState,
                        Modifier.removeRange(
                            editorState.getCurrentContent(),
                            selection,
                            "forward"
                        ),
                        "remove-range"
                    ), prevBlock));
                    return "handled";
                }
                return "not-handled";
            }
            if (command?.command === "tab") {
                let nextState = RichUtils.onTab(command.e, editorState, 2);
                if (!nextState) return "not-handled";

                setEditorState(nextState);
                return "handled";
            }

            let nextState = RichUtils.handleKeyCommand(editorState, command);
            if (!nextState) return "not-handled";

            setEditorState(nextState);
            return "handled";
        },
        onFocus: () => store.focus = true,
        onBlur: () => store.focus = false,
        MiscEditing
    };
}
