import React from 'react';
import Questions from "joynt/sidebar/Questions";
//import PropTypes from 'prop-types';

export function QA(props) {

    const {id} = props;

    return (
        <Questions id={id} />
    );

}

QA.propTypes = {};
QA.defaultProps = {};

export default QA;
