import React from "react";
import PropTypes from "prop-types";

import { useNodeData } from "joynt/hooks";

import MaterialPostCard from "./MaterialPostCard";
import PostAuthor from "./../PostAuthor";
import PostTags from "./../PostTags";
import { useSelector } from "react-redux";
import { selectPostContent } from "joynt/state/selectors/joint";
import { useClick, usePending, useRouteParam } from "state/hooks";
import { ROUTE_POST } from "joynt/config";
import { useFetchLinkData } from "joynt/components/LinkEmbed";
import { useImpressionMonitor } from "joynt/components/ImpressionMonitor";

const LinkMaterialPostCard = React.forwardRef((props, ref) => {
    const { link, ...other } = props;
    const linkData = useFetchLinkData(link);

    const title = other.title || linkData.title;
    const description = other.content || linkData.description;

    return (
        <MaterialPostCard
            {...other}
            ref={ref}
            title={title}
            content={description}
            isPending={other.isPending || linkData.pending}
        />
    );
});

export function PostCardContainer(props) {
    const { id } = props;
    const [, showPost] = useRouteParam(ROUTE_POST);
    const { name, created_at, identity } = useNodeData(id);
    const { content, link } = useSelector((s) => selectPostContent(s, id));
    const click = useClick({ onClick: showPost, id });
    const isPending = usePending(id);
    const { ref, hasNotifications } = useImpressionMonitor(id, true);

    const Component = link ? LinkMaterialPostCard : MaterialPostCard;

    return (
        <Component
            {...props}
            ref={ref}
            hasNotifications={hasNotifications}
            title={name}
            content={content}
            postedAt={created_at}
            author={identity ? <PostAuthor id={identity} /> : null}
            tags={<PostTags id={id} />}
            onClick={click}
            link={link}
            isPending={isPending}
        />
    );
}

PostCardContainer.propTypes = {
    id: PropTypes.string,
};
PostCardContainer.defaultProps = {};

export default PostCardContainer;
