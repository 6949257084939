import { TREE_EXPAND } from "config/list-aliases";

export const expand = (type, list, item) => {
    let listId = [list, TREE_EXPAND].join('/');
    return {
        type: 'DATA.LIST.APPEND',
        payload: {
            type,
            list: listId,
            item
        }
    }
};

export const collapse = (type, list, item) => {
    let listId = [list, TREE_EXPAND].join('/');
    return {
        type: 'DATA.LIST.DELETE',
        payload: {
            type,
            list: listId,
            item
        }
    }
};