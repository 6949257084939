import React from 'react';
//import PropTypes from 'prop-types';

export default class ModalBody extends React.Component {

    render() {
        return (<div className={'modal__body dark-c700 rows'}>
            {this.props.children}
        </div>);
    }

}

ModalBody.propTypes = {};