export const joinMeeting =
    (context) => (id, forced, dontAskForConfirmation) => ({
        type: "MEETING.JOIN",
        payload: { id, forced, dontAskForConfirmation },
        context,
    });

export const autoJoinMeeting = (id) => ({
    type: "MEETING.AUTO_JOIN",
    payload: { id },
});

export const leaveMeeting = (id) => ({
    type: "MEETING.LEAVE",
});

export const startRecording = (context) => (id) => ({
    type: "MEETING.RECORD.START",
    payload: { id },
    context,
});

export const stopRecording = (context) => (id) => ({
    type: "MEETING.RECORD.STOP",
    payload: { id },
    context,
});

export const endSession = (context) => (id) => ({
    type: "MEETING.SESSION.END",
    payload: { id },
    context,
});

export const resetSession = (context) => (id) => ({
    type: "MEETING.SESSION.RESET",
    payload: { id },
    context,
});

export const startNextSession = (context) => (id) => ({
    type: "MEETING.SESSION.NEXT",
    payload: { id },
    context,
});

export const callToSession = (context) => (id) => ({
    type: "MEETING.SESSION.CALL",
    payload: { parent: id },
    context,
});

export const startSessions = (context) => (id) => ({
    type: "MEETING.SESSIONS.START",
    payload: { id },
    context,
});

export const endSessions = (context) => (id) => ({
    type: "MEETING.SESSIONS.END",
    payload: { id },
    context,
});

export const startPresentation = (context) => (session, id) => ({
    type: "MEETING.PRESENT",
    payload: { session, id },
    context,
});

export const logger = (message, identity, data) => ({
    type: "MEETING.LOG",
    payload: { message, identity, data },
});

export const goLive = (id, state, reason) => ({
    type: "MEETING.LIVE",
    payload: { id, state, reason },
});

export const createSession = (context) => (parent, list) => ({
    type: "MEETING.SESSION.CREATE",
    payload: {
        parent,
        list: list || `nodes.${parent}.live`,
    },
    context,
});

export const scheduleSession = (context) => (stage, session) => ({
    type: "MEETING.STAGE.SCHEDULE",
    payload: {
        stage,
        session,
    },
    context,
});

export const setSessionFlowSegment = (context) => (session, segment) => ({
    type: "MEETING.SESSION.SET_FLOW_SEGMENT",
    payload: {
        session,
        segment,
    },
    context,
});

export const confirm = () => ({ type: "MEETING.LIVE.CONFIRM" });
export const deny = () => ({ type: "MEETING.LIVE.DENY" });

export const confirmParticipation = (context) => (session) => ({
    type: "MEETING.PARTICIPATION.CONFIRM",
    payload: { session },
    context,
});

export const denyParticipation = (context) => (session) => ({
    type: "MEETING.PARTICIPATION.DENY",
    payload: { session },
    context,
});

export const revokeConfirmation = (context) => (session) => ({
    type: "MEETING.PARTICIPATION.REVOKE",
    payload: { session },
    context,
});
