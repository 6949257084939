import React from 'react';
import {push} from 'connected-react-router'
import {connect} from 'react-redux';
import ServiceList from "components/user-menu/ServiceList";
import {selectLang} from "state/selectors/router";

const mapStateToProps = (store, props) => {
    return {
        lang: selectLang(store)
    }
};

const mapDispatchToProps = {
    push
};

class ServiceListContainer extends React.PureComponent {

    click = (id, type) => {
        const { lang } = this.props;
        const route = type === 'website'
            ? `/${lang}/${id}?resource=cms.pages`
            : `/${lang}/${id}`;

        window.location.href = route;
    };

    render() {
        return (<ServiceList {...this.props} onClick={this.click} />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceListContainer);