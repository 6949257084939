import React from 'react';
// import PropTypes from 'prop-types';
import SessionOverviewHeader from "joynt/components/SessionPage/SessionOverviewHeader";
import SessionInfo from "joynt/components/SessionPage/SessionInfo";
import SessionsSlider from "joynt/components/SessionsSlider";
import SessionPageLayout from "joynt/components/SessionPage/SessionPageLayout";
import Materials from "joynt/components/SessionPage/Materials/Materials.container";
import Discussions from "joynt/components/SessionPage/Discussions/Discussions.container";

export function WebinarSeriesPage(props) {

    const {id} = props;

    return <SessionPageLayout
        id={id}
        header={<SessionOverviewHeader id={id} />}
        content={<>
            <SessionInfo id={id} />
            <SessionsSlider id={id} />
        </>}
    >
        <Materials id={id} showAggregate={true} />
        <Discussions id={id} />
    </SessionPageLayout>;

}

WebinarSeriesPage.propTypes = {};

WebinarSeriesPage.defaultProps = {};

export default WebinarSeriesPage;