import {useCallback} from "react";
import {useApiActions} from "state/hooks";
import {post} from "state/actions/api";

/**
 * Mark notification as seen
 *
 * @returns {function(*): void}
 */
export function useNotification() {
    const {postAction} = useApiActions({postAction: post});
    return useCallback((id) => {
        postAction(`joynt/notifications/${id}`, {}, 'notification-stats')
    }, [postAction]);
}