import { useMemo } from "react";

function isTouchDevice() {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}

const __isTouchDevice__ = isTouchDevice();

export default function useTouchInteraction(mouseEnter, mouseLeave, onClick) {
    const isTouch = __isTouchDevice__;
    return useMemo(
        () => ({
            onClick: isTouch ? mouseEnter : onClick,
            onMouseEnter: !isTouch ? mouseEnter : null,
            onMouseLeave: !isTouch ? mouseLeave : null,
        }),
        [isTouch, mouseEnter, mouseLeave, onClick]
    );
}
