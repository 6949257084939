import React from 'react';
// import PropTypes from 'prop-types';

import cn from "classnames";
import Page from "joynt/views/Page";

export const SessionText = React.forwardRef((props, ref) => {
    const {id, isTruncated, className} = props;
    return <div
        className={cn("session-text", className, {
            "is-truncated": isTruncated
        })}
        ref={ref}
    >
        <Page id={id} />
    </div>
});

SessionText.propTypes = {};

SessionText.defaultProps = {
    isTruncated: false
};

export default SessionText;