import React from 'react';
import cn from "classnames";
import {useImpressionMonitor} from "./hooks/useImpressionMonitor";
//import PropTypes from 'prop-types';

export default function ImpressionMonitor(props) {

    const {id, on, children} = props;

    const {className} = props;
    const {ref, hasNotifications} = useImpressionMonitor(id, on);


    return <div ref={ref} className={cn(className, {
        'is-new': hasNotifications
    })}>
        {children || null}
    </div> ;

}

ImpressionMonitor.propTypes = {};
ImpressionMonitor.defaultProps = {
    on: false
};
