export const welcome = [
    {
        content: 'Welcome to Joynt, the ultimate virtual event, office and personal space app in which you can organise conferences, trainings, store knowledge, socialise, meet and work together with others.It is great for managing organisations, event venues, startup accelerators, conference centres, communities or remote teams in lifelike, realtime spaces.',
        placement: 'center',
        isFixed: true,
        target: '#root'
    },
    {
        target: '.sidebar-tray',
        title: 'Workspaces',
        content: 'The leftmost icons are a collection of all your workspaces . Think of workspaces like organisations, venues or communities that you visit or manage. The icons are shortcuts to move around your world quickly. The uppermost one is your Home workspace, to which all the others are connected to. The name of currently active workspace is displayed in the upper left corner.',
        isFixed: true
    },
    /*{
        target: '.main-stage-channel',
        title: 'Main stage',
    },*/
    {
        target: '.utility-nav-inbox',
        title: 'Inbox',
        content: 'Your messages from the active workspace are visible here. To see messages from all workspaces, select your home workspace first, and then go to your inbox - your home gathers all the messages from every workspace into one list.'
    },
    {
        target: '.utility-nav-people',
        title: 'Participants',
        content: 'These are the people living in a selected workspace. Click on them to start chatting, or hover to see who they are. You can also see where they are in the workspace, by pressing a blue "find" button, as workspaces have many rooms and locations.',
        isFixed: true,
        placement: 'left'
    },
    {
        target: '.sidebar-nav',
        title: 'Sidebar',
        content: 'Sidebar is also your navigation around a workspace. It shows most important elements, such as the event Mainstage (visible during live events), collections, chats, posts with feeds, rooms/offices and so on. You can add new channels to expand your workspace here. Every workspace location or element can be bookmarked in the sidebar for quick access.',
        isFixed: true
    }
];
