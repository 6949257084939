import React from 'react';
import Skeleton from "joynt/components/Skeleton";
//import PropTypes from 'prop-types';

export default function MessageSkeleton(props) {
    return <div className={'message-skeleton'}>
        <Skeleton width={280} height={40}>
            <circle cx="20" cy="20" r="20" />
            <rect x="56" y="0" rx="3" ry="3" width="180" height="10" />
            <rect x="56" y="20" rx="4" ry="4" width="280" height="13" />
        </Skeleton>
    </div>;
}

MessageSkeleton.propTypes = {};
MessageSkeleton.defaultProps = {};
