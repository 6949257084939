import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Badge from "@material-ui/core/Badge";

import Icon from "components/Icon";
import { mapTypeToIcon } from "joynt/components/Inbox";
import { MessageTime } from "joynt/components/messages/index";
import { getMoment } from "joynt/hooks/useMessage";

import "./style.css";

export function InboxThreadCard(props) {
    const {
        source,
        sourceType,
        message,
        authorsIdentityAvatars,
        parentLink,
        date,
        moreContent,
        unread,
        selected,
        onClick,
        className,
    } = props;

    return (
        <article
            onClick={onClick}
            className={cn(
                "inbox-thread-card rows gap-5",
                selected && "inbox-thread-card--selected",
                unread && "inbox-thread-card--unread",
                className
            )}
        >
            <div className={"inbox-thread__header row cols"}>
                <header className={"inbox-thread__source col grow"}>
                    <Icon>{mapTypeToIcon(sourceType)}</Icon>
                    <p className={"inbox-thread__source-name"}>{source}</p>
                </header>
                <Badge
                    className={"inbox-thread__badge col"}
                    invisible={!unread}
                    color={"secondary"}
                    variant={"dot"}
                    overlap={"circular"}
                >
                    <span className={"inbox-thread__date"}>
                        <MessageTime time={getMoment(date)} format={"short"} />
                    </span>
                </Badge>
            </div>
            <section className={"inbox-thread__body row "}>
                <div className={"cols gap-3"}>
                    <section className={"inbox-thread__avatar col"}>
                        {authorsIdentityAvatars}
                    </section>
                    <section className={"inbox-thread__content grow col"}>
                        <div className={"rows gap-5"}>
                            <div className={"inbox-thread__message row"}>
                                {message}
                            </div>
                            {parentLink && <div className={"row inbox-thread__parent"}>
                                {parentLink}
                            </div>}
                        </div>
                    </section>
                </div>
            </section>
            {moreContent && (
                <section className={"inbox-thread__more-content row"}>
                    {moreContent}
                </section>
            )}
        </article>
    );
}

InboxThreadCard.propTypes = {
    source: PropTypes.string,
    sourceType: PropTypes.oneOf(["private", "channels", "comments"]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    authorsIdentityAvatars: PropTypes.node,
    parentLink: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    moreContent: PropTypes.node,
    unread: PropTypes.bool,
    selected: PropTypes.bool,
};

export default InboxThreadCard;
