import React from "react";
import PropTypes from "prop-types";
import Materials from "./Materials";
import { useEdgesData, useNodeData } from "joynt/hooks";
import { usePostCreate } from "joynt/components/Post/PostCreate/hooks";
import { useRouteParam } from "state/hooks";
import { ROUTE_POST } from "joynt/config";
import PostUnder from "joynt/components/Post/PostCreate/PostUnder";

export function MaterialsContainer(props) {
    const { id: parent, showAggregate } = props;
    const { feed: feedId } = useEdgesData(parent);
    const feedNodeId = showAggregate ? parent : feedId || parent;
    const { can_post } = useNodeData(feedNodeId);

    const list = ["db.posts", feedNodeId].join(".");
    const { id } = usePostCreate(feedNodeId, list);
    const [, setPost] = useRouteParam(ROUTE_POST);

    const onCreate = () => setPost(`edit.${id}`);

    if (!feedNodeId) return null;

    return (<>
        {id && can_post && <PostUnder
            id={id}
            parent={feedId}
            show={false}
        />}
        <Materials
            {...props}
            id={feedNodeId}
            onCreate={can_post ? onCreate : null}
        />
    </>);
}

MaterialsContainer.propTypes = {
    id: PropTypes.string,
};
MaterialsContainer.defaultProps = {};

export default MaterialsContainer;
