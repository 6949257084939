import React, { useCallback } from "react";
import PropTypes from "prop-types";

import WitnessSession from "./WitnessSession";
import { useApiActions, usePending } from "state/hooks";
import { startSession } from "./../actions";
import { useNode } from "joynt/hooks";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";

export function WitnessSessionContainer(props) {
    const { id } = props;

    const isDraft = useSelector((s) => selectIsDraft(s, "db.nodes", id));

    const {
        task_witness: isWitness,
        task_witness_session: session,
        task_witness_match,
        event_start,
    } = useNode(id);

    const hasSession = !!session;
    const pending = usePending([id, session]);

    const { onStart } = useApiActions({
        onStart: startSession,
    });

    const start = useCallback(() => {
        onStart(id);
    }, [onStart, id]);

    return (
        <WitnessSession
            {...props}
            isDraft={isDraft}
            isPaired={!!task_witness_match}
            isPending={pending}
            isWitness={isWitness}
            start={event_start}
            session={session}
            onStartSession={start}
            hasSession={hasSession}
        />
    );
}

WitnessSessionContainer.propTypes = {
    id: PropTypes.string,
};
WitnessSessionContainer.defaultProps = {};

export default WitnessSessionContainer;
