export const userJoined = (id, user) => ({
    type: 'JOINT.PRESENCE.USER_JOINED',
    payload: {
        id,
        user
    }
});

export const userLeft = (id, user) => ({
    type: 'JOINT.PRESENCE.USER_LEFT',
    payload: {
        id,
        user
    }
});

export const connectedToChannel = (id, users) => ({
    type: 'JOINT.PRESENCE.CONNECTED',
    payload: {
        id,
        users
    }
});

export const addUser = (id, user) => ({
    type: 'JOINT.PRESENCE.ADD_USER',
    payload: {
        id,
        user
    }
});

export const removeUser = (id, user) => ({
    type: 'JOINT.PRESENCE.REMOVE_USER',
    payload: {
        id,
        user
    }
});

export const sessionPresence = (id, user, meta) => ({
    type: 'JOINT.PRESENCE.SESSION',
    payload: {
        id,
        user,
        meta
    }
});

export const sessionPresenceClear = (id) => ({
    type: 'JOINT.PRESENCE.SESSION.CLEAR',
    payload: {id}
});

export const publishState = context => (data) => ({
    type: 'JOINT.PRESENCE.PUBLISH',
    payload: {data},
    context
});

export const updateState = (data) => ({
    type: 'JOINT.PRESENCE.UPDATE',
    payload: {data}
});