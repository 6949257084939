import React from "react";
import { connect } from "react-redux";
import ComponentTree from "cms/ui/ComponentTree";
import { selectEntity } from "state/selectors/data";
import * as tree from "state/actions/tree";
import { selectTree } from "cms/state/selectors/cms";

const mapStateToProps = (store, props) => {
    if (!props.id) return {};
    const section = selectEntity(store, props.type, props.id);
    const key = props.type === "cms.sections" ? "items" : "components";
    const tree = selectTree(
        store,
        section[key] || [],
        "cms.components",
        props.id,
        props.type
    );
    return {
        tree,
        root: section,
    };
};

const mapDispatchToProps = {
    onExpand: tree.expand,
    onCollapse: tree.collapse,
};

class ComponentTreeContainer extends React.PureComponent {
    handleToggleBranch = ({ node, expanded }) => {
        const { type, onCollapse, onExpand } = this.props;
        const list = this.props.id;
        const { id } = node;
        const fn = expanded ? onExpand : onCollapse;
        fn(type, list, id);
    };

    render() {
        return (
            <ComponentTree
                {...this.props}
                onClick={this.props.onFocus}
                onVisibilityToggle={this.handleToggleBranch}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentTreeContainer);
