import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import Field from "containers/Field";
import { useApiActions, useData } from "state/hooks";
import { apartmentsImport } from "cms/state/actions/apartments";
import Button from "components/Button";
import JsonView from "react-json-view";

export function ImportForm(props) {
    const { id } = props;

    const { onImport } = useApiActions({
        onImport: apartmentsImport,
    });

    const data = useData({ type: "apartments.resource-bindings.data", id });

    return (
        <FormLayout.Fieldset pad>
            <Field
                id={"resource"}
                fieldType={"ref"}
                label={"Resource"}
                source={"schema:data.import-resources"}
            />
            <div className={"cols gap-sm"}>
                <Field
                    fieldType={"select"}
                    id={"remote.resource_type"}
                    label={"Sync type"}
                    source={"schema:data.import-sources"}
                />
                <Field id={"remote.resource_id"} label={"URL"} />
            </div>
            <div>
                <Button onClick={() => onImport(id)}>Preview</Button>
                <Button onClick={() => onImport(id, true)}>Import</Button>
            </div>
            <JsonView src={data} />
        </FormLayout.Fieldset>
    );
}

ImportForm.propTypes = {};

ImportForm.defaultProps = {};

export default ImportForm;
