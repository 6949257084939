import React from "react";
import Field from "containers/Field";

export default function IdentityStep1() {
    return (
        <div className={"rows gap-md"}>
            <div className={"type-heading-sm text-center"}>
                1. Create your profile
            </div>
            <div>
                <Field
                    id={"media"}
                    collectionType={"db.media"}
                    maxItems={1}
                    fieldType={"avatar"}
                />
            </div>
            <div className={"rows gap-sm"}>
                <Field
                    id={"name"}
                    label={"Your name or nickname"}
                    fieldType={"text"}
                    validate={true}
                />
                <Field
                    id={"company_name"}
                    label={"Company"}
                    fieldType={"text"}
                    validate={true}
                    optional
                />
                <Field
                    id={"company_role"}
                    label={"Role"}
                    fieldType={"text"}
                    validate={true}
                    optional
                />
            </div>
        </div>
    );
}
