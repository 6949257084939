import React, { useRef, useState } from "react";
//import PropTypes from 'prop-types';

import Button from "components/Button";
import { ListLayout, ListContainer, ListPagination } from "playground/cms/List";
import PageForm from "./PageForm";
import PagesLayout from "./PagesLayout";
import PageSections, { usePageSections } from "./../Sections/PageSections";
import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";

function PagesList(props) {
    const { type, id, onClick, children, onCreate } = props;

    const dragRef = useRef();

    const onDragOver = (id) => {
        if (dragRef.current) {
            clearTimeout(dragRef.current);
            dragRef.current = null;
        }
        if (id) {
            dragRef.current = setTimeout(() => {
                onClick(id);
            }, 1000);
        }
    };

    return (
        <ListLayout className={"main-list"}>
            {children}
            <ListLayout.Header>
                <ListLayout.Header.Icons>
                    <ListLayout.Header.Reload type={type} />
                </ListLayout.Header.Icons>
            </ListLayout.Header>
            <ListLayout.Content>
                <ListContainer
                    type={type}
                    id={id}
                    onClick={onClick}
                    onDragOver={onDragOver}
                    onDragLeave={onDragOver}
                    sortable={true}
                    className={"list overflow-scroll"}
                />
            </ListLayout.Content>
            <ListLayout.Footer>
                <ListPagination type={type} />
                <ListLayout.Footer.Actions>
                    <Button onClick={onCreate} disabled={!!id}>
                        Create
                    </Button>
                </ListLayout.Footer.Actions>
            </ListLayout.Footer>
        </ListLayout>
    );
}

const withSections = [
    "cms.pages",
    "cms.entries.offers",
    "cms.entries.posts",
    "cms.entries.projects",
    "cms.layouts",
];

export function Pages(props) {
    const { type, id, onClose, onClick, onCreate, children } = props;

    const {
        section,
        onCloseSection,
        onCloseCreateSection,
        onToggleCreateSection,
        createSection,
    } = usePageSections();

    const handleClose = () => {
        onClose();
        onCloseCreateSection();
    };

    const hasSections = withSections.includes(type);
    const createSectionCallback = () => onCloseCreateSection();

    return (
        <PagesLayout isCollapsed={createSection}>
            <PagesList {...props}>
                {ListLayout.Children.findLayout(children)}
            </PagesList>
            <ListLayout.Form open={!!id}>
                {id ? (
                    <PageForm
                        type={type}
                        id={id}
                        onClose={handleClose}
                        createSection={createSection}
                        onCreateSection={onToggleCreateSection}
                        section={section}
                        hasSections={hasSections}
                        callbackFn={createSectionCallback}
                    >
                        {FormLayout.Children.findLayout(children)}
                    </PageForm>
                ) : null}
            </ListLayout.Form>
            {hasSections && (
                <PageSections
                    type={type}
                    section={section}
                    onCloseSection={onCloseSection}
                    createSection={createSection}
                    onClose={onCloseCreateSection}
                />
            )}
        </PagesLayout>
    );
}

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;
