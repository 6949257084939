import React from "react";

//import PropTypes from 'prop-types';

import "./style.css";
import cn from "classnames";
import PostFiles from "joynt/components/Post/PostContent/content-types/Files";

export const Files = React.forwardRef((props, ref) => {
    const { block, contentState, isFocused, onClick } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const data = entity.getData();
    const className = cn("joynt-files", { "is-focused": isFocused });

    return (
        <div
            className={className}
            ref={ref}
            onClick={!isFocused ? onClick : null}
        >
            <PostFiles id={data.post} />
        </div>
    );
});

Files.propTypes = {};
Files.defaultProps = {};

export default Files;
