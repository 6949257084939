import AgoraRTC from "agora-rtc-sdk-ng";

const SCREEN_SHARE_CONFIG = {
    encoderConfig: {
        width: 1920,
        height: 1080
    }
}

export default class ScreenShareClient {

    constructor(config) {
        const {appId, channel, user, access} = config;

        this.appId = appId;
        this.channel = channel;
        this.user = user;
        this.streamId = `screen_${user}`;
        this.access = access;
        this.client = null;
        this.status = false;
    }

    async start() {
        const track = await this.createTrack();

        this.client = AgoraRTC.createClient({
            mode: 'rtc',
            codec: 'vp8'
        });

        track.on('track-ended', async () => {
            await this.stop();
        });

        await this.client.join(
            this.appId,
            this.channel,
            this.access.token,
            this.streamId
        );

        await this.client.publish(track);
        this.status = true;
    }

    async stop() {
        if (!this.client) {
            this.status = false;
            return;
        }
        const track = this.client.localTracks[0];

        if (track) {
            track.stop();
            track.close();
        }

        await this.client.leave();

        this.client = null;
        this.status = false;
    }

    async createTrack() {
        return await AgoraRTC.createScreenVideoTrack(SCREEN_SHARE_CONFIG);
    }

}