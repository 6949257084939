import React from 'react';
//import PropTypes from 'prop-types';

export default function Heading(props) {

    const {text} = props;

    return (<div className={'type-heading'}>
        {text}
    </div>);

}

Heading.propTypes = {};
Heading.defaultProps = {};
