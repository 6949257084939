import useFetch from "state/hooks/useFetch";
import { useEdges } from "joynt/hooks";

export function useFetchNodeRoles(id) {
    const { workspace } = useEdges(id);

    const url = `joynt/nodes/${workspace}/node-roles`;
    const type = "db.node-roles";
    const list = `node.${workspace}.node-roles`;

    return useFetch({
        type,
        url,
        list,
    });
}
