import { setIn } from 'lodash-redux-immutability';
import { createReducer } from "../helpers/create-reducer";

const actions = {
    'FILES.UPLOAD.PROGRESS': (state, {payload}) => {
        const { id, progress } = payload;
        return setIn(state, [id], progress);
    }
};

export default createReducer({}, actions);