import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Skeleton from "joynt/components/Skeleton";
import { useEdgesData, useNode } from "joynt/hooks";
import { Tooltip } from "@material-ui/core";
import Icon from "components/Icon";
import { useNotificationCount } from "joynt/hooks/notifications";
import { useClick } from "state/hooks";
import { abbreviation } from "joynt/util";
import {
    DEFAULT_CARD_COLOR,
    NODE_TYPE_PROJECT,
    NODE_TYPE_EVENT,
} from "joynt/config";

import "./style.css";

export function SpaceAvatarSkeleton() {
    return (
        <>
            <Skeleton
                className={"space-avatar-skeleton"}
                width={50}
                height={50}
            >
                <rect width={50} height={50} />
            </Skeleton>
        </>
    );
}

function defaultAbbr(abbr, name) {
    if (abbr) return abbr;
    return abbreviation(name, 3);
}

export function SpaceAvatar(props) {
    const {
        abbr,
        active,
        className,
        color,
        icon,
        isGeneric,
        name,
        noTooltip,
        notifications,
        onClick,
        size,
    } = props;

    const defaultText = !icon ? defaultAbbr(abbr, name) : null;

    return (
        <Tooltip
            title={noTooltip || !name ? "" : name}
            placement={"right"}
            arrow
        >
            <div
                onClick={onClick}
                className={cn(
                    `space-avatar space-avatar--${size}`,
                    !isGeneric && `bg-${color}`,
                    isGeneric && "space-avatar--generic",
                    className,
                    {
                        active,
                    }
                )}
            >
                {icon && <Icon>{icon}</Icon>}
                {!icon && <div className={"abbr"}>{defaultText}</div>}
                {notifications ? (
                    <div className={"badge space-avatar__badge success"}>
                        {notifications}
                    </div>
                ) : null}
            </div>
        </Tooltip>
    );
}

SpaceAvatar.defaultProps = {
    active: false,
    notifications: 0,
    name: "",
    size: "md",
};

SpaceAvatar.propTypes = {
    onClick: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    active: PropTypes.bool,
    abbr: PropTypes.string,
    notifications: PropTypes.number,
    noTooltip: PropTypes.bool,
};

SpaceAvatar.defaultProps = {
    color: DEFAULT_CARD_COLOR,
};

export default function SpaceAvatarContainer(props) {
    const {
        id,
        selected,
        parentId,
        name: parentName,
        elementId,
        ...other
    } = props;
    const { name, icon, color, workspace_abbr } = useNode(id);

    const edgesData = useEdgesData(elementId);
    const parentType = edgesData?.parent_node?.subtype;

    const genericIcon = {
        [NODE_TYPE_EVENT]: "mui-videocam",
        [NODE_TYPE_PROJECT]: "mui-work",
    };

    const [notifications] = useNotificationCount(id);
    const click = useClick(props);
    const isSelected = selected === id;

    return (
        <SpaceAvatar
            active={isSelected}
            name={name}
            abbr={workspace_abbr}
            isGeneric={!!genericIcon[parentType]}
            icon={genericIcon[parentType] || icon}
            color={color}
            onClick={click}
            notifications={!isSelected && notifications}
            {...other}
        />
    );
}
