import React, { useCallback } from "react";
//import PropTypes from 'prop-types';

import Icon from "components/Icon";
import Popover from "components/Popover";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import { OverlayForm, useFormContext } from "playground/cms/Form";
import PageTitleSlug from "./../PageTitleSlug";
import Button from "components/Button";

import "./style.css";
import { useActions } from "state/hooks";
import { pathUnset } from "state/actions/data";

function EditPageUrl(props) {
    const { type, id, onClose } = props;
    const { onCancel, isValid, isPending, onSave } = useFormContext();
    const { onUnsetPath } = useActions({ onUnsetPath: pathUnset });
    const reject = () => {
        onUnsetPath([type, id, "slug"]);
        onClose();
    };
    const submit = () => {
        onSave();
        onClose();
    };
    return (
        <div className={"pad-md rows gap-md"}>
            <PageTitleSlug type={type} id={id} />
            <div className={"cols gap-xs"}>
                <Button onClick={reject} color={"default"} fullWidth>
                    Anuluj
                </Button>
                <Button
                    onClick={submit}
                    disabled={!isValid || isPending}
                    fullWidth
                >
                    Zapisz
                </Button>
            </div>
        </div>
    );
}

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

export function PageUrl(props) {
    const { type, id, host, slug, edit, onEdit, onOpen } = props;

    const handleClose = useCallback(() => onEdit(false), [onEdit]);

    return (
        <>
            <div className={"slug-field cols"}>
                <Popover
                    anchorOrigin={anchorOrigin}
                    trigger={({ onClick }) => (
                        <span className={"slug-domain"} onClick={onClick}>
                            <div className={"slug-url"}>
                                <span className={"slug-host"}>/</span>
                                <span className={"slug-value"}>{slug}</span>
                            </div>
                        </span>
                    )}
                    content={({ onClose }) => (
                        <SelectList onClick={onClose}>
                            <SelectListItem
                                onClick={onOpen}
                                icon={<Icon>mui-open_in_new</Icon>}
                            >
                                Otwórz w nowej karcie
                            </SelectListItem>
                            <SelectListItem
                                onClick={() => onEdit(true)}
                                icon={<Icon>mui-edit</Icon>}
                            >
                                Zmień adres podstrony
                            </SelectListItem>
                        </SelectList>
                    )}
                />
            </div>
            <OverlayForm
                open={edit}
                onClose={null}
                position={"center"}
                width={"md"}
                fullHeight={false}
            >
                {edit && (
                    <EditPageUrl type={type} id={id} onClose={handleClose} />
                )}
            </OverlayForm>
        </>
    );
}

PageUrl.propTypes = {};

PageUrl.defaultProps = {};

export default PageUrl;
