import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { TopicNav, TopicNavItem } from "./TopicNav";
import { useWindowSize } from "util/hooks/useWindowSize";

export function Topic(props) {
    const { className, children } = props;

    return (
        <div className={cn("tutorial-section__topic", className)}>
            {children}
        </div>
    );
}

export function TutorialSection(props) {
    const { variant, title, children, reverse, sideContent } = props;

    const [current, setCurrent] = useState(0);
    const [highestCardHeight, setHighestCardHeight] = useState(180);
    const contentRef = useRef();
    const { width } = useWindowSize();

    useEffect(() => {
        const cards = Array.from(contentRef?.current?.children);

        if (cards) {
            const heights = cards.map((card) => card.clientHeight);
            const highestCard = Math.max.apply(Math, heights);
            setHighestCardHeight(highestCard);
        }
    }, [contentRef, width]);

    const SideContent = () => {
        return <div className={"tutorial-section__side"}>{sideContent}</div>;
    };

    return (
        <div
            className={cn(
                "tutorial-section",
                `variant-${variant}`,
                sideContent && "tutorial-section--with-side"
            )}
        >
            {sideContent && reverse && <SideContent />}
            <div className={"tutorial-section__main"}>
                <div className={"tutorial-section__info"}>
                    <h3 className={"tutorial-section__title"}>{title}</h3>
                </div>
                <div className={"tutorial-section__tutorial"}>
                    <TopicNav className={"tutorial-section__topics"}>
                        {React.Children.map(children, (child, index) => (
                            <TopicNavItem
                                key={index}
                                onClick={() => setCurrent(index)}
                                active={index === current}
                            >
                                {child.props.title}
                            </TopicNavItem>
                        ))}
                    </TopicNav>
                    <div
                        className={"tutorial-section__content"}
                        style={{ height: highestCardHeight }}
                        ref={contentRef}
                    >
                        {React.Children.map(children, (child, index) => {
                            const className = cn(child.props.className, {
                                "is-active": index === current,
                            });
                            return React.cloneElement(child, { className });
                        })}
                    </div>
                </div>
            </div>
            {sideContent && !reverse && <SideContent />}
        </div>
    );
}

TutorialSection.defaultProps = {
    variant: "primary",
};

TutorialSection.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["primary", "secondary"]),
};
