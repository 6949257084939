import React from "react";
import TextField from "components/fields/text/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/Button";
import Preloader from "components/Preloader";

//import PropTypes from 'prop-types';

export function FontBrowser(props) {
    const {
        children,
        sources,
        onChangeSource,
        search,
        onChangeSearch,
        source,
        isPending,
    } = props;
    return (
        <>
            <div className={"theme-light rows grow"}>
                <div className={"cols pad-sm gap-md"}>
                    <ButtonGroup>
                        {sources.map((s) => (
                            <Button
                                key={s}
                                onClick={() => onChangeSource(s)}
                                color={s === source ? "primary" : "default"}
                                variant={
                                    s === source ? "contained" : "outlined"
                                }
                            >
                                {s}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <TextField
                        variant={"search"}
                        value={search}
                        onChange={onChangeSearch}
                        label={"Search"}
                    />
                </div>
                <div className={"rows grow"}>{children}</div>
            </div>
            <Preloader visible={isPending} />
        </>
    );
}

FontBrowser.propTypes = {};

FontBrowser.defaultProps = {};

export default FontBrowser;
