import React from 'react';
//import PropTypes from 'prop-types';

import "./style.css";

export function DrawerNav(props) {

    const {children} = props;

    return (<div className={"drawer-nav"}>
        {children}
    </div>);

}

DrawerNav.propTypes = {};
DrawerNav.defaultProps = {};

export default DrawerNav;
