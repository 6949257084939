import React, {useCallback} from 'react';
import {connect} from "react-redux";
//import PropTypes from 'prop-types';
import cn from "classnames";
import List from "containers/ContextMenuList";
import {SortableIterator} from "components/dnd";
import {withData} from "containers/Entity";
import {ListItem} from "components/list/items/ListItem";
import {ArrayIterator} from "components/list/iterator";
import Task from "components/list/items/Task";
import {selectFieldValue} from "state/selectors/data";
import {pathChange} from "state/actions/data";
import MenuItem from "@material-ui/core/MenuItem";
import MuiList from "@material-ui/core/List";

import 'css/fields/collection.css';
import 'css/list/tasks.css';

const ItemIterator = SortableIterator(withData(ListItem));
const TaskIterator = ArrayIterator(withData(Task));

const items = {
    'tasks': TaskIterator,
    'default': ItemIterator
};


const mapState = (store, {type, id, field}) => {
    return {
        value: selectFieldValue(store, type, id, field)
    };
};

const mapDispatch = {
    onChange: pathChange
};

function ToggleMenuItemComponent({type, id, field, value, onChange}) {
    const change = useCallback(() => {
        onChange([type, id, field], !value);
    }, [onChange, type, id, field, value]);
    return <MenuItem onClick={change}>{field}: {value ? 'yes' : 'no'}</MenuItem>
}

const ToggleMenuItem = connect(mapState, mapDispatch)(ToggleMenuItemComponent);

function MenuItems({type, id, onDelete}) {
    return <>
        <MenuItem onClick={()=>{
            onDelete(id);
        }}>delete</MenuItem>
        <ToggleMenuItem type={type} id={id} field={'active'} />
    </>;
}

function typeName(type) {
    let parts = type.split('.');
    return parts[1];
}

export function DefaultCollection(props) {
    const {
        id,
        type,
        value,
        onEdit,
        onCreate,
        onDelete,
        onSortEnd,
        readOnly,
        className,
        RenderCreateButton,
        ...other
    } = props;

    return (<MuiList className={cn('collection-list', className)}>
        <List
            {...other}
            type={type}
            items={value}
            onCreate={onCreate}
            onClick={onEdit}
            onDelete={onDelete}
            onSortEnd={onSortEnd}
            readOnly={readOnly}
            Iterator={props.Iterator || items[id] || items['default']}
            RenderMenuItems={MenuItems}
        />
        { !readOnly && RenderCreateButton
            ? <RenderCreateButton onClick={onCreate} />
            : null }
        { !readOnly && !RenderCreateButton && RenderCreateButton !== false ? <div className={'cols cols-center'}>
            <div
                className={'collection-add default-c600'}
                onClick={onCreate}>add {typeName(type)}</div>
        </div> : null }
    </MuiList>);
}