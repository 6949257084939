import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import MaterialModal from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import useScreenSize from "util/hooks/useScreenSize";

export function ResponsiveModal(props) {
    const { isMobile } = useScreenSize();

    const { open, onClose, Component, ...other } = props;

    const [visible, setVisible] = useState(open);
    const hide = useCallback(() => setVisible(false), [setVisible]);
    useEffect(() => setVisible(open), [open]);

    const transitionProps = useMemo(
        () => ({
            onExited: onClose,
        }),
        [onClose]
    );

    return isMobile ? (
        <Drawer anchor={"bottom"} open={open} onClose={onClose}>
            {props.children}
        </Drawer>
    ) : (
        <Component
            onClose={onClose ? hide : null}
            TransitionProps={transitionProps}
            open={visible}
            {...other}
        >
            {props.children}
        </Component>
    );
}

ResponsiveModal.defaultProps = {
    Component: MaterialModal,
};

/**
 * Modal that switches to Drawer on mobile
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Modal(props) {
    const { trigger, content, onClose, ...other } = props;
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => {
        setOpen(false);
        if (onClose) onClose();
    }, [setOpen, onClose]);
    const handleOpen = useCallback(
        (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            setOpen(true);
        },
        [setOpen]
    );

    return (
        <React.Fragment>
            {trigger({ onClick: handleOpen })}
            <ResponsiveModal open={open} onClose={handleClose} {...other}>
                {content ? content({ onClose: handleClose }) : props.children}
            </ResponsiveModal>
        </React.Fragment>
    );
}

Modal.propTypes = {
    trigger: PropTypes.func.isRequired,
    content: PropTypes.func,
    onClose: PropTypes.func,
};
