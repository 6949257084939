import React from 'react';
import {IdentityAvatarContainer as Avatar} from "joynt/components/Avatar";
import {useUserSettings} from "joynt/components/UserSettings";

//import PropTypes from 'prop-types';

export function ProfileButton(props) {

    const {id, onClick} = props;
    const {identity} = useUserSettings(id);

    return (<button onClick={onClick}>
        <Avatar id={identity} />
    </button>);

}

ProfileButton.propTypes = {};
ProfileButton.defaultProps = {};

export default ProfileButton;
