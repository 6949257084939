import React, { useCallback } from "react";
import Button from "components/Button";
import LinkCopyToClipboard from "joynt/components/LinkCopyToClipboard";
import {useNodeData, usePublicView} from "joynt/hooks";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import {useActions} from "state/hooks";
import {pushRoute} from "state/actions/router";
import Checkbox from "components/fields/checkbox/Checkbox";

//import PropTypes from 'prop-types';

export function Share(props) {
    const { id, isPage } = props; //workspace

    const [, setView] = useUtilityNav();

    const { published, access_type, can_edit } = useNodeData(id);

    const canInvitePeople = !!can_edit && !isPage;
    const canShareLink = access_type !== "invite" && published;

    const {
        hasPublicView,
        onTogglePublicView,
        workspaceUrl,
        publicUrl
    } = usePublicView(id);

    const share = useCallback(() => {
        setView("invite");
    }, [setView]);

    return (
        <div className={"rows gap-lg pad-md"}>
            {hasPublicView && <div>
                <LinkCopyToClipboard
                    value={publicUrl}
                    label={"Public session link"}
                />
                {can_edit && <Checkbox
                    onChange={onTogglePublicView}
                    value={isPage}
                    label={"Show in public link mode"}
                />}
            </div>}
            {canInvitePeople && (
                <div className={"rows rows-center gap-sm"}>
                    <div>Invite people and assign roles here</div>
                    <div>
                        <Button onClick={share}>Invite people to workspace</Button>
                    </div>
                </div>
            )}
            {canShareLink && !isPage && (
                <div className={"rows gap-sm"}>
                    <div className={"text-center"}>
                        Copy link to share this workspace
                    </div>
                    <LinkCopyToClipboard value={workspaceUrl} />
                </div>
            )}
            {!canInvitePeople && !canShareLink && (
                <div className={"text-center"}>
                    This workspace is private, only the admins can invite new
                    people.
                </div>
            )}
        </div>
    );
}

Share.propTypes = {};
Share.defaultProps = {};

export default Share;
