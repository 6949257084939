import React, {useEffect, useMemo, useState} from 'react';
import {authHeaders} from "util/api";
import Echo from "laravel-echo";
import env from "util/env";
//import PropTypes from 'prop-types';

export const EchoContext = React.createContext(null);

function useWebsocketAuthHeaders() {
    const [auth, setAuth] = useState(authHeaders({}));

    useEffect(() => {
        const onAccessTokenChanged = () => {
            setAuth(authHeaders({}));
        };
        document.addEventListener('access-token-changed', onAccessTokenChanged);
        return () => {
            document.removeEventListener('access-token-changed', onAccessTokenChanged);
        }
    }, [setAuth]);

    const {Authorization, 'X-zuu-api-key': apiKey} = auth;

    return useMemo(() => ({
        Authorization: Authorization,
        'X-zuu-api-key': apiKey
    }), [Authorization, apiKey]);
}

export default function EchoProvider(props) {

    const {children} = props;
    const [echo, setEcho] = useState(null);

    const auth = useWebsocketAuthHeaders();

    useEffect(() => {
        console.log(`Create Echo instance with updated auth headers`);
        let instance = new Echo({
            broadcaster: 'socket.io',
            host: env('ECHO_SERVER'),
            auth: {
                headers: auth
            },
            disableStats: true,
            enabledTransports: ['ws', 'wss', 'websocket']
        });
        setEcho(instance);
        return () => {
            if (instance) {
                setEcho(null);
            }
        }
    }, [auth]);

    return (<EchoContext.Provider value={echo}>
        {children || null}
    </EchoContext.Provider>);

}

EchoProvider.propTypes = {};
EchoProvider.defaultProps = {};
