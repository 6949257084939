import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import { useData } from "state/hooks";

export default function IdentityAvatar(props) {
    const { id, size } = props;

    const { name } = useData({ type: "db.identities", id });

    return (
        <>
            <Tooltip title={name || ""}>
                <div>
                    <Avatar id={id} size={size} />
                </div>
            </Tooltip>
        </>
    );
}
