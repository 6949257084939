import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { usePending, useUiFlag } from "state/hooks";
import { usePostEdit } from "./../hooks";

import PostView from "./PostView";
import PostContent from "./../PostContent";
import PostHeader from "./../PostHeader";
import PostComments from "./../PostComments";
import PostTags from "./../PostTags";
import CreateAttachment from "joynt/components/Post/CreateAttachment";

import FormActions from "joynt/components/Form/FormActions";
import Upload from "components/upload/Upload";
import {
    useEdgesData,
    useFeatureEnabled,
    useForm,
    useNode,
    useNodeExists,
} from "joynt/hooks";
import { FLAG_NEW_POST_ID } from "joynt/config";
import useFetch from "state/hooks/useFetch";
import ToggleTask from "joynt/components/Tasks/ToggleTask";
import { FEATURE_TASKS } from "joynt/config/features";
import WitnessSession from "joynt/components/Witness/WitnessSession";
import TaskProperties from "joynt/components/Tasks/TaskProperties";
import { useImpressionMonitor } from "joynt/components/ImpressionMonitor";

function Uploadable({ onUpload, enable, children }) {
    if (!enable) return children;
    return <Upload onFileUpload={onUpload}>{children}</Upload>;
}

export function PostViewContainer(props) {
    const { id, onClose, editable } = props;
    const isPending = usePending(id);
    const [newPostId] = useUiFlag(FLAG_NEW_POST_ID);
    const isNewPost = newPostId === id;
    const tasksEnabled = useFeatureEnabled(FEATURE_TASKS);

    const {
        parent: postParent,
        node_role: nodeRole,
        public: accessPublic,
        published: accessPublished,
        content,
    } = useNode(id);

    const isTask = nodeRole === "task";
    const isPrivateTask = isTask && !accessPublic && !accessPublished;
    const isDraftEditor = content?.version === "draft-js.raw";

    const { parent: edgeParent } = useEdgesData(id);
    const parent = edgeParent || postParent;
    const list = ["db.posts", parent].join(".");
    const exists = useNodeExists(id);

    useFetch({
        enable: exists && !isNewPost,
        type: "db.nodes",
        id,
    });

    const { isDraft, onSubmit, onReject, onCreateContent, onUpload, onChange } =
        usePostEdit(id, list);

    const close = useCallback(() => {
        if (!onClose) {
            return;
        }

        if (isNewPost) {
            onClose();
            return;
        }
        onReject();
        onClose();
    }, [isNewPost, onReject, onClose]);

    const submit = useCallback(() => {
        onSubmit();
        onClose();
    }, [onSubmit, onClose]);

    const attach = useCallback(
        (files) => {
            onUpload(files);
        },
        [onUpload]
    );

    const { isValid } = useForm("db.nodes", id);

    const { ref } = useImpressionMonitor(id, true);

    return (
        <Uploadable onUpload={onUpload} enable={editable && !isDraftEditor}>
            <PostView {...props} isPending={isPending} ref={ref}>
                <PostHeader id={id} editable={editable} onClose={close} />
                {isTask && <TaskProperties id={id} editable={editable} />}
                {!isNewPost && isPrivateTask && <WitnessSession id={id} />}
                <PostTags id={id} editable={editable} />
                <PostContent id={id} editable={editable} />
                {!editable && <PostComments id={id} />}
                {editable && (
                    <div className={"cols cols-middle gap-sm"}>
                        <CreateAttachment
                            onCreate={onCreateContent}
                            onUpload={attach}
                        />
                        {tasksEnabled && (
                            <ToggleTask
                                onChange={onChange}
                                nodeRole={nodeRole}
                            />
                        )}
                    </div>
                )}
                {editable && (
                    <FormActions
                        onSubmit={submit}
                        onReject={close}
                        disableSubmit={!isValid}
                        submitLabel={isDraft ? "Create" : "Save"}
                    />
                )}
            </PostView>
        </Uploadable>
    );
}

PostViewContainer.propTypes = {
    id: PropTypes.string,
    editable: PropTypes.bool,
};
PostViewContainer.defaultProps = {
    editable: false,
};

export default PostViewContainer;
