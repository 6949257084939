import React from 'react';
import cn from "classnames";
// import PropTypes from 'prop-types';
import {useNodeData} from "joynt/hooks";
import Icon from "components/Icon";

export function BreadcrumbsLink(props) {

    const {id, onClick, isActive} = props;
    const {name} = useNodeData(id);
    const click = !isActive ? ()=>onClick(id) : null;

    return (<div onClick={click} className={cn("breadcrumbs-link", {
        "is-active": isActive
    })}>
        <div className={"breadcrumbs-link__label"}>
            {name}
        </div>
        {!isActive && <div className={"breadcrumbs-link__separator"}>
            <Icon>mui-keyboard_arrow_right</Icon>
        </div>}
    </div>);

}

BreadcrumbsLink.propTypes = {};

BreadcrumbsLink.defaultProps = {};

export default BreadcrumbsLink;