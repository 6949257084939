import React from "react";
import cn from "classnames";

import "./style.css";

export const SideHelperList = ({
  variant,
  imgAlt,
  header,
  noImage,
  children,
  className,
}) => {
  return (
    <aside className={cn("side-helper-list", className)}>
      <header className={"side-helper-list__header"}>
        <h3>{header}</h3>
        {!noImage && (
          <img
            className={"side-helper-list__image"}
            src={`https://joynt.works/thumb?file=joynt-website/user/app/workspace-create-${
              variant || 1
            }.svg`}
            alt={imgAlt}
          />
        )}
      </header>
      <div className={"side-helper-list__content"}>
        {React.Children?.map(children, (element, index) => {
          return element;
        })}
      </div>
    </aside>
  );
};

export default SideHelperList;
