import React, { useCallback } from "react";
import { useMeetingSession } from "joynt/meeting/MeetingContainer";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import Button from "components/Button";
import Icon from "components/Icon";
import { useNodeData } from "joynt/hooks";
//import PropTypes from 'prop-types';

export default function EventFlow(props) {
    const { id } = props;

    const { event_session_id } = useNodeData(id);
    const [, session] = event_session_id ? event_session_id.split(":") : [];

    const { onEndSession, onStartNextSession } = useMeeting();

    const { isEventSession } = useMeetingSession();

    const end = useCallback(() => onEndSession(session), [
        session,
        onEndSession,
    ]);
    const next = useCallback(() => onStartNextSession(session), [
        session,
        onStartNextSession,
    ]);

    if (!session || !isEventSession) return null;

    return (
        <div className={"rows gap-px"}>
            <Button
                buttonClassName={"event-flow__control-button"}
                color={"default"}
                onClick={next}
                startIcon={<Icon>mui-skip_next</Icon>}
            >
                Start next session
            </Button>
            <Button
                buttonClassName={"event-flow__control-button"}
                color={"default"}
                onClick={end}
                startIcon={<Icon>mui-stop</Icon>}
            >
                End current session
            </Button>
        </div>
    );
}

EventFlow.propTypes = {};
EventFlow.defaultProps = {};
