import React from 'react';
import PropTypes from 'prop-types';

import SessionItem from "./SessionItem.container";
import SessionTrack from "joynt/components/SessionTrack";
import TrackSessionItem from "joynt/components/SessionTrack/SessionItem";

export function StagesList(props) {

    const {items} = props;
    const stageIds = Object.keys(items);

    return <div className={"rows gap-sm"}>
        <div className={"grid grid-3 gap-md"}>
            {stageIds.map(stage => {
                return <SessionTrack key={stage} id={stage}>
                    {items[stage].map(session => {
                        return <SessionItem
                            key={session}
                            id={session}
                            Component={TrackSessionItem}
                        />;
                    })}
                </SessionTrack>
            })}
        </div>
    </div>;

}

StagesList.propTypes = {
    items: PropTypes.object
};
StagesList.defaultProps = {
    items: {}
};

export default StagesList;
