import React from "react";

import { useEdgesData, useThread } from "joynt/hooks";
import { useClick, useRouteParam } from "state/hooks";
import { useNotificationCount } from "joynt/hooks/notifications";
import { useRouter } from "joynt/hooks/channels";

import { IdentityAvatarContainer } from "joynt/components/Avatar";
import { MessageSummary } from "joynt/components/Inbox";
import MoreContentButton from "joynt/components/MoreContentButton";
import { NODE_TYPE_CHAT } from "joynt/config";
import SpaceAvatar from "joynt/components/SpaceAvatar";

import InboxThreadCard from "./InboxThreadCard";
import Icon from "components/Icon";
import useChat from "joynt/participants/hooks/useChat";

const nodeTypeNameDictionary = {
    "private-chat": "private",
    [NODE_TYPE_CHAT]: "channels",
    comment: "comments",
};

export function InboxThreadCardContainer(props) {
    const { id, selected, showParentLink } = props;

    const threadProps = useThread(id);
    const onClick = useClick(props);

    const {
        identity,
        messageIdentity,
        subtype,
        genericName,
        lastMessage,
        parent_node,
        timestamp,
        threadType,
        participants,
    } = threadProps;

    const isSelected = selected === id;

    const isPrivateChat = threadType === "private-chat";
    const recipient = participants.find(
        (participant) => participant !== identity
    );

    const isComment = threadType === "comments";
    const [notifications] = useNotificationCount(id);
    const { workspace } = useEdgesData(id);
    const { onShow: onOpenChat } = useChat(workspace, recipient);
    const [, setOpen] = useRouteParam("side");

    const ThreadAvatar = () => {
        if (!isPrivateChat) {
            return <IdentityAvatarContainer id={identity} />;
        }

        let avatarIds = [];

        if (identity === messageIdentity) {
            avatarIds.push(recipient, identity);
        } else {
            avatarIds.push(identity, messageIdentity);
        }

        return (
            <div className={"inbox-thread__double-avatar"}>
                <IdentityAvatarContainer id={avatarIds[0]} />
                <IdentityAvatarContainer id={avatarIds[1]} />
            </div>
        );
    };

    const ParentComponent = ({showParentLink}) => {
        const { onLink } = useRouter();

        if (!parent_node?.id) {
            return null;
        }

        return (
            <div className={"inbox-thread-card__parent"}>
                <SpaceAvatar
                    elementId={id}
                    {...parent_node}
                    parentId={workspace}
                    noTooltip
                    notifications={null}
                    size={"xs"}
                    // icon={}
                />
                <span className={"inbox-thread-card__parent-name"}>
                    {parent_node?.name}
                </span>
                {showParentLink && <div
                    className={"inbox-thread-card__link"}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (isPrivateChat) {
                            setOpen(null);
                            onOpenChat();
                        } else {
                            onLink(id);
                        }
                    }}
                >
                    Go to <Icon>mui-arrow_forward</Icon>
                </div>}
            </div>
        );
    };

    const CardSourceType = () => {
        if (isPrivateChat) {
            return "private-chat";
        }

        if (isComment) {
            return "comment";
        }

        return subtype;
    };

    return (
        <InboxThreadCard
            {...props}
            {...threadProps}
            parentLink={<ParentComponent showParentLink={showParentLink}/>}
            date={timestamp}
            selected={isSelected}
            sourceType={nodeTypeNameDictionary[CardSourceType()]}
            unread={!!notifications}
            className={"row"}
            source={genericName}
            message={<MessageSummary id={lastMessage} />}
            authorsIdentityAvatars={<ThreadAvatar />}
            onClick={onClick}
            moreContent={
                !notifications || notifications <= 1 ? null : (
                    <MoreContentButton
                        count={notifications - 1}
                        label={
                            nodeTypeNameDictionary[subtype] === "channels" ||
                            nodeTypeNameDictionary[subtype] === "private"
                                ? "more messages"
                                : "more comments"
                        }
                    />
                )
            }
        />
    );
}

InboxThreadCardContainer.defaultProps = {
    showParentLink: true
}

export default InboxThreadCardContainer;
