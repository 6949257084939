//import React from 'react';
//import PropTypes from 'prop-types';

export function Link(props) {

    const {children} = props;
    return children;

}

Link.propTypes = {};
Link.defaultProps = {};

export default Link;
