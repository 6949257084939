import React from "react";

import ApiContext from "containers/context/ApiContext";
import Theme from "joynt/Theme";
import Snackbar from "components/Snackbar";
import domainRouter from "util/domainRouter";

const config = domainRouter();
const apiContext = config.apiContext || {};

function EntrypointNoAuth(props) {
    return (
        <ApiContext.Provider value={apiContext}>
            <Theme>
                {props.children}
                <Snackbar />
            </Theme>
        </ApiContext.Provider>
    );
}

export default EntrypointNoAuth;
