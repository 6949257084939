import React from "react";
//import PropTypes from 'prop-types';

import { Helmet } from "react-helmet";
import Projects from "./Projects";

export function Home(props) {
    const {} = props;
    return (
        <>
            <Helmet defer={false}>
                <title>zuu.cms v4</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat&amp;display=swap"
                    rel="stylesheet"
                />
                <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc&amp;libraries=places"></script>
                <body className={"theme-light default cms-theme"} />
            </Helmet>
            <Projects key={"projects"} />
        </>
    );
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
