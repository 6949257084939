import React from "react";
import cn from "classnames";
import Icon from "components/Icon";

//import PropTypes from 'prop-types';

export function Role(props) {
    const { label, onClick } = props;
    return (
        <div
            className={cn("role-select", {
                "is-active": !!onClick,
            })}
            onClick={onClick}
        >
            {onClick && <Icon>mui-edit</Icon>}
            <div className={"role-select__label"}>{label}</div>
        </div>
    );
}

Role.propTypes = {};
Role.defaultProps = {};

export default Role;
