export default {
  'style.text--left': 'format_align_left',
  'style.text--right': 'format_align_right',
  'style.text--center': 'format_align_center',
  'style.text--centered': 'subject',
  'style.text--auto': 'swap_horiz',

  'style.l-rows': 'view_stream',
  'style.l-cols': 'view_column',
  'style.l-grid': 'view_quilt',
  'style.l-layers': 'layers',
  'style.l-cols-text': 'dashboard'
};