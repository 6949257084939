import React from "react";
import PropTypes from "prop-types";

import TaskCheck from "joynt/components/Tasks/TaskCheck";
import TaskDue from "joynt/components/Tasks/TaskDue";
import TaskAssignees from "joynt/components/Tasks/TaskAssignees";
import PostContextMenu from "joynt/components/Post/PostContextMenu";
import NodeLink from "joynt/components/Post/NodeLink";
import PostProperties from "joynt/components/Post/PostProperties";

import "./style.css";

export function TaskListItem(props) {
    const { id, parent, name, properties, onClick } = props;

    return (
        <div className={"task-list-item"}>
            <div className={"task-list-item__check"}>
                <TaskCheck id={id} />
            </div>
            <div className={"task-list-item__body"} onClick={onClick}>
                <div className={"task-list-item__name"}>{name}</div>
                <NodeLink id={parent} />
                {properties && (
                    <PostProperties
                        properties={properties}
                        layout={"horizontal"}
                    />
                )}
            </div>
            <div className={"task-list-item__details"}>
                <div className={"cols cols-middle gap-sm"}>
                    <TaskDue id={id} />
                    <TaskAssignees id={id} />
                    <PostContextMenu id={id} />
                </div>
            </div>
        </div>
    );
}

TaskListItem.propTypes = {
    name: PropTypes.string,
    isCompleted: PropTypes.bool,
    onClick: PropTypes.func,
    onToggleCompleted: PropTypes.func,
};

TaskListItem.defaultProps = {
    isCompleted: false,
};

export default TaskListItem;
