import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';

import SerpPreview from 'react-serp-preview';
import Card from '@material-ui/core/Card';
import {selectPageUrl, selectSeo} from "cms/state/selectors/cms";

const mapState = (store, props) => {
    return {
        ...selectSeo(store, props),
        url: selectPageUrl(store, props.entityType, props.entityId)
    };
};

const mapDispatch = {};

class Seo extends React.PureComponent {

    render() {
        const { title, description, url } = this.props;
        return (<div className={'rows gap-sm'}>
            <Card className={'pad-sm bg-white'}>
                <SerpPreview
                    title={title || ''}
                    metaDescription={description || ''}
                    url={url}
                />
            </Card>
            {this.props.children}
        </div>);
    }

}

Seo.propTypes = {};

export default connect(mapState, mapDispatch)(Seo);