export const pushActivityEvent = context => (event) => {
    return {
        type: 'ACTIVITY.PUSH',
        payload: {
            event
        },
        context
    }
};

export const pushNotification = event => ({
    type: 'JOINT.NOTIFICATION',
    payload: {event}
});