import React from 'react';
//import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Popover from "@material-ui/core/Popover";
import {selectEntity} from "state/selectors/data";
import 'css/fields/type-styles.css';
import TypographyStyle from "components/fields/typography-styles/TypographyStyle";
import TypographyStyleSettings from "components/fields/typography-styles/TypographyStyleSettings";
import TextField from "components/fields/text/TextField";
import Button from "components/Button";

const mapState = (store, props) => {
    return {
        preset: selectEntity(store, props.entityType, props.entityId)
    };
}

const mapDispatch = {};

const quickSearch = [
    'heading',
    'headline',
    'body',
    'btn'
];

const defaultTypeStyle = {
    letter_spacing: 0,
    line_height: '1.6',
    font_weight: 'regular',
    font: 'body',
    font_size: 16
}

class TypographyStylesField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {edit: false, anchorEl: null, search:null};
    }

    edit = (index, evt) => {
        this.setState({edit: index, anchorEl: evt.target});
    }

    close = () => this.setState({edit: null, anchorEl: null});

    append = () => {
        const { onChange } = this.props;
        const next = this.props.value.slice();
        next.push({id: this.state.search, ...defaultTypeStyle});
        onChange(next);
    }

    change = (i, value) => {
        const { onChange } = this.props;
        const next = this.props.value.slice();
        next[i] = {...next[i], ...value};
        onChange(next);
    }

    remove = (i) => {
        const { onChange, value } = this.props;
        const next = value.slice();
        delete next[i];
        onChange(next.map(e=>e));
        this.close();
    }

    search = (value) => {
        this.setState({search: value});
    }

    render() {
        const { value, preset } = this.props;
        const { edit, anchorEl, search } = this.state;
        const isOpen = edit || edit === 0;
        const searchValues = search
            ? value.filter(item=>item.id.indexOf(search)>-1)
            : value;
        const sortValues = searchValues.sort((a,b) => {
            if (a.font_size > b.font_size) {
                return -1
            }
            if (a.font_size < b.font_size) {
                return 1;
            }
            return 0;
        });
        return (<div className={'rows gap-md grow'}>
            <div className={'rows gap-xs'}>
                <TextField
                    onChange={this.search}
                    variant={'search'}
                    label={'Search'}
                    value={this.state.search || ''}
                />
                <div className={'cols gap-xs'}>
                    {quickSearch.map(s => {
                        return <div key={s} onClick={()=>this.search(s)}>{s}</div>
                    })}
                </div>
            </div>
            <div className={'rows'}>
                {sortValues.map((style, index) => {
                    return <TypographyStyle
                        preset={preset.typography}
                        key={index}
                        value={style}
                        isOpen={value.indexOf(style) === edit}
                        onChange={(v)=>this.change(value.indexOf(style), v)}
                        onEdit={(evt)=>this.edit(value.indexOf(style), evt)}
                        onDelete={(evt)=>this.remove(value.indexOf(style))}
                    />;
                })}
            </div>
            <Button onClick={this.append}>create style</Button>
            <Popover open={isOpen} anchorEl={anchorEl} onClose={this.close}>
                {isOpen
                    ? <TypographyStyleSettings
                        value={value[edit]}
                        onDelete={(evt)=>this.remove(edit)}
                        onChange={(value)=>this.change(edit, value)}
                    />
                    : null}
            </Popover>
        </div>);
    }

}

TypographyStylesField.defaultProps = {
    value: []
}

TypographyStylesField.propTypes = {};

export default connect(mapState,mapDispatch)(TypographyStylesField);