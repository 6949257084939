import { createCallback } from "state/actions/create";
import uuid from "uuid/v4";
import { NODE_TYPE_CHAT } from "joynt/config";

export function createThread(cb, parent, list, recipients, id, type) {
    let nodeId = id || uuid();
    let threadId = uuid();

    if (!recipients) recipients = [];

    const data = {
        id: nodeId,
        node_type: "stem",
        edges_ref: { parent },
        parent,
        type: "nodes",
        subtype: NODE_TYPE_CHAT,
        thread: {
            id: threadId,
            participants: recipients,
            thread_type: type,
        },
        draft: true,
        recipient_identities: recipients,
    };

    const nextCb = () => {
        if (cb) cb(data.id);
    };

    return createCallback(nextCb, "db.nodes", data, list, -1);
}

export const createMessage =
    (context) => (thread, message, nodeId, threadType) => {
        return {
            type: "MESSAGES.CREATE",
            payload: {
                thread,
                message,
                nodeId,
                threadType,
            },
            context,
        };
    };

export const pushMessage = (message) => ({
    type: "MESSAGES.PUSH",
    payload: { message },
});

export const fetchMentionSuggestions = (context) => (id, search, callback) => ({
    type: "JOINT.MENTIONS",
    payload: { id, search, callback },
    context,
});

export const startChat = (context) => (parent, id) => ({
    type: "MESSAGES.START_CHAT",
    payload: { parent, id },
    context,
});

export const showChat = (id) => ({
    type: "MESSAGES.SHOW_CHAT",
    payload: { id },
});
