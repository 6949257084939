import {usePresentation} from "joynt/meeting/presentation";
import {useCallback, useMemo} from "react";
import {useApiActions, useRouteParam} from "state/hooks";
import {moveNode, shareNode} from "joynt/state/actions";
import useMeeting from "joynt/meeting/hooks/useMeeting";
import {ROUTE_POST} from "joynt/config";

export function usePostActions(id) {
    const {onPresent} = usePresentation();
    const {session} = useMeeting();

    const {
        onMove,
        onShare
    } = useApiActions({
        onShare: shareNode,
        onMove: moveNode
    });

    const [,setRoute] = useRouteParam(ROUTE_POST);

    const present = useCallback(() => onPresent(id), [onPresent, id]);
    const share = useCallback(() => onShare(id, session), [onShare, id, session]);
    const move = useCallback(() => onMove(id), [onMove, id]);
    const edit = useCallback(() => setRoute(['edit', id].join('.')), [setRoute, id]);

    return useMemo(() => ({
        onPresent: session ? present : null,
        onShare: session ? share : null,
        onMove: move,
        onEdit: edit
    }), [
        session,
        present,
        share,
        move,
        edit
    ]);
}