import {useCallback} from "react";
import {useApiActions, useCurrentUser} from "state/hooks";
import {useSelector} from "react-redux";
import {selectEntity} from "state/selectors/data";
import {post} from "state/actions/api";

/**
 * Overall notification count for user (notification icon)
 *
 * @returns {{count: *, onReset: (function(): void)}}
 */
export function useGlobalNotifications(groupId = 'all') {
    const user = useCurrentUser();
    const stats = useSelector(s=>selectEntity(s, 'db.notification-stats', user));
    const group = stats[groupId] || {};
    const count = group.count || 0;
    const {postAction} = useApiActions({postAction: post});

    const reset = useCallback(() => {
        postAction('joynt/notifications', {}, 'notification-stats');
    }, [postAction]);

    const seen = useCallback(() => {
        postAction(`joynt/notifications/group/${groupId}`, {}, 'notification-stats');
    }, [postAction, groupId]);

    return {
        show: count && (group.updated_at > group.seen_at),
        count,
        onReset: reset,
        onSeen: seen
    }
}