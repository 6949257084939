import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { EntryDescription } from "playground/cms/views/Entries";
import {
    PropertyGrid,
    Property,
    PropertyPopover,
} from "playground/cms/fields/Properties";
import { useData } from "state/hooks";
import Popover from "components/Popover";
import EnumValues from "playground/cms/fields/EnumValues";
import { FormLayout } from "playground/cms/Form";
import Button from "components/Button";
import { MediaField } from "playground/cms/fields/Media";
import Icon from "components/Icon";

import "./style.css";

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
    headline: false,
    media: false,
    text: false,
    "settings.area": false,
    "settings.price": false,
    "settings.price_freeform": false,
};

function RoomsConfPrice(props) {
    const { price, price_freeform } = useData(props);
    const str = [price, price_freeform].filter((v) => !!v).join(", ");
    return (
        <PropertyPopover label={"Cena"} {...props} value={str}>
            <div className={"rows pad-md gap-sm"}>
                <Field
                    label={"Cena"}
                    id={"price"}
                    fieldType={"text"}
                    size={"small"}
                />
                <Field
                    label={"Cena w formacie dowolnym"}
                    id={"price_freeform"}
                    fieldType={"text"}
                    size={"small"}
                />
            </div>
        </PropertyPopover>
    );
}

const conferenceSettings = {
    s_theatrical: "Teatralne",
    s_school: "Szkolne",
    s_horseshoe: "Podkowa",
    s_banquet: "Bankiet",
    s_conference: "Konferencyjne",
    s_horseshoe_full: (
        <>
            Podkowa
            <br />
            (miejsca w środku)
        </>
    ),
};

function ConferenceSettingsPopover(prop) {
    return (
        <Popover
            trigger={({ onClick }) => (
                <Button
                    variant={"outlined"}
                    onClick={onClick}
                    endIcon={<Icon>mui-edit</Icon>}
                >
                    Zmień
                </Button>
            )}
        >
            <div className={"grid grid-2 gap-xs pad-md"}>
                {Object.entries(conferenceSettings).map(([k, v]) => (
                    <Field key={k} label={v} id={k} size={"small"} />
                ))}
            </div>
        </Popover>
    );
}

function ConferenceSettings(props) {
    const data = useData(props);
    return (
        <FormLayout.Section
            name={"Ustawienia sali"}
            action={<ConferenceSettingsPopover />}
        >
            <div className={"conference-settings"}>
                {Object.keys(conferenceSettings).map((k) => (
                    <div key={k}>
                        <div className={"value"}>{data[k] || "-"}</div>
                        <div className={"label"}>{conferenceSettings[k]}</div>
                    </div>
                ))}
            </div>
        </FormLayout.Section>
    );
}

export function RoomsConfForm(props) {
    return (
        <div className={"pad-md rows gap-md"}>
            <EntryDescription {...props} mediaVariant={"form-section"} />
            <PropertyGrid>
                <Property label={"Powierzchnia"}>
                    <Field
                        label={null}
                        id={"area"}
                        fieldType={"text"}
                        variant={"dense"}
                    />
                </Property>
                <RoomsConfPrice {...props} />
            </PropertyGrid>
            <Field
                label={"Udogodnienia"}
                id={"amenities"}
                enumType={"conference-amenities"}
                FieldComponent={EnumValues}
            />
            <ConferenceSettings {...props} />
            <MediaField id={"attachments"} label={"Załączniki"} />
            {/*<Form type={type} id={id} selectFields={selectFields} />*/}
        </div>
    );
}

RoomsConfForm.propTypes = {};

RoomsConfForm.defaultProps = {};

export default RoomsConfForm;
