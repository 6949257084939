import React, { useState } from "react";

import CommandPalette, {
    useHandleOpenCommandPalette,
    filterItems,
    renderJsonStructure,
} from "react-cmdk";

import "react-cmdk/dist/cmdk.css";
import { useFetch, useRouteParam, useUiFlag } from "state/hooks";
import { useSelector, useDispatch } from "react-redux";
import { selectAll, selectEntity, selectList } from "state/selectors/data";
import { selectList as selectListIds } from "state/selectors/lists";
import { pushRoute } from "state/actions/router";

//import PropTypes from 'prop-types';

function selectProjects(state, search) {
    const projects = selectList(state, "cms.services", "command");
    return projects
        .filter((p) => {
            return ["website", "apartments"].includes(p.type);
        })
        .map((p) => ({
            id: p.id,
            children: p.name,
            href: `/pl/${p.name}`,
            keywords: [p.name, ...(p.domain_names || [])],
        }));
}

function moduleUrl(state, module) {
    const settings = selectListIds(state, "app.user-menu", "settings");
    if (settings.includes(module.id)) {
        return `?resource=cms.settings&s=${module.path}`;
    }
    return `?resource=${module.path}`;
}

function selectModules(state, search) {
    const pages = selectList(state, "app.user-menu", "pages");
    const entries = selectList(state, "app.user-menu", "entries");
    const settings = selectList(state, "app.user-menu", "settings");
    const modules = pages.concat(entries).concat(settings);
    const list = modules.map((m) => ({
        id: m.id,
        children: m.label,
        href: moduleUrl(state, m),
        keywords: [m.id, m.path],
    }));
    list.push({
        id: "console",
        children: "zuu.console",
        href: "/console",
        keywords: ["console"],
    });
    return list;
}

function selectSearchResult(state) {
    const items = selectList(state, "app.search", "search");
    const itemsByType = items.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
    }, {});
    return Object.keys(itemsByType).map((type) => ({
        id: type,
        heading: selectEntity(
            state,
            "app.user-menu",
            type.split(".")[type.split(".").length - 1]
        ).label,
        items: itemsByType[type].map((item) => ({
            id: item.id,
            children: item.title,
            href: "?resource=" + item.type + "&id=" + item.id,
        })),
    }));
}

function selectItems(state, search) {
    return [
        {
            id: "cmd",
            items: [
                {
                    id: "cmd.search",
                    children: "Search",
                    href: "#search",
                    closeOnSelect: false,
                },
            ],
        },
        {
            id: "modules",
            heading: "Modules",
            items: selectModules(state, search),
        },
        {
            id: "projects",
            heading: "Projects",
            items: selectProjects(state, search),
        },
    ];
}

function RenderLink(props) {
    return <div {...props} />;
}

const renderLinkWithRouter = (push, setPage) => (props) => {
    const onClick =
        props.href.indexOf("#") === 0
            ? () => setPage(props.href.substr(1))
            : () => push(props.href);
    return <RenderLink {...props} onClick={onClick} href={null} />;
};

export function Command(props) {
    const {} = props;
    const [isOpen, setIsOpen] = useUiFlag("app.cmdk", false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("root");
    const [service] = useRouteParam("service");

    useHandleOpenCommandPalette(setIsOpen);

    useFetch({
        type: "cms.services",
        url: `console/services?index=true`,
        list: "command",
        enable: isOpen && search.length > 2,
    });

    useFetch({
        type: "app.search",
        url: `v2/cms/search?search=${search}&service=${service}`,
        list: "search",
        enable: isOpen && page === "search" && search.length > 2,
    });

    const allItems = useSelector((store) => selectItems(store, search));
    const items = filterItems(allItems, search);
    const dispatch = useDispatch();
    const link = (href) => {
        dispatch(pushRoute(href));
    };

    const searchResults = useSelector((store) => selectSearchResult(store));

    return (
        <CommandPalette
            isOpen={isOpen}
            onChangeOpen={setIsOpen}
            search={search}
            onChangeSearch={setSearch}
            page={page}
            renderLink={renderLinkWithRouter(link, setPage)}
        >
            <CommandPalette.Page id={"root"}>
                {renderJsonStructure(items || [])}
            </CommandPalette.Page>
            <CommandPalette.Page
                id={"search"}
                searchPrefix={["Search"]}
                onEscape={() => setPage("root")}
            >
                {renderJsonStructure(searchResults || [])}
            </CommandPalette.Page>
        </CommandPalette>
    );
}

Command.propTypes = {};

Command.defaultProps = {};

export default Command;
