import React from "react";
//import PropTypes from 'prop-types';
import TransferList from "./TransferList";
import { useFetch, useFilter, useList } from "state/hooks";

import schema from "config/schema";
import { useSelector } from "react-redux";
import { selectListTagsIndex } from "playground/cms/state/selectors/list";

function getRefType(type, field) {
    return schema?.[type]?.fields?.[field]?.[0] || "cms.tags";
}

function toggle(value = [], item) {
    if (!item) return value;
    if (value.includes(item)) {
        return value.filter((t) => t !== item);
    } else {
        return value.concat([item]);
    }
}

function useFilteredList(type, id) {
    const { items: base } = useList(type, id);
    const { items: filtered } = useList(type, [id, "filter"].join("/"));
    const filterExists = useSelector((store) => {
        return store?.lists?.[type]?.[[id, "filter"].join("/")];
    });
    if (!filterExists) return { items: base || [] };
    return { items: filtered || [] };
}

export function TransferListContainer(props) {
    const { value, onChange } = props;

    const { entityId, type, id, targetListId } = props;
    const entityType = props.targetEntityType || props.entityType;

    const refType = getRefType(entityType, id);

    const listId = [entityType, id].join(".");
    const filterId = [refType, listId].join("/");

    const fetchProps = {
        type: refType,
        list: listId,
        url: refType,
    };
    const isPending = useFetch(fetchProps);

    const { items } = useFilteredList(refType, listId, true);

    //useRegisterList({ ...fetchProps, filter: filterId });

    const { value: filterValue, onSet: onChangeFilter } = useFilter(
        filterId,
        refType
    );

    const handleToggle = (item) => {
        const next = toggle(value, item);
        console.log(value, item, next);
        onChange(item);
    };

    const available = items.filter((t) => !value.includes(t));

    const counts = useSelector((state) => {
        return selectListTagsIndex(
            state,
            entityType,
            targetListId || entityType
        );
    });

    const suggested = available
        .filter((id) => counts[id] > 0)
        .sort((a, b) => counts[b] - counts[a]);

    const other = available.filter((id) => !suggested.includes(id));

    const result = suggested.concat(other);

    return (
        <>
            <TransferList
                value={value}
                items={result}
                counts={counts}
                onToggle={handleToggle}
                isPending={isPending}
                search={filterValue?.search || ""}
                onSearch={(v) => onChangeFilter("search", v)}
            />
        </>
    );
}

TransferListContainer.propTypes = {};

TransferListContainer.defaultProps = {
    value: [],
};

export default TransferListContainer;
