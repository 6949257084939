import React from "react";
import PropTypes from 'prop-types';

import { sanitize } from "dompurify";

const config = {
    'html': {
        ALLOWED_TAGS: [
            "br",
            "span",
            "strong",
            "p",
            "a",
            "i",
            "u",
            "li",
            "ul",
            "ol",
            "em",
        ],
        ALLOWED_ATTR: ["href", "target", "class"],
    },
    'plain-text': {
        ALLOWED_TAGS: [],
        ALLOWED_ATTR: []
    }
};

export default function Html(props) {
    const { value, elementType, sanitizationMode, ...other } = props;
    const sanitizationConfig = config[sanitizationMode];

    return React.createElement(elementType, {
        dangerouslySetInnerHTML: { __html: sanitize(value, sanitizationConfig) },
        ...other,
    });
}

Html.propTypes = {
    sanitizationMode: PropTypes.oneOf(['html', 'plain-text'])
};

Html.defaultProps = {
    elementType: "div",
    sanitizationMode: "html"
};
