import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Discussions from "./Discussions";
import { useEdgesData } from "joynt/hooks";

export function DiscussionsContainer(props) {
    const { id } = props;

    const { session_chat: chatId, threads: qaId } = useEdgesData(id);

    const [tab, setTab] = useState(chatId);

    const tabs = useMemo(() => {
        const tabs = {};
        if (chatId)
            tabs[chatId] = {
                id: chatId,
                label: "General chat",
                type: "chat",
                icon: "mui-chat",
            };
        if (qaId)
            tabs[qaId] = {
                id: qaId,
                label: "Ask a question",
                type: "qa",
                icon: "mui-question_answer",
            };
        return tabs;
    }, [chatId, qaId]);

    return (
        <Discussions
            {...props}
            tabs={tabs}
            onChangeTab={setTab}
            selected={tab}
        />
    );
}

DiscussionsContainer.propTypes = {
    id: PropTypes.string,
};
DiscussionsContainer.defaultProps = {};

export default DiscussionsContainer;
