import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

export function FormActions(props) {
    const {
        onSubmit,
        onReject,
        submitLabel,
        rejectLabel,
        disableSubmit,
        disableReject,
    } = props;

    const buttonProps = { size: "large" };

    return (
        <div className={"cols cols-center gap-xs"}>
            {onReject && (
                <Button
                    {...buttonProps}
                    color={"default"}
                    onClick={onReject}
                    disabled={disableReject}
                >
                    {rejectLabel}
                </Button>
            )}
            {onSubmit && (
                <Button
                    {...buttonProps}
                    onClick={onSubmit}
                    disabled={disableSubmit}
                >
                    {submitLabel}
                </Button>
            )}
        </div>
    );
}

FormActions.propTypes = {
    onSubmit: PropTypes.func,
    onReject: PropTypes.func,
    submitLabel: PropTypes.string,
    rejectLabel: PropTypes.string,
    disableSubmit: PropTypes.bool,
    disableReject: PropTypes.bool,
};

FormActions.defaultProps = {
    submitLabel: "Submit",
    rejectLabel: "Cancel",
    disableSubmit: false,
    disableReject: false,
};

export default FormActions;
