import React from "react";
//import PropTypes from 'prop-types';

import UtilityNavDrawer from "./Drawer";
import DrawerContent from "./DrawerContent";
import NavGroup from "./NavGroup";
import Button, { NotificationButton, ProfileButton } from "./Button";
import UtilityNavPeople from "./PeopleList";

import "./style.css";

function WorkspaceNav(props) {
    const { workspace, onShow, isHome } = props;

    return (
        <>
            <NavGroup>
                <ProfileButton
                    id={workspace}
                    onClick={() => onShow("profile")}
                    className={"utility-nav-profile"}
                />
                <NotificationButton
                    group={"activity"}
                    label={"Activity"}
                    onClick={() => onShow("activity")}
                    icon={"mui-notifications"}
                    className={"utility-nav-activity"}
                />
                <NotificationButton
                    group={"messages"}
                    label={"Inbox"}
                    onClick={() => onShow("inbox")}
                    icon={"mui-email"}
                    className={"utility-nav-inbox"}
                />
                <Button
                    label={"Calendar"}
                    onClick={() => onShow("calendar")}
                    icon={"mui-today"}
                />
                {!isHome && (
                    <Button
                        label={"Share"}
                        onClick={() => onShow("share")}
                        icon={"mui-share"}
                        color={"link"}
                        className={"utility-nav-share"}
                    />
                )}
            </NavGroup>
            <NavGroup>
                <Button
                    label={"People"}
                    onClick={() => onShow("people")}
                    icon={"mui-people"}
                    className={"utility-nav-people"}
                />
                <UtilityNavPeople id={workspace} />
            </NavGroup>
        </>
    );
}

function PageNav(props) {
    const { workspace, onShow, isHome } = props;

    return (
        <>
            <NavGroup>
                <ProfileButton
                    id={workspace}
                    onClick={() => onShow("profile")}
                    className={"utility-nav-profile"}
                />
                <NotificationButton
                    group={"messages"}
                    label={"Inbox"}
                    onClick={() => onShow("inbox")}
                    icon={"mui-email"}
                    className={"utility-nav-inbox"}
                />
                {!isHome && (
                    <Button
                        label={"Share"}
                        onClick={() => onShow("share")}
                        icon={"mui-share"}
                        color={"link"}
                        className={"utility-nav-share"}
                    />
                )}
            </NavGroup>
            <NavGroup>
                <Button
                    label={"People"}
                    onClick={() => onShow("people")}
                    icon={"mui-people"}
                    className={"utility-nav-people"}
                />
                <UtilityNavPeople id={workspace} />
            </NavGroup>
        </>
    );
}

export function UtilityNav(props) {
    const { isHiddenNav, session, ...other } = props;
    const Nav = props.isPage ? PageNav : WorkspaceNav;

    return (
        <UtilityNavDrawer
            isHiddenNav={isHiddenNav}
            nav={!session && <Nav {...other} />}
        >
            <DrawerContent {...other} />
        </UtilityNavDrawer>
    );
}

UtilityNav.propTypes = {};
UtilityNav.defaultProps = {};

export default UtilityNav;
