import React from 'react';
import {connect} from 'react-redux';
import MediaBrowser from "cms/ui/MediaBrowser";
import {selectRouteParam} from "state/selectors/router";
import {selectFieldValue} from "state/selectors/data";
import {clone} from "state/actions/copy";
import {pathDetach} from "state/actions/data";

const mapStateToProps = (store, props) => {
    const focus = selectRouteParam(store, 'focus');
    const [type, id] = focus.split('/');
    const field = 'media';
    const selection = selectFieldValue(store, type, id, field);
    const target = [type,id,field].join('/');
    return {
        selection,
        target
    }
};

const mapDispatchToProps = {
    onSelect: clone,
    onSelectionRemove: pathDetach
};

class MediaBrowserSelect extends React.PureComponent {

    select = (id) => {
        const { target, onSelect } = this.props;
        onSelect('cms.media', id, target);
    };

    deselect = (id) => {
        const { target, onSelectionRemove } = this.props;
        onSelectionRemove(target.split('/'), id);
    };

    render() {
        return (<MediaBrowser
            {...this.props}
            onSelect={this.select}
            onSelectionRemove={this.deselect}
        />);
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaBrowserSelect);