import React from 'react';

import Layout from "./Layout";
import LearnMoreButton from "./LearnMoreButton";
import config from "./config";
import Button from "components/Button";
//import PropTypes from 'prop-types';

export default function DeviceAccessError(props) {

    const {onClose, camera, microphone} = props;

    const { chromeHelpUrl: url } = config;

    const devices = [];

    if (camera) devices.push('camera');
    if (microphone) devices.push('microphone');
    const devicesStr = devices.join(' and ');

    return (<Layout
        title={`We can't access your ${devicesStr}`}
        description={<>
            It seems joynt is blocked from accessing your {devicesStr} by your browser settings.
            Please enable access in order to participate in the session.
        </>}
    >
        <div className={"rows gap-sm rows-center"}>
            {onClose && <Button onClick={onClose} color={"default"}>
                Close
            </Button>}
            <div className={'cols cols-center'}>
                <LearnMoreButton url={url} />
            </div>
        </div>
    </Layout>);

}

DeviceAccessError.propTypes = {};
DeviceAccessError.defaultProps = {};
