import React from "react";
//import PropTypes from 'prop-types';

import SchemaModulesContext from "./SchemaModulesContext";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const OPTIONAL_MODULES = ["properties"];

const selectModules = createSelector(
    [(store) => store?.data?.["schema.db"] || {}],
    (schema) => {
        return Object.values(schema)
            .filter((item) => OPTIONAL_MODULES.includes(item.slug))
            .map((item) => item.slug);
    }
);

export function SchemaModulesProvider(props) {
    const {} = props;

    const modules = useSelector(selectModules);

    return (
        <SchemaModulesContext.Provider value={modules}>
            {props.children}
        </SchemaModulesContext.Provider>
    );
}

SchemaModulesProvider.propTypes = {};

SchemaModulesProvider.defaultProps = {};

export default SchemaModulesProvider;
