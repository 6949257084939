import React from 'react';
import PropTypes from 'prop-types';
import Select from "./Select";

export function SelectContainer(props) {

    //const {} = props;
    return (<Select {...props} />);

}

SelectContainer.propTypes = {
    id: PropTypes.string
};
SelectContainer.defaultProps = {};

export default SelectContainer;