import React, { useState } from "react";
//import PropTypes from 'prop-types';
import FontBrowser from "./FontBrowser";
import { useFetch, useList } from "state/hooks";
import fontSources from "config/font-sources";
import { VirtualizedListIterator } from "components/list/virtualized";
import FontItem from "./FontItem";
import List from "containers/List";

const Iterator = VirtualizedListIterator(FontItem);
const availableSources = Object.keys(fontSources);

export function FontBrowserContainer(props) {
    const { type, initialSource, onSelect } = props;

    const [source, setSource] = useState(initialSource);
    const [search, setSearch] = useState("");

    const listId = [type, source].join(".");

    const { items } = useList(listId, listId);

    const filteredItems = items.filter((item) => {
        if (!search) return true;
        return item.toLowerCase().includes(search.toLowerCase());
    });

    const isPending = useFetch({
        type: listId,
        list: listId,
        url: fontSources[source],
    });

    const fontList = (
        <List
            type={listId}
            list={listId}
            items={filteredItems}
            Iterator={Iterator}
            rowHeight={48}
            columnCount={3}
            onClick={onSelect}
        />
    );

    return (
        <FontBrowser
            sources={availableSources}
            source={source}
            onChangeSource={setSource}
            search={search}
            onChangeSearch={setSearch}
            isPending={isPending}
        >
            {fontList}
        </FontBrowser>
    );
}

FontBrowserContainer.propTypes = {};

FontBrowserContainer.defaultProps = {
    initialSource: "google",
    type: "cms.fonts",
};

export default FontBrowserContainer;
