import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";

export function Text(props) {
    const { children } = props;
    return (
        <>
            {children}
            <Field id={"title"} label={"Title"} />
            <Field id={"headline"} label={"Headline"} />
            <Field id={"text"} label={"Text"} fieldType={"rich"} />
        </>
    );
}

Text.propTypes = {};

Text.defaultProps = {};

export default Text;
