import React from "react";
import { PillButton } from "../../PillButton/PillButton";
import Chat from "joynt/components/messages/chat/Chat";
import SessionPageSection from "joynt/components/SessionPage/SessionPageSection";
import Questions from "joynt/sidebar/Questions";
import {useNotificationCount} from "joynt/hooks/notifications";

//import PropTypes from 'prop-types';

const views = {
    chat: Chat,
    qa: Questions,
};

function ButtonContainer(props) {
    const { id, onClick } = props;
    const [count, markSeen] = useNotificationCount(id);

    const click = () => {
        markSeen(id);
        onClick();
    }

    return <PillButton
        {...props}
        hasNotifications={!!count}
        onClick={click}
    />;
}

export function Discussions(props) {
    const { selected, tabs, onChangeTab } = props;

    const current = tabs[selected];
    const ViewComponent = views[current?.type];

    return (
        <SessionPageSection title={"Discussions"}>
            <div className={"pill-button-nav"}>
                {Object.values(tabs).map((tab) => (
                    <ButtonContainer
                        key={tab.id}
                        id={tab.id}
                        onClick={() => onChangeTab(tab.id)}
                        isActive={selected === tab.id}
                        label={tab.label}
                        icon={tab.icon}
                    />
                ))}
            </div>
            {current && ViewComponent && (
                <ViewComponent id={current.id} showHeader={false} />
            )}
        </SessionPageSection>
    );
}

Discussions.propTypes = {};
Discussions.defaultProps = {};

export default Discussions;
