import React from "react";
//import PropTypes from 'prop-types';

import { useData } from "state/hooks";
import GalleryItem from "./GalleryItem";

export const GalleryItemContainer = React.forwardRef((props, ref) => {
    const { id, onClick, selected, ...rest } = props;
    const { active, media } = useData(props);
    const mediaData = useData({ type: "cms.media", id: media?.[0] });

    return (
        <GalleryItem
            {...rest}
            ref={ref}
            entityType={rest.type}
            id={id}
            file={mediaData.file}
            type={mediaData.type}
            active={active}
            isSelected={selected === id}
            onClick={() => onClick(id)}
        />
    );
});

GalleryItemContainer.propTypes = {};

GalleryItemContainer.defaultProps = {};

export default GalleryItemContainer;
