import React from "react";

//import PropTypes from 'prop-types';

export function Link(props) {
    return <>link component</>;
}

Link.propTypes = {};

Link.defaultProps = {};

export default Link;
