import React from 'react';
import PropTypes from "prop-types";

import Popover from "components/Popover";

import {useMoment} from "./../hooks";
import InputBox from "./../InputBox";
import DatePickerCalendar from "./DatePickerCalendar";

import {isDifferentDay} from "./../util";

export function DatePicker(props) {
    const {start, end, format, onChange} = props;
    const startMoment = useMoment(start);
    const endMoment = useMoment(end);
    const isRange = isDifferentDay(new Date(start*1000), new Date(end*1000));

    return (
        <Popover
            trigger={({onClick}) => <InputBox startIcon={"mui-today"} onClick={onClick}>
                {isRange ? `${startMoment.format(format)} - ${endMoment.format(format)}`
                    : startMoment.format(format)}
            </InputBox>}
            content={({onClose}) => <DatePickerCalendar
                value={startMoment}
                onChange={(v)=>{onChange(v.unix());onClose()}}
            />}
        />
    );
}

DatePicker.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    format: PropTypes.string,
    onChange: PropTypes.func
}

DatePicker.defaultProps = {
    format: "DD MMM YYYY"
}

export default DatePicker;
