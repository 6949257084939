import React from 'react';
//import PropTypes from 'prop-types';
import {ArrayIterator} from "components/list/iterator";
import {withData} from "containers/Entity";
import Card from '@material-ui/core/Card';
import Price from "playground/booking/Price";
import RoomGallery from "playground/booking/RoomGallery";

import 'playground/booking/css/product-card.css';

export default class ProductCard extends React.PureComponent {

    click = () => {
        const { id, onClick } = this.props;
        onClick(id);
    };

    render() {
        const { title, price, media } = this.props.data;

        return (<Card onClick={this.click}>
            <div className={'product-card-image'}>
                <RoomGallery media={media} />
            </div>
            <div className={'cols cols-spread pad-sm'}>
                <div>{title}</div>
                <div>
                    <Price price={price} />
                </div>
            </div>
        </Card>);
    }

}

export const ProductCardIterator = ArrayIterator(withData(ProductCard));

ProductCard.propTypes = {};