import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import Form from "containers/Form";
import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";
import { MediaField } from "playground/cms/fields/Media";

import {
    PropertyGrid,
    PropertyPopover,
    Select,
    OfferPrice,
    DateRange,
} from "playground/cms/fields/Properties";

import TagsField from "playground/cms/fields/TagsField";
import { EntryDescription } from "playground/cms/views/Entries";

import "./style.css";
import ExtendedFields from "playground/cms/fields/ExtendedFields";
import BookingIntegration from "playground/cms/fields/BookingIntegration";

function renderStayLength(data) {
    const { days, min_stay, max_stay } = data;
    if (!days && !min_stay && !max_stay) return null;
    let result = [];
    if (days) result.push(days + " dni");
    const range = [];
    if (min_stay) range.push(min_stay);
    if (max_stay) range.push(max_stay);
    if (range.length) result.push(range.join("-").concat(" noclegi"));
    return result.join(", ");
}

function OfferStayLength(props) {
    const data = useData(props);
    const value = renderStayLength(data);
    return (
        <PropertyPopover
            label={"Długość pobytu"}
            placeholder={"Ustaw"}
            value={value}
        >
            <div className={"pad-md rows gap-sm w-md"}>
                <div className={"cols gap-xs"}>
                    <Field
                        id={"min_stay"}
                        fieldType={"text"}
                        label={"Min stay"}
                        size={"small"}
                        InputProps={{ endAdornment: "noc" }}
                    />
                    <Field
                        id={"max_stay"}
                        fieldType={"text"}
                        label={"Max stay"}
                        size={"small"}
                        InputProps={{ endAdornment: "noc" }}
                    />
                </div>
                <Field
                    id={"days"}
                    fieldType={"text"}
                    label={"Dni"}
                    size={"small"}
                    InputProps={{ endAdornment: "dni" }}
                />
            </div>
        </PropertyPopover>
    );
}

function customFieldsSelector(store, name, id) {
    const def = store?.data?.["db.fields"]?.[id];
    if (!def?.fields) return false;
    const custom = def.fields.reduce((acc, fieldId) => {
        const field = store?.data?.["db.fields"]?.[fieldId];
        if (field?.properties?.extension) acc.push(fieldId);
        return acc;
    }, []);
    return custom;
    //return id;
}

const selectFields = {
    settings: customFieldsSelector,
};

export function OfferForm(props) {
    const { type, id } = props;
    return (
        <div className={"rows gap-lg pad-md"}>
            <div className={"rows gap-md"}>
                <EntryDescription {...props} />

                <FormLayout.Section
                    name={"Parametry oferty"}
                    variant={"default"}
                >
                    <div className={"rows gap-sm"}>
                        <PropertyGrid>
                            <OfferPrice {...props} />
                            <DateRange {...props} />

                            <Field
                                id={"mealType"}
                                fieldType={"select"}
                                source={"schema:data.mealType"}
                                label={"Wyżywienie"}
                                placeholder={"Wybierz"}
                                variant={"inline"}
                                FieldComponent={Select}
                            />

                            <OfferStayLength {...props} />
                        </PropertyGrid>
                    </div>
                </FormLayout.Section>

                <BookingIntegration {...props}>
                    <div className={"cols cols-2 gap-md"}>
                        <Field
                            id={"online_booking_disabled"}
                            fieldType={"checkbox"}
                            label={"Wyłącz rezerwację online"}
                        />
                        <Field
                            id={"archived"}
                            fieldType={"checkbox"}
                            label={"Archiwalny"}
                        />
                    </div>
                </BookingIntegration>

                <MediaField id={"attachments"} label={"Załączniki"} />

                <FormLayout.Section name={"Custom fields"} variant={"default"}>
                    <ExtendedFields {...props} />
                    {/*<Form type={type} id={id} selectFields={selectFields} />*/}
                </FormLayout.Section>
            </div>
        </div>
    );
}

OfferForm.propTypes = {};

OfferForm.defaultProps = {};

export default OfferForm;
