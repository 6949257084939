import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import Icon from '@material-ui/core/Icon';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

class SelectInput extends React.PureComponent {
    render() {
        return <div style={{ flex: 1, padding: '0.85rem' }}>
            {this.props.value || 'wybierz'}
        </div>;
    }
}

export default class CustomMuiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.InputLabelRef = null;
        this.state = {
            labelWidth: 0
        }
    }

    componentDidMount() {
        this.updateLabelWidth();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.label !== this.props.label) {
            this.updateLabelWidth();
        }
    }

    updateLabelWidth = () => {
        const label = findDOMNode(this.InputLabelRef);
        this.setState({
            labelWidth: label ? label.offsetWidth : 0,
        });
    };

    handleClear = (evt) => {
        const { onClear, onChange } = this.props;
        evt.stopPropagation();
        if (onClear) {
            onClear(evt);
            return;
        }
        if (onChange) {
            onChange(this.props.multiple ? [] : null);
        }
    };

    inputProps = {};

    labelRef = ref => {
        this.InputLabelRef = ref;
    };

    render() {
        const {
            id,
            label,
            nullable,
            onClick,
            value,
            error,
            helperText
        } = this.props;

        const { labelWidth } = this.state;

        return (<FormControl variant={'outlined'} fullWidth={true} error={error}>
            <InputLabel shrink={true} htmlFor={id} variant={'outlined'} ref={this.labelRef}>
                {label}
            </InputLabel>
            <OutlinedInput
                notched={true}
                id={id}
                name={id}
                labelWidth={labelWidth}
                readOnly={true}
                inputComponent={SelectInput}
                value={''}
                inputProps={{
                    value,
                    label
                }}
                endAdornment={nullable ? (<div className={'cols'}>
                    <Icon onClick={this.handleClear} className="icon-btn">clear</Icon>
                    <Icon>keyboard_arrow_down</Icon>
                </div>) : (
                    <Icon>keyboard_arrow_down</Icon>
                )}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </FormControl>);
    }

}

CustomMuiSelect.propTypes = {
    id: PropTypes.string,
    nullable: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.any
};