import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {selectChannelsByType} from "joynt/hooks/channels/useChannelGroups";
import {types as nodeTypes} from "joynt/config/types";
import SidebarSection, {CreateChannelIcon} from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import SidebarNodeItem from "./../SidebarItem/SidebarItem.container";

function ChannelSection(props) {
    const {children, type, isAdmin, onCreate} = props;
    let name = nodeTypes[type]?.label;
    if (name && name[name.length - 1] !== "s") {
        name += "s";
    }
    const create = useCallback(() => {
        onCreate({subtype: type});
    }, [onCreate, type]);

    return <SidebarSection
        name={name}
        icon={isAdmin && <CreateChannelIcon
            onClick={create}
        />}
    >
        {children}
    </SidebarSection>
}

export function Channels(props) {

    const {id, selected, path, isAdmin, onCreate, ...other} = props;

    const groups = useSelector(s => selectChannelsByType(s, null, id));
    const types = Object.keys(groups);

    return types.map(type => <ChannelSection
        key={type}
        type={type}
        isAdmin={isAdmin}
        onCreate={onCreate}
    >
        {groups[type].nodes.map(n => <SidebarNodeItem
            key={n}
            id={n}
            isActive={selected === n}
            isHighlighted={path && path.includes(n)}
            {...other}
        />)}
    </ChannelSection>);
}

Channels.propTypes = {};

Channels.defaultProps = {};

export default Channels;