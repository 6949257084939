import React from "react";
import { useIdentity, useUserPresence } from "joynt/hooks/participants";
import { thumbnail } from "util/thumbnail";
import Avatar from "joynt/components/Avatar";
import Icon from "components/Icon";
import ContextMenu from "components/ContextMenu";
import AttendeeContextMenu from "./AttendeeContextMenu";
//TODO: replace with joynt/components/IconButton
import IconButton from "components/IconButton";
import { useNodeData } from "joynt/hooks";
import useSessionPresence from "./hooks/useSessionPresence";
import { useCurrentUser } from "state/hooks";
import { RECORDING_IDENTITY } from "joynt/meeting/config";
import useProfileInteraction from "joynt/participants/hooks/useProfileInteraction";

export default function AttendeeContainer(props) {
    const { id, parent, workspace, session, event } = props;
    const { can_edit } = useNodeData(parent);

    const { created_by } = useIdentity(id);
    const userId = useCurrentUser();
    const isYou = created_by === userId;

    const attributes = useSessionPresence(id, session);
    const presence = useUserPresence(created_by);

    const events = useProfileInteraction(workspace, id);

    return (
        <IdentityAttendee
            {...props}
            {...events}
            isAway={!!presence.away}
            attributes={attributes}
            icons={<AttendeeIcons {...attributes} />}
            menu={
                can_edit && !isYou ? (
                    <ContextMenu
                        id={id}
                        parent={event}
                        session={session}
                        workspace={workspace}
                        RenderMenuItems={AttendeeContextMenu}
                    >
                        {({ onMenuOpen }) => (
                            <IconButton
                                size={"small"}
                                icon={"mui-more_vert"}
                                onClick={onMenuOpen}
                            />
                        )}
                    </ContextMenu>
                ) : null
            }
        />
    );
}

function AttendeeIcons({ connected, audio, video, screen, hand }) {
    return connected ? (
        <>
            {hand ? <Icon>mui-pan_tool</Icon> : null}
            {video ? <Icon>mui-videocam</Icon> : null}
            {audio ? <Icon>mui-microphone</Icon> : null}
            {screen ? <Icon>mui-screen_share</Icon> : null}
        </>
    ) : null;
}

export function IdentityAttendee(props) {
    const { id } = props;
    const { image, name, created_by } = useIdentity(id);
    const userId = useCurrentUser();
    const isYou = created_by === userId;

    if (id === RECORDING_IDENTITY)
        return <Attendee id={id} name={"agora.io recording"} />;

    return (
        <Attendee
            {...props}
            image={image?.file}
            name={isYou ? `${name} (You)` : name}
        />
    );
}

export function Attendee(props) {
    const {
        onMouseEnter,
        onMouseLeave,
        onClick,
        isOnline,
        isAway,
        icons,
        menu,
        name,
        image,
    } = props;

    const src = thumbnail(image, [64, 64]);

    return (
        <div
            className={"participant cols cols-middle cols-spread gap-xs"}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={"cols cols-middle gap-xs"} onClick={onClick}>
                <Avatar
                    isOnline={isOnline}
                    isAway={isAway}
                    name={name}
                    src={src}
                />
                <div className={"type-default"}>{name}</div>
            </div>
            <div className={"cols cols-middle"}>
                {icons}
                {menu}
            </div>
        </div>
    );
}

AttendeeContainer.propTypes = {};
AttendeeContainer.defaultProps = {
    isOnline: false,
};
