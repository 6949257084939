import React from 'react';
import PropTypes from 'prop-types';
import {SpaceAvatar} from "joynt/components/SpaceAvatar";
import Icon from "components/Icon";

import "./style.css";

export function ParentNode(props) {
    const {icon, color, onClick} = props;

    return <div onClick={onClick} className={"workspace-link"}>
        <SpaceAvatar
            icon={icon}
            color={color}
            size={"xs"}
        />
        <Icon>mui-arrow_back</Icon>
        <div className={"workspace-link__label"}>Back</div>
    </div>
}

ParentNode.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func
}

export function SidebarBreadcrumbs(props) {

    const {children} = props;

    return (<div className={"sidebar-nav__breadcrumbs"}>
        {children}
    </div>);

}

SidebarBreadcrumbs.propTypes = {};
SidebarBreadcrumbs.defaultProps = {};

export default SidebarBreadcrumbs;
