import React from 'react';
//import PropTypes from 'prop-types';

import Avatar from "../Networking/Avatar";

export function Speakers(props) {

    const {className, value} = props;

    return (<div className={className}>
        {value.map((speaker) => (
            <Avatar size={"xs"} id={speaker} />
        ))}
        <span>{value.length} Speakers</span>
    </div>);

}

Speakers.propTypes = {};

Speakers.defaultProps = {
    value: []
};

export default Speakers;