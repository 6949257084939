import React from 'react';
import {useNode} from "joynt/hooks";
//import PropTypes from 'prop-types';

export function parseExternalUrl(url) {
    if (!url) return {};

    if (url.indexOf('miro.com') > -1) {
        let regex = /https:\/\/miro\.com\/app\/board\/(.*)\//;
        let result = url.match(regex);
        if (!result) return {};
        return {
            type: 'miro',
            boardId: result[1],
            iframeUrl: `https://miro.com/app/live-embed/${result[1]}`
        };
    }

    const googleUrls = [
        'docs.google.com',
        'sheets.google.com'
    ];

    const isGoogle = (url) => {
        let result = false;
        googleUrls.forEach(googleUrl => {
            if (url.indexOf(googleUrl) > 1) result = true;
        });
        return result;
    }

    if (isGoogle(url)) {
        return {
            type: 'docs',
            iframeUrl: url
        }
    }

    return {type: 'url', url};
}

function Iframe({url}) {
    return <iframe
        title={'Miro board'}
        width="100%"
        height="100%"
        src={url}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
    />;
}

export function Embed({url}) {
    const data = parseExternalUrl(url);
    const {type} = data;

    if (type === 'miro')
        return <Iframe url={data.iframeUrl} />

    if (type === 'docs')
        return <Iframe url={data.iframeUrl} />


    return data.url || url;
}

export default function External(props) {

    const {id} = props;
    const {external_url} = useNode(id);

    return <Embed url={external_url} />;

}

External.propTypes = {};
External.defaultProps = {};
