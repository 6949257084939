import React, { useCallback, useEffect } from "react";
import { useApiActions, useFilter } from "state/hooks";
import Select from "components/fields/select/Select";
import Checkbox from "components/fields/checkbox/Checkbox";
import { deleteUnusedSections } from "cms/state/actions/cms";
import Button from "components/Button";
import { Tags } from "playground/cms/List/Filter";
import TextField from "components/fields/text/TextField";
import { useSectionsFilter } from "playground/cms/views/Sections/SectionsFilterContext";

//import PropTypes from 'prop-types';

export function SectionsFilter(props) {
    const { type, list } = props;
    const { value, onSet } = useSectionsFilter();

    //const { value, onSet } = useFilter(list || type, type, list || type);

    const id = ["tags", type].join(".");
    const { onDeleteUnused } = useApiActions({
        onDeleteUnused: deleteUnusedSections,
    });

    const setTemplate = useCallback(
        (v) => {
            onSet("template", v);
        },
        [onSet]
    );

    return (
        <div className={"rows gap-sm"}>
            <TextField
                label={"Szukaj"}
                variant={"search"}
                value={value.search}
                onChange={(v) => onSet("search", v)}
                debounce={true}
            />
            <Tags type={type} list={list} name={"cms_tags"} />
            <Select
                label={"Template"}
                id={"cms.sections.templates"}
                list={"cms.sections.templates"}
                type={"cms.sections"}
                url={"cms/sections?type=view"}
                value={value.template}
                nullable={true}
                onChange={setTemplate}
                variant={"dense"}
            />
            <div className={"rows"}>
                <Checkbox
                    value={value.ungroupTemplates === true}
                    label={"Expand templates"}
                    onChange={(v) => onSet("ungroupTemplates", v ? true : null)}
                    variant={"checkbox"}
                />
                <Checkbox
                    value={value.type === "view"}
                    label={"Templates"}
                    onChange={(v) => onSet("type", v ? "view" : null)}
                    variant={"checkbox"}
                />
                <Checkbox
                    value={value.inactive === true}
                    label={"Show inactive"}
                    onChange={(v) => onSet("inactive", v ? true : null)}
                    variant={"checkbox"}
                />
                <Checkbox
                    value={value.active === false}
                    label={"Inactive"}
                    onChange={(v) => onSet("active", v ? false : null)}
                    variant={"checkbox"}
                />
                <Checkbox
                    value={value.unused === true}
                    label={"Not used"}
                    onChange={(v) => onSet("unused", v ? true : null)}
                    variant={"checkbox"}
                />
                {value.unused === true && (
                    <Button onClick={onDeleteUnused}>Delete unused</Button>
                )}
            </div>
        </div>
    );
}

SectionsFilter.propTypes = {};

SectionsFilter.defaultProps = {};

export default SectionsFilter;
