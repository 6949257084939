import { useEffect } from "react";

const TRACK_VOLUME_THRESHOLD = 0.001;
const TRACK_VOLUME_SAMPLING_RATE = 50;

export function useSampleTrackVolume(
    audioTrack,
    onSetVolume,
    rate = TRACK_VOLUME_SAMPLING_RATE
) {
    useEffect(() => {
        const volumeInterval = setInterval(() => {
            let vol = audioTrack ? audioTrack.getVolumeLevel() : 0;
            let recordVolume =
                vol > TRACK_VOLUME_THRESHOLD || vol === 0
                    ? vol
                    : TRACK_VOLUME_THRESHOLD;
            onSetVolume(recordVolume);
        }, rate);
        return () => clearInterval(volumeInterval);
    }, [onSetVolume, audioTrack, rate]);
}
