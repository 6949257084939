import React, { useCallback, useContext, useState } from "react";

import {
    useActions,
    useApiActions,
    useData,
    useFetch,
    useFilter,
    useList,
} from "state/hooks";
import {
    detachSection,
    exportToLibrary,
    saveToLibrary,
    toggleProperty,
} from "cms/state/actions/cms";
import { entityDelete } from "state/actions/delete";
import MenuItem from "components/context-menu/MenuItem";
import { PageContext } from "playground/cms/views/Pages";
import { pathChange } from "state/actions/data";
import { submitOne } from "state/actions/api";
import { useClipboardContext } from "playground/cms/Clipboard";
import SelectList, {
    SelectListItem,
    SelectListDivider,
} from "playground/ui/SelectList";
import Icon from "components/Icon";
import { useSectionsFilter } from "./../SectionsFilterContext";

//import PropTypes from 'prop-types';

function TagItem(props) {
    const { type, id, ...other } = props;
    const { title } = useData({ type, id });

    return <MenuItem {...other} primaryText={title} />;
}

function TagManager(props) {
    const { type, id, onClose } = props;
    const { cms_tags } = useData(props);
    const value = cms_tags || [];
    const { items } = useList("cms.tags", "tags.cms.sections");
    const { onChange } = useActions({ onChange: pathChange });
    const { onSubmit } = useApiActions({ onSubmit: submitOne });

    useFetch({
        type: "cms.tags",
        list: "tags.cms.sections",
        url: "cms/tags/cms",
        filter: "tags.cms.sections",
    });
    const toggle = (tag) => {
        let next = (value || []).slice();
        if (next.includes(tag)) {
            next = next.filter((t) => t !== tag);
        } else {
            next.push(tag);
        }
        onChange([type, id, "cms_tags"], next);
        onSubmit(type, id);
    };
    const available = items.filter((t) => !value.includes(t));
    return (
        <>
            <MenuItem onClick={onClose} primaryText={"Go back"} />
            <div className={"cols cols-2"}>
                <div className={"rows"}>
                    {available.map((t) => (
                        <TagItem
                            key={t}
                            type={"cms.tags"}
                            id={t}
                            onClick={() => toggle(t)}
                            selected={value.includes(t)}
                        />
                    ))}
                </div>
                <div className={"rows"}>
                    {value.map((t) => (
                        <TagItem
                            key={t}
                            type={"cms.tags"}
                            id={t}
                            onClick={() => toggle(t)}
                            selected={value.includes(t)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export function ContextMenu(props) {
    const { type, id } = props;
    const page = useContext(PageContext);
    const { active, type: sectionType, template } = useData(props);
    const { onDelete, onToggle, onExport, onDetach, onSaveToLibrary } =
        useApiActions({
            onDelete: entityDelete,
            onToggle: toggleProperty,
            onExport: exportToLibrary,
            onSaveToLibrary: saveToLibrary,
            onDetach: detachSection,
        });
    const deleteUrl = type.replace(".", "/") + "/:id";
    const handleDelete = useCallback(
        () => onDelete(type, id, null, deleteUrl),
        [type, id, onDelete, deleteUrl]
    );
    const toggle = useCallback(
        () => onToggle(type, id, "active"),
        [type, id, onToggle]
    );
    const handleExport = useCallback(() => onExport(id), [id, onExport]);

    const { onSet } = useSectionsFilter();

    const handleShowTemplateSections = useCallback(() => {
        onSet("template", template || id);
    }, [onSet, id, template]);

    const [tags, setTags] = useState(false);

    const { onCopy, onPaste, types } = useClipboardContext();

    if (tags) {
        return [
            <div onClick={(e) => e.stopPropagation()}>
                <TagManager
                    type={type}
                    id={id}
                    onClose={() => setTags(!tags)}
                />
            </div>,
        ];
    }

    const menu = [];

    menu.push(
        <SelectListItem
            key={"ctx-tags"}
            onClick={(e) => {
                setTags(!tags);
                e.stopPropagation();
            }}
            label={"Tags"}
        />
    );

    if ((!page.id && template) || sectionType === "view") {
        menu.push(
            <SelectListItem
                key={"ctx-template"}
                onClick={handleShowTemplateSections}
                icon={<Icon>mui-library_books</Icon>}
                label={"Show template sections"}
            />
        );
    }

    menu.push(
        <SelectListItem
            key={"ctx-active"}
            onClick={toggle}
            icon={active ? <Icon>mui-close</Icon> : <Icon>mui-check</Icon>}
            label={active ? "Deaktywuj" : "Aktywuj"}
        />
    );

    menu.push(
        <SelectListItem
            key={"ctx-copy"}
            onClick={() => onCopy({ type: type, id: id })}
            icon={<Icon>mui-content_copy</Icon>}
            label={"Skopiuj"}
        />
    );

    if (types && types.includes("cms.sections")) {
        menu.push(
            <SelectListItem
                key={"ctx-paste"}
                onClick={() => onPaste({ ...page, section: id })}
                icon={<Icon>mui-content_paste</Icon>}
                label={"Wklej"}
            />
        );
    }

    menu.push(
        <SelectListItem
            key={"ctx-delete"}
            onClick={handleDelete}
            icon={<Icon>mui-delete</Icon>}
            label={"Usuń"}
        />
    );

    if (page.type && page.id) {
        menu.push(
            <SelectListItem
                key={"ctx-detach"}
                onClick={() => onDetach(id)}
                icon={<Icon>mui-link_off</Icon>}
                label={"Odłącz"}
            />
        );
    }

    menu.push(<SelectListDivider />);

    menu.push(
        <SelectListItem
            key={"ctx-save-to-library"}
            onClick={() => onSaveToLibrary(type, id)}
            label={"Save to library"}
        />
    );

    if (sectionType === "view") {
        menu.push(
            <SelectListItem
                key={"ctx-export"}
                onClick={handleExport}
                icon={<Icon>mui-library_books</Icon>}
                label={"Export to library"}
            />
        );
    }

    return <SelectList>{menu}</SelectList>;
}

ContextMenu.propTypes = {};

ContextMenu.defaultProps = {};

export default ContextMenu;
