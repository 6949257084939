import React from "react";
import root from "react-shadow";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
//import PropTypes from 'prop-types';

function ShadowDom(props) {
    return (
        <root.div id={"theme-shadow-root"} className={props.className}>
            <div className={"theme-scope ready"}>{props.children}</div>
        </root.div>
    );
}

function FrameDnd({ children }) {
    return (
        <FrameContextConsumer>
            {({ window }) => (
                <DndProvider backend={HTML5Backend} window={window}>
                    {children}
                </DndProvider>
            )}
        </FrameContextConsumer>
    );
}

function Iframe(props) {
    const { bodyClassName } = props;
    return (
        <Frame
            id={"theme-iframe"}
            className={props.className}
            mountTarget={"body"}
            initialContent={`<!DOCTYPE html><html><head></head><body></body></html>`}
        >
            <FrameDnd>{props.children}</FrameDnd>
        </Frame>
    );
}

const hostComponents = {
    shadow: ShadowDom,
    iframe: Iframe,
};

export function HostElement(props) {
    const { className, children, hostType, ...rest } = props;
    const HostComponent = hostComponents[hostType];

    if (!HostComponent) {
        return <div>Invalid host element: {hostType}</div>;
    }

    return (
        <HostComponent className={className} {...rest}>
            {children}
        </HostComponent>
    );
}

HostElement.propTypes = {};

HostElement.defaultProps = {
    hostType: "iframe",
};

export default HostElement;
