import React from "react";
//import PropTypes from 'prop-types';
//import {Grid as VirtualizedList, AutoSizer} from "react-virtualized";

import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import VirtualizedList from "react-virtualized/dist/commonjs/Grid";

export function VirtualizedListIterator(ItemRenderer) {
    return function ({
        items,
        width,
        height,
        rowHeight,
        columnCount,
        ...other
    }) {
        let columns = columnCount || 1;
        let rows = Math.ceil(items.length / columns);

        const render = (cell) => {
            let index = cell.rowIndex * columns + cell.columnIndex;
            let item = items[index];
            return (
                <ItemRenderer
                    {...other}
                    key={cell.key}
                    index={index}
                    id={item}
                    style={cell.style}
                />
            );
        };
        if (width && height)
            return (
                <VirtualizedList
                    width={width}
                    height={height}
                    columnCount={columns}
                    columnWidth={width}
                    rowHeight={rowHeight || 63}
                    overscanRowCount={0}
                    rowCount={rows}
                    cellRenderer={render}
                />
            );

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <VirtualizedList
                        width={width}
                        height={height}
                        columnCount={columns}
                        columnWidth={width / columns}
                        rowHeight={rowHeight || 63}
                        overscanRowCount={0}
                        rowCount={rows}
                        cellRenderer={render}
                    />
                )}
            </AutoSizer>
        );
    };
}
