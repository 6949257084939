import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectList } from "state/selectors/lists";
import { isPending } from "state/selectors/ui";
import { dataListDelete } from "state/actions/data";

const mapStateToProps = (store, props) => {
  let baseListId = props.list;
  let data = props.items;
  let type = props.type;
  if (!data) {
    let filteredListId = [baseListId, "filter"].join("/");
    data = selectList(store, type, filteredListId);
    if (!props.filtered && !data.length)
      data = selectList(store, type, baseListId);
  }
  let pendingId = props.pendingId ?? [type, baseListId].join("/");
  return {
    items: data,
    isPending: isPending(store, [pendingId], true),
    isEmpty: !data.length,
  };
};

const mapDispatchToProps = {
  onDeleteItem: dataListDelete,
};

class List extends React.PureComponent {
  handleDeleteItem = (item) => {
    const { onDelete, onDeleteItem, type, list } = this.props;
    let deleteFn = onDelete || onDeleteItem;
    deleteFn(type, item, list);
  };

  render() {
    const {
      isPending,
      isEmpty,
      pendingState,
      emptyState,
      Iterator,
      pendingId,
      pendingReplace,
      ...other
    } = this.props;

    if (isEmpty && !isPending && emptyState) return emptyState;

    const iterate = Iterator;

    if (!iterate)
      return (
        <div>
          No list iterator provided {other.list} {other.type}
        </div>
      );

    if (isPending && pendingState && pendingReplace) return pendingState;

    return (
      <>
        {isPending && pendingState ? pendingState : null}
        {iterate({
          ...other,
          onDelete: this.handleDeleteItem,
        })}
      </>
    );
  }
}

List.propTypes = {
  type: PropTypes.string.isRequired,
  list: PropTypes.string,
  fetch: PropTypes.oneOf([false, true, "once"]),
};

List.defaultProps = {
  fetch: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
