import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";

import root from "react-shadow";
import Frame, { FrameContextConsumer } from "react-frame-component";

import version from "util/version";
import { selectWebsiteCanonicalHost } from "cms/state/selectors/cms";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useWindowSize } from "util/hooks/useWindowSize";

const PROTOCOL = window.location.protocol;
const ENDPOINT = process.env.REACT_APP_CONSOLE_ASSETS_URL;
const CACHE_BYPASS_PORT = process.env.REACT_APP_CACHE_BYPASS_PORT;
const CACHE_BYPASS_PORT_HTTPS = process.env.REACT_APP_CACHE_BYPASS_PORT_HTTPS;

const consoleAssetUrl = (asset) => {
    const url = PROTOCOL + "//" + [ENDPOINT, asset].join("/");
    return url.replace("%version", version());
};

const mapStateToProps = (store, props) => {
    const { service } = props;
    const host = selectWebsiteCanonicalHost(store, service);
    const port =
        host?.indexOf("https") === 0
            ? CACHE_BYPASS_PORT_HTTPS
            : CACHE_BYPASS_PORT;
    const shouldLoadThemeCss = host && !props.customThemeCss;
    return {
        themeCss: shouldLoadThemeCss
            ? `${host}:${port}/assets/styles/all.min.css`
            : null,
        stylesCount: shouldLoadThemeCss ? 5 : 0,
        host,
    };
};

const mapDispatchToProps = {};

function ShadowDom(props) {
    const size = useWindowSize();
    console.log(size);
    return (
        <root.div
            id={"theme-shadow-root"}
            className={props.className}
            style={{
                "--vwPx": size.width,
                "--vhPx": size.height,
            }}
        >
            {props.children}
        </root.div>
    );
}

function FrameDnd({ children }) {
    return (
        <FrameContextConsumer>
            {({ window }) => (
                <DndProvider backend={HTML5Backend} window={window}>
                    {children}
                </DndProvider>
            )}
        </FrameContextConsumer>
    );
}

function Iframe(props) {
    return (
        <Frame
            id={"theme-iframe"}
            className={props.className}
            mountTarget={"body"}
        >
            <FrameDnd>{props.children}</FrameDnd>
        </Frame>
    );
}

function ThemePendingState(props) {
    const { PendingState, pending, pendingStatus, children } = props;
    if (PendingState)
        return (
            <>
                {pending && <PendingState status={pendingStatus} />}
                {children}
            </>
        );
    if (!pending) return children;
    return (
        <div>
            {pendingStatus} {children}
        </div>
    );
}

class ThemeShadow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { stylesLoaded: 0 };
    }

    onLoadStyle = () => {
        this.setState({ stylesLoaded: this.state.stylesLoaded + 1 });
    };

    render() {
        const {
            PendingState,
            stylesCount,
            themeCss,
            className,
            hostType,
            host,
        } = this.props;
        const { stylesLoaded } = this.state;
        const pending = stylesCount > stylesLoaded;
        const pendingStatus = `loading styles from ${host}: ${stylesLoaded}/${stylesCount}`;

        const DomWrapper = {
            shadow: ShadowDom,
            iframe: Iframe,
        }[hostType];

        return (
            <ThemePendingState
                PendingState={PendingState}
                pending={pending}
                pendingStatus={pendingStatus}
            >
                <DomWrapper className={cn(className, { loading: !!pending })}>
                    {themeCss ? (
                        <link
                            rel={"stylesheet"}
                            href={themeCss}
                            onLoad={this.onLoadStyle}
                        />
                    ) : null}
                    <link
                        rel="stylesheet"
                        href={consoleAssetUrl("quill.core.css")}
                        onLoad={this.onLoadStyle}
                    />
                    <link
                        rel="stylesheet"
                        href={consoleAssetUrl("quill.bubble.css")}
                        onLoad={this.onLoadStyle}
                    />
                    {/*<link*/}
                    {/*    rel={"stylesheet"}*/}
                    {/*    href={consoleAssetUrl("page.css")}*/}
                    {/*    onLoad={this.onLoadStyle}*/}
                    {/*/>*/}
                    <link
                        rel={"stylesheet"}
                        href={consoleAssetUrl("debug.css")}
                        onLoad={this.onLoadStyle}
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdn.zuu.systems/fh-default/fh-default.css"
                        onLoad={this.onLoadStyle}
                    />
                    {!pending ? this.props.children : null}
                </DomWrapper>
            </ThemePendingState>
        );
    }
}

ThemeShadow.propTypes = {
    hostType: PropTypes.oneOf(["shadow", "iframe"]),
};

ThemeShadow.defaultProps = {
    hostType: "shadow",
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeShadow);
