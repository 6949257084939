import React, {useCallback} from 'react';
import {useNode} from "joynt/hooks";
import {DEFAULT_CARD_COLOR} from "joynt/config";
import cn from "classnames";
import PostIdentity from "joynt/post/PostIdentity";
//import PropTypes from 'prop-types';

export default function Event(props) {
    const {
        id,
        min,
        max,
        onClick
    } = props;

    const {
        name,
        event_start,
        event_end,
        event_speakers,
        color
    } = useNode(id);

    let start = event_start-min;
    let end = event_end-min;
    let range = max-min;
    let unit = range / 100;
    let style = {
        left: start/unit + '%',
        width: (end-start)/unit + '%'
    }
    const click = useCallback(() => {
        onClick(id)
    }, [id, onClick]);

    const fgClass = color || DEFAULT_CARD_COLOR;

    const bgClass = color
        ? `bg-${color}`
        : `bg-${DEFAULT_CARD_COLOR}`;

    return <div
        onClick={click}
        className={cn('timeline-event cols', fgClass)}
        style={style}
    >
        <div className={cn('timeline-event-bg', bgClass)} />
        <PostIdentity identities={event_speakers} />
        <div className={'timeline-event-name'}>{name}</div>
    </div>
}

Event.propTypes = {};
Event.defaultProps = {};
