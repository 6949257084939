import React from "react";
import PropTypes from "prop-types";
import MenuContainer from "containers/ContextMenu";
import { ResponsivePopover } from "components/Popover";

export default function ContextMenu(props) {
    const { items, RenderMenuItems } = props;

    return (
        <MenuContainer>
            {(menuProps) => {
                const { open, anchorEl, onMenuClose } = menuProps;
                return (
          <>
            {props.children({ ...menuProps })}
            <ResponsivePopover
                open={!!open}
                anchorEl={anchorEl}
                onClose={onMenuClose}
                onClick={onMenuClose}
            >
                {RenderMenuItems ? (
                    <RenderMenuItems {...props} id={props.id || menuProps.id} />
                ) : (
                    items
                )}
            </ResponsivePopover>
          </>
                );
            }}
        </MenuContainer>
    );
}

ContextMenu.propTypes = {
    items: PropTypes.any,
};

ContextMenu.defaultProps = {
    open: false,
};
