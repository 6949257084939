import React from "react";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

//import PropTypes from 'prop-types';

export function DatePickerCalendar(props) {
    const { value, onChange, ...other } = props;
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Calendar
                date={value}
                onChange={onChange}
                disablePast={false}
                {...other}
            />
        </MuiPickersUtilsProvider>
    );
}

DatePickerCalendar.propTypes = {};

DatePickerCalendar.defaultProps = {};

export default DatePickerCalendar;
