import React, { useEffect, useState } from "react";
import cn from "classnames";
import Icon from "components/Icon";
import { useIdentity } from "joynt/hooks/participants";
import { IdentityAvatarContainer } from "joynt/components/Avatar";
import { useConfig } from "joynt/meeting/agora-ng/hooks";
//import PropTypes from 'prop-types';

export function AudioIcon(props) {
    const { on, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={cn("tile-icon", {
                on,
                off: !on,
            })}
        >
            <Icon>{on ? "mui-mic" : "mui-mic_off"}</Icon>
        </div>
    );
}

export function VideoIcon(props) {
    const { on, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={cn("tile-icon", {
                on,
                off: !on,
            })}
        >
            <Icon>{on ? "mui-videocam" : "mui-videocam_off"}</Icon>
        </div>
    );
}

export function HandIcon(props) {
    const { on } = props;

    return (
        <div
            className={cn("tile-icon tile-icon--hand", {
                on,
            })}
        >
            <Icon fontSize={"inherit"}>mui-pan_tool</Icon>
        </div>
    );
}

function VideoTile(props) {
    const {
        children,
        name,
        identity,
        elementId,
        audio,
        video,
        own,
        screen,
        speaking,
        focus,
        hand,
    } = props;
    return (
        <div
            className={cn("video-tile", {
                own,
                screen,
                speaking,
                focus,
                "no-video": !video,
            })}
            id={elementId}
        >
            <HandIcon on={hand} />
            {children}
            <div className={"tile-avatar"}>
                <IdentityAvatarContainer
                    id={identity}
                    size={"large"}
                    className={"tile-avatar__image"}
                />
                <div className={"tile-avatar__name"}>{name}</div>
            </div>
            <div className={"tile-info cols cols-middle gap-xs"}>
                {!screen && <AudioIcon on={audio} />}
                {screen && <Icon>mui-screen_share</Icon>}
                <div className={"video-tile__name type-default"}>
                    {own ? "You" : name}
                </div>
            </div>
        </div>
    );
}

function useIndicateSpeaking(isAboveThreshold, timeout) {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const indicateSpeaking = isAboveThreshold && !isSpeaking;

    useEffect(() => {
        if (indicateSpeaking) {
            setIsSpeaking(true);
        }
    }, [indicateSpeaking, timeout]);

    useEffect(() => {
        if (isSpeaking) {
            const tm = setTimeout(() => setIsSpeaking(false), timeout);
            return () => clearTimeout(tm);
        }
    }, [isSpeaking, timeout]);

    return isSpeaking;
}

export default function VideoTileContainer(props) {
    const { identity, volume, ...other } = props;
    const { name } = useIdentity(identity);

    const { TRACK_VOLUME_SPEAKING_THRESHOLD, TRACK_VOLUME_SPEAKING_TIMEOUT } =
        useConfig();

    const aboveThreshold = volume > TRACK_VOLUME_SPEAKING_THRESHOLD * 1;
    const isSpeaking = useIndicateSpeaking(
        aboveThreshold,
        TRACK_VOLUME_SPEAKING_TIMEOUT * 1
    );

    return (
        <VideoTile
            {...other}
            identity={identity}
            name={name}
            speaking={isSpeaking}
        />
    );
}

VideoTile.propTypes = {};
VideoTile.defaultProps = {};
