import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import "./style.css";
import IconButton from "components/IconButton";

export function Section(props) {

    const {
        name,
        children,
        variant,
        cols,
        onShowSettings
    } = props;

    return (<section className={cn("stage-section", {
        [`stage-section--${variant}`]: true,
        [`stage-section--cols-${cols}`]: true
    })}>
        {name && <div className={"stage-section__name"}>
            {name}
            {onShowSettings && <IconButton
                icon={"mui-settings"}
                onClick={onShowSettings}
                size={"small"}
            />}
        </div>}
        <div className={"stage-section__body"}>
            {children}
        </div>
    </section>);

}

Section.propTypes = {
    name: PropTypes.string,
    variant: PropTypes.oneOf(["default", "transparent"]),
    cols: PropTypes.number
};
Section.defaultProps = {
    variant: "default",
    cols: 1
};

export default Section;
