import React from "react";
//import PropTypes from 'prop-types';
import {
    ParticipantsCount,
    SessionConfirmActions,
} from "joynt/components/SessionParticipation";
import AvatarGroup from "joynt/components/AvatarGroup/AvatarGroup.container";
import { useParticipation, useSessionParticipants } from "joynt/hooks/sessions";
import { useSelector } from "react-redux";
import { selectUserNodeIdentity } from "joynt/state/selectors/joint";
import { IdentityAvatarContainer as Avatar } from "joynt/components/Avatar";
import Icon from "components/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import ResponsivePopover from "components/Popover";

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
};

const transformOrigin = {
    vertical: "top",
    horizontal: "center",
};

function ParticipantAvatar(props) {
    const { identity, node } = props;

    return (
        <ResponsivePopover
            placement={"top"}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            trigger={({ onClick }) => (
                <Tooltip title={"You"}>
                    <div className={"participant-avatar"} onClick={onClick}>
                        <Avatar id={identity} size={"xs"} />
                        <Icon>mui-check</Icon>
                    </div>
                </Tooltip>
            )}
            content={({ onClose }) => (
                <div onClick={onClose} className={"dark-mode bg pad-md"}>
                    <SessionConfirmActions id={node} />
                </div>
            )}
        />
    );
}

export function Participants(props) {
    const { id } = props;

    const { isConfirmed } = useParticipation(id);
    const identity = useSelector((s) => selectUserNodeIdentity(s, id));

    const { identities, max, count } = useSessionParticipants(id);
    const otherIdentities = identities.filter((i) => i !== identity);

    return (
        <div className={"cols cols-middle gap-sm"}>
            <ParticipantsCount id={id} />
            {isConfirmed && <ParticipantAvatar identity={identity} node={id} />}
            <AvatarGroup ids={otherIdentities} maxSize={4} />
        </div>
    );
}

Participants.propTypes = {};

Participants.defaultProps = {};

export default Participants;
