import React, { useCallback } from "react";

//import PropTypes from 'prop-types';
import { ParticipantList } from "joynt/participants/ParticipantList";
import SessionSettings from "joynt/meeting/mock/SessionSettings";
import UtilityNavDrawer from "joynt/components/UtilityNav/Drawer/UtilityNavDrawer";

import NavGroup from "joynt/components/UtilityNav/NavGroup";
import Button from "joynt/components/UtilityNav/Button";
import { useUtilityNav } from "joynt/components/UtilityNav/hooks";
import { useRouteParam, useUiFlag } from "state/hooks";
import { ROUTE_UTILITY_NAV } from "joynt/config";

function Nav(props) {
    const { onShow } = props;
    return (
        <NavGroup>
            <Button
                label={"People"}
                onClick={() => onShow("people")}
                icon={"mui-people"}
            />
            <Button
                label={"Settings"}
                onClick={() => onShow("settings")}
                icon={"mui-settings"}
            />
        </NavGroup>
    );
}

export function MockSessionUtilityNav(props) {
    const { id, onPublishEvent, view, onChange } = props;

    return (
        <UtilityNavDrawer
            isOpen={!!view}
            onClose={() => onChange(null)}
            onToggle={() => onChange(view ? null : "settings")}
            nav={<Nav onShow={onChange} view={view} />}
        >
            {view === "people" && (
                <ParticipantList
                    id={id}
                    parent={id}
                    list={`participants.${id}`}
                />
            )}
            {view === "settings" && (
                <SessionSettings channel={id} onPublishEvent={onPublishEvent} />
            )}
        </UtilityNavDrawer>
    );
}

MockSessionUtilityNav.propTypes = {};
MockSessionUtilityNav.defaultProps = {};

export default MockSessionUtilityNav;
