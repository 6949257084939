import React from "react";
import SettingsView from "./SettingsView";

//import PropTypes from 'prop-types';

export function Settings(props) {
    const {} = props;
    return <SettingsView />;
}

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
