import React from 'react';
import PropTypes from 'prop-types';

import {useNodeData} from "joynt/hooks";
import EventOverview from "./EventOverview";
import AggregateRoot from "containers/AggregateRoot";

export function EventOverviewContainer(props) {

    const {id} = props;
    const {content} = useNodeData(id);

    const hasContent = content
        && content.blocks
        && content.blocks.length;

    return (<AggregateRoot type={"db.nodes"} id={id}>
        <EventOverview
            {...props}
            hasContent={hasContent}
        />
    </AggregateRoot>);

}

EventOverviewContainer.propTypes = {
    id: PropTypes.string
};
EventOverviewContainer.defaultProps = {};

export default EventOverviewContainer;