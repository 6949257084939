import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import "./style.css";
import { FormLayout } from "playground/cms/Form";
import Button from "components/Button";
import Icon from "components/Icon";
import UploadButton from "./UploadButton";
import IconButton from "components/IconButton";

function EmptyState(props) {
    // return (
    //     <div className={"media-field-grid"}>
    //         <div className={"media-thumbnail-skeleton"} />
    //         <div className={"media-thumbnail-skeleton"} />
    //     </div>
    // );
    return (
        <div className={"o-30 cols cols-center cols-middle"}>
            Wgraj lub wybierz z biblioteki
        </div>
    );
}

export function Media(props) {
    const { label, onOpenLibrary, onUpload, isEmpty, count, children } = props;
    return (
        <div className={"rows gap-sm"}>
            <div className={"form-section-name"}>{label}</div>
            <div className={cn("media-field", { single: count === 1 })}>
                <div className={"grow"}>
                    {isEmpty ? <EmptyState /> : children}
                </div>
                <div className={"rows rows-right rows-middle pad-md-h gap-xs"}>
                    <div className={"cols gap-xs"}>
                        <UploadButton onUpload={onUpload} />
                        <Button
                            variant={"outlined"}
                            endIcon={<Icon>mui-search</Icon>}
                            onClick={onOpenLibrary}
                        >
                            Wybierz
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

Media.propTypes = {};

Media.defaultProps = {};

export default Media;
