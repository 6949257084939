import React from 'react';
import UserSettings, {useUserSettings} from "joynt/components/UserSettings";

//import PropTypes from 'prop-types';

export function Profile(props) {

    const {workspace} = props;
    const {identity} = useUserSettings(workspace);
    return (<UserSettings id={identity}/>);

}

Profile.propTypes = {};
Profile.defaultProps = {};

export default Profile;
