import React from "react";
import cn from "classnames";
import "./style.css";

const UnderConstructionBar = ({ children, animated }) => {
    return (
        <div
            className={cn(
                "under-construction-bar under-construction-bg",
                animated && "under-construction-bar--animated"
            )}
        >
            {children}
        </div>
    );
};

export default UnderConstructionBar;
