import React, {useState} from 'react';
import Fetch from "containers/Fetch";
import List from "containers/List";
import {useClick, useData} from "state/hooks";
import cn from "classnames";
import Icon from "components/Icon";
import {ArrayIterator} from "components/list/iterator";
import {useNodeData} from "joynt/hooks";
import {Tooltip} from "@material-ui/core";
import {useNotificationAction} from "joynt/hooks/notifications";
import {IdentityAvatarContainer} from "joynt/components/Avatar";
import NotificationDescription from "joynt/notifications/Description";
import DateDiff from "components/DateDiff";
//import PropTypes from 'prop-types';

function AvatarBadge(props) {
    const {visible, children} = props;
    return <div className={cn('avatar-badge', {
        'is-visible': !!visible
    })}>
        {children}
    </div>
}

function Notification(props) {

    const {id, seen, created_at, identity} = useData(props);
    const {onClick} = useNotificationAction(id);
    const propsClick = useClick(props);
    const click = props.onClick ? propsClick : onClick;

    return <div className={cn('notification pad-sm cols gap-sm', {
        'was-seen': !!seen
    })} onClick={click}>
        <AvatarBadge visible={!seen}>
            <IdentityAvatarContainer id={identity} />
        </AvatarBadge>
        <div>
            <NotificationDescription id={id}/>
            <div className={"o-40 notification-date"}>
                <DateDiff value={created_at}/>
            </div>
        </div>
    </div>
}

const NotificationsIterator = ArrayIterator(Notification);

function WorkspaceAvatar({id}) {
    const {name, color, icon} = useNodeData(id);
    return <Tooltip title={name}>
        <div className={`workspace-avatar bg-${color}`}>
            <Icon>{icon}</Icon>
        </div>
    </Tooltip>;
}

function NotificationTag(props) {

    const {onClick} = props;
    const {seen, notifications, workspace} = useData(props);
    const notification = notifications[0];
    const allNotifications = notifications.slice(1);
    const [show, setShow] = useState(false);
    return <div className={cn('notification-group', {
        'is-new': !seen
    })}>
        <Notification
            type={'db.notifications'}
            id={notification}
            onClick={onClick}
        />
        <div className={'notification-origin'}>
            { workspace ? <WorkspaceAvatar id={workspace} /> : null }
        </div>
        {allNotifications.length
            ? <div className={'notifications-expand'} onClick={()=>setShow(!show)}>
                <div className={'notifications-expand-btn'}>
                    { !show ? <div className={'count'}>+{allNotifications.length}</div> : null }
                    { !show ? <div>more notifications</div> : null }
                    { show ? <Icon>mui-expand_less</Icon> : null }
                </div>
            </div>
            : null }
        { show ? <List
            type={'db.notifications'}
            items={allNotifications}
            onClick={onClick}
            Iterator={NotificationsIterator}
        /> : null }
    </div>;

}

const NotificationsTagIterator = ArrayIterator(NotificationTag);

export function NotificationList(props) {
    const url = props.url || `joynt/user/notifications/tag?notification_group=!messages`;
    const {onClick} = props;

    return <Fetch
        type={'db.notifications-tag'}
        list={'user-notifications'}
        url={url}
        enable={true}
        force={true}
    >
        <div className={'notifications-aggregated'}>
            <List
                type={'db.notifications-tag'}
                list={'user-notifications'}
                onClick={onClick}
                Iterator={NotificationsTagIterator}
            />
        </div>
    </Fetch>;
}

NotificationList.propTypes = {};
NotificationList.defaultProps = {};

export default NotificationList;
