import React, { useCallback } from "react";
//import PropTypes from 'prop-types';
import cn from "classnames";

import { SpacesSkeleton } from "./index";

import {
    FLAG_SESSION_PENDING,
    NODE_TYPE_EVENT,
    NODE_TYPE_SESSIONS,
    NODE_TYPE_SPACE,
} from "joynt/config";
import { useKeep, useNode, useNodeData } from "joynt/hooks";
import Icon from "components/Icon";
import Card from "joynt/components/Card";
import { useChannels, useRouter } from "joynt/hooks/channels";
import SpaceHeader from "joynt/components/SpaceHeader";
import { SpaceNestingContext, useSpaceNesting } from "joynt/views";
import { useRouteParam } from "state/hooks";
import Fetch from "containers/Fetch";
import List from "containers/List";
import { useSelector } from "react-redux";

export function NodeIterator(ItemRenderer) {
    return function ({ items, parent, ...other }) {
        return items
            .filter((id) => {
                return id !== parent;
            })
            .map((item, index) => (
                <ItemRenderer {...other} key={item} id={item} index={index} />
            ));
    };
}

function Tile(props) {
    const { id, onClick } = props;
    const { name, subtype } = useNode(id);

    const isPending = useSelector((s) => {
        let data = s.ui[`meeting.connection.${id}`] || {};
        let state = data.connectionState || {};
        return data.loading; //&&
        //!["DISCONNECTING", "DISCONNECTED"].includes(state.currentState)
    });

    return <Card {...props} isPending={isPending} />;

    //return <Card {...props} />;
}

export const SpacesIterator = NodeIterator(Tile);

function SpacesList(props) {
    const { id, className, onClick } = props;

    const list = props.list || `db.nodes.spaces.${id}`;
    let url = props.url;

    if (!url) {
        url = `joynt/nodes/${id}/category`;
    }

    const workspaceId = useRouteParam("id")[0];
    const { onCreate, onEdit } = useChannels(id, list);
    const { onLink, onPreview } = useRouter();

    const { can_edit, subtype, default_view } = useNodeData(id);

    const { onKeep } = useKeep();
    const isSpaces = subtype === NODE_TYPE_SESSIONS;

    const create = useCallback(
        () =>
            onCreate({
                subtype: default_view === "all" ? NODE_TYPE_SPACE : null,
                default_view,
            }),
        [onCreate, default_view]
    );

    const isNested = id !== workspaceId;

    return (
        <div className={cn("spaces-grid", className)}>
            <Fetch type={"db.nodes"} url={url} list={list} />
            <List
                type={"db.nodes"}
                list={list}
                Iterator={SpacesIterator}
                onClick={onClick || onLink}
                onPreview={isSpaces ? onClick : onPreview}
                onKeep={onKeep}
                onEdit={onEdit}
                parent={id}
                buttonLabel={isSpaces ? "Join" : "Info"}
                pendingState={<SpacesSkeleton />}
                pendingReplace={true}
            />
            {isNested && can_edit && (
                <div className={"tile-create pad-sm"} onClick={create}>
                    <div className={"rows gap-xs rows-center o-50 type-lg"}>
                        <div>Create a new space</div>
                        <div className={"cols cols-center"}>
                            <Icon>mui-add</Icon>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export function Spaces(props) {
    const { id } = props;
    const { nested } = useSpaceNesting();

    if (nested) return <SpacesList {...props} />;

    return (
        <div className={"rows gap-sm grow"}>
            <SpaceHeader id={id} tabs={false} />
            <SpaceNestingContext.Provider value={true}>
                <SpacesList {...props} />
            </SpaceNestingContext.Provider>
        </div>
    );
}

Spaces.propTypes = {};
