import React from 'react';
import PropTypes from 'prop-types';
import SidebarNodeItem from "joynt/components/Sidebar/SidebarNav/SidebarItem/SidebarItem.container";
import SidebarSection from "joynt/components/Sidebar/SidebarNav/SidebarSection";
import {useEvents} from "joynt/hooks/channels";

export function Events(props) {

    const {id, onClick} = props;
    const events = useEvents(id);

    if (!events.length) return null;

    return (<SidebarSection name={"Events"}>
        {events.map(id => <SidebarNodeItem
            key={id}
            id={id}
            onClick={onClick}a
        />)}
    </SidebarSection>);

}

Events.propTypes = {};

Events.defaultProps = {};

export default Events;