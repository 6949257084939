import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import {SessionDate} from "joynt/components/TimeUtils";

import "./style.css";
import Button from "components/Button";
import Icon from "components/Icon";

export function EventStage(props) {

    const {
        name,
        start,
        end,
        isLive,
        onLink
    } = props;

    const className = cn("event-session-tile card dashboard-tile brand-bg-alt", {
        "is-live": isLive
    });

    return (<div className={className}>
        <div className={"event-session-tile__heading"}>
            {isLive ? "Live now" : "Up next"}
        </div>
        <div className={"event-session-tile__text"}>
            <div className={"event-session-tile__date"}>
                <SessionDate start={start} end={end} />
            </div>
            <div className={"event-session-tile__name"}>
                {name}
            </div>
        </div>
        <div className={"event-session-tile__link"}>
            <Button
                variant={"text"}
                onClick={onLink}
                endIcon={<Icon>mui-arrow_forward</Icon>}
            >
                Go to session page
            </Button>
        </div>
    </div>);

}

EventStage.propTypes = {
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    isLive: PropTypes.bool,
    onLink: PropTypes.func
};

EventStage.defaultProps = {
    isLive: false
};

export default EventStage;
