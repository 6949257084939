import React from "react";
import {
    SideHelperList,
    SideHelperSection,
} from "joynt/components/SideHelperList";
//import PropTypes from 'prop-types';

const data = [
    {
        id: 4,
        title: "4. About",
        content:
            "This section will be displayed on the main page of your workspace for all its members. You can use this space to write your manifesto or rules for your community.  Or maybe write a little about the community itself - what is it? What does it do? Whom is it for? Is it a startup accelerator? Or maybe a NGO? Or perhaps a yoga club? ",
    },
    {
        id: 5,
        title: "After creating",
        content:
            "Once the workspace is created you will be able to suit it to your needs and make it fit like a glove. Roles for admins, collaborators, members can be assigned, new rooms can be added, channels for chatting, posting, video sessions, announcements and more. And remember - you can always change all the settings later!",
    },
];

export default function CreateWorkspaceRight() {
    return (
        <SideHelperList header={"Create your space with ease"} variant={3}>
            {data.map((section) => (
                <SideHelperSection
                    key={section.id}
                    title={section.title}
                    content={section.content}
                />
            ))}
        </SideHelperList>
    );
}

CreateWorkspaceRight.propTypes = {};
CreateWorkspaceRight.defaultProps = {};
