export default function updateActiveSpeakers(volume, user, threshold, prev) {
    const sortByVolume = (a, b) => {
        return b.level - a.level;
    };
    let sorted = volume.sort(sortByVolume);
    let speakers = sorted.reduce((arr, s) => {
        if (s.level > threshold * 1 && s.uid !== user) {
            arr[s.uid] = Date.now() + s.level;
        }
        return arr;
    }, {});

    const len = Object.keys(speakers).length;
    if (!len) return prev;

    console.log(`[volume] ${len} volume above threshold ${threshold}`, sorted);

    let data = { ...prev, ...speakers };
    let order = Object.keys(data);
    order.sort(function (a, b) {
        return data[b] - data[a];
    });
    let obj = {};
    order.forEach(function (o) {
        obj[o] = data[o];
    });

    if (Object.keys(obj)[0] !== Object.keys(prev)[0]) {
        console.log(
            `[volume] Updated active speaker ${threshold}`,
            sorted,
            obj,
            prev
        );
    }

    return obj;
}
