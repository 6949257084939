import React from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
import Popover from 'components/Popover';
import TextDense from 'components/fields/text/TextDense';
import Switch from '@material-ui/core/Switch';
import {get} from 'lodash';
import Icon from "components/Icon";

const mapState = (store, props) => {
    return {
        options: get(store.data, ['cms.styles','options',props.id]) || []
    }
};

function SelectInput({onChange, options, value, id}) {
    const change = (value, cb) => {
        onChange(value);
        cb();
    };
    return <Popover
        trigger={({onClick}) => <div
            className={'style-class'}
            onClick={onClick}
        >
            {value}
            <Icon>mui-arrow_drop_down</Icon>
        </div>}
        content={({onClose}) => <div
            className={'style-select-menu'}
        >
            {options.map(option => {
                return <div className={'style-select'} key={option} onClick={()=>change(option, onClose)}>
                    {option}
                </div>
            })}
        </div>}
    />;
}

const ConnectedSelectInput = connect(mapState)(SelectInput);

function ValueInput({onChange, value}) {
    return <Popover
        trigger={({onClick}) => <div
            className={'style-class'}
            onClick={onClick}
        >
            {value}
        </div>}
        content={({onClick}) => <div
            className={'pad-sm'}
        >
            <TextDense
                value={value}
                onChange={onChange}
                label={'Value'}
            />
        </div>}
    />;
}

function BooleanInput({onChange, value}) {
    const change = ()=>onChange(value ? null : 'true');
    return <div onClick={change}>
        <Switch checked={!!value} />
    </div>;
}

const types = {
    'value': ValueInput,
    'select': ConnectedSelectInput,
    'boolean': BooleanInput
};

export default class StyleElementValue extends React.PureComponent {

    render() {
        const { id, type } = this.props; //, onChange, value

        const ValueComponent = types[type];

        return (<div className={'cols cols-spread fill'}>
            <div>{id}</div>
            <div>
                {ValueComponent
                    ? <ValueComponent {...this.props} />
                    : null}
            </div>
        </div>);
    }

}

StyleElementValue.propTypes = {};