import React from 'react';
import {connect} from 'react-redux';
import {subscribe} from 'util/broadcasting';
import {changed} from "util/changed";

import { showEventNotification } from 'state/actions/ui';
import {selectCurrentUserId} from "state/selectors/app";

const mapStateToProps = (store, props) => {
    const user = selectCurrentUserId(store);
    const userChannel = user ? 'user-'+user : null;
    const channel = props.channel || userChannel;
    return {
        channel
    }
};

const mapDispatchToProps = {
    defaultHandler: showEventNotification
};

class Notifications extends React.PureComponent {

    componentDidMount() {
        if (!this.props.channel) return;
        this.subscribe(this.props.channel);
    }

    componentDidUpdate(prevProps) {
        if (changed(['channel'], prevProps, this.props)) {
            this.subscribe(this.props.channel);
        }
    }

    subscribe = (channel) => {
        const {
            namespace,
            handler,
            defaultHandler
        } = this.props;

        subscribe(
            channel,
            namespace,
            handler || defaultHandler
        );
    };

    render() {
        return this.props.children || null;
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);