import React from "react";
import { useData } from "state/hooks";
import Section from "cms/containers/Section";
import SectionForm from "playground/cms/views/Sections/SectionForm/SectionForm.container";
import FormRenderContext from "containers/context/FormRenderContext";
import { RenderFieldType } from "containers/Field";
import RenderContext from "cms/context/RenderContext";
import GlobalScripts from "cms/GlobalScripts";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ComponentTreeContainer from "cms/ui/containers/ComponentTree";
import ThemeShadow from "playground/theme/ThemeShadow";
import { useCurrentService } from "playground/cms/hooks";
import SectionPreviewHeader from "playground/cms/views/Sections/SectionPreview/SectionPreviewHeader";

//import PropTypes from 'prop-types';

function RenderField(props) {
    return <RenderFieldType {...props} />;
    return <RenderFieldType {...props} variant={"dense"} />;
}

export function SectionPreview(props) {
    const { id, onClose } = props;
    const type = "cms.sections";
    const data = useData({ type, id });
    let section = data.type === "view" ? data.used_in?.[0]?.section_id : null;
    let structure = data.template || section;
    const website = useCurrentService();
    return (
        <div className={"section-preview cols overflow-hide"}>
            {/*<div className={"section-preview-aside"}>*/}
            {/*    {data.template}*/}
            {/*    <ComponentTreeContainer*/}
            {/*        type={"cms.sections"}*/}
            {/*        id={structure}*/}
            {/*        onFocus={(...args) => console.log(args)}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className={"section-preview-canvas"}>
                <SectionPreviewHeader id={id} />
                <TransformWrapper
                    initialScale={0.4}
                    maxScale={1.4}
                    minScale={0.1}
                    initialPositionX={200}
                    initialPositionY={200}
                    centerZoomedOut={false}
                    limitToBounds={false}
                >
                    <TransformComponent>
                        <div className={"section-preview-artboard"}>
                            <ThemeShadow
                                hostType={"shadow"}
                                className={"preview-desktop"}
                                websiteId={website?.id}
                                fetchStyles={true}
                            >
                                <RenderContext.Provider value={null}>
                                    <GlobalScripts>
                                        <Section
                                            id={section || id}
                                            type={"cms.sections"}
                                        />
                                    </GlobalScripts>
                                </RenderContext.Provider>
                            </ThemeShadow>
                            <ThemeShadow
                                hostType={"iframe"}
                                className={"preview-phone"}
                                websiteId={website?.id}
                            >
                                <RenderContext.Provider value={null}>
                                    <GlobalScripts>
                                        <Section
                                            id={section || id}
                                            type={"cms.sections"}
                                        />
                                    </GlobalScripts>
                                </RenderContext.Provider>
                            </ThemeShadow>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>

            <div className={"section-preview-form overflow-hide rows"}>
                <FormRenderContext.Provider value={RenderField}>
                    <SectionForm
                        type={"cms.sections"}
                        id={section || id}
                        onClose={onClose}
                    />
                </FormRenderContext.Provider>
            </div>
        </div>
    );
}

SectionPreview.propTypes = {};

SectionPreview.defaultProps = {};

export default SectionPreview;
