import React from 'react';
import Grow from "@material-ui/core/Grow";
import cn from "classnames";
//import PropTypes from 'prop-types';

export default function PageModal(props) {
    const {children, transitionProps, open} = props;
    return <Grow {...transitionProps}>
        <div className={cn('page-modal',{'is-open': open})}>
            {children}
        </div>
    </Grow>;
}

PageModal.defaultProps = {
    open: false,
    transitionProps: {}
}

PageModal.propTypes = {};
PageModal.defaultProps = {};
