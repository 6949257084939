import React from 'react';
import FormLayout from "components/auth/FormLayout";
import Field from "containers/Field";
import {useData, usePending} from "state/hooks";
//import PropTypes from 'prop-types';

export default function PasswordResetForm(props) {

    const {onChangeView, onResetPassword} = props;
    const {success} = useData({type: 'ui.forms', id: 'password-reset'});
    const pending = usePending('password-reset');

    return (<FormLayout
        {...props}
        onSubmit={!success ? onResetPassword : null}
        submitLabel={'Reset'}
        onCancel={()=>onChangeView('login')}
        cancelLabel={'Go back'}
        isPending={pending}
    >
        { success
            ? <div className={'text-center'}>
                Check your email for further instructions.
            </div>
            : <Field
                label={'E-mail'}
                id={'username'}
                fieldType={'text'}
                validate={true}
            /> }

    </FormLayout>);

}

PasswordResetForm.propTypes = {};
PasswordResetForm.defaultProps = {};
