import { useCover, useData } from "state/hooks";
import React from "react";
import PropTypes from "prop-types";
import Avatar from "joynt/components/Avatar/Avatar";

export const IdentityAvatarContainer = ({ id, ...other }) => {
    const { name, media, description } = useData({ type: "db.identities", id });

    const src = useCover(media, [64, 64]);

    return (
        <Avatar src={src} name={name} description={description} {...other} />
    );
};

IdentityAvatarContainer.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
