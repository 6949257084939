import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";

import {LiveSessionIcon} from "joynt/components/Sidebar/Icons";
import SidebarItem from "joynt/components/Sidebar/SidebarNav/SidebarItem";

import "./style.css";
import CircularProgress from "@material-ui/core/CircularProgress";

function ConnectingState({isConnecting}) {
    return <div className={cn("session-item-connecting", {
        "is-visible": isConnecting
    })}>
        <CircularProgress size={16} color={"inherit"} />
        <div>Joining session...</div>
    </div>;
}

export function LiveSession(props) {

    const {
        id,
        isLive,
        isConnected,
        isConnecting,
        icon,
        timer,
        start,
        avatars,
        ...other
    } = props;

    return (<SidebarItem
        {...other}
        className={cn("session-item")}
        icon={<LiveSessionIcon
            size={"xs"}
            isConnected={isConnected}
        />}
        editIcon={null}
        endIcon={null}
    >
        <div className={"session-item__footer"}>
            {avatars}
            <div className={"session-item__timer"}>
                {timer}
            </div>
        </div>
        <ConnectingState isConnecting={isConnecting} />
    </SidebarItem>);

}

LiveSession.propTypes = {
    isLive: PropTypes.bool,
    isConnected: PropTypes.bool,
    isConnecting: PropTypes.bool
};

LiveSession.defaultProps = {
    isLive: false,
    isConnected: false,
    isConnecting: false
};

export default LiveSession;
