import React from 'react';
import cn from "classnames";
import PropTypes from 'prop-types';

import Icon from "components/Icon";
import LiveNowBadge from "./LiveNowBadge";

import "./style.css";

export function MainStageEvent(props) {

    const {
        isActive,
        isLive,
        onClick,
        timer,
        name
    } = props;
    return (<div className={cn('live-now rows',{
        'is-active': isActive,
        'is-live': isLive
    })} onClick={onClick}>
        <div className={'cols cols-spread cols-middle cols-top gap-md'}>
            <div className={'live-now__label cols cols-middle gap-xs'}>
                <Icon>mui-subscriptions</Icon>
                {isLive && <LiveNowBadge /> }
                <div>
                    { name && <div className={'live-now__name'}>
                        {isLive ? name : <span>Up next: {name}</span>}
                    </div> }
                </div>
            </div>
            { timer && <div className={'cols cols-middle gap-xs'}>
                <div className={'live-now__timer'}>
                    {timer}
                </div>
            </div> }
        </div>
    </div>);

}

MainStageEvent.propTypes = {
    isActive: PropTypes.bool,
    isLive: PropTypes.bool,
    onClick: PropTypes.func,
    name: PropTypes.string,
    timer: PropTypes.element
};
MainStageEvent.defaultProps = {
    isActive: false,
    isLive: false
};

export default MainStageEvent;
